import clickMixin from '@/mixins/clickMixin';
import { API, DOWNLOAD, VUE_EVENT_NAME } from '@/utils/constants';
import { mapState, mapMutations } from 'vuex';

export default {
  mixins: [clickMixin],
  computed: {
    ...mapState(['exportLoading', 'counter']),
    ...mapState('session', ['tabs']),
    tab() {
      if (this.isAdvance) {
        return this.$store.state.byEventSession.tabs[this.uniqueIndex];
      }
      return this.tabs[this.uniqueIndex];
    },
  },
  data() {
    return {
      visible: false,
      checkedOptions: [],
      exportTypeInfo: {
        [DOWNLOAD.LABS]: {
          type: 1,
          endpoint: API.EXPORT_LAB,
          filename: 'EMR_lab_data',
          fileType: DOWNLOAD.CSV,
        },
        [DOWNLOAD.RECORDS]: {
          type: 2,
          endpoint: API.EXPORT_RECORD,
          filename: 'EMR_searched_record',
          fileType: DOWNLOAD.CSV,
        },
        [DOWNLOAD.SUMMARY]: {
          type: 3,
          endpoint: API.EXPORT_INDICATOR_SUMMARY,
          filename: 'EMR_indicator_summary',
          fileType: DOWNLOAD.XLSX,
        },
        [DOWNLOAD.PATIENT_LIST]: {
          type: 4,
          endpoint: API.EXPORT_INDICATOR_PATIENT_LIST,
          filename: 'EMR_indicator_patient_list',
          fileType: DOWNLOAD.XLSX,
        },
        [DOWNLOAD.EVENT_PATIENT_LIST]: {
          type: 5,
          endpoint: API.EXPORT_EVENT_PATIENT_LIST,
          filename: 'EMR_event_patient_list',
          fileType: DOWNLOAD.XLSX,
        },
        [DOWNLOAD.ORDERS]: {
          type: 6,
          endpoint: API.EXPORT_ORDER,
          filename: 'EMR_medications',
          fileType: DOWNLOAD.CSV,
        },
      },
    };
  },
  methods: {
    ...mapMutations(['setExportLoading', 'setCounter', 'setExportIdMappingCount', 'setEMRSearchExportIdList']),
    showModal() {
      if (this.exportOptions && this.exportOptions.length === 1) {
        this.checkedOptions = this.exportOptions;
        this.doExportAndCloseModal();
      } else if (this.exportRecordsLimit && !this.exportLimitModalVisible) {
        this.exportLimitModalVisible = true;
      } else {
        this.exportLimitModalVisible = false;
        this.emptySelectError = false;
        this.exportLimitModalVisible = false;
        this.visible = true;
        this.checkedOptions = [];
      }
    },
    upgradeLoaded(counter) {
      this.setCounter(counter);
    },
    async handleExport(apiHelper, payloadFunc) {
      this.visible = false;
      // Log once for all options selected on a single export modal click.
      if (this.isAdvance) {
        this.advanceTrackClick('downloadExcel', { selected: this.checkedOptions });
      } else {
        this.trackClick('downloadExcel', { selected: this.checkedOptions });
      }

      // Do each export operation synchronously to support viewing download state
      // for each export operation.
      for (const checkedOption of this.checkedOptions) {
        try {
          this.setCounter(0);
          const { endpoint, exportContent } = payloadFunc(checkedOption);
          const result = await apiHelper.exportData(endpoint, exportContent, this.upgradeLoaded);
          this.setEMRSearchExportIdList(result.requestId);
          this.setExportIdMappingCount({
            id: result.requestId,
            OPDCount: this.tab.counters.OPD.Record,
            IPDCount: this.tab.counters.IPD.Record,
          });
        } catch (error) {
          console.error(error);
          this.exportLimitHint = true;
        }
      }

      this.$root.$emit(VUE_EVENT_NAME.SET_EXPORT_JOB);
      this.checkedOptions = [];
    },
  },
};
