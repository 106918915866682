<template>
  <a-modal
    key="record-modal"
    destroyOnClose
    v-model="visible"
    class="patient-modal"
    style="top: 0px"
    width="1278px"
    height="600px"
    :footer="null"
    :zIndex="9999"
  >
    <div id="patient-id">Patient ID</div>
    <div id="patient-id" class="id-margin">{{ pid }}</div>
    <div id="patient">
      <div v-if="uiConfig.Timeline">
        <div style="margin-bottom: 20px">
          <span id="timeline-title">Timeline</span>
          <span> 顯示這個病患所有住院與門診紀錄(黃色為符合條件的紀錄) </span>
        </div>
        <Timeline :chart_no="pid" :uniqueIndex="uniqueIndex" :mode="mode" />
      </div>
      <div v-if="uiConfig.PatientLabTable">
        <br /><br />
        <br /><br />
        <span id="lab-title">Lab</span>
        <span> 顯示這個病人每天初次檢驗數據 </span>
        <ExamTable :chart_no="pid" />
      </div>
    </div>
  </a-modal>
</template>
<script>
import trackTimeMixin from '@/mixins/trackTimeMixin';
import Timeline from '@/views/components/Timeline.vue';
import ExamTable from '@/views/components/PatientLabTable.vue';
import { mapSubUiConfig } from '@/utils/uiConfig';
import { PAGES } from '@/utils/constants';

export default {
  name: 'PatientInformationModal',
  mixins: [trackTimeMixin],
  components: {
    Timeline,
    ExamTable,
  },
  props: {
    pid: String,
    uniqueIndex: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    visible() {
      if (!this.visible) {
        this.endTime('PatientInformationModal', { pid: this.pid });
      } else {
        this.startTime('PatientInformationModal', { pid: this.pid });
      }
    },
  },
  computed: {
    ...mapSubUiConfig('PatientInformationModal'),
  },
  data() {
    return {
      visible: false,
      mode: PAGES.INPATIENT_EMR,
    };
  },
  methods: {
    handleShowPatient() {
      this.visible = false;
    },
  },
  created() {
    // different logic with trackTimeMixin
    window.addEventListener('beforeunload', async () => {
      if (this.visible) {
        await this.endTime('PatientInformationModal', { pid: this.pid });
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.patient-modal {
  width: 1200px;
}

#patient-id {
  height: 20px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333f6b;
}

.id-margin {
  margin-bottom: 42px;
}

#patient {
  width: 100%;
  display: inline-block;
}

#timeline-title {
  width: 56px;
  height: 18px;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333f6b;
  margin-bottom: 20px;
  margin-right: 10px;
}

#lab-title {
  width: 56px;
  height: 18px;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333f6b;
  margin-right: 10px;
}
</style>
