export default {
  生化: [
    'Myoglobin',
    'Mg',
    'Total protein',
    'Protein (心胞液)',
    'ALT',
    'A/G Ratio',
    'Globulin',
    'Glucose (心胞液)',
    'Alkalinephosphatase',
    'OGTT',
    'G.T.T.(1hr)',
    'G.T.T.(2hr)',
    'G.T.T.(AC)',
    'G.T.T (3hrs)',
    'Ammonia',
    'BUN (Spot Urine)',
    'γ-GT',
    'Creatinine (腹水)',
    'Glucose AC',
    'ALKP (其他)',
    'Triglyceride (心胞液)',
    '24 hrs 總尿量',
    'Mg (Spot Urine)',
    'Creatinine (Spot Urine)',
    'Cholsterol (心胞液)',
    'K',
    'Protein / Creatinine',
    'Glucose AC (one touch)',
    'P (24hrs Urine)',
    'P(urine測量值)',
    'P (24hrs)',
    'Creatinine (urine測量值)',
    'Creatinine (24hrs)',
    'Creatinine (24hrs Urine)',
    'Microalbumin',
    'Microprotein / Creatinine',
    'Triglyceride',
    'Lipase',
    'Cl (24hrs)',
    'CL (24hrs Urine)',
    'Cl(urine測量值)',
    'Na',
    'Direct Bilirubin',
    'Cholesterol, Total',
    'Glucose (CSF)',
    'CK-MB',
    'Total Protein (CSF)',
    'PH',
    'PO2',
    'PCO2',
    'O2SAT',
    'ctco2',
    'HCO3act',
    'BE(ect)',
    'LDL-cholesterol',
    'Amylase (其他)',
    'BUN',
    'Creatintine (WCC1)',
    'BUN (WCC1)',
    'Glucose (PET 4 hr)',
    'Creatinine (PET 4hr)',
    'Cl',
    'P (Spot Urine)',
    'Glucose (PET 0 hr)',
    'Creatinine (PET 0 hr)',
    'Glucose (PET 2 hr)',
    'Creatinine (PET 2 hr)',
    'Mg (24 hrs)',
    'UA (Spot Urine)',
    'Mg (24hrs Urine)',
    'Mg(urine測量值)',
    'Cholesterol (其他)',
    'Glucose (Spot Urine)',
    'LDH (其他)',
    'ALKP  (腹水)',
    'P',
    'BUN (24hrs)',
    'BUN (urine測量值)',
    'BUN (24hrs Urine)',
    'NT-pro BNP',
    'AST',
    'Uric acid',
    'Blood gas analysis (VEIN)',
    'Cl (Spot Urine)',
    'LDH (胸水)',
    'Protein (胸水)',
    'Glucose (胸水)',
    'Lipase (其他)',
    'Amylase',
    'BUN(下次洗腎前)',
    'Glucose PC (1 hr)',
    'HDL-cholesterol',
    'HbA1c',
    'Glucose (24hrs Urine)',
    'Glucose (urine測量值)',
    'Glucose (24hrs)',
    'Total protein(其他)',
    'BUN (腹水)',
    'CHOL/HDL-C',
    'Amylase (24 hrs Urine)',
    'Amylase (24hrs)',
    'Amylase (urine測量值)',
    'TP(urine測量值)',
    'Total Protein(24hr Urine)',
    'Total Protein (24hrs)',
    'Glucose PC',
    'Amylase (Spot Urine)',
    'ALB (其他)',
    'Protein  (腹水)',
    'Albumin  (腹水)',
    'Creatintine (其他)',
    'LDH (腹水)',
    'Glucose (其他)',
    'COHb',
    'MetHb',
    'Lactic acid',
    'Glucose (腹水)',
    'Amylase (腹水)',
    'Microalbumin / Creatinine',
    'Albumin (胸水)',
    'Glucose PC (one touch)',
    'Ca++',
    'Blood Ketone',
    'Amylase (胸水)',
    'Albumin',
    'CRP-HS',
    'Serum Fe & TIBC',
    'FE',
    'TIBC',
    'Ca (Spot Urine)',
    'BUN (其他)',
    'Alkalinphosohatase',
    'Total Protein(Spot Urine)',
    'Ca (24hrs)',
    'Ca(urine測量值)',
    'Glucose (關節液)',
    'Ca (24hrs Urine)',
    'Protein (關節液)',
    'Uric acid (24hrs Urine)',
    'UA (urine測量值)',
    'Uric acid (24 hrs)',
    'K (24hrs Urine)',
    'K (24hrs)',
    'K(urine測量值)',
    'Total Bilirubin',
    'CRP',
    'NA(urine 測量值)',
    'Na (24hrs)',
    'NA (24hrs Urine)',
    'Ca',
    'CPK',
    'Glucose (random)',
    'Blood gas analysis',
    'Triglyceride (其他)',
    '乙醇Ethyl alcohol',
    'Creatinine (洗腎後)',
    'BUN (洗腎後)',
    'Na (Spot Urine)',
    'Troponin組套',
    'Troponin-I',
    'Creatinine',
    'eGFR',
    'K (Spot Urine)',
    'Osmolality(serum)',
    'COMMENT',
    'LDH',
    'Osmolality(urine)',
  ],
  血液: [
    'Teardrop cells',
    'Lymphocyte (total)',
    'Hypochromic',
    'Eosinophil count',
    'HGB',
    'Spherocytes',
    'WBC',
    'RBC',
    'PLT',
    'CBC',
    'HCT',
    'MCV',
    'MCHC',
    'MCH',
    'Target cell',
    'Macrocytic',
    'Promegaloblast',
    'Special stain',
    'RET',
    'Normocytic',
    'Giant Platelet',
    'Normochromic',
    'Hypersegmented Neut',
    'Polychromic',
    '判讀醫師',
    'Reticulum cell',
    'Megakaryocyte Morph.',
    'Stomatocytes',
    'Band form  Eosin.',
    'Fragmented cells',
    'Lymphocyte  Medium',
    'LYMPH%',
    'MONO%',
    'EO%',
    'BASO%',
    'DIFF',
    'NEUT%',
    'Reticulocyte count',
    'Double check 醫檢師',
    'Blood type',
    'Rh type',
    'Mitosis',
    'Acanthocytes',
    'Helmet cells',
    'ATYPICAL LYMPH',
    'Band form  Baso.',
    'Toxic granules',
    'Gaint Platelet',
    'D-Dimer',
    'BAND',
    'Basophlic stippling',
    'Malaria or Filaria(smear)',
    'BT',
    'CT',
    'PLASMA',
    'LAP SCORE',
    'BM examination',
    'Pappenheimer bodies',
    'Megaloblast',
    'Iron stain',
    'PAS Stain',
    'Platelet clumping',
    'META',
    'Others',
    'APTT',
    'Unit',
    'Band form  Neutro.',
    'Interpretation',
    'Location',
    'Segment Form Neutro.',
    'Cellularity',
    'Myelocyte  Neutro.',
    'Metamyelocyte Neut.',
    'Normoblast Polychro.',
    'Normoblast Orthochr.',
    'Fibrinogen',
    'Peroxidase stain',
    'Normoblast  Baso.',
    'Promyelocyte',
    'Poikilocytosis',
    'Anisocytosis',
    'RBC Morphology',
    'Prothrombine time',
    'PT INR',
    'MNPT',
    'PT',
    'Pronomoblast',
    'Blasts',
    'Monocyte',
    'Megakaryocyte Number',
    'Hyperchromic',
    'Metamyelocyte Eosin.',
    'M/E ratio',
    'Plasma Cell',
    'MYELO',
    'PLT Aspirin agonist',
    'FDP',
    'Other',
    'Segment Form Baso.',
    'Elli/Ovalocyte',
    'Lymphocyte  Small',
    'HbA',
    'HbA2',
    'Hb電泳(或HPLC)',
    'HbF',
    'Lymphocyte  Large',
    '臨床診斷',
    'NRBC%',
    'Burr cells',
    'Microcytic',
    'PLT P2Y12 agonist',
    'P2Y12 Reaction Unit',
    'PROMYELO',
    'Inhibition rate (%)',
    'BASE:',
    'PRO_MONO',
    'PRO-LYMP',
    'APTT control',
    'COMMENT',
    'E.S.R.',
    'Segment Form Eosin.',
    'BLAST',
    'Metamyelocyte Baso.',
    'Bite cells',
    'Myelocyte Baso.',
    'Toxic Granules',
    '....',
    'Myelocyte  Eosin.',
  ],
  尿液: [
    'Others',
    'Other',
    'Urine Routine',
    'UF',
    'Protein',
    'Glucose',
    'pH',
    'Turbidity',
    'Nitrite',
    'Ketone',
    'Bilirubin',
    'Color',
    'Occult Blood',
    'Urobilinogen',
    'Specific Gravity',
    'Leukocyte esterase',
    'RBC',
    'Crystal',
    'Cast',
    'WBC',
    'Bacteria',
    'Yeast-like',
    'Urine Routine & Sediment',
    'Epithelial Cell',
    'Amphetamine(定性)',
    'Epithelial Cells',
    'Morphine(定性)',
    'COMMENT',
    'Pregnancy test',
  ],
  細菌: [
    '* Blood Culture',
    'TB PCR定量 (其他檢體)',
    'Caspofungin',
    'Micafungin',
    'Organism 3',
    'Cefazolin(Other)',
    'Cefazolin(CZ30)',
    'Tazocin(TZP110)',
    'Blood Culture Result',
    'Ceftazidime(CAZ30)',
    'Gentamicin(GM120)',
    'Rifampin (RIF5)',
    'AST Table 1',
    'Growth 1',
    'Clindamycin(CC2)',
    'Aztreonam(ATM30)',
    'O27/NAPI/BI-高產毒菌株',
    'Cl. difficile toxin',
    'Cl.difficle toxin PCR',
    'Ampicilin(AM10)',
    'Trimeth/sulfa(SXT)',
    'Flumarin(FLO30)',
    'Aerobic Culture Result',
    'Organism 6',
    'TB PCR定量(sputum)',
    '* INDIA INK STAIN',
    'INDIAN INK',
    'Levofloxacin(LVX)',
    'Meropenem(MEM10)',
    'Ciprofloxacin(CIP5)',
    'Ampi/Sulbactam(SAM20)',
    'Influenza A PCR',
    'Influenza B PCR',
    'Gentamicin (GM10)',
    'Amikacin(AN30)',
    'Cefpirome(CPO30)',
    'C. difficile Toxin',
    'C. difficile Ag',
    'C. difficile 抗原篩檢',
    'Tigecycline(TGC15)',
    'Ceftriaxone(CRO30)',
    'Doripenem(DOR10)',
    'TB PCR定量 (Urine)',
    'Pipera/Tazobactam(TZP110)',
    "Gram''s stain",
    'Growth 3',
    'AST Table 3',
    'Cefuroxime (CXM30)',
    'Ertapenem(ETP10)',
    'Organism 5',
    'TB Rifampin Resistance',
    '* GBS culture',
    'Gentamicin High Level',
    'Streptomycin High Level',
    'Nei.gonorrhoeae PCR',
    'Chl.trachomatis PCR',
    'Flomoxef(FLO30)',
    'Organism 2',
    'Imipenem(IPM10)',
    'AST Table 6',
    'Growth 6',
    'GBS culture Result',
    'TB PCR定量 (腹水)',
    'TB PCR定量 (CSF)',
    'Cefoxitin 30',
    'Augmentin(AMC30)',
    'TB PCR',
    'MTB Conc.',
    'Sample type 1',
    'COMMENT',
    'Penicillin(P10)',
    'Organism 4',
    'Growth 5',
    'AST Table 5',
    '* KOH Preparation',
    'KOH Findings',
    'Vancomycin(VA30)',
    'Itraconazole',
    'Amikacin (AK-32)',
    '* An Culture',
    'Metronidazole(MET80)',
    'TB PCR定量 (胸水)',
    'Result',
    '* ACID FAST STAIN (院內)',
    'TB PCR定量',
    'AST Table 2',
    'Growth 2',
    '厭氧培養基',
    'Cefazolin(Urine)',
    'Linezolid(LZD30)',
    'Teicoplanin(TEC30)',
    'Erythromycin(E15)',
    'Cefazolin',
    'Organism 1',
    'Chloramphenicol(C30)',
    'Cefepime(FEP30)',
    '* Aerobic Culture',
    'Cefazolin.',
    'Daptomycin(DAP)',
    "* Gram's stain",
    'Oxacilline(OX1)',
    'Moxifloxacin(MXF5)',
    'Amphotercin B',
    'Growth 4',
    'AST Table 4',
    '* Aerobic/An Culture',
    'Flucytosine',
    'Voriconazole',
    'Drug ENB',
    'Fluconazole',
    'Flucytosin',
    'MTB Result',
    'Netilmicin(NET30)',
    'Isoniazid 0.2',
    'Linezolid(LZD16)',
  ],
  血清: [
    'Streptococcus gr.A rapid',
    'AFP',
    '唐氏症兒危險率',
    '唐氏症篩檢',
    '本年齡危險率',
    '神經管缺損危險率',
    'Enterovirus 71 IgM rapid',
    'CEA (其他)',
    'HIV-1/2 Ab Rapid Test',
    'FSH',
    'CEA',
    'LH',
    'Homocysteine',
    'IgA',
    'Pneumococcus rapid screen',
    'Microalbumin / Creatinine',
    'Rota virus Ag',
    'Testosterone',
    'CA199',
    'ST2蛋白(心衰竭預後指標)',
    'I-PTH',
    'IgG (CSF)',
    'Prealbumin',
    'Cryptococcus Ag(serum)',
    'Prolactin',
    'E2',
    'CEA (心包液)',
    'Transferrin',
    'Vit B12',
    'Microalbumin',
    'Microalbumin (CSF)',
    'Chlamydia pneumoniae IgM',
    '24 hrs 總尿量',
    'Microalbumin (24HR)',
    'IgG',
    'Chlamydia penumoniae Ag',
    'Cyfra 21-1(肺癌指標)',
    'Cortisol AM 6-10',
    'Folate',
    'AFP (其他)',
    'ACTH',
    'PSA',
    'CRP',
    'Influenza B Ag',
    'Influenza A Ag',
    'AFP (心包液)',
    'Cold hemoagglutinin',
    'IgM',
    'CA125',
    'Cortisol PM 4-8',
    'CEA (胸水)',
    '登革熱NS1抗原快篩',
    'T3',
    'NSE(肺癌指標)',
    'Free PSA',
    'FREE PSA/PSA',
    'CA-199 (其他)',
    'C-peptide',
    'B-HCG',
    'T4',
    'Cortisol AM 7-10',
    'RSV Ag',
    'Ferritin',
    'Thyroglobulin',
    'Adeno virus Ag(呼吸道)',
    'TPPA-TPHA(CSF報告僅供參考',
    'STS(RPR)',
    'Adenovirus Ag',
    'Chlamydia Ag',
    'Strep. pneumoniae Ag',
    'TSH',
    'SCC',
    'RF',
    'COMMENT',
    'CA153',
    'Streptococcus gr.A Ag',
    'AFP (胸水)',
    'Free T4',
    'CA-125 (其他)',
    'Norovirus rapid test',
    'TPPA(TPHA)',
    'Legionella pneumophila Ag',
    'C4',
    'PCT',
    'C3',
    'ASLO',
    'Mycoplasma pneumonia Ag',
    'Cyptococcus Ag (CSF)',
    'Anti-Thyro.Peroxidase Ab',
    'Anti-Thyroglobulin Ab',
    '糞便 OB 快篩',
    'TSH receptor Ab',
    'M.pneumoniae IgM',
    'Legionella pneumophila',
    'Crypto. neoformans Ag',
    'CA153  (其他)',
  ],
  風免科: [
    'F91芒果',
    'D1屋塵璊',
    'K82Latex乳膠',
    'K80福馬林',
    'C7 Cefaclor',
    'C1 Penicilloyl G',
    'Anti-Ro &La Antibody',
    'Anti-Ro Antibody',
    'Anti-La Antibody',
    'F87哈蜜瓜',
    'F24蝦子',
    'B2-glycoprotin I Ab IgM',
    'Preformed Antibody Test',
    'F1蛋白',
    'F2牛奶',
    'F23蟹',
    'M5白色念珠菌',
    'F13花生',
    'F25蕃茄',
    'W1豬草',
    'D2粉塵璊',
    'Anti-Cardiolipin IgM',
    'MX2(黴菌類)',
    'RX3(雜草類)',
    'Anti-Ribosomal-P',
    'F3鱈魚',
    'G2狗牙根草',
    'Panel-E S-IgE(混合型',
    'Panel-K S-IgE',
    'F17榛果',
    'FX1(堅果類)',
    'F18巴西果',
    'HLA-B5801',
    'Panel-S',
    'M1青黴菌',
    'Panel-F S-IgE(食入性',
    'M3煙色麴菌',
    'M2芽枝黴菌',
    'M6交錯黴菌',
    'T19相思樹',
    'T18尤加利樹',
    'HLA-B27',
    'COMMENT',
    '自費過敏原25項',
    'HX2(塵蹣類)',
    'Ro52',
    'PM-Scl 100 (PM100)',
    'PM-Scl75 (PM75)',
    'Ku',
    'Anti-Scl100/75 Antibody',
    '自費過敏原50項',
    'B2-glycoprotin I Ab IgG',
    'T17日本杉',
    'W18羊蹄草',
    'Panel-D S-IgE(吸入性',
    'Phadiatop (Qualitative)',
    'Panel-L S-IgE',
    'EX73禽鳥羽毛類',
    'Panel-J S-IgE',
    'Panel-A S-IgE',
    'Anti-CCP Antibody',
    'ECP Test',
    'Quantiferon-TB Gold',
    'F14大豆',
    'F79麵筋',
    'HLA-AB',
    'HLA-DR',
    'Rheumatoid Factor IgM',
    'SAE1',
    'MDA5',
    'NXP2',
    'Mi-2alpha (Mi-2a)',
    'TIF1gamma(T1F1g)',
    'OJ',
    'Myositis antibodies',
    'SRP',
    'EJ',
    'PL-12',
    'PL-7',
    'Jo-1',
    'Anti-Jo1(EJ/OJ) Antibody',
    'Mi-2beta (Mi-2b)',
    'F41鮭魚',
    'Panel Reactive Ab class2',
    'FX10(蛋肉類)',
    'F284火雞肉',
    'EX2(動物毛屑類)',
    'F31胡蘿蔔',
    'F10芝麻',
    '過敏免疫風濕三合一',
    'phadiatop infant',
    'C5 Ampicilloyl',
    'C6 Amoxicilloyl',
    'E87大白鼠',
    'E88小白鼠',
    'E6天竺鼠皮屑',
    'Anti-RNP &Sm Antibody',
    'Anti-Sm Antibody',
    'Anti-RNP Antibody',
    'M8長蠕孢屬',
    'W10藜',
    'W9車前草',
    'G5黑麥草',
    'G17百喜草',
    '抗生素過敏組合',
    'Anti Mi-2 Antibody',
    'Crystal Examination',
    'F4小麥',
    'F84奇異果',
    'F20杏仁',
    'd201五爪璊',
    'Cryoglobulin',
    'F47大蒜',
    'F35馬鈴薯',
    'F75蛋黃',
    'C6 安莫西林',
    'C5 安比西林',
    'C2 青黴菌',
    'C7 頭泡菌素',
    'C1 盤尼西林',
    'E86鴨毛',
    'I207亞洲蟑螂',
    'F25番茄',
    'K82乳膠',
    'E85雞毛',
    'W12菊科植物',
    'F37孔雀蛤',
    'F45酵母菌',
    'W6艾草',
    '過敏晶片',
    'F300羊奶',
    'G6牧草',
    'F81乳酪',
    'W14刺莧草',
    'Panel-C S-IgE(吸入性',
    'cANCA',
    'pANCA',
    'F95桃',
    'F40鮪魚',
    'F37藍貝',
    'F89芥末',
    'F92香蕉',
    'FX26(奶蛋豆類)',
    'FX2(海鮮類)',
    'H2屋塵',
    'M81金黃色葡萄球菌B',
    'ANA titre',
    'ANA pattern',
    'ANA',
    'HX2-2(塵蹣類)',
    'Panel Reactive Ab class 1',
    'K75甲苯二異氰酸根',
    '職業病防治組合',
    'F210鳳梨',
    'Panel-R S-IgE(混合型',
    'Mitogen',
    'Mitogen-Nil',
    'TB Ag',
    'Nil',
    'TB Ag-Nil',
    'Panel-B S-IgE(吸入性',
    'F11蕎麥',
    'F7燕麥',
    'F8玉蜀黍',
    'Anti-ds DNA Antibody',
    'FX3(小麥澱粉類)',
    'F36椰子',
    'K79鄰苯二甲酸',
    'K78環氧乙烷',
    'Fax6職業性過敏原',
    '丙型干擾素釋放試驗IGRA',
    'FX21(水果類)',
    'Anti-ENA test(定性)',
    'ANCA',
    'Total IgE',
    '.',
    'f9米飯',
    'Anti-Ku Antibody',
    'Th/To',
    'Centromere A (CA)',
    'Fibrillarin (Fib)',
    'RNA Pol III 155 kDa',
    'PDGFR',
    'Systemic Sclerosis Ab',
    'RNA Pol III 11 kDa',
    'NOR90',
    'Centromere B (CB)',
    'Scl-70',
    'Anti-RF IgM',
    'Total Th Cell',
    'Th Cell',
    'T Cell',
    'Total Ts Cell',
    'Total T Cell',
    'NK Cell',
    'Total B Cell',
    'Ts Cell',
    'H/S',
    'Total NK Cell',
    'B Cell',
    'Total T & B Lymphocytes',
    'AT Cell',
    'Total AT Cell',
    'comment',
    'K85 氯胺T',
    'Anti-ScL 70 Antibody',
    'PR3-ANCA',
    'MPO-ANCA',
    'F48洋蔥',
    'F85芹菜',
    'K83棉花籽',
    'Anti-Cardiolipin IgG',
    'RF-IgM',
    'F33柳橙',
    'F44草莓',
    'F80龍蝦',
    'F338干貝',
    'F202腰果',
    'F49蘋果',
    'F207蛤',
    'F208檸檬',
    'F27牛肉',
    'E215鴿羽毛',
    'F290牡蠣',
    'F320淡水螯蝦',
    'FX13(五穀澱粉類)',
    'K74家蠶蠶絲',
    'T9油橄欖樹',
    'T16白皮松樹',
    'T21白千層',
    'F45酵母',
    'TX7(植物花粉類)',
    'F94梨',
    'FX20(蔬果類)',
    'F203開心果',
    'F256胡桃',
    'T12黃花柳',
    'F201山核桃',
    '自費過敏原100項',
    'F15白豆',
    'F5裸麥',
    'FX16(水果類)',
    'FX22(堅果類)',
    'F26豬肉',
    'F12碗豆',
    'F6大麥',
    'F83雞肉',
    'F58魷魚',
    'FX15(水果類)',
    'FX7(蔬果類)',
    'FX74(海鮮類)',
    'M80金黃色葡萄球菌A',
    'F254鰈魚',
    'F205鯡魚',
    'F258透抽',
    'F206鯖魚',
    'F59章魚',
    'F50鯖魚',
    'F259葡萄',
    'E1貓毛',
    'HLA-B1502',
    'Anti-Jo-1 Antibody',
    'FX9(水果類)',
    '調味食品組合',
    'RF317胡荽(香菜)',
    'I6德國蟑螂',
    'E5狗毛',
    'k82Latex乳膠',
    'H1家塵',
    'k80福馬林',
    'C2 Penicilloyl V',
    'ANA T4',
    'ANA T2',
    '自體免疫疾病篩檢',
    'EliA Mi-2',
    'TNF-α',
    'HLA-B5801 gene typing',
    'ANA T3',
    'ANA T1',
  ],
  外檢: [
    '黏多醣症第2型',
    'Platelet Antibody',
    'TB PCR(sputum)',
    '25-OH Vitamin D Total',
    'Flucytosine',
    'Mumps IgG',
    'HBV DNA',
    '結直腸mRNA訊息基因-NPM',
    'Urine free Cortisol',
    'M-peak',
    'Serum immuno EP',
    'IgG/A/M/Kappa/Lambda',
    'Ceruloplasmin',
    'COMMENT',
    'TB PCR(體液)-加驗',
    'HPV (High Risk) Screen',
    'inv(16)CBFB/MYH11',
    'Chromsome(血液)',
    't(4;11)AFF1/MLL(AML)',
    'Assure(癌症風險評估)',
    'APTT',
    'Thyroglobulin',
    'G6PD',
    'Catecholamine( plasma)',
    'JAK2V617F mutation',
    'EB VCA IgM',
    'Acetylcholine receptor Ab',
    'Amoeba Antibody',
    'TB PCR(體液)',
    'Free Light Chain',
    'Gamma Urine',
    'Albumin Urine',
    'Protein Urine',
    'A/G Ratio',
    'Alpha2 Urine',
    'Beta Urine',
    'ABN Peak',
    'Alpha1 Urine',
    'Urine immuno EP',
    'DHEA-S',
    'ACTMonitor(12 gene)',
    '判讀依WHO標準',
    'micro-del(1p32)SIL/TAL1',
    'Cholinesterase',
    'POL',
    'Phenobarbital',
    't(9;9)SET/CAN',
    'Benzodiazepines Screen',
    '檢驗結果',
    'ENV',
    'GAG',
    '半乳糖血症-GAL',
    '苯酮尿症PKU',
    'C6/C8/C10(MCAD)',
    '異戊酸血症IVA',
    '甲狀腺低能症-CHT',
    '戊二酸酸血症第1型GA1',
    '高胱胺酸尿症HCU',
    '先天腎上腺增生症CAH',
    '楓糖尿症MSUD',
    '甲基丙二酸血症MMA',
    '極長鏈脂肪酸代謝異常',
    '瓜胺酸血症第II型CIT2',
    '瓜胺酸血症第I型CIT1',
    '肉鹼結合酵素缺乏症第1型',
    '三羥基三甲基戊二酸尿症',
    '原發性肉鹼缺乏症PCD',
    '戊二酸血症第2型',
    '全羧化脢合成脢缺乏症HCSD',
    '丙酸血症PA',
    '肉鹼結合酵素缺乏症第2型',
    'Somatomedin-C(IGF-1)',
    'Lupus anticoagulant',
    'Mumps IgM',
    'Ciprofloxacin (CIP-2)',
    'HCV RNA',
    'Flucytosin',
    'Collection time',
    'inv(16)CBF/MYH11',
    'HIV-1 p34(pol)',
    'Western Blot HIV1',
    'HIV-1 p55(Gag)',
    'ACTMonitor +(50 gene)',
    'HIV-1 gp41(Env)',
    'HIV-1 p24/25(Gag)',
    'APTT (2hrs for mixing)',
    'vWF',
    'HIV-1 p52(pol)',
    'HIV-1 p40(Gag)',
    'HIV-1 gp160(Env)',
    'HIV-1 p68(pol)',
    'HIV-1 p18(Gag)',
    'SARS-CoV-2 RT-PCR',
    'Report time',
    'ACTBRCA-TM (screening)',
    'Examination time',
    'HIV-1 gp110/120(Env',
    'Mixing APTT (2h)',
    'Toluidine blue O stain',
    'Received time',
    'Mixing APTT (Delay 2h)',
    'SARS-CoV-2新型冠狀病毒',
    'HIV Western blot Pattern',
    '高雪氏症(Gaucher)',
    '法布瑞氏症(Fabry)',
    '龐貝式症(Pompe)',
    'Isoniazid 1.0',
    'Rifampin 1.0',
    'Isoniazid 0.2',
    'Streptomycin 2.0',
    'Ethambutol 5.0',
    'Ethambutol 10',
    'Streptomycin 10',
    'Copper 銅(Serum)',
    'Methotrexate(MTX)',
    'Clarithromycin (CLR-4)',
    'Amikacin (AK-32)',
    'Theophylline',
    '抑制素',
    'Epinephrine',
    'Norepinephrine',
    'Gastrin',
    'Progesterone',
    'LA1/LA2 Normalized ratio',
    'LA 1(Screening)',
    'LA 2(Confirmation)',
    'Chromogranin A',
    'PT',
    'IgD',
    'a1-Antitrypsin',
    'Chlamydia trachomatis IgM',
    'CA 72-4',
    'HSV (I,II) IgM',
    'HSV 1+2 IgM',
    'Growth Hormone',
    'Measles IgM',
    'Fabry(法布瑞氏症)-初篩',
    'CD19',
    'HSV 2 DNA Qualitative',
    'Dopamine',
    'Catecholamine(urine)',
    'Organism 1',
    'HSV 1 DNA Quantitative',
    '檢驗說明:',
    'CMV PCR(專案)',
    'Virus culture',
    'X染色體脆折症',
    'Clarithromycin (CLR-16)',
    'Haptoglobin',
    'Acute leukemia marker',
    '備註',
    'HSV PCR(CSF)',
    'Liver',
    'Bone',
    'LDH 1',
    'LDH 3',
    'LDH 5',
    '砷(血)',
    '汞(血)',
    'Urine 17-OHCS',
    '鉛(血)',
    'Fast liver',
    'JAK2 V617F',
    'ALKP',
    'LDH 2',
    'ALK-P electrophoresis',
    'Amoebic Antibody',
    'LDH 4',
    '淋巴球藥物活化試驗-10項藥',
    'Factor XII assay',
    'Intestine',
    'Sirolimus',
    'Urine Amount',
    '脊髓性肌肉萎縮症',
    'RQ-t(9;22)BCR/ABL1(P210)',
    '',
    'Sample type 1',
    'Anti-Mullerian Hormone',
    'Basement membrane zone Ab',
    'HSV 2 IgG',
    'Linezolid(LZD16)',
    'Ethambutol (EM-8)',
    'Urine Free Cortisol',
    'IGG4 level',
    'Helicobater pylori Ab',
    'Oligo band',
    'Immuno EP(CSF)',
    'B2-Microglobulin',
    'Chromosome(癌患者P.B.)',
    'HSV 1 IgG',
    'Lymphoproliferative disea',
    'HPV Genotyping',
    'HSV 1+2 DNA PCR',
    'HBV DNA (Abott)',
    'ACE Test',
    'HPV mRNA',
    'HPV DNA高危險群',
    'HPV DNA低危險群',
    'Methanol',
    'Copper(urine)',
    't(9;22)BCR/ABL1(P190)',
    'Urine 17-KS',
    't(9;22)BCR/ABL1(P210)',
    'HE-4 副睪蛋白質4',
    'Monitor(大腸癌追蹤)',
    'Organism 3',
    'Cannabinoid(大麻)',
    'Parvovirus B19 PCR(定性)',
    'Thrombin time',
    't(1;5)PDE4DIP/PDGFRB',
    'Amylase isoenzyme',
    'Total Amylase',
    'P/T ratio',
    'Certican',
    'Pancreatic ISO',
    'Salivary ISO',
    'Measles IgG',
    'V.Zoster IgG',
    'HCV RNA genotyping',
    'CMV PCR(定性)',
    'Protein S',
    'Protein C',
    'Hg',
    'Amikacin Through',
    'CD2',
    'Micafungin',
    'CD10',
    'CD20',
    'CD22',
    'CD5',
    'Antithrombin III',
    'CGG型態',
    '結論:',
    'EB VCA IgG',
    'VDRL (CSF)',
    'Chlamydia pneumoniae IgG',
    'Aldosterone',
    'TB PCR(Blood)',
    'EB-EA IgG',
    'MPL mutation analysis',
    '5-HIAA (24hr)',
    '5-HIAA (Urine)',
    '5-HIAA',
    'Inuslin Ab',
    'HCV Genotype',
    'kappa',
    'Free light chain',
    'lambda',
    'kappa/lambda',
    'Rubella IgM',
    'Chlamydia trachomatis IgA',
    'Coproporphyrin',
    'Alpha1 CSF',
    'Alpha2 CSF',
    'Albumin CSF',
    '鉛/汞/砷(血)3項',
    'LA Confirm (Mixing)',
    'CSF Protein EP',
    'Urine basic drug confirm',
    'Normalized Ratio (Mixing)',
    'Protein(CSF)',
    'Anti-GBM',
    'Gentamicin (Peak)',
    'Measles Titer IgG',
    'FIP1L1/PDGFRA',
    'Beta CSF',
    'RQ-t(9;22)BCR/ABL1(P190)',
    'Anti-HDV',
    '高危險型 HPV mRNA 結果',
    'Gamma CSF',
    'LA Screen (Mixing)',
    'Prealbumin(if present)',
    '新生兒篩檢(複檢)',
    'Rifampin (RIF-1.0)',
    'Isoniazid (IN-1.0)',
    'CALR mutation analysis',
    'Tissue Polypeptide Ag',
    'Renin Activity',
    'Homocysteine',
    'Drug ENB',
    'Nested-PCR',
    'AST Table 1',
    'Growth 1',
    'G-6-PD',
    '新生兒先天代謝疾病篩檢',
    't(9;22)BCR/ABL(P210)',
    'Plasma Renin Activity',
    'HTLV-1+2',
    '嚴重複合型免疫缺乏(SCID)',
    '黏多醣症第1型(MPSI)',
    'GAD Autoantibody',
    'Rotention ratio(15分)',
    'ICG (15分)',
    'ICG (5分)',
    'ICG (10分)',
    'ICG',
    'FDP (定量)',
    'PCP(肺囊蟲肺炎)',
    'Organism 2',
    'Streptomycin (SM-10)',
    'Ethambutol (EM-4)',
    '* Acid Fast Stain (台美)',
    'Result',
    'Caspofungin',
    'Chlamydia trachomatis Ag',
    'TB PCR(Urine)',
    '17-a-Hydroxy Progesterone',
    'Chlamydia trachomatis IgG',
    'CMV PCR(定量)',
    '* TB Culture',
    '* Fungus Culture',
    'Urine Amount(24HR)',
    'JAK2Exon12 mutation analy',
    'Tryptase Test',
    'Insulin',
    '脊髓型肌肉萎縮症',
    '生物素脢缺乏症',
    '腎上腺腦白質失養症',
    'Everolimus(Certican)',
    'Calcitonin',
    'Glycated Albumin',
    'TB Culture Result',
    't(9;11)MLLT3/MLL',
    'Gentamycin(給藥前)',
    'PE',
    'CD23',
    'Phencyclidine',
    'Lambda',
    'Amphetamines',
    'Kappa',
    't(3;21)AML1/MDS1/EVI1',
    'Opiates',
    'CD79b',
    'Urine 6 drugs  abuse scre',
    '鄰苯二甲酸單苯基酯',
    '磺胺劑',
    'Cocaine古柯鹼篩檢',
    '鄰苯二甲酸單甲基酯',
    '鋁(血)',
    'Amoebic Ab(E.histo IgG)',
    'Posaconazole',
    '克倫德羅',
    'CD19(A1+A2)',
    '三聚氰胺生態檢驗',
    'Paraquat',
    'SHBG',
    '全方位生態健檢專案',
    'AST Table 3',
    'Lipoprotein EP',
    '沙丁胺醇',
    '砷(尿)/肌酸酐',
    '黃麴毒素生態檢驗',
    '汞(尿)/肌酸酐',
    't(4;12)ETV6/PDGFRA',
    'Epialleles A50',
    'HCV (NS5A 抗藥性)',
    '銅(尿)',
    'Cotinine',
    '自由基生態檢驗',
    't(5;15)TP53BP1/PDGFRB',
    'IA2-Ab',
    'Urine Amphetamines confir',
    '每日代謝量',
    'Epialleles B321',
    '有機磷類',
    'Anidulafungin',
    '氯黴素',
    'Organism 4',
    'Epialleles B333',
    'Plasma hemoglobin',
    '萊克多巴胺',
    '鎳(尿)',
    '錳(尿)',
    'Alpha',
    'Mixed APTT',
    '四環黴素',
    'Mutate site',
    '鄰苯二甲酸單乙基己酯',
    'Testosterone',
    '鎘(尿)/肌酸酐',
    '肺mRNA訊息基因-NPM',
    'FACTOR II',
    'Lipo Pre-beta',
    'Lipo Beta',
    '鎘(尿)',
    '鎳(尿)/肌酸酐',
    'Lipo Chylomicron',
    'Bioavalible Testosterone',
    'Lymphocyte surface marker',
    'ACTAssociate-Breast/Ovari',
    'Total(N=)',
    '鄰苯二甲酸單乙基酯',
    '汞(尿)',
    '鋅(尿)',
    '砷(尿)',
    't(12;4)ETV6/PDGFRA',
    '青黴素',
    'GAA enzyme',
    '唐氏基測',
    'Lipoprotein (a)',
    'Factor VIII inhibitor',
    '氨基甲酸鹽類',
    '鄰苯二甲酸單丁基酯',
    'Growth 3',
    'Free Testosterone (Calc)',
    '鉛(尿)',
    'Epialleles AX1',
    'Free Testosterone',
    '多環芳香烴生態檢驗',
    '第二孕期四指標唐氏症篩檢',
    '甲型胎兒蛋白',
    'B-HCG',
    'uE3',
    '唐氏症',
    '本年齡風險值',
    '神經管缺陷',
    '愛德華氏症',
    't(9;22)BCR/ABL(P190)',
    'Benzoylecgonine',
    'Factor VII assay',
    'Myoglobin(Urine)',
    'Tetrahydrocannabinol',
    '基因檢驗結果',
    'Amikacin Peak',
    '建議 :',
    'Secobarbital',
    'TB PCR(sputum)-加驗',
    'HBV DNA (Abbott)',
    '篩檢標號',
    '檢驗別',
    '甲狀腺低能症-TSH',
    '異戊酸血症-C5',
    '苯酮尿症-Phe',
    '先天腎上腺增生症17-OHP',
    '半乳糖血症-Gal',
    'C6/C8/C10',
    '戊二酸酸血症第1型-C5DC',
    '高胱胺酸尿症-Met',
    '甲基丙二酸血症-C3',
    '楓糖尿症-Leu/Val',
    'Chromosome(癌患者B.M.)',
    '串聯質譜儀其他篩檢項目',
    'Factor X assay',
    'PFA-EPI血小板功能閉鎖時間',
    'Mixing APTT (0h)',
    'PFA-ADP血小板功能閉鎖時間',
    't(6;11)MLLT4/MLL',
    'HCV Genotyping',
    'Aspergillus GM Ag Test',
    '龐貝式症(GAA)',
    '法布瑞氏症(GLA)',
    '高雪氏症(ABG)',
    'Fungus Culture Result',
    'HCV Genotype (Abbott)',
    'LA Confirm',
    'Normalized dRVVT Ratio',
    'LA Screen',
    'Serum protein EP',
    'm Band',
    'HCV Drug resistance Exam.',
    '聽損基因',
    'HPV DNA(定量)',
    'Mutant site',
    'HCV RAS test(NS5A)',
    'HPV DNA(含陽性分型)',
    '檢驗結果說明',
    'Factor II assay',
    'FLT3D835 mutation analysi',
    'FLT3 ITD PCR analysis',
    't(8;21)RUNX1/RUNX1T1',
    'Weil-Felix OX-2',
    'Weil-Felix OX-19',
    'Widal Typhoid O',
    'Widal Typhoid H',
    'Weil-Felix OX-K',
    'Widal , Weil-Felix',
    'Fluconazole',
    'Voriconazole',
    'Reducing substances',
    'Amphotercin B',
    'Erythropoietin(EPO)',
    'CD33',
    'CD13',
    '淋巴球藥物活化試驗-5項藥',
    'Acetaminophen',
    'HLA-DR',
    'LDH1/2 Ratio',
    'CD34',
    'HIV-1Western blot(西方墨',
    'LDH isoenzyme',
    'CD7',
    'CD3',
    'NPM1 PCR',
    'DR-70',
    'Widal Paratyphi B',
    'Widal Paratyphi A',
    'V.Zoster IgM',
    'VMA',
    'TB PCR(CSF)',
    'HBsAg 定量',
    'Factor V assay',
    'HCV Genotype (Cobas)',
    'Bence Jones Protein',
    'Mycoplasma pneumonia IgG',
    'HPV DNA(定性)',
    'Itraconazole',
    'HSV Titer IgG',
    'Se',
    'HG',
    'CKMM',
    'CKBB',
    'Cr',
    'Cd',
    't(12;21)ETV6/RUNX1',
    'Ni',
    'Sb',
    'CKMB',
    'Sn',
    'CK-total',
    '身體重金屬檢測套組',
    'Serum viscosity',
    'CEBPA/bZIP(C) mutation',
    'CK electrophoresis',
    'AS',
    'CU',
    'Tl',
    'Co 鈷',
    'Mn',
    'Factor IX assay',
    '溶小體儲積症四合一',
    'Aluminum',
    'Inhibin A',
    'Paratyphi A',
    'Paratyphi B',
    'Mixing PT (0h)',
    '分型結果',
    '初期唐氏症',
    'Trisomy 21 Backgroud',
    'Trisomy 21 Adjusted',
    'Trisomy 18 Adjusted',
    't(16;21)TLS/ERG',
    'Trisomy 18 Backgroud',
    'PAPP-A',
    'Crown-rump length',
    'Free β-HCG',
    'Gestational age',
    'CEBPA/TAD(N) mutation',
    'Hb. H. Preparation',
    'PNH marker',
    'Trisomy 13 Adjusted',
    'Trisomy 13 Backgroud',
    'Nuchal translucency',
    '黏多醣症第2型(MPSII)',
    '溶小體儲積症五合一',
    'Growth 2',
    'AST Table 2',
    'Zinc 鋅',
    'Pb(Lead)',
    'Fructosamine',
    'Moxifloxacin (MXF-2)',
    'HCV 1a RAS',
    'HPV DNA',
    't(4;11)AFF1/MLL(ALL)',
    't(1;19)TCF3/PBX1',
    'Creatinine (Urine)',
    'Arsenic / Creatinine',
    'Arsenic(As)',
    't(15;17)PML/RARA(S form)',
    'Luquid Biopsy(癌症預測)',
    'Albumin',
    'Beta',
    'Gamma',
    'Alpha2',
    'Alpha1',
    'A/G',
    'CGG重複數',
    'Total protein',
    'HPV 型別',
    '高危險型 HPV mRNA',
    'HBV DNA genotyping',
    'Factor XI assay',
    'c-KIT Exon8 mutation',
    'HIV Viral load',
    'Factor VIII assay',
    'c-KIT Exon17 mutation',
    't(15;17)PML/RARA(L/V form',
    'Tau Protein',
    'CD22(A1+A2)',
    'GLA基因型',
    'Quantitation of bcr/abl g',
    'CD10(A2+A4)',
    'Lambda/CD19',
    'CD5(A2+A4)',
    'Mumps Titer IgG',
    'Amyloidβ1-42 xTauProtein',
    'VZV PCR',
    'HSV 1 DNA Qualitative',
    'Kappa(A2+A4)',
    'GLA activity',
    'CD23(A1+A2)',
    'Growth 4',
    'CD5/CD23',
    'IMR(阿茲海默症檢測)',
    'Foundation One Liquid',
    'Lyso-Gb3複檢',
    'CD10/CD22',
    'Lambda(A2+A4)',
    'Protect(大腸癌篩檢)',
    'Gentamicin(給藥前)',
    'LDH',
    'P TYPE',
    'CD2/PE',
    'Cocaine(Benzoylecgonine)',
    'LLBx(癌可明-肺癌)',
    'Vitamin A(Retinol)',
    'Cannabinoid',
    'Organic acids analysis',
    'Pompe(龐貝氏症)-初篩',
    'CD20(A2+A4)',
    't(2;4)STRN/PDGFRA',
    'Urine Morphine confirm',
    'AST Table 4',
    'PE(A1+A2)',
    'CD20/CD79b',
    'Kappa/CD19',
    'S TYPE',
    'Amyloid β1-42',
    'ACTBRCA (screening)',
    'CD2(A2+A4)',
    'CD79b(A1+A2)',
    'Fabry(法布瑞氏症)-複檢',
    'Barbiturates',
    'Teicoplanin(TEC30)',
    't(5;10)H4(D10S170)/PDGFRB',
  ],
  鏡檢: [
    'Occult Blood(免疫法)',
    'Pericardial fluid test',
    'Stool WBC',
    'Fatty acid stain',
    'Neutral fat stain',
    'Parasite Ova(direct smear',
    'Occult Blood(國健署)',
    'Morphology normal',
    'Amount',
    'WBC (10^6/mL)',
    'Liquefaction time',
    'Count (10^6/mL)',
    'RBC count',
    'Neutrophil',
    'Lymphocyte',
    'Eosinophil',
    'CSF Analysis',
    'Rivalta Test',
    'WBC count',
    'Morphology Normal',
    'Amout',
    'Liquefaction Time',
    'Count',
    'Apperance',
    'Occult Blood(免疫法)arc',
    'Reducing substances',
    'Occult blood(化學法)',
    'Color',
    'Appearance',
    "Pandy's Test",
    'Occult Blood(國健署)arc',
    'Occult blood(胃液化學法)',
    'Pleural fulid analysis',
    'Synovial Fluid Analysis',
    'Viscosity string test',
    'Mucin clot test',
    'Occult Blood(免疫法)ARK',
    'Stool Routine',
    'Mucus',
    'Perianal swab 蟯蟲膠片',
    'Amoeba',
    'Parasite Ova(concentrate)',
    'Pandy Test',
    'APT Test',
    'Histiocyte',
    'Bronchoalceolar lavage',
    'Apppearance',
    'Helicobacter pylori Ag',
    'Discharge microscopic',
    'RBC',
    'sample type',
    'other',
    'WBC',
    'COMMENT',
    'Ascitic Fluid Analysis',
    'PH',
    'Semen Analysis',
    'Morphology atypical',
    'Motility',
    'Pleural fluid analysis',
    '糞便 TF 快篩',
    '糞便 TRF 快篩',
  ],
  病毒: [
    'HBeAg',
    'HIV combo',
    'CMV IgG',
    'Anti-HAV  IgG',
    'Other HR HPV',
    'HPV Type 18',
    'HPV (High Risk) Screen',
    'HPV Type 16',
    'Anti-HCV',
    'CMV IgM (csf)',
    'HBsAg定性',
    'Anti-HBs',
    'HIV Ag/Ab',
    'ST2蛋白(心衰竭預後指標)',
    'EBV EA/NA IgA(鼻咽癌指標)',
    'Rubella IgG',
    'CMV IgG (csf)',
    'Anti-HAV(含 IgG)',
    'EBV Viral load',
    'Anti-HAV(Total )',
    'Anti-HBc IgM',
    'HBeAg(產檢專用)',
    'HBsAg(產檢專用)',
    'B肝產檢',
    'HBV DNA',
    'HCV RNA',
    'Toxoplasma IgM',
    'Toxoplasma IgG',
    'Anti-HBe',
    'HIV combo (csf)',
    'COMMENT',
    'CMV PCR(定量)',
    'Anti-HAV IgM',
    'CMV viral load',
    'Total Anti-HAV',
    'Total Anti-HBc',
    'Anti-HAV IgG',
    'CMV IgM',
    'HBsAg',
    'EB VCA IgA',
    'Anti-HBc',
    'EBV viral load',
    'HBeAg(新住民)',
    'HBsAg(新住民)',
    'Rubella IgG(新住民)',
    '新住民B肝產檢',
    'HPV DNA低危險群',
    'HPV DNA高危險群',
    'HPV Genotyping',
  ],
  風免科外檢: [
    'Anti-Smooth Muscle Ab',
    'Anti-Mitochondrial Ab',
    'Tuna',
    'Yeast,Brewer',
    'House dust',
    'Latex',
    'Chicken Feathcrs',
    'Shrimp',
    'Eucalyptus',
    'Egg White',
    'Mite Farinae',
    'Pigweed Mix',
    'Cockroach Mix',
    'Soybean',
    'Crab',
    'Timothy Grass',
    'Cat',
    'White Mulberry',
    'Beef',
    'Cheddar Cheese',
    'Cladosporium',
    'Milk',
    'Aspergillus',
    'Mast',
    'Mite Pteronyssin',
    'Wheat',
    'Pork',
    'Codfish',
    'Dog',
    'Ragweed Mix',
    'Alternaria',
    'Bermuda Grass',
    'Avocado',
    'Penicillium',
    'Japanese Cedar',
    'Peanut',
    'Willow Black',
    'Clam',
    'Egg Yolk',
    'Anti-Parietal Antibody',
    'COMMENT',
  ],
  藥物濃度: [
    'FK506',
    'Cyclosporin (trough)',
    'Lithium',
    'COMMENT',
    'Cyclosporin (Peak)',
    'Vancomycin ( trough )',
    'Phenytoin',
    'Digoxin',
    'Carbamazepine',
    'Valproic acid',
    'Vancomycin ( Peak )',
  ],
  血庫: [
    "Direct Commb's test",
    "Indirect Commb's test",
    'COMMENT',
    'Coomb’s C3d Mono-specifi',
    'Coomb’s IgG Mono-specifi',
  ],
};
