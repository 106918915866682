export default function getEventPatientListAggsQuery(
  conditionQuery,
  includes,
  { highlight, additionalAggs, result_size = 100, sortField = ['IN_DATE'] } = {}
) {
  const aggsQuery = {
    aggs: {
      patientNum: {
        terms: {
          field: 'CHART_NO',
          size: result_size,
        },
        aggs: {
          top: {
            top_hits: {
              sort: [...sortField.map((field) => ({ [field]: { order: 'asc' } }))],
              _source: {
                includes,
              },
              ...highlight,
              size: 1,
            },
          },
          ...additionalAggs,
        },
      },
    },
  };
  return {
    ...conditionQuery,
    ...aggsQuery,
    size: 0,
    track_total_hits: true,
  };
}
