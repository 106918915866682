<template>
  <div class="mt-10px">
    <div v-if="hintReadyDataset" class="hint-ready-dataset" @click.stop="">
      <div class="d-flex align-items-center">
        <b-img src="@/assets/images/icon_event_export_white.svg" />
        <div class="hint-title-text">Dataset Processing</div>
      </div>
      <div class="hint-content-text mt-10px">Your dataset is processing, view progress in your study.</div>
      <div class="d-flex mt-20px justify-content-end">
        <div class="cancel-button mr-20px" @click="clickHintCancel">Cancel</div>
        <div class="view-button" @click="clickViewCancel">View</div>
      </div>
    </div>
    <a-tabs v-model="tabName" hide-add type="editable-card" class="tabs-result" @change="switchResultTab">
      <a-tab-pane v-for="(tab, index) in tabs" :key="index" :closable="true">
        <template slot="tab">
          <div class="custom-tab d-flex align-items-center" :title="getAbbreviationName(tab.name)">
            <b-img src="@/assets/images/icon_analysis_tab_cohort_enable.svg" v-if="tabName === index" />
            <b-img src="@/assets/images/icon_analysis_tab.svg" v-else />
            <div class="border-fixer ml-5px">
              {{ getAbbreviationName(tab.name) }}
            </div>
            <b-img src="@/assets/images/icon_close.svg" class="icon-close ml-10px" @click.stop="closeTab(index)" />
          </div>
          <div
            :title="`Loading... ${getProgressPercent}%`"
            class="progress-bar-block"
            v-if="showProgressbar && activeTabIndex === index && !tabContent.resultDone"
          >
            <a-progress
              :percent="getProgressPercent"
              :show-info="false"
              :strokeWidth="4"
              strokeColor="#4D8EDC"
              trailColor="#E0E0E0"
            />
          </div>
        </template>
      </a-tab-pane>
    </a-tabs>
    <a-tabs id="search-portal-tab" type="card" @change="switchTab" :activeKey="tabName">
      <a-tab-pane :key="PAGES.COHORT_STUDY" :closable="false">
        <template slot="tab">
          <div class="custom-tab d-flex align-items-center">
            <b-img src="@/assets/images/icon_my_study_cohort_enable.svg" v-if="tabName === PAGES.COHORT_STUDY" />
            <b-img src="@/assets/images/icon_my_study.svg" v-else />
            <div class="border-fixer ml-5px" style="width: 80px">Saved List</div>
          </div>
        </template>
        <a-row>
          <a-col>
            <MyStudy />
          </a-col>
        </a-row>
      </a-tab-pane>
      <a-tab-pane :key="PAGES.SEARCH" :closable="false">
        <template slot="tab">
          <div class="custom-tab d-flex align-items-center">
            <b-img src="@/assets/images/icon_search_portal_cohort_enable.svg" v-if="tabName === PAGES.SEARCH" />
            <b-img src="@/assets/images/icon_search_portal.svg" v-else />
            <div class="border-fixer ml-5px" style="width: 55px">
              {{ PAGES.SEARCH }}
            </div>
          </div>
        </template>
        <a-row>
          <a-col>
            <EventSearch />
          </a-col>
        </a-row>
      </a-tab-pane>
    </a-tabs>
    <EventResult v-if="activeTabIndex >= 0" :uniqueIndex="activeTabIndex" />
  </div>
</template>
<script>
import { PAGES, FEATURES, DEMO_VIDEO_LINK, VUE_EVENT_NAME } from '@/utils/constants';
import EventSearch from '@/views/EventSearch.vue';
import { ClickMixin } from '@/mixins';
import { mapState, mapMutations } from '@/store';
import MyStudy from '@/components/byEvent/myStudy/MyStudy.vue';
import EventResult from '@/components/byEvent/result/EventResult.vue';
import { SLA_DEFINITION, PROGRESS_CONFIG } from '@/utils/cohortProgressConfig';

export default {
  name: 'SearchPortal',
  mixins: [ClickMixin],
  components: {
    EventSearch,
    MyStudy,
    EventResult,
  },
  async mounted() {
    const demoVideoIsfirstTime = await this.$api.getIsFirstTime(this.userId, FEATURES.DEMO_VIDEO);
    if (demoVideoIsfirstTime.first_use) {
      this.setDemoVideoIndex(1);
      this.setDemoVideoVisible(true);
      this.trackClick('openDemoVideo', { index: 0, video: DEMO_VIDEO_LINK, isAuto: true });
      this.setUsedFunction(FEATURES.DEMO_VIDEO);
    }

    this.$root.$on(VUE_EVENT_NAME.SET_VIEW_DATASET, () => {
      this.switchTab(PAGES.COHORT_STUDY);
    });

    this.$root.$on(VUE_EVENT_NAME.SET_VIEW_SAVED_ANALYSIS, () => {
      this.switchTab(PAGES.COHORT_STUDY);
    });

    this.checkTabName();
  },
  destroyed() {
    this.$root.$off(VUE_EVENT_NAME.SET_VIEW_DATASET);
    this.$root.$off(VUE_EVENT_NAME.SET_VIEW_SAVED_ANALYSIS);
  },
  watch: {
    activeTabIndex() {
      this.checkTabName();
    },
    tabContent: {
      handler() {
        this.setProgress();
      },
      deep: true,
    },
  },
  data() {
    return {
      PAGES,
      tabName: PAGES.SEARCH,
      showProgressbar: false,
      getProgressPercent: 0,
    };
  },
  computed: {
    ...mapState('cohortSession', ['tabs', 'activeTabIndex']),
    ...mapState(['hintReadyDataset']),
    ...mapState('user', ['userId']),
    tabContent() {
      return this.tabs[this.activeTabIndex];
    },
  },
  methods: {
    ...mapMutations('cohortSession', ['setActiveTabIndex', 'removeTab', 'updateTab']),
    ...mapMutations(['setHintReadyDataset', 'setDemoVideoIndex', 'setDemoVideoVisible']),
    switchTab(tab) {
      this.tabName = tab;
      this.setActiveTabIndex(-1);
      this.cohortStudyTrackClick('switchTab', tab);
    },
    switchResultTab(tab) {
      this.tabName = tab;
      this.setActiveTabIndex(tab);
      this.cohortStudyTrackClick('switchResultTab', tab);
    },
    closeTab(index) {
      this.removeTab(index);
      this.cohortStudyTrackClick('closeTab', index);
    },
    checkTabName() {
      if (this.activeTabIndex >= 0) {
        this.tabName = this.activeTabIndex;
      } else if (this.tabName !== PAGES.SEARCH && this.tabName !== PAGES.COHORT_STUDY) {
        this.tabName = PAGES.SEARCH;
      }
    },
    clickHintCancel() {
      this.setHintReadyDataset(false);
      this.cohortStudyTrackClick('clickHintCancel');
    },
    clickViewCancel() {
      this.setHintReadyDataset(false);
      this.$root.$emit(VUE_EVENT_NAME.SET_VIEW_DATASET);
      this.cohortStudyTrackClick('clickViewCancel');
    },
    getAbbreviationName(name) {
      if (name.length > 20) {
        return `${name.slice(0, 20)} ...`;
      }
      return name;
    },
    setProgress() {
      if (this.tabContent) {
        this.showProgressbar =
          this.tabContent.characteristicsTableRequestInProgress ||
          this.tabContent.incidenceRateTableRequestInProgress ||
          this.tabContent.survivalRateChartRequestInProgress ||
          this.tabContent.coxRegressionRequestInProgress ||
          this.tabContent.patientListRequestInProgress;
        const totalPeriod =
          SLA_DEFINITION.PATIENT_PROFILE_FORM +
          SLA_DEFINITION.PATIENT_CHARACTERISTICS +
          SLA_DEFINITION.PATIENT_INCIDENCE_RATE +
          SLA_DEFINITION.PATIENT_KM_CURVE +
          SLA_DEFINITION.PATIENT_HAZARD_RATIO;
        this.getProgressPercent = parseInt(
          Math.min(
            (this.tabContent.characteristicsTableProgressPercent * SLA_DEFINITION.PATIENT_CHARACTERISTICS) /
              totalPeriod +
              (this.tabContent.incidenceRateTableProgressPercent * SLA_DEFINITION.PATIENT_INCIDENCE_RATE) /
                totalPeriod +
              (this.tabContent.survivalRateChartProgressPercent * SLA_DEFINITION.PATIENT_KM_CURVE) / totalPeriod +
              (this.tabContent.coxRegressionProgressPercent * SLA_DEFINITION.PATIENT_HAZARD_RATIO) / totalPeriod +
              (this.tabContent.patientListProgressPercent * SLA_DEFINITION.PATIENT_PROFILE_FORM) / totalPeriod,
            PROGRESS_CONFIG.MAX_BOUNDARY
          ),
          10
        );

        if (
          this.tabContent.characteristicsTableProgressPercent === 100 &&
          this.tabContent.incidenceRateTableProgressPercent === 100 &&
          this.tabContent.survivalRateChartProgressPercent === 100 &&
          this.tabContent.coxRegressionProgressPercent === 100 &&
          this.tabContent.patientListProgressPercent === 100
        ) {
          this.updateTab({
            index: this.activeTabIndex,
            resultDone: true,
          });
        }
      }
    },
  },
};
</script>
<style scoped>
#search-portal-tab >>> .ant-tabs-tab > .custom-tab {
  position: relative;
  border-bottom: 5px solid #ffffff;
  top: -2px;
}
#search-portal-tab >>> .ant-tabs-tab-active > .custom-tab {
  font-weight: bold;
  position: relative;
  border-bottom: 5px solid #4d8edc;
  top: -2px;
}
#search-portal-tab >>> .ant-tabs-tab-active > .custom-tab > .border-fixer {
  position: relative;
  color: #4d8edc;
}
</style>
<style lang="scss" scoped>
#search-portal-tab {
  /deep/ .ant-tabs-nav {
    margin-left: 20px !important;
  }
}

.tutorial-menu {
  position: absolute;
  width: 330px;
  padding: 20px 20px;
  background: #4a94d2;
  border-radius: 5px;
  z-index: 1;
  top: 9px;
  left: 55px;
}

.tutorial-menu:after {
  content: '';
  position: absolute;
  top: -7px;
  left: 50%;
  margin-left: -7px;
  width: 0;
  height: 0;
  border-bottom: solid 7px #4a94d2;
  border-left: solid 7px transparent;
  border-right: solid 7px transparent;
}

.tutorial-menu-title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 165%;
  height: 26px;
  color: #ffffff;
}

.icon-close-white {
  cursor: pointer;
}

.tutorial-menu-content {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 165%;
  color: #ffffff;
  margin: 7px 0px 20px 0px;
}

.button-dismiss,
.button-dismiss:hover,
.button-dismiss:focus,
.button-dismiss:active,
.button-dismiss:active:visited {
  width: 140px;
  height: 32px;
  background: #4a94d2 !important;
  border-radius: 6px;
  border: 1px solid #ffffff !important;
  color: #ffffff !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}

.button-try,
.button-try:hover,
.button-try:focus,
.button-try:active,
.button-try:active:visited {
  width: 140px;
  height: 32px;
  background: #ffffff !important;
  border-radius: 6px;
  border: 1px solid #110606 !important;
  color: #333f6b !important;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
}

/deep/ .ant-tabs-bar {
  margin: 0px;
}

.tabs-result {
  position: absolute;
  margin-left: 280px;
  max-width: calc(100% - 422px);
  min-width: calc(100% - 422px);
  width: calc(100% - 422px);
  z-index: 1;

  /deep/ .ant-tabs-nav {
    margin-left: 0px !important;
  }

  /deep/ .ant-tabs-tab {
    .custom-tab {
      border-bottom: 5px solid #ffffff;
      margin-top: -2px;
    }
  }

  /deep/ .ant-tabs-tab-active {
    .custom-tab {
      font-weight: bold;
      border-bottom: 5px solid #4d8edc;
      margin-top: -2px;
      color: #4d8edc;
    }
  }

  /deep/ .ant-tabs-nav-container-scrolling {
    span {
      background: #ffffff;
      border: 1px solid #e8e8e8;
      border-bottom: none;

      i {
        display: none;
      }

      .ant-tabs-tab-next-icon {
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 6px solid #334e97;
      }

      .ant-tabs-tab-prev-icon {
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-right: 6px solid #334e97;
      }
    }
  }
}

.icon-close {
  width: 20px;
  height: 20px;
}

.hint-ready-dataset {
  position: absolute;
  padding: 20px;
  background: #4d8edc;
  border-radius: 8px;
  width: 406px;
  top: 115px;
  left: 30px;
  z-index: 9999;
}

.hint-ready-dataset:after {
  content: '';
  position: absolute;
  top: -7px;
  left: 10%;
  margin-left: -7px;
  width: 0;
  height: 0;
  border-bottom: solid 7px #4a94d2;
  border-left: solid 7px transparent;
  border-right: solid 7px transparent;
}

.hint-title-text {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}

.hint-content-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 165%;
  color: #ffffff;
}

.cancel-button {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  padding: 8px 24px;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  background: #4d8edc;
  cursor: pointer;
}

.view-button {
  padding: 8px 24px;
  background: #ffffff;
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #4d8edc;
  cursor: pointer;
}

.progress-bar-block {
  position: absolute;
  top: 16px;
  margin-left: -16px;
  width: 100%;
}
</style>
