import { defaultTimeSelectObject } from '@/utils/advance/initState';
import { CLASS, PAGES, SPECIAL_CHARS, MAX_NAME_LENGTH } from '@/utils/constants';
import ConstraintName from '@/utils/conditions/core/advance/constraint/Interface';
import { DateUnit } from '@/utils/conditions/core/Interface';
import { AxisFormatter, removePoint, viewAllAxisFormatter } from '@/components/charts/utils/utils';
import { cloneDeep } from 'lodash';
import { ChartKey } from '@/components/charts/core/Interface';
import { v4 as uuid } from 'uuid';
import { getUiConfig } from '@/utils/uiConfig';
import dayjs from 'dayjs';
import urljoin from 'url-join';
import { PROGRESS_CONFIG } from '@/utils/cohortProgressConfig';

const CAPSULE_ABBR = 'capsule-abbr';

export function formatText(text) {
  return text.trim().replace('\r\n\r\n', '\r\n');
}

export function medFormatText(text) {
  return text.trim().replace(', ', ',').replace(',', ', ');
}

export const isEllipsisActive = (e) => e.offsetHeight < e.scrollHeight;

export const checkStartWith = (matchExpectArray, matchTarget) => {
  let matchState = false;
  matchExpectArray
    .filter((match) => match.length > 0)
    .forEach((match) => {
      if (matchTarget.toLowerCase().trim().includes(match.toLowerCase().trim())) {
        matchState = true;
      }
    });
  return matchState;
};

export const disableColumnClass = (item, disableClass, labColumnFilter) => {
  const newItem = item;
  // non-char is split
  const matchExpectArray = labColumnFilter.split(';');
  if (!checkStartWith(matchExpectArray, newItem.chHead)) {
    newItem.className = disableClass;
  } else {
    newItem.className = CLASS.SECOND_LAYER_ROW;
  }
  return newItem;
};

export const setColumnClass = (item) => {
  const newItem = item;
  newItem.className = CLASS.SECOND_LAYER_ROW;
  return newItem;
};

export const filterColumn = (type, result, disableClass, labColumnFilter) => {
  if (type === 'disable') {
    return result[1].children.map((item) => disableColumnClass(item, disableClass, labColumnFilter));
  }
  return result[1].children.map((item) => setColumnClass(item));
};

// let one portal handle the shared link
// so the user will be redirected to shared link after login
export const getRedirectLink = (baseUrl) => baseUrl;

// under portal & gateway environment, url is suffixed with `/emr` or `/emr-stage`
export const getSharedUrl = (itemId = '') => {
  const { origin: baseUrl } = window.location;
  return urljoin(baseUrl, 'url', itemId);
};

export const getAbbreviation = (data) => {
  switch (data) {
    case 'Diagnosis':
      return 'DX';
    case 'Medication':
      return 'MD';
    case 'Medical Order':
      return 'O';
    default:
      return 'DX';
  }
};

export const getAbbreviationClass = (data) => {
  switch (data) {
    case 'Diagnosis':
      return CAPSULE_ABBR;
    case 'Medication':
      return 'abbr-md';
    case 'Medical Order':
      return 'abbr-o';
    default:
      return CAPSULE_ABBR;
  }
};

export const getCapsuleAbbreviation = (data) => {
  switch (data) {
    case 'icd10':
      return 'DX';
    case 'icd9':
      return 'DX';
    case 'med':
      return 'MD';
    case 'medicalOrder':
      return 'O';
    default:
      return 'DX';
  }
};

export const getCapsuleAbbreviationClass = (data) => {
  switch (data) {
    case 'icd10':
      return CAPSULE_ABBR;
    case 'icd9':
      return CAPSULE_ABBR;
    case 'med':
      return 'abbr-md';
    case 'medicalOrder':
      return 'abbr-o';
    default:
      return CAPSULE_ABBR;
  }
};

// eslint-disable-next-line max-len
export const numberRange = (start, end) => Array.from(Array(end + 1 - start).keys(), (i) => i + start);

export const formatAge = (age) => (age === 200 ? '100+' : age.toString());

export const getConstraintName = (type) => {
  if (type === ConstraintName.AgeConstraint) {
    return 'Age';
  }
  return '';
};

export const getEventDesc = (event) => {
  const codeList = [];
  event.condition.forEach((conditionElement) => {
    conditionElement.list.forEach((listElement) => {
      codeList.push(listElement.code);
    });
  });

  const constraintList = [];
  event.constraintList.forEach((constraint) => {
    if (constraint.constraintType === ConstraintName.AgeConstraint) {
      constraintList.push(
        `${getConstraintName(constraint.constraintType)} ${constraint.start}~${formatAge(constraint.end)}`
      );
    }
  });

  if (event.constraintList.length !== 0) {
    return `${codeList.join(', ')}\n${constraintList.join(', ')}`;
  }
  return codeList.join(', ');
};

export const transformTimeUnit = (data) => {
  if (data === `12 ${DateUnit.MONTHS}`) {
    return `1 ${DateUnit.YEARS}`;
  }
  return data;
};

export const formatIcdGroupMapItem = (item) => ({
  description: item.english_name,
  chineseDescription: item.chinese_name,
  highlightDescription: item.highlighted_chinese_name,
  highlightChineseDescription: item.highlighted_english_name,
  type: 'GroupAndMap',
  patientNum: item.patient_num,
  recordNum: item.record_num,
  icd10List: item.icd10_list,
  icd9List: item.icd9_list,
  candidateIcd10List: item.icd10_list,
  candidateIcd9List: item.icd9_list,
  code: item.code,
  billable: item.billable,
  expand: false,
  check: false,
  child: [],
  level: item.level,
  parent: item.parents,
  partial: false,
  descendants: item.descendants,
  siblings: item.siblings,
  selectDescendants: item.descendants,
  ancestors: item.ancestors,
});

export const getIcdFormatList = (data, formatItem) => {
  const ICDCodes = [];

  data.forEach((item) => {
    ICDCodes.push(formatItem(item));
  });

  return ICDCodes;
};

export const isValidSome = (data, value) => data.some((item) => item.value > value);

export const sourceOption = {
  xAxis: {
    type: 'value',
    axisLabel: {
      formatter: removePoint,
    },
    name: 'Record',
    nameLocation: 'end',
    nameGap: -30,
    nameTextStyle: {
      padding: [60, 0, 0, 0],
      color: '#757575',
    },
  },
  yAxis: {
    type: 'category',
    inverse: true,
    triggerEvent: true,
    axisLabel: {
      formatter: AxisFormatter,
    },
  },
};

export const CodeOption = {
  xAxis: {
    type: 'value',
    axisLabel: {
      formatter: removePoint,
    },
    name: 'Patient',
    nameLocation: 'end',
    nameGap: -30,
    nameTextStyle: {
      padding: [60, 0, 0, 0],
      color: '#757575',
    },
  },
  yAxis: {
    type: 'category',
    triggerEvent: true,
    inverse: true,
    z: 10,
  },
};

export const CodeViewAllOption = {
  title: {
    show: false,
  },
  grid: {
    top: 30,
  },
  xAxis: {
    nameTextStyle: {
      padding: [0, 0, 60, 0],
    },
    position: 'top',
  },
  yAxis: {
    axisLabel: {
      formatter: viewAllAxisFormatter,
    },
  },
};

export const getDefaultTabContent = (medicalOrderCategory, advSearch) => {
  const tab = {
    id: '',
    name: 'Result',
    updateTime: dayjs().format('YYYY/MM/DD HH:mm:ss'),

    includes: [],
    excludes: [],

    counters: {
      OPD: { Patient: 0, Record: 0 },
      IPD: { Patient: 0, Record: 0 },
      ALL: { Patient: 0, Record: 0 },
    },

    chartParams: {
      [ChartKey.ICD10]: {
        diagnosisRange: 'Any',
      },
      [ChartKey.ICD9]: {
        diagnosisRange: 'Any',
      },
      [ChartKey.MedicalOrder]: {
        category: medicalOrderCategory[0].code,
      },
      [ChartKey.Readmission]: {
        standard: getUiConfig().SearchForm.standard,
      },
    },

    data: {
      sortChartByAI: false,
      bucketByPatient: true,
      riskLevel: '',
      root: { trackId: '' },
      trackId: uuid(),
      analyzeTab: PAGES.STATISTIC_SUMMARY,
      onlyMyPatient: false,
    },
  };

  if (advSearch) {
    tab.state = 'summary';
    tab.TimeSelectObject = cloneDeep(defaultTimeSelectObject);
  }

  return cloneDeep(tab);
};

export const delay = (interval) => {
  return new Promise((resolve) => {
    setTimeout(resolve, interval);
  });
};

export const hasSpecialChar = (specialChars, text) => {
  for (let index = 0; index < specialChars.length; index++) {
    if (text.indexOf(specialChars.substring(index, index + 1)) !== -1) {
      return true;
    }
  }
  return false;
};

export const getPopupContainer = (trigger) => {
  return trigger.parentElement;
};

export const inclusionListNameError = (inclusionList) => {
  return inclusionList.some((item) => hasSpecialChar(SPECIAL_CHARS.COHORT_NAME, item.name));
};

export const stringLessLength = (string, length) => {
  return string.length < length;
};

export const checkCohortName = (eventSearch) => {
  return (
    !stringLessLength(eventSearch.experimental.name, MAX_NAME_LENGTH) ||
    !stringLessLength(eventSearch.control.name, MAX_NAME_LENGTH)
  );
};

export const checkEventSearchNameError = (eventSearch) => {
  return (
    hasSpecialChar(SPECIAL_CHARS.COHORT_NAME, eventSearch.experimental.name) ||
    hasSpecialChar(SPECIAL_CHARS.COHORT_NAME, eventSearch.experimental.eventList[0].name) ||
    hasSpecialChar(SPECIAL_CHARS.COHORT_NAME, eventSearch.experimental.indexEvent.name) ||
    hasSpecialChar(SPECIAL_CHARS.COHORT_NAME, eventSearch.control.name) ||
    hasSpecialChar(SPECIAL_CHARS.COHORT_NAME, eventSearch.control.eventList[0].name) ||
    hasSpecialChar(SPECIAL_CHARS.COHORT_NAME, eventSearch.control.indexEvent.name) ||
    inclusionListNameError(eventSearch.covariate.inclusionList) ||
    inclusionListNameError(eventSearch.outcome.inclusionList) ||
    checkCohortName(eventSearch)
  );
};

export const calcProgress = (startTime, SLATime) => {
  return parseInt(Math.min((Date.now() - startTime) / SLATime / Math.pow(10, 1), PROGRESS_CONFIG.MAX_BOUNDARY), 10);
};

export const transformNumber = (value, fixed = 2) => {
  if (typeof value === 'string') {
    return value;
  }
  return value.toFixed(fixed);
};

export default {
  formatText,
  medFormatText,
  isEllipsisActive,
  checkStartWith,
  disableColumnClass,
  setColumnClass,
  filterColumn,
  getAbbreviation,
  getAbbreviationClass,
  numberRange,
  formatAge,
  getEventDesc,
  transformTimeUnit,
  formatIcdGroupMapItem,
  getIcdFormatList,
  isValidSome,
  sourceOption,
  CodeOption,
  CodeViewAllOption,
  getDefaultTabContent,
  getCapsuleAbbreviation,
  getCapsuleAbbreviationClass,
  delay,
  hasSpecialChar,
  getPopupContainer,
  checkEventSearchNameError,
  calcProgress,
  stringLessLength,
  transformNumber,
};
