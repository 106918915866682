import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import { isEqual } from 'lodash';
import objectPath from 'object-path';
import { SearchQueryGenerator } from '@/utils/lumos/SearchQueryGenerator';

function assign(content: any, event: any) {
  Object.keys(content).forEach((key) => {
    const value = content[key];
    if (value !== undefined && !isEqual(event[key], value)) {
      // eslint-disable-next-line no-param-reassign
      event[key] = value;
    }
  });
}

@Module({ namespaced: true })
export default class EventResult extends VuexModule {
  experimentalGroupPatient = 0;
  controlGroupPatient = 0;

  eventSearch: any = {};

  experimentalLoadingCount = 0;
  controlLoadingCount = 0;

  currentMenu = '';

  nameError = '';

  @Mutation
  // eslint-disable-next-line no-unused-vars
  updateWithPathMutation({ api, path, ...content }: any) {
    const event = objectPath.get(this.eventSearch, path.slice(2, path.length));
    assign(content, event);
  }

  @Mutation
  updatePatientCountStatus({ groupType, patientCount }: any) {
    if (groupType === 'experimental') {
      this.experimentalGroupPatient = patientCount;
    } else {
      this.controlGroupPatient = patientCount;
    }
  }

  @Mutation
  setMenuResultPath({ path, menuName }: any) {
    this.currentMenu = `${path}/${menuName}`;
  }

  @Mutation
  restoreData({ experimentalGroupPatient, controlGroupPatient, eventSearch }: any) {
    this.experimentalGroupPatient = experimentalGroupPatient;
    this.controlGroupPatient = controlGroupPatient;
    this.eventSearch = eventSearch;
  }

  @Mutation
  updateExperimentalLoadingCount(experimentalLoadingCount: number) {
    this.experimentalLoadingCount = experimentalLoadingCount;
  }

  @Mutation
  updateControlLoadingCount(controlLoadingCount: number) {
    this.controlLoadingCount = controlLoadingCount;
  }

  @Mutation
  updateTotalPatientCount(patientCount: number) {
    this.experimentalGroupPatient = patientCount;
    this.controlGroupPatient = patientCount;
  }

  @Mutation
  checkNameErrorResult({ name, tag }: any) {
    const nameList: any[] = [];
    this.eventSearch.covariate.inclusionList.forEach((element: { name: any }, index: any) => {
      nameList.push({
        name: element.name,
        tag: `covariate-${index}`,
      });
    });
    this.eventSearch.outcome.inclusionList.forEach((element: { name: any }, index: any) => {
      nameList.push({
        name: element.name,
        tag: `outcome-${index}`,
      });
    });

    this.nameError = '';
    if (nameList.filter((item) => item.name === name).length > 1) {
      this.nameError = tag;
    } else {
      nameList.forEach((item) => {
        if (nameList.filter((kidItem) => item.name === kidItem.name).length > 1) {
          this.nameError = item.tag;
        }
      });
    }
  }

  @Mutation
  updateNameError(nameError: string) {
    this.nameError = nameError;
  }

  @Action
  async updateTotalPatient({ api }: any) {
    const queries = [
      {
        aggs: {
          patientNum: {
            cardinality: {
              field: 'CHART_NO',
            },
          },
        },
        size: 0,
      },
    ];
    const patientCountResponse = await api.getTotalPatientCount(queries);
    this.context.commit(
      'updateTotalPatientCount',
      patientCountResponse.patient_count
        ? patientCountResponse.patient_count
        : patientCountResponse.aggregations.patientNum.value
    );
  }

  @Action
  async updateWithResultPath({ path, ...content }: any) {
    this.context.commit('updateWithPathMutation', { path, ...content });
  }

  @Action
  async updatepatientResultCount({ api, path }: any) {
    let searchObject = undefined;
    if (path === 'experimental') {
      searchObject = this.eventSearch.experimental;
      this.context.commit('updateExperimentalLoadingCount', this.experimentalLoadingCount + 1);
    } else {
      searchObject = this.eventSearch.control;
      this.context.commit('updateControlLoadingCount', this.controlLoadingCount + 1);
    }

    const queries = SearchQueryGenerator.generateCohortQuery(searchObject);
    const patientCountResponse = await api.getPatientCount(queries, searchObject);
    const patientCount = patientCountResponse.patient_count;

    this.context.commit('updatePatientCountStatus', { groupType: path, patientCount: patientCount });

    if (path === 'experimental') {
      this.context.commit('updateExperimentalLoadingCount', this.experimentalLoadingCount - 1);
    } else {
      this.context.commit('updateControlLoadingCount', this.controlLoadingCount - 1);
    }
  }
}
