import { CHART } from '../constants';
import { ConditionFactory, ConditionName, LogicOp, TermCondition } from './core';

export default class MedicalOrderDef extends TermCondition {
  static get NAME(): string {
    return CHART.TITLE.MEDICAL_ORDER;
  }

  get medicalOrderQuery(): Record<string, unknown>[] {
    return this.list.map((item) => ({ regexp: { ORDER_CODE: `${item.code}` } }));
  }

  public getFilter(): Record<string, unknown> {
    return {
      bool: {
        [this.andOr]: this.medicalOrderQuery,
      },
    };
  }

  public get isHidden(): boolean {
    return this.global.searchByPatient;
  }

  public get andOr(): LogicOp {
    if (this.global.searchByPatient) {
      return LogicOp.Or;
    }
    return super.andOr;
  }
}

ConditionFactory.register(MedicalOrderDef, ConditionName.MedicalOrder);
