export default {
  Blood: [
    'Hb',
    'WBC',
    'K',
    'Ht',
    'Na',
    'RBC',
    'MCV',
    'CRE',
    'MCHC',
    'MCH',
    'RDW-CV',
    'BvUN',
    'MPV',
    'Lym.',
    'Mono.',
    'Eosin.',
    'Baso.',
    'PL',
    'N.seg.',
    'PDW',
    'P-LCR',
    'eGFR',
    'Control',
    'CRP',
    'INR',
    'Ca',
    'APTT',
    'PT',
    'TBI',
    'PLT',
    'pH',
    'HCO3',
    'BEecf',
    'TCO2',
    'ABE',
    'SBC',
    'N.band',
    'O2 sat.',
    'ALB',
    'ALT (GPT)',
    'Irr. Ab',
    'Mg',
    'GLU-AC',
    'pO2',
    'pCO2',
    'AST (GOT)',
    'DBI',
    'GOT/AST',
    'ALT',
    'GPT/ALT',
    'AST',
    'Aty.Lym.',
    'Cl',
    'N.seg',
    'CCr(體重)',
    'I P',
    'Meta.',
    'Myelo.',
    'Rh D',
    'MNAPTT',
    'T P',
    'MNPT',
    'PCO2',
    'PO2',
    'ABO type',
    'ALT(GPT)',
    'N.RBC',
    'ALP',
    'GLO',
    'AST(GOT)',
    'C K',
    'TG',
    'ALB-BCG',
    'TCH',
    'CK-MB',
    'NH3',
    'value',
    'LDL-C',
    'TSH',
    'APTT(sec)',
    'Lactate',
    'Blood Type',
    'Value',
    'PT(sec)',
    'ESR',
    'GGT',
    'U A',
    'HbA1c',
    'Rh TypingD',
    'Anti HCV',
    'HDL-C',
    'PCT',
    'BIL(Micro)',
    'LDH',
    'O2sat.',
    'Troponin I',
    'HBs Ag',
    'Lipase',
    'GLU',
    'AMY',
    'Ca ionized',
    's/c value',
    'Ca i-pH7.4',
    'CEA',
    'FT4',
    'UA',
    'Promyelo.',
    'A∕G ratio',
    'HbA1C',
    'Cortisol',
    'eA GLU',
    'GLU-random',
    'Fibrinogen',
    'Depakine',
    'BT(IVY)',
    'STS-RPR',
    'Anti HBs',
    'AFP',
    'T3',
    'NT-proBNP',
    'ACTH',
    'Prealbumin',
    'Osmo.',
    'FreeT4',
    'S/C value',
    'CA 19-9',
    'TIBC',
    'Fe',
    'D dimer',
    'FDP-Ddimer',
    'Vancomycin',
    'M.Pneu.IgM',
    'GLU-Random',
    'HBsAg',
    'Blast',
    's∕c value',
    'Ferritin',
    'Free T4',
    'Vanco(前)',
    'TnI',
    'RPR',
    'Vanco(後)',
    'GOT∕AST',
    'GLU 2hr',
    'Lithium',
    'hsTnI',
    'Anti HBc',
    'S∕Cvalue',
    'GPT∕ALT',
    'hs Tn.I',
    'PSA',
    'LDL/HDL',
    'TP',
    'ANA',
    'Cortisol 8',
    'Folate',
    'ABO Type',
    'HIV Ab-EIA',
    'Crypto. Ag',
    'Ret.',
    'hs Troponin I',
    'M.Pneu. Ab',
    'Nucleolar',
    'M.Pn. IgM',
    'CA199',
    'G6PD',
    'SCID',
    's∕co valu',
    'index',
    'HIV Ag∕Ab',
    'RBC Morph.',
    'C 3',
    'C 4',
    'R F',
    'HIV screen',
    'CAH',
    'HCU',
    'MSUD',
    'IVA',
    'PKU',
    'MCAD',
    'MMA',
    'CHT',
    'GAL',
    'MPS1',
    'FABRY',
    'MPS6',
    'MPS2',
    'SCC',
    'Irr. Ab ID',
    'POMPE',
    'GAUCHER',
    'Tacrolimus',
    'Monocytoid',
    'CA 125',
    'CMV IgM',
    'TPPA',
    'D-dimer',
    'GAI',
    '罕見先驅',
    'Ig G',
    'Alb',
    'A2-Glo',
    'G-Glo',
    'A1-Glo',
    'B-Glo',
    'M.Pn. IgG',
    'M.Pneu. IgM',
    'BT (Ivy)',
    'HBV DNA',
    'Cytoplasm',
    'IgG',
    'CA125',
    'Ret. %',
    'HFR',
    'IRF',
    'LFR',
    'MFR',
    'Ret. No.',
    'Ret. He',
    'HIV Ag/Ab',
    'HBe Ag',
    'Centromere',
    'Ret. index',
    'Ret. PI',
    'CA-199',
    'CMV DNA-Q',
    'HAV IgM',
    'Anti nDNA',
    'IgA',
    'BD',
    'Cold HA',
    'Zn',
    'SMA',
    'Chol/HDL',
    'Vit B12',
    'Vit. B12',
    'Ig M',
    'Speckle',
    'Nu. dot',
    'Diffuse',
    'Other',
    'PTH-intact',
    'CK-MB mass',
    'Transferin',
    'HBc IgM',
    'Ig A',
    'Anti SS-A',
    'Anti SS-B',
    'Digoxin',
    'Ig E',
    'Transferri',
    'EB-VCA IgM',
    'IgM',
    'C4',
    'Aldo.',
    'C3c',
    'Homogeneou',
    'Speckled',
    'Granular',
    'Cortisol(8AM)',
    'ALD',
    'PRA',
    'T4',
    'M.Pneu. IgG',
    'PR3',
    'MPO',
    'B-Ketone',
    'Anti-TPO',
    'HTLV 1+2Ab',
    'Cardio-IgG',
    'CO2',
    'GLU2hr',
    'Anti HBe',
    'CD34+細胞',
    'HBc IgG',
    'Vit.B12',
    'ACTH(8AM)',
    'Vancomycin(trough)',
    'Cardio-IgM',
    'Folic Acid',
    'Random GLU',
    'CMV IgG',
    'ds-DNA',
    'ASMA',
    'DAT',
    'SSB',
    'SSA',
    'Pre Alb',
    'Protein C',
    'Protein S',
    'Aspergi.Ag',
    'AMA',
    'FSH',
    'CA153',
    'Anti RNP',
    'Osmo-Blood',
    'CD34',
    'Myoglobin',
    'G H',
    'EB-VCA IgG',
    'KetoneBody',
    'PR3 Value',
    'MPO Value',
    'Lepto(MAT)',
    'M-protein',
    'EBV DNA(Q)',
    'RF',
    'IAT',
    'HCV RNA',
    'CA-153',
    'CA 15-3',
    'AT III',
    'CA-125',
    'PTH-I',
    'HSV 2 IgG',
    'Haptoglo.',
    'FDP-D Dime',
    'LH',
    'Ethanol',
    'Anti HBs(Value)',
    'HBV DNA(Q)',
    'Anti-HBs',
    'Anti Sm',
    'Cyc.sporin',
    'HSV1+2 IgM',
    'Ab-TSH Re.',
    'ATG',
    'T-IgE',
    'Thyroglobu',
    'ASLO',
    'Eos. Count',
    'IGF-1',
    'Anti CENPB',
    'HSV',
    'Anti dsDNA',
    'cANCA(PR3)',
    'pANCA(MPO)',
    'Intact PT',
    'B2GP1-IgG',
    'ACA-IgG',
    'S/N Ratio',
    'PRL',
    'C-Peptide',
    'β2GP1-IgM',
    'Phenytoin',
    'HSV1 IgG',
    'APL-IgG',
    'Hb A2',
    'HGH',
    'Ratio',
    'Anti-TG',
    'Hb F',
    'Hb A',
    'E2',
    'TPHA',
    'CA 153',
    'CD8',
    'CD4',
    'Insulin',
    'APL-IgM',
    'ACA-IgM',
    'VZV IgM',
    'Aspergillus Ag',
    'Cerulopla.',
    'C3',
    'Cryoglobin',
    'Phenylalan',
    'Methionine',
    'ICG 15min.',
    'Lym',
    'CD3',
    'CMV PCR-健',
    'CD19',
    'LA 2(Confirmation',
    'LA 1(Screening)',
    'HBVDNA',
    'RNP',
    'Sm',
    'Anti HAV',
    'PA',
    'HCSD',
    'VLCAD',
    'PCD',
    'HMG',
    'b2-glyco-p',
    'Gaucher',
    'S.typhi O',
    'S.typhi OH',
    'Sal. PA',
    'Sal. PB',
    'C5DC',
    'CT',
    'P.OX2',
    'P.OX19',
    'P.OXK',
    '17-OHP',
    'MCA-C10',
    'MCA-C6',
    'MCAC8/10',
    'MCA-C8',
    'C5',
    'ratio',
    'Ketone(B)',
    'CMVpp67',
    'Valine',
    'CIC',
    'FLLC',
    'FKLC',
    'A/G ratio',
    'MPS4A',
    'Galactose',
    'O.tsutsIgG',
    'O.tsutsIgM',
    'Ct value',
    'OTHER',
    'MTX',
    'Leucine',
    'Argininosu',
    '2M3HBA',
    'VL-acCoA',
    'Beta-keto',
    'Citrull',
    'Argininemi',
    '2-MBCD',
    'Carnit-Upt',
    'Tyrosinemi',
    'propionic',
    '3-MCC',
    'Carnit-Tan',
    'homocirtul',
    'L-acyCoA',
    '3-Methylgl',
    'HMG-CoA',
    'Carnit-PAL',
    'Holocarb',
    'S-acyCoA',
    'Hyperammon',
    'Hyperornit',
    'Trifunctio',
    'HSV1+2IgM',
    'B-HCG',
    'Anti Jo-1',
    'Theophylin',
    'HSV 2 IgM',
    'HSV 1 IgM',
    'M-peak',
    'other',
    'Speck',
    'Nucleo',
    'Homogenous',
    'MPS 6',
    'Pompe GAA',
    'MPS 2',
    'Fabry Dis',
    'MPS 1',
    'GA 1',
    'CIT 2',
    'CPT 1',
    'CIT 1',
    'CPT 2',
    'GA 2',
    'ECP',
    'EBNA',
    'Testo.',
    'Ig G 4',
    'chl.pneIgM',
    'Cortisol(9PM)',
    'Osteocalci',
    '25-OH VitD',
    'EBEA IgG',
    'ENA-CTD',
    'ACTH(9PM)',
    'Tegretol',
    'Anti-HCV',
    'Vancomycin(Peak)',
    'LAC screen',
    'O.tsutsPCR',
    'Anti GBM',
    'HBeAg',
    'Cu',
    'Cryoglobul',
    'PreAlb',
    'VZV IgG',
    'FK506',
    'AntiScl-70',
    'Anti-HBc',
    'EB-VCA IgA',
    'B2-GP1 IgG',
    'Gram Stain',
    'IgE',
    'AChR Ab',
    'HCV RNA(Q)',
    'Normalized',
    'DHEA-S',
    'tHCY',
    'LA 1/LA2 ratio',
    'Aldo(隨時)',
    'UIBC',
    'CD4/CD8',
    'Protein',
    'Alpha-1',
    'Alpha-2',
    'A/G Ratio',
    'Gamma',
    'Albumin',
    'Beta',
    'IgG/A/M Kappa/Lambda',
    'Free PSA',
    'Amoeba IHA',
    'FK/FLratio',
    'LA 1∕LA2 ratio',
    'AFB Stain',
    'CD16+56',
    'TB Ag-Nil',
    'G-6-PD',
    'aTPO',
    'Anti CCP',
    'Anti Smd',
    'Cockroach',
    'Aspergi(q)',
    'FDP(Quant)',
    'Lept.IgG+M',
    'LA',
    'HSV 1 IgG',
    'HLA-B1502',
    'LACconfirm',
    'Toxo. Ab',
    'B2 MG',
    'Prolactin',
    'Tacrolimus(FK-506)',
    'A-TPO',
    'TB antigen',
    'MTB infect',
    'Mit-Nil',
    'Nil contro',
    'Mitogen-C',
    'Titer',
    'HBcIgM',
    'Anti-GBM',
    'Chlamyd pneumoniaIgM',
    'HLA-B type',
    'tHCY.',
    'C-peptide',
    'CD19/45',
    'CD5/45',
    'CD38/45',
    'RBC Morpho',
    'CD56/45',
    'HBeAgQual',
    'HPC',
    'Toxo.IgM',
    'CD13/45',
    'CD33/45',
    'HAV-IgM',
    'C/NP ratio',
    'S/NP ratio',
    'S∕N Ratio',
    'Ig G4',
    'Anti BMZ',
    'Lept-ntPCR',
    'Ribosomal',
    'HIV-1 RNA',
    'HCY',
    'Crab',
    'K/L ratio',
    'HLA-B',
    'Kappa FLC',
    'Lambda FLC',
    'EBEA+NAIgA',
    'Anti ICS',
    'ALP(bone)',
    'Hcy',
    'ChlamydIgM',
    'HLA DR',
    'TB Antigen',
    'HLADR/45',
    'LAP Stain',
    'ICG',
    'HAVIgM',
    'CD11b/45',
    'TSH R Ab',
    'Lepto PCR',
    'HLA C',
    'HLA A',
    'HLA B',
    'Barbital',
    'CD34/45',
    'ABN Hb%',
    'Everolimus',
    'HLA-A-h',
    'DHT',
    'HLA-B-h',
    'O2Hb',
    'MetHb',
    'tHb',
    'Jo-1',
    'Shrimp',
    'COHb',
    'Anti-HBe',
    'Beef',
    'Index',
    'HLA-C-h',
    'Legione Ab',
    'Scl-70s',
    'Anti-CTD',
    'RHb',
    'VZV',
    'Egg white',
    'sO2m',
    'Peanut',
    'O2ct',
    '蛋白',
    'Wheat',
    'APA',
    'P1NP',
    'avidity',
    'score',
    'GLU 1hr',
    'Pork',
    'Egg yolk',
    'Codfish',
    '25-(OH)D3',
    '牛奶',
    'Soybean',
    'Yeast',
    'Milk',
    'Renin(PRA)',
    'Ragweed',
    'Penicilium',
    'STR DNA',
    'Willow',
    'Feathers',
    'Avocado',
    'Eucalyptus',
    'Mite DF',
    'Latex',
    'Mulberry',
    'Pigweed',
    'Cat',
    'Aspergilus',
    'Housedust',
    'Timothy',
    'J. cedar',
    'Clam',
    'Cheese',
    'Alternaria',
    'Tuna',
    'Cladospori',
    'Mite DP',
    'Bermuda',
    'Dog',
    'aTG',
    'HLA-B27',
    'IgG4',
    'Mitogen minus Nil',
    'O2cap',
    'HLA-DR-h',
    'HLA match',
    '蝦',
    'MTB Inf.Report',
    'Mitogen Control',
    'VitaminB12',
    'Nil Control',
    'GLU 3hr',
    'CTx',
    'Calcitonin',
    'RF-IgA',
    'CD7/45',
    'Methanol',
    'CCP',
    'HLA-DR',
    'TB Antigen minus Nil',
    'JAK2-ss',
    'K∕L ratio',
    'Cortiso8A',
    'HLA B27',
    'HBsAg定量',
    'Entero71Ab',
    'Thyroglo',
    'Panadol',
    'CD2/45',
    'HLA-DQ',
    '蟹',
    'AntithyrAb',
    'Toxo.IgG',
    'IgG 4',
    '19/L/45',
    'Cortisol8AM(1mgDexa)',
    '19/K/45',
    'D.pteronys',
    'Pb',
    'Testo',
    'HCV gene',
    'FactorVIII',
    'HIV-1(WB)',
    'ASD',
    '蛋黃',
    'CD22/45',
    'M peak',
    'Folic acid',
    'A∕G rate',
    'IgM Value',
    'CD10/45',
    'Hb ABN',
    '19/45/34',
    'CD3+∕CD8+ Suppre. T',
    'CD20/45',
    'HLA DQ',
    'CD3+∕CD4+ Helper T',
    'CMV PP67',
    'T4 (Thyrox',
    'EBV PCR-健',
    'Insulin Ab',
    'FMC7/45',
    'CD23/45',
    'Dengue IgM',
    'ChlamP.IgM',
    'HTLV1+2 Ab',
    'Parasite',
    'HLA-A',
    'HAV(Total)',
    '56/45/34',
    'CD16/45',
    'Vitamin D',
    'CIT I',
    'CPT II',
    'CPT I',
    'GA II',
    'RF IgM',
    'GA I',
    'CIT II',
    'Dengue IgG',
    'CD19+ B cells',
    'CD3+ T cells',
    'ICS Ab',
    'Culture-痰',
    'CD14/45',
    'ICG15min.',
    'FK∕FL ratio',
    'Dengue NS1',
    'ChlamydIgG',
    'GP41-ENV',
    'GP120-ENV',
    'HLA-C',
    'GP160-ENV',
    'MTBC PCR',
    'CD4∕CD8',
    'LA 2',
    'P55-GAG',
    'LA1∕2rati',
    'LA 1',
    'Anti B',
    'P52-POL',
    'Anti A',
    'P25-GAG',
    'Coombs IgG',
    'Sirolimus',
    'P34-POL',
    'P68-POL',
    'P18-GAG',
    'Coombs C3d',
    'EBNA IgG',
    'Proge.',
    'food mix28',
    'Haptoglobi',
    'CD3+/CD8+ Suppre. T',
    'A/G rate',
    'HCVRNA',
    'MAComplex',
    'CD3+/CD4+ Helper T',
    'sp.IgE MFA',
    'Cystatin C',
    'Asp Ag',
    'Caionized',
    'FungalMix2',
    'EpiderMix2',
    'Leptospira',
    'Cai-pH7.4',
    'Parvo B19',
    'sp.IgE MIA',
    'M.pneumoni',
    'LAC confir',
    'Toxo.godni',
    'GH(0 min)',
    'CD8/45',
    'CD4/45',
    'GH(30 min)',
    '鱈魚',
    'Gastrin',
    'AMH',
    'GH(60 min)',
    '花生',
    'HSV IgM',
    'GH(90 min)',
    'CD3/45',
    '動物毛皮篩',
    'GH(120 min)',
    'ACTH 8A',
    '花粉',
    'EBV RNA-Ly',
    'HBs Ag(Q)',
    '芒果',
    'M.Pneu.IgG',
    'EBV RNA-La',
    'Eos Count',
    'VZV Ab',
    '花草類篩檢',
    'class II',
    'BUN洗前',
    'Rube. IgG',
    'HBV DNA 必治妥',
    '食物篩檢',
    'class I',
    'fx2',
    '黴菌篩檢',
    '小麥',
    '家塵檢查',
    '五穀類篩',
    '狗毛屑',
    'HPC %',
    '貝',
    'Phadinfan',
    '水果類篩檢',
    'ChlamydIgA',
    'GA',
    'Pompe',
    'HPC No.',
    'mAPTT  2x',
    'HLA-A-l',
    'AAT',
    'BCR-ABL rt',
    'HLA-B-l',
    'RATIO',
    'GLU(0min)',
    'Ragweedmix',
    'MSUD-L',
    'As',
    'MSUD-V',
    'PFACol∕Epi',
    '大豆',
    'PFACol∕ADP',
    '混合花草',
    '貓毛屑',
    'Phadiatop',
    'CMV',
    'NormalPool',
    'Amikacin',
    'HCV genome',
    'Pl. Ab',
    'Orig.APTT',
    'Paraquat',
    'CD117/45',
    '肉類篩檢()',
    'CD36/45',
    'mAPTT1.25x',
    '魚類篩檢',
    '蔬果類篩檢',
    'Mitogen-Ni',
    'CD64/45',
    'mAPTT  5x',
    'NilControl',
    'TB Ag -Nil',
    'Mitogen Co',
    '調味類篩檢',
    'Hg',
    'Anti CENP',
    'JAK2',
    'Cyclosporin',
    'CDC-S',
    'HCV Genoty',
    'CMV PCR',
    '17-OH Prg.',
    'HLA-B5801',
    'B2MG',
    '交錯黴菌',
    'HLA-DRB1-l',
    'C.P IgM',
    'BCR∕abl(q)',
    'C sporid-q',
    'FLT3∕ITD',
    'mPT 2x',
    '串聯質譜儀',
    'IGRA-TB',
    'HAV IgG',
    'GLU(30min)',
    'FK/FL ratio',
    'APCA',
    'FSH-30min',
    'Toxo IgM',
    'LH-60min',
    'FSH-60min',
    'CHE',
    'CMV-PCR(定性)',
    'Ab-TSH rec',
    'LH-30min',
    'FSH-0min',
    'HIV快篩',
    'LH-0min',
    'B2-Microgl',
    '粉塵蹣',
    '屋塵蹣',
    'CD34+ Count',
    '%CD34+',
    '羊肉',
    'Aldosteron',
    'GLU(60min)',
    'Salicylate',
    'CD45+Total leukocyte',
    '白色念珠菌',
    'f PSA/PSA',
    '家塵',
    '蟑螂',
    '蚌',
    'Dog dander',
    'Ornithine',
    'Arginine',
    'Tyrosine',
    'Glycine',
    'GLU(45min)',
    'Alanine',
    'Citrulline',
    'Al',
    'mPT 5x',
    'mPT 1.25x',
    'GLU(90min)',
    'GLU(15min)',
    'RBC/55',
    'B. hensela',
    'GLU(120min)',
    'RBC/59',
    '蛤',
    'B2GP-G',
    'FSH-90min',
    'LH-90min',
    'LH-120min',
    'Factor IX',
    '章魚',
    'Anti-HAV',
    '牡蠣',
    '扇貝',
    '鎖管,透抽',
    'GLU 0.5hr',
    'BCR∕ABL',
    'ACHR-AB(Ac',
    'Rube. IgM',
    'FSH-120min',
    '1.25(OH)-D',
    'Toxo. IgM',
    'β- HCG',
    'RBC/GlyA',
    'GH(45 min)',
    'AQP4 Ab',
    'α1-AT',
    'GH(15 min)',
    'CD33/55/45',
    'B.grass',
    'Ig KLC',
    'mx2',
    'Gentamicin',
    'BUN季',
    'CD33/59/45',
    'Cat Hair',
    'Ig LLC',
    'EV71IgM-E',
    'C2-carniti',
    'IGF-I',
    'C12:1-carn',
    'C16:1-carn',
    'C5:1',
    'C5OH-carni',
    'C3-carniti',
    'C18-carnit',
    'C16-carnit',
    'C8-carniti',
    'C10:1-carn',
    'C16OH-carn',
    'C18:1-carn',
    'Thrombin T',
    'F.carnitin',
    'C6-carniti',
    'C4OH',
    'C14OH-carn',
    'C14:1-carn',
    'C3DC-carni',
    'C4-carniti',
    'C5-carniti',
    'C10-carnit',
    'C5DC-carni',
    'C8:1-carni',
    'C18:1OH-ca',
    'AGAD',
    'C14-carnit',
    'C12-carnit',
    'EV71IgM-R',
    '樹木花粉篩',
    '堅果篩fx22',
    'HIV-1RNA',
    '花粉篩rx3',
    '花粉篩rx6',
    'Orig.PT',
    'Mito-Nil',
    'Mitogen',
    'Nil',
    'Mix. APTT',
    'Result',
    'TB Ag',
    'ex2皮毛類',
    'fx21水果類',
    'rx3花粉類',
    'Scl-70',
    'P40-GAG',
    'Anti-CCP',
    'hx2屋塵類',
    'fx2海鮮類',
    'B2GP-M',
    'β-HCG',
    'Lyme IgG',
    'CgA',
    'Mumps IgM',
    'vWF',
    'BUN洗後',
    'ALP Stain',
    '登革熱',
    'HSV2.Value',
    'HLA-DRB1-h',
    'MeaslesIgM',
    'GH',
    'HCV GT',
    'Toxo.Value',
    'HSV1.Value',
    'CMV.Value',
    'R.tsu. Ab',
    'Rube.Value',
    'Factor V',
    'J.ence.IgM',
    'Mixing PT',
    'Lept-rtPCR',
    'NPM1DNA(q)',
    'J.ence.IgG',
    'GAD Ab',
    '芹菜',
    'EBV DNA',
    'Anti-ENA',
    '蕃茄',
    'Cereal Mix',
    'Nuts Mix',
    'FruitMix15',
    'R.typhiIgM',
    'Gluten',
    'Vege Mix7',
    'R.typhiIgG',
    'R.typhiPCR',
    'Score',
    'EPO',
    'Aspergillu',
    'JE.IgM-CSF',
    'JE.IgG-CSF',
    'HLA-C-l',
    'ATA',
    'Ig G3',
    'T3 uptake',
    'Test Value',
    'Cortisol4P',
    'JAK2-V617F',
    'NSE',
    'RF-total',
    'HbA2',
    'AFB  Stain',
    'Chla-IgG',
    'α-Tha. SEA',
    '樺樹',
    '車前草',
    'HbA',
    'Testostero',
    'HbF',
    'Chla-IgM',
    'VWF',
    'FLT3-D835',
    'HSV2 IgG',
    'Rub.IgG',
    'BKV DNA',
    'Chl. trachomatis IgG',
    'ACE',
    'Dengue',
    'Ig G2',
    'Cd',
    'Ig G1',
    'ABN Hb ID',
    'N.poolAPTT',
    'Ig D',
    'VZV-PCR',
    'chromosome',
    'NPM1-MLF1',
    'PML-RAR',
    'MLL-TLLT11',
    'MLL-FOXO4',
    'FUS-ERG',
    'STIL-TAL1',
    'MLL-AFF1',
    'CBFB-MYH11',
    'C8∕C10',
    'ETV6-ABL1',
    'ETV6-MN1',
    'MLL-MLLT3',
    '法布GLA',
    'PHE∕TYR',
    'ETV6-PDGFR',
    'MLL-EPS15',
    'kiwi',
    'MPL W515L',
    'MLL-MLLT6',
    'TCF3-PBX1',
    'C3∕C2',
    'C5DC∕C16',
    'ETV6-RUNX1',
    'DEK-NUP214',
    'C0∕(C16+C1',
    'GAD自體抗',
    'Leu∕Isleu',
    'MLL-MLLT1',
    'SET-NUP214',
    'RNUX1-RUNX',
    'MLL-MLLT4',
    'ZBTB16-RAR',
    'CDC-B',
    'MPL W515K',
    'BCR-ABL1',
    'NPM1-RARA',
    'TCF3-HLF',
    'MLL-ELL',
    'MLL-MLLT10',
    'RUNX1-MECO',
    'HLA-DQB1',
    'Grass Mix3',
    'Cortisol(0 min)',
    'C.pneumoni',
    'PML-RARA',
    'HouseDust2',
    'Food mix5',
    'AntiHAVIgM',
    'everolimus',
    'Sesame',
    'HLA-DQ-h',
    'Nei. gono.',
    'AQP-4 Ab',
    'CMV DNA',
    'cfEGFR',
    'mAPTT 1:1',
    '榛子',
    'LDH 4',
    'LDH 5',
    'BCR/abl(q)',
    'LDH 2',
    'PFACol/Epi',
    '麥粉',
    'HBV DRRM',
    '甜瓜',
    'Adenovirus',
    '25-(OH)',
    'PFACol/ADP',
    '紅蘿蔔',
    '酪蛋白',
    'LDH 3',
    'TPA',
    'LDH 1',
    'alpha SEA',
    'C.burnePCR',
    '馬鈴薯',
    'β-2 micro',
    'HbF RBCs',
    'CD25/45',
    'Amoebic Ab',
    'Pan-EV',
    'Cr',
    'F 8 Inhib.',
    'HbA RBCs',
    'HLA-Cw',
    'C5DC/C16',
    '橘子',
    'Anti-ds DN',
    '杏仁',
    'PHE/TYR',
    'C0/(C16+C1',
    'Leu/Isleu',
    'Co',
    '黃豆',
    'Deng.NS1Ag',
    'HHb',
    'Deng. IgM',
    '芝麻種子',
    '蘋果',
    'Deng. IgG',
    'Mumps Ab',
    'C8/C10',
    'Measles Ab',
    '核桃',
    'O2cap(BO2)',
    'AntiHBcIgM',
    'RF-CCP',
    'C3/C2',
    '兔子',
    '麴菌屬',
    'mAPTT 2hr',
    '玉米',
    '粉塵璊',
    '天竺鼠',
    '交錯黴菌屬',
    '馬毛屑',
    '艾萵',
    'HBV DNA 禾利行',
    '赤楊',
    'Cortisol(30min)',
    'EV 71 IgM',
    'CD1a/45',
    '芽枝菌屬',
    '黃金鼠',
    'mAPTT2hCtl',
    '日本腦炎',
    '榛木',
    '屋塵璊',
    'β-CrossLaps',
    '青黴菌',
    '橡木',
    'H.pylo Ab',
    '法布(初篩)',
    'NPM1(quali',
    'CA 72-4',
    'C. tracho.',
    'Cortisol(90min)',
    'HBs Ag( 定量)',
    'mPT 2hrCtl',
    'CALRType2',
    'Lepto.IgM',
    'mPT 1:1',
    'N.pool PT',
    'PML-RARα',
    'CALR Type1',
    'Cortisol(60min)',
    'CD3-/CD16+/CD56+ NK',
    'T.pallidum',
    'HLA Cw',
    'CYFRA 21-1',
    'mPT 2hr',
    'CD184/45',
    'Ribos-P',
    'ALP-Liver',
    '香蕉',
    '大蒜',
    'T cell cross match',
    'pneumococa',
    '酵母菌',
    'Cyfra 21-1',
    '桃子',
    'B cell cross match',
    'FLT3/ITD',
    '奇異果',
    'HCV NS5A',
    'Cortisol(120min)',
    '鳳梨',
    'Chlamyd trachomatIgM',
    'Peroxidase',
    'C.T IgG',
    'Gastrin-17',
    'Testo.(pre-test)',
    'Hanta V.Ab',
    '洋蔥',
    'Tryptase',
    'ALP-Intest',
    'ALP-Bone',
    'B27 PCR',
    '蕎麥',
    'Asp.sppPCR',
    'E3',
    '燕麥',
    'TB Ag minus Nil',
    '芝麻',
    'Ente.71IgM',
    'Fast liver',
    'IDUA∕GLA',
    'Chl.trachomatis IgA',
    'Cat dander',
    'HBVgenotyp',
    'A-Lipo',
    'HBV DNA 衛采',
    'F/24/45/33',
    'Q fever',
    'C.burneIgG',
    '貓皮毛屑',
    'Chyl.',
    'BCR/ABL',
    'C4DC-carni',
    'Lysine',
    'H.pylori',
    'Mi-2',
    'Threonine',
    'Glutamate',
    'C.burneIgM',
    'F/14/45/33',
    'CALR type1',
    '灰塵類篩檢',
    'Proline',
    '豌豆',
    'Tryptophan',
    'FTA-ABS',
    'B-Lipo',
    'C.T IgM',
    'OH-Proline',
    'DAT-C3d',
    'Glutamine',
    'CALR type2',
    '黴菌類篩檢',
    'Aspartate',
    'B. troppic',
    'Serine',
    'D.farinae',
    'Isoleucine',
    'Toxo. IgG',
    'PJP',
    '38/cL/45',
    '38/cG1/45',
    '71/GlyA/45',
    'CD56',
    'uE3',
    '豬草',
    'PRG',
    '38/cK/45',
    'anti-GAD',
    'C-Peptide(0 min)',
    '百喜草',
    '138/38/cK/',
    'H.influe.b',
    '狗牙根草',
    '138/38/cL/',
    '黑麥草',
    'PM-Scl',
    'Free β-HC',
    'HLA Ab',
    'BCR∕abl(Q)',
    '白皮松樹',
    '尤加利樹',
    'Asparagine',
    'T3+DR+',
    'Histidine',
    'Vit E',
    'CK-MM',
    'CD3-∕CD16+∕CD56+ N',
    'CMV Ab',
    'HCVtype(RT',
    'CD3-∕56+∕69+',
    'JEV',
    'EV71型快篩',
    '長葉相思樹',
    'BCR/abl(Q)',
    'C-Peptide(6 min)',
    'PRA C- II',
    'CD3-∕56+∕25+',
    'CK-BB',
    'Parvovirus B19',
    'BCR-ABL IS',
    '黃花柳',
    'HSV 1 Ab',
    '白千層',
    '138/38/56/',
    'Toxo.Ab',
    'Rubella Ab',
    'T3+DR-',
    'JCV DNA',
    'CD3-∕56+',
    'HSV 2 Ab',
    '其他類',
    '油橄欖樹',
    'HCV RNA 羅氏',
    'HDV Ab',
    '33/55/45Mo',
    'AHT',
    'SWT',
    'EGFR L858R',
    'Free B-HCG',
    'EGFR ex20',
    'Cow milk',
    'MICA',
    'BCR-ABL(Q)',
    'DAT-MonIg',
    'Pre B-Lipo',
    'EGFR S768I',
    '芽枝黴菌',
    'FLAER/CD24 TIII Def.',
    'EGFR 19del',
    '乳膠',
    'MTB-PCR',
    'FLAER/CD16 TII Def.',
    'm81',
    '艾草',
    'EGFR L861Q',
    'MTB PCR',
    'EGFR T790M',
    '33/59/45My',
    '橙',
    'HCVgenotyp',
    '33/55/45My',
    'FLAER/CD24 TII Def.',
    'FLAER/CD16 TIII Def.',
    '酵母',
    'ChlamT IgM',
    'Vit.A',
    'EGFR G719X',
    '梯牧草',
    'N.meninigi',
    'A2APlasmin',
    'ADP test',
    'Fibrillar',
    'DHT(post-tes)',
    '33/59/45Mo',
    'RNApol III',
    'm80',
    'f PSA∕PSA',
    'HBV DNA 輝瑞',
    'HBV DNA 羅氏-限風免',
    'HSV PCR',
    'JC virus',
    'Rh(c)',
    'Rh(e)',
    'Lewis Leb',
    'MNS M',
    '藜',
    'Anti HBs 3',
    '紅色毛癬菌',
    'RF-IgM',
    '家塵篩檢',
    'CD20',
    'GBM',
    'Rh E',
    'Kidd Jka',
    'f24蝦',
    'Q. feronTB',
    'ACA-G',
    'd2粉塵璊',
    'Lewis Lea',
    '動物皮毛篩',
    'ACT Colon',
    'MNS S',
    'MNS N',
    '急性A肝',
    'GAD',
    'GPT',
    'Dilutions',
    'NPM',
    'i6德國蟑螂',
    'Inhibin A',
    'Rh C',
    'FTI',
    'mAPTT 2x',
    'C.T IgA',
    'Kidd Jkb',
    'F 8 Resid.',
    'pax6',
    '食物篩檢5',
    'd1屋塵璊',
    'Apo A1',
    'P2Y12 test',
    '芽孢菌屬',
    'CDC-BC',
    'f1蛋白',
    '45/DR',
    '45/2',
    'Begin',
    '碗豆',
    'food mix9',
    'Factor XI',
    'Turkeymeat',
    'Anti HDV',
    'BK virus',
    '45/38',
    'Plasminoge',
    '(EA+NA)IgA',
    'Lept IgG+M',
    'HCV RNA 輝瑞',
    'HBV DNA 羅氏',
    'CD16',
    'food mix5',
    'HLA Class I Ab',
    '琥珀膽鹼',
    'CRC Monito',
    '食物篩檢 5',
    'GLU1hr',
    'DRB1-h',
    'f2牛奶',
    'cTdT/45',
    'Dengue Vir',
    'PFACol∕AD',
    'Lyso-Gb3',
    'Panadol定',
    'food mix8',
    '45/56',
    '胡蘿蔔',
    '白豆',
    'Complete',
    'ACTH 4P',
    'cMPO/45',
    'HLA DQ-H',
    '45/34',
    'mAPTT 5x',
    'HLA Class II Ab',
    'Dengue Ag',
    'Ovomucoid',
    '45/13',
    'FTA-ABS g',
    'mAPTT 1.25',
    '花椰菜(綠',
    '45/11b',
    '45/19',
    'T3 Uptake ratio',
    '17a-OHP',
    '45/5',
    'CD10',
    'PFACol∕Ep',
    'HLA-B5701',
    'Leptro-IgM',
    '45/33',
    'T13',
    'ChlamT IgG',
    'chl.pneuAb',
    '藜草',
    'IgG Value',
    'Anti-ENA SmRNP',
    'APT',
    'Apo B',
    'BCR-ABL',
    '代謝異常',
    'SkMA',
    'P51∕52-PO',
    'P17∕18-GA',
    'ACA-M',
    'T21',
    'Bcr-Abl RNA定量',
    'Progest',
    'Anti-ENA RNP',
    '武漢肺炎',
    '豬肉',
    'P66∕68-PO',
    'DHT(pre-test)',
    'Erythropoi',
    'Duffy Fyb',
    'T Uptake',
    'Banana',
    'Soy bean',
    'Anti-ENA RNP A',
    'ADA',
    'Rice',
    'P31∕34-PO',
    'Rye',
    'Factor VII',
    'Ethanol(D)',
    'Anti-ENA RNP 68',
    'PAI-1',
    'vWF:RC',
    'Kell(k)',
    'Anti-ENA Sm',
    'Cox. B4 Ab',
    'N-Sp Ease',
    'Cox. B1 Ab',
    'AvgRisk',
    'Cox. B3 Ab',
    '雞肉',
    'Kell K',
    'Cox. B2 Ab',
    '相思樹',
    'OncoLBx',
    'MNS(s)',
    'CD25',
    '尤加利',
    'GLU(180min)',
    'Cox. B5 Ab',
    'Sp.Ease',
    'CHS',
    'Cox. B6 Ab',
    'Barley',
    'DHEA-SO4',
    '牛肉',
    'BCRABL定性',
    'A.canto.Ab',
    '腸病毒',
    'BrucelaAb',
    'H.P. Ab',
    'Duffy Fya',
    'T18',
    'SCA 6',
    'PreB-Lipo',
    'SCA 17',
    '牛肉蛋白',
    'begin',
    'CD14',
    'THA-a3.7',
    'GLU0.5hr',
    '萊姆病',
    'P24∕25-GA',
    'Casein',
    'L861Q',
    'THA-SEA',
    'AntiHBstit',
    '健檢HIV',
    'NPM1',
    'CD7',
    'Peach',
    'FactorIXAb',
    'CD5',
    'cCD3/45',
    '13號染色體',
    'Anti-PLA2R',
    '1,25(OH)2D',
    'Exon19缺失',
    'PAP',
    '急性E肝',
    'Fetal Hb',
    'Onion',
    'FPSA/TPSA',
    'f14大豆',
    'THA-28',
    'THA-FIL',
    'Pecan Nut',
    'CD16+56(%)',
    '18號染色體',
    'Celery',
    '45/19/k',
    'L858R',
    'CGH',
    '開心果',
    'Rif',
    'THA-THAI',
    'Exon20插入',
    'risk',
    'SCA 3',
    'Cod fish',
    'IgE sn.',
    '胡桃',
    'EBVDNA定性',
    '45/FMC-7',
    '鯡魚',
    '腰果',
    'Cortisol 8AM (HDDS)',
    '45/7',
    'Heinz Body',
    'A.A補助',
    'CD33',
    'IDUA∕GAA',
    'SCA 2',
    'Food mix',
    'Apple',
    'EV 71',
    'f3鱈魚',
    '癌可明',
    'SCA 1',
    '2019-nCoV',
    'EGFR gene',
    'NTD risk',
    'Cashew Nut',
    'T790M',
    'LH0`',
    'B-乳酸球蛋',
    '腸追蹤',
    'CD13',
    'PAS Stain',
    'Factor XII',
    '山核果',
    'S768I',
    'IL-6',
    'THA-c41.42',
    'Garlic',
    'Tri18 risk',
    'complete',
    'GH(180 min)',
    'Orange',
    'GOT',
    'f13花生',
    'A.A',
    'BRCA1∕2',
    '鯖魚',
    'ABLkd mut.',
    'THA-IVS2',
    'a-乳酸蛋白',
    'Walnut',
    '45/19/L',
    'OGTT AC',
    '21號染色體',
    'TITER',
    'Factor X',
    'OGTT 2hr',
    '17α-OHP',
    '45/22',
    '鰈魚',
    'Apo B∕A1',
    'Ethanol(ND',
    'Tomato',
    'VZV PCR',
    'EC Protein',
    'THA-a4.2',
    'THA-c17',
    'PRA(ratio)',
    'CMLglivec',
    'G719X',
    '45/20',
    'f4小麥',
    '45/23',
    'Pistachio',
    'CD16/CD56',
    '45/10',
    'HCV RNA 衛采',
    'HBV DNA 台田',
    'HGH60`',
    'CJD',
    'PRA I',
    '胺基酸 31',
    'fx1堅果類',
    'other chomo',
    'Grape',
    'HCV RNA 可善挺',
    'RISTO test',
    '性染色體',
    '其他染色體',
    'HCV RNA 嬌生',
    'AFPmom',
    'fx5 食物篩',
    'C.albican',
    'GLU3hr',
    'Fungal Mix',
    'C.herbarum',
    'Inhibition',
    'NMDA R-ab',
    'Lepto.DNA',
    'b-hcg mom',
    'FIB4',
    'mx2黴菌類',
    'LH60`',
    'FSH90`',
    'FSH-180min',
    'A.fumigatu',
    '油加利樹花粉',
    '葎草花粉',
    'Chromosome',
    'SugarWater',
    '判讀',
    'ex2動物皮',
    'IgA Value',
    'cG1-PE/45',
    '晚發型龐貝氏症篩檢',
    '類鼻疽',
    '45/14',
    'Aldo.(post N/S)',
    'Amikacin-P',
    'SMA screen',
    'HCV RNA 中外',
    '火雞肉',
    'FSH30`',
    'NS.Enolase',
    'epider.Mix',
    'D2:D.farin',
    'Anti-RNA3',
    '日本杉花粉',
    'ParvoB19',
    'Pl.Ab',
    'other Hb',
    'downs risk',
    'CD16&CD56',
    'HCV1bNS5A',
    'ACT lung',
    'HGH30`',
    '3種微缺失',
    'Tcell 4°C',
    '相思樹花粉',
    '5p15',
    '胺基酸 93',
    'Giemsa',
    '45/1a',
    'H-Dust.Mix',
    'crossmatch',
    'HCV RNA 必治妥',
    'PAPP-A',
    'FX5 IgE sn',
    'Bcell 37°C',
    'Cortisol0`',
    'FSH 0`',
    '45/25',
    '豬草花粉',
    'Melons',
    '龐貝(初篩)',
    'ASPI test',
    '45/15',
    'ChlamT IgA',
    '1p36',
    'uE3mom',
    'Inhi-Amom',
    'Candida al',
    'Cortisol(15 min)',
    'HCV RNA 田邊製藥',
    'CD11b',
    'Sex chomo',
    'IU/ml',
    '45/16',
    'FSH45`',
    'Cy-CD3',
    '45/3',
    'T18risk',
    'HGH90`',
    'Aldo.(post ARB)',
    'Pyruvate',
    '性染色體數',
    'fx5主副食',
    'TroponinI',
    'Cow Milk',
    'Goat milk',
    'Soya bean',
    'Sta.ETB',
    'CD19∕L',
    'FMC7',
    'As(total)',
    'OGTT 1hr',
    'LH45`',
    'PRA II',
    '消毒抗菌劑',
    'APRI',
    'Iron Stain',
    'Bcell 4°C',
    'β-lactalb',
    'HBV DNA 嬌生',
    'Almond',
    'Mango frui',
    'HCV type',
    '22q11',
    'CD23',
    'Tcell 37°C',
    'α-lactalb',
    'Total B-HC',
    'fx3澱粉類',
    '柳樹花粉',
    'A.tenuis',
    '45/8',
    'HGH0`',
    'Bleed time',
    'LH-180min',
    'CD19∕K',
    'cG1-F/45',
    'Apo B/A1',
    'OSB Risk',
    '45/117',
    'T18 Risk',
    'NAG/GAA',
    'FSH60`',
    'FSH15`',
    '45/4',
    'HGH120`',
    'LH90`',
    'Cortisol 8AM (LDDS)',
    'Cros Match',
    'IgD',
    'FXS scn',
    'P.notatum',
    'BMZ Ab',
    'NTDrisk',
    'LH15`',
    'LH30`',
    'EGFR',
    'Free Hb',
    'Acid Ham',
    'Down Risk',
  ],
  Urine: [
    'WBC',
    'Oth.',
    'Bact.',
    'GLU',
    'pH',
    'PRO',
    'OB',
    'NIT',
    'BIL',
    'Color',
    'Clarity',
    'URO',
    'RBC',
    'Cast',
    'Sp. Gr.',
    'Ep. Cell',
    'KET',
    'LEU',
    'Crystals',
    'Fungi',
    'Mucus',
    'Parasites',
    'others-3',
    'others-2',
    'Crystal',
    'others-1',
    'WBCesteras',
    'KET-AA',
    'Sp.Gr.',
    'Ep.Cell',
    'Leuko',
    'CRE',
    'Na',
    'CRE(urine)',
    'Osmo.',
    'TP(Urine)',
    'K',
    'CRE單次尿',
    'BUN',
    'Urine volu',
    '24 hrs urine',
    'Urine HCG',
    'Total Vol.',
    'Osmo-Urine',
    'Cl',
    'volu',
    'Value',
    'S. pneum.',
    'Cut-off',
    'Gram Stain',
    'ALB',
    'TP-Urine',
    'Opiates',
    'A1-Glo',
    'A2-Glo',
    'B-Glo',
    'Alb',
    'G-Glo',
    'urine vol',
    'TP/CRE',
    'VMA',
    'Amphetamin',
    'C.Cr',
    'ALB∕CRE',
    'Epinephrin',
    'Dopamin',
    'Norepineph',
    'UPCR',
    'Myoglobin',
    'Legione Ag',
    'ALB/CRE',
    'Osmo.-U',
    'Pre Alb',
    'free corti',
    'TCA',
    'Cocaines',
    'Methadone',
    'THC',
    'T P',
    'Barbiturat',
    'AFB Stain',
    'Benzodiaze',
    'TP(24h U)',
    'mAMP',
    'Acetaminop',
    'Phencyclid',
    'Ca',
    'MDMA',
    'M-protein',
    'Cannabinoids',
    'Ketamine',
    'BENZ',
    'Aldo.',
    'BUN 24hrU',
    '24hrs Urine',
    'Amphetamine',
    'Legionella',
    'U A',
    'Paraquat',
    'Na單次尿',
    'I P',
    'Na 24hrU',
    'BKV DNA',
    'JCV DNA',
    'dysmorphic',
    'normal',
    'Mg',
    'Specific G',
    'Ig G',
    'Ig M',
    'Ig A',
    'Ig E',
    'Morphine',
    'U-microalb',
    'Cocaine',
    'Diazepines',
    'Lept-ntPCR',
    'S.pneu. Ag',
    'Lepto PCR',
    'Phencyclidine P',
    'ABN peak',
    'K 24hrU',
    'Methamphetamine',
    'TP(Random)',
    'Cortisol',
    'Parasite',
    'BJ Protein',
    'Upcr',
    'UACR',
    'PreAlb',
    'FLC',
    '17-KS',
    '5-HIAA',
    'AFB  Stain',
    'U-TP',
    'K 單次尿',
    'Cu',
    'FLLC',
    'FKLC',
    'Leptospira',
    'Ca 單次尿',
    'IgM',
    'FK/FLratio',
    'IgG',
    'IgA',
    'Acetaminophen',
    'OPI',
    'Protein',
    'APAP',
    'Alpha-1',
    'A/G Ratio',
    'Beta',
    'Alpha-2',
    'MAMP',
    'BAR',
    'MTD',
    'PCP',
    'Albumin',
    'BZO',
    'COC',
    'Gamma',
    'AMP',
    'Dark-field',
    'Methylamphetamines',
    'Tetrahydrocannabinol',
    'PBG',
    'Phencyclidine',
    'Barbiturates',
    'Benzodiazepines',
    'Tricyclic Antidepres',
    'Free Light Chain',
    'MTBC PCR',
    'Total vol.',
    'MET screen',
    'K他命',
    'MAComplex',
    'Benzodiapi',
    'Ig LLC',
    'Ig KLC',
    'pH-pHMeter',
    'KET screen',
    'CRE(Urine)',
    'MTB-PCR',
    'Cortisol urine',
    'MOR screen',
    'Nei. gono.',
    'Lept-rtPCR',
    '17-OHCS',
    'C. tracho.',
    'Cop.phyrin',
    'Cl 單次尿',
    'M peak',
    'GLU單次尿',
    'Cl 24hrU',
    'Cannabinoi',
    'Cortisol U',
    'TP(CSF\\U)',
    'pH by pH M',
    'JC virus',
    'FungiStain',
    'DrugScreen',
    'UUN單次尿',
    'BK virus',
    'UA單次尿',
    'K/L ratio',
    'Cr',
    'Toxicology',
    'Microalbum',
    'Cu(24hrs.)',
    'Co',
    'B2 MG',
    'Urine Vol.',
    'As',
    'Micro ALB',
    'Ca 24hrU',
    'MTB PCR',
    'Chyuria',
    'Cannabis',
    'Ethanol',
    'Cotinine',
    'CDC-Urine',
    'Pb',
    'CRE(清晨尿',
    'NH3',
    'TBI',
    'Rif',
    'NG',
    '24 hr urine',
    'AMY',
    'Cd',
    'organic ac',
    'Chlamyd.Ag',
    'CT',
    'S-ALA',
    'CMV-PCR(定性)',
    'Strep. B',
    'DBI',
    'UR PROT',
    'FK∕FL ratio',
    'UA 24hrU',
    'CMV',
    'Ni',
    'GLU 24hrU',
    'Crypto. Ag',
    'Mg 24hrU',
    'B-HCG',
    'Lipase',
    'As(urine)',
    'TP(單次尿)',
    'LDH',
    'N.gono DNA',
    'CT DNA',
    'MOR',
    'FK/FL ratio',
    'RBC Morph.',
    'MTBc',
    'As∕CRE',
    'MET',
    'CMV-PCR',
    'ALA',
    'N.gonorrho',
    'Coproporph',
    'Transferin',
  ],
  Stool: [
    'Color',
    'OB',
    'Parasite',
    'RBC',
    'WBC',
    'Pus',
    'Consisten.',
    'Consistenc',
    'Appearance',
    'Consisten',
    '寄生蟲學名',
    'OB-Im.Quan',
    'value',
    'Amoeba',
    'Rota Ag',
    'Ova Direct',
    'Consistency',
    'Oth.',
    'CDtoxinA+B',
    'OB-Im.Qual',
    'TRF Rapid',
    'CD GDH Ag',
    'Ova Conc.',
    'ToxinA+B',
    'OB(result)',
    'OB(value)',
    'CD Antigen',
    'OB(Result)',
    'C.diff',
    'GDH Ag',
    'CD-Epi 027',
    'Toxic-CD',
    'OB(Value)',
    'FOB',
    'TF',
    'C.diff-PCR',
    'Ag',
    'TF(Result)',
    'C.difficile toxin B',
    '027 strain',
    'Noro rapid',
    'AFB Stain',
    'Adeno Ag',
    'Amoeb∕ova',
    'Rota virus',
    'Neut. Fat',
    '檢驗值',
    'OB(定量)',
    'Fatty Acid',
    'AAT',
    'Starch',
    'Norovirus',
    'Entameba',
    'C sporid-q',
    'Entameba h',
    'C.sporidum',
    'CDC-Stool',
    'MTBC PCR',
    'MAComplex',
    'K',
    'Na',
    'NoroviruAg',
    'Osmo.',
    'APT test',
    'pH',
    'AFB  Stain',
    'CMV',
    'Redu.Sugar',
    'Apt test',
    'H.pyloriAg',
    '霍亂',
    '急性A肝',
    'Calprotect',
    'H. pylori Ag',
    'HP Ag',
    'H.pylori',
    '腸病毒',
    'CMV DNA-Q',
    'MTB-PCR',
    'Neut.Fat',
  ],
  Sputum: [
    'WBC',
    'Ep.Cell',
    'Gram Stain',
    'AFB Stain',
    'Chess Mass',
    'Dittr.Plug',
    'Fungi',
    'RBC',
    'Color',
    'Parasite',
    'Curshman`s',
    'C-L Cryst.',
    'Bron. Cast',
    'H-F Cell',
    'AFB  Stain',
    'Lung Stone',
    'Clarity',
    'Appearance',
    'Bron.Stone',
    'RSV Ag',
    'MTB-PCR',
    'P.jirove-q',
    'Pigm Cell',
    'Value',
    'MTB PCR',
    'Aspergi(q)',
    'MTBc',
    'HSV',
    'P.jiroveci',
    'Rif',
    'CMV',
    'M.pneumoni',
    'C.pneumoni',
    'CMV DNA-Q',
    'Inf A PCR',
    'RSV PCR',
    'Inf B PCR',
    'TBGenotype',
    'Covid-19',
    'MTBC PCR',
    'MTBc-RIFR',
    'FungiStain',
    'Bron.Cast',
    'H-F cell',
    'C-L Cryst',
    'MTBC',
    'MAComplex',
    'P.jirov-G',
    'COVID-19',
    'Legione Ag',
    'TB',
    'PJP',
    '武漢肺炎',
    'CDC-Sputum',
    'C.trachoAg',
    'MTBC-RIFR',
    'Pn.carinii',
    'VZV',
    'EBV DNA(Q)',
    'Asp.sppPCR',
    'IndiaStain',
    'InflBrtPCR',
    'InflArtPCR',
    '流感重症',
    'CMV PCR',
    'C. tracho.',
    'Giemsa',
    'Inf.A-H1N1',
    '2019-nCoV',
    'Parvo B19',
    'Chlamyd.Ag',
    'Adenovirus',
    'Rif. resistance',
    'India Stai',
    'Toxo.godni',
    'M.pneu PCR',
    'CMV-PCR',
    'Inf.B',
    'Inf.A-H1',
    '其他類',
    'SARS-CoV2',
    'CMV-PCR(定性)',
    'Inf.A-H3',
  ],
  'Pleural fluid': [
    'WBC',
    'RBC',
    'Color',
    'WBC Count',
    'LDH',
    'Clarity',
    'GLU',
    'T P',
    'Lymphocyte',
    'Monocyte',
    'N.seg.',
    'Gram Stain',
    'Eosinophil',
    'Macrophage',
    'Basophil',
    'N.band',
    'AFB Stain',
    'Meso.cell',
    'pH-pHMeter',
    'Lympho.',
    'Mono.',
    'PMNs',
    'Meso. cell',
    'TP',
    'AFB  Stain',
    'ADA',
    'pH by pH M',
    'Appearance',
    'Plasma cell',
    'GLO',
    'ALB-BCG',
    'Mesothelia',
    'Specific G',
    'MTB-PCR',
    'Value',
    'ALB',
    'TG',
    'Ht',
    'TCH',
    'FungiStain',
    'Hb',
    'AMY',
    'MTBc',
    'IndiaStain',
    'CD38/45',
    'CD56/45',
    'CD19/45',
    'CD11b/45',
    'MTBC PCR',
    'CD33/45',
    'CD13/45',
    'HLADR/45',
    'CD5/45',
    'TBI',
    'CD34/45',
    '19/K/45',
    'CD20/45',
    '19/L/45',
    'CD2/45',
    'FMC7/45',
    'CD10/45',
    'Lipase',
    'CD22/45',
    'CD23/45',
    'MTB PCR',
    'CRE',
    'K',
    'CEA',
    '19/45/34',
    'Ca',
    'Aspergi(q)',
    'Na',
    'Sp. Gr.',
    'Cl',
    'DBI',
    '56/45/34',
    'MAComplex',
    'CD7/45',
    'CD8/45',
    'CD4/45',
    'P.jirove-q',
    'CD3/45',
    'CMV DNA-Q',
    'HSV',
    'BUN',
    'CMV',
    'pH',
    'Rif',
    'MCV',
    'Baso.',
    'MCH',
    'Lym.',
    'MTBC',
    'MCHC',
    'LDL-C',
    'VZV',
    'Crypto. Ag',
    'M.pneumoni',
    'MTBc-RIFR',
    'Homogeneou',
    'PL',
    'EBV DNA(Q)',
    'Ova Direct',
    'SCC',
    'Centromere',
    'Eosin.',
    'Osmo.',
    'Granular',
    'R F',
    'Nucleolar',
    'Speckled',
    'Parvo B19',
    'Toxo.godni',
    'HDL-C',
    'Ova Conc.',
    'ALP',
    'GPT/ALT',
    '38/cK/45',
    '45/10',
    'CK-MB',
    'HCO3',
    'SBC',
    'CD1a/45',
    '45/19/k',
    '45/13',
    '45/11b',
    '45/23',
    'Lactate',
    '38/cL/45',
    '45/38',
    'I P',
    'pCO2',
    'India Stai',
    '38/cG1/45',
    '45/20',
    '45/FMC-7',
    'CA 125',
    'ABE',
    'CD25/45',
    'Amoeba',
    'BEecf',
    'pO2',
    'O2 sat.',
    '45/19/L',
    '45/34',
    'N.seg',
    'C K',
    '45/22',
    '45/56',
    'ANA',
    '45/5',
    '45/33',
    '45/DR',
    'TCO2',
    'Eosin',
    'Adenovirus',
    'C 3',
    'CRP',
    '45/19',
    'C 4',
    '45/2',
  ],
  CSF: [
    'WBC',
    'RBC',
    'Color',
    'RBC Morph.',
    'GLU',
    'Lymphocyte',
    'WBC Count',
    'Eosinophil',
    'Monocyte',
    'N.seg.',
    'Basophil',
    'Macrophage',
    'Epend.cell',
    'N.band',
    'Gram Stain',
    'Appearance',
    'TP-CSF',
    'Clarity',
    'Value',
    'AFB Stain',
    'Crypto. Ag',
    'TP(CSF\\U)',
    'WBC count',
    'Xanthochro',
    'RBC count',
    'TP(CSF)',
    'LDH',
    'IndiaStain',
    'Lympho.',
    'Mono.',
    'Lactate',
    'PMNs',
    'HSV',
    'Plasma cell',
    'A1-Glo',
    'Alb',
    'B-Glo',
    'A2-Glo',
    'G-Glo',
    'MTB-PCR',
    'Ig G',
    'Mesothelia',
    'HSV 1 IgG',
    'HSV 2 IgM',
    'HSV 1 IgM',
    'HSV 2 IgG',
    'VZV IgG',
    'VZV IgM',
    'FungiStain',
    'ALB',
    'VZV',
    'CMV',
    'TP',
    'TPPA',
    'Oligo band',
    'VDRL-CSF',
    'Prealbumin',
    'Pre Alb',
    'MTBC PCR',
    'AFB  Stain',
    'MTB PCR',
    'EBV DNA(Q)',
    'M-protein',
    'T.pallidum',
    'India Stai',
    'CRE',
    'Titer',
    'CMV DNA-Q',
    'Rif',
    'FTA-ABS',
    'Dark-field',
    'T P',
    'EBV DNA',
    'MAComplex',
    'C sporid-q',
    'RPR',
    'MTBc',
    'Toxo.godni',
    'Basophi',
    'Ig A',
    'Ig M',
    'EV 71',
    'JCV DNA',
    'pH-pHMeter',
    'Aspergi(q)',
    'BKV DNA',
    'CD19/45',
    'CD5/45',
    'Pan-EV',
    'JEV',
    'STS-RPR',
    'HLADR/45',
    'N.meninigi',
    'CD10/45',
    'CD34/45',
    'value',
    'MTBC',
    'CD11b/45',
    'CD20/45',
    'CD7/45',
    'CD56/45',
    '19/L/45',
    'CD38/45',
    'CD33/45',
    '19/K/45',
    '19/45/34',
    'CD13/45',
    'TPHA',
    'Lept-ntPCR',
    'HSV1IgM',
    'HSV2 IgM',
    '日本腦炎',
    '56/45/34',
    'CD22/45',
    'HSV PCR',
    'EV',
    'PreAlb',
    'CD4/45',
    'Adenovirus',
    'Lepto PCR',
    'VDRL',
    'CD2/45',
    'P.jirove-q',
    'CMV IgG',
    'CD8/45',
    'CMV IgM',
    'BCR/abl(q)',
    'CD23/45',
    'Cryp. neo',
    'IgG',
    'E. coliK1',
    'CD117/45',
    'Parecho',
    'L. mono',
    'Cryp. gat',
    'S. agala',
    'CD3/45',
    'Parvo B19',
    'HSV 1',
    'CD16/45',
    'HSV 6',
    'N. mening',
    'H. influ',
    'S. pneu',
    'HSV 2',
    'VZV-PCR',
    'VDRL(CSF)',
    'CD14/45',
    'Cl',
    'CDC-CSF',
    'FLC',
    'ABN',
    'CD36/45',
    'HSV1+2 IgM',
    'CD64/45',
    'C.pneumoni',
    'FMC7/45',
    'M.pneumoni',
    'HTLV 1+2Ab',
    '腸病毒',
    'AMY',
    'S.pneu. Ag',
    'AFP',
    'HIV-1 RNA',
    'B-HCG',
    'CMV-PCR(定性)',
    'M peak',
    'CD184/45',
    'A.canto.Ab',
    'S. pneum.',
    'Osmo.',
    'FKLC',
    '萊姆病',
    'FK/FLratio',
    'Lept.IgG+M',
    'Toxo. Ab',
    'Na',
    'K',
    'ADA',
    'Fungi Stai',
    'Giemsa',
    'CJD',
    'Parvovirus B19',
    'EV71IgM-R',
    'U A',
    'EV71IgM-E',
    '其他類',
    'FLLC',
    'Iso. Virus',
  ],
  Ascites: [
    'Color',
    'RBC',
    'WBC',
    'WBC Count',
    'Clarity',
    'Lymphocyte',
    'Monocyte',
    'N.seg.',
    'Eosinophil',
    'Macrophage',
    'Basophil',
    'N.band',
    'AMY',
    'Meso.cell',
    'Gram Stain',
    'T P',
    'LDH',
    'AFB Stain',
    'ALB',
    'GLU',
    'Mono.',
    'Lympho.',
    'PMNs',
    'Appearance',
    'Meso. cell',
    'CRE',
    'TG',
    'Mesothelia',
    'Lipase',
    'GLO',
    'ALB-BCG',
    'Plasma cell',
    'TBI',
    'DBI',
    'BUN',
    'AFB  Stain',
    'Hb',
    'pH-pHMeter',
    'ADA',
    'Ht',
    'TCH',
    'MTB-PCR',
    'TP',
    'Value',
    'FungiStain',
    'K',
    'CEA',
    'ALP',
    'MTB PCR',
    'Sp. Gr.',
    'Na',
    'CD5/45',
    'CD19/45',
    'OB',
    'CD11b/45',
    'CD34/45',
    '19/K/45',
    '19/L/45',
    'CD13/45',
    'CD20/45',
    'CD33/45',
    'CD2/45',
    'CD56/45',
    'HLADR/45',
    'CD38/45',
    'CD23/45',
    'CD22/45',
    'ALT (GPT)',
    'I P',
    'CD10/45',
    'AST (GOT)',
    'FMC7/45',
    '19/45/34',
    'Rif',
    'CA199',
    'Cl',
    'GOT/AST',
    'Entameba h',
    'CA 19-9',
    'Entameba',
    'GPT/ALT',
    'pH by pH M',
    'Osmo.',
    'U A',
    '56/45/34',
    'Ova Conc.',
    'Specific G',
    'CD1a/45',
    'Eosin.',
    'Ova Direct',
    'IndiaStain',
    'Lym.',
    'LDL-C',
    'Ca',
    'A2-Glo',
    'CA 125',
    'Mg',
    'G-Glo',
    'B-Glo',
    'Alb',
    'Baso.',
    'A1-Glo',
    '45/DR',
    '45/5',
    '45/13',
    'Aty.Lym.',
    'Meso.Cell',
    'NH3',
    '45/19/k',
    '45/11b',
    'pH',
    '45/23',
    'CD8/45',
    'Crypto. Ag',
    '45/34',
    'B-HCG',
    'Amoeba',
    'AFP',
    '45/FMC-7',
    'CD4/45',
    'HSV',
    '45/19',
    '45/38',
    'CD7/45',
    '45/33',
    'Amoeba IHA',
    'N.RBC',
    'M.pneumoni',
    '45/19/L',
    'HDL-C',
    '45/22',
    'CD25/45',
    'Aspergi(q)',
    '45/20',
    '45/2',
    'P.jirove-q',
    '45/10',
    '45/56',
  ],
  PBSC: [
    'WBC',
    'Hb',
    'MCV',
    'MCH',
    'MCHC',
    'Ht',
    'RBC',
    'Mono.',
    'Lym.',
    'Eosin.',
    'Baso.',
    'N.seg.',
    'Myelo.',
    'N.band',
    'Meta.',
    'Promyelo.',
    'N.RBC',
    'CD34',
    'PLT',
    'Aty.Lym.',
    'Blast',
    'PL',
    'MPV',
    'P-LCR',
    'PDW',
    'Monocytoid',
    'RDW-CV',
    'N.seg',
    'CD45+Total leukocyte',
    'CD34+ Count',
    '%CD34+',
    'CD38/45',
    'HLADR/45',
    'CD19/45',
    'CD5/45',
    'CD56/45',
    'CD11b/45',
    'CD33/45',
    'CD19',
    'CD8',
    'CD13/45',
    'CD16+56',
    'CD34/45',
    'CD2/45',
    'Lym',
    'CD3',
    'CD4',
    '19/K/45',
    '19/L/45',
    'CD4/45',
    'CD1a/45',
    '19/45/34',
    'CD4/CD8',
    '56/45/34',
    'CD20/45',
    'FMC7/45',
    'CD22/45',
    'CD8/45',
    'CD7/45',
    'CD23/45',
    'CD10/45',
    'CD3/45',
    'CD25/45',
    '38/cG1/45',
    '38/cK/45',
    '38/cL/45',
  ],
  'Cord blood': [
    'ABO Type',
    'Rh D',
    'Ht',
    'Hb',
    'MCV',
    'PL',
    'WBC',
    'MCHC',
    'RBC',
    'MCH',
    'P-LCR',
    'PDW',
    'RDW-CV',
    'MPV',
  ],
  Human: ['EKG(Rest)', 'Diff.', 'Result', 'T30 min', 'T0 min', 'CDC-Info'],
  'Bone marrow': [
    'CD5/45',
    'CD19/45',
    'CD38/45',
    'HLADR/45',
    'CD56/45',
    'CD11b/45',
    'CD33/45',
    'CD13/45',
    'CD34/45',
    '19/45/34',
    '56/45/34',
    'CD7/45',
    'CD16/45',
    'CD14/45',
    'CD36/45',
    'CD117/45',
    'CD64/45',
    '19/K/45',
    '19/L/45',
    'CD10/45',
    'CD20/45',
    'CD22/45',
    'CD2/45',
    'FMC7/45',
    'CD23/45',
    'CD184/45',
    'FLT3∕ITD',
    'NPM1DNA(q)',
    'CD4/45',
    'CD8/45',
    'Chromosome',
    'CD3/45',
    'Peroxidase',
    '38/cL/45',
    '38/cG1/45',
    '38/cK/45',
    '45/DR',
    '45/5',
    '45/11b',
    '45/19',
    'JAK2-ss',
    '45/56',
    '45/38',
    '45/33',
    '45/13',
    '138/38/cL/',
    '138/38/cK/',
    '45/7',
    '45/34',
    '71/GlyA/45',
    '138/38/56/',
    '45/2',
    'Iron Stain',
    'STR DNA',
    'FLT3-D835',
    'EBV DNA(Q)',
    'PML-RARA',
    'Sp.Ease',
    'N-Sp Ease',
    '45/16',
    '45/117',
    '45/14',
    'BCR/abl(q)',
    '45/15',
    'MCHC',
    'WBC',
    'MCV',
    'Ht',
    'MCH',
    'Hb',
    'RBC',
    'Eosin.',
    'Lym.',
    'N.seg.',
    'Baso.',
    'N.RBC',
    'Mono.',
    'N.band',
    'Meta.',
    'Myelo.',
    'PLT',
    'Promyelo.',
    'CD25/45',
    'MPV',
    'Blast',
    'CD1a/45',
    'RDW-CV',
    '45/19/k',
    'PDW',
    'P-LCR',
    '45/19/L',
    'Aty.Lym.',
    'PAS Stain',
    '45/FMC-7',
    'BCR∕abl(q)',
    '45/10',
    '45/22',
    '45/20',
    '45/23',
    'BCR/abl(Q)',
    'AFB stain',
    'CD34',
    '45/8',
    '45/4',
    'BCR-ABL(Q)',
    '45/3',
    'PML-RARα',
    'NPM1(quali',
    'CD19',
    'CD14',
    'CD10',
    'CD3',
    'HLA-DR',
    'CD20',
    'CD5',
    'CD33',
    'CD34+ Count',
    'CD11b',
    '%CD34+',
    'CD45+Total leukocyte',
    'CD7',
    'CD13',
    'CD56',
    'FLT3/ITD',
    'CD16',
    '45/38/cK',
    '45/38/cL',
    'Monocytoid',
    '45/36',
    'CMV DNA-Q',
    'Parvo B19',
    '45/38/cG1',
    'AFB  Stain',
    'P53',
    'Aspergi(q)',
    'NPM1',
    'MTB-PCR',
    'MAF',
    'FGFR3',
    '45/gly/71',
    'NPM',
    'Gram Stain',
    'CD19∕CD5',
    'MTBC PCR',
    'JAK2',
    'CD15/45',
    '45/25',
    'Toxo.godni',
    'PL',
    'ABLkd mut.',
    'CD23',
    'CD8',
    'FMC7',
    'cMPO/45',
    'chromosome',
    'CD19/CD5',
    'BCR∕abl(Q)',
    '45/1a',
    'CD25',
    'CD4',
    '19/5/45',
    'BCR/ABL',
    'CD38',
    'CD33/41/45',
    'CD33/59/45',
    'RBC/59',
    '33/42b/45',
    'α-Tha. SEA',
    '45/cTdT',
    '138/38/cL',
    '45/cMPO',
    'CD19∕K',
    'BCR∕ABL',
    'CMV',
    '45/11c',
    'CD33/55/45',
    'CD33/61/45',
    '19/103/45',
    'BCR-ABL rt',
    'CD19∕L',
    'alpha SEA',
    'CD2',
    '138/38/cK',
    'MAComplex',
    'RBC/GlyA',
    '103/45',
    'RBC/55',
    '19/11c/45',
  ],
  'Urine-24hrs': [
    'CRE(Urine)',
    'Urine Vol.',
    'CCr',
    'TP-Urine',
    'CRE',
    'TP/CRE',
    'VMA',
    'Norepineph',
    'Dopamin',
    'Epinephrin',
    'BUN',
    'K',
    'Cu',
    'Na',
    'Osmo.',
    'Ca',
    'Cortisol',
    'Urine',
    '5-HIAA',
    'Cl',
    'Osmo.-U',
    'Total vol.',
    'U A',
    'I P',
    'ALB',
    'ALB/CRE',
    '17-KS',
    '17-OHCS',
    'GLU',
    'δ-ALA',
    'Mg',
    'AMY',
    'Aldo.',
    'Lipase',
  ],
  'Blood-Venous': ['TCO2', 'O2 sat.', 'pH', 'pO2', 'SBC', 'pCO2', 'HCO3', 'BEecf', 'ABE'],
  Nasopharyngeal: [
    'flu.A',
    'flu.B',
    'Influ B Ag',
    'Influ A Ag',
    'Flu B-NAT',
    'Flu A-NAT',
    'Adeno Ag',
    'RSV',
    'InflArtPCR',
    'InflBrtPCR',
    'Flu A Ag',
    'Flu B Ag',
    'COVID-19',
    'RSV Ag',
    'NAT Flu A',
    'NAT Flu B',
    'C. P',
    'Para.flu3',
    'CoV.NL63',
    'Flu A',
    'CoV.229E',
    'Para.flu1',
    'Para.flu2',
    'Para.flu4',
    'Adeno',
    'Metapneumo',
    'CoV.OC43',
    'B. P',
    'M. P',
    'CoV.HKU1',
    'Flu B',
    'HSV',
    'M.pneumoni',
    'C.pneumoni',
    'Aspergi(q)',
    'CMV',
    'P.jirove-q',
    'EV',
    'Rhino',
    'RSV PCR',
    'Rhino∕EV',
    'Inf B PCR',
    'Inf A PCR',
    'Inf.A-H1N1',
    'Covid-19',
    'InfAB PCR',
    'influA PCR',
    'influB PCR',
    'AFB Stain',
    'InfB PCR',
    'InfAPCR',
  ],
  'Gastric juice': [
    'OB',
    'Color',
    'Clarity',
    'Appearance',
    'APT test',
    'APT',
    'Apt test',
    'AFB Stain',
    'pH-pHMeter',
    'pH by pH M',
    'AFB  Stain',
  ],
  'Synovial fluid': [
    'WBC',
    'WBC Count',
    'Color',
    'RBC',
    'Clarity',
    'N.seg.',
    'Crystals',
    'Lymphocyte',
    'Monocyte',
    'Eosinophil',
    'Gram Stain',
    'Basophil',
    'Macrophage',
    'Synov.cell',
    'N.band',
    'AFB Stain',
    'UA Crystal',
    'PPDCrystal',
    'PMNs',
    'Appearance',
    'Mono.',
    'Lympho.',
    'Crystal-PM',
    'Plasma cell',
    'GLU',
    'FungiStain',
    'Mesothelia',
    'AFB  Stain',
    'MTB PCR',
    'LDH',
    'R F',
    'pH-pHMeter',
    'T P',
    'MTBC PCR',
    'ALB-BCG',
    'GLO',
    'Ht',
    'U A',
    'Rif',
    'Hb',
    'RF-tota',
    'TCH',
    'Eosin.',
    'IndiaStain',
    'TG',
    'MAComplex',
    'Baso.',
    'Lym.',
  ],
  'Throat swab': [
    'Flu B Ag',
    'Flu A Ag',
    'Adeno Ag',
    'Influ B Ag',
    'Influ A Ag',
    'Flu A-NAT',
    'Flu B-NAT',
    'InflArtPCR',
    'InflBrtPCR',
    'Adenovirus',
    '流感重症',
    '武漢肺炎',
    'CDC-Swab',
    'COVID-19',
    'EV 71',
    'SARS-CoV2',
    'EV',
    'Inf A PCR',
    'Inf B PCR',
    'RSV PCR',
    'Mycoplasma Ag',
    'Covid-19',
    'EV GT',
    'Inf.A-H1N1',
    'Inf.B',
    'Inf.A-H3',
    'Inf.A-H1',
    'RSV Ag',
    'NAT Flu A',
    'NAT Flu B',
    'Gram Stain',
    'M.pneumoni',
    '2019-nCoV',
    'Pan-EV',
    '腸病毒',
    '2019 -nCoV',
    'SARS-CoV-2',
    '百日咳',
    'InfB PCR',
    '其他類',
    'VZV',
    'HSV',
    'Inf.A-H7N9',
    'Iso. Virus',
    'InfA PCR',
    '猩紅熱',
  ],
  'Urine-M. stream': ['S.pneu. Ag', 'L. pne. Ag', 'AFB Stain', 'Stre.B Ag', 'FungiStain'],
  'Wax block': [
    '檢體號碼',
    'Exon20插入',
    'L861Q',
    'L858R',
    'Exon19缺失',
    'T790M',
    'G719X',
    'S768I',
    'PD-L1',
    'ALK IHC',
    'NRAS/KRAS',
    'K-ras',
    'KRAS 12∕13',
    'ROS1 FISH',
    'ALK FISH',
  ],
  Vomit: [
    'Color',
    'OB',
    'Consisten.',
    'Consistency',
    'Appearance',
    'Clearity',
    'Consistenc',
    'APT test',
    'pH-pHMeter',
    'Apt test',
    'Paraquat',
  ],
  'Blood-Arterial': ['pH', 'HCO3', 'pCO2', 'BEecf', 'TCO2', 'ABE', 'SBC', 'O2 sat.', 'pO2'],
  'Bronchial washi': ['AFB Stain', 'Gram Stain', 'FungiStain', 'Aspergillu', 'value', 'MTB PCR', 'Rif', 'TB'],
  'Bronchial wash': [
    'AFB Stain',
    'Value',
    'Gram Stain',
    'MTB-PCR',
    'Aspergillus Ag',
    'AFB  Stain',
    'Color',
    'Macrophage',
    'Lymphocyte',
    'N.seg.',
    'Eosinophil',
    'Basophil',
    'Clarity',
    'WBC',
    'Meso. cell',
    'N.band',
    'Monocyte',
    'RBC',
    'P.jiroveci',
    'TotalWBC',
    'WBC count',
    'epithelium',
    'RBC  count',
    'Volum(o/i)',
    'Viability',
    'P.jirove-q',
    'Pn.carinii',
    'Aspergi(q)',
    'Fungi Stai',
    'India Stai',
    'CMV-PCR(定性)',
    'PJP',
    'M.pneumoni',
    'VZV',
    'CMV',
    'FungiStain',
    'Asp.sppPCR',
    'CMV PCR',
    'MTBC PCR',
    'HSV',
    'Ct value',
    'Aspergil',
    'MAComplex',
    'Toxo.godni',
    'CMV DNA-Q',
    'C.pneumoni',
    'P.jirov-G',
    'CMVpp67',
  ],
  'Pleural effusio': [
    'AFB Stain',
    'Gram Stain',
    'PMNs',
    'Mono.',
    'MTB PCR',
    'Lympho.',
    'Rif',
    'WBC Count',
    'Clarity',
    'Color',
    'WBC',
    'RBC',
    'Eosinophil',
    'Basophil',
    'Mesothelia',
  ],
  Dialysate: [
    'Clarity',
    'RBC',
    'Color',
    'WBC',
    'WBC Count',
    'Monocyte',
    'N.seg.',
    'Lymphocyte',
    'Meso. cell',
    'Eosinophil',
    'Macrophage',
    'Plasma cell',
    'N.band',
    'Basophil',
    'AFB Stain',
    'CRE 0 hr',
    'Gram Stain',
    'CRE',
    'BUN',
    'GLU 0 HR',
    'GLU',
    'GLU 4 HR',
    'CRE 2 hr',
    'CRE 4 hr',
    'GLU 2 HR',
    'TP-Dialysa',
    'Value',
    'MTB-PCR',
    'GLU0HR',
    'Rif. resistance',
  ],
  Stone: [
    'Ca Oxalate',
    'Uric Acid',
    'Na Urate',
    'Ammo.Urate',
    'Cysteine',
    'Ca Carbo.',
    'Ca(HPO4)2',
    'Ca Phosphate CO3 For',
    'Ammonium Mg Phosphat',
    'Ca-P',
    'Protein(Calculi)蛋白',
    'Ca3(PO4)2',
    'Ca Stearat',
    'CHO',
    'Ca Bili.',
    'Ca.HPO4CO3',
    'Triple-P',
    'Ca Silicat',
    'Protein',
    'Ca.HPO4',
    'CaHPO4.2H2',
    'Ca CO2',
    'Ca Phosph.',
    '尿酸銨',
    '磷酸銨鎂',
    'Others',
    'Cystine',
    'Ca CO3',
    'Ca Phospha',
    'Sodium Ura',
    'Ca-P(Biliary)',
    'Calculi',
  ],
  'B.alveolar lav.': [
    'AFB Stain',
    'P.jirove-q',
    'HSV',
    'WBC count',
    'N.band',
    'Eosinophil',
    'Macrophage',
    'N.seg.',
    'RBC  count',
    'Appearance',
    'Meso.cell',
    'Color',
    'Monocyte',
    'Basophil',
    'Lymphocyte',
    'Aspergi(q)',
    'Gram Stain',
    'CMV DNA-Q',
    'M.pneumoni',
    'CMV',
    'index',
    'Aspergi.Ag',
    'MTBC PCR',
    'MAComplex',
    'C.pneumoni',
    'EBV DNA(Q)',
    'MTBc',
    'FungiStain',
    'Crypto. Ag',
    'MTBC',
    'IndiaStain',
    'P.jirov-G',
    'Toxo.godni',
    'CMVpp67',
    'Ct value',
    'VZV',
    'C. tracho.',
    'MTBc-RIFR',
    'MTBC-RIFR',
    'Parvo B19',
    'CD4',
    'Inf.A-H1N1',
    'CD19',
    'Adenovirus',
    'CD3',
    'InflArtPCR',
    'Flu B-NAT',
    'Lym',
    'Flu A-NAT',
    'InflBrtPCR',
    'CD8',
    'WBC',
    'CD4/CD8',
    'TBGenotype',
  ],
  'Blood-CVP': ['HCO3', 'BEecf', 'pCO2', 'O2 sat.', 'pH', 'pO2', 'TCO2', 'ABE', 'SBC'],
  'Urine-Catheter': ['S.pneu. Ag', 'L. pne. Ag', 'AFB Stain', 'Gram Stain', 'Stre.B Ag'],
  'Pericardi fluid': [
    'RBC',
    'WBC Count',
    'Lymphocyte',
    'WBC',
    'Color',
    'Monocyte',
    'Eosinophil',
    'Macrophage',
    'Basophil',
    'N.seg.',
    'N.band',
    'Meso.cell',
    'Clarity',
    'Gram Stain',
    'Appearance',
    'LDH',
    'GLU',
    'AFB Stain',
    'N.seg',
    'Mesothelia',
    'AFB  Stain',
    'T P',
    'ADA',
    'TP',
    'Ht',
    'Hb',
    'ALB',
    'TCH',
    'MTB-PCR',
    'ALB-BCG',
    'GLO',
  ],
  'Tissue stamp': ['AFB Stain', 'Gram Stain', 'FungiStain', 'AFB  Stain', 'Fungi Stai', 'Rif', 'MTB PCR'],
  Pus: [
    'AFB Stain',
    'Gram Stain',
    'AFB  Stain',
    'FungiStain',
    'Amoeba',
    'MTB-PCR',
    'Mono.',
    'Baso.',
    'Eosin.',
    'Lym.',
    'N.seg.',
    'Value',
    'N.band',
    'Hb',
    'Fungi Stai',
    'WBC',
    'MCV',
    'MCH',
    'Ht',
    'KOH Stain',
    'RBC',
    'MTBC PCR',
    'Liu Stain',
    'PL',
    'Ova Direct',
    'MCHC',
  ],
  'Lung aspiration': ['AFB Stain', 'Gram Stain', 'FungiStain', 'MTB PCR', 'Rif', 'IndiaStain', 'Aspergillu'],
  'Lymph node': [
    'CD19/45',
    'CD5/45',
    'CD38/45',
    'CD56/45',
    'HLADR/45',
    'CD11b/45',
    'CD33/45',
    'CD13/45',
    '19/L/45',
    'CD34/45',
    '19/K/45',
    'CD20/45',
    'CD22/45',
    'CD10/45',
    'CD2/45',
    'CD23/45',
    'FMC7/45',
    'CD3/45',
    'CD4/45',
    'CD7/45',
    'CD8/45',
    '19/45/34',
    '56/45/34',
    '38/cL/45',
    '38/cK/45',
    '38/cG1/45',
    'AFB  Stain',
    '138/38/cK/',
    '138/38/cL/',
    'CD64/45',
    'CD14/45',
    'CD16/45',
    'CD117/45',
    '138/38/56/',
    'CD36/45',
    'CD25/45',
    'MTBc',
    'CD1a/45',
    'CD184/45',
    'B. hensela',
    'MAComplex',
  ],
  Wound: ['AFB Stain', 'Gram Stain', 'AFB  Stain', 'Rif', 'MTB PCR', 'FungiStain', 'Fungi Stai'],
  'Parafin tissue': [
    'KRAS',
    'EGFR',
    'NRAS',
    'BRAFco.600',
    'EGFRgene',
    'PD-L1_22C3',
    'PD-L1 IHC',
    'PD-L1_28-8',
    'ALK-IHC',
    'PDL1_SP142',
    'ROS1',
    'HER-2/neu',
    'MSI',
    'ALK',
    'HER-2∕neu',
    'CDx 324',
    'RScore',
    'ACTonco440',
  ],
  'WBC conc.': [
    'WBC',
    'CD19',
    'CD16+56',
    'CD3',
    'CD4',
    'Lym',
    'CD8',
    'PLT',
    'N.seg.',
    'Baso.',
    'Mono.',
    'MCV',
    'MCHC',
    'Ht',
    'Eosin.',
    'Lym.',
    'Hb',
    'RBC',
    'MCH',
    'MPV',
    'PDW',
    'P-LCR',
    'CD4/CD8',
    'Aty.Lym.',
    'N.band',
    'N.RBC',
    'Monocytoid',
    'Myelo.',
    'Meta.',
    'RDW-CV',
    'Promyelo.',
    'Blast',
  ],
  'Urine-Treated': ['S.pneu. Ag', 'L. pne. Ag', 'AFB Stain', 'FungiStain', 'Gram Stain'],
  'Sputum-trachea': ['Gram Stain', 'WBC', 'Ep.Cell', 'AFB Stain', 'FungiStain'],
  'Pericardical fl': [
    'AFB Stain',
    'Clarity',
    'RBC',
    'Color',
    'WBC Count',
    'WBC',
    'Lympho.',
    'Mono.',
    'PMNs',
    'T P',
    'LDH',
    'Na',
    'K',
    'Cl',
    'Gram Stain',
    'Ca',
    'GLU',
    'Mesothelia',
    'Eosinophil',
    'Basophil',
    'pH-pHMeter',
  ],
  'Tumor aspiratio': ['AFB Stain', 'Gram Stain'],
  others: ['AFB Stain', '蟯蟲卵', 'Gram Stain', 'MTB PCR', '霍亂', '猩紅熱', 'APT', 'NDM-1', 'H.influe.b', 'Rif'],
  Bile: ['Gram Stain', 'TBI', 'AMY', 'DBI', 'AFB Stain', 'Lipase', 'CA 19-9', 'CEA', 'Ca', 'FungiStain', 'K', 'CA199'],
  Discharge: [
    'PMNs',
    'RBC',
    'WBC',
    'MoNs',
    'WBC Count',
    'Color',
    'Appearance',
    'Gram Stain',
    'Clarity',
    'Chl. trachomatis Ag',
    'FungiStain',
    'AFB Stain',
    'Chlamyd.Ag',
    'Bacteria',
    'Fungus',
    'Chlamydia',
    'Trichmonas',
  ],
  Tissues: [
    'AFB Stain',
    'MTB PCR',
    'Rif',
    'MTBC PCR',
    'MTBc',
    'MAComplex',
    'Toxo.godni',
    'Aspergi(q)',
    'B. hensela',
    'Lept-ntPCR',
    'MTBc-RIFR',
    'HPV DNA',
    'CD10/45',
    'CD56/45',
    'CD22/45',
    'CD19/45',
    'CD11b/45',
    'CD38/45',
    'HLADR/45',
    '19/L/45',
    'CD23/45',
    'MTBC',
    'CD33/45',
    'Nei. gono.',
    'CD13/45',
    'CD20/45',
    'FMC7/45',
    '19/K/45',
    'CD5/45',
    '19/45/34',
    'C. tracho.',
    'CD2/45',
    'CD34/45',
    '56/45/34',
  ],
  Tissue: ['MTB-PCR', 'Value'],
  'Nasal aspirate': [
    'Influ B Ag',
    'Influ A Ag',
    'Inf B PCR',
    'Inf A PCR',
    'RSV PCR',
    'Covid-19',
    'Flu A Ag',
    'Flu B Ag',
  ],
  'Body fluid': [
    'MTB-PCR',
    'Value',
    'AFB Stain',
    'MTBC PCR',
    'Ht',
    'Hb',
    'MTB PCR',
    'Rif',
    'Gram Stain',
    'CDC-B flui',
    'MAComplex',
    'N.band',
    'Lym.',
    'N.seg.',
    'Mono.',
  ],
  'Eye discharge': [
    'Gram Stain',
    'AFB Stain',
    'Adenovirus',
    'FungiStain',
    'Chlamydia',
    'Chlamyd.Ag',
    'Chl. trachomatis Ag',
    'HSV',
  ],
  Vaginal: [
    'C.trachoAg',
    'Trichmonas',
    'Gram Stain',
    'WBC',
    'Sperm',
    'Ep. Cell',
    'Clue Cells',
    'RBC',
    'Bact.',
    'Nei. gono.',
    'FungiStain',
    'Chlamyd.Ag',
    'HPV DNA',
    'HSV',
    'C. tracho.',
    'Amine test',
    'AFB Stain',
    'Fungi',
  ],
  'Drain tube': ['Gram Stain', 'AFB Stain', 'AFB  Stain', 'FungiStain'],
  'Lung aspirate': [
    'AFB Stain',
    'Gram Stain',
    'AFB  Stain',
    'MTB-PCR',
    'N.band',
    'Lym.',
    'N.seg.',
    'Eosin.',
    'CMV DNA-Q',
    'MTBC PCR',
    'Baso.',
    'Mono.',
  ],
  Skin: ['KOH stain', 'KOH Stain'],
  'Rectal swab': ['EV 71', 'EV', 'EV GT', 'Pan-EV', '腸病毒'],
  'Renal cyst': [
    'Gram Stain',
    'Oth.',
    'AFB Stain',
    'RBC',
    'WBC',
    'Cast',
    'Bact.',
    'Ep. Cell',
    'OB',
    'Clarity',
    'Crystals',
    'PRO',
    'Mucus',
    'NIT',
    'URO',
    'Parasites',
    'BIL',
    'GLU',
    'pH',
    'Color',
    'Fungi',
    'WBCesteras',
    'AMY',
    'BUN',
    'Sp.Gr.',
    'CRE',
    'KET-AA',
    'Sp. Gr.',
    'Leuko',
    'Ep.Cell',
    'KET',
  ],
  'Urine-PCN': ['Gram Stain', 'AFB Stain', 'S.pneu. Ag', 'L. pne. Ag'],
  'Aqueous humor': ['VZV', 'HSV', 'CMV', 'EBV DNA(Q)', 'CMV DNA-Q', 'Toxo.godni', 'T.pallidum', 'Aspergi(q)'],
  'Pericardial eff': ['AFB Stain', 'Gram Stain'],
  Semen: [
    'Clarity',
    'Color',
    'RBC',
    'WBC',
    'Morph.(N)',
    'pH',
    'Sperm No',
    'Volume',
    'Lique Time',
    'Motility',
    'Motile',
    'Grade 2',
    'Grade 1',
    'Grade 3',
  ],
  'Liver abscess': ['Entameba', 'Amoeba', 'Entameba h', 'Gram Stain', 'Ova Conc.'],
  Saliva: ['F-Cortisol'],
  'Cyst fluid': ['AMY', 'Lipase', 'MAComplex', 'Aspergi(q)', 'MTBC PCR'],
  'Urine-Strai.cat': ['S.pneu. Ag', 'L. pne. Ag'],
  swab: ['Mycoplasma Ag'],
  Biopsy: ['EGFR gene'],
  PCN: ['AFB Stain', 'Gram Stain'],
  'Bronchial brush': ['AFB Stain', 'FungiStain'],
  Urethral: ['Bact.', 'RBC', 'WBC', 'Ep. Cell', 'Sperm', 'C. tracho.', 'Gram Stain', 'Nei. gono.'],
  'Vesicular fluid': ['VZV', 'HSV'],
  FFPE: ['CDx 324'],
  RBC: ['CHS', 'CHS(RBC)'],
  'Cervical Sample': [
    'Gono.RNA',
    'RLU',
    'HPV type18',
    'Cut-off',
    'HPV DNA',
    'other type',
    'Ratio',
    'HPV type16',
    'C.trac RNA',
  ],
  'Fresh tissue': ['EGFR', 'JAK2-ss', 'FLT3∕ITD', 'NPM1DNA(q)', 'C. tracho.', 'EBV DNA(Q)'],
  'Amniotic fluid': ['AFB Stain', 'GW', 'AFP'],
  'Tumor aspirate': ['AFB Stain', 'AFB  Stain', 'DBI', 'IndiaStain', 'Gram Stain', 'TBI'],
  'Pancreas juice': ['CEA', 'CA 19-9'],
  '': ['AFB Stain'],
  'CVP line': ['AFB Stain', 'Gram Stain'],
  'Genital dischar': ['Chlamyd.Ag', 'Chl. trachomatis Ag'],
  'Cervical swab': ['HPV type16', 'HPV type18', 'other type'],
  Cervical: ['HPV'],
  'Rectal Swab': ['霍亂', '腸病毒'],
  'Anterior chambe': ['CMV PCR', 'Value'],
  'R.Pleural fluid': ['AFB  Stain', 'ADA'],
  'Urine-Cystoscop': ['L. pne. Ag', 'S.pneu. Ag'],
  Nasopharynge: ['flu.A', 'flu.B'],
  'Cyst  fluid': ['AMY'],
  Plasma: ['CHS(Plasma'],
  'Unknown drug': ['Toxicology'],
  'L.Pleural fluid': ['ADA'],
  'Genital discha.': ['HSV PCR'],
};
