import { cloneDeep } from 'lodash';
import { EVENT } from '@/utils/constants';
import { DateUnit, EventStage } from '@/utils/conditions/core/Interface';
import { getTimeRangeInYear } from '@/utils/conditions/TimeDef';
import { getUiConfig } from '@/utils/uiConfig';

const defaultConditionAndConstraint = {
  constraintList: [],
  constraint: {
    firstOccurrence: true,
  },
  condition: [],
};

const defaultStateWithoutFirstOccurrence = {
  constraintList: [],
  constraint: {
    firstOccurrence: false,
  },
  condition: [],
};

const inExclusionList = {
  inclusionList: [],
  exclusionList: [],
};

const defaultFirstEvent = {
  ...cloneDeep(defaultConditionAndConstraint),
  relation: {
    beforeValue: 'any',
    beforeUnit: DateUnit.DAYS,
    afterValue: '0',
    afterUnit: DateUnit.DAYS,
  },
  name: EVENT.DEFAULT_NAME.POPULATION,
  eventStage: EventStage.COHORT,
  ...cloneDeep(inExclusionList),
};

const defaultThirdEvent = {
  ...cloneDeep(defaultConditionAndConstraint),
  relation: {
    beforeValue: '0',
    beforeUnit: DateUnit.DAYS,
    afterValue: 'any',
    afterUnit: DateUnit.DAYS,
  },
  name: EVENT.DEFAULT_NAME.THIRD_EVENT,
  eventStage: EventStage.OUTCOME,
  ...cloneDeep(inExclusionList),
};

const defaultIndexEvent = {
  ...cloneDeep(defaultConditionAndConstraint),
  name: EVENT.DEFAULT_NAME.INTERVENTION,
  eventStage: EventStage.INTERVENTION,
  ...cloneDeep(inExclusionList),
};

const defaultCovariantEvent = {
  ...cloneDeep(defaultConditionAndConstraint),
  name: 'COVARIANT1',
  eventStage: 'COVARIANT1',
  ...cloneDeep(inExclusionList),
};

const defaultInclusionEvent = {
  ...cloneDeep(defaultConditionAndConstraint),
  relation: {
    beforeValue: 'any',
    beforeUnit: DateUnit.DAYS,
    afterValue: '0',
    afterUnit: DateUnit.DAYS,
  },
  name: EVENT.DEFAULT_NAME.INCLUSION_EVENT,
};

const defaultInclusionEventWithoutFirstOccurrence = {
  ...cloneDeep(defaultStateWithoutFirstOccurrence),
  relation: {
    beforeValue: 'any',
    beforeUnit: DateUnit.DAYS,
    afterValue: '0',
    afterUnit: DateUnit.DAYS,
  },
  name: EVENT.DEFAULT_NAME.INCLUSION_EVENT,
};

const defaultInclusionCovariantEvent = {
  ...cloneDeep(defaultStateWithoutFirstOccurrence),
  relation: {
    beforeValue: 'any',
    beforeUnit: DateUnit.DAYS,
    afterValue: '0',
    afterUnit: DateUnit.DAYS,
  },
  name: EVENT.DEFAULT_NAME.CORVARIANT,
  inclusionList: [],
  exclusionList: [],
};

const defaultInclusionOutcomeEvent = {
  ...cloneDeep(defaultStateWithoutFirstOccurrence),
  relation: {
    beforeValue: '-1',
    beforeUnit: DateUnit.DAYS,
    afterValue: 'any',
    afterUnit: DateUnit.DAYS,
  },
  name: EVENT.DEFAULT_NAME.INCLUSION_EVENT,
  inclusionList: [],
  exclusionList: [],
};

const defaultExclusionEvent = {
  ...cloneDeep(defaultConditionAndConstraint),
  relation: {
    beforeValue: 'any',
    beforeUnit: DateUnit.DAYS,
    afterValue: '0',
    afterUnit: DateUnit.DAYS,
  },
  name: EVENT.DEFAULT_NAME.EXCLUSION_EVENT,
};

const defaultExclusionEventWithoutFirstOccurrence = {
  ...cloneDeep(defaultStateWithoutFirstOccurrence),
  relation: {
    beforeValue: 'any',
    beforeUnit: DateUnit.DAYS,
    afterValue: '0',
    afterUnit: DateUnit.DAYS,
  },
  name: EVENT.DEFAULT_NAME.EXCLUSION_EVENT,
};

const defaultTimeSelectObject = {
  beforeValue: '0',
  beforeUnit: DateUnit.DAYS,
  afterValue: '6',
  afterUnit: DateUnit.MONTHS,
};

const defaultGenderList = [{ type: 'male' }, { type: 'female' }];

const defaultBodyMassList = [{ type: 'height' }, { type: 'weight' }, { type: 'BMI' }];

// defaultFirstEvent -> inclusionList/exclusionList -> false
const defaultExperimental = {
  timePeriod: getTimeRangeInYear(getUiConfig().SearchForm.time),
  eventList: [cloneDeep(defaultFirstEvent)],
  indexEvent: cloneDeep(defaultIndexEvent),
  genderList: cloneDeep(defaultGenderList),
  name: EVENT.DEFAULT_NAME.EXPERIMENTAL_GROUP,
  url: '',
};

// defaultFirstEvent -> inclusionList/exclusionList -> false
const defaultControl = {
  timePeriod: getTimeRangeInYear(getUiConfig().SearchForm.time),
  eventList: [cloneDeep(defaultFirstEvent)],
  indexEvent: cloneDeep(defaultIndexEvent),
  genderList: cloneDeep(defaultGenderList),
  name: EVENT.DEFAULT_NAME.CONTROL_GROUP,
  url: '',
};

const defaultCovariant = {
  inclusionList: [
    {
      ...cloneDeep(defaultInclusionCovariantEvent),
      name: `${EVENT.DEFAULT_NAME.CORVARIANT}1`,
    },
  ],
  ageList: [],
  genderList: cloneDeep(defaultGenderList),
  bodyMassList: cloneDeep(defaultBodyMassList),
};

const defaultOutcome = {
  inclusionList: [
    {
      ...cloneDeep(defaultInclusionOutcomeEvent),
      name: EVENT.DEFAULT_NAME.THIRD_EVENT,
    },
  ],
};

const defaultEventSearch = {
  experimental: cloneDeep(defaultExperimental),
  control: cloneDeep(defaultControl),
  covariate: cloneDeep(defaultCovariant),
  outcome: cloneDeep(defaultOutcome),
};

export {
  defaultFirstEvent,
  defaultThirdEvent,
  defaultIndexEvent,
  defaultCovariantEvent,
  defaultInclusionEvent,
  defaultExclusionEvent,
  defaultTimeSelectObject,
  defaultGenderList,
  defaultInclusionCovariantEvent,
  defaultInclusionOutcomeEvent,
  defaultEventSearch,
  defaultInclusionEventWithoutFirstOccurrence,
  defaultExclusionEventWithoutFirstOccurrence,
  defaultExperimental,
  defaultControl,
};
