import { defaultFirstEvent, defaultThirdEvent, defaultIndexEvent } from '@/utils/advance/initState';
import { cloneDeep } from 'lodash';
import { ISearchState, ITimeRelationEvent, IIndexEvent, RangeData } from '../Interface';

class SearchState implements ISearchState {
  readonly eventList: ITimeRelationEvent[];

  readonly indexEvent: IIndexEvent;

  readonly timePeriod: RangeData;

  genderList: any[];
  bodyMassList: any[];

  constructor(
    eventList: ITimeRelationEvent[] = [cloneDeep(defaultFirstEvent), cloneDeep(defaultThirdEvent)],
    indexEvent: IIndexEvent = cloneDeep(defaultIndexEvent),
    timePeriod: RangeData = {
      start: new Date().getFullYear() - 9,
      end: new Date().getFullYear(),
    }
  ) {
    this.eventList = eventList;
    this.indexEvent = indexEvent;
    this.timePeriod = timePeriod;
    this.genderList = [
      {
        type: 'female',
      },
      {
        type: 'male',
      },
    ];
    this.bodyMassList = [
      {
        type: 'height',
      },
      {
        type: 'weight',
      },
      {
        type: 'BMI',
      },
    ];
  }
}

export default SearchState;
