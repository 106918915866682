import { mapState } from 'vuex';

import { FEEDBACK } from '@/utils/constants';
import trackTemplate from '@/mixins/trackTemplate';

export default {
  mixins: [trackTemplate],
  computed: {
    ...mapState('user', ['userId']),
  },
  created() {
    window.addEventListener('beforeunload', async () => {
      await this.endTime(this.currentTab);
    });
  },
  methods: {
    startTime(name, extras = {}) {
      // add start time in page
      this.sendTimeFeedback({
        value: this.trackTimeTemplate(name, extras),
        type: 'start',
        mode: FEEDBACK.MODE_TYPE.RECORD,
        template: this.trackContentTemplate(),
      });
    },
    endTime(name, extras = {}) {
      // add end time in page
      this.sendTimeFeedback({
        value: this.trackTimeTemplate(name, extras),
        type: 'end',
        mode: FEEDBACK.MODE_TYPE.RECORD,
        template: this.trackContentTemplate(),
      });
    },
    advanceStartTime(value) {
      // add start time in page
      this.sendTimeFeedback({
        value,
        type: 'start',
        mode: FEEDBACK.MODE_TYPE.EVENT,
        template: this.advanceTrackContentTemplate(),
      });
    },
    advanceEndTime(value) {
      // add end time in page
      this.sendTimeFeedback({
        value,
        type: 'end',
        mode: FEEDBACK.MODE_TYPE.EVENT,
        template: this.advanceTrackContentTemplate(),
      });
    },
    sendTimeFeedback({ value, type, mode, template }) {
      this.$api.feedback(
        FEEDBACK.NAME.TRACK_TIME,
        {
          [FEEDBACK.COMPONENT_NAME]: this.$options.name,
          [FEEDBACK.TIME]: Date.now(),
          [FEEDBACK.USER_ID]: this.userId,
          [FEEDBACK.VALUE]: value,
          [FEEDBACK.TYPE]: type,
          [FEEDBACK.ROUTE]: this.$route.path,
          [FEEDBACK.MODE]: mode,
        },
        template
      );
    },
  },
};
