/* eslint-disable global-require */
module.exports = {
  SLA_DEFINITION: {
    PATIENT_COUNT: 10,
    PATIENT_PROFILE_FORM: 15,
    PATIENT_CHARACTERISTICS: 20,
    PATIENT_INCIDENCE_RATE: 20,
    PATIENT_KM_CURVE: 20,
    PATIENT_HAZARD_RATIO: 20,
    COHORT_EXPORT: 10,
    EXPORT_CENTER: 100,
  },
  PROGRESS_CONFIG: {
    MAX_BOUNDARY: 90,
  },
};
