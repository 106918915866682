import BasicChartConfig from '@/components/charts/core/BasicChartConfig';
import { BasicChartQueryParams, ChartItem, ChartKey, RawItem } from '@/components/charts/core/Interface';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { getUiConfig } from '@/utils/uiConfig';
import { ConditionName, RangeCondition, RangeConditionData, SearchType } from '../../utils/conditions/core';
import { removePoint, UNSELECTED_COLOR } from './utils/utils';

const OPTION = {
  grid: {
    top: 30,
  },
  xAxis: {
    type: 'category',
    triggerEvent: true,
    axisLabel: {
      interval: 0,
      rotate: 45,
      margin: 25,
      align: 'center',
    },
    nameLocation: 'end',
    nameGap: -30,
    nameTextStyle: {
      padding: [100, 0, 0, 0],
      color: '#757575',
    },
  },
  yAxis: {
    type: 'value',
    nameLocation: 'end',
    nameTextStyle: {
      color: '#757575',
    },
    axisLabel: {
      formatter: removePoint,
    },
  },
};

interface TrendRawItem extends RawItem {
  key_as_string: string;
  patient_count: {
    value: number;
  };
}

export default class TrendConfig extends BasicChartConfig<TrendRawItem> {
  protected isRangeLessThanOneYear = false;

  constructor() {
    super(ChartKey.Trend);

    this.merge(OPTION);
  }

  protected processQueryParams(): BasicChartQueryParams {
    const condList = this.params.includes.select(
      (cond) => cond.hasTag(SearchType.Raw) || cond.name !== ConditionName.Time
    );
    return {
      ...this.params,
      includes: condList,
    };
  }

  protected setAxisData(): void {
    this.unset('xAxis.data');
    this.merge({
      xAxis: {
        data: this.data.map((item) => item.name),
        name: this.isRangeLessThanOneYear ? 'Month' : 'Year',
      },
      yAxis: {
        name: this.bucketByPatient ? 'Patient' : 'Record',
      },
    });
    this.chartDescr.Title = this.bucketByPatient ? 'Patient Count' : 'Record Count';
  }

  protected get hasData(): boolean {
    return this.data.some((item) => item.value !== 0);
  }

  protected createChartItem(rawItem: TrendRawItem): ChartItem {
    const {
      key: timestamp,
      doc_count,
      patient_count: { value },
    } = rawItem;

    const day = dayjs(timestamp);
    const format = this.isRangeLessThanOneYear ? 'YYYY/MM' : 'YYYY';
    const dayString = day.format(format);
    const condList = this.params.includes.additions.with(ConditionName.Time);
    let color = '#333f6b';
    if (condList.length !== 0) {
      const condition = (condList[0] as unknown) as RangeCondition;
      color = UNSELECTED_COLOR;
      if (condition.isSurrounding(day.unix())) {
        color = '#333f6b';
      }
    }

    const targetValue = this.bucketByPatient ? value : doc_count;

    return {
      key: dayString,
      value: targetValue,
      code: dayString,
      name: dayString,
      color,
      percentage: this.calculatePercentage(targetValue),
    };
  }

  public createConditionFromItem(chartItem: ChartItem): RangeConditionData {
    const { name } = chartItem;
    const condition = {
      name: ConditionName.Time,
      tags: [SearchType.Basic, SearchType.Additional],
    };
    const day = dayjs(name);
    const timeUnit = this.isRangeLessThanOneYear ? 'month' : 'year';
    return {
      ...condition,
      ranges: [{ start: day.startOf(timeUnit).unix(), end: day.endOf(timeUnit).unix() }],
    };
  }

  protected get aggsQuery(): Record<string, unknown> {
    const condList = this.params.includes.raws.with(ConditionName.Time);
    const rawRange = (condList[0] as RangeCondition).ranges[0];
    const startDay = isEmpty(rawRange) ? dayjs.unix(getUiConfig().SearchForm.time.start) : dayjs.unix(rawRange.start);
    const endDay = isEmpty(rawRange) ? dayjs.unix(getUiConfig().SearchForm.time.end) : dayjs.unix(rawRange.end);

    this.isRangeLessThanOneYear = endDay.diff(startDay, 'year') === 0;
    const calendar_interval = this.isRangeLessThanOneYear ? '1M' : '1y';
    const format = this.isRangeLessThanOneYear ? 'yyyy/MM' : 'yyyy';

    const text = `${this.isRangeLessThanOneYear ? 'Monthly' : 'Yearly'} ${
      this.bucketByPatient ? 'Patient' : 'Record'
    } Count`;
    this.merge({
      title: {
        text,
      },
    });

    return {
      date_histogram: {
        field: 'OUT_DATE',
        calendar_interval,
        min_doc_count: 0,
        format,
        extended_bounds: {
          min: startDay.format(format.toUpperCase()),
          max: endDay.format(format.toUpperCase()),
        },
      },
      aggs: {
        patient_count: {
          cardinality: {
            field: 'CHART_NO',
          },
        },
      },
    };
  }
}
