var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"left-div"},[_c('LeftEMRSearch')],1),_c('div',{staticClass:"center-div"},[_c('div',{staticClass:"d-flex align-items-center hint-div"},[_c('b-img',{staticClass:"mr-15px",attrs:{"src":require("@/assets/images/icon_hint_light.svg")}}),_c('div',{staticClass:"hint-text"},[_vm._v(" EMR Search identifies medical records that match your search criteria defined below. ")])],1),_c('InExcludeRender',{ref:"InExcludeRender",staticClass:"mt-20px"}),_c('div',{staticClass:"d-flex justify-content-end",staticStyle:{"margin-top":"40px"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-center mr-30px",staticStyle:{"cursor":"pointer"},on:{"click":_vm.clickClearAll}},[_c('b-img',{staticClass:"mr-5px",attrs:{"src":require("@/assets/images/icon_emr_search_clear_all.svg")}}),_c('div',{staticClass:"clear-all-text"},[_vm._v("Clear All")])],1),_c('div',{staticClass:"d-flex align-items-center justify-content-center search-button",class:{ 'disable-button': _vm.inPatient === 0 && _vm.outPatient === 0 },attrs:{"id":"emr-search-button"},on:{"click":function($event){_vm.handleSubmit(
            _vm.conditions.filter(function (item) { return !item.list || item.list.length !== 0; }),
            _vm.excludedConditions.filter(function (item) { return !item.list || item.list.length !== 0; })
          )}}},[_c('b-img',{staticClass:"mr-5px",attrs:{"src":require("@/assets/images/icon_search_button.svg")}}),_c('div',{staticClass:"search-text"},[_vm._v("Search")])],1)]),(_vm.EMRSearchLandingIndex === 7)?_c('div',{staticClass:"landing-mask"},[_c('div',{staticClass:"d-flex align-items-center justify-content-center search-button",staticStyle:{"pointer-events":"auto"},style:({ 'margin-left': (_vm.searchLeft + "px"), 'margin-top': (_vm.searchTop + "px"), width: (_vm.searchWidth + "px") }),on:{"click":_vm.clickNext7}},[_c('b-img',{staticClass:"mr-5px",attrs:{"src":require("@/assets/images/icon_search_button.svg")}}),_c('div',{staticClass:"search-text"},[_vm._v("Search")])],1),_c('div',{staticClass:"landing-1-tooltip",staticStyle:{"display":"inline-block"},style:({ left: ((_vm.searchLeft - 107) + "px"), top: ((_vm.searchTop - 182) + "px") })},[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-img',{attrs:{"src":require("@/assets/images/landing_3.svg")}}),_c('div',{staticClass:"mini-title ml-5px"},[_vm._v("估算與搜尋")])],1),_c('div',{staticClass:"landing-text mt-10px"},[_vm._v("最後步驟")]),_c('div',{staticClass:"landing-content-text mt-10px"},[_vm._v(" 完成所有設定後點擊 “Search” 查看符合設定條件病人 demographics, medical records, lab data 等資訊 ")])])]):_vm._e()],1),_c('div',{staticClass:"right-div",attrs:{"id":"right-SavedConcept"}},[_c('SavedConcept')],1),(_vm.EMRSearchLandingIndex === 4)?_c('div',{staticClass:"landing-mask"},[_c('div',{staticClass:"right-div",staticStyle:{"background":"#ffffff","border-radius":"16px"},style:({
        width: (_vm.width + "px"),
        width: ("calc(100% - " + _vm.top + "px - 40px)"),
        'max-width': (_vm.width + "px"),
        'min-width': (_vm.width + "px"),
        margin: (_vm.top + "px 0px 0px " + _vm.left + "px"),
        display: _vm.EMRSearchLandingIndex === 4 ? 'inline-block' : 'block',
      })},[_c('SavedConcept',{staticStyle:{"pointer-events":"none"}}),_c('div',{staticClass:"landing-tooltip",style:({ left: ((_vm.left - 296 - 21) + "px") })},[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-img',{attrs:{"src":require("@/assets/images/landing_1.svg")}}),_c('div',{staticClass:"mini-title ml-5px"},[_vm._v("輸入條件與儲存個人化concept")])],1),_c('div',{staticClass:"landing-text mt-10px"},[_vm._v("個人化concept清單")]),_c('div',{staticClass:"landing-content-text mt-10px"},[_vm._v(" 現在可以看到剛剛儲存的 “user input name” 已經出現在 My concept 裡，還有已經存好的 抗腫瘤壞死因子製劑 (anti-TNF biologics) ，以後可以重複利用這個 concept 到不同的搜尋裡 ")]),_c('div',{staticClass:"next-button mt-10px",on:{"click":_vm.clickNext}},[_vm._v("Next")])])],1)]):_vm._e(),(_vm.savedHint)?_c('div',{staticClass:"saved-hint"},[_vm._v("✓ Saved")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }