<template>
  <div>
    <div id="capsule-container">
      <div id="age-capsule">
        <div id="age-capsule-word">Age</div>
        <div @click.stop="openAgeSlider" id="age-capsule-dropbox">
          <div id="age-capsule-dropbox-word">{{ formatAge(getAgeStart) }}~{{ formatAge(getAgeEnd) }}</div>
          <span>
            <b-img src="@/assets/images/icon_polygon.svg" class="icon-polygon" />
          </span>
        </div>
        <div id="age-capsule-delete">
          <b-img
            src="@/assets/images/icon_remove_blue.svg"
            style="cursor: pointer; size: 13px"
            @click="deleteConstraint(ConstraintType.AgeConstraint)"
          />
        </div>
      </div>
    </div>
    <div v-if="!hasCondition" id="invalid-warning">Invalid when no condition</div>
    <div v-if="isOpenMenu" v-click-outside="closeAgeSlider" id="slide-container">
      <div>
        <span id="slide-age-describe">
          <span id="slide-age-word">Age</span>
          <span id="slide-age-range">{{ formatAge(getAgeStart) }}~{{ formatAge(getAgeEnd) }}</span>
        </span>
        <span>
          <b-img
            id="slide-age-remove"
            src="@/assets/images/icon_close.svg"
            style="cursor: pointer; size: 13px"
            @click="closeAgeSlider"
          />
        </span>
      </div>
      <div style="width: 310px; margin: auto">
        <a-slider :min="0" :max="100" range :defaultValue="getAgeConstraint" @afterChange="changeTime" />
      </div>
    </div>
  </div>
</template>

<script>
import { findIndex } from 'lodash';

import ClickOutside from 'vue-click-outside';

import { AdvanceSearchMixin, ClickMixin, AdvanceUpdateMixin } from '@/mixins';
import ConstraintType from '@/utils/conditions/core/advance/constraint/Interface';
import { EventType } from '@/utils/conditions/core/Interface';
import { formatAge } from '@/utils/util';

import { mapState } from '../../../store';

export default {
  name: 'AgeConstraint',
  directives: {
    ClickOutside,
  },
  mixins: [AdvanceSearchMixin, AdvanceUpdateMixin, ClickMixin],
  props: {
    index: {
      type: Number,
      default: 1,
    },
    data: {
      type: Object,
    },
    eventType: {
      type: String,
      default: EventType.FIX_EVENT,
    },
    path: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isSliderOpen: true,
      ConstraintType,
    };
  },
  computed: {
    ...mapState('SearchStateObject', ['advancePortal']),
    getAgeConstraint() {
      return [
        this.currentEvent.constraintList[this.constrainIndex].start,
        this.currentEvent.constraintList[this.constrainIndex].end === 200
          ? 100
          : this.currentEvent.constraintList[this.constrainIndex].end,
      ];
    },
    currentEvent() {
      return this.getEventDataByPath(this.path);
    },
    constrainIndex() {
      return findIndex(this.currentEvent.constraintList, {
        constraintType: ConstraintType.AgeConstraint,
      });
    },
    hasCondition() {
      return this.currentEvent.condition.length > 0;
    },
    getAgeStart() {
      return this.currentEvent.constraintList[this.constrainIndex].start;
    },
    getAgeEnd() {
      return this.currentEvent.constraintList[this.constrainIndex].end;
    },
    isOpenMenu() {
      return this.isCurrentMenu(this.path, 'AgeConstraint') && this.isSliderOpen;
    },
  },
  methods: {
    formatAge,
    changeTime(values) {
      const event = this.getEventDataByPath(this.path);
      const constraintIndex = findIndex(event.constraintList, {
        constraintType: ConstraintType.AgeConstraint,
      });
      const end = values[1] === 100 ? 200 : values[1];
      event.constraintList[constraintIndex].start = values[0];
      event.constraintList[constraintIndex].end = end;
      const body = {
        constraintList: event.constraintList,
      };
      this.updateEventWithPath(body, this.path);

      this.advanceTrackClick('changeTime', {
        path: this.path,
        index: this.index,
        constraintList: event.constraintList,
      });
    },
    closeAgeSlider() {
      if (this.isOpenMenu) {
        this.advanceTrackClick('closeAgeSlider', {
          path: this.path,
          index: this.index,
        });
      }

      this.isSliderOpen = false;
    },
    openAgeSlider() {
      this.advanceTrackClick('openAgeSlider', {
        path: this.path,
        index: this.index,
      });

      this.isSliderOpen = true;
      this.setCurrentMenu(this.path, 'AgeConstraint');
    },
  },
};
</script>

<style scoped>
#capsule-container {
  display: inline-block;
}

#age-capsule {
  display: -webkit-inline-box;
  padding: 8px 0px 8px 0px;
  margin-left: 10px;
  background-color: #edf5fb;
  border-radius: 100px;
  width: 175px;
}

#age-capsule-word {
  position: relative;
  top: 2px;
  font-style: Muli;
  font-weight: 400;
  size: 14px;
  color: #333333;
  margin-left: 10px;
}

#age-capsule-dropbox {
  background-color: white;
  border-radius: 20px;
  margin-left: 5px;
  width: 100px;
  padding: 1px 0px;
  cursor: pointer;
  border: 1px solid #e0e0e0;
}

#age-capsule-dropbox-word {
  margin-left: 10px;
  font-weight: 700;
  font-size: 16px;
  color: #0f4895;
  display: inline-block;
}

#age-capsule-delete {
  margin-left: 10px;
}

#slide-container {
  border: #d6d9e1 solid 1px;
  width: 360px;
  height: 92px;
  position: absolute;
  background-color: white;
  z-index: 1;
  border-radius: 5px;
}

#slide-age-describe {
  display: inline-block;
  position: relative;
  margin-left: 20px;
  margin-top: 20px;
}

#slide-age-word {
  color: #0f4895;
}

#slide-age-range {
  margin-left: 10px;
}

#slide-age-remove {
  position: relative;
  float: right;
  margin-right: 18px;
  margin-top: 18px;
  width: 13px;
  height: 13px;
}

#invalid-warning {
  display: inline-block;
  color: #da6d6d;
  font-size: 14px;
  margin-left: 10px;
}

.icon-polygon {
  width: 10px;
  height: 10px;
  margin-left: 5px;
}
</style>
