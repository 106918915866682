export default {
  conceptName: 'Anti-TNF Biologics',
  conceptType: 'include',
  name: 'Medication',
  tags: ['Basic', 'Raw'],
  andOr: 'should',
  list: [
    {
      code: 'HUMI',
      label: 'HUMI',
      description: '(審)Humira pre-filled syringe(需冷藏)40mg/0.4ml',
      chineseDescription: '復邁針筒裝注射劑(L)',
      value: 'HUMI',
      type: 'med',
      patientNum: 578,
      recordNum: 16834,
      field: 'med',
      ingredient: 'adalimumab',
      atcCode: 'L04AB04',
      time: 1646618953754,
    },
    {
      code: 'ENBR',
      label: 'ENBR',
      description: 'Enbrel 25mg/vial(需冷藏)',
      chineseDescription: '●(審)恩博凍晶注射劑',
      value: 'ENBR',
      type: 'med',
      patientNum: 10,
      recordNum: 33,
      field: 'med',
      ingredient: 'etanercept',
      atcCode: 'L04AA11',
      time: 1646618959168,
    },
    {
      code: 'ENBR5',
      label: 'ENBR5',
      description: '(審)(大) Enbrel 50mg/vial (需冷藏)',
      chineseDescription: '恩博 針筒裝注射劑 50公絲 (L)',
      value: 'ENBR5',
      type: 'med',
      patientNum: 160,
      recordNum: 4423,
      field: 'med',
      ingredient: 'etanercept',
      atcCode: 'L04AA11',
      time: 1646618959773,
    },
    {
      code: 'ENBRP',
      label: 'ENBRP',
      description: '(審)(小)Enbrel 25mg/vial (需冷藏)用完DC',
      chineseDescription: '恩博針筒裝注射劑 25 公絲(L)',
      value: 'ENBRP',
      type: 'med',
      patientNum: 270,
      recordNum: 7243,
      field: 'med',
      ingredient: 'etanercept',
      atcCode: 'L04AA11',
      time: 1646618960285,
    },
    {
      code: 'REMIC',
      label: 'REMIC',
      description: '(審)Remicade 100mg/20ml/vial 需冷藏(L)',
      chineseDescription: '類克凍晶注射劑(L)',
      value: 'REMIC',
      type: 'med',
      patientNum: 23,
      recordNum: 167,
      field: 'med',
      ingredient: 'infliximab',
      atcCode: 'L04AB02',
      time: 1646618966686,
    },
    {
      code: 'REMS',
      label: 'REMS',
      description: 'Remsima 100mg/vial(需冷藏)',
      chineseDescription: '類希瑪',
      value: 'REMS',
      type: 'med',
      patientNum: 10,
      recordNum: 30,
      field: 'med',
      ingredient: 'infliximab',
      atcCode: 'L04AB02',
      time: 1646618967214,
    },
    {
      code: 'SIMP',
      label: 'SIMP',
      description: '(審)(小)Simponi injection 50mg/0.5ml/vial (需冷藏)',
      chineseDescription: '欣普尼注射液(L)',
      value: 'SIMP',
      type: 'med',
      patientNum: 373,
      recordNum: 10452,
      field: 'med',
      ingredient: 'golimumab',
      atcCode: 'L04AB06',
      time: 1646618971001,
    },
    {
      code: 'SIMP1',
      label: 'SIMP1',
      description: '(審)(大)Simponi injection 100mg/1ml/vial (需冷藏)',
      chineseDescription: '(大)欣普尼 注射液(L)',
      value: 'SIMP1',
      type: 'med',
      patientNum: 23,
      recordNum: 108,
      field: 'med',
      ingredient: 'golimumab',
      atcCode: 'L04AB06',
      time: 1646618971457,
    },
  ],
  diagnosisRange: false,
  index: 1,
};
