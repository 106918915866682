import { mapActions } from '../store';
import { postProcessEvent } from '@/utils/advance/postProcessLab';

export default {
  methods: {
    ...mapActions('eventSearch', ['updateWithPath', 'updatepatientCount']),
    ...mapActions('eventResult', ['updateWithResultPath', 'updatepatientResultCount']),
    updateEventWithPath(body, path) {
      const modifiedBody = postProcessEvent(body, path);
      if (path[0] === 'session') {
        this.updateWithResultPath({ api: this.$api, path, ...modifiedBody });
      } else {
        this.updateWithPath({ api: this.$api, path, ...modifiedBody });
      }
    },
    updateCount(path) {
      if (path[0] === 'session') {
        this.updatepatientResultCount({ api: this.$api, path: path[2] });
      } else {
        this.updatepatientCount({ api: this.$api, path: path[0] });
      }
    },
  },
};
