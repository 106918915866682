import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { isEqual, cloneDeep } from 'lodash';

import { RangeData, ITimeRelation } from '@/utils/conditions/core/Interface';
import { getPatientQuery } from '@/utils/query';
import {
  defaultFirstEvent,
  defaultThirdEvent,
  defaultIndexEvent,
  defaultTimeSelectObject,
} from '@/utils/advance/initState';
import { foolProof } from '@/utils/advance/foolproof';
import objectPath from 'object-path';
import { getUiConfig } from '@/utils/uiConfig';
import { getTimeRangeInYear } from '@/utils/conditions/TimeDef';
import AdvanceESQuery from '../../utils/query/core/advance/AdvanceESQuery';
import { SearchState, IndexEvent, TimeRelationEvent } from '../../utils/conditions/core/advance';

function assign(content: any, event: any) {
  Object.keys(content).forEach((key) => {
    const value = content[key];
    if (value !== undefined && !isEqual(event[key], value)) {
      // eslint-disable-next-line no-param-reassign
      event[key] = value;
    }
  });
}

@Module({ namespaced: true })
export default class AdvancePortalModule extends VuexModule {
  SearchStateObject: SearchState = {
    timePeriod: getTimeRangeInYear(getUiConfig().SearchForm.time),
    eventList: [cloneDeep(defaultFirstEvent), cloneDeep(defaultThirdEvent)],
    indexEvent: cloneDeep(defaultIndexEvent),
    genderList: [],
    bodyMassList: [],
  };

  TimeSelectObject: ITimeRelation = cloneDeep(defaultTimeSelectObject);

  patientCount = 0;

  loadingCount = 0;

  sortChartByAI = false;

  currentMenu = '';

  @Mutation
  updateFirstEventMutation({ ...content }: Partial<TimeRelationEvent>) {
    const firstEvent = this.SearchStateObject.eventList[0];
    assign(content, firstEvent);
  }

  @Mutation
  updateThirdEventMutation({ ...content }: Partial<TimeRelationEvent>) {
    const thirdEvent = this.SearchStateObject.eventList[1];
    assign(content, thirdEvent);
  }

  @Mutation
  updateIndexEventMutation({ ...content }: Partial<IndexEvent>) {
    const { indexEvent } = this.SearchStateObject;
    assign(content, indexEvent);
  }

  @Mutation
  updateTimePeriodMutation({ ...content }: Partial<RangeData>) {
    const { timePeriod } = this.SearchStateObject;
    assign(content, timePeriod);
  }

  @Mutation
  setPatientCount(patientCount: number) {
    this.patientCount = patientCount;
  }

  @Mutation
  updateTimeStatisticMutation({ ...content }: Partial<ITimeRelation>) {
    const timePeriod = this.TimeSelectObject;
    assign(content, timePeriod);
  }

  @Mutation
  updateLoadingCount(data: number) {
    this.loadingCount += data;
  }

  @Mutation
  updateWithPathMutation({ path, ...content }: any) {
    const event = objectPath.get(this.SearchStateObject, path);
    assign(content, event);
  }

  @Mutation
  setMenuPath({ path, menuName }: any) {
    this.currentMenu = `${path}/${menuName}`;
  }

  @Action
  async advanceUpdate(api: any) {
    const params = {
      SearchStateObject: this.SearchStateObject,
    };
    const query = new AdvanceESQuery(foolProof(params)).getQuery();
    const patientQuery = getPatientQuery(query);
    this.context.dispatch('updateStatus', { patientQuery, api });
  }

  @Action
  async updateStatus({ patientQuery, api }: any) {
    this.context.commit('updateLoadingCount', 1);
    const result = await api.advSearch(patientQuery, 0);
    const { patient } = {
      patient: result.aggregations.patientNum.value,
    };
    this.context.commit('setPatientCount', patient);
    this.context.commit('updateLoadingCount', -1);
  }

  @Action
  async updateFirstEvent({ api, ...content }: any) {
    this.context.commit('updateFirstEventMutation', { ...content });
    this.context.dispatch('advanceUpdate', api);
  }

  @Action
  async updateThirdEvent({ api, ...content }: any) {
    this.context.commit('updateThirdEventMutation', { ...content });
    this.context.dispatch('advanceUpdate', api);
  }

  @Action
  async updateIndexEvent({ api, ...content }: any) {
    this.context.commit('updateIndexEventMutation', { ...content });
    this.context.dispatch('advanceUpdate', api);
  }

  @Action
  async updateTimePeriod({ api, ...content }: any) {
    this.context.commit('updateTimePeriodMutation', { ...content });
    this.context.dispatch('advanceUpdate', api);
  }

  @Action
  async updateTimeStatistic({ api, ...content }: any) {
    this.context.commit('updateTimeStatisticMutation', { ...content });
    this.context.dispatch('advanceUpdate', api);
  }

  @Action
  async updateWithPath({ api, path, ...content }: any) {
    this.context.commit('updateWithPathMutation', { path, ...content });
    this.context.dispatch('advanceUpdate', api);
  }
}
