<template>
  <div
    @click.stop="addDiagnosisRepeatedTimesConstraint"
    class="medicaiob-total-adder"
    :class="{ disabled: this.constraintIndex !== -1 }"
    v-if="hasDiagnosisRepeatedTimesCondition"
  >
    + Repeated Diagnosis
  </div>
</template>
<script>
import { findIndex, cloneDeep } from 'lodash';
import { EventFilterMixin } from '@/mixins';
import ConstraintName from '@/utils/conditions/core/advance/constraint/Interface';

export default {
  name: 'DiagnosisRepeatedTimes',
  mixins: [EventFilterMixin],
  computed: {
    constraintIndex() {
      return findIndex(this.currentEvent.constraintList, {
        constraintType: ConstraintName.DiagnosisRepeatedTimesConstraint,
      });
    },
    hasDiagnosisRepeatedTimesCondition() {
      return this.currentEvent.condition.find((item) => item.name === 'Diagnosis');
    },
  },
  methods: {
    addDiagnosisRepeatedTimesConstraint() {
      const event = cloneDeep(this.currentEvent);

      if (this.constraintIndex === -1) {
        event.constraintList.push({
          constraintType: ConstraintName.DiagnosisRepeatedTimesConstraint,
          repeatedTimes: 1,
          operator: 'in',
          timeRange: 'any',
          timeUnit: 'days',
        });
      }
      const body = {
        constraintList: event.constraintList,
      };
      this.updateEventWithPath(body, this.path);
      this.setCurrentMenu(this.path, ConstraintName.DiagnosisRepeatedTimesConstraint);
      this.cohortStudyTrackClick('openDiagnosisRepeatedFilter', {
        path: this.path,
        index: this.index,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.medicaiob-total-adder {
  padding: 4px 8px;
  background: #ffffff;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #4d8edc;
  cursor: pointer;
  border: 1px solid #4d8edc;
  box-sizing: border-box;
  border-radius: 4px;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
