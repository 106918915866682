import BasicChartConfig from '@/components/charts/core/BasicChartConfig';
import { ChartItem, ChartKey, RawItem } from '@/components/charts/core/Interface';
import numeral from 'numeral';
import { flow } from 'lodash';
import { PRECISION_THRESHOLD, UICONFIG } from '@/utils/constants';
import { CodeOption, CodeViewAllOption } from '@/utils/util';
import {
  ConditionName,
  LogicOp,
  MedicalTermType,
  SearchType,
  TermCondition,
  TermConditionData,
} from '../../utils/conditions/core';
import { AxisFormatter, SELECTED_COLOR, findNameByKey } from './utils/utils';

interface DiagnosisRawItem extends RawItem {
  patient_count: {
    value: number;
  };
}

export default class MedicationConfig extends BasicChartConfig<DiagnosisRawItem> {
  constructor() {
    super(ChartKey.Medication);

    this.hasViewAll = true;
    this.merge({ ...CodeOption });
  }

  public axisLabelFormatter(label: string) {
    return label.length > 20 ? `${label.substring(0, 20)}..` : label;
  }

  protected setAxisData(): void {
    this.unset('xAxis.name');
    this.unset('yAxis.data');
    this.merge({
      xAxis: {
        name: this.bucketByPatient ? 'Patient' : 'Record',
      },
      yAxis: {
        data: this.data.map((item) => item.key),
        axisLabel: {
          formatter: flow([findNameByKey(this.data), AxisFormatter]),
        },
      },
    });
  }

  public createViewAllConfig(): MedicationConfig {
    const config = new MedicationConfig();
    config.resultSize = UICONFIG.VIEW_ALL_SIZE;
    config.bucketByPatient = this.bucketByPatient; // Copy over previous bucketing status.
    config.merge({ ...CodeViewAllOption });
    return config;
  }

  protected createChartItem(rawItem: DiagnosisRawItem): ChartItem {
    const {
      key,
      doc_count,
      patient_count: { value },
    } = rawItem;
    const [code, name] = key.split('##');

    const condList = this.params.includes.additions.with(ConditionName.Medication);

    let color = '#333f6b';
    if (condList.length !== 0) {
      const condition = condList[0] as TermCondition;
      if (condition.hitCode(code)) {
        color = SELECTED_COLOR;
      }
    }

    const targetValue = this.bucketByPatient ? value : doc_count;

    return {
      key: code,
      value: targetValue,
      code,
      name,
      color,
      percentage: this.calculatePercentage(targetValue),
    };
  }

  public createConditionFromItem(chartItem: ChartItem): TermConditionData {
    return {
      name: ConditionName.Medication,
      tags: [SearchType.Basic, SearchType.Additional],
      andOr: LogicOp.And,
      list: [{ code: chartItem.code, type: MedicalTermType.Medication }],
    };
  }

  protected percentageFormatter(seriesList: any) {
    let currentSeries: any = null;
    if (Array.isArray(seriesList)) {
      currentSeries = seriesList[0];
    } else {
      currentSeries = seriesList;
    }
    const item = currentSeries.data as ChartItem;
    const formatedNumber = numeral(item.value).format('0,0');
    return `[${item.code}] ${item.name}: ${formatedNumber} (${item.percentage}%)`;
  }

  protected get aggsQuery(): Record<string, unknown> {
    const orderField = this.getOrderField();
    return {
      terms: {
        field: 'MEDICATION_NEW_FULL',
        size: this.resultSize,
        shard_size: this.resultSize * 4 + 10,
        order: {
          [orderField]: 'desc',
        },
        exclude: '.*每日藥費.*',
      },
      aggs: {
        patient_count: {
          cardinality: {
            field: 'CHART_NO',
            precision_threshold: PRECISION_THRESHOLD,
          },
        },
      },
    };
  }
}
