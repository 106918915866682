import localforage from 'localforage';
import ApiHelper from './ApiHelper';

enum DescriptionType {
  ICD10 = 'ICD10-CM',
  ICD9 = 'ICD9-DIAGNOSIS',
  PCS = 'PCS',
  NHI_ORDER = 'NHI-ORDER',
  MEDICATION = 'ORDER-ID',
  MEDICAL_ORDER = 'MEDICAL-ORDER',
  ATC_CODE = 'ATC-CODE',
}

const localDBs = new Map<DescriptionType, LocalForage>();

Object.values(DescriptionType).forEach((name) => {
  localDBs.set(name, localforage.createInstance({ name }));
});

function setName(type: DescriptionType, code: string, name: string) {
  try {
    return localDBs.get(type)!.setItem(code, {
      code,
      english_name: name,
    });
  } catch (e) {
    console.error(`setName: ${e}`);
    return '';
  }
}

function setMedicalOrder(type: DescriptionType, code: string, name: string, option: string) {
  try {
    return localDBs.get(type)!.setItem(code, {
      code,
      english_name: name,
      type: option,
    });
  } catch (e) {
    console.error(`setMedicalOrder: ${e}`);
    throw new Error(e);
  }
}

async function getName(type: DescriptionType, code: string) {
  if ([DescriptionType.ICD10, DescriptionType.ICD9].includes(type)) {
    code = code.replace('.', '');
    if (code.length > 3) {
      code = code.slice(0, 3) + '.' + code.slice(3);
    }
  }
  try {
    const result = await localDBs
      .get(type)!
      .getItem<any>(code)
      .catch((error) => {
        console.error(error);
      });

    if (result !== null && result !== undefined) {
      return result.english_name;
    }

    const API = new ApiHelper();
    const data: any = await API.lookupName({
      query: {
        [type]: [code],
      },
    }).catch((error) => {
      console.error(error);
    });

    localDBs.get(type)!.setItem(code, data.results[type][code]);
    return data.results[type][code].english_name;
  } catch (e) {
    return Promise.resolve(e);
  }
}

async function getType(type: DescriptionType, code: string) {
  try {
    const result = await localDBs
      .get(type)!
      .getItem<any>(code)
      .catch((error) => {
        console.error(error);
      });

    if (result !== null && result !== undefined) {
      return result.type;
    }

    const API = new ApiHelper();
    const data: any = await API.lookupName({
      query: {
        [type]: [code],
      },
    }).catch((error) => {
      console.error(error);
    });
    localDBs.get(type)!.setItem(code, data.results[type][code]);
    return data.results[type][code].type;
  } catch (e) {
    console.error(`getType: ${e}`);
    return Promise.resolve(e);
  }
}

export { DescriptionType, getName, setName, setMedicalOrder, getType };
