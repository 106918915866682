<template>
  <div>
    <div v-for="(condition, index) in getInExcludeData" :key="index">
      <ConceptEdit :condition="condition" :path="['search', condition.index]" :id="`ConceptEdit-${index}`" />
      <div class="mt-5px mb-5px concept-logic-text" v-if="getInExcludeData.length - 1 !== index">AND</div>
    </div>
    <div class="mt-20px d-flex align-items-center">
      <div class="in-ex-clude-button" @click="addInclude">+ Include</div>
      <div class="ml-10px in-ex-clude-button" @click="addExclude">+ Exclude</div>
    </div>
    <div
      class="landing-mask"
      v-if="EMRSearchLandingIndex === 2 || EMRSearchLandingIndex === 3 || EMRSearchLandingIndex === 5"
    >
      <ConceptEdit
        :condition="EMRSearchLandingIndex === 5 ? getInExcludeData[1] : getInExcludeData[0]"
        :path="['search', 1]"
        :style="{
          width: `${width}px`,
          'max-width': `${width}px`,
          'min-width': `${width}px`,
          margin: `${top}px 0px 0px ${left}px`,
          display: EMRSearchLandingIndex === 3 ? 'inline-block' : 'block',
        }"
        style="pointer-events: none"
        :landing="EMRSearchLandingIndex === 3 || EMRSearchLandingIndex === 5"
        @updateLandingMenu="updateLandingMenu"
      />
      <div class="landing-tooltip" v-if="EMRSearchLandingIndex === 2">
        <div class="d-flex align-items-center">
          <b-img src="@/assets/images/landing_1.svg" />
          <div class="mini-title ml-5px">輸入條件與儲存個人化concept</div>
        </div>
        <div class="landing-text mt-10px">輸入搜尋條件</div>
        <div class="landing-content-text mt-10px">
          以自體免疫性疾病 (Inflammatory diseases) 使用抗腫瘤壞死因子製劑 (anti-TNF biologics)
          為例，將代表自體免疫性疾病的 ICD-10 code 輸入
        </div>
        <div class="next-button mt-10px" @click="clickNext">Next</div>
      </div>
      <div
        class="landing-2-tooltip"
        v-if="EMRSearchLandingIndex === 3 && !landingSaveAsMenu"
        :style="{
          display: EMRSearchLandingIndex === 3 ? 'inline-block' : 'block',
          margin: `${top - 43}px 0px 0px 22px`,
        }"
      >
        <div class="d-flex align-items-center">
          <b-img src="@/assets/images/landing_1.svg" />
          <div class="mini-title ml-5px">輸入條件與儲存個人化concept</div>
        </div>
        <div class="landing-text mt-10px">按下圖示，儲存concept</div>
        <div class="landing-content-text mt-5px">
          按 “save as” icon，把這一些代表 自體免疫性疾病 (Inflammatory diseases) 的 code
          存起來成為一個可經常重複利用的個人化concept
        </div>
      </div>
      <div
        class="landing-3-tooltip"
        v-if="EMRSearchLandingIndex === 5"
        :style="{ top: `${top - 223 - 21}px`, left: `${left + width - 320}px` }"
      >
        <div class="d-flex align-items-center">
          <b-img src="@/assets/images/landing_2.svg" />
          <div class="mini-title ml-5px">匯入concept至criteria</div>
        </div>
        <div class="landing-text mt-10px">加入 concept</div>
        <div class="landing-content-text mt-10px">
          你可以用 import 加入抗腫瘤壞死因子製劑 (anti-TNF biologics)至Criteria設定區
        </div>
        <div class="next-button mt-10px" @click="clickNext5">Next</div>
      </div>
    </div>
  </div>
</template>
<script>
import { EMR_SEARCH_TEXT } from '@/utils/constants';
import ConceptEdit from '@/components/forms/ConceptEdit.vue';
import { mapState, mapMutations } from '@/store';
import ConditionList from '@/utils/ConditionList';
import { cloneDeep, sortBy } from 'lodash';
import { DiagnosisRanges, DiagnosisType } from '@/utils/conditions/core';
import { ClickMixin } from '@/mixins';
import { getAntiTNFBiologicsConfig } from '@/utils/landingConfig/';

export default {
  name: 'InExcludeRender',
  components: {
    ConceptEdit,
  },
  mixins: [ClickMixin],
  data() {
    return {
      conditionNameList: ['Diagnosis', 'Medication', 'Medical Order'],
      top: 0,
      left: 0,
      width: 0,
      tagName: 'Inflammatory diseases (RA + PSO + PSA + SLE)',
      landingSaveAsMenu: false,
    };
  },
  watch: {
    EMRSearchLandingIndex() {
      this.setPosition();
    },
  },
  computed: {
    ...mapState('condition', ['conditions', 'excludedConditions']),
    ...mapState(['EMRSearchLandingIndex']),
    getInExcludeLength() {
      return (
        this.conditions.filter((item) => this.conditionNameList.includes(item.name)).length +
        this.excludedConditions.filter((item) => this.conditionNameList.includes(item.name)).length
      );
    },
    getInExcludeData() {
      return sortBy(
        this.conditions.concat(this.excludedConditions).filter((item) => this.conditionNameList.includes(item.name)),
        ['time']
      );
    },
  },
  methods: {
    ...mapMutations('condition', ['setCondition', 'setExcludedCondition']),
    ...mapMutations(['setEMRSearchLandingIndex']),
    getTemplateDiagnosis() {
      const diagnosis = cloneDeep(ConditionList.diagnosis);
      diagnosis.tags.push(DiagnosisRanges.ALL);
      diagnosis.tags.push(DiagnosisType.GroupAndMap);
      diagnosis.diagnosisRange = false;
      return diagnosis;
    },
    addInclude() {
      const result = cloneDeep(this.conditions);
      result.push({
        conceptName: `${EMR_SEARCH_TEXT.CRITERIA} ${this.getInExcludeLength + 1}`,
        conceptType: 'include',
        ...this.getTemplateDiagnosis(),
        index: this.getInExcludeLength,
        time: Date.now(),
      });
      this.setCondition(result);
      this.trackClick('addInclude');
    },
    addExclude() {
      const result = cloneDeep(this.excludedConditions);
      result.push({
        conceptName: `${EMR_SEARCH_TEXT.CRITERIA} ${this.getInExcludeLength + 1}`,
        conceptType: 'exclude',
        ...this.getTemplateDiagnosis(),
        index: this.getInExcludeLength,
        time: Date.now(),
      });
      this.setExcludedCondition(result);
      this.trackClick('addExclude');
    },
    clickNext() {
      this.setEMRSearchLandingIndex(3);
    },
    clickNext5() {
      const result = cloneDeep(this.$store.state.condition.conditions);
      result[2] = {
        ...result[2],
        ...getAntiTNFBiologicsConfig(),
      };
      this.setCondition(result);
      this.setEMRSearchLandingIndex(6);
    },
    setPosition() {
      this.$nextTick(() => {
        const offsets = document
          .getElementById(this.EMRSearchLandingIndex === 5 ? 'ConceptEdit-1' : 'ConceptEdit-0')
          .getBoundingClientRect();
        const { top } = offsets;
        const { left } = offsets;
        const { width } = offsets;
        this.top = top;
        this.left = left;
        this.width = width;
      });
    },
    updateLandingMenu(state) {
      this.landingSaveAsMenu = state;
    },
  },
};
</script>
<style lang="scss" scoped>
.in-ex-clude-button {
  background: #0074e6;
  border-radius: 8px;
  padding: 4px 12px;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  cursor: pointer;
}

.concept-logic-text {
  color: #919191;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
}

.landing-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}

.landing-tooltip {
  padding: 20px;
  width: 493px;
  margin-top: 27px;
  background: #ffffff;
  border-radius: 16px;
  margin-left: calc(50% - 493px / 2);
}

.landing-tooltip::before {
  content: '';
  position: absolute;
  display: block;
  width: 0px;
  margin-top: -15px;
  left: calc(50% - 6px);
  border: 12px solid transparent;
  border-top: 0;
  border-bottom: 12px solid #ffffff;
  -webkit-transform: translate(-50%, calc(-100% - 5px));
  -ms-transform: translate(-50%, calc(-100% - 5px));
  transform: translate(-50%, calc(-100% - 5px));
}

.mini-title {
  color: #0074e6;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}

.landing-text {
  color: #212121;
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
}

.landing-content-text {
  color: #262626;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
}

.next-button {
  background: #0074e6;
  border-radius: 8px;
  width: 453px;
  padding: 12px 16px;
  color: #ffffff;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  cursor: pointer;
}

.save-as-menu {
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(185, 185, 185, 0.3), 0px 16px 20px rgba(157, 205, 245, 0.2);
  border-radius: 8px;
  padding: 20px;
  width: 299px;
}

.save-as-text {
  color: #262626;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
}

.tag-name-text {
  color: #5b5b5b;
  font-size: 14px;
  line-height: 17px;
}

.tag-name-input {
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 8px;
}

.cancel-button {
  padding: 8px 16px;
  border: 1px solid #0074e6;
  box-sizing: border-box;
  border-radius: 8px;
  color: #0074e6;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}

.save-as-button {
  padding: 8px 16px;
  background: #0074e6;
  border-radius: 8px;
  color: #ffffff;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}

.landing-2-tooltip {
  padding: 20px;
  width: 339px;
  background: #ffffff;
  border-radius: 16px;
  position: absolute;
}

.landing-2-tooltip::before {
  content: '';
  position: absolute;
  display: block;
  width: 0px;
  margin-top: 65px;
  left: -6px;
  -webkit-transform: translate(-50%, calc(-100% - 5px));
  -ms-transform: translate(-50%, calc(-100% - 5px));
  transform: translate(-50%, calc(-100% - 5px));
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-right: 12px solid #ffffff;
}

.landing-3-tooltip {
  padding: 20px;
  width: 493px;
  background: #ffffff;
  border-radius: 16px;
  position: absolute;
}

.landing-3-tooltip::before {
  content: '';
  position: absolute;
  display: block;
  width: 0px;
  margin-top: 220px;
  left: 250px;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid #ffffff;
  -webkit-transform: translate(-50%, calc(-100% - 5px));
  -ms-transform: translate(-50%, calc(-100% - 5px));
  transform: translate(-50%, calc(-100% - 5px));
}
</style>
