export default {
  conceptName: 'Anti-TNF Biologics',
  conceptType: 'include',
  name: 'Medication',
  tags: ['Basic', 'Raw'],
  andOr: 'should',
  list: [
    {
      code: 'CHUMI02',
      label: 'CHUMI02',
      description: 'Humira 40mg/0.4mL/syringe (Adalimumab)',
      chineseDescription: 'Humira 40mg/0.4mL/syringe (Adalimumab)',
      value: 'CHUMI02',
      type: 'med',
      patientNum: 69,
      recordNum: 1878,
      field: 'med',
      ingredient: 'adalimumab',
      atcCode: 'L04AB04',
      time: 1646288744161,
    },
    {
      code: 'IADAL04',
      label: 'IADAL04',
      description: '需冷藏【點交】Adalimumab (Humira) 40 mg/0.4ml/syringe',
      chineseDescription: '需冷藏【點交】Adalimumab (Humira) 40 mg/0.4ml/syringe',
      value: 'IADAL04',
      type: 'med',
      patientNum: 68,
      recordNum: 2200,
      field: 'med',
      ingredient: 'adalimumab',
      atcCode: 'L04AB04',
      time: 1646288752016,
    },
    {
      code: 'IADAL',
      label: 'IADAL',
      description: '【慢連】冷藏{高貴點交}Adalimumab (Humira) 40mg/0.8ml/syringe',
      chineseDescription: '【慢連】冷藏{高貴點交}Adalimumab (Humira) 40mg/0.8ml/syringe',
      value: 'IADAL',
      type: 'med',
      patientNum: 113,
      recordNum: 7510,
      field: 'med',
      ingredient: 'adalimumab',
      atcCode: 'L04AB04',
      time: 1646288758856,
    },
    {
      code: 'IETAN',
      label: 'IETAN',
      description: '需冷藏 Enbrel 25 mg/mL/vial (Etanercept)',
      chineseDescription: '需冷藏 Enbrel 25 mg/mL/vial (Etanercept)',
      value: 'IETAN',
      type: 'med',
      patientNum: 27,
      recordNum: 138,
      field: 'med',
      ingredient: '',
      atcCode: '',
      time: 1646288768088,
    },
  ],
  diagnosisRange: false,
  index: 1,
};
