<template>
  <div>
    <div class="hint-text">The first admission note/discharge summary of patients in search conditions</div>
    <SearchBar
      style="margin: 20px 0px 10px 0px"
      :configTabs="configTabs"
      v-on:patientResultSetTab="keywordSetTab"
      :uniqueIndex="uniqueIndex"
    />
    <div id="id-block">Patient ID</div>
    <a-spin id="loading-spin" v-if="loadingContent" />
    <div :class="loadingContent ? 'block' : ''" v-if="!loadingContent">
      <b-tabs pills card id="second-tab">
        <b-tab
          v-for="(tab, i) in configTabs"
          :key="tab.key"
          @click="switchTab(tab.name, i)"
          class="discharge-type"
          :title="tab.name"
          :active="tabIndex === i"
        >
          <div v-if="patient > 0">
            <a-row
              class="patient_info"
              type="flex"
              v-for="data in datas"
              :key="data.chartNo"
              justify="center"
              align="middle"
            >
              <a-col @click="enterPatientPage(data.chartNo)" class="chartno-grid" :offset="0" :span="3">
                <div class="chartno">
                  <a-tag class="tag-chart-no">
                    <a-icon type="user" style="color: #333f6b" class="profile-icon" />
                    {{ chartNoFormatter(data.chartNo) }}
                  </a-tag>
                </div>
              </a-col>
              <a-col
                class="text-context-grid"
                :class="data[tab.key].snippet === '' ? 'NIL-style' : ''"
                :offset="0"
                :span="20"
                @click="showFullText(tab.name, data.chartNo, data[tab.key].fullHighlight)"
              >
                <div v-expandable:treatmentViewAll="data" class="text-context">
                  <div v-html="data[tab.key].snippet"></div>
                  <div v-if="data[tab.key].snippet === ''">(NIL)</div>
                </div>
              </a-col>
            </a-row>
          </div>
          <div v-if="showNoResults">
            <a-row type="flex" justify="center" align="top" style="margin-top: 15px">
              <a-col>
                <SearcClearExtendFilterhBar :uniqueIndex="uniqueIndex" />
              </a-col>
            </a-row>
          </div>
        </b-tab>
      </b-tabs>
      <div class="paging-block">
        <div id="last-page-hint" v-if="current === Math.ceil(UICONFIG.PATIENT_SIZE / 10)">
          Show at most {{ UICONFIG.PATIENT_SIZE }} patients.
        </div>
        <a-pagination
          class="pagin"
          size="small"
          v-show="patient > 10"
          v-model="current"
          :total="Math.min(UICONFIG.PATIENT_SIZE, patient)"
          @change="handleTableChange"
          showLessItems
          :itemRender="itemPagerRender"
        />
      </div>
    </div>
    <a-modal
      class="fulltext-modal"
      :visible="visible"
      @ok="handleOk"
      @cancel="handleCancel"
      :mask="true"
      :footer="null"
      width="1010px"
      centered
      :zIndex="9999"
    >
      <div id="modal-title">{{ currentTitle }}</div>
      <div id="modal-chart_no">{{ currentChartNo }}</div>
      <div id="modal-text" v-html="currentText"></div>
      <div v-if="currentText === ''" :class="currentText === '' ? 'NIL-style' : ''">(NIL)</div>
    </a-modal>
  </div>
</template>

<script>
import Expandable from '@/directives/expandable';
import { ClickMixin, TrackTimeMixin, queryMixin, Utils } from '@/mixins';
import { zipObject, debounce } from 'lodash';
import { formatText } from '@/utils/util';
import { mapSubUiConfig } from '@/utils/uiConfig';
import { EMR_SECTION_NAME } from '@/utils/string';
import SearchBar from '@/components/analysis/status/SearchBar.vue';
import SearcClearExtendFilterhBar from '@/components/analysis/status/clear/ClearExtendFilter.vue';
import { UICONFIG, PAGES, VUE_EVENT_NAME } from '@/utils/constants';
import ConditionArray from '@/utils/query/core/ConditionArray';
import { mapState } from '../../store';

const TEMPLATE = {
  pre_tags: '<span style="mix-blend-mode: multiply; background-color: rgba(245, 186, 66, 0.3);">',
  post_tags: '</span>',
};

const TEMPLATE_FULL = {
  pre_tags: '<span style="mix-blend-mode: multiply; background-color: rgba(245, 186, 66, 0.3);">',
  post_tags: '</span>',
  number_of_fragments: 0,
};

export default {
  name: 'PatientResult',
  mixins: [TrackTimeMixin, ClickMixin, queryMixin, Utils],
  components: {
    SearchBar,
    SearcClearExtendFilterhBar,
  },
  directives: {
    expandable: Expandable,
  },
  props: {
    type: String,
    uniqueIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      datas: [],
      total: 0,
      pagination: {},
      visible: false,
      currentText: String,
      currentTitle: String,
      currentChartNo: '',
      loadingContent: false,
      current: 1,
      patientList: [],
      currentTab: 'Chief Complaint',
      UICONFIG,
      PAGES,
      currentCondition: JSON.stringify({
        extendConditions: this.extendConditions,
      }),
      tabIndex: 0,
    };
  },
  async mounted() {
    await this.fetchPatientData().catch((error) => {
      console.error(error);
    });
    this.getInPatientsDatas(1);
  },
  watch: {
    async extendConditions() {
      if (this.tabName === this.type) {
        await this.fetchPatientData().catch((error) => {
          console.error(error);
        });
        this.datas = this.getInPatientsDatas(1);
      } else {
        this.debouncedFetchPatientData();
      }
    },
  },
  computed: {
    ...mapSubUiConfig('Analyze.PatientResult'),
    ...mapState(['tabName']),
    ...mapState('session', ['tabs']),
    tab() {
      if (this.tabs.length === 0) {
        return {
          includes: [],
          excludes: [],
          counters: {
            OPD: 0,
            IPD: 0,
          },
        };
      }
      return this.tabs[this.uniqueIndex];
    },
    extendConditions() {
      return new ConditionArray(this.tab.includes).additions;
    },
    patient() {
      if (this.type === PAGES.INPATIENT_EMR) {
        return this.tab.counters.IPD.Patient;
      }
      if (this.type === PAGES.PATIENT_EMR) {
        return this.tab.counters.ALL.Patient;
      }
      return this.tab.counters.OPD.Patient;
    },
    showNoResults() {
      if (this.loading) {
        return false;
      }
      return this.patient === 0;
    },
    configTabs() {
      if (this.type === PAGES.INPATIENT_EMR) {
        return this.uiConfig.tabs.map((tab) => ({
          name: EMR_SECTION_NAME[tab],
          key: tab,
        }));
      }

      if (this.type === PAGES.OUTPATIENT_EMR) {
        return this.uiConfig.OPD_tabs.map((tab) => ({
          name: EMR_SECTION_NAME[tab],
          key: tab,
        }));
      }

      return this.uiConfig.tabs.map((tab) => ({
        name: EMR_SECTION_NAME[tab],
        key: tab,
      }));
    },
  },
  methods: {
    debouncedFetchPatientData: debounce(async function () {
      await this.fetchPatientData().catch((error) => {
        console.error(error);
      });
      this.datas = this.getInPatientsDatas(1);
    }, 0),
    getHighlight(data, field) {
      const splitToken = '...... ';
      return data.highlight && data.highlight[field]
        ? data.highlight[field].join(splitToken)
        : // eslint-disable-next-line no-underscore-dangle
          data._source[field];
    },
    async getInPatientsDatas(from) {
      try {
        this.loadingContent = true;
        const currentPatientList = this.patientList.slice(10 * (from - 1), 10 * (from - 1) + 10);
        let includeConfig = [];

        if (this.type === PAGES.INPATIENT_EMR || this.type === PAGES.PATIENT_EMR) {
          includeConfig = ['CHART_NO', ...this.uiConfig.tabs];
        } else {
          includeConfig = ['CHART_NO', ...this.uiConfig.OPD_tabs];
        }

        let fieldsContent;

        if (this.type === PAGES.INPATIENT_EMR || this.type === PAGES.PATIENT_EMR) {
          fieldsContent = zipObject(
            this.uiConfig.tabs,
            this.uiConfig.tabs.map(() => TEMPLATE)
          );
        } else {
          fieldsContent = zipObject(
            this.uiConfig.OPD_tabs,
            this.uiConfig.OPD_tabs.map(() => TEMPLATE)
          );
        }

        const highlightConfig = {
          highlight: {
            require_field_match: false,
            fields: fieldsContent,
            // make it like
            // {
            //   PATH_REPORT_1: TEMPLATE,
            //   ...
            //   TREATMENT_PATH: TEMPLATE,
            // }
          },
        };

        if (this.type === PAGES.INPATIENT_EMR || this.type === PAGES.PATIENT_EMR) {
          fieldsContent = zipObject(
            this.uiConfig.tabs,
            this.uiConfig.tabs.map(() => TEMPLATE_FULL)
          );
        } else {
          fieldsContent = zipObject(
            this.uiConfig.OPD_tabs,
            this.uiConfig.OPD_tabs.map(() => TEMPLATE_FULL)
          );
        }

        const additionalAggs = {
          top2: {
            top_hits: {
              sort: [
                {
                  IN_DATE: {
                    order: 'asc',
                  },
                },
              ],
              _source: {
                ...includeConfig.values(),
              },
              size: 1,
              highlight: {
                require_field_match: false,
                fields: fieldsContent,
                // make it like
                // {
                //   PATH_REPORT_1: TEMPLATE,
                //   ...
                //   TREATMENT_PATH: TEMPLATE,
                // }
              },
            },
          },
        };
        const rawDatas = await this.getPatientTopRecord(includeConfig, currentPatientList, {
          highlight: highlightConfig,
          additionalAggs,
          currentView: this.type,
        }).catch((error) => {
          console.error(error);
        });
        const datas = rawDatas.map((item) => {
          const rawData = item.top.hits.hits[0];
          const rawDataFH = item.top2.hits.hits[0];
          // eslint-disable-next-line no-underscore-dangle
          const data = { chartNo: rawData._source.CHART_NO };
          this.configTabs.forEach((tab) => {
            const snippetValue = formatText(this.getHighlight(rawData, tab.key))
              ? formatText(this.getHighlight(rawData, tab.key))
              : '';
            // eslint-disable-next-line no-underscore-dangle
            // eslint-disable-next-line no-underscore-dangle
            const full = formatText(rawData._source[tab.key]);
            const fullHighlight = formatText(this.getHighlight(rawDataFH, tab.key));
            data[tab.key] = { snippet: snippetValue, full, fullHighlight };
          });
          return data;
        });
        this.loadingContent = false;
        const pagination = { ...this.pagination };
        this.datas = datas;
        pagination.total = this.patient;
        this.pagination = pagination;
        return datas;
      } catch (error) {
        this.loadingContent = false;
        this.datas = [];
        this.pagination = 1;
        return [];
      }
    },
    async handleTableChange(pagination) {
      await this.getInPatientsDatas(pagination).catch((error) => {
        console.error(error);
      });
      window.scrollTo(0, 0);
      this.$root.$emit(VUE_EVENT_NAME.SET_VUEPERFECTSCROLLBAR_UPDATE);
      this.trackClick('handleTableChange', { pagination });
    },
    showFullText(title, chartNo, text) {
      this.visible = true;
      this.currentText = text;
      this.currentChartNo = chartNo;
      this.currentTitle = title;
      this.trackClick('showFullText', { title, chartNo, text });
    },
    handleCancel() {
      this.visible = false;
    },
    handleOk() {
      this.visible = false;
    },
    switchTab(tabName, index) {
      this.$forceUpdate();
      this.trackClick('switchTabDischarge', { tabName });
      this.endTime(this.currentTab);
      this.currentTab = tabName;
      this.tabIndex = index;
      this.startTime(this.currentTab);
    },
    itemPagerRender(current, type, originalElement) {
      if (type === 'prev') {
        return (
          <a>
            <b-icon-caret-left-fill />
          </a>
        );
      }
      if (type === 'next') {
        return (
          <a>
            <b-icon-caret-right-fill />
          </a>
        );
      }
      return originalElement;
    },
    enterPatientPage(patientId) {
      this.$emit('showPatient', patientId, this.type);
      this.trackClick('enterPatientPage', { pid: patientId });
      this.startTime('PatientInformationModal', { pid: patientId });
    },
    keywordSetTab(fields) {
      if (fields.length === 1) {
        this.tabIndex = this.configTabs.findIndex((item) => item.key === fields[0]);
      }
    },
    async fetchPatientData() {
      this.loadingContent = true;
      this.patientList = await this.getPatientList(UICONFIG.PATIENT_SIZE, { currentView: this.type }).catch((error) => {
        console.error(error);
      });
      this.current = 1;
      this.currentCondition = JSON.stringify({
        extendConditions: this.extendConditions,
      });
    },
  },
};
</script>

<style scoped>
.red {
  color: red;
}

#second-tab >>> .tab-content {
  margin-top: 10px;
}

.hide-view-all {
  display: none;
}

.pagin {
  float: None;
  margin: 16px 0;
  text-align: center;
}
.pagin >>> .ant-pagination-item-active {
  border: 1px solid #ffffff;
  color: #333f6b;
  font-weight: bold;
}
.pagin >>> .ant-pagination-item-active a {
  color: #333f6b;
}
.pagin >>> .ant-pagination-item-container {
  bottom: 2px;
  font-size: 1px;
}
.pagin >>> .ant-pagination-jump-next,
.pagin >>> .ant-pagination-jump-prev {
  pointer-events: none;
}
#second-tab >>> .card-body {
  padding-top: 0px;
}
.text-context {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  font-size: 16px;
  white-space: pre-wrap;
  padding: 5px;
  line-height: 30px;
  text-align: justify;
  text-justify: inter-ideograph;
}

.text-context-grid {
  display: grid;
  align-content: center;
}

.text-context-grid:hover {
  cursor: pointer;
  color: #333f6b !important;
}

#second-tab {
  margin-left: 120px;
  margin-right: 120px;
}

#id-block {
  width: auto;
  height: 20px;
  opacity: 0.5;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333f6b;
  position: absolute;
  margin-left: calc(17%);
  margin-top: calc(22px);
}

@media (min-width: 1888px) {
  #id-block {
    margin-top: calc(1% + 5px);
    margin-left: calc(8% + 65px);
  }
}

@media (max-width: 1280px) {
  #id-block {
    margin-top: calc(3% + 5px);
    margin-left: calc(8% + 65px);
  }
}

@media (min-width: 1280px) and (max-width: 1888px) {
  #id-block {
    margin-top: calc(3% + 10px);
    margin-left: calc(8% + 65px);
  }
}

.chartno {
  margin: auto 0;
}

.chartno-grid {
  text-align: center;
  display: grid;
}

.chartno-grid:hover {
  cursor: pointer;
}

#modal-text {
  white-space: pre-wrap;
}

#modal-title {
  height: 20px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333f6b;
}

#modal-chart_no {
  height: 18px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #828282;
  margin-top: 5px;
  margin-bottom: 30px;
}

#last-page-hint {
  text-align: center;
}

#loading-spin {
  top: 50%;
  position: fixed;
  left: 50%;
}
.block {
  position: relative;
  background: transparent;
  opacity: 0.5;
}

.block:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}

.tag-chart-no {
  height: 30px;
  border-radius: 3px;
  border: solid 1px #333f6b;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333f6b;
  background-color: white;
  padding: 5px 6px 1px 6px;
  cursor: pointer;
  font-family: monospace, Roboto, Muli, Montserrat, 'OpenSans', 'PingFangTC', '微軟正黑體', 'Microsoft JhengHei',
    sans-serif;
  margin-left: -20px;
}

.NIL-style {
  position: relative;
  color: #828282;
  pointer-events: none;
}

.NIL-style:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}

.profile-icon >>> svg {
  width: 18px;
  height: 18px;
  margin-top: -6px !important;
}
</style>

<style>
.patient_info {
  min-height: 60px;
  /* padding: 21px 0px 21px 0px; */
  border-bottom: 1px #d6d9e1 solid;
  margin: 0px 0px;
}
.tab {
  width: 80%;
}

.nav-item {
  margin-right: 20px;
  margin-bottom: 20px;
}
.nav-link {
  border-radius: 6px !important;
  background-color: #ffffff !important;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333f6b !important;
  border: 1px #333f6b solid;
}
.nav-link.active {
  border-radius: 6px !important;
  background-color: #333f6b !important;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff !important;
}
</style>

<style lang="scss" scoped>
/deep/ .card-header {
  background-color: transparent !important;
  border-bottom-width: 0px !important;
  margin-left: calc(16%);
}

.hint-text {
  height: 24px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
}
</style>
