<template>
  <a-modal
    :visible="EMRSearchLandingIndex === 8"
    :mask="true"
    :destroyOnClose="true"
    :footer="null"
    :zIndex="9999"
    :closable="false"
    :maskClosable="false"
    centered
  >
    <div class="d-flex justify-content-center">
      <b-img src="@/assets/images/end_landing.png" style="width: 144px; height: 144px" />
    </div>
    <div class="landing-text mt-10px">恭喜!</div>
    <div class="landing-content-text mt-10px">
      現在你已經知道如何在 EMR Search 利用 concept 來定義條件，開始設定你的題目吧！
    </div>
    <div class="landing-button mt-40px" @click="clickGo">Go</div>
  </a-modal>
</template>
<script>
import { mapState, mapMutations } from '@/store';

export default {
  name: 'EndLandingModal',
  computed: {
    ...mapState(['EMRSearchLandingIndex']),
  },
  methods: {
    ...mapMutations(['setEMRSearchLandingIndex']),
    clickGo() {
      this.setEMRSearchLandingIndex(0);
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .ant-modal-body {
  padding: 40px;
  background: #ffffff;
  border-radius: 16px;
  width: 403px;
}

/deep/ .ant-modal-content {
  border-radius: 16px;
  width: 403px;
}

.landing-text {
  color: #212121;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
}

.landing-content-text {
  color: #262626;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
}

.landing-button {
  cursor: pointer;
  width: 323px;
  background: #0074e6;
  border-radius: 8px;
  padding: 12px 16px;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  text-align: center;
}
</style>
