<template>
  <div class="saved-search">
    <a-layout>
      <a-layout-content>
        <a-tabs default-active-key="Result" id="analyze-tab" type="card" style="margin-top: 15px">
          <a-tab-pane key="Result" :closable="false" style="background-color: #ffffff" :forceRender="true">
            <template slot="tab">
              <div class="custom-tab" @click="switchSavedSearchTab('Result')">
                <div class="border-fixer">Results</div>
              </div>
            </template>
            <savedList />
          </a-tab-pane>
          <a-tab-pane key="History" :closable="false" style="background-color: #ffffff" :forceRender="true">
            <template slot="tab">
              <div class="custom-tab" @click="switchSavedSearchTab('History')">
                <div class="border-fixer">History</div>
              </div>
            </template>
            <HistoryList />
          </a-tab-pane>
        </a-tabs>
      </a-layout-content>
    </a-layout>
    <div class="delete-query-text" v-if="deleteQueryText">Deleted</div>
    <div class="link-copied-text" v-if="linkCopiedText">Link copied</div>
    <div class="error-menu" v-if="errorMenu">
      <b-row no-gutters>
        <b-col>
          <b-row no-gutters>
            <b-col class="error-title">{{ errorTitle }}</b-col>
          </b-row>
          <b-row no-gutters>
            <b-col class="error-content">{{ errorContent }} </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import savedList from '@/components/saveSearch/SavedList.vue';
import HistoryList from '@/components/saveSearch/HistoryList.vue';
import TrackTimeMixin from '@/mixins/trackTimeMixin';
import ClickMixin from '@/mixins/clickMixin';
import { VUE_EVENT_NAME } from '@/utils/constants';

export default {
  name: 'SavedSearch',
  mixins: [TrackTimeMixin, ClickMixin],
  components: {
    savedList,
    HistoryList,
  },
  data() {
    return {
      deleteQueryText: false,
      linkCopiedText: false,
      errorMenu: false,
    };
  },
  mounted() {
    this.startTime('SavedSearch');
    this.$root.$on(VUE_EVENT_NAME.OPEN_DELETED_TEXT_IN_SAVED_SEARCH, () => {
      this.deleteQueryText = true;
      this.linkCopiedText = false;
      this.errorMenu = false;
    });

    this.$root.$on(VUE_EVENT_NAME.CLOSE_DELETED_TEXT_IN_SAVED_SEARCH, () => {
      this.deleteQueryText = false;
    });

    this.$root.$on(VUE_EVENT_NAME.OPEN_LINK_COPIED_TEXT_IN_SAVED_SEARCH, () => {
      this.deleteQueryText = false;
      this.linkCopiedText = true;
      this.errorMenu = false;
    });

    this.$root.$on(VUE_EVENT_NAME.CLOSE_LINK_COPIED_TEXT_IN_SAVED_SEARCH, () => {
      this.linkCopiedText = false;
    });

    this.$root.$on(VUE_EVENT_NAME.OPEN_ERROR_MENU_IN_SAVED_SEARCH, (errorTitle, errorContent) => {
      this.errorMenu = true;
      this.deleteQueryText = false;
      this.linkCopiedText = false;
      this.errorTitle = errorTitle;
      this.errorContent = errorContent;
    });

    this.$root.$on(VUE_EVENT_NAME.CLOSE_ERROR_MENU_IN_SAVED_SEARCH, () => {
      this.errorMenu = false;
    });
  },
  destroyed() {
    this.$root.$off(VUE_EVENT_NAME.OPEN_DELETED_TEXT_IN_SAVED_SEARCH);
    this.$root.$off(VUE_EVENT_NAME.CLOSE_DELETED_TEXT_IN_SAVED_SEARCH);
    this.$root.$off(VUE_EVENT_NAME.OPEN_LINK_COPIED_TEXT_IN_SAVED_SEARCH);
    this.$root.$off(VUE_EVENT_NAME.CLOSE_LINK_COPIED_TEXT_IN_SAVED_SEARCH);
    this.$root.$off(VUE_EVENT_NAME.OPEN_ERROR_MENU_IN_SAVED_SEARCH);
    this.$root.$off(VUE_EVENT_NAME.CLOSE_ERROR_MENU_IN_SAVED_SEARCH);
  },
  methods: {
    switchSavedSearchTab(name) {
      this.trackClick('switchSavedSearchTab', { name });
    },
  },
};
</script>
<style scoped>
.saved-search {
  height: 95%;
  background-color: #fff;
  min-height: 900px;
}

#analyze-tab >>> .ant-tabs-tab-active > .custom-tab {
  font-weight: bold;
  border-bottom: 5px solid #333f6b;
  position: relative;
  top: -2px;
}
#analyze-tab >>> .ant-tabs-tab-active > .custom-tab > .border-fixer {
  position: relative;
}

.delete-query-text {
  width: 100px;
  height: 54px;
  padding: 15px 20px;
  border-radius: 6px;
  box-shadow: 0 4px 10px 0 rgba(51, 63, 107, 0.2);
  border: solid 1px #ffffff;
  background-color: #da6d6d;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  position: fixed;
  bottom: 80px;
  left: calc(50% - 44px);
}

.link-copied-text {
  width: 130px;
  height: 54px;
  padding: 15px 20px;
  border-radius: 6px;
  -webkit-box-shadow: 0 4px 10px 0 rgba(51, 63, 107, 0.2);
  box-shadow: 0 4px 10px 0 rgba(51, 63, 107, 0.2);
  border: solid 1px #ffffff;
  background-color: #758cc8;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  position: fixed;
  bottom: 80px;
  left: calc(50% - 205px);
}

.error-menu {
  width: 340px;
  padding: 15px 20px;
  border-radius: 6px;
  box-shadow: 0 4px 10px 0 rgba(51, 63, 107, 0.2);
  border: solid 1px #ffffff;
  background-color: #da6d6d;
  position: fixed;
  bottom: 80px;
  left: calc(50% - 140px);
}

.error-title {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  margin-bottom: 5px;
}

.error-content {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}
</style>
<style lang="scss" scoped>
.saved-search {
  .ant-layout {
    background-color: #ffffff;
  }
}
</style>
