import BasicChartConfig from '@/components/charts/core/BasicChartConfig';
import {
  BasicChartQueryParams,
  ChartItem,
  ChartViewState,
  RawItem,
  ChartKey,
} from '@/components/charts/core/Interface';
import { isValidSome } from '@/utils/util';
import { UICONFIG } from '@/utils/constants';
import {
  ConditionName,
  RangeCondition,
  RangeConditionData,
  RangeData,
  SearchType,
  VisitType,
} from '../../utils/conditions/core';
import { removePoint, UNSELECTED_COLOR } from './utils/utils';

const XAXIS_LABEL = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10+'];

const RANGES = [
  { from: 0, to: 2 },
  { from: 2, to: 3 },
  { from: 3, to: 4 },
  { from: 4, to: 5 },
  { from: 5, to: 6 },
  { from: 6, to: 7 },
  { from: 7, to: 8 },
  { from: 8, to: 9 },
  { from: 9, to: 10 },
  { from: 10 },
];

const OPTION = {
  grid: {
    top: 30,
  },
  xAxis: {
    type: 'category',
    data: XAXIS_LABEL,
    triggerEvent: true,
    name: 'Day',
    nameLocation: 'end',
    nameGap: -30,
    nameTextStyle: {
      padding: [60, 0, 0, 0],
      color: '#757575',
    },
  },
  yAxis: {
    type: 'value',
    nameLocation: 'end',
    name: 'Record',
    nameTextStyle: {
      color: '#757575',
    },
    axisLabel: {
      formatter: removePoint,
    },
  },
};

interface StayRawItem extends RawItem {
  from?: number;
  to?: number;
}

function splitAndFetch(key: string): number {
  return Number(`${key}`.split('-')[0]);
}

function transformDuration(data: StayRawItem[]): StayRawItem[] {
  const stayCounter = new Map<number, number>();
  const max = Math.max(10, Math.min(40, splitAndFetch(data[data.length - 1].key)));
  data.forEach((item: StayRawItem) => {
    if (!stayCounter.has(splitAndFetch(item.key))) {
      stayCounter.set(splitAndFetch(item.key), 0);
    }
    if (item.from === 0) {
      stayCounter.set(1, item.doc_count);
    } else if (splitAndFetch(item.key) >= 40) {
      if (!stayCounter.has(40)) {
        stayCounter.set(40, 0);
      }
      const stay40 = stayCounter.get(40);
      stayCounter.set(40, stay40! + item.doc_count);
    } else {
      const stayDays = stayCounter.get(splitAndFetch(item.key));
      stayCounter.set(splitAndFetch(item.key), stayDays! + item.doc_count);
    }
  });
  const result: StayRawItem[] = [];
  for (let i = 1; i <= max; i += 1) {
    if (stayCounter.has(i)) {
      result.push({
        ...RANGES[i - 1],
        doc_count: stayCounter.get(i)!,
        key: `${i}`,
      });
    } else {
      result.push({
        ...RANGES[i - 1],
        doc_count: 0,
        key: `${i}`,
      });
    }
  }
  if (splitAndFetch(result[result.length - 1].key) === 40) {
    result[result.length - 1].key = '40+';
  }
  return result;
}

export default class StayConfig extends BasicChartConfig<StayRawItem> {
  constructor() {
    super(ChartKey.Stay);

    this.hasViewAll = true;
    this.bucketByPatient = false;
    this.disallowBucketChange = true;
    this.merge(OPTION);
  }

  protected get hasData(): boolean {
    return isValidSome(this.data, 0);
  }

  get isMaximize() {
    return this.params.chartParams.viewState === ChartViewState.Maximize;
  }

  protected setAxisData(): void {
    if (!this.isMaximize) {
      return;
    }

    this.unset('xAxis.data');
    this.merge({
      xAxis: {
        data: this.data.map((item) => item.key),
      },
    });
  }

  public createViewAllConfig(): StayConfig {
    const config = new StayConfig();
    config.resultSize = UICONFIG.VIEW_ALL_SIZE;
    config.bucketByPatient = this.bucketByPatient; // Copy over previous bucketing status.
    config.merge({
      title: {
        show: false,
      },
    });
    config.assign({
      xAxis: {
        type: 'category',
        triggerEvent: true,
      },
    });
    return config;
  }

  protected createChartData(rawData: any) {
    return transformDuration(rawData).map((item: any, index: number) => this.createChartItem(item, index));
  }

  protected processQueryParams(): BasicChartQueryParams {
    const condList = this.params.includes.without(ConditionName.Stay);
    return {
      ...this.params,
      includes: condList,
      visitType: VisitType.IPD,
    };
  }

  protected createChartItem(rawItem: StayRawItem, index: number): ChartItem {
    const { key, doc_count: value } = rawItem;
    const condList = this.params.includes.additions.with(ConditionName.Stay);

    let color = '#333f6b';
    if (condList.length !== 0) {
      const condition = condList[0] as RangeCondition;
      color = UNSELECTED_COLOR;
      if (this.params.chartParams.viewState !== ChartViewState.Maximize) {
        if (condition.hitStartOrEnd(rawItem.from, rawItem.to)) {
          color = '#333f6b';
        }
      } else if (condition.ranges.some((range) => range.start === Number(rawItem.key))) {
        color = '#333f6b';
      }
    }

    return {
      key: this.isMaximize ? key : XAXIS_LABEL[index],
      value,
      code: key,
      name: key,
      color,
      percentage: this.calculatePercentage(value),
    };
  }

  public createConditionFromItem(chartItem: ChartItem): RangeConditionData {
    const { name } = chartItem;
    const condition = {
      name: ConditionName.Stay,
      tags: [SearchType.Basic, SearchType.Additional],
    };

    const ranges: RangeData[] = [];

    if (name === '1') {
      ranges.push({ start: 0, end: 1 });
    } else if (name === '40+') {
      ranges.push({ start: 40, end: 999999 });
    } else if (name === '10+') {
      ranges.push({ start: 10, end: 999999 });
    } else {
      ranges.push({ start: Number(name), end: Number(name) });
    }

    return {
      ...condition,
      ranges,
    };
  }

  protected get aggsQuery(): Record<string, unknown> {
    if (this.resultSize === 10) {
      return {
        range: {
          field: 'DURATION',
          ranges: RANGES,
        },
      };
    }

    return {
      terms: {
        field: 'DURATION',
        size: this.resultSize,
        shard_size: UICONFIG.SHARD_SIZE,
      },
    };
  }
}
