<template>
  <a v-bind:href="enable ? (url ? url : '') : ''" class="mainLink">
    <div :class="mainClass" @click="goto()">
      <div class="service-icon">
        <img class="service-img" :src="serviceImg" alt="aics service" />
      </div>
      <div class="service-title">
        {{ title }}
      </div>
    </div>
  </a>
</template>

<script>
import { ClickMixin } from '@/mixins';

export default {
  name: 'Service',
  mixins: [ClickMixin],
  props: {
    code: String,
    title: String,
    info: String,
    serviceImg: String,
    url: String,
    enable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    mainClass() {
      return {
        'main-card': true,
        'cursor-pointer': this.enable,
        'cursor-stop': !this.enable,
      };
    },
  },
  methods: {
    async goto() {
      if (this.enable) {
        await this.trackClick('goto', { name: 'goto', url: this.url });
        window.location.href = this.url;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mainLink:hover {
  text-decoration: none;
}

.main-card {
  width: 100px;
  height: 140px;
  border-radius: 4px;
}

.main-card:hover {
  border-radius: 4px;
  background-color: #eaf2fd;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-stop {
  cursor: no-drop;
}

.service-icon {
  width: 60px;
  height: 60px;
  background-color: transparent;
  margin-top: 8px;
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 8px;
}

.service-title {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333f6b;
  padding-bottom: 8px;
  text-align: center;
  white-space: pre-wrap;
}

.service-info {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4f4f4f;
  margin-left: 50px;
  margin-right: 50px;
  text-align: center;
}

.service-img {
  width: 60px;
  height: 60px;
}
</style>
