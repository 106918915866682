import { BasicFinalQueryParams } from '@/utils/query/core/Interface';
import { VisitType } from '../../conditions/core';
import AggsQuery from './AggregationQuery';
import ConditionArray from './ConditionArray';
import ConditionQuery from './ConditionQuery';

class ESQuery<AggsQueryType extends AggsQuery> {
  private readonly query: ConditionQuery;

  private readonly params: BasicFinalQueryParams;

  constructor(params?: BasicFinalQueryParams, private readonly aggsQuery?: AggsQueryType) {
    this.params = params || {
      includes: new ConditionArray([]),
      excludes: new ConditionArray([]),
    };
    this.query = new ConditionQuery(this.params.includes, this.params.excludes);
  }

  public get viditType(): VisitType {
    return this.params.visitType ? this.params.visitType : VisitType.ALL;
  }

  public get conditionQuery(): ConditionQuery {
    return this.query;
  }

  public get aggregationQuery(): AggsQueryType {
    if (this.aggsQuery === undefined) {
      throw new Error('Not assigned aggsQuery yet!');
    }
    return this.aggsQuery;
  }

  public getQuery(): Record<string, unknown> {
    return {
      ...this.query.getQuery(),
      aggs: this.aggsQuery ? this.aggsQuery.getAggsQuery() : {},
      size: 0,
      track_total_hits: true,
      comp: this.viditType.toString(),
    };
  }
}

export default ESQuery;
