import { CategoryConditionData, ConditionName, LogicOp, SearchType } from '../conditions/core';

function getExtendCondition(title: string, name: string): CategoryConditionData {
  if (title !== 'Chart no') {
    throw new Error('No matched extend condition');
  }
  return {
    name: ConditionName.ChartNo,
    tags: [SearchType.Basic, SearchType.Additional],
    andOr: LogicOp.Or,
    list: typeof name === 'string' ? [name] : [...(name as string[])],
  };
}

export default getExtendCondition;
