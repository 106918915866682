import {
  getPatientListAggsQuery,
  getExtendCondition,
  getPatientTopRecordAggsQuery,
  getLabHistogramAggsQuery,
  getLabHistogramInfoAggsQuery,
} from '@/utils/query';
import { round, multiply, floor, ceil } from 'lodash';
import { FEEDBACK, PAGES } from '@/utils/constants';
import { mapState } from '../store';
import ConditionArray from '../utils/query/core/ConditionArray';

const CHUNCK_SIZE = 25;

export default {
  computed: {
    ...mapState(['tabName']),
    ...mapState('session', ['tabs']),
    currentLogConditions() {
      return this.$store.getters['condition/currentConditions'];
    },
    tab() {
      return this.tabs[this.uniqueIndex];
    },
    conditions() {
      return new ConditionArray(this.tab.includes).raws;
    },
  },
  methods: {
    getPatientList(size = CHUNCK_SIZE, customValue = { currentView: '' }) {
      const query = this.getQuery({
        includes: this.tab.includes,
        excludes: this.tab.excludes,
      });
      const queryWithAggs = getPatientListAggsQuery(query, size);

      if (customValue.currentView === PAGES.OUTPATIENT_EMR) {
        queryWithAggs.comp = 'OPD';
      } else if (customValue.currentView === PAGES.INPATIENT_EMR) {
        queryWithAggs.comp = 'IPD';
      }
      const priority = this.tab.data.analyzeTab === customValue.currentView ? 1 : 0;
      this.$api.setLogQueryInfo({
        type: FEEDBACK.QUERY_TYPE.PATIENT_AGGREGATE,
        conditions: this.currentLogConditions,
      });
      return this.$api
        .search(queryWithAggs, priority)
        .then((response) => {
          if ('buckets' in response.aggregations.patientNum) {
            return response.aggregations.patientNum.buckets.map((hit) => hit.key);
          }
          return [];
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async getPatientTopRecord(includes, patientList = [], customValue = { currentView: '' }) {
      const result = [];
      const priority = this.tab.data.analyzeTab === customValue.currentView ? 1 : 0;

      for (let i = 0; i < patientList.length; i += CHUNCK_SIZE) {
        const slicePatientList = patientList.slice(i, i + CHUNCK_SIZE);
        const query = this.getQuery({
          includes: this.tab.includes,
          excludes: this.tab.excludes,
        });
        const queryWithAggs = getPatientTopRecordAggsQuery(query, includes, { ...customValue, slicePatientList });

        if (customValue.currentView === PAGES.OUTPATIENT_EMR) {
          queryWithAggs.comp = 'OPD';
        } else if (customValue.currentView === PAGES.INPATIENT_EMR) {
          queryWithAggs.comp = 'IPD';
        }
        this.$api.setLogQueryInfo({
          type: FEEDBACK.QUERY_TYPE.PATIENT_TOP_AGGREGATE,
          conditions: this.currentLogConditions,
          additionalCondition: [getExtendCondition('Chart no', slicePatientList)],
        });
        // eslint-disable-next-line no-await-in-loop
        const buckets = await this.$api
          .search(queryWithAggs, priority)
          .then((response) => response.aggregations.patientNum.buckets)
          .catch((error) => {
            console.error(error);
          });
        result.push(...buckets);
      }
      return result;
    },
    getLabHistogramInfo(CHSPECI, CHHEAD, UNIT, newExtendConditions, tag = 'first') {
      const priority = 1;
      const query = this.getQuery({
        includes: this.conditions.concat(newExtendConditions),
        excludes: this.tab.excludes,
      });
      const queryWithAggs = getLabHistogramInfoAggsQuery(query, CHSPECI, CHHEAD, UNIT, tag);
      this.$api.setLogQueryInfo({
        type: FEEDBACK.QUERY_TYPE.LAB_HISTOGRAM_INFO_AGGREGATE,
        conditions: this.currentLogConditions,
      });
      return this.$api
        .search(queryWithAggs, priority)
        .then((res) => res.aggregations.path1.path_lab.buckets[0].path4)
        .catch((error) => {
          console.error(error);
        });
    },
    getInterval(originalUpper, originalLower) {
      const diff = originalUpper - originalLower;
      let start = 1;
      let interval = 0.1;
      let digit = 1;
      if (diff === 0) {
        interval = 0;
      } else if (diff >= 1) {
        while (!(diff >= start && diff < start * 10)) {
          interval = start;
          start *= 10;
        }
      } else if (diff < 1) {
        while (!(diff >= start && diff < start * 10)) {
          digit += 1;
          start /= 10;
          interval = start;
        }
      }
      const max = multiply(round(originalUpper / interval, 0), interval);
      const min = multiply(floor(originalLower / interval, 0), interval);
      return {
        max,
        min,
        interval,
        digit,
      };
    },
    async getLabHistogramData({ CHSPECI, CHHEAD, UNIT, newExtendConditions, labInfo, tag = 'first' }) {
      const priority = 1;
      const query = this.getQuery({
        includes: this.conditions.concat(newExtendConditions),
        excludes: this.tab.excludes,
      });
      const rangeQuery = this.getQuery({
        includes: this.tab.includes,
        excludes: this.tab.excludes,
      });
      const { max, min, interval, digit } = this.getInterval(
        Math.min(labInfo.std_deviation_bounds.upper, labInfo.max),
        Math.max(labInfo.std_deviation_bounds.lower, labInfo.min)
      );

      const ranges = [];
      if (interval !== 0) {
        ranges.push({ to: round(min, digit) });
        for (let i = 0; i < (max - min) / interval; i += 1) {
          ranges.push({
            from: round(min + interval * i, digit),
            to: round(min + interval * (i + 1), digit),
          });
        }
        ranges.push({ from: round(max, digit) });
      } else {
        ranges.push({
          from: floor(labInfo.max, 2),
          to: ceil(labInfo.max + 1, 2),
        });
      }
      const queryWithAggs = getLabHistogramAggsQuery(query, CHSPECI, CHHEAD, UNIT, ranges, tag);

      const rangeQueryWithAggs = getLabHistogramAggsQuery(rangeQuery, CHSPECI, CHHEAD, UNIT, ranges, tag);

      const seriesDataWithRange = await this.$api
        .search(rangeQueryWithAggs, priority)
        // eslint-disable-next-line max-len
        .then((response) => response.aggregations.patientNum.recordCount.buckets[0].lab_value.buckets)
        .catch((error) => {
          console.error(error);
        });

      this.$api.setLogQueryInfo({
        type: FEEDBACK.QUERY_TYPE.LAB_HISTOGRAM_DATA_AGGREGATE,
        conditions: this.currentLogConditions,
      });
      return (
        this.$api
          .search(queryWithAggs, priority)
          // eslint-disable-next-line max-len
          .then((response) => ({
            seriesData: response.aggregations.patientNum.recordCount.buckets[0].lab_value.buckets,
            highBound: response.aggregations.patientNum.recordCount.buckets[0].highbound.buckets[0].key,
            lowBound: response.aggregations.patientNum.recordCount.buckets[0].lowbound.buckets[0].key,
            digit,
            seriesDataWithRange,
          }))
          .catch((error) => {
            console.error(error);
          })
      );
    },
  },
};
