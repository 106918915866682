<template>
  <div class="cohort-group" :class="{ 'search-group': this.flowType[0] !== 'session' }">
    <b-row no-gutters class="sticky-header">
      <b-col>
        <div class="d-flex align-items-center justify-content-between" style="padding: 30px 0px">
          <div class="d-flex align-items-center">
            <div class="patient-count">{{ getPatientCount }}</div>
            <div class="patient-text ml-10px">patients</div>
            <div
              class="d-flex align-items-center ml-10px patient-count-button"
              :class="{ 'button-disable': !hasCondition || getLoadingCount !== 0 }"
              @click="updatePatientCount"
            >
              <b-img
                src="@/assets/images/icon_event_count_loading.svg"
                style="cursor: pointer"
                v-if="getLoadingCount === 0"
              />
              <a-progress
                v-else
                type="circle"
                :percent="progressPercent"
                :width="19"
                :showInfo="false"
                :strokeWidth="10"
                strokeColor="#ffffff"
                trailColor="none"
              />
              <div class="count-patient-text ml-10px" v-if="getLoadingCount === 0">Calculate</div>
              <div class="count-patient-text ml-10px" v-else>{{ `Calculating ... ${progressPercent}%` }}</div>
              <div class="update-dot ml-5px" v-if="updateDot"></div>
            </div>
          </div>
          <div class="d-flex align-items-center" v-if="!editMode">
            <b-img
              src="@/assets/images/icon_clear_cohort.svg"
              class="mr-10px"
              style="cursor: pointer"
              title="Clear cohort"
              @click="setDefaultCohort"
            />
            <b-img
              src="@/assets/images/icon_import_event_cohort.svg"
              class="mr-10px"
              style="cursor: pointer"
              title="Import cohort"
              @click="clickImportCohort"
            />
            <div v-click-outside="closeCopyLinkMenu">
              <b-img
                src="@/assets/images/icon_save_as_event_cohort.svg"
                class="mr-15px"
                style="cursor: pointer"
                :class="{
                  'button-disable':
                    !hasCondition ||
                    hasSpecialChar(SPECIAL_CHARS.COHORT_NAME, SearchStateObject.name) ||
                    hasSpecialChar(SPECIAL_CHARS.COHORT_NAME, SearchStateObject.eventList[0].name) ||
                    hasSpecialChar(SPECIAL_CHARS.COHORT_NAME, SearchStateObject.indexEvent.name) ||
                    !stringLessLength(SearchStateObject.name, MAX_NAME_LENGTH),
                }"
                title="Save as"
                @click="openCopyLinkMenu"
              />
              <div class="share-link-menu" v-if="copyLinkMenu">
                <b-row no-gutters>
                  <b-col>
                    <b-row no-gutters>
                      <b-col class="title-text">Save As</b-col>
                      <b-col>
                        <b-img src="@/assets/images/icon_close.svg" class="icon-close" @click="closeCopyLinkMenu(0)" />
                      </b-col>
                    </b-row>
                    <b-row no-gutters>
                      <b-col class="name-text">Cohort Name</b-col>
                    </b-row>
                    <b-row no-gutters>
                      <b-col>
                        <b-input
                          placeholder="Cohort name"
                          v-model="nameText"
                          class="name-input"
                          ref="saveQueryButtonInput"
                        ></b-input>
                      </b-col>
                    </b-row>
                    <b-row no-gutters>
                      <b-col>
                        <b-button variant="outline-primary" class="copy-link-button" @click="saveQuery"
                          >Save As</b-button
                        >
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </div>
            </div>
            <b-img
              src="@/assets/images/icon_save_event_cohort.svg"
              style="cursor: pointer"
              :class="{
                'button-disable':
                  !getId ||
                  hasSpecialChar(SPECIAL_CHARS.COHORT_NAME, SearchStateObject.name) ||
                  hasSpecialChar(SPECIAL_CHARS.COHORT_NAME, SearchStateObject.eventList[0].name) ||
                  hasSpecialChar(SPECIAL_CHARS.COHORT_NAME, SearchStateObject.indexEvent.name) ||
                  !stringLessLength(SearchStateObject.name, MAX_NAME_LENGTH),
              }"
              title="Save"
              @click="updateQuery"
            />
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row no-gutters>
      <b-col>
        <div class="d-flex align-items-center">
          <template v-if="isEditCohortName">
            <a-tooltip
              placement="bottom"
              overlayClassName="edit-name-error-tooltip"
              :visible="
                hasSpecialChar(SPECIAL_CHARS.COHORT_NAME, cohortName) || !stringLessLength(cohortName, MAX_NAME_LENGTH)
              "
              :get-popup-container="getPopupContainer"
            >
              <template slot="title">
                <div>Analysis name must be 30 characters or less and can’t contain any of the following charecter</div>
                <div>[ ] : * ? / \</div>
              </template>
              <div class="d-flex align-items-center">
                <input
                  v-model="cohortName"
                  ref="editCohortName"
                  v-click-outside="closeEditCohortName"
                  @keyup.enter="closeEditCohortName"
                />
              </div>
            </a-tooltip>
          </template>
          <template v-else>
            <a-tooltip
              placement="bottom"
              overlayClassName="edit-name-error-tooltip"
              :visible="
                hasSpecialChar(SPECIAL_CHARS.COHORT_NAME, SearchStateObject.name) ||
                !stringLessLength(SearchStateObject.name, MAX_NAME_LENGTH)
              "
              :get-popup-container="getPopupContainer"
            >
              <template slot="title">
                <div>Analysis name must be 30 characters or less and can’t contain any of the following charecter</div>
                <div>[ ] : * ? / \</div>
              </template>
              <div class="d-flex align-items-center">
                <div class="group-title" style="cursor: pointer" @click.stop="clickEditCohortName">
                  {{ SearchStateObject.name }}
                </div>
                <b-img
                  src="@/assets/images/icon_study_edit.svg"
                  class="ml-10px"
                  style="cursor: pointer"
                  @click.stop="clickEditCohortName"
                />
              </div>
            </a-tooltip>
          </template>
        </div>
      </b-col>
    </b-row>
    <b-row no-gutters>
      <b-col>
        <div class="d-flex align-items-center mt-20px">
          <div class="second-group-title mr-10px">Gender</div>
          <b-form-checkbox v-model="male" value="accepted" unchecked-value="not_accepted"> </b-form-checkbox>
          <div class="second-text ml-5px">Male</div>
          <b-form-checkbox v-model="female" value="accepted" unchecked-value="not_accepted" class="ml-20px">
          </b-form-checkbox>
          <div class="second-text ml-5px">Female</div>
        </div>
      </b-col>
    </b-row>
    <b-row no-gutters>
      <b-col>
        <div class="d-flex justify-content-between align-items-end mt-30px">
          <div class="d-flex align-items-center">
            <template v-if="isEditPopulationName">
              <a-tooltip
                placement="bottom"
                overlayClassName="edit-name-error-tooltip"
                :visible="hasSpecialChar(SPECIAL_CHARS.COHORT_NAME, populationName)"
                :get-popup-container="getPopupContainer"
              >
                <template slot="title">
                  <div>Analysis name can’t contain any of the following charecter</div>
                  <div>[ ] : * ? / \</div>
                </template>
                <div class="d-flex align-items-center">
                  <input
                    v-model="populationName"
                    ref="editPopulationName"
                    v-click-outside="closeEditPopulationName"
                    @keyup.enter="closeEditPopulationName"
                  />
                </div>
              </a-tooltip>
            </template>
            <template v-else>
              <a-tooltip
                placement="bottom"
                overlayClassName="edit-name-error-tooltip"
                :visible="hasSpecialChar(SPECIAL_CHARS.COHORT_NAME, SearchStateObject.eventList[0].name)"
                :get-popup-container="getPopupContainer"
              >
                <template slot="title">
                  <div>Analysis name can’t contain any of the following charecter</div>
                  <div>[ ] : * ? / \</div>
                </template>
                <div class="d-flex align-items-center">
                  <div class="first-text" style="cursor: pointer" @click.stop="clickEditPopulationName">
                    {{ SearchStateObject.eventList[0].name }}
                  </div>
                  <b-img
                    src="@/assets/images/icon_study_edit.svg"
                    class="mid-edit ml-5px"
                    @click.stop="clickEditPopulationName"
                  />
                </div>
              </a-tooltip>
            </template>
          </div>
          <div class="d-flex align-items-center clear-button" @click="clearPopulation">
            <b-img src="@/assets/images/icon_clear_event.svg" />
            <div class="clear-text ml-5px">Clear</div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row no-gutters>
      <b-col>
        <EventContainer
          :index="1"
          :eventType="EventType.FIX_EVENT"
          :event="SearchStateObject.eventList[0]"
          :path="flowType.concat(['eventList', '0'])"
          class="mt-10px"
          ref="population"
        />
      </b-col>
    </b-row>
    <b-row no-gutters class="row-margin-10">
      <b-col class="d-flex align-items-center mt-40px">
        <b-img src="@/assets/images/icon_timeline_event.svg" />
        <EventTimeRelation :index="1" :eventType="EventType.FIX_EVENT" :path="flowType.concat(['eventList', '0'])" />
      </b-col>
    </b-row>
    <b-row no-gutters>
      <b-col>
        <div class="d-flex justify-content-between align-items-end mt-30px">
          <div class="d-flex align-items-center">
            <template v-if="isEditInterventionName">
              <a-tooltip
                placement="bottom"
                overlayClassName="edit-name-error-tooltip"
                :visible="hasSpecialChar(SPECIAL_CHARS.COHORT_NAME, interventionName)"
                :get-popup-container="getPopupContainer"
              >
                <template slot="title">
                  <div>Analysis name can’t contain any of the following charecter</div>
                  <div>[ ] : * ? / \</div>
                </template>
                <div class="d-flex align-items-center">
                  <input
                    v-model="interventionName"
                    ref="editInterventionName"
                    v-click-outside="closeEditInterventionName"
                    @keyup.enter="closeEditInterventionName"
                  />
                </div>
              </a-tooltip>
            </template>
            <template v-else>
              <a-tooltip
                placement="bottom"
                overlayClassName="edit-name-error-tooltip"
                :visible="hasSpecialChar(SPECIAL_CHARS.COHORT_NAME, SearchStateObject.indexEvent.name)"
                :get-popup-container="getPopupContainer"
              >
                <template slot="title">
                  <div>Analysis name can’t contain any of the following charecter</div>
                  <div>[ ] : * ? / \</div>
                </template>
                <div class="d-flex align-items-center">
                  <div class="first-text" style="cursor: pointer" @click.stop="clickEditInterventionName">
                    {{ SearchStateObject.indexEvent.name }}
                  </div>
                  <b-img
                    src="@/assets/images/icon_study_edit.svg"
                    class="mid-edit ml-5px"
                    @click.stop="clickEditInterventionName"
                  />
                </div>
              </a-tooltip>
            </template>
          </div>
          <div class="d-flex align-items-center clear-button" @click="clearIntervention">
            <b-img src="@/assets/images/icon_clear_event.svg" />
            <div class="clear-text ml-5px">Clear</div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row no-gutters>
      <b-col>
        <EventContainer
          :index="2"
          :eventType="EventType.FIX_EVENT"
          :event="SearchStateObject.indexEvent"
          :path="flowType.concat(['indexEvent'])"
          class="mt-10px"
          ref="intervention"
        />
      </b-col>
    </b-row>
    <div class="cohort-saved-text" v-if="cohortSavedText">✓ Cohort saved</div>
    <div class="cohort-saved-text" v-if="importedText">✓ Imported</div>
    <a-modal
      :visible="cohortListVisible"
      :mask="true"
      :footer="null"
      width="1010px"
      centered
      :closable="true"
      :zIndex="9999"
      @cancel="clickCancel"
    >
      <CohortList :importMode="true" :flowType="flowType" @importCohort="importCohort" />
    </a-modal>
  </div>
</template>
<script>
import { mapState } from '@/store';
import { GROUP_STATE, SPECIAL_CHARS, MAX_NAME_LENGTH, VUE_EVENT_NAME } from '@/utils/constants';
import EventContainer from '@/components/byEvent/event/EventContainer.vue';
import EventTimeRelation from '@/components/byEvent/filter/EventTimeRelation.vue';
import CohortList from '@/components/byEvent/cohort/CohortList.vue';
import { EventType } from '@/utils/conditions/core/Interface';
import { EventSearchMixin, EventUpdateMixin, PatientNumberMixin, ClickMixin } from '@/mixins';
import { cloneDeep } from 'lodash';
import ClickOutside from 'vue-click-outside';
import dayjs from 'dayjs';
import { hasSpecialChar, getPopupContainer, delay, calcProgress, stringLessLength } from '@/utils/util';
import { defaultExperimental, defaultControl } from '@/utils/event/initState';
import { SLA_DEFINITION } from '@/utils/cohortProgressConfig';

const LINK_SAVED_WAIT = 3000; // 3 seconds

export default {
  name: 'CohortGroup',
  directives: {
    ClickOutside,
  },
  components: {
    EventContainer,
    EventTimeRelation,
    CohortList,
  },
  mixins: [EventSearchMixin, EventUpdateMixin, PatientNumberMixin, ClickMixin],
  props: {
    flowType: {
      type: Array,
      default: () => ['experimental'],
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      GROUP_STATE,
      male: 'accepted',
      female: 'accepted',
      EventType,
      path: this.flowType,
      cohortName: '',
      populationName: '',
      interventionName: '',
      nameText: 'Cohort name',
      copyLinkMenu: false,
      savedCondition: '',
      savedTextTimeout: null,
      errorMenuTimeout: null,
      cohortSavedText: false,
      cohortListVisible: false,
      importedText: false,
      updateDot: false,
      SPECIAL_CHARS,
      updateCountStartTime: 0,
      progressPercent: 0,
      MAX_NAME_LENGTH,
    };
  },
  watch: {
    male() {
      this.updateGenderList('male');

      this.cohortStudyTrackClick('male');
    },
    female() {
      this.updateGenderList('female');

      this.cohortStudyTrackClick('female');
    },
    genderListFromStore: {
      handler() {
        this.restoreGenderFromStore();
      },
      immediate: true,
    },
    getPopulationCondition() {
      this.updateDot = true;
    },
    getInterventionCondition() {
      this.updateDot = true;
    },
    isEditCohortName() {
      if (!this.isEditCohortName) {
        this.closeEditCohortName();
      }
    },
    isEditPopulationName() {
      if (!this.isEditPopulationName) {
        this.closeEditPopulationName();
      }
    },
    isEditInterventionName() {
      if (!this.isEditInterventionName) {
        this.closeEditInterventionName();
      }
    },
  },
  computed: {
    ...mapState('eventSearch', [
      'experimentalGroupState',
      'controlGroupState',
      'experimentalGroupPatient',
      'controlGroupPatient',
      'experimentalLoadingCount',
      'controlLoadingCount',
    ]),
    getPatientCount() {
      if (this.flowType[0] === 'experimental') {
        return this.formatedTemplateNumber(this.experimentalGroupPatient);
      } else if (this.flowType[0] === 'control') {
        return this.formatedTemplateNumber(this.controlGroupPatient);
      } else if (this.flowType[2] === 'experimental') {
        return this.$store.state.eventResult.experimentalGroupPatient;
      }
      return this.$store.state.eventResult.controlGroupPatient;
    },
    hasCondition() {
      return (
        this.currentEvent.eventList[0].condition.length !== 0 || this.currentEvent.indexEvent.condition.length !== 0
      );
    },
    getId() {
      return this.currentEvent.url;
    },
    getLoadingCount() {
      if (this.flowType[0] === 'experimental') {
        return this.experimentalLoadingCount;
      } else if (this.flowType[0] === 'control') {
        return this.controlLoadingCount;
      } else if (this.flowType[2] === 'experimental') {
        return this.$store.state.eventResult.experimentalLoadingCount;
      }
      return this.$store.state.eventResult.controlLoadingCount;
    },
    genderListFromStore() {
      return this.currentEvent.genderList;
    },
    getPopulationCondition() {
      return this.currentEvent.eventList[0].condition;
    },
    getInterventionCondition() {
      return this.currentEvent.indexEvent.condition;
    },
    isEditCohortName() {
      return this.isCurrentMenu(this.path, 'editCohortName');
    },
    isEditPopulationName() {
      return this.isCurrentMenu(this.path, 'editPopulationName');
    },
    isEditInterventionName() {
      return this.isCurrentMenu(this.path, 'editInterventionName');
    },
  },
  methods: {
    hasSpecialChar,
    getPopupContainer,
    stringLessLength,
    restoreGenderFromStore() {
      const data = ['male', 'female'];
      data.forEach((item) => {
        const result = this.currentEvent.genderList.find((gender) => gender.type === item);
        this[item] = result ? 'accepted' : 'not_accepted';
      });
    },
    async updateProgressPercent() {
      while (this.getLoadingCount !== 0) {
        this.progressPercent = calcProgress(this.updateCountStartTime, SLA_DEFINITION.PATIENT_COUNT);
        await delay(100);
      }
    },
    updatePatientCount() {
      this.updateCount(this.path);
      this.updateCountStartTime = Date.now();
      this.progressPercent = 0;
      this.updateProgressPercent();

      this.cohortStudyTrackClick('updatePatientCount');
      this.updateDot = false;
    },
    clearPopulation() {
      this.$refs.population.setDefaultState();

      this.cohortStudyTrackClick('clearPopulation');
    },
    clearIntervention() {
      this.$refs.intervention.setDefaultState();

      this.cohortStudyTrackClick('clearIntervention');
    },
    updateGenderList(type) {
      let { genderList } = cloneDeep(this.SearchStateObject);
      if (this[type] === 'accepted') {
        genderList.push({ type });
      } else {
        genderList = genderList.filter((item) => item.type !== type);
      }

      const body = {
        genderList,
      };

      if (this.path[0] === 'session') {
        this.updateEventWithPath(body, this.path.slice(0, 3));
      } else {
        this.updateEventWithPath(body, this.path[0]);
      }
    },
    closeEditCohortName() {
      this.setCurrentMenu(this.path, '');

      const body = {
        name: this.cohortName,
      };

      this.updateEventWithPath(body, this.path);
    },
    clickEditCohortName() {
      this.cohortName = this.SearchStateObject.name;
      this.setCurrentMenu(this.path, 'editCohortName');
      this.$nextTick(() => {
        this.$refs.editCohortName.select();
      });

      this.cohortStudyTrackClick('clickEditCohortName');
    },
    closeEditPopulationName() {
      this.setCurrentMenu(this.path, '');

      const body = {
        name: this.populationName,
      };

      this.updateEventWithPath(body, this.path.concat(['eventList', '0']));
    },
    clickEditPopulationName() {
      this.populationName = this.SearchStateObject.eventList[0].name;
      this.setCurrentMenu(this.path, 'editPopulationName');
      this.$nextTick(() => {
        this.$refs.editPopulationName.select();
      });

      this.cohortStudyTrackClick('clickEditPopulationName');
    },
    closeEditInterventionName() {
      this.setCurrentMenu(this.path, '');

      const body = {
        name: this.interventionName,
      };

      this.updateEventWithPath(body, this.path.concat(['indexEvent']));
    },
    clickEditInterventionName() {
      this.interventionName = this.SearchStateObject.indexEvent.name;
      this.setCurrentMenu(this.path, 'editInterventionName');
      this.$nextTick(() => {
        this.$refs.editInterventionName.select();
      });

      this.cohortStudyTrackClick('clickEditInterventionName');
    },
    openCopyLinkMenu() {
      this.copyLinkMenu = true;
      this.nameText = this.SearchStateObject.name;
      this.$nextTick(() => {
        this.$refs.saveQueryButtonInput.select();
      });

      this.cohortStudyTrackClick('openCopyLinkMenu');
    },
    closeCopyLinkMenu() {
      this.copyLinkMenu = false;
    },
    saveTextRefresh() {
      this.$root.$emit(VUE_EVENT_NAME.REFRESH_COHORT_SAVED_LIST);

      clearTimeout(this.copiedTextTimeout);
      this.cohortSavedText = true;
      this.savedTextTimeout = setTimeout(() => {
        this.cohortSavedText = false;
      }, LINK_SAVED_WAIT);
    },
    async saveQuery() {
      this.copyLinkMenu = false;
      const { url } = await this.$api.saveQuery(
        cloneDeep(this.nameText),
        {
          ...cloneDeep(this.currentEvent),
          name: cloneDeep(this.nameText),
          updateTime: dayjs().format('YYYY/MM/DD HH:mm:ss'),
        },
        'saveCohort'
      );

      this.updateEventWithPath({ url }, this.path);
      this.saveTextRefresh();

      this.cohortStudyTrackClick('saveQuery');
      const body = {
        name: this.nameText,
      };

      this.updateEventWithPath(body, this.path);
    },
    async updateQuery() {
      await this.$api.updateQuery(this.getId, {
        ...cloneDeep(this.currentEvent),
        name: cloneDeep(this.SearchStateObject.name),
        updateTime: dayjs().format('YYYY/MM/DD HH:mm:ss'),
      });
      this.saveTextRefresh();

      this.cohortStudyTrackClick('updateQuery');
    },
    clickImportCohort() {
      this.cohortListVisible = true;
      this.cohortStudyTrackClick('clickImportCohort');
    },
    clickCancel() {
      this.cohortListVisible = false;
      this.cohortStudyTrackClick('clickCancel');
    },
    importCohort() {
      this.cohortListVisible = false;
      clearTimeout(this.copiedTextTimeout);
      this.importedText = true;
      this.savedTextTimeout = setTimeout(() => {
        this.importedText = false;
      }, LINK_SAVED_WAIT);
    },
    setDefaultCohort() {
      const defaultEvent = this.path.includes('experimental')
        ? cloneDeep(defaultExperimental)
        : cloneDeep(defaultControl);
      if (this.path[0] === 'session') {
        this.updateEventWithPath(defaultEvent, this.path.slice(0, 3));
      } else {
        this.updateEventWithPath(defaultEvent, this.path[0]);
      }
      this.cohortStudyTrackClick('setDefaultCohort');
    },
  },
};
</script>
<style lang="scss" scoped>
.cohort-group {
  background: #fafafa;
  padding: 40px 40px;
  min-height: calc(100vh - 126px);
}

.search-group {
  box-shadow: 0px 4px 16px #e0e0e0, 0px 16px 16px rgba(157, 205, 245, 0.1);
  border-radius: 8px 0px 0px 8px;
  padding: 0px 40px 550px 40px;
}

.group-title {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: #334e97;
}

.patient-count {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 45px;
  color: #334e97;
}

.patient-text {
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 30px;
  color: #212121;
}

.second-group-title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #334e97;
}

.second-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #212121;
}

.first-text {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  color: #212121;
}

.mid-edit {
  width: 12px;
  height: 12px;
  cursor: pointer;
}

.clear-button {
  border: 1px solid #4d8edc;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
}

.clear-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #4d8edc;
}

.button-disable {
  opacity: 0.5;
  pointer-events: none;
}

.count-patient-text {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #ffffff;
}

.patient-count-button {
  padding: 8px 16px;
  background: #4d8edc;
  border-radius: 6px;
  cursor: pointer;
}

.share-link-menu {
  position: absolute;
  width: 420px;
  height: 200px;
  border-radius: 6px;
  border: solid 1px #d6d9e1;
  background-color: #ffffff;
  padding: 20px 25px 20px 25px;
  z-index: 10;
  margin: 8px 0px 0px -390px;
}
.title-text {
  color: #4d8edc;
}

.copy-link-button {
  border-color: #4d8edc !important;
  background-color: #4d8edc !important;
}

.cohort-saved-text {
  padding: 12px 16px;
  position: fixed;
  background: #57ac79;
  border: 1px solid #ffffff;
  box-shadow: 0px 2px 8px #e0e0e0, 0px 8px 8px rgba(157, 205, 245, 0.1);
  border-radius: 6px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: #ffffff;
  z-index: 2;
  left: 64%;
  bottom: 30px;
}

.rotate {
  animation: rotation 1s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.sticky-header {
  background: #fafafa;
  z-index: 2;
  position: sticky;
  top: 0;
  left: 0;
}

.update-dot {
  width: 8px;
  height: 8px;
  background: #f5d03d;
  border-radius: 99px;
  margin-bottom: 15px;
}
</style>
