import { CHART } from '../constants';
import { CategoryCondition, ConditionFactory, ConditionName } from './core';

function getVisitQuery(genders: string[]): Record<string, unknown>[] {
  return genders.map((value) => ({
    term: {
      DEPARTMENT: value,
    },
  }));
}

export default class VisitDef extends CategoryCondition {
  static get NAME(): string {
    return CHART.TITLE.VISTI;
  }

  public getFilter(): Record<string, unknown> {
    return {
      bool: {
        [this.andOr]: getVisitQuery(this.list),
      },
    };
  }
}

ConditionFactory.register(VisitDef, ConditionName.Visit);
