import {
  CategoryConditionData,
  ConditionName,
  LogicOp,
  RangeConditionData,
  SearchType,
  DiagnosisType,
} from './conditions/core';

export const INIT_CONDITIONS = {
  AGE: {
    START: 0,
    END: 200,
  },
};

const age: RangeConditionData = {
  name: ConditionName.Age,
  tags: [SearchType.Basic, SearchType.Raw],
  ranges: [
    {
      start: INIT_CONDITIONS.AGE.START,
      end: INIT_CONDITIONS.AGE.END,
    },
  ],
};

const time: RangeConditionData = {
  name: ConditionName.Time,
  tags: [SearchType.Basic, SearchType.Raw],
  ranges: [],
};

const diagnosis: CategoryConditionData = {
  name: ConditionName.Diagnosis,
  tags: [SearchType.Basic, SearchType.Raw],
  andOr: LogicOp.Or,
  list: [],
};

const diagnosisGroupMap: CategoryConditionData = {
  name: ConditionName.Diagnosis,
  tags: [SearchType.Basic, SearchType.Raw, DiagnosisType.GroupAndMap],
  andOr: LogicOp.Or,
  list: [],
};

const excludedDiagnosis: CategoryConditionData = {
  name: ConditionName.Diagnosis,
  tags: [SearchType.Basic, SearchType.Raw],
  andOr: LogicOp.Or,
  list: [],
};

const medication: CategoryConditionData = {
  name: ConditionName.Medication,
  tags: [SearchType.Basic, SearchType.Raw],
  andOr: LogicOp.Or,
  list: [],
};

const medicalOrder: CategoryConditionData = {
  name: ConditionName.MedicalOrder,
  tags: [SearchType.Basic, SearchType.Raw],
  andOr: LogicOp.Or,
  list: [],
};

const excludedMedication: CategoryConditionData = {
  name: ConditionName.Medication,
  tags: [SearchType.Basic, SearchType.Raw],
  andOr: LogicOp.Or,
  list: [],
};

const excludedMedicalOrder: CategoryConditionData = {
  name: ConditionName.MedicalOrder,
  tags: [SearchType.Basic, SearchType.Raw],
  andOr: LogicOp.Or,
  list: [],
};

const procedure: CategoryConditionData = {
  name: ConditionName.Procedure,
  tags: [SearchType.Basic, SearchType.Raw],
  andOr: LogicOp.Or,
  list: [],
};

const excludedProcedure: CategoryConditionData = {
  name: ConditionName.Procedure,
  tags: [SearchType.Basic, SearchType.Raw],
  andOr: LogicOp.Or,
  list: [],
};

const doctorId: CategoryConditionData = {
  name: ConditionName.DoctorId,
  tags: [SearchType.Basic, SearchType.Raw],
  andOr: LogicOp.Or,
  list: [],
};

const death: CategoryConditionData = {
  name: ConditionName.Death,
  tags: [SearchType.Basic, SearchType.Raw],
  andOr: LogicOp.Or,
  list: [],
};

const lab: CategoryConditionData = {
  name: ConditionName.Lab,
  tags: [SearchType.Basic, SearchType.Raw],
  andOr: LogicOp.Or,
  list: [],
};

export default {
  age,
  time,
  doctorId,
  diagnosis,
  excludedDiagnosis,
  medication,
  medicalOrder,
  excludedMedication,
  procedure,
  excludedProcedure,
  excludedMedicalOrder,
  diagnosisGroupMap,
  death,
  lab,
};
