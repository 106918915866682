import { cloneDeep } from 'lodash';
import { foolProof } from '@/utils/advance/foolproof';
import objectPath from 'object-path';
import { mapState, mapMutations } from '../store';

export default {
  computed: {
    ...mapState('advancePortal', ['SearchStateObject', 'currentMenu']),
    getSelectTimeStart() {
      return this.SearchStateObject.timePeriod.start;
    },
    getSelectTimeEnd() {
      return this.SearchStateObject.timePeriod.end;
    },
    currentEvent() {
      return this.getEventDataByPath(this.path);
    },
  },
  methods: {
    ...mapMutations('advancePortal', ['setMenuPath']),
    foolProof,
    getEventData(index) {
      const indexToEventListMapper = new Map([
        [1, cloneDeep(this.SearchStateObject.eventList[0])],
        [2, cloneDeep(this.SearchStateObject.indexEvent)],
        [3, cloneDeep(this.SearchStateObject.eventList[1])],
      ]);
      return indexToEventListMapper.get(index);
    },
    getEventDataByPath(path) {
      return cloneDeep(objectPath.get(this.SearchStateObject, path));
    },
    isCurrentMenu(path, menuName) {
      return `${path}/${menuName}` === this.currentMenu;
    },
    setCurrentMenu(path, menuName) {
      this.setMenuPath({ path, menuName });
    },
    deleteConstraint(value) {
      let newConstraintList = this.currentEvent.constraintList;
      newConstraintList = newConstraintList.filter((item) => item.constraintType !== value);
      const body = {
        constraintList: newConstraintList,
      };
      this.updateEventWithPath(body, this.path);

      this.advanceTrackClick('deleteConstraint', {
        path: this.path,
        index: this.index,
        value,
        constraintList: newConstraintList,
      });
    },
  },
};
