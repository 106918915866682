import BasicChartConfig from '@/components/charts/core/BasicChartConfig';
import { ChartItem, DiagnosisRawItem, ChartKey } from '@/components/charts/core/Interface';
import { CodeOption, CodeViewAllOption } from '@/utils/util';
import { codeAxisData, codePercentageFormatter, codeAggsQuery, SELECTED_COLOR } from '@/components/charts/utils/utils';
import { UICONFIG } from '@/utils/constants';
import {
  ConditionName,
  LogicOp,
  MedicalTermType,
  SearchType,
  TermCondition,
  TermConditionData,
  VisitType,
} from '../../utils/conditions/core';

export default class ATCCodeConfig extends BasicChartConfig<DiagnosisRawItem> {
  constructor() {
    super(ChartKey.ATCCode);

    this.hasViewAll = true;
    this.merge({ ...CodeOption });
  }

  protected setAxisData(): void {
    this.unset('xAxis.name');
    this.unset('yAxis.data');
    this.merge({ ...codeAxisData(this.bucketByPatient, this.data, VisitType.ALL) });
  }

  public createViewAllConfig(): ATCCodeConfig {
    const config = new ATCCodeConfig();
    config.resultSize = UICONFIG.VIEW_ALL_SIZE;
    config.merge({ ...CodeViewAllOption });
    return config;
  }

  protected createChartItem(rawItem: DiagnosisRawItem): ChartItem {
    const {
      key,
      doc_count,
      patient_count: { value },
    } = rawItem;
    const [code, name] = key.split('##');

    const condList = this.params.includes.additions.with(ConditionName.ATCCode);

    let color = '#333f6b';
    if (condList.length !== 0) {
      const condition = condList[0] as TermCondition;
      if (condition.hitCode(code)) {
        color = SELECTED_COLOR;
      }
    }

    const targetValue = this.bucketByPatient ? value : doc_count;

    return {
      key: code,
      value: targetValue,
      code,
      name,
      color,
      percentage: this.calculatePercentage(targetValue),
    };
  }

  public createConditionFromItem(chartItem: ChartItem): TermConditionData {
    return {
      name: ConditionName.ATCCode,
      tags: [SearchType.Basic, SearchType.Additional],
      andOr: LogicOp.And,
      list: [
        {
          code: chartItem.code,
          type: MedicalTermType.ATCCode,
          name: chartItem.name,
        },
      ],
    };
  }

  protected percentageFormatter(seriesList: any) {
    return codePercentageFormatter(seriesList);
  }

  protected get aggsQuery(): Record<string, unknown> {
    return codeAggsQuery(this.getOrderField(), 'ATC_FULL', this.resultSize);
  }
}
