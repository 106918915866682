export enum DiagnosisRanges {
  ALL = 'all',
  Primary = 'primary',
  Top5 = 'top-5',
}

export enum ReadmissionStandard {
  NHI = 'nhi',
  ANY = 'any',
}

export enum DiagnosisType {
  GroupAndMap = 'groupAndMap',
}

export enum SearchType {
  Basic = 'Basic',
  Advanced = 'Advanced',
  Raw = 'Raw',
  Additional = 'Additional',
}
