import { cloneDeep, groupBy } from 'lodash';

import ESQuery from '@/utils/query/core/ESQuery';
import { getUiConfig } from '@/utils/uiConfig';
import CF from '@/utils/conditions/core/ConditionFactory';
import AdvanceESQuery from '@/utils/query/core/advance/AdvanceESQuery';
import ConditionArray from './query/core/ConditionArray';
import { SearchType, DiagnosisRanges, ConditionName } from './conditions/core';

export const getKeywordFields = (type = '') => {
  // Mandatory type
  switch (type) {
    case 'ipd':
      return getUiConfig().Analyze.PatientResult.tabs;
    case 'opd':
      return getUiConfig().Analyze.PatientResult.OPD_tabs;
    case 'all':
      return getUiConfig().Analyze.PatientResult.all_tabs;
    default:
  }
  return getUiConfig().Analyze.PatientResult.all_tabs;
};

export const filterExtendType = (extendConditions) => {
  // filter keyword condition

  const filterKeywordExtendConditions = [];
  extendConditions.forEach((condition) => {
    if (condition.name === 'Keyword') {
      if (condition.fields.length === 1) {
        filterKeywordExtendConditions.push(condition);
      } else {
        const newFields = [];
        newFields.push('SUB_CHART_NO');
        newFields.push('TEXT_ALL');
        const newCondition = cloneDeep(condition);
        newCondition.fields = newFields;
        filterKeywordExtendConditions.push(newCondition);
      }
    } else {
      // other type condition
      filterKeywordExtendConditions.push(condition);
    }
  });
  return filterKeywordExtendConditions;
};

export const getConditionDef = (conditions) => {
  const filters = [];
  conditions
    .filter((condData) => condData.tags.includes(SearchType.Raw))
    .forEach((condition) => {
      filters.push(CF.create(condition));
    });
  const additional = conditions.filter((condData) => condData.tags.includes(SearchType.Additional));
  const group = groupBy(additional, (item) => {
    const { name } = item;
    const hasIcdAll = item.tags.includes(DiagnosisRanges.IcdALL);
    const hasIcdPrimary = item.tags.includes(DiagnosisRanges.IcdPrimary);
    const hasIcdTop5 = item.tags.includes(DiagnosisRanges.IcdTop5);
    let lab = '';
    if (name === ConditionName.Lab) {
      lab = `${item.lab}-${item.exam}`;
    }
    return `${name}-${hasIcdAll}-${hasIcdPrimary}-${hasIcdTop5}-${lab}`;
  });
  Object.values(group).forEach((conditionGroup) => {
    filters.push(CF.create(conditionGroup));
  });
  return new ConditionArray(filters);
};

export const getQuery = ({ includes = [], excludes = [] }) => {
  const filterExtendConditions = getUiConfig().Analyze.OPD_Searchable ? filterExtendType(includes) : includes;
  return new ESQuery({
    includes: getConditionDef(filterExtendConditions),
    excludes: getConditionDef(excludes),
  }).getQuery();
};

export const getAdvQuery = ({ searchState = {} }) => {
  return new AdvanceESQuery(searchState).getQuery();
};
