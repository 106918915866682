import { RawItem, ChartKey } from '@/components/charts/core/Interface';
import AdvChartConfig from './core/AdvChartConfig';
import { AxisFormatter, removePoint } from '../utils/utils';

const OPTION = {
  xAxis: {
    type: 'value',
    axisLabel: {
      formatter: removePoint,
    },
  },
  yAxis: {
    type: 'category',
    axisLabel: {
      formatter: AxisFormatter,
    },
  },
};

export default abstract class AdvLineChartConfig extends AdvChartConfig<RawItem> {
  constructor(key: ChartKey) {
    super(key);

    this.merge(OPTION);
    this.setChartType();
  }

  protected setChartType(): void {
    this.merge({
      series: {
        type: 'line',
        symbol: 'circle',
        symbolSize: 14,
        showSymbol: false,
      },
    });
  }
}
