<template>
  <div class="cohort-list" :class="{ block: loading, 'import-cohort-list': importMode }">
    <a-spin id="loading-spin" v-if="loading" />
    <div class="top-title-text">Saved Cohorts</div>
    <div v-if="initSavedListLength !== 0">
      <b-row no-gutters>
        <b-col class="d-flex justify-content-between align-items-center">
          <div class="d-flex align-items-center">
            <b-input placeholder="Search" v-model="searchNameText" class="name-search"> </b-input>
            <b-img src="@/assets/images/icon_search_blue.svg" class="icon-input-search" />
          </div>
          <div class="new-cohort-button" v-if="!importMode" @click="clickNewCohort">+ New cohort</div>
        </b-col>
      </b-row>
      <b-row no-gutters class="title-row">
        <b-col class="d-flex align-items-center">
          <div class="title-name">Name</div>
        </b-col>
        <b-col class="d-flex align-items-center justify-content-end">
          <div class="title-date">Modified Date</div>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col>
          <b-row no-gutters v-for="(item, index) in savedList" :key="index" class="data-row" @click="clickRow(index)">
            <b-col style="overflow: hidden" class="d-flex justify-content-center">
              <div class="left-div" style="padding-right: 20px">
                <b-row no-gutters>
                  <b-col>
                    <a-tooltip placement="bottomLeft" overlayClassName="cohort-detail-tooltip">
                      <template slot="title">
                        <div style="padding: 20px">
                          <div class="tooltip-title">{{ item.cohortName }}</div>
                          <div class="tooltip-sub-title mt-10px">{{ item.populationName }}</div>
                          <div
                            v-for="(code, index) in getCodes(item.query.eventList[0].condition)"
                            :key="`${index}-population`"
                            class="mt-10px"
                          >
                            <div class="tooltip-content-text mt-5px">{{ code.code }}</div>
                            <div class="tooltip-content-text mt-5px" v-if="code.description">
                              {{ code.description }}
                            </div>
                            <div class="tooltip-content-text mt-5px" v-if="code.exam">{{ code.exam }}</div>
                            <div class="tooltip-content-text mt-5px" v-if="code.unit">{{ code.unit }}</div>
                            <div class="tooltip-content-cn-text mt-5px" v-if="code.description">
                              {{ code.chineseDescription }}
                            </div>
                          </div>
                          <div class="tooltip-sub-title mt-10px">{{ item.interventionName }}</div>
                          <div
                            v-for="(code, index) in getCodes(item.query.indexEvent.condition)"
                            :key="`${index}-intervention`"
                            class="mt-10px"
                          >
                            <div class="tooltip-content-text mt-5px">{{ code.code }}</div>
                            <div class="tooltip-content-text mt-5px" v-if="code.description">
                              {{ code.description }}
                            </div>
                            <div class="tooltip-content-text mt-5px" v-if="code.exam">{{ code.exam }}</div>
                            <div class="tooltip-content-text mt-5px" v-if="code.unit">{{ code.unit }}</div>
                            <div class="tooltip-content-cn-text mt-5px" v-if="code.description">
                              {{ code.chineseDescription }}
                            </div>
                          </div>
                        </div>
                      </template>
                      <div class="cohort-name-text">{{ item.cohortName }}</div>
                    </a-tooltip>
                  </b-col>
                </b-row>
                <b-row no-gutters class="mt-5px">
                  <b-col class="d-flex align-items-center">
                    <a-tooltip placement="bottomLeft" overlayClassName="cohort-detail-tooltip">
                      <template slot="title">
                        <div style="padding: 20px">
                          <div class="tooltip-title">{{ item.cohortName }}</div>
                          <div class="tooltip-sub-title mt-10px">{{ item.populationName }}</div>
                          <div
                            v-for="(code, index) in getCodes(item.query.eventList[0].condition)"
                            :key="`${index}-population`"
                            class="mt-10px"
                          >
                            <div class="tooltip-content-text mt-5px">{{ code.code }}</div>
                            <div class="tooltip-content-text mt-5px" v-if="code.description">
                              {{ code.description }}
                            </div>
                            <div class="tooltip-content-text mt-5px" v-if="code.exam">{{ code.exam }}</div>
                            <div class="tooltip-content-text mt-5px" v-if="code.unit">{{ code.unit }}</div>
                            <div class="tooltip-content-cn-text mt-5px" v-if="code.chineseDescription">
                              {{ code.chineseDescription }}
                            </div>
                          </div>
                          <div class="tooltip-sub-title mt-10px">{{ item.interventionName }}</div>
                          <div
                            v-for="(code, index) in getCodes(item.query.indexEvent.condition)"
                            :key="`${index}-intervention`"
                            class="mt-10px"
                          >
                            <div class="tooltip-content-text mt-5px">{{ code.code }}</div>
                            <div class="tooltip-content-text mt-5px" v-if="code.description">
                              {{ code.description }}
                            </div>
                            <div class="tooltip-content-text mt-5px" v-if="code.exam">{{ code.exam }}</div>
                            <div class="tooltip-content-text mt-5px" v-if="code.unit">{{ code.unit }}</div>
                            <div class="tooltip-content-cn-text mt-5px" v-if="code.chineseDescription">
                              {{ code.chineseDescription }}
                            </div>
                          </div>
                        </div>
                      </template>
                      <div class="d-flex align-items-center">
                        <div class="sub-title-text">Population</div>
                        <div class="ml-5px sub-content-text">{{ item.populationName }}</div>
                        <div class="ml-20px sub-title-text">Intervention</div>
                        <div class="ml-5px sub-content-text">{{ item.interventionName }}</div>
                      </div>
                    </a-tooltip>
                  </b-col>
                </b-row>
              </div>
              <div class="right-div d-flex align-items-center justify-content-end">
                <b-img src="@/assets/images/icon_study_edit.svg" class="icon-edit mr-20px" @click="clickEdit(item)" />
                <b-img
                  src="@/assets/images/icon_delete_event.svg"
                  class="icon-edit mr-30px"
                  @click="clickDelete(item)"
                />
                <div class="sub-content-text" style="width: 90px">{{ item.updateTime }}</div>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col>
          <a-pagination
            v-show="total > pageSize"
            class="pagin"
            size="small"
            v-model="current"
            :total="total"
            showLessItems
            :itemRender="itemPagerRender"
            @change="handlePaginationChange"
          />
        </b-col>
      </b-row>
    </div>
    <div v-else>
      <b-row no-gutters>
        <b-col>
          <div class="d-flex justify-content-center">
            <b-img src="@/assets/images/no_results.svg" class="icon-no-result" />
          </div>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col class="no-result-title-text">No saved cohort</b-col>
      </b-row>
      <b-row no-gutters>
        <b-col>
          <div class="d-flex justify-content-center">
            <div class="no-result-content-text">
              There’s no saved cohort. To save your cohort and keep all the criteria and filter, simply click
              <div style="display: inline-block; font-weight: bold">SAVE</div>
              button at the top-right in your cohort page
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-modal
      :visible="deleteModal"
      centered
      no-close-on-backdrop
      no-close-on-esc
      hide-header
      hide-footer
      hide-header-close
      content-class="delete-content-modal"
      body-class="delete-body-modal"
    >
      <b-row no-gutters>
        <b-col>
          <b-row no-gutters>
            <b-col class="title-text" style="text-align: center">Delete Cohort</b-col>
          </b-row>
          <b-row no-gutters style="margin-top: 20px">
            <b-col class="name-delete-text"> Are you sure you want to delete this cohort? </b-col>
          </b-row>
          <b-row no-gutters>
            <b-col class="name-delete-text"> It will be deleted permanently. </b-col>
          </b-row>
          <b-row no-gutters>
            <b-col class="d-flex justify-content-center button-col">
              <b-button variant="outline-primary" class="button-close" @click="closeDeleteModal">CANCEL</b-button>
              <b-button variant="outline-primary" class="button-cancel" @click="clickDeleteCohort">DELETE</b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-modal>
    <a-modal
      :visible="visible"
      :mask="true"
      :footer="null"
      width="1010px"
      centered
      :zIndex="9999"
      :destroyOnClose="true"
      :closable="false"
      dialogClass="edit-cohort"
      :maskClosable="true"
      @cancel="clickMaskToClose"
    >
      <CohortGroup :flowType="['session', '0', 'experimental']" :editMode="true" />
      <div class="d-flex justify-content-end align-items-center bottom-button-group">
        <div class="mr-40px cancel-button" @click="clickCancel">CANCEL</div>
        <div class="done-button" @click="clickDone">DONE</div>
      </div>
    </a-modal>
    <div>
      <a-modal
        :visible="alertVisable"
        :mask="true"
        :footer="null"
        centered
        :zIndex="9999"
        :destroyOnClose="true"
        :closable="false"
        width="495px"
        dialogClass="alert-modal"
      >
        <b-row no-gutters>
          <b-col class="d-flex justify-content-center align-items-center">
            <div class="modal-apply-title">Apply Changes?</div>
          </b-col>
        </b-row>
        <b-row no-gutters class="mt-40px">
          <b-col class="d-flex justify-content-between">
            <div class="no-button" @click="closeAlertModal">No</div>
            <div class="modal-apply-button" @click="applyAlertModal">Yes</div>
          </b-col>
        </b-row>
      </a-modal>
    </div>
  </div>
</template>
<script>
import dayjs from 'dayjs';
import { mapState, mapMutations } from '@/store';
import { debounce, cloneDeep, sortBy, isEqual } from 'lodash';
import { EventUpdateMixin, ClickMixin } from '@/mixins';
import { defaultEventSearch } from '@/utils/event/initState';
import { VUE_EVENT_NAME } from '@/utils/constants';

const pageSize = 10;

export default {
  name: 'CohortList',
  components: {
    CohortGroup: () => import('@/components/byEvent/group/CohortGroup.vue'),
  },
  mixins: [EventUpdateMixin, ClickMixin],
  data() {
    return {
      searchNameText: '',
      savedList: [],
      total: pageSize,
      pageSize,
      current: 1,
      initSavedListLength: 0,
      loading: false,
      deleteModal: false,
      deleteInfo: {},
      visible: false,
      editInfo: {},
      alertVisable: false,
      initCohort: {},
    };
  },
  async mounted() {
    this.loading = true;
    try {
      await this.fetchData();
    } catch (error) {
      console.error(error);
    }
    this.loading = false;

    this.$root.$on(VUE_EVENT_NAME.REFRESH_COHORT_SAVED_LIST, () => {
      this.fetchData();
    });
  },
  destroyed() {
    this.$root.$off(VUE_EVENT_NAME.REFRESH_COHORT_SAVED_LIST);
  },
  computed: {
    ...mapState('user', ['userId']),
    ...mapState('eventSearch', ['totalPatient']),
    ...mapState('eventResult', ['eventSearch']),
  },
  props: {
    importMode: {
      type: Boolean,
      default: false,
    },
    flowType: {
      type: Array,
      default: () => ['experimental'],
    },
  },
  watch: {
    searchNameText() {
      if (this.searchNameText === '') {
        this.fetchData();
      } else {
        this.current = 1;
        this.searchByName();
      }
    },
  },
  methods: {
    ...mapMutations('eventResult', ['restoreData']),
    async fetchData(pagination = 1) {
      this.current = pagination;
      const result = await this.$api
        .getUserQuery(this.userId, { page: pagination - 1, purpose: 'saveCohort' })
        .catch((error) => {
          console.error(error);
        });
      if (result.content) {
        this.total = result.content.hits.total.value;

        this.setSavedList(result);

        this.initSavedListLength = this.savedList.length;
        this.$root.$emit(VUE_EVENT_NAME.SET_VUEPERFECTSCROLLBAR_UPDATE);
      }

      if (this.total !== 0 && Math.ceil(this.total / pageSize) < this.current) {
        this.current = 1;
        this.fetchData();
      }
      return Promise.resolve();
    },
    setSavedList(savedCohortList) {
      this.savedList = [];
      savedCohortList.content.hits.hits.forEach((item) => {
        const data = item._source.query;
        this.savedList.push({
          cohortName: data.name,
          populationName: data.eventList[0].name,
          interventionName: data.indexEvent.name,
          updateTime: dayjs(data.updateTime).format('YYYY/MM/DD'),
          query: data,
          url: item._source.url,
        });
      });
    },
    itemPagerRender(current, type, originalElement) {
      if (type === 'prev') {
        return (
          <a>
            <b-icon-caret-left-fill />
          </a>
        );
      }
      if (type === 'next') {
        return (
          <a>
            <b-icon-caret-right-fill />
          </a>
        );
      }
      return originalElement;
    },
    async handlePaginationChange(pagination) {
      if (this.searchNameText.length > 0) {
        await this.searchByName().catch((error) => {
          console.error(error);
        });
      } else {
        await this.fetchData(pagination).catch((error) => {
          console.error(error);
        });
      }

      this.cohortStudyTrackClick('handlePaginationChange', { pagination });
    },
    searchByName: debounce(async function () {
      const content = {
        userId: this.userId,
        keyword: this.searchNameText,
        topN: 10,
        page: this.current - 1,
        purpose: 'saveCohort',
      };
      const result = await this.$api.searchQuery(content).catch((error) => {
        console.error(error);
      });
      if (result.content) {
        this.total = result.content.hits.total.value;

        this.setSavedList(result);

        this.$root.$emit(VUE_EVENT_NAME.SET_VUEPERFECTSCROLLBAR_UPDATE);
      }

      if (this.total !== 0 && Math.ceil(this.total / pageSize) < this.current) {
        this.current = 1;
        this.searchByName();
      }
      return Promise.resolve();
    }, 100),
    clickRow(index) {
      if (this.importMode) {
        this.updateEventWithPath(
          {
            ...this.savedList[index].query,
            url: this.savedList[index].url,
          },
          this.flowType
        );
        this.$emit('importCohort');
      }

      this.cohortStudyTrackClick('clickRow');
    },
    clickDelete(data) {
      this.deleteModal = true;
      this.deleteInfo = data;

      this.cohortStudyTrackClick('clickDelete');
    },
    closeDeleteModal() {
      this.deleteModal = false;
      this.deleteInfo = {};

      this.cohortStudyTrackClick('closeDeleteModal');
    },
    async clickDeleteCohort() {
      this.deleteModal = false;
      await this.$api.deleteQuery(this.deleteInfo.url);
      this.fetchData();

      this.cohortStudyTrackClick('clickDeleteCohort');
    },
    clickCancel() {
      this.visible = false;

      this.cohortStudyTrackClick('clickCancel');
    },
    async clickDone() {
      this.visible = false;
      this.loading = true;

      try {
        if (!Object.keys(this.editInfo).length) {
          await this.$api.saveQuery(
            cloneDeep(this.$store.state.eventResult.eventSearch.experimental.name),
            {
              ...cloneDeep(this.$store.state.eventResult.eventSearch.experimental),
              updateTime: dayjs().format('YYYY/MM/DD HH:mm:ss'),
            },
            'saveCohort'
          );
        } else {
          await this.$api.updateQuery(this.editInfo.url, {
            ...cloneDeep(this.$store.state.eventResult.eventSearch.experimental),
            updateTime: dayjs().format('YYYY/MM/DD HH:mm:ss'),
          });
        }
        await this.fetchData();
      } catch (error) {
        console.error(error);
      }

      this.loading = false;

      this.cohortStudyTrackClick('clickDone');
    },
    clickNewCohort() {
      this.editInfo = {};
      this.restoreData({
        eventSearch: cloneDeep(defaultEventSearch),
        experimentalGroupPatient: cloneDeep(this.totalPatient),
        controlGroupPatient: cloneDeep(this.totalPatient),
      });
      this.visible = true;
      this.initCohort = cloneDeep(this.eventSearch);

      this.cohortStudyTrackClick('clickNewCohort');
    },
    clickEdit(data) {
      this.editInfo = data;
      this.restoreData({
        eventSearch: cloneDeep({
          ...cloneDeep(defaultEventSearch),
          experimental: cloneDeep(data.query),
        }),
        experimentalGroupPatient: cloneDeep(this.totalPatient),
        controlGroupPatient: cloneDeep(this.totalPatient),
      });
      this.visible = true;
      this.initCohort = cloneDeep(this.eventSearch);

      this.cohortStudyTrackClick('clickEdit');
    },
    getCodes(data) {
      let result = [];
      data.forEach((condition) => {
        result = result.concat(condition.list);
      });

      result = sortBy(result, 'time');
      return result;
    },
    clickMaskToClose() {
      this.visible = false;
      if (!isEqual(this.eventSearch, this.initCohort)) {
        this.alertVisable = true;
      }
    },
    closeAlertModal() {
      this.alertVisable = false;
    },
    applyAlertModal() {
      this.alertVisable = false;
      this.clickDone();
    },
  },
};
</script>
<style lang="scss" scoped>
.cohort-list {
  background: #ffffff;
  min-height: calc(100vh - 116px);
  padding: 20px 0px 0px 0px;
}

.import-cohort-list {
  padding: 40px;
}

.name-search {
  width: 505px;
  height: 36px;
  border-radius: 6px;
  border: solid 1px #d6d9e1;
  background-color: #ffffff;
  margin-top: 20px;
  padding-left: 38px;
}

.icon-input-search {
  position: absolute;
  margin: 20px 0px 0px 10px;
}

.new-cohort-button {
  padding: 8px 16px;
  background: #4d8edc;
  border-radius: 6px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;
}

.title-row {
  padding: 4px 20px;
  border-radius: 6px;
  background-color: #ebecf0;
  margin-top: 20px;
}

.title-name {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #334e97;
  text-align: left;
}

.title-date {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #334e97;
  text-align: right;
}

.data-row {
  box-shadow: inset 0 -1px 0 0 #d6d9e1;
  cursor: pointer;
  padding: 20px;
}

.data-row:hover {
  background-color: #eaf2fd;
}

.icon-edit {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.cohort-name-text {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #212121;
}

.sub-title-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #757575;
  white-space: pre;
}

.sub-content-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #212121;
  white-space: pre;
}

.icon-no-result {
  width: 88px;
  height: 88px;
  margin-top: 194px;
  object-fit: contain;
}

.no-result-title-text {
  height: 36px;
  margin-top: 12px;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #333f6b;
}

.no-result-content-text {
  width: 417px;
  height: 82px;
  margin-top: 12px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #333333;
}

#loading-spin {
  top: 50%;
  position: fixed;
  left: 50%;
  z-index: 10;
}

.block {
  position: relative;
  background: transparent;
  opacity: 0.5;
}

.block:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}

/deep/ .delete-content-modal {
  width: 475px;
  height: 217px;
  border-radius: 6px;
  background-color: #ffffff;
  border: 1px solid white;
}

/deep/ .delete-body-modal {
  padding: 30px 30px 30px 30px;
}

.name-delete-text {
  height: 20px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333f6b;
}

.button-col {
  margin-top: 30px;
}

.button-cancel,
.button-cancel:hover,
.button-cancel:focus,
.button-cancel:active,
.button-cancel:active:visited {
  margin-right: 20px;
  width: 168px;
  height: 44px;
  border-radius: 6px;
  border: solid 1px #da6d6d !important;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff !important;
  background-color: #da6d6d !important;
}

.button-close,
.button-close:hover,
.button-close:focus,
.button-close:active,
.button-close:active:visited {
  margin-right: 20px;
  width: 168px;
  height: 44px;
  border-radius: 6px;
  border: solid 1px #333f6b !important;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333f6b !important;
  background-color: #ffffff !important;
}

.title-text {
  height: 23px;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #333f6b;
}

.cancel-button {
  padding: 12px 24px;
  cursor: pointer;
  border: 1px solid #4d8edc;
  box-sizing: border-box;
  border-radius: 6px;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #4d8edc;
}

.done-button {
  padding: 12px 24px;
  cursor: pointer;
  background: #4d8edc;
  box-sizing: border-box;
  border-radius: 6px;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}

.tooltip-title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #212121;
}

.tooltip-sub-title {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-transform: capitalize;
  color: #212121;
}

.tooltip-content-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #212121;
}

.tooltip-content-cn-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #616161;
}

.top-title-text {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #334e97;
}

/deep/ .edit-cohort {
  .ant-modal-body {
    padding: 0px;
    background: #fafafa;
  }
}

.bottom-button-group {
  padding: 0px 40px 40px 40px;
  position: sticky;
  bottom: 0px;
  background: #ffffff;
}

.modal-apply-title {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #333f6b;
}

.no-button {
  width: 197px;
  padding: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #4d8edc;
  border: 1px solid #4d8edc;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
}

.modal-apply-button {
  width: 197px;
  padding: 12px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  background: #4d8edc;
  border-radius: 4px;
  cursor: pointer;
}

/deep/ .alert-modal {
  .ant-modal-body {
    padding: 40px;
    background: #ffffff;
    border-radius: 8px;
  }
}

.right-div {
  width: 200px;
  max-width: 200px;
  min-width: 200px;
}

.left-div {
  width: calc(100% - 200px);
  max-width: calc(100% - 200px);
  min-width: calc(100% - 200px);
}
</style>
<style scoped>
.pagin {
  float: None;
  margin: 48px 0px 30px 0px;
  text-align: center;
}

.pagin >>> .ant-pagination-item-active {
  border: 1px solid #ffffff;
  color: #333f6b;
  font-weight: bold;
}

.pagin >>> .ant-pagination-item-active a {
  color: #333f6b;
}

.pagin >>> .ant-pagination-item-container {
  bottom: 2px;
  font-size: 1px;
}

.pagin >>> .ant-pagination-jump-next,
.pagin >>> .ant-pagination-jump-prev {
  pointer-events: none;
}
</style>
<style lang="scss">
.cohort-detail-tooltip {
  width: 336px;
  max-width: 336px !important;
  z-index: 10000 !important;
  max-height: 300px;
  overflow-y: scroll;

  .ant-tooltip-arrow {
    display: none;
  }

  .ant-tooltip-inner {
    background: #ffffff;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    border-radius: 4px;
  }
}
</style>
