export default {
  Blood: [
    'Creatinine',
    'eGFR',
    'Glucose',
    'Hb',
    'GPT(ALT)',
    'GOT(AST)',
    'WBC',
    'Triglyceride',
    'K',
    'Lymphocyte',
    'Basophil',
    'Eosinophil',
    'Monocyte',
    'Promyelocyte',
    'Metamyelocyte',
    'Blast',
    'Nucleated RBC',
    'Myelocyte',
    'Band',
    'Atypical lymphocyte',
    'Differential count',
    'Segmented Neutro.',
    'Na',
    'Ht',
    'LDL-C',
    'BUN',
    'Platelet',
    'HbA1C',
    'RBC',
    'MCV',
    '#Borderline high',
    '#Optimal',
    '#Very high',
    '#High',
    '#Near optimal',
    'MCHC',
    'RDW',
    'MCH',
    'Normal control',
    'High',
    'T-Cholesterol',
    'Desirable',
    'Borderline high',
    'ANC(absolute Neu.#)',
    'CBC/Platelet/DC',
    'T-Bilirubin',
    'Abnormal lymphocyte',
    'Abnormal monocyte',
    'Promonocyte',
    'Plasmacytoid lymph.',
    'Uric Acid',
    'INR',
    'PT',
    'HDL-c',
    'T-chol/HDL-C',
    'albumin',
    'CRP',
    'Total cholesterol',
    'P',
    'APTT ratio',
    'Uric acid',
    'iCa',
    'TSH',
    'Glucose PC',
    'APTT',
    'Blood',
    'Troponin I',
    'Diabetes',
    'Prediabetes',
    'Alkaline p-tase',
    'AFP',
    'Equivocal',
    '99% URL',
    'Cutoff for AMI',
    'Ab Screening',
    'Cl',
    'Lipase',
    'T4,Free',
    'T4',
    'CBC+DC',
    'Ca',
    'Cross Matching Test',
    'D-Bilirubin',
    'APTT.',
    'Globulin',
    'Total protein',
    'Protein',
    'Albumin/Globulin',
    'PSA',
    'CEA',
    'CPK',
    'ABO Typing',
    'HBsAg',
    'Rh Typing D',
    'HDL-C',
    'Anti-HCV',
    'Panel I',
    'r-GT',
    'Ferritin',
    'Packed RBCs',
    'CK-MB',
    'Serum Iron/TIBC',
    'Iron',
    'TIBC',
    'STS-RPR',
    'Iron/TIBC',
    'ESR',
    'Mg',
    'T3',
    'Male',
    'Lactate',
    'HBsAg(-)',
    'HBsAg(+)',
    'DM Profile I',
    'Leukocyte-Poor RBCs',
    'Blood culture',
    'Total-protein',
    'Dialysis Profile',
    'Ammonia',
    'LDH',
    'A/G',
    'MAR',
    '#prepuberty',
    '#Postmenopause',
    '#midcycle',
    'FSH',
    '#Luteal phase',
    '#Follicular phase',
    '#Male',
    'Anti-HCV(-)',
    'Anti-HCV(+)',
    'Estrogen',
    '**Mid luteal',
    '**Male',
    '**Mid follicular',
    'Post-menopausal',
    'ANA(IFA)',
    '**Peri-ovulatory',
    'CA 19-9',
    '**Prolactin',
    'HBeAg',
    'Anti-HBs',
    'Female',
    'CA125',
    '**PM Cortisol',
    '**AM Cortisol',
    'fPSA/PSA',
    'PSA,Free',
    'Fresh Frozen Plasma',
    'CK-MB/CK',
    '#Cortisol',
    'C3',
    'HIV Screen',
    'C4',
    'Osmolarity',
    'D dimer(ELISA)',
    'RA',
    'Bleeding time(Duke)',
    'Anti-HBs(+)',
    'Anti-HBs(-)',
    'CA153',
    'postmenopause',
    'pre-menopause',
    'B12',
    'Amylase',
    '**Testosterone',
    'Anti-TPO',
    'Renal function',
    '**IU/mL',
    '**Copy number',
    'Anti-HBe',
    'Apheresis Platelet',
    '**UR Cortisol',
    'DM Profile',
    'HBeAg(+)',
    'HBeAg(-)',
    'Folate',
    'GTT',
    '空腹',
    '2 小時',
    '1 小時',
    '@T-Bilirubin',
    'M.C.V.',
    '&Negative',
    '&Positive',
    'Anti-ds DNA',
    '&Equivocal',
    'Segment',
    'TPPA',
    '***Follicular phase',
    '***Midcycle',
    '***Male',
    '#LH',
    '***Postmenopause',
    '***Luteal phase',
    'IgE',
    'Benzodiazepin',
    '*Male',
    'Beta-HCG',
    '*Nonpreg.female',
    'Digoxin',
    'Fibrinogen',
    'Testosterone',
    'Blood Ketone',
    'IgG',
    'NT-proBNP',
    'IgA',
    'Anti-HBc',
    'Valproic acid',
    'CBC/Platelet',
    'IgM',
    'Anti-ds DNA.',
    'Free b-HCG',
    'PAPP-A',
    "Down's Risk First",
    'HBV-DNA viral load',
    'Anti-HBe(-)',
    'Anti-HBe(+)',
    'f2 Milk',
    'f24 Shrimp',
    'f13 Peanut',
    'f23 Crab',
    '**IU',
    '**copy number',
    'MAST',
    'f3 Codfish',
    'm2 Cladosporium',
    'i6 Cockroach Mix',
    'e5 Dog',
    'h2 Housedust',
    'f1 Egg White',
    'm3 Aspergillus',
    'f27 Beef',
    'm6 Alternaria',
    't18 Eucalyptus',
    'e1 Cat',
    'g2 Bermuda Grass',
    'm1 Penicillium',
    'f14 Soybean',
    'f26 Pork',
    'f75 Egg Yolk',
    'Ethyl alcohol',
    'PFA-100:Col/Epi time',
    'PFA-100:Col/ADP time',
    'PLT function screen',
    'Reticulocyte count',
    't32 Willow Black',
    'e85 Chicken Feathers',
    't17 Japanese Cedar',
    'f96 Avocado',
    'f207 Clam',
    'f4 Wheat(Food)',
    'f40 Tuna',
    'k82 Latex',
    'f81 Cheddar Cheese',
    'f155 Brewer Yeast',
    'd2 Mite DF',
    'w1 Ragweed Mix I',
    'w14 Pigweed',
    'g6 Timothy Grass',
    'd1 Mite DP',
    't70 White Mulberry',
    'Anti-HAV IgM',
    'HCV-RNA(viral load)',
    'SCC',
    '36-week',
    '30-week',
    '40-week',
    '22-week',
    '32-week',
    '29-week',
    '34-week',
    '18-week',
    '38-week',
    'D.pteronyssinus',
    'Theophylline',
    'pro-BNP',
    'HBV DNA(Baraclude)',
    'Thyroglobulin',
    'Anti-ENA screen.',
    '&&Equivocal',
    '&&Positive',
    '&&Negative',
    '& Negative',
    '& Grayzone',
    '& Positive',
    'Anti-HBc(-)',
    'Anti-HBc(+)',
    'HLA-B27',
    'Microorganism mixes',
    '*Postmenopause',
    '*Preg.4-5week',
    'Al',
    'Animal dander mixes',
    'SMA PCR',
    'Anti-ENA SSA.',
    '>>Equivocal',
    '>>Positive',
    '>>Negative',
    "GTT1'(OBS)",
    'Cockroach',
    ')Negative',
    ')Positive',
    ')Equivocal',
    'Anti-cardiolipin IgG',
    'Micro-method',
    '>>>Equivocal',
    '>>>Positive',
    '>>>Negative',
    'Anti-ENA SSB.',
    'S.enterotoxin B',
    'Vancomycin(trough)',
    'Procalcitonin',
    'DAT',
    'IAT',
    '*Preg.2nd month',
    '*Preg.1st week',
    '*Preg.3rd week',
    '*Preg.4-6week',
    '*Preg.3rd month',
    '*Preg.4th week',
    '*Preg.2nd week',
    'FK506',
    'Vancomycin Trough',
    'Vancomycin',
    'Vancomycin Peak',
    'Phadiatop',
    '))Equivocal',
    '))Positive',
    'Anti-B2 glycoprotein',
    '))Negative',
    'Negative',
    'Positive',
    'Anti-MitochondrialAb',
    'Ab ID',
    'Ceruloplasmin',
    'Grass mixes',
    'Allergen Panel F',
    'IFE',
    'PR3 ANCA.',
    'MPO ANCA.',
    'Myco.pneumonia Ab',
    'Milk',
    'CD4 Absolute count',
    'CD8 Absolute count',
    'CD4/CD8 ratio',
    'CD4 T cell',
    'ANCA',
    'Myoglobin',
    'PTH(intact)',
    'Myco.pneumonia IgM.',
    '"""Equivocal',
    'Anti-CCP',
    '"""Positive',
    '"""Negative',
    'Eosinophil count',
    'HBV DNA(Viread)',
    'T3,Free',
    'HIV viral load test',
    '**Ovulatory peak',
    '**Early follicular',
    'food mixes 2',
    'food mixes 21',
    'RBC morphology',
    'Leukocyte-Poor PLT',
    'Platelet Concentrate',
    'Rubella IgG',
    '@@@Negative',
    '@@@Equivocal',
    '@@@Positive',
    'Cryoprecipitate',
    'Crab',
    '!Negative',
    '!Positive',
    'Measles Ab',
    '!Equivocal',
    'Phenytoin',
    '*Positive',
    '*Equivocal',
    '*Negative',
    'Anti-HAV',
    'Phadiatop Infant',
    'ASMA',
    '6-9 years',
    'New born',
    '1-5 years',
    '10-15 years',
    'Adults',
    'Infants in 1st year',
    'Cyclosporine A',
    '$Positive',
    '$Equivocal',
    '$Negative',
    'HSV 2-Ab',
    'D.farinae',
    'FDP',
    '###Positive',
    'HSV 1-Ab.',
    '###Negative',
    '###Equivocal',
    'Blood irradiation',
    'Homocysteine',
    'Anti-HBc IgM',
    '@@Positive',
    'CMV IgM',
    '@@Equivocal',
    '@@Negative',
    'Yeast',
    'ASLO',
    '@Negative',
    '@Positive',
    '***Equivocal',
    '***Positive',
    '***Negative',
    '**Negative',
    '**Positive',
    '**Equivocal',
    'Deficient',
    '25(OH)Vit D Total',
    'Insufficient',
    'Upper Safety Limit',
    '****Male',
    '#Progesterone',
    '****Postmenopause',
    'CMV IgG',
    'Bermuda grass',
    '嚴重感染-Trough',
    'Egg white',
    'Beta2-Microglobulin',
    'Peanut',
    'EBV-VCA IgA.',
    'Prealbumin',
    '&&&Positive',
    '&&&Negative',
    'Anti-ENA Sm.',
    '&&&Equivocal',
    'EBV-EA IgA.',
    '*preg.0.2-1 week',
    '*preg.3-4 week',
    '*preg.6-8 week',
    '*Preg.8-12week',
    '*preg.5-6 week',
    '*preg.2-3 week',
    '*preg.1-2 week',
    'Protein S,functional',
    'Protein C,functional',
    'Anti-microsomal Ab',
    'TB Ag minus Nil',
    'Mitogen control',
    'TB Antigen',
    'Mitogen minus Nil',
    'IGRA result',
    'Nil control',
    'pediatric',
    'adult',
    'children-young',
    '#Equivocal',
    'H.pylori IgG.',
    '#Negative',
    '#Positive',
    '$$Positive',
    '$$Equivocal',
    'EBNA-Ab',
    '$$Negative',
    '*HbF 3 weeks',
    '*HbF 3-4 months',
    'HbA1',
    'HbH',
    '*HbF 6 weeks',
    '*HbF 6-12 months',
    'HbF',
    '*HbF 1-20 years',
    'HbA2',
    '*HbF 6 months',
    'Hb EP',
    '*HbF 1 day',
    '****Mid follicular',
    '****preg.0-3months',
    '****preg.3-6months',
    '****Mid luteal',
    'Allergen Panel G',
    'Anti-HAV(+)',
    'food mixes 1',
    'Anti-HAV(-)',
    'Li',
    '**Weil-F OXK',
    '**Weil-F OX2',
    '**S.paratyphi-AH',
    '**Weil-F OX19',
    '**S.typhi-O',
    '**S.paratyphi-BH',
    '**S.typhi-H',
    'Widal& Weil-F',
    'FDP conc.',
    'SO2%',
    'PCO2',
    'K+',
    'PO2',
    'HCO3-',
    'Allergen Panel E',
    'AT-III activity',
    'HCV Genotyping',
    'Candida albicans',
    'Alternaria alternata',
    'Cladosporium herbaru',
    'Penicillium notatum',
    'Thyroglobulin Ab',
    'Dog dander',
    'Cryoglobulin screen',
    'Plantain',
    'Helminthosporium hal',
    'Hollister-Stier Labs',
    'Goosefoot',
    'Common ragweed',
    'Wheat',
    'GLU',
    'Cat dander',
    'Soybean',
    'Toxoplasma IgG',
    '@@@@Negative',
    '@@@@Positive',
    '@@@@Equivocal',
    'Cyfra 21-1',
    'Cockroach,German',
    'pH',
    'Adult',
    'Children',
    'G-6-PD',
    'Hct',
    'Bahia Grass',
    'Anti-HBc IgM(+)',
    'Na+',
    'Anti-HBc IgM(-)',
    'BE-b',
    'Neonates',
    'Ca++',
    'Lac',
    'hs,CRP',
    'IgG4',
    'EBV-VCA IgM.',
    'Chlamydia Ab IgM.',
    'Stored Frozen Plasma',
    'Lupus anticagulant',
    'LA Normalized Ratio',
    '>Negative',
    'Anti-ENA RNP.',
    '>Equivocal',
    '>Positive',
    'f4 Wheat',
    'S.enterotoxin A',
    'LAP stain',
    'HIV screen-國',
    'Shrimp',
    'Latex Crypt Ag',
    'Chlamydia Ab IgG.',
    'Insulin',
    'LA1(DRVVT Screening)',
    'LA2 (DRVVT confirm.)',
    'd2 M.Farinae',
    'm5 Candida',
    'e85 Feather Mix',
    'w14 Pigweed Mix',
    'd1 M.Pteronyssinus',
    'f45 Yeast,Brewer',
    't70 Mulberry Mix',
    'w1 Ragweed Mix',
    'f207 Shellfish Mix',
    'g1 Grass Mix',
    'f33 Citrus Mix',
    'f8 Corn',
    'f25 Vegetable Mix',
    't12 Cottnwd/Willow',
    't73 Pine Mix',
    'Gum-tree',
    'Olive',
    'Acacia',
    'White pine',
    'Cajeput-tree',
    'Willow',
    'Carbamazepine',
    'Anti-Mullerian Horm.',
    'Banana',
    'EBV-VCA IgG.',
    'BM zone Ab',
    'Zn',
    'Kiwi',
    'Haptoglobin',
    'Pineapple',
    'Egg yolk',
    'Walnut',
    'Phlebotomy',
    'Melon',
    'Peach',
    'BNP',
    '(Positive',
    '(Negative',
    'Cu',
    'VZV Ab',
    'IGRA',
    'Chlam.pneumoniae IgM',
    'HCV(Harvoni Sovaldi)',
    '<<Equivocal',
    'Anti-ENA Jo-1.',
    '<<Positive',
    '<<Negative',
    'T/D-Bili Profile',
    'Blue mussel',
    'Tuna',
    'Salmon',
    'HLA-B1502',
    'Toxoplasma IgM',
    'Chlam.pneumoniae IgG',
    'Aspergillus fumigatu',
    'Fish-cod',
    'Latex',
    '(Equivocal',
    'Guineapig epithelium',
    'Blomia tropicalis',
    'Mouse epithelium',
    'Rat epithelium',
    'Whole Blood',
    'Anti-ENA Scl70.',
    '<Negative',
    '<Equivocal',
    '<Positive',
    '##Positive',
    '##Equivocal',
    '##Negative',
    'Sesame seed',
    'Buckwheat',
    'Oat',
    'Cryptococcus Ag',
    'Food mixes 26',
    'IHA for Amoeba',
    'Pb',
    'HLA-ABC',
    'HLA-DR/DQ',
    'Food Allergy 4',
    'Allergen Other mix3',
    'Timothy',
    'Tree Pollens mixes 7',
    'Aspergillus fumigate',
    'Microorganisms mix2',
    'House dust mixes 2',
    'Rye-Grass',
    'Maize Corn',
    'Mustard',
    'Rye Grass',
    'Cereal mix',
    'Seafood mix',
    'Mango',
    'free lambda',
    'Pre-formed Ab(I&II)',
    'Ig kappa/lambda',
    'PRA-class I',
    'free kappa',
    'PRA-class II',
    'kappa/lambda',
    'Phenobarbital',
    'JAK2V617F',
    'Cashew nut',
    'Pecan nut',
    'Pistachio',
    'Nutmix 22',
    'Normal pool plasma',
    'Rice',
    'Rye',
    '{Negative',
    '{Equivocal',
    '{Positive',
    '(((Equivocal',
    '(((Negative',
    '(((Positive',
    'HSV-2 IgM Ab',
    '((Equivocal',
    '((Negative',
    '((Positive',
    'Food mixes 5',
    'Beef',
    'Pork',
    'Allergen Panel C',
    'Onion',
    'Tomato',
    'Garlic',
    'Celery',
    'NSE',
    'Chicken',
    'Food Allergy 1',
    'Barley',
    'cod',
    'Hg',
    'Acetaminophen',
    'Chloramin T',
    'Ethylene oxide',
    'Phthalic anhydride',
    'House dust mix',
    'Fruit mix',
    'HSV-1 IgM Ab',
    'Grass mixes 1',
    'Food Allergy 2',
    'wheat',
    'egg yolk',
    'Potato',
    'DHEAS',
    'Female:65-74 years',
    'Female:15-19 years',
    'Male:10-14 years',
    'Female:45-54 years',
    'Female:10-14 years',
    'Male:>75 years',
    'Male:20-24 yaers',
    'Male:25-34 years',
    'Male:15-19 years',
    'Male:45-54 years',
    'Female:55-64 years',
    'Female:35-44 years',
    'Male:55-64 years',
    'Female:20-24 years',
    'Female:>75 years',
    'Female:25-34 years',
    'Male:35-44 years',
    'Male:65-74 years',
    'Therapeutic rang',
    'Casein',
    'beta-lactoglobulin',
    'B.c.fungus',
    'e1 Cat hair',
    'd2 D.farinae',
    'Allergen Panel A',
    'm5 C.albicans',
    'i6 Cockroach',
    'e5 Dog dander',
    'd1 D.pteronyssinus',
    'alpha-lactalbumin',
    'HBsAg quant',
    'HBV DNA(Sebivo)',
    'ESR-1hr',
    'Grass mixes 3',
    'incuba.2hr mix APTT',
    'incub.2hr contr.PTT',
    'Mixed APTT',
    "incub.2hr pt's APTT",
    'immediated mix APTT',
    "patient's APTT",
    'Gluten',
    'Squid',
    'Yeast MIC',
    'Grass mixes 7',
    'rAra h2 Peanut',
    'Aspergillus Ag',
    ')))Equivocal',
    'Haptoglobulin',
    'Anti-GBM Ab',
    ')))Negative',
    ')))Positive',
    'Washed RBCs',
    'Amikacin(trough)',
    'Pear',
    'Strawberry',
    'Lemon',
    'Paprika',
    'Cotton seed',
    'Grass mixes 6',
    'Pea',
    'Vegetables mixes 72',
    'Vegetables mixes 14',
    'Oak',
    'Parrot feathers',
    'Box-elder',
    'Goose feather',
    'Ginger',
    'Carrot',
    'Fruit mixes 16',
    'Silk',
    'Oyster',
    'Duck feather',
    'Anise',
    'Spinach',
    'White bean',
    'Chicken feather',
    'Meat mix 73',
    'Common silver birch',
    'Lobster',
    'American beech',
    'Vegetables mixes 7',
    'Food mixes 13',
    'Cabbage',
    'Cereal mixes 20',
    'Basil',
    '****Luteal phase',
    '****Mid-luteal phase',
    '****Follicular phase',
    '"Equivocal',
    '"Positive',
    '"Negative',
    'VZV IgM Ab',
    'Cd',
    'Specific IgE 100item',
    'House dust mix 2',
    'Fruit mix 21',
    'Animal dander mix 2',
    'gluten',
    'Ovomucoid',
    'Ovalbumin',
    'D. pteronyssinus',
    'HIV screen(preg.)',
    'Chemical mixes 6',
    'Formalin',
    '$$$Positive',
    'EBV-EA IgG',
    '$$$Negative',
    '$$$Equivocal',
    'Specific IgE Panel',
    'CA19-9',
    'AAT',
    'Intercellular sub.Ab',
    'Lymphocyte marker',
    'Amikacine Peak',
    'Amikacine',
    'Amikacine Trough',
    'p2PSA',
    'phi',
    'ProstateHealth Index',
    'CO2',
    'QuantiFeron-TB',
    'Dengue NS1 Ag',
    'BE-B',
    'NA+',
    'PH',
    'HCT',
    'HB',
    'Food allergen Panel',
    'HTLV-1 Ab',
    'TP/Cr',
    'Transfusion Reaction',
    'HCV RNA(Pegasys)',
    'LAC',
    'CA++',
    'HIV-1(Western blot)',
    'Malaria',
    'Enterovirus Ab',
    'immediated mixed PT',
    "patient's PT",
    'incub.2hr contr. PT',
    'Mixed PT',
    'incuba.2hr mixed PT',
    "incub.2hr pt's PT",
    'Almond',
    'Hazel nut',
    'Coconut',
    'Brazil nut',
    'PET-F',
    'CA15-3',
    '!!!Negative',
    '!!!Equivocal',
    '!!!Positive',
    'Mumps Ab',
    "GTT 60'",
    "GTT 180'",
    'GTT Ac',
    "GTT 120'",
    'CK',
    'AMI Screening',
    'Animal dander mix 71',
    'Transferrin',
    'Inhalant allergen',
    'IGRA(Enbrel/Xeljanz)',
    'Male Lead job',
    'Female Lead job',
    '<1週',
    'Cystatin C',
    '5-10 years',
    '1-12個月',
    '1-4 years',
    '1-4週',
    'Anti-parietal cellAb',
    'Factor V',
    'Animal dander mix 73',
    'Low range IgM',
    'Cockroach, German',
    'Cereal mix 20',
    'Condiments mix',
    'Rye grass',
    'Mutton',
    'D. farinae',
    'Bahia grass',
    'Seafood mix 2',
    'Chemical mix',
    'EBV PCR',
    'Formaldehyde',
    'MIC',
    'Rubella IgM',
    'Food mixes 21',
    'Glu',
    'Factor VIII:C',
    'Enterovirus71 IgM',
    '@Equivocal',
    'Rubella-IgG',
    'Food mixes 1',
    'HTLV-1 Ab quanti.',
    'LA Screen',
    'LA Confirm',
    'Food mixes 73',
    'Elution&Ab ID',
    'HLA-B5801',
    'Food mixes 3',
    'M-urine',
    'HSV 1&2 PCR',
    'Ovomucoid,Egg',
    'CMV PCR',
    'Anti-HDV',
    'PET-M',
    'DAD Profile',
    'Food Allergy 6',
    'Allergen Panel B',
    'f1 Egg white',
    '**Undetectable',
    'Methyl alcohol',
    '**Toxic Conc.',
    'Mushroom',
    'Food mixes 12',
    'HCV(DaklinzaSunvepra',
    'Pumpkin',
    'Corn',
    'vWF(Factor VIIIR:Ag)',
    'HSV 1+2 IgM',
    'Nutmix 1',
    'Acid elution test',
    'Food Allergy 7',
    'ALPHA 1',
    'Albumin EP',
    'Protein EP',
    'ALPHA 2',
    'BETA',
    'GAMMA',
    'Total Protein',
    'CMV viral load',
    '-Equivocal',
    '-Positive',
    'Chla.trachomatis IgG',
    '-Negative',
    'IgG 2',
    'IgG 1',
    'Leukemia/Lymphoma',
    'IgG subclass',
    'IgG 4',
    'IgG 3',
    'lymphoma phenotyping',
    'Pre-formed Ab class1',
    'Pre-formed Ab class2',
    'OGTT 2hr',
    'Apo-A1',
    'Lolium perenne',
    'IGRA(Humira)',
    '""Equivocal',
    'Mumps IgM Ab',
    '""Positive',
    '""Negative',
    '}}Equivocal',
    '}}}Negative',
    '}}Negative',
    'Ovalbumin,Egg',
    '}}Positive',
    'Anti-Ro52',
    '}}}Equivocal',
    'IGRA(Actemra)',
    'Anti-Ro60',
    '}}}Positive',
    '--Positive',
    'vWF:RCo(functional)',
    'Chla.trachomatis IgM',
    '--Negative',
    '--Equivocal',
    'CPK Isoenzyme',
    'CPK MM',
    '* GAL陽性',
    'Apo-B',
    'CPK MB',
    'CPK BB',
    'Fabry Screen',
    'GAL',
    'Lyso-Gb3',
    '* GAL Borderline',
    '1小時',
    '2小時',
    'HCV(viekirax exviera',
    'Leukemia/lymphoma-2',
    'Factor IX:C',
    'Gentamicin(trough)',
    'F/A.urine',
    'Macro CK II',
    'Macro CK I',
    'Preformed crossmatch',
    'Cold hemagglutinin',
    'DAT-IgG',
    'Allergen Panel D',
    'IGRA(Simponi,Stelara',
    'Egg White',
    'DAT-C3d',
    'Factor VII',
    'Cd Smoker',
    'Clam',
    'Cd Non-smoker',
    'Turkey',
    'Food mixes 10',
    'Sputum',
    'Crypt Ag titer',
    'PNH phenotyping',
    'IGRA(Olumiant)',
    'HBV DNA+YMDD',
    '**Mutation Rate',
    '**HBV DNA',
    '**YMDD',
    'Food mixes 2',
    'HBV DNA(Olumiant)',
    'IGRA(Orencia)',
    'Filaria',
    'Measles IgM Ab',
    '!!Positive',
    'Color',
    '!!Negative',
    '!!Equivocal',
    'HBV(Enbrel/Xeljanz)',
    "pt's Min. fragility",
    'Cynodon dactylon',
    'Cat hair',
    'Gentamycin Peak',
    "pt's MCF fragility",
    "pt's Max. fragility",
    'contr.Min.fragility',
    'Paspalum notatum',
    'Chenopodium album',
    'Food Allergy 3',
    'Ambrosia elatior',
    'Plantago lanceolata',
    'contr. MCF fragility',
    'contr.Max. fragility',
    'Gentamycin',
    'Gentamycin Trough',
    'Fungus',
    'IGRA(Entyvio)',
    '@D-Bilirubin',
    'HCV(elbasvir grazopr',
    'HBV DNA(Pegasys)',
    'Food Allergy 9',
    '{{Negative',
    'Isolation & ID virus',
    'AMI profile',
    '{{Positive',
    'Factor VIII:Ab',
    '{{Equivocal',
    'Anti-Centromere',
    'Salicylate',
    'Crystal',
    'Others',
    'Epithelial cell',
    'Bacteria',
    'Sediment',
    'Cry-amount',
    'cast-amount',
    'protein',
    'Cast',
    'ae,an,',
    'MIC rapid test',
    'Stool',
    'Clarity',
    'Bilirubin',
    'Urobilinogen',
    'OB',
    'Ketone',
    'Urine routine',
    'glucose',
    'WBC(esterase)',
    'Sp.gr.',
    'Nitrite',
    'Total Acid p-tase',
    'PLT Ab(Indirect)',
    'NBT phagocytosis',
    'Food mixes 22',
    'Thalassemia(Single)',
    'HLA符合試驗',
    'Vancomycin(peak)',
    'IGRA(Simpo,Stel,Trem',
    'VZV PCR',
    'IGRA(Simponi)',
    'Tree mixes',
    'Eucalyptus spp.',
    'Phragmites communis',
    'Mutant result',
    'HBV mutant 3',
    'HBV mutant 1',
    'HBV drug mutation',
    'Secale cereale',
    'TB culture',
    'Anthoxanthum odoratu',
    'Grass mixes 2',
    'Holcus lanatus',
    'Setomelanomma rostra',
    'HBV mutant 4',
    'Melaleuca leucadendr',
    'HBV mutant 2',
    'Salix caprea',
    'Pinus strobus',
    'Olea europaca',
    'Acacia longifolia',
    'Cholinesterase',
    'Amoebic Ab',
    'WBC Concentrate',
    'Flow MRD',
    'Food Allergy 8',
    'HCV RNA(Olumiant)',
    'HBV DNA(Humira)',
    'Bleeding time(Temp)',
    'ApoE gene PCR',
    'Fragility;Incubated',
    'G,stain',
    'Trough(5mg/day)',
    'Anti-PM-Scl',
    'LD2',
    'LD5',
    '}Positive',
    'Total LDH',
    'LD3',
    'LD4',
    'Sirolimus(委外)',
    'HBV(Simponi,Stelara)',
    'Leukemia phenotyping',
    'Fragility;Immediate',
    'LD1',
    '}Equivocal',
    'LDH Isoenzyme',
    '}Negative',
    'Sirolimus',
    'Trough(2mg/day)',
    'Sugar water test',
    'ER Blood irradiation',
    'Factor X',
    'S.agalactiae',
    'S.aureus',
    'Osmolality (Urine)',
    'S.marcescens',
    'HCV(Simponi,Stelara)',
    'C.parapsilosis',
    'K.pneumoniae',
    'E.coli',
    'Streptococci',
    '---Negative',
    'P.aeruginosa',
    'L.monocytogenes',
    'A.baumannii',
    'C.albicans',
    'KPC',
    'Staphylococci',
    'C.glabrata',
    'S.pyogenes',
    'BCID-Panel PCR',
    'C.krusei',
    'Copy number',
    'Enterobacteriaceae',
    'E.cloacae complex',
    '---Equivocal',
    '---Positive',
    'C.tropicalis',
    'Enterococci',
    'K.oxytoca',
    'N.meningitidis',
    'S.pneumoniae',
    'Proteus',
    'Anti-Mi2 Ab',
    'vanA/B',
    'mecA',
    'H.influenzae',
    'FLEAR/CD16 type II',
    'Maize,Corn',
    'FLEAR/CD24 type II',
    'PNH granulocyte flow',
    'Cacao',
    'Inhalant,food Panel',
    'NPM screen',
    'FLT3 screen',
    'Food Allergy 5',
    'Food mixes 7',
    'Goat milk',
    'FLEAR/CD24 type III',
    'FLEAR/CD16 type III',
    'Factor XI',
    '嚴重感染-Peak',
    '空 腹',
    'TDM-TCA',
    'Factor II',
    'Benzodiazepine',
    'HPV DNA PCR',
    'Tetrahydrocannabino',
    'Opiates',
    'Factor IX:Ab',
    'Factor XII',
    'KOH',
    'PMN bacteri capacity',
    'Hexamethylene diisoc',
    'Specific IgE 53 item',
    'Other',
    'Amikacin(peak)',
    'Mucin clot test',
    'Penicilloyl G Antibi',
    'Catecholamine',
    'Amoxicilloyl Antibio',
    'Penicilloyl V Antibi',
    'L:N',
    'Diphenylmethane diis',
    'Ampicilloyl Antibiot',
    'Drug,Chemical mix',
    'Cefaclor Antibiotics',
    'HCV RNA(Humira)',
    'PML/RARa mutant',
    'Dopamine',
    'Appearance',
    'Succinylcholine',
    'Toluene diisocyanate',
    'Synovial fluid',
    'PML/RARa quant.',
    'Epinephrine',
    'Plasminogen,Function',
    'HBV DNA(Simponi)',
    'Uric acid(Extra)',
    '新生兒換血血液費',
    'HBV DNA(Envir)',
    'HBV DNA(Orencia)',
    'Uric acid(Intra)',
    'TB PCR',
    'Chemical mix 5',
    'IGRA(Tecfidera)',
    'HBV(Simpo,Stela,Trem',
    'ThalassemiaPCR-Adult',
    'String test',
    'HCV(Enbrel/Xeljanz)',
    'Anti-RNA-pol III',
    'HCV(Simpo,Stela,Trem',
    'HCV RNA(Entyvio)',
    'HCV RNA(PegIntron)',
    'Rh Factor',
    '飯前',
    '{{{Equivocal',
    'Non-epinephrine',
    '{{{Positive',
    'LE cell preparation',
    '{{{Negative',
    'Leukemia/lymphoma',
    'Food mixes 16',
    'HBV DNA(Entyvio)',
    'Bronchial Washing',
    'N.gonorrhoeae PCR',
    'AML1/ETO quant.',
    'Cocaine',
    'AML1/ETO mutant',
    'EBV viral load',
    '',
    'Thyroid Asp Cytology',
    'Amphetamine',
    '毒物Panel(躁動)',
    '毒物Panel(嗜睡)',
    'Barbiturate',
    'Beta',
    'pre-Beta',
    'Very High',
    'Squamous Epi. cell',
    "Gram's stain",
    'chylomicrons',
    'HCV NS5A mutant(BMS)',
    'Normal',
    'Other Sites Cytology',
    'Lipoprotein EP',
    'Borderline High',
    'Gram(-) Cocci',
    'Gram(-) Bacilli',
    'Fungi',
    'Gram(+) Bacilli',
    'PMN',
    'EIA S.pneumonia Ag',
    'Common ae',
    'Surgical Pathology 4',
    'Gram(+) Cocci',
    'Alpha',
    'HBV DNA(Tecfidera)',
    'Microalbumin/Crea',
    'Microalbumin',
    'PML/RARa screen',
    'C.albicans PCR',
    'Influenza A,B test',
    'Anti-A,B Titer',
    '24 Hrs.Microalbumin',
    'C. diffi toxin',
    'HBV DNA(Actemra)',
    'A,stain',
    'HCV RNA(Orencia)',
    'VMA',
    'Gentamicin(peak)',
    'Myeloma phenotyping',
    'Pregnancy-EIA',
    'O2CT',
    'CBFB/MYH11 screen',
    'Liver Asp',
    'Interleukin-6',
    'Influenza A Ag',
    'Nor-epinephrine',
    'ParainfluenzaVirus4',
    'HIV 1+2 Confirmatory',
    'Coronavirus229E',
    'O2HB',
    'Pus cell',
    'Influenza B Ag',
    'HCV RNA(Tecfidera)',
    'Prothrombin time',
    'AML1/ETO screen',
    'BordetellaParapert',
    'Methamphetamine',
    'IVF Profile',
    'HBV DNA(Remicade)',
    'IGRA(Cosentyx)',
    'LN Asp',
    'Low risk',
    'HumanMetapneumoviru',
    'Breast Asp',
    'THB',
    'Resp-Panel PCR',
    'Influenza A&B Ag',
    'HIV-2 Ab',
    'ParainfluenzaVirus1',
    'COHB',
    'Lymphocyte transform',
    '**LH',
    'ECP test',
    'ChlamydophilaPneumo',
    'Influenza A',
    'HCV RNA(Remicade)',
    'High risk',
    'ParainfluenzaVirus2',
    'CoronavirusHKU1',
    'Occult blood (Chem)',
    'ParainfluenzaVirus3',
    'Adenovirus',
    'HIV-1 Ab',
    'HCV RNA(Simponi)',
    '**#Progesterone',
    'CoronavirusNL63',
    '##Estrogen',
    'BordetellaPertussis',
    'RespSyncytial Virus',
    'METHB',
    'IGRA(Remicade)',
    'CoronavirusOC43',
    'Influenza B',
    'HPV Genotyping',
    'Neck Mass Asp',
    'MycoplasmaPneumonia',
    'HumanRhino/Enterovi',
    'Acid Ham test',
    'Fluid Cyto/Block',
    'O2CAP',
  ],
  Others: [
    'Hb',
    'pH',
    'WBC',
    'RBC',
    'SO2%',
    'PO2',
    'K+',
    'HCO3-',
    'PCO2',
    '',
    'Creatinine',
    'BUN',
    'BE-b',
    'Na+',
    'Ht',
    'GPT(ALT)',
    'Hct',
    'eGFR',
    'Glucose',
    'GOT(AST)',
    'protein',
    'Platelet',
    'MCV',
    'Triglyceride',
    'Color',
    'glucose',
    'Promyelocyte',
    'Band',
    'Atypical lymphocyte',
    'Blast',
    'Myelocyte',
    'Monocyte',
    'Nucleated RBC',
    'Basophil',
    'Eosinophil',
    'Metamyelocyte',
    'Lymphocyte',
    'Differential count',
    'Clarity',
    'OB',
    'MCHC',
    'MCH',
    'RDW',
    'CBC/Platelet/DC',
    'Segmented Neutro.',
    'HDL-c',
    'T-chol/HDL-C',
    'albumin',
    'Ketone',
    'Urobilinogen',
    'Nitrite',
    'WBC(esterase)',
    'Bilirubin',
    'Cry-amount',
    'Crystal',
    'Cast',
    'Bacteria',
    'Epithelial cell',
    'Others',
    'cast-amount',
    'Sediment',
    'Alkaline p-tase',
    'GLU',
    'HBsAg',
    'Sp.gr.',
    'LDL-C',
    'Urine routine',
    'AFP',
    'CEA',
    'Desirable',
    'High',
    'Borderline high',
    'T-Cholesterol',
    'K',
    'Ca++',
    'Anti-HCV',
    'Na',
    'Surgical Pathology 4',
    'Anti-HBs',
    'Total-protein',
    'HbA1C',
    'TSH',
    'T-Bilirubin',
    'Lac',
    '#Near optimal',
    '#Borderline high',
    '#Very high',
    '#Optimal',
    '#High',
    'Stool HB(BHP)',
    'A/G',
    'T4,Free',
    'r-GT',
    'D-Bilirubin',
    'ANC(absolute Neu.#)',
    'hs,CRP',
    'Stool HB(quantity)',
    'Parasite Ova-Direct',
    'Surgical Pathology 3',
    'Uric Acid',
    'HBsAg(+)',
    'HBsAg(-)',
    'Anti-HBs(+)',
    'Anti-HBs(-)',
    'Anti-HCV(-)',
    'Anti-HCV(+)',
    'PSA',
    'Uric acid',
    'Abnormal monocyte',
    'Plasmacytoid lymph.',
    'Promonocyte',
    'Abnormal lymphocyte',
    'NA+',
    'STS-RPR',
    'BE-B',
    'PH',
    'HCT',
    'Globulin',
    'Albumin/Globulin',
    'HB',
    'Normal control',
    'CA125',
    'P',
    'CA 19-9',
    'iCa',
    'Protein',
    'Total protein',
    'Total cholesterol',
    'M.C.V.',
    'HDL-C',
    'CA19-9',
    'Dialysis Profile',
    'CA++',
    'LAC',
    'Rh Typing D',
    'ABO Typing',
    'INR',
    'PT',
    'Small specimen',
    'Anti-HAV',
    'Homocysteine',
    'Glucose PC',
    'APTT',
    'Prediabetes',
    'Diabetes',
    'nCa',
    'SBC',
    'BE-ecf',
    'TCO2',
    'a/A',
    'A',
    'Pap smear',
    'PO2/FIO2',
    'HBeAg',
    'ae,an,',
    'PET-M',
    'CA153',
    '@Positive',
    '@Negative',
    '@Equivocal',
    'Rubella-IgG',
    'Cl',
    'APTT ratio',
    'Multistix strip test',
    'Sp.Gr.',
    'Ferritin',
    'TIBC',
    'Serum Iron/TIBC',
    'Iron',
    'Ca',
    'Glu',
    'PET-F',
    'CA15-3',
    'Surgical Pathology 5',
    'Hema-combi+sed(SHE-U',
    'SHE-B',
    'CPK',
    'Anti-HAV(+)',
    'Anti-HAV(-)',
    'Apo-A1',
    'CPK MM',
    'Macro CK II',
    'CPK MB',
    'CPK BB',
    'Macro CK I',
    'CPK Isoenzyme',
    'Ab Screening',
    'Iron/TIBC',
    'HIV screen-國',
    'Surgical Pathology 2',
    'Apo-B',
    'Fibrinogen',
    'PCU profile',
    'Surgical Pathology 6',
    'Alpha',
    'Lipoprotein EP',
    'chylomicrons',
    'pre-Beta',
    'Beta',
    'SHE-A',
    'HBeAg(+)',
    'HBeAg(-)',
    'HIV screen(preg.)',
    'Anti-HBc',
    'Folate',
    'Insulin',
    'VZV Ab',
    '(Negative',
    '(Positive',
    'Pineapple',
    'Wheat',
    'Peanut',
    'Kiwi',
    'Milk',
    'Crab',
    'Peach',
    'Banana',
    'Melon',
    'Egg white',
    'Shrimp',
    'Soybean',
    'Tuna',
    'Fish-cod',
    'Salmon',
    'Blue mussel',
    'Food mixes 26',
    'Latex',
    'Microorganism mixes',
    'Penicillium notatum',
    'Aspergillus fumigatu',
    'Alternaria alternata',
    'Cladosporium herbaru',
    'Candida albicans',
    'Dog dander',
    'Plantain',
    'Rat epithelium',
    'Blomia tropicalis',
    'Helminthosporium hal',
    'Common ragweed',
    'Mouse epithelium',
    'D.pteronyssinus',
    'Cat dander',
    'Guineapig epithelium',
    'Bermuda grass',
    'Hollister-Stier Labs',
    'Cockroach,German',
    'Sesame seed',
    'Buckwheat',
    'Oat',
    'Rye Grass',
    'S.enterotoxin A',
    'Goosefoot',
    'Seafood mix',
    'S.enterotoxin B',
    'Mustard',
    'D.farinae',
    'Maize Corn',
    'Bahia Grass',
    'Cereal mix',
    'Frozen section',
    '(Equivocal',
    'Stool HB(SHE)',
    'Fruit mix',
    'Animal dander mixes',
    'House dust mix',
    'Specific IgE Panel',
    'Grass mixes 1',
    'APTT.',
    'Upper Safety Limit',
    'Insufficient',
    'Deficient',
    '25(OH)Vit D Total',
    'Walnut',
    '@@@Positive',
    '@@@Negative',
    '@@@Equivocal',
    'Rubella IgG',
    'HPV DNA PCR',
    'Pistachio',
    'Cashew nut',
    'Nutmix 22',
    'Pecan nut',
    'Al',
    'HIV Screen',
    'Anti-HBc(-)',
    'Anti-HBc(+)',
    'TB culture',
    'h2 Housedust',
    'MAST',
    'f26 Pork',
    'e5 Dog',
    'f13 Peanut',
    'i6 Cockroach Mix',
    't18 Eucalyptus',
    'f2 Milk',
    'f3 Codfish',
    'm2 Cladosporium',
    'e1 Cat',
    'f24 Shrimp',
    'f23 Crab',
    'm3 Aspergillus',
    'g2 Bermuda Grass',
    'm1 Penicillium',
    'f1 Egg White',
    'f14 Soybean',
    'm6 Alternaria',
    'f75 Egg Yolk',
    'f27 Beef',
    '!Positive',
    '!Negative',
    'Measles Ab',
    '!Equivocal',
    'IgE',
    '6-9 years',
    'Adults',
    '1-5 years',
    '10-15 years',
    'New born',
    'Infants in 1st year',
    'Segment',
    'Stool Hb(免疫法)',
    'DAD Profile',
    'T4',
    'T3',
    'f81 Cheddar Cheese',
    'w1 Ragweed Mix I',
    'f96 Avocado',
    't70 White Mulberry',
    'f40 Tuna',
    'f207 Clam',
    't17 Japanese Cedar',
    'w14 Pigweed',
    'f155 Brewer Yeast',
    'f4 Wheat(Food)',
    'g6 Timothy Grass',
    'd1 Mite DP',
    'k82 Latex',
    't32 Willow Black',
    'd2 Mite DF',
    'e85 Chicken Feathers',
    'Hazel nut',
    'Coconut',
    'Brazil nut',
    'Almond',
    'Nutmix 1',
    'F/A.urine',
    'MAR',
    'Motility',
    'Anti-HAV IgM',
    'CRP',
    'Blood',
    'Consistency',
    'Peritoneal Effusion',
    'Cross Matching Test',
    'Bleeding time(Duke)',
    'Thyroid Asp Cytology',
    'LDH',
    'Bladder/UT Wash/Brus',
    'Packed RBCs',
    'Surgical pathology I',
    'f25 Vegetable Mix',
    'd2 M.Farinae',
    'f207 Shellfish Mix',
    'w14 Pigweed Mix',
    'f8 Corn',
    't70 Mulberry Mix',
    'f33 Citrus Mix',
    'e85 Feather Mix',
    't12 Cottnwd/Willow',
    'w1 Ragweed Mix',
    'f45 Yeast,Brewer',
    'd1 M.Pteronyssinus',
    'g1 Grass Mix',
    't73 Pine Mix',
    'f4 Wheat',
    'm5 Candida',
    'Other Sites Asp',
    'Amount',
    'Examination time',
    'Semen analysis',
    'Collection time',
    'Count',
    'ANA(IFA)',
    'Stool routine',
    'Mucus',
    'Occult Blood (Chem)',
    'Appearance',
    'Other',
    'L:N',
    "Rivalta's test",
    'Yeast',
    'Mango',
    'Rye',
    'Rice',
    'Pork',
    'Tomato',
    'Celery',
    'Egg yolk',
    'Barley',
    'Chicken',
    'Onion',
    'Beef',
    'Garlic',
    'Pleural fluid',
    'Stool',
    'CBC+DC',
    'Parasite Ova-Conc.',
    'O2CAP',
    'O2HB',
    'METHB',
    'O2CT',
    'THB',
    'COHB',
    'Grass mixes 7',
    'Olive',
    'White pine',
    'Acacia',
    'Cajeput-tree',
    'Gum-tree',
    'Willow',
    'Potato',
    'Squid',
    'Meat mix 73',
    'Basil',
    'Oak',
    'Lobster',
    'Ginger',
    'Box-elder',
    'Cabbage',
    'Vegetables mixes 7',
    'Vegetables mixes 14',
    'Anise',
    'Pea',
    'Cotton seed',
    'Food mixes 13',
    'Goose feather',
    'Carrot',
    'Strawberry',
    'Gluten',
    'White bean',
    'Parrot feathers',
    'Fruit mixes 16',
    'Cereal mixes 20',
    'Common silver birch',
    'Spinach',
    'Lemon',
    'Silk',
    'Vegetables mixes 72',
    'Chicken feather',
    'Paprika',
    'Oyster',
    'Pear',
    'Duck feather',
    'American beech',
    'Grass mixes 6',
    'Morphology',
    'c:non-progressive',
    'Agglutination',
    'Liguefaction',
    'a:rapid-linear',
    'Concentration',
    'b:slow-linear',
    'Apperance',
    'd:immotile',
    'Bronchial Brushing',
    'Grass mixes 3',
    'Chloramin T',
    'Ethylene oxide',
    'Phthalic anhydride',
    'Chemical mixes 6',
    'Specific IgE 100item',
    'Fruit mix 21',
    'Formalin',
    'Animal dander mix 2',
    'House dust mix 2',
    'Volume(ml)',
    "WBC'S(/HPF)",
    'Pregnancy-EIA',
    '**Weil-F OXK',
    '**S.typhi-O',
    'Widal& Weil-F',
    '**Weil-F OX19',
    '**S.paratyphi-AH',
    '**S.typhi-H',
    '**S.paratyphi-BH',
    '**Weil-F OX2',
    'Fluid Cyto/Block',
    "Down's Risk First",
    'Free b-HCG',
    'PAPP-A',
    'Fungus',
    'Atypical sperm',
    '& Positive',
    '& Grayzone',
    '& Negative',
    'Normal forms',
    'Bronchial Washing',
    'Animal dander mix 73',
    'Cl-',
    'Sputum',
    'OI',
    '#midcycle',
    '#Postmenopause',
    '#Luteal phase',
    '#Follicular phase',
    'FSH',
    '#Male',
    '#prepuberty',
    'Animal dander mix 71',
    'ESR',
    'Male',
    'Lipase',
    'Estrogen',
    '**Mid follicular',
    'Post-menopausal',
    '**Mid luteal',
    '**Male',
    '**Peri-ovulatory',
    'Pleural Effusion',
    'Urine Cytology,Cath.',
    'Amylase',
    'Female',
    '-Negative',
    '-Positive',
    'Chla.trachomatis IgG',
    '-Equivocal',
    'Troponin I',
    'LN Asp',
    'TPPA',
    '**Testosterone',
    '**IU/mL',
    '**Copy number',
    'HBV-DNA viral load',
    'Common ae',
    'Blastocystis hominis',
    'Equivocal',
    'Trophozoite',
    'E.histolytica/Dispar',
    'Cyst',
    'Endolimax nana',
    'Entamoeba coli',
    'G,stain',
    'Neck Mass Asp',
    'Mg',
    'Ammonia',
    'TP/Cr',
    'Other Sites Cytology',
    'HDL',
    'Viscosity',
    '**Prolactin',
    'RA',
    '99% URL',
    'Cutoff for AMI',
    '**Positive',
    '**Equivocal',
    '**Negative',
    'Thin layer cyt',
    'Chlamydia Ab IgG.',
    '#LH',
    '***Follicular phase',
    '***Male',
    '***Luteal phase',
    '***Postmenopause',
    '***Midcycle',
    'CO2',
    'Breast Asp',
    'Washed RBCs',
    'PSA,Free',
    'fPSA/PSA',
    'Mitogen control',
    'Nil control',
    'TB Ag minus Nil',
    'TB Antigen',
    'IGRA result',
    'Mitogen minus Nil',
    'Beta-HCG',
    '*Nonpreg.female',
    '*Male',
    'CK-MB',
    '*Preg.4-5week',
    'HER-2/NEU,IHC',
    'M-urine',
    'A,stain',
    'pre-menopause',
    'postmenopause',
    'HIV-1(Western blot)',
    'Food mixes 1',
    'Ab ID',
    '**copy number',
    'Specific IgE 53 item',
    '**IU',
    'Occult blood (Chem)',
    'Testosterone',
    'IGRA',
    '**PM Cortisol',
    'Anti-HBe',
    '**AM Cortisol',
    'SHE-M',
    '22-week',
    '#Cortisol',
    '40-week',
    '36-week',
    'Ca Phosphate CO3 For',
    'Calcium Carbonate',
    'Ammonium Mg Phosphat',
    'Cystine',
    '29-week',
    '30-week',
    '32-week',
    'Ca Phosphate CaHPO4.',
    'Ca Phosphate Ca3(PO4',
    '18-week',
    'Protein(Calculi)',
    '38-week',
    'Ammonium Urate',
    '34-week',
    'Calcium Oxalate',
    'Sodium Urate',
    'HCV-RNA(viral load)',
    'Leukocyte-Poor RBCs',
    'Microalbumin/Crea',
    '24 Hrs.Microalbumin',
    'EBV-VCA IgA.',
    '*Negative',
    '*Equivocal',
    '*Positive',
    'Microalbumin',
    '自體輸血',
    'Pancreas Asp',
    "WBC'S",
    'Voume',
    'SCC',
    'C3',
    '**UR Cortisol',
    'C4',
    'EIA S.pneumonia Ag',
    'D dimer(ELISA)',
    'Negative',
    'CSF Cytology',
    'Positive',
    'HLA-B27',
    'EBV-EA IgA.',
    '*Preg.4-6week',
    '*Preg.2nd week',
    '*Preg.1st week',
    '*Preg.3rd month',
    '*Preg.4th week',
    '*Preg.3rd week',
    '*Preg.2nd month',
    'NSE',
    'TB PCR',
    'Anti-HBe(-)',
    'Anti-HBe(+)',
    'Food allergen Panel',
    'LDL',
    'SHE-F',
    'Cereal mix 20',
    'Seafood mix 2',
    'Mutton',
    'Condiments mix',
    'ESR-1hr',
    'Blood culture',
    'EIA Strpto gr A Ag',
    '*preg.3-4 week',
    '*preg.5-6 week',
    '*Preg.8-12week',
    '*preg.1-2 week',
    '*preg.0.2-1 week',
    '*preg.2-3 week',
    '*preg.6-8 week',
    'Digoxin',
    'HSV-2 IgM Ab',
    '(((Positive',
    'QuantiFeron-TB',
    '(((Equivocal',
    '(((Negative',
    'Myco.pneumonia Ab',
    '#Progesterone',
    '****Male',
    'Panel I',
    '****Postmenopause',
    'Soft Tissue Asp',
    'Creatinine(U)',
    'Food mixes 73',
    'Lactate',
    '$Equivocal',
    '$Positive',
    'Myco.pneumonia IgM.',
    '$Negative',
    'HSV 2-Ab',
    'Pus cell',
    '****preg.0-3months',
    '****Mid follicular',
    '****Mid luteal',
    '****preg.3-6months',
    'Ascites',
    'Anti-TPO',
    'IgG',
    'Osmolarity',
    'PTH(intact)',
    'B12',
    'Anti-ds DNA.',
    'KOH',
    'Cyfra 21-1',
    'Food mixes 5',
    '###Negative',
    'GTT',
    'HSV 1-Ab.',
    '###Positive',
    '1 小時',
    '2 小時',
    'Salivary Gland Asp',
    'Total-protein(U)',
    '###Equivocal',
    'Hg',
    '空腹',
    'Apheresis Platelet',
    'IAT',
    '*Postmenopause',
    '**Early follicular',
    '**Ovulatory peak',
    'DAT',
    'Fresh Frozen Plasma',
    'Kidney Study',
    'Urine Cytology,Void',
    'Latex Crypt Ag',
    'Lung Aspiratio',
    'Trichomonas Test',
    'Pb',
    'Blood adult no anti',
    'CK-MB/CK',
    'IgA',
    'Renal function',
    'IgM',
    '&Negative',
    'Transfusion Reaction',
    'Osmolality (Urine)',
    '&Positive',
    '&Equivocal',
    'Anti-ds DNA',
    'DM Profile I',
    'Sputum Cytology',
    'NT-proBNP',
    '***Positive',
    '>>Equivocal',
    'Dialysate',
    'pro-BNP',
    '##Equivocal',
    '##Negative',
    'Pericardial Effusion',
    '>>Positive',
    '##Positive',
    'Blood Ketone',
    '***Negative',
    'Anti-ENA SSA.',
    'C.difficil',
    '>>Negative',
    '***Equivocal',
    'Male:65-74 years',
    'Female:>75 years',
    'Female:10-14 years',
    'Food mixes 10',
    'Turkey',
    'Female:15-19 years',
    'Female:35-44 years',
    'Male:45-54 years',
    'Male:>75 years',
    'Male:15-19 years',
    'Male:10-14 years',
    'SMA PCR',
    'Female:45-54 years',
    'Female:65-74 years',
    'Male:20-24 yaers',
    'Female:55-64 years',
    'Male:25-34 years',
    'DHEAS',
    'Female:25-34 years',
    'Male:35-44 years',
    'Female:20-24 years',
    'Male:55-64 years',
    'Anti-Mullerian Horm.',
    'Zn',
    '>>>Negative',
    '"""Positive',
    'Feces Rota virus Ag',
    '((Positive',
    '5-10 years',
    'Anti-CCP',
    '"""Equivocal',
    '((Negative',
    '((Equivocal',
    'Blood adult anti',
    'Reticulocyte count',
    'Lupus anticagulant',
    'High risk',
    'Chlamydia Ab IgM.',
    '<1週',
    '>>>Positive',
    '1-4 years',
    'LA Normalized Ratio',
    'Ethyl alcohol',
    '1-12個月',
    '"""Negative',
    '1-4週',
    'Anti-ENA SSB.',
    '>>>Equivocal',
    '@T-Bilirubin',
    'Micro-method',
    'B.c.fungus',
    'Leukocyte-Poor PLT',
    'Yeast MIC',
    'HLA符合試驗',
    'Chlamydia Ag',
    'Opiates',
    '****Mid-luteal phase',
    'Thyroglobulin Ab',
    '****Follicular phase',
    'Benzodiazepin',
    '****Luteal phase',
    'Blood irradiation',
    'Chemical mix',
    'G-6-PD',
    'Benzodiazepine',
    '#Negative',
    'IFE',
    'Neonates',
    '#Positive',
    'Children',
    'Formaldehyde',
    'Male Lead job',
    'Acetaminophen',
    'Female Lead job',
    'Influenza A,B test',
    'Amphetamine',
    'H.pylori IgG.',
    '#Equivocal',
    'CBC/Platelet',
    'TDM-TCA',
    'Platelet Concentrate',
    'Adult',
    'Tetrahydrocannabino',
    'Cocaine',
    'Procalcitonin',
    'AMI Screening',
    'Low risk',
    'LA2 (DRVVT confirm.)',
    'HSV 1+2 IgM',
    'String test',
    'T3,Free',
    'Drug,Chemical mix',
    'Myoglobin',
    'HCV RNA(Pegasys)',
    'HIV viral load test',
    'Toluene diisocyanate',
    '<<Equivocal',
    'Mucin clot test',
    'Uric acid(Intra)',
    'Uric acid(Extra)',
    'Mumps Ab',
    'Penicilloyl V Antibi',
    'Gram(+) Bacilli',
    'PFA-100:Col/ADP time',
    'Ampicilloyl Antibiot',
    'HPV 16',
    'HSV-1 IgM Ab',
    'Synovial fluid',
    'Succinylcholine',
    'Cefaclor Antibiotics',
    'PR3 ANCA.',
    'HPV Genotyping',
    'Valproic acid',
    'Gram(+) Cocci',
    '<<Positive',
    'Inhalant allergen',
    'CK',
    '!!!Negative',
    'PLT function screen',
    'Ketamine Screen',
    'Gram(-) Bacilli',
    'Methamphetamine',
    '&&Positive',
    '&&Equivocal',
    'Hexamethylene diisoc',
    'PFA-100:Col/Epi time',
    'Diphenylmethane diis',
    '<<Negative',
    '!!!Equivocal',
    'India ink',
    'MPO ANCA.',
    'Squamous Epi. cell',
    'Penicilloyl G Antibi',
    '&&Negative',
    'Anti-ENA Jo-1.',
    'Mediastinal Asp',
    'LA Screen',
    '!!!Positive',
    'Fungi',
    'Gram(-) Cocci',
    'Chemical mix 5',
    '毒物Panel(躁動)',
    'Cu',
    'PMN',
    'Whole Blood',
    "Gram's stain",
    'Amoxicilloyl Antibio',
    'LA Confirm',
    'Anti-ENA screen.',
    'LA1(DRVVT Screening)',
    'Prothrombin time',
    'Thyroglobulin',
    'Cockroach, German',
    'EBV-VCA IgG.',
    'O2Cap',
    'CoronavirusOC43',
    'ParainfluenzaVirus3',
    'FDP',
    'Na(U)',
    'Hb EP',
    'Lymphocyte marker',
    'Coronavirus229E',
    'CD4/CD8 ratio',
    'Aspergillus Ag',
    'Ceruloplasmin',
    'Protein C,functional',
    '*HbF 6 weeks',
    '"Positive',
    '*HbF 3-4 months',
    '*HbF 6-12 months',
    'Influenza A',
    '<Positive',
    'CoronavirusHKU1',
    'BordetellaPertussis',
    'Bahia grass',
    'O2Ct',
    'CoronavirusNL63',
    'Anti-ENA Scl70.',
    'HbA1',
    'MycoplasmaPneumonia',
    'HCV(Harvoni Sovaldi)',
    'HPV 18/45',
    'HumanRhino/Enterovi',
    'Leukemia/lymphoma-2',
    'CD4 T cell',
    'Cd',
    '<Equivocal',
    '*HbF 1 day',
    'tHb',
    '{Positive',
    'Influenza A Ag',
    'HbF',
    'HbH',
    'Influenza B Ag',
    'AMI profile',
    '<Negative',
    'Cryptococcus Ag',
    '*HbF 3 weeks',
    'EBV PCR',
    'COHb',
    'RespSyncytial Virus',
    'Peroxidase stain',
    'HumanMetapneumoviru',
    'HPV 16/18/45',
    'Cytogenetics study',
    'CD4 Absolute count',
    "GTT1'(OBS)",
    'ANCA',
    'HbA2',
    'HCV Genotyping',
    '"Negative',
    'Lolium perenne',
    'VZV IgM Ab',
    '"Equivocal',
    'Pigeon feathers',
    'Anti-MitochondrialAb',
    'ParainfluenzaVirus1',
    'ParainfluenzaVirus4',
    'MetHb',
    'BUN(U)',
    'Influenza A&B Ag',
    'K(U)',
    'ASLO',
    'D. farinae',
    '{Negative',
    'Total Acid p-tase',
    'Rye grass',
    'B-strep A',
    'CD8 Absolute count',
    'D. pteronyssinus',
    'Adenovirus',
    'ChlamydophilaPneumo',
    "Pandy's test",
    'Li',
    'Protein S,functional',
    'O2Hb',
    'Isolation & ID virus',
    'Influenza B',
    '{Equivocal',
    'CSF',
    'ParainfluenzaVirus2',
    'Chlam.pneumoniae IgM',
    '*HbF 6 months',
    '*HbF 1-20 years',
    'Resp-Panel PCR',
    'Liver Asp',
    'Anti-microsomal Ab',
    'Eosinophil count',
    'EBV viral load',
    'BM zone Ab',
    'ASMA',
    'N.gono',
    'C.albicans PCR',
    'C.neoformans/gattii',
    'L.monocytogenes',
    'FK506',
    '@@@@Positive',
    'Allergen Panel E',
    'Myoglobin.',
    'Setomelanomma rostra',
    'PRA-class II',
    'HSV6',
    'Flow MRD',
    'Barbiturate',
    'EBV-VCA IgM.',
    'FDP conc.',
    'Cockroach',
    'HPV 16/18',
    'Paspalum notatum',
    'Respir.Adenovirus Ag',
    '>Positive',
    'Food mixes 21',
    'Cynodon dactylon',
    'RBC morphology',
    'P-AFB',
    '!!Equivocal',
    'N.meningitidis',
    'T/D-Bili Profile',
    'Rubella IgM',
    'ME-Panel PCR',
    'Anthoxanthum odoratu',
    'Melaleuca leucadendr',
    'GBS screening test',
    'Transferrin',
    'HTLV-1 Ab quanti.',
    'H.influenzae',
    'Holcus lanatus',
    'Vancomycin(trough)',
    'iFOB & Transferrin',
    'CPPD(Intra)',
    'food mixes 2',
    'Chla.trachomatis PCR',
    'Preformed crossmatch',
    'Toxoplasma IgG',
    'EBNA-Ab',
    'Tree mixes',
    'Measles IgM Ab',
    'Low Range IgE',
    'Pumpkin',
    'Anti-HBc IgM(+)',
    '@@@@Negative',
    'Food mixes 3',
    '>Equivocal',
    'Cytomegalovirus',
    'Pre-formed Ab(I&II)',
    '嚴重感染-Trough',
    'Campylobacter',
    'Stool H.P Ag',
    'BM.examination',
    'Secale cereale',
    'Cryoglobulin screen',
    'Factor VIII:Ab',
    'children-young',
    'HCV(DaklinzaSunvepra',
    'S.agalactiae',
    'Pb(U)',
    'Cat hair',
    'p2PSA',
    '!!Positive',
    'adult',
    'HLA-ABC',
    'Myeloma phenotyping',
    'Olea europaca',
    'ProstateHealth Index',
    'VZV',
    'Salix caprea',
    'HTLV-1 Ab',
    'Acacia longifolia',
    '@@@@Equivocal',
    'BordetellaParapert',
    'Eucalyptus spp.',
    '$$Negative',
    'pediatric',
    'Anti-HBc IgM(-)',
    'HLA-DR/DQ',
    '毒物Panel(嗜睡)',
    'Leukemia/lymphoma',
    'Bleeding time(Temp)',
    'S.pneumoniae',
    'Enterovirus',
    'PRA-class I',
    'HCV(viekirax exviera',
    'HPV 18',
    'Pinus strobus',
    'Anti-HBc IgM',
    'HSV2',
    '$$Equivocal',
    'HBsAg quant',
    'phi',
    'Ambrosia elatior',
    'Theophylline',
    '!!Negative',
    'HSV1',
    'IgG4',
    'CPPD(Extra)',
    'E.Coli K1',
    'Dengue NS1 Ag',
    'Chenopodium album',
    'Phragmites communis',
    'Cl(U)',
    '>Negative',
    'Parechovirus',
    'Corn',
    'iFOB',
    'Anti-ENA RNP.',
    'Food mixes 12',
    'Plantago lanceolata',
    'LAP stain',
    'Mushroom',
    'Grass mixes 2',
    '$$Positive',
    'food mixes 21',
  ],
  Urine: [
    'WBC',
    'cast-amount',
    'Crystal',
    'Bacteria',
    'Cry-amount',
    'Others',
    'Cast',
    'RBC',
    'Sediment',
    'Epithelial cell',
    'protein',
    'glucose',
    'pH',
    'Ketone',
    'Urobilinogen',
    'Nitrite',
    'Bilirubin',
    'Color',
    'WBC(esterase)',
    'Clarity',
    'OB',
    'Sp.gr.',
    'Urine routine',
    'TP/Cr',
    'Microalbumin',
    '24 Hrs.Microalbumin',
    'Microalbumin/Crea',
    'Creatinine(U)',
    'Creatinine',
    'eGFR',
    'M-urine',
    'Total-protein(U)',
    'Total-protein',
    'F/A.urine',
    'Urine Cytology,Void',
    'Pregnancy-EIA',
    'Osmolality (Urine)',
    'Na',
    'BUN',
    'Na(U)',
    'K',
    'EIA S.pneumonia Ag',
    'Opiates',
    'Benzodiazepine',
    'Acetaminophen',
    'Tetrahydrocannabino',
    'TDM-TCA',
    'BUN(U)',
    'K(U)',
    'Cl',
    'Barbiturate',
    '毒物Panel(嗜睡)',
    'Latex B-strept Ag',
    'Amphetamine',
    'Multistix strip test',
    'Sp.Gr.',
    'Leginella Ag',
    'Cocaine',
    '毒物Panel(躁動)',
    'Cl(U)',
    'Legionella Ag',
    'Ca',
    'Methamphetamine',
    'P',
    'Glu',
    'DAD Profile',
    'Urine Cytology,Cath.',
    'Ca(U)',
    'Urine RBC morphology',
    'total RBC',
    'crenate RBC',
    'ghost RBC',
    'Chlamydia Ag',
    'Mg',
    'P(U)',
    'Ketamine Screen',
    'Yeast MIC',
    'IFE',
    'Mg(U)',
    'Porphyrin',
    'Fungus',
    'Trichomonas Test',
    'TB culture',
    'Myoglobin.',
    'Uric Acid',
    'Uric Acid(U)',
    'Bence-Jones protein',
    'Isolation & ID virus',
    'Uric acid',
    'Blood',
    'Myoglobin',
    'N.gono',
    'Glucose(U)',
    'A,stain',
    'ae,an,',
    'CMV PCR',
    'Total Protein',
    'ALPHA 1',
    'Protein EP',
    'ALPHA 2',
    'GAMMA',
    'BETA',
    'Albumin EP',
    'Osmolarity',
    'Pb',
    'Glucose',
    'b2-Microglobulin(U)',
    'Chyle',
    'N.gonorrhoeae PCR',
    'TB PCR',
    'HCO3-',
    'Ca++',
    'SO2%',
    'K+',
    'PO2',
    'PCO2',
    'Lac',
    'Hb',
    'BE-b',
    'Hct',
    'Cu(U)',
    'Sputum',
    'GLU',
    '',
    'Surgical Pathology 4',
    'Occult blood (Chem)',
    'MIC rapid test',
    'Bladder/UT Wash/Brus',
    'NA+',
    'Codeine(GCMS)',
    'G,stain',
    'Morphine',
    'Cu',
    'Blood culture',
    'Morphine(GCMS)',
    'VMA',
    'Pus cell',
    'Common ae',
    'Stool',
    'Other Sites Asp',
    'Beta2-Microglobulin',
    'Feces Rota virus Ag',
    '**UR Cortisol',
    'Other Sites Cytology',
    'AFB',
    '**AM Cortisol',
    'Pb(U)',
    'Na+',
    '**PM Cortisol',
    'B.c.fungus',
    '#Cortisol',
    '###Negative',
    'Catecholamine',
    'Cotinine(U)',
    'Non-epinephrine',
    'HSV 2-Ab',
    '###Positive',
    'HSV 1-Ab.',
    'Amphetamine(GC/MS)',
    'Myco.pneumonia Ab',
    'Epinephrine',
    '###Equivocal',
    'D-Bilirubin',
    '$Positive',
    '$Equivocal',
    'Dopamine',
    '$Negative',
    '**Negative',
    'Basophil',
    'APT test',
    'Parasite ova',
    'HSV 1&2 PCR',
    'Cd(U)',
    'CEA',
    'Gram(-) Cocci',
    'Promyelocyte',
    'Myelocyte',
    'Influenza A,B test',
    '**Positive',
    '##Positive',
    'Peritoneal Effusion',
    'Gram(+) Bacilli',
    'PMN',
    'E. coli O157',
    'HSV-1 IgM Ab',
    'Gram(-) Bacilli',
    'Myco.pneumonia IgM.',
    'Blood Ketone',
    '***Equivocal',
    'Nucleated RBC',
    'Segmented Neutro.',
    'MIC',
    'CSF Cytology',
    '***Positive',
    'Metamyelocyte',
    'RSV Ag(FA)',
    '***Negative',
    'ANC(absolute Neu.#)',
    'Fluid Cyto/Block',
    'Blast',
    'Band',
    'C.albicans PCR',
    '((Equivocal',
    'Squamous Epi. cell',
    '##Negative',
    'Chlam.pneumoniae IgG',
    'Parasite Ova-Direct',
    '((Negative',
    'Amylase(U)',
    "Gram's stain",
    '((Positive',
    'Lymphocyte',
    'Cd',
    '##Equivocal',
    'Yeast',
    '**Equivocal',
    'LDH',
    'Monocyte',
    'Chlam.pneumoniae IgM',
    'Differential count',
    'Atypical lymphocyte',
    'Fungi',
    'Gram(+) Cocci',
    'Eosinophil',
    'albumin',
  ],
  Stool: [
    'Occult blood (Chem)',
    'Stool HB(quantity)',
    'Mucus',
    'Consistency',
    'Stool routine',
    'Occult Blood (Chem)',
    'Color',
    'Blood',
    'Stool',
    'Pus cell',
    'Parasite Ova-Conc.',
    'Stool Hb(免疫法)',
    'Cyst',
    'E.histolytica/Dispar',
    'Endolimax nana',
    'Trophozoite',
    'Entamoeba coli',
    'Blastocystis hominis',
    'Fat stain',
    'Fat stain(heat)',
    'Fat stain(unheat)',
    'Parasite Ova-Direct',
    'Transferrin',
    'iFOB & Transferrin',
    'iFOB',
    'CD antigen test',
    'Feces Rotavirus Ag',
    'Feces Adenovirus Ag',
    'Feces RotaAdenovirus',
    'C.difficil',
    'Stool H.P Ag',
    'Feces Rota virus Ag',
    'Reducing substance',
    'C. diffi toxin',
    'pH',
    'Campylobacter',
    'Stool Hb(CC Project)',
    'Vibrio cholerae',
    'TB culture',
    'Starch stain',
    'E. coli O157',
    'Norovirus Ag',
    'Phlebotomy',
    'Isolation & ID virus',
    'ae,an,',
    'Fecal Calprotectin',
    'APT test',
    'Sucrose',
    'GI-Panel PCR',
    'Cyclo. cayetanensis',
    'E.coli (EIEC)',
    'E.coli (EPEC)',
    'E.coli (STEC)stx1/2',
    'Cryptosporidium',
    'Y. enterocolitica',
    'Sapovirus',
    'Rotavirus A',
    'Vibrio',
    'Norovirus GI/GII',
    'E.histolytica',
    'Astrovirus',
    'C. difficile A/B',
    'Adenovirus F 40/41',
    'Salmonella',
    'E.coli (ETEC)lt/st',
    'Giardia lamblia',
    'Ples. shigelloides',
    'E.coli O157',
    'E.coli (EAEC)',
    'Influenza A,B test',
    'A,stain',
    'Common ae',
    'Blood culture',
    'Sputum',
    'Stool HB(SHE)',
    'TB PCR',
    'iFOBT',
    'iFOBT & Transferrin',
    'M-urine',
    'Fungus',
    '',
    'Yeast MIC',
    'AFB',
    'Surgical Pathology 4',
    'G,stain',
    'Hb',
    'Ab Screening',
    'D.pteronyssinus',
    'SO2%',
    'S.enterotoxin A',
    'Lymphocyte',
    'P',
    'Hollister-Stier Labs',
    'Bermuda grass',
    'Plasmacytoid lymph.',
    'Blomia tropicalis',
    'Goosefoot',
    'Blue mussel',
    'B-strep A',
    'Almond',
    'Sesame seed',
    'Nutmix 1',
    'RDW',
    'HER-2/NEU,FISH',
    'K+',
    'Alternaria alternata',
    'Ca++',
    'Grass mixes 1',
    'Latex B-strept Ag',
    'House dust mix',
    'Segmented Neutro.',
    'Common ragweed',
    'Pineapple',
    'Bahia Grass',
    'Abnormal lymphocyte',
    'Creatinine',
    'Guineapig epithelium',
    'Animal dander mixes',
    'Plantain',
    'Dengue NS1 Ag',
    'Leukocyte-Poor RBCs',
    'GLU',
    'PO2',
    'Helminthosporium hal',
    'Na+',
    'eGFR',
    'Buckwheat',
    'Candida albicans',
    'K',
    'HCO3-',
    'Specific IgE Panel',
    'Fish-cod',
    'D.farinae',
    'Myelocyte',
    'Cockroach,German',
    'Hct',
    'Equivocal',
    'MCH',
    'Blast',
    'Lac',
    'Platelet',
    'Coconut',
    'Wheat',
    'Abnormal monocyte',
    'Cat dander',
    'CBC/Platelet/DC',
    'Shrimp',
    'Troponin I',
    '99% URL',
    'Atypical lymphocyte',
    'Hazel nut',
    'Differential count',
    'Oat',
    'Fruit mix',
    'Brazil nut',
    'Egg white',
    'Rye Grass',
    'Maize Corn',
    'Cutoff for AMI',
    'BE-b',
    'Peach',
    'Cereal mix',
    'Mouse epithelium',
    'Promyelocyte',
    'Crab',
    'Basophil',
    'Penicillium notatum',
    'Milk',
    'ABO Typing',
    'ESR',
    'Mustard',
    'GOT(AST)',
    'Kiwi',
    'MCV',
    'Metamyelocyte',
    'Melon',
    'Band',
    'S.enterotoxin B',
    'ANC(absolute Neu.#)',
    'Tuna',
    'Aspergillus fumigatu',
    'Microorganism mixes',
    'Banana',
    'Cladosporium herbaru',
    'PCO2',
    'Soybean',
    'Rh Typing D',
    'Peanut',
    'Eosinophil',
    'RBC',
    'Promonocyte',
    'Ht',
    'Seafood mix',
    'Monocyte',
    'Dog dander',
    'Cross Matching Test',
    'Salmon',
    'Na',
    'WBC',
    'Latex',
    'Rat epithelium',
    'Nucleated RBC',
    'MCHC',
    'Food mixes 26',
    'N.gono',
  ],
  'GYN.': [
    'Pap smear',
    'High risk',
    'HPV 16',
    'Low risk',
    'HPV Genotyping',
    'HPV 16/18/45',
    'HPV 18/45',
    'HPV DNA PCR',
    'HPV 16/18',
    'HPV 18',
    'N.gono',
    'ae,an,',
    'Urine Cytology,Void',
    'Chlamydia Ag',
    '',
    'Surgical Pathology 4',
    'Breast Asp',
    'Peritoneal Effusion',
    'Common ae',
    'Chla.trachomatis PCR',
    'Sputum Cytology',
    'Chlamydia PCR',
    'Thyroid Asp Cytology',
    'Myelocyte',
    'Segmented Neutro.',
    'SMA PCR',
    'Atypical lymphocyte',
    'Promonocyte',
    'Abnormal monocyte',
    'Basophil',
    'Promyelocyte',
    'Eosinophil',
    'CSF Cytology',
    'Other Sites Asp',
    'Soft Tissue Asp',
    'Abnormal lymphocyte',
    'Plasmacytoid lymph.',
    'Surgical Pathology 3',
    'Fluid Cyto/Block',
    'GBS screening test',
    'Band',
    'Monocyte',
    'ANC(absolute Neu.#)',
    'Blast',
    'WBC',
    'Nucleated RBC',
    'Differential count',
    'Isolation & ID virus',
    'Lymphocyte',
    'LDH',
    'Metamyelocyte',
  ],
  Sputum: [
    'Sputum',
    'TB culture',
    'Sputum Cytology',
    'Gram(+) Cocci',
    'Yeast',
    'Gram(-) Cocci',
    'PMN',
    'Gram(-) Bacilli',
    'Gram(+) Bacilli',
    "Gram's stain",
    'Fungi',
    'Squamous Epi. cell',
    'A,stain',
    'AFB',
    'RSV Ag(FA)',
    'G,stain',
    'TB PCR',
    'Chlamydia Ag',
    'ae,an,',
    'Fungus',
    'M-urine',
    'Stool',
    'Blood culture',
    'Yeast MIC',
    'MIC rapid test',
    'Blood',
    'F/A.urine',
    'Influenza A,B test',
    'Urine Cytology,Void',
    'APT test',
    'Common ae',
    'Fluid Cyto/Block',
    'C.difficil',
    'Isolation & ID virus',
    'EIA Strpto gr A Ag',
    'Occult blood (Chem)',
    'Bronchial Washing',
    'N.gono',
    'Pleural Effusion',
    'KOH',
    'Bronchial Brushing',
    'Influenza A Ag',
    'Influenza B Ag',
    'Influenza A&B Ag',
    'India ink',
    'MIC',
    'Breast Asp',
    'Neck Mass Asp',
    'Other Sites Cytology',
    'B-strep A',
    'CSF Cytology',
    'Other',
    'C. diffi toxin',
    'Appearance',
    'RBC',
    'Pleural fluid',
    'Aspergillus Ag',
    'Thyroid Asp Cytology',
    'Soft Tissue Asp',
    'Elution&Ab ID',
    'Sp.gr.',
    'WBC',
    'EIA S.pneumonia Ag',
    "Rivalta's test",
    'Other Sites Asp',
    'L:N',
    'Color',
    'Campylobacter',
    'CD antigen test',
    'E. coli O157',
    'P-AFB',
  ],
  Other: [
    'ae,an,',
    'WBC',
    'Color',
    'RBC',
    'Other',
    'L:N',
    'Appearance',
    'Sp.gr.',
    "Rivalta's test",
    'Pleural fluid',
    'Occult blood (Chem)',
    'Respir.Adenovirus Ag',
    'Common ae',
    'Gram(+) Cocci',
    "Gram's stain",
    'Gram(-) Bacilli',
    'Gram(-) Cocci',
    'PMN',
    'Gram(+) Bacilli',
    'Yeast',
    'Fungi',
    'Squamous Epi. cell',
    'Examination time',
    'Collection time',
    'Motility',
    'Amount',
    'Semen analysis',
    'Count',
    'Influenza A,B test',
    'Blood',
    'APT test',
    'Normal forms',
    'Hb',
    'SO2%',
    'pH',
    'Hct',
    'Ca++',
    'HCO3-',
    'Na+',
    'PCO2',
    'Lac',
    'PO2',
    'K+',
    'BE-b',
    'GLU',
    'Atypical sperm',
    'G,stain',
    'Uric acid(Intra)',
    'Uric acid(Extra)',
    'Mucin clot test',
    'String test',
    'Synovial fluid',
    'Fungus',
    'TB culture',
    'Blood culture',
    'Chlamydia Ag',
    'CPPD(Intra)',
    'CPPD(Extra)',
    'LDH',
    'KOH',
    'Glucose',
    'Total-protein',
    'Creatinine',
    'eGFR',
    'TP/Cr',
    'Ascites',
    'Isolation & ID virus',
    'Amylase',
    'GBS screening test',
    'Lipase',
    'BUN',
    'T-Bilirubin',
    'CMV PCR',
    'EIA Strpto gr A Ag',
    'Thyroid Asp Cytology',
    'RSV Ag(FA)',
    'N.gono',
    'Triglyceride',
    'albumin',
    'Glucose PC',
    'A,stain',
    'Yeast MIC',
    '',
    'Other Sites Cytology',
    'High risk',
    'HPV 16',
    'HPV 16/18/45',
    'Sputum',
    'HPV 18/45',
    'Vancomycin(trough)',
    'HSV 1&2 PCR',
    'TB PCR',
    'VZV PCR',
    'Lactate',
    'B.c.fungus',
    'Latex Crypt Ag',
    'A/G',
    'Influenza B Ag',
    'Influenza A&B Ag',
    'Influenza A Ag',
    'Kidney Study',
    'Ht',
    'Surgical Pathology 4',
    'Desirable',
    'Borderline high',
    'T-Cholesterol',
    'High',
    'Glu',
    'Bronchial Washing',
    'Consistency',
    "Pandy's test",
    'CSF',
    'B-strep A',
    'Dialysate',
    'Mucus',
    'Stool routine',
    'Occult Blood (Chem)',
    'D-Bilirubin',
    'M-urine',
    'Pericardial Effusion',
    'Stool',
    'F/A.urine',
    'LN Asp',
    'Peritoneal Effusion',
    'India ink',
    'EBV PCR',
    'Dengue NS1 Ag',
    'Other Sites Asp',
    'Neck Mass Asp',
    'Liver Asp',
    'Parasite Ova-Conc.',
    'DAD Profile',
    'AFB',
    'Stool Hb(免疫法)',
    'Fluid Cyto/Block',
    'Leukemia/Lymphoma',
    'N.gonorrhoeae PCR',
    'Pleural Effusion',
    'Differential count',
    'CEA',
    'Latex B-strept Ag',
    'P.acnes PCR',
    'O2CT',
    'Basophil',
    'Blast',
    'CA 19-9',
    'Lymphocyte',
    'COHB',
    'C.difficil',
    'Atypical lymphocyte',
    'Segmented Neutro.',
    'THB',
    'O2CAP',
    'Parasite Ova-Direct',
    'Promyelocyte',
    'MIC rapid test',
    'Metamyelocyte',
    'Pancreas Asp',
    'Nucleated RBC',
    'O2HB',
    'METHB',
    'Eosinophil',
    'Myelocyte',
    'Monocyte',
    'Band',
    'Ca',
    'MCH',
    'Breast Asp',
    'ANC(absolute Neu.#)',
    'MCV',
    'MIC',
    'Total cholesterol',
    'MCHC',
    'Aspergillus Ag',
    'Sputum Cytology',
    'D dimer(ELISA)',
    'Platelet',
    'HPV 18',
    'Fibrinogen',
    'RDW',
    'HPV 16/18',
    'Normal control',
    'EIA S.pneumonia Ag',
    'Surgical Pathology 5',
    'Chlamydia Ab IgM.',
    'Ab ID',
    '@@@@Equivocal',
    'Prediabetes',
    'ChlamydophilaPneumo',
    '***Equivocal',
    'HumanMetapneumoviru',
    '@@@@Positive',
    'INR',
    'Micro-method',
    'K',
    'Albumin/Globulin',
    'RespSyncytial Virus',
    'Vancomycin',
    'Vancomycin Trough',
    'CSF Cytology',
    'ParainfluenzaVirus4',
    'Influenza A',
    'BE-B',
    'ParainfluenzaVirus3',
    'ANA(IFA)',
    'APTT',
    'Renal function',
    'Others',
    'Low risk',
    'Total protein',
    'Na',
    'Promonocyte',
    'IFE',
    'Protein',
    '**Negative',
    'Reducing substance',
    'BordetellaPertussis',
    'CoronavirusHKU1',
    'Toxoplasma IgG',
    'Bacteria',
    'Uric acid',
    'HER-2/NEU,FISH',
    'P',
    '**Equivocal',
    'Epithelial cell',
    'Abnormal lymphocyte',
    'Cry-amount',
    'CBC+DC',
    'Bleeding time(Duke)',
    'Influenza B',
    'ParainfluenzaVirus2',
    'Adenovirus Ag',
    'Bronchial Brushing',
    'Chlamydia Ab IgG.',
    'Eosinophil count',
    'PT',
    'Adenovirus',
    'HumanRhino/Enterovi',
    'Sediment',
    'HPV Genotyping',
    '**Positive',
    'CoronavirusOC43',
    'Valproic acid',
    'BordetellaParapert',
    'ParainfluenzaVirus1',
    'Alkaline p-tase',
    'APTT ratio',
    'Cl',
    '@@@@Negative',
    'Cast',
    'Plasmacytoid lymph.',
    'MycoplasmaPneumonia',
    'IHA for Amoeba',
    'Resp-Panel PCR',
    'FDP',
    'Chlamydia PCR',
    'Abnormal monocyte',
    'Vancomycin Peak',
    'Crystal',
    '@T-Bilirubin',
    'SMA PCR',
    'Coronavirus229E',
    'Diabetes',
    '***Negative',
    'HbA1C',
    '***Positive',
    'Globulin',
    'CoronavirusNL63',
    'CBC/Platelet/DC',
    'cast-amount',
    'Pus cell',
    'Lung Aspiratio',
    'Mediastinal Asp',
  ],
  CSF: [
    'WBC',
    'RBC',
    'Appearance',
    "Pandy's test",
    'CSF',
    'L:N',
    'Color',
    'Other',
    'Total-protein',
    'Glucose',
    'ae,an,',
    'Lactate',
    'Yeast',
    'Squamous Epi. cell',
    'PMN',
    'Gram(+) Bacilli',
    'Gram(-) Cocci',
    'Gram(+) Cocci',
    "Gram's stain",
    'Fungi',
    'Gram(-) Bacilli',
    'India ink',
    'TB culture',
    'LDH',
    'Latex Crypt Ag',
    'Fungus',
    'CSF Cytology',
    'Cryptococcus Ag',
    'A,stain',
    'Glucose PC',
    'G,stain',
    'IFE',
    'Isolation & ID virus',
    'Latex B-strept Ag',
    'Blood',
    'Common ae',
    'HSV 1&2 PCR',
    'VDRL(CSF)',
    'Enterovirus',
    'Parechovirus',
    'H.influenzae',
    'N.meningitidis',
    'Cytomegalovirus',
    'S.pneumoniae',
    'E.Coli K1',
    'S.agalactiae',
    'C.neoformans/gattii',
    'HSV2',
    'L.monocytogenes',
    'VZV',
    'HSV1',
    'ME-Panel PCR',
    'HSV6',
    'VZV PCR',
    'TB PCR',
    'Crypt Ag titer',
    'AFB',
    'STS-RPR',
    'TPPA',
    'B.c.fungus',
    'Blood culture',
    'CMV PCR',
    'albumin',
    'EIA S.pneumonia Ag',
    'A/G',
    'EBV PCR',
    'MIC rapid test',
    '((Positive',
    '((Equivocal',
    '((Negative',
    'MIC',
    'HSV-1 IgM Ab',
    '24 Hrs.Microalbumin',
    'Microalbumin',
    'Microalbumin/Crea',
    '(((Equivocal',
    '(((Positive',
    'Alkaline p-tase',
    'HSV-2 IgM Ab',
    '*Nonpreg.female',
    '(((Negative',
    '*Male',
    'Beta-HCG',
    'Fluid Cyto/Block',
    'AFP',
    'K',
    '###Equivocal',
    'Yeast MIC',
    'HSV 2-Ab',
    'M-urine',
    '$Negative',
    '###Negative',
    'HSV 1-Ab.',
    '##Positive',
    '##Negative',
    'Myco.pneumonia IgM.',
    'HSV 1+2 IgM',
    'Cl',
    'Na',
    '$Positive',
    'Benzodiazepin',
    '*preg.3-4 week',
    '*Preg.4-5week',
    '###Positive',
    '*preg.5-6 week',
    '*preg.0.2-1 week',
    '##Equivocal',
    'Other Sites Cytology',
    '*Preg.8-12week',
    '$Equivocal',
    'Peritoneal Effusion',
    'CEA',
    '*preg.1-2 week',
    'Myco.pneumonia Ab',
    '*preg.2-3 week',
    '*Postmenopause',
    '*preg.6-8 week',
  ],
  Tissue: [
    '',
    'Surgical Pathology 4',
    'Surgical Pathology 5',
    'Surgical Pathology 3',
    'Kidney Study',
    'HER-2/NEU,FISH',
    'Cytogenetics study',
    'EGFR-PCR',
    'Surgical Pathology 2',
    'ae,an,',
    'Surgical pathology I',
    'TB culture',
    'Thyroid Asp Cytology',
    'Other Sites Asp',
    'LN Asp',
    'HER-2/NEU,IHC',
    'Neck Mass Asp',
    'Fungus',
    'Common ae',
    'Surgical Pathology 6',
    'Breast Asp',
    'Abnormal monocyte',
    'Other Sites Cytology',
    'Yeast MIC',
    'Atypical lymphocyte',
    'C.difficil',
    'Bronchial Brushing',
    'Lymphocyte',
    'Frozen section',
    'Pancreas Asp',
    'KOH',
    'Basophil',
    'Metamyelocyte',
    'Blast',
    'Abnormal lymphocyte',
    'Band',
    'Segmented Neutro.',
    'Soft Tissue Asp',
    'Fluid Cyto/Block',
    'Differential count',
    'TB PCR',
    'Promonocyte',
    'A,stain',
    'Plasmacytoid lymph.',
    'Ht',
    'Eosinophil',
    'Promyelocyte',
    'Nucleated RBC',
    'Lung Aspiratio',
    'Pericardial Effusion',
    'ANC(absolute Neu.#)',
    'Myelocyte',
    'WBC',
    'Monocyte',
  ],
  'Pleural Fl': [
    'Total-protein',
    'LDH',
    'TB culture',
    'Blood',
    'Glucose',
    'Fluid Cyto/Block',
    'ae,an,',
    'WBC',
    'RBC',
    'L:N',
    'Color',
    'Appearance',
    'Sp.gr.',
    'Other',
    "Rivalta's test",
    'Pleural fluid',
    'Pleural Effusion',
    "Gram's stain",
    'Gram(+) Bacilli',
    'Fungi',
    'Gram(+) Cocci',
    'PMN',
    'Gram(-) Bacilli',
    'Yeast',
    'Squamous Epi. cell',
    'Gram(-) Cocci',
    'A,stain',
    'G,stain',
    'Glucose PC',
    'albumin',
    'A/G',
    'Common ae',
    'Triglyceride',
    'Ht',
    'Hb',
    'Desirable',
    'High',
    'Borderline high',
    'T-Cholesterol',
    'Amylase',
    'HCO3-',
    'PCO2',
    'SO2%',
    'K+',
    'PO2',
    'Hct',
    'Fungus',
    'Bronchial Washing',
    'pH',
    'BE-b',
    'Na+',
    '',
    'Surgical Pathology 5',
    'Lactate',
    'NA+',
    'PH',
    'BE-B',
    'Normal control',
    'Platelet',
    'Yeast MIC',
    'AFB',
    'T-Bilirubin',
    'Isolation & ID virus',
    'Lipase',
    'Eosinophil',
    'Nucleated RBC',
    'Basophil',
    'Atypical lymphocyte',
    'Metamyelocyte',
    'Differential count',
    'Segmented Neutro.',
    'Promyelocyte',
    'TB PCR',
    'INR',
    'PT',
    'Myelocyte',
    'Band',
    'Blast',
    'Monocyte',
    'Lymphocyte',
    'Total cholesterol',
    'MCV',
    'Bronchial Brushing',
    'Blood culture',
    'Sputum',
    'RDW',
    'ANC(absolute Neu.#)',
    'APTT',
    'MCHC',
    'APTT ratio',
    'MCH',
    'HCT',
    'CBC/Platelet/DC',
    'B.c.fungus',
    'CMV PCR',
    'K',
    'India ink',
    'Na',
    'eGFR',
    'Lac',
    'Ca++',
    'M-urine',
    'Creatinine',
    'LN Asp',
    'GLU',
    'Plasmacytoid lymph.',
    'Albumin/Globulin',
    'Surgical Pathology 4',
    'BUN',
    'GOT(AST)',
    'Protein',
    'Promonocyte',
    'Globulin',
    'Abnormal monocyte',
    'Other Sites Asp',
    'Uric acid',
    'Alkaline p-tase',
    'Neck Mass Asp',
    'Abnormal lymphocyte',
    'GPT(ALT)',
    'MAR',
    'Total protein',
    'Cl',
    'KOH',
    'Fibrinogen',
    'ANA(IFA)',
    'D-Bilirubin',
    'CA125',
    'LAC',
    'MIC rapid test',
    'Ca',
    'Liver Asp',
    'Ascites',
    'Other Sites Cytology',
    'Peritoneal Effusion',
    'CEA',
    'TP/Cr',
    'EGFR-PCR',
    'Bleeding time(Duke)',
    'CA++',
    'Influenza B Ag',
    'LE cell preparation',
    'Glu',
    'Influenza A Ag',
    'Influenza A&B Ag',
    'CBC+DC',
    'APTT.',
    'LDL-C',
    'CSF Cytology',
    'Pericardial Effusion',
    'Lung Aspiratio',
    'Blood adult anti',
  ],
  Ascite: [
    'Thyroid Asp Cytology',
    'WBC',
    'Color',
    'Appearance',
    'L:N',
    'Other',
    'RBC',
    "Rivalta's test",
    'Sp.gr.',
    'Ascites',
    'Blood',
    'ae,an,',
    'albumin',
    'Breast Asp',
    'LDH',
    'Glucose',
    'TB culture',
    'Total-protein',
    'A/G',
    'Gram(+) Bacilli',
    "Gram's stain",
    'Gram(-) Cocci',
    'Squamous Epi. cell',
    'Fungi',
    'Gram(+) Cocci',
    'Gram(-) Bacilli',
    'PMN',
    'Yeast',
    'Peritoneal Effusion',
    'G,stain',
    'LN Asp',
    'CSF Cytology',
    'Fluid Cyto/Block',
    'Amylase',
    'A,stain',
    'Lipase',
    'T-Bilirubin',
    'Glucose PC',
    'Fungus',
    'Triglyceride',
    'Common ae',
    'Creatinine',
    'TP/Cr',
    'eGFR',
    'BUN',
    'Neck Mass Asp',
    'Other Sites Asp',
    'Thin layer cyt',
    'Yeast MIC',
    'B.c.fungus',
    'Other Sites Cytology',
    'Ht',
    'Alkaline p-tase',
    'Lactate',
    'Liver Asp',
    'AFB',
    'Hb',
    'Soft Tissue Asp',
    'TB PCR',
    'D-Bilirubin',
    'Desirable',
    'Borderline high',
    'High',
    'T-Cholesterol',
    'Blood culture',
    'Synovial fluid',
    'String test',
    'K',
    'Uric acid(Extra)',
    'Total cholesterol',
    'Mucin clot test',
    'Uric acid(Intra)',
    'Salivary Gland Asp',
    'Pleural Effusion',
    'Bronchial Brushing',
    'CEA',
    'KOH',
    '#High',
    'HDL-c',
    'GOT(AST)',
    '*Preg.1st week',
    'r-GT',
    '#Very high',
    '#Near optimal',
    '*Preg.3rd month',
    '#Optimal',
    '*Male',
    'Influenza A,B test',
    '*Preg.4th week',
    '#Borderline high',
    'Packed RBCs',
    'Ab Screening',
    'Urine Cytology,Cath.',
    'Cross Matching Test',
    'T-chol/HDL-C',
    '*Preg.4-5week',
    'Pleural fluid',
    'India ink',
    'Dialysate',
    '*Preg.2nd week',
    'Latex B-strept Ag',
    'CA125',
    'ABO Typing',
    'LDL-C',
    'Na',
    'Isolation & ID virus',
    'Rh Typing D',
    '*Preg.4-6week',
    'GPT(ALT)',
    'Beta-HCG',
    '*Preg.3rd week',
    '*Preg.2nd month',
    '*Nonpreg.female',
  ],
  '24 hrs UR': [
    'Creatinine',
    'TP/Cr',
    'eGFR',
    'Total-protein',
    'Cr(24hrs U)',
    'TP(24hrs U)',
    'VMA',
    'Dopamine',
    'Catecholamine',
    'Epinephrine',
    'Uric acid',
    'Nor-epinephrine',
    'Uric Acid',
    'Uric Acid(24hrs U)',
    'Non-epinephrine',
    'Na',
    'K',
    'Ca',
    'Cu',
    'P',
    'Mg',
    'BUN',
    'Cd',
    'Pb',
    '17-KS',
    '17-KS(11-14y)',
    '17-KS(0-10y)',
    'Microalbumin/Crea',
    '24 Hrs.Microalbumin',
    'Microalbumin',
    'Microalb.(24hrsU)',
    'BUN(24hrs U)',
    'Cl',
    'Osmolality (Urine)',
    'Cortisol(24hrs U)',
    'Na(24hrs U)',
    '**AM Cortisol',
    '**UR Cortisol',
    '**PM Cortisol',
    '#Cortisol',
    '17-OHCS',
    '17-OHCS(0-1y)',
    '17-OHCS(<12y)',
    'K(24hrs U)',
    'Cd Non-smoker',
    'Cd Smoker',
    'Cu(24hrs U)',
    'Ca(24hrs U)',
    'P(24hrs U)',
    'Mg(24hrs U)',
    'Cl(24hrs U)',
    'Glucose',
    'Pb(24hrs U)',
    'M-urine',
    'Glu',
    'Cd(24hrs U)',
    'DAD Profile',
  ],
  Dialysate: [
    'Creatinine',
    'Glucose',
    'Appearance',
    'Other',
    'RBC',
    'WBC',
    'L:N',
    'Color',
    'Sp.gr.',
    "Rivalta's test",
    'Dialysate',
    'BUN',
    'Glu',
    'DAD Profile',
    'Blood',
    'G,stain',
    'B.c.fungus',
    'TP/Cr',
    'eGFR',
    'Gram(+) Bacilli',
    'Gram(+) Cocci',
    'Gram(-) Cocci',
    'Gram(-) Bacilli',
    "Gram's stain",
    'Squamous Epi. cell',
    'Fungi',
    'PMN',
    'Yeast',
    'Fungus',
    'ae,an,',
    'A,stain',
    'TB culture',
    'Ascites',
    'Pleural fluid',
    'LDH',
    'Total-protein',
    'Common ae',
    'Stool',
    'albumin',
    'CD antigen test',
    'Yeast MIC',
    'Fluid Cyto/Block',
    'String test',
    'Uric acid(Intra)',
    'GPT(ALT)',
    'Synovial fluid',
    'Alkaline p-tase',
    'Other Sites Cytology',
    'Uric acid(Extra)',
    'Na',
    'K',
    'C.difficil',
    'CPPD(Extra)',
    'TB PCR',
    'CPPD(Intra)',
    'A/G',
    'Mucin clot test',
  ],
  'PUS/WOUND': [
    'ae,an,',
    'Blood',
    'Common ae',
    'G,stain',
    'TB culture',
    'Gram(+) Bacilli',
    "Gram's stain",
    'PMN',
    'Squamous Epi. cell',
    'Gram(+) Cocci',
    'Yeast',
    'Fungi',
    'Gram(-) Bacilli',
    'Gram(-) Cocci',
    'Fungus',
    'Yeast MIC',
    'KOH',
    'N.gono',
    'Blood culture',
    'A,stain',
    'M-urine',
    'TB PCR',
    'Isolation & ID virus',
    'F/A.urine',
    'Sputum',
    'AFB',
    'Breast Asp',
    'MIC rapid test',
    'Other Sites Cytology',
    'Other Sites Asp',
    'Stool',
    'B-strep A',
    'Neck Mass Asp',
    'B.c.fungus',
    'MIC',
    'Appearance',
    'Sp.gr.',
    'Color',
    'Ascites',
    'LN Asp',
    'Influenza A,B test',
    'CD antigen test',
    'Other',
    "Rivalta's test",
    'WBC',
    'RBC',
    'Lung Aspiratio',
    'L:N',
  ],
  Semen: [
    'Motility',
    'c:non-progressive',
    'Concentration',
    'b:slow-linear',
    'Apperance',
    'Liguefaction',
    'Volume(ml)',
    'd:immotile',
    'Morphology',
    'a:rapid-linear',
    'Agglutination',
    "WBC'S(/HPF)",
    'Consistency',
    'Viscosity',
    'WBC',
    'Amount',
    'Collection time',
    'Examination time',
    'Count',
    'Semen analysis',
    'Normal forms',
    'Atypical sperm',
    'Latex Crypt Ag',
    'ae,an,',
    'Seafood mix',
    'Sputum',
    '#Postmenopause',
    'Post-menopausal',
    'Maize Corn',
    'Microorganism mixes',
    'Egg white',
    'Food mixes 26',
    '**Mid luteal',
    '#Luteal phase',
    'Plantain',
    'Crab',
    '#midcycle',
    'Pecan nut',
    'Cladosporium herbaru',
    'Mouse epithelium',
    'Cereal mix',
    'Helminthosporium hal',
    'Bahia Grass',
    'Oat',
    'Blomia tropicalis',
    'Melon',
    'Hollister-Stier Labs',
    'Shrimp',
    'Protein S,functional',
    'D.farinae',
    'Wheat',
    'Fruit mix',
    'Specific IgE Panel',
    'Estrogen',
    'JAK2V617F',
    'Pineapple',
    'Buckwheat',
    'Kiwi',
    '#Male',
    'Guineapig epithelium',
    'FSH',
    'Animal dander mixes',
    'Tuna',
    'Sesame seed',
    'Stool',
    'Aspergillus fumigatu',
    'Cashew nut',
    'D.pteronyssinus',
    'Pistachio',
    'Bermuda grass',
    'Rye Grass',
    'Cat dander',
    'Latex',
    'IHA for Amoeba',
    'Salmon',
    'Nutmix 22',
    'Milk',
    '**Peri-ovulatory',
    'Cockroach,German',
    'Rat epithelium',
    'S.enterotoxin A',
    'Peanut',
    'Goosefoot',
    'S.enterotoxin B',
    'Grass mixes 1',
    'Walnut',
    'Alternaria alternata',
    'Blue mussel',
    'Peach',
    '#prepuberty',
    'Banana',
    '**Mid follicular',
    'Penicillium notatum',
    'Dog dander',
    'Fish-cod',
    'Mustard',
    '**Male',
    'Candida albicans',
    'House dust mix',
    '#Follicular phase',
    'Soybean',
    'Common ragweed',
  ],
  Nasopharynx: [
    'Influenza A,B test',
    'Influenza A Ag',
    'Influenza B Ag',
    'Influenza A&B Ag',
    'MycoplasmaPneumonia',
    'ParainfluenzaVirus4',
    'ParainfluenzaVirus1',
    'Resp-Panel PCR',
    'ChlamydophilaPneumo',
    'Influenza B',
    'ParainfluenzaVirus3',
    'HumanMetapneumoviru',
    'CoronavirusHKU1',
    'HumanRhino/Enterovi',
    'Adenovirus',
    'ParainfluenzaVirus2',
    'CoronavirusOC43',
    'BordetellaPertussis',
    'CoronavirusNL63',
    'Coronavirus229E',
    'RespSyncytial Virus',
    'Influenza A',
    'BordetellaParapert',
    'Influenza A PCR Test',
    'Influenza A&B PCR',
    'Influenza B PCR Test',
    'Influenza A H3',
    'RSV Ag(FA)',
    'B-strep A',
    'ae,an,',
  ],
  'Synovial F': [
    'L:N',
    'Uric acid(Extra)',
    'Appearance',
    'WBC',
    'Mucin clot test',
    'Other',
    'Color',
    'String test',
    'Synovial fluid',
    'Uric acid(Intra)',
    'RBC',
    'CPPD(Intra)',
    'CPPD(Extra)',
    'Common ae',
    'ae,an,',
    'Gram(-) Cocci',
    'Gram(+) Bacilli',
    'PMN',
    'Gram(-) Bacilli',
    'Yeast',
    "Gram's stain",
    'Gram(+) Cocci',
    'Fungi',
    'Squamous Epi. cell',
    'Blood',
    'G,stain',
    'AFB',
    'TB culture',
    'Fungus',
    'A,stain',
    'Other Sites Cytology',
    '',
    'Surgical Pathology 4',
  ],
  Aspiration: [
    'Neck Mass Asp',
    'Thin layer cyt',
    'Thyroid Asp Cytology',
    'Peritoneal Effusion',
    'Breast Asp',
    'Fluid Cyto/Block',
    'LN Asp',
    'Salivary Gland Asp',
    'Other Sites Asp',
    'Soft Tissue Asp',
    'Liver Asp',
    'Other Sites Cytology',
    'ae,an,',
    'Lung Aspiratio',
    'Common ae',
    'Pancreas Asp',
    'Synovial fluid',
    'TB PCR',
    'String test',
    'Mucin clot test',
    'Uric acid(Intra)',
    'RBC',
    'WBC',
    'Occult blood (Chem)',
    'Uric acid(Extra)',
    'Color',
    'Urine Cytology,Void',
    'L:N',
    'Appearance',
    'Other',
  ],
  Calculi: [
    'Protein(Calculi)',
    'Ca Phosphate CaHPO4.',
    'Sodium Urate',
    'Ammonium Mg Phosphat',
    'Calcium Oxalate',
    'Ca Phosphate Ca3(PO4',
    'Ammonium Urate',
    'Cystine',
    'Calcium Carbonate',
    'Uric Acid',
    'Ca Phosphate CO3 For',
    'TB culture',
    'Sputum',
  ],
  'CVP Tip': ['ae,an,', 'Common ae', 'Yeast MIC', 'Fungus', 'Sputum', 'G,stain', 'Blood', 'F/A.urine', 'TB culture'],
  Throat: [
    'Influenza A,B test',
    'EIA Strpto gr A Ag',
    'B-strep A',
    'Isolation & ID virus',
    'ae,an,',
    'RSV Ag(FA)',
    'Chlamydia Ag',
    'Common ae',
    'Sputum',
    'Influenza A Ag',
    'Influenza A&B Ag',
    'Influenza B Ag',
    'Fungus',
    'Thyroid Asp Cytology',
    'TB culture',
    'TB PCR',
    'Surgical Pathology 4',
    '',
    'Influenza A',
    'APT test',
    'ChlamydophilaPneumo',
    'CMV PCR',
    'RespSyncytial Virus',
    'AFB',
    'N.gono',
    'ParainfluenzaVirus2',
    'ParainfluenzaVirus3',
    'HumanMetapneumoviru',
    'BordetellaParapert',
    'MycoplasmaPneumonia',
    'Resp-Panel PCR',
    'HumanRhino/Enterovi',
    'M-urine',
    'Coronavirus229E',
    'Adenovirus',
    'ParainfluenzaVirus1',
    'Neck Mass Asp',
    'CoronavirusHKU1',
    'CoronavirusNL63',
    'CoronavirusOC43',
    'Influenza B',
    'Occult blood (Chem)',
    'ParainfluenzaVirus4',
    'BordetellaPertussis',
    '!Negative',
    'Synovial fluid',
    'Color',
    'String test',
    'Appearance',
    '!Equivocal',
    'Uric acid(Extra)',
    'Measles Ab',
    'A,stain',
    'Blood culture',
    'Other',
    '!Positive',
    'Uric acid(Intra)',
    'Pap smear',
    'Blood',
    'RBC',
    'Mucin clot test',
    'WBC',
    'L:N',
    'EIA S.pneumonia Ag',
  ],
  'Throat swab': [
    'Respir.Adenovirus Ag',
    'Influenza A,B test',
    'EIA Strpto gr A Ag',
    'Influenza A Ag',
    'Influenza B Ag',
    'Influenza A&B Ag',
    'RSV Ag(FA)',
    'HumanMetapneumoviru',
    'Influenza B',
    'ParainfluenzaVirus2',
    'ParainfluenzaVirus1',
    'ParainfluenzaVirus4',
    'CoronavirusOC43',
    'CoronavirusNL63',
    'Adenovirus',
    'Resp-Panel PCR',
    'Coronavirus229E',
    'HumanRhino/Enterovi',
    'ParainfluenzaVirus3',
    'ChlamydophilaPneumo',
    'MycoplasmaPneumonia',
    'RespSyncytial Virus',
    'BordetellaPertussis',
    'CoronavirusHKU1',
    'Influenza A',
    'BordetellaParapert',
    'ae,an,',
    'B-strep A',
    'KOH',
    'Sputum',
  ],
  'Cervix/retum': ['GBS screening test', 'Common ae', 'CRP'],
  Bonemarrow: [
    'BM.examination',
    'Leukemia/lymphoma-2',
    'Cytogenetics study',
    '',
    'Surgical Pathology 5',
    'Flow MRD',
    'Leukemia/Lymphoma',
    'Myeloma phenotyping',
    'lymphoma phenotyping',
    'Leukemia phenotyping',
    'Peroxidase stain',
    'Breast Asp',
    'Surgical Pathology 4',
    'ANBE',
    'Esterase stain',
    'Combined esterase',
    'Blood',
    'sideroblast',
    'Iron stain',
    'partial ring sidero',
    'CAE',
    'ring sideroblast',
    'Iron store',
    'Iron grading',
    'FLT3 screen',
    'NPM screen',
    'Leukemia/lymphoma',
    'ae,an,',
    'TB culture',
    'Urine Cytology,Void',
    'Lymphocyte marker',
    'Thyroid Asp Cytology',
    'FLEAR/CD24 type III',
    'PML/RARa mutant',
    'FLEAR/CD24 type II',
    'Copy number',
    'PNH granulocyte flow',
    'FLEAR/CD16 type II',
    'Fungus',
    'PML/RARa quant.',
    'Normal control',
    'FLEAR/CD16 type III',
    'PNH phenotyping',
    'Other Sites Asp',
    'Neck Mass Asp',
    'PT',
    'APTT ratio',
    'Ch. AE',
    'diffused',
    'coarse',
    'B.c.fungus',
    'INR',
    'JAK2V617F',
    'APTT.',
    'PAS stain',
  ],
  'Genital D.': [
    'ae,an,',
    'Common ae',
    'N.gono',
    'Fungus',
    'Yeast MIC',
    'M-urine',
    'Blood',
    'F/A.urine',
    'Isolation & ID virus',
    'G,stain',
    'Chlamydia Ag',
  ],
  'Pericar Fl': [
    'TB culture',
    'ae,an,',
    'Blood',
    'Pericardial Effusion',
    'Fluid Cyto/Block',
    'G,stain',
    'Pleural Effusion',
    'Common ae',
    'Gram(+) Cocci',
    'Squamous Epi. cell',
    'PMN',
    'Gram(+) Bacilli',
    'Fungi',
    "Gram's stain",
    'Gram(-) Bacilli',
    'Gram(-) Cocci',
    'Yeast',
    "Rivalta's test",
    'RBC',
    'L:N',
    'WBC',
    'Appearance',
    'Color',
    'Other',
    'Pleural fluid',
    'Sp.gr.',
    'A,stain',
    'Fungus',
    'Breast Asp',
    'AFB',
    'Isolation & ID virus',
    'TB PCR',
    'BE-b',
    'PO2',
    'Ca++',
    'Occult blood (Chem)',
    'PCO2',
    'HCO3-',
    'pH',
    'Blood culture',
    'Na+',
    'GLU',
    'India ink',
    'SO2%',
    'Hct',
    'Peritoneal Effusion',
    'Lac',
    'K+',
    'Thyroid Asp Cytology',
    'Hb',
    'Sputum',
  ],
  'Bronchoalveolar lavage': [
    'TB culture',
    'Sputum',
    'Fungus',
    'ae,an,',
    'Aspergillus Ag',
    'Bronchial Washing',
    'Yeast MIC',
    'L:N',
    'Pleural fluid',
    'Color',
    'Other',
    "Rivalta's test",
    'Sp.gr.',
    'Appearance',
    'WBC',
    'RBC',
    'G,stain',
    'Blood',
    'CMV PCR',
    'TB PCR',
    'A,stain',
    'Common ae',
    'Creatinine',
    'P-AFB',
    '',
    'Total-protein',
    'TP/Cr',
    'Surgical Pathology 4',
    'Bronchial Brushing',
    'eGFR',
    'MIC rapid test',
  ],
  'Bronch br.': [
    'Bronchial Brushing',
    'Bronchial Washing',
    'TB culture',
    'Breast Asp',
    'Blood',
    'Sputum',
    'ae,an,',
    'Lung Aspiratio',
    'Blood culture',
    'LN Asp',
    'Fungus',
    'Aspergillus Ag',
    'A,stain',
    'Common ae',
    'Sputum Cytology',
    'Other Sites Cytology',
  ],
  'Nose-gastric tube aspirate': ['Occult blood (Chem)', 'APT test'],
  'Eye Discha.': [
    'ae,an,',
    'Common ae',
    'G,stain',
    'KOH',
    'Fungus',
    'Gram(-) Cocci',
    "Gram's stain",
    'Yeast',
    'Fungi',
    'Blood culture',
    'Gram(-) Bacilli',
    'PMN',
    'Gram(+) Bacilli',
    'Gram(+) Cocci',
    'Squamous Epi. cell',
  ],
  Bone: [
    '',
    'Surgical Pathology 4',
    'ae,an,',
    'Common ae',
    'Fungi',
    'WBC',
    'Gram(-) Bacilli',
    "Gram's stain",
    'Gram(-) Cocci',
    'Blood',
    'Kidney Study',
    'Squamous Epi. cell',
    'PMN',
    'Gram(+) Bacilli',
    'Gram(+) Cocci',
    'Yeast',
  ],
  'Vesicle Fl.': [
    'SO2%',
    'Hb',
    'PO2',
    'Ca++',
    'pH',
    'HCO3-',
    'Na+',
    'Lac',
    'GLU',
    'PCO2',
    'BE-b',
    'Hct',
    'K+',
    '',
    'Surgical Pathology 4',
    'THB',
    'METHB',
    'Surgical Pathology 3',
    'O2CT',
    'COHB',
    'O2CAP',
    'O2HB',
  ],
  Bile: [
    'Blood',
    'ae,an,',
    'G,stain',
    'Ab Screening',
    'Cross Matching Test',
    'Leukocyte-Poor RBCs',
    'Glu',
    'Yeast',
    'High',
    'Squamous Epi. cell',
    'Hb',
    'Gram(-) Cocci',
    "Gram's stain",
    '#Borderline high',
    'T-Cholesterol',
    'BUN',
    'Gram(+) Cocci',
    '#Very high',
    'Triglyceride',
    'Ferritin',
    'Gram(-) Bacilli',
    'DAD Profile',
    'LDL-C',
    'Gram(+) Bacilli',
    'Serum Iron/TIBC',
    'Alkaline p-tase',
    'Iron',
    'A/G',
    'PMN',
    'Desirable',
    'Total-protein',
    '#Near optimal',
    'Uric Acid',
    'albumin',
    'Creatinine',
    'Borderline high',
    'Al',
    'T-chol/HDL-C',
    'Iron/TIBC',
    'TIBC',
    '#High',
    'HDL-c',
    '#Optimal',
    'Fungi',
  ],
  Endocervix: ['ae,an,', 'Common ae', 'Blood culture', 'TB culture', 'Sputum'],
  Cyst: ['Surgical Pathology 4', '', 'Chlamydia Ag', 'Thyroid Asp Cytology'],
  'Middle UR': [
    'M-urine',
    'Leginella Ag',
    'EIA S.pneumonia Ag',
    'WBC',
    'Cry-amount',
    'Bacteria',
    'Sediment',
    'cast-amount',
    'Cast',
    'Epithelial cell',
    'RBC',
    'Crystal',
    'Others',
  ],
  plasma: [
    'ae,an,',
    'G,stain',
    'Gram(+) Bacilli',
    'TB culture',
    'Gram(-) Cocci',
    'Blood',
    'A,stain',
    'PMN',
    'Yeast MIC',
    'Fungi',
    'Gram(-) Bacilli',
    'Yeast',
    'Squamous Epi. cell',
    'MIC rapid test',
    'Gram(+) Cocci',
    "Gram's stain",
  ],
  'Prostate F': [
    'Ab Screening',
    'Rh Typing D',
    'Cross Matching Test',
    'ABO Typing',
    'M-urine',
    'Packed RBCs',
    'Leukocyte-Poor RBCs',
    'Sputum',
    'TB culture',
    'Washed RBCs',
    'P',
  ],
  'Gastric Fl.': ['Occult blood (Chem)', 'APT test', 'Common ae'],
  'Foley UR': ['F/A.urine', 'Yeast MIC'],
  IMAGING: [
    'Triglyceride',
    '#Very high',
    'Desirable',
    'HDL-c',
    'High',
    'LDL-C',
    '#Optimal',
    '#High',
    '#Borderline high',
    'Common ae',
    'Glucose',
    'GPT(ALT)',
    'Creatinine',
    'eGFR',
    'T-Cholesterol',
    '#Near optimal',
    'T-chol/HDL-C',
    'Borderline high',
  ],
  'Double Lumen(HD)Tip': ['ae,an,', 'Blood', 'Common ae', 'Yeast MIC'],
  Cervix: ['ae,an,'],
  'Joint Fl.': ['Common ae'],
  Platelet: ['Glucose PC', 'Pericardial Effusion', 'ae,an,'],
  Cryoprecipitate: ['Stool', 'Sputum', 'ae,an,', 'G,stain'],
  'Pap Smear': ['Blood', '', 'Kidney Study', 'M-urine'],
  'White blood cell concentrates': ['ae,an,'],
  'FC & CB': ['Feces Adenovirus Ag', 'Feces RotaAdenovirus', 'Feces Rotavirus Ag'],
  Skin: ['ae,an,'],
  'Foley Tip': ['F/A.urine', 'ae,an,'],
  'Liver cyst': ['Liver Asp'],
  Sinus: ['Blood culture'],
  'Amniotic F': ['M-urine'],
  Vagina: ['ae,an,'],
  Abscess: ['Blood'],
  'Duoden Fl.': ['ae,an,'],
};
