import { cloneDeep } from 'lodash';
import { foolProof } from '@/utils/advance/foolproof';
import objectPath from 'object-path';
import { mapState, mapMutations } from '@/store';

export default {
  computed: {
    ...mapState('eventSearch', ['currentMenu', 'eventSearch', 'nameError']),
    getEventSearch() {
      if (this.path[0] === 'session') {
        return this.$store.state.eventResult.eventSearch;
      }
      return this.eventSearch;
    },
    getSelectTimeStart() {
      return this.SearchStateObject.timePeriod.start;
    },
    getSelectTimeEnd() {
      return this.SearchStateObject.timePeriod.end;
    },
    currentEvent() {
      return this.getEventDataByPath(this.path);
    },
    SearchStateObject() {
      if (this.path[0] === 'session') {
        return objectPath.get(this.getEventSearch, this.path[2]);
      }
      return objectPath.get(this.getEventSearch, this.path[0]);
    },
    getCurrentMenu() {
      if (this.path[0] === 'session') {
        return this.$store.state.eventResult.currentMenu;
      }
      return this.currentMenu;
    },
    getNameError() {
      if (this.path[0] === 'session') {
        return this.$store.state.eventResult.nameError;
      }
      return this.nameError;
    },
  },
  methods: {
    ...mapMutations('eventSearch', ['setMenuPath', 'checkNameError']),
    ...mapMutations('eventResult', ['setMenuResultPath', 'checkNameErrorResult']),
    foolProof,
    getEventData(index) {
      const indexToEventListMapper = new Map([
        [1, cloneDeep(this.SearchStateObject.eventList[0])],
        [2, cloneDeep(this.SearchStateObject.indexEvent)],
        [3, cloneDeep(this.SearchStateObject.eventList[1])],
      ]);
      return indexToEventListMapper.get(index);
    },
    getEventDataByPath(path) {
      if (path[0] === 'session') {
        return cloneDeep(objectPath.get(this.getEventSearch, path.slice(2, path.length)));
      }
      return cloneDeep(objectPath.get(this.getEventSearch, path));
    },
    isCurrentMenu(path, menuName) {
      if (path[0] === 'session') {
        return `${path}/${menuName}` === this.$store.state.eventResult.currentMenu;
      }
      return `${path}/${menuName}` === this.currentMenu;
    },
    setCurrentMenu(path, menuName) {
      if (path[0] === 'session') {
        this.setMenuResultPath({ path, menuName });
      } else {
        this.setMenuPath({ path, menuName });
      }
    },
    deleteConstraint(value) {
      let newConstraintList = this.currentEvent.constraintList;
      newConstraintList = newConstraintList.filter((item) => item.constraintType !== value);
      const body = {
        constraintList: newConstraintList,
      };
      this.updateEventWithPath(body, this.path);

      this.cohortStudyTrackClick('deleteConstraint', { value });
    },
    checkNameErrorWithTag(name, tag) {
      if (this.path[0] === 'session') {
        this.checkNameErrorResult({ name, tag });
      } else {
        this.checkNameError({ name, tag });
      }
    },
  },
};
