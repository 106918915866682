<template>
  <div class="d-inline-flex align-items-center constraint-capsule" @click.stop="">
    <div class="constraint-text">Primary Diagnosis</div>
    <a-select
      :default-value="DIAGNOSIS_RANGE_OPTIONS[0].value"
      @change="changeDiagnosisRange"
      :getPopupContainer="(trigger) => trigger.parentNode"
      class="constraint-select"
    >
      <a-icon slot="suffixIcon" type="caret-down" />
      <a-select-option v-for="option in DIAGNOSIS_RANGE_OPTIONS" :value="option.value" :key="option.value">
        {{ option.lable }}
      </a-select-option>
    </a-select>
    <b-img
      src="@/assets/images/icon_remove_blue.svg"
      @click="deleteConstraint(ConstraintType.DiagnosisRangeConstraint)"
      class="delete-button"
    />
  </div>
</template>
<script>
import { EventType } from '@/utils/conditions/core/Interface';
import ConstraintType from '@/utils/conditions/core/advance/constraint/Interface';
import { EventSearchMixin, EventUpdateMixin, ClickMixin } from '@/mixins';
import { findIndex } from 'lodash';
import { DIAGNOSIS_RANGE_OPTIONS } from '@/utils/constants';

export default {
  name: 'DiagnosisRangeConstraint',
  data() {
    return {
      DIAGNOSIS_RANGE_OPTIONS,
      ConstraintType,
    };
  },
  mixins: [EventSearchMixin, EventUpdateMixin, ClickMixin],
  props: {
    index: {
      type: Number,
      default: 1,
    },
    data: {
      type: Object,
    },
    eventType: {
      type: String,
      default: EventType.FIX_EVENT,
    },
    path: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    changeDiagnosisRange(value) {
      const event = this.getEventDataByPath(this.path);
      const constraintIndex = findIndex(event.constraintList, {
        constraintType: ConstraintType.DiagnosisRangeConstraint,
      });
      event.constraintList[constraintIndex].diagnosisRange = value;
      const body = {
        constraintList: event.constraintList,
      };
      this.updateEventWithPath(body, this.path);

      this.cohortStudyTrackClick('changeDiagnosisRange');
    },
  },
};
</script>
<style lang="scss" scoped>
.constraint-capsule {
  padding: 5px 10px;
  background: #edf5fb;
  border-radius: 100px;
  margin-left: 10px;
}

.constraint-text {
  height: 18px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #333333;
  margin-right: 10px;
}

.constraint-select {
  width: 140px;
  height: 26px;
  margin-right: 10px;
}

.delete-button {
  cursor: pointer;
  size: 13px;
}

/deep/ .ant-select-selection--single {
  border-radius: 53px;
  border: 1px solid #e0e0e0;
  height: 26px;
}

/deep/ .ant-select-selection-selected-value {
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #334e97;
}

/deep/ .ant-select-arrow {
  color: #334e97;
}
</style>
