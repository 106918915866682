export default {
  Blood: [
    'BASO',
    'EOS',
    'NEU',
    'LYM',
    'MONO',
    'CREA',
    'Hb',
    'WBC',
    'ALT',
    'Platelet',
    'K',
    'Na',
    'Hct',
    'BUN',
    'One touch(ROOM SITE)',
    'RBC',
    'MCV',
    'WBC-DC',
    'MCHC',
    'MCH',
    'CBC-I',
    'RDW',
    'AST',
    'CRP定量',
    'TRIG',
    'CHOL',
    'GLU(AC)',
    'LDL-C',
    'P.T.',
    'HbA1C',
    'GLU',
    'CK',
    'URIC ACID',
    'TBIL',
    'HDL-C',
    'TroponinI',
    'PT INR',
    'CK-MB',
    'ALB',
    'APTT RATIO',
    'A.P.T.T.',
    'APTT',
    'PH',
    'PCO2',
    'PO2',
    'O2Sat',
    'HCO3',
    'BE-B',
    'ALP',
    'TSH',
    'Free T4',
    'STS-RPR',
    'TOTAL CALCIUM',
    'Blood Gas(Arterial)',
    'GGT',
    'INORGANIC PHOSPHORUS',
    'AFP',
    'HBs Ag',
    'ESR(1hr)',
    'LIP',
    'CEA',
    'Ab screeni',
    'ABO typing',
    'HIV combo(ECLIA)',
    'ANTI- HCV (ECLIA)',
    'Blood Gas(Vein)',
    'RH(D)型檢查',
    'FREE Ca',
    'DBIL',
    'Amylase',
    'MG',
    'Anti-HBs',
    'PSA',
    'NT-proBNP',
    'C 3',
    'C 4',
    'One touch(AC)',
    'CA-19-9',
    'RF定量',
    'LDH',
    'D-DIMER',
    'CA-125',
    'IRON & TIBC',
    'IRON',
    'TIBC',
    'UIBC',
    'Ferritin',
    'TPPA',
    'ANTI-DS-DNA IgG定量',
    'MNPT',
    'Ig A',
    'MNAPTT',
    'Anti-SSA/S',
    'Anti-SSA(RO)',
    'Anti-SSB(La)',
    'T3',
    'Lactate',
    'One touch(PC)',
    'HAV-IgM',
    'ANA',
    '肺炎黴漿菌IgM',
    'AMON',
    'Myco.pneu.Ab titer',
    'TP',
    '肺炎披衣菌IgM 定性',
    'MICRO BILIRUBIN(ROOM',
    'Widal',
    'Typhoid 0',
    'Typhoid H',
    'Paratyphi A-H',
    'Paratyphi B-H',
    'OX-2',
    'OX-K',
    'OX-19',
    'Ig E',
    'Anti-cardiolipin IgG',
    'CA 15-3',
    'HBe Ag',
    'LEGIONELLA AB',
    'Cl',
    'cardio-IgM',
    'VitB12',
    'Folate',
    'Sm/RNP Ab',
    'Anti-ENA Sm',
    'Anti-ENA RNP',
    'Ig G',
    'Rubell.IgG',
    'ATA',
    'Ig M',
    'Anti-TPO',
    'Anti-ENA RNP 68',
    'Anti-ENA RNP A',
    'Anti-ENA SmRNP A',
    'Ab-TSH recepter',
    'ECP',
    'I-PTH',
    'Eosinophil',
    'Cortisol A',
    'PCT',
    'Blood Routine',
    'ACTH(AM)',
    'GLU(PC)',
    'GLO',
    'Reticulocyte',
    '50G OGTT Gluc',
    '吸入性過敏原定性篩檢',
    'Ethanol',
    'Anti-HBc',
    'Special Al',
    'Anti-CCP',
    'FSH',
    'D-BIL/T-BIL',
    'Measles IgG',
    'COLD AGGLU',
    'Chlamydia IgM',
    'Chla. Pneumonia IgM',
    'Chla. trachomat IgM',
    'Chla. psittaci IgM',
    'T4',
    'PRL',
    'Ketone body',
    'HCV RNA 定量(限B,C型',
    'E2',
    'SCC腫瘤',
    'CD4/CD8 Ratio',
    'Lymphocyte',
    'CD3',
    'CD4',
    'CD8',
    'Beta2-MICR',
    'HLA- B27',
    'Cryptococc',
    'Testoster.',
    'ANCA',
    'cANCA',
    'pANCA',
    'Hb A',
    'Hb F',
    'Hb A2',
    '第二孕期四指標唐氏症',
    'AFP檢驗值',
    'AFP評估值',
    'uE3檢驗值',
    'uE3評估值',
    'Inhibin A檢驗值',
    'Inhibin A評估值',
    'Down Syndrome risk',
    'Trisomy 18  risk',
    'LH',
    'V- ZIgG',
    'Total β-hCG檢驗值',
    'Total β-hCG評估值',
    'OSB(NTD) risk',
    'Anti-GBM Ab',
    '等電點血色素電泳',
    'Cortisol',
    'Atypical pANCA',
    'HBV DNA 定量',
    'Beta-HCG',
    'ASLO',
    'ACTH',
    'Digoxin',
    'Aspergillus Ag',
    'Aldosteron',
    'Aluminum',
    'HIV Viral load',
    'Valproic A',
    'AMA',
    'S.M.A',
    'Beta',
    'PRA',
    'HAV-IgG',
    'Protein EP',
    'Protein',
    'Albumin',
    'Alpha 1',
    'Alpha 2',
    'Gamma',
    'Haptoglobi',
    'Transferri',
    'FXS',
    'FMR-1(CGG)type',
    'Length of CGG repeat',
    'FreePSA',
    'Special Al(40)',
    'Thyroglo',
    'HCV Genotyping Test(',
    'Osmolality',
    'CMV IgM',
    'HbH Stain',
    'Anti-HBe',
    'RALatex',
    'FreeT3',
    'ASMA',
    'Immunoelet',
    'Derm. pteronyssinus',
    'Derm. farinae',
    'Cockroach, German',
    'Molds',
    'Lupus anti',
    'Fibrinogen',
    'MTB Infection Report',
    'FDP',
    'AMH',
    'Dog dander',
    'Homocystei',
    'Cat dander',
    'AntScl-70',
    'TB Antigen',
    'Cryoglobul',
    'EBV-CA IgM',
    'IGRA',
    'FK506',
    'Special Al-Panel A',
    'Blomia tropicalls',
    'Coomb Test',
    'Cortisol(P',
    'Phenytoin',
    'G H',
    'Nil Control',
    'TB Antigen minus Nil',
    '25-OH Vitamin D Tot',
    'ACTH(PM)',
    'INDIRECT C',
    'Vancomycin',
    'Mitogen minus Nil',
    'Free light chain',
    'Free kappa',
    'Free lambda',
    'Kappa/lambda',
    'Protein-S',
    'HBV DNA 定量(限B,C型',
    'Anti-Jo-l',
    'IGF-I',
    'Ceruloplas',
    'Ig G4',
    'EBV-CA IgG',
    'AC',
    'HSV1+ 2 IgM',
    '2HR',
    '(針扎)HIV combo(ECLI',
    '(針扎)AST',
    '(針扎)ANTI- HCV(ECLI',
    '(針扎)STS-RPR',
    '(針扎)ALT',
    'Anti-ENA SmRNP',
    '(針扎)HBs Ag',
    '100G OGTT',
    '1HR',
    '3HR',
    'Carboxylhe',
    'tHb',
    'O2Hb',
    'COHb',
    'MetHb',
    'HHb',
    'CMV IgG',
    'Toxo IgG',
    'Myoglobin',
    'Ab identif',
    'Mitogen Control',
    'LA1(Screening)',
    'LA2(Confirmation)',
    'LA1/LA2  Ratio',
    'Protein-C',
    'Cyclospori',
    'Hb Ele',
    'Insulin',
    'Cu(Copper)',
    'Anti-Throm',
    '(針扎)Anti-HBs',
    'HSV 1+2 IgM',
    'G6PD定量',
    'Theophylli',
    'HSV 2 IgG',
    'HSV 1 IgG',
    'CMV DNA 定量',
    '幼兒過敏原定性篩檢',
    'RBC形態',
    'Anisocytosis',
    'Poikilocytosis',
    'Acanthocytes',
    'Teardrop cell',
    'Spherocytes',
    'Bite cell',
    'Burr cell',
    'Ovalocytes',
    'Stomatocytes',
    'Target Cell',
    'Schistocytes',
    'Sickle cell',
    'CYFRA21-1',
    'HLA-B1502 gene typin',
    'HLA Ab screening',
    'HLA Class I Ab',
    'HLA Class II Ab',
    'BT',
    'EBV-CA IgA',
    'APA',
    'FreePSA/PS',
    'Mitogen  Control',
    'Toxoplasma IgM',
    'Zinc',
    'ACHRAb',
    'Blood Gas(Central Ve',
    'Down’s',
    'HCG',
    'RISK OF Down`s',
    'Atyp ANCA',
    'Anti HBc',
    'HIV-I Ab (Western bl',
    '(針扎)TPPA',
    'HBc-IgM',
    'Lithium',
    'DHEA-S',
    'HTLV-1+2 Ab',
    'V Z-IgM',
    'progestero',
    'Nil',
    'Mitogen',
    'Chlamydia IgG',
    'Chla. Pneumonia IgG',
    'Chla. trachomat IgG',
    'Chla. psittaci IgG',
    'Acetamin',
    'ENV-GP160',
    'ENV-GP110/120',
    'ENV-GP41',
    'POL-P68/66',
    'GAG-P55',
    'POL-P52/51',
    'GAG-P40',
    'POL-P34',
    'GAG-P24/25',
    'GAG-P18',
    'Amoebic Ab',
    'Mumps virus IgG',
    'C-Peptide AC',
    'Special Al-Panel B',
    'Animal furs',
    'Pollen',
    'NSE',
    'Chol-Plasm',
    'HCV RNA定量',
    'CA 72-4',
    'Platelet-Ab',
    'LA1( Screening)',
    'LA2( Confirmation)',
    'LA1/LA2 ratio',
    'Chol-RBC',
    'HSV 1 IgM',
    'HSV 2 IgM',
    'HLA-ABC(多種抗原)',
    'HLA-A genotype',
    'HLA-B genotype',
    'HLA-C genotype',
    'Mumps virus IgM',
    'HLA Class I % PRA',
    'HLA Class II % PRA',
    'CARBAMAZEPINE',
    'Pb',
    'HLA-DQ,DR(多種抗原)',
    'HLA-DQB1 genotype',
    'HLA-DRB1 genotype',
    'Antitrypsi',
    'FTA-ABS',
    'Rube. IgM',
    'free β-hCG檢驗值',
    'free β-hCG評估值',
    'OSB(NTD)',
    'EPO',
    'HLA符合試驗',
    'Staph. enterotoxin B',
    '17-OHP',
    'Methanol',
    'EBV-EA IgG',
    'CPK isoenz',
    'Total CK',
    'CK-MM',
    'CK-BB',
    'HCV RNA定量+ genotyp',
    'HCV genotype',
    'HP',
    'EBNA IgG',
    '非侵入性胎兒染色體檢',
    '13號染色體',
    '18號染色體',
    '21號染色體',
    'Measl IgM',
    '血緣鑑定',
    'Paraquat(定量)',
    'OaK',
    'QuantiFERON(QFT)',
    'House dust category',
    'FREE B-HCG',
    'Insulin Ab',
    'Apo-E Genotyping',
    'X染色體脆折症基因檢',
    'Chlamydia IgA',
    'Chla. Pneumonia IgA',
    'Chla. trachomat IgA',
    'Chla. psittaci IgA',
    'TB antigen',
    'HIV快速免費檢驗(限未',
    'Chromosome',
    'EBV-EA IgA',
    'Thalassemia Mutation',
    'Mitogen Control Nil',
    'Phenobarbi',
    'Special Al-Panel C',
    'Child common foods',
    'FactorVIII',
    'Mango',
    'ICG(15分)',
    'Ig G3',
    'Ig G2',
    '組織配對(LCT)',
    'Special Al-Panel E',
    'Shrimp',
    'IgG1',
    'TB PCR',
    'Hg',
    'Clot. Time',
    '登革熱NS1抗原快篩(自',
    'Gastrin',
    'Lipo.EP',
    'Alpha',
    'Pre Beta',
    'Chylomicro',
    'Factor  IX',
    'Gluten',
    'Yeast',
    'Cheese, cheddar type',
    'As',
    'Calcitonin',
    'Blood Gas(ICU專用)',
    'Fruits',
    'Sea food',
    'Squid',
    'Clam',
    'Honey',
    'TPA',
    '75G OGTT',
    'Orange',
    'Crab',
    'Parasite',
    'House dust mix 2',
    'Fish,cod',
    'Pork',
    'Beef',
    'MTX',
    'C-Peptide PC',
    'Amikacin',
    'GAD Ab',
    'HTLV-1 Ab',
    'Fish(cod)',
    'HIV抗藥性基因檢測',
    'Cd',
    'E3  FREE',
    '登革熱NS1抗原快篩(符',
    'Special Al-Panel D',
    'Egg white',
    'Milk',
    'Wheat',
    'Peanut',
    'Soybean',
    'HBV變異株偵測',
    '病毒分離及',
    'BAP',
    'Maize',
    '腦膜炎之肺炎雙球菌抗',
    'Salicylate',
    '腦膜炎之B型嗜血桿菌',
    '腦膜炎之B型鏈球菌抗',
    '腦膜炎之腦膜炎雙球菌',
    '50G OGTT O',
    'Anti-ds-DNA',
    'Lipoprotein A',
    'Indium',
    'Sesame seed',
    'HHV-8 Real-Time PCR',
    'Special Al-Panel G',
    'Blue mussel',
    'Tuna',
    'Salmon',
    'Oyster',
    'HLA-B5801 gene typin',
    'Gentamicin',
    'HSV PCR',
    'Ab-TSH',
    'GLU(1小時)',
    'GLU(90分)',
    'GLU(2小時)',
    'GLU(3小時)',
  ],
  Urine: [
    'Protein',
    'OB',
    'pH',
    'Sp. Gr.',
    'WBCesteras',
    'Urobilinogen',
    'GLU',
    'RBC',
    'BIL',
    'KET',
    'Ep. cell',
    'Cast',
    'Crystal',
    'WBC/PUS',
    'Urine Routine',
    'Bacteria',
    'Other',
    'NIT',
    'Creatinine(隨機尿)',
    'Sugar',
    'Microalbum',
    'Urine T.P',
    'Pregnancy test',
    'S. pneumoniae Ag',
    'Legionella urin Ag',
    'Urine T.P(24小時)',
    'Total Volume',
    'CREA(24hr)',
    'Volume',
    'Amphetamin',
    'Benzo',
    'Urea-N(24小時Urine)',
    'Na(隨機尿)',
    'Morph(Hero',
    'Urine Volume(24小時)',
    'Osmolality',
    'Na(24hr urine)',
    'K(隨機尿)',
    'Nitrite',
    'Catecholam',
    'Nor-Epinep',
    'Epinephrin',
    'Dopamine',
    'VMA',
    'VMA Random',
    'VMA(24hr)',
    'Morphine-MA/MDMA二合',
    'MA/MDMA(定',
    'Morphine(定性)',
    'Cortisol U',
    'Cl(隨機尿)',
    'CA(隨機尿)',
    'K(24小時urine)',
    '尿液總量',
    'Paraquat定性檢查',
    'Microalbum(24hr)',
    'Cocaine',
    '天使塵',
    'Urea-N(隨機尿)',
    'CA(24h URINE)',
    'Albumin',
    'α-1',
    'α-2',
    'β',
    'γ',
    'A/G',
    'Bence Jone',
    'EP Urine p',
    'Chla.trach.Ag',
    '尿生化檢查',
    'Ketone body',
    'Sediment',
    'WBC',
    'Bact.',
    'Myoglobin',
    'Cu(Copper)',
    'TOTALVolume',
    'Paraquat(定量)',
    'Ketamine',
    '鹼性藥物類篩檢',
    'ACID FAST STAIN',
    'Chloride(24小時urine',
    'Cl(24小時urine)',
    'Legionellosis',
    'URIC ACID(24小時尿液',
    'URIC ACID',
    'TOTAL VOLUME',
    'CT PCR',
    'NG PCR',
    'IEP Urine',
    'Abn peak',
    'total volu',
    'Measles',
    'TB PCR',
    'Lead(隨機尿)',
    '17-KS',
    '病毒分離及',
    'Cr',
    '5-HIAA',
    'Ni(Nickel)',
    'Chlaym DNA',
    'Leptospirosis',
    'Beta2-MICR',
  ],
  Stool: [
    'OB',
    'Stool OB(EIA法)',
    'Color',
    'Stool Exam',
    'Form',
    'RBC',
    'WBC',
    'Parasite',
    'Amoeba',
    'Ova direct',
    '蟯蟲膠片',
    'Amoeba(direct smear)',
    'Rota virus Ag',
    '幽門桿菌糞便抗原檢查',
    'C.difficile GDH (Ag)',
    'C.difficile毒素A/B',
    'Toxin C. diff-Tox-CD',
    'Toxin C. diff-027-P',
    'ACID FAST STAIN',
    'Amebic dysentery',
    'Toxin C. diff PCR',
    '急性病毒性A型肝炎',
    '病毒分離及',
    'Shigellosis',
    'Poliomyelitis/AFP',
    '困難梭狀桿菌檢測',
  ],
  'Gastric juice': ['OB', 'pH'],
  'Nasal swab': ['Infl A Ag', 'Infl B Ag', 'Adenovirus Ag', 'RSV Ag', 'Chla.trach.Ag', 'S. aureus PCR'],
  Sputum: [
    'ACID FAST STAIN',
    'Gram stain',
    'GPC',
    'GPC in clu',
    'GPC in cha',
    'GPC diploco',
    'GNB',
    'GNCB',
    'GNC',
    'GPB',
    'Epithelial cells',
    'Yeast- lik',
    'Phagocytes',
    'Neutrophils cells',
    'Other',
    'TB PCR',
    '嚴重特殊傳染性肺炎',
    'MTB PCR',
    'Legionellosis',
    '疑似新冠狀病毒感染送',
  ],
  Ascites: [
    'Asci. anal',
    'Color',
    'Clarity',
    'RBC  count',
    'WBC count',
    'PMNs',
    'MoNs',
    'T.P',
    'ALB',
    'Gram stain',
    'ACID FAST STAIN',
    'LDH',
    'GLU',
    'ADA',
    'Amylase',
    'TRIG',
    'LIP',
    'TB PCR',
    'CREA',
    'CHOL',
    'CEA',
    'K',
    'pH',
    'Na',
  ],
  Stone: [
    'Calculi',
    'Ca.Oxalate',
    'Ca3(PO4)2',
    'CaHPO4.2H2O',
    'AmmoniumMg Phosphate',
    'Uric Acid',
    'Sodium Urate',
    'Ammonium Urate',
    'Cystine',
    'Ca.Carbonate',
    'Ca Phosphate CO3Form',
    'Protein(Calculi)',
  ],
  'Genital swab': ['HPV DNA'],
  Semen: ['Semen anal', 'PH', 'Volume', 'Sperm No.', 'Motility', 'Morph.(N)', 'RBC', 'WBC', 'Sperm Ab'],
  菌株: [
    'S. aureus PCR',
    'MRSA PCR',
    'Invasive Pneumococca',
    'Listeria monocytogen',
    'Meningococcal mening',
    '傷寒/副傷寒',
  ],
  病毒拭子: ['定醫-腸病毒感染症', '定醫-呼吸道病毒感染'],
  'Nasal. asp': ['Infl B Ag', 'Infl A Ag', 'RSV Ag', '病毒分離及'],
  Dialysate: [
    'Urea-N(Dialysate)',
    'CREA(24HRSDialysate)',
    'Total Volume(Dialysa',
    'Total Volume',
    'MicroT.P(24小時 Dial',
    'MicroT.P',
    'Volume',
    'Glucose Pet',
    'Glu:Base',
    'Glu:0hr',
    'Glu:2hr',
    'Glu:4hr',
    'Cr Pet',
    'Cr:Bsae',
    'Cr:0hr',
    'Cr:2hr',
    'Cr:4hr',
  ],
  支氣管沖洗液: ['Gram stain', 'ACID FAST STAIN', 'TB PCR', 'Indi stain', 'ALB'],
  'Pleural fluid': [
    'Ple.f anal',
    'Color',
    'Clarity',
    'RBC count',
    'WBC count',
    'PMNs',
    'MoNs',
    'T.P',
    'LDH',
    'GLU',
    'ACID FAST STAIN',
    'Gram stain',
    'Blood Gas(Pleural fl',
    'PH',
    'PCO2',
    'PO2',
    'HCO3',
    'BE-B',
    'O2Sat',
    'ADA',
    'CEA',
    'TRIG',
    'CHOL',
    'Hct',
    'ALB',
    'pH',
    'Amylase',
    'TB PCR',
    'RF定量',
    'ANTI-DS-DNA IgG定量',
    'LIP',
  ],
  Discharge: ['Chla.trach.Ag', 'CREA', 'Chlaym DNA'],
  'Throat swab': [
    'A群鏈球菌抗原',
    'Infl B Ag',
    'Infl A Ag',
    '嚴重特殊傳染性肺炎',
    '疑似新冠狀病毒感染送',
    '流感併發重症',
    'RSV Ag',
    '病毒分離及',
    'Measles',
    '腸病毒感染併發重症',
  ],
  Tissue: ['ACID FAST STAIN', 'TB PCR'],
  陰道口: ['NG PCR', 'CT PCR'],
  Serum: [
    '嚴重特殊傳染性肺炎',
    'Legionellosis',
    'Scrub Typhus Fever',
    'Leptospirosis',
    'Japanese Encephaliti',
    '急性病毒性A型肝炎',
    'Dengue fever',
    'Toxoplasmosis',
    'Chikungunya fever',
    '腸病毒感染併發重症',
    'Q Fever',
  ],
  'Synovial fluid': [
    'Syn.f anal',
    'Color',
    'Clarity',
    'RBC count',
    'WBC count',
    'PMNs',
    'MoNs',
    'Crystal',
    'GLU',
    'Gram stain',
    'ACID FAST STAIN',
    'LDH',
    'TB PCR',
  ],
  CSF: [
    'CSF analys',
    'Color',
    'Clarity',
    'WBC count',
    'PMNs',
    'MoNs',
    'RBC count',
    'Pandy test',
    'CSF TP',
    'GLU',
    'LDH',
    'Gram stain',
    'ACID FAST STAIN',
    'Indi stain',
    '腦膜炎之腦膜炎雙球菌',
    '腦膜炎之B型鏈球菌抗',
    '腦膜炎之B型嗜血桿菌',
    '腦膜炎之肺炎雙球菌抗',
    'Cryptococc',
    'Lactate',
    'VDRL(CSF)',
    '病毒分離及',
    'Ig G',
    'TB PCR',
    'CSF TP EP',
    'Protein',
    'Albumin',
    'Alpha 1',
    'Alpha 2',
    'Beta',
    'Gamma',
    'Prealbumin',
    'IEP for C',
    'HSV PCR',
    'Japanese Encephaliti',
    'S. pneumoniae Ag',
    'ADA',
    '腸病毒感染併發重症',
    'Leptospirosis',
  ],
  Pus: ['ACID FAST STAIN', 'Gram stain', '病毒分離及', 'TB PCR', 'Amebic dysentery'],
  子宮頸: ['CT PCR', 'NG PCR'],
  Abscess: ['Gram stain', 'ACID FAST STAIN', 'TB PCR'],
  'Liver abscess': ['Amoeba', 'Ova direct'],
  'Genital Tract': ['Direct Sme', 'WBC', 'Epith-Cell', 'Bacterial', 'Trich', 'Fungus', 'Other', '精蟲檢驗'],
  EDTA全血: ['Scrub Typhus Fever', 'Leptospirosis', 'Measles', 'Toxoplasmosis', 'Q Fever', 'Malaria'],
  'Urine(中段尿)': [
    'ACID FAST STAIN',
    'TB PCR',
    'Gram stain',
    '腦膜炎之B型鏈球菌抗',
    '腦膜炎之B型嗜血桿菌',
    '腦膜炎之肺炎雙球菌抗',
  ],
  'Bone marrow': ['骨髓細胞染色體檢查'],
  Bile: ['Gram stain', 'ACID FAST STAIN', 'ALB', 'LDH'],
  引流液: ['ACID FAST STAIN', 'Gram stain', 'BUN'],
  Other: ['pH', 'I-PTH', 'Gram stain', 'Ova direct'],
  'Urine(導尿)': ['ACID FAST STAIN'],
  心包膜液: [
    'ACID FAST STAIN',
    'Peric. ana',
    'Color',
    'Clarity',
    'RBC  count',
    'WBC count',
    'PMNs',
    'MoNs',
    'T.P',
    'GLU',
    'LDH',
    'Gram stain',
    'ADA',
    'Blood Gas(心包膜',
    'PH',
    'PCO2',
    'PO2',
    'HCO3',
    'BE-B',
    'O2Sat',
    'TB PCR',
    'pH',
  ],
  'Urine(膀胱鏡)': ['ACID FAST STAIN', 'TB PCR'],
  氣管刷取: ['ACID FAST STAIN'],
  'Urine(穿刺尿)': ['ACID FAST STAIN'],
  'Rectal swab': ['腸病毒感染併發重症'],
  'Vig. Discharge': ['HPV DNA'],
  'Urine(單導)': ['ACID FAST STAIN'],
  mass: ['ACID FAST STAIN'],
  'Bronchial washi': ['病毒分離及'],
  urine: [
    'Urine Routine',
    'GLU',
    'BIL',
    'KET',
    'Sp. Gr.',
    'OB',
    'pH',
    'Protein',
    'Urobilinogen',
    'NIT',
    'WBCesteras',
    'RBC',
    'WBC/PUS',
    'Ep. cell',
    'Cast',
    'Crystal',
    'Bacteria',
    'Other',
  ],
  BLOOD: ['CK'],
  'Body fluid': ['HSV PCR'],
};
