<template>
  <a-row type="flex" :gutter="gutter">
    <a-col :span="24 / columns" v-for="(cell, index) in filteredCells" :key="index">
      <div class="bg-white rounded">
        <slot name="cell" :config="cell" :index="index" />
      </div>
    </a-col>
  </a-row>
</template>
<script>
import { mapState } from '../../store';

export default {
  props: {
    columns: {
      type: Number,
      default: 3,
    },
    gutter: {
      type: Array,
      default: () => [25, 25],
    },
    cells: {
      type: Array,
      default: () => [],
    },
    uniqueIndex: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapState('session', ['tabs']),
    bucketByPatient() {
      return this.tabs[this.uniqueIndex].data.bucketByPatient;
    },
    filteredCells() {
      return this.cells.filter((c) => (c.disallowBucketChange ? this.bucketByPatient === c.bucketByPatient : true));
    },
  },
};
</script>
