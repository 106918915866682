import Vue from 'vue';
import * as query from '@/utils/queryUtils';

const Query = {
  install(vue) {
    vue.mixin({
      methods: {
        ...query,
      },
    });
  },
};

Vue.use(Query);
