import numeral from 'numeral';
import BasicChartConfig from '@/components/charts/core/BasicChartConfig';
import {
  CategoryCondition,
  CategoryConditionData,
  ConditionName,
  LogicOp,
  SearchType,
} from '../../utils/conditions/core';
import { PRECISION_THRESHOLD } from '../../utils/constants';
import { BasicChartQueryParams, ChartItem, ChartKey, RawItem } from './core/Interface';
import { UNSELECTED_COLOR } from './utils/utils';

const GENDER_TYPES = {
  F: 'Female',
  M: 'Male',
  U: 'Unknown',
};

const OPTION = {
  series: {
    type: 'pie',
    center: ['50%', '70%'],
    height: '80%',
  },
  legend: {
    left: 0,
    top: 0,
    padding: 0,
    icon: 'circle',
    data: Object.values(GENDER_TYPES),
    selectedMode: false,
  },
  tooltip: {
    trigger: 'item',
  },
};

interface DiagnosisRawItem extends RawItem {
  patient_count: {
    value: number;
  };
}

export default class GenderConfig extends BasicChartConfig<DiagnosisRawItem> {
  constructor() {
    super(ChartKey.Gender);

    this.merge(OPTION);
    this.bucketByPatient = true;
  }

  protected processQueryParams(): BasicChartQueryParams {
    const condList = this.params.includes.without(ConditionName.Gender);
    return {
      ...this.params,
      includes: condList,
    };
  }

  protected createChartItem(rawItem: DiagnosisRawItem): ChartItem {
    const {
      key,
      patient_count: { value },
    } = rawItem;

    const condList = this.params.includes.with(ConditionName.Gender);

    let color: string | null = null;
    if (condList.length !== 0) {
      const condition = condList[0] as CategoryCondition;
      color = UNSELECTED_COLOR;
      if (condition.hitKey(key)) {
        color = null;
      }
    }

    return {
      key,
      value,
      code: key,
      name: GENDER_TYPES[key as 'F' | 'M' | 'U'],
      color,
      percentage: Math.round((value / this.patientCount) * 100.0),
    };
  }

  public createConditionFromItem(chartItem: ChartItem): CategoryConditionData {
    const { name } = chartItem;
    return {
      name: ConditionName.Gender,
      tags: [SearchType.Basic, SearchType.Additional],
      andOr: LogicOp.Or,
      list: [name[0]],
    };
  }

  protected get aggsQuery(): Record<string, unknown> {
    return {
      terms: {
        field: 'SEX',
      },
      aggs: {
        patient_count: {
          cardinality: {
            field: 'CHART_NO',
            precision_threshold: PRECISION_THRESHOLD,
          },
        },
      },
    };
  }

  protected percentageFormatter(seriesList: any): string {
    let currentSeries: any = null;
    if (Array.isArray(seriesList)) {
      currentSeries = seriesList[0];
    } else {
      currentSeries = seriesList;
    }
    const item = currentSeries.data as ChartItem;
    const formattedNumber = numeral(item.value).format('0,0');
    return `${item.name}: ${formattedNumber} patients (${item.percentage}%)`;
  }
}
