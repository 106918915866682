<template>
  <div v-click-outside="closeCopyLinkMenu">
    <div
      class="button-share-query d-flex align-items-center justify-content-center"
      @click="openCopyLinkMenu"
      :class="copyLinkMenu ? 'enable-button' : ''"
    >
      <b-img src="@/assets/images/icon_share.svg" class="share-icon" />
      <span class="share-query-text"> Share </span>
    </div>
    <div class="share-link-menu" v-if="copyLinkMenu">
      <b-row no-gutters>
        <b-col>
          <b-row no-gutters>
            <b-col class="title-text">Share Link</b-col>
            <b-col>
              <b-img src="@/assets/images/icon_close.svg" class="icon-close" @click="closeCopyLinkMenu(0)" />
            </b-col>
          </b-row>
          <b-row no-gutters>
            <b-col class="name-text">Name this search result</b-col>
          </b-row>
          <b-row no-gutters>
            <b-col>
              <b-input
                placeholder="Search result name"
                v-model="nameText"
                class="name-input"
                ref="shareQueryButtonInput"
              ></b-input>
            </b-col>
          </b-row>
          <b-row no-gutters>
            <b-col>
              <b-button variant="outline-primary" class="copy-link-button" @click="copyToClip()">COPY LINK</b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { ClickMixin } from '@/mixins';
import { getRedirectLink, getSharedUrl } from '@/utils/util';
import ClickOutside from 'vue-click-outside';
import { mapState, mapMutations } from '../../../store';
import { VUE_EVENT_NAME } from '@/utils/constants';

const LINK_COPIED_WAIT = 3000; // 3 seconds

export default {
  name: 'ShareQuery',
  mixins: [ClickMixin],
  data() {
    return {
      nameText: 'Lumos',
      copyLinkMenu: false,
      copiedTextTimeout: null,
      errorMenuTimeout: null,
    };
  },
  directives: {
    ClickOutside,
  },
  props: {
    uniqueIndex: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    this.$root.$on(VUE_EVENT_NAME.BV_DROPDOWN_SHOW, () => {
      this.copyLinkMenu = false;
    });
  },
  destroyed() {
    this.$root.$off(VUE_EVENT_NAME.BV_DROPDOWN_SHOW);
  },
  computed: {
    // ...mapGetters('condition', ['getIndexTab']),
    ...mapState('session', ['tabs']),
    tab() {
      return this.tabs[this.uniqueIndex];
    },
  },
  methods: {
    ...mapMutations('session', ['updateTab']),
    listener(e) {
      e.clipboardData.setData('text/html', this.content);
      e.clipboardData.setData('text/plain', this.copyLink);
      e.preventDefault();
    },
    async copyToClip() {
      try {
        this.copyLinkMenu = false;
        const result = await this.$api.saveQuery(this.tab.name, this.tab, 'share').catch((error) => {
          console.error(error);
        });

        const text = this.nameText === '' ? 'unnamed' : this.nameText;
        const sharedUrl = getSharedUrl(result.url);
        this.copyLink = getRedirectLink(sharedUrl);
        this.content = `<div>邀請您使用 AICS Lumos <a href="${this.copyLink}">${text}</a></div>`;

        document.addEventListener('copy', this.listener);
        document.execCommand('copy');
        document.removeEventListener('copy', this.listener);

        this.trackClick('copyToClip', { url: this.copyLink, text });
        this.nameText = this.tab.name;

        this.showCopiedText();
      } catch (e) {
        clearTimeout(this.errorMenuTimeout);
        this.$root.$emit(VUE_EVENT_NAME.OPEN_ERROR_MENU, 'Share query failed!', `${e}`);
        this.errorMenuTimeout = setTimeout(() => {
          this.$root.$emit(VUE_EVENT_NAME.CLOSE_ERROR_MENU);
        }, LINK_COPIED_WAIT);
        throw Error(`Copy query failed! ${e}`);
      }
    },
    showCopiedText() {
      clearTimeout(this.copiedTextTimeout);
      this.$root.$emit(VUE_EVENT_NAME.OPEN_LINK_COPIED_TEXT);
      this.copiedTextTimeout = setTimeout(() => {
        this.$root.$emit(VUE_EVENT_NAME.CLOSE_LINK_COPIED_TEXT);
      }, LINK_COPIED_WAIT);
    },
    openCopyLinkMenu() {
      this.copyLinkMenu = true;
      this.nameText = this.tab.name;
      this.trackClick('openCopyLinkMenu');
      this.$nextTick(() => {
        try {
          this.$refs.shareQueryButtonInput.select();
        } catch (error) {
          console.error(error);
        }
      });
    },
    closeCopyLinkMenu(index) {
      this.copyLinkMenu = false;
      if (index === 0) {
        this.trackClick('closeCopyLinkMenu');
      }
    },
  },
};
</script>

<style scope>
.button-share-query {
  border-radius: 6px;
  border: solid 1px #333f6b;
  background-color: white;
  margin-right: 10px;
  z-index: 10;
  padding: 0px 16px;
  cursor: pointer;
  height: 38px;
}

.share-query-text {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333f6b;
}

.share-query-url {
  color: #0000ff;
  word-wrap: break-word;
}

.share-link-menu {
  position: absolute;
  width: 420px;
  height: 200px;
  border-radius: 6px;
  box-shadow: 0 4px 10px 0 rgba(51, 63, 107, 0.2);
  border: solid 1px #d6d9e1;
  background-color: #ffffff;
  padding: 20px 25px 20px 25px;
  z-index: 10;
  margin: 8px 0px 0px -312px;
}

.title-text {
  height: 23px;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #333f6b;
}

.icon-close {
  float: right;
  cursor: pointer;
}

.name-text {
  height: 20px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
  margin-top: 10px;
}

.name-input {
  height: 40px;
  border-radius: 3px;
  border: solid 1px #333f6b;
  margin-top: 10px;
}

.copy-link-button {
  width: 370px;
  height: 44px;
  border-radius: 6px !important;
  border-color: #333f6b !important;
  background-color: #333f6b !important;
  font-size: 16px !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff !important;
  margin-top: 10px;
}

.enable-button {
  background-color: #eaf2fd;
  cursor: pointer;
}

.link-copied-text {
  position: absolute;
  width: 114px;
  height: 34px;
  border-radius: 6px;
  background-color: #758cc8;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  padding: 5px 15px 5px 15px;
  z-index: 11;
  margin: -5px 0px 0px 130px;
}

.share-icon {
  margin: 2px 10px 0px 0px;
}
</style>
