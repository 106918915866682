import { ChartConfig } from '@/components/charts/core';
import { AdvChartQueryParams, RawItem } from '@/components/charts/core/Interface';
import AdvAgeConfig from '@/components/charts/advance/AdvAgeConfig';
import AdvGenderConfig from '@/components/charts/advance/AdvGenderConfig';
import { foolProof } from '@/utils/advance/foolproof';
import ApiHelper from './ApiHelper';

export function preloadChart(
  config: ChartConfig<RawItem>,
  params: AdvChartQueryParams,
  api: ApiHelper,
  userId: string,
  trackContent: any
) {
  config.applyData(params, api, userId, trackContent, 0);
}

export default function preloadAdvCharts(
  params: AdvChartQueryParams,
  api: ApiHelper,
  userId: string,
  trackContent: any
) {
  const ageConfig = new AdvAgeConfig();
  const genderConfig = new AdvGenderConfig();
  [ageConfig, genderConfig].forEach((config) => {
    preloadChart(config, foolProof(params), api, userId, trackContent);
  });
}
