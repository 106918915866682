import { mapState } from '../store';

function getStyle(text, exam) {
  if (!exam.isNumber) {
    return { color: 'white' };
  }
  if (parseFloat(text) > parseFloat(exam.CHNH)) {
    return { color: '#da6d6d', 'font-weight': 'bold' };
  }
  if (parseFloat(text) < parseFloat(exam.CHNL)) {
    return { color: '#da6d6d', 'font-weight': 'bold' };
  }
  return { color: '#333333' };
}

export default {
  computed: {
    ...mapState('condition', ['searchByOutpatient']),
  },
  methods: {
    renderAbnormal(lab, text, key) {
      if (text !== undefined) {
        const { name } = key;
        const style = getStyle(text, lab);
        if (lab.isNumber) {
          return (
            <a-tooltip key={name} placement="topLeft">
              <span
                // eslint-disable-next-line object-shorthand
                title={lab.CHNL !== '' || lab.CHNH !== '' ? `Range: ${lab.CHNL}~${lab.CHNH}` : null}
                style={{ ...style, paddingLeft: '5px', paddingRight: '5px' }}
                class="tag-font"
              >
                {text}
              </span>
            </a-tooltip>
          );
        }
      }
      return text;
    },
  },
};
