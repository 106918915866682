import { RangeData } from '../Interface';

class TimePeriod implements RangeData {
  readonly start: number;

  readonly end: number;

  constructor(timePeriod: any) {
    this.start = timePeriod.start;
    this.end = timePeriod.end;
  }
}

export default TimePeriod;
