<template>
  <div class="advance-search d-flex justify-content-center">
    <b-row no-gutters class="w-100 advance-search-max-width">
      <b-col>
        <b-row no-gutters class="padding-20" :style="hasShaddow" style="position: relative; z-index: 10">
          <b-col>
            <b-row no-gutters>
              <b-col class="d-flex align-items-center">
                <div class="d-flex justify-content-start align-items-center">
                  <span class="cohort-text">Cohort:</span>
                  <span class="content-text">about</span>
                  <span class="count-text">{{ formatedTemplateNumber(patientCount) }}</span>
                  <a-spin class="spin-loading" v-if="loadingCount !== 0" />
                  <span class="content-text">patients</span>
                </div>
              </b-col>
              <b-col class="d-flex justify-content-end align-items-end">
                <div style="width: 300px">
                  <div class="d-flex justify-content-start align-items-center">
                    <div class="time-period-text">Time period</div>
                    <div class="time-period-value-text">{{ getSelectTimeStart }}~{{ getSelectTimeEnd }}</div>
                  </div>
                  <AdvanceTimeFilter :range="getTimeRangeInYear(getUiConfig().SearchForm.time)" />
                </div>
                <div>
                  <button class="submit-btn" :class="!hasPatient ? 'disable' : ''" @click.stop="handleSubmit">
                    SEARCH
                  </button>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <VuePerfectScrollbar :style="getMaxHeight" ref="scrollbar" id="scroll-div" @ps-scroll-y="scrollHandle">
          <b-row no-gutters class="padding-20 search-padding-bottom d-flex justify-content-center">
            <b-col class="content-max-width">
              <b-row no-gutters class="desc-container row-margin">
                <b-col>
                  <PortalSearchDescription />
                </b-col>
                <b-col class="d-flex justify-content-end">
                  <TimeDescription />
                </b-col>
              </b-row>
              <b-row no-gutters class="row-margin">
                <b-col class="left-desc d-flex justify-content-center align-items-center">
                  <div v-click-outside="closeEditEventName">
                    <div v-if="isEditEventName === 1" @click.stop="">
                      <input
                        maxlength="15"
                        v-model="SearchStateObject.eventList[0].name"
                        v-on:change="detectChanges(1)"
                        ref="eventNameEditor"
                      />
                    </div>
                    <div v-else class="d-flex justify-content-center align-items-center">
                      <div
                        class="event-name-text"
                        @click.stop="clickEditEventName(1, SearchStateObject.eventList[0].name)"
                      >
                        {{ SearchStateObject.eventList[0].name }}
                      </div>
                      <b-img
                        @click.stop="clickEditEventName(1, SearchStateObject.eventList[0].name)"
                        src="@/assets/images/icon_edit.svg"
                        class="icon-edit"
                      />
                    </div>
                  </div>
                </b-col>
                <b-col>
                  <EventContainer
                    id="event-block-1"
                    :index="1"
                    :eventType="EventType.FIX_EVENT"
                    :event="SearchStateObject.eventList[0]"
                    :path="['eventList', '0']"
                  />
                </b-col>
              </b-row>
              <b-row no-gutters class="row-margin-10">
                <b-col class="left-desc d-flex justify-content-center align-items-center">
                  <b-img src="@/assets/images/icon_arrow.svg" />
                </b-col>
                <b-col>
                  <TimeRelationFilter :index="1" :eventType="EventType.FIX_EVENT" :path="['eventList', '0']" />
                </b-col>
              </b-row>
              <b-row no-gutters class="row-margin-10">
                <b-col class="left-desc d-flex justify-content-center align-items-center">
                  <div>
                    <div v-click-outside="closeEditEventName">
                      <div v-if="isEditEventName === 2" @click.stop="">
                        <input
                          maxlength="15"
                          v-model="SearchStateObject.indexEvent.name"
                          v-on:change="detectChanges(2)"
                          ref="eventNameEditor"
                        />
                      </div>
                      <div v-else class="d-flex justify-content-center align-items-center">
                        <div
                          class="event-name-text"
                          @click.stop="clickEditEventName(2, SearchStateObject.indexEvent.name)"
                        >
                          {{ SearchStateObject.indexEvent.name }}
                        </div>
                        <b-img
                          src="@/assets/images/icon_edit.svg"
                          @click.stop="clickEditEventName(2, SearchStateObject.indexEvent.name)"
                          class="icon-edit"
                        />
                      </div>
                    </div>
                  </div>
                </b-col>
                <b-col>
                  <EventContainer
                    id="event-block-2"
                    :index="2"
                    :eventType="EventType.FIX_EVENT"
                    :event="SearchStateObject.indexEvent"
                    :path="['indexEvent']"
                  />
                </b-col>
              </b-row>
              <b-row no-gutters class="row-margin-10">
                <b-col class="left-desc d-flex justify-content-center align-items-center">
                  <b-img src="@/assets/images/icon_arrow.svg" />
                </b-col>
                <b-col>
                  <TimeRelationFilter :eventType="EventType.FIX_EVENT" :index="3" :path="['eventList', '1']" />
                </b-col>
              </b-row>
              <b-row no-gutters class="row-margin-10">
                <b-col class="left-desc d-flex justify-content-center align-items-center">
                  <div v-click-outside="closeEditEventName">
                    <div v-if="isEditEventName === 3" @click.stop="">
                      <input
                        maxlength="15"
                        v-model="SearchStateObject.eventList[1].name"
                        v-on:change="detectChanges(3)"
                        ref="eventNameEditor"
                      />
                    </div>
                    <div v-else class="d-flex justify-content-center align-items-center">
                      <div
                        class="event-name-text"
                        @click.stop="clickEditEventName(3, SearchStateObject.eventList[1].name)"
                      >
                        {{ SearchStateObject.eventList[1].name }}
                      </div>
                      <b-img
                        src="@/assets/images/icon_edit.svg"
                        @click.stop="clickEditEventName(3, SearchStateObject.eventList[1].name)"
                        class="icon-edit"
                      />
                    </div>
                  </div>
                </b-col>
                <b-col ref="thirdEvent">
                  <EventContainer
                    id="event-block-3"
                    :index="3"
                    :eventType="EventType.FIX_EVENT"
                    :event="SearchStateObject.eventList[1]"
                    :path="['eventList', '1']"
                  />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </VuePerfectScrollbar>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import AdvanceTimeFilter from '@/components/advanceSearch/filter/AdvanceTimeFilter.vue';
import PortalSearchDescription from '@/components/advanceSearch/status/PortalSearchDescription.vue';
import TimeDescription from '@/components/advanceSearch/status/TimeDescription.vue';
import EventContainer from '@/components/advanceSearch/EventContainer.vue';
import TimeRelationFilter from '@/components/advanceSearch/filter/TimeRelationFilter.vue';
import ClickOutside from 'vue-click-outside';
import { v4 as uuid } from 'uuid';
import dayjs from 'dayjs';
import { AdvanceSearchMixin, PatientNumberMixin, TrackTimeMixin, ClickMixin } from '@/mixins';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { EVENT, VUE_EVENT_NAME } from '@/utils/constants';
import preloadAdvCharts from '@/utils/preloadAdvCharts';
import { getTimeRangeInYear } from '@/utils/conditions/TimeDef';
import { getUiConfig } from '@/utils/uiConfig';
import { DateUnit, EventType } from '@/utils/conditions/core/Interface';
import { mapMutations, mapState, mapActions } from '../store';

export default {
  name: 'AdvanceSearch',
  components: {
    AdvanceTimeFilter,
    PortalSearchDescription,
    TimeDescription,
    EventContainer,
    TimeRelationFilter,
    VuePerfectScrollbar,
  },
  directives: {
    ClickOutside,
  },
  mixins: [AdvanceSearchMixin, PatientNumberMixin, TrackTimeMixin, ClickMixin],
  computed: {
    ...mapState('advancePortal', ['SearchStateObject', 'patientCount', 'loadingCount']),
    currentSearchState() {
      return JSON.stringify(this.SearchStateObject);
    },
    getMaxHeight() {
      const maxHeight = this.innerHeight - 202;
      return {
        'max-height': `${maxHeight}px`,
      };
    },
    hasShaddow() {
      if (!this.atTop) {
        return {
          'box-shadow': 'rgb(117 117 117 / 50%) 0px 4px 10px',
        };
      }
      return {};
    },
    firstConditions() {
      return JSON.stringify(this.getEventDataByPath(['eventList', '0']).condition);
    },
    indexConditions() {
      return JSON.stringify(this.getEventDataByPath(['indexEvent']).condition);
    },
    thirdConditions() {
      return JSON.stringify(this.getEventDataByPath(['eventList', '1']).condition);
    },
    hasPatient() {
      return this.patientCount > 0;
    },
  },
  watch: {
    currentSearchState() {
      preloadAdvCharts(
        {
          SearchStateObject: this.SearchStateObject,
          TimeSelectObject: {
            beforeValue: '0',
            beforeUnit: DateUnit.DAYS,
            afterValue: '6',
            afterUnit: DateUnit.MONTHS,
          },
        },
        this.$api,
        this.userId,
        this.trackContentTemplate()
      );
    },
    firstConditions() {
      this.updateEventName(1);
    },
    indexConditions() {
      this.updateEventName(2);
    },
    thirdConditions() {
      this.updateEventName(3);
    },
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
    this.$root.$off(VUE_EVENT_NAME.SET_SEARCH_PADDING_BOTTOM);
    this.$root.$off(VUE_EVENT_NAME.CLOSE_SEARCH_PADDING_BOTTOM);
    this.$root.$off(VUE_EVENT_NAME.SET_SCROLL_POSITION);
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);

    this.advanceStartTime('AdvanceSearch');

    window.addEventListener('beforeunload', () => {
      this.advanceEndTime('AdvanceSearch');
    });

    this.updateFirstEvent({ api: this.$api });

    this.$root.$on(VUE_EVENT_NAME.SET_SEARCH_PADDING_BOTTOM, (index) => {
      this.searchPaddingBottom = true;

      this.setScrollOffest(index);
    });

    this.$root.$on(VUE_EVENT_NAME.CLOSE_SEARCH_PADDING_BOTTOM, () => {
      this.searchPaddingBottom = false;
    });

    this.$root.$on(VUE_EVENT_NAME.SET_SCROLL_POSITION, (index) => {
      this.setScrollOffest(index);
    });
  },
  data() {
    return {
      isEditEventName: 0,
      searchPaddingBottom: false,
      atTop: true,
      editedEventIndex: new Set(),
      EventType,
      innerHeight: window.innerHeight,
    };
  },
  methods: {
    getTimeRangeInYear,
    getUiConfig,
    ...mapMutations('byEventSession', ['addTab']),
    ...mapActions('advancePortal', ['updateFirstEvent']),
    genTabContent() {
      return {
        id: '',
        name: 'Result',
        updateTime: dayjs().format('YYYY/MM/DD HH:mm:ss'),
        ...this.foolProof(this.$store.state.advancePortal),

        data: {
          root: { trackId: this.$api.advanceTrackId },
          trackId: uuid(),
        },
        state: 'summary',
        includes: [],
        excludes: [],
      };
    },
    handleSubmit() {
      this.advanceTrackClick('handleSubmit', {
        tab: {
          ...this.foolProof(this.$store.state.advancePortal),
        },
      });

      this.addTab(this.genTabContent());
      this.$api.setAdvanceTrackId(uuid());
      this.$root.$emit(VUE_EVENT_NAME.GET_INTERVAL_FEEDBACK);
    },
    detectChanges(index) {
      this.editedEventIndex.add(index);
    },
    clickEditEventName(index, name) {
      this.isEditEventName = index;
      this.$nextTick(() => {
        this.$refs.eventNameEditor.select();
      });
      this.advanceTrackClick('clickEditEventName', {
        index,
        name,
      });
    },
    closeEditEventName() {
      if (this.isEditEventName !== 0) {
        const indexToEventNameListMapper = new Map([
          [1, this.SearchStateObject.eventList[0].name],
          [2, this.SearchStateObject.indexEvent.name],
          [3, this.SearchStateObject.eventList[1].name],
        ]);

        this.advanceTrackClick('closeEditEventName', {
          index: this.isEditEventName,
          name: indexToEventNameListMapper.get(this.isEditEventName),
        });

        this.updateEventName(this.isEditEventName);
      }
      this.isEditEventName = 0;
    },
    screenMoveTo(position) {
      this.$nextTick(() => {
        this.timer = window.setInterval(() => {
          if (this.$refs.scrollbar.$el.scrollTop > position) {
            clearInterval(this.timer);
          } else {
            this.$refs.scrollbar.$el.scrollTop += 3;
          }
        }, 5);
      });
    },
    setScrollOffest(index) {
      switch (index) {
        case 1:
          this.screenMoveTo(150);
          break;
        case 2:
          this.screenMoveTo(370);
          break;
        case 3:
          this.screenMoveTo(this.$refs.scrollbar.ps.contentHeight - 870);
          break;
        default:
          break;
      }
    },
    scrollHandle() {
      if (this.$refs.scrollbar.$el.scrollTop === 0) {
        this.atTop = true;
      } else {
        this.atTop = false;
      }
    },
    generateConceptName(conditionList) {
      if (conditionList.length > 2) {
        return conditionList.slice(0, 2).join(', ').concat('...');
      }
      return conditionList.join(', ');
    },
    updateEventName(index) {
      const indexToEventListMapper = new Map([
        [1, this.SearchStateObject.eventList[0]],
        [2, this.SearchStateObject.indexEvent],
        [3, this.SearchStateObject.eventList[1]],
      ]);
      const defaultNameMapper = new Map([
        [1, EVENT.DEFAULT_NAME.FIRST_EVENT],
        [2, EVENT.DEFAULT_NAME.SECOND_EVENT],
        [3, EVENT.DEFAULT_NAME.THIRD_EVENT],
      ]);
      const targetEvent = indexToEventListMapper.get(index);
      if (!this.editedEventIndex.has(index)) {
        if (targetEvent.condition.length > 0 && targetEvent.condition[0].list.length !== 0) {
          targetEvent.name = this.generateConceptName(targetEvent.condition[0].list.map((item) => item.code));
        } else {
          targetEvent.name = defaultNameMapper.get(index);
        }
      } else if (targetEvent.name.trim().length === 0) {
        if (targetEvent.condition.length > 0) {
          targetEvent.name = this.generateConceptName(targetEvent.condition[0].list.map((item) => item.code));
        } else {
          targetEvent.name = defaultNameMapper.get(index);
        }
        this.editedEventIndex.delete(index);
      }
    },
    handleResize() {
      this.innerHeight = window.innerHeight;
    },
  },
};
</script>
<style lang="scss" scoped>
.padding-20 {
  padding: 20px 20px 20px 20px;
}

.left-desc {
  max-width: 200px;
  width: 200px;
  min-width: 200px;
}

.row-margin {
  margin-top: 20px;
}

.desc-container {
  border: 1px solid #bdbdbd;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 20px;
}

.advance-search {
  background-color: #fff;
}

.cohort-text {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: #333f6b;
}

.content-text {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  color: #333333;
  margin-left: 10px;
}

.count-text {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 45px;
  color: #333f6b;
  margin-left: 10px;
}

.submit-btn {
  width: 386px;
  height: 44px;
  border-radius: 5px;
  background-color: #333f6b;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  border-width: 0 0 0 0;
  margin-left: 50px;
}

.time-period-text {
  height: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #333f6b;
}
.time-period-value-text {
  height: 18px;
  left: 98px;
  top: 1px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #333333;
  margin-left: 10px;
}

.event-name-text {
  word-break: break-word;
  max-width: 150px;
  // height: 23px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #333f6b;
  cursor: pointer;
}

.icon-edit {
  cursor: pointer;
  margin-left: 5px;
}

.index-event-text {
  height: 18px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #828282;
  text-align: center;
  margin-top: 5px;
}

.spin-loading {
  width: 18px;
  height: 18px;
  margin-left: 10px;
}

.search-padding-bottom {
  padding-bottom: 600px;
}

.advance-search-max-width {
  max-width: 1920px;
}

.content-max-width {
  max-width: 1200px;
}
.row-margin-10 {
  margin-top: 10px;
}
#in-exclusion-container {
  margin-left: 200px;
}

.disable {
  opacity: 0.5;
  pointer-events: none;
}
</style>
