import { ConditionData } from '@/utils/conditions/core';
import { DescriptionType } from '@/utils/descriptionCache';
import { ChartKey } from '../core';
import AdvCategoryChartConfig from './AdvCategoryChartConfig';

export default class AdvICD10Config extends AdvCategoryChartConfig {
  protected descriptionType = DescriptionType.ICD10;

  protected fieldName = 'ICD10';

  protected canAISort = true;

  constructor() {
    super(ChartKey.AdvICD10);
  }

  protected createConditionFromItem(): ConditionData {
    throw new Error('Method not implemented.');
  }
}
