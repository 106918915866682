import { mapState, mapMutations } from '@/store';

export default {
  computed: {
    ...mapState('condition', ['currentMenu']),
    getCurrentMenu() {
      return this.currentMenu;
    },
  },
  methods: {
    ...mapMutations('condition', ['setMenuPath']),
    isCurrentMenu(path, menuName) {
      return `${path}/${menuName}` === this.currentMenu;
    },
    setCurrentMenu(path, menuName) {
      this.setMenuPath({ path, menuName });
    },
  },
};
