<template>
  <div>
    <div class="button-group fixed-div-button-group">
      <ShareQuery v-if="uiConfig.share" :uniqueIndex="uniqueIndex" style="z-index: 10" />
      <SaveQuery v-if="uiConfig.save" :uniqueIndex="uniqueIndex" style="z-index: 10" />
      <ExportModal
        v-if="uiConfig.export"
        :uniqueIndex="uniqueIndex"
        style="z-index: 10"
        :exportOptions="exportOptions"
      />
    </div>
    <div style="height: 95%; background-color: #fff" class="analyze">
      <a-layout>
        <a-layout-content>
          <a-tabs
            :default-active-key="PAGES.STATISTIC_SUMMARY"
            id="analyze-tab"
            type="card"
            style="margin-top: 15px"
            @change="switchTab"
            :activeKey="currentTab"
          >
            <a-tab-pane
              :key="PAGES.STATISTIC_SUMMARY"
              :closable="false"
              style="background-color: #ffffff"
              v-if="uiConfig.StatisticSummary"
            >
              <template slot="tab">
                <div class="custom-tab" @click="switchTab(PAGES.STATISTIC_SUMMARY)">
                  <div class="border-fixer">
                    {{ PAGES.STATISTIC_SUMMARY }}
                  </div>
                </div>
              </template>
              <a-row>
                <a-col>
                  <a-row class="search-status-raw fixed-div backgroud-white" :ref="PAGES.STATISTIC_SUMMARY">
                    <a-col>
                      <SearchStatus
                        :keywordObject="keywordObject"
                        :uniqueIndex="uniqueIndex"
                        showTFIDF
                        :propTabName="PAGES.STATISTIC_SUMMARY"
                      />
                    </a-col>
                  </a-row>
                  <a-row>
                    <a-col>
                      <StatisticSummary ref="statisticSummary" :uniqueIndex="uniqueIndex" class="tab-content-raw" />
                    </a-col>
                  </a-row>
                </a-col>
              </a-row>
            </a-tab-pane>
            <a-tab-pane :key="PAGES.LABS" :closable="false" style="background-color: #ffffff" v-if="uiConfig.LabResult">
              <template slot="tab">
                <div class="custom-tab">
                  <div class="border-fixer">
                    {{ PAGES.LABS }}
                  </div>
                </div>
              </template>
              <a-row>
                <a-col>
                  <a-row class="search-status-raw fixed-div backgroud-white" :ref="PAGES.LABS">
                    <a-col>
                      <SearchStatus
                        :keywordObject="keywordObject"
                        :uniqueIndex="uniqueIndex"
                        :propTabName="PAGES.LABS"
                      />
                    </a-col>
                  </a-row>
                  <a-row>
                    <a-col>
                      <LabResult
                        @showPatient="handleShowPatient"
                        :query="query"
                        :uniqueIndex="uniqueIndex"
                        class="tab-content-raw"
                      />
                    </a-col>
                  </a-row>
                </a-col>
              </a-row>
            </a-tab-pane>
            <a-tab-pane
              :key="PAGES.PATIENT_EMR"
              :closable="false"
              style="background-color: #ffffff"
              v-if="!uiConfig.OPD_Searchable"
            >
              <template slot="tab">
                <div class="custom-tab">
                  <div class="border-fixer">
                    {{ PAGES.PATIENT_EMR }}
                  </div>
                </div>
              </template>
              <a-row>
                <a-col>
                  <a-row class="search-status-raw fixed-div backgroud-white" :ref="PAGES.PATIENT_EMR">
                    <a-col>
                      <SearchStatus
                        :keywordObject="keywordObject"
                        :uniqueIndex="uniqueIndex"
                        :propTabName="PAGES.PATIENT_EMR"
                      />
                    </a-col>
                  </a-row>
                  <a-row>
                    <a-col>
                      <PatientResult
                        @showPatient="handleShowPatient"
                        :query="JSON.stringify(query)"
                        :patientNum="patientNum"
                        :type="PAGES.PATIENT_EMR"
                        :uniqueIndex="uniqueIndex"
                        class="tab-content-raw"
                      />
                    </a-col>
                  </a-row>
                </a-col>
              </a-row>
            </a-tab-pane>
            <a-tab-pane
              :key="PAGES.INPATIENT_EMR"
              :closable="false"
              style="background-color: #ffffff"
              v-if="uiConfig.OPD_Searchable"
            >
              <template slot="tab">
                <div class="custom-tab">
                  <div class="border-fixer">
                    {{ PAGES.INPATIENT_EMR }}
                  </div>
                </div>
              </template>
              <a-row>
                <a-col>
                  <a-row class="search-status-raw fixed-div backgroud-white" :ref="PAGES.INPATIENT_EMR">
                    <a-col>
                      <SearchStatus
                        :keywordObject="keywordObject"
                        :uniqueIndex="uniqueIndex"
                        :propTabName="PAGES.INPATIENT_EMR"
                      />
                    </a-col>
                  </a-row>
                  <a-row>
                    <a-col>
                      <PatientResult
                        @showPatient="handleShowPatient"
                        :query="JSON.stringify(query)"
                        :patientNum="patientNum"
                        :type="PAGES.INPATIENT_EMR"
                        :uniqueIndex="uniqueIndex"
                        class="tab-content-raw"
                      />
                    </a-col>
                  </a-row>
                </a-col>
              </a-row>
            </a-tab-pane>
            <a-tab-pane
              :key="PAGES.OUTPATIENT_EMR"
              :closable="false"
              style="background-color: #ffffff"
              v-if="uiConfig.OPD_Searchable"
            >
              <template slot="tab">
                <div class="custom-tab">
                  <div class="border-fixer">
                    {{ PAGES.OUTPATIENT_EMR }}
                  </div>
                </div>
              </template>
              <a-row>
                <a-col>
                  <a-row class="search-status-raw fixed-div backgroud-white" :ref="PAGES.OUTPATIENT_EMR">
                    <a-col>
                      <SearchStatus
                        :keywordObject="keywordObject"
                        :uniqueIndex="uniqueIndex"
                        :propTabName="PAGES.OUTPATIENT_EMR"
                      />
                    </a-col>
                  </a-row>
                  <a-row>
                    <a-col>
                      <PatientResult
                        @showPatient="handleShowPatient"
                        :query="JSON.stringify(query)"
                        :patientNum="patientNum"
                        :type="PAGES.OUTPATIENT_EMR"
                        :uniqueIndex="uniqueIndex"
                        class="tab-content-raw"
                      />
                    </a-col>
                  </a-row>
                </a-col>
              </a-row>
            </a-tab-pane>
          </a-tabs>
        </a-layout-content>
      </a-layout>
      <PatientInformationModal :pid="patientId" ref="patientModel" :uniqueIndex="uniqueIndex" />
      <DemoVideo />
    </div>
    <div class="save-query-text" v-if="savedQueryText">Saved</div>
    <div class="link-copied-text" v-if="linkCopiedText">Link copied</div>
    <div class="error-menu" v-if="errorMenu">
      <b-row no-gutters>
        <b-col>
          <b-row no-gutters>
            <b-col class="error-title">{{ errorTitle }}</b-col>
          </b-row>
          <b-row no-gutters>
            <b-col class="error-content">{{ errorContent }} </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import LabResult from '@/components/analysis/LabResult.vue';
import PatientResult from '@/components/analysis/PatientResult.vue';
import StatisticSummary from '@/components/analysis/StatisticSummary.vue';
import SearchStatus from '@/components/analysis/status/SearchStatus.vue';
import PatientInformationModal from '@/views/components/PatientInformationModal.vue';
import DemoVideo from '@/components/modals/DemoVideo.vue';
import ShareQuery from '@/components/elements/buttons/ShareQuery.vue';
import SaveQuery from '@/components/elements/buttons/SaveQuery.vue';
import ExportModal from '@/components/modals/ExportModal.vue';

import { Tooltip } from 'ant-design-vue';

import { mapSubUiConfig } from '@/utils/uiConfig';

import { ClickMixin, TrackTimeMixin, UpdateMixin, PatientNumberMixin, AdvanceSearchMixin } from '@/mixins';
import { DOWNLOAD, OBSERVERS, PAGES, VUE_EVENT_NAME } from '@/utils/constants';
import { mapState, mapMutations } from '../../store';

export default {
  name: 'Analyze',
  mixins: [TrackTimeMixin, ClickMixin, UpdateMixin, PatientNumberMixin, AdvanceSearchMixin],
  components: {
    LabResult,
    PatientResult,
    StatisticSummary,
    PatientInformationModal,
    SearchStatus,
    // eslint-disable-next-line vue/no-unused-components
    'a-tooltip': Tooltip,
    DemoVideo,
    ShareQuery,
    SaveQuery,
    ExportModal,
  },
  props: {
    uniqueIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      patientId: '0',
      keywordObject: {},
      exportLoading: false,
      currentTab: PAGES.STATISTIC_SUMMARY,
      downloadTest: {},
      counter: 0,
      PAGES,
      exportType: 1,
      radioStyle: {
        display: 'block',
        height: '15px',
        lineHeight: '15px',
      },
      radioValue: 1,
      savedQueryText: false,
      linkCopiedText: false,
      errorMenu: false,
      listVisible: true,
      summaryHeight: 0,
      labHeight: 0,
      patientEMRHeight: 0,
      inpatientEMRHeight: 0,
      outpatientEMRHeight: 0,
      pageToObserver: {
        [PAGES.STATISTIC_SUMMARY]: {
          observer: OBSERVERS.STATISTIC_SUMMARY,
        },
        [PAGES.LABS]: {
          observer: OBSERVERS.LABS,
        },
        [PAGES.PATIENT_EMR]: {
          observer: OBSERVERS.PATIENT_EMR,
        },
        [PAGES.INPATIENT_EMR]: {
          observer: OBSERVERS.INPATIENT_EMR,
        },
        [PAGES.OUTPATIENT_EMR]: {
          observer: OBSERVERS.OUTPATIENT_EMR,
        },
      },
    };
  },
  computed: {
    ...mapState('condition', ['patient']),
    ...mapState('session', ['tabs']),
    ...mapState(['rootId', 'scrollTop']),
    ...mapSubUiConfig('Analyze'),
    state() {
      return this.tabs[this.uniqueIndex].state;
    },
    tab() {
      if (this.tabs.length === 0) {
        return {
          includes: [],
          excludes: [],
        };
      }
      return this.tabs[this.uniqueIndex];
    },
    query() {
      return this.getQuery({
        includes: this.tab.includes,
        excludes: this.tab.excludes,
      });
    },
    patientNum() {
      return this.patient;
    },
    getClickCheck() {
      return this.currentTab !== PAGES.INPATIENT_EMR && this.currentTab !== PAGES.OUTPATIENT_EMR;
    },
    tabState() {
      return JSON.stringify([this.tab.includes, this.tab.excludes, this.tab.name, this.tab.updateTime]);
    },
    exportOptions() {
      const exportOptions = [DOWNLOAD.RECORDS, DOWNLOAD.LABS];
      if (this.uiConfig.medicationInfoExport) {
        exportOptions.splice(1, 0, DOWNLOAD.ORDERS);
      }
      return exportOptions;
    },
  },
  watch: {
    tabState() {
      if (this.tab.data.analyzeTab !== this.currentTab && !this.isAdvanceSearch) {
        this.switchTab(this.tab.data.analyzeTab);
      }
    },
  },
  methods: {
    ...mapMutations(['setTabName', 'setExportLoading', 'setCounter', 'setDemoVideoIndex', 'setDemoVideoVisible']),
    ...mapMutations('session', ['updateTab']),
    clickCollapse() {
      this.listVisible = !this.listVisible;
      this.advanceTrackClick('clickCollapse', {
        listVisible: this.listVisible,
        tabIndex: this.uniqueIndex,
      });
    },
    handleShowPatient(pid, mode) {
      this.patientId = pid;
      if (mode) {
        this.$refs.patientModel.mode = mode;
      }
      this.$refs.patientModel.visible = true;
    },
    switchTab(tabName) {
      this.updateTab({
        index: this.uniqueIndex,
        data: {
          ...this.tab.data,
          analyzeTab: tabName,
        },
      });
      const previousTab = this.currentTab;
      this.currentTab = tabName;
      this.setTabName(tabName);
      this.trackClick('switchTabPage', { tabName });
      this.endTime(previousTab);
      this.startTime(this.currentTab);
    },
    observeHeight(pageValid, data) {
      if (this.$refs[pageValid]) {
        const resizeObserver = new ResizeObserver(() => {
          this[data] = this.$refs[pageValid].$el.offsetHeight + 15;
          this.$root.$emit(VUE_EVENT_NAME.SET_VUEPERFECTSCROLLBAR_UPDATE);
        });

        resizeObserver.observe(this.$refs[pageValid].$el);
      }
    },
  },
  created() {
    // Disable right click for copy
    if (!this.uiConfig.rightClick) {
      window.addEventListener(
        'contextmenu',
        (e) => {
          if (e.origin !== process.env.VUE_APP_BASE_URL) {
            e.preventDefault();
          }
        },
        false
      );
    }

    this.startTime(this.currentTab);
  },
  mounted() {
    this.update({
      includes: this.tab.includes,
      excludes: this.tab.excludes,
      uniqueIndex: this.uniqueIndex,
    });
    // Avoid export errors, re-enter and restore the initial state
    this.setExportLoading(false);
    this.setDemoVideoVisible(false);
    this.setDemoVideoIndex(0);
    this.setCounter(0);
    this.$root.$on(VUE_EVENT_NAME.OPEN_SAVED_TEXT, () => {
      this.savedQueryText = true;
      this.linkCopiedText = false;
      this.errorMenu = false;
    });

    this.$root.$on(VUE_EVENT_NAME.CLOSE_SAVED_TEXT, () => {
      this.savedQueryText = false;
    });

    this.$root.$on(VUE_EVENT_NAME.OPEN_LINK_COPIED_TEXT, () => {
      this.savedQueryText = false;
      this.linkCopiedText = true;
      this.errorMenu = false;
    });

    this.$root.$on(VUE_EVENT_NAME.CLOSE_LINK_COPIED_TEXT, () => {
      this.linkCopiedText = false;
    });

    this.$root.$on(VUE_EVENT_NAME.OPEN_ERROR_MENU, (errorTitle, errorContent) => {
      this.errorMenu = true;
      this.linkCopiedText = false;
      this.savedQueryText = false;
      this.errorTitle = errorTitle;
      this.errorContent = errorContent;
    });

    this.$root.$on(VUE_EVENT_NAME.CLOSE_ERROR_MENU, () => {
      this.errorMenu = false;
    });
  },
  destroyed() {
    this.$root.$off(VUE_EVENT_NAME.OPEN_SAVED_TEXT);
    this.$root.$off(VUE_EVENT_NAME.CLOSE_SAVED_TEXT);
    this.$root.$off(VUE_EVENT_NAME.OPEN_LINK_COPIED_TEXT);
    this.$root.$off(VUE_EVENT_NAME.CLOSE_LINK_COPIED_TEXT);
    this.$root.$off(VUE_EVENT_NAME.OPEN_ERROR_MENU);
    this.$root.$off(VUE_EVENT_NAME.CLOSE_ERROR_MENU);
  },
};
</script>

<style scope>
.analyze {
  min-height: 900px;
}

.ant-tabs-nav {
  margin-left: 4px !important;
}

.ant-tabs-tab-active {
  height: 40px;
  border-radius: 6px;
  border: none !important;
  background-color: #ffffff !important;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333f6b !important;
}

.ant-tabs-nav .ant-tabs-tab:hover {
  color: #333f6b !important;
}

.ant-tabs-tab {
  height: 40px;
  background-color: #ffffff !important;
  border-radius: 6px;
  border: none !important;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333f6b !important;
  margin-right: 4px !important;
}

.ant-tabs-bar {
  margin: 0 0 0 0 !important;
  border: none !important;
}
</style>
<style scoped>
.button-export {
  width: 117px;
  height: 30px;
  border-radius: 6px;
  border: solid 1px #333f6b;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333f6b;
  background-color: #ffffff;
  z-index: 1000;
}

.button-export >>> .anticon-loading {
  left: -5px;
  position: relative;
  top: -3px;
}

.button-export.ant-btn-loading >>> span {
  margin-left: 0px;
}

.view-all {
  position: absolute;
  top: 5px;
  right: 45px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  cursor: pointer;
  z-index: 1;
}

.tab-title-text {
  width: 983px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #333333;
}

.icon-help {
  position: absolute;
  top: 5px;
  left: 160px;
  z-index: 1;
  cursor: pointer;
}

.help-text {
  position: absolute;
  top: 30px;
  right: 80px;
  z-index: 1;
  border-radius: 1px;
  border: solid 1px #e0e0e0;
  background-color: rgba(255, 255, 255, 0.99);
  width: 311px;
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 15px;
  padding-left: 15px;
}

.hover {
  display: none;
}

.icon-help:hover + .hover {
  display: block;
}

#analyze-tab >>> .ant-tabs-tab-active > .custom-tab {
  font-weight: bold;
  border-bottom: 5px solid #0074e6;
  position: relative;
  top: -2px;
}
#analyze-tab >>> .ant-tabs-tab-active > .custom-tab > .border-fixer {
  position: relative;
  top: 2px;
}

.radio-position {
  position: absolute;
  margin: 10px 0px 0px 40px;
}

.tab-content-raw {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 27px;
}

.search-status-raw {
  margin-top: 15px;
  padding: 20px 20px 10px 20px;
}

.patient-record-block {
  width: 369px;
  min-width: 369px;
  max-width: 369px;
  position: absolute;
  margin: 10px 0px 0px 65px;
  z-index: 10;
}

.patinet-text {
  width: 71px;
  min-width: 71px;
  max-width: 71px;
  height: 15px;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333f6b;
}

.patinet-content {
  width: 64px;
  min-width: 64px;
  max-width: 64px;
  height: 15px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333f6b;
  text-align: right;
}

.record-text {
  width: 110px;
  min-width: 110px;
  max-width: 110px;
  height: 14px;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333f6b;
}

.record-content {
  width: 64px;
  min-width: 64px;
  max-width: 64px;
  height: 15px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333f6b;
  text-align: right;
}

.patient-block {
  margin-left: 10px;
}

.record-block {
  margin-left: 40px;
}

.highlight-row {
  border-radius: 100px;
  background-color: #ffffff;
}

.highlight-text {
  font-weight: bold;
}

.hover-canclick:hover {
  cursor: pointer;
}
</style>
<style lang="scss" scoped>
/deep/ .ant-radio-inner {
  border-color: #333f6b !important;
  background-color: #ffffff !important;
}

/deep/.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner.ant-radio-inner {
  border-color: #333f6b !important;
  background-color: #ffffff !important;
}

/deep/ .ant-radio-checked {
  border-color: #333f6b !important;
  background-color: #ffffff !important;
}

/deep/ .ant-radio-inner::after {
  background-color: #333f6b !important;
}

.button-group {
  padding: 15px 20px 0px 0px;
  display: flex;
  max-height: 0px;
  justify-content: flex-end;
  z-index: 1001 !important;
  background-color: #ffffff;
}

/deep/ .text-keyword-content .category {
  float: left;
}

/deep/ .text-keyword-content .concater {
  float: left;
  top: 2px;
  padding: 0px 5px;
}

.save-query-text {
  width: 88px;
  height: 54px;
  padding: 15px 20px;
  border-radius: 6px;
  box-shadow: 0 4px 10px 0 rgba(51, 63, 107, 0.2);
  border: solid 1px #ffffff;
  background-color: #758cc8;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  position: fixed;
  bottom: 80px;
  left: calc(50% - 44px);
  z-index: 1001;
}

.link-copied-text {
  width: 130px;
  height: 54px;
  padding: 15px 20px;
  border-radius: 6px;
  -webkit-box-shadow: 0 4px 10px 0 rgba(51, 63, 107, 0.2);
  box-shadow: 0 4px 10px 0 rgba(51, 63, 107, 0.2);
  border: solid 1px #ffffff;
  background-color: #758cc8;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  position: fixed;
  bottom: 80px;
  left: calc(50% - 205px);
  z-index: 1001;
}

.error-menu {
  width: 340px;
  padding: 15px 20px;
  border-radius: 6px;
  box-shadow: 0 4px 10px 0 rgba(51, 63, 107, 0.2);
  border: solid 1px #ffffff;
  background-color: #da6d6d;
  position: fixed;
  bottom: 80px;
  left: calc(50% - 140px);
  z-index: 1001;
}

.error-title {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  margin-bottom: 5px;
}

.error-content {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}

.backgroud-white {
  background-color: #ffffff;
}

.analyze {
  .ant-layout {
    background-color: #ffffff;
  }
}
</style>
