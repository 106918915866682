import { getName, DescriptionType } from '@/utils/descriptionCache';
import { ChartItem, ChartKey, RawItem } from '@/components/charts/core/Interface';
import ChartConfig from './core/AdvChartConfig';
import { advanceAxisFormatter, removePoint } from '../utils/utils';

const fieldMap = new Map([
  ['6', '0.5_YEAR'],
  ['12', '1_YEAR'],
]);

const OPTION = {
  xAxis: {
    type: 'value',
    axisLabel: {
      formatter: removePoint,
    },
  },
  yAxis: {
    type: 'category',
    triggerEvent: true,
    inverse: true,
    axisLabel: {
      formatter: advanceAxisFormatter,
    },
  },
  grid: {
    top: 80,
  },
};

interface DiagnosisRawItem extends RawItem {
  count_unique_patient: {
    value: number;
  };
  name: string;
}

export default abstract class AdvCategoryChartConfig extends ChartConfig<DiagnosisRawItem> {
  protected abstract descriptionType: DescriptionType;

  protected abstract fieldName: string;

  protected abstract canAISort: boolean;

  constructor(key: ChartKey) {
    super(key);

    this.hasViewAll = true;
    this.merge(OPTION);
    this.setChartType();
  }

  protected preProcessReturnData(data: any) {
    return Promise.all(
      data.aggregations.result.buckets.map(async (item: { key: any; count_unique_patient: { value: any } }) => {
        const { key } = item;
        return {
          ...item,
          name: await getName(this.descriptionType, key),
        };
      })
    );
  }

  protected setAxisData(): void {
    this.unset('yAxis.data');
    this.merge({
      yAxis: {
        data: this.data.map((item: { key: any }) => item.key),
      },
    });
  }

  protected setChartType(): void {
    this.merge({
      series: {
        type: 'bar',
        barWidth: '70%',
        animation: false,
      },
    });
  }

  protected createChartItem(rawItem: DiagnosisRawItem): ChartItem {
    const {
      key,
      name,
      count_unique_patient: { value },
    } = rawItem;

    return {
      key: `${name}∙${key}`,
      value,
      code: key,
      name,
      color: '#333f6b',
      percentage: Math.round((value / this.patientCount) * 100.0),
    };
  }

  protected get aggsQuery(): Record<string, unknown> {
    const termOrSignificantTerm = this.params.sortChartByAI && this.canAISort ? 'significant_terms' : 'terms';
    const order = this.params.sortChartByAI
      ? {}
      : {
          order: { count_unique_patient: 'desc' },
        };
    return {
      [termOrSignificantTerm]: {
        field: `STATISTIC_PERIOD.${fieldMap.get(this.params.TimeSelectObject.afterValue)}.${this.fieldName}.FIRST`,
        size: 10,
        ...order,
      },
      aggs: {
        count_unique_patient: {
          cardinality: {
            field: 'CHART_NO',
          },
        },
      },
    };
  }
}
