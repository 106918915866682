/* eslint-disable no-param-reassign */
import { formatIcdGroupMapItem, getIcdFormatList } from '@/utils/util';

export default {
  methods: {
    async openHoverExpand(option) {
      const response = await this.$api
        .searchDiagnosisExpand({
          query: this.inputText,
          diagnosis_range: this.getDiagnosisRange,
          code: option.code,
          query_mode: 'ICD10',
        })
        .catch((error) => {
          console.error(error);
        });

      const formatItem = (item) => ({
        ...formatIcdGroupMapItem(item),
        check: option.check,
        level: option.level + 1,
        parent: option.code,
      });

      const ICDCodes = getIcdFormatList(response.children, formatItem);

      const index = this.hoverOptions.findIndex((item) => item.code === option.code);
      if (index !== -1) {
        this.hoverOptions.splice(index + 1, 0, ...ICDCodes);
        option.child = ICDCodes;
        option.expand = true;
      } else {
        this.hoverOptions = ICDCodes;
      }

      this.trackClick('openHoverExpand', { option });
    },
    async getHoverInitData(option) {
      const response = await this.$api.searchDiagnosisMerge({
        diagnosis_range: this.getDiagnosisRange,
        query_mode: 'ICD10',
        icd_list: option.selectDescendants,
      });

      const formatItem = (item) => ({
        ...formatIcdGroupMapItem(item),
        level: 1,
        parent: '',
      });

      const ICDCodes = getIcdFormatList(response.results, formatItem);

      this.hoverOptions = ICDCodes;

      return Promise.resolve();
    },
  },
};
