<template>
  <div>
    <a-radio-group :value="bucketType" @change="onBucketChange">
      <a-radio :value="CHART.BUCKET.PATIENT" class="word"> Patient Count </a-radio>
      <a-radio :value="CHART.BUCKET.RECORD" class="word"> Record Count </a-radio>
    </a-radio-group>
  </div>
</template>

<script>
import { ClickMixin } from '@/mixins';
import { CHART } from '@/utils/constants';
import { mapMutations, mapState } from '../../../../store';

export default {
  name: 'ByPatientOrRecordGroup',
  mixins: [ClickMixin],
  props: {
    uniqueIndex: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapState('session', ['tabs']),
    tab() {
      return this.tabs[this.uniqueIndex];
    },
    bucketType() {
      return this.tab.data.bucketByPatient ? CHART.BUCKET.PATIENT : CHART.BUCKET.RECORD;
    },
  },
  data() {
    return {
      CHART,
    };
  },
  methods: {
    ...mapMutations('session', ['updateTab']),
    onBucketChange(e) {
      const {
        target: { value },
      } = e;
      // If not by-patient grouping, switch off AI sorting.
      const updateAiSort = value !== CHART.BUCKET.PATIENT ? false : this.tab.data.sortChartByAI;
      this.trackClick('onBucketChange', { value, uniqueIndex: this.uniqueIndex });
      this.updateTab({
        index: this.uniqueIndex,
        data: {
          ...this.tab.data,
          bucketByPatient: value === CHART.BUCKET.PATIENT,
          sortChartByAI: updateAiSort,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.word {
  margin-left: 10px;
  color: #333f6b;
  font-size: 14px;
  font-weight: bold;
}
</style>
