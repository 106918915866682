





import { Vue, Component, Prop } from 'vue-property-decorator';
import { MedicalTerm } from '@/utils/conditions/core';

@Component
export default class PCSCodeValue extends Vue {
  @Prop(Object)
  private readonly value!: MedicalTerm;
}
