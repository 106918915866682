import BasicChartConfig from '@/components/charts/core/BasicChartConfig';
import { BasicChartQueryParams, ChartItem, ChartKey, RawItem } from '@/components/charts/core/Interface';
import {
  CategoryCondition,
  CategoryConditionData,
  ConditionName,
  LogicOp,
  SearchType,
} from '../../utils/conditions/core';
import { PRECISION_THRESHOLD } from '../../utils/constants';
import { UNSELECTED_COLOR } from './utils/utils';

const OPTION = {
  series: {
    type: 'pie',
    center: ['50%', '70%', '80%'],
    height: '80%',
  },
  legend: {
    left: 0,
    top: 0,
    padding: 0,
    icon: 'circle',
    data: ['Inpatient', 'Outpatient', 'ER'],
    selectedMode: false,
  },
  tooltip: {
    trigger: 'item',
  },
};

interface DiagnosisRawItem extends RawItem {
  patient_count: {
    value: number;
  };
}

export default class VisitConfig extends BasicChartConfig<DiagnosisRawItem> {
  constructor() {
    super(ChartKey.Visit);

    this.merge(OPTION);
  }

  protected processQueryParams(): BasicChartQueryParams {
    const condList = this.params.includes.without(ConditionName.Visit);
    return {
      ...this.params,
      includes: condList,
    };
  }

  protected createChartItem(rawItem: DiagnosisRawItem): ChartItem {
    const {
      key,
      doc_count,
      patient_count: { value },
    } = rawItem;

    const condList = this.params.includes.additions.with(ConditionName.Visit);

    let color: string | null = null;
    if (condList.length !== 0) {
      const condition = condList[0] as CategoryCondition;
      color = UNSELECTED_COLOR;
      if (condition.hitKey(key)) {
        color = null;
      }
    }

    let name = '';
    switch (key) {
      case 'I':
        name = 'Inpatient';
        break;
      case 'O':
        name = 'Outpatient';
        break;
      case 'E':
        name = 'ER';
        break;
      default:
        throw new Error(`Not found visit type ${key}`);
    }

    const targetValue = this.bucketByPatient ? value : doc_count;

    return {
      key,
      value: targetValue,
      code: name,
      name,
      color,
      percentage: this.calculatePercentage(targetValue),
    };
  }

  public createConditionFromItem(chartItem: ChartItem): CategoryConditionData {
    const { name } = chartItem;
    let key = '';
    switch (name) {
      case 'Inpatient':
        key = 'I';
        break;
      case 'Outpatient':
        key = 'O';
        break;
      case 'ER':
        key = 'E';
        break;
      default:
        throw new Error(`Not found visit type ${name}`);
    }
    return {
      name: ConditionName.Visit,
      tags: [SearchType.Basic, SearchType.Additional],
      andOr: LogicOp.Or,
      list: [key],
    };
  }

  protected get aggsQuery(): Record<string, unknown> {
    return {
      terms: {
        field: 'DEPARTMENT',
      },
      aggs: {
        patient_count: {
          cardinality: {
            field: 'CHART_NO',
            precision_threshold: PRECISION_THRESHOLD,
          },
        },
      },
    };
  }
}
