import { CHART } from '../constants';
import { CategoryCondition, ConditionFactory, ConditionName } from './core';

const HABITFIELDS: { [index: string]: string } = {
  smoke: 'SMOKE_STATUS',
  alcohol: 'ALCOHOL_STATUS',
  betelnut: 'BETELNUT_STATUS',
};

function getSectionQuery(section: any[]) {
  return section.map((value) => ({
    term: {
      [HABITFIELDS[value.habit]]: value.status,
    },
  }));
}

export default class HabitDef extends CategoryCondition {
  // static get TYPE(): string { return ConditionType.CATEGORY; }
  static get NAME(): string {
    return CHART.TITLE.HABIT;
  }

  public getFilter(): Record<string, unknown> {
    return {
      bool: {
        [this.andOr]: getSectionQuery(this.list),
      },
    };
  }
}

ConditionFactory.register(HabitDef, ConditionName.Habit);
