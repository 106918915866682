<template>
  <div
    v-on:click.stop="addAgeConstraint"
    class="advance-ageFilter-adder"
    :class="{ disabled: this.constraintIndex !== -1 }"
  >
    + AGE
  </div>
</template>

<script>
import { findIndex, cloneDeep } from 'lodash';
import { EventType } from '@/utils/conditions/core/Interface';
import { AdvanceSearchMixin, AdvanceUpdateMixin, ClickMixin } from '@/mixins';
import ConstraintType from '@/utils/conditions/core/advance/constraint/Interface';

export default {
  name: 'AdvanceAgeFilter',
  props: {
    index: {
      type: Number,
      default: 1,
    },
    eventType: {
      type: String,
      default: EventType.FIX_EVENT,
    },
    path: {
      type: Array,
      default: () => [],
    },
  },
  mixins: [AdvanceSearchMixin, AdvanceUpdateMixin, ClickMixin],
  computed: {
    currentEvent() {
      return this.getEventDataByPath(this.path);
    },
    constraintIndex() {
      return findIndex(this.currentEvent.constraintList, {
        constraintType: ConstraintType.AgeConstraint,
      });
    },
  },
  methods: {
    addAgeConstraint() {
      const ageConstraint = {
        start: 0,
        end: 200,
      };
      const event = cloneDeep(this.currentEvent);

      if (this.constraintIndex === -1) {
        event.constraintList.push({
          constraintType: ConstraintType.AgeConstraint,
          ...ageConstraint,
        });
      }
      const body = {
        constraintList: event.constraintList,
      };
      this.updateEventWithPath(body, this.path);
      this.setCurrentMenu(this.path, 'AgeConstraint');
      this.advanceTrackClick('openAgeFilter', {
        path: this.path,
        index: this.index,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.advance-ageFilter-adder {
  padding: 5px 15px;
  border: 1px solid #4a94d2;
  background: #ffffff;
  border-radius: 53px;
  font-size: 16px;
  line-height: 20px;
  color: #4a94d2;
  margin-left: 10px;
  cursor: pointer;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
