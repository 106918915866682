





import { Vue, Component, Prop } from 'vue-property-decorator';
// eslint-disable-next-line no-unused-vars
import { MedicalTerm } from '../../../../../utils/conditions/core';
import { API_TARGET } from '../../../../../utils/constants';
import { mapSubUiConfig } from '../../../../../utils/uiConfig';
import { mapGetters } from '../../../../../store';

declare module 'vue/types/vue' {
  export interface Vue {
    NHI(value: string): string;
    PCS(value: string): string;
  }
}

@Component({
  computed: {
    ...mapSubUiConfig('SearchForm'),
    ...mapGetters([]),
  },
})
export default class ProcedureValue extends Vue {
  public readonly uiConfig: any;

  @Prop(Object) private readonly value!: MedicalTerm;

  getProcedureValue(value: string) {
    if (this.uiConfig.procedureTarget === API_TARGET.NHI_ORDER) {
      return this.NHI(value);
    }
    return this.PCS(value);
  }
}
