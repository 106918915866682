import BasicChartConfig from '@/components/charts/core/BasicChartConfig';
import { isValidSome } from '@/utils/util';
import { RangeCondition, RangeConditionData, ConditionName, SearchType, VisitType } from '../../utils/conditions/core';
import { BasicChartQueryParams, ChartItem, RawItem, ChartKey } from './core/Interface';
import { UNSELECTED_COLOR } from './utils/utils';

const RANGES = [
  { key: 'No Info', from: 0, to: 0.1 },
  { key: '<21 kg/m2', from: 0.1, to: 21.0 },
  { key: '>=21 kg/m2', from: 21.0, to: 100.0 },
];

const OPTION = {
  series: {
    type: 'pie',
    center: ['50%', '70%'],
    height: '80%',
  },
  tooltip: {
    trigger: 'item',
  },
};

interface BMIRawItem extends RawItem {
  from: number;
  to?: number;
}

export default class BMIConfig extends BasicChartConfig<BMIRawItem> {
  constructor() {
    super(ChartKey.BMI);

    this.merge(OPTION);
    this.bucketByPatient = false;
    this.disallowBucketChange = true;
  }

  protected get hasData(): boolean {
    return isValidSome(this.data, 0);
  }

  protected processQueryParams(): BasicChartQueryParams {
    const condList = this.params.includes.without(ConditionName.BMI);
    return {
      ...this.params,
      includes: condList,
      visitType: VisitType.IPD,
    };
  }

  protected createChartItem(rawItem: BMIRawItem): ChartItem {
    const { key, doc_count } = rawItem;
    const condList = this.params.includes.with(ConditionName.BMI);

    let color: string | null = null;
    if (condList.length !== 0) {
      const condition = (condList[0] as unknown) as RangeCondition;
      color = UNSELECTED_COLOR;
      if (condition.hitStartOrEnd(rawItem.from, rawItem.to)) {
        color = '#333f6b';
      }
    }

    return {
      key,
      value: doc_count,
      code: `${rawItem.from}-${rawItem.to}`,
      name: key,
      color,
      percentage: Math.round((doc_count / this.recordCount) * 100.0),
    };
  }

  public createConditionFromItem(chartItem: ChartItem): RangeConditionData {
    const { code, key } = chartItem;
    const condition = {
      name: ConditionName.BMI,
      tags: [SearchType.Basic, SearchType.Additional],
    };
    return {
      ...condition,
      key,
      ranges: [
        {
          start: Number(code.split('-')[0]),
          end: Number(code.split('-')[1]),
        },
      ],
    };
  }

  protected get aggsQuery(): Record<string, unknown> {
    return {
      range: {
        field: 'BMI_FLOAT',
        ranges: RANGES,
      },
    };
  }
}
