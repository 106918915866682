import ConstraintName from '@/utils/conditions/core/advance/constraint/Interface';
import * as Tags from './Tags';

enum CounterType {
  Patient = 'Patient',
  Record = 'Record',
}

enum VisitType {
  OPD = 'OPD',
  IPD = 'IPD',
  ALL = 'ALL',
}

enum ConditionName {
  AbnormalLab = 'AbnormalLab',
  Age = 'Age',
  ChartNo = 'ChartNo',
  Diagnosis = 'Diagnosis',
  Gender = 'Gender',
  Keyword = 'Keyword',
  Lab = 'Lab',
  Medication = 'Medication',
  ATCCode = 'ATC Code',
  Procedure = 'Procedure',
  Risk = 'Risk',
  Section = 'Section',
  Stay = 'Stay',
  Symptom = 'Symptom',
  Time = 'Time',
  Visit = 'Visit',
  MedicalOrder = 'Medical Order',
  PCSCode = 'PCS Code',
  DoctorId = 'Physician ID',
  DRGCode = 'DRG Code',
  DischargeStatus = 'Discharge Status',
  PatientSource = 'Patient Source',
  BMI = 'BMI',
  Readmission = 'Readmission',
  ReOPDE = 'Revisit to ER',
  Habit = 'Habit',
  Death = 'Death',
}

enum LogicOp {
  And = 'must',
  Or = 'should',
}

type ValuesOf<E> = E[keyof E];
type UnionOf<E> = { [K in keyof E]: ValuesOf<E[K]> }[keyof E];

type ConditionTag = UnionOf<typeof Tags>;

enum MedicalTermType {
  ICD9 = 'icd9',
  ICD10 = 'icd10',
  Medication = 'med',
  NHI = 'nhi',
  MedicalOrder = 'medicalOrder',
  ATCCode = 'atcCode',
  PCSCode = 'pcsCode',
  DRGCode = 'drgCode',
}

enum DateUnit {
  DAYS = 'day(s)',
  MONTHS = 'month(s)',
  YEARS = 'year(s)',
}

enum EventType {
  FIX_EVENT = 'fixEvent',
  INCLUSION = 'inclusion',
  EXCLUSION = 'exclusion',
}

enum EventStage {
  COHORT = 'cohort',
  INTERVENTION = 'intervention',
  OUTCOME = 'outcome',
}

enum EventDiagnosisRange {
  ALL = 'all',
  MAIN = 'main',
  TOP3 = 'top3',
}

enum VisitTypeName {
  ALL = 'all',
  OUTPATIENT = 'outpatient',
  INPATIENT = 'inpatient',
  ERPATIENT = 'erpatient',
}
interface GlobalData {
  readonly visitType: VisitType;
  readonly searchByPatient: boolean;
  readonly sortChartByAI: boolean;
  readonly filterByDoctorId: boolean;
}

// for Diagnosis, Medication, and Procedure
interface MedicalTerm {
  icd10List?: any;
  icd9List?: any;
  type: MedicalTermType;
  code: string;
  field?: string;
  name?: string;
}

interface RangeData {
  readonly start: number;
  readonly end: number;
}

interface ConditionData {
  readonly name: ConditionName;
  readonly tags: ConditionTag[];
  [x: string]: any;
}

interface CategoryConditionData extends ConditionData {
  readonly list: string[];
  readonly andOr: LogicOp;
}

interface HabitConditionData extends ConditionData {
  readonly list: [
    {
      habit: string;
      status: string;
    }
  ];
  readonly andOr: LogicOp;
  readonly habit: string;
}

interface TermConditionData extends ConditionData {
  [x: string]: any;
  readonly list: MedicalTerm[];
  readonly andOr: LogicOp;
}

interface RangeConditionData extends ConditionData {
  readonly ranges: RangeData[];
  readonly key?: string;
}

interface KeywordConditionData extends CategoryConditionData {
  readonly fields: string[];
}

interface LabConditionData extends RangeConditionData {
  readonly exam: string;
  readonly lab: string;
}

interface Condition {
  readonly type: string;
  readonly list: unknown[];
  readonly name: string;
}

interface TimeRelation {
  readonly beforeValue: string;
  readonly beforeUnit: DateUnit;
  readonly afterValue: string;
  readonly afterUnit: DateUnit;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Constraint {
  readonly constraintType: ConstraintName;
  // readonly data: any;
}
interface AgeConstraint extends Constraint {
  readonly start: number;
  readonly end: number;
}

interface VisitTypeConstraint extends Constraint {
  readonly visitType: VisitTypeName;
}

interface DiagnosisRangeConstraint extends Constraint {
  readonly diagnosisRange: EventDiagnosisRange;
}

interface Options {
  firstOccurrence: boolean;
}

interface BaseMedicalEvent {
  readonly constraintList: Constraint[];
  readonly constraint: Options;
  readonly condition: Condition[];
  readonly name: string;
  readonly additionalCondition?: AdditionalConditionParam;
}

interface InExclusionList {
  readonly inclusionList: TailTimeRelationEvent[];
  readonly exclusionList: TailTimeRelationEvent[];
}

interface TimeRelationEvent extends BaseMedicalEvent, InExclusionList {
  readonly relation: TimeRelation;
  readonly eventStage: string;
}

interface TailTimeRelationEvent extends BaseMedicalEvent {
  readonly relation: TimeRelation;
}

interface IndexEvent extends BaseMedicalEvent, InExclusionList {}

interface SearchState {
  readonly eventList: TimeRelationEvent[];
  readonly indexEvent: IndexEvent;
  readonly timePeriod: RangeData;
  readonly genderList: any[];
  readonly bodyMassList: any[];
}

interface AdditionalConditionParam {
  isDeath: boolean;
  isFirstTime: boolean;
}

export {
  CounterType,
  VisitType,
  VisitTypeName,
  ConditionName,
  GlobalData,
  LogicOp,
  ConditionTag,
  MedicalTermType,
  MedicalTerm,
  RangeData,
  ConditionData,
  CategoryConditionData,
  TermConditionData,
  RangeConditionData,
  KeywordConditionData,
  LabConditionData,
  HabitConditionData,
  DateUnit,
  Condition as ICondition,
  TimeRelation as ITimeRelation,
  AgeConstraint as IAgeConstraint,
  VisitTypeConstraint as IVisitTypeConstraint,
  DiagnosisRangeConstraint as IDiagnosisRangeConstraint,
  BaseMedicalEvent as IBaseMedicalEvent,
  Options as IOptions,
  TimeRelationEvent as ITimeRelationEvent,
  IndexEvent as IIndexEvent,
  SearchState as ISearchState,
  Constraint as IConstraint,
  AdditionalConditionParam as IAdditionalConditionParam,
  EventType,
  EventStage,
  InExclusionList,
  TailTimeRelationEvent as ITailTimeRelationEvent,
  EventDiagnosisRange,
  DiagnosisRangeConstraint,
};
