import { FEEDBACK } from '@/utils/constants';
import trackTemplate from '@/mixins/trackTemplate';
import { mapState } from 'vuex';

export default {
  mixins: [trackTemplate],
  computed: {
    ...mapState('user', ['userId']),
  },
  methods: {
    trackClick(name, value) {
      this.$api.feedback(
        FEEDBACK.NAME.TRACK_CLICK,
        {
          [FEEDBACK.COMPONENT_NAME]: this.$options.name,
          [FEEDBACK.CLICK_NAME]: name,
          [FEEDBACK.USER_ID]: this.userId,
          [FEEDBACK.VALUE]: value,
          [FEEDBACK.ROUTE]: this.$route.path,
          [FEEDBACK.TIME]: Date.now(),
          [FEEDBACK.FROM_SERVICE]: this.$api.fromService,
          [FEEDBACK.MODE]: FEEDBACK.MODE_TYPE.RECORD,
        },
        this.trackContentTemplate()
      );
    },
    advanceTrackClick(name, value) {
      this.$api.feedback(
        FEEDBACK.NAME.TRACK_CLICK,
        {
          [FEEDBACK.COMPONENT_NAME]: this.$options.name,
          [FEEDBACK.CLICK_NAME]: name,
          [FEEDBACK.USER_ID]: this.userId,
          [FEEDBACK.VALUE]: value,
          [FEEDBACK.ROUTE]: this.$route.path,
          [FEEDBACK.TIME]: Date.now(),
          [FEEDBACK.FROM_SERVICE]: this.$api.fromService,
          [FEEDBACK.MODE]: FEEDBACK.MODE_TYPE.EVENT,
        },
        this.advanceTrackContentTemplate()
      );
    },
    setUsedFunction(functionName) {
      this.$api.setNoticeFunction(this.userId, functionName).catch((error) => {
        console.error(error);
      });
    },
    cohortStudyTrackClick(name, value) {
      this.$api.feedback(
        FEEDBACK.NAME.TRACK_CLICK,
        {
          [FEEDBACK.COMPONENT_NAME]: this.$options.name,
          [FEEDBACK.CLICK_NAME]: name,
          [FEEDBACK.USER_ID]: this.userId,
          [FEEDBACK.VALUE]: value,
          [FEEDBACK.ROUTE]: this.$route.path,
          [FEEDBACK.TIME]: Date.now(),
          [FEEDBACK.FROM_SERVICE]: this.$api.fromService,
          [FEEDBACK.MODE]: FEEDBACK.MODE_TYPE.COHORT_STUDY,
        },
        this.cohortStudyTrackContentTemplate()
      );
    },
  },
};
