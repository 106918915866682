<template>
  <div class="d-flex">
    <div class="left-div">
      <LeftEMRSearch />
    </div>
    <div class="center-div">
      <div class="d-flex align-items-center hint-div">
        <b-img src="@/assets/images/icon_hint_light.svg" class="mr-15px" />
        <div class="hint-text">
          EMR Search identifies medical records that match your search criteria defined below.
        </div>
      </div>
      <InExcludeRender class="mt-20px" ref="InExcludeRender" />
      <div class="d-flex justify-content-end" style="margin-top: 40px">
        <div
          class="d-flex align-items-center justify-content-center mr-30px"
          style="cursor: pointer"
          @click="clickClearAll"
        >
          <b-img src="@/assets/images/icon_emr_search_clear_all.svg" class="mr-5px" />
          <div class="clear-all-text">Clear All</div>
        </div>
        <div
          class="d-flex align-items-center justify-content-center search-button"
          id="emr-search-button"
          :class="{ 'disable-button': inPatient === 0 && outPatient === 0 }"
          @click="
            handleSubmit(
              conditions.filter((item) => !item.list || item.list.length !== 0),
              excludedConditions.filter((item) => !item.list || item.list.length !== 0)
            )
          "
        >
          <b-img src="@/assets/images/icon_search_button.svg" class="mr-5px" />
          <div class="search-text">Search</div>
        </div>
      </div>
      <div class="landing-mask" v-if="EMRSearchLandingIndex === 7">
        <div
          class="d-flex align-items-center justify-content-center search-button"
          :style="{ 'margin-left': `${searchLeft}px`, 'margin-top': `${searchTop}px`, width: `${searchWidth}px` }"
          style="pointer-events: auto"
          @click="clickNext7"
        >
          <b-img src="@/assets/images/icon_search_button.svg" class="mr-5px" />
          <div class="search-text">Search</div>
        </div>
        <div
          class="landing-1-tooltip"
          :style="{ left: `${searchLeft - 107}px`, top: `${searchTop - 182}px` }"
          style="display: inline-block"
        >
          <div class="d-flex align-items-center">
            <b-img src="@/assets/images/landing_3.svg" />
            <div class="mini-title ml-5px">估算與搜尋</div>
          </div>
          <div class="landing-text mt-10px">最後步驟</div>
          <div class="landing-content-text mt-10px">
            完成所有設定後點擊 “Search” 查看符合設定條件病人 demographics, medical records, lab data 等資訊
          </div>
        </div>
      </div>
    </div>
    <div class="right-div" id="right-SavedConcept">
      <SavedConcept />
    </div>
    <div class="landing-mask" v-if="EMRSearchLandingIndex === 4">
      <div
        class="right-div"
        :style="{
          width: `${width}px`,
          width: `calc(100% - ${top}px - 40px)`,
          'max-width': `${width}px`,
          'min-width': `${width}px`,
          margin: `${top}px 0px 0px ${left}px`,
          display: EMRSearchLandingIndex === 4 ? 'inline-block' : 'block',
        }"
        style="background: #ffffff; border-radius: 16px"
      >
        <SavedConcept style="pointer-events: none" />
        <div class="landing-tooltip" :style="{ left: `${left - 296 - 21}px` }">
          <div class="d-flex align-items-center">
            <b-img src="@/assets/images/landing_1.svg" />
            <div class="mini-title ml-5px">輸入條件與儲存個人化concept</div>
          </div>
          <div class="landing-text mt-10px">個人化concept清單</div>
          <div class="landing-content-text mt-10px">
            現在可以看到剛剛儲存的 “user input name” 已經出現在 My concept 裡，還有已經存好的 抗腫瘤壞死因子製劑
            (anti-TNF biologics) ，以後可以重複利用這個 concept 到不同的搜尋裡
          </div>
          <div class="next-button mt-10px" @click="clickNext">Next</div>
        </div>
      </div>
    </div>
    <div class="saved-hint" v-if="savedHint">✓ Saved</div>
  </div>
</template>
<script>
import InExcludeRender from '@/components/forms/InExcludeRender.vue';
import SavedConcept from '@/components/forms/SavedConcept.vue';
import LeftEMRSearch from '@/components/forms/LeftEMRSearch.vue';
import FormMixin from '@/mixins/formMixin';
import { ClickMixin } from '@/mixins';
import { mapState, mapMutations } from '@/store';
import { VUE_EVENT_NAME, EMR_SEARCH_TEXT } from '@/utils/constants';
import { DiagnosisRanges, DiagnosisType } from '@/utils/conditions/core';
import ConditionList from '@/utils/ConditionList';
import { cloneDeep } from 'lodash';

const LINK_SAVED_WAIT = 3000; // 3 seconds

export default {
  name: 'EMRSearch',
  mixins: [FormMixin, ClickMixin],
  components: {
    InExcludeRender,
    SavedConcept,
    LeftEMRSearch,
  },
  computed: {
    ...mapState('condition', ['conditions', 'excludedConditions', 'inPatient', 'outPatient']),
    ...mapState(['EMRSearchLandingIndex']),
  },
  data() {
    return {
      savedHint: false,
      savedTextTimeout: null,
      top: 0,
      left: 0,
      width: 0,
      searchTop: 0,
      searchLeft: 0,
      searchWidth: 0,
    };
  },
  watch: {
    EMRSearchLandingIndex() {
      this.$nextTick(() => {
        this.setPosition();
      });
    },
  },
  mounted() {
    this.$root.$on(VUE_EVENT_NAME.SET_EMR_SEARCH_SAVED_HINT_VALUE, (value) => {
      this.setSavedHint(value);

      clearTimeout(this.savedTextTimeout);
      this.savedTextTimeout = setTimeout(() => {
        this.setSavedHint(false);
      }, LINK_SAVED_WAIT);
    });

    if (this.conditions.length === 0) {
      this.clickClearAll();
    }
  },
  destroyed() {
    this.$root.$off(VUE_EVENT_NAME.SET_EMR_SEARCH_SAVED_HINT_VALUE);
  },
  methods: {
    ...mapMutations('condition', ['setCondition', 'setExcludedCondition']),
    ...mapMutations(['setEMRSearchLandingIndex']),
    setSavedHint(value) {
      this.savedHint = value;
    },
    clickClearAll() {
      const diagnosis = cloneDeep(ConditionList.diagnosis);
      diagnosis.tags.push(DiagnosisRanges.ALL);
      diagnosis.tags.push(DiagnosisType.GroupAndMap);
      diagnosis.diagnosisRange = false;

      this.setCondition([
        { ...ConditionList.time },
        {
          conceptName: `${EMR_SEARCH_TEXT.CRITERIA} 1`,
          conceptType: 'include',
          index: 0,
          ...diagnosis,
          time: Date.now(),
        },
      ]);
      this.setExcludedCondition([]);
      this.trackClick('clickClearAll');
    },
    clickNext() {
      this.$refs.InExcludeRender.addInclude();
      this.setEMRSearchLandingIndex(5);
    },
    clickNext7() {
      this.setEMRSearchLandingIndex(8);
    },
    setPosition() {
      if (this.EMRSearchLandingIndex === 7) {
        const offsets = document.getElementById('emr-search-button').getBoundingClientRect();
        const { top } = offsets;
        const { left } = offsets;
        const { width } = offsets;
        this.searchTop = top;
        this.searchLeft = left;
        this.searchWidth = width;
      } else if (this.EMRSearchLandingIndex === 4) {
        const offsets = document.getElementById('right-SavedConcept').getBoundingClientRect();
        const { top } = offsets;
        const { left } = offsets;
        const { width } = offsets;
        this.top = top;
        this.left = left;
        this.width = width;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.left-div {
  width: 292px;
  max-width: 292px;
  min-width: 292px;
  min-height: calc(100vh - 106px);
  max-height: calc(100vh - 106px);
  height: calc(100vh - 106px);
  padding: 20px;
  overflow-y: auto;
}

.center-div {
  width: calc(100% - 592px);
  max-width: calc(100% - 592px);
  min-width: calc(100% - 592px);
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  min-height: calc(100vh - 106px);
  max-height: calc(100vh - 106px);
  height: calc(100vh - 106px);
  padding: 40px 40px 550px 40px;
  overflow-y: auto;
}

.right-div {
  width: 300px;
  max-width: 300px;
  min-width: 300px;
  min-height: calc(100vh - 106px);
  max-height: calc(100vh - 106px);
  height: calc(100vh - 106px);
  padding: 40px;
  overflow-y: auto;
}

.hint-div {
  padding: 12px 20px;
  background: #e6f2ff;
  border-radius: 12px;
}

.hint-text {
  font-size: 16px;
  line-height: 24px;
  color: #262626;
}

.search-button {
  cursor: pointer;
  padding: 12px 0px;
  background: #0074e6;
  border-radius: 8px;
  width: calc(50% - 120px);
  max-width: 222px;
}

.search-text {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}

.disable-button {
  opacity: 0.5;
  pointer-events: none;
}

.saved-hint {
  position: fixed;
  background: #059e24;
  border-radius: 8px;
  padding: 8px 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  bottom: 70px;
  left: calc(50% - 45px);
}

.clear-all-text {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #0074e6;
}

.landing-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}

.landing-tooltip {
  padding: 20px;
  width: 296px;
  margin-top: 27px;
  background: #ffffff;
  border-radius: 16px;
  position: absolute;
  top: 170px;
}

.landing-tooltip::before {
  content: '';
  position: absolute;
  display: block;
  width: 0px;
  -webkit-transform: translate(-50%, calc(-100% - 5px));
  -ms-transform: translate(-50%, calc(-100% - 5px));
  transform: translate(-50%, calc(-100% - 5px));
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-left: 12px solid #ffffff;
  right: -18px;
  margin-top: 160px;
}

.mini-title {
  color: #0074e6;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}

.landing-text {
  color: #212121;
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
}

.landing-content-text {
  color: #262626;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
}

.next-button {
  background: #0074e6;
  border-radius: 8px;
  width: 256px;
  padding: 12px 16px;
  color: #ffffff;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  cursor: pointer;
}

.landing-1-tooltip {
  padding: 20px;
  width: 434px;
  background: #ffffff;
  border-radius: 16px;
  position: absolute;
}

.landing-1-tooltip::before {
  content: '';
  position: absolute;
  display: block;
  width: 0px;
  -webkit-transform: translate(-50%, calc(-100% - 5px));
  -ms-transform: translate(-50%, calc(-100% - 5px));
  transform: translate(-50%, calc(-100% - 5px));
  left: 217px;
  bottom: -29px;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid #ffffff;
}
</style>
