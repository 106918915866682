import { ConditionFactory, ConditionName, RangeCondition, RangeData } from './core';

function getBMIQuery(ranges: RangeData[]): Record<string, unknown>[] {
  return ranges.map((range) => ({
    range: {
      BMI_FLOAT: {
        gte: range.start,
        lt: range.end,
      },
    },
  }));
}

export default class BMIDef extends RangeCondition {
  public getFilter(): Record<string, unknown> {
    return {
      bool: {
        [this.andOr]: getBMIQuery(this.ranges),
      },
    };
  }
}

ConditionFactory.register(BMIDef, ConditionName.BMI);
