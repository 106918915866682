import { ConditionData, DiagnosisRanges, DiagnosisType } from '@/utils/conditions/core';
import ConditionList from '@/utils/ConditionList';
import { EMR_SEARCH_TEXT } from '@/utils/constants';
import { cloneDeep } from 'lodash';

const diagnosis = cloneDeep(ConditionList.diagnosis);
diagnosis.tags.push(DiagnosisRanges.ALL);
diagnosis.tags.push(DiagnosisType.GroupAndMap);
diagnosis.diagnosisRange = false;

const conditionState = {
  conditions: [
    { ...ConditionList.time },
    {
      conceptName: `${EMR_SEARCH_TEXT.CRITERIA} 1`,
      conceptType: 'include',
      index: 0,
      ...diagnosis,
      time: Date.now(),
    },
  ] as Array<ConditionData>,
  extendConditions: [] as Array<ConditionData>,
  excludedConditions: [] as Array<ConditionData>,
  patient: 0,
  record: 0,
  inPatient: 0,
  inRecord: 0,
  outPatient: 0,
  outRecord: 0,
  totalPatient: 0,
  totalRecord: 0,
  risk: 0,
  formDiagnosisRange: DiagnosisRanges.ALL,
  loaded: true,
  labSelect: 'Blood',
  excludeType: 'Diagnosis',
  onlyMyPatient: false,
  currentMenu: '',
};
type ConditionState = typeof conditionState;

export default {
  namespaced: true,
  state: conditionState,
  mutations: {
    resetCondition(state: ConditionState) {
      state.conditions = [];
      state.extendConditions = [];
      state.excludedConditions = [];
      state.patient = 0;
      state.record = 0;
      state.outPatient = 0;
      state.outRecord = 0;
      state.totalPatient = 0;
      state.totalRecord = 0;
      state.excludeType = 'Diagnosis';
    },
    setExcludedCondition(state: ConditionState, conditions: Array<ConditionData>) {
      state.excludedConditions = conditions;
    },
    setExtendCondition(state: ConditionState, conditions: Array<ConditionData>) {
      state.extendConditions = conditions;
    },
    setCondition(state: ConditionState, conditions: Array<ConditionData>) {
      state.conditions = conditions;
    },
    setPatient(state: ConditionState, patient: number) {
      state.patient = patient;
    },
    setRecord(state: ConditionState, record: number) {
      state.record = record;
    },
    setInPatient(state: ConditionState, inPatient: number) {
      state.inPatient = inPatient;
    },
    setInRecord(state: ConditionState, inRecord: number) {
      state.inRecord = inRecord;
    },
    setOutPatient(state: ConditionState, outPatient: number) {
      state.outPatient = outPatient;
    },
    setOutRecord(state: ConditionState, outRecord: number) {
      state.outRecord = outRecord;
    },
    setTotalPatient(state: ConditionState, totalPatient: number) {
      state.totalPatient = totalPatient;
    },
    setTotalRecord(state: ConditionState, totalRecord: number) {
      state.totalRecord = totalRecord;
    },
    setRisk(state: ConditionState, risk: number) {
      state.risk = risk;
    },
    setLoaded(state: ConditionState, loaded: boolean) {
      state.loaded = loaded;
    },
    setLabselect(state: ConditionState, labSelect: string) {
      state.labSelect = labSelect;
    },
    setExcludeType(state: ConditionState, excludeType: string) {
      state.excludeType = excludeType;
    },
    resetCountNum(state: ConditionState) {
      state.patient = 0;
      state.record = 0;
      state.inPatient = 0;
      state.inRecord = 0;
      state.outPatient = 0;
      state.outRecord = 0;
      state.totalPatient = 0;
      state.totalRecord = 0;
    },
    setFormDiagnosisRange(state: ConditionState, type: DiagnosisRanges) {
      state.formDiagnosisRange = type;
    },
    setOnlyMyPatient(state: ConditionState, onlyMyPatient: boolean) {
      state.onlyMyPatient = onlyMyPatient;
    },
    setMenuPath(state: ConditionState, { path, menuName }: any) {
      state.currentMenu = `${path}/${menuName}`;
    },
  },
};
