import TimeRelationEvent from '@/utils/conditions/core/advance/TimeRelationEvent';
import IndexEvent from '@/utils/conditions/core/advance/IndexEvent';
import TimePeriod from '@/utils/conditions/core/advance/TimePeriod';
import { IAdditionalConditionParam, ISearchState } from '@/utils/conditions/core/Interface';
import IndexEventQuery from './IndexEventQuery';
import TimeEventQuery from './TimeEventQuery';
import TimePeriodQuery from './TimePeriodQuery';
import CovariantQuery from './CovariantQuery';
import GenderQuery from './GenderQuery';
import { cloneDeep } from 'lodash';
import InnerhitsQuery from './InnerhitsQuery';
import EventHistoryQuery from './EventHistoryQuery';
import ConstraintName from '@/utils/conditions/core/advance/constraint/Interface';

class SearchStateQuery {
  private searchState: ISearchState;

  private additionalCondition: IAdditionalConditionParam;

  public covariantList: any[] = [];

  public innerhitsList: any[] = [];

  public medicationConstraints: any[] = [];

  constructor(searchState: ISearchState, additionCondition: IAdditionalConditionParam) {
    this.searchState = searchState;
    this.additionalCondition = additionCondition;
    this.medicationConstraints = this.filterMedicationConstraints(searchState);
  }

  postProcessCovariant(covariates: any[]) {
    const newConvariantList = cloneDeep(covariates);
    newConvariantList.forEach((cov: { condition: any[]; name: string }) => {
      const labConditions = cov.condition.filter((covCondition: { name: string }) => {
        return covCondition.name === 'Lab';
      });
      if (labConditions.length > 0) {
        const extendCov = cloneDeep(cov);
        extendCov.name = cov.name + '_extend';
        extendCov.condition.forEach((extendCovCond: { name: string; list: { end: string; start: string }[] }) => {
          if (extendCovCond.name === 'Lab') {
            extendCovCond.list.forEach((extendCovCondList: { end: string; start: string }) => {
              extendCovCondList.end = '';
              extendCovCondList.start = '';
            });
          }
        });
        newConvariantList.push(extendCov);
      }
    });
    return newConvariantList;
  }

  removeMedicationConstraint(event: any[]) {
    const newEvent = [];
    for (const i in event) {
      if (
        event[i].constraintList.some((temp: any) => temp.constraintType === 'MedicationTotalConstraint') &&
        event[i].condition.length > 1
      ) {
        const tmp_event = cloneDeep(event[i]);
        tmp_event.condition = event[i].condition.filter((item: any) => item.name !== 'Medication');
        if (!tmp_event['inclusionList']) {
          tmp_event.inclusionList = [];
        }
        if (!tmp_event['exclusionList']) {
          tmp_event.exclusionList = [];
        }
        tmp_event.name = tmp_event.name + '_check';
        newEvent.push(tmp_event);
      }
    }
    return newEvent;
  }

  setCovariant(covariates: any[]) {
    this.covariantList = this.postProcessCovariant(covariates);
  }

  searchMedicationTotalConstraintInList(list: any[]) {
    list.forEach((element) => {
      const medicationConstraint = element.constraintList.filter(
        (item: any) => item.constraintType === 'MedicationTotalConstraint'
      );
      if (medicationConstraint.length > 0) {
        this.medicationConstraints.push(...medicationConstraint);
      }
    });
  }

  searchMedicationTotalConstraintInEvent(event: any) {
    const medicationConstraint = event.constraintList.filter(
      (item: any) => item.constraintType === 'MedicationTotalConstraint'
    );
    if (medicationConstraint.length > 0) {
      this.medicationConstraints.push(...medicationConstraint);
    }
    this.searchMedicationTotalConstraintInList(event.inclusionList);
    this.searchMedicationTotalConstraintInList(event.exclusionList);
  }

  filterMedicationConstraints(searchObject: any) {
    for (const item of searchObject.eventList) {
      this.searchMedicationTotalConstraintInEvent(item);
    }
    this.searchMedicationTotalConstraintInEvent(searchObject.indexEvent);
    return this.medicationConstraints;
  }

  getBasicDummyQuery() {
    return [
      {
        exists: {
          field: 'CHART_NO',
        },
      },
    ];
  }

  dummyQueryTemplate(value: any): any {
    return {
      bool: {
        should: [
          {
            term: {
              CHART_NO: {
                value,
              },
            },
          },
        ],
      },
    };
  }

  formatDummyQuery(dummyQuery: any[]) {
    return dummyQuery.length > 1
      ? [
          {
            bool: {
              should: dummyQuery,
            },
          },
        ]
      : [];
  }

  genMedicationDummyQuery() {
    const dummyQuery = this.getBasicDummyQuery();
    this.medicationConstraints.forEach((element) => {
      const dummyValue = `${element.operator}-${element.timeUnit}-${element.drugDay}-${element.timeRange}`;
      dummyQuery.push(this.dummyQueryTemplate(dummyValue));
    });
    return this.formatDummyQuery(dummyQuery);
  }

  getMedicationPrescriptionTimesDummyQuery() {
    const dummyQuery = this.getBasicDummyQuery();
    this.searchState.indexEvent.constraintList.forEach((element) => {
      if (element.constraintType === ConstraintName.MedicationPrescriptionTimesConstraint) {
        const constraint = element as any;
        const dummyValue = `${constraint.constraintType}-${constraint.operator}-${constraint.timeUnit}-${constraint.prescriptionTimes}-${constraint.timeRange}`;
        dummyQuery.push(this.dummyQueryTemplate(dummyValue));
      }
    });
    return this.formatDummyQuery(dummyQuery);
  }

  getMedicationDoseTimesDummyQuery() {
    const dummyQuery = this.getBasicDummyQuery();
    this.searchState.indexEvent.constraintList.forEach((element) => {
      if (element.constraintType === ConstraintName.MedicationDoseTimesConstraint) {
        const constraint = element as any;
        const dummyValue = `${constraint.constraintType}-${constraint.operator}-${constraint.timeUnit}-${constraint.drugDay}-${constraint.timeRange}`;
        dummyQuery.push(this.dummyQueryTemplate(dummyValue));
      }
    });
    return this.formatDummyQuery(dummyQuery);
  }

  getDiagnosisRepeatedDummyQuery() {
    const dummyQuery = this.getBasicDummyQuery();
    this.searchState.indexEvent.constraintList.forEach((element) => {
      if (element.constraintType === ConstraintName.DiagnosisRepeatedTimesConstraint) {
        const constraint = element as any;
        const dummyValue = `${constraint.constraintType}-${constraint.repeatedTimes}-${constraint.operator}-${constraint.timeRange}-${constraint.timeUnit}`;
        dummyQuery.push(this.dummyQueryTemplate(dummyValue));
      }
    });
    return this.formatDummyQuery(dummyQuery);
  }

  setInnerhits(searchObject: any, covariates: any[]) {
    const newCovariates = this.removeMedicationConstraint(this.postProcessCovariant(covariates));
    const newInclusions = this.removeMedicationConstraint(
      this.postProcessCovariant(searchObject.indexEvent.inclusionList)
    );
    const newIndexevents = [];
    if (
      searchObject.indexEvent.constraintList.some((temp: any) => temp.constraintType === 'MedicationTotalConstraint') &&
      searchObject.indexEvent.condition.length > 1
    ) {
      newIndexevents.push(cloneDeep(searchObject.indexEvent));
      newIndexevents[0].condition = searchObject.indexEvent.condition.filter((item: any) => item.name !== 'Medication');
      newIndexevents[0].name = newIndexevents[0].name + '_check';
    }
    if (newIndexevents.length > 0 && !newIndexevents[0].relation) {
      newIndexevents[0].relation = {
        beforeValue: 'any',
        beforeUnit: 'day(s)',
        afterValue: 'any',
        afterUnit: 'day(s)',
      };
    }
    this.innerhitsList = [...newCovariates, ...newInclusions, ...newIndexevents];
  }

  getQuery(): Record<string, unknown> {
    const timePeriod = new TimePeriod(this.searchState.timePeriod);

    const timePeriodQueryList = [];
    const localTimePeriod = this.searchState.indexEvent.constraintList.filter(
      (constraint) => constraint.constraintType === 'DataRangeConstraint'
    );
    if (localTimePeriod.length > 0) {
      const localTimePeriodInstance = new TimePeriod(localTimePeriod[0]);
      const localTimePeriodQuery = new TimePeriodQuery(localTimePeriodInstance, false).getQuery();
      timePeriodQueryList.push(localTimePeriodQuery);
    }

    const indexEvent = new IndexEvent(this.searchState.indexEvent);
    if (this.additionalCondition.isFirstTime) {
      indexEvent.setFirstTime();
    }
    // eslint-disable-next-line max-len
    const indexEventQuery = indexEvent.hasConditions ? new IndexEventQuery(indexEvent, timePeriod).getQuery() : [];
    const genderQuery =
      this.searchState.genderList.length === 2 ? [] : new GenderQuery(this.searchState.genderList).getQuery();
    const timeEventQueryList = this.searchState.eventList
      .filter((event) => event.condition.length > 0)
      .map((event) => new TimeEventQuery(new TimeRelationEvent(event), timePeriod, event.eventStage).getQuery());
    const covariantQuery = new CovariantQuery(this.covariantList, timePeriod).getQuery();
    const innerhitsQuery = new InnerhitsQuery(this.innerhitsList, timePeriod).getQuery();
    const deathCondition = [];
    if (this.additionalCondition.isDeath) {
      deathCondition.push({
        exists: {
          field: 'DEATH_DATE',
        },
      });
    }
    const medicationDummyQuery = this.genMedicationDummyQuery();
    const diagnosisRepeatedDummyQuery = this.getDiagnosisRepeatedDummyQuery();
    const medicationPrescriptionTimesDummyQuery = this.getMedicationPrescriptionTimesDummyQuery();
    const medicationDoseTimesDummyQuery = this.getMedicationDoseTimesDummyQuery();

    const eventHistoryQuery = indexEvent.hasConditions ? new EventHistoryQuery(indexEvent).getQuery() : [];

    return {
      query: {
        bool: {
          filter: [
            ...eventHistoryQuery,
            ...indexEventQuery,
            ...genderQuery,
            ...deathCondition,
            ...timeEventQueryList,
            ...covariantQuery,
            ...innerhitsQuery,
            ...medicationDummyQuery,
            ...timePeriodQueryList,
            ...diagnosisRepeatedDummyQuery,
            ...medicationPrescriptionTimesDummyQuery,
            ...medicationDoseTimesDummyQuery,
          ],
        },
      },
    };
  }
}

export default SearchStateQuery;
