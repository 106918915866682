import { IIndexEvent, ITailTimeRelationEvent } from '../Interface';
import BaseMedicalEvent from './BaseMedicalEvent';
import TailTimeRelationEvent from './TailTimeRelationEvent';

class IndexEvent extends BaseMedicalEvent implements IIndexEvent {
  readonly inclusionList: ITailTimeRelationEvent[];

  readonly exclusionList: ITailTimeRelationEvent[];

  constructor(indexEvent: IIndexEvent) {
    super(indexEvent);
    this.inclusionList = indexEvent.inclusionList.map((event) => new TailTimeRelationEvent(event));
    this.exclusionList = indexEvent.exclusionList.map((event) => new TailTimeRelationEvent(event));
  }

  public setFirstTime(): void {
    this.constraint.firstOccurrence = true;
  }

  public get hasConditions(): boolean {
    return this.condition.length > 0;
  }
}

export default IndexEvent;
