// eslint-disable-next-line no-unused-vars
export default function getLabHistogramInfoAggsQuery(conditionQuery, CHSPECI, CHHEAD, UNIT) {
  const aggsQuery = {
    aggs: {
      path1: {
        nested: {
          path: 'FIRST_LAB',
        },
        aggs: {
          path_lab: {
            filters: {
              filters: [
                {
                  bool: {
                    filter: [
                      {
                        match: {
                          'FIRST_LAB.CHSPECI': CHSPECI,
                        },
                      },
                      {
                        match: {
                          'FIRST_LAB.CHHEAD': CHHEAD,
                        },
                      },
                      {
                        match: {
                          'FIRST_LAB.UNIT': UNIT,
                        },
                      },
                      {
                        match: {
                          'FIRST_LAB.is_number': true,
                        },
                      },
                    ],
                  },
                },
              ],
            },
            aggs: {
              path3: {
                percentiles: {
                  field: 'FIRST_LAB.VALUE',
                  percents: [0, 100],
                },
              },
              path4: {
                extended_stats: {
                  field: 'FIRST_LAB.VALUE',
                },
              },
            },
          },
        },
      },
    },
  };
  return {
    ...conditionQuery,
    ...aggsQuery,
    size: 0,
    track_total_hits: true,
  };
}
