















































































import BasicChartConfig from '@/components/charts/core/BasicChartConfig';
import { ChartOption, ChartViewState, RawItem } from '@/components/charts/core/Interface';
import { ChartKey } from '@/components/charts/utils/Chart';
import trackTemplate from '@/mixins/trackTemplate';
import getConditionDef from '@/utils/getConditionDef';
import { preloadChart } from '@/utils/preloadCharts';
import { isEqual, xorWith } from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { mapMutations } from 'vuex';
import { FEEDBACK, PAGES, PRECISION_THRESHOLD, UICONFIG, VUE_EVENT_NAME } from '@/utils/constants';
import { ClickMixin, UpdateMixin } from '../../../mixins';
import { mapState } from '../../../store';
import { TabContent } from '../../../store/session/Session';
import ApiHelper from '../../../utils/ApiHelper';
import { DiagnosisRanges } from '../../../utils/conditions/core';

declare module 'vue/types/vue' {
  export interface Vue {
    $api: ApiHelper;
    // Subsequent property declarations must have the same type.
    // Property 'tabs' must be of type 'TabContent[]', but here has type 'any[]'.Vetur(2717)
    tabs: any[];
    userId: string;
    update(params: any): any;
    trackClick(name: string, params: any): any;
    updateTab(params: { index: number } & Partial<TabContent>): void;
    trackContentTemplate(): any;
  }
}

@Component<Chart>({
  name: 'Chart',
  mixins: [Vue.extend(ClickMixin), Vue.extend(UpdateMixin), Vue.extend(trackTemplate)],
  computed: {
    ...mapState('user', ['userId']),
    ...mapState(['tabName', 'medicalOrderCategory']),
    ...mapState('session', ['tabs']),
  },
  methods: {
    ...mapMutations('condition', ['setLoaded']),
    ...mapMutations('session', ['updateTab']),
  },
})
export default class Chart extends Vue {
  @Prop(Object) config!: BasicChartConfig<RawItem>;

  @Prop({ type: Boolean, default: false }) expanded!: boolean;

  @Prop({ type: Number, default: 0 }) uniqueIndex!: number;

  @Prop({ type: Boolean, default: true }) parentLoading!: boolean;

  private readonly tabName!: string;

  private medicalOrderCategory: any;

  private medicalOrderOption!: string[];

  private viewAllHeight = 325;

  private total = 0;

  private patient = 0;

  private option: ChartOption = { hasData: true };

  private getQuery: any;

  private expandLoading = false;

  private summaryLoading = false;

  private toggle = false;

  private get tab() {
    return this.tabs[this.uniqueIndex];
  }

  private get showViewMore() {
    return this.config.canViewAll && !this.config.isNoDataChart && this.hasData && !this.expanded;
  }

  private get conditionState() {
    return JSON.stringify([
      this.tab.includes,
      this.tab.excludes,
      this.tab.data.sortChartByAI,
      this.tab.data.bucketByPatient,
    ]);
  }

  private get showSelection(): boolean {
    return [ChartKey.ICD10, ChartKey.ICD9].includes(this.chartKey);
  }

  private get showSelectionMedicalOrder(): boolean {
    return this.chartKey === ChartKey.MedicalOrder;
  }

  onMouseEnter(): void {
    if (this.config.canViewAll) {
      const viewAllConfig = this.config.createViewAllConfig();
      preloadChart(
        viewAllConfig,
        {
          includes: getConditionDef(this.tab.includes),
          excludes: getConditionDef(this.tab.excludes),
          chartParams: {
            viewState: ChartViewState.Maximize,
            selection: this.selectedType,
          },
        },
        this.$api,
        this.userId,
        this.trackContentTemplate()
      );
    }
  }

  getOption(option: { series: any; xAxis: any; yAxis: any; tooltip: any }) {
    let cpOption = { ...option };
    // Avoid changing the original option
    // Items that need to be changed about xAxis.show, yAxis.show, tooltip.axisPointer.type
    if (this.showNoResult) {
      cpOption = {
        ...option,
        series: [],
        xAxis: {
          ...option.xAxis,
          show: false,
        },
        yAxis: {
          ...option.yAxis,
          show: false,
        },
        tooltip: {
          ...option.tooltip,
          axisPointer: {
            type: 'none',
          },
        },
      };
    }
    return cpOption;
  }

  checkMedicalOrderOption(data: string): boolean {
    if (this.medicalOrderOption) {
      return !this.medicalOrderOption.includes(data);
    }
    return true;
  }

  private get showNoResult(): boolean {
    if (this.expanded) {
      return false;
    }
    const isMedicalOrder = this.config.chartKey === ChartKey.MedicalOrder;
    return !this.hasData || (isMedicalOrder && this.checkMedicalOrderOption(this.selectedType) && !this.parentLoading);
  }

  @Watch('conditionState')
  private onConditionStateChange() {
    this.summaryLoading = true;
    if (this.config.isBucketByPatient !== this.tab.data.bucketByPatient && !this.config.bucketChangeDisallowed) {
      this.config.setBucketByPatient(this.tab.data.bucketByPatient);
    }
    this.fetchChartData();
  }

  private get hasData(): boolean {
    if (!this.option) {
      return false;
    }
    return this.option.hasData;
  }

  private get hint(): string {
    return this.config.hint;
  }

  private get chartKey(): ChartKey {
    return this.config.chartKey;
  }

  get selectedType(): string {
    switch (this.chartKey) {
      case ChartKey.ICD10:
        return this.tab.chartParams.ICD10.diagnosisRange;
      case ChartKey.ICD9:
        return this.tab.chartParams.ICD9.diagnosisRange;
      case ChartKey.MedicalOrder:
        return this.tab.chartParams.MedicalOrder.category;
      default:
        return '';
    }
  }

  get basicFilterOptions(): { value: string; label: string }[] {
    return [
      { value: 'all', label: 'Any' },
      { value: 'primary', label: 'Primary' },
      { value: 'top-5', label: 'Top 5' },
    ];
  }

  mounted(): void {
    if (this.expanded) {
      this.expandLoading = true;
    } else {
      this.summaryLoading = true;
      this.$root.$on(VUE_EVENT_NAME.FETCH_CHART_DATA, (type: ChartKey) => {
        if (type === this.chartKey) {
          this.fetchChartData();
        }
      });
    }
    this.fetchChartData();
  }

  destroyed(): void {
    this.$root.$off(VUE_EVENT_NAME.FETCH_CHART_DATA);
  }

  handleChangeIcdLength(value: string): void {
    let diagnosisRange;
    switch (value) {
      case 'primary':
        diagnosisRange = DiagnosisRanges.Primary;
        break;
      case 'all':
        diagnosisRange = DiagnosisRanges.ALL;
        break;
      case 'top-5':
        diagnosisRange = DiagnosisRanges.Top5;
        break;
      default:
        throw Error(`Not defined selection type: ${value}`);
    }
    this.updateTab({
      index: this.uniqueIndex,
      chartParams: {
        ...this.tab.chartParams,
        [this.chartKey]: {
          diagnosisRange,
        },
      },
    });
    this.fetchChartData().then(() => {
      this.emitFetchDataIfExpanded();
    });
    this.trackClick('handleChangeIcdLength', { value, title: this.chartKey });
  }

  handleChangeMedicalOrder(value: string): void {
    this.updateTab({
      index: this.uniqueIndex,
      chartParams: {
        ...this.tab.chartParams,
        [this.chartKey]: {
          category: value,
        },
      },
    });
    this.fetchChartData().then(() => {
      this.emitFetchDataIfExpanded();
    });
    this.trackClick('handleChangeMedicalOrder', { value, title: this.chartKey });
  }

  emitFetchDataIfExpanded(): void {
    if (this.expanded) {
      this.$root.$emit(VUE_EVENT_NAME.FETCH_CHART_DATA, this.chartKey);
    }
  }

  get chartExpandedStyle(): Record<string, unknown> {
    const style: any = {};

    if (this.expanded && this.config.chartDirection === 'vertical') {
      const height = Math.max(325, this.config.count * 32);
      style.height = `${height}px`;
      style.width = '1200px';
    }

    if (this.expanded && this.config.chartKey === ChartKey.Stay) {
      style.height = `${500}px`;
    }

    return style;
  }

  async onClick(event: any): Promise<void> {
    const label = event.componentType === 'series' ? event.data.key : event.value;
    if (label === '10+') {
      this.onViewAll();
      return;
    }

    const newExtendConditions = [this.config.createCondition(label)];
    this.update({
      includes: xorWith(this.tab.includes, newExtendConditions, isEqual),
      excludes: this.tab.excludes,
      uniqueIndex: this.uniqueIndex,
    });
    this.trackClick('clickData', {
      title: this.config.chartKey,
      name: label,
      type: 'chart',
      IcdLengthType:
        this.chartKey === ChartKey.ICD10
          ? this.tab.chartParams.ICD10.diagnosisRange
          : this.tab.chartParams.ICD9.diagnosisRange,
    });
  }

  private onViewAll() {
    const viewAllConfig = this.config.createViewAllConfig();
    this.$emit('clickViewAll', this.config.chartKey, viewAllConfig);
    this.trackClick('clickViewAll', {
      title: this.config.chartKey,
      viewAll: true,
      type: 'chart',
    });
  }

  async fetchChartData(): Promise<void> {
    if (this.config.chartKey === ChartKey.MedicalOrder) {
      await this.fetchMedicalOrderOption().catch((error) => {
        console.error(error);
      });
    }

    this.$emit('lastChart', this.config.chartKey);
    this.$api.setLogQueryInfo({
      type: FEEDBACK.QUERY_TYPE.CHART_AGGREGATE,
      conditions: this.$store.getters['condition/currentConditions'],
    });

    const conditions = this.tab.includes;
    const excludedConditions = this.tab.excludes;

    const chartParamsData = {
      includes: getConditionDef(conditions),
      excludes: getConditionDef(excludedConditions),
      sortChartByAI: this.tab.data.sortChartByAI,
      bucketByPatient: this.tab.data.bucketByPatient,
      chartParams: {
        viewState: this.expanded ? ChartViewState.Maximize : ChartViewState.Normal,
        selection: this.selectedType,
      },
    };

    if (this.chartKey === ChartKey.Readmission) {
      chartParamsData.chartParams.selection = this.tab.chartParams.Readmission.standard;
    }

    this.option = await this.config
      .applyData(
        chartParamsData,
        this.$api,
        this.userId,
        this.trackContentTemplate(),
        this.tab.data.analyzeTab === PAGES.STATISTIC_SUMMARY ? 1 : 0
      )
      .catch();
    this.expandLoading = false;
    this.summaryLoading = false;
  }

  async fetchMedicalOrderOption(): Promise<void> {
    const query = {
      ...this.getQuery({
        includes: this.tab.includes,
        excludes: this.tab.excludes,
      }),
      aggs: {
        result: {
          terms: {
            field: 'ORDER_CATEGORY',
            size: 50,
            shard_size: UICONFIG.SHARD_SIZE,
            order: {
              count_unique_patient: 'desc',
            },
          },
          aggs: {
            count_unique_patient: {
              cardinality: {
                field: 'CHART_NO',
                precision_threshold: PRECISION_THRESHOLD,
              },
            },
          },
        },
        patientNum: {
          cardinality: {
            field: 'CHART_NO',
            precision_threshold: PRECISION_THRESHOLD,
          },
        },
      },
      size: 0,
      track_total_hits: true,
      comp: 'ALL',
    };
    const priority = this.tab.data.analyzeTab === PAGES.STATISTIC_SUMMARY ? 1 : 0;
    const result = await this.$api.search(query, priority).catch((error) => {
      console.error(error);
    });
    this.mapMedicalOrder(result);
  }

  mapMedicalOrder(data: any) {
    this.medicalOrderOption = data.aggregations.result.buckets.map((item: { key: any }) => item.key);
  }

  private get isAdvanceSearch(): boolean {
    return Object.keys(this.tab).includes('SearchStateObject');
  }
}
