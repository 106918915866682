import { SearchState } from '@/utils/conditions/core/advance';
import { InExclusionList } from '@/utils/conditions/core/Interface';
import { cloneDeep } from 'lodash';

export default class Indicator {
  index: number;
  name: string;
  mode: string;
  facet: string;
  description: string;
  numerator: { SearchStateObject: SearchState };
  denominator: { SearchStateObject: SearchState };
  cohort: { SearchStateObject: SearchState };

  partialNumerator: {
    indexEvent: InExclusionList;
  };

  partialDenominator: {
    indexEvent: InExclusionList;
  };

  constructor(indicator: any, cohort: any) {
    const {
      partialNumerator: { SearchStateObject: partialNumerator },
      partialDenominator: { SearchStateObject: partialDenominator },
    } = indicator;

    this.index = indicator.index;
    this.name = indicator.name;
    this.mode = indicator.mode;
    this.facet = indicator.facet;
    this.description = indicator.description;
    this.partialNumerator = partialNumerator;
    this.partialDenominator = partialDenominator;
    this.cohort = cohort;

    const {
      SearchStateObject: { indexEvent, timePeriod, eventList },
    } = cohort;

    const denominatorIndexEvent = cloneDeep(indexEvent);

    denominatorIndexEvent.inclusionList = denominatorIndexEvent.inclusionList.concat(
      partialDenominator.indexEvent.inclusionList
    );
    denominatorIndexEvent.exclusionList = denominatorIndexEvent.exclusionList.concat(
      partialDenominator.indexEvent.exclusionList
    );

    this.denominator = {
      SearchStateObject: new SearchState(eventList, denominatorIndexEvent, timePeriod),
    };

    const numeratorIndexEvent = cloneDeep(indexEvent);

    numeratorIndexEvent.inclusionList = numeratorIndexEvent.inclusionList.concat(
      partialDenominator.indexEvent.inclusionList,
      partialNumerator.indexEvent.inclusionList
    );
    numeratorIndexEvent.exclusionList = numeratorIndexEvent.exclusionList.concat(
      partialDenominator.indexEvent.exclusionList,
      partialNumerator.indexEvent.exclusionList
    );

    this.numerator = {
      SearchStateObject: new SearchState(eventList, numeratorIndexEvent, timePeriod),
    };
  }
}
