export default function getPatientListAggsQuery(conditionQuery, size = 100) {
  const aggsQuery = {
    aggs: {
      patientNum: {
        terms: {
          field: 'CHART_NO',
          size,
          order: {
            _key: 'asc',
          },
        },
      },
    },
  };
  return {
    ...conditionQuery,
    ...aggsQuery,
    size: 0,
    track_total_hits: true,
  };
}
