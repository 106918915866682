<template>
  <div id="clear-all-filter">
    <a-row>
      <a-col>
        <a-row type="flex" justify="center" align="top" style="margin-top: 50px">
          <a-col>
            <b-img src="@/assets/images/no_results.svg" />
          </a-col>
        </a-row>
        <a-row type="flex" justify="center" align="top" style="margin-top: 10px">
          <a-col class="no-results-text"> No results </a-col>
        </a-row>
        <a-row type="flex" justify="center" align="top" style="margin-top: 50px">
          <a-col>
            <button class="clear-button" @click="clearExtendCondition">Clear all filters</button>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import { UpdateMixin, ClickMixin } from '@/mixins';
import { SearchType } from '@/utils/conditions/core';
import { mapState } from '../../../../store';

export default {
  name: 'ClearExtendFilter',
  mixins: [UpdateMixin, ClickMixin],
  computed: {
    ...mapState('session', ['tabs']),
    tab() {
      return this.tabs[this.uniqueIndex];
    },
  },
  props: {
    uniqueIndex: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    clearExtendCondition() {
      this.trackClick('clearExtendCondition', { tab: this.tab });
      this.update({
        includes: this.tab.includes.filter((cond) => cond.tags.includes(SearchType.Raw)),
        excludes: this.tab.excludes,
        uniqueIndex: this.uniqueIndex,
      });
    },
  },
};
</script>
<style scoped>
#clear-all-filter {
  width: 276px;
  height: 266px;
}

.no-results-text {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #828282;
}

.clear-button {
  width: 256px;
  height: 44px;
  border-radius: 6px;
  border: solid 1px #333f6b;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333f6b;
  background-color: white;
}
</style>
