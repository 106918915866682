/* eslint-disable global-require */
module.exports = {
  SERVICE_CONFIG: [
    {
      CODE: 'med-14day',
      TITLE: '14 Days Unplanned Readmission',
      IMG: require('@/../public/img/navbar/portal_14d.svg'),
      URL: process.env.VUE_APP_URL_14D,
      INFO: '結合了Business Intelligence (BI) 與 Artificial Intelligence (AI)，提供清楚易懂的視覺化平台。BI 協助用戶了解現況及原因，AI 進一步預測風險以輔助院方減少再住院率。不需要分析與 IT 技能即可輕鬆上手',
    },
    {
      CODE: 'emr',
      TITLE: 'Lumos',
      IMG: require('@/../public/img/navbar/portal_emr.svg'),
      URL: process.env.VUE_APP_URL_EMR,
      INFO: '透過病歷結構化、AI 排序及 NER 等技術，提供簡單、容易上手及高互動式的電子化病歷查詢系統。適用於醫療人員做疾病相關研究前預先查詢，也能應用醫品管理、病人招募等場景',
    },
    {
      CODE: 'covid-dash',
      TITLE: 'COVID roster dashboard',
      IMG: require('@/../public/img/navbar/portal_covid_dash.svg'),
      URL: process.env.VUE_APP_URL_COVID_DASH,
      INFO: '顯示COVID-19 人手調配相關資訊，協助院內主管了解目前調派狀況，並精準調派人力支援COVID-19相關業務。',
    },
  ],
};
