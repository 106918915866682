export default {
  Blood: [
    'K',
    'Na',
    'WBC count (白血球計數)',
    'Hb (血色素)',
    'RBC count (紅血球計數)',
    'Hct (血球比容值)',
    'MCV (平均紅血球體積)',
    'MCH (平均紅血球血色素含量)',
    'MCHC (平均紅血球血色素濃度)',
    'RDW (紅血球體積分布寬度)',
    'MPV (平均血小板體積)',
    'Platelet count (血小板計數)',
    'Neutrophil',
    'Lymphocyte',
    'Monocyte',
    'Creatinine',
    'Eosinophil',
    'Estimated GFR',
    'Basophil',
    'Urea Nitrogen',
    'GPT (ALT)',
    'Blood culture',
    'Ca',
    'Hb',
    'GOT (AST)',
    'I.N.R.',
    'Bilirubin-T',
    'Mg',
    'APTT',
    'Normoblast',
    'Prothrombin time',
    'Albumin',
    'P',
    'Cell No. Counted',
    'CRP',
    'Absolute Neutrophil Count',
    'Bilirubin-D',
    'control',
    'Total protein',
    'A/G',
    'Lactate',
    'pH',
    'PCO2',
    'CPK',
    'Cl',
    'Metamyelocyte',
    'ALP',
    'HCO3',
    'Hct',
    'PO2',
    'Platelet count',
    'MPV',
    'CPK-MB mass',
    'Glucose (Random)',
    'Myelocyte',
    'Reactive lymphocyte',
    'Triglyceride',
    'Large granular lymphocyte',
    'Ionized Ca',
    'Cholesterol',
    'Fibrinogen',
    'γ-Glutamyltransferase',
    'Transferrin',
    'WBC count',
    'HbA1c',
    'BE-B',
    'BE-ECF',
    'Uric Acid',
    'TSH',
    'O2 Saturation',
    'Alveolar Oxygen',
    'AaDO2',
    'a/A',
    'Free T4',
    'PT',
    'Glucose',
    'Ionized Mg',
    'LDL-cholesterol',
    'HDL-cholesterol',
    'Cholesterol/HDL',
    'Procalcitonin',
    'Neutrophil-Band',
    'Anti-HCV S/CO',
    'Anti-HCV interpretation',
    'Estimated TIBC',
    'Medication time(m/d hh:mm)',
    'Glucose (PC)',
    'Base excess',
    'Troponin-I',
    'Ammonia',
    'HBsAg(screening) interpretation',
    'Cortisol (Blood, AM)',
    'Activated clotting time(ACT)',
    'Interpretation',
    'Prealbumin',
    '先天性甲狀腺低能症(TSH)',
    'LDH',
    'NT-proBNP',
    'pO2',
    'HBsAg(screening) S/CO',
    'Anti-Mycoplasma pneumoniae IgM',
    '高胱胺酸尿症(HCU)',
    '楓漿尿症(Leucine)',
    '楓漿尿症(Valine)',
    '異戊酸血症(IVA)',
    '苯酮尿症(PKU)',
    '中鏈醯輔酵素A去氫酵素缺乏症(C10)',
    '中鏈醯輔酵素A去氫酵素缺乏症(C6)',
    '中鏈醯輔酵素A去氫酵素缺乏症(C8)',
    '戊二酸血症第一型(GAI)',
    '甲基丙二酸血症(C3)',
    '先天性腎上腺增生症(CAH)',
    'Lipase',
    '半乳糖血症(GAL)',
    'HIV Ag/Ab combo test S/CO',
    'HIV Ag/Ab combo test interpretation',
    '葡萄糖六磷酸鹽去氫酵素缺乏症(G6PD)',
    'Amylase',
    'Estimated average glucose (eAG)',
    'Rapid plasma reagin (RPR)',
    'ESR',
    'D-dimer',
    'Tacrolimus (FK506)',
    'Glucose (AC)',
    'SCID-TREC',
    'Acid alpha-glucosidase(GAA)',
    'Alpha-galactosidase(α-GLA)',
    'Acid beta-glucosidase(ABG)',
    'Iduronidase, alpha-L-(IDUA) gene',
    'CEA',
    'Promyelocyte',
    '中鏈醯輔酵素A去氫酵素缺乏(C8/C10)',
    '罕見疾病先驅計畫',
    'Reactivity',
    'Neonatal screen-SMN1 gene',
    'Neonatal screen-Biotinidase activity',
    'C. pneumoniae IgM Interpretation',
    'Morphology finding',
    'Anti-HBs',
    'Osmolality',
    'Myoglobin',
    'C. pneumoniae IgM cutoff index',
    'Anti-Legionella Ab',
    'Zn',
    'Blast',
    'Ferritin',
    'Reticulocyte count ; Automated',
    'Iduronate 2-sulfatase(ID2S)',
    'Arylsulfatase B(ARSB) gene',
    'T3',
    'CA 19-9',
    'FSH',
    'LH',
    'ABO blood type',
    '26 LPC',
    'Fe',
    'Vit. B12',
    'Cortisol (Blood, PM)',
    'Severe combined immunodeficiency (SCID)',
    '龐貝氏症',
    '法布瑞氏症',
    '黏多醣症第 I 型',
    '黏多醣症第 II 型',
    '黏多醣症第 VI 型',
    '高雪氏症',
    'Alpha-fetoprotein',
    'Folate',
    '瓜胺酸血症第一型(CIT I)',
    '瓜胺酸血症第二型(CIT II)',
    '原發性肉鹼缺乏症(PCD)',
    '丙酸血症(PA)',
    '肉鹼棕櫚醯基轉移酵素缺乏症第一型(CPT I)',
    '甲基丙二酸血症(C3/C2)',
    '3羥基3甲基戊二酸尿症(HMG)',
    '全羧化酵素合成酵素缺乏症(HCSD)',
    '肉鹼棕櫚醯基轉移酵素缺乏症第二型(C16)',
    '肉鹼棕櫚醯基轉移酵素缺乏症第二型(C18)',
    '肉鹼棕櫚醯基轉移酵素缺乏症第二型(C18:1)',
    '極長鏈醯輔酵素Α去氫酵素缺乏症(C14)',
    '極長鏈醯輔酵素Α去氫酵素缺乏症(C14:1)',
    '戊二酸血症第二型(C4)',
    '戊二酸血症第二型(C5)',
    '戊二酸血症第二型(C5DC)',
    '戊二酸血症第二型(C8)',
    '戊二酸血症第二型(C12)',
    '戊二酸血症第二型(C14)',
    '戊二酸血症第二型(C16)',
    '戊二酸血症第二型(Score)',
    'Index',
    'Valproic acid',
    'Anisocytosis',
    'Macrocyte',
    'Microcyte',
    'Hypochromic cell',
    'Polychromatophilic RBC',
    'Acanthocyte',
    'Bite cell',
    'Blister cells/Prekeratocyte',
    'Echinocyte',
    'Ovalocyte',
    'Fragmented RBC',
    'Sickle cell',
    'Spherocytes',
    'Stomatocyte',
    'Target cell',
    'Teardrop cell',
    'Basophilic stippling',
    'Howell-Jolly body',
    'Pappenheimer body',
    'Dimorphic red cells',
    'RBC agglutinates',
    'Rouleaux',
    'CMV Viral load',
    'hs Troponin-T',
    'Anti-HBc IgG',
    'IgG',
    'Intact PTH',
    'GALNS gene',
    'Iduronate-2-sulfatase (ID2S)',
    'Biotinidase activity screening',
    'Arylsulfatase B (ARSB) gene',
    'C26LPC',
    'Neonatal screen-SMN1 gene screening',
    'Antibody identification',
    'Growth hormone',
    'Bilirubin-D/Bilirubin-T Ratio',
    'C3C',
    'C4',
    "Coombs' polyspecific test",
    'Cryptococcal Ag',
    'IgA',
    'hs-Troponin I',
    'Fibrin degradation product (D-dimer)',
    'Rh (D) type',
    'Screen cell I',
    'Screen cell II',
    'Screen cell III',
    'Screen cell IV',
    'IgM',
    '高胱胺酸尿症(Met)',
    '苯酮尿症(Phe)',
    '半乳糖血症(Galactose)',
    '先天性腎上腺增生症(17 OHP)',
    '楓漿尿病(Leucine)',
    '葡萄糖六磷酸鹽去氫?缺乏症(G6PD)',
    '楓漿尿病(Valine)',
    '中鏈醯輔  A去氫  缺乏症(C8)',
    '中鏈醯輔  A去氫  缺乏症(C6)',
    '中鏈醯輔  A去氫  缺乏症(C10)',
    '罕見先天性代謝疾病(篩檢研究)',
    'IgE',
    'Fine Speckled pattern',
    'HBsAg(screening) concentration',
    'CA 125',
    'Haptoglobin',
    'Prostate specific antigen (PSA)',
    'Anti-CMV IgM',
    'HBeAg S/CO',
    'HBeAg interpretation',
    'Vancomycin: Trough',
    'Glucose-6-phosphate dehydrogenase',
    'Squamous cell carcinoma antigen',
    "Direct Coombs' test (Polyspecific)",
    'Comment',
    'β-hydroxybutyrate (β-Ketone)',
    'Homogeneous pattern',
    'CA 15-3',
    'EBV VCA IgG',
    'Anti-CMV IgG',
    'Digoxin',
    'Estradiol (Pediatric, 適用年齡<18歲)',
    'Prolactin',
    'EBV NA IgG',
    'EBV VCA IgM',
    'Anti-amoeba Ab',
    'D. pteronyssinus (屋塵璊)',
    'Mold panel (黴菌)',
    'Food panel (食物)',
    'Thyroglobulin Ab',
    'UIBC',
    'TIBC',
    'Medication time',
    'Lithium (Li)',
    'One touch glucose (verification)',
    'Anti-HAV IgM interpretation',
    'Anti-HAV IgM S/CO',
    'Anti-cytoplasmic staining pattern',
    'RF-IgM',
    'D. farinae (粉塵璊)',
    'Dust-greer (家塵)',
    'hCG β-subunit',
    'Anti-SSA Interpretation',
    'Anti-SSB Interpretation',
    'Anti-SSA Reactivity',
    'Anti-SSB Reactivity',
    'Anti-TPO',
    'Heterophile Ab',
    "Direct Coombs' IgG monospecific test",
    'T. pallidum Ab confirmation (TPPA )',
    "Direct Coombs' C3d monospecific test",
    'EBV Viral load:IU/mL',
    'EBV Viral load:Log(IU/mL)',
    'EBV Viral load:copies/mL',
    'Trough',
    'HBV viral load (Log (IU/mL))',
    'HBV viral load (IU/mL)',
    'ACTH',
    'Thyroglobulin',
    "Direct Coombs' test",
    'Protein S function',
    'C. pneumoniae IgG Interpretation',
    'Protein C function',
    'Anti-HBe',
    'Anti-B titer (IgM)',
    'Anti-B titer (IgG)',
    'Anti-A titer (IgM)',
    'Anti-A titer (IgG)',
    'Estimated AG',
    'Antithrombin function',
    'dRVVT Screen',
    'dRVVT Confirm',
    'Normalized dRVVT Ratio',
    'SCT Screen',
    'SCT Confirm',
    'Normalized SCT Ratio',
    'Non-reactive',
    'C. pneumoniae IgG cutoff index',
    '引起輸血反應的可能原因',
    '與輸血之相關性',
    'T4',
    'Calculated-LDL',
    'Homocysteine',
    'Anti-TSH receptor Ab',
    'β2-microglobulin',
    'Vancomycin',
    'Anti-HAV IgG',
    'Other',
    '建議下次輸血',
    'Nuclear fine speckled(AC4)',
    'Theophylline',
    '嚴重度',
    'Cold agglutinin',
    "Coombs'  C3d monospecific test",
    "Coombs'  IgG monospecific test",
    'C. pneumoniae IgM Cutoff(index)',
    'Random',
    'Factor V activity',
    'Anti-myeloperoxidase Ab',
    'Anti-protease 3 Ab',
    'Anti-HBc IgM S/CO',
    'Anti-HBc IgM interpretation',
    'Anti-RNP Interpretation',
    'Anti-Sm Interpretation',
    'Anti-RNP Reactivity',
    'Anti-Sm Reactivity',
    'Aldosterone',
    'Anti-Toxoplasma IgG',
    'Macroprolactin interference',
    'HSV Type I &II IgM index',
    'HSV Type I &II IgM Interpretation',
    'Anti-HSV-1 IgG',
    'Anti-HSV-2 IgG',
    'Free kappa/lambda ratio',
    'kappa free light chain',
    'lambda free light chain',
    'Anti-neutrophilic cytoplasmic Ab (semi-quantitative)',
    'Anti-smooth muscle Ab (ASMA)',
    'Albumin fraction',
    'Alpha-1 globulins fraction',
    'Alpha-2 globulins fraction',
    'Beta-1 globulins fraction',
    'Beta-2 globulins fraction',
    'Gamma globulins fraction',
    '25-OH Vitamin D Total',
    'Immunotyping/Immunofixation electrophoresis',
    'Nuclear homogeneous(AC1)',
    'Phenobarbital',
    'D. pteronyssinus',
    'D. farinae',
    'cryocrit',
    'D. microceras',
    'IgG1',
    'IgG2',
    'IgG3',
    'IgG4',
    'Testosterone',
    'Dust-greer',
    'Abnormal band',
    'Non-reactive (AC0)',
    'Anti-HTLV 1/2',
    'Phenytoin',
    'VCA IgG',
    'NA IgG',
    'VCA IgM',
    'Cryoglobulin',
    'Nucleolar pattern',
    'Anti-mitochondrial Ab',
    'Plasma renin activity',
    'HCV viral load (Log (IU/mL))',
    'HCV viral load (IU/mL)',
    'Anti-Toxoplasma IgM',
    'Tryptase',
    'C-Peptide (AC)',
    'Eosinophil count',
    'B genotype',
    'A genotype',
    'C genotype',
    'DR genotype',
    'Screening (enzymatic assay)',
    'Free Protein S Antigen',
    'Protein C antigen',
    '72hr',
    'Egg white (蛋白)',
    'Milk (牛奶)',
    'Codfish (鱈魚)',
    'Peanut (花生)',
    'Soybean (黃豆)',
    'Alpha-1 globulins',
    'Alpha-2 globulins',
    'Beta-2 globulins',
    'Beta-1 globulins',
    'Gamma globulins',
    'Wheat (小麥)',
    'Anti-HBc IgG(transplantation)',
    'Anti-HBc IgM S/CO(transplantation)',
    'Anti-HBc IgM interpretation(transplantation)',
    'HSV type 1',
    'HSV type 2',
    'DQA1 genotype',
    'DQB1 genotype',
    'HbH (BCB test)',
    'Hemoglobin electrophoresis',
    'Hemoglobin F quantitation',
    'Hemoglobin A2 quantitation',
    'Estradiol (Adult, 適用年齡≧18歲)',
    "Coombs' IgG monospecific test",
    "Coombs' C3d monospecific test",
    'Anti-Chlamydia trachomatis IgM',
    'ASLO',
    'Promonocyte',
    'Factor VIII activity',
    'Irregular antibody screening',
    'LE cell',
    'Insulin (AC)',
    'Ceruloplasmin',
    'HLA-B*15:02 allele',
    'CD19+ B-cell',
    'CD3+ T-cell',
    'Dengue virus NS1 Ag',
    'Dengue virus Ab (IgM)',
    'Dengue virus Ab (IgG)',
    'CD4+ T-cell',
    'CD8+ T-cell',
    'Capillary whole blood',
    'Unclassfied cytoplasmic pattern',
    'Quantiferon (IGRA)',
    'Nil control',
    'TB Antigen',
    'Mitogen Control',
    'TB Antigen minus Nil',
    'Mitogen minus Nil',
    'Serum Benzodiazepine screening',
    'Total PSA',
    'Free PSA',
    '% free PSA',
    'Anti-Chlamydia trachomatis IgG',
    'Pseudocholinesterase',
    'IGF-1',
    'Carboxyhemoglobin (CO-Hb)',
    'Varicella zoster IgM index',
    'Varicella zoster IgM Interpretation',
    'Carbamazepine',
    'Mixing dRVVT Screen',
    'Mixing dRVVT Confirm',
    'Mixing Normalized dRVVT Ratio',
    'Homogeneous nucleolar(AC8)',
    'Collagen/Epinephrine',
    'Collagen/ADP',
    'Pooled normal plasma (PNP)',
    'Patient (neat)',
    'Control mix',
    '1:1 Immediate mix',
    '1:1 Incubated mix (2 hrs)',
    'Coarse Speckled pattern',
    'High sensitivity Estradio',
    'Intercellular bridge (AC27)',
    'Anti-Glomerular basement membrane antibody',
    'Shrimp (蝦)',
    'Anti-basement membrane zone Ab (Skin)',
    'Renin',
    'Anti-intercellular substance Ab',
    'Anti-Rubella IgM',
    'CD4+ T/ CD8+ T ratio',
    'CD4 count',
    'Rubella IgG Interpretation',
    'Rubella IgG Concentration',
    'RBC count',
    'Fabry disease screening',
    'von Willebrand Factor Activity',
    'von Willebrand Factor antigen',
    '48hr',
    'Southeast Asia deletion',
    'Philippine deletion',
    'Thailand deletion',
    '3.7-kb deletion',
    '4.2-kb deletion',
    'Anti-aquaporin-4 (AQP4) antibody',
    'Plasma cell',
    'Sex hormone-binding globulin',
    'Free testosterone index (FTI)',
    'Crab (螃蟹)',
    'Acetaminophen',
    'Anti-Varicella Zoster virus IgG',
    'Vancomycin: Random',
    'Venous whole blood',
    'DHEA-S',
    'Anti-GAD Ab',
    'Vancomycin: Peak',
    'CMV DNA (定性)',
    'Cu',
    'C. pneumoniae IgG Reactivity',
    'HIV-1 viral load (copies/mL)',
    'HIV-1 viral load (IU/mL)',
    'Thrombin time',
    'PNH granulocyte clone',
    'Serum viscosity',
    'HBsAg concentration',
    'HBsAg interpretation',
    'Acetylcholine receptor Ab',
    'Immunoelectrophoresis(Serum)(IgA, IgG, IgM)',
    'LAP Score',
    'Centromere pattern',
    'Parvovirus B19 DNA',
    'HLA-B*27',
    '法布瑞氏症篩檢(μmol/hr)',
    'Cytoplasmic reticular/AMA(AC21)',
    'Neuron Specific Enolase (NSE)',
    'Factor VII activity',
    'BK virus DNA',
    'Glucose(Random, Pre-operation)',
    'DPA1 genotype',
    'DPB1 genotype',
    'Anti-CCP Ab',
    'Methemoglobin (Met-Hb)',
    'HCV RNA',
    'HSV Type I IgM Reactivity',
    'HSV Type I IgM Interpretation',
    'HSV Type II IgM Reactivity',
    'HSV Type II IgM Interpretation',
    'Cytoplasmic dense fine speckled(AC19)',
    'Proteus OX2',
    'Proteus OX19',
    'Proteus OXK',
    'Factor IX activity',
    'T cell FCXM',
    'B cell FCXM',
    'Methanol',
    'Salmonella typhi O',
    'Salmonella typhi H',
    'Salmonella paratyphi A',
    'Salmonella paratyphi B',
    'Hb Constant Spring mutation',
    'Hb Quong-Sze mutation',
    'HCV genotyping',
    'Cockroach',
    'CD3-/CD56+NK-cell',
    'T cell CDC',
    'T cell CDC (DTT-treat)',
    'B cell CDC',
    'B cell CDC (DTT-treat)',
    'Interpretation 1',
    'Insulin antibodies',
    'CD3+/HLA-DR+Activated T-cell ※',
    '其他意見',
    '24hr',
    'Gating strategy',
    'Gating region',
    'Centrosome(AC24)',
    'CD19',
    'C-Peptide (PC)',
    'Mixing Normalized SCT Ratio',
    'HLA class I PRA',
    'HLA class II PRA',
    'Mixing SCT Screen',
    'Mixing SCT Confirm',
    'C. pneumoniae IgG Cutoff(index)',
    'Factor VIII inhibitor',
    'Ht',
    'IDUA/GLA',
    'Cells gated/TNC *',
    'Template bleeding time',
    'Anti-basement membrane zone Ab (Kidney)',
    'gp160',
    'gp120',
    'p68',
    'p55',
    'p52',
    'gp41',
    'p40',
    'p34',
    'p25',
    'p18',
    'Pb',
    'CD3',
    'Dog dander',
    'Peak',
    'Anti-dsDNA antibody (IFA)',
    'Virus 1',
    'CD7',
    'Plasma Lyso-Gb3 (ng/mL)',
    'Prolymphocyte',
    'Nuclear large/coarse speckled(AC5)',
    'Everolimus (Certican)',
    'CD10',
    'CD38',
    'Mumps IgM index',
    'Mumps IgM Interpretation',
    'PIVKA-II',
    'HIV-1 Ab confirmation',
    'CD117',
    'Hb Constant spring',
    'Hb Quong-sze',
    'Abnormal lymphoid cell',
    'Cytoplasmic fibrillar filamentous(AC16)',
    'CD33',
    'CD34',
    'CD13',
    'Anti-mumps virus IgG',
    'Factor IIactivity',
    'Calcitonin',
    'Free T3',
    'CH50',
    'CyCD3',
    'NuMA-like(AC26)',
    'Factor X activity',
    'D. Pteronyssinus(屋塵璊)',
    'D. Farinae(粉塵璊)',
    'Dust-Greer(家塵)',
    'Mold panel(黴菌)',
    'HLA-B*58:01 allele',
    'TA Genotyping',
    'Allele',
    'CD56',
    'CD15',
    'Staphylococcal enterotoxin A(金黃色葡萄球菌腸毒素A)',
    'Staphylococcal enterotoxin B(金黃色葡萄球菌腸毒素B)',
    'Anti-HBc IgG (人體移植檢驗專用)',
    'Anti-HBc IgM S/CO (人體移植檢驗專用)',
    'Anti-HBc IgM interpretation (人體移植檢驗專用)',
    'ADA (Adenosine deaminase)',
    'Aluminum (Al)',
    'Mercury (Hg)',
    'DQ genotype',
    'Chromogranin A',
    'α1-antitrypsin',
    'Estradiol',
    'Spindle fiber(AC25)',
    'Gastrin',
    'Anti-HDV',
    'Centromere(AC3)',
    '17-OH progesterone',
    'CD20',
    'HLA B27',
    'HLA-A',
    'HLA-B',
    'HLA-C',
    'HLA-DRB1',
    'HLA-DQB1',
    'Apolipoprotein A1',
    'Factor IX inhibitor',
    'CD22',
    'Cryofibrinogen',
    'Blood parasite',
    'Anti-histone Ab',
    'Reactivity (OD)',
    'Measles IgM index',
    'Measles IgM Interpretation',
    'Cat epithelium',
    'Anti-parietal cell Ab',
    'CD79 alpha',
    'Anti-MPO',
    'TdT',
    'Phenobarbital (人體移植檢驗專用)',
    'Comment1',
    'Anti-measles virus IgG',
    'Apolipoprotein B',
    'iCa',
    'CD2',
    'CD5',
    'Arsenic (As, blood)',
    'Class I on MFI cut-off of 1000',
    'Class I on MFI cut-off of 2000',
    'Class II on MFI cut-off of 1000',
    'Class II on MFI cut-off of 2000',
    'IgD',
    'Nuclear dots pattern',
    'Elution & antibody identification',
    'Anti-NuMA-1(nuclear mitotic apparatus) pattern',
    'Lyso-Gb3',
    'CD16',
    'NG2',
    'Nuclear dense fine speckled(AC2)',
    'Cockroach (蟑螂)',
    'Aspergillus',
    'Progesterone',
    'HLADR',
    'CD22 ※',
    'Cytoplasmic fine speckled(AC20)',
    'Androstenedione',
    'Factor XIII screen',
    "0'",
    "120'",
    'Bone-Alkaline phosphatase (BAP)',
    'Anti-EBEA IgG',
    'BK viral load (copies/mL)',
    'Angiotensin converting enzyme',
    'Paraquat (Blood, 定量)',
    'CD3/CD4',
    'Cytoplasmic fibrillar linear(AC15)',
    'CD19/κ',
    'CD19/λ',
    'CD3/CD8',
    'CD11b',
    'CD14',
    'CD35',
    'CD64',
    'CD300e',
    'Dog epithelium',
    'Few nuclear dots(AC7)',
    'CD19/κ ※',
    'CD19/λ ※',
    'nBos d 8 Casein',
    'nGal d 1 Ovomucoid',
    'VZV viral load',
    'CD123',
    'Anti-MPO ※',
    'CD79 alpha ※',
    'Cytoplasmic discrete dots/GW-body like(AC18)',
    'Tricyclic antidepressants (TCA)',
    'Insulin (PC)',
    'Egg yolk',
    "60'",
    'PCNA pattern',
    'TdT※',
    'Aberrant expression',
    'Anti-Glutamate receptor (type NMDA)',
    'Multiple nuclear dots(AC6)',
    'PNH RBC clone',
    'Staphylococcal enterotoxin B',
    'CD23',
    'LAP score (leukocyte alkaline phosphatase)',
    'Glycated albumin',
    'PM-Scl 100',
    'PM-Scl 75',
    'Jo-1',
    'Mi-2α',
    'Mi-2β',
    'TIF1γ',
    'MDA5',
    'NXP2',
    'SAE',
    'Ku',
    'SRP',
    'PL-7',
    'PL-12',
    'EJ',
    'OJ',
    'Ro-52',
    'Factor XI activity',
    'Sirolimus',
    'HLADR ※',
    'T3 uptake',
    'CD81',
    'RBC Count',
    'Malassezia spp',
    'RPR (Source)',
    'Factor XII activity',
    'BKV DNA',
    'Punctate nucleolar(AC10)',
    'Connective tissue disease(CTD) Autoantibody Screen Test',
    'CD9',
    'CD19/Cμ',
    'CD21',
    'CD24',
    'CD58',
    'CD65',
    'CD66c',
    'rAra h 2 Peanut',
    'Penicillium',
    'BK DNA',
    'Salicylate',
    'CD3/TCRδγ',
    'Smooth nuclear envelope(AC11)',
    'Cells gated/NEC *',
    'CYFRA 21-1',
    'CDC crossmatch (4°C、T cell)',
    'CDC crossmatch (37°C、B cell)',
    'CDC crossmatch (4°C、B cell)',
    'CDC crossmatch (37°C、T cell)',
    'Bermuda grass',
    'CD3/TCRαβ',
    'CRAB isolation',
    'CD11b ※',
    'Mango',
    'Shrimp',
    'Anti-mullerian hormone(AMH)',
    'BK viral load (IU/mL)',
    'Erythropoietin',
    'DR-70 (FDP)',
    'FMC7 ※',
    'CD79 beta ※',
    'Gluten',
    'nGal d 2 Ovalbumin',
    'HIV Blot Interpretation',
    'Staphylococcal enterotoxin A',
    'Cytoplasmic fibrillar segmental(AC17)',
    'Polar/Golgi-like(AC22)',
    'CD14  ※',
    'Crab',
    'Interleukin-6',
    'Clumpy nucleolar(AC9)',
    'CD19/Cμ※',
    'Anti-mitochondrial Ab-M2 type',
    'Neutrophil-Seg',
    'CD64 ※',
    'Venous plasma',
    'Hazelnut',
    'HBsAg (Source)(Source) interpretation',
    'VRE isolation',
    'Plasma Alcohol (gas chromatography)',
    'HIV Ag/Ab (Source) S/CO',
    'HIV Ag/Ab (Source) interpretation',
    'GAA enzyme assay',
    'D. Pteronyssinus',
    'Oat',
    'Cadmium',
    'LH (for infertility)',
    'CD25',
    'Uric acid (for Rasburicase treatment)',
    'CD79 beta',
    'FMC7',
    'Egg white',
    'Unclassfied nuclear pattern',
    'rilpivirine(RPV)',
    'lamivudine(3TC)',
    'abacavir(ABC)',
    'zidovudine(AZT)',
    'emtricitabine(FTC)',
    'tenofovir  disoproxil fumarate(TDF)',
    'efavirenz(EFV)',
    'etravirine(ETR)',
    'nevirapine(NVP)',
    'nBos d 4 a-lactalbumin',
    'nBos d 5 b-lactoglobulin',
    'Interpretation 2',
    'CD1a',
    'CD44',
    'Rods and rings(AC23)',
    'CyCD22 ※',
    'T. pallidum Ab (Source)',
    'Milk',
    'Common ragweed',
    'Helicobacter pylori IgG interpretation',
    'Helicobacter pylori IgG reactivity',
    'Centrioles pattern',
    'CD3/TCRαβ※',
    'CD3/TCRδγ※',
    'ritonavir-boosted atazanavir(ATV/r)',
    'ritonavir-boosted darunavir(DRV/r)',
    'indinavir(IDV)',
    'ritonavir-boosted lopinavir(LPV/r)',
    'nelfinavir(NFV)',
    'stavudine(d4T)',
    'didanosine(ddI)',
    'raltegravir(RAL)',
    'elvitegravir(EVG)',
    'HBsAg (Source) interpretation',
    'Anti-HCV (Source) interpretation',
    'Anti-HCV (Source) S/CO',
    'Punctate nuclear envelope(AC12)',
    'Lobster',
    'IFE for cryoglobulin',
    "180'",
    'IDUA/GAA (初篩)',
    'CD58※',
    'CD5/CD38/CD19',
    'Blomia tropicalis',
    'Wheat',
    'Herpes simplex virus (HSV) DNA',
    'Tyrosine phosphatase antibodies',
    'Fetal RBC detection',
    'ritonavir-boosted fosamprenavir(FPV/r)',
    'ritonavir-boosted saquinavir(SQV/r)',
    'ritonavir-boosted tipranavir(TPV/r)',
    'bictegravir(BIC)',
    'dolutegravir(DTG)',
    'Doravirine(DOR)_NNRTI',
    'Candida albicans',
    'Alternaria tenuis',
    'Cladosporium',
    'Peanut',
    'Soybean',
    'Rapid plasma reagin (RPR) (for sexual assault)',
    'CRKP isolation',
    'Serum inhibitory titer:',
    'Serum bactericidal titer:',
    'Peripheral pattern',
    'Alpha fraction',
    'Pre-beta fraction',
    'Beta fraction',
    'Chylomicron fraction',
    'Lipoprotein EP interpretation',
    'Melon',
    'Immunoelectrophoresis(Serum)(IgD, IgE)',
    'Latex',
    'Mitotic chromosomal envelope(AC28)',
    'CD45+ Leukocyte Absolute Count',
    '%CD34+ HPC',
    'CD34+ HPC Absolute Count',
    'Comment2',
    'Comment3',
    'Confirmation (Gas chromatography)',
    'rDer p10 Tropomyosi',
    'Free E3 MoM',
    'βhCG MoM',
    'ONTD risk',
    'Trisomy18 Risk',
    'Down syndrome risk',
    'Inhibin A MoM',
    'Gravida, G',
    'Para, P',
    'α-fetoprotein',
    'α-fetoprotein MoM',
    'Free E3',
    'βhCG',
    'Inhibin A',
    'BPD (ultrasound )',
    'Birth date',
    'Race',
    'Presence of insulin dependent DM',
    'Family history of ONTD',
    'First day of the LMP',
    'Presence of multiple gestation',
    'Smoking',
    'In vitro fertilization',
    'Maternal weight',
    'Initial or repeat specimen',
    'HBV DNA',
    'rtI169T',
    'rtV173L',
    'rtL180M',
    'rtA181T/V',
    'rtT184S/A/I/L',
    'rtA194T',
    'rtS202G/I',
    'rtM204I/V',
    'rtN236T',
    'rtM250I/V',
    'IGF-BP3',
    'Honey bee venom',
    'Mosquito',
    "Gestational OGTT(75g,0')",
    "Gestational OGTT(75g,60')",
    "Gestational OGTT(75g,120')",
    'CD30',
    'atazanavir(ATV)',
    'darunavir(DRV)',
    'fosamprenavir(FPV)',
    'lopinavir(LPV)',
    'saquinavir(SQV)',
    'tipranavir(TPV)',
    'Unclassfied mitotic pattern',
    'Orange',
    'Yeast',
    'Topo I-like (AC29)',
    "Oral glucose tolerance test (75g, 0')",
    "Oral glucose tolerance test (75g,120')",
    'Codfish',
    'CD27',
    'CD28',
    'CD38/Cyκ',
    'CD38/Cyλ',
    'CD138',
    'Neonatal bilirubin',
    "Gestational OGTT(100g,0')",
    "Gestational OGTT(100g,60')",
    "Gestational OGTT(100g,120')",
    "Gestational OGTT(100g,180')",
    'PCNA-like(AC13)',
    'Total P1NP',
  ],
  Stool: [
    'Stool OB (Immuno-quantitative )',
    'Pus cell (WBC)',
    'Stool OB (化學法)',
    '檢驗結果(1):',
    'C. difficile toxigenic strain',
    '027/NAP1/BI hypervirulent strain',
    'Adenovirus antigen',
    'Rotavirus antigen',
    'Norovirus antigen',
    'Campylobacter spp. DNA',
    'Clostridium difficile toxin A/B DNA',
    'Plesiomonas shigelloides DNA',
    'Salmonella spp. DNA',
    'Vibrio spp. DNA',
    'Vibrio cholerae DNA',
    'Yersinia enterocolitica DNA',
    'Enteroaggregative Escherichia coli (EAEC) DNA',
    'Enterotoxigenic Escherichia coli (ETEC) lt/st DNA',
    'Shiga-like toxin-producing E. coli (STEC) stx1/stx2 DNA',
    'Shigella/ Enteroinvasive Escherichia coli (EIEC) DNA',
    'Cryptosporidium DNA',
    'Cyclospora cayetanensis DNA',
    'Entamoeba histolytica DNA',
    'Giardia lamblia DNA',
    'Adenovirus F 40/41 DNA',
    'Astrovirus RNA',
    'Norovirus GI/GII RNA',
    'Rotavirus A RNA',
    'Sapovirus RNA',
    'Enteropathogenic Escherichia coli (EPEC) DNA',
    'Fetal Hb test (APT test)',
    '檢驗效能',
    'Stool reducing substance',
    'Neutral fat (Sudan III stain)',
    'Helicobacter pylori antigen test',
    'Clostridium difficile toxin A/B',
    'Neutral fat',
    'E. Vermicularis (Scotch tape)',
    'Virus 1',
    'E. coli O157 DNA',
  ],
  Artery: ['PCO2', 'pH', 'PO2', 'Base Excess', 'HCO3', 'O2 Saturation', 'Total Carbon Dioxide'],
  Urine: [
    'pH',
    'Protein',
    'Specific gravity',
    'Ketone',
    'Glucose',
    'Bilirubin',
    'Occult Blood',
    'Urobilinogen',
    'Nitrite',
    'WBC esterase',
    'Color',
    'Clarity',
    'RBC (/HPF)',
    'WBC (/HPF)',
    'Squamous Epithelial Cell (/HPF)',
    'RBC (/μL)',
    'WBC (/μL)',
    'Squamous Epithelial Cell (/μL)',
    'Bacteria',
    '24 hr urine amount',
    'Streptococcus pneumoniae Ag',
    'Gr. B Streptococcus Ag',
    'Creatinine (Random urine)',
    'Hyaline Cast',
    'Creatinine',
    'Creatinine (24 hr urine)',
    'Urea Nitrogen',
    'Urea Nitrogen (24 hr urine)',
    'Amorphous Crystal',
    'Yeast',
    'Granular Cast',
    'Osmolality (Random urine)',
    'Protein (Random urine)',
    'Na (Random urine)',
    'Protein-to-Creatinine Ratio',
    'Creatinine (Urine)',
    'Transitional Epithelial Cell',
    'K (urine)',
    'Urine K to creatinine ratio',
    'Calcium Oxalate Crystal',
    'CCR',
    'Urine volume',
    'Creatinine (Blood)',
    'Cl (Random Urine)',
    'Albumin(Urine)',
    'Creatinine(Urine)',
    'A/C Ratio',
    'Urine pregnancy test (beta-hCG)',
    'Protein (24 hr urine)',
    'Urea Nitrogen (Random urine)',
    'Legionella pneumophila serogroup 1 antigen (Urine)',
    'Dysmorphic RBC (Urine)',
    'Cell No. Counted',
    'CMV DNA (定性)',
    'Uric Acid Crystal',
    'Benzodiazepine',
    'Myoglobin',
    'Triple Phosphate Crystal',
    'Ca (Random urine)',
    'Opiates screening',
    'Amphetamine screening',
    'VMA (24 hr urine)',
    'Na',
    'TCA(TCA)',
    'Amphetamines(AMP)',
    'Methamphetamines(mAMP)',
    'Opiates(OPI)',
    'Acetaminophen(APAP)',
    'Tetrahydrocannabinol(THC)',
    'Osmolality',
    'Immunotyping/Immunofixation electrophoresis',
    'Cocaine(COC)',
    'Benzodiazepines(BZO)',
    'Barbiturate(BAR)',
    'Phencyclidine(PCP)',
    'Interpretation',
    'MDMA screening',
    'Norepinephrine',
    'Epinephrine',
    'Dopamine',
    'P (Random urine)',
    'K',
    'Mg (Random urine)',
    'Paraquat (Urine, qualitative)',
    'Cellular Cast',
    'Unclassified Cast',
    'Uric acid (Random urine)',
    'Porphyrin',
    'Renal Tubular Epithelial Cell',
    'Cortisol (24 hr urine)',
    'Cortisol',
    'Osmolality (24 hr urine)',
    'K (24 hr urine)',
    'Albumin(24 hr urine)',
    'Albumin conc.(Urine)',
    'Na (24 hr urine)',
    'Unclassifiable Epithelial Cell',
    'Ca',
    'Ca (24 hr urine)',
    'BK virus DNA',
    'Unclassified Crystal',
    'Virus 1',
    'Aldosterone (24 hr urine)',
    'Free cortisol (24 hr urine)',
    'Calcium Phosphate Crystal',
    'Other',
    'Morphology finding',
    'P (24 hr urine)',
    'P',
    'Cl (24 hr urine)',
    'Cl',
    'Cu (urine)',
    'Cu (24 hr urine)',
    'β2-microglobulin (Urine)',
    'Immunofixation electrophoresis(Urine)',
    '5-HIAA (24 hr urine)',
    '17-KS (24 hr urine)',
    'Mg (24 hr urine)',
    'Mg',
    'Uric acid',
    'Uric acid (24 hr urine)',
    'Gram-negative bacilli',
    '結果判讀',
    'Finding (1)',
    'Urine reducing substance',
    'BK viral load (copies/mL)',
    'Gram-positive cocci',
    'Cannabinoids screening',
    '17-OHCS (24 hr urine)',
    'Acid-fast stain (direct smear)',
    'Mercury (urine)',
    'Mercury to creatinine ratio',
    'BK viral load (IU/mL)',
    'Paraquat (Urine, 定量)',
    '安非他命',
    '甲基安非他命',
    'Waxy Cast',
    '可待因(Codeine)',
    '嗎啡(Morphine)',
    'Cannabinoid',
    'BKV DNA',
    '24hr urine amount',
    'Pb (Urine)',
    'Pb(24 hr urine)',
    'Tryosine Crystal',
    'Mercury (Hg)',
    'Cadmium',
    'Cadmium to creatinine ratio',
    'Cadmium (24 hr urine)',
    'As3+(Urine)',
    'DMA(Urine)',
    'MMA(Urine)',
    'As5+(Urine)',
    'Total Inorganic As (Urine)',
    'Amylase (Random urine)',
    'Epithelial Cast',
  ],
  Vein: ['pH', 'PCO2', 'PO2', 'Base Excess', 'HCO3', 'O2 Saturation', 'Total Carbon Dioxide', 'pO2', 'Hb', 'Na', 'K'],
  'Other, please specify': [
    'Common Aerobic Culture',
    'Common anaerobic culture',
    'ADA (Adenosine deaminase)',
    'Fungus culture',
    'Finding (1)',
    'Bilirubin-T',
    'Gram-negative bacilli',
    'Mycobacterium culture',
    'Acid-fast stain',
    'Enterovirus 71 RNA',
    'MTBC DNA',
    'Rif Resistance',
    'Acid fast stain (concentrated)',
    'Gram-positive cocci',
    'Interpretation',
    'Tuberculin skin test',
    'Gating strategy',
    'Gating region',
    'CD19',
    'CD5',
    'Enterovirus D68 RNA',
    'CD20',
    'CMV DNA (定性)',
    'Cells gated/TNC  *',
    'CD23',
    'CD2',
    'CD3',
    'LDH (Body fluid)',
    'Gram-positive bacilli',
    'CMV Viral load',
    'HSV type 1',
    'HSV type 2',
    'Occult blood (Body fluid)',
    'Acid-fast stain (direct smear)',
    'CD56',
    'Alpha-fetoprotein',
    'Acid fast stain',
    'CD19/κ',
    'CD19/λ',
    'CD22',
    'CD79 beta',
    'FMC7',
    'CD38',
    'CD22 ※',
    'CD3/CD4',
    'CD19/κ ※',
    'CD19/λ ※',
    'FMC7 ※',
    'CD79 beta ※',
    'CD10',
    'hCG β-subunit',
    'pH (Body fluid)',
    'Cells gated/NEC  *',
    'CD3/CD8',
    'CD25',
    'CD3/TCRδγ',
    'CD7',
    'CD13',
    'CD15',
    'CD34',
    'CD117',
    '檢驗結果(1):',
    'CD3/TCRαβ',
    'HLADR',
    'CRKP isolation',
    'CD5/CD38/CD19',
    'CD33',
    'Gram-negative cocci',
    'CA 125',
    'Reactivity',
    'CD30',
    'CD24',
    'CD9',
    'CD58',
    'CD81',
    'Gram-negative coccobacilli',
    'Yeast',
    'Yeast with pseudohyphae',
    'HIV-1 viral load (IU/mL)',
    'HIV-1 viral load (copies/mL)',
    'CRAB isolation',
    'Anti-Mycoplasma pneumoniae IgM',
    'CD11b ※',
    'CD14  ※',
    'CD16',
    'HLADR ※',
    'Immunotyping/Immunofixation electrophoresis',
    '7-AAD cell viability',
    'CD19/Cμ',
    'CD21',
    'CD65',
    'CD66c',
    'TdT',
    'NG2',
    'CD38/Cyκ ※',
    'CD38/Cyλ ※',
    'CD138 ※',
    'Gram variable bacilli',
    'Glucose (Body fluid)',
    'CD79 alpha ※',
    'CD64 ※',
    'CD1a',
    'Gram-positive bacilli with branching',
    'VRE isolation',
    'Gram-positive coccobacilli',
    'C. pneumoniae IgM Interpretation',
    'C. pneumoniae IgM cutoff index',
    'Lactate',
    'CREC isolation',
  ],
  CSF: [
    'Appearance',
    'Leukocyte Count',
    'RBC Count',
    'Lymphocyte',
    'Cell No. Counted',
    'Neutrophil',
    'Protein (Body fluid)',
    'Glucose (Body fluid)',
    'Monocyte',
    'Lactate',
    'pH (Body fluid)',
    'LDH (Body fluid)',
    'Occult blood (Body fluid)',
    'Virus 1',
    'Histiocyte',
    'Variant lymphocyte',
    'Degenerated neutrophil',
    'HSV type 1',
    'HSV type 2',
    'Serum albumin',
    'CSF albumin',
    'CSF IgG',
    'IgG index',
    'Serum IgG',
    'Eosinophil',
    'Reactive lymphocyte',
    'Morphology finding',
    'Plasma cell',
    'ADA (Adenosine deaminase)',
    'VDRL (Neurosyphilis screening)',
    'Blast cell',
    'Metamyelocyte',
    'Anti-Glutamate receptor (type NMDA)',
    'Non-reactive',
    'Myelocyte',
    'Cl',
    'IgG',
    'LDH',
    'Non-reactive (AC0)',
    'Comment',
    'Promyelocyte',
  ],
  'Nasopharyngeal swab': [
    'Influenza B Ag',
    'Influenza A Ag',
    'Respiratory syncytial virus antigen',
    'Virus 1',
    'Influenza B virus',
    'Influenza A virus',
    '檢驗效能',
    'Adenovirus DNA',
    'Coronavirus 229E RNA',
    'Coronavirus HKU1 RNA',
    'Coronavirus NL63 RNA',
    'Coronavirus OC43 RNA',
    'Human Metapneumovirus RNA',
    'Human Rhinovirus/Enterovirus RNA',
    'Influenza virus B RNA',
    'Parainfluenza virus 1 RNA',
    'Parainfluenza virus 2 RNA',
    'Parainfluenza virus 3 RNA',
    'Parainfluenza virus 4 RNA',
    'Respiratory syncytial virus RNA',
    'Bordetella parapertussis (IS1001) DNA',
    'Bordetella pertussis (ptxP) DNA',
    'Chlamydia pneumoniae DNA',
    'Mycoplasma pneumoniae DNA',
    'Influenza virus A RNA',
    'Comment',
    'Influenza virus A H1-2009 RNA',
    'Respiratory syncytial virus',
    'Virus 2',
    'Influenza virus A H3 RNA',
  ],
  'Throat Swab': [
    'Respiratory Adenovirus Ag',
    'Virus 1',
    'SARS-CoV-2 RNA(Throat)',
    'Enterovirus 71 RNA',
    'Group A Streptococcus Ag',
    'Enterovirus D68 RNA',
    'Group A Streptococcus DNA',
    'Comment',
    'Mycoplasma pneumoniae DNA PCR',
    'MP-MLS 抗藥基因檢測',
  ],
  'Gastric Juice': [
    'Occult blood (Body fluid)',
    'Acid-fast stain (direct smear)',
    'Glucose (Body fluid)',
    'Protein (Body fluid)',
    'LDH (Body fluid)',
    'Paraquat (Gastric juice, qualitative)',
  ],
  'Pleural Effusion': [
    'RBC Count',
    'Nucleated Cells Count',
    'Appearance',
    'Cell No. Counted',
    'Lymphocyte',
    'Leukocyte Count',
    'Neutrophil',
    'Protein (Body fluid)',
    'Glucose (Body fluid)',
    'Monocyte',
    'LDH (Body fluid)',
    'Finding (1)',
    'pH (Body fluid)',
    'M+H',
    'ADA (Adenosine deaminase)',
    'Degenerated neutrophil',
    'Plasma cell',
    'Variant lymphocyte',
    'Morphology finding',
    'Eosinophil',
    'Occult blood (Body fluid)',
    'Reactive lymphocyte',
    'Acid-fast stain (direct smear)',
    'Gram-negative bacilli',
    'Gram-positive cocci',
    'Triglyceride',
    'Cholesterol',
    'Total protein',
    'Albumin',
    'Gram-positive bacilli',
    'LDH',
    'Metamyelocyte',
    'Blast cell',
    'Myelocyte',
    'Gram-positive coccobacilli',
    'Lipase',
    'Creatinine',
    'Yeast',
    'Gram-positive cocci in pairs and chains',
    'Promyelocyte',
    'Gram variable bacilli',
    'Gram-negative coccobacilli',
    'Gram variable cocci',
    'Virus 1',
    'Yeast with pseudohyphae',
    'Basophil',
    'Gram-positive bacilli with branching',
    'Gram variable coccobacilli',
  ],
  'Sputum,cough-up': [
    'Mycobacterium culture',
    'Acid-fast stain',
    'Direct Gram stain for sputum',
    'Aerobic culture for sputum',
    'Acid-fast stain (direct smear)',
    'Fungus culture',
    'MTBC DNA',
    'Rif Resistance',
    'Acid fast stain (concentrated)',
    'Acid-fast stain ( ICU Screening )',
    'PMN',
    '上皮細胞',
    'Gram-positive cocci',
    'Gram-negative bacilli',
    'Gram-positive bacilli',
    'Acid fast stain',
    'Direct smear for fungus',
    'Pneumocystis jirovecii DNA',
    'CRAB isolation',
    'Gram-negative cocci',
    'Yeast',
    'Finding (1)',
    'Yeast with pseudohyphae',
    'CRKP isolation',
    'CREC isolation',
    'Virus 1',
    'Fungus',
    'VRE isolation',
    'Gram-negative coccobacilli',
  ],
  Ascites: [
    'Common aerobic culture',
    'Common anaerobic culture',
    'Appearance',
    'Nucleated Cells Count',
    'RBC Count',
    'Cell No. Counted',
    'Neutrophil',
    'Lymphocyte',
    'Leukocyte Count',
    'Monocyte',
    'Finding (1)',
    'Glucose (Body fluid)',
    'M+H',
    'LDH (Body fluid)',
    'Protein (Body fluid)',
    'pH (Body fluid)',
    'Degenerated neutrophil',
    'Albumin',
    'Acid-fast stain (direct smear)',
    'Occult blood (Body fluid)',
    'Acid-fast stain',
    'Mycobacterium culture',
    'Plasma cell',
    'Variant lymphocyte',
    'Fungus culture',
    'Amylase',
    'Morphology finding',
    'Eosinophil',
    'Reactive lymphocyte',
    'Creatinine',
    'Triglyceride',
    'Gram-negative bacilli',
    'ADA (Adenosine deaminase)',
    'Lipase',
    'Urea Nitrogen',
    'Gram-positive cocci',
    'Cholesterol',
    'Total protein',
    'Gram-positive bacilli',
    'LDH',
    'Yeast',
    'Gram-positive coccobacilli',
    'Gram variable bacilli',
    'ALP',
    'Gram-negative coccobacilli',
    'Gram variable coccobacilli',
    'Myelocyte',
    'Gram variable cocci',
    '上皮細胞',
    'Gram-positive cocci in pairs and chains',
    'Basophil',
    'Metamyelocyte',
    'Promyelocyte',
    'Blast cell',
  ],
  'Rectal Swab': ['Virus 1', 'Comment'],
  'Nasal swab': [
    'Staphylococcus aureus',
    'Methicillin-resistant Staphylococcus aureus',
    'MRSA',
    'SA',
    'MRSA isolation',
    'CRAB isolation',
    'VRE isolation',
  ],
  'Sputum,tracheal aspirate(suction)': [
    'Aerobic culture for sputum',
    'Direct Gram stain for sputum',
    'Acid-fast stain ( ICU Screening )',
    'Mycobacterium culture',
    'Acid-fast stain',
    'CRAB isolation',
    'Fungus culture',
    'Acid-fast stain (direct smear)',
    'MTBC DNA',
    'Rif Resistance',
    'Acid fast stain (concentrated)',
    'PMN',
    '上皮細胞',
    'Gram-negative bacilli',
    'Gram-positive cocci',
    'Pneumocystis jirovecii DNA',
    'CRKP isolation',
    'Gram-positive bacilli',
    'Direct smear for fungus',
    'Acid fast stain',
    'Gram-negative cocci',
    'Virus 1',
    'Yeast',
    'CREC isolation',
    'VRE isolation',
    'Finding (1)',
    'Gram-negative coccobacilli',
    'Yeast-',
    'Yeast with pseudohyphae',
  ],
  'Abscess/Pus': [
    'Finding (1)',
    'Gram-negative bacilli',
    'Gram-positive cocci',
    'Direct smear for fungus',
    'Gram-positive bacilli',
    'Acid-fast stain (direct smear)',
    'Yeast',
    'Gram-negative cocci',
    'Gram variable bacilli',
    'Gram-positive coccobacilli',
    'Gram-positive cocci in clusters',
    'Gram variable cocci',
    'Yeast with pseudohyphae',
    'Gram-negative coccobacilli',
  ],
  'Apheresis products': ['CD45+ Leukocyte Absolute Count', '%CD34+ HPC', 'CD34+ HPC Absolute Count'],
  'Bone Marrow': [
    'Erythroid series',
    'Lymphocyte',
    'Orthochromatic normoblast',
    'N. Seg',
    '抽取位置',
    'Myeloid series',
    'Megakaryocte distribution/number',
    'Myelocyte',
    'Metamyelocyte',
    'N. Band',
    'Gross:Marrow unit',
    'Cellularity:',
    'Monocyte',
    '報告日期',
    'Promyelocyte',
    '臨床診斷',
    'Polychromatic normoblast',
    'Fat component',
    '血液診斷',
    'Eosinophil',
    'Blast',
    'Plasma cell',
    '檢體號碼',
    '抽取日期',
    'Smear:',
    'Basophilic normoblast',
    'Comments',
    'Interpretation',
    'Gating strategy',
    'Gating region',
    'CD19',
    'M/E',
    'CD38',
    'CD34',
    'Cells gated/TNC *',
    'CD117',
    'CD33',
    'Abnormal cell/Pattern',
    'CD10',
    'CD7',
    'CD13',
    'CD56',
    'Pro-normoblast',
    'CD20',
    'CD15',
    'Myeloid/Erythroid',
    'CD16',
    'CD2',
    'CD3',
    'CD5',
    'CD22',
    'HLADR ※',
    'CyCD3',
    'CD22 ※',
    'Cells gated/NEC *',
    'HLADR',
    'CD11b ※',
    'CD14',
    'CD11b',
    'NG2',
    'Mitotic figures:',
    'CD14  ※',
    'CD64',
    'CD35',
    'CD300e',
    'CD79 alpha ※',
    'Anti-MPO',
    'Anti-MPO ※',
    'TdT※',
    'CD23',
    'CD3/CD4',
    'CD19/κ',
    'CD19/λ',
    'TdT',
    'CD79 alpha',
    'CD9',
    'CD58',
    'CD3/CD8',
    'CyCD22 ※',
    'Peroxidase',
    'FMC7',
    'CD19/κ ※',
    'CD19/λ ※',
    'CD79 beta',
    'Aberrant expression',
    'CD81',
    'CD64 ※',
    'Blood culture',
    'Others:',
    'Acid-fast stain',
    'Mycobacterium culture',
    'Periodic acid schiff stain (PAS)',
    'CD3/TCRδγ',
    'CD123',
    'FMC7 ※',
    'CD19/Cμ※',
    'CD79 beta ※',
    'Fungus culture',
    'CD66c',
    'CD24',
    'CD19/Cμ',
    'Prolymphocyte',
    'CD27',
    'CD38/Cyκ',
    'CD38/Cyλ',
    'CD138',
    'CD105',
    'Specific esterase stain (CAE)',
    'CD28',
    'CD36',
    'CD71',
    'CD21',
    'CD25',
    'Nonspecific esterase stain (ANBE)',
    'CD138 ※',
    'CD65',
    'CD3/TCRαβ',
    'CD38/Cyκ ※',
    'CD38/Cyλ ※',
    'Promonocyte',
    'CD58※',
    'Auer body',
    'CD3/TCRαβ※',
    'CD3/TCRδγ※',
    'Basophil',
    'IgM',
    'CD71※',
    'CD44',
    'CD1a',
    'CD30',
    'CD5/CD38/CD19',
    'CD41 ※',
    'Iron store',
    'Iron grading',
    'Sideroblast',
    'Ring sideroblast',
    'Siderocyte',
    'GPA ※',
    'Cancer cell',
    'CD41',
    'CD42a',
    'CD42b',
    'CD203c',
  ],
  'CSF , lumbar puncture(LP)': [
    'Common aerobic culture',
    'Common anaerobic culture',
    'Finding (1)',
    'Acid-fast stain',
    'Mycobacterium culture',
    'Fungus culture',
    'Cryptococcal Ag',
    'Streptococcus B',
    'Haemophilus influenzae type b',
    'Streptococcus pneumoniae',
    'N. meningitidis A, C, Y, W135',
    'India ink stain for cryptococcus (CSF)',
    'N. meningitidis serotype B/E. coli K1',
    'Escherichia coli K1 DNA',
    'Haemophilus influenzae DNA',
    'Listeria monocytogenes DNA',
    'Neisseria meningitidis DNA',
    'Streptococcus agalactiae DNA',
    'Streptococcus pneumoniae DNA',
    'Cytomegalovirus DNA',
    'Enterovirus RNA',
    'Herpes simplex virus-1 DNA',
    'Herpes simplex virus-2 DNA',
    'Human herpesvirus-6 DNA',
    'Human parechovirus RNA',
    'Varicella zoster virus DNA',
    'Cryptococcus neoformans/gattii DNA',
    'N. meningitidis B/E. coli K1',
    '檢驗效能',
    'Yeast',
    'Acid-fast stain (direct smear)',
    'Gram-negative bacilli',
    'Gram-positive cocci',
    'Gram-positive coccobacilli',
    '檢驗結果(1):',
    '上皮細胞',
    'Gram variable cocci',
    'Gram-negative coccobacilli',
  ],
  'Body fluid': [
    'CEA',
    'Amylase',
    'Triglyceride',
    'Creatinine',
    'Lipase',
    'Cholesterol',
    'Albumin',
    'Glucose (Body fluid)',
    'Fine Speckled pattern',
    'Homogeneous pattern',
    'LDH',
    'Anti-cytoplasmic staining pattern',
    'Total protein',
    'Nuclear homogeneous (AC1)',
    'Unclassfied cytoplasmic pattern',
    'Coarse Speckled pattern',
    'Non-reactive',
    'Nuclear fine speckled (AC4)',
    'Other',
    'Non-reactive (AC0)',
    'Nucleolar pattern',
    'Anti-NuMA-1(nuclear mitotic apparatus) pattern',
    '檢驗結果(1):',
    'Homogeneous nucleolar (AC8)',
    'Cytoplasmic fibrillar filamentous (AC16)',
    'Cytoplasmic dense fine speckled (AC19)',
    'Centrosome (AC24)',
    'Intercellular bridge (AC27)',
  ],
  'Blood & CSF': ['Oligoclonal band for CSF'],
  Stone: ['Composition 1', 'Composition 2', 'Composition 3'],
  'Urine,clean-voided midstream,has no antibiotic used': [
    'Common aerobic culture',
    'Fungus culture',
    'Mycobacterium culture',
    'Acid-fast stain',
    'CRAB isolation',
    'CRKP isolation',
    'VRE isolation',
  ],
  'Abscess,deep/close,site:': [
    'Common aerobic culture',
    'Common anaerobic culture',
    'Fungus culture',
    'Mycobacterium culture',
    'Acid-fast stain',
    'VRE isolation',
    'CRAB isolation',
    'CREC isolation',
    'CRKP isolation',
  ],
  'Urine,indwelling catheter(Foley)': [
    'Common aerobic culture',
    'Fungus culture',
    'CRAB isolation',
    'Mycobacterium culture',
    'Acid-fast stain',
    'CRKP isolation',
    'VRE isolation',
    'CREC isolation',
    'MRSA isolation',
  ],
  Bile: [
    'Common aerobic culture',
    'Common anaerobic culture',
    'Gram-negative bacilli',
    'Gram-positive cocci',
    'Finding (1)',
    'Fungus culture',
    'Gram-positive bacilli',
    'Acid-fast stain',
    'Mycobacterium culture',
    'Occult blood (Body fluid)',
    'pH (Body fluid)',
    'Acid-fast stain (direct smear)',
    'Yeast',
    'Gram variable bacilli',
    'Gram-negative cocci',
    'Gram-positive coccobacilli',
    'Gram-positive cocci in pairs and chains',
    'Yeast with pseudohyphae',
    'Gram-negative coccobacilli',
    'Gram variable cocci',
    'Fungus',
  ],
  'Synovial fluid, side:': [
    'Common aerobic culture',
    'Common anaerobic culture',
    'Mycobacterium culture',
    'Acid-fast stain',
    'Fungus culture',
  ],
  'Pleural fluid, side:': [
    'Common aerobic culture',
    'Mycobacterium culture',
    'Acid-fast stain',
    'Common anaerobic culture',
    'Fungus culture',
    'Rif Resistance',
    'Acid fast stain (concentrated)',
    'MTBC DNA',
  ],
  'Tissue,site:': [
    'Common aerobic culture',
    'Common anaerobic culture',
    'Mycobacterium culture',
    'Acid-fast stain',
    'Fungus culture',
    'Finding (1)',
    'Gram-positive cocci',
    'Gram-negative bacilli',
  ],
  Nasopharynx: ['Fungus culture'],
  Pericardial: [
    'Common aerobic culture',
    'Mycobacterium culture',
    'Acid-fast stain',
    'Common anaerobic culture',
    'Fungus culture',
  ],
  'BAL/B.washing': [
    'Aerobic culture for sputum',
    'Direct Gram stain for sputum',
    'Fungus culture',
    'Acid-fast stain',
    'Mycobacterium culture',
    'Pneumocystis jirovecii DNA',
    'MTBC DNA',
    'Rif Resistance',
    'Acid fast stain (concentrated)',
    'Acid-fast stain (direct smear)',
    'Direct smear for fungus',
    'Acid fast stain',
    'Gram-negative bacilli',
    'Finding (1)',
    'Gram-positive cocci',
    'Yeast',
    'Gram-positive bacilli',
  ],
  'Abscess,superficial/open,site:': [
    'Common aerobic culture',
    'Fungus culture',
    'CRAB isolation',
    'Acid-fast stain',
    'Mycobacterium culture',
    'CRKP isolation',
    'VRE isolation',
    'CREC isolation',
  ],
  'Urine,clean-voided midstream,has antibiotic used': [
    'Common aerobic culture',
    'Fungus culture',
    'Acid-fast stain',
    'Mycobacterium culture',
    'CRAB isolation',
    'VRE isolation',
    'CRKP isolation',
    'CREC isolation',
  ],
  'Rectal swab': [
    'VRE isolation',
    'CRKP isolation',
    'CREC isolation',
    'Stool culture for Salmonella and Shigella',
    'Stool culture for Vibrio, Aeromonas and Plesiomonas',
    'Campylobacter culture (Stool)',
    'CRAB isolation',
    'Pathogenic E. Coli O157 culture (Stool)',
    'Gonococcus culture',
  ],
  'Pharynx(throat swab)': ['Common aerobic culture', 'Group A Streptococcus Ag', 'Fungus culture'],
  'Tip,FVP': ['Common aerobic culture', 'Fungus culture'],
  'Urine,straight catheter (in and out)': [
    'Common aerobic culture',
    'Fungus culture',
    'CRAB isolation',
    'CRKP isolation',
    'VRE isolation',
    'Acid-fast stain',
    'Mycobacterium culture',
    'CREC isolation',
  ],
  Endometrium: ['Common aerobic culture', 'Common anaerobic culture', 'Fungus culture', 'Gonococcus culture'],
  'Stool culture': [
    'Stool culture for Salmonella and Shigella',
    'Stool culture for Vibrio, Aeromonas and Plesiomonas',
    'Stool culture for Campylobacter',
    'Pathogenic E. Coli O157 culture (Stool)',
    'CREC isolation',
    'CRKP isolation',
    'VRE isolation',
    'CRAB isolation',
  ],
  'Gastric biopsy': ['Urease test', 'Acid-fast stain', 'Mycobacterium culture'],
  'Tip,Double lumen': ['Common aerobic culture', 'Fungus culture'],
  'Blood for fungal culture': ['Fungus culture'],
  'Tip, type:': ['Common aerobic culture', 'CRAB isolation'],
  'Blood for TB culture': ['Mycobacterium culture', 'Acid-fast stain'],
  'Tip,CVP/Swan-Ganz': ['Common aerobic culture', 'Fungus culture'],
  Graft: ['Common aerobic culture', 'Common anaerobic culture'],
  Cervix: ['Common aerobic culture', 'Fungus culture', 'Gonococcus culture'],
  'Gastric aspirate': [
    'Mycobacterium culture',
    'Acid-fast stain',
    'Rif Resistance',
    'MTBC DNA',
    'Acid fast stain (concentrated)',
    'Acid fast stain',
  ],
  'Ear. outer': ['Common aerobic culture', 'Fungus culture', 'Mycobacterium culture', 'Acid-fast stain'],
  'CSF , Ventricular drain(VD)': [
    'Common aerobic culture',
    'Common anaerobic culture',
    'Finding (1)',
    'Fungus culture',
    'Acid-fast stain',
    'Mycobacterium culture',
    'Cryptococcal Ag',
    'Gram-negative bacilli',
    'India ink stain for cryptococcus (CSF)',
    'N. meningitidis A, C, Y, W135',
    'Streptococcus B',
    'Haemophilus influenzae type b',
    'Streptococcus pneumoniae',
    'Gram-positive cocci',
    'N. meningitidis B/E. coli K1',
    'N. meningitidis serotype B/E. coli K1',
    'Acid-fast stain (direct smear)',
    'Gram-positive bacilli',
    '檢驗結果(1):',
    'Gram variable coccobacilli',
    'Gram-positive coccobacilli',
  ],
  MIC: ['Minimum inhibitory concentration'],
  'Urine,nephrostomy': [
    'Common aerobic culture',
    'Common anaerobic culture',
    'Fungus culture',
    'Mycobacterium culture',
    'Acid-fast stain',
    'VRE isolation',
    'CRAB isolation',
  ],
  Conjunctiva: ['Common aerobic culture', 'Fungus culture', 'Gonococcus culture'],
  'Aqueous/vitreous fluid': [
    'Common aerobic culture',
    'Fungus culture',
    'Direct smear for fungus',
    'Finding (1)',
    'Common anaerobic culture',
    'Gram-positive cocci',
    'Acid-fast stain',
    'Mycobacterium culture',
    'Gram variable coccobacilli',
  ],
  'Tip,PCVC(peripheral central venous catheter)': ['Common aerobic culture', 'Fungus culture'],
  'Bronchial brush': [
    'Acid-fast stain (direct smear)',
    'Direct Gram stain for sputum',
    'Aerobic culture for sputum',
    'Mycobacterium culture',
    'Acid-fast stain',
    'Fungus culture',
    'Direct smear for fungus',
    'Finding (1)',
    'Gram-positive cocci',
    'PMN',
    '上皮細胞',
    'Gram-positive bacilli',
    'Yeast',
    'Gram-negative cocci',
  ],
  'Corneal scrapings': [
    'Common aerobic culture',
    'Fungus culture',
    'Common anaerobic culture',
    'Direct smear for fungus',
    'Finding (1)',
    'Gram-negative bacilli',
    'Gram-positive bacilli',
    'Gram variable cocci',
    'Yeast',
    'Fungus',
    'Gram variable bacilli',
  ],
  'Bone, site:': [
    'Common aerobic culture',
    'Common anaerobic culture',
    'Mycobacterium culture',
    'Acid-fast stain',
    'Fungus culture',
  ],
  'Synovial Fluid': [
    'Appearance',
    'RBC Count',
    'Neutrophil',
    'Cell No. Counted',
    'Nucleated Cells Count',
    'Lymphocyte',
    'Monocyte',
    'MSU Intracellular',
    'MSU Extracellular',
    'CPPD Intracellular',
    'CPPD Extracellular',
    'Finding (1)',
    'Glucose (Body fluid)',
    'Degenerated neutrophil',
    'LDH (Body fluid)',
    'Histiocyte',
    'Protein (Body fluid)',
    'Morphology finding',
    'pH (Body fluid)',
    'Gram-positive cocci',
    'Acid-fast stain (direct smear)',
    'Occult blood (Body fluid)',
    'Gram-negative bacilli',
    'Eosinophil',
    'Variant lymphocyte',
    'Reactive lymphocyte',
    'Other',
    'Plasma cell',
    'Gram-positive coccobacilli',
    'Gram-positive cocci in clusters',
    'Gram variable coccobacilli',
  ],
  'Urine,suprapubic aspirate': [
    'Common aerobic culture',
    'Common anaerobic culture',
    'Fungus culture',
    'Mycobacterium culture',
    'CRAB isolation',
    'Acid-fast stain',
  ],
  'CSF , Ommaya reservoir(OR)': [
    'Common aerobic culture',
    'Common anaerobic culture',
    'Fungus culture',
    'Acid-fast stain',
    'Mycobacterium culture',
    'Finding (1)',
    'Cryptococcal Ag',
    'India ink stain for cryptococcus (CSF)',
    'Streptococcus B',
    'Haemophilus influenzae type b',
    'N. meningitidis A, C, Y, W135',
    'N. meningitidis serotype B/E. coli K1',
    'Streptococcus pneumoniae',
    'Gram-negative bacilli',
    'Gram-positive cocci',
    'Gram variable cocci',
    'Acid-fast stain (direct smear)',
  ],
  'Bronchoalveolar Lavage': [
    'Interpretation',
    'Index',
    'RBC Count',
    'Neutrophil',
    'Nucleated Cells Count',
    'Cell No. Counted',
    'Lymphocyte',
    'Monocyte',
    'Epithelial cell',
    'Histiocyte',
    'Morphology finding',
    'Eosinophil',
    'Other',
  ],
  'Pericardial fluid': [
    'Glucose (Body fluid)',
    'Protein (Body fluid)',
    'LDH (Body fluid)',
    'Appearance',
    'Leukocyte Count',
    'Lymphocyte',
    'RBC Count',
    'Nucleated Cells Count',
    'Cell No. Counted',
    'Neutrophil',
    'Finding (1)',
    'Monocyte',
    'M+H',
    'pH (Body fluid)',
    'Morphology finding',
    'Degenerated neutrophil',
    'Plasma cell',
    'Eosinophil',
    'Variant lymphocyte',
    'Acid-fast stain (direct smear)',
    'Reactive lymphocyte',
    'Virus 1',
    'Gram-positive cocci',
    'Myelocyte',
  ],
  'Nasopharyngeal wash/aspirate': [
    'Influenza B Ag',
    'Influenza A Ag',
    'Respiratory syncytial virus antigen',
    'Virus 1',
    '檢驗效能',
  ],
  'Urethra,male': ['Common aerobic culture', 'Gonococcus culture', 'Fungus culture'],
  "Bartholin's cyst": ['Common aerobic culture', 'Common anaerobic culture', 'Fungus culture'],
  'Protected bronchoscopic bronchial brush': [
    'Common anaerobic culture',
    'Fungus culture',
    'Direct Gram stain for sputum',
    'Mycobacterium culture',
    'Acid-fast stain',
    'Aerobic culture for sputum',
    'Acid-fast stain (direct smear)',
  ],
  'Vesicle Swab': ['Virus 1'],
  Vagina: ['Common aerobic culture', 'Fungus culture', 'Gonococcus culture'],
  'CSF(cerebrospinal fluid)': [
    'Rif Resistance',
    'MTBC DNA',
    'Acid fast stain (concentrated)',
    'Herpes simplex virus (HSV) DNA',
    'Acid fast stain',
  ],
  'Urethra, female': ['Gonococcus culture', 'Common aerobic culture', 'Fungus culture'],
  'Isolated colony': ['NTM genotyping', 'Common aerobic culture', 'NTM typing'],
  Amniotic: [
    'Common aerobic culture',
    'Common anaerobic culture',
    'Mycobacterium culture',
    'Acid-fast stain',
    'Fungus culture',
  ],
  'Vaginal & rectal swabs': ['Group B streptococcus culture (pregnancy)'],
  'Ear. inner': ['Common aerobic culture', 'Common anaerobic culture'],
  Sputum: ['SARS-CoV-2 RNA(Sputum)'],
  'Duodenal Juice': ['Occult blood (Body fluid)', 'Protein (Body fluid)'],
  'Nasopharyngeal / Throat swab': ['SARS-CoV-2 RNA(NP swab)', 'SARS-CoV-2 RNA(Throat swab)'],
  'Amniotic fluid': ['Virus 1'],
  'Cord Blood': ['Cord blood IgE'],
  Semen: [
    'Volume',
    'Color',
    'Viscosity',
    'Conc.',
    'Motility',
    'Progressing',
    'Days of Abstinence',
    'Appearance',
    'Time to liquefaction',
    'Gross viscosity',
    'pH',
    'Progressive motility',
    'Total motility',
    'WBC',
    'Sperm concentration',
    'Sperm morphology (normal form)',
    '禁慾天數:',
  ],
  'Tissue:': ['Virus 1', 'Direct smear for fungus'],
  'Cul-de-sac': ['Common aerobic culture', 'Common anaerobic culture'],
  'Corneal ulcer': ['Direct smear for fungus', 'Finding (1)'],
  'Tip,UVC(umbilical venous catheter)': ['Common aerobic culture', 'Fungus culture'],
  'Hair,nails,or skin scrapings(for fungal)': ['Fungus culture'],
  'Bron. Washing': ['Virus 1'],
  'Cervical discharge': ['Gram-negative bacilli'],
};
