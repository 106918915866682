<template>
  <div v-click-outside="closeCopyLinkMenu">
    <b-img
      src="@/assets/images/icon_event_share.svg"
      class="mr-5px"
      style="cursor: pointer"
      title="Share"
      @click="openCopyLinkMenu"
    />
    <div class="share-analysis-menu" v-if="copyLinkMenu">
      <div class="d-flex justify-content-between">
        <div class="title-text">Share</div>
        <b-img src="@/assets/images/icon_close.svg" class="icon-close" @click="closeCopyLinkMenu" />
      </div>
      <div class="name-text mt-20px">Analysis Name</div>
      <b-input
        placeholder="Analysis Name"
        v-model="nameText"
        class="name-input mt-10px"
        ref="shareQueryButtonInput"
      ></b-input>
      <div class="share-analysis-button mt-20px" @click="clickCopyLink">Copy Link</div>
    </div>
  </div>
</template>
<script>
import ClickOutside from 'vue-click-outside';
import { mapState } from '@/store';
import dayjs from 'dayjs';
import { getRedirectLink } from '@/utils/util';
import urljoin from 'url-join';
import { ClickMixin } from '@/mixins';
import { VUE_EVENT_NAME } from '@/utils/constants';

const LINK_COPIED_WAIT = 3000; // 3 seconds

export default {
  name: 'ShareAnalysis',
  mixins: [ClickMixin],
  directives: {
    ClickOutside,
  },
  props: {
    uniqueIndex: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapState('cohortSession', ['tabs']),
    tab() {
      return this.tabs[this.uniqueIndex];
    },
    eventSearch() {
      return this.tab.eventSearch;
    },
  },
  data() {
    return {
      copyLinkMenu: false,
      nameText: 'Analysis Name',
      copiedTextTimeout: null,
    };
  },
  methods: {
    openCopyLinkMenu() {
      this.copyLinkMenu = true;
      this.nameText = this.tab.name;
      this.$nextTick(() => {
        try {
          this.$refs.shareQueryButtonInput.select();
        } catch (error) {
          console.error(error);
        }
      });

      this.cohortStudyTrackClick('openCopyLinkMenu');
    },
    closeCopyLinkMenu() {
      this.copyLinkMenu = false;
    },
    listener(e) {
      e.clipboardData.setData('text/plain', this.copyLink);
      e.preventDefault();
    },
    getSharedUrl(itemId) {
      const { origin:baseUrl } = window.location;
      return urljoin(baseUrl, 'analysis-url', itemId);
    },
    async clickCopyLink() {
      this.copyLinkMenu = false;
      const result = await this.$api.postCohortStudy({
        name: this.nameText,
        updateTime: dayjs().toISOString(),
        purpose: 'share',
        request: {
          searchState: this.eventSearch,
        },
      });

      const sharedUrl = this.getSharedUrl(result.url);
      this.copyLink = getRedirectLink(sharedUrl);

      document.addEventListener('copy', this.listener);
      document.execCommand('copy');
      document.removeEventListener('copy', this.listener);

      this.showCopiedText();

      this.cohortStudyTrackClick('clickCopyLink');
    },
    showCopiedText() {
      clearTimeout(this.copiedTextTimeout);
      this.$root.$emit(VUE_EVENT_NAME.OPEN_ANALYSIS_LINK_COPIED_TEXT);
      this.copiedTextTimeout = setTimeout(() => {
        this.$root.$emit(VUE_EVENT_NAME.CLOSE_ANALYSIS_LINK_COPIED_TEXT);
      }, LINK_COPIED_WAIT);
    },
  },
};
</script>
<style lang="scss" scoped>
.share-analysis-menu {
  position: absolute;
  width: 420px;
  border-radius: 6px;
  box-shadow: 0 4px 10px 0 rgba(51, 63, 107, 0.2);
  border: solid 1px #d6d9e1;
  background-color: #ffffff;
  padding: 20px 25px;
  z-index: 10;
  margin: 0px 0px 0px -264px;
}

.title-text {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #334e97;
}

.name-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #212121;
}

.name-input {
  height: 40px;
  border-radius: 3px;
  border: solid 1px #4d8edc;
}

.share-analysis-button {
  padding: 12px 12px;
  background: #4d8edc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
}
</style>
