<template>
  <div
    v-on:click.stop="addDataRangeConstraint"
    class="advance-ageFilter-adder"
    :class="{ disabled: this.constraintIndex !== -1 }"
    v-if="hasCondition"
  >
    + Time Period
  </div>
</template>

<script>
import { findIndex, cloneDeep } from 'lodash';
import { EventType } from '@/utils/conditions/core/Interface';
import { EventSearchMixin, EventUpdateMixin, ClickMixin, EventFilterMixin } from '@/mixins';
import ConstraintType from '@/utils/conditions/core/advance/constraint/Interface';
import { getUiConfig } from '@/utils/uiConfig';
import { getTimeRangeInYear } from '@/utils/conditions/TimeDef';

export default {
  name: 'DataRangeFilter',
  props: {
    index: {
      type: Number,
      default: 1,
    },
    eventType: {
      type: String,
      default: EventType.FIX_EVENT,
    },
    path: {
      type: Array,
      default: () => [],
    },
  },
  mixins: [EventSearchMixin, EventUpdateMixin, ClickMixin, EventFilterMixin],
  computed: {
    currentEvent() {
      return this.getEventDataByPath(this.path);
    },
    constraintIndex() {
      return findIndex(this.currentEvent.constraintList, {
        constraintType: ConstraintType.DataRangeConstraint,
      });
    },
  },
  methods: {
    addDataRangeConstraint() {
      const dataRangeConstraint = getTimeRangeInYear(getUiConfig().SearchForm.time);
      const event = cloneDeep(this.currentEvent);

      if (this.constraintIndex === -1) {
        event.constraintList.push({
          constraintType: ConstraintType.DataRangeConstraint,
          ...dataRangeConstraint,
        });
      }
      const body = {
        constraintList: event.constraintList,
      };
      this.updateEventWithPath(body, this.path);
      this.setCurrentMenu(this.path, 'DataRangeConstraint');
      this.cohortStudyTrackClick('DataRangeConstraint', {
        path: this.path,
        index: this.index,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.advance-ageFilter-adder {
  padding: 4px 8px;
  background: #ffffff;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #4d8edc;
  cursor: pointer;
  border: 1px solid #4d8edc;
  box-sizing: border-box;
  border-radius: 4px;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
