import { cloneDeep } from 'lodash';
import { API_TARGET, KEY, TRANSFER, PAGES, VUE_EVENT_NAME } from '@/utils/constants';
import { getName, setMedicalOrder, DescriptionType } from '@/utils/descriptionCache';
import { ChartKey } from '@/components/charts/core/Interface';
import dayjs from 'dayjs';
import { v4 as uuid } from 'uuid';
import { formatIcdGroupMapItem } from '@/utils/util';
import { getUiConfig } from '@/utils/uiConfig';
import { SearchType, DiagnosisRanges, DiagnosisType } from '../utils/conditions/core';
import { mapState, mapMutations } from '../store';

function formatIcd(codeDescription, billable) {
  if (!billable) {
    return `${codeDescription} (All)`;
  }
  return codeDescription;
}

function formatProcedureDescription(codeDescription, billable) {
  if (!billable) {
    return `(All) ${codeDescription}`;
  }
  return codeDescription;
}

const MEDICAL_ORDER = 'Medical Order';

export default {
  computed: {
    ...mapState(['medicalOrderCategory']),
    ...mapState('condition', [
      'formDiagnosisRange',
      'inPatient',
      'inRecord',
      'outPatient',
      'outRecord',
      'totalPatient',
      'totalRecord',
      'risk',
      'onlyMyPatient',
    ]),
    ...mapState('session', ['tabs']),
    ...mapState(['confirmIcdMap']),
    getDiagnosisRange() {
      switch (this.formDiagnosisRange) {
        case 'all':
          return 'Any';
        case 'primary':
          return 'Primary';
        case 'top-5':
          return 'Top5';
        default:
          return 'Any';
      }
    },
    ckeckMapEdit() {
      return this.diagnosisGroupMap.list.length !== 0;
    },
  },
  methods: {
    ...mapMutations('condition', ['setFormDiagnosisRange']),
    ...mapMutations('session', ['addTab', 'setActiveTabIndex', 'updateTab']),
    onAddOption(option) {
      switch (option.field) {
        case 'Diagnosis':
          this.$refs.diagnosisFilterComponent[0].addOption(option);
          break;
        case 'Medication':
          this.$refs.medicationFilterComponent[0].addOption(option);
          break;
        case 'Procedure':
          this.$refs.procedureFilterComponent[0].addOption(option);
          break;
        case 'MedicalOrder':
          this.$refs.MedicalOrderFilterComponent[0].addOption(option);
          break;
        case 'DoctorId':
          this.$refs.DoctorIdFilterComponent[0].addOption(option);
          break;
        default:
          break;
      }
    },
    icdFeedback(code, mode) {
      if (mode === 'basic') {
        this.keySearch({ query: code });
      } else {
        this.advanceKeySearch({ query: code });
      }
    },
    medicationFeedback(code, mode) {
      if (mode === 'basic') {
        this.keySearchPCS({ query: code });
      } else {
        this.advanceKeySearchPCS({ query: code });
      }
    },
    procedureFeedback(body, mode) {
      if (mode === 'basic') {
        this.keySearchProcedure(body);
      } else {
        this.advanceKeySearchProcedure(body);
      }
    },
    // for diagnosis search code filter
    async fetchICD(ICDName, setOptions, mode = 'basic') {
      if (ICDName.length < 2) {
        setOptions([]);
        this.$api.clearPending('diagnosis');
        return;
      }
      const ICDCodes = [];

      this.icdFeedback(ICDName, mode);

      const response = await this.$api
        .searchDiagnosis({
          query: ICDName,
          diagnosis_range: this.getDiagnosisRange,
        })
        .catch((error) => {
          console.error(error);
        });

      const formatItem = (item, type) => ({
        code: item.code,
        label: item.code,
        labelDX: formatIcd(item.code, item.billable),
        description: item.english_name,
        chineseDescription: item.chinese_name,
        value: item.code,
        type,
        patientNum: item.patient_num,
        recordNum: item.record_num,
        field: type,
      });

      const currentIcd10 = response.current.icd10_list ? response.current.icd10_list : [];
      if (currentIcd10) {
        currentIcd10.forEach((itemIcd10) => {
          ICDCodes.push(formatItem(itemIcd10, 'icd10'));
        });
      }

      const currentIcd9 = response.current.icd9_list ? response.current.icd9_list : [];
      if (currentIcd9) {
        currentIcd9.forEach((itemIcd9) => {
          ICDCodes.push(formatItem(itemIcd9, 'icd9'));
        });
      }

      const rawData = response.children[0] ? response.children : [];
      rawData.forEach((item) => {
        item.icd9_list.forEach((itemIcd9) => {
          ICDCodes.push(formatItem(itemIcd9, 'icd9'));
        });
        item.icd10_list.forEach((itemIcd10) => {
          ICDCodes.push(formatItem(itemIcd10, 'icd10'));
        });
      });
      setOptions(ICDCodes);
    },
    async fetchICDGroupMap(ICDName, setOptions, value, mode = 'basic') {
      if (ICDName.length < 2) {
        setOptions([], 'ICD_KEYWORD');
        this.$api.clearPending('diagnosis');
        return;
      }
      const ICDCodes = [];

      this.icdFeedback(ICDName, mode);

      const response = await this.$api
        .searchDiagnosisGroupMap({
          query: ICDName,
          diagnosis_range: this.getDiagnosisRange,
        })
        .catch((error) => {
          console.error(error);
        });

      const formatItem = (item) => {
        const selectList = value.map((itemValue) => itemValue.selectDescendants).flat();
        const check =
          !!value.find((valueItem) => valueItem.code === item.code && !valueItem.partial) ||
          !!selectList.find((valueItem) => valueItem === item.code);

        return {
          ...formatIcdGroupMapItem(item),
          check,
          level: 1,
        };
      };

      response.results.forEach((item) => {
        ICDCodes.push(formatItem(item));
      });

      setOptions(ICDCodes, response.query_mode);
    },
    async fetchMedication(medication, setOptions, mode = 'basic') {
      if (medication === '') {
        setOptions([]);
        this.$api.clearPending('medication');
        return;
      }
      this.currentText = medication;

      this.medicationFeedback(medication, mode);

      const response = await this.$api.searchMedication({ query: medication }).catch((error) => {
        console.error(error);
      });

      const medicationCodes = [];

      const formatItem = (item, type) => ({
        code: item.code,
        label: item.code,
        description: item.english_name,
        chineseDescription: item.chinese_name,
        value: item.code,
        type,
        patientNum: item.patient_num,
        recordNum: item.record_num,
        field: type,
        ingredient: item.ingredient,
        atcCode: item.atc_code,
      });

      function setMedData(itemMed) {
        if (itemMed) {
          medicationCodes.push(formatItem(itemMed, 'med'));
        }
      }

      if (response.current.order_id_list) {
        response.current.order_id_list.forEach(setMedData);
      }

      if (response.children[0]) {
        response.children.forEach((item) => {
          item.order_id_list.forEach(setMedData);
        });
      }

      setOptions(medicationCodes);
    },
    async fetchProcedure(procedure, setOptions, mode = 'basic') {
      if (procedure.length < 2) {
        setOptions([]);
        this.$api.clearPending('procedure');
        return;
      }
      const body = {
        query: procedure,
        target: this.uiConfig.procedureTarget,
        non_billable: this.uiConfig.procedureTarget === API_TARGET.NHI_ORDER,
      };

      this.procedureFeedback(body, mode);

      const response = await this.$api.searchProcedure(body).catch((error) => {
        console.error(error);
      });

      this.options = [];
      const options = [];

      const formatItem = (data, type) => ({
        value: data.code,
        code: data.code,
        label: data.code,
        description: formatProcedureDescription(data.english_name, data.billable),
        chineseDescription: formatProcedureDescription(data.chinese_name, data.billable),
        type,
        patientNum: data.patient_num,
        recordNum: data.record_num,
        field: type,
      });

      let type;
      if (this.uiConfig.procedureKey === KEY.NHI_ORDER_LIST) {
        type = 'nhi';
      } else {
        type = 'pcs';
      }

      if (response.current[this.uiConfig.procedureKey]) {
        options.push(formatItem(response.current[this.uiConfig.procedureKey][0], type));
      }
      if (response.children[0]) {
        response.children.forEach((item) => {
          item[this.uiConfig.procedureKey].forEach((itemIcd10) => {
            if (itemIcd10) {
              options.push(formatItem(itemIcd10, type));
            }
          });
        });
      }
      setOptions(options);
    },
    async fetchMedicalOrder(medication, setOptions, mode = 'basic') {
      if (medication === '') {
        setOptions([]);
        return;
      }
      const body = {
        query: medication,
        target: 'MEDICAL-ORDER',
        sug_num: 50,
      };

      this.procedureFeedback(body, mode);

      const response = await this.$api.searchProcedure(body).catch((error) => {
        console.error(error);
      });
      const medicationCodes = [];

      const formatItem = (data) => ({
        value: data.code,
        code: data.code,
        label: data.code,
        description: data.english_name,
        chineseDescription: data.chinese_name,
        type: data.type,
        patientNum: data.patient_num,
        recordNum: data.record_num,
        nhiOrder: data.nhi_order,
        hisVersion: data.his_version,
        field: 'medicalOrder',
      });

      function setMedData(itemMed) {
        if (itemMed) {
          setMedicalOrder(DescriptionType.MEDICAL_ORDER, itemMed.code, itemMed.english_name, itemMed.type).catch(
            (error) => {
              console.error(error);
            }
          );
          medicationCodes.push(formatItem(itemMed));
        }
      }

      if (response.current.hospital_order_list) {
        response.current.hospital_order_list.forEach(setMedData);
      }

      if (response.children[0]) {
        response.children.forEach((item) => {
          item.hospital_order_list.forEach(setMedData);
        });
      }

      setOptions(medicationCodes);
    },
    async fetchLab(lab, setOptions) {
      if (lab === '') {
        setOptions([]);
        return;
      }

      const body = {
        query: lab,
        target: 'LAB-TEST',
        sug_num: 50,
      };

      const response = await this.$api.searchLab(body).catch((error) => {
        console.error(error);
      });

      const labCodes = [];

      const formatItem = (item) => ({
        value: item.exam_item_name,
        code: item.exam_item_name,
        label: item.exam_item_name,
        lab: item.exam_item_name,
        type: 'lab',
        patientNum: item.patient_num,
        recordNum: item.record_num,
        field: 'lab',
        exam: item.specimen,
        unit: item.unit,
      });

      if (response.current.lab_test_list) {
        response.current.lab_test_list.forEach((itemLab) => {
          labCodes.push(formatItem(itemLab));
        });
      }

      if (response.children[0]) {
        response.children.forEach((item) => {
          item.lab_test_list.forEach((itemLab) => {
            if (itemLab) {
              labCodes.push(formatItem(itemLab));
            }
          });
        });
      }

      setOptions(labCodes);
    },
    async fetchDoctorId(doctorId, setOptions) {
      if (doctorId === '') {
        setOptions([]);
        return;
      }
      const body = {
        query: doctorId,
        target: 'DOCTOR-ID',
        sug_num: 50,
      };

      this.keySearchDoctorId(body);
      const response = await this.$api.searchProcedure(body).catch(() => {});
      const medicationCodes = [];

      const formatItem = (item) => ({
        code: item.code,
        label: item.code,
        description: item.english_name,
        value: item.code,
        patientNum: item.patient_num,
        recordNum: item.record_num,
      });

      function setMedData(itemMed) {
        if (itemMed) {
          medicationCodes.push(formatItem(itemMed));
        }
      }

      if (response.current.doctor_id_list) {
        response.current.doctor_id_list.forEach(setMedData);
      }

      if (response.children[0]) {
        response.children.forEach((item) => {
          item.doctor_id_list.forEach(setMedData);
        });
      }

      setOptions(medicationCodes);
    },
    genTabContent(includes, excludes) {
      return {
        id: '',
        name: 'Result',
        updateTime: dayjs().format('YYYY/MM/DD HH:mm:ss'),

        includes,
        excludes,

        counters: {
          OPD: { Patient: this.outPatient, Record: this.outRecord },
          IPD: { Patient: this.inPatient, Record: this.inRecord },
          ALL: { Patient: this.totalPatient, Record: this.totalRecord },
        },

        chartParams: {
          [ChartKey.ICD10]: {
            diagnosisRange: this.formDiagnosisRange,
          },
          [ChartKey.ICD9]: {
            diagnosisRange: this.formDiagnosisRange,
          },
          [ChartKey.MedicalOrder]: {
            category: this.medicalOrderCategory[0],
          },
          [ChartKey.Readmission]: {
            standard: getUiConfig().SearchForm.standard,
          },
        },

        data: {
          sortChartByAI: false,
          bucketByPatient: true,
          riskLevel: this.risk,
          root: { trackId: this.$api.trackId },
          trackId: uuid(),
          analyzeTab: PAGES.STATISTIC_SUMMARY,
          onlyMyPatient: this.onlyMyPatient,
        },
      };
    },
    handleSubmit(includes, excludes) {
      if (this.inPatient !== 0 || this.outPatient !== 0) {
        this.handleSearch(includes, excludes);
      }
    },
    handleSearch(includes, excludes) {
      this.trackClick('handleSubmit');

      this.addTab(cloneDeep(this.genTabContent(includes, excludes)));
      this.$api.setTrackId(uuid());
      this.$root.$emit(VUE_EVENT_NAME.GET_INTERVAL_FEEDBACK);
    },
    handleICDMapSearch(icd9value) {
      this.handleICDMapValue(icd9value);

      this.handleSearch();
    },
    async handleDiagnosisValue(value, andOr, init) {
      this.diagnosis.list = value;
      this.diagnosis.andOr = andOr;
      this.diagnosis.tags = [SearchType.Basic, SearchType.Raw, this.formDiagnosisRange];
      if (init !== true) {
        this.trackClick('handleDiagnosisValue', {
          list: value,
          andOr,
          query: this.$api.queryLog,
          selectLog: this.$api.selectLog,
          getQuery: this.$api.getQuery,
          recommendedRanking: this.$api.recommendedRanking,
        });
      }
      if (this.IcdTransfer !== true || this.lengthIcd === value.length) {
        this.updateCurrentPatient();
        this.IcdTransfer = false;
      }
    },
    async handleDiagnosisGroupMapValue(value, andOr, init) {
      this.diagnosisGroupMap.list = value;
      this.diagnosisGroupMap.andOr = andOr;
      this.diagnosisGroupMap.tags = [
        SearchType.Basic,
        SearchType.Raw,
        this.formDiagnosisRange,
        DiagnosisType.GroupAndMap,
      ];

      if (init !== true) {
        this.trackClick('handleDiagnosisGroupMapValue', {
          andOr,
          query: this.$api.queryLog,
          recommendedRanking: this.$api.recommendedRanking,
        });
      }
      this.updateCurrentPatient();
    },
    handleICDMapValue(icd9value) {
      this.diagnosisGroupMap.list = icd9value;
      this.diagnosisGroupMap.tags = [
        SearchType.Basic,
        SearchType.Raw,
        this.formDiagnosisRange,
        DiagnosisType.GroupAndMap,
      ];

      this.trackClick('handleDiagnosisGroupMapValue', {
        andOr: this.diagnosisGroupMap.andOr,
        query: this.$api.queryLog,
        recommendedRanking: this.$api.recommendedRanking,
      });

      this.updateCurrentPatient();
    },
    async handleMedicationValue(value, andOr, init) {
      this.medication.list = value;
      this.medication.andOr = andOr;
      if (init !== true) {
        this.trackClick('handleMedicationValue', {
          list: value,
          andOr,
          query: this.$api.queryLog,
          selectLog: this.$api.selectLog,
          getQuery: this.$api.getQuery,
          recommendedRanking: this.$api.recommendedRanking,
        });
      }
      this.updateCurrentPatient();
    },
    async handleProcedureValue(value, andOr, init) {
      this.procedure.list = value;
      this.procedure.andOr = andOr;
      if (init !== true) {
        this.trackClick('handleProcedureValue', {
          list: value,
          andOr,
          query: this.$api.queryLog,
          selectLog: this.$api.selectLog,
          getQuery: this.$api.getQuery,
          recommendedRanking: this.$api.recommendedRanking,
        });
      }
      this.updateCurrentPatient();
    },
    async handleMedicalOrderValue(value, andOr, init) {
      this.medicalOrder.list = value;
      this.medicalOrder.andOr = andOr;
      if (init !== true) {
        this.trackClick('handleMedicalOrderValue', {
          list: value,
          andOr,
          query: this.$api.queryLog,
          selectLog: this.$api.selectLog,
          getQuery: this.$api.getQuery,
          recommendedRanking: this.$api.recommendedRanking,
        });
      }
      this.updateCurrentPatient();
    },
    async handleDoctorIdValue(value, andOr, init) {
      const result = value.map((item) => item.code || item);
      this.doctorId.list = result;
      this.doctorId.andOr = andOr;
      if (init !== true) {
        this.trackClick('handleDoctorIdValue', {
          list: result,
          andOr,
          query: this.$api.queryLog,
          selectLog: this.$api.selectLog,
          getQuery: this.$api.getQuery,
          recommendedRanking: this.$api.recommendedRanking,
        });
      }
      this.updateCurrentPatient();
    },
    handleExcluded(values, andOr, init) {
      this.excludedDiagnosis.andOr = andOr;
      this.excludedDiagnosis.list = [];
      this.excludedDiagnosis.tags = [SearchType.Basic, SearchType.Raw, this.formDiagnosisRange];
      this.excludedMedication.andOr = andOr;
      this.excludedMedication.list = [];
      this.excludedProcedure.andOr = andOr;
      this.excludedProcedure.list = [];
      this.excludedMedicalOrder.andOr = andOr;
      this.excludedMedicalOrder.list = [];

      const excludeList = [];
      values.forEach((value) => {
        const [type, name] = value.key.split('##');
        excludeList.push({
          type,
          name,
        });
        switch (type) {
          case 'Diagnosis':
            this.excludedDiagnosis.list.push(value);
            break;
          case 'Medication':
            this.excludedMedication.list.push(value);
            break;
          case 'Procedure':
            this.excludedProcedure.list.push(value);
            break;
          case MEDICAL_ORDER:
            this.excludedMedicalOrder.list.push(value);
            break;
          default:
            break;
        }
      });
      this.updateCurrentPatient();
      if (init !== true) {
        this.trackClick('handleExcluded', {
          list: excludeList,
          andOr,
          query: this.$api.queryLog,
          selectLog: this.$api.selectLog,
          getQuery: this.$api.getQuery,
          recommendedRanking: this.$api.recommendedRanking,
        });
      }
    },
    getExcudeCondition() {
      switch (this.excludeType) {
        case 'Diagnosis':
          return this.diagnosis;
        case 'Medication':
          return this.medication;
        case 'Procedure':
          return this.procedure;
        case MEDICAL_ORDER:
          return this.medicalOrder;
        default:
          break;
      }
      return this.diagnosis;
    },
    fetchExclude(name, setOptions) {
      switch (this.excludeType) {
        case 'Diagnosis':
          return this.fetchICD(name, setOptions);
        case 'Medication':
          return this.fetchMedication(name, setOptions);
        case 'Procedure':
          return this.fetchProcedure(name, setOptions);
        case MEDICAL_ORDER:
          return this.fetchMedicalOrder(name, setOptions);
        default:
          return this.fetchICD(name, setOptions);
      }
    },
    updateCurrentPatient() {
      this.update({
        includes: this.currentConditions,
        excludes: this.currentExcludedConditions,
      });
      const conditions = this.getCountRecordConditions(this.currentConditions, this.currentExcludedConditions);
      this.countRecords({
        totalPatient: this.totalPatient,
        totalRecord: this.totalRecord,
        ...conditions,
      });
    },
    getExcludeText(condition, name, init) {
      condition.list.forEach(async (item) => {
        let type = DescriptionType.ICD10;
        switch (name) {
          case 'Diagnosis':
            if (item.type === 'icd9') {
              type = DescriptionType.ICD9;
            }
            if (item.type === 'icd10') {
              type = DescriptionType.ICD10;
            }
            break;
          case 'Medication':
            type = DescriptionType.MEDICATION;
            break;
          case 'Procedure':
            type = DescriptionType.NHI_ORDER;
            break;
          case 'MedicalOrder':
            type = DescriptionType.MEDICAL_ORDER;
            break;
          default:
            type = DescriptionType.ICD10;
            break;
        }
        try {
          this.$refs.excludeFilterComponent[0].addOption(
            {
              code: item.code,
              key: item.key,
              label: item.label,
              desc: await getName(type, item.code).catch((error) => {
                console.error(error);
              }),
            },
            init
          );
        } catch (e) {
          console.error(e);
        }
      });
    },
    getExclude(condition, init) {
      switch (condition.CONDITION) {
        case 'DIAGNOSIS':
          this.getExcludeText(condition, 'Diagnosis', init);
          break;
        case 'MEDICATION':
          this.getExcludeText(condition, 'Medication', init);
          break;
        case 'PROCEDURE':
          this.getExcludeText(condition, 'Procedure', init);
          break;
        case 'MEDICAL_ORDER':
          this.getExcludeText(condition, 'MedicalOrder', init);
          break;
        default:
          break;
      }
    },
    initializeDiagnosis(condition) {
      condition.list.forEach(async (item) => {
        try {
          this.$refs.diagnosisFilterComponent[0].addOption(
            {
              field: 'Diagnosis',
              code: item.code,
              label: item.code,
              key: item.code,
              desc:
                item.type === 'icd10'
                  ? await getName(DescriptionType.ICD10, item.code).catch((error) => {
                      console.error(error);
                    })
                  : await getName(DescriptionType.ICD9, item.code).catch((error) => {
                      console.error(error);
                    }),
              type: item.type,
            },
            true
          );
        } catch (e) {
          console.error(e);
        }
      });
      this.$refs.diagnosisFilterComponent[0].andOr = condition.andOr;
    },
    async initialize() {
      this.updateCurrentPatient();
    },
    handleChangeIcdLength(value) {
      let diagnosisRange;
      switch (value) {
        case 'primary':
          diagnosisRange = DiagnosisRanges.Primary;
          break;
        case 'all':
          diagnosisRange = DiagnosisRanges.ALL;
          break;
        case 'top-5':
          diagnosisRange = DiagnosisRanges.Top5;
          break;
        default:
          throw Error(`Not defined selection type: ${value}`);
      }
      this.setFormDiagnosisRange(diagnosisRange);
      this.updateCurrentPatient();
      this.trackClick('handleChangeIcdLength', { diagnosisRange });
    },
    mapIcdFormat(data) {
      return data.map((item) => ({
        code: item.icd,
        type: 'icd10',
      }));
    },
    getInitIcdState(transferData) {
      if (transferData.selected.length > 0) {
        return this.mapIcdFormat(transferData.selected);
      }
      if (transferData.related.length > 0) {
        return this.mapIcdFormat(transferData.related).slice(0, 1);
      }
      return [];
    },
    async initTransfer() {
      const { from } = this.$route.query;
      this.$api.setFromService(from);

      // get localStorage
      const transferData = localStorage.getItem(TRANSFER.KEY.TRANSFER_EMR);
      const cond = {
        list: [],
        andOr: 'should',
      };

      if (transferData) {
        cond.list = this.getInitIcdState(JSON.parse(transferData).code);
      }

      if (cond.list.length > 0) {
        cond.andOr = 'must';
      }

      this.IcdTransfer = true;
      this.lengthIcd = cond.list.length;

      if (cond.list.length === 0) {
        this.updateCurrentPatient();
      }

      await this.initializeDiagnosis(cond);

      this.serviceTransfer({
        fromService: from,
        condition: cond,
        transferData,
      });

      localStorage.removeItem(TRANSFER.KEY.TRANSFER_EMR);
    },
    openIcdMap() {
      if (this.ckeckMapEdit) {
        this.search = false;
        this.$refs.icdmap.openIcdMap();
        this.trackClick('openIcdMap', { search: this.search });
      }
    },
  },
};
