<template>
  <a-modal
    :visible="icdMapVisible"
    centered
    :mask="true"
    :destroyOnClose="true"
    :footer="null"
    @cancel="autoSave"
    :zIndex="9999"
    :closable="false"
  >
    <div class="title-block">
      <div class="icd-map-title-text">Mapped ICD-9</div>
      <div class="icd-map-title-text-2" style="margin-top: 10px">
        The list of mapped ICD codes from your selected diagnoses.
      </div>
      <div class="d-flex align-items-end" style="margin-top: 10px">
        <div class="icd-map-title-text-2">Total</div>
        <div class="icd-map-title-text">
          {{ `\xa0${formatedIcdMapPatient}\xa0` }}
        </div>
        <div class="icd-map-title-text-2">patients</div>
      </div>
      <div class="icd-map-title-text-3" style="margin-top: 20px">
        The mapped ICDs were selected because of following codes
      </div>
    </div>
    <div class="d-flex flex-wrap" style="padding: 0px 20px">
      <div v-for="tag in getTag" :key="tag.code" class="tag d-flex align-items-center">
        <div class="partial-dot" v-if="tag.partial"></div>
        <div>
          {{ tag.code }}
        </div>
      </div>
    </div>
    <div class="codesearch-menu" v-if="options.length !== 0">
      <div class="codesearch-menu-content">
        <b-row no-gutters class="header-row">
          <b-col cols="2" class="d-flex align-items-center">
            <b-img
              src="@/assets/images/icon_unselect.svg"
              class="icon-unselect"
              v-if="!selectAll"
              @click="handleSelectAll"
            />
            <b-img src="@/assets/images/icon_select.svg" class="icon-unselect" v-else @click="handleUnselectAll" />
            <div class="header-text-left-title font-weight-normal" style="margin-left: 10px">ICD-9</div>
          </b-col>
          <b-col class="header-text-left"> Description </b-col>
          <b-col cols="2" class="header-text-right"> Patients/Records </b-col>
        </b-row>
        <VuePerfectScrollbar @wheel.stop>
          <b-row no-gutters v-for="option in options" :title="option.description" :key="option.code" class="row-option">
            <b-col>
              <b-row
                no-gutters
                class="code-search-menu"
                :class="{ 'disable-option': option.patientNum === 0 }"
                @click="handleChange(option)"
              >
                <b-col
                  cols="2"
                  class="d-flex align-items-center"
                  :style="{
                    'margin-left': `${getExpandMargin(option.level)}px`,
                  }"
                >
                  <b-img src="@/assets/images/icon_unselect.svg" class="icon-unselect" v-if="!option.check" />
                  <b-img src="@/assets/images/icon_select.svg" class="icon-unselect" v-else />
                  <div class="code-text">{{ `${option.code}` }}</div>
                </b-col>
                <b-col
                  style="padding: 6px 0px"
                  class="d-flex align-items-center"
                  :style="{
                    flex: `0 0 calc(100%/12*8 - ${getExpandMargin(option.level)}px)`,
                    'max-width': `calc(100%/12*8 - ${getExpandMargin(option.level)}px)`,
                  }"
                >
                  <div style="max-width: calc(100% - 64px)">
                    <div class="icd-description">{{ option.description }}</div>
                    <div class="icd-description">
                      {{ option.chineseDescription }}
                    </div>
                  </div>
                  <b-img
                    src="@/assets/images/icon_expand_open.svg"
                    class="icon-expand ml-auto"
                    v-if="!option.billable && !option.expand"
                    @click.stop="openExpand(option)"
                  />
                  <b-img
                    src="@/assets/images/icon_expand_close.svg"
                    class="icon-expand ml-auto"
                    v-if="!option.billable && option.expand"
                    @click.stop="closeExpand(option)"
                  />
                </b-col>
                <b-col
                  class="icd-record-count d-flex align-items-center justify-content-end"
                  v-if="option.patientNum !== undefined"
                  cols="2"
                >
                  <div class="icd-record-count">
                    {{ formatedTemplateNumber(option.patientNum) }} /
                    {{ formatedTemplateNumber(option.recordNum) }}
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </VuePerfectScrollbar>
      </div>
    </div>
    <div v-if="!search">
      <button class="save-button" @click="clickDone">Done</button>
    </div>
    <div v-else>
      <div class="d-flex">
        <button class="cancel-button" @click="handleCancel">CANCEL</button>
        <button class="save-button" @click="clickSearch">SEARCH</button>
      </div>
      <div class="d-flex justify-content-center">
        <a-tooltip placement="top" overlayClassName="setting-icd-groudandmap-tooltip">
          <template slot="title">
            <div style="padding: 15px 20px">
              <div class="setting-icd-groudandmap-text">You can enable editing again at</div>
              <div class="d-flex align-items-center">
                <div class="setting-icd-groudandmap-text">Settings</div>
                <b-img src="@/assets/images/icon_setting_tooltip.svg" style="margin: 0px 5px" />
                <div class="setting-icd-groudandmap-text">in the top-right corner</div>
              </div>
            </div>
          </template>
          <div class="d-flex align-items-center" style="cursor: pointer" @click="onChangeIcdMap">
            <b-img src="@/assets/images/icon_unselect.svg" v-if="!icdMapchecked" />
            <b-img src="@/assets/images/icon_select.svg" v-else />
            <div class="icd-check-text">Do not show this again</div>
          </div>
        </a-tooltip>
      </div>
    </div>
  </a-modal>
</template>
<script>
/* eslint-disable no-param-reassign */
import { PatientNumberMixin, ClickMixin } from '@/mixins';
import { cloneDeep, difference, intersection } from 'lodash';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { getPatientQuery } from '@/utils/query';
import { formatIcdGroupMapItem, getIcdFormatList } from '@/utils/util';
import { mapMutations, mapState } from '../../store';
import { VUE_EVENT_NAME } from '@/utils/constants';

const ICD_MAP_SAVE_WAIT = 3000; // 3 seconds

export default {
  name: 'IcdMap',
  mixins: [PatientNumberMixin, ClickMixin],
  props: {
    currentConditions: {
      type: Array,
    },
    currentExcludedConditions: {
      type: Array,
    },
    search: {
      type: Boolean,
      default: false,
    },
    conceptCondition: Object,
  },
  components: {
    VuePerfectScrollbar,
  },
  data() {
    return {
      icdMapVisible: false,
      condition: [],
      options: [],
      selectAll: true,
      selectDescendants: [],
      unselectDescendants: [],
      candidateDescendants: [],
      formatedIcdMapPatient: 0,
      icd9Condition: [],
      savedTextTimeout: null,
      icdMapchecked: !this.$store.state.confirmIcdMap,
      tmpSelectDescendants: [],
      tmpUnselectDescendants: [],
    };
  },
  computed: {
    ...mapState('user', ['userId']),
    ...mapState(['confirmIcdMap']),
    getTag() {
      if (this.condition[0]) {
        return this.condition[0].list;
      }
      return [];
    },
  },
  mounted() {
    this.$root.$on(VUE_EVENT_NAME.SYNC_ICD9_UNSELECT_DESCENDANTS, (value) => {
      this.unselectDescendants = difference(this.unselectDescendants, value);
    });

    this.$root.$on(VUE_EVENT_NAME.RESET_ICD9_UNSELECT_DESCENDANTS, () => {
      this.selectDescendants = [];
      this.unselectDescendants = [];
    });
  },
  destroyed() {
    this.$root.$off(VUE_EVENT_NAME.SYNC_ICD9_UNSELECT_DESCENDANTS);
    this.$root.$off(VUE_EVENT_NAME.RESET_ICD9_UNSELECT_DESCENDANTS);
  },
  watch: {
    confirmIcdMap() {
      this.icdMapchecked = !this.confirmIcdMap;
    },
  },
  methods: {
    ...mapMutations(['setConfirmIcdMap']),
    ...mapMutations('condition', ['setCondition', 'setExcludedCondition']),
    async openIcdMap() {
      this.condition = cloneDeep(this.currentConditions.filter((item) => item.name === 'Diagnosis'));

      if (this.condition[0]) {
        await this.syncOptions();
      }

      this.selectAll = this.checkSelectAll();
      this.syncIcd9Condition();
      this.icdMapVisible = true;
    },
    setCheck(item) {
      item.check = item.descendants
        .concat([item.code])
        .every((itemValue) => this.selectDescendants.includes(itemValue));
    },
    async syncOptions() {
      const response = await this.$api.searchDiagnosisMerge({
        diagnosis_range: 'Any',
        query_mode: 'ICD9',
        icd_list: this.condition[0].list.map((item) => item.candidateIcd9List).flat(),
      });

      const formatItem = (item) => ({
        ...formatIcdGroupMapItem(item),
        level: 1,
        parent: '',
        check: true,
      });

      const ICDCodes = getIcdFormatList(response.results, formatItem);

      this.options = ICDCodes;
      this.selectDescendants = this.options.map((item) => item.descendants.concat([item.code])).flat();
      this.candidateDescendants = this.selectDescendants;
      this.selectDescendants = difference(this.selectDescendants, this.unselectDescendants);

      this.tmpSelectDescendants = this.selectDescendants;
      this.tmpUnselectDescendants = this.unselectDescendants;

      this.options.forEach((item) => this.setCheck(item));
    },
    handleCancel() {
      this.icdMapVisible = false;
      this.trackClick('handleCancel');

      this.selectDescendants = this.tmpSelectDescendants;
      this.unselectDescendants = this.tmpUnselectDescendants;
    },
    getExpandMargin(level) {
      return (level - 1) * 12;
    },
    async openExpand(option) {
      const response = await this.$api.searchDiagnosisExpand({
        query: '',
        diagnosis_range: this.getDiagnosisRange,
        code: option.code,
        query_mode: 'ICD9',
      });

      const formatItem = (item) => ({
        ...formatIcdGroupMapItem(item),
        check: option.check,
        level: option.level + 1,
        parent: option.code,
      });

      const ICDCodes = getIcdFormatList(response.children, formatItem);
      // Add ICDCodes to options
      // Set child child
      // Set expand is true
      const index = this.options.findIndex((item) => item.code === option.code);
      if (index !== -1) {
        this.options.splice(index + 1, 0, ...ICDCodes);
        option.child = ICDCodes;
        option.expand = true;
      }

      this.options.forEach((item) => this.setCheck(item));

      this.trackClick('openExpand');
    },
    checkSelectAll() {
      const result = this.options.map((item) => item.check);
      return !result.includes(false);
    },
    async handleChange(option) {
      if (option.patientNum === 0) {
        return;
      }

      if (!option.check) {
        this.selectDescendants = [...new Set(this.selectDescendants.concat(option.descendants))];
        this.selectDescendants = [...new Set(this.selectDescendants.concat([option.code]))];

        const response = await this.$api.searchDiagnosisMerge({
          diagnosis_range: 'Any',
          query_mode: 'ICD9',
          icd_list: this.selectDescendants,
        });

        this.selectDescendants = [
          ...new Set(response.results.map((itemValue) => itemValue.descendants.concat([itemValue.code])).flat()),
        ];
        option.check = true;
        this.unselectDescendants = difference(this.candidateDescendants, this.selectDescendants);
      } else {
        this.selectDescendants = difference(this.selectDescendants, option.descendants);
        this.selectDescendants = difference(this.selectDescendants, [option.code]);
        this.selectDescendants = difference(this.selectDescendants, option.ancestors);
        option.check = false;
        this.unselectDescendants = [...new Set(this.unselectDescendants.concat(option.descendants))];
        this.unselectDescendants = [...new Set(this.unselectDescendants.concat([option.code]))];
      }

      this.options.forEach((item) => this.setCheck(item));

      this.selectAll = this.checkSelectAll();
      this.syncIcd9Condition();

      this.trackClick('handleChange');
    },
    handleSelectAll() {
      this.selectDescendants = this.candidateDescendants;
      this.unselectDescendants = [...new Set(difference(this.unselectDescendants, this.selectDescendants))];
      this.options.forEach((item) => {
        item.check = true;
      });
      this.selectAll = true;
      this.syncIcd9Condition();

      this.trackClick('handleSelectAll');
    },
    handleUnselectAll() {
      this.unselectDescendants = [...new Set(difference(this.unselectDescendants.concat(this.selectDescendants)))];
      this.selectDescendants = [];
      this.options.forEach((item) => {
        item.check = false;
      });
      this.selectAll = false;
      this.syncIcd9Condition();

      this.trackClick('handleUnselectAll');
    },
    async syncIcd9Condition() {
      const response = await this.$api.searchDiagnosisMerge({
        diagnosis_range: 'Any',
        query_mode: 'ICD9',
        icd_list: this.selectDescendants,
      });

      const formatItem = (item) => ({
        ...formatIcdGroupMapItem(item),
      });

      const ICDCodes = getIcdFormatList(response.results, formatItem);

      const includes = cloneDeep(this.currentConditions);
      let list = [];
      includes.forEach((item) => {
        if (item.name === 'Diagnosis') {
          // eslint-disable-next-line prefer-destructuring
          list = item.list;
        }
      });

      list.forEach((listItem) => {
        listItem.icd9List = [];
        ICDCodes.forEach((icd9Item) => {
          if (intersection(listItem.candidateIcd9List, icd9Item.descendants.concat([icd9Item.code])).length !== 0) {
            listItem.icd9List = listItem.icd9List.concat(icd9Item.icd9List);
          }
        });
      });

      this.icd9Condition = list;

      this.updateIcdMapPatient();
    },
    async updateIcdMapPatient() {
      const includes = cloneDeep(this.currentConditions);
      includes.forEach((item) => {
        if (item.name === 'Diagnosis') {
          item.list = this.icd9Condition;
        }
      });
      const query = this.getQuery({
        includes,
        excludes: this.currentExcludedConditions,
      });
      const patientQuery = getPatientQuery(query);

      const result = await this.$api.search(patientQuery, 0);

      this.formatedIcdMapPatient = this.formatedTemplateNumber(result.aggregations.patientNum.value);
    },
    clickDone() {
      this.icdMapVisible = false;
      this.trackClick('clickDone');

      this.autoSave();
    },
    autoSave() {
      this.icdMapVisible = false;

      this.$root.$emit(VUE_EVENT_NAME.SAVE_TO_ICD_CODESEARCH_FILTER, this.icd9Condition);

      const result =
        this.conceptCondition.conceptType === 'include'
          ? cloneDeep(this.$store.state.condition.conditions)
          : cloneDeep(this.$store.state.condition.excludedConditions);
      const resultIndex = result.findIndex((item) => item.time === this.conceptCondition.time);
      result[resultIndex].list = cloneDeep(this.icd9Condition);

      if (this.conceptCondition.conceptType === 'include') {
        this.setCondition(result);
      } else {
        this.setExcludedCondition(result);
      }

      clearTimeout(this.savedTextTimeout);
      this.$root.$emit(VUE_EVENT_NAME.OPEN_SAVED_ICD_MAP_TEXT);
      this.savedTextTimeout = setTimeout(() => {
        this.$root.$emit(VUE_EVENT_NAME.CLOSE_SAVED_ICD_MAP_TEXT);
      }, ICD_MAP_SAVE_WAIT);

      this.trackClick('autoSave');
    },
    clickSearch() {
      this.$emit('setSearch', this.icd9Condition);
      this.icdMapVisible = false;

      this.autoSave();
    },
    onChangeIcdMap() {
      this.icdMapchecked = !this.icdMapchecked;
      this.setConfirmIcdMap(!this.icdMapchecked);

      this.$api.setSettings(this.userId, {
        'search.edit_mapped_icd': !this.icdMapchecked,
      });
      this.trackClick('onChangeIcdMap');
    },
    closeExpand(option) {
      this.options = this.options.filter((item) => !option.descendants.includes(item.code));
      option.expand = false;
      this.trackClick('closeExpand');
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .ant-modal {
  min-width: 880px;
  max-width: 880px;
  width: 880px !important;
}

/deep/ .ant-modal-body {
  padding: 20px 0px 10px 0px;
}

.title-block {
  padding: 0px 20px;
}

.icd-map-title-text {
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: #333f6b;
}

.icd-map-title-text-2 {
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #333f6b;
}

.icd-map-title-text-3 {
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #333f6b;
}

/deep/ .ps {
  max-height: 378px;
}

.codesearch-menu {
  margin-top: 10px;
}

.codesearch-menu-content {
  box-shadow: inset 0px -1px 0px #d6d9e1;
  background: #ffffff;
}

.header-row {
  height: 38px;
  padding: 10px 20px 10px 20px;
  background-color: #eff0f3;
}

.icon-unselect {
  cursor: pointer;
}

.header-text-left-title {
  height: 15px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #333f6b;
}

.header-text-left {
  height: 15px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #333f6b;
}

.header-text-right {
  height: 15px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #333f6b;
}

.row-option {
  box-shadow: inset 0px 1px 0px #d6d9e1;
  cursor: pointer;
}

.code-search-menu {
  padding: 0px 20px;
  height: 54px;
}

.disable-option {
  opacity: 0.3;
  cursor: not-allowed;
}

.code-text {
  height: 18px;
  margin: 1px 10px;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #333f6b;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icd-description {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  height: 20px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
}

.icd-record-count {
  text-align: right;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #828282;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.icon-expand {
  width: 34px;
  height: 34px;
}

.tag {
  padding: 3px 10px;
  height: 24px;
  background: #333f6b;
  border-radius: 53px;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  margin-top: 5px;
  margin-right: 5px;
}

.partial-dot {
  width: 6px;
  height: 6px;
  background: #4fc2e7;
  border-radius: 6px;
  margin-right: 5px;
}

.save-button {
  width: calc(100% - 40px);
  height: 44px;
  border-radius: 6px;
  background-color: #333f6b;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  border-width: 0 0 0 0;
  margin: 20px;
}

.cancel-button {
  width: calc(100% - 40px);
  height: 44px;
  border-radius: 6px;
  background-color: #ffffff;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333f6b;
  border-width: 0 0 0 0;
  margin: 20px;
  border: solid 1px #333f6b;
}

.setting-icd-groudandmap-text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  height: 24px;
  color: #ffffff;
}

.icd-check-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #333f6b;
  margin-left: 10px;
}
</style>
<style lang="scss">
.setting-icd-groudandmap-tooltip {
  width: 324px;
  max-width: 324px !important;
  z-index: 10000 !important;

  .ant-tooltip-arrow {
    display: none;
  }

  .ant-tooltip-inner {
    background: #4d8edc;
    border-radius: 6px;
    color: #ffffff;
  }
}
</style>
