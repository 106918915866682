<template>
  <div v-click-outside="closeCopyLinkMenu">
    <div
      class="button-save-query d-flex align-items-center justify-content-center"
      @click="openCopyLinkMenu"
      :class="copyLinkMenu ? 'enable-button' : ''"
    >
      <b-img src="@/assets/images/icon_unsave.svg" class="save-icon" v-if="tab.id === ''" />
      <span class="save-query-text" v-if="tab.id === ''"> Save </span>
      <b-img src="@/assets/images/icon_save.svg" class="save-icon" v-if="tab.id !== ''" />
      <span class="save-query-text" v-if="tab.id !== ''"> Update </span>
    </div>
    <div class="menu-center" v-if="copyLinkMenu">
      <b-row no-gutters>
        <b-col>
          <b-row no-gutters>
            <b-col class="title-text">{{ getTitleText }}</b-col>
            <b-col>
              <b-img src="@/assets/images/icon_close.svg" class="icon-close" @click="closeCopyLinkMenu(0)" />
            </b-col>
          </b-row>
          <b-row no-gutters>
            <b-col class="name-text">Name this search result</b-col>
          </b-row>
          <b-row no-gutters>
            <b-col>
              <b-input
                placeholder="Search result name"
                v-model="nameText"
                class="name-input"
                ref="saveQueryButtonInput"
              ></b-input>
            </b-col>
          </b-row>
          <b-row no-gutters>
            <b-col>
              <b-button variant="outline-primary" class="copy-link-button" @click="saveQuery">{{
                getButtonText
              }}</b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import dayjs from 'dayjs';

import { ClickMixin } from '@/mixins';
import ClickOutside from 'vue-click-outside';
import { getUiConfig } from '@/utils/uiConfig';
import { mapState, mapMutations } from '../../../store';
import { VUE_EVENT_NAME } from '@/utils/constants';

const LINK_SAVED_WAIT = 3000; // 3 seconds

export default {
  name: 'SaveQuery',
  mixins: [ClickMixin],
  data() {
    return {
      nameText: 'New 1',
      copyLinkMenu: false,
      savedCondition: '',
      savedTextTimeout: null,
      errorMenuTimeout: null,
    };
  },
  directives: {
    ClickOutside,
  },
  props: {
    uniqueIndex: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    this.$root.$on(VUE_EVENT_NAME.BV_DROPDOWN_SHOW, () => {
      this.copyLinkMenu = false;
    });
  },
  destroyed() {
    this.$root.$off(VUE_EVENT_NAME.BV_DROPDOWN_SHOW);
  },
  computed: {
    ...mapState('session', ['tabs']),
    tab() {
      return this.tabs[this.uniqueIndex];
    },
    getButtonText() {
      if (this.tab.id === '') {
        return 'SAVE';
      }
      return 'UPDATE';
    },
    getTitleText() {
      if (this.tab.id === '') {
        return 'Save';
      }
      return 'Update saved result';
    },
    getMenuPosition() {
      return getUiConfig().Analyze.export;
    },
  },
  methods: {
    ...mapMutations('session', ['updateTab', 'setTabUndirty']),
    initState() {
      this.nameText = this.tab.name;
    },
    openCopyLinkMenu() {
      this.copyLinkMenu = true;
      this.initState();
      this.trackClick('openCopyLinkMenu');
      this.$nextTick(() => {
        this.$refs.saveQueryButtonInput.select();
      });
    },
    closeCopyLinkMenu(index) {
      this.copyLinkMenu = false;
      this.initState();
      if (index === 0) {
        this.trackClick('closeCopyLinkMenu');
      }
    },
    async saveQuery() {
      try {
        this.updateTab({
          index: this.uniqueIndex,
          name: this.nameText,
          updateTime: dayjs().format('YYYY/MM/DD HH:mm:ss'),
        });

        this.closeCopyLinkMenu();
        if (this.tab.id === '') {
          this.trackClick('saveQuery', {
            value: 'addTabLimitation',
            tab: this.tab,
            nameText: this.nameText,
            type: 'save',
          });

          const { url } = await this.$api.saveQuery(this.nameText, this.tab, 'save');
          this.tab.id = url;
          this.updateTab({
            index: this.uniqueIndex,
            id: this.tab.id,
          });
        } else {
          this.trackClick('saveQuery', {
            value: 'addTabLimitation',
            tab: this.tab,
            nameText: this.nameText,
            type: 'update',
          });
          await this.$api.updateQuery(this.tab.id, this.tab).catch((error) => {
            console.error(error);
          });
        }
        this.setTabUndirty(this.uniqueIndex);
        this.$root.$emit(VUE_EVENT_NAME.REFRESH_SAVED_LIST);

        clearTimeout(this.copiedTextTimeout);
        this.$root.$emit(VUE_EVENT_NAME.OPEN_SAVED_TEXT);
        this.savedTextTimeout = setTimeout(() => {
          this.$root.$emit(VUE_EVENT_NAME.CLOSE_SAVED_TEXT);
        }, LINK_SAVED_WAIT);
      } catch (e) {
        clearTimeout(this.errorMenuTimeout);
        this.$root.$emit(VUE_EVENT_NAME.OPEN_ERROR_MENU, 'Save query failed!', `${e}`);
        this.errorMenuTimeout = setTimeout(() => {
          this.$root.$emit(VUE_EVENT_NAME.CLOSE_ERROR_MENU);
        }, LINK_SAVED_WAIT);
        throw Error(`Save query failed! ${e}`);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.button-save-query {
  border-radius: 6px;
  border: solid 1px #333f6b;
  background-color: white;
  margin-right: 10px;
  padding: 8px 16px;
  cursor: pointer;
}

.save-query-text {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333f6b;
}

.save-icon {
  margin: 0px 10px 0px 0px;
}

.enable-button {
  background-color: #eaf2fd !important;
  cursor: pointer;
}

.menu-center {
  position: absolute;
  width: 420px;
  height: 200px;
  border-radius: 6px;
  box-shadow: 0 4px 10px 0 rgba(51, 63, 107, 0.2);
  border: solid 1px #d6d9e1;
  background-color: #ffffff;
  padding: 20px 25px 20px 25px;
  z-index: 10;
  margin: 8px 0px 0px -320px;
}
</style>
