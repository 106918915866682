import { Component, Vue, Prop } from 'vue-property-decorator';
import { mapState } from '../store';
import { ConditionData } from '../utils/conditions/core';

@Component<TemplateValue>({
  computed: {
    ...mapState('condition', ['conditions', 'extendConditions', 'excludedConditions']),
  },
})
export default class TemplateValue extends Vue {
  @Prop({
    type: String,
    default: ', ',
  })
  readonly concater!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  readonly deletable!: string;

  @Prop({
    type: Array,
    required: true,
  })
  readonly refCond!: ConditionData[];

  @Prop()
  conditionName: string | undefined;
}
