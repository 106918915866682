<template>
  <div>
    <div v-if="noResult" class="no-result">
      <div class="incidencerate-table-title-text ml-20px mt-20px" style="position: absolute">
        Cox Regression Summary
      </div>
      <div class="no-result-wrapper">
        <div class="mb-10px">
          <b-img src="@/assets/images/icon_no_results.svg" />
        </div>
        <div class="no-results-text">No results</div>
        <div class="d-flex align-items-center edit-button mt-40px" @click="clickEditCovariate">
          <b-img src="@/assets/images/icon_edit_event_result_white.svg" class="mr-5px" />
          <div class="edit-text">Adjust Covariate</div>
        </div>
      </div>
    </div>
    <div v-else-if="loading" class="no-result">
      <div class="incidencerate-table-title-text ml-20px mt-20px" style="position: absolute">
        Cox Regression Summary
      </div>
      <div class="no-result-wrapper">
        <a-progress
          type="circle"
          :percent="progressPercent"
          :width="70"
          :strokeWidth="8"
          strokeColor="#4D8EDC"
          trailColor="#E0E0E0"
        >
          <template #format="percent">
            <span class="progress-text">{{ `${percent}%` }}</span>
          </template></a-progress
        >
        <div class="progress-text mt-10px" v-if="requestInProgress">Analyzing...</div>
        <div class="progress-text mt-10px" v-else>Queuing...</div>
      </div>
    </div>
    <div class="incidencerate-table" v-else>
      <b-row no-gutters>
        <b-col>
          <div class="incidencerate-table-title-text">Cox Regression Summary</div>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col>
          <a-tabs id="patient-list-tab" type="card" @change="switchTab" :activeKey="tabName" style="margin-top: 20px">
            <a-tab-pane :key="PAGES.ADJUSTED" :closable="false">
              <template slot="tab">
                <div class="custom-tab d-flex align-items-center">
                  <div class="border-fixer">{{ PAGES.ADJUSTED }}</div>
                </div>
              </template>
              <div class="mt-20px">
                <a-table
                  tableLayout="auto"
                  :columns="incidencerateColumn"
                  :data-source="incidencerateData.slice(0, VIEW_MORE_THRESHOLD + 6)"
                  :pagination="false"
                  :rowClassName="getrowClassName"
                >
                </a-table>
              </div>
            </a-tab-pane>
            <a-tab-pane :key="PAGES.CRUDE" :closable="false">
              <template slot="tab">
                <div class="custom-tab d-flex align-items-center">
                  <div class="border-fixer">{{ PAGES.CRUDE }}</div>
                </div>
              </template>
              <div class="mt-20px">
                <a-table
                  tableLayout="auto"
                  :columns="incidencerateColumn"
                  :data-source="incidencerateCrudeData.slice(0, VIEW_MORE_THRESHOLD + 6)"
                  :pagination="false"
                  :rowClassName="getrowClassName"
                >
                </a-table>
              </div>
            </a-tab-pane>
          </a-tabs>
          <div class="cover-view-more" v-if="showViewMore">
            <div class="gradient-cover"></div>
            <div class="view-more-text" @click="clickViewMore">View more</div>
          </div>
          <div class="hint-text-major">*Age, height, weight, and BMI are included as continuous variables</div>
          <div class="hint-text-major">
            *Lab covariates are included as categorical variables (people meet the criteria vs. people fail the
            criteria)
          </div>
        </b-col>
      </b-row>
    </div>
    <div>
      <a-modal
        v-model="visible"
        class="view-all-modal"
        destroyOnClose
        :footer="null"
        centered
        width="1000px"
        :zIndex="9999"
      >
        <template #closeIcon>
          <img src="@/../public/img/icon_close.svg" alt="icon_close" />
        </template>
        <template #title>
          <div class="title">Cox Regression Summary</div>
        </template>
        <a-table
          tableLayout="auto"
          :columns="incidencerateColumn"
          :data-source="incidencerateData"
          :pagination="false"
          :rowClassName="getrowClassName"
        >
        </a-table>
        <div class="hint-text-major">*Age, height, weight, and BMI are included as continuous variables</div>
        <div class="hint-text-major">
          *Lab covariates are included as categorical variables (people meet the criteria vs. people fail the criteria)
        </div>
      </a-modal>
    </div>
    <div>
      <a-modal
        v-model="crudeVisible"
        class="view-all-modal"
        destroyOnClose
        :footer="null"
        centered
        width="1000px"
        :zIndex="9999"
      >
        <template #closeIcon>
          <img src="@/../public/img/icon_close.svg" alt="icon_close" />
        </template>
        <template #title>
          <div class="title">Cox Regression Summary</div>
        </template>
        <a-table
          tableLayout="auto"
          :columns="incidencerateColumn"
          :data-source="incidencerateCrudeData"
          :pagination="false"
          :rowClassName="getrowClassName"
        >
        </a-table>
        <div class="hint-text-major">*Age, height, weight, and BMI are included as continuous variables</div>
        <div class="hint-text-major">
          *Lab covariates are included as categorical variables (people meet the criteria vs. people fail the criteria)
        </div>
      </a-modal>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from '@/store';
import column from '@/components/byEvent/result/column.js';
import { ClickMixin } from '@/mixins';
import { delay, calcProgress, transformNumber } from '@/utils/util';
import { SLA_DEFINITION } from '@/utils/cohortProgressConfig';
import { API, VUE_EVENT_NAME } from '@/utils/constants';

const POLLING_PERIOD = 3000;
const VIEW_MORE_THRESHOLD = 1;

export default {
  name: 'CoxRegression',
  mixins: [ClickMixin],
  props: {
    uniqueIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      incidencerateColumn: column.cox_regression,
      incidencerateData: [],
      incidencerateCrudeData: [],
      loading: false,
      VIEW_MORE_THRESHOLD,
      visible: false,
      crudeVisible: false,
      noResult: false,
      progressPercent: 0,
      requestInProgress: false,
      queryData: {},
      PAGES: {
        CRUDE: 'Crude',
        ADJUSTED: 'Adjusted',
      },
      tabName: 'Adjusted',
    };
  },
  computed: {
    ...mapState('cohortSession', ['tabs']),
    tab() {
      return this.tabs[this.uniqueIndex];
    },
    experimentalName() {
      return this.tab.eventSearch.experimental.name;
    },
    controlName() {
      return this.tab.eventSearch.control.name;
    },
    eventSearch() {
      return this.tab.eventSearch;
    },
    showViewMore() {
      if (this.tabName === this.PAGES.ADJUSTED) {
        return this.incidencerateData.length > VIEW_MORE_THRESHOLD + 5;
      }
      return this.incidencerateCrudeData.length > VIEW_MORE_THRESHOLD + 5;
    },
  },
  mounted() {
    this.updateData();

    this.fn = () => {
      this.getPatientListData(true);
    };
    this.$root.$on(VUE_EVENT_NAME.SAVE_ANALYSIS_RECORD, this.fn);
  },
  beforeDestroy() {
    this.$root.$off(VUE_EVENT_NAME.SAVE_ANALYSIS_RECORD, this.fn);
  },
  watch: {
    uniqueIndex() {
      this.updateData();
    },
  },
  methods: {
    ...mapMutations('cohortSession', ['updateTab']),
    transformNumber,
    async pollingData() {
      let result = { result: 'processing' };
      while (result.result === 'processing' && result.result !== 'fail') {
        result = await this.getPatientListData();
        if (result.result === 'processing') {
          await delay(POLLING_PERIOD);
        }
      }

      if (result.result === 'fail' || result.summaries.length === 0) {
        this.noResult = true;
      } else {
        this.noResult = false;
      }

      this.coxRegressionSummaries = result;
      return Promise.resolve();
    },
    async updateProgressPercent() {
      while (this.loading) {
        this.requestInProgress = this.$api.checkInProgress(this.queryData, API.PATIENT_COXMODEL);
        if (this.requestInProgress) {
          this.progressPercent = calcProgress(
            JSON.parse(this.requestInProgress).startTime,
            SLA_DEFINITION.PATIENT_HAZARD_RATIO
          );

          this.updateTab({
            index: this.uniqueIndex,
            coxRegressionRequestInProgress: true,
            coxRegressionProgressPercent: this.progressPercent,
          });
        }
        await delay(100);
      }
      this.updateTab({
        index: this.uniqueIndex,
        coxRegressionRequestInProgress: false,
        coxRegressionProgressPercent: 100,
      });
    },
    getRecoveryParams() {
      if (this.tab.tabType === 'history' || this.tab.tabType === 'save') {
        return {
          get_latest: this.tab.getLatest,
          url: this.tab.url || this.tab.id,
        };
      }
      return {
        get_latest: true,
        url: this.tab.url || this.tab.id,
      };
    },
    async updateData() {
      try {
        this.loading = true;

        this.progressPercent = 0;
        this.requestInProgress = false;
        this.updateProgressPercent();
        await this.pollingData();
        this.setCoxRegressionData();
      } catch (error) {
        console.error(error);
        this.noResult = true;
      }
      this.progressPercent = 0;
      this.requestInProgress = false;
      this.loading = false;
      this.updateTab({
        index: this.uniqueIndex,
        patientListRequestInProgress: false,
        patientListProgressPercent: 100,
      });
    },
    async getPatientListData(saveAnalysisRecord = false) {
      const params = {
        searchState: this.eventSearch,
      };
      if (saveAnalysisRecord) {
        const query = {
          ...params,
          ...{
            get_latest: true,
            url: this.tab.id,
          },
        };
        this.queryData = query;
        return this.$api.getCoxModelData(query);
      }
      const query = {
        ...params,
        ...this.getRecoveryParams(),
      };
      this.queryData = query;
      return this.$api.getCoxModelData(query);
    },
    setTabCoxRegressionData(data, item, index) {
      const notBaseData = item.groups.find((baseItem) => !baseItem.is_reference);
      if (notBaseData) {
        data.push({
          key: `${notBaseData.name}-${index}-nobase`,
          name: item.name,
          groupName: item.name === 'Group' || item.name === 'Gender' ? notBaseData.name : '',
          hazard_ratio: this.transformNumber(notBaseData.hazard_ratio),
          '95CI': `${this.transformNumber(notBaseData['95_percent_confidence_interval'].lower)}~${this.transformNumber(
            notBaseData['95_percent_confidence_interval'].upper
          )}`,
          PValue: this.transformNumber(notBaseData.p_value),
        });
      }

      const baseData = item.groups.find(
        (baseItem) => baseItem.is_reference && (item.name === 'Group' || item.name === 'Gender')
      );
      if (baseData) {
        data.push({
          key: `${baseData.name}-${index}-baseData`,
          name: '',
          groupName: baseData.name,
          hazard_ratio: this.transformNumber(baseData.hazard_ratio),
          '95CI': '-',
          PValue: '-',
        });
      }
    },
    setCoxRegressionData() {
      this.incidencerateCrudeData = [];
      this.incidencerateData = [];
      this.coxRegressionSummaries.summaries.forEach((item, index) => {
        if (item.type === 'crude') {
          this.setTabCoxRegressionData(this.incidencerateCrudeData, item, index);
        } else {
          this.setTabCoxRegressionData(this.incidencerateData, item, index);
        }
      });
    },
    clickViewMore() {
      if (this.tabName === this.PAGES.ADJUSTED) {
        this.visible = true;
      } else {
        this.crudeVisible = true;
      }

      this.cohortStudyTrackClick('clickViewMore');
    },
    clickEditCovariate() {
      this.$emit('clickEditCovariate');
    },
    getrowClassName(record) {
      return record.hazard_ratio === 'NA' || record['95CI'] === 'NA~NA' || record.PValue === 'NA' ? 'na-text' : '';
    },
    switchTab(name) {
      this.tabName = name;
    },
  },
};
</script>
<style scoped>
#patient-list-tab >>> .ant-tabs-tab-active > .custom-tab {
  font-weight: bold;
  border-bottom: 5px solid #4d8edc !important;
  position: relative;
  top: -2px;
  color: #4d8edc;
}

#patient-list-tab >>> .ant-tabs-tab-active > .custom-tab > .border-fixer {
  position: relative;
  top: 2px;
}

#patient-list-tab >>> .ant-tabs-tab {
  margin-right: 10px;
  height: 40px;
  color: #4d8edc;
  background: #ffffff !important;
  border-color: #ffffff !important;
  padding-left: 0px;
}
</style>
<style lang="scss" scoped>
.incidencerate-table {
  padding: 20px 20px;
  box-shadow: 0px 4px 16px #e0e0e0, 0px 16px 16px rgba(157, 205, 245, 0.1);
  border-radius: 8px;
}

.incidencerate-table-title-text {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #212121;
}

/deep/ .ant-table-column-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #757575;
}

.loading-spin {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
}

.loading-disable {
  opacity: 0.5;
}

/deep/.ant-table-row td:nth-child(1) {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #9e9e9e;
}

/deep/.ant-table-row td:nth-child(2) {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #757575;
}

/deep/.ant-table-row td:nth-child(n + 3) {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #212121;
}

/deep/.na-text td {
  color: #9e9e9e !important;
}

.view-more-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #4d8edc;
  padding: 4px;
  text-align: center;
  background-color: #ffffff;
  cursor: pointer;
}

.gradient-cover {
  height: 60px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  background: -moz-linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  background: -o-linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
}

.cover-view-more {
  position: absolute;
  width: inherit;
  margin-top: -85px;
}

.no-result {
  height: 300px;
  box-shadow: 0px 4px 16px #e0e0e0, 0px 16px 16px rgba(157, 205, 245, 0.1);
  border-radius: 8px;
}

.no-result-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.background {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}

.edit-button {
  padding: 8px 16px;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  background: #4d8edc;
}

.edit-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}

.no-results-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #9e9e9e;
}

.na-hint-text {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #9e9e9e;
}

.gray-title {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #9e9e9e;
}

.row-title {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #757575;
}

.progress-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

/deep/ .ant-tabs-nav {
  margin-left: 0px !important;
}

/deep/ .ant-tabs-nav-wrap {
  position: relative !important;
  margin-top: 0px !important;
}

/deep/.ant-tabs-bar {
  border-bottom: 0;
}

.hint-text-major {
  font-size: 14px;
  line-height: 17px;
  color: #757575;
  padding-top: 5px;
  background: #ffffff;
}
</style>
