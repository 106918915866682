<template>
  <a-modal
    v-model="visible"
    destroyOnClose
    :footer="null"
    centered
    width="896px"
    :closable="false"
    @ok="close"
    :zIndex="9999"
  >
    <div class="lab-histogram">
      <a-row>
        <a-col>
          <a-row class="title-text">
            <a-col>{{ title }}</a-col>
          </a-row>
          <a-row class="second-title-text">
            <a-col>
              <span class="hint"> 門住診初次檢驗值 </span>
            </a-col>
          </a-row>
          <a-row>
            <span class="yaxis-text">病<br />歷<br />數</span>
            <LabHistogramChart
              :CHSPECI="exam"
              :CHHEAD="title"
              :unit="unit"
              ref="LabHistogramChart"
              :uniqueIndex="uniqueIndex"
            />
          </a-row>
          <a-row>
            <a-col>
              <div class="xaxis-text">{{ unit }}</div>
            </a-col>
          </a-row>
          <a-row class="title-text" style="margin-bottom: 27px">
            <a-col>Filter Lab Value Range</a-col>
          </a-row>
          <a-row class="title-text" style="margin-bottom: 47px">
            <a-col :span="12">
              <div class="content-text">Greater than or equal to</div>
              <a-input v-model="greaterThan" class="lab-input" placeholder="≧" />
              <div class="content-text">{{ unit }}</div>
            </a-col>
            <a-col :span="12">
              <div class="content-text">Less than or equal to</div>
              <a-input v-model="lessThan" class="lab-input" placeholder="≦" />
              <div class="content-text">{{ unit }}</div>
              <span v-if="notValidate" style="color: red"></span>
            </a-col>
          </a-row>
          <a-row>
            <a-col>
              <a-button
                ghost
                type="primary"
                class="lab-button submit-filter"
                :class="notValidate ? 'block' : ''"
                @click="addLabFilter"
              >
                FILTER
              </a-button>
              <a-button ghost type="primary" class="lab-button cancel-button" style="margin-right: 30px" @click="close">
                CANCEL
              </a-button>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </div>
  </a-modal>
</template>
<script>
import { ClickMixin, TrackTimeMixin, UpdateMixin } from '@/mixins';
import LabHistogramChart from '@/components/charts/LabHistogramChart.vue';
import { ConditionName, LogicOp } from '@/utils/conditions/core/Interface';
import { SearchType } from '@/utils/conditions/core/Tags';
import { mapState, mapMutations } from '../../store';

export default {
  mixins: [TrackTimeMixin, ClickMixin, UpdateMixin],
  name: 'LabHistogram',
  props: {
    exam: {
      type: String,
    },
    title: {
      type: String,
    },
    unit: {
      type: String,
    },
    uniqueIndex: {
      type: Number,
      default: 0,
    },
  },
  components: {
    LabHistogramChart,
  },
  data() {
    return {
      visible: false,
      greaterThan: '',
      lessThan: '',
    };
  },
  computed: {
    ...mapState('session', ['tabs']),
    tab() {
      return this.tabs[this.uniqueIndex];
    },
    notValidate() {
      const isNumber = function isNumber(value) {
        // eslint-disable-next-line no-restricted-globals
        return typeof value === 'number' || isFinite(value);
      };
      if (
        isNumber(this.lessThan) &&
        isNumber(this.greaterThan) &&
        parseFloat(this.greaterThan) > parseFloat(this.lessThan)
      ) {
        return true;
      }
      return this.lessThan === '' && this.greaterThan === '';
    },
  },
  methods: {
    ...mapMutations('session', ['updateTab']),
    close() {
      this.visible = false;
      this.greaterThan = '';
      this.lessThan = '';
    },
    show() {
      this.greaterThan = '';
      this.lessThan = '';
      this.visible = true;
    },
    addLabFilter() {
      if (this.notValidate) {
        return;
      }

      const labCondition = {
        name: ConditionName.Lab,
        tags: [SearchType.Basic, SearchType.Additional],
        andOr: LogicOp.And,
        start: this.greaterThan,
        end: this.lessThan,
        exam: this.exam,
        lab: this.title,
        unit: this.unit,
        ranges: [{ end: this.lessThan, start: this.greaterThan }],
      };

      this.updateTab({
        index: this.uniqueIndex,
        includes: this.tab.includes.concat(labCondition),
      });
      const options = {
        includes: this.tab.includes,
        excludes: this.tab.excludes,
        uniqueIndex: this.uniqueIndex,
      };

      this.update(options);

      this.trackClick('addLabFilter', { ...options });
      this.close();
      this.greaterThan = '';
      this.lessThan = '';
    },
  },
};
</script>
<style scoped>
.lab-histogram {
  padding: 6px 16px 6px 16px;
}

.title-text {
  height: 20px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333f6b;
}

.content-text {
  height: 20px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333f6b;
  display: inline-block;
  margin-right: 11px;
}

.lab-input {
  width: 101px;
  height: 33.2px;
  border-radius: 3px;
  border: solid 1px #d6d9e1;
  background-color: #ffffff;
  display: inline-block;
  margin-right: 11px;
  padding-left: 16px;
}

.lab-button {
  float: right;
}

.cancel-button {
  width: 164px;
  height: 42px;
  border-radius: 6px;
  border: solid 1px #333f6b !important;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333f6b !important;
}

.submit-filter {
  width: 150px;
  height: 42px;
  border-radius: 6px;
  background-color: #333f6b !important;
  border: solid 1px #333f6b !important;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff !important;
}

.block {
  position: relative;
  background: transparent;
  opacity: 0.5;
  cursor: default !important;
}

.block:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}

.second-title-text {
  margin-top: 10px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #a3a3a3;
}

.xaxis-text {
  float: right;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #a3a3a3;
  margin-right: 52px;
}

.yaxis-text {
  position: absolute;
  margin-top: 201px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  color: #a3a3a3;
}
</style>
