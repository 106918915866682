import BasicChartConfig from '@/components/charts/core/BasicChartConfig';
import { PRECISION_THRESHOLD, UICONFIG } from '@/utils/constants';
import { sourceOption, CodeViewAllOption } from '@/utils/util';
import {
  ConditionName,
  DiagnosisRanges,
  LogicOp,
  MedicalTermType,
  SearchType,
  TermCondition,
  TermConditionData,
} from '../../utils/conditions/core';
import { ChartItem, ChartKey, RawItem } from './core/Interface';
import { SELECTED_COLOR } from './utils/utils';

interface DiagnosisRawItem extends RawItem {
  patient_count: {
    value: number;
  };
}

export default class DiagnosisConfig extends BasicChartConfig<DiagnosisRawItem> {
  constructor(key: ChartKey) {
    super(key);

    this.hasViewAll = true;
    const options = { ...sourceOption };
    this.merge(options);
  }

  protected setAxisData(): void {
    this.unset('xAxis.name');
    this.unset('yAxis.data');
    this.merge({
      xAxis: {
        name: this.bucketByPatient ? 'Patient' : 'Record',
      },
      yAxis: {
        data: this.data.map((item) => item.key),
      },
    });
  }

  public createViewAllConfig(): DiagnosisConfig {
    const config = new DiagnosisConfig(this.chartKey);
    config.resultSize = UICONFIG.VIEW_ALL_SIZE;
    config.bucketByPatient = this.bucketByPatient; // Copy over previous bucketing status.
    config.merge({ ...CodeViewAllOption });
    return config;
  }

  protected createChartItem(rawItem: DiagnosisRawItem): ChartItem {
    const {
      key,
      doc_count,
      patient_count: { value },
    } = rawItem;
    const [code, name] = key.split('##');

    const condList = this.params.includes.additions.with(ConditionName.Diagnosis);

    let color = '#333f6b';
    condList.forEach((condition) => {
      const cond = condition as TermCondition;
      if (cond.hitCode(code) && cond.hasTag(this.rangeType)) {
        color = SELECTED_COLOR;
      }
    });

    const targetValue = this.bucketByPatient ? value : doc_count;

    return {
      key: code,
      value: targetValue,
      code,
      name,
      color,
      percentage: this.calculatePercentage(targetValue),
    };
  }

  protected createConditionFromItem(chartItem: ChartItem): TermConditionData {
    const type = this.chartKey === ChartKey.ICD10 ? MedicalTermType.ICD10 : MedicalTermType.ICD9;
    return {
      name: ConditionName.Diagnosis,
      tags: [SearchType.Basic, SearchType.Additional, this.rangeType],
      andOr: LogicOp.And,
      list: [{ code: chartItem.code, type }],
    };
  }

  private get rangeType(): DiagnosisRanges {
    const { selection } = this.params.chartParams;
    switch (selection) {
      case 'all':
        return DiagnosisRanges.ALL;
      case 'top-5':
        return DiagnosisRanges.Top5;
      case 'primary':
      default:
        return DiagnosisRanges.Primary;
    }
  }

  private get icdRange(): string {
    switch (this.rangeType) {
      case DiagnosisRanges.Primary:
        return 'MAIN_';
      case DiagnosisRanges.ALL:
        return '20_';
      case DiagnosisRanges.Top5:
        return '';
      default:
        return '20_';
    }
  }

  private get fieldName(): string {
    let ICDType = '';
    if (this.chartKey === ChartKey.ICD9) {
      ICDType = '9';
    }

    return `ICD${ICDType}_${this.icdRange}ENG_FULL`;
  }

  protected get aggsQuery(): Record<string, unknown> {
    const orderField = this.getOrderField();
    return {
      terms: {
        field: this.fieldName,
        size: this.resultSize,
        shard_size: this.resultSize * 2 + 10,
        order: {
          [orderField]: 'desc',
        },
      },
      aggs: {
        patient_count: {
          cardinality: {
            field: 'CHART_NO',
            precision_threshold: PRECISION_THRESHOLD,
          },
        },
      },
    };
  }
}
