<template>
  <div style="background: #ffffff">
    <div class="title-text mb-20px">My Concepts</div>
    <div v-if="initState && saveList.length === 0">
      <div class="title-detail-text text-center">Design and use your personal concepts</div>
      <div class="d-flex justify-content-center" style="margin-top: 6px">
        <b-img src="@/assets/images/icon_add_concept.png" style="width: 72px; height: 72px" />
      </div>
      <div class="d-flex justify-content-center mt-20px">
        <div class="second-title-text">Create Concept</div>
      </div>
      <div class="d-flex justify-content-center mt-20px">
        <b-img src="@/assets/images/icon_left_arrow.svg" class="mr-10px" />
        <div class="d-flex align-items-center">
          <div class="title-detail-text">Click</div>
          <b-img src="@/assets/images/icon_emr_search_save_concept.svg" class="ml-5px mr-5px" />
          <div class="title-detail-text">to save codes in</div>
        </div>
      </div>
      <div class="title-detail-text text-center">criteria box as a new concept</div>
    </div>
    <div v-else>
      <a-input
        class="search-name-input"
        v-model="searchName"
        placeholder="Search"
        allow-clear
        :style="{ width: restore ? '780px' : 'auto' }"
      >
        <b-img src="@/assets/images/icon_search_portal_record_enable.svg" slot="prefix" />
      </a-input>
      <template v-if="!restore">
        <div class="mt-40px" v-if="getDiagnosisList.length !== 0">
          <div class="d-flex mb-10px">
            <div class="collapse-title-text" @click="changeDiagnosisVisible(!diagnosisVisible)" style="cursor: pointer">
              Diagnosis
            </div>
            <b-img
              src="@/assets/images/icon_saved_concept_collapse.svg"
              class="ml-5px"
              :class="{ rotateimg180: !diagnosisVisible }"
              @click="changeDiagnosisVisible(!diagnosisVisible)"
              style="cursor: pointer"
            />
          </div>
          <b-collapse v-model="diagnosisVisible" class="mt-20px">
            <div v-for="(concept, index) of getDiagnosisList" :key="index" class="mb-10px concept-block">
              <div class="d-flex align-items-center justify-content-between">
                <div class="concept-name-text">{{ concept._source.displayName }}</div>
                <div class="d-flex align-items-center">
                  <b-img
                    src="@/assets/images/icon_emr_search_concept_name_edit.svg"
                    class="icon-img mr-10px"
                    title="Edit"
                    @click="clickEditTag(concept)"
                    v-if="false"
                  />
                  <b-img
                    src="@/assets/images/icon_emr_search_delete_concept.svg"
                    class="icon-img"
                    title="Delete"
                    @click="clickDeleteTag(concept)"
                  />
                </div>
              </div>
              <div class="d-flex mt-10px" style="overflow: hidden">
                <div
                  v-for="(tag, tagIndex) in concept._source.query.list"
                  :key="`tag-${tagIndex}`"
                  class="d-flex align-items-center capsule-abbr"
                  :class="getCapsuleAbbreviationClass(tag.field)"
                  :style="{ 'margin-right': tagIndex !== concept._source.query.list.length - 1 ? '12px' : '0px' }"
                >
                  <template v-if="concept._source.query.name === 'Diagnosis'">
                    <ICDCapsule :path="[]" :tag="tag" :deleteFunction="false" />
                  </template>
                  <template v-else>
                    <div class="capsule-abbr-text mr-10px" :title="`${tag.code} ${tag.description}`">
                      {{ getCapsuleAbbreviation(tag.field) }}
                    </div>
                    <div class="capsule-code" :title="`${tag.code} ${tag.description}`">{{ tag.code }}</div>
                  </template>
                </div>
              </div>
            </div>
          </b-collapse>
        </div>
        <div class="mt-40px" v-if="getMedicationList.length !== 0">
          <div class="d-flex mb-10px">
            <div
              class="collapse-title-text"
              @click="changeMedicationVisible(!medicationVisible)"
              style="cursor: pointer"
            >
              Medication
            </div>
            <b-img
              src="@/assets/images/icon_saved_concept_collapse.svg"
              class="ml-5px"
              :class="{ rotateimg180: !medicationVisible }"
              @click="changeMedicationVisible(!medicationVisible)"
              style="cursor: pointer"
            />
          </div>
          <b-collapse v-model="medicationVisible">
            <div v-for="(concept, index) of getMedicationList" :key="index" class="mb-10px concept-block">
              <div class="d-flex align-items-center justify-content-between">
                <div class="concept-name-text">{{ concept._source.displayName }}</div>
                <div class="d-flex align-items-center">
                  <b-img
                    src="@/assets/images/icon_emr_search_concept_name_edit.svg"
                    class="icon-img mr-10px"
                    title="Edit"
                    @click="clickEditTag(concept)"
                    v-if="false"
                  />
                  <b-img
                    src="@/assets/images/icon_emr_search_delete_concept.svg"
                    class="icon-img"
                    title="Delete"
                    @click="clickDeleteTag(concept)"
                  />
                </div>
              </div>
              <div class="d-flex mt-10px" style="overflow: hidden">
                <div
                  v-for="(tag, tagIndex) in concept._source.query.list"
                  :key="`tag-${tagIndex}`"
                  class="d-flex align-items-center capsule-abbr"
                  :class="getCapsuleAbbreviationClass(tag.field)"
                  :style="{ 'margin-right': tagIndex !== concept._source.query.list.length - 1 ? '12px' : '0px' }"
                >
                  <template v-if="concept._source.query.name === 'Diagnosis'">
                    <ICDCapsule :path="[]" :tag="tag" :deleteFunction="false" />
                  </template>
                  <template v-else>
                    <div class="capsule-abbr-text mr-10px" :title="`${tag.code} ${tag.description}`">
                      {{ getCapsuleAbbreviation(tag.field) }}
                    </div>
                    <div class="capsule-code" :title="`${tag.code} ${tag.description}`">{{ tag.code }}</div>
                  </template>
                </div>
              </div>
            </div>
          </b-collapse>
        </div>
        <div class="mt-40px" v-if="getOrderList.length !== 0">
          <div class="d-flex mb-10px">
            <div class="collapse-title-text" @click="changeOrderVisible(!orderVisible)" style="cursor: pointer">
              Medical Order
            </div>
            <b-img
              src="@/assets/images/icon_saved_concept_collapse.svg"
              class="ml-5px"
              :class="{ rotateimg180: !orderVisible }"
              @click="changeOrderVisible(!orderVisible)"
              style="cursor: pointer"
            />
          </div>
          <b-collapse v-model="orderVisible">
            <div v-for="(concept, index) of getOrderList" :key="index" class="mb-10px concept-block">
              <div class="d-flex align-items-center justify-content-between">
                <div class="concept-name-text">{{ concept._source.displayName }}</div>
                <div class="d-flex align-items-center">
                  <b-img
                    src="@/assets/images/icon_emr_search_concept_name_edit.svg"
                    class="icon-img mr-10px"
                    title="Edit"
                    @click="clickEditTag(concept)"
                    v-if="false"
                  />
                  <b-img
                    src="@/assets/images/icon_emr_search_delete_concept.svg"
                    class="icon-img"
                    title="Delete"
                    @click="clickDeleteTag(concept)"
                  />
                </div>
              </div>
              <div class="d-flex mt-10px" style="overflow: hidden">
                <div
                  v-for="(tag, tagIndex) in concept._source.query.list"
                  :key="`tag-${tagIndex}`"
                  class="d-flex align-items-center capsule-abbr"
                  :class="getCapsuleAbbreviationClass(tag.field)"
                  :style="{ 'margin-right': tagIndex !== concept._source.query.list.length - 1 ? '12px' : '0px' }"
                >
                  <template v-if="concept._source.query.name === 'Diagnosis'">
                    <ICDCapsule :path="[]" :tag="tag" :deleteFunction="false" />
                  </template>
                  <template v-else>
                    <div class="capsule-abbr-text mr-10px" :title="`${tag.code} ${tag.description}`">
                      {{ getCapsuleAbbreviation(tag.field) }}
                    </div>
                    <div class="capsule-code" :title="`${tag.code} ${tag.description}`">{{ tag.code }}</div>
                  </template>
                </div>
              </div>
            </div>
          </b-collapse>
        </div>
        <div>
          <DeleteTagModal ref="DeleteTagModal" />
        </div>
      </template>
      <template v-else>
        <VuePerfectScrollbar @wheel.stop>
          <div class="mt-40px" v-if="getDiagnosisList.length !== 0">
            <div class="d-flex mb-10px">
              <div
                class="collapse-title-text"
                @click="changeDiagnosisVisible(!diagnosisVisible)"
                style="cursor: pointer"
              >
                Diagnosis
              </div>
              <b-img
                src="@/assets/images/icon_saved_concept_collapse.svg"
                class="ml-5px"
                :class="{ rotateimg180: !diagnosisVisible }"
                @click="changeDiagnosisVisible(!diagnosisVisible)"
                style="cursor: pointer"
              />
            </div>
            <b-collapse v-model="diagnosisVisible" class="mt-20px">
              <div
                v-for="(concept, index) of getDiagnosisList"
                :key="index"
                class="mb-10px concept-block"
                style="cursor: pointer; width: 780px"
                :class="{ disabled: getSelectType !== 'all' && getSelectType !== concept._source.query.name }"
                @click="
                  !checkSelected(concept._source.query)
                    ? clickSelect(concept._source.query)
                    : clickUnSelect(concept._source.query)
                "
              >
                <div class="d-flex align-items-center">
                  <div class="d-flex align-items-center">
                    <b-img
                      src="@/assets/images/icon_unselect_grey.svg"
                      class="mr-20px"
                      v-if="!checkSelected(concept._source.query)"
                    />
                    <b-img src="@/assets/images/icon_select_concept_blue.svg" class="mr-20px" v-else />
                  </div>
                  <div>
                    <div class="concept-name-text">{{ concept._source.displayName }}</div>
                    <div class="d-flex flex-wrap">
                      <div
                        v-for="(tag, tagIndex) in concept._source.query.list"
                        :key="`tag-${tagIndex}`"
                        class="d-flex align-items-center capsule-abbr mt-10px"
                        :class="getCapsuleAbbreviationClass(tag.field)"
                        :style="{ 'margin-right': tagIndex !== concept._source.query.list.length - 1 ? '12px' : '0px' }"
                      >
                        <template v-if="concept._source.query.name === 'Diagnosis'">
                          <ICDCapsule :path="[]" :tag="tag" :deleteFunction="false" />
                        </template>
                        <template v-else>
                          <div class="capsule-abbr-text mr-10px" :title="`${tag.code} ${tag.description}`">
                            {{ getCapsuleAbbreviation(tag.field) }}
                          </div>
                          <div class="capsule-code" :title="`${tag.code} ${tag.description}`">{{ tag.code }}</div>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
          <div class="mt-40px" v-if="getMedicationList.length !== 0">
            <div class="d-flex mb-10px">
              <div
                class="collapse-title-text"
                @click="changeMedicationVisible(!medicationVisible)"
                style="cursor: pointer"
              >
                Medication
              </div>
              <b-img
                src="@/assets/images/icon_saved_concept_collapse.svg"
                class="ml-5px"
                :class="{ rotateimg180: !medicationVisible }"
                @click="changeMedicationVisible(!medicationVisible)"
                style="cursor: pointer"
              />
            </div>
            <b-collapse v-model="medicationVisible">
              <div
                v-for="(concept, index) of getMedicationList"
                :key="index"
                class="mb-10px concept-block"
                style="cursor: pointer; width: 780px"
                :class="{ disabled: getSelectType !== 'all' && getSelectType !== concept._source.query.name }"
                @click="
                  !checkSelected(concept._source.query)
                    ? clickSelect(concept._source.query)
                    : clickUnSelect(concept._source.query)
                "
              >
                <div class="d-flex align-items-center">
                  <div class="d-flex align-items-center">
                    <b-img
                      src="@/assets/images/icon_unselect_grey.svg"
                      class="mr-20px"
                      v-if="!checkSelected(concept._source.query)"
                    />
                    <b-img src="@/assets/images/icon_select_concept_blue.svg" class="mr-20px" v-else />
                  </div>
                  <div>
                    <div class="concept-name-text">{{ concept._source.displayName }}</div>
                    <div class="d-flex flex-wrap">
                      <div
                        v-for="(tag, tagIndex) in concept._source.query.list"
                        :key="`tag-${tagIndex}`"
                        class="d-flex align-items-center capsule-abbr mt-10px"
                        :class="getCapsuleAbbreviationClass(tag.field)"
                        :style="{ 'margin-right': tagIndex !== concept._source.query.list.length - 1 ? '12px' : '0px' }"
                      >
                        <template v-if="concept._source.query.name === 'Diagnosis'">
                          <ICDCapsule :path="[]" :tag="tag" :deleteFunction="false" />
                        </template>
                        <template v-else>
                          <div class="capsule-abbr-text mr-10px" :title="`${tag.code} ${tag.description}`">
                            {{ getCapsuleAbbreviation(tag.field) }}
                          </div>
                          <div class="capsule-code" :title="`${tag.code} ${tag.description}`">{{ tag.code }}</div>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
          <div class="mt-40px" v-if="getOrderList.length !== 0">
            <div class="d-flex mb-10px">
              <div class="collapse-title-text" @click="changeOrderVisible(!orderVisible)" style="cursor: pointer">
                Medical Order
              </div>
              <b-img
                src="@/assets/images/icon_saved_concept_collapse.svg"
                class="ml-5px"
                :class="{ rotateimg180: !orderVisible }"
                @click="changeOrderVisible(!orderVisible)"
                style="cursor: pointer"
              />
            </div>
            <b-collapse v-model="orderVisible">
              <div
                v-for="(concept, index) of getOrderList"
                :key="index"
                class="mb-10px concept-block"
                style="cursor: pointer; width: 780px"
                :class="{ disabled: getSelectType !== 'all' && getSelectType !== concept._source.query.name }"
                @click="
                  !checkSelected(concept._source.query)
                    ? clickSelect(concept._source.query)
                    : clickUnSelect(concept._source.query)
                "
              >
                <div class="d-flex align-items-center">
                  <div class="d-flex align-items-center">
                    <b-img
                      src="@/assets/images/icon_unselect_grey.svg"
                      class="mr-20px"
                      v-if="!checkSelected(concept._source.query)"
                    />
                    <b-img src="@/assets/images/icon_select_concept_blue.svg" class="mr-20px" v-else />
                  </div>
                  <div>
                    <div class="concept-name-text">{{ concept._source.displayName }}</div>
                    <div class="d-flex flex-wrap">
                      <div
                        v-for="(tag, tagIndex) in concept._source.query.list"
                        :key="`tag-${tagIndex}`"
                        class="d-flex align-items-center capsule-abbr mt-10px"
                        :class="getCapsuleAbbreviationClass(tag.field)"
                        :style="{ 'margin-right': tagIndex !== concept._source.query.list.length - 1 ? '12px' : '0px' }"
                      >
                        <template v-if="concept._source.query.name === 'Diagnosis'">
                          <ICDCapsule :path="[]" :tag="tag" :deleteFunction="false" />
                        </template>
                        <template v-else>
                          <div class="capsule-abbr-text mr-10px" :title="`${tag.code} ${tag.description}`">
                            {{ getCapsuleAbbreviation(tag.field) }}
                          </div>
                          <div class="capsule-code" :title="`${tag.code} ${tag.description}`">{{ tag.code }}</div>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
        </VuePerfectScrollbar>
      </template>
    </div>
  </div>
</template>
<script>
import { mapState } from '@/store';
import { getCapsuleAbbreviation, getCapsuleAbbreviationClass } from '@/utils/util';
import ICDCapsule from '@/components/capsules/ICDCapsule.vue';
import { VUE_EVENT_NAME } from '@/utils/constants';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import DeleteTagModal from '@/components/modals/DeleteTagModal.vue';

export default {
  name: 'SavedConcept',
  data() {
    return {
      saveList: [],
      initState: true,
      searchName: '',
      selectedList: [],
      diagnosisVisible: true,
      medicationVisible: true,
      orderVisible: true,
    };
  },
  components: {
    ICDCapsule,
    VuePerfectScrollbar,
    DeleteTagModal,
  },
  props: {
    restore: {
      type: Boolean,
      default: false,
    },
    condition: {
      type: Object,
      default: Object,
    },
  },
  watch: {
    searchName() {
      this.getSaveList();
    },
  },
  mounted() {
    this.getSaveList(true);

    this.$root.$on(VUE_EVENT_NAME.REFRESH_EMR_SEARCH_CONCEPT_LIST, this.getSaveList);
  },
  destroyed() {
    this.$root.$off(VUE_EVENT_NAME.REFRESH_EMR_SEARCH_CONCEPT_LIST, this.getSaveList);
  },
  computed: {
    ...mapState('user', ['userId']),
    getSelectType() {
      if (this.condition.list && this.condition.list.length !== 0) {
        return this.condition.name;
      }
      if (this.selectedList[0]) {
        return this.selectedList[0].name;
      }
      return 'all';
    },
    getDiagnosisList() {
      return this.saveList.filter((item) => item._source.query.name === 'Diagnosis');
    },
    getMedicationList() {
      return this.saveList.filter((item) => item._source.query.name === 'Medication');
    },
    getOrderList() {
      return this.saveList.filter((item) => item._source.query.name === 'Medical Order');
    },
  },
  methods: {
    getCapsuleAbbreviation,
    getCapsuleAbbreviationClass,
    async getSaveList(state) {
      let result = {};
      if (this.searchName === '') {
        result = await this.$api.getUserQuery(this.userId, { purpose: 'saveConcept' });
      } else {
        const content = {
          userId: this.userId,
          keyword: this.searchName,
          topN: 10,
          page: 0,
          purpose: 'saveConcept',
        };
        result = await this.$api.searchQuery(content);
      }
      if (state) {
        this.initState = result.content.hits.total.value === 0;
      }
      this.saveList = result.content.hits.hits;
    },
    checkSelected(concept) {
      return this.selectedList.find((item) => item.time === concept.time);
    },
    clickSelect(concept) {
      this.selectedList.push(concept);
    },
    clickUnSelect(concept) {
      this.selectedList = this.selectedList.filter((item) => item.time !== concept.time);
    },
    changeDiagnosisVisible(value) {
      this.diagnosisVisible = value;
    },
    changeMedicationVisible(value) {
      this.medicationVisible = value;
    },
    changeOrderVisible(value) {
      this.orderVisible = value;
    },
    clickEditTag() {},
    clickDeleteTag(tag) {
      this.$refs.DeleteTagModal.openModal(tag);
    },
  },
};
</script>
<style lang="scss" scoped>
.title-text {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #262626;
}

.title-detail-text {
  font-size: 16px;
  line-height: 150%;
  color: #5b5b5b;
}

.second-title-text {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #5b5b5b;
}

.search-name-input {
  border-radius: 8px;
  height: 44px;
  border: 0px;

  /deep/.ant-input {
    background: #f2f2f2;
    border-radius: 8px;
    height: 44px;
    border: 0px;
    padding-left: 35px !important;
  }
}

.concept-block {
  background: #fafafa;
  border-radius: 12px;
  padding: 12px;
}

.concept-name-text {
  font-size: 16px;
  line-height: 19px;
  color: #262626;
}

.capsule-abbr {
  padding: 4px 8px;
  display: inline-block;
  background: #abe0e4;
  border-radius: 4px;
  width: fit-content;
}

.capsule-abbr-text {
  font-size: 14px;
  line-height: 17px;
  color: #919191;
  white-space: nowrap;
}

.capsule-code {
  color: #262626;
  font-size: 16px;
  line-height: 19px;
  margin-top: -1px;
  white-space: nowrap;
}

.capsule-delete {
  cursor: pointer;
}

.abbr-md {
  background: #b2e1bb;
}

.abbr-o {
  background: #fad195;
}

/deep/ .ps {
  max-height: 450px;
  max-width: 780px;
  width: 780px;
  min-width: 780px;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.collapse-title-text {
  color: #5b5b5b;
  font-size: 14px;
  line-height: 17px;
}

.rotateimg180 {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.icon-img {
  cursor: pointer;
}

/deep/ .concept-block:hover {
  background: #e6f2ff !important;
}
</style>
