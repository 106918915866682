export default {
  conceptName: 'Anti-TNF Biologics',
  conceptType: 'include',
  name: 'Medication',
  tags: ['Basic', 'Raw'],
  andOr: 'should',
  list: [
    {
      code: 'HUM08I',
      label: 'HUM08I',
      description: 'Adalimumab(Humira) 40mg Syringe#',
      chineseDescription: '復邁針筒裝注射劑',
      value: 'HUM08I',
      type: 'med',
      patientNum: 271,
      recordNum: 11523,
      field: 'med',
      ingredient: 'adalimumab',
      atcCode: 'L04AB04',
      time: 1646618743729,
    },
    {
      code: 'REM03I',
      label: 'REM03I',
      description: 'Infliximab(Remsima) 100mg Vial',
      chineseDescription: '類希瑪注射劑',
      value: 'REM03I',
      type: 'med',
      patientNum: 4,
      recordNum: 8,
      field: 'med',
      ingredient: 'infliximab',
      atcCode: 'L04AB02',
      time: 1646618801450,
    },
    {
      code: 'SIM02I',
      label: 'SIM02I',
      description: 'Golimumab (Simponi) 50mg/0.5ml#',
      chineseDescription: '欣普尼注射液',
      value: 'SIM02I',
      type: 'med',
      patientNum: 56,
      recordNum: 1335,
      field: 'med',
      ingredient: 'golimumab',
      atcCode: 'L04AB06',
      time: 1646618807121,
    },
  ],
  diagnosisRange: false,
  index: 1,
};
