<template>
  <div class="event-search" ref="eventSearch">
    <b-row
      no-gutters
      v-if="
        !(
          experimentalGroupState === GROUP_STATE.EDITING ||
          controlGroupState === GROUP_STATE.EDITING ||
          covariantGroupState === GROUP_STATE.EDITING ||
          outcomeGroupState === GROUP_STATE.EDITING
        )
      "
    >
      <b-col class="d-flex justify-content-center mt-40px">
        <div class="search-title">Click the button below to start a new cohort study</div>
      </b-col>
    </b-row>
    <b-row no-gutters align-h="center">
      <b-col cols="4">
        <MainSearch />
      </b-col>
      <b-col cols="8" v-show="experimentalGroupState === GROUP_STATE.EDITING">
        <CohortGroup :flowType="['experimental']" />
      </b-col>
      <b-col cols="8" v-show="controlGroupState === GROUP_STATE.EDITING">
        <CohortGroup :flowType="['control']" />
      </b-col>
      <b-col cols="8" v-show="covariantGroupState === GROUP_STATE.EDITING">
        <CovariantGroup />
      </b-col>
      <b-col cols="8" v-show="outcomeGroupState === GROUP_STATE.EDITING">
        <OutcomeGroup />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import MainSearch from '@/components/byEvent/search/MainSearch.vue';
import CohortGroup from '@/components/byEvent/group/CohortGroup.vue';
import CovariantGroup from '@/components/byEvent/group/CovariantGroup.vue';
import OutcomeGroup from '@/components/byEvent/group/OutcomeGroup.vue';
import { mapState } from '@/store';
import { GROUP_STATE, VUE_EVENT_NAME } from '@/utils/constants';

export default {
  name: 'EventSearch',
  components: {
    MainSearch,
    CohortGroup,
    CovariantGroup,
    OutcomeGroup,
  },
  data() {
    return {
      GROUP_STATE,
    };
  },
  computed: {
    ...mapState('eventSearch', [
      'experimentalGroupState',
      'controlGroupState',
      'covariantGroupState',
      'outcomeGroupState',
    ]),
  },
  mounted() {
    this.$root.$on(VUE_EVENT_NAME.SET_EVENT_SEARCH_TO_TOP, () => {
      if ('eventSearch' in this.$refs) {
        this.$refs.eventSearch.scrollTop = 0;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.event-search {
  background: #ffffff;
  min-height: calc(100vh - 106px);
  max-height: calc(100vh - 106px);
  height: calc(100vh - 106px);
  overflow-y: auto;
  scroll-behavior: smooth;
}

.search-title {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
  color: #334e97;
  padding: 0px 40px;
  text-align: center;
}
</style>
