<template>
  <div class="advance-codeSearch-filter" v-click-outside="closeSearchMenu">
    <div class="type-menu" v-if="isOpenMenu && selectType === 1" @click.stop="">
      <b-row no-gutters>
        <b-col>
          <b-row no-gutters>
            <b-col class="option-text" @click.stop="clickType('Diagnosis')">Diagnosis</b-col>
          </b-row>
          <b-row no-gutters>
            <b-col class="option-text" @click.stop="clickType('Medication')">Medication</b-col>
          </b-row>
          <b-row no-gutters>
            <b-col class="option-text" @click.stop="clickType('Medical Order')">Medical Order</b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
    <div v-if="selectType !== 1 && selectType !== -1 && isOpenMenu" class="search-menu" @click.stop="">
      <b-row no-gutters>
        <b-col class="menu-title">{{ selectType }}</b-col>
        <b-col class="d-flex justify-content-end">
          <b-img src="@/assets/images/icon_close.svg" class="close-icon" @click="closeSearchMenu" />
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col>
          <div v-if="selectType === 'Diagnosis' && isOpenMenu" class="filter-container">
            <ICDCodeSearchFilter
              :placeholder="uiConfig.searchPlaceholder.DX"
              :fetchOptions="fetchICDGroupMap"
              feedbackField="diagnosis"
              :path="path"
              :condition="condition"
            />
          </div>
          <div v-if="selectType === 'Medication' && isOpenMenu" class="filter-container">
            <CodeSearchFilter
              :placeholder="uiConfig.searchPlaceholder.MED"
              :fetchOptions="fetchMedication"
              feedbackField="medication"
              :path="path"
              :condition="condition"
            />
          </div>
          <div v-if="selectType === 'Medical Order' && isOpenMenu" class="filter-container">
            <CodeSearchFilter
              :placeholder="uiConfig.searchPlaceholder.ORDER"
              :fetchOptions="fetchMedicalOrder"
              feedbackField="Medical Order"
              :path="path"
              :condition="condition"
            />
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import ClickOutside from 'vue-click-outside';

import CodeSearchFilter from '@/components/filters/CodeSearchFilter.vue';
import ICDCodeSearchFilter from '@/components/filters/ICDCodeSearchFilter.vue';
import FormMixin from '@/mixins/formMixin';
import { EMRSearchMixin, EventMixin } from '@/mixins';
import { mapSubUiConfig } from '@/utils/uiConfig';

export default {
  name: 'CodeSearchMenu',
  components: {
    CodeSearchFilter,
    ICDCodeSearchFilter,
  },
  mixins: [EMRSearchMixin, FormMixin, EventMixin],
  directives: {
    ClickOutside,
  },
  props: {
    condition: Object,
    path: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    selectType() {
      this.$emit('updateSelectType', this.selectType);
    },
    isOpenMenu() {
      if (!this.isOpenMenu) {
        this.selectType = -1;
      }
    },
  },
  data() {
    return {
      selectType: -1,
    };
  },
  computed: {
    ...mapSubUiConfig('SearchForm'),
    isOpenMenu() {
      return this.isCurrentMenu(this.path, 'EMRSearchCodeSearchMenu');
    },
  },
  methods: {
    clickCondition() {
      this.openSearchMenu();
    },
    openSearchMenu() {
      if (this.selectType !== -1) {
        this.selectType = -1;
      } else if (this.selectType === -1 && this.condition.list.length > 0) {
        this.setCurrentMenu(this.path, 'EMRSearchCodeSearchMenu');
        this.selectType = this.condition.name;
      } else {
        this.setCurrentMenu(this.path, 'EMRSearchCodeSearchMenu');
        this.selectType = 1;
      }
    },
    closeSearchMenu() {
      this.selectType = -1;
    },
    clickType(value) {
      this.selectType = value;
      this.setCurrentMenu(this.path, 'EMRSearchCodeSearchMenu');
    },
  },
};
</script>
<style lang="scss" scoped>
.condition-add-text {
  padding: 5px 15px;
  background: #4a94d2;
  border-radius: 53px;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;
}

.condition-add-text-focus {
  background: #0f4895;
}

.type-menu {
  position: absolute;
  width: 135px;
  background: #ffffff;
  border: 1px solid #bdbdbd;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(51, 63, 107, 0.2);
  border-radius: 5px;
  z-index: 1;
  margin: -10px 0px 0px 0px;
  padding: 0px 0px 20px 0px;
}

.option-text {
  height: 18px;
  text-align: left;
  font-size: 14px;
  line-height: 18px;
  color: #333333;
  margin: 20px 0px 0px 20px;
  cursor: pointer;
}

.highlight-option-text {
  color: #0f4895;
  font-weight: bold;
}

.filter-container {
  margin: 21px 0px 0px 0px;
}

.search-menu {
  position: absolute;
  z-index: 1;
  border: 1px solid #0f4895;
  box-shadow: 0px 4px 10px rgba(51, 63, 107, 0.2);
  border-radius: 6px;
  padding: 20px 0px 0px 0px;
  background: #ffffff;
  height: auto;
  margin: -10px 0px 0px 0px;
  width: -webkit-fill-available;
}

.menu-title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #334e97;
  margin-left: 20px;
}

.close-icon {
  cursor: pointer;
  margin-right: 20px;
}

.disable-type {
  opacity: 0.3;
  pointer-events: none;
  cursor: not-allowed;
}
</style>
<style scoped src="@/css/home.css"></style>
<style scoped src="@/css/searchForm.css"></style>
<style lang="scss" scoped src="@/css/searchForm.scss"></style>
