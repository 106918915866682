import dayjs from 'dayjs';
import { getUiConfig } from '@/utils/uiConfig';
import { ConditionFactory, ConditionName, LogicOp, RangeCondition, RangeData } from './core';

function getTimeString(time: number, splitter = '-') {
  return dayjs.unix(time).format(`YYYY${splitter}MM`);
}

function getEndTimeString(time: number, splitter = '-') {
  return dayjs.unix(time).add(1, 'month').format(`YYYY${splitter}MM`);
}

function getTimeQuery(ranges: RangeData[]): Record<string, unknown>[] {
  // default range
  if (ranges.length === 0) {
    return [
      {
        range: {
          OUT_DATE: {
            gte: getTimeString(getUiConfig().SearchForm.time.start),
            lt: getEndTimeString(getUiConfig().SearchForm.time.end),
          },
        },
      },
    ];
  }
  return ranges.map((range) => ({
    range: {
      OUT_DATE: {
        gte: getTimeString(range.start),
        lt: getEndTimeString(range.end),
      },
    },
  }));
}

export function getTimeRangeInYear(range: RangeData) {
  return {
    start: dayjs.unix(range.start).year(),
    end: dayjs.unix(range.end).year(),
  };
}

export default class TimeDef extends RangeCondition {
  static get NAME(): string {
    return 'Time Period';
  }

  public getFilter(): Record<string, unknown> {
    return {
      bool: {
        [LogicOp.Or]: getTimeQuery(this.ranges),
      },
    };
  }
}

ConditionFactory.register(TimeDef, ConditionName.Time);
