import { ConditionData, ConditionName, ConditionTag, GlobalData, LogicOp } from './Interface';

abstract class Condition implements ConditionData {
  protected readonly data: ConditionData[];

  protected readonly global: GlobalData;

  constructor(data: ConditionData[], global: GlobalData) {
    this.data = data;
    this.global = global;
  }

  public static fromData<CondType extends Condition>(
    this: new (data: ConditionData[], global: GlobalData) => CondType,
    data: ConditionData[],
    global: GlobalData
  ): CondType {
    return new this(data, global);
  }

  public of(type: typeof Condition): boolean {
    return this instanceof type;
  }

  public get name(): ConditionName {
    return this.view.name;
  }

  public get tags(): ConditionTag[] {
    return this.view.tags;
  }

  public get ref(): ConditionData[] {
    return this.data;
  }

  public abstract toString(): string;

  public abstract getFilter(): Record<string, unknown>;

  public abstract get view(): ConditionData;

  // eslint-disable-next-line class-methods-use-this
  public get isHidden(): boolean {
    return false;
  }

  // eslint-disable-next-line class-methods-use-this
  public get andOr(): LogicOp {
    return LogicOp.Or;
  }

  public hasTag(tag: ConditionTag): boolean {
    return this.tags.includes(tag);
  }
}

export default Condition;
