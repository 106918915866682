import { CategoryCondition, VisitType } from '@/utils/conditions/core';
import numeral from 'numeral';
import { PRECISION_THRESHOLD } from '@/utils/constants';
import { ChartItem } from '../core/Interface';
import ConstraintName from '@/utils/conditions/core/advance/constraint/Interface';
import {
  IConstraint,
  IAgeConstraint,
  IVisitTypeConstraint,
  IDiagnosisRangeConstraint,
} from '@/utils/conditions/core/Interface';

export const UNSELECTED_COLOR = '#BDBDBD';
export const SELECTED_COLOR = '#758CC8';
export const VIEW_ALL_WORD_LENGTH = 30;

export function removePoint(value: string): string {
  if (Number.isNaN(value)) {
    return `${value}`;
  }
  if (`${value}`.includes('.')) {
    return '';
  }
  return `${value}`;
}

export function AxisFormatter(value: string): string {
  return value.length > 10 ? `${value.substring(0, 10)}..` : value;
}

export function advanceAxisFormatter(value: string): string {
  return value.length > 40 ? `${value.substring(0, 40)}..` : value;
}

export function viewAllAxisFormatter(value: string): string {
  return value.length > VIEW_ALL_WORD_LENGTH ? `${value.substring(0, VIEW_ALL_WORD_LENGTH)}..` : value;
}

export function durationMapper(item: any): Record<string, unknown> {
  return {
    value: item.doc_count,
    itemStyle: { normal: { color: item.color } },
  };
}

export const findNameByKey = (data: ChartItem[]) => (key: string) => data.find((item) => item.key === key)?.name || '';

export const sourceData = (key: any, doc_count: number, recordCount: number, condList: string | any[]) => {
  let modified_key = key;
  if (modified_key === '') {
    modified_key = '無記錄';
  }

  let color: string | null = null;
  if (condList.length !== 0) {
    const condition = condList[0] as CategoryCondition;
    color = UNSELECTED_COLOR;
    if (condition.hitKey(modified_key)) {
      color = null;
    }
  }

  return {
    key: modified_key,
    value: doc_count,
    code: modified_key,
    name: modified_key,
    color,
    percentage: Math.round((doc_count / recordCount) * 100.0),
  };
};

export const codeAxisData = (bucketByPatient: any, data: any[], visitType: VisitType) => ({
  xAxis: {
    name: bucketByPatient ? 'Patient' : 'Record',
  },
  yAxis: {
    data: data.map((item) => item.key),
    visitType,
  },
});

export const codePercentageFormatter = (seriesList: any) => {
  let currentSeries: any = null;
  if (Array.isArray(seriesList)) {
    currentSeries = seriesList[0];
  } else {
    currentSeries = seriesList;
  }
  const item = currentSeries.data as ChartItem;
  const formatedNumber = numeral(item.value).format('0,0');
  return `[${item.code}] ${item.name}: ${formatedNumber} (${item.percentage}%)`;
};

export const codeAggsQuery = (getOrderField: any, field: any, resultSize: number): Record<string, unknown> => {
  const orderField = getOrderField;
  return {
    terms: {
      field,
      size: resultSize,
      shard_size: resultSize * 4 + 10,
      order: {
        [orderField]: 'desc',
      },
      exclude: ['##'],
    },
    aggs: {
      patient_count: {
        cardinality: {
          field: 'CHART_NO',
          precision_threshold: PRECISION_THRESHOLD,
        },
      },
    },
  };
};

export const formatConstraint = (constraint: IConstraint) => {
  if (constraint.constraintType === ConstraintName.AgeConstraint) {
    const c = constraint as IAgeConstraint;
    return `Age: ${c.start}~${c.end}`;
  } else if (constraint.constraintType === ConstraintName.VisitTypeConstraint) {
    const c = constraint as IVisitTypeConstraint;
    return `VisitType: ${c.visitType}`;
  } else if (constraint.constraintType === ConstraintName.DiagnosisRangeConstraint) {
    const c = constraint as IDiagnosisRangeConstraint;
    return `DiagnosisRange: ${c.diagnosisRange}`;
  }
  return constraint.constraintType;
};
