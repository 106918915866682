import { ConditionFactory, ConditionName, CategoryCondition } from './core';

function getDoctorIdQuery(doctorIdList: string[]) {
  return doctorIdList.map((value) => ({ term: { DOCTOR_ID: value } }));
}

export default class DoctorIdDef extends CategoryCondition {
  public getFilter(): Record<string, unknown> {
    return {
      bool: {
        [this.andOr]: getDoctorIdQuery(this.list),
      },
    };
  }
}

ConditionFactory.register(DoctorIdDef, ConditionName.DoctorId);
