import { CHART } from '../constants';
import {
  ConditionFactory,
  ConditionName,
  DiagnosisRanges,
  LogicOp,
  MedicalTermType,
  TermCondition,
  DiagnosisType,
} from './core';

export default class DiagnosisDef extends TermCondition {
  static get NAME(): string {
    return CHART.TITLE.DIAGNOSIS;
  }

  get diagnosisQuery(): Record<string, unknown>[] {
    return this.list.map((item) => {
      let fieldName = '';
      if (item.type === MedicalTermType.ICD10 && this.hasTag(DiagnosisRanges.Top5)) {
        fieldName = 'ICD';
      } else if (item.type === MedicalTermType.ICD9 && this.hasTag(DiagnosisRanges.Top5)) {
        fieldName = 'ICD9';
      } else if (item.type === MedicalTermType.ICD10 && this.hasTag(DiagnosisRanges.ALL)) {
        fieldName = 'ICD_20';
      } else if (item.type === MedicalTermType.ICD9 && this.hasTag(DiagnosisRanges.ALL)) {
        fieldName = 'ICD9_20';
      } else if (item.type === MedicalTermType.ICD10 && this.hasTag(DiagnosisRanges.Primary)) {
        fieldName = 'ICD10_MAIN';
      } else if (item.type === MedicalTermType.ICD9 && this.hasTag(DiagnosisRanges.Primary)) {
        fieldName = 'ICD9_MAIN';
      }
      return { regexp: { [fieldName]: `${item.code}.*` } };
    });
  }

  get icd10field() {
    let fieldName = '';
    if (this.hasTag(DiagnosisRanges.Top5)) {
      fieldName = 'ICD';
    } else if (this.hasTag(DiagnosisRanges.ALL)) {
      fieldName = 'ICD_20';
    } else if (this.hasTag(DiagnosisRanges.Primary)) {
      fieldName = 'ICD10_MAIN';
    }
    return fieldName;
  }

  get icd9field() {
    let fieldName = '';
    if (this.hasTag(DiagnosisRanges.Top5)) {
      fieldName = 'ICD9';
    } else if (this.hasTag(DiagnosisRanges.ALL)) {
      fieldName = 'ICD9_20';
    } else if (this.hasTag(DiagnosisRanges.Primary)) {
      fieldName = 'ICD9_MAIN';
    }
    return fieldName;
  }

  get diagnosisGroupMapQuery(): Record<string, unknown>[] {
    const result: any = [];
    this.list.forEach((listItem) => {
      const Icd10List = listItem.icd10List.map((item: any) => ({ regexp: { [this.icd10field]: `${item}.*` } }));
      const Icd9List = listItem.icd9List.map((item: any) => ({ regexp: { [this.icd9field]: `${item}.*` } }));
      result.push({
        bool: {
          should: Icd10List.concat(Icd9List),
        },
      });
    });

    return result;
  }

  public getFilter(): Record<string, unknown> {
    if (this.hasTag(DiagnosisType.GroupAndMap)) {
      return {
        bool: {
          [this.andOr]: this.diagnosisGroupMapQuery,
        },
      };
    }
    return {
      bool: {
        [this.andOr]: this.diagnosisQuery,
      },
    };
  }

  public get isHidden(): boolean {
    return this.global.searchByPatient;
  }

  public get andOr(): LogicOp {
    if (this.global.searchByPatient) {
      return LogicOp.Or;
    }
    return super.andOr;
  }
}

ConditionFactory.register(DiagnosisDef, ConditionName.Diagnosis);
