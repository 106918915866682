import { VisitType } from '../conditions/core';
import LabNameData from './data/LabName';
import LabNameDataYG from './data/LabNameYG';
import LabNameDataOPD from './data/LabNameOPD';
import LabNameCP from './data/LabNameCP';
import LabNameGL from './data/LabNameGL';
import LabNameCC from './data/LabNameCC';
import LabNameSD from './data/LabNameSD';
import LabNamePC from './data/LabNamePC';
import { getUiConfig } from '../uiConfig';

function getJBLabName(visitType: VisitType): Record<string, unknown> {
  if (visitType === VisitType.IPD) {
    return LabNameData;
  }
  return LabNameDataOPD;
}

export function getLabName(visitType: VisitType) {
  switch (getUiConfig().hospital) {
    case 'Jelly BeanN':
    case 'Jelly BeanC':
    case 'Jelly BeanS':
    case 'Jelly BeanE':
      return getJBLabName(visitType);
    case 'Cheese Cake':
      return LabNameCC;
    case 'Yogurt':
      return LabNameDataYG;
    case 'Crepes':
      return LabNameCP;
    case 'Gelato':
      return LabNameGL;
    case 'Sundae':
      return LabNameSD;
    case 'Pineapple Cake':
      return LabNamePC;
    default:
      return getJBLabName(visitType);
  }
}

export function getDefaultLabName(visitType: VisitType): string {
  return Object.keys(getLabName(visitType))[0];
}
