import TimeRelationEvent from '@/utils/conditions/core/advance/TimeRelationEvent';
import TailTimeRelationEvent from '@/utils/conditions/core/advance/TailTimeRelationEvent';
import TimePeriod from '@/utils/conditions/core/advance/TimePeriod';
import ConditionQuery from './ConditionQuery';
import TimePeriodQuery from './TimePeriodQuery';
import ConstraintQueryFactory from './ConstraintQueryFactory';

class TimeEventQuery {
  private timeRelationEvent: TimeRelationEvent | TailTimeRelationEvent;

  private timePeriod: TimePeriod;

  private constraintQueryFactory = new ConstraintQueryFactory();

  private eventStage = 'normal';

  constructor(
    timeRelationEvent: TimeRelationEvent | TailTimeRelationEvent,
    timePeriod: TimePeriod,
    eventStage?: string
  ) {
    this.timeRelationEvent = timeRelationEvent;
    this.timePeriod = timePeriod;
    this.constraintQueryFactory.setIsTimeRelation();
    if (eventStage) {
      this.eventStage = eventStage;
    }
  }

  private excludeTemplate(conditionQueryList: any, timePeriodQuery: any, timeRelationConstraintQueryList: any) {
    const gt = 'gte';
    const lt = 'lte';
    return {
      bool: {
        must_not: [
          {
            nested: {
              path: 'EVENT_HISTORY',
              query: {
                bool: {
                  filter: [
                    {
                      bool: {
                        should: conditionQueryList,
                      },
                    },
                    {
                      range: {
                        'EVENT_HISTORY.TIME_DIFF': {
                          [gt]:
                            this.timeRelationEvent.relation.beforeValue === 'any'
                              ? null
                              : this.timeRelationEvent.relation.beforeDays * -1,
                          [lt]:
                            this.timeRelationEvent.relation.afterValue === 'any'
                              ? null
                              : this.timeRelationEvent.relation.afterDays,
                        },
                      },
                    },
                    ...timePeriodQuery,
                    ...timeRelationConstraintQueryList,
                  ],
                },
              },
            },
          },
        ],
      },
    };
  }

  private includeTemplate(conditionQueryList: any, timePeriodQuery: any, timeRelationConstraintQueryList: any) {
    const gt = 'gte';
    const lt = 'lte';
    const timeDiffOrder = 'asc';
    const innerHit =
      this.eventStage !== 'normal'
        ? {
            inner_hits: {
              name: this.eventStage,
              sort: {
                'EVENT_HISTORY.TIME_DIFF': { order: timeDiffOrder },
              },
              size: 1,
              _source: ['EVENT_HISTORY.TIME_KEY', 'EVENT_HISTORY.TIME_DIFF'],
            },
          }
        : {};
    return {
      nested: {
        path: 'EVENT_HISTORY',
        ...innerHit,
        query: {
          bool: {
            filter: [
              {
                bool: {
                  should: conditionQueryList,
                },
              },
              {
                range: {
                  'EVENT_HISTORY.TIME_DIFF': {
                    [gt]:
                      this.timeRelationEvent.relation.beforeValue === 'any'
                        ? null
                        : this.timeRelationEvent.relation.beforeDays * -1,
                    [lt]:
                      this.timeRelationEvent.relation.afterValue === 'any'
                        ? null
                        : this.timeRelationEvent.relation.afterDays,
                  },
                },
              },
              ...timePeriodQuery,
              ...timeRelationConstraintQueryList,
            ],
          },
        },
      },
    };
  }

  getQuery(isExcluded = false) {
    let conditionQueryList: any[] = [];
    this.timeRelationEvent.condition.forEach((condition) => {
      conditionQueryList = conditionQueryList.concat(
        new ConditionQuery(
          condition,
          this.timeRelationEvent.constraintList,
          true,
          this.timeRelationEvent.constraint.firstOccurrence
        ).getQuery()
      );
    });
    const timePeriodQueryList = [];
    const timePeriod = this.timeRelationEvent.constraintList.filter(
      (constraint) => constraint.constraintType === 'DataRangeConstraint'
    );
    if (timePeriod.length > 0) {
      const timePeriodInstance = new TimePeriod(timePeriod[0]);
      const timePeriodQuery = new TimePeriodQuery(timePeriodInstance, true).getQuery();
      timePeriodQueryList.push(timePeriodQuery);
    }
    const timeRelationConstraintQueryList = this.timeRelationEvent.constraintList
      .map((e) => this.constraintQueryFactory.getQuery(e))
      .filter((c) => c !== undefined);
    if (isExcluded) {
      return this.excludeTemplate(conditionQueryList, timePeriodQueryList, timeRelationConstraintQueryList);
    }
    return this.includeTemplate(conditionQueryList, timePeriodQueryList, timeRelationConstraintQueryList);
  }
}

export default TimeEventQuery;
