<template>
  <div id="incidencerate-table">
    <div v-if="noResult" class="no-result">
      <div class="incidencerate-table-title-text ml-20px mt-20px" style="position: absolute">Incidence Rate</div>
      <div class="no-result-wrapper">
        <div class="mb-10px">
          <b-img src="@/assets/images/icon_no_results.svg" />
        </div>
        <div class="no-results-text">No results</div>
      </div>
    </div>
    <div v-else-if="loading" class="no-result">
      <div class="incidencerate-table-title-text ml-20px mt-20px" style="position: absolute">Incidence Rate</div>
      <div class="no-result-wrapper">
        <a-progress
          type="circle"
          :percent="progressPercent"
          :width="70"
          :strokeWidth="8"
          strokeColor="#4D8EDC"
          trailColor="#E0E0E0"
        >
          <template #format="percent">
            <span class="progress-text">{{ `${percent}%` }}</span>
          </template></a-progress
        >
        <div class="progress-text mt-10px" v-if="requestInProgress">Analyzing...</div>
        <div class="progress-text mt-10px" v-else>Queuing...</div>
      </div>
    </div>
    <div class="incidencerate-table" v-else>
      <b-row no-gutters>
        <b-col>
          <div class="incidencerate-table-title-text">Incidence Rate​</div>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col>
          <a-table
            tableLayout="auto"
            :columns="incidencerateColumn"
            :data-source="incidencerateData"
            class="mt-10px"
            :pagination="false"
          ></a-table>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from '@/store';
import column from '@/components/byEvent/result/column.js';
import { delay, calcProgress } from '@/utils/util';
import { SLA_DEFINITION } from '@/utils/cohortProgressConfig';
import { API, VUE_EVENT_NAME } from '@/utils/constants';
import { PatientNumberMixin } from '@/mixins';

const POLLING_PERIOD = 3000;

export default {
  name: 'IncidenceRateTable',
  props: {
    uniqueIndex: {
      type: Number,
      default: 0,
    },
  },
  mixins: [PatientNumberMixin],
  data() {
    return {
      incidencerateColumn: column.incidence_rate_outcome,
      incidencerateData: [],
      loading: false,
      noResult: false,
      progressPercent: 0,
      requestInProgress: false,
      queryData: {},
    };
  },
  computed: {
    ...mapState('cohortSession', ['tabs']),
    tab() {
      return this.tabs[this.uniqueIndex];
    },
    eventSearch() {
      return this.tab.eventSearch;
    },
  },
  mounted() {
    this.updateData();

    this.fn = () => {
      this.getPatientListData(true);
    };
    this.$root.$on(VUE_EVENT_NAME.SAVE_ANALYSIS_RECORD, this.fn);
  },
  beforeDestroy() {
    this.$root.$off(VUE_EVENT_NAME.SAVE_ANALYSIS_RECORD, this.fn);
  },
  watch: {
    uniqueIndex() {
      this.updateData();
    },
  },
  methods: {
    ...mapMutations('cohortSession', ['updateTab']),
    async pollingData() {
      let result = { result: 'processing' };
      while (result.result === 'processing' && result.result !== 'fail') {
        result = await this.getPatientListData();
        if (result.result === 'processing') {
          await delay(POLLING_PERIOD);
        }
      }

      if (result.result === 'fail' || result.incidence_rates.length === 0) {
        this.noResult = true;
      } else {
        this.noResult = false;
      }

      this.incidencerate = result;
      return Promise.resolve();
    },
    async updateProgressPercent() {
      while (this.loading) {
        this.requestInProgress = this.$api.checkInProgress(this.queryData, API.PATIENT_INCIDENT_RATE);
        if (this.requestInProgress) {
          this.progressPercent = calcProgress(
            JSON.parse(this.requestInProgress).startTime,
            SLA_DEFINITION.PATIENT_INCIDENCE_RATE
          );

          this.updateTab({
            index: this.uniqueIndex,
            incidenceRateTableRequestInProgress: true,
            incidenceRateTableProgressPercent: this.progressPercent,
          });
        }
        await delay(100);
      }
      this.updateTab({
        index: this.uniqueIndex,
        incidenceRateTableRequestInProgress: false,
        incidenceRateTableProgressPercent: 100,
      });
    },
    async updateData() {
      try {
        this.loading = true;

        this.progressPercent = 0;
        this.requestInProgress = false;
        this.updateProgressPercent();
        await this.pollingData();
        this.setIncidencerateData();
      } catch (error) {
        console.error(error);
        this.noResult = true;
      }
      this.progressPercent = 0;
      this.requestInProgress = false;
      this.loading = false;
      this.updateTab({
        index: this.uniqueIndex,
        patientListRequestInProgress: false,
        patientListProgressPercent: 100,
      });
    },
    getRecoveryParams() {
      if (this.tab.tabType === 'history' || this.tab.tabType === 'save') {
        return {
          get_latest: this.tab.getLatest,
          url: this.tab.url || this.tab.id,
        };
      }
      return {
        get_latest: true,
        url: this.tab.url || this.tab.id,
      };
    },
    async getPatientListData(saveAnalysisRecord = false) {
      const params = {
        searchState: this.eventSearch,
      };
      if (saveAnalysisRecord) {
        const query = {
          ...params,
          ...{
            get_latest: true,
            url: this.tab.id,
          },
        };
        this.queryData = query;
        return this.$api.getIncidentRateData(query);
      }
      const query = {
        ...params,
        ...this.getRecoveryParams(),
      };
      this.queryData = query;
      return this.$api.getIncidentRateData(query);
    },
    transformNumber(value, fixed = 4) {
      if (typeof value === 'string') {
        return value;
      }
      return value.toFixed(fixed);
    },
    setIncidencerateData() {
      this.incidencerateData = [];
      this.incidencerate.incidence_rates.forEach((item, index) => {
        this.incidencerateData.push({
          ...item,
          incidence_rate: this.transformNumber(item.incidence_rate, 1),
          key: index,
          num_people: this.formatedTemplateNumber(item.num_people),
          num_events: this.formatedTemplateNumber(item.num_events),
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.incidencerate-table {
  padding: 20px 20px;
  box-shadow: 0px 4px 16px #e0e0e0, 0px 16px 16px rgba(157, 205, 245, 0.1);
  border-radius: 8px;
}

.incidencerate-table-title-text {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #212121;
}

/deep/ .ant-table-column-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #757575;
}

.loading-spin {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
}

.loading-disable {
  opacity: 0.5;
}

/deep/.ant-table-row td:nth-child(1) {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #757575;
}
/deep/.ant-table-row td:nth-child(n + 2) {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #212121;
}

.no-result {
  height: 300px;
  box-shadow: 0px 4px 16px #e0e0e0, 0px 16px 16px rgba(157, 205, 245, 0.1);
  border-radius: 8px;
}

.no-result-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.background {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}

.no-results-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #9e9e9e;
}

.progress-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}
</style>
