const sorterByField = (a, b, field) => {
  const a_ = a[field] ? a[field] : '';
  const b_ = b[field] ? b[field] : '';
  return a_.localeCompare(b_);
};

export default {
  patient_list_column: [
    {
      title: 'Patient ID',
      dataIndex: 'patient_id',
      key: 'patient_id',
      align: 'center',
      width: 150,
      sorter: (a, b) => sorterByField(a, b, 'patient_id'),
    },
    {
      title: 'Gender',
      dataIndex: 'sex',
      key: 'sex',
      align: 'center',
      width: 150,
      sorter: (a, b) => sorterByField(a, b, 'sex'),
    },
    {
      title: 'Age',
      dataIndex: 'age',
      key: 'age',
      align: 'center',
      width: 150,
      sorter: (a, b) => a.age - b.age,
    },
    {
      title: 'Height',
      dataIndex: 'height',
      key: 'height',
      align: 'center',
      width: 150,
    },
    {
      title: 'Weight',
      dataIndex: 'weight',
      key: 'weight',
      align: 'center',
      width: 150,
    },
    {
      title: 'BMI',
      dataIndex: 'bmi',
      key: 'bmi',
      align: 'center',
      width: 150,
    },
    {
      title: 'Population Diagnosis Date',
      dataIndex: 'population_diagnosis_date',
      key: 'population_diagnosis_date',
      align: 'center',
      width: 150,
      sorter: (a, b) => sorterByField(a, b, 'population_diagnosis_date'),
    },
    {
      title: 'Intervention Date',
      dataIndex: 'index_date',
      key: 'index_date',
      align: 'center',
      width: 150,
      sorter: (a, b) => sorterByField(a, b, 'index_date'),
    },
    {
      title: 'Outcome Date',
      dataIndex: 'outcome_date',
      key: 'outcome_date',
      align: 'center',
      width: 150,
      sorter: (a, b) => sorterByField(a, b, 'outcome_date'),
    },
    {
      title: 'Last Record Date',
      dataIndex: 'last_record_date',
      key: 'last_record_date',
      align: 'center',
      width: 150,
      sorter: (a, b) => sorterByField(a, b, 'last_record_date'),
    },
    {
      title: 'Person-Year',
      dataIndex: 'person_years',
      key: 'person_years',
      align: 'center',
      width: 150,
      sorter: (a, b) => a.person_years - b.person_years,
    },
    {
      title: 'Outcome',
      dataIndex: 'has_outcome',
      key: 'has_outcome',
      align: 'center',
      width: 150,
      sorter: (a, b) => a.has_outcome - b.has_outcome,
    },
  ],
  cox_regression: [
    {
      title: '',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '',
      dataIndex: 'groupName',
      key: 'groupName',
    },
    {
      title: 'HR',
      dataIndex: 'hazard_ratio',
      key: 'hazard_ratio',
      align: 'center',
    },
    {
      title: '95% CI',
      dataIndex: '95CI',
      key: '95CI',
      align: 'center',
    },
    {
      title: 'P Value​',
      dataIndex: 'PValue',
      key: 'PValue',
      align: 'center',
    },
  ],
  incidence_rate_outcome: [
    {
      title: '',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Number Of Patients',
      dataIndex: 'num_people',
      key: 'num_people',
      align: 'center',
    },
    {
      title: 'Number Of Events',
      dataIndex: 'num_events',
      key: 'num_events',
      align: 'center',
    },
    {
      title: 'Person-Years',
      dataIndex: 'person_years',
      key: 'person_years',
      align: 'center',
    },
    {
      title: 'Incidence Rate ( Per 1,000 Person-Years)',
      dataIndex: 'incidence_rate',
      key: 'incidence_rate',
      align: 'center',
    },
  ],
  cox_model: [
    {
      title: '',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'HR',
      dataIndex: 'hazard_ratio',
      key: 'hazard_ratio',
      align: 'center',
    },
    {
      title: '95% CI​',
      dataIndex: '95CI',
      key: '95CI',
      align: 'center',
    },
    {
      title: 'P value',
      dataIndex: 'p_value',
      key: 'p_value',
      align: 'center',
    },
  ],
};
