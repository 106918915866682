import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { GROUP_STATE, EVENT } from '@/utils/constants';

import { isEqual, cloneDeep } from 'lodash';
import { defaultEventSearch } from '@/utils/event/initState';
import objectPath from 'object-path';
import { SearchQueryGenerator } from '@/utils/lumos/SearchQueryGenerator';
import { checkEventSearchNameError } from '@/utils/util';

function assign(content: any, event: any) {
  Object.keys(content).forEach((key) => {
    const value = content[key];
    if (value !== undefined && !isEqual(event[key], value)) {
      // eslint-disable-next-line no-param-reassign
      event[key] = value;
    }
  });
}

function getCohortHasCondition(cohort: any) {
  return cohort.eventList[0].condition.length !== 0 || cohort.indexEvent.condition.length !== 0;
}

function inclusionListHasCondition(inclusionList: any) {
  let hasCondition = false;
  inclusionList.forEach((item: { condition: string | any[] }) => {
    if (item.condition.length > 0) {
      hasCondition = true;
    }
  });
  return hasCondition;
}

function checkDisableCompare(eventSearch: any, experimentalGroupPatient: number, controlGroupPatient: number) {
  const experimentalHasCondition = getCohortHasCondition(eventSearch.experimental);

  const controlHasCondition = getCohortHasCondition(eventSearch.control);

  const outcomeHasCondition = inclusionListHasCondition(eventSearch.outcome.inclusionList);

  return !(
    experimentalHasCondition &&
    controlHasCondition &&
    outcomeHasCondition &&
    experimentalGroupPatient > 0 &&
    controlGroupPatient > 0
  );
}

@Module({ namespaced: true })
export default class EventSearch extends VuexModule {
  // init -> editing -> done
  experimentalGroupState = GROUP_STATE.INIT;
  controlGroupState = GROUP_STATE.INIT;
  covariantGroupState = GROUP_STATE.INIT;
  outcomeGroupState = GROUP_STATE.INIT;

  experimentalGroupPatient = 0;
  controlGroupPatient = 0;
  totalPatient = 13804039;

  eventSearch: any = cloneDeep(defaultEventSearch);

  experimentalLoadingCount = 0;
  controlLoadingCount = 0;

  currentMenu = '';

  disableCompare = true;

  studyName = EVENT.DEFAULT_NAME.NEW_STUDY;

  nameError = '';

  @Mutation
  updateExperimentalGroupState(state: string) {
    this.experimentalGroupState = state;

    if (state === GROUP_STATE.INIT && getCohortHasCondition(this.eventSearch.experimental)) {
      this.experimentalGroupState = GROUP_STATE.DONE;
    }
  }

  @Mutation
  updateControlGroupState(state: string) {
    this.controlGroupState = state;

    if (state === GROUP_STATE.INIT && getCohortHasCondition(this.eventSearch.control)) {
      this.controlGroupState = GROUP_STATE.DONE;
    }
  }

  @Mutation
  updateCovariantGroupState(state: string) {
    this.covariantGroupState = state;

    if (state === GROUP_STATE.INIT && inclusionListHasCondition(this.eventSearch.covariate.inclusionList)) {
      this.covariantGroupState = GROUP_STATE.DONE;
    }
  }

  @Mutation
  updateOutcomeGroupState(state: string) {
    this.outcomeGroupState = state;

    if (state === GROUP_STATE.INIT && inclusionListHasCondition(this.eventSearch.outcome.inclusionList)) {
      this.outcomeGroupState = GROUP_STATE.DONE;
    }
  }

  @Mutation
  // eslint-disable-next-line no-unused-vars
  updateWithPathMutation({ api, path, ...content }: any) {
    const event = objectPath.get(this.eventSearch, path);
    assign(content, event);
    this.disableCompare =
      checkDisableCompare(this.eventSearch, this.experimentalGroupPatient, this.controlGroupPatient) ||
      checkEventSearchNameError(this.eventSearch);
  }

  @Mutation
  updatePatientCountStatus({ groupType, patientCount }: any) {
    if (groupType === 'experimental') {
      this.experimentalGroupPatient = patientCount;
    } else {
      this.controlGroupPatient = patientCount;
    }

    this.disableCompare =
      checkDisableCompare(this.eventSearch, this.experimentalGroupPatient, this.controlGroupPatient) ||
      checkEventSearchNameError(this.eventSearch);
  }

  @Mutation
  setMenuPath({ path, menuName }: any) {
    this.currentMenu = `${path}/${menuName}`;
  }

  @Mutation
  updateStudyName(name: string) {
    this.studyName = name;
  }

  @Mutation
  updateExperimentalLoadingCount(experimentalLoadingCount: number) {
    this.experimentalLoadingCount = experimentalLoadingCount;
  }

  @Mutation
  updateControlLoadingCount(controlLoadingCount: number) {
    this.controlLoadingCount = controlLoadingCount;
  }

  @Mutation
  updateTotalPatientCount(patientCount: number) {
    this.experimentalGroupPatient = patientCount;
    this.controlGroupPatient = patientCount;
    this.totalPatient = patientCount;
  }

  @Mutation
  checkNameError({ name, tag }: any) {
    const nameList: any[] = [];
    this.eventSearch.covariate.inclusionList.forEach((element: { name: any }, index: any) => {
      nameList.push({
        name: element.name,
        tag: `covariate-${index}`,
      });
    });
    this.eventSearch.outcome.inclusionList.forEach((element: { name: any }, index: any) => {
      nameList.push({
        name: element.name,
        tag: `outcome-${index}`,
      });
    });

    this.nameError = '';
    if (nameList.filter((item) => item.name === name).length > 1) {
      this.nameError = tag;
    } else {
      nameList.forEach((item) => {
        if (nameList.filter((kidItem) => item.name === kidItem.name).length > 1) {
          this.nameError = item.tag;
        }
      });
    }
  }

  @Mutation
  setDefaultEventSearch() {
    this.eventSearch = cloneDeep(defaultEventSearch);

    this.experimentalGroupState =
      this.experimentalGroupState === GROUP_STATE.EDITING ? GROUP_STATE.EDITING : GROUP_STATE.INIT;
    this.controlGroupState = this.controlGroupState === GROUP_STATE.EDITING ? GROUP_STATE.EDITING : GROUP_STATE.INIT;
    this.covariantGroupState = GROUP_STATE.INIT;
    this.outcomeGroupState = GROUP_STATE.INIT;

    this.experimentalGroupPatient = cloneDeep(this.totalPatient);
    this.controlGroupPatient = cloneDeep(this.totalPatient);

    this.experimentalLoadingCount = 0;
    this.controlLoadingCount = 0;

    this.currentMenu = '';

    this.disableCompare = true;

    this.studyName = EVENT.DEFAULT_NAME.NEW_STUDY;

    this.nameError = '';
  }

  @Action
  async updateTotalPatient({ api }: any) {
    const queries = [
      {
        aggs: {
          patientNum: {
            cardinality: {
              field: 'CHART_NO',
            },
          },
        },
        size: 0,
      },
    ];
    const patientCountResponse = await api.getTotalPatientCount(queries);
    this.context.commit(
      'updateTotalPatientCount',
      patientCountResponse.patient_count
        ? patientCountResponse.patient_count
        : patientCountResponse.aggregations.patientNum.value
    );
  }

  @Action
  async updateWithPath({ path, ...content }: any) {
    this.context.commit('updateWithPathMutation', { path, ...content });
  }

  @Action
  async updatepatientCount({ api, path }: any) {
    let searchObject = undefined;
    if (path === 'experimental') {
      searchObject = this.eventSearch.experimental;
      this.context.commit('updateExperimentalLoadingCount', this.experimentalLoadingCount + 1);
    } else {
      searchObject = this.eventSearch.control;
      this.context.commit('updateControlLoadingCount', this.controlLoadingCount + 1);
    }

    const queries = SearchQueryGenerator.generateCohortQuery(searchObject);

    try {
      const patientCountResponse = await api.getPatientCount(queries, searchObject);
      const patientCount = patientCountResponse.patient_count;

      this.context.commit('updatePatientCountStatus', { groupType: path, patientCount: patientCount });
    } catch (error) {
      console.error(error);
    }

    if (path === 'experimental') {
      this.context.commit('updateExperimentalLoadingCount', this.experimentalLoadingCount - 1);
    } else {
      this.context.commit('updateControlLoadingCount', this.controlLoadingCount - 1);
    }
  }
}
