


















































import { Vue, Component, Prop } from 'vue-property-decorator';

import { CategoricalValue, RangeValue, KeywordValue } from './values';
import LabValue from './values/LabValue.vue';
import CF from '../../../utils/conditions/core/ConditionFactory';
// eslint-disable-next-line no-unused-vars
import Condition from '../../../utils/conditions/core/Condition';
import {
  CategoryCondition,
  RangeCondition,
  KeywordCondition,
  LabCondition,
  // eslint-disable-next-line no-unused-vars
  ConditionData,
  TermCondition,
  LogicOp,
  DiagnosisRanges,
} from '../../../utils/conditions/core';

@Component<SearchCondition>({
  components: {
    CategoricalValue,
    RangeValue,
    KeywordValue,
    LabValue,
  },
})
export default class SearchCondition extends Vue {
  @Prop([Array, Object])
  private readonly condition!: ConditionData | ConditionData[];

  @Prop({ type: Boolean, default: false })
  private readonly deletable!: boolean;

  @Prop({ type: Boolean, default: false })
  private readonly extend!: boolean;

  @Prop({ type: Number, default: 0 })
  private readonly uniqueIndex!: number;

  // eslint-disable-next-line class-methods-use-this
  get categoryType(): typeof CategoryCondition {
    return CategoryCondition;
  }

  // eslint-disable-next-line class-methods-use-this
  get termType(): typeof TermCondition {
    return TermCondition;
  }

  // eslint-disable-next-line class-methods-use-this
  get rangeType(): typeof RangeCondition {
    return RangeCondition;
  }

  // eslint-disable-next-line class-methods-use-this
  get keywordType(): typeof KeywordCondition {
    return KeywordCondition;
  }

  // eslint-disable-next-line class-methods-use-this
  get labType(): typeof LabCondition {
    return LabCondition;
  }

  get conditionDef(): Condition {
    return CF.create(this.condition);
  }

  get andOr(): string {
    if (this.conditionDef.andOr === LogicOp.And) {
      return 'and';
    }
    return 'or';
  }

  get ICDPrefix(): string {
    const { tags } = this.conditionDef;
    if (tags.includes(DiagnosisRanges.ALL)) {
      return 'Any';
    }
    if (tags.includes(DiagnosisRanges.Primary)) {
      return '1st Code';
    }
    return '1st~5nd Code';
  }

  getConditionDisplayName(): string {
    const { name } = this.conditionDef;
    switch (name) {
      case 'Diagnosis':
        return `${this.ICDPrefix} Diagnosis`;
      case 'Medical Order':
        return 'Order';
      case 'AbnormalLab':
        return 'Abnormal Lab';
      case 'Stay':
        return 'Length of Stay';
      case 'Lab':
        return '';
      case 'Time':
        return 'Time Period';
      default:
        return name;
    }
  }
}
