<template>
  <div
    class="event-container"
    :class="{
      'focus-border': selectType !== -1,
    }"
  >
    <b-row no-gutters>
      <b-col>
        <b-row no-gutters>
          <b-col style="display: inline-flex">
            <div class="d-flex align-items-center">
              <a-switch :defaultChecked="false" @change="switchFirstOccurrence" :checked="checked" />
            </div>
            <div>
              <div class="first-occurrence-text">First occurrence</div>
              <div class="first-occurrence-content-text">Given conditions happened to patients for the first time</div>
            </div>
          </b-col>
          <b-col class="d-flex justify-content-end">
            <div>OR</div>
          </b-col>
        </b-row>
        <b-row no-gutters style="margin-top: 20px">
          <b-col>
            <div
              v-if="refEvent.condition && refEvent.condition.length === 0"
              class="all-condition"
              v-on:click.stop="openCodeSearchMenu()"
            >
              All conditions
            </div>
            <span v-for="(data, index) in refEvent.condition" :key="`${data.name}-${index}`">
              <span v-for="item in data.list" :key="item.code" class="capsule">
                <span class="capsule-abbr" :class="getAbbreviationClass(data.name)">{{
                  getAbbreviation(data.name)
                }}</span>
                <span class="capsule-code">{{ item.code }}</span>
                <b-img
                  src="@/assets/images/icon_capsule_delete.svg"
                  @click="clickDelete(item, data)"
                  class="capsule-delete"
                />
              </span>
            </span>
          </b-col>
        </b-row>
        <b-row no-gutters>
          <b-col class="d-flex constraint-box">
            <div class="constraint-text" v-if="refEvent.constraintList && refEvent.constraintList.length !== 0">
              Constraint:
            </div>
            <div>
              <div
                v-for="(constraint, constraintIndex) in refEvent.constraintList"
                :key="`${constraint.constraintType}`"
              >
                <ConstraintRender
                  :index="index"
                  :eventType="eventType"
                  :constraintType="constraint.constraintType"
                  :data="constraint.data"
                  :path="path"
                  :style="{
                    'margin-bottom': constraintIndex + 1 < refEvent.constraintList.length ? '12px' : '0px',
                  }"
                />
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row no-gutters style="margin-top: 20px">
          <b-col class="d-flex justify-content-between">
            <div>
              <AdvanceCodeSearchMenu
                class="d-inline-block"
                :index="index"
                :eventType="eventType"
                :path="path"
                ref="advanceCodeSearchMenu"
                @updateSelectType="updateSelectType"
              />
              <AdvanceAgeFilterAdder class="d-inline-block" :index="index" :eventType="eventType" :path="path" />
              <AdvanceVisitTypeFilterAdder class="d-inline-block" :index="index" :eventType="eventType" :path="path" />
              <AdvanceDiagnosisRange class="d-inline-block" :index="index" :eventType="eventType" :path="path" />
            </div>
            <div>
              <button class="clear-all-btn d-flex align-items-center justify-content-center" @click="setDefaultState">
                <b-img src="@/assets/images/icon_close_red.svg" class="icon-close-red" />
                <div class="clear-all-text">CLEAR ALL</div>
              </button>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div v-if="index === 2 && eventType === EventType.FIX_EVENT" :class="{ disabled: !this.hasCondition }">
              <InExclusionContainer id="in-exclusion-container" :index="index" :path="path" :event="event">
              </InExclusionContainer>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="timerelation-filter">
              <TimeRelationFilter
                v-if="eventType !== EventType.FIX_EVENT"
                :eventType="eventType"
                :index="index"
                :path="path"
              />
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import AdvanceAgeFilterAdder from '@/components/advanceSearch/filter/AdvanceAgeFilter.vue';
import AdvanceVisitTypeFilterAdder from '@/components/advanceSearch/filter/AdvanceVisitTypeFilterAdder.vue';
import ConstraintRender from '@/components/advanceSearch/constraint/ConstraintRender.vue';
import AdvanceCodeSearchMenu from '@/components/advanceSearch/filter/AdvanceCodeSearchMenu.vue';
import TimeRelationFilter from '@/components/advanceSearch/filter/TimeRelationFilter.vue';
import AdvanceDiagnosisRange from '@/components/advanceSearch/filter/AdvanceDiagnosisRange.vue';
import { EventType } from '@/utils/conditions/core/Interface';
import ConstraintType from '@/utils/conditions/core/advance/constraint/Interface';
import { getAbbreviation, getAbbreviationClass } from '@/utils/util';
import { AdvanceUpdateMixin, AdvanceSearchMixin, ClickMixin } from '@/mixins';
import {
  defaultFirstEvent,
  defaultThirdEvent,
  defaultIndexEvent,
  defaultInclusionEvent,
  defaultExclusionEvent,
} from '@/utils/advance/initState';
import { cloneDeep, pick } from 'lodash';
import { mapState } from '../../store';
import { VUE_EVENT_NAME } from '@/utils/constants';

export default {
  name: 'EventContainer',
  mixins: [AdvanceUpdateMixin, AdvanceSearchMixin, ClickMixin],
  components: {
    AdvanceAgeFilterAdder,
    AdvanceVisitTypeFilterAdder,
    ConstraintRender,
    AdvanceCodeSearchMenu,
    TimeRelationFilter,
    InExclusionContainer: () => import('@/components/advanceSearch/InExclusionContainer.vue'),
    AdvanceDiagnosisRange,
  },
  data() {
    return {
      EventType,
      checked: false,
      hasCodeSearchMenuOpen: false,
      selectType: -1,
    };
  },
  computed: {
    ...mapState('advancePortal', ['SearchStateObject']),
    refEvent() {
      return this.event;
    },
    hasCondition() {
      return this.event.condition.length > 0;
    },
  },
  mounted() {
    this.$root.$on(VUE_EVENT_NAME.SET_CODESEARCH_MENU_OPEN, (value) => {
      this.hasCodeSearchMenuOpen = value;
    });
  },
  destroyed() {
    this.$root.$off(VUE_EVENT_NAME.SET_CODESEARCH_MENU_OPEN);
  },
  props: {
    index: {
      type: Number,
      default: 1,
    },
    event: {
      type: Object,
      default: null,
    },
    eventType: {
      type: String,
      default: EventType.FIX_EVENT,
    },
    path: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    getAbbreviation,
    getAbbreviationClass,
    clickDelete(capsule, data) {
      const result = data;
      const resultList = data.list.filter((item) => item.code !== capsule.code);
      result.list = resultList;

      const filterCondition = [];
      this.refEvent.condition.forEach((element) => {
        const filterResult = element;
        if (element.name === data.name) {
          const elementCondition = element.list.filter((item) => item.code !== capsule.code);
          filterResult.list = elementCondition;
        }

        if (filterResult.list.length !== 0) {
          filterCondition.push(filterResult);
        }

        if (filterResult.list.length === 0 && filterResult.name === 'Diagnosis') {
          this.deleteConstraint(ConstraintType.DiagnosisRangeConstraint);
        }
      });

      const body = {
        condition: filterCondition,
      };

      this.updateEventWithPath(body, this.path);
      this.advanceTrackClick('clickDelete', {
        path: this.path,
        index: this.index,
        capsule,
      });
    },
    switchFirstOccurrence(check) {
      this.checked = check;
      const body = {
        constraint: {
          ...this.refEvent.constraint,
          firstOccurrence: check,
        },
      };
      this.updateEventWithPath(body, this.path);

      this.advanceTrackClick('switchFirstOccurrence', {
        path: this.path,
        index: this.index,
        firstOccurrence: check,
      });
    },
    setDefaultState() {
      let body = {};
      if (this.eventType === EventType.FIX_EVENT) {
        const indexToDefaultEventListMapper = new Map([
          [1, pick(cloneDeep(defaultFirstEvent), ['constraintList', 'constraint', 'condition'])],
          [
            2,
            pick(cloneDeep(defaultIndexEvent), [
              'constraintList',
              'constraint',
              'condition',
              'inclusionList',
              'exclusionList',
            ]),
          ],
          [3, pick(cloneDeep(defaultThirdEvent), ['constraintList', 'constraint', 'condition'])],
        ]);
        body = indexToDefaultEventListMapper.get(this.index);
      } else if (this.eventType === EventType.INCLUSION) {
        body = pick(cloneDeep(defaultInclusionEvent), ['constraintList', 'constraint', 'condition']);
      } else if (this.eventType === EventType.EXCLUSION) {
        body = pick(cloneDeep(defaultExclusionEvent), ['constraintList', 'constraint', 'condition']);
      }

      this.updateEventWithPath(body, this.path);
      this.$refs.advanceCodeSearchMenu.clearCondition();
      this.checked = false;

      this.advanceTrackClick('setDefaultState', {
        path: this.path,
        index: this.index,
      });
    },
    openCodeSearchMenu() {
      this.$root.$emit(VUE_EVENT_NAME.SET_CODESEARCH_MENU_OPEN, this.path);
      if (this.eventType === EventType.FIX_EVENT) {
        this.$root.$emit(VUE_EVENT_NAME.SET_SCROLL_POSITION, this.index, this.eventType);
      }
    },
    updateSelectType(value) {
      this.selectType = value;
    },
  },
};
</script>
<style lang="scss" scoped>
.event-container {
  border: 1px solid #bdbdbd;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 20px;
}

.capsule {
  padding: 3px 10px;
  background: #333f6b;
  border-radius: 53px;
  margin: 0px 10px 10px 0px;
  display: inline-block;
}

.capsule-abbr {
  padding: 0px 2px;
  background: #c2caea;
  border-radius: 2px;
  color: #0f4895;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  margin-right: 5px;
}

.capsule-code {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  margin-right: 5px;
}

.capsule-delete {
  cursor: pointer;
  margin-bottom: 3px;
}

.abbr-md {
  background: #6fcf97;
}

.abbr-o {
  background: #f5ba42;
}

.first-occurrence-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #333333;
  margin-left: 10px;
}

.clear-all-btn {
  width: 132px;
  height: 30px;
  border: 1px solid #da6d6d;
  box-sizing: border-box;
  border-radius: 6px;
  background-color: #ffffff;
}

.icon-close-red {
  margin-right: 8px;
}

.clear-all-text {
  font-size: 16px;
  line-height: 20px;
  color: #da6d6d;
}

button:focus {
  outline: none !important;
}

.all-condition {
  display: inline-block;
  min-height: 37px;
  font-size: 16px;
  color: #828282;
}

.first-occurrence-content-text {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #828282;
  margin-left: 10px;
}

.focus-border {
  border: 2px solid #4a94d2;
}

.timerelation-filter {
  margin-top: 23px;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.constraint-box {
  border-left: 2px solid rgba(15, 72, 149, 0.5);
}

.constraint-text {
  height: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #333333;
  margin: 10px 0px 0px 10px;
}
</style>
