import { getLabName } from '@/utils/lab/LabName';

export function handleFirstLayer(listOfDict, handleColumn, visitType) {
  const firstLayerMap = {};
  let counter = 0;
  // eslint-disable-next-line no-restricted-syntax
  for (const lab of listOfDict) {
    if (!(lab.CHSPECI in firstLayerMap)) {
      firstLayerMap[lab.CHSPECI] = {};
      firstLayerMap[lab.CHSPECI].title = lab.CHSPECI;
      firstLayerMap[lab.CHSPECI].children = [];
      firstLayerMap[lab.CHSPECI].align = 'left';
      firstLayerMap[lab.CHSPECI].className = 'first-layer-row';
    }
    const key = lab.name;
    const secondLayer = handleColumn(lab, key, counter);
    firstLayerMap[lab.CHSPECI].children.push(secondLayer);
    counter += 1;
  }
  // sort firstLayerMap according to known lab items (i.e., chHeadList)
  Object.keys(firstLayerMap).forEach((key) => {
    const chHeadList = getLabName(visitType)[key];
    if (chHeadList === undefined) {
      console.warn(`cannot find chHeadList for key: ${key}, maybe we should update LabNames`);
      return;
    }
    // eslint-disable-next-line max-len
    // eslint-disable-next-line arrow-body-style
    firstLayerMap[key].children.sort((a, b) => {
      // eslint-disable-next-line max-len
      return chHeadList.indexOf(a.chHead) - chHeadList.indexOf(b.chHead);
    });
  });

  return Object.values(firstLayerMap).sort((a, b) => {
    const labList = Object.keys(getLabName(visitType));
    return labList.indexOf(a.title) - labList.indexOf(b.title);
  });
}

export function processPatientLabRecord(records) {
  const dict = {};
  const timeDict = {};
  const categoryDict = {};
  let keyCounter = 0;
  records.forEach((inpatientEvent) => {
    // eslint-disable-next-line no-underscore-dangle
    const labRecords = inpatientEvent._source.LAB.sort((a, b) => b.SEQNO - a.SEQNO);
    labRecords.forEach((labRecord) => {
      const name = `${labRecord.CHSPECI} ${labRecord.CHHEAD} (${labRecord.UNIT})`;
      if (!(name in dict)) {
        dict[name] = {
          counter: 0,
          abCounter: 0,
          CHNL: labRecord.CHNL,
          CHNH: labRecord.CHNH,
          isNumber: labRecord.is_number,
          CHSPECI: labRecord.CHSPECI,
          CHHEAD: labRecord.CHHEAD,
          UNIT: labRecord.UNIT,
        };
      }

      dict[name].counter += 1;

      // store data by date
      const timekey = labRecord.EXAM_DATE;
      if (!(timekey in timeDict)) {
        timeDict[timekey] = {
          [name]: labRecord.is_number ? labRecord.VALUE : labRecord.CHCOMMT,
          key: keyCounter,
        };
        keyCounter += 1;
      } else {
        timeDict[timekey][name] = labRecord.is_number ? labRecord.VALUE : labRecord.CHCOMMT;
      }

      // store data by category
      if (!(name in categoryDict)) {
        categoryDict[name] = {
          counter: 0,
          valHis: [],
        };
        categoryDict[name].lowBound = labRecord.CHNL;
        categoryDict[name].highBound = labRecord.CHNH;
        categoryDict[name].examName = labRecord.CHHEAD;
        categoryDict[name].unit = labRecord.UNIT;
      }
      if (labRecord.is_number) {
        categoryDict[name].valHis.push([labRecord.EXAM_DATE, labRecord.VALUE]);
      }
    });
  });
  return { dict, timeDict, categoryDict };
}
