<template>
  <div class="time-description w-100 d-flex align-items-center">
    <b-row no-gutters class="w-100">
      <b-col>
        <vue-slider
          id="time-describe-slider"
          v-model="sliderValue"
          :height="1"
          :process="false"
          :marks="marks"
          :clickable="false"
          :railStyle="{
            color: '#828282',
          }"
          tooltip="none"
          style="margin-top: 30px"
          :disabled="true"
        >
          <template v-slot:dot="{ value }">
            <div class="d-flex align-items-center flex-column event-position" v-if="value === getSliderPosition(1)">
              <div
                :id="`graph-first-event${uuid}`"
                class="event-name"
                @mouseover="setEventTime(1)"
                @mouseleave="hoverEvent(1, getFirstEventConditionDescription)"
              >
                {{ firstEventFP.name }}
                <div class="first-time-text" v-if="firstEventFP.constraint.firstOccurrence">first time</div>
              </div>
              <b-tooltip
                variant="tooltip-style"
                :target="`graph-first-event${uuid}`"
                triggers="hover"
                v-if="value === getSliderPosition(1) && firstEventFP.condition.length > 0"
              >
                <EventToolTip :event="firstEventFP" />
              </b-tooltip>
              <div class="event-line"></div>
              <div class="event-circle"></div>
            </div>
            <div v-else></div>
            <div class="d-flex align-items-center flex-column event-position" v-if="value === getSliderPosition(2)">
              <div
                :id="`graph-index-event${uuid}`"
                class="event-name"
                :title="getIndexEventConditionDescription"
                @mouseover="setEventTime(2)"
                @mouseleave="hoverEvent(2, getIndexEventConditionDescription)"
              >
                {{ indexEventFP.name }}
                <div class="first-time-text" v-if="indexEventFP.constraint.firstOccurrence">first time</div>
              </div>
              <b-tooltip
                variant="tooltip-style"
                :target="`graph-index-event${uuid}`"
                triggers="hover"
                v-if="value === getSliderPosition(2) && indexEventFP.condition.length > 0"
              >
                <EventToolTip :event="indexEventFP" />
              </b-tooltip>
              <div class="event-line"></div>
              <div class="event-circle"></div>
            </div>
            <div v-else></div>
            <div class="d-flex align-items-center flex-column event-position" v-if="value === getSliderPosition(3)">
              <div
                class="event-name"
                :id="`graph-third-event${uuid}`"
                :title="getThirdEventConditionDescription"
                @mouseover="setEventTime(3)"
                @mouseleave="hoverEvent(3, getThirdEventConditionDescription)"
              >
                {{ thirdEventFP.name }}
                <div class="first-time-text" v-if="thirdEventFP.constraint.firstOccurrence">first time</div>
              </div>
              <b-tooltip
                :target="`graph-third-event${uuid}`"
                triggers="hover"
                variant="tooltip-style"
                v-if="value === getSliderPosition(3) && thirdEventFP.condition.length > 0"
              >
                <EventToolTip :event="thirdEventFP" />
              </b-tooltip>
              <div class="event-line"></div>
              <div class="event-circle"></div>
            </div>
            <div v-else></div>
          </template>
        </vue-slider>
      </b-col>
    </b-row>
    <div
      class="event-timerelation d-flex justify-content-between"
      v-if="getFirstTimerelationText !== ''"
      style="right: calc(50% + 15px)"
    >
      <b-img src="@/assets/images/left-arrow.svg" class="icon-arrow" />
      <div>{{ getFirstTimerelationText }}</div>
      <b-img src="@/assets/images/left-arrow.svg" style="transform: rotate(180deg)" class="icon-arrow" />
    </div>
    <div
      class="event-timerelation d-flex justify-content-between"
      v-if="getSecondTimerelationText !== ''"
      style="left: calc(50% + 15px)"
    >
      <b-img src="@/assets/images/left-arrow.svg" class="icon-arrow" />
      <div>{{ getSecondTimerelationText }}</div>
      <b-img src="@/assets/images/left-arrow.svg" style="transform: rotate(180deg)" class="icon-arrow" />
    </div>
    <div
      class="d-flex align-items-center flex-column statistics-div"
      v-if="uniqueIndex !== -1"
      :style="getStatisticsWidth"
    >
      <div class="w-100 d-flex justify-content-between">
        <b-img src="@/assets/images/center-left-arrow.svg" class="icon-arrow" />
        <b-img src="@/assets/images/center-right-arrow.svg" class="icon-arrow" />
      </div>
      <div class="statistics-period">Statistics period</div>
      <div class="statistics-content">{{ getStatistics }}</div>
    </div>
  </div>
</template>
<script>
import { getEventDesc, getConstraintName, transformTimeUnit } from '@/utils/util';
import { AdvanceTabMixin, EventMixin, AdvanceSearchMixin } from '@/mixins';
import { DateUnit } from '@/utils/conditions/core/Interface';
import EventToolTip from '@/components/advanceSearch/EventToolTip.vue';
import { v4 as uuid } from 'uuid';

import { mapState } from '../../../store';

const sliderValue = [10, 50, 90];

const marks = {
  100: {
    label: '▶',
    labelStyle: {
      left: '100%',
      margin: '0 0 0 -2px',
      top: '50%',
      transform: 'translateY(-50%)',
      color: '#828282',
    },
  },
};

export default {
  name: 'TimeDescription',
  mixins: [AdvanceTabMixin, EventMixin, AdvanceSearchMixin],
  components: {
    EventToolTip,
  },
  props: {
    uniqueIndex: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      sliderValue,
      marks,
      uuid: '',
    };
  },
  computed: {
    ...mapState('byEventSession', ['tabs']),
    timeSelectObject() {
      return this.tabs[this.uniqueIndex].TimeSelectObject;
    },
    getFirstTimerelationText() {
      if (this.firstEventFP.relation.beforeValue === 'any') {
        return '';
      }
      return `≤ ${this.firstEventFP.relation.beforeValue} ${this.firstEventFP.relation.beforeUnit}`;
    },
    getSecondTimerelationText() {
      if (this.thirdEventFP.relation.afterValue === 'any') {
        return '';
      }
      return `≤ ${this.thirdEventFP.relation.afterValue} ${this.thirdEventFP.relation.afterUnit}`;
    },
    getFirstEventConditionDescription() {
      return this.getEventDesc(this.firstEventFP);
    },
    getThirdEventConditionDescription() {
      return this.getEventDesc(this.thirdEventFP);
    },
    getIndexEventConditionDescription() {
      return this.getEventDesc(this.indexEventFP);
    },
    getStatistics() {
      return transformTimeUnit(
        `${this.tabs[this.uniqueIndex].TimeSelectObject.afterValue} ${
          this.tabs[this.uniqueIndex].TimeSelectObject.afterUnit
        }`
      );
    },
    checkStatisticsRange() {
      const statisticsDays =
        this.getTimeUnit(this.tabs[this.uniqueIndex].TimeSelectObject.afterUnit) *
        this.tabs[this.uniqueIndex].TimeSelectObject.afterValue;

      const days = this.getTimeUnit(this.thirdEventFP.relation.afterUnit) * this.thirdEventFP.relation.afterValue;

      return statisticsDays > days;
    },
    getStatisticsWidth() {
      if (
        this.getMonth(this.thirdEventFP.relation.afterValue, this.thirdEventFP.relation.afterUnit) -
          this.getMonth(this.timeSelectObject.afterValue, this.timeSelectObject.afterUnit) ===
        0
      ) {
        return 'width: calc(40% - 7px);';
      }
      if (this.thirdEventFP.relation.afterValue !== 'any' && this.checkStatisticsRange) {
        return 'width: calc(45%);';
      }
      return 'width: calc(30%);';
    },
  },
  mounted() {
    this.uuid = uuid();
  },
  methods: {
    getEventDesc,
    getConstraintName,
    getTimeUnit(unit) {
      switch (unit) {
        case DateUnit.DAYS:
          return 1;
        case DateUnit.MONTHS:
          return 30;
        case DateUnit.YEARS:
          return 360;
        default:
          throw new Error('Get time unit error.');
      }
    },
    getMonth(volumn, unit) {
      if (volumn === 'any') {
        return Infinity;
      }
      if (unit === DateUnit.YEARS) {
        return parseInt(volumn, 10) * 12;
      }
      if (unit === DateUnit.DAYS) {
        return 1;
      }
      return volumn;
    },
    checkConditionNull(event) {
      return event.condition.length === 0;
    },
    check3EvenNull() {
      return (
        this.checkConditionNull(this.firstEventFP) &&
        this.checkConditionNull(this.indexEventFP) &&
        this.checkConditionNull(this.thirdEventFP)
      );
    },
    getfoolProofEvent(index) {
      const indexToFPEventListMapper = new Map([
        [1, this.firstEventFP],
        [2, this.indexEventFP],
        [3, this.thirdEventFP],
      ]);
      return indexToFPEventListMapper.get(index);
    },
    getSliderPosition(index) {
      if (this.check3EvenNull()) {
        return this.sliderValue[index - 1];
      }

      const event = this.getfoolProofEvent(index);

      if (!this.checkConditionNull(event)) {
        return this.sliderValue[index - 1];
      }

      return -1;
    },
  },
};
</script>
<style>
.tooltip.b-tooltip {
  opacity: 1 !important;
}

.b-tooltip > .tooltip-inner {
  max-height: 350px;
  overflow-y: auto;
  max-width: 456px;
  background-color: white;
  border: #bdbdbd 1px solid;
  color: black;
  text-align: inherit;
  padding: 0px;
  border-radius: 6px;
}

.b-tooltip > .arrow::before {
  display: none;
}
</style>
<style lang="scss" scoped>
.time-description {
  padding: 0px 10px;
}

.event-circle {
  background-color: #4a94d2;
  width: 10px;
  height: 10px;
  border-radius: 10px;
}

.event-line {
  background-color: #4a94d2;
  width: 2px;
  height: 28px;
}

.event-name {
  height: 18px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #0f4895;
  width: max-content;
}

.event-timerelation {
  height: 16px;
  font-size: 13px;
  line-height: 16px;
  color: #333333;
  width: calc(40% - 40px);
  position: absolute;
  margin-bottom: 7px;
}

.event-position {
  transform: translateY(calc(-100% + 12px));
}

.statistics-period {
  height: 18px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #212121;
}

.statistics-content {
  height: 16px;
  font-size: 13px;
  line-height: 16px;
  color: #212121;
}

.statistics-div {
  position: absolute;
  margin-top: 90px;
  left: 50%;
}

.vue-slider-disabled {
  opacity: 1;
  cursor: default;
}

.first-time-text {
  height: 18px;
  font-size: 12px;
  line-height: 15px;
  color: #828282;
  font-weight: normal;
  display: contents;
}
</style>
