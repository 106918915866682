<template>
  <a-modal
    id="viewAllModal"
    v-model="visible"
    class="view-all-modal"
    destroyOnClose
    :footer="null"
    centered
    width="fit-content"
    :zIndex="9999"
  >
    <template #closeIcon>
      <img src="@/../public/img/icon_close.svg" alt="icon_close" />
    </template>
    <template #title>
      <div class="title">
        {{ title }}
        <div class="subtitle">{{ subtitle }}</div>
      </div>
    </template>
    <div :style="{ width: '1200px' }" class="h-100">
      <slot name="content" />
    </div>
  </a-modal>
</template>

<script>
import { ClickMixin } from '@/mixins';

export default {
  name: 'ViewAllModal',
  mixins: [ClickMixin],
  props: {
    title: String,
    subtitle: String,
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    show() {
      this.visible = true;
    },
  },
};
</script>

<style scoped>
/* #viewAllModal >>> .ant-modal-content {
    max-height: 800px;
  } */

#viewAllModal >>> .ant-modal-body {
  overflow: auto;
  max-height: 800px;
  padding-right: 20px;
}
</style>

<style lang="scss" scoped>
/deep/ .ant-modal-header {
  border-bottom: 0px !important;
  padding: 16px 27px 30px 27px !important;
}

.view-all-modal {
  min-width: 500px;
}

.title {
  height: 20px;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333f6b;
  margin: 0px 0px 10px 20px;

  .subtitle {
    font-size: 14px;
    color: #828282;
  }
}

.ant-modal {
  transition: width 1s;
  width: fit-content;
}

.ps-container > .ps-scrollbar-y-rail {
  opacity: 0.6;
}
</style>
