<template>
  <div @mouseenter="setHoverStart" @mouseleave="setHoverEnd">
    <div v-if="noResult" class="no-result">
      <div class="survival-rate-text ml-20px mt-20px" style="position: absolute">Kaplan-Meier Curve</div>
      <div class="no-result-wrapper">
        <div class="mb-10px">
          <b-img src="@/assets/images/icon_no_results.svg" />
        </div>
        <div class="no-results-text">No results</div>
      </div>
    </div>
    <div v-else-if="loading" class="no-result">
      <div class="survival-rate-text ml-20px mt-20px" style="position: absolute">Kaplan-Meier Curve</div>
      <div class="no-result-wrapper">
        <a-progress
          type="circle"
          :percent="progressPercent"
          :width="70"
          :strokeWidth="8"
          strokeColor="#4D8EDC"
          trailColor="#E0E0E0"
        >
          <template #format="percent">
            <span class="progress-text">{{ `${percent}%` }}</span>
          </template></a-progress
        >
        <div class="progress-text mt-10px" v-if="requestInProgress">Analyzing...</div>
        <div class="progress-text mt-10px" v-else>Queuing...</div>
      </div>
    </div>
    <div class="survival-rate-chart" v-else>
      <b-row no-gutters>
        <b-col>
          <div class="survival-rate-text">Kaplan-Meier Curve</div>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col>
          <v-chart :options="option" class="chart" autoresize />
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col class="d-flex justify-content-between">
          <div class="month-text">{{ pValue ? `*P value ${pValue}` : pValue }}</div>
          <div class="month-text">Month</div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import SurvivalRateChart from '@/components/byEvent/result/SurvivalRateChart.json';
import { cloneDeep } from 'lodash';
import { mapState, mapMutations } from '@/store';
import dayjs from 'dayjs';
import { ClickMixin } from '@/mixins';
import { FEEDBACK, API, VUE_EVENT_NAME } from '@/utils/constants';
import { delay, calcProgress } from '@/utils/util';
import { SLA_DEFINITION } from '@/utils/cohortProgressConfig';
import numeral from 'numeral';

const POLLING_PERIOD = 3000;

function formatedTemplateNumber(number) {
  return numeral(number).format('0,0');
}

function percentageFormatter(seriesList) {
  const itemData1 = seriesList[0].data;
  const name1 = seriesList[0].seriesName;
  const String1 = `Time: ${itemData1.name} months<br><br>${name1} Current Patient: ${formatedTemplateNumber(
    itemData1.at_risk
  )}<br>${name1} observed: ${formatedTemplateNumber(itemData1.observed)}<br>${name1} censored: ${formatedTemplateNumber(
    itemData1.censored
  )}<br>${name1} Survival rate: ${itemData1.value}%`;

  const itemData2 = seriesList[1].data;
  const name2 = seriesList[1].seriesName;
  const String2 = `<br><br>${name2} Current Patient: ${formatedTemplateNumber(
    itemData2.at_risk
  )}<br>${name2} observed: ${formatedTemplateNumber(itemData2.observed)}<br>${name2} censored: ${formatedTemplateNumber(
    itemData2.censored
  )}<br>${name2} Survival rate: ${itemData2.value}%`;
  return String1 + String2;
}

function xAxisformatter(value) {
  return `${value.toString()}m`;
}

function yAxisformatter(value) {
  return `${value.toString()}%`;
}

export default {
  name: 'SurvivalRateChart',
  mixins: [ClickMixin],
  props: {
    uniqueIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      option: {},
      loading: false,
      noResult: false,
      pValue: null,
      progressPercent: 0,
      requestInProgress: false,
      queryData: {},
    };
  },
  watch: {
    uniqueIndex() {
      this.updateData();
    },
  },
  mounted() {
    this.updateData();

    this.fn = () => {
      this.getCMCurveData(true);
    };
    this.$root.$on(VUE_EVENT_NAME.SAVE_ANALYSIS_RECORD, this.fn);
  },
  beforeDestroy() {
    this.$root.$off(VUE_EVENT_NAME.SAVE_ANALYSIS_RECORD, this.fn);
  },
  computed: {
    ...mapState('cohortSession', ['tabs']),
    tab() {
      return this.tabs[this.uniqueIndex];
    },
    experimentalName() {
      return this.tab.eventSearch.experimental.name;
    },
    controlName() {
      return this.tab.eventSearch.control.name;
    },
    eventSearch() {
      return this.tab.eventSearch;
    },
  },
  methods: {
    ...mapMutations('cohortSession', ['updateTab']),
    async pollingData() {
      let result = { result: 'processing' };
      while (result.result === 'processing' && result.result !== 'fail') {
        result = await this.getCMCurveData();
        if (result.result === 'processing') {
          await delay(POLLING_PERIOD);
        }
      }

      if (result.result === 'fail' || result.groups.length === 0) {
        this.noResult = true;
      } else {
        this.noResult = false;
      }

      this.patientSurvivalRate = result;
      return Promise.resolve();
    },
    async updateProgressPercent() {
      while (this.loading) {
        this.requestInProgress = this.$api.checkInProgress(this.queryData, API.PATIENT_KMCURVE);
        if (this.requestInProgress) {
          this.progressPercent = calcProgress(
            JSON.parse(this.requestInProgress).startTime,
            SLA_DEFINITION.PATIENT_KM_CURVE
          );

          this.updateTab({
            index: this.uniqueIndex,
            survivalRateChartRequestInProgress: true,
            survivalRateChartProgressPercent: this.progressPercent,
          });
        }
        await delay(100);
      }
      this.updateTab({
        index: this.uniqueIndex,
        survivalRateChartRequestInProgress: false,
        survivalRateChartProgressPercent: 100,
      });
    },
    async updateData() {
      try {
        this.loading = true;

        this.progressPercent = 0;
        this.requestInProgress = false;
        this.updateProgressPercent();
        await this.pollingData();
        this.setOption();
      } catch (error) {
        console.error(error);
        this.noResult = true;
      }
      this.progressPercent = 0;
      this.requestInProgress = false;
      this.loading = false;
      this.updateTab({
        index: this.uniqueIndex,
        patientListRequestInProgress: false,
        patientListProgressPercent: 100,
      });
    },
    getRecoveryParams() {
      if (this.tab.tabType === 'history' || this.tab.tabType === 'save') {
        return {
          get_latest: this.tab.getLatest,
          url: this.tab.url || this.tab.id,
        };
      }
      return {
        get_latest: true,
        url: this.tab.url || this.tab.id,
      };
    },
    async getCMCurveData(saveAnalysisRecord = false) {
      const params = {
        searchState: this.eventSearch,
      };
      if (saveAnalysisRecord) {
        const query = {
          ...params,
          ...{
            get_latest: true,
            url: this.tab.id,
          },
        };
        this.queryData = query;
        return this.$api.getPatientCMCurveData(query);
      }
      const query = {
        ...params,
        ...this.getRecoveryParams(),
      };
      this.queryData = query;
      return this.$api.getPatientCMCurveData(query);
    },
    setOption() {
      const option = cloneDeep(SurvivalRateChart);

      this.pValue = this.patientSurvivalRate.p_value;

      const xAxisData = [];
      for (let index = 0; index <= 60; index++) {
        xAxisData.push(index);
      }

      const yAxisExperimentalData = [];
      const yAxisControlData = [];

      const experimentalData = this.patientSurvivalRate.groups.find((item) => item.name === 'experimental');
      if (experimentalData) {
        experimentalData.data.forEach((item) => {
          yAxisExperimentalData.push({
            key: item.month,
            value: Math.round(item.value),
            name: item.month,
            color: '#f1a15d',
            at_risk: item.at_risk,
            censored: item.censored,
            observed: item.observed,
            percentage: Math.round(item.value),
            itemStyle: {
              normal: {
                color: '#f1a15d',
              },
            },
          });
        });
      }

      const controlData = this.patientSurvivalRate.groups.find((item) => item.name === 'control');
      if (controlData) {
        controlData.data.forEach((item) => {
          yAxisControlData.push({
            key: item.month,
            value: Math.round(item.value),
            name: item.month,
            color: '#549cea',
            at_risk: item.at_risk,
            censored: item.censored,
            observed: item.observed,
            percentage: Math.round(item.value),
            itemStyle: {
              normal: {
                color: '#549cea',
              },
            },
          });
        });
      }

      option.xAxis.data = xAxisData;
      option.series = [
        {
          name: this.experimentalName,
          type: 'line',
          data: yAxisExperimentalData,
          barWidth: '70%',
          animation: false,
          symbol: 'circle',
          symbolSize: 14,
          showSymbol: false,
          cursor: 'default',
          lineStyle: { color: '#f1a15d' },
        },
        {
          name: this.controlName,
          type: 'line',
          data: yAxisControlData,
          barWidth: '70%',
          animation: false,
          symbol: 'circle',
          symbolSize: 14,
          showSymbol: false,
          cursor: 'default',
          lineStyle: { color: '#549cea' },
        },
      ];
      option.tooltip.formatter = percentageFormatter;
      option.xAxis.axisLabel.formatter = xAxisformatter;
      option.yAxis.axisLabel.formatter = yAxisformatter;

      this.option = option;
    },
    setHoverStart() {
      this.timestamp = dayjs();
    },
    setHoverEnd() {
      const diff = dayjs() - this.timestamp;

      this.cohortStudyTrackClick(FEEDBACK.NAME.CHART_TOOLTIP_HOVER, {
        diff,
        title: 'SurvivalRateChart',
        [FEEDBACK.MODE]: FEEDBACK.MODE_TYPE.COHORT_STUDY,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.survival-rate-chart {
  padding: 20px 20px;
  box-shadow: 0px 4px 16px #e0e0e0, 0px 16px 16px rgba(157, 205, 245, 0.1);
  border-radius: 8px;
  height: 318px;
}

.survival-rate-text {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #212121;
}

.month-text {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #757575;
}

.chart {
  margin: 0px auto;
  height: 240px;
  width: 100%;
}

.loading-spin {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
}

.loading-disable {
  opacity: 0.5;
}

.no-result {
  height: 300px;
  box-shadow: 0px 4px 16px #e0e0e0, 0px 16px 16px rgba(157, 205, 245, 0.1);
  border-radius: 8px;
}

.no-result-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.background {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}

.no-results-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #9e9e9e;
}

.progress-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}
</style>
