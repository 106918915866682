<template>
  <div>
    <b-row no-gutters>
      <b-col v-if="state === 'summary'">
        <AdvanceStatisticSummary :uniqueIndex="uniqueIndex" />
      </b-col>
      <b-col v-if="state === 'patientList'">
        <PatientList :uniqueIndex="uniqueIndex" />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import AdvanceStatisticSummary from '@/components/advanceSearch/analysis/AdvanceStatisticSummary.vue';
import PatientList from '@/components/advanceSearch/analysis/PatientList.vue';
import { mapState } from '@/store';

export default {
  name: 'AdvanceAnalysis',
  components: {
    AdvanceStatisticSummary,
    PatientList,
  },
  props: {
    uniqueIndex: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapState('byEventSession', ['tabs']),
    state() {
      return this.tabs[this.uniqueIndex].state;
    },
  },
};
</script>
