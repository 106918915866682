<template>
  <a-modal
    :visible="icdMapVisible"
    centered
    :mask="true"
    :destroyOnClose="true"
    :footer="null"
    @cancel="autoSave"
    :zIndex="9999"
    :closable="false"
  >
    <div class="title-block">
      <div class="icd-map-title-text">Mapped ICD-9</div>
      <div class="d-flex align-items-center mt-20px">
        <div class="icd-map-title-text-2">Total</div>
        <div class="icd-map-title-text">
          {{ `\xa0${formatedIcdMapPatient}\xa0` }}
        </div>
        <div class="icd-map-title-text-2">patients</div>
        <div
          class="d-flex align-items-center ml-10px patient-count-button"
          @click="updateIcdMapPatient"
          :class="{ 'button-disable': loadingCount !== 0 }"
        >
          <b-img
            src="@/assets/images/icon_event_count_loading.svg"
            style="cursor: pointer"
            class="mr-10px"
            :class="{ rotate: loadingCount !== 0 }"
          />
          <div class="count-patient-text">Calculate</div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-wrap mt-20px" style="padding: 0px 20px">
      <div v-for="tag in getTag" :key="tag.code" class="tag d-flex align-items-center">
        <div class="partial-dot" v-if="tag.partial"></div>
        <div>
          {{ tag.code }}
        </div>
      </div>
    </div>
    <div class="codesearch-menu" v-if="options.length !== 0">
      <div class="codesearch-menu-content">
        <b-row no-gutters class="header-row">
          <b-col cols="2" class="d-flex align-items-center">
            <b-img
              src="@/assets/images/icon_unselect_blue.svg"
              class="icon-unselect"
              v-if="!selectAll"
              @click="handleSelectAll"
            />
            <b-img src="@/assets/images/icon_select_blue.svg" class="icon-unselect" v-else @click="handleUnselectAll" />
            <div class="header-text-left-title font-weight-normal" style="margin-left: 10px">ICD-9</div>
          </b-col>
          <b-col class="header-text-left"> Description </b-col>
          <b-col cols="2" class="header-text-right"> Patients/Records </b-col>
        </b-row>
        <VuePerfectScrollbar @wheel.stop>
          <b-row no-gutters v-for="option in options" :title="option.description" :key="option.code" class="row-option">
            <b-col>
              <b-row
                no-gutters
                class="code-search-menu"
                :class="{ 'disable-option': option.patientNum === 0 }"
                @click="handleChange(option)"
              >
                <b-col
                  cols="2"
                  class="d-flex align-items-center"
                  :style="{
                    'margin-left': `${getExpandMargin(option.level)}px`,
                  }"
                >
                  <b-img src="@/assets/images/icon_unselect_blue.svg" class="icon-unselect" v-if="!option.check" />
                  <b-img src="@/assets/images/icon_select_blue.svg" class="icon-unselect" v-else />
                  <div class="code-text">{{ `${option.code}` }}</div>
                </b-col>
                <b-col
                  style="padding: 6px 0px"
                  class="d-flex align-items-center"
                  :style="{
                    flex: `0 0 calc(100%/12*8 - ${getExpandMargin(option.level)}px)`,
                    'max-width': `calc(100%/12*8 - ${getExpandMargin(option.level)}px)`,
                  }"
                >
                  <div style="max-width: calc(100% - 64px)">
                    <div class="icd-description">{{ option.description }}</div>
                    <div class="icd-description">
                      {{ option.chineseDescription }}
                    </div>
                  </div>
                  <b-img
                    src="@/assets/images/icon_expand_open_blue.svg"
                    class="icon-expand ml-auto"
                    v-if="!option.billable && !option.expand"
                    @click.stop="openExpand(option)"
                  />
                  <b-img
                    src="@/assets/images/icon_expand_close_blue.svg"
                    class="icon-expand ml-auto"
                    v-if="!option.billable && option.expand"
                    @click.stop="closeExpand(option)"
                  />
                </b-col>
                <b-col
                  class="icd-record-count d-flex align-items-center justify-content-end"
                  v-if="option.patientNum !== undefined"
                  cols="2"
                >
                  <div class="icd-record-count">
                    {{ formatedTemplateNumber(option.patientNum) }} /
                    {{ formatedTemplateNumber(option.recordNum) }}
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </VuePerfectScrollbar>
      </div>
    </div>
    <div>
      <button class="save-button" @click="clickDone">Done</button>
    </div>
  </a-modal>
</template>
<script>
/* eslint-disable no-param-reassign */
import { PatientNumberMixin, ClickMixin, EventSearchMixin, EventUpdateMixin } from '@/mixins';
import { cloneDeep, difference, intersection } from 'lodash';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { formatIcdGroupMapItem, getIcdFormatList } from '@/utils/util';
import { mapMutations, mapState } from '@/store';
import { defaultExperimental } from '@/utils/event/initState';
import { SearchQueryGenerator } from '@/utils/lumos/SearchQueryGenerator';
import { VUE_EVENT_NAME } from '@/utils/constants';

export default {
  name: 'IcdMap',
  mixins: [PatientNumberMixin, ClickMixin, EventSearchMixin, EventUpdateMixin],
  props: {
    path: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    VuePerfectScrollbar,
  },
  data() {
    return {
      icdMapVisible: false,
      condition: [],
      options: [],
      selectAll: true,
      selectDescendants: [],
      unselectDescendants: [],
      candidateDescendants: [],
      formatedIcdMapPatient: 0,
      icd9Condition: [],
      savedTextTimeout: null,
      icdMapchecked: !this.$store.state.confirmIcdMap,
      tmpSelectDescendants: [],
      tmpUnselectDescendants: [],
      loadingCount: 0,
    };
  },
  computed: {
    ...mapState('user', ['userId']),
    ...mapState(['confirmIcdMap']),
    getTag() {
      if (this.condition[0]) {
        return this.condition[0].list;
      }
      return [];
    },
    currentConditions() {
      return this.currentEvent.condition;
    },
    currentExcludedConditions() {
      return [];
    },
  },
  mounted() {
    this.$root.$on(VUE_EVENT_NAME.SYNC_ICD9_UNSELECT_DESCENDANTS_EVENT, (value, path) => {
      if (this.path === path) {
        this.unselectDescendants = difference(this.unselectDescendants, value);
      }
    });

    this.$root.$on(VUE_EVENT_NAME.RESET_ICD9_UNSELECT_DESCENDANTS_EVENT, (path) => {
      if (this.path === path) {
        this.selectDescendants = [];
        this.unselectDescendants = [];
      }
    });
  },
  destroyed() {
    this.$root.$off(VUE_EVENT_NAME.SYNC_ICD9_UNSELECT_DESCENDANTS_EVENT);
    this.$root.$off(VUE_EVENT_NAME.RESET_ICD9_UNSELECT_DESCENDANTS_EVENT);
  },
  watch: {
    confirmIcdMap() {
      this.icdMapchecked = !this.confirmIcdMap;
    },
  },
  methods: {
    ...mapMutations(['setConfirmIcdMap']),
    async openIcdMap() {
      this.condition = cloneDeep(this.currentConditions.filter((item) => item.name === 'Diagnosis'));

      if (this.condition[0]) {
        await this.syncOptions();
      }

      this.selectAll = this.checkSelectAll();
      await this.syncIcd9Condition();
      this.updateIcdMapPatient();
      this.icdMapVisible = true;
    },
    setCheck(item) {
      item.check = item.descendants
        .concat([item.code])
        .every((itemValue) => this.selectDescendants.includes(itemValue));
    },
    async syncOptions() {
      const response = await this.$api.searchDiagnosisMerge({
        diagnosis_range: 'Any',
        query_mode: 'ICD9',
        icd_list: this.condition[0].list.map((item) => item.candidateIcd9List).flat(),
      });

      const formatItem = (item) => ({
        ...formatIcdGroupMapItem(item),
        level: 1,
        parent: '',
        check: true,
      });

      const ICDCodes = getIcdFormatList(response.results, formatItem);

      this.options = ICDCodes;
      this.selectDescendants = this.options.map((item) => item.descendants.concat([item.code])).flat();
      this.candidateDescendants = this.selectDescendants;
      this.selectDescendants = difference(this.selectDescendants, this.unselectDescendants);

      this.tmpSelectDescendants = this.selectDescendants;
      this.tmpUnselectDescendants = this.unselectDescendants;

      this.options.forEach((item) => this.setCheck(item));
    },
    handleCancel() {
      this.icdMapVisible = false;
      this.cohortStudyTrackClick('handleCancel');

      this.selectDescendants = this.tmpSelectDescendants;
      this.unselectDescendants = this.tmpUnselectDescendants;
    },
    getExpandMargin(level) {
      return (level - 1) * 12;
    },
    async openExpand(option) {
      const response = await this.$api.searchDiagnosisExpand({
        query: '',
        diagnosis_range: this.getDiagnosisRange,
        code: option.code,
        query_mode: 'ICD9',
      });

      const formatItem = (item) => ({
        ...formatIcdGroupMapItem(item),
        check: option.check,
        level: option.level + 1,
        parent: option.code,
      });

      const ICDCodes = getIcdFormatList(response.children, formatItem);
      // Add ICDCodes to options
      // Set child child
      // Set expand is true
      const index = this.options.findIndex((item) => item.code === option.code);
      if (index !== -1) {
        this.options.splice(index + 1, 0, ...ICDCodes);
        option.child = ICDCodes;
        option.expand = true;
      }

      this.options.forEach((item) => this.setCheck(item));

      this.cohortStudyTrackClick('openExpand', { option });
    },
    checkSelectAll() {
      const result = this.options.map((item) => item.check);
      return !result.includes(false);
    },
    async handleChange(option) {
      if (option.patientNum === 0) {
        return;
      }

      if (!option.check) {
        this.selectDescendants = [...new Set(this.selectDescendants.concat(option.descendants))];
        this.selectDescendants = [...new Set(this.selectDescendants.concat([option.code]))];

        const response = await this.$api.searchDiagnosisMerge({
          diagnosis_range: 'Any',
          query_mode: 'ICD9',
          icd_list: this.selectDescendants,
        });

        this.selectDescendants = [
          ...new Set(response.results.map((itemValue) => itemValue.descendants.concat([itemValue.code])).flat()),
        ];
        option.check = true;
        this.unselectDescendants = difference(this.candidateDescendants, this.selectDescendants);
      } else {
        this.selectDescendants = difference(this.selectDescendants, option.descendants);
        this.selectDescendants = difference(this.selectDescendants, [option.code]);
        this.selectDescendants = difference(this.selectDescendants, option.ancestors);
        option.check = false;
        this.unselectDescendants = [...new Set(this.unselectDescendants.concat(option.descendants))];
        this.unselectDescendants = [...new Set(this.unselectDescendants.concat([option.code]))];
      }

      this.options.forEach((item) => this.setCheck(item));

      this.selectAll = this.checkSelectAll();
      this.syncIcd9Condition();

      this.cohortStudyTrackClick('handleChange', { option });
    },
    handleSelectAll() {
      this.selectDescendants = this.candidateDescendants;
      this.unselectDescendants = [...new Set(difference(this.unselectDescendants, this.selectDescendants))];
      this.options.forEach((item) => {
        item.check = true;
      });
      this.selectAll = true;
      this.syncIcd9Condition();

      this.cohortStudyTrackClick('handleSelectAll');
    },
    handleUnselectAll() {
      this.unselectDescendants = [...new Set(difference(this.unselectDescendants.concat(this.selectDescendants)))];
      this.selectDescendants = [];
      this.options.forEach((item) => {
        item.check = false;
      });
      this.selectAll = false;
      this.syncIcd9Condition();

      this.cohortStudyTrackClick('handleUnselectAll');
    },
    async syncIcd9Condition() {
      const response = await this.$api.searchDiagnosisMerge({
        diagnosis_range: 'Any',
        query_mode: 'ICD9',
        icd_list: this.selectDescendants,
      });

      const formatItem = (item) => ({
        ...formatIcdGroupMapItem(item),
      });

      const ICDCodes = getIcdFormatList(response.results, formatItem);

      const includes = cloneDeep(this.currentConditions);
      let list = [];
      includes.forEach((item) => {
        if (item.name === 'Diagnosis') {
          // eslint-disable-next-line prefer-destructuring
          list = item.list;
        }
      });

      list.forEach((listItem) => {
        listItem.icd9List = [];
        ICDCodes.forEach((icd9Item) => {
          if (intersection(listItem.candidateIcd9List, icd9Item.descendants.concat([icd9Item.code])).length !== 0) {
            listItem.icd9List = listItem.icd9List.concat(icd9Item.icd9List);
          }
        });
      });

      this.icd9Condition = list;
      return Promise.resolve();
    },
    async updateIcdMapPatient() {
      this.loadingCount += 1;

      const searchObject = cloneDeep(defaultExperimental);
      const index = this.currentConditions.findIndex((item) => item.name === 'Diagnosis');
      searchObject.indexEvent.condition = cloneDeep(this.currentConditions);
      if (index > -1) {
        searchObject.indexEvent.condition[index].list = cloneDeep(this.icd9Condition);
      }
      const query = SearchQueryGenerator.generateNormalQuery(searchObject);
      const patientCount = await this.$api.getPatientCount([query]);

      this.formatedIcdMapPatient = this.formatedTemplateNumber(patientCount.patient_count);

      this.loadingCount -= 1;
      this.cohortStudyTrackClick('updateIcdMapPatient');
    },
    clickDone() {
      this.icdMapVisible = false;
      this.cohortStudyTrackClick('clickDone');

      this.autoSave();
    },
    autoSave() {
      this.icdMapVisible = false;

      const index = this.currentConditions.findIndex((item) => item.name === 'Diagnosis');

      if (index > -1) {
        const filterCondition = cloneDeep(this.currentConditions);
        filterCondition[index].list = cloneDeep(this.icd9Condition);

        const body = {
          condition: filterCondition,
        };

        this.updateEventWithPath(body, this.path);
      }
    },
    closeExpand(option) {
      this.options = this.options.filter((item) => !option.descendants.includes(item.code));
      option.expand = false;
      this.cohortStudyTrackClick('closeExpand', { option });
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .ant-modal {
  min-width: 880px;
  max-width: 880px;
  width: 880px !important;
}

/deep/ .ant-modal-body {
  padding: 20px 0px 10px 0px;
}

.title-block {
  padding: 0px 20px;
}

.icd-map-title-text {
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: #334e97;
}

.icd-map-title-text-2 {
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #334e97;
}

.icd-map-title-text-3 {
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #334e97;
}

/deep/ .ps {
  max-height: 378px;
}

.codesearch-menu {
  margin-top: 10px;
}

.codesearch-menu-content {
  box-shadow: inset 0px -1px 0px #d6d9e1;
  background: #ffffff;
}

.header-row {
  height: 38px;
  padding: 10px 20px 10px 20px;
  background-color: #eff0f3;
}

.icon-unselect {
  cursor: pointer;
}

.header-text-left-title {
  height: 15px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #334e97;
}

.header-text-left {
  height: 15px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #334e97;
}

.header-text-right {
  height: 15px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #334e97;
}

.row-option {
  box-shadow: inset 0px 1px 0px #d6d9e1;
  cursor: pointer;
}

.code-search-menu {
  padding: 0px 20px;
  height: 54px;
}

.disable-option {
  opacity: 0.3;
  cursor: not-allowed;
}

.code-text {
  height: 18px;
  margin: 1px 10px;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #334e97;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icd-description {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  height: 20px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
}

.icd-record-count {
  text-align: right;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #828282;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.icon-expand {
  width: 34px;
  height: 34px;
}

.tag {
  padding: 3px 10px;
  height: 24px;
  background: #334e97;
  border-radius: 53px;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  margin-top: 5px;
  margin-right: 5px;
}

.partial-dot {
  width: 6px;
  height: 6px;
  background: #4fc2e7;
  border-radius: 6px;
  margin-right: 5px;
}

.save-button {
  width: calc(100% - 40px);
  height: 44px;
  border-radius: 6px;
  background-color: #334e97;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  border-width: 0 0 0 0;
  margin: 20px;
}

.cancel-button {
  width: calc(100% - 40px);
  height: 44px;
  border-radius: 6px;
  background-color: #ffffff;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #334e97;
  border-width: 0 0 0 0;
  margin: 20px;
  border: solid 1px #334e97;
}

.setting-icd-groudandmap-text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  height: 24px;
  color: #ffffff;
}

.icd-check-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #334e97;
  margin-left: 10px;
}

.patient-count-button {
  padding: 8px 16px;
  background: #4d8edc;
  border-radius: 6px;
  cursor: pointer;
}

.count-patient-text {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #ffffff;
}

.rotate {
  animation: rotation 1s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.button-disable {
  opacity: 0.5;
  pointer-events: none;
}
</style>
