<template>
  <div>
    <div v-if="noResult" class="no-result">
      <div class="patient-list-title-text ml-20px mt-20px" style="position: absolute">Patient Profile Form</div>
      <div class="no-result-wrapper">
        <div class="mb-10px">
          <b-img src="@/assets/images/icon_no_results.svg" />
        </div>
        <div class="no-results-text">No results</div>
      </div>
    </div>
    <div v-else-if="loading" class="no-result">
      <div class="patient-list-title-text ml-20px mt-20px" style="position: absolute">Patient Profile Form</div>
      <div class="no-result-wrapper">
        <a-progress
          type="circle"
          :percent="progressPercent"
          :width="70"
          :strokeWidth="8"
          strokeColor="#4D8EDC"
          trailColor="#E0E0E0"
        >
          <template #format="percent">
            <span class="progress-text">{{ `${percent}%` }}</span>
          </template></a-progress
        >
        <div class="progress-text mt-10px" v-if="requestInProgress">Analyzing...</div>
        <div class="progress-text mt-10px" v-else>Queuing...</div>
      </div>
    </div>
    <div class="patient-list" v-else>
      <b-row no-gutters>
        <b-col>
          <div class="patient-list-title-text">Patient Profile Form</div>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col>
          <a-tabs id="patient-list-tab" type="card" @change="switchTab" :activeKey="tabName" style="margin-top: 20px">
            <a-tab-pane :key="PAGES.EXPERIMENTAL" :closable="false">
              <template slot="tab">
                <div class="custom-tab d-flex align-items-center">
                  <div class="border-fixer">{{ experimentalName }}</div>
                </div>
              </template>
              <div class="d-flex mt-20px">
                <div class="dot cohort-color"></div>
                <div class="ml-5px stage-name-text">{{ getPopulationName }}</div>
                <div class="dot intervention-color ml-10px"></div>
                <div class="ml-5px stage-name-text">{{ getInterventionName }}</div>
                <div class="dot outcome-color ml-10px"></div>
                <div class="ml-5px stage-name-text">{{ getOutcomeName }}</div>
              </div>
              <a-table
                :columns="patientListColumn"
                :data-source="patientListData"
                :scroll="{ x: 1500, y: 1000 }"
                class="mt-10px"
                :expandIcon="expandIcon"
              >
                <template slot="expandedRowRender" slot-scope="record" style="margin: 0">
                  <b-card>
                    <Timeline
                      :chart_no="getExpandData(record).chartNo"
                      :uniqueIndex="uniqueIndex"
                      :tt="uuid + getExpandData(record).chartNo"
                      :zoomable="false"
                      :start="getExpandData(record).start"
                      :end="getExpandData(record).end"
                      :intervention="getExpandData(record).intervention"
                      :cohort="getExpandData(record).cohort"
                      :outcome="getExpandData(record).outcome"
                      :isAdvance="true"
                    />
                  </b-card>
                </template>
              </a-table>
            </a-tab-pane>
            <a-tab-pane :key="PAGES.CONTROL" :closable="false">
              <template slot="tab">
                <div class="custom-tab d-flex align-items-center">
                  <div class="border-fixer">{{ controlName }}</div>
                </div>
              </template>
              <div class="d-flex mt-20px">
                <div class="dot cohort-color"></div>
                <div class="ml-5px stage-name-text">{{ getPopulationName }}</div>
                <div class="dot intervention-color ml-10px"></div>
                <div class="ml-5px stage-name-text">{{ getInterventionName }}</div>
                <div class="dot outcome-color ml-10px"></div>
                <div class="ml-5px stage-name-text">{{ getOutcomeName }}</div>
              </div>
              <a-table
                :columns="patientListControlColumn"
                :data-source="patientListControlData"
                :scroll="{ x: 1500, y: 1000 }"
                class="mt-10px"
                :expandIcon="expandIcon"
              >
                <template slot="expandedRowRender" slot-scope="record" style="margin: 0">
                  <b-card>
                    <Timeline
                      :chart_no="getExpandData(record).chartNo"
                      :uniqueIndex="uniqueIndex"
                      :tt="uuid + getExpandData(record).chartNo"
                      :zoomable="false"
                      :start="getExpandData(record).start"
                      :end="getExpandData(record).end"
                      :intervention="getExpandData(record).intervention"
                      :cohort="getExpandData(record).cohort"
                      :outcome="getExpandData(record).outcome"
                      :isAdvance="true"
                    />
                  </b-card>
                </template>
              </a-table>
            </a-tab-pane>
          </a-tabs>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from '@/store';
import column from '@/components/byEvent/result/column.js';
import { omit, cloneDeep } from 'lodash';
import { SearchQueryGenerator } from '@/utils/lumos/SearchQueryGenerator';
import { getPatientListQuery } from '@/utils/query/getEventPatientFromDocValue';
import { delay, calcProgress, transformNumber } from '@/utils/util';
import { SLA_DEFINITION } from '@/utils/cohortProgressConfig';
import { API, VUE_EVENT_NAME } from '@/utils/constants';
import Timeline from '@/views/components/Timeline.vue';
import { v4 as uuid } from 'uuid';
import dayjs from 'dayjs';
import { ClickMixin } from '@/mixins';

const POLLING_PERIOD = 3000;
export default {
  name: 'PatientList',
  components: {
    Timeline,
  },
  mixins: [ClickMixin],
  data() {
    return {
      PAGES: {
        EXPERIMENTAL: 'Experimental',
        CONTROL: 'Control',
      },
      tabName: 'Experimental',
      patientListColumn: cloneDeep(column.patient_list_column),
      patientListData: [],
      patientListControlColumn: cloneDeep(column.patient_list_column),
      patientListControlData: [],
      loading: false,
      noResult: false,
      progressPercent: 0,
      requestInProgress: false,
      queryData: {},
      expandedRowKeys: [],
      uuid: '',
    };
  },
  props: {
    uniqueIndex: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    this.uuid = uuid();
    this.updateData();

    this.fn = () => {
      this.getPatientListData(true);
    };
    this.$root.$on(VUE_EVENT_NAME.SAVE_ANALYSIS_RECORD, this.fn);
  },
  beforeDestroy() {
    this.$root.$off(VUE_EVENT_NAME.SAVE_ANALYSIS_RECORD, this.fn);
  },
  watch: {
    uniqueIndex() {
      this.updateData();
    },
  },
  computed: {
    ...mapState('cohortSession', ['tabs']),
    tab() {
      return this.tabs[this.uniqueIndex];
    },
    experimentalName() {
      return this.tab.eventSearch.experimental.name;
    },
    controlName() {
      return this.tab.eventSearch.control.name;
    },
    eventSearch() {
      return this.tab.eventSearch;
    },
    getPageData() {
      if (this.tabName === this.PAGES.CONTROL) {
        return this.eventSearch.control;
      }
      return this.eventSearch.experimental;
    },
    getPopulationName() {
      return this.getPageData.eventList[0].name;
    },
    getInterventionName() {
      return this.getPageData.indexEvent.name;
    },
    getOutcomeName() {
      return this.eventSearch.outcome.inclusionList[0].name;
    },
  },
  methods: {
    ...mapMutations('cohortSession', ['updateTab']),
    transformNumber,
    async pollingData() {
      let result = { result: 'processing' };
      while (result.result === 'processing' && result.result !== 'fail') {
        result = await this.getPatientListData();
        if (result.result === 'processing') {
          await delay(POLLING_PERIOD);
        }
      }

      if (result.result === 'fail') {
        this.noResult = true;
      } else {
        this.noResult = false;
      }

      this.rawData = result;
      return Promise.resolve();
    },
    async updateProgressPercent() {
      while (this.loading) {
        this.requestInProgress = this.$api.checkInProgress(this.queryData, API.PATIENT_LIST);
        if (this.requestInProgress) {
          this.progressPercent = calcProgress(
            JSON.parse(this.requestInProgress).startTime,
            SLA_DEFINITION.PATIENT_PROFILE_FORM
          );

          this.updateTab({
            index: this.uniqueIndex,
            patientListRequestInProgress: true,
            patientListProgressPercent: this.progressPercent,
          });
        }
        await delay(100);
      }
      this.updateTab({
        index: this.uniqueIndex,
        patientListRequestInProgress: false,
        patientListProgressPercent: 100,
      });
    },
    async updateData() {
      try {
        this.loading = true;

        this.progressPercent = 0;
        this.requestInProgress = false;
        this.updateProgressPercent();
        await this.pollingData();
        this.setColumn('patientListColumn', this.rawData.experimental);
        this.setPatientListData('patientListData', this.rawData.experimental);
        this.setColumn('patientListControlColumn', this.rawData.control);
        this.setPatientListData('patientListControlData', this.rawData.control);
      } catch (error) {
        console.error(error);
        this.noResult = true;
      }
      this.progressPercent = 0;
      this.requestInProgress = false;
      this.loading = false;
      this.updateTab({
        index: this.uniqueIndex,
        patientListRequestInProgress: false,
        patientListProgressPercent: 100,
      });
    },
    getRecoveryParams() {
      if (this.tab.tabType === 'history' || this.tab.tabType === 'save') {
        return {
          get_latest: this.tab.getLatest,
          url: this.tab.url || this.tab.id,
        };
      }
      return {
        get_latest: true,
        url: this.tab.url || this.tab.id,
      };
    },
    async getPatientListData(saveAnalysisRecord = false) {
      const experimentalSearchObject = this.eventSearch.experimental;
      const controlSearchObject = this.eventSearch.control;
      const { covariate, outcome } = this.eventSearch;

      const experimentalQueries = SearchQueryGenerator.generateCohortQuery(experimentalSearchObject);
      const controlQueries = SearchQueryGenerator.generateCohortQuery(controlSearchObject);

      const experimentalCharacteristicQuery = getPatientListQuery(
        SearchQueryGenerator.generateCharacteristicQuery(
          experimentalSearchObject,
          covariate.inclusionList,
          outcome.inclusionList
        ),
        covariate
      );
      const controlCharacteristicQuery = getPatientListQuery(
        SearchQueryGenerator.generateCharacteristicQuery(
          controlSearchObject,
          covariate.inclusionList,
          outcome.inclusionList
        ),
        covariate
      );

      const params = {
        experimental: {
          queries: experimentalQueries,
          CharacteristicQuery: experimentalCharacteristicQuery,
        },
        control: {
          queries: controlQueries,
          CharacteristicQuery: controlCharacteristicQuery,
        },
        searchState: this.eventSearch,
      };
      if (saveAnalysisRecord) {
        const query = {
          ...params,
          ...{
            get_latest: true,
            url: this.tab.id,
          },
        };
        this.queryData = query;
        return this.$api.getPatientListData(query);
      }
      const query = {
        ...params,
        ...this.getRecoveryParams(),
      };
      this.queryData = query;
      return this.$api.getPatientListData(query);
    },
    switchTab(name) {
      this.tabName = name;
    },
    setColumn(name, data) {
      this[name] = cloneDeep(column.patient_list_column);
      if (data[0]) {
        data[0].covariates.forEach((item) => {
          this[name].push({
            title: item.name,
            dataIndex: item.name,
            key: item.name,
            align: 'center',
            width: 150,
          });
        });
      }

      if (this.eventSearch.covariate.genderList.length === 0) {
        this[name] = this[name].filter((item) => item.key !== 'sex');
      }

      const bodyMass = [
        { key: 'height', type: 'height' },
        { key: 'weight', type: 'weight' },
        { key: 'bmi', type: 'BMI' },
      ];
      bodyMass.forEach((bodyMassItem) => {
        if (!this.eventSearch.covariate.bodyMassList.find((item) => item.type === bodyMassItem.type)) {
          this[name] = this[name].filter((item) => item.key !== bodyMassItem.key);
        }
      });
    },
    setPatientListData(name, data) {
      this[name] = [];
      if (data[0]) {
        data.forEach((item) => {
          const covariantsData = {};
          item.covariates.forEach((covariantItem) => {
            covariantsData[covariantItem.name] = covariantItem.data;
          });

          this[name].push({
            ...omit(item, 'covariates', 'height', 'weight', 'bmi'),
            ...covariantsData,
            key: item.patient_id,
            height: item.height ? this.transformNumber(item.height, 1) : '',
            weight: item.weight ? this.transformNumber(item.weight, 1) : '',
            bmi: item.bmi ? this.transformNumber(item.bmi, 1) : '',
          });
        });
      }
    },
    expandIcon(record) {
      if (record.expanded) {
        return (
          <div onClick={() => this.clickExpand(record)} class="expand-button">
            Hide timeline
          </div>
        );
      }
      return (
        <div onClick={() => this.clickExpand(record)} class="expand-button">
          Show timeline
        </div>
      );
    },
    clickExpand(record) {
      record.onExpand();
      this.cohortStudyTrackClick('clickExpand');
    },
    getExpandData(record) {
      const timeFormat = 'YYYYMMDD';
      const start = dayjs(record.population_diagnosis_date || record.index_date || record.outcome_date).format(
        timeFormat
      );
      const end = dayjs(record.outcome_date || record.index_date || record.population_diagnosis_date).format(
        timeFormat
      );
      const populationDate = record.population_diagnosis_date
        ? dayjs(record.population_diagnosis_date).format(timeFormat)
        : '';
      const interventionDate = record.index_date ? dayjs(record.index_date).format(timeFormat) : '';
      const outcomeDate = record.outcome_date ? dayjs(record.outcome_date).format(timeFormat) : '';
      return {
        chartNo: record.patient_id,
        start,
        end,
        cohort: { name: this.getPopulationName, date: populationDate },
        intervention: { name: this.getInterventionName, date: interventionDate },
        outcome: { name: this.getOutcomeName, date: outcomeDate },
      };
    },
  },
};
</script>
<style scoped>
#patient-list-tab >>> .ant-tabs-tab-active > .custom-tab {
  font-weight: bold;
  border-bottom: 5px solid #4d8edc !important;
  position: relative;
  top: -2px;
  color: #4d8edc;
}

#patient-list-tab >>> .ant-tabs-tab-active > .custom-tab > .border-fixer {
  position: relative;
  top: 2px;
}

#patient-list-tab >>> .ant-tabs-tab {
  margin-right: 10px;
  height: 40px;
  color: #4d8edc;
  background: #ffffff !important;
  border-color: #ffffff !important;
  padding-left: 0px;
}
</style>
<style lang="scss" scoped>
.patient-list {
  padding: 20px 20px;
  box-shadow: 0px 4px 16px #e0e0e0, 0px 16px 16px rgba(157, 205, 245, 0.1);
  border-radius: 8px;
}

.patient-list-title-text {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #212121;
}

/deep/ .ant-tabs-nav {
  margin-left: 0px !important;
}

/deep/ .ant-tabs-nav-wrap {
  position: relative !important;
  margin-top: 0px !important;
}

/deep/.ant-tabs-bar {
  border-bottom: 0;
}

/deep/ .ant-table-column-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #757575;
}

.loading-spin {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 10;
}

.loading-disable {
  opacity: 0.5;
}

.no-result {
  height: 300px;
  box-shadow: 0px 4px 16px #e0e0e0, 0px 16px 16px rgba(157, 205, 245, 0.1);
  border-radius: 8px;
}

.no-result-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.background {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}

.no-results-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #9e9e9e;
}

.progress-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.expand-button {
  color: #4d8edc;
  background-color: transparent;
  border: 1px solid #4d8edc;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 4px;
  width: fit-content;
  padding: 5px 5px;
  cursor: pointer;
}

/deep/ .ant-table-row-expand-icon-cell,
/deep/ .ant-table-expand-icon-th {
  width: 120px;
}

.dot {
  border-radius: 20px;
  height: 18px;
  width: 18px;
}

.cohort-color {
  background-color: #6cbec8;
}

.intervention-color {
  background-color: #e98484;
}

.outcome-color {
  background-color: #f1b065;
}

.stage-name-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #212121;
}
</style>
