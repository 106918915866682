<template>
  <div>
    <a-input-group compact class="inline-display" style="width: 530px">
      <a-select
        default-value="All"
        style="width: 180px; text-align: center"
        v-model="field"
        :getPopupContainer="(trigger) => trigger.parentNode"
      >
        <a-select-option value="All"> All </a-select-option>
        <a-select-option v-for="tab in configTabs" :value="tab.key" :key="tab.key">
          {{ tab.name }}
        </a-select-option>
      </a-select>
      <a-input
        style="width: 350px; text-align: left"
        placeholder="keyword search"
        v-model="text"
        @keyup.enter="addKeyword"
        :maxLength="70"
      >
        <a-icon slot="addonAfter" type="search" @click="addKeyword" />
      </a-input>
    </a-input-group>

    <img alt="aics" src="@/../public/img/navbar/info_blue.svg" class="help-icon inline-display help-icon-hover" />
    <div class="tooltip-content tooltip-overlay">
      <a-row>
        <a-col>
          <a-row>
            <a-col>
              <div class="search-text">Search tip:</div>
            </a-col>
          </a-row>
          <a-row class="margin-top-5">
            <a-col>
              <div class="inline-display operator">A+B</div>
              <div class="inline-display operator-text">A AND B</div>
            </a-col>
          </a-row>
          <a-row class="margin-top-5">
            <a-col>
              <div class="inline-display operator">A|B</div>
              <div class="inline-display operator-text">A OR B</div>
            </a-col>
          </a-row>
          <a-row class="margin-top-5">
            <a-col>
              <div class="inline-display operator">-A</div>
              <div class="inline-display operator-text">Exclude record with A</div>
            </a-col>
          </a-row>
          <a-row class="margin-top-5">
            <a-col>
              <div class="inline-display operator">"AAA BBB"</div>
              <div class="inline-display operator-text">Find words exact match "AAA BBB", including space</div>
            </a-col>
          </a-row>
          <a-row class="margin-top-5">
            <a-col>
              <div class="inline-display operator">AAA*</div>
              <div class="inline-display operator-text">Find words start with AAA...</div>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import { ClickMixin, UpdateMixin } from '@/mixins';

import { ConditionName, LogicOp } from '@/utils/conditions/core/Interface';
import { SearchType } from '@/utils/conditions/core/Tags';
import { mapState, mapMutations } from '../../../store';

const defaultField = ['SUB_CHART_NO'];

export default {
  name: 'SearchBar',
  mixins: [ClickMixin, UpdateMixin],
  data() {
    return {
      text: '',
      field: 'All',
    };
  },
  computed: {
    ...mapState('session', ['tabs']),
    tab() {
      return this.tabs[this.uniqueIndex];
    },
  },
  props: {
    configTabs: {
      type: Array,
    },
    uniqueIndex: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    field() {
      this.trackClick('changeField', { field: this.field });
    },
  },
  methods: {
    ...mapMutations('session', ['updateTab']),
    addKeyword() {
      if (!this.text.trim()) {
        return;
      }
      const fields = this.field === 'All' ? defaultField.concat(['TEXT_ALL']) : [this.field];
      const textCondition = {
        name: ConditionName.Keyword,
        tags: [SearchType.Basic, SearchType.Additional],
        andOr: LogicOp.And,
        list: [this.text.trim()],
        fields,
      };

      this.trackClick('addKeyword', {
        includes: this.tab.includes.concat([textCondition]),
        excludes: this.tab.excludes,
      });
      this.updateTab({
        index: this.uniqueIndex,
        includes: this.tab.includes.concat([textCondition]),
      });
      this.update({
        includes: this.tab.includes,
        excludes: this.tab.excludes,
        uniqueIndex: this.uniqueIndex,
      });
      this.text = '';
      this.$emit('patientResultSetTab', fields);
    },
  },
};
</script>
<style scoped lang="scss">
.inline-display {
  display: inline-block !important;
}

.help-icon {
  width: 24px;
  height: 24px;
  object-fit: contain;
  margin: 8px 0px 0px 12px;
}

.tooltip-content {
  display: none;
  position: absolute;
  margin-top: 42px;
  padding: 10px 15px 10px 15px;
}

.tooltip-overlay {
  border-radius: 1px;
  border: solid 1px #e0e0e0;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 1;
}

.help-icon-hover:hover ~ .tooltip-content {
  display: inline-block;
}

.search-text {
  width: 281px;
  height: 21px;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #333f6b;
}

.margin-top-5 {
  margin-top: 5px;
}

.operator {
  width: 100px;
  height: 24px;
  border-radius: 1px;
  background-color: #eaf2fd;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #333333;
  text-align: center;
}

.operator-text {
  height: 24px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #333333;
  margin-left: 10px;
}
</style>
