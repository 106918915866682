<template>
  <div class="advance-codesearch-filter">
    <b-form-input v-model="inputText" :placeholder="placeholder" class="advance-codesearch-input" ref="inputText">
    </b-form-input>
    <b-img src="@/assets/images/icon_search_blue.svg" class="icon-search" />
    <b-img src="@/assets/images/icon_clear.svg" class="icon-clear" v-if="inputText !== ''" @click.stop="clearInput" />
    <template v-if="options.length !== 0">
      <template v-if="feedbackField === 'medication'">
        <b-row no-gutters class="header-row">
          <b-col cols="2" class="d-flex align-items-center" style="height: 100%">
            <b-img
              src="@/assets/images/icon_unselect_blue.svg"
              class="icon-unselect"
              @click="handleSelectAll"
              v-if="!checkSelectAll() && getSelectAllStatus"
            />
            <b-img
              src="@/assets/images/icon_select_blue.svg"
              class="icon-unselect"
              @click="handleUnselectAll"
              v-if="checkSelectAll() && getSelectAllStatus"
            />
            <div class="header-text-left-title ml-10px">Medication</div>
          </b-col>
          <b-col class="d-flex align-items-center" style="height: 100%">
            <div class="header-text-left padding-12">Description</div>
          </b-col>
          <b-col cols="2" class="d-flex align-items-center" style="height: 100%">
            <div class="header-text-left padding-12">ATC & Generic name</div>
          </b-col>
          <b-col cols="2" class="d-flex align-items-center justify-content-end" style="height: 100%">
            <div class="header-text-right">Patients/Records</div>
          </b-col>
        </b-row>
      </template>
      <template v-if="feedbackField === 'procedure'">
        <b-row no-gutters class="header-row">
          <b-col cols="2" class="d-flex align-items-center" style="height: 100%">
            <b-img
              src="@/assets/images/icon_unselect_blue.svg"
              class="icon-unselect"
              @click="handleSelectAll"
              v-if="!checkSelectAll() && getSelectAllStatus"
            />
            <b-img
              src="@/assets/images/icon_select_blue.svg"
              class="icon-unselect"
              @click="handleUnselectAll"
              v-if="checkSelectAll() && getSelectAllStatus"
            />
            <div class="header-text-left-title ml-10px">Procedure</div>
          </b-col>
          <b-col class="d-flex align-items-center" style="height: 100%">
            <div class="header-text-left padding-12">Description</div>
          </b-col>
          <b-col cols="2" class="d-flex align-items-center justify-content-end" style="height: 100%">
            <div class="header-text-right">Patients/Records</div>
          </b-col>
        </b-row>
      </template>
      <template v-if="feedbackField === 'Medical Order'">
        <b-row no-gutters class="header-row">
          <b-col cols="2" class="d-flex align-items-center" style="height: 100%">
            <b-img
              src="@/assets/images/icon_unselect_blue.svg"
              class="icon-unselect"
              @click="handleSelectAll"
              v-if="!checkSelectAll() && getSelectAllStatus"
            />
            <b-img
              src="@/assets/images/icon_select_blue.svg"
              class="icon-unselect"
              @click="handleUnselectAll"
              v-if="checkSelectAll() && getSelectAllStatus"
            />
            <div class="header-text-left-title ml-10px">Order</div>
          </b-col>
          <b-col class="d-flex align-items-center" style="height: 100%">
            <div class="header-text-left padding-12">Description</div>
          </b-col>
          <b-col cols="1" class="d-flex align-items-center justify-content-end" style="height: 100%">
            <div class="header-text-right padding-12">NHI Order</div>
          </b-col>
          <b-col cols="1" class="d-flex align-items-center justify-content-end" style="height: 100%">
            <div class="header-text-right padding-12">Category</div>
          </b-col>
          <b-col
            cols="1"
            class="d-flex align-items-center justify-content-end"
            v-if="uiConfig.hisVersion"
            style="height: 100%"
          >
            <div class="header-text-right padding-12">HIS 2/5</div>
          </b-col>
          <b-col cols="2" class="d-flex align-items-center justify-content-end" style="height: 100%">
            <div class="header-text-right">Patients/Records</div>
          </b-col>
        </b-row>
      </template>
      <template v-if="feedbackField === 'Lab'">
        <b-row no-gutters class="header-row">
          <b-col cols="6" class="d-flex align-items-center" style="height: 100%">
            <div class="header-text-left-title">Lab name</div>
          </b-col>
          <b-col cols="2" class="d-flex align-items-center" style="height: 100%">
            <div class="header-text-left padding-12">Specimen</div>
          </b-col>
          <b-col cols="2" class="d-flex align-items-center" style="height: 100%">
            <div class="header-text-left padding-12">Unit</div>
          </b-col>
          <b-col cols="2" class="d-flex align-items-center justify-content-end" style="height: 100%">
            <div class="header-text-right">Patients/Records</div>
          </b-col>
        </b-row>
      </template>
    </template>
    <VuePerfectScrollbar v-if="optionsWithCount.length !== 0" @wheel.stop>
      <b-row
        no-gutters
        v-for="option in optionsWithCount"
        :title="option.description"
        :key="option.type === 'lab' ? option.code + option.exam + option.unit : option.code + option.type"
        class="row-option"
      >
        <b-col>
          <b-row
            no-gutters
            class="code-search-menu"
            :class="option.patientNum === 0 ? 'disable-option' : ''"
            @click.stop="handleChange(option)"
          >
            <b-col :cols="feedbackField !== 'Lab' ? 2 : 6" class="d-flex align-items-center padding-12">
              <b-img
                src="@/assets/images/icon_unselect_blue.svg"
                class="icon-unselect icon-unselect-margin"
                v-if="!checkSelect(option)"
              />
              <b-img src="@/assets/images/icon_select_blue.svg" class="icon-unselect icon-unselect-margin" v-else />
              <div class="code-text" v-if="feedbackField === 'diagnosis'">
                {{ `${option.labelDX}` }}
              </div>
              <div class="code-text" v-else>{{ `${option.label}` }}</div>
            </b-col>
            <b-col style="padding: 6px 0px" :cols="getDescCols" class="padding-12" v-if="feedbackField !== 'Lab'">
              <div class="icd-description">{{ option.description }}</div>
              <div class="icd-description">{{ option.chineseDescription }}</div>
            </b-col>
            <b-col
              v-if="feedbackField === 'Medical Order'"
              cols="1"
              class="icd-record-count d-flex align-items-center justify-content-end padding-12"
            >
              <div class="icd-record-count">
                {{ getNhiDefault(option.nhiOrder) }}
              </div>
            </b-col>
            <b-col
              v-if="feedbackField === 'Medical Order'"
              cols="1"
              class="icd-record-count d-flex align-items-center justify-content-end padding-12"
            >
              <div class="icd-record-count">{{ option.type }}</div>
            </b-col>
            <b-col
              v-if="feedbackField === 'Medical Order' && uiConfig.hisVersion"
              cols="1"
              class="icd-record-count d-flex align-items-center justify-content-end padding-12"
            >
              <div class="icd-record-count">{{ option.hisVersion }}</div>
            </b-col>
            <b-col
              class="icd-record-count d-flex align-items-center justify-content-end padding-12"
              v-if="feedbackField === 'diagnosis'"
              cols="1"
            >
              <div class="icd-record-count">{{ getTypeName(option.type) }}</div>
            </b-col>
            <b-col
              class="d-flex align-items-center description-text padding-12"
              v-if="feedbackField === 'medication'"
              cols="2"
              :title="option.ingredient"
            >
              <div class="description-text">
                <div class="atc-code-text">
                  {{ getNhiDefault(option.atcCode) }}
                </div>
                <div class="ingredient-text">
                  {{ getNhiDefault(option.ingredient) }}
                </div>
              </div>
            </b-col>
            <b-col
              class="d-flex align-items-center description-text padding-12"
              v-if="feedbackField === 'Lab'"
              cols="2"
              :title="option.exam"
            >
              <div class="description-text">{{ option.exam }}</div>
            </b-col>
            <b-col
              class="d-flex align-items-center description-text padding-12"
              v-if="feedbackField === 'Lab'"
              cols="2"
              :title="option.unit"
            >
              <div class="description-text">{{ option.unit }}</div>
            </b-col>
            <b-col
              class="icd-record-count d-flex align-items-center justify-content-end"
              v-if="option.patientNum !== undefined"
              cols="2"
            >
              <div class="icd-record-count">
                {{ formatedTemplateNumber(option.patientNum) }} /
                {{ formatedTemplateNumber(option.recordNum) }}
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </VuePerfectScrollbar>
  </div>
</template>
<script>
import { throttle, cloneDeep } from 'lodash';

import VuePerfectScrollbar from 'vue-perfect-scrollbar';

import { EventMixin, Utils, PatientNumberMixin, ClickMixin, EventSearchMixin } from '@/mixins';
import FilterMixin from '@/mixins/filterMixin';
import { EMR_TYPE_NAME } from '@/utils/string';
import { mapSubUiConfig } from '@/utils/uiConfig';
import { mapMutations } from '@/store';
import ConditionList from '@/utils/ConditionList';

export default {
  name: 'CodeSearchFilter',
  mixins: [EventMixin, Utils, FilterMixin, PatientNumberMixin, ClickMixin, EventSearchMixin],
  data() {
    return {
      andOr: 'should',
      inputText: '',
      value: [],
    };
  },
  props: {
    condition: Object,
    path: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    inputText() {
      this.inputDescription(this.inputText);
    },
    condition() {
      let result = cloneDeep(this.value);
      this.value.forEach((value) => {
        if (!this.condition.list.find((item) => item.code === value.code)) {
          result = result.filter((item) => item.code !== value.code);
        }
      });
      this.value = result;
    },
  },
  components: {
    VuePerfectScrollbar,
  },
  computed: {
    ...mapSubUiConfig('SearchForm'),
    optionsWithCount() {
      const noRecordResult = this.options.filter((option) => option.patientNum === 0);
      return this.options.filter((option) => option.patientNum !== 0).concat(noRecordResult);
    },
    fetchOptionsThrottled() {
      return throttle(
        (description) =>
          this.fetchOptions(description, this.setOptions, 'advance').catch(() => {
            this.setOptions([]);
          }),
        100
      );
    },
    getDescCols() {
      switch (this.feedbackField) {
        case 'diagnosis':
          return 7;
        case 'medication':
          return 6;
        case 'procedure':
          return 8;
        case 'Medical Order':
          return 5;
        default:
          return 7;
      }
    },
    getSelectAllStatus() {
      // If the items in the list are all 0 people, the selected checkbox will not be displayed
      return !this.options.every((item) => item.patientNum === 0);
    },
  },
  created() {
    if (this.condition.list) {
      this.value = cloneDeep(this.condition.list);
    }
  },
  mounted() {
    try {
      this.$refs.inputText.focus();
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    ...mapMutations('condition', ['setCondition', 'setExcludedCondition']),
    setOptions(options) {
      this.options = options;
    },
    inputDescription(text) {
      this.fetchOptionsThrottled(text);
    },
    addCondtionToValue(option) {
      const conditionTemplate = {
        ...option,
        time: Date.now(),
      };
      if (this.feedbackField === 'Lab') {
        this.value.push({
          ...conditionTemplate,
          start: '',
          startOperator: 'gte',
          end: '',
          endOperator: 'lte',
          andOr: 'filter',
          mutilRow: false,
        });
      } else {
        this.value.push(conditionTemplate);
      }
    },
    handleChange(option) {
      this.focusInput();
      if (option.patientNum === 0) {
        return;
      }

      const check = this.checkSelect(option);

      if (!check) {
        this.addCondtionToValue(option);
      } else {
        if (this.feedbackField === 'Lab') {
          this.value = this.value.filter(
            (item) => !(item.code === option.code && item.exam === option.exam && item.unit === option.unit)
          );
        } else {
          this.value = this.value.filter((item) => item.code !== option.code);
        }
      }

      this.trackClick('handleChange');

      this.syncToStore();
    },
    getTypeName(type) {
      return EMR_TYPE_NAME[type];
    },
    getNhiDefault(data) {
      if (data === '') {
        return '-';
      }
      return data;
    },
    checkSelect(value) {
      if (this.feedbackField === 'Lab') {
        return this.value
          .map((item) => item.code + item.exam + item.unit)
          .includes(value.code + value.exam + value.unit);
      } else {
        return this.value.map((item) => item.code + item.type).includes(value.code + value.type);
      }
    },
    clearInput() {
      this.inputText = '';
    },
    handleSelectAll() {
      this.focusInput();
      this.options.forEach((item) => {
        const check = this.checkSelect(item);
        if (item.patientNum !== 0 && !check) {
          this.addCondtionToValue(item);
        }
      });

      this.syncToStore();
    },
    handleUnselectAll() {
      this.focusInput();
      this.options.forEach((item) => {
        const check = this.checkSelect(item);
        if (item.patientNum !== 0 && check) {
          this.value = this.value.filter((itemValue) => itemValue.code !== item.code);
        }
      });

      this.syncToStore();
    },
    checkSelectAll() {
      if (this.value.length === 0) {
        return false;
      }

      const result = this.options.filter((item) => item.patientNum !== 0).map((item) => this.checkSelect(item));
      return result.every((item) => item);
    },
    focusInput() {
      this.$refs.inputText.focus();
    },
    syncToStore() {
      const result =
        this.condition.conceptType === 'include'
          ? cloneDeep(this.$store.state.condition.conditions)
          : cloneDeep(this.$store.state.condition.excludedConditions);
      const resultIndex = result.findIndex((item) => item.time === this.condition.time);

      if (result[resultIndex].list.length === 0 && this.feedbackField === 'medication') {
        result[resultIndex] = {
          ...result[resultIndex],
          ...ConditionList.medication,
        };
      } else if (result[resultIndex].list.length === 0 && this.feedbackField === 'Medical Order') {
        result[resultIndex] = {
          ...result[resultIndex],
          ...ConditionList.medicalOrder,
        };
      }

      result[resultIndex].list = cloneDeep(this.value);

      if (this.condition.conceptType === 'include') {
        this.setCondition(result);
      } else {
        this.setExcludedCondition(result);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.advance-codesearch-filter {
  border-radius: 6px;
}

.advance-codesearch-input {
  width: calc(100% - 20px);
  height: 36px;
  margin: 0px 10px 10px 10px;
  border-radius: 6px;
  border: 1px solid #4a94d2;
  padding-left: 34px;
  padding-right: 35px;
}

.form-control:focus {
  border: 1px solid #4a94d2;
  box-shadow: none;
}

.header-row {
  height: 38px;
  padding: 10px 20px 10px 20px;
  background-color: #eff0f3;
}

.header-text-left-title {
  height: 15px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #334e97;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.header-text-left {
  height: 15px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #334e97;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.header-text-right {
  height: 15px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #334e97;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.icd-description {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  height: 20px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
}

.icd-record-count {
  text-align: right;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #828282;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.icd-record-type {
  position: relative;
  float: right;
  color: rgba(51, 63, 107, 0.5);
  width: 44px;
  margin-left: 10px;
}

.code-search-menu {
  padding: 0px 20px;
  height: 54px;
}

.code-text {
  height: 18px;
  margin: 1px 10px;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #334e97;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.row-option {
  box-shadow: inset 0px 1px 0px #d6d9e1;
  cursor: pointer;
}

.disable-option {
  opacity: 0.3;
  cursor: not-allowed;
}

/deep/ .ps {
  max-height: 405px;
}

.icon-search {
  position: absolute;
  top: 29px;
  left: 20px;
}

.icon-clear {
  position: absolute;
  top: 29px;
  right: 20px;
  cursor: pointer;
}

.description-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.atc-code-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 13px;
  line-height: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #757575;
}

.ingredient-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 13px;
  line-height: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #212121;
  margin-top: 2px;
}

.padding-12 {
  padding-right: 12px !important;
}

.lab-input {
  width: 50px;
  height: 23px;
  padding: 0px 5px;
}

.lab-description-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #212121;
}

.lab-logic-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #757575;
}

/deep/ .ant-select-arrow {
  color: #0f4895;
}

.icon-unselect {
  cursor: pointer;
}
</style>
