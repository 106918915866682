<template>
  <div :id="'timeline' + tt">
    <a-icon
      v-if="timeline"
      @click="move(-0.5)"
      class="scroller"
      id="to-right-buttom"
      slot="suffixIcon"
      type="caret-right"
    />
    <a-icon
      v-if="timeline"
      @click="move(0.5)"
      class="scroller"
      id="to-left-buttom"
      slot="suffixIcon"
      type="caret-left"
    />
    <a-icon
      @click="
        timeline.zoomIn(0.5, { animation: false });
        trackClick('timeline.zoomIn');
      "
      id="zoom-in"
      class="zoom"
      type="zoom-in"
    />
    <a-icon
      @click="
        timeline.zoomOut(0.5, { animation: false });
        trackClick('timeline.zoomOut');
      "
      id="zoom-out"
      class="zoom"
      type="zoom-out"
    />
    <a-spin style="display: block; left: 50%" :spinning="loading" />
    <EMRDetail :emr="modalEmr" :chart-no="chart_no" :detailType="detailType" ref="emrDetail" />
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */
import vis from 'vis';
import { getExtendCondition } from '@/utils/query';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import { ClickMixin } from '@/mixins';
import EMRDetail from '@/components/modals/EMRDetail.vue';
import { mapSubUiConfig } from '@/utils/uiConfig';
import { FEEDBACK, PAGES } from '@/utils/constants';
import { mapState } from '../../store';
import moment from 'moment';

dayjs.extend(calendar);

const YYYYMMDD = 'YYYYMMDD';
const YYYY_MM_DD = 'YYYY-MM-DD';

export default {
  name: 'Timeline',
  mixins: [ClickMixin],
  components: {
    EMRDetail,
  },
  props: {
    chart_no: String,
    uniqueIndex: {
      type: Number,
      default: 0,
    },
    mode: {
      type: String,
      default: PAGES.INPATIENT_EMR,
    },
    tt: {
      type: String,
      default: '',
    },
    zoomable: {
      type: Boolean,
      default: true,
    },
    intervention: {
      type: Object,
      default() {
        return { date: '', name: '' };
      },
    },
    isAdvance: {
      type: Boolean,
      default: false,
    },
    start: {
      type: String,
      default: '',
    },
    end: {
      type: String,
      default: '',
    },
    cohort: {
      type: Object,
      default() {
        return { date: '', name: '' };
      },
    },
    outcome: {
      type: Object,
      default() {
        return { date: '', name: '' };
      },
    },
  },
  computed: {
    ...mapState('session', ['tabs']),
    ...mapSubUiConfig('PatientInformationModal.Timeline'),
    currentLogConditions() {
      return this.$store.getters['condition/currentConditions'];
    },
    tab() {
      if (this.tabs.length === 0) {
        return {
          includes: [],
          excludes: [],
        };
      }
      return this.tabs[this.uniqueIndex];
    },
  },
  data() {
    return {
      dataSet: null,
      timeline: null,
      visData: null,
      modalEmr: null,
      loading: true,
      detailType: 'inpatient',
    };
  },
  async mounted() {
    this.loading = true;

    const reqs = [];
    reqs.push(this.fetchAllData('inPatient'));
    reqs.push(this.fetchTargetData());
    if (this.uiConfig.outPatient) {
      reqs.push(this.fetchTargetData({ outPatient: true }));
      reqs.push(this.fetchAllData('outPatient'));
    }

    const results = await Promise.all(reqs).catch((error) => {
      console.error(error);
    });
    this.allEvent = results[0];
    this.targetEvent = results[1];
    this.outPatientTargetEvent = results[2];
    this.outPatientData = results[3];

    this.renderTimeline();
    this.loading = false;
  },
  methods: {
    async fetchAllData(type) {
      let allRecordQuery = this.getQuery({
        includes: [getExtendCondition('Chart no', this.chart_no)],
        excludes: [],
      });
      let seriesData;
      if (type === 'inPatient') {
        allRecordQuery = {
          ...allRecordQuery,
          size: 10000,
          _source: {
            includes: ['ID', 'ICD', 'IN_DATE', 'OUT_DATE', ...this.uiConfig.additionalQueryFields],
          },
          comp: 'IPD',
        };
        this.$api.setLogQueryInfo({
          type: FEEDBACK.QUERY_TYPE.TIMELINE_ALL_RECORD,
          conditions: this.currentLogConditions,
          additionalCondition: [getExtendCondition('Chart no', this.chart_no)],
        });
        seriesData = await this.$api.search(allRecordQuery).catch((error) => {
          console.error(error);
        });
      } else {
        allRecordQuery = {
          ...allRecordQuery,
          size: 10000,
          _source: {
            includes: ['ID', 'TIME_KEY', ...this.uiConfig.additionalOutPatientQueryFields],
          },
          comp: 'OPD',
        };
        this.$api.setLogQueryInfo({
          type: FEEDBACK.QUERY_TYPE.TIMELINE_ALL_RECORD,
          conditions: this.currentLogConditions,
          additionalCondition: [getExtendCondition('Chart no', this.chart_no)],
        });
        seriesData = await this.$api.searchOutpatient(allRecordQuery).catch((error) => {
          console.error(error);
        });
      }

      return seriesData.hits.hits;
    },
    async fetchTargetData({ outPatient = false } = {}) {
      let targetRecordQuery = this.getQuery({
        includes: this.tab.includes.concat([getExtendCondition('Chart no', [this.chart_no])]),
        excludes: this.tab.excludes,
      });
      targetRecordQuery = {
        ...targetRecordQuery,
        size: 10000,
        _source: { exclude: ['*'] },
        sort: [{ IN_DATE: 'asc' }],
        comp: outPatient ? 'OPD' : 'IPD',
      };
      this.$api.setLogQueryInfo({
        type: FEEDBACK.QUERY_TYPE.TIMELINE_TARGET_RECORD,
        conditions: this.currentLogConditions,
        additionalCondition: [getExtendCondition('Chart no', [this.chart_no])],
      });
      const seriesData = await this.$api.search(targetRecordQuery).catch((error) => {
        console.error(error);
      });
      // eslint-disable-next-line no-underscore-dangle
      return seriesData.hits.hits.map((record) => record._id);
    },
    clickTimeline(properties) {
      if (properties.what === 'item') {
        const currentEvent = this.visData.get(properties.item);
        this.modalEmr = currentEvent.data;
        if (this.modalEmr.TIME_KEY) {
          this.detailType = 'outpatient';
        } else {
          this.detailType = 'inpatient';
        }
        this.$refs.emrDetail.show();
        if (this.isAdvance) {
          this.cohortStudyTrackClick('cohortTimeline');
        } else {
          this.trackClick('timeline');
        }
      }
    },
    getIcdList(data) {
      if (data.ICD.length === 0 && data.ICD9) {
        return data.ICD9;
      }
      return data.ICD;
    },
    getFirstIcd(item) {
      return this.getIcdList(item)[0] ? this.getIcdList(item)[0] : '';
    },
    getIpdData(startEvent, datas) {
      const targetEventSet = new Set(this.targetEvent);

      const firstEventID = this.targetEvent[0];

      this.allEvent = this.allEvent || [];

      this.allEvent.forEach((e) => {
        // eslint-disable-next-line no-underscore-dangle
        const item = e._source;
        if (!item) {
          return;
        }
        // eslint-disable-next-line no-underscore-dangle
        const recordID = e._id;
        // eslint-disable-next-line no-underscore-dangle
        if (e._id === firstEventID && (this.mode === PAGES.INPATIENT_EMR || this.mode === PAGES.PATIENT_EMR)) {
          startEvent = item;
          startEvent.type = 'ipd';
        }
        const eventTemplate = {
          start: item.IN_DATE,
          end: item.OUT_DATE,
          content: item.SECTION_NAME
            ? `住院 ${item.SECTION_NAME} ${this.getFirstIcd(item)}`
            : `住院 ${this.getFirstIcd(item)}`,
          title: this.getIcdList(item).join('<br>'),
          className: targetEventSet.has(recordID) ? 'highlight' : 'normal',
          ICDNames: this.getIcdList(item).join('\n'),
          data: item,
          group: 1,
        };

        if (item.SECTION_NAME !== '' || this.getIcdList(item) !== '') {
          // https://app.asana.com/0/0/1190482219867841/f
          datas.push(eventTemplate);
        }
      });
      return [startEvent, datas];
    },
    getOpdEvent(item, outPatientTargetEventSet, recordID) {
      return {
        start: dayjs(item.TIME_KEY, YYYYMMDD).format(YYYY_MM_DD),
        content:
          item.CHCASENO === 'OPDR'
            ? `<div>
                  <div class="outpatient">門</div>
                  <div class="timeline-content">
                    ${item.SECTION_NAME} ${this.getFirstIcd(item)}
                  </div>
                </div>`
            : `<div>
                  <div class="outpatient-opde">急</div>
                  <div class="timeline-content">
                    ${item.SECTION_NAME} ${this.getFirstIcd(item)}
                  </div>
                </div>`,
        title: this.getIcdList(item).join('<br>'),
        className: outPatientTargetEventSet.has(recordID) ? 'outpatienthighlight normal' : 'normal',
        ICDNames: this.getIcdList(item).join('\n'),
        data: item,
        group: 0,
        align: 'left',
      };
    },
    getMarkerEvent(item, className) {
      return {
        start: dayjs(item.TIME_KEY, YYYYMMDD).format(YYYY_MM_DD),
        content: `<div>
                    <div class="${className}">
                      ${item.name}
                    </div>
                  </div>`,
        title: item.name,
        className,
        data: item,
        group: 1,
        align: 'left',
      };
    },
    getOpdData(startEvent, datas, groups) {
      if (this.uiConfig.outPatient) {
        // create a data set with groups
        const names = ['門診', '住院'];
        names.forEach((element, index) => {
          groups.add({ id: index, content: element });
        });
        const outPatientTargetEventSet = new Set(this.outPatientTargetEvent);
        const firstOPDEventID = this.outPatientTargetEvent[0];
        this.outPatientData.forEach((e) => {
          // eslint-disable-next-line no-underscore-dangle
          const item = e._source;
          if (!item) {
            return;
          }
          // eslint-disable-next-line no-underscore-dangle
          const recordID = e._id;
          // eslint-disable-next-line no-underscore-dangle
          if (e._id === firstOPDEventID && this.mode === PAGES.OUTPATIENT_EMR) {
            startEvent = item;
            startEvent.type = 'opd';
          }
          // eslint-disable-next-line no-underscore-dangle
          const eventTemplate = this.getOpdEvent(item, outPatientTargetEventSet, recordID);

          if (item.SECTION_NAME !== '' || this.getIcdList(item) !== '') {
            // https://app.asana.com/0/0/1190482219867841/f
            datas.push(eventTemplate);
          }
        });
      }
      return [startEvent, datas, groups];
    },
    getTimelineOptions(startEvent) {
      if (!startEvent) {
        return {
          height: '300px',
          maxHeight: '300px',
          minHeight: '300px',
          zoomMax: 31536000000,
          zoomMin: 86400000,
          align: 'left',
          zoomable: this.zoomable,
        };
      }
      return {
        height: '300px',
        maxHeight: '300px',
        minHeight: '300px',
        zoomMax: 31536000000,
        zoomMin: 86400000,
        start:
          startEvent.type === 'ipd'
            ? dayjs(startEvent.IN_DATE, YYYY_MM_DD).toDate()
            : dayjs(startEvent.TIME_KEY, YYYYMMDD).toDate(),
        end:
          startEvent.type === 'ipd'
            ? moment(startEvent.OUT_DATE, YYYY_MM_DD).add(30, 'days')
            : moment(startEvent.TIME_KEY, YYYYMMDD).add(30, 'days'),
        moment(date) {
          return vis.moment(date).utc();
        },
        align: 'left',
        zoomable: this.zoomable,
      };
    },
    getAdvanceTimelineOption() {
      const daysDiff = dayjs(this.end, YYYYMMDD).diff(dayjs(this.start, YYYYMMDD), 'day');
      return {
        height: '350',
        maxHeight: '350px',
        minHeight: '350px',
        // zoomMax: 31536000000,
        // zoomMin: 86400000,
        start: moment(this.start, YYYYMMDD).subtract(3, 'days'),
        end: moment(this.end, YYYYMMDD)
          .add(3, 'days')
          .add(Math.floor(daysDiff * 0.13), 'days'),
        moment(date) {
          return vis.moment(date).utc();
        },
        align: 'left',
        zoomable: this.zoomable,
      };
    },
    getAdvanceDatas() {
      const advanceData = [
        {
          start: this.intervention.date,
          // eslint-disable-next-line max-len
          end: this.intervention.date,
          moment(date) {
            return vis.moment(date).utc();
          },
          type: 'background',
          className: 'index-date',
          align: 'left',
        },
        this.getMarkerEvent(
          {
            TIME_KEY: this.intervention.date,
            name: this.intervention.name,
          },
          'intervention-marker'
        ),
      ];
      if (this.cohort.date !== '') {
        advanceData.push({
          start: this.cohort.date,
          end: this.cohort.date,
          moment(date) {
            return vis.moment(date).utc();
          },
          type: 'background',
          className: 'cohort-date',
          align: 'left',
        });
        advanceData.push(
          this.getMarkerEvent(
            {
              TIME_KEY: this.cohort.date,
              name: this.cohort.name,
            },
            'cohort-marker'
          )
        );
      }
      if (this.outcome.date !== '') {
        advanceData.push({
          start: this.outcome.date,
          end: this.outcome.date,
          moment(date) {
            return vis.moment(date).utc();
          },
          type: 'background',
          className: 'outcome-date',
        });
        advanceData.push(
          this.getMarkerEvent(
            {
              TIME_KEY: this.outcome.date,
              name: this.outcome.name,
            },
            'outcome-marker'
          )
        );
      }
      return advanceData;
    },
    renderTimeline() {
      let datas = [];
      let startEvent = null;

      [startEvent, datas] = this.getIpdData(startEvent, datas);

      // create a data set with groups
      let groups = new vis.DataSet();

      [startEvent, datas, groups] = this.getOpdData(startEvent, datas, groups);

      this.visData = new vis.DataSet();
      if (this.isAdvance) {
        const advanceDatas = this.getAdvanceDatas();
        this.visData.add(advanceDatas);
      }
      this.visData.add(datas);
      const container = document.getElementById(`timeline${this.tt}`);
      const options = this.isAdvance !== true ? this.getTimelineOptions(startEvent) : this.getAdvanceTimelineOption();
      this.timeline = new vis.Timeline(container, this.visData, options);
      if (this.uiConfig.outPatient) {
        this.timeline.setGroups(groups);
      }

      this.timeline.on('click', (properties) => this.clickTimeline(properties));
    },
    move(percentage) {
      const range = this.timeline.getWindow();
      const interval = range.end - range.start;
      this.timeline.setWindow({
        start: range.start.valueOf() - interval * percentage,
        end: range.end.valueOf() - interval * percentage,
      });
      this.trackClick('move', {
        start: range.start.valueOf() - interval * percentage,
        end: range.end.valueOf() - interval * percentage,
      });
    },
  },
};
</script>
<style scoped>
@import '~vue2vis/dist/vue2vis.css';

#timeline >>> .vis-item.highlight {
  background-color: #f5ba42;
  color: #333f6b;
}

#timeline >>> .vis-item.outpatienthighlight {
  background-color: #f5ba42;
  color: #333f6b;
}

#timeline >>> .vis-item-content {
  line-height: 1.8;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 0px;
}

#timeline >>> .vis-item {
  cursor: pointer;
}

#timeline >>> .vis-range {
  min-width: 50px;
  height: 40px;
  border-radius: 3px;
  font-size: 14px;
  background-color: #758cc8;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  padding: 8px;
}

#report-modal {
  width: 1011px;
}

.scroller {
  position: relative;
  top: 130px;
  font-size: 30px;
}

.scroller:hover {
  color: #758cc8;
  font-weight: bold;
  cursor: pointer;
}

#to-right-buttom {
  float: right;
}

#to-left-buttom {
  float: left;
}

.zoom {
  position: absolute;
  float: right;
  font-size: 20px;
  top: 80px;
}

.zoom:hover {
  font-size: 23px;
  cursor: pointer;
  color: #758cc8;
}

#zoom-out {
  left: 90px;
  z-index: 100;
}
#zoom-in {
  left: 60px;
  z-index: 100;
}
</style>
<style lang="scss" scoped>
/deep/ .outpatient {
  display: inline-block;
  background-color: #758cc8;
  color: #ffffff;
  width: 24px;
  height: 40px;
  padding: 10px 5px 10px 5px;
  border-radius: 3px 0px 0px 3px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

/deep/.outpatient-opde {
  display: inline-block;
  background-color: #da6d6d;
  color: #ffffff;
  width: 24px;
  height: 38px;
  padding: 10px 5px 10px 5px;
  border-radius: 3px 0px 0px 3px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

/deep/ .timeline-content {
  display: inline-block;
  background-color: transparent;
  color: #333333;
  padding: 10px 10px 10px 10px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

/deep/ .normal {
  border: 0px;
  .vis-item-content {
    background-color: transparent;
    border-radius: 3px 3px 3px 3px;
  }
}

/deep/ .vis-panel.vis-left {
  display: none;
}

/deep/ .vis-item.vis-selected {
  background-color: rgb(224, 224, 224);
}

/deep/ .vis-item {
  background-color: rgb(224, 224, 224);
  padding: 0px;
}

/deep/ .index-date {
  background-color: #e98484;
}

/deep/ .cohort-date {
  background-color: #6cbec8;
}

/deep/ .outcome-date {
  background-color: #f1b065;
}

/deep/ .cohort-marker {
  background-color: #6cbec8;
  border-radius: inherit;
  border: none;
  color: white;
  pointer-events: none;
}

/deep/ .intervention-marker {
  background-color: #e98484;
  border-radius: inherit;
  border: none;
  color: white;
  pointer-events: none;
}

/deep/ .outcome-marker {
  background-color: #f1b065;
  border-radius: inherit;
  border: none;
  color: white;
  pointer-events: none;
}
</style>
