<template>
  <a-modal
    :visible="demoVideoVisible"
    centered
    :mask="true"
    :destroyOnClose="true"
    :footer="null"
    @cancel="handleCancel"
    :zIndex="9999"
  >
    <div>
      <a-row>
        <a-col class="title-text">Demo Video</a-col>
      </a-row>
      <a-row style="margin-top: 20px">
        <a-col class="d-flex">
          <div class="d-flex flex-column flex-grow-1">
            <div v-for="(chapter, index) in getVideoConfig" :key="index">
              <div
                class="chapter-text"
                @click="clickChapter(index)"
                :class="{
                  'HL-chapter': chapter.START <= currentTime && currentTime < chapter.END,
                }"
              >
                {{ `${chapter.TIME} ${chapter.TITLE}` }}
              </div>
            </div>
          </div>
          <div>
            <video autoplay controls style="margin-left: 10px" :id="demoVideo">
              <source :src="getVideoLink" />
              <track src="@/assets/vtt/video.vtt" kind="subtitles" srclang="zh" label="zh" />
            </video>
          </div>
        </a-col>
      </a-row>
    </div>
  </a-modal>
</template>
<script>
import { DEMO_VIDEO_LINK } from '@/utils/constants';
import { ClickMixin } from '@/mixins';
import { DEMO_VIDEO_CONFIG, DEMO_VIDEO_CONFIG2 } from '@/utils/demoVideoConfig';
import { mapState, mapMutations } from '../../store';
import { mapSubUiConfig } from '@/utils/uiConfig';

const DEMO_VIDEO = 'demo-video';

export default {
  name: 'DemoVideo',
  mixins: [ClickMixin],
  data() {
    return {
      currentTime: 0,
      expireFlag: null,
      autoPlayFlag: false,
      demoVideo: DEMO_VIDEO,
    };
  },
  computed: {
    ...mapState(['demoVideoIndex', 'demoVideoVisible']),
    ...mapSubUiConfig('SearchForm'),
    getVideoLink() {
      return this.getVideoPath(this.demoVideoIndex === 1);
    },
    getVideoConfig() {
      return this.demoVideoIndex === 1 ? DEMO_VIDEO_CONFIG : DEMO_VIDEO_CONFIG2;
    },
  },
  watch: {
    demoVideoVisible() {
      if (this.demoVideoVisible) {
        this.$nextTick(() => {
          const video = document.getElementById(DEMO_VIDEO);
          if (video) {
            video.addEventListener('timeupdate', this.updateCurrentTime);

            this.expireFlag = null;
            this.autoPlayFlag = false;

            video.addEventListener('canplay', this.canUpdate);

            video.addEventListener('play', this.play);

            video.addEventListener('pause', this.pause);

            video.addEventListener('seeked', this.seeked);
          }
        });
      } else {
        const video = document.getElementById(DEMO_VIDEO);
        if (video) {
          video.removeEventListener('timeupdate', this.updateCurrentTime);
        }
      }
    },
  },
  methods: {
    ...mapMutations(['setDemoVideoVisible']),
    handleCancel() {
      this.setDemoVideoVisible(false);
      this.trackClick('handleCancel', { video: this.getVideoLink });
    },
    clickChapter(index) {
      const video = document.getElementById(DEMO_VIDEO);
      if (video) {
        this.currentTime = this.getVideoConfig[index].START;
        video.currentTime = this.getVideoConfig[index].START;
      }
      this.trackClick('clickChapter', { chapter: this.getVideoConfig[index].TITLE });
    },
    updateCurrentTime() {
      const video = document.getElementById(DEMO_VIDEO);
      if (video) {
        this.currentTime = video.currentTime;
      }
    },
    canUpdate() {
      clearTimeout(this.expireFlag);
      this.autoPlayFlag = this.autoplay;
      setTimeout(() => {
        this.autoPlayFlag = false;
      }, 100);
    },
    play() {
      if (!this.autoPlayFlag) {
        this.trackClick('clickPlayVideo');
      }
    },
    pause() {
      this.trackClick('clickPauseVideo');
    },
    seeked() {
      const video = document.getElementById(DEMO_VIDEO);
      this.trackClick('clickSeekedVideo', { timestamp: video.currentTime });
    },
    getVideoPath(cohortStudy) {
      const videoLink = cohortStudy ? DEMO_VIDEO_LINK.VIDEO_1 : DEMO_VIDEO_LINK.VIDEO_2;
      return `${process.env.VUE_APP_BASE_URL}${videoLink}`;
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .ant-modal {
  min-width: 1024px;
  max-width: 1980px;
  width: 70% !important;
  padding: 30px;
}

.title-text {
  height: 30px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: #333f6b;
}

.chapter-text {
  height: 42px;
  width: 230px;
  padding: 10px 20px;
  color: #212121;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
}

video {
  width: 100% !important;
  height: auto !important;
  padding: 0px 10px 0px 0px;
  min-width: 690px !important;
  min-height: 380px !important;
}

.HL-chapter {
  background: #e6f3fc;
  border-radius: 6px;
}
</style>
