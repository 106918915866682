<template>
  <div>
    <div>{{ $api.isQueueEmpty }}</div>
    <a-spin id="loading-spin" v-if="false" />
    <div v-if="tab.counters.ALL.Patient > 0">
      <div class="block-title" style="margin-bottom: 10px" v-if="demographicCharts.length > 0">Demographic</div>
      <GridFlow :cells="demographicCharts" :uniqueIndex="uniqueIndex" :columns="2">
        <template #cell="{ config: config }">
          <Chart
            :config="config"
            :expanded="false"
            @clickViewAll="showViewAllModal"
            @lastChart="getLastChart"
            :uniqueIndex="uniqueIndex"
          />
        </template>
      </GridFlow>
      <div class="d-flex align-items-center" style="margin: 40px 0px 8px 0px">
        <div class="block-title">Summary</div>
        <ByPatientOrRecordGroup :uniqueIndex="uniqueIndex" style="margin-left: 20px" />
      </div>
      <TfidfSwitch :uniqueIndex="uniqueIndex" />
      <div class="sub-block-title" style="margin: 16px 0px 8px 0px" v-if="charts.length > 0">Clinical Chart</div>
      <GridFlow :cells="charts" :uniqueIndex="uniqueIndex">
        <template #cell="{ config: config }">
          <Chart
            :config="config"
            :expanded="false"
            @clickViewAll="showViewAllModal"
            @lastChart="getLastChart"
            :uniqueIndex="uniqueIndex"
          />
        </template>
      </GridFlow>
      <div class="sub-block-title" v-if="InpatientCharts.length > 0">Inpatient Summary</div>
      <div class="patient-number-desc" v-if="InpatientCharts.length > 0" style="margin-bottom: 8px">
        <span class="patient-number">
          {{ this.getInPatientCount }}
        </span>
        inpatients ∙
        <span class="patient-number">
          {{ this.getInRecordCount }}
        </span>
        records
        <span v-if="false">
          <span style="margin-left: 20px"> Readmission risk with 14 days:</span>
          <span class="risk-number" :style="{ color: risk >= 60 ? '#da6d6d' : '#333f6b' }">
            {{ this.getRisk }}
          </span>
        </span>
      </div>
      <GridFlow :cells="InpatientCharts" :uniqueIndex="uniqueIndex">
        <template #cell="{ config: config }">
          <Chart
            :config="config"
            :expanded="false"
            @clickViewAll="showViewAllModal"
            @lastChart="getLastChart"
            :uniqueIndex="uniqueIndex"
            :parentLoading="loading"
          />
        </template>
      </GridFlow>
      <ViewAllModal
        ref="viewAllModal"
        :title="viewAllConfig ? viewAllConfig.title : ''"
        :subtitle="viewAllConfig ? viewAllConfig.subtext : ''"
      >
        <template #content>
          <Chart :config="viewAllConfig" :expanded="true" :uniqueIndex="uniqueIndex" />
        </template>
      </ViewAllModal>
    </div>
    <div v-if="tab.counters.ALL.Patient === 0 && !loading">
      <a-row type="flex" justify="center" align="top" style="margin-top: 15px">
        <a-col>
          <SearchClearExtendFilterBar :uniqueIndex="uniqueIndex" />
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import ViewAllModal from '@/components/modals/ViewAllModal.vue';
import GridFlow from '@/components/layout/GridFlow.vue';
import { ClickMixin, TrackTimeMixin, UpdateMixin, PatientNumberMixin } from '@/mixins';
import { mapSubUiConfig, getUiConfig } from '@/utils/uiConfig';
import SearchClearExtendFilterBar from '@/components/analysis/status/clear/ClearExtendFilter.vue';
import ByPatientOrRecordGroup from '@/components/analysis/status/radio/ByPatientOrRecordGroup.vue';
import TfidfSwitch from '@/components/analysis/status/tfidf/TfidfSwitch.vue';
import { mapState, mapGetters } from '../../store';
import Chart from '../charts/core/Chart.vue';
import { ChartKey } from '../charts/core/Interface';
import AgeConfig from '../charts/AgeConfig';
import DiagnosisConfig from '../charts/DiagnosisConfig';
import AbnormalLabConfig from '../charts/AbnormalLabConfig';
import GenderConfig from '../charts/GenderConfig';
import MedicationConfig from '../charts/MedicationConfig';
import ATCCodeConfig from '../charts/ATCCodeConfig';
import ProcedureConfig from '../charts/ProcedureConfig';
import PCSCodeConfig from '../charts/PCSCodeConfig';
import RiskConfig from '../charts/RiskConfig';
import SectionConfig from '../charts/SectionConfig';
import StayConfig from '../charts/StayConfig';
import SymptomConfig from '../charts/SymptomConfig';
import VisitConfig from '../charts/VisitConfig';
import MedicalOrderConfig from '../charts/MedicalOrderConfig';
import DoctorIdConfig from '../charts/DoctorIdConfig';
import TrendConfig from '../charts/TrendConfig';
import DRGCodeConfig from '../charts/DRGCodeConfig';
import DischargeStatusConfig from '../charts/DischargeStatusConfig';
import PatientSourceConfig from '../charts/PatientSourceConfig';
import BMIConfig from '../charts/BMIConfig';
import ReadmissionConfig from '../charts/ReadmissionConfig';
import ReOPDEConfig from '../charts/ReOPDEConfig';
import HabitConfig from '../charts/HabitConfig';

export default {
  name: 'StatisticSummary',
  mixins: [TrackTimeMixin, ClickMixin, UpdateMixin, PatientNumberMixin],
  components: {
    Chart,
    ViewAllModal,
    GridFlow,
    SearchClearExtendFilterBar,
    ByPatientOrRecordGroup,
    TfidfSwitch,
  },
  props: {
    uniqueIndex: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapState('condition', ['patient']),
    ...mapState(['queueEmpty']),
    ...mapSubUiConfig('Analyze.StatisticSummary'),
    ...mapGetters('condition', [
      'getIndexTabConditions',
      'getIndexTabExtendConditions',
      'getIndexTabExcludedConditions',
    ]),
    ...mapState('session', ['tabs']),
    charts() {
      if (getUiConfig().SearchForm.findByDoctor) {
        return this.uiConfig.all_charts.map((chartType) => this.CHART_CONFIG[chartType]);
      }
      return this.uiConfig.all_charts
        .filter((chart) => chart !== ChartKey.DoctorId)
        .map((chartType) => this.CHART_CONFIG[chartType]);
    },
    InpatientCharts() {
      return this.uiConfig.charts.map((chartType) => this.CHART_CONFIG[chartType]);
    },
    demographicCharts() {
      return this.uiConfig.demographic_charts
        .filter((chart) => chart !== ChartKey.DoctorId)
        .map((chartType) => this.CHART_CONFIG[chartType]);
    },
    loading() {
      if (this.patient === 0 || this.nLoaded === 0 || this.nLoaded === this.charts.length || this.queueEmpty) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.nLoaded = 0;
        return false;
      }
      return true;
    },
    tab() {
      return this.tabs[this.uniqueIndex];
    },
    getInPatientCount() {
      return this.formatedTemplateNumber(this.tab.counters.IPD.Patient);
    },
    getInRecordCount() {
      return this.formatedTemplateNumber(this.tab.counters.IPD.Record);
    },
    getRisk() {
      return this.formatedTemplateRisk(this.tab.data.riskLevel);
    },
  },
  data() {
    return {
      viewAllConfig: null,
      nLoaded: 0,
      isQueueActive: true,
      CHART_CONFIG: {
        [ChartKey.AbnormalLab]: new AbnormalLabConfig(),
        [ChartKey.Age]: new AgeConfig(),
        [ChartKey.ATCCode]: new ATCCodeConfig(),
        [ChartKey.BMI]: new BMIConfig(),
        [ChartKey.DischargeStatus]: new DischargeStatusConfig(),
        [ChartKey.DoctorId]: new DoctorIdConfig(),
        [ChartKey.DRGCode]: new DRGCodeConfig(),
        [ChartKey.Gender]: new GenderConfig(),
        [ChartKey.ICD10]: new DiagnosisConfig(ChartKey.ICD10),
        [ChartKey.ICD9]: new DiagnosisConfig(ChartKey.ICD9),
        [ChartKey.MedicalOrder]: new MedicalOrderConfig(),
        [ChartKey.Medication]: new MedicationConfig(),
        [ChartKey.PatientSource]: new PatientSourceConfig(),
        [ChartKey.PCSCode]: new PCSCodeConfig(),
        [ChartKey.Procedure]: new ProcedureConfig(),
        [ChartKey.Readmission]: new ReadmissionConfig(ChartKey.Readmission),
        [ChartKey.ReOPDE]: new ReOPDEConfig(),
        [ChartKey.Risk]: new RiskConfig(),
        [ChartKey.Section]: new SectionConfig(),
        [ChartKey.Stay]: new StayConfig(),
        [ChartKey.Symptom]: new SymptomConfig(),
        [ChartKey.Trend]: new TrendConfig(),
        [ChartKey.Visit]: new VisitConfig(),
        [ChartKey.Habit]: new HabitConfig(),
      },
    };
  },
  methods: {
    showViewAllModal(title, expandConfig) {
      this.viewAllConfig = expandConfig;
      this.$refs.viewAllModal.show();
      this.trackClick('showViewAllModal', { title });
    },
    getLastChart() {
      this.nLoaded += 1;
    },
  },
};
</script>

<style lang="scss">
.scroll-area {
  position: relative;
  margin: auto;
  width: 570px;
  height: 100%;
}

.patient-number-desc {
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  clear: both;
  display: block;
  width: fit-content;

  .patient-number {
    font-weight: bold;
    color: #333f6b;
  }

  .risk-number {
    font-weight: bold;
  }
}
</style>

<style scoped>
.block {
  position: relative;
  background: transparent;
  opacity: 0.5;
}

.block:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}

#loading-spin {
  top: 50%;
  position: fixed;
  left: 50%;
}

.block-title {
  height: 30px;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #333f6b;
}

.sub-block-title {
  height: 23px;
  font-size: 18px;
  line-height: 23px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #333f6b;
  margin: 16px 0px 8px 0px;
}
</style>
