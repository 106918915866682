<template>
  <div class="diagnosis-range" @click.stop="AddDiagnosisRange">+ Primary Diagnosis</div>
</template>
<script>
import { mapMutations } from '@/store';
import { cloneDeep } from 'lodash';

export default {
  name: 'DiagnosisRange',
  props: {
    condition: Object,
  },
  methods: {
    ...mapMutations('condition', ['setCondition', 'setExcludedCondition']),
    AddDiagnosisRange() {
      const result =
        this.condition.conceptType === 'include'
          ? cloneDeep(this.$store.state.condition.conditions)
          : cloneDeep(this.$store.state.condition.excludedConditions);
      const resultIndex = result.findIndex((item) => item.time === this.condition.time);
      result[resultIndex].diagnosisRange = true;

      if (this.condition.conceptType === 'include') {
        this.setCondition(result);
      } else {
        this.setExcludedCondition(result);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.diagnosis-range {
  padding: 4px 12px;
  border: 1px solid #0074e6;
  box-sizing: border-box;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #0074e6;
  cursor: pointer;
}
</style>
