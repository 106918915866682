<template>
  <div class="history-list">
    <div v-if="historyTabs && historyTabs.length === 0">
      <b-row no-gutters>
        <b-col>
          <div class="d-flex justify-content-center">
            <b-img src="@/assets/images/no_results.svg" class="icon-no-result" />
          </div>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col class="no-result-title-text">No history</b-col>
      </b-row>
      <b-row no-gutters>
        <b-col>
          <div class="d-flex justify-content-center">
            <div class="no-result-content-text">
              There’s no history search. Every closed search tab will be a history record.
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col>
          <div class="d-flex justify-content-center">
            <b-button variant="outline-primary" class="button-new-search" @click="clickNewSearch">NEW SEARCH</b-button>
          </div>
        </b-col>
      </b-row>
    </div>
    <div v-if="historyTabs && historyTabs.length !== 0">
      <div>
        <div class="d-inline-block save-title">History</div>
        <div class="d-inline-block recent-title">Recent 10 search results</div>
      </div>
      <b-row no-gutters class="title-row">
        <b-col class="title-name">Name</b-col>
        <b-col class="title-date">Modified Date</b-col>
      </b-row>
      <b-row no-gutters>
        <b-col>
          <b-row no-gutters v-for="(item, index) in historyList" :key="index" class="data-row" @click="restore(item)">
            <b-col class="d-flex justify-content-center" style="padding-right: 30px">
              <div class="left-div">
                <b-row no-gutters>
                  <b-col
                    ><div class="name-text">{{ item.name }}</div></b-col
                  >
                </b-row>
                <b-row no-gutters>
                  <b-col>
                    <div class="condition-text">
                      <div v-if="getConditions(item.includes).length > 0" class="condition-type-text">Criteria:</div>
                      <SearchCondition
                        v-for="(condition, index) in getConditions(item.includes)"
                        :key="`condition-${index}`"
                        :condition="condition"
                        style="margin: 0px 10px 0px 0px"
                      />
                      <div v-if="item.excludes.length > 0" class="condition-type-text">Excluded:</div>
                      <SearchCondition
                        v-for="(condition, index) in item.excludes"
                        :key="`excludedConditions-${index}`"
                        :condition="condition"
                        style="margin: 0px 10px 0px 0px"
                      />
                      <div v-if="getExtendConditions(item.includes).length > 0" class="condition-type-text">
                        Filter:
                      </div>
                      <SearchCondition
                        v-for="(condition, index) in groupedConditions(getExtendConditions(item.includes))"
                        :key="`extendConditions-${index}`"
                        :condition="condition"
                        style="margin: 0px 10px 0px 0px"
                      />
                    </div>
                  </b-col>
                </b-row>
              </div>
              <div class="right-div d-flex align-items-center justify-content-end">
                <div class="time-text">{{ formatTime(item.updateTime) }}</div>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import { groupBy } from 'lodash';
import SearchCondition from '@/components/analysis/status/SearchCondition.vue';
import dayjs from 'dayjs';
import { UpdateMixin, ClickMixin } from '@/mixins';
import { v4 as uuid } from 'uuid';
import { UICONFIG, VUE_EVENT_NAME, PAGES } from '@/utils/constants';
import { mapMutations, mapState } from '../../store';
import ConditionArray from '../../utils/query/core/ConditionArray';

export default {
  name: 'HistoryList',
  mixins: [UpdateMixin, ClickMixin],
  components: {
    SearchCondition,
  },
  computed: {
    ...mapState('session', ['tabs', 'historyTabs']),
    ...mapState('user', ['userId']),
  },
  data() {
    return {
      historyList: [],
    };
  },
  mounted() {
    this.initHistoryData();
  },
  watch: {
    historyTabs: {
      handler() {
        this.fetchData();
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations('session', ['setActiveTabIndex', 'addTab', 'addHistoryTab', 'setHistoryTabs']),
    ...mapMutations(['setEMRSearchTabName']),
    clickNewSearch() {
      this.setActiveTabIndex(-1);
      this.setEMRSearchTabName(PAGES.SEARCH);
      this.trackClick('clickNewSearch');
    },
    getConditions(includes) {
      return new ConditionArray(includes).raws;
    },
    getExtendConditions(includes) {
      return new ConditionArray(includes).additions;
    },
    async initHistoryData() {
      const historyData = await this.$api.getUserQuery(this.userId, { purpose: 'history' }).catch((error) => {
        console.error(error);
      });
      const resultData = [];
      if (historyData && historyData.content && historyData.content.hits && historyData.content.hits.hits) {
        historyData.content.hits.hits.forEach((element) => {
          // eslint-disable-next-line no-underscore-dangle
          const { query } = element._source;
          resultData.push({
            ...query,
            id: '',
          });
        });

        resultData.reverse();
        this.setHistoryTabs(resultData);
        this.fetchData();
      }
    },
    fetchData() {
      const resultData = [];
      this.$store.state.session.historyTabs
        .slice()
        .reverse()
        .slice(0, 10)
        .forEach((element) => {
          if (element) {
            resultData.push(element);
          }
        });
      this.historyList = resultData;
    },
    groupedConditions(data) {
      return Object.values(groupBy(data, 'name'));
    },
    restore(item) {
      if (this.tabs.length >= UICONFIG.MAX_NUM_RESULT_TABS) {
        this.$root.$emit(VUE_EVENT_NAME.ADD_TAB_LIMITATION, item);
        this.trackClick('addTabLimitation', { value: 'addTabLimitation', item });
      } else {
        // eslint-disable-next-line no-param-reassign
        item.data.root = '';
        // eslint-disable-next-line no-param-reassign
        item.data.trackId = uuid();

        this.addTab(item);
        const lastIndex = this.tabs.length - 1;
        this.update({
          includes: this.tabs[lastIndex].includes,
          excludes: this.tabs[lastIndex].excludes,
          uniqueIndex: lastIndex,
        });
        this.trackClick('restore', { value: 'restore', item });
      }
    },
    formatTime(time) {
      return dayjs(new Date(time)).format('YYYY/MM/DD HH:mm');
    },
  },
};
</script>
<style scoped>
.history-list {
  padding: 30px 25px 30px 25px;
  min-height: 800px;
}

.icon-no-result {
  width: 88px;
  height: 88px;
  margin-top: 211px;
  object-fit: contain;
}

.no-result-title-text {
  height: 36px;
  margin-top: 12px;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #333f6b;
}

.no-result-content-text {
  width: 417px;
  height: 48px;
  margin-top: 12px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #333333;
}

.button-new-search,
.button-new-search:hover,
.button-new-search:focus,
.button-new-search:active,
.button-new-search:active:visited {
  width: 417px;
  height: 44px;
  border-radius: 6px;
  background-color: #333f6b !important;
  border: solid 1px #333f6b !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-top: 35px;
}

.icon-save {
  margin-top: -4px;
}

.save-title {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #333f6b;
  height: 23px;
}

.recent-title {
  height: 20px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #828282;
  margin-left: 15px;
}

.title-row {
  height: 38px;
  border-radius: 6px;
  background-color: #ebecf0;
  margin-top: 10px;
}

.title-name {
  height: 18px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #333f6b;
  margin: 10px 0px 0px 20px;
}

.title-date {
  height: 18px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #333f6b;
  margin: 10px 30px 0px 0px;
}

.data-row {
  height: 68px;
  box-shadow: inset 0 -1px 0 0 #d6d9e1;
  cursor: pointer;
}

.data-row:hover {
  background-color: #eaf2fd;
}

.name-text {
  height: 20px;
  margin: 10px 20px 0px 20px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
}

.condition-text {
  height: 18px;
  margin: 5px 20px 0px 20px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #828282;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}

.condition-type-text {
  float: left;
  margin-right: 10px;
}

.time-text {
  height: 20px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #333333;
  width: 140px;
}
</style>
<style lang="scss" scoped>
/deep/ .text-keyword-content {
  display: inline !important;
  float: none !important;
}

/deep/ .text-keyword-content .range {
  color: #828282 !important;
  display: inline !important;
}

/deep/ .text-keyword-content .category-text {
  color: #828282 !important;
  display: inline !important;
}

/deep/ .text-keyword-content .keyword-text {
  color: #828282 !important;
  display: inline !important;
}

/deep/ .text-keyword-content .category {
  color: #828282 !important;
  display: inline !important;
}

/deep/ .text-keyword-content .concater {
  top: 0px !important;
}

/deep/ .text-keyword {
  display: inline !important;
  float: none !important;
}

/deep/ .floater {
  display: inline !important;
  float: none !important;
}

/deep/ .condition-type-text {
  display: inline !important;
  float: none !important;
}

.right-div {
  width: 140px;
  max-width: 140px;
  min-width: 140px;
}

.left-div {
  width: calc(100% - 140px);
  max-width: calc(100% - 140px);
  min-width: calc(100% - 140px);
}
</style>
