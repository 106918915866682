<template>
  <span>
    <span v-if="value.type === 'icd10'" class="category-text">{{ value.code }}</span>
    <div class="tooltip-content" v-if="value.type === 'icd10'">
      <a-row>
        <a-col class="tooltip-content-text">
          {{ ICD10(value.code) }}
        </a-col>
      </a-row>
      <a-row style="margin-top: 5px">
        <a-col class="tooltip-content-text">{{ 'ICD-10' }}</a-col>
      </a-row>
    </div>
    <span v-if="value.type === 'icd9'" class="category-text">{{ value.code }}</span>
    <div class="tooltip-content" v-if="value.type === 'icd9'">
      <a-row>
        <a-col class="tooltip-content-text">
          {{ ICD9(value.code) }}
        </a-col>
      </a-row>
      <a-row style="margin-top: 5px">
        <a-col class="tooltip-content-text">{{ 'ICD-9' }}</a-col>
      </a-row>
    </div>
    <a-tooltip
      v-if="value.type === 'GroupAndMap'"
      placement="bottomLeft"
      overlayClassName="groupandmap-tooltip"
      :get-popup-container="getPopupContainer"
      :destroyTooltipOnHide="true"
      @mouseenter="getHoverData"
    >
      <template slot="title">
        <div class="codesearch-menu-content">
          <b-row no-gutters class="hover-header-row hover-header-row-top">
            <b-col class="d-flex align-items-center">
              <div class="header-text">ICD 10</div>
            </b-col>
            <b-col cols="1" class="d-flex align-items-center justify-content-end">
              <b-img
                src="@/assets/images/icon_expand_open.svg"
                class="icon-expand-header"
                v-if="!icd10Expand"
                @click.stop="openHoverIcd10Expand()"
              />
              <b-img
                v-else
                src="@/assets/images/icon_expand_close.svg"
                class="icon-expand-header"
                @click.stop="closeHoverIcd10Expand()"
              />
            </b-col>
          </b-row>
          <VuePerfectScrollbar @wheel.stop v-if="icd10Expand">
            <b-row
              no-gutters
              v-for="option in hoverOptions"
              :title="option.description"
              :key="option.code"
              class="row-option"
            >
              <b-col>
                <b-row no-gutters class="code-search-menu">
                  <b-col
                    cols="2"
                    class="d-flex align-items-center"
                    :style="{
                      'margin-left': `${getExpandMargin(option.level)}px`,
                    }"
                  >
                    <div class="code-text">{{ `${option.code}` }}</div>
                  </b-col>
                  <b-col
                    class="d-flex align-items-center"
                    :style="{
                      flex: `0 0 calc(100%/12*10 - ${getExpandMargin(option.level)}px)`,
                      'max-width': `calc(100%/12*10 - ${getExpandMargin(option.level)}px)`,
                    }"
                  >
                    <div style="max-width: calc(100% - 64px)">
                      <div class="icd-description">
                        {{ option.description }}
                      </div>
                      <div class="icd-description">
                        {{ option.chineseDescription }}
                      </div>
                    </div>
                    <b-img
                      src="@/assets/images/icon_expand_open.svg"
                      class="icon-expand ml-auto"
                      v-if="!option.billable && !option.expand"
                      @click.stop="openHoverExpand(option)"
                    />
                    <b-img
                      src="@/assets/images/icon_expand_close.svg"
                      class="icon-expand ml-auto"
                      v-if="!option.billable && option.expand"
                      @click.stop="closeHoverExpand(option)"
                    />
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </VuePerfectScrollbar>
          <b-row no-gutters class="hover-header-row" :class="{ 'hover-header-row-bottom': !icd9Expand }">
            <b-col class="d-flex align-items-center">
              <div class="header-text">Mapped ICD 9</div>
            </b-col>
            <b-col cols="1" class="d-flex align-items-center justify-content-end">
              <b-img
                src="@/assets/images/icon_expand_open.svg"
                class="icon-expand-header"
                v-if="!icd9Expand"
                @click.stop="openHoverIcd9Expand()"
              />
              <b-img
                v-else
                src="@/assets/images/icon_expand_close.svg"
                class="icon-expand-header"
                @click.stop="closeHoverIcd9Expand()"
              />
            </b-col>
          </b-row>
          <VuePerfectScrollbar @wheel.stop v-if="icd9Expand">
            <b-row
              no-gutters
              v-for="(option, index) in hoverMapOptions"
              :title="option.description"
              :key="option.code"
              class="row-option"
              :class="{
                'hover-header-row-bottom': index === hoverMapOptions.length - 1,
              }"
            >
              <b-col>
                <b-row no-gutters class="code-search-menu">
                  <b-col
                    cols="2"
                    class="d-flex align-items-center"
                    :style="{
                      'margin-left': `${getExpandMargin(option.level)}px`,
                    }"
                  >
                    <div class="code-text">{{ `${option.code}` }}</div>
                  </b-col>
                  <b-col
                    style="padding: 6px 0px"
                    class="d-flex align-items-center"
                    :style="{
                      flex: `0 0 calc(100%/12*10 - ${getExpandMargin(option.level)}px)`,
                      'max-width': `calc(100%/12*10 - ${getExpandMargin(option.level)}px)`,
                    }"
                  >
                    <div style="max-width: calc(100% - 64px)">
                      <div class="icd-description">
                        {{ option.description }}
                      </div>
                      <div class="icd-description">
                        {{ option.chineseDescription }}
                      </div>
                    </div>
                    <b-img
                      src="@/assets/images/icon_expand_open.svg"
                      class="icon-expand ml-auto"
                      v-if="!option.billable && !option.expand"
                      @click.stop="openHoverIcd9OptionExpand(option)"
                    />
                    <b-img
                      src="@/assets/images/icon_expand_close.svg"
                      class="icon-expand ml-auto"
                      v-if="!option.billable && option.expand"
                      @click.stop="closeHoverIcd9OptionExpand(option)"
                    />
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </VuePerfectScrollbar>
        </div>
      </template>
      <span class="category-text">
        <div class="partial-dot" v-if="value.partial"></div>
        {{ value.code }}</span
      >
    </a-tooltip>
  </span>
</template>
<script>
/* eslint-disable no-param-reassign */
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { ClickMixin, OptionsMixin } from '@/mixins';
import { formatIcdGroupMapItem, getIcdFormatList } from '@/utils/util';

export default {
  name: 'ICDValue',
  mixins: [ClickMixin, OptionsMixin],
  props: {
    value: {
      type: Object,
    },
  },
  components: {
    VuePerfectScrollbar,
  },
  data() {
    return {
      hoverOptions: [],
      hoverMapOptions: [],
      icd10Expand: true,
      icd9Expand: false,
    };
  },
  methods: {
    async getHoverData() {
      if (this.value.type === 'GroupAndMap' && this.hoverOptions.length === 0 && this.hoverMapOptions.length === 0) {
        if (this.value.partial) {
          await this.getHoverInitData(this.value);
          const valueDescendants = this.value.selectDescendants;
          this.hoverOptions.forEach((item) => {
            item.check = valueDescendants.includes(item.code);
          });
          this.hoverOptions = this.hoverOptions.filter((item) => item.check);
        } else {
          this.hoverOptions = [this.value];
        }

        const icd_list = this.value.icd9List;

        const response = await this.$api
          .searchDiagnosisMerge({
            diagnosis_range: 'Any',
            query_mode: 'ICD9',
            icd_list,
          })
          .catch((error) => {
            console.error(error);
          });

        const formatItem = (item) => ({
          ...formatIcdGroupMapItem(item),
          level: 1,
          parent: '',
        });

        const ICDCodes = getIcdFormatList(response.results, formatItem);

        this.hoverMapOptions = ICDCodes;
      }
    },
    closeHoverExpand(option) {
      this.hoverOptions = this.hoverOptions.filter((item) => !option.descendants.includes(item.code));
      option.expand = false;
      this.trackClick('closeHoverExpand', { option });
    },
    getPopupContainer(trigger) {
      return trigger.parentElement;
    },
    getExpandMargin(level) {
      return (level - 1) * 12;
    },
    openHoverIcd10Expand() {
      this.icd10Expand = true;
      this.trackClick('openHoverIcd10Expand');
    },
    closeHoverIcd10Expand() {
      this.icd10Expand = false;
      this.trackClick('closeHoverIcd10Expand');
    },
    openHoverIcd9Expand() {
      this.icd9Expand = true;
      this.trackClick('openHoverIcd9Expand');
    },
    closeHoverIcd9Expand() {
      this.icd9Expand = false;
      this.trackClick('closeHoverIcd9Expand');
    },
    async openHoverIcd9OptionExpand(option) {
      const response = await this.$api
        .searchDiagnosisExpand({
          query: this.inputText,
          diagnosis_range: this.getDiagnosisRange,
          code: option.code,
          query_mode: 'ICD9',
        })
        .catch((error) => {
          console.error(error);
        });

      const formatItem = (item) => ({
        ...formatIcdGroupMapItem(item),
        check: option.check,
        level: option.level + 1,
        parent: option.code,
      });

      const ICDCodes = getIcdFormatList(response.children, formatItem);

      // Add ICDCodes to options
      // Set child child
      // Set expand is true
      const index = this.hoverMapOptions.findIndex((item) => item.code === option.code);
      if (index !== -1) {
        this.hoverMapOptions.splice(index + 1, 0, ...ICDCodes);
        option.child = ICDCodes;
        option.expand = true;
      }

      this.trackClick('openHoverIcd9OptionExpand', { option });
    },
    closeHoverIcd9OptionExpand(option) {
      this.hoverMapOptions = this.hoverMapOptions.filter((item) => !option.descendants.includes(item.code));
      option.expand = false;
      this.trackClick('closeHoverIcd9OptionExpand', { option });
    },
  },
};
</script>
<style lang="scss">
.groupandmap-tooltip {
  width: 640px;
  max-width: 640px !important;

  .ant-tooltip-arrow {
    display: none;
  }
  .ant-tooltip-inner {
    border-radius: 6px;
    width: 640px;
    background: #ffffff;
    padding: 0px;

    .codesearch-menu-content {
      border: 1px solid #d6d9e1;
      border-radius: 6px;
      background: #ffffff;
    }

    .hover-header-row-top {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }

    .hover-header-row-bottom {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    .hover-header-row {
      height: 38px;
      padding: 0px 20px 0px 20px;
      background-color: #f5f5f5;

      .header-text {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        color: #334e97;
      }

      .icon-expand-header {
        width: 34px;
        height: 34px;
        background: #ffffff;
        border-radius: 100px;
        cursor: pointer;
      }
    }

    .row-option {
      box-shadow: inset 0px 1px 0px #d6d9e1;

      .code-search-menu {
        padding: 0px 20px;
        height: 46px;
      }

      .code-text {
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #334e97;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .icd-description {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 14px;
        line-height: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #333333;
      }

      .icon-expand {
        cursor: pointer;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
/deep/ .ps {
  max-height: 230px;
}

.partial-dot {
  width: 6px;
  height: 6px;
  background: #4fc2e7;
  border-radius: 6px;
  margin-right: 5px;
  display: inline-block;
  margin-bottom: 2px;
}
</style>
