<template>
  <div class="text-keyword-content">
    <span v-for="(lab, index) in labs" :key="index" :title="'test'">
      <span class="text-keyword"> {{ lab.exam }} - {{ lab.lab }} </span>
      <span class="text-keyword-content">
        <span v-for="(labItem, indexItem) in lab.ranges" :key="`${indexItem}-item`">
          <span :class="{ range: true, deletable }" :title="`${lab.exam}-${lab.lab}`">
            <template v-if="labItem.start && labItem.end && labItem.start !== labItem.end">
              {{ `${labItem.start}~${labItem.end}` }}
            </template>
            <template v-else-if="labItem.start && !labItem.end">
              <span style="font-size: 10">≧</span>{{ labItem.start }}
            </template>
            <template v-else-if="!labItem.start && labItem.end">
              <span style="font-size: 10">≦</span>{{ labItem.end }}
            </template>
            <template v-else>
              {{ labItem.start }}
            </template>
            <span
              v-if="deletable"
              @click="
                onDelete(
                  {
                    start: labItem.start,
                    end: labItem.end,
                    lab: lab.lab,
                    exam: lab.exam,
                  },
                  indexItem
                )
              "
              class="del-icon"
              >x</span
            >
          </span>
          <span v-if="indexItem !== lab.ranges.length - 1" class="concater"> or </span>
        </span>
      </span>
      <span v-if="index !== labs.length - 1" class="concater"> and </span>
    </span>
  </div>
</template>

<script>
import { ClickMixin, TemplateValue, UpdateMixin } from '@/mixins';
import { xorWith } from 'lodash';
import { mapState } from '../../../../store';

export default {
  name: 'LabValue',
  mixins: [ClickMixin, TemplateValue, UpdateMixin],
  props: {
    labs: [Array],
    uniqueIndex: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapState('session', ['tabs']),
    tab() {
      return this.tabs[this.uniqueIndex];
    },
  },
  methods: {
    onDelete(lab, index) {
      const extendConditions = [this.refCond[index]];
      this.trackClick('onDelete', {
        includes: xorWith(this.tab.includes, extendConditions),
        excludes: this.tab.excludes,
        lab,
        uniqueIndex: this.uniqueIndex,
      });
      this.update({
        includes: xorWith(this.tab.includes, extendConditions),
        excludes: this.tab.excludes,
        uniqueIndex: this.uniqueIndex,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.text-keyword-content {
  float: left;
  overflow: visible;

  .range {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333f6b;
  }

  .concater {
    font-size: 12px;
    font-weight: normal;
    color: #828282;
    position: relative;
    top: -4px !important;
    float: none !important;
  }

  .deletable {
    height: 24px;
    border-radius: 20px;
    margin: 2px 5px;
    padding: 2px 10px;
    background-color: #333f6b;
    font-family: Muli, Serif, Sans-serif, cursive, fantasy, Monospace;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    display: inline-block;
    position: relative;
    top: -4px;
  }

  .del-icon {
    cursor: pointer;
    color: #f5ba42;
  }
}

.text-keyword {
  position: relative;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #828282;
  margin-right: 5px;
  float: left;
}
</style>
