<template>
  <div @click.stop="">
    <div class="d-inline-flex align-items-center">
      <div class="d-inline-flex align-items-center constraint-capsule">
        <div class="constraint-capsule-word">Visit Type</div>
        <a-select
          :default-value="VISIT_TYPE_OPTIONS[0].value"
          @change="handleChange"
          :getPopupContainer="(trigger) => trigger.parentNode"
          class="constraint-select"
          :value="visitValue"
        >
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option v-for="option in VISIT_TYPE_OPTIONS" :value="option.value" :key="option.value">
            {{ option.lable }}
          </a-select-option>
        </a-select>
        <b-img
          src="@/assets/images/icon_remove_blue.svg"
          class="delete-button"
          @click="deleteConstraint(ConstraintType.VisitTypeConstraint)"
        />
      </div>
      <div v-if="!hasCondition" id="invalid-warning">Invalid when no condition</div>
    </div>
  </div>
</template>

<script>
import { findIndex } from 'lodash';
import ClickOutside from 'vue-click-outside';
import { EventSearchMixin, ClickMixin, EventUpdateMixin } from '@/mixins';
import ConstraintType from '@/utils/conditions/core/advance/constraint/Interface';
import { EventType, VisitTypeName } from '@/utils/conditions/core/Interface';
import { VISIT_TYPE_OPTIONS } from '@/utils/constants';

export default {
  name: 'VisitTypeConstraint',
  directives: {
    ClickOutside,
  },
  mixins: [EventSearchMixin, EventUpdateMixin, ClickMixin],
  props: {
    index: {
      type: Number,
      default: 1,
    },
    data: {
      type: Object,
    },
    eventType: {
      type: String,
      default: EventType.FIX_EVENT,
    },
    path: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      VisitTypeName,
      isSliderOpen: true,
      VISIT_TYPE_OPTIONS,
      ConstraintType,
      visitValue: VISIT_TYPE_OPTIONS[0].value,
    };
  },
  mounted() {
    this.restoreVisitType();
  },
  watch: {
    currentEvent() {
      this.restoreVisitType();
    },
  },
  computed: {
    currentEvent() {
      return this.getEventDataByPath(this.path);
    },
    hasCondition() {
      return this.currentEvent.condition.length > 0;
    },
  },
  methods: {
    getStoreVisitType() {
      const event = this.getEventDataByPath(this.path);
      const constraintIndex = findIndex(event.constraintList, {
        constraintType: ConstraintType.VisitTypeConstraint,
      });
      return { constraintIndex, constraintList: event.constraintList };
    },
    handleChange(visitType) {
      this.visitValue = visitType;
      const { constraintIndex, constraintList } = this.getStoreVisitType();
      constraintList[constraintIndex].visitType = visitType;
      const body = {
        constraintList,
      };
      this.updateEventWithPath(body, this.path);

      this.cohortStudyTrackClick('changeVisitConstraintType');
    },
    restoreVisitType() {
      const { constraintIndex, constraintList } = this.getStoreVisitType();
      this.visitValue = constraintList[constraintIndex].visitType;
    },
  },
};
</script>

<style lang="scss" scoped>
.constraint-capsule {
  padding: 5px 10px;
  margin-left: 10px;
  background-color: #edf5fb;
  border-radius: 100px;
}

#invalid-warning {
  display: inline-block;
  color: #da6d6d;
  font-size: 14px;
  margin-left: 10px;
}

.icon-remove-blue {
  cursor: pointer;
  size: 13px;
}

.constraint-capsule-word {
  height: 18px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #333333;
  margin-right: 10px;
}

.constraint-select {
  width: 140px;
  height: 26px;
  margin-right: 10px;
}

.delete-button {
  cursor: pointer;
  size: 13px;
}

/deep/ .ant-select-selection--single {
  border-radius: 53px;
  border: 1px solid #e0e0e0;
  height: 26px;
}

/deep/ .ant-select-selection-selected-value {
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #334e97;
}

/deep/ .ant-select-arrow {
  color: #334e97;
}
</style>
