import { ConditionFactory, ConditionName } from '../core';
import KeywordCondition from '../core/KeywordCondition';

function getQuery(keywords: string[], fields: string[]) {
  return keywords.map((keyword) => ({
    simple_query_string: {
      query: keyword,
      fields,
      default_operator: 'AND',
    },
  }));
}

export default class KeywordDef extends KeywordCondition {
  public getFilter(): Record<string, unknown> {
    return {
      bool: {
        [this.andOr]: getQuery(this.list, this.fields),
      },
    };
  }
}

ConditionFactory.register(KeywordDef, ConditionName.Keyword);
