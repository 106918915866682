<template>
  <div
    class="concept-edit"
    :class="{
      'focus-border': selectType !== -1 && isFocusMenu,
    }"
    :style="{ padding: !(selectType !== -1 && isFocusMenu) ? '11px 21px' : '10px 20px' }"
    @click.stop="openCodeSearchMenu()"
  >
    <div class="top-header d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center">
        <b-img
          src="@/assets/images/icon_exclude_concept.svg"
          class="mr-5px"
          v-if="condition.conceptType === 'exclude'"
        />
        <div class="exclude-concept-text mr-10px" v-if="condition.conceptType === 'exclude'">Exclude</div>
        <template v-if="!isEditConeceptName">
          <div class="concept-name" style="cursor: pointer" @click.stop="clickEditConceptName">
            {{ condition.conceptName }}
          </div>
          <b-img
            src="@/assets/images/icon_emr_search_concept_name_edit.svg"
            class="ml-5px icon-img"
            style="cursor: pointer"
            @click.stop="clickEditConceptName"
          />
        </template>
        <template v-else>
          <input
            v-model="conceptName"
            ref="editConceptName"
            v-click-outside="closeEditConecptName"
            @keyup.enter="closeEditConecptName"
            @click.stop=""
          />
        </template>
      </div>
      <div class="d-flex align-items-center" style="position: relative">
        <b-img
          src="@/assets/images/icon_emr_search_delete_concept.svg"
          class="icon-img"
          title="Delete"
          :class="{ disabled: getInExcludeLength === 1 }"
          @click.stop="clickDeleteConcept"
        />
        <b-img
          src="@/assets/images/icon_emr_search_open_concept_highlight.svg"
          class="ml-5px icon-img"
          title="Import Concepts"
          v-if="landing && EMRSearchLandingIndex === 5"
        />
        <b-img
          src="@/assets/images/icon_emr_search_open_concept.svg"
          class="ml-5px icon-img"
          title="Import Concepts"
          @click.stop="openRestoreConceptModal"
          v-else
        />
        <b-img
          src="@/assets/images/icon_emr_search_save_concept_highlight.svg"
          class="ml-5px icon-img"
          title="Save As Concept"
          :class="{ disabled: condition.list.length === 0 }"
          @click.stop="clickLandingSaveAs"
          v-if="landing && EMRSearchLandingIndex === 3"
          style="pointer-events: auto"
        />
        <b-img
          src="@/assets/images/icon_emr_search_save_concept.svg"
          class="ml-5px icon-img"
          title="Save As Concept"
          :class="{ disabled: condition.list.length === 0 }"
          @click.stop="clickSaveAs"
          v-else
        />
        <div class="save-as-menu" @click.stop="" v-if="landingSaveAsMenu">
          <div class="save-as-text">Save As</div>
          <div class="tag-name-text mt-25px mb-10px">Criteria Name</div>
          <a-input
            class="tag-name-input"
            v-model="tagName"
            ref="saveAsConceptName"
            allow-clear
            style="pointer-events: auto"
          ></a-input>
          <div class="d-flex align-items-center justify-content-end mt-20px">
            <div class="mr-10px cancel-button" @click="closeLandingSaveAsMenu" style="pointer-events: auto">Cancel</div>
            <div class="save-as-button" @click="clickLandingSaveQuery" style="pointer-events: auto">Save As</div>
          </div>
        </div>
        <div class="landing-tooltip" v-if="landingSaveAsMenu">
          <div class="d-flex align-items-center">
            <b-img src="@/assets/images/landing_1.svg" />
            <div class="mini-title ml-5px">輸入條件與儲存個人化concept</div>
          </div>
          <div class="landing-text mt-10px">命名並儲存</div>
          <div class="landing-content-text mt-5px">輸入想要的名稱點擊 “Save As”</div>
        </div>
        <div
          class="save-as-menu"
          @click.stop=""
          v-if="saveAsMenu && isCurrentMenu(path, 'saveAsMenu')"
          v-click-outside="closeSaveAsMenu"
        >
          <div class="save-as-text">Save As</div>
          <div class="tag-name-text mt-25px mb-10px">Criteria Name</div>
          <a-input class="tag-name-input" v-model="tagName" ref="saveAsConceptName" allow-clear></a-input>
          <div class="d-flex align-items-center justify-content-end mt-20px">
            <div class="mr-10px cancel-button" @click="closeSaveAsMenu">Cancel</div>
            <div class="save-as-button" @click="clickSaveAsQuery">Save As</div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center mt-10px">
      <div class="logic-text">OR</div>
      <div class="edit-icd-text" style="cursor: pointer" v-if="hasDiagnosis" @click.stop="clickEditIcdMap">
        Edit Mapped ICD-9
      </div>
    </div>
    <template v-if="condition.list.length === 0">
      <div class="placeholder-text mt-15px" v-if="condition.list.length === 0">Add codes here</div>
    </template>
    <template v-else>
      <div class="d-flex flex-wrap">
        <div
          v-for="(item, index) in condition.list"
          :key="`${item.code}-${index}`"
          class="capsule-abbr d-flex align-items-center mt-10px"
          :class="getCapsuleAbbreviationClass(item.field)"
          :style="{ 'margin-right': index !== condition.list.length - 1 ? '12px' : '0px' }"
        >
          <template v-if="condition.name === 'Diagnosis'">
            <ICDCapsule :path="path" :tag="item" @clickDelete="clickDelete" />
          </template>
          <template v-else>
            <div class="capsule-abbr-text mr-10px" :title="`${item.code} ${item.description}`">
              {{ getCapsuleAbbreviation(item.field) }}
            </div>
            <div class="capsule-code mr-10px" :title="`${item.code} ${item.description}`">{{ item.code }}</div>
            <b-img
              src="@/assets/images/icon_capsule_delete_grey.svg"
              @click.stop="clickDelete(item)"
              class="capsule-delete"
              :title="`${item.code} ${item.description}`"
            />
          </template>
        </div>
      </div>
    </template>
    <b-row no-gutters style="height: 0px">
      <b-col style="height: 0px">
        <CodeSearchMenu
          class="d-inline-block"
          :condition="condition"
          :path="path"
          ref="codeSearchMenu"
          @updateSelectType="updateSelectType"
        />
      </b-col>
    </b-row>
    <div class="d-flex align-items-center mt-20px" v-if="hasDiagnosis && condition.diagnosisRange">
      <div class="constraint-text">Constraint:</div>
      <DiagnosisRangeRender :condition="condition" />
    </div>
    <div class="d-flex align-items-center mt-20px" v-if="hasDiagnosis">
      <DiagnosisRange :condition="condition" :class="{ disabled: condition.diagnosisRange }" />
    </div>
    <div>
      <IcdMap
        ref="icdmap"
        :currentConditions="condition.conceptType === 'include' ? [condition] : []"
        :currentExcludedConditions="condition.conceptType === 'exclude' ? [condition] : []"
        :search="false"
        :conceptCondition="condition"
      />
    </div>
    <div>
      <RestoreConceptModal ref="restoreConcept" :condition="condition" />
    </div>
  </div>
</template>
<script>
import CodeSearchMenu from '@/components/filters/CodeSearchMenu.vue';
import { EMRSearchMixin, ClickMixin } from '@/mixins';
import ICDCapsule from '@/components/capsules/ICDCapsule.vue';
import { getCapsuleAbbreviation, getCapsuleAbbreviationClass } from '@/utils/util';
import { mapMutations, mapState } from '@/store';
import { cloneDeep } from 'lodash';
import IcdMap from '@/components/modals/IcdMap.vue';
import DiagnosisRange from '@/components/filters/Constraints/DiagnosisRange.vue';
import DiagnosisRangeRender from '@/components/filters/ConstraintRender/DiagnosisRangeRender.vue';
import ClickOutside from 'vue-click-outside';
import { VUE_EVENT_NAME } from '@/utils/constants';
import RestoreConceptModal from '@/components/modals/RestoreConceptModal.vue';

export default {
  name: 'ConceptEdit',
  mixins: [EMRSearchMixin, ClickMixin],
  directives: {
    ClickOutside,
  },
  props: {
    condition: Object,
    path: {
      type: Array,
      default: () => [],
    },
    landing: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CodeSearchMenu,
    ICDCapsule,
    IcdMap,
    DiagnosisRange,
    DiagnosisRangeRender,
    RestoreConceptModal,
  },
  data() {
    return {
      selectType: -1,
      saveAsMenu: false,
      tagName: 'Tag Name',
      conceptName: '',
      conditionNameList: ['Diagnosis', 'Medication', 'Medical Order'],
      landingSaveAsMenu: false,
    };
  },
  computed: {
    ...mapState('condition', ['conditions', 'excludedConditions']),
    ...mapState(['EMRSearchLandingIndex']),
    isFocusMenu() {
      return this.isCurrentMenu(this.path, 'EMRSearchCodeSearchMenu');
    },
    hasDiagnosis() {
      return this.condition.name === 'Diagnosis' && this.condition.list.length > 0;
    },
    isEditConeceptName() {
      return this.isCurrentMenu(this.path, 'ConeceptName');
    },
    getInExcludeLength() {
      return (
        this.conditions.filter((item) => this.conditionNameList.includes(item.name)).length +
        this.excludedConditions.filter((item) => this.conditionNameList.includes(item.name)).length
      );
    },
  },
  methods: {
    ...mapMutations('condition', ['setCondition', 'setExcludedCondition']),
    ...mapMutations(['setEMRSearchLandingIndex']),
    getCapsuleAbbreviation,
    getCapsuleAbbreviationClass,
    openCodeSearchMenu() {
      if (this.isEditConeceptName) {
        this.closeEditConecptName(false);
      }
      this.updateSelectType(1);
      this.$refs.codeSearchMenu.clickCondition();
      this.trackClick('openCodeSearchMenu');
    },
    updateSelectType(value) {
      this.selectType = value;
    },
    clickDelete(capsule) {
      this.setCurrentMenu(this.path, '');

      const result =
        this.condition.conceptType === 'include'
          ? cloneDeep(this.$store.state.condition.conditions)
          : cloneDeep(this.$store.state.condition.excludedConditions);
      const resultIndex = result.findIndex((item) => item.time === this.condition.time);
      const capsuleIndex = result[resultIndex].list.findIndex((item) => item.code === capsule.code);

      result[resultIndex].list.splice(capsuleIndex, 1);

      if (this.condition.conceptType === 'include') {
        this.setCondition(result);
      } else {
        this.setExcludedCondition(result);
      }
      this.trackClick('clickDelete');
    },
    clickEditIcdMap() {
      this.$refs.icdmap.openIcdMap();
      this.trackClick('clickEditIcdMap');
    },
    clickDeleteConcept() {
      this.clickOtherConceptName();
      const result =
        this.condition.conceptType === 'include'
          ? cloneDeep(this.$store.state.condition.conditions)
          : cloneDeep(this.$store.state.condition.excludedConditions);
      const resultIndex = result.findIndex((item) => item.time === this.condition.time);
      result.splice(resultIndex, 1);

      if (this.condition.conceptType === 'include') {
        this.setCondition(result);
      } else {
        this.setExcludedCondition(result);
      }
      this.trackClick('clickDeleteConcept');
    },
    clickSaveAs() {
      this.clickOtherConceptName();
      this.saveAsMenu = true;
      this.selectType = -1;
      this.setCurrentMenu(this.path, 'saveAsMenu');
      this.$nextTick(() => {
        this.tagName = this.condition.conceptName;
        this.$refs.saveAsConceptName.select();
      });
      this.trackClick('clickSaveAs');
    },
    closeSaveAsMenu() {
      this.saveAsMenu = false;
      this.selectType = -1;
      this.setCurrentMenu(this.path, '');
      this.trackClick('closeSaveAsMenu');
    },
    async clickSaveAsQuery() {
      await this.$api.saveQuery(this.tagName, { ...this.condition, time: Date.now() }, 'saveConcept');
      this.closeSaveAsMenu();

      this.$root.$emit(VUE_EVENT_NAME.SET_EMR_SEARCH_SAVED_HINT_VALUE, true);
      this.$root.$emit(VUE_EVENT_NAME.REFRESH_EMR_SEARCH_CONCEPT_LIST);
      this.trackClick('clickSaveAsQuery');
    },
    clickLandingSaveAs() {
      this.landingSaveAsMenu = true;
      this.$emit('updateLandingMenu', true);
      this.selectType = -1;
      this.$nextTick(() => {
        this.tagName = this.condition.conceptName;
        this.$refs.saveAsConceptName.select();
      });
      this.trackClick('clickSaveAs');
    },
    async clickLandingSaveQuery() {
      await this.$api.saveQuery(this.tagName, { ...this.condition, time: Date.now() }, 'saveConcept');

      this.$root.$emit(VUE_EVENT_NAME.REFRESH_EMR_SEARCH_CONCEPT_LIST);
      this.setEMRSearchLandingIndex(4);
      this.closeLandingSaveAsMenu();
    },
    closeLandingSaveAsMenu() {
      this.landingSaveAsMenu = false;
      this.$emit('updateLandingMenu', false);
      this.selectType = -1;
      this.trackClick('closeSaveAsMenu');
    },
    openRestoreConceptModal() {
      this.clickOtherConceptName();
      this.$refs.restoreConcept.openModal();
      this.trackClick('openRestoreConceptModal');
    },
    clickEditConceptName() {
      this.conceptName = this.condition.conceptName;
      this.setCurrentMenu(this.path, 'ConeceptName');
      this.$nextTick(() => {
        this.$refs.editConceptName.select();
      });
      this.trackClick('clickEditConceptName');
    },
    closeEditConecptName(close = true) {
      if (close) {
        this.setCurrentMenu(this.path, '');
      }

      const result =
        this.condition.conceptType === 'include'
          ? cloneDeep(this.$store.state.condition.conditions)
          : cloneDeep(this.$store.state.condition.excludedConditions);
      const resultIndex = result.findIndex((item) => item.time === this.condition.time);
      result[resultIndex].conceptName = this.conceptName;
      if (this.condition.conceptType === 'include') {
        this.setCondition(result);
      } else {
        this.setExcludedCondition(result);
      }
      this.trackClick('closeEditConecptName');
    },
    clickOtherConceptName() {
      if (this.isEditConeceptName) {
        this.closeEditConecptName();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.concept-edit {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 8px;
}

.top-header {
  padding: 0px 0px 10px 0px;
  border-bottom: 1px solid #e0e0e0;
}

.concept-name {
  color: #262626;
  font-size: 18px;
  line-height: 22px;
}

.logic-text {
  color: #919191;
  font-size: 14px;
  line-height: 17px;
}

.placeholder-text {
  color: #919191;
  font-size: 16px;
  line-height: 19px;
}

.icon-img {
  cursor: pointer;
}

.exclude-concept-text {
  font-size: 14px;
  line-height: 17px;
  color: #919191;
}

.edit-icd-text {
  font-size: 14px;
  line-height: 17px;
  color: #0074e6;
}

.focus-border {
  border: 2px solid #4d8edc;
}

.capsule-abbr {
  padding: 4px 8px;
  display: inline-block;
  background: #abe0e4;
  border-radius: 4px;
  width: fit-content;
}

.capsule-abbr-text {
  font-size: 14px;
  line-height: 17px;
  color: #919191;
}

.capsule-code {
  color: #262626;
  font-size: 16px;
  line-height: 19px;
  margin-top: -1px;
}

.capsule-delete {
  cursor: pointer;
}

.abbr-md {
  background: #b2e1bb;
}

.abbr-o {
  background: #fad195;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.constraint-text {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #333333;
}

.save-as-menu {
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(185, 185, 185, 0.3), 0px 16px 20px rgba(157, 205, 245, 0.2);
  border-radius: 8px;
  padding: 20px;
  z-index: 1;
  width: 350px;
  margin-top: 248px;
  right: -22px;
}

.save-as-text {
  color: #262626;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
}

.tag-name-text {
  color: #5b5b5b;
  font-size: 14px;
  line-height: 17px;
}

.tag-name-input {
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 8px;
}

.cancel-button {
  padding: 8px 16px;
  border: 1px solid #0074e6;
  box-sizing: border-box;
  border-radius: 8px;
  color: #0074e6;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
}

.save-as-button {
  padding: 8px 16px;
  background: #0074e6;
  border-radius: 8px;
  color: #ffffff;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
}

.landing-tooltip {
  padding: 20px;
  width: 339px;
  background: #ffffff;
  border-radius: 16px;
  position: absolute;
  margin-left: 138px;
  margin-top: 375px;
}

.landing-tooltip::before {
  content: '';
  position: absolute;
  display: block;
  width: 0px;
  margin-top: 65px;
  left: -6px;
  -webkit-transform: translate(-50%, calc(-100% - 5px));
  -ms-transform: translate(-50%, calc(-100% - 5px));
  transform: translate(-50%, calc(-100% - 5px));
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-right: 12px solid #ffffff;
}

.mini-title {
  color: #0074e6;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}

.landing-text {
  color: #212121;
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
}

.landing-content-text {
  color: #262626;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
}
</style>
