export default {
  conceptName: 'Anti-TNF Biologics',
  conceptType: 'include',
  name: 'Medication',
  tags: ['Basic', 'Raw'],
  andOr: 'should',
  list: [
    {
      code: 'IADAL04',
      label: 'IADAL04',
      description: 'Adalimumab (Humira) 復邁 40mg/0.4ml/syringe (審)',
      chineseDescription: 'Adalimumab (Humira) 復邁 40mg/0.4ml/syringe (審)',
      value: 'IADAL04',
      type: 'med',
      patientNum: 318,
      recordNum: 8810,
      field: 'med',
      ingredient: 'adalimumab',
      atcCode: 'L04AB04',
      time: 1646620047830,
    },
    {
      code: 'IETAN25',
      label: 'IETAN25',
      description: 'Etanercept (Enbrel) 恩博預充式針筒裝 25mg/vial(審)',
      chineseDescription: 'Etanercept (Enbrel) 恩博預充式針筒裝 25mg/vial(審)',
      value: 'IETAN25',
      type: 'med',
      patientNum: 504,
      recordNum: 26958,
      field: 'med',
      ingredient: 'etanercept',
      atcCode: 'L04AA11',
      time: 1646620056748,
    },
    {
      code: 'IINFL',
      label: 'IINFL',
      description: 'Infliximab(Remicade) 類克凍晶注射劑 100mg/vial(審)',
      chineseDescription: 'Infliximab(Remicade) 類克凍晶注射劑 100mg/vial(審)',
      value: 'IINFL',
      type: 'med',
      patientNum: 17,
      recordNum: 85,
      field: 'med',
      ingredient: 'infliximab',
      atcCode: 'L04AB02',
      time: 1646620061659,
    },
    {
      code: 'IINFL10',
      label: 'IINFL10',
      description: 'Infliximab(Remsima)類希瑪100 mg/vial(審)',
      chineseDescription: 'Infliximab(Remsima)類希瑪100 mg/vial(審)',
      value: 'IINFL10',
      type: 'med',
      patientNum: 5,
      recordNum: 17,
      field: 'med',
      ingredient: 'infliximab',
      atcCode: 'L04AB02',
      time: 1646620062141,
    },
    {
      code: 'IGOLI10',
      label: 'IGOLI10',
      description: 'Golimumab【 100 mg/vial】 (Simponi) 欣普尼(審)(臨)',
      chineseDescription: 'Golimumab【 100 mg/vial】 (Simponi) 欣普尼(審)(臨)',
      value: 'IGOLI10',
      type: 'med',
      patientNum: 3,
      recordNum: 8,
      field: 'med',
      ingredient: 'golimumab',
      atcCode: 'L04AB06',
      time: 1646620066566,
    },
    {
      code: 'IGOLIM',
      label: 'IGOLIM',
      description: 'Golimumab 50 mg/vial (Simponi) 欣普尼 (審)',
      chineseDescription: 'Golimumab 50 mg/vial (Simponi) 欣普尼 (審)',
      value: 'IGOLIM',
      type: 'med',
      patientNum: 408,
      recordNum: 13871,
      field: 'med',
      ingredient: 'golimumab',
      atcCode: 'L04AB06',
      time: 1646620067100,
    },
  ],
  diagnosisRange: false,
  index: 1,
};
