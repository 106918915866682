var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((_vm.getInExcludeData),function(condition,index){return _c('div',{key:index},[_c('ConceptEdit',{attrs:{"condition":condition,"path":['search', condition.index],"id":("ConceptEdit-" + index)}}),(_vm.getInExcludeData.length - 1 !== index)?_c('div',{staticClass:"mt-5px mb-5px concept-logic-text"},[_vm._v("AND")]):_vm._e()],1)}),_c('div',{staticClass:"mt-20px d-flex align-items-center"},[_c('div',{staticClass:"in-ex-clude-button",on:{"click":_vm.addInclude}},[_vm._v("+ Include")]),_c('div',{staticClass:"ml-10px in-ex-clude-button",on:{"click":_vm.addExclude}},[_vm._v("+ Exclude")])]),(_vm.EMRSearchLandingIndex === 2 || _vm.EMRSearchLandingIndex === 3 || _vm.EMRSearchLandingIndex === 5)?_c('div',{staticClass:"landing-mask"},[_c('ConceptEdit',{staticStyle:{"pointer-events":"none"},style:({
        width: (_vm.width + "px"),
        'max-width': (_vm.width + "px"),
        'min-width': (_vm.width + "px"),
        margin: (_vm.top + "px 0px 0px " + _vm.left + "px"),
        display: _vm.EMRSearchLandingIndex === 3 ? 'inline-block' : 'block',
      }),attrs:{"condition":_vm.EMRSearchLandingIndex === 5 ? _vm.getInExcludeData[1] : _vm.getInExcludeData[0],"path":['search', 1],"landing":_vm.EMRSearchLandingIndex === 3 || _vm.EMRSearchLandingIndex === 5},on:{"updateLandingMenu":_vm.updateLandingMenu}}),(_vm.EMRSearchLandingIndex === 2)?_c('div',{staticClass:"landing-tooltip"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-img',{attrs:{"src":require("@/assets/images/landing_1.svg")}}),_c('div',{staticClass:"mini-title ml-5px"},[_vm._v("輸入條件與儲存個人化concept")])],1),_c('div',{staticClass:"landing-text mt-10px"},[_vm._v("輸入搜尋條件")]),_c('div',{staticClass:"landing-content-text mt-10px"},[_vm._v(" 以自體免疫性疾病 (Inflammatory diseases) 使用抗腫瘤壞死因子製劑 (anti-TNF biologics) 為例，將代表自體免疫性疾病的 ICD-10 code 輸入 ")]),_c('div',{staticClass:"next-button mt-10px",on:{"click":_vm.clickNext}},[_vm._v("Next")])]):_vm._e(),(_vm.EMRSearchLandingIndex === 3 && !_vm.landingSaveAsMenu)?_c('div',{staticClass:"landing-2-tooltip",style:({
        display: _vm.EMRSearchLandingIndex === 3 ? 'inline-block' : 'block',
        margin: ((_vm.top - 43) + "px 0px 0px 22px"),
      })},[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-img',{attrs:{"src":require("@/assets/images/landing_1.svg")}}),_c('div',{staticClass:"mini-title ml-5px"},[_vm._v("輸入條件與儲存個人化concept")])],1),_c('div',{staticClass:"landing-text mt-10px"},[_vm._v("按下圖示，儲存concept")]),_c('div',{staticClass:"landing-content-text mt-5px"},[_vm._v(" 按 “save as” icon，把這一些代表 自體免疫性疾病 (Inflammatory diseases) 的 code 存起來成為一個可經常重複利用的個人化concept ")])]):_vm._e(),(_vm.EMRSearchLandingIndex === 5)?_c('div',{staticClass:"landing-3-tooltip",style:({ top: ((_vm.top - 223 - 21) + "px"), left: ((_vm.left + _vm.width - 320) + "px") })},[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-img',{attrs:{"src":require("@/assets/images/landing_2.svg")}}),_c('div',{staticClass:"mini-title ml-5px"},[_vm._v("匯入concept至criteria")])],1),_c('div',{staticClass:"landing-text mt-10px"},[_vm._v("加入 concept")]),_c('div',{staticClass:"landing-content-text mt-10px"},[_vm._v(" 你可以用 import 加入抗腫瘤壞死因子製劑 (anti-TNF biologics)至Criteria設定區 ")]),_c('div',{staticClass:"next-button mt-10px",on:{"click":_vm.clickNext5}},[_vm._v("Next")])]):_vm._e()],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }