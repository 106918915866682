export default {
  conceptName: 'Anti-TNF Biologics',
  conceptType: 'include',
  name: 'Medication',
  tags: ['Basic', 'Raw'],
  andOr: 'should',
  list: [
    {
      code: 'CADALI1',
      label: 'CADALI1',
      description: '【0.4ml】Humira 【0.4ml】',
      chineseDescription: '復邁注射劑',
      value: 'CADALI1',
      type: 'med',
      patientNum: 92,
      recordNum: 1734,
      field: 'med',
      ingredient: 'adalimumab',
      atcCode: 'L04AB04',
      time: 1646620145840,
    },
    {
      code: 'CADALIM',
      label: 'CADALIM',
      description: '【0.8ml】Humira【0.8ml】',
      chineseDescription: '復邁針筒裝注射液',
      value: 'CADALIM',
      type: 'med',
      patientNum: 91,
      recordNum: 1193,
      field: 'med',
      ingredient: 'adalimumab',
      atcCode: 'L04AB04',
      time: 1646620146326,
    },
    {
      code: 'CINFLIX',
      label: 'CINFLIX',
      description: 'Remsima[附過濾器]',
      chineseDescription: '類希瑪',
      value: 'CINFLIX',
      type: 'med',
      patientNum: 8,
      recordNum: 18,
      field: 'med',
      ingredient: 'infliximab',
      atcCode: 'L04AB02',
      time: 1646620162657,
    },
    {
      code: 'CGOLIMU',
      label: 'CGOLIMU',
      description: '【50】Simponi【50mg】',
      chineseDescription: '欣普尼注射液',
      value: 'CGOLIMU',
      type: 'med',
      patientNum: 64,
      recordNum: 1583,
      field: 'med',
      ingredient: 'golimumab',
      atcCode: 'L04AB06',
      time: 1646620166513,
    },
  ],
  diagnosisRange: false,
  index: 1,
};
