<template>
  <div class="corvariant-group" :class="{ 'search-group': this.flowType[0] !== 'session' }">
    <b-row no-gutters>
      <b-col>
        <div class="group-title">Covariates</div>
      </b-col>
    </b-row>
    <b-row no-gutters>
      <b-col>
        <div class="second-title mt-40px">Demographics</div>
      </b-col>
    </b-row>
    <b-row no-gutters v-if="false">
      <b-col>
        <div class="third-title mt-10px">Age</div>
        <div class="mt-10px d-flex flex-wrap">
          <div class="d-flex align-items-center" v-for="(age, index) in ageList" :key="index">
            <div
              class="d-flex align-items-center age-success-group mb-10px"
              :class="{ 'mr-10px': index < ageList.length }"
              v-if="age.status === ageStatus.success"
            >
              <div class="age-success-text" style="cursor: pointer" @click.stop="editAge(index)">
                {{ `${age.start}~${age.end}` }}
              </div>
              <b-img
                class="ml-5px"
                src="@/assets/images/icon_delete_age_range.svg"
                style="cursor: pointer"
                @click.stop="deleteAge(index)"
              />
            </div>
            <input
              class="edit-input mb-10px"
              v-model="ageRange"
              ref="editAgeRange"
              v-else-if="age.status === ageStatus.edit"
              :class="{ 'mr-10px': index < ageList.length }"
              v-click-outside="closeEditAge"
            />
            <div
              class="d-flex align-items-center age-fail-group mb-10px"
              :class="{ 'mr-10px': index < ageList.length }"
              v-if="age.status === ageStatus.fail"
            >
              <div class="d-flex" style="cursor: pointer" @click.stop="editAge(index)">
                <div :class="{ 'age-success-text': !age.startFail, 'age-fail-text': age.startFail }">
                  {{ age.start }}
                </div>
                <div class="age-tilde-text">~</div>
                <div :class="{ 'age-success-text': !age.endFail, 'age-fail-text': age.endFail }">{{ age.end }}</div>
              </div>
              <b-img
                class="ml-5px"
                src="@/assets/images/icon_delete_age_range.svg"
                style="cursor: pointer"
                @click.stop="deleteAge(index)"
              />
            </div>
          </div>
          <div class="d-flex align-items-center mb-10px" style="cursor: pointer" @click.stop="clickAddAge">
            <b-img src="@/assets/images/icon_add_age_range.svg" />
            <div class="add-age-range-text ml-5px">Add age range</div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row no-gutters>
      <b-col class="d-flex align-items-center mt-10px">
        <div class="d-flex align-items-center checkbox-group">
          <b-form-checkbox v-model="gender" value="accepted" unchecked-value="not_accepted"> </b-form-checkbox>
          <div class="second-title">Gender</div>
        </div>
        <div class="d-flex align-items-center ml-15px">
          <b-form-checkbox v-model="height" value="accepted" unchecked-value="not_accepted"> </b-form-checkbox>
          <div class="second-title">Height</div>
        </div>
        <div class="d-flex align-items-center ml-15px">
          <b-form-checkbox v-model="weight" value="accepted" unchecked-value="not_accepted"> </b-form-checkbox>
          <div class="second-title">Weight</div>
        </div>
        <div class="d-flex align-items-center ml-15px">
          <b-form-checkbox v-model="bmi" value="accepted" unchecked-value="not_accepted"> </b-form-checkbox>
          <div class="second-title">BMI</div>
        </div>
      </b-col>
    </b-row>
    <b-row no-gutters>
      <b-col>
        <div class="mt-40px" v-for="(event, key) in currentEvent.inclusionList" :key="key">
          <div class="d-flex justify-content-between align-items-end mb-10px">
            <div class="d-flex align-items-center">
              <template v-if="isEditCorvariant(key)">
                <a-tooltip
                  placement="bottom"
                  overlayClassName="edit-name-error-tooltip"
                  :visible="hasSpecialChar(SPECIAL_CHARS.COHORT_NAME, corvariantName)"
                  :get-popup-container="getPopupContainer"
                >
                  <template slot="title">
                    <div>Analysis name can’t contain any of the following charecter</div>
                    <div>[ ] : * ? / \</div>
                  </template>
                  <div class="d-flex align-items-center">
                    <input
                      v-model="corvariantName"
                      ref="editCorvariant"
                      v-click-outside="closeEditCorvariantName"
                      @keyup.enter="closeEditCorvariantName"
                    />
                  </div>
                </a-tooltip>
              </template>
              <template v-else>
                <a-tooltip
                  placement="bottom"
                  overlayClassName="covariate-name-error-tooltip"
                  :visible="
                    getNameError === `covariate-${key}` || hasSpecialChar(SPECIAL_CHARS.COHORT_NAME, event.name)
                  "
                  :get-popup-container="getPopupContainer"
                >
                  <template slot="title">
                    <div v-if="getNameError === `covariate-${key}`">Dulicate name</div>
                    <div v-else-if="hasSpecialChar(SPECIAL_CHARS.COHORT_NAME, event.name)">
                      <div>Analysis name can’t contain any of the following charecter</div>
                      <div>[ ] : * ? / \</div>
                    </div>
                  </template>
                  <div class="d-flex align-items-center">
                    <div
                      class="second-title name-block"
                      @click.stop="clickEditCorvariantName(key)"
                      :class="{ 'name-error': getNameError === `covariate-${key}` }"
                    >
                      {{ event.name }}
                    </div>
                    <b-img
                      src="@/assets/images/icon_study_edit.svg"
                      class="mid-edit ml-5px"
                      @click.stop="clickEditCorvariantName(key)"
                    />
                  </div>
                </a-tooltip>
              </template>
            </div>
            <div class="d-flex">
              <div
                class="d-flex align-items-center delete-button ml-10px"
                v-if="key !== 0"
                @click.stop="deleteInclusionEvent(key)"
              >
                <b-img src="@/assets/images/icon_delete_event.svg" />
                <div class="delete-text ml-5px">Delete</div>
              </div>
              <div class="d-flex align-items-center clear-button ml-10px" @click.stop="clearInclusionEvent(key)">
                <b-img src="@/assets/images/icon_clear_event.svg" />
                <div class="clear-text ml-5px">Clear</div>
              </div>
            </div>
          </div>
          <div class="event-block">
            <EventContainer
              :index="2"
              :eventType="EventType.INCLUSION"
              :event="event"
              :path="path.concat(['inclusionList', key])"
              :ref="`inclusion${key}`"
            />
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row no-gutters>
      <b-col class="d-flex">
        <div class="event-adder mt-40px" @click="addInclusionEvent">+ Covariate</div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import ClickOutside from 'vue-click-outside';
import EventContainer from '@/components/byEvent/event/EventContainer.vue';
import { EventSearchMixin, EventUpdateMixin, ClickMixin } from '@/mixins';
import { EventType } from '@/utils/conditions/core/Interface';
import { cloneDeep } from 'lodash';
import { defaultInclusionCovariantEvent } from '@/utils/event/initState';
import { EVENT, SPECIAL_CHARS } from '@/utils/constants';
import { hasSpecialChar, getPopupContainer } from '@/utils/util';

export default {
  name: 'CorvariantGroup',
  directives: {
    ClickOutside,
  },
  components: {
    EventContainer,
  },
  mixins: [EventSearchMixin, EventUpdateMixin, ClickMixin],
  props: {
    flowType: {
      type: Array,
      default: () => ['covariate'],
    },
  },
  computed: {
    genderListFromStore() {
      return this.currentEvent.genderList;
    },
    ageListFromStore() {
      return this.SearchStateObject.ageList;
    },
    bodyMassListFromStore() {
      return this.SearchStateObject.bodyMassList;
    },
  },
  data() {
    return {
      path: this.flowType,
      ageList: [],
      ageStatus: {
        success: 'success',
        fail: 'fail',
        edit: 'edit',
      },
      ageRange: '',
      gender: 'accepted',
      height: 'accepted',
      weight: 'accepted',
      bmi: 'accepted',
      EventType,
      corvariantName: '',
      editCorvariantIndex: -1,
      SPECIAL_CHARS,
    };
  },
  watch: {
    gender() {
      this.updateGenderList();

      this.cohortStudyTrackClick('gender');
    },
    genderListFromStore: {
      handler() {
        this.restoreGenderFromStore();
      },
      immediate: true,
    },
    ageListFromStore() {
      this.restoreAgeFromStore();
    },
    getCurrentMenu() {
      if (this.editCorvariantIndex > -1 && !this.isEditCorvariant(this.editCorvariantIndex)) {
        this.closeEditCorvariantName();
      }
    },
    bodyMassListFromStore: {
      handler() {
        this.restoreBodyMassList();
      },
      immediate: true,
    },
    height() {
      this.updateBodyMassList();
    },
    weight() {
      this.updateBodyMassList();
    },
    bmi() {
      this.updateBodyMassList();
    },
  },
  methods: {
    hasSpecialChar,
    getPopupContainer,
    restoreGenderFromStore() {
      this.currentEvent.genderList.length > 0 ? (this.gender = 'accepted') : (this.gender = 'not_accepted');
    },
    restoreAgeFromStore() {
      this.ageList = this.ageListFromStore;
      this.checkAgeRange();
    },
    clickAddAge() {
      let lastAge = 0;
      if (this.ageList.length > 0) {
        lastAge = this.ageList[this.ageList.length - 1].end + 1;
      }

      this.ageList.push({
        status: this.ageStatus.success,
        start: lastAge,
        end: lastAge + 9,
        startFail: false,
        endFail: false,
      });
      this.checkAgeRange();
      this.syncAgeListToStore();

      this.cohortStudyTrackClick('clickAddAge');
    },
    deleteAge(index) {
      this.ageList.splice(index, 1);
      this.syncAgeListToStore();

      this.cohortStudyTrackClick('deleteAge');
    },
    setLastEdit() {
      const editingIndex = this.ageList.findIndex((item) => item.status === this.ageStatus.edit);
      if (editingIndex > -1) {
        this.ageList[editingIndex].status = this.ageStatus.success;
      }
    },
    editAge(index) {
      this.setLastEdit();
      this.checkAgeRange();

      this.ageRange = `${this.ageList[index].start}~${this.ageList[index].end}`;
      this.ageList[index].status = this.ageStatus.edit;
      this.$nextTick(() => {
        this.$refs.editAgeRange[0].select();
      });

      this.cohortStudyTrackClick('editAge');
    },
    closeEditAge() {
      const editingIndex = this.ageList.findIndex((item) => item.status === this.ageStatus.edit);
      this.ageList[editingIndex].start = parseInt(this.ageRange.split('~')[0]);
      this.ageList[editingIndex].end = parseInt(this.ageRange.split('~')[1]);
      this.setLastEdit();
      this.checkAgeRange();
      this.syncAgeListToStore();
    },
    checkAgeRange() {
      this.ageList.forEach((item, index) => {
        let startFail = false;
        let endFail = false;
        this.ageList.forEach((kidItem, kidIndex) => {
          const start = Math.min(kidItem.start, kidItem.end);
          const end = Math.max(kidItem.start, kidItem.end);

          if (kidIndex !== index && item.start >= start && item.start <= end) {
            startFail = true;
          }

          if (kidIndex !== index && item.end >= start && item.end <= end) {
            endFail = true;
          }
        });

        this.ageList[index].startFail = startFail;
        this.ageList[index].endFail = endFail;
        this.ageList[index].status = startFail || endFail ? this.ageStatus.fail : this.ageStatus.success;
      });
    },
    updateCovariateState(body) {
      if (this.path[0] === 'session') {
        this.updateEventWithPath(body, this.path.slice(0, 3));
      } else {
        this.updateEventWithPath(body, this.path[0]);
      }
    },
    syncAgeListToStore() {
      const body = {
        ageList: this.ageList,
      };

      this.updateCovariateState(body);
    },
    deleteInclusionEvent(index) {
      const event = cloneDeep(this.currentEvent);
      event.inclusionList.splice(index, 1);
      this.updateEventWithPath(event, this.path);
      this.checkNameErrorWithTag(null, '');

      this.cohortStudyTrackClick('deleteInclusionEvent');
    },
    clearInclusionEvent(index) {
      this.$refs[`inclusion${index}`][0].setDefaultState();

      this.cohortStudyTrackClick('clearInclusionEvent');
    },
    addInclusionEvent() {
      const event = cloneDeep(this.currentEvent);
      event.inclusionList.push({
        ...cloneDeep(defaultInclusionCovariantEvent),
        name: `${EVENT.DEFAULT_NAME.CORVARIANT}${event.inclusionList.length + 1}`,
        eventId: new Date().getTime().toString,
      });
      this.updateEventWithPath(event, this.path);

      this.cohortStudyTrackClick('addInclusionEvent');
      this.checkNameErrorWithTag(
        `${EVENT.DEFAULT_NAME.CORVARIANT}${event.inclusionList.length + 1}`,
        `covariate-${event.inclusionList.length - 1}`
      );

      this.$nextTick(() => {
        const containerDiv = document.querySelector(
          `#${this.path.concat(['inclusionList', this.currentEvent.inclusionList.length - 1]).join('-')}-${2}-${
            EventType.INCLUSION
          }`
        );
        if (containerDiv) {
          containerDiv.scrollIntoView({
            block: 'center',
          });
        }
      });
    },
    updateGenderList() {
      let genderList = [];
      if (this.gender === 'accepted') {
        genderList = [
          {
            type: 'male',
          },
          {
            type: 'female',
          },
        ];
      }

      const body = {
        genderList,
      };

      this.updateCovariateState(body);
    },
    closeEditCorvariantName() {
      const body = {
        name: this.corvariantName,
      };

      this.updateEventWithPath(body, this.path.concat(['inclusionList', `${this.editCorvariantIndex}`]));
      this.checkNameErrorWithTag(this.corvariantName, `covariate-${this.editCorvariantIndex}`);

      this.setCurrentMenu(this.path, '');
      this.editCorvariantIndex = -1;
    },
    clickEditCorvariantName(index) {
      this.editCorvariantIndex = index;
      this.corvariantName = this.currentEvent.inclusionList[index].name;
      this.setCurrentMenu(this.path, `editCorvariantName${index}`);
      this.$nextTick(() => {
        this.$refs.editCorvariant[0].select();
      });

      this.cohortStudyTrackClick('clickEditCorvariantName');
    },
    isEditCorvariant(index) {
      return this.isCurrentMenu(this.path, `editCorvariantName${index}`);
    },
    getBodyMassTypeState(type) {
      return this.bodyMassListFromStore.some((item) => item.type === type) ? 'accepted' : 'not_accepted';
    },
    restoreBodyMassList() {
      this.height = this.getBodyMassTypeState('height');
      this.weight = this.getBodyMassTypeState('weight');
      this.bmi = this.getBodyMassTypeState('BMI');
    },
    updateBodyMassList() {
      const bodyMassList = [];
      const data = [
        { data: 'height', type: 'height' },
        { data: 'weight', type: 'weight' },
        { data: 'bmi', type: 'BMI' },
      ];
      data.forEach((item) => {
        if (this[item.data] === 'accepted') {
          bodyMassList.push({ type: item.type });
        }
      });
      this.updateCovariateState({
        bodyMassList,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.corvariant-group {
  background: #fafafa;
  padding: 40px 40px;
  min-height: calc(100vh - 126px);
}

.search-group {
  box-shadow: 0px 4px 16px #e0e0e0, 0px 16px 16px rgba(157, 205, 245, 0.1);
  border-radius: 8px 0px 0px 8px;
  padding: 40px 40px 550px 40px;
}

.group-title {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: #334e97;
}

.second-title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #334e97;
}

.third-title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #334e97;
}

.add-age-range-text {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #757575;
}

.age-success-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #212121;
}

.age-success-group {
  padding: 2px 4px;
  background: #e6f3fc;
  border-radius: 4px;
}

.edit-input {
  height: 23px;
  width: 80px;
}

.age-fail-group {
  padding: 2px 4px;
  background: #fcecef;
  border: 1px solid #d65c51;
  box-sizing: border-box;
  border-radius: 4px;
}

.age-fail-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #c8544d;
}

.age-tilde-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #9e9e9e;
}

.second-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #212121;
}

.mid-edit {
  width: 12px;
  height: 12px;
  cursor: pointer;
}

.clear-button {
  border: 1px solid #4d8edc;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
}

.clear-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #4d8edc;
}

.delete-button {
  border: 1px solid #d65c51;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
}

.delete-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #d65c51;
}

.event-adder {
  padding: 4px 8px;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  height: 28px;
  background: #4d8edc;
  border-radius: 4px;
  cursor: pointer;
}

.name-block {
  cursor: pointer;
}

.name-error {
  border: 2px solid #d65c51;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 2px;
}

.checkbox-group {
  .custom-control {
    min-height: initial;
  }
}
</style>
<style lang="scss">
.covariate-name-error-tooltip {
  .ant-tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 5px 6px 5px;
    border-color: transparent transparent #d65c51 transparent;
    margin-top: 7px;
  }
  .ant-tooltip-inner {
    background: #d65c51;
    border-radius: 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
  }
}
</style>
