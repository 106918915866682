import { CHART } from '../constants';
import ABNORMALLAB from './AbnormalLabDef';
import AGE from './AgeDef';
import DIAGNOSIS from './DiagnosisDef';
import GENDER from './GenderDef';
import MEDICATION from './MedicationDef';
import ATC_CODE from './ATCCodeDef';
import PROCEDURE from './ProcedureDef';
import STAY from './StayDef';
import STAYRANGE from './StayRangeDef';
import TIME from './TimeDef';
import SYMPTOM from './SymptomDef';
import CHARTNO from './ChartNorDef';
import KEYWORD from './textDef/KeywordDef';
import RISK from './RiskDef';
import LAB from './LabDef';
import SECTION from './SectionDef';
import VISIT from './VisitDef';
import MEDICAL_ORDER from './MedicalOrderDef';
import PCS_CODE from './PCSCodeDef';
import DOCTOR_ID from './DoctorIdDef';
import DRG_CODE from './DRGCodeDef';
import DISCHARGE_STATUS from './DischargeStatusDef';
import PATIENT_SOURCE from './PatientSourceDef';
import BMI from './BMIDef';
import READMISSION from './ReadmissionDef';
import REOPDE from './ReOPDEDef';
import HABIT from './HabitDef';

export {
  ABNORMALLAB,
  AGE,
  DIAGNOSIS,
  GENDER,
  MEDICATION,
  ATC_CODE,
  PROCEDURE,
  STAY,
  STAYRANGE,
  TIME,
  SYMPTOM,
  CHARTNO,
  KEYWORD,
  RISK,
  LAB,
  SECTION,
  VISIT,
  MEDICAL_ORDER,
  PCS_CODE,
  DOCTOR_ID,
  DRG_CODE,
  DISCHARGE_STATUS,
  PATIENT_SOURCE,
  BMI,
  READMISSION,
  REOPDE,
  HABIT,
};

export const FIELD_DEF = {
  ABNORMAL_LIST: ABNORMALLAB,
  AGE,
  ICD_ENG_FULL: DIAGNOSIS,
  ICD9_ENG_FULL: DIAGNOSIS,
  ICD_20_ENG_FULL: DIAGNOSIS,
  ICD9_20_ENG_FULL: DIAGNOSIS,
  ICD_MAIN_ENG_FULL: DIAGNOSIS,
  ICD9_MAIN_ENG_FULL: DIAGNOSIS,
  PROCEDURE_INS_FULL: PROCEDURE,
  PROCEDURE_INS_CODE: PROCEDURE,
  PROCEDURE_INS_NAME: PROCEDURE,
  MEDICATION_FULL: MEDICATION,
  MEDICATION_NAME: MEDICATION,
  MEDICATION_CODE: MEDICATION,
  MEDICATION_NEW_CODE: MEDICATION,
  MEDICATION_NEW_FULL: MEDICATION,
  MEDICATION_NEW_NAME: MEDICATION,
  ATC_CODE,
  SYMPTOM_NAME: SYMPTOM,
  SEX: GENDER,
  MEDICATION,
  PROCEDURE,
  DURATION: STAY,
  STAYRANGE,
  TIME,
  SYMPTOM,
  CHARTNO,
  KEYWORD,
  RISK,
  SECTION_NAME: SECTION,
  VISIT,
  MEDICAL_ORDER,
  PROCEDURE_PCS_CODE: PCS_CODE,
  PROCEDURE_PCS_NAME: PCS_CODE,
  PROCEDURE_PCS_FULL: PCS_CODE,
  DOCTOR_ID,
  TWDRG_CODE: DRG_CODE,
  TURN_NAME: DISCHARGE_STATUS,
  PATIENT_SOURCE_NAME: PATIENT_SOURCE,
  BMI_CATEGORY: BMI,
  READMISSION,
  REOPDE,
  HABIT,
};

export const CONDITIONS = {
  ABNORMALLAB: 'ABNORMALLAB',
  AGE: 'AGE',
  DIAGNOSIS: 'DIAGNOSIS',
  GENDER: 'GENDER',
  MEDICATION: 'MEDICATION',
  ATC_CODE: 'ATC_CODE',
  PROCEDURE: 'PROCEDURE',
  STAY: 'STAY',
  STAYRANGE: 'STAYRANGE',
  TIME: 'TIME',
  SYMPTOM: 'SYMPTOM',
  CHARTNO: 'CHARTNO',
  KEYWORD: 'KEYWORD',
  RISK: 'RISK',
  LAB: 'LAB',
  SECTION: 'SECTION',
  VISIT: 'VISIT',
  MEDICAL_ORDER: 'MEDICAL_ORDER',
  PCS_CODE: 'PCS_CODE',
  DOCTOR_ID: 'DOCTOR_ID',
  DRG_CODE: 'DRG_CODE',
  DISCHARGE_STATUS: 'DISCHARGE_STATUS',
  PATIENT_SOURCE: 'PATIENT_SOURCE',
  BMI: 'BMI',
  READMISSION: 'READMISSION',
  REOPDE: 'REOPDE',
  HABIT: 'HABIT',
};

export const EXTEND_MAPPING = {
  [CHART.TITLE.AGE]: 'AGE',
  [CHART.TITLE.GENDER]: 'GENDER',
  [CHART.TITLE.ICD9]: 'DIAGNOSIS',
  [CHART.TITLE.SECTION]: 'SECTION',
  [CHART.TITLE.MEDICATION]: 'MEDICATION',
  [CHART.TITLE.ATC_CODE]: 'ATC_CODE',
  [CHART.TITLE.DIAGNOSIS]: 'DIAGNOSIS',
  [CHART.TITLE.LENGTH_OF_STAY]: 'STAYRANGE',
  [CHART.TITLE.PROCEDURE]: 'PROCEDURE',
  [CHART.TITLE.SYMPTOM]: 'SYMPTOM',
  [CHART.TITLE.RISK_LEVEL]: 'RISK',
  [CHART.TITLE.ABNORMAL_LAB]: 'ABNORMALLAB',
  [CHART.TITLE.VISTI]: 'VISTI',
  [CHART.TITLE.MEDICAL_ORDER]: 'MEDICAL_ORDER',
  [CHART.TITLE.PCS_CODE]: 'PCS_CODE',
  [CHART.TITLE.DRG_CODE]: 'DRG_CODE',
  [CHART.TITLE.DISCHARGE_STATUS]: 'DISCHARGE_STATUS',
  [CHART.TITLE.PATIENT_SOURCE]: 'PATIENT_SOURCE',
  [CHART.TITLE.BMI]: 'BMI',
  [CHART.TITLE.READMISSION]: 'READMISSION',
  [CHART.TITLE.REOPDE]: 'REOPDE',
  [CHART.TITLE.HABIT]: 'HABIT',
};
