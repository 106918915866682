<template>
  <div class="service-tab">
    <div
      v-for="page of pages"
      :key="page.url"
      @click="clickFixedNav(page)"
      style="width: 88px; height: 88px"
      class="d-flex align-items-center justify-content-center"
    >
      <b-img
        src="@/assets/images/icon_cohort_study.svg"
        class="nav-icon"
        v-if="page.id === 'cohort-study' && $route.path !== page.path"
        :title="page.title"
      />
      <b-img
        src="@/assets/images/icon_cohort_study_active.svg"
        class="nav-icon"
        v-else-if="page.id === 'cohort-study' && $route.path === page.path"
        :title="page.title"
      />
      <b-img
        src="@/assets/images/icon_by_record.svg"
        class="nav-icon"
        v-else-if="page.id === 'emr-search' && $route.path !== page.path"
        :title="page.title"
      />
      <b-img
        src="@/assets/images/icon_by_record_active.svg"
        class="nav-icon"
        v-else-if="page.id === 'emr-search' && $route.path === page.path"
        :title="page.title"
      />
      <b-img
        src="@/assets/images/icon_by_event.svg"
        class="nav-icon"
        v-else-if="page.id === 'by-event' && $route.path !== page.path"
        :title="page.title"
      />
      <b-img
        src="@/assets/images/icon_by_event_active.svg"
        class="nav-icon"
        v-else-if="page.id === 'by-event' && $route.path === page.path"
        :title="page.title"
      />
    </div>
  </div>
</template>
<script>
import { ClickMixin } from '@/mixins';
import { getUiConfig } from '@/utils/uiConfig';

export default {
  name: 'ServiceTab',
  mixins: [ClickMixin],
  data() {
    return {
      cohortStudyEnabled: false,
      byEventEnabled: false,
    };
  },
  mounted() {
    this.changeCohortStudyEnabled();
    this.changeByEventEnabled();
    window.addEventListener('uiConfigChange', () => {
      // uiConfigChange not reactive
      // So you need to use the uiConfigChange event to trigger navTab to display
      this.changeCohortStudyEnabled();
      this.changeByEventEnabled();
      if (this.cohortStudyEnabled && !window.location.href.includes('url')) {
        // router/index.js redirect init redirect is /emr-search
        // So you need to use the uiConfigChange event to trigger route to /cohort-study
        this.$router.push('/cohort-study');
      }
    });
  },
  destroyed() {
    window.removeEventListener('uiConfigChange', this.changeCohortStudyEnabled());
    window.removeEventListener('uiConfigChange', this.changeByEventEnabled());
  },
  computed: {
    pages() {
      const result = [];

      if (this.cohortStudyEnabled) {
        result.push({
          id: 'cohort-study',
          title: 'Cohort Study',
          path: '/cohort-study',
          icon: 'icon-cohort-study',
        });
      }

      result.push({
        id: 'emr-search',
        title: 'EMR Search',
        path: '/emr-search',
        icon: 'icon-by-record',
      });

      if (this.byEventEnabled) {
        result.push({
          id: 'by-event',
          title: 'By Event',
          path: '/by-event',
          icon: 'icon-by-event',
        });
      }
      return result;
    },
  },
  methods: {
    clickFixedNav(page) {
      this.trackClick('clickNav', { id: page.id });
      this.$router.push(page.path);
    },
    changeCohortStudyEnabled() {
      this.cohortStudyEnabled = getUiConfig().SearchForm.cohortStudy;
    },
    changeByEventEnabled() {
      this.byEventEnabled = getUiConfig().SearchForm.byEvent;
    },
  },
};
</script>
<style lang="scss" scoped>
.service-tab {
  width: 88px;
  max-width: 88px;
  min-width: 88px;
}

.nav-icon {
  cursor: pointer;
}
</style>
