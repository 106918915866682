<template>
  <a-modal
    :visible="EMRSearchLandingIndex === 1"
    :mask="true"
    :destroyOnClose="true"
    :footer="null"
    :zIndex="9999"
    :closable="false"
    :maskClosable="false"
    centered
  >
    <div class="landing-title-text">歡迎使用 EMR Search 全新搜尋介面</div>
    <div class="landing-content-text mt-40px">
      接下來介紹如何設定搜尋 criteria 還有儲存 concept， 只需不到 1 分鐘的時間
    </div>
    <div class="go-button mt-40px" @click="clickGo">開始教學</div>
  </a-modal>
</template>
<script>
import { mapState, mapMutations } from '@/store';
import ConditionList from '@/utils/ConditionList';
import { DiagnosisRanges, DiagnosisType } from '@/utils/conditions/core';
import { cloneDeep } from 'lodash';
import { VUE_EVENT_NAME } from '@/utils/constants';
import { getAntiTNFBiologicsConfig } from '@/utils/landingConfig/';
import InflammatoryDiseases from '@/utils//landingConfig/Inflammatory-Diseases';

export default {
  name: 'WelcomeEMRSearchModal',
  computed: {
    ...mapState(['EMRSearchLandingIndex']),
  },
  methods: {
    ...mapMutations(['setEMRSearchLandingIndex']),
    ...mapMutations('condition', ['setCondition', 'setExcludedCondition']),
    async clickGo() {
      this.setEMRSearchLandingIndex(2);

      const diagnosis = cloneDeep(ConditionList.diagnosis);
      diagnosis.tags.push(DiagnosisRanges.ALL);
      diagnosis.tags.push(DiagnosisType.GroupAndMap);
      diagnosis.diagnosisRange = false;
      const condition = [
        { ...ConditionList.time },
        {
          conceptName: 'Inflammatory diseases (RA + PSO + PSA + SLE)',
          conceptType: 'include',
          index: 0,
          ...diagnosis,
          time: Date.now(),
          list: InflammatoryDiseases.list,
        },
      ];
      // set default condition
      this.setCondition(condition);
      this.setExcludedCondition([]);

      // set default concept
      await this.$api.saveQuery(
        'Anti-TNF Biologics',
        { ...getAntiTNFBiologicsConfig(), time: Date.now() },
        'saveConcept'
      );

      this.$root.$emit(VUE_EVENT_NAME.REFRESH_EMR_SEARCH_CONCEPT_LIST);
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .ant-modal-body {
  padding: 40px;
  background: #ffffff;
  border-radius: 16px;
  width: 520px;
}

/deep/ .ant-modal-content {
  border-radius: 16px;
}

.landing-title-text {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #212121;
}

.landing-content-text {
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #000000;
}

.go-button {
  width: 440px;
  padding: 12px 16px;
  background: #0074e6;
  border-radius: 8px;
  text-align: center;
  color: #ffffff;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  cursor: pointer;
}
</style>
