<template>
  <div
    class="d-flex flex-wrap align-items-center constraint-capsule"
    @click.stop=""
    title="* If selected medications are on one prescription, the days will be counted multiple times."
  >
    <div class="constraint-text">{{ constraintText }}</div>
    <a-select
      @change="changeOperator"
      :getPopupContainer="(trigger) => trigger.parentNode"
      class="constraint-select ml-5px mr-5px"
      default-value="gte"
      v-model="operator"
    >
      <a-icon slot="suffixIcon" type="caret-down" />
      <a-select-option value="gte"> >=</a-select-option>
      <a-select-option value="lte"> {{ lessThanString }} </a-select-option>
    </a-select>
    <a-input
      placeholder="1"
      type="number"
      style="width: 70px"
      v-model="drugDay"
      :controls="false"
      @change="changeDrugDay"
      class="mr-5px"
      min="1"
      :max="drugDayMaxLimit"
    />
    <div class="constraint-text">{{ constraintUnit }}</div>
    <template v-if="eventType === EventType.FIX_EVENT">
      <div class="constraint-text ml-5px mr-5px">within</div>
      <a-select
        v-model="timeRange"
        style="width: 70px"
        @change="changeTimeRange"
        :getPopupContainer="(trigger) => trigger.parentNode"
        class="constraint-select ml-5px mr-5px"
        default-value="any"
      >
        <a-icon slot="suffixIcon" type="caret-down" />
        <a-select-option value="any"> any</a-select-option>
        <a-select-option v-for="index in timeMaxRange[timeUnit]" :key="index" :value="index">
          {{ index }}
        </a-select-option>
      </a-select>
      <a-select
        @change="changeTimeUnit"
        :getPopupContainer="(trigger) => trigger.parentNode"
        class="constraint-select ml-5px"
        :default-value="timeUnitOptions[0]"
        v-model="timeUnit"
      >
        <a-icon slot="suffixIcon" type="caret-down" />
        <a-select-option v-for="option in timeUnitOptions" :value="option.value" :key="option.value">
          {{ option.label }}
        </a-select-option>
      </a-select>
    </template>
    <b-img
      src="@/assets/images/icon_remove_blue.svg"
      @click="deleteConstraint(constraintType)"
      class="delete-button ml-10px"
    />
  </div>
</template>
<script>
import { EventType } from '@/utils/conditions/core/Interface';
import ConstraintType from '@/utils/conditions/core/advance/constraint/Interface';
import { EventSearchMixin, EventUpdateMixin, ClickMixin } from '@/mixins';
import { findIndex, cloneDeep } from 'lodash';
export default {
  name: 'MedicationTotalConstraint',
  data() {
    return {
      EventType,
      timeUnitOptions: [
        { label: 'days', value: 'days' },
        { label: 'months', value: 'months' },
        { label: 'years', value: 'years' },
      ],
      timeMaxRange: {
        days: 28,
        months: 12,
        years: 5,
      },
      drugDay: 1,
      timeRange: 'any',
      timeUnit: 'days',
      operator: 'gte',
      lessThanString: '<=',
    };
  },
  mixins: [EventSearchMixin, EventUpdateMixin, ClickMixin],
  props: {
    index: {
      type: Number,
      default: 1,
    },
    data: {
      type: Object,
    },
    eventType: {
      type: String,
      default: EventType.FIX_EVENT,
    },
    constraintType: {
      type: String,
      validator(value) {
        return [
          ConstraintType.MedicationTotalConstraint,
          ConstraintType.MedicationDoseTimesConstraint,
          ConstraintType.MedicationPrescriptionTimesConstraint,
        ].includes(value);
      },
    },
    path: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    getConstraintFromStore() {
      this.syncToLocal();
    },
  },
  mounted() {
    this.syncToLocal();
  },
  computed: {
    getConstraintFromStore() {
      const { event, constraintIndex } = this.getMedicationTotalConstraint();
      return event.constraintList[constraintIndex];
    },
    drugDayMaxLimit() {
      switch (this.constraintType) {
        case ConstraintType.MedicationPrescriptionTimesConstraint:
          return 10;
        case ConstraintType.MedicationDoseTimesConstraint:
          return 50;
        case ConstraintType.MedicationTotalConstraint:
        default:
          if (this.timeRange === 'any' || this.timeUnit !== 'days') {
            return 30;
          } else {
            return this.timeRange;
          }
      }
    },
    drugDayKeyMapping() {
      switch (this.constraintType) {
        case ConstraintType.MedicationPrescriptionTimesConstraint:
          return 'prescriptionTimes';
        case ConstraintType.MedicationDoseTimesConstraint:
          return 'drugDay';
        case ConstraintType.MedicationTotalConstraint:
        default:
          return 'drugDay';
      }
    },
    constraintUnit() {
      switch (this.constraintType) {
        case ConstraintType.MedicationPrescriptionTimesConstraint:
        case ConstraintType.MedicationDoseTimesConstraint:
          return 'times';
        case ConstraintType.MedicationTotalConstraint:
        default:
          return 'days';
      }
    },
    constraintText() {
      switch (this.constraintType) {
        case ConstraintType.MedicationPrescriptionTimesConstraint:
          return 'Total Drug Prescribing Times';
        case ConstraintType.MedicationDoseTimesConstraint:
          return 'Total Drug Dose';
        case ConstraintType.MedicationTotalConstraint:
        default:
          return 'Total Drug Used Days';
      }
    },
  },
  methods: {
    syncToLocal() {
      const { event, constraintIndex } = this.getMedicationTotalConstraint();
      this.drugDay = cloneDeep(event.constraintList[constraintIndex][this.drugDayKeyMapping]);
      this.timeRange = cloneDeep(event.constraintList[constraintIndex].timeRange);
      this.timeUnit = cloneDeep(event.constraintList[constraintIndex].timeUnit);
      this.operator = cloneDeep(event.constraintList[constraintIndex].operator);
    },
    getMedicationTotalConstraint() {
      const event = this.getEventDataByPath(this.path);
      const constraintIndex = findIndex(event.constraintList, {
        constraintType: this.constraintType,
      });
      return { event, constraintIndex };
    },
    changeMedicationTotalConstraint(key, value) {
      const { event, constraintIndex } = this.getMedicationTotalConstraint();
      event.constraintList[constraintIndex][key] = value;
      const body = {
        constraintList: event.constraintList,
      };
      this.updateEventWithPath(body, this.path);
    },
    changeDrugDay() {
      if (this.drugDay < 1) {
        this.drugDay = 1;
      } else if (this.drugDay > this.drugDayMaxLimit) {
        this.drugDay = this.drugDayMaxLimit;
      }
      this.changeMedicationTotalConstraint(this.drugDayKeyMapping, this.drugDay);
    },
    changeTimeRange() {
      this.changeDrugDay();
      this.changeMedicationTotalConstraint('timeRange', this.timeRange);
    },
    changeTimeUnit() {
      this.changeMedicationTotalConstraint('timeUnit', this.timeUnit);

      this.timeRange = 'any';
      this.changeTimeRange();
    },
    changeOperator() {
      this.changeMedicationTotalConstraint('operator', this.operator);
    },
  },
};
</script>
<style lang="scss" scoped>
.constraint-capsule {
  padding: 5px 10px;
  background: #edf5fb;
  border-radius: 100px;
  margin-left: 10px;
}

.constraint-text {
  height: 18px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #333333;
}

.constraint-select {
  height: 26px;
}

.delete-button {
  cursor: pointer;
  size: 13px;
}

/deep/ .ant-select-selection--single {
  border-radius: 53px;
  border: 1px solid #e0e0e0;
  height: 26px;
}

/deep/ .ant-select-selection-selected-value {
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #334e97;
}

/deep/ .ant-select-arrow {
  color: #334e97;
}
</style>
