<template>
  <div>
    <div v-if="noResult" class="no-result">
      <div class="characteristics-title-text ml-20px mt-20px" style="position: absolute">Characteristics</div>
      <div class="no-result-wrapper">
        <div class="mb-10px">
          <b-img src="@/assets/images/icon_no_results.svg" />
        </div>
        <div class="no-results-text">No results</div>
        <div class="d-flex align-items-center edit-button mt-40px" @click="clickEditCovariate">
          <b-img src="@/assets/images/icon_edit_event_result_white.svg" class="mr-5px" />
          <div class="edit-text">Adjust Covariate</div>
        </div>
      </div>
    </div>
    <div v-else-if="loading" class="no-result">
      <div class="characteristics-title-text ml-20px mt-20px" style="position: absolute">Characteristics</div>
      <div class="no-result-wrapper">
        <a-progress
          type="circle"
          :percent="progressPercent"
          :width="70"
          :strokeWidth="8"
          strokeColor="#4D8EDC"
          trailColor="#E0E0E0"
        >
          <template #format="percent">
            <span class="progress-text">{{ `${percent}%` }}</span>
          </template></a-progress
        >
        <div class="progress-text mt-10px" v-if="requestInProgress">Analyzing...</div>
        <div class="progress-text mt-10px" v-else>Queuing...</div>
      </div>
    </div>
    <div class="characteristics-table" :class="{ 'characteristics-table-view-more': showViewMore }" v-else>
      <b-row no-gutters>
        <b-col>
          <div class="characteristics-title-text">Characteristics</div>
        </b-col>
      </b-row>
      <b-row no-gutters class="mt-10px">
        <b-col>
          <b-row no-gutters class="header-row">
            <b-col></b-col>
            <b-col class="header-text text-center">{{ experimentalName }}</b-col>
            <b-col class="header-text text-center">{{ controlName }}</b-col>
            <b-col class="header-text text-center">P Value</b-col>
          </b-row>
          <b-row no-gutters class="content-row mt-10px">
            <b-col class="left-title">Patient count</b-col>
            <b-col class="content-text text-center">{{ patientCount.experimental }}</b-col>
            <b-col class="content-text text-center">{{ patientCount.control }}</b-col>
            <b-col class="content-text text-center null-text">-</b-col>
          </b-row>
          <b-row no-gutters>
            <b-col cols="9">
              <b-row
                no-gutters
                v-for="(genderContent, index) in gender.data"
                :key="`${index}-gender`"
                :class="{ 'border-top': index === 0 }"
                class="content-row"
              >
                <b-col>
                  <b-row no-gutters>
                    <b-col class="left-subtitle mr-5px" v-if="index === 0">Gender</b-col>
                    <b-col class="left-subtitle mr-5px" v-else></b-col>
                    <b-col class="left-title">{{ genderContent.title }}</b-col>
                  </b-row>
                </b-col>
                <b-col class="content-text text-center">{{ genderContent.experimental }}</b-col>
                <b-col class="content-text text-center">{{ genderContent.control }}</b-col>
              </b-row>
            </b-col>
            <b-col
              cols="3"
              class="content-text text-center d-flex align-items-center justify-content-center"
              :class="{ 'border-top': gender.data.length > 0 }"
            >
              <div>{{ gender.pValue }}</div>
            </b-col>
          </b-row>
          <b-row no-gutters class="content-row border-top">
            <b-col class="left-title">Age (Mean±SD)</b-col>
            <b-col class="content-text text-center">{{ age.experimental }}</b-col>
            <b-col class="content-text text-center">{{ age.control }}</b-col>
            <b-col class="content-text text-center">{{ age.pValue }}</b-col>
          </b-row>
          <b-row no-gutters>
            <b-col>
              <b-row
                no-gutters
                v-for="(heightContent, index) in height.data"
                :key="`${index}-height`"
                :class="{ 'border-top': index === 0 }"
                class="content-row"
              >
                <b-col cols="9">
                  <b-row no-gutters>
                    <b-col>
                      <b-row no-gutters>
                        <b-col class="left-subtitle mr-5px" v-if="index === 0">Height</b-col>
                        <b-col class="left-subtitle mr-5px" v-else></b-col>
                        <b-col class="left-title">{{ heightContent.title }}</b-col>
                      </b-row>
                    </b-col>
                    <b-col class="content-text text-center">{{ heightContent.experimental }}</b-col>
                    <b-col class="content-text text-center">{{ heightContent.control }}</b-col>
                  </b-row>
                </b-col>
                <b-col cols="3" class="content-text text-center d-flex align-items-start justify-content-center">
                  <div v-if="height.pValue && index === 0">{{ height.pValue }}</div>
                  <div v-else-if="index === 1" class="null-text">-</div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row no-gutters>
            <b-col>
              <b-row
                no-gutters
                v-for="(weightContent, index) in weight.data"
                :key="`${index}-height`"
                :class="{ 'border-top': index === 0 }"
                class="content-row"
              >
                <b-col cols="9">
                  <b-row no-gutters>
                    <b-col>
                      <b-row no-gutters>
                        <b-col class="left-subtitle mr-5px" v-if="index === 0">Weight</b-col>
                        <b-col class="left-subtitle mr-5px" v-else></b-col>
                        <b-col class="left-title">{{ weightContent.title }}</b-col>
                      </b-row>
                    </b-col>
                    <b-col class="content-text text-center">{{ weightContent.experimental }}</b-col>
                    <b-col class="content-text text-center">{{ weightContent.control }}</b-col>
                  </b-row>
                </b-col>
                <b-col cols="3" class="content-text text-center d-flex align-items-start justify-content-center">
                  <div v-if="weight.pValue && index === 0">{{ weight.pValue }}</div>
                  <div v-else-if="index === 1" class="null-text">-</div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row no-gutters>
            <b-col>
              <b-row
                no-gutters
                v-for="(bmiContent, index) in bmi.data"
                :key="`${index}-height`"
                :class="{ 'border-top': index === 0 }"
                class="content-row"
              >
                <b-col cols="9">
                  <b-row no-gutters>
                    <b-col>
                      <b-row no-gutters>
                        <b-col class="left-subtitle mr-5px" v-if="index === 0">BMI</b-col>
                        <b-col class="left-subtitle mr-5px" v-else></b-col>
                        <b-col class="left-title">{{ bmiContent.title }}</b-col>
                      </b-row>
                    </b-col>
                    <b-col class="content-text text-center">{{ bmiContent.experimental }}</b-col>
                    <b-col class="content-text text-center">{{ bmiContent.control }}</b-col>
                  </b-row>
                </b-col>
                <b-col cols="3" class="content-text text-center d-flex align-items-start justify-content-center">
                  <div v-if="bmi.pValue && index === 0">{{ bmi.pValue }}</div>
                  <div v-else-if="index === 1" class="null-text">-</div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row
            no-gutters
            class="content-row border-top"
            v-for="(covariantContent, index) in covariate.slice(0, VIEW_MORE_THRESHOLD + 1)"
            :key="`${index}-covariate`"
            :class="{ 'border-bottom': index === covariate.length - 1 }"
          >
            <template v-if="covariantContent.type === 'lab'">
              <b-col>
                <b-row no-gutters>
                  <b-col cols="9">
                    <b-row no-gutters class="content-row">
                      <b-col>
                        <b-row no-gutters>
                          <b-col class="left-subtitle mr-5px" :title="covariantContent.title">{{
                            covariantContent.title
                          }}</b-col>
                          <b-col class="left-title">Mean±SD</b-col>
                        </b-row>
                      </b-col>
                      <b-col class="content-text text-center">{{ covariantContent.experimentalMean }}</b-col>
                      <b-col class="content-text text-center">{{ covariantContent.controlMean }}</b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="3" class="content-text text-center d-flex align-items-center justify-content-center">
                    <div>{{ covariantContent.pValue }}</div>
                  </b-col>
                </b-row>
                <b-row no-gutters>
                  <b-col cols="9">
                    <b-row no-gutters class="content-row">
                      <b-col>
                        <b-row no-gutters>
                          <b-col class="left-subtitle mr-5px"> </b-col>
                          <b-col class="left-title" :title="getLabText(covariantContent.title)">
                            {{ getLabText(covariantContent.title) }}
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col class="content-text text-center">{{ covariantContent.experimentalPatientCount }}</b-col>
                      <b-col class="content-text text-center">{{ covariantContent.controlPatientCount }}</b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="3" class="content-text text-center d-flex align-items-center justify-content-center">
                    <div class="null-text">-</div>
                  </b-col>
                </b-row>
                <b-row no-gutters>
                  <b-col cols="9">
                    <b-row no-gutters class="content-row">
                      <b-col>
                        <b-row no-gutters>
                          <b-col class="left-subtitle mr-5px"></b-col>
                          <b-col class="left-title">Missing</b-col>
                        </b-row>
                      </b-col>
                      <b-col class="content-text text-center">{{ covariantContent.experimentalMissing }}</b-col>
                      <b-col class="content-text text-center">{{ covariantContent.controlMissing }}</b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="3" class="content-text text-center d-flex align-items-center justify-content-center">
                    <div class="null-text">-</div>
                  </b-col>
                </b-row>
              </b-col>
            </template>
            <template v-else>
              <b-col class="left-title" :title="covariantContent.title">{{ covariantContent.title }}</b-col>
              <b-col class="content-text text-center">{{ covariantContent.experimental }}</b-col>
              <b-col class="content-text text-center">{{ covariantContent.control }}</b-col>
              <b-col class="content-text text-center">{{ covariantContent.pValue }}</b-col>
            </template>
          </b-row>
          <div class="cover-view-more" v-if="showViewMore">
            <div class="gradient-cover"></div>
            <div class="view-more-text" @click="clickViewMore">View more</div>
            <div class="hint-text">*People with missing age or missing gender were excluded.</div>
            <div class="hint-text-major">
              *Lumos uses t-test for continuous variables and Pearson's chi-squared test for categorical variables
            </div>
            <div class="hint-text-major">
              *Lab covariates calculate the mean and standard deviation for continuous data only
            </div>
          </div>
          <div class="hint-text" v-if="!showViewMore">*People with missing age or missing gender were excluded.</div>
          <div class="hint-text-major" v-if="!showViewMore">
            *Lumos uses t-test for continuous variables and Pearson's chi-squared test for categorical variables
          </div>
          <div class="hint-text-major" v-if="!showViewMore">
            *Lab covariates calculate the mean and standard deviation for continuous data only
          </div>
        </b-col>
      </b-row>
    </div>
    <div>
      <a-modal
        v-model="visible"
        class="view-all-modal"
        destroyOnClose
        :footer="null"
        centered
        width="1000px"
        :zIndex="9999"
      >
        <template #closeIcon>
          <img src="@/../public/img/icon_close.svg" alt="icon_close" />
        </template>
        <template #title>
          <div class="title">Characteristics</div>
        </template>
        <b-row no-gutters>
          <b-col>
            <b-row no-gutters class="header-row">
              <b-col></b-col>
              <b-col class="header-text text-center">{{ experimentalName }}</b-col>
              <b-col class="header-text text-center">{{ controlName }}</b-col>
              <b-col class="header-text text-center">P Value</b-col>
            </b-row>
            <b-row no-gutters class="content-row mt-10px">
              <b-col class="left-title">Patient count</b-col>
              <b-col class="content-text text-center">{{ patientCount.experimental }}</b-col>
              <b-col class="content-text text-center">{{ patientCount.control }}</b-col>
              <b-col class="content-text text-center null-text">-</b-col>
            </b-row>
            <b-row no-gutters>
              <b-col cols="9">
                <b-row
                  no-gutters
                  v-for="(genderContent, index) in gender.data"
                  :key="`${index}-gender`"
                  :class="{ 'border-top': index === 0 }"
                  class="content-row"
                >
                  <b-col>
                    <b-row no-gutters>
                      <b-col class="left-subtitle mr-5px" v-if="index === 0">Gender</b-col>
                      <b-col class="left-subtitle mr-5px" v-else></b-col>
                      <b-col class="left-title">{{ genderContent.title }}</b-col>
                    </b-row>
                  </b-col>
                  <b-col class="content-text text-center">{{ genderContent.experimental }}</b-col>
                  <b-col class="content-text text-center">{{ genderContent.control }}</b-col>
                </b-row>
              </b-col>
              <b-col
                cols="3"
                class="content-text text-center border-top d-flex align-items-center justify-content-center"
              >
                <div>{{ gender.pValue }}</div>
              </b-col>
            </b-row>
            <b-row no-gutters class="content-row border-top">
              <b-col class="left-title">Age (Mean±SD)</b-col>
              <b-col class="content-text text-center">{{ age.experimental }}</b-col>
              <b-col class="content-text text-center">{{ age.control }}</b-col>
              <b-col class="content-text text-center">{{ age.pValue }}</b-col>
            </b-row>
            <b-row no-gutters>
              <b-col>
                <b-row
                  no-gutters
                  v-for="(heightContent, index) in height.data"
                  :key="`${index}-height`"
                  :class="{ 'border-top': index === 0 }"
                  class="content-row"
                >
                  <b-col cols="9">
                    <b-row no-gutters>
                      <b-col>
                        <b-row no-gutters>
                          <b-col class="left-subtitle mr-5px" v-if="index === 0">Height</b-col>
                          <b-col class="left-subtitle mr-5px" v-else></b-col>
                          <b-col class="left-title">{{ heightContent.title }}</b-col>
                        </b-row>
                      </b-col>
                      <b-col class="content-text text-center">{{ heightContent.experimental }}</b-col>
                      <b-col class="content-text text-center">{{ heightContent.control }}</b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="3" class="content-text text-center d-flex align-items-start justify-content-center">
                    <div v-if="height.pValue && index === 0">{{ height.pValue }}</div>
                    <div v-else-if="index === 1" class="null-text">-</div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row no-gutters>
              <b-col>
                <b-row
                  no-gutters
                  v-for="(weightContent, index) in weight.data"
                  :key="`${index}-height`"
                  :class="{ 'border-top': index === 0 }"
                  class="content-row"
                >
                  <b-col cols="9">
                    <b-row no-gutters>
                      <b-col>
                        <b-row no-gutters>
                          <b-col class="left-subtitle mr-5px" v-if="index === 0">Weight</b-col>
                          <b-col class="left-subtitle mr-5px" v-else></b-col>
                          <b-col class="left-title">{{ weightContent.title }}</b-col>
                        </b-row>
                      </b-col>
                      <b-col class="content-text text-center">{{ weightContent.experimental }}</b-col>
                      <b-col class="content-text text-center">{{ weightContent.control }}</b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="3" class="content-text text-center d-flex align-items-start justify-content-center">
                    <div v-if="weight.pValue && index === 0">{{ weight.pValue }}</div>
                    <div v-else-if="index === 1" class="null-text">-</div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row no-gutters>
              <b-col>
                <b-row
                  no-gutters
                  v-for="(bmiContent, index) in bmi.data"
                  :key="`${index}-height`"
                  :class="{ 'border-top': index === 0 }"
                  class="content-row"
                >
                  <b-col cols="9">
                    <b-row no-gutters>
                      <b-col>
                        <b-row no-gutters>
                          <b-col class="left-subtitle mr-5px" v-if="index === 0">BMI</b-col>
                          <b-col class="left-subtitle mr-5px" v-else></b-col>
                          <b-col class="left-title">{{ bmiContent.title }}</b-col>
                        </b-row>
                      </b-col>
                      <b-col class="content-text text-center">{{ bmiContent.experimental }}</b-col>
                      <b-col class="content-text text-center">{{ bmiContent.control }}</b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="3" class="content-text text-center d-flex align-items-start justify-content-center">
                    <div v-if="bmi.pValue && index === 0">{{ bmi.pValue }}</div>
                    <div v-else-if="index === 1" class="null-text">-</div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row
              no-gutters
              class="content-row border-top"
              v-for="(covariantContent, index) in covariate"
              :key="`${index}-covariate`"
              :class="{ 'border-bottom': index === covariate.length - 1 }"
            >
              <template v-if="covariantContent.type === 'lab'">
                <b-col>
                  <b-row no-gutters>
                    <b-col cols="9">
                      <b-row no-gutters class="content-row">
                        <b-col>
                          <b-row no-gutters>
                            <b-col class="left-subtitle mr-5px" :title="covariantContent.title">{{
                              covariantContent.title
                            }}</b-col>
                            <b-col class="left-title">Mean±SD</b-col>
                          </b-row>
                        </b-col>
                        <b-col class="content-text text-center">{{ covariantContent.experimentalMean }}</b-col>
                        <b-col class="content-text text-center">{{ covariantContent.controlMean }}</b-col>
                      </b-row>
                    </b-col>
                    <b-col cols="3" class="content-text text-center d-flex align-items-center justify-content-center">
                      <div>{{ covariantContent.pValue }}</div>
                    </b-col>
                  </b-row>
                  <b-row no-gutters>
                    <b-col cols="9">
                      <b-row no-gutters class="content-row">
                        <b-col>
                          <b-row no-gutters>
                            <b-col class="left-subtitle mr-5px"> </b-col>
                            <b-col class="left-title" :title="getLabText(covariantContent.title)">{{
                              getLabText(covariantContent.title)
                            }}</b-col>
                          </b-row>
                        </b-col>
                        <b-col class="content-text text-center">{{ covariantContent.experimentalPatientCount }}</b-col>
                        <b-col class="content-text text-center">{{ covariantContent.controlPatientCount }}</b-col>
                      </b-row>
                    </b-col>
                    <b-col cols="3" class="content-text text-center d-flex align-items-center justify-content-center">
                      <div class="null-text">-</div>
                    </b-col>
                  </b-row>
                  <b-row no-gutters>
                    <b-col cols="9">
                      <b-row no-gutters class="content-row">
                        <b-col>
                          <b-row no-gutters>
                            <b-col class="left-subtitle mr-5px"></b-col>
                            <b-col class="left-title">Missing</b-col>
                          </b-row>
                        </b-col>
                        <b-col class="content-text text-center">{{ covariantContent.experimentalMissing }}</b-col>
                        <b-col class="content-text text-center">{{ covariantContent.controlMissing }}</b-col>
                      </b-row>
                    </b-col>
                    <b-col cols="3" class="content-text text-center d-flex align-items-center justify-content-center">
                      <div class="null-text">-</div>
                    </b-col>
                  </b-row>
                </b-col>
              </template>
              <template v-else>
                <b-col class="left-title" :title="covariantContent.title">{{ covariantContent.title }}</b-col>
                <b-col class="content-text text-center">{{ covariantContent.experimental }}</b-col>
                <b-col class="content-text text-center">{{ covariantContent.control }}</b-col>
                <b-col class="content-text text-center">{{ covariantContent.pValue }}</b-col>
              </template>
            </b-row>
            <div class="hint-text">*People with missing age or missing gender were excluded.</div>
            <div class="hint-text-major">
              *Lumos uses t-test for continuous variables and Pearson's chi-squared test for categorical variables
            </div>
            <div class="hint-text-major">
              *Lab covariates calculate the mean and standard deviation for continuous data only
            </div>
          </b-col>
        </b-row>
      </a-modal>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from '@/store';
import { PatientNumberMixin, ClickMixin } from '@/mixins';
import { delay, calcProgress, transformNumber } from '@/utils/util';
import { SLA_DEFINITION } from '@/utils/cohortProgressConfig';
import { API, VUE_EVENT_NAME } from '@/utils/constants';

const VIEW_MORE_THRESHOLD = 1;
const POLLING_PERIOD = 3000;

export default {
  name: 'CharacteristicsTable',
  mixins: [PatientNumberMixin, ClickMixin],
  props: {
    uniqueIndex: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    this.updateData();

    this.fn = () => {
      this.getPatientListData(true);
    };
    this.$root.$on(VUE_EVENT_NAME.SAVE_ANALYSIS_RECORD, this.fn);
  },
  beforeDestroy() {
    this.$root.$off(VUE_EVENT_NAME.SAVE_ANALYSIS_RECORD, this.fn);
  },
  watch: {
    uniqueIndex() {
      this.updateData();
    },
  },
  data() {
    return {
      patientCount: {},
      gender: {
        data: [],
      },
      age: {},
      covariate: [],
      loading: false,
      VIEW_MORE_THRESHOLD,
      visible: false,
      noResult: false,
      progressPercent: 0,
      requestInProgress: false,
      queryData: {},
      height: {
        data: [],
      },
      weight: {
        data: [],
      },
      bmi: {
        data: [],
      },
    };
  },
  computed: {
    ...mapState('cohortSession', ['tabs']),
    tab() {
      return this.tabs[this.uniqueIndex];
    },
    experimentalName() {
      return this.tab.eventSearch.experimental.name;
    },
    controlName() {
      return this.tab.eventSearch.control.name;
    },
    eventSearch() {
      return this.tab.eventSearch;
    },
    showViewMore() {
      return this.covariate.length > VIEW_MORE_THRESHOLD;
    },
  },
  methods: {
    ...mapMutations('cohortSession', ['updateTab']),
    transformNumber,
    async pollingData() {
      let result = { result: 'processing' };
      while (result.result === 'processing' && result.result !== 'fail') {
        result = await this.getPatientListData();
        if (result.result === 'processing') {
          await delay(POLLING_PERIOD);
        }
      }

      if (result.result === 'fail' || result.characteristics.length === 0) {
        this.noResult = true;
      } else {
        this.noResult = false;
      }

      this.patientCharacteristics = result;
      return Promise.resolve();
    },
    async updateProgressPercent() {
      while (this.loading) {
        this.requestInProgress = this.$api.checkInProgress(this.queryData, API.PATIENT_CHARACTERISTIC);
        if (this.requestInProgress) {
          this.progressPercent = calcProgress(
            JSON.parse(this.requestInProgress).startTime,
            SLA_DEFINITION.PATIENT_CHARACTERISTICS
          );

          this.updateTab({
            index: this.uniqueIndex,
            characteristicsTableRequestInProgress: true,
            characteristicsTableProgressPercent: this.progressPercent,
          });
        }
        await delay(100);
      }
      this.updateTab({
        index: this.uniqueIndex,
        characteristicsTableRequestInProgress: false,
        characteristicsTableProgressPercent: 100,
      });
    },
    async updateData() {
      try {
        this.loading = true;

        this.progressPercent = 0;
        this.requestInProgress = false;
        this.updateProgressPercent();
        await this.pollingData();
        this.setPatientCount();
        this.setGender();
        this.setAge();
        this.setHeight();
        this.setWeight();
        this.setBMI();
        this.setCovariant();
      } catch (error) {
        console.error(error);
        this.noResult = true;
      }
      this.progressPercent = 0;
      this.requestInProgress = false;
      this.loading = false;
      this.updateTab({
        index: this.uniqueIndex,
        patientListRequestInProgress: false,
        patientListProgressPercent: 100,
      });
    },
    getRecoveryParams() {
      if (this.tab.tabType === 'history' || this.tab.tabType === 'save') {
        return {
          get_latest: this.tab.getLatest,
          url: this.tab.url || this.tab.id,
        };
      }
      return {
        get_latest: true,
        url: this.tab.url || this.tab.id,
      };
    },
    async getPatientListData(saveAnalysisRecord = false) {
      const params = {
        searchState: this.eventSearch,
      };
      if (saveAnalysisRecord) {
        const query = {
          ...params,
          ...{
            get_latest: true,
            url: this.tab.id,
          },
        };
        this.queryData = query;
        return this.$api.getPatientCharateristicData(query);
      }
      const query = {
        ...params,
        ...this.getRecoveryParams(),
      };
      this.queryData = query;
      return this.$api.getPatientCharateristicData(query);
    },
    getPatientCountFieldData(data) {
      return {
        experimental: `${this.formatedTemplateNumber(data.experimental)}`,
        control: `${this.formatedTemplateNumber(data.control)}`,
      };
    },
    getFieldData(data) {
      return {
        experimental: `${this.formatedTemplateNumber(data.experimental)}(${Math.round(data.experimental_percentage)}%)`,
        control: `${this.formatedTemplateNumber(data.control)}(${Math.round(data.control_percentage)}%)`,
        pValue: data.p_value,
      };
    },
    getCovariateData(data, type) {
      if (type === 'lab') {
        return {
          experimentalMissing: `${data.experimental_missing}(${Math.round(data.experimental_missing_percentage)}%)`,
          controlMissing: `${data.control_missing}(${Math.round(data.control_missing_percentage)}%)`,
          pValue: data.p_value,
          pValuePatientCount: data.chisquare_p_value,
          experimentalPatientCount: `${data.experimental_passed}(${Math.round(data.experimental_passed_percentage)}%)`,
          controlPatientCount: `${data.control_passed}(${Math.round(data.control_passed_percentage)}%)`,
          experimentalMean: `${this.transformNumber(data.experimental_mean, 1)}±${this.transformNumber(
            data.experimental_std_dev,
            1
          )}`,
          controlMean: `${this.transformNumber(data.control_mean, 1)}±${this.transformNumber(data.control_std_dev, 1)}`,
          type: 'lab',
        };
      }
      return this.getFieldData(data);
    },
    setPatientCount() {
      this.patientCount = {};
      const index = this.patientCharacteristics.characteristics.findIndex((item) => item.covariate === 'patient_count');
      if (index > -1) {
        const data = this.patientCharacteristics.characteristics[index];
        this.patientCount = this.getPatientCountFieldData(data);
      }
    },
    setGender() {
      this.gender = {};
      const data = this.patientCharacteristics.characteristics.find((item) => item.covariate === 'gender');
      this.gender.data = [];

      if (data) {
        this.gender.pValue = data.p_value;

        data.data.forEach((item) => {
          this.gender.data.push({
            title: item.subtype,
            ...this.getFieldData(item),
          });
        });
      }
    },
    setAge() {
      this.age = {};
      const data = this.patientCharacteristics.characteristics.find((item) => item.covariate === 'age');

      if (data) {
        this.age = {
          experimental: `${this.transformNumber(data.data[0].experimental_mean, 1)}±${this.transformNumber(
            data.data[0].experimental_std_dev,
            1
          )}`,
          control: `${this.transformNumber(data.data[0].control_mean, 1)}±${this.transformNumber(
            data.data[0].control_std_dev,
            1
          )}`,
          pValue: data.p_value,
        };
      }
    },
    setCovariant() {
      this.covariate = [];
      const nonCovariateFactor = ['patient_count', 'gender', 'age', 'height', 'weight', 'bmi'];
      const data = this.patientCharacteristics.characteristics.filter(
        (item) => !nonCovariateFactor.includes(item.covariate)
      );
      data.forEach((item) => {
        this.covariate.push({
          title: `${item.covariate}`,
          ...this.getCovariateData(item.data[0], item.covariate_type),
          pValue: item.p_value,
        });
      });
    },
    clickViewMore() {
      this.visible = true;

      this.cohortStudyTrackClick('clickViewMore');
    },
    clickEditCovariate() {
      this.$emit('clickEditCovariate');
    },
    transformRound(value) {
      if (typeof value === 'string') {
        return value;
      }
      return `${Math.round(value)}`;
    },
    setBodyMass(factor) {
      this[factor] = {};
      const data = this.patientCharacteristics.characteristics.find((item) => item.covariate === factor);
      this[factor].data = [];

      if (data) {
        this[factor].pValue = data.p_value;

        const bodyMassData = data.data[0];
        this[factor].data.push({
          title: 'Mean±SD',
          experimental: `${this.transformNumber(bodyMassData.experimental_mean, 1)}±${this.transformNumber(
            bodyMassData.experimental_std_dev,
            1
          )}`,
          control: `${this.transformNumber(bodyMassData.control_mean, 1)}±${this.transformNumber(
            bodyMassData.control_std_dev,
            1
          )}`,
        });
        this[factor].data.push({
          title: 'Missing',
          experimental: `${this.formatedTemplateNumber(bodyMassData.experimental_missing)}(${this.transformRound(
            bodyMassData.experimental_missing_percentage
          )}%)`,
          control: `${this.formatedTemplateNumber(bodyMassData.control_missing)}(${this.transformRound(
            bodyMassData.control_missing_percentage
          )}%)`,
        });
      }
    },
    setHeight() {
      this.setBodyMass('height');
    },
    setWeight() {
      this.setBodyMass('weight');
    },
    setBMI() {
      this.setBodyMass('bmi');
    },
    getLabText(name) {
      const lab = this.eventSearch.covariate.inclusionList.find((inclusion) => inclusion.name === name);

      if (!lab || (!lab.condition[0].list[0].start && !lab.condition[0].list[0].end)) {
        return 'Patient count';
      }
      const labData = lab.condition[0].list[0];

      const andOr = {
        filter: 'and',
        should: 'or',
      };

      const nameMapper = new Map([
        ['gte', '>='],
        ['lte', '<='],
        ['gt', '>'],
        ['lt', '<'],
        ['e', '='],
      ]);
      if (labData.end && labData.start) {
        return `${nameMapper.get(labData.startOperator)} ${labData.start} ${andOr[labData.andOr]} ${nameMapper.get(
          labData.endOperator
        )}${labData.end}`;
      }
      if (labData.start) {
        return `${nameMapper.get(labData.startOperator)} ${labData.start}`;
      }
      if (labData.end) {
        return `${nameMapper.get(labData.endOperator)} ${labData.end}`;
      }
      return 'Patient count';
    },
  },
};
</script>
<style lang="scss" scoped>
.characteristics-table {
  padding: 20px 20px;
  box-shadow: 0px 4px 16px #e0e0e0, 0px 16px 16px rgba(157, 205, 245, 0.1);
  border-radius: 8px;
}

.characteristics-table-view-more {
  padding: 20px 20px 70px 20px;
}

.characteristics-title-text {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #212121;
}

.header-text {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #757575;
}

.header-row {
  padding: 2px;
  background: #f5f5f5;
  border-radius: 2px;
}

.left-title {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #757575;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.content-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #212121;
}

.content-row {
  padding: 8px 2px;
}

.left-subtitle {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #9e9e9e;
  height: 18px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.border-top {
  border-top: 1px solid #e0e0e0;
}

.border-bottom {
  border-bottom: 1px solid #e0e0e0;
}

.loading-spin {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
}

.loading-disable {
  opacity: 0.5;
}

.view-more-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #4d8edc;
  padding: 4px;
  text-align: center;
  background-color: #ffffff;
  cursor: pointer;
}

.gradient-cover {
  height: 50px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  background: -moz-linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  background: -o-linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
}

.cover-view-more {
  position: absolute;
  width: inherit;
  margin-top: -90px;
}

.no-result {
  height: 300px;
  box-shadow: 0px 4px 16px #e0e0e0, 0px 16px 16px rgba(157, 205, 245, 0.1);
  border-radius: 8px;
}

.no-result-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.background {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}

.edit-button {
  padding: 8px 16px;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  background: #4d8edc;
}

.edit-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}

.no-results-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #9e9e9e;
}

.hint-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #757575;
  padding-top: 5px;
  background: #ffffff;
}

.progress-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.hint-text-major {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #757575;
  padding-top: 5px;
  background: #ffffff;
}

.null-text {
  font-size: 16px;
  line-height: 19px;
  color: #919191;
}
</style>
