import IndexEvent from '@/utils/conditions/core/advance/IndexEvent';

import ConditionQuery from './ConditionQuery';

class EventHistoryQuery {
  private indexEvent: IndexEvent;
  constructor(indexEvent: IndexEvent) {
    this.indexEvent = indexEvent;
  }

  getQuery() {
    if (!this.indexEvent.constraintList.some((child) => child.constraintType === 'DiagnosisRepeatedTimesConstraint')) {
      return [];
    }
    let conditionQueryList: any[] = [];
    this.indexEvent.condition.forEach((c) => {
      const conditionList = new ConditionQuery(c, this.indexEvent.constraintList, true).getQuery();
      conditionQueryList = conditionQueryList.concat(conditionList);
    });
    conditionQueryList = this.indexEvent.condition.flatMap((c) =>
      new ConditionQuery(c, this.indexEvent.constraintList, true).getQuery()
    );

    return [
      {
        nested: {
          path: 'EVENT_HISTORY',
          inner_hits: {
            name: 'icd_history',
            from: 0,
            size: 1000,
            sort: [
              {
                'EVENT_HISTORY.TIME_DIFF': {
                  order: 'asc',
                },
              },
            ],
          },
          query: {
            bool: {
              should: conditionQueryList,
            },
          },
        },
      },
    ];
  }
}

export default EventHistoryQuery;
