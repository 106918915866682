export default {
  conceptName: 'Anti-TNF Biologics',
  conceptType: 'include',
  name: 'Medication',
  tags: ['Basic', 'Raw'],
  andOr: 'should',
  list: [
    {
      code: 'KC01039271',
      label: 'KC01039271',
      description: 'Humira Inj',
      chineseDescription: '復邁注射液',
      value: 'KC01039271',
      type: 'med',
      patientNum: 53,
      recordNum: 963,
      field: 'med',
      ingredient: 'adalimumab',
      atcCode: 'L04AB04',
      time: 1646618612808,
    },
    {
      code: 'KC00851240',
      label: 'KC00851240',
      description: 'Enbrel prefilled-syringe',
      chineseDescription: '恩博針筒裝注射劑',
      value: 'KC00851240',
      type: 'med',
      patientNum: 29,
      recordNum: 803,
      field: 'med',
      ingredient: 'etanercept',
      atcCode: 'L04AA11',
      time: 1646618619899,
    },
    {
      code: 'REMS',
      label: 'REMS',
      description: 'Remsima 100mg/vial(需冷藏)',
      chineseDescription: '類希瑪',
      value: 'REMS',
      type: 'med',
      patientNum: 1,
      recordNum: 1,
      field: 'med',
      ingredient: 'infliximab',
      atcCode: 'L04AB02',
      time: 1646618625720,
    },
    {
      code: 'KC00980255',
      label: 'KC00980255',
      description: 'Remicade Inj.',
      chineseDescription: '類克凍晶注射劑',
      value: 'KC00980255',
      type: 'med',
      patientNum: 3,
      recordNum: 17,
      field: 'med',
      ingredient: 'infliximab',
      atcCode: 'L04AB02',
      time: 1646618626270,
    },
    {
      code: 'KC00911209',
      label: 'KC00911209',
      description: '(審)(大)Simponi injection 100mg/1ml/vial (需冷藏)',
      chineseDescription: '(大)欣普尼 注射液(L)',
      value: 'KC00911209',
      type: 'med',
      patientNum: 2,
      recordNum: 15,
      field: 'med',
      ingredient: 'golimumab',
      atcCode: 'L04AB06',
      time: 1646618632271,
    },
    {
      code: 'KC00911206',
      label: 'KC00911206',
      description: 'Simponi Inj.',
      chineseDescription: '欣普尼注射液',
      value: 'KC00911206',
      type: 'med',
      patientNum: 53,
      recordNum: 1271,
      field: 'med',
      ingredient: 'golimumab',
      atcCode: 'L04AB06',
      time: 1646618632782,
    },
  ],
  diagnosisRange: false,
  index: 1,
};
