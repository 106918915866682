import Vue from 'vue';

import { DescriptionType, getName, getType } from '../utils/descriptionCache';

declare module 'vue/types/vue' {
  interface Vue {
    getDesc(type: DescriptionType, code: string): string;
    ICD10_Dict: any;
    ICD9_Dict: any;
    PCS_Dict: any;
    MEDICATION_Dict: any;
    NHI_ORDER_Dict: any;
    MEDICAL_ORDER_Dict: any;
    MEDICAL_ORDER_TYPE_Dict: any;
  }
}

function DescriptionPlugin(vue: typeof Vue): void {
  vue.mixin({
    data() {
      return {
        ICD10_Dict: {},
        ICD9_Dict: {},
        PCS_Dict: {},
        MEDICATION_Dict: {},
        NHI_ORDER_Dict: {},
        MEDICAL_ORDER_Dict: {},
        MEDICAL_ORDER_TYPE_Dict: {},
      };
    },
    methods: {
      getDesc(type: DescriptionType, code: string): string {
        let dict: any = null;
        switch (type) {
          case DescriptionType.ICD10:
            dict = this.ICD10_Dict;
            break;
          case DescriptionType.ICD9:
            dict = this.ICD9_Dict;
            break;
          case DescriptionType.MEDICAL_ORDER:
            dict = this.MEDICAL_ORDER_Dict;
            break;
          case DescriptionType.MEDICATION:
            dict = this.MEDICATION_Dict;
            break;
          case DescriptionType.NHI_ORDER:
            dict = this.NHI_ORDER_Dict;
            break;
          case DescriptionType.PCS:
            dict = this.PCS_Dict;
            break;
          default:
            throw new Error(`There is no description type ${type}`);
        }
        if (dict[code] === undefined) {
          this.$set(dict, code, 'loading');
          getName(type, code).then((desc) => {
            this.$set(dict, code, desc);
          });
        }
        return dict[code];
      },
      ICD10(code: string) {
        return this.getDesc(DescriptionType.ICD10, code);
      },
      ICD9(code: string) {
        return this.getDesc(DescriptionType.ICD9, code);
      },
      PCS(code: string) {
        return this.getDesc(DescriptionType.PCS, code);
      },
      MED(code: string) {
        return this.getDesc(DescriptionType.MEDICATION, code);
      },
      NHI(code: string) {
        return this.getDesc(DescriptionType.NHI_ORDER, code);
      },
      MEDICAL_ORDER(code: string) {
        return this.getDesc(DescriptionType.MEDICAL_ORDER, code);
      },
      MEDICAL_ORDER_TYPE(code: string) {
        const dict = this.MEDICAL_ORDER_TYPE_Dict;
        if (dict[code] === undefined) {
          this.$set(dict, code, 'loading');
          getType(DescriptionType.MEDICAL_ORDER, code).then((desc) => {
            this.$set(dict, code, desc);
          });
        }
        return dict[code];
      },
    },
  });
}

Vue.use(DescriptionPlugin);
