<template>
  <div
    @click.stop="addDiagnosisRangeConstraint"
    class="advance-diagnosisRange-adder"
    :class="{ disabled: this.constraintIndex !== -1 }"
    v-if="hasDiagnosisCondition"
  >
    + Primary Diagnosis
  </div>
</template>
<script>
import { findIndex, cloneDeep } from 'lodash';
import { EventDiagnosisRange } from '@/utils/conditions/core/Interface';
import { EventFilterMixin } from '@/mixins';
import ConstraintType from '@/utils/conditions/core/advance/constraint/Interface';

export default {
  name: 'DiagnosisRange',
  mixins: [EventFilterMixin],
  computed: {
    constraintIndex() {
      return findIndex(this.currentEvent.constraintList, {
        constraintType: ConstraintType.DiagnosisRangeConstraint,
      });
    },
    hasDiagnosisCondition() {
      return this.currentEvent.condition.find((item) => item.name === 'Diagnosis');
    },
  },
  methods: {
    addDiagnosisRangeConstraint() {
      const diagnosisRangeConstraint = {
        diagnosisRange: EventDiagnosisRange.MAIN,
      };
      const event = cloneDeep(this.currentEvent);

      if (this.constraintIndex === -1) {
        event.constraintList.push({
          constraintType: ConstraintType.DiagnosisRangeConstraint,
          ...diagnosisRangeConstraint,
        });
      }
      const body = {
        constraintList: event.constraintList,
      };
      this.updateEventWithPath(body, this.path);
      this.setCurrentMenu(this.path, 'DiagnosisRangeConstraint');
      this.cohortStudyTrackClick('openDiagnosisRangeFilter', {
        path: this.path,
        index: this.index,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.advance-diagnosisRange-adder {
  padding: 4px 8px;
  background: #ffffff;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #4d8edc;
  cursor: pointer;
  border: 1px solid #4d8edc;
  box-sizing: border-box;
  border-radius: 4px;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
