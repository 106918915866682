<template>
  <div class="event-result">
    <b-row no-gutters>
      <b-col>
        <b-row no-gutters>
          <b-col>
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="d-flex align-items-center">
                  <template v-if="editResultName">
                    <a-tooltip
                      placement="bottom"
                      overlayClassName="edit-name-error-tooltip"
                      :visible="
                        hasSpecialChar(SPECIAL_CHARS.COHORT_NAME, resultName) ||
                        !stringLessLength(resultName, MAX_NAME_LENGTH)
                      "
                      :get-popup-container="getPopupContainer"
                    >
                      <template slot="title">
                        <div>
                          Analysis name must be 30 characters or less and can’t contain any of the following charecter
                        </div>
                        <div>[ ] : * ? / \</div>
                      </template>
                      <div class="d-flex align-items-center">
                        <input
                          v-model="resultName"
                          ref="editResultName"
                          v-click-outside="closeEditResultName"
                          @keyup.enter="closeEditResultName"
                        />
                      </div>
                    </a-tooltip>
                  </template>
                  <template v-else>
                    <a-tooltip
                      placement="bottom"
                      overlayClassName="edit-name-error-tooltip"
                      :visible="
                        hasSpecialChar(SPECIAL_CHARS.COHORT_NAME, tab.name) ||
                        !stringLessLength(tab.name, MAX_NAME_LENGTH)
                      "
                      :get-popup-container="getPopupContainer"
                    >
                      <template slot="title">
                        <div>
                          Analysis name must be 30 characters or less and can’t contain any of the following charecter
                        </div>
                        <div>[ ] : * ? / \</div>
                      </template>
                      <div class="d-flex align-items-center">
                        <div class="search-title" style="cursor: pointer" @click.stop="clickEditResultName">
                          {{ tab.name }}
                        </div>
                        <b-img
                          src="@/assets/images/icon_study_edit.svg"
                          class="ml-10px"
                          style="cursor: pointer"
                          @click.stop="clickEditResultName"
                        />
                      </div>
                    </a-tooltip>
                  </template>
                </div>
              </div>
              <div class="d-flex align-items-center">
                <ShareAnalysis :uniqueIndex="uniqueIndex" />
                <SaveAsAnalysis :uniqueIndex="uniqueIndex" />
                <SaveAnalysis :uniqueIndex="uniqueIndex" />
                <b-img
                  src="@/assets/images/icon_event_export.svg"
                  style="cursor: pointer"
                  title="Export"
                  @click="clickExport"
                  :class="{
                    'button-disable':
                      hasSpecialChar(SPECIAL_CHARS.COHORT_NAME, tab.name) ||
                      !stringLessLength(tab.name, MAX_NAME_LENGTH),
                  }"
                />
                <div class="export-limit-error-menu" v-if="exportLimitHint" v-click-outside="closeExportLimitHint">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="export-limit-error-text">Please Try Again Later</div>
                    <b-img
                      src="@/assets/images/icon_close_white.svg"
                      style="cursor: pointer"
                      @click="closeExportLimitHint"
                    />
                  </div>
                  <div class="mt-5px">
                    <div class="export-limit-error-content-text">
                      {{
                        `You can only export up to ${MAX_EXPORT_LIMIT} files at a time. Our system is working on processing your requests; please check the status of your current exports`
                      }}
                    </div>
                    <div class="d-flex align-items-center">
                      <div class="export-limit-error-content-text">in the Export Manager.</div>
                      <img
                        alt="aics"
                        title="Exports"
                        src="@/../public/img/navbar/icon_export_center.svg"
                        class="ml-5px mr-5px"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row no-gutters class="mt-20px">
          <b-col class="d-flex align-items-center">
            <div class="d-flex align-items-center edit-button" @click="clickEdit('Experimental')">
              <b-img src="@/assets/images/icon_edit_event_result.svg" class="mr-5px" />
              <div class="edit-text">{{ experimentalName }}</div>
            </div>
            <div class="d-flex align-items-center edit-button ml-20px" @click="clickEdit('Control')">
              <b-img src="@/assets/images/icon_edit_event_result.svg" class="mr-5px" />
              <div class="edit-text">{{ controlName }}</div>
            </div>
            <div class="d-flex align-items-center edit-button ml-20px" @click="clickEdit('Covariate')">
              <b-img src="@/assets/images/icon_edit_event_result.svg" class="mr-5px" />
              <div class="edit-text">Covariate</div>
            </div>
            <div class="d-flex align-items-center edit-button ml-20px" @click="clickEdit('Outcome')">
              <b-img src="@/assets/images/icon_edit_event_result.svg" class="mr-5px" />
              <div class="edit-text">Outcome</div>
            </div>
            <div
              class="d-flex update-button ml-20px"
              @click="updateResult"
              :class="{ 'button-disable': checkDisableUpdate }"
            >
              <div class="d-flex align-items-center">
                <b-img src="@/assets/images/icon_event_count_loading.svg" class="mr-5px" />
                <div class="update-text">Update</div>
              </div>
              <div class="update-dot ml-5px" v-if="visibleUpdateDot"></div>
            </div>
            <div class="d-flex align-items-center update-button ml-20px" @click="clickAnalysis" v-if="false">
              <b-img src="@/assets/images/icon_event_statistics.svg" class="mr-5px" />
              <div class="update-text">Run Statistics</div>
            </div>
          </b-col>
        </b-row>
        <b-row no-gutters class="mt-40px">
          <b-col>
            <b-row no-gutters>
              <b-col cols="6" style="padding: 0px 20px 0px 0px">
                <b-row no-gutters>
                  <b-col>
                    <CharacteristicsTable
                      :uniqueIndex="uniqueIndex"
                      ref="CharacteristicsTable"
                      @clickEditCovariate="clickEdit('Covariate')"
                    />
                  </b-col>
                </b-row>
                <b-row no-gutters>
                  <b-col>
                    <SurvivalRateChart :uniqueIndex="uniqueIndex" ref="SurvivalRateChart" class="mt-40px" />
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="6" style="padding: 0px 0px 0px 20px">
                <b-row no-gutters>
                  <b-col>
                    <IncidenceRateTable :uniqueIndex="uniqueIndex" ref="IncidenceRateTable" />
                  </b-col>
                </b-row>
                <b-row no-gutters>
                  <b-col>
                    <CoxRegression
                      :uniqueIndex="uniqueIndex"
                      ref="CoxRegression"
                      class="mt-40px"
                      @clickEditCovariate="clickEdit('Covariate')"
                    />
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row no-gutters class="mt-40px">
              <b-col>
                <PatientList :uniqueIndex="uniqueIndex" ref="PatientList" />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <a-modal
      :visible="visible === 'Experimental'"
      :mask="true"
      :footer="null"
      width="1010px"
      centered
      :zIndex="9999"
      :closable="false"
      dialogClass="edit-cohort"
      :maskClosable="true"
      @cancel="clickMaskToClose"
    >
      <CohortGroup :flowType="['session', uniqueIndex, 'experimental']" />
      <div class="d-flex justify-content-end align-items-center bottom-button-group">
        <div class="mr-40px cancel-button" @click="clickCancel">CANCEL</div>
        <div class="done-button" @click="clickExperimentalDone">DONE</div>
      </div>
    </a-modal>
    <a-modal
      :visible="visible === 'Control'"
      :mask="true"
      :footer="null"
      width="1010px"
      centered
      :zIndex="9999"
      :closable="false"
      dialogClass="edit-cohort"
      :maskClosable="true"
      @cancel="clickMaskToClose"
    >
      <CohortGroup :flowType="['session', uniqueIndex, 'control']" />
      <div class="d-flex justify-content-end align-items-center bottom-button-group">
        <div class="mr-40px cancel-button" @click="clickCancel">CANCEL</div>
        <div class="done-button" @click="clickControlDone">DONE</div>
      </div>
    </a-modal>
    <a-modal
      :visible="visible === 'Covariate'"
      :mask="true"
      :footer="null"
      width="1010px"
      centered
      :zIndex="9999"
      :closable="false"
      dialogClass="edit-cohort"
      :maskClosable="true"
      @cancel="clickMaskToClose"
    >
      <CovariantGroup :flowType="['session', uniqueIndex, 'covariate']" />
      <div class="d-flex justify-content-end align-items-center bottom-button-group">
        <div class="mr-40px cancel-button" @click="clickCancel">CANCEL</div>
        <div class="done-button" @click="clickCovariantDone" :class="{ 'button-disable': getCovariateNameError }">
          DONE
        </div>
      </div>
    </a-modal>
    <a-modal
      :visible="visible === 'Outcome'"
      :mask="true"
      :footer="null"
      width="1010px"
      centered
      :zIndex="9999"
      :closable="false"
      dialogClass="edit-cohort"
      :maskClosable="true"
      @cancel="clickMaskToClose"
    >
      <OutcomeGroup :flowType="['session', uniqueIndex, 'outcome']" />
      <div class="d-flex justify-content-end align-items-center bottom-button-group">
        <div class="mr-40px cancel-button" @click="clickCancel">CANCEL</div>
        <div class="done-button" @click="clickOutcomeDone" :class="{ 'button-disable': getOutcomeNameError }">DONE</div>
      </div>
    </a-modal>
    <div class="hint-link-copied" v-if="hintLinkCopied">✓ Link copied to clipboard</div>
    <div class="hint-link-copied" v-if="hintLinkSave">✓ Analysis saved</div>
    <div>
      <a-modal
        :visible="
          alertVisable === 'Experimental' ||
          alertVisable === 'Control' ||
          alertVisable === 'Covariate' ||
          alertVisable === 'Outcome'
        "
        :mask="true"
        :footer="null"
        centered
        :zIndex="9999"
        :destroyOnClose="true"
        :closable="false"
        width="495px"
        dialogClass="alert-modal"
      >
        <b-row no-gutters>
          <b-col class="d-flex justify-content-center align-items-center">
            <div class="modal-apply-title">Apply Changes?</div>
          </b-col>
        </b-row>
        <b-row no-gutters class="mt-40px">
          <b-col class="d-flex justify-content-between">
            <div class="no-button" @click="closeAlertModal">No</div>
            <div class="modal-apply-button" @click="applyAlertModal">Yes</div>
          </b-col>
        </b-row>
      </a-modal>
    </div>
  </div>
</template>
<script>
import CharacteristicsTable from '@/components/byEvent/result/CharacteristicsTable.vue';
import SurvivalRateChart from '@/components/byEvent/result/SurvivalRateChart.vue';
import PatientList from '@/components/byEvent/result/PatientList.vue';
import IncidenceRateTable from '@/components/byEvent/result/IncidenceRateTable.vue';
import CoxRegression from '@/components/byEvent/result/CoxRegression.vue';
import CohortGroup from '@/components/byEvent/group/CohortGroup.vue';
import CovariantGroup from '@/components/byEvent/group/CovariantGroup.vue';
import OutcomeGroup from '@/components/byEvent/group/OutcomeGroup.vue';
import ShareAnalysis from '@/components/byEvent/button/ShareAnalysis.vue';
import SaveAsAnalysis from '@/components/byEvent/button/SaveAsAnalysis.vue';
import SaveAnalysis from '@/components/byEvent/button/SaveAnalysis.vue';

import { mapState, mapMutations } from '@/store';
import { cloneDeep, isEqual } from 'lodash';
import dayjs from 'dayjs';
import ClickOutside from 'vue-click-outside';

import { SearchQueryGenerator } from '@/utils/lumos/SearchQueryGenerator';
import { getPatientListQuery } from '@/utils/query/getEventPatientFromDocValue';
import { SPECIAL_CHARS, MAX_NAME_LENGTH, VUE_EVENT_NAME, MAX_EXPORT_LIMIT } from '@/utils/constants';
import { ClickMixin } from '@/mixins';
import { hasSpecialChar, getPopupContainer, checkEventSearchNameError, stringLessLength } from '@/utils/util';

export default {
  name: 'EventResult',
  mixins: [ClickMixin],
  directives: {
    ClickOutside,
  },
  components: {
    CharacteristicsTable,
    SurvivalRateChart,
    PatientList,
    IncidenceRateTable,
    CoxRegression,
    CohortGroup,
    CovariantGroup,
    OutcomeGroup,
    ShareAnalysis,
    SaveAsAnalysis,
    SaveAnalysis,
  },
  props: {
    uniqueIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      visible: false,
      counter: 0,
      loading: false,
      resultName: '',
      editResultName: false,
      visibleUpdateDot: false,
      hintLinkCopied: false,
      hintLinkSave: false,
      SPECIAL_CHARS,
      progressPercent: 0,
      alertVisable: false,
      initCohort: {},
      MAX_NAME_LENGTH,
      exportLimitHint: false,
      MAX_EXPORT_LIMIT,
    };
  },
  computed: {
    ...mapState('cohortSession', ['tabs', 'NAME_COUNTER']),
    tab() {
      return this.tabs[this.uniqueIndex];
    },
    eventSearch() {
      return this.tab.eventSearch;
    },
    experimentalName() {
      return this.tab.eventSearch.experimental.name;
    },
    controlName() {
      return this.tab.eventSearch.control.name;
    },
    checkDisableUpdate() {
      const experimentalHasCondition = this.getCohortHasCondition(this.eventSearch.experimental);
      const controlHasCondition = this.getCohortHasCondition(this.eventSearch.control);
      const outcomeHasCondition = this.inclusionListHasCondition(this.eventSearch.outcome.inclusionList);
      return (
        !(experimentalHasCondition && controlHasCondition && outcomeHasCondition) ||
        checkEventSearchNameError(this.eventSearch) ||
        !stringLessLength(this.tab.name, MAX_NAME_LENGTH)
      );
    },
    getCovariateNameError() {
      return (
        this.$store.state.eventResult.nameError && this.$store.state.eventResult.nameError.split('-')[0] === 'covariate'
      );
    },
    getOutcomeNameError() {
      return (
        this.$store.state.eventResult.nameError && this.$store.state.eventResult.nameError.split('-')[0] === 'outcome'
      );
    },
  },
  mounted() {
    this.$root.$on(VUE_EVENT_NAME.OPEN_ANALYSIS_LINK_COPIED_TEXT, () => {
      this.hintLinkCopied = true;
    });
    this.$root.$on(VUE_EVENT_NAME.CLOSE_ANALYSIS_LINK_COPIED_TEXT, () => {
      this.hintLinkCopied = false;
    });

    this.$root.$on(VUE_EVENT_NAME.OPEN_ANALYSIS_LINK_SAVE_TEXT, () => {
      this.hintLinkSave = true;
    });
    this.$root.$on(VUE_EVENT_NAME.CLOSE_ANALYSIS_LINK_SAVE_TEXT, () => {
      this.hintLinkSave = false;
    });
  },
  destroyed() {
    this.$root.$off(VUE_EVENT_NAME.OPEN_ANALYSIS_LINK_COPIED_TEXT);
    this.$root.$off(VUE_EVENT_NAME.CLOSE_ANALYSIS_LINK_COPIED_TEXT);
    this.$root.$off(VUE_EVENT_NAME.OPEN_ANALYSIS_LINK_SAVE_TEXT);
    this.$root.$off(VUE_EVENT_NAME.CLOSE_ANALYSIS_LINK_SAVE_TEXT);
  },
  methods: {
    ...mapMutations('cohortSession', ['updateTab']),
    ...mapMutations('eventResult', ['restoreData', 'updateNameError']),
    ...mapMutations(['setHintReadyDataset', 'setCohortStudyExportIdList']),
    hasSpecialChar,
    getPopupContainer,
    stringLessLength,
    clickEdit(value) {
      this.updateNameError('');
      this.restoreData({
        eventSearch: cloneDeep(this.tab.eventSearch),
        experimentalGroupPatient: cloneDeep(this.tab.experimentalGroupPatient),
        controlGroupPatient: cloneDeep(this.tab.controlGroupPatient),
      });
      this.visible = value;
      this.initCohort = cloneDeep(this.tab.eventSearch);

      this.cohortStudyTrackClick('clickEdit', { value });
    },
    clickCancel() {
      this.visible = false;

      this.cohortStudyTrackClick('clickCancel');
    },
    clickExperimentalDone() {
      this.updateTab({
        index: this.uniqueIndex,
        eventSearch: {
          ...cloneDeep(this.tab.eventSearch),
          experimental: cloneDeep(this.$store.state.eventResult.eventSearch.experimental),
        },
      });
      this.visible = false;
      this.visibleUpdateDot = true;

      this.cohortStudyTrackClick('clickExperimentalDone');
    },
    clickControlDone() {
      this.updateTab({
        index: this.uniqueIndex,
        eventSearch: {
          ...cloneDeep(this.tab.eventSearch),
          control: cloneDeep(this.$store.state.eventResult.eventSearch.control),
        },
      });
      this.visible = false;
      this.visibleUpdateDot = true;

      this.cohortStudyTrackClick('clickControlDone');
    },
    clickCovariantDone() {
      this.updateTab({
        index: this.uniqueIndex,
        eventSearch: {
          ...cloneDeep(this.tab.eventSearch),
          covariate: cloneDeep(this.$store.state.eventResult.eventSearch.covariate),
        },
      });
      this.visible = false;
      this.visibleUpdateDot = true;

      this.cohortStudyTrackClick('clickCovariantDone');
    },
    clickOutcomeDone() {
      this.updateTab({
        index: this.uniqueIndex,
        eventSearch: {
          ...cloneDeep(this.tab.eventSearch),
          outcome: cloneDeep(this.$store.state.eventResult.eventSearch.outcome),
        },
      });
      this.visible = false;
      this.visibleUpdateDot = true;

      this.cohortStudyTrackClick('clickOutcomeDone');
    },
    async updateResult() {
      const result = await this.$api.postCohortStudy({
        name: `Result ${this.NAME_COUNTER}`,
        updateTime: dayjs().toISOString(),
        purpose: 'history',
        request: {
          searchState: this.tab.eventSearch,
        },
      });

      this.updateTab({
        index: this.uniqueIndex,
        url: result.url,
        tabType: 'history',
        getLatest: true,
        resultDone: false,
      });

      this.$refs.CharacteristicsTable.updateData();
      this.$refs.SurvivalRateChart.updateData();
      this.$refs.IncidenceRateTable.updateData();
      this.$refs.CoxRegression.updateData();
      this.$refs.PatientList.updateData();
      this.visibleUpdateDot = false;

      this.$root.$emit(VUE_EVENT_NAME.REFRESH_HISTORY_ANALYSIS_LIST);

      this.cohortStudyTrackClick('updateResult');
    },
    getQueryData() {
      const experimentalSearchObject = this.eventSearch.experimental;
      const controlSearchObject = this.eventSearch.control;
      const { covariate } = this.eventSearch;
      const experimentalQueries = SearchQueryGenerator.generateCohortQuery(experimentalSearchObject);
      const controlQueries = SearchQueryGenerator.generateCohortQuery(controlSearchObject);
      const experimentalCharacteristicQuery = getPatientListQuery(
        SearchQueryGenerator.generateCharacteristicQuery(experimentalSearchObject, covariate.inclusionList, []),
        covariate
      );
      const controlCharacteristicQuery = getPatientListQuery(
        SearchQueryGenerator.generateCharacteristicQuery(controlSearchObject, covariate.inclusionList, []),
        covariate
      );

      return {
        experimental: {
          queries: experimentalQueries,
          CharacteristicQuery: experimentalCharacteristicQuery,
        },
        control: {
          queries: controlQueries,
          CharacteristicQuery: controlCharacteristicQuery,
        },
        searchState: this.eventSearch,
      };
    },
    async clickExport() {
      this.cohortStudyTrackClick('clickExport');

      try {
        const params = this.getQueryData();

        const result = await this.$api.cohortStudyExport(
          {
            ...params,
            url: this.tab.url || this.tab.id,
            analysisName: this.tab.name,
            exportType: 'Cohort study',
          },
          this.upgradeLoaded
        );
        this.setCohortStudyExportIdList(result.requestId);
        this.$root.$emit(VUE_EVENT_NAME.SET_EXPORT_JOB);
      } catch (error) {
        console.error(error);
        this.exportLimitHint = true;
      }
    },
    clickAnalysis() {
      this.setHintReadyDataset(true);
      const params = this.getQueryData();
      this.$api.postCohortStudy({
        request: params,
        name: this.tab.name,
        updateTime: dayjs().toISOString(),
        purpose: 'dataset',
      });

      this.cohortStudyTrackClick('clickAnalysis');
    },
    closeEditResultName() {
      this.editResultName = false;

      this.updateTab({
        index: this.uniqueIndex,
        name: this.resultName,
      });
    },
    clickEditResultName() {
      this.resultName = this.tab.name;
      this.editResultName = true;
      this.$nextTick(() => {
        this.$refs.editResultName.select();
      });

      this.cohortStudyTrackClick('clickEditResultName');
    },
    getCohortHasCondition(cohort) {
      return cohort.eventList[0].condition.length !== 0 || cohort.indexEvent.condition.length !== 0;
    },
    inclusionListHasCondition(inclusionList) {
      let hasCondition = false;
      inclusionList.forEach((item) => {
        if (item.condition.length > 0) {
          hasCondition = true;
        }
      });
      return hasCondition;
    },
    clickMaskToClose() {
      const alertVisable = cloneDeep(this.visible);
      this.visible = false;
      if (!isEqual(this.$store.state.eventResult.eventSearch, this.initCohort)) {
        this.alertVisable = alertVisable;
      }
    },
    closeAlertModal() {
      this.alertVisable = false;
    },
    applyAlertModal() {
      const alertVisable = cloneDeep(this.alertVisable);
      this.alertVisable = false;
      if (alertVisable === 'Experimental') {
        this.clickExperimentalDone();
      } else if (alertVisable === 'Control') {
        this.clickControlDone();
      } else if (alertVisable === 'Covariate') {
        this.clickCovariantDone();
      } else if (alertVisable === 'Outcome') {
        this.clickOutcomeDone();
      }
    },
    closeExportLimitHint() {
      this.exportLimitHint = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.event-result {
  padding: 40px 40px;
  background: #ffffff;
  min-height: calc(100vh - 56px);
}

.search-title {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: #334e97;
}

.edit-button {
  padding: 8px 16px;
  border: 1px solid #4d8edc;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
}

.edit-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #4d8edc;
}

.statistics-button {
  padding: 8px 16px;
  border-radius: 6px;
  background-color: #333f6b;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  border-width: 0 0 0 0;
  width: fit-content;
  cursor: pointer;
}

/deep/ .edit-cohort {
  .ant-modal-body {
    padding: 0px;
    background: #fafafa;
  }
}

.cancel-button {
  padding: 12px 24px;
  cursor: pointer;
  border: 1px solid #4d8edc;
  box-sizing: border-box;
  border-radius: 6px;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #4d8edc;
}

.done-button {
  padding: 12px 24px;
  cursor: pointer;
  background: #4d8edc;
  box-sizing: border-box;
  border-radius: 6px;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}

.update-button {
  padding: 8px 16px;
  background: #4d8edc;
  border-radius: 4px;
  cursor: pointer;
}

.update-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}

.update-dot {
  width: 8px;
  height: 8px;
  background: #f5d03d;
  border-radius: 99px;
}

.hint-link-copied {
  padding: 12px 16px;
  background: #57ac79;
  border: 1px solid #ffffff;
  border-radius: 6px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 150%;
  color: #ffffff;
  position: fixed;
  bottom: 40px;
  left: 43%;
}

.button-disable {
  opacity: 0.5;
  pointer-events: none;
}

.bottom-button-group {
  padding: 0px 40px 40px 40px;
  position: sticky;
  bottom: 0px;
  background: #ffffff;
}

.modal-apply-title {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #333f6b;
}

.no-button {
  width: 197px;
  padding: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #4d8edc;
  border: 1px solid #4d8edc;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
}

.modal-apply-button {
  width: 197px;
  padding: 12px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  background: #4d8edc;
  border-radius: 4px;
  cursor: pointer;
}

/deep/ .alert-modal {
  .ant-modal-body {
    padding: 40px;
    background: #ffffff;
    border-radius: 8px;
  }
}

.export-limit-error-menu {
  padding: 20px;
  position: absolute;
  width: 350px;
  background: #4d8edc;
  border-radius: 5px;
  margin-left: -174px;
  margin-top: 195px;
  z-index: 1;
}

.export-limit-error-menu::before {
  content: '';
  position: absolute;
  display: block;
  width: 0px;
  left: 330px;
  top: 5px;
  border: 7px solid transparent;
  border-top: 0;
  border-bottom: 7px solid #4d8edc;
  -webkit-transform: translate(-50%, calc(-100% - 5px));
  -ms-transform: translate(-50%, calc(-100% - 5px));
  transform: translate(-50%, calc(-100% - 5px));
}

.export-limit-error-text {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}

.export-limit-error-content-text {
  font-size: 14px;
  line-height: 150%;
  color: #ffffff;
}
</style>
<style lang="scss">
.edit-name-error-tooltip {
  .ant-tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 5px 6px 5px;
    border-color: transparent transparent #d65c51 transparent;
    margin-top: 7px;
  }
  .ant-tooltip-inner {
    background: #d65c51;
    border-radius: 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
  }
}
</style>
