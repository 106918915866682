import BasicChartConfig from '@/components/charts/core/BasicChartConfig';
import { BasicChartQueryParams, ChartItem, ChartKey, RawItem } from '@/components/charts/core/Interface';
import { isValidSome } from '@/utils/util';
import { ConditionName, RangeCondition, RangeConditionData, SearchType } from '../../utils/conditions/core';
import { removePoint, UNSELECTED_COLOR } from './utils/utils';

const XAXIS_LABEL = ['0-9', '10-19', '20-29', '30-39', '40-49', '50-59', '60-69', '70-79', '80-89', '90-99', '100+'];

const RANGES = [
  { from: 0, to: 10 },
  { from: 10, to: 20 },
  { from: 20, to: 30 },
  { from: 30, to: 40 },
  { from: 40, to: 50 },
  { from: 50, to: 60 },
  { from: 60, to: 70 },
  { from: 70, to: 80 },
  { from: 80, to: 90 },
  { from: 90, to: 100 },
  { from: 100 },
];

const OPTION = {
  grid: {
    top: 30,
  },
  xAxis: {
    type: 'category',
    data: XAXIS_LABEL,
    triggerEvent: true,
    axisLabel: {
      interval: 0,
      rotate: 45,
      margin: 20,
      align: 'center',
    },
    name: 'Age',
    nameLocation: 'end',
    nameGap: -30,
    nameTextStyle: {
      padding: [100, 0, 0, 0],
      color: '#757575',
    },
  },
  yAxis: {
    type: 'value',
    nameLocation: 'end',
    nameTextStyle: {
      color: '#757575',
    },
    axisLabel: {
      formatter: removePoint,
    },
  },
};

interface AgeRawItem extends RawItem {
  from: number;
  to?: number;
}

export default class AgeConfig extends BasicChartConfig<AgeRawItem> {
  constructor() {
    super(ChartKey.Age);

    this.merge(OPTION);
    this.bucketByPatient = false;
    this.merge({
      yAxis: {
        name: this.bucketByPatient ? 'Patient' : 'Record',
      },
    });
  }

  protected get hasData(): boolean {
    return isValidSome(this.data, 0);
  }

  protected processQueryParams(): BasicChartQueryParams {
    const condList = this.params.includes.select(
      (cond) => cond.hasTag(SearchType.Raw) || cond.name !== ConditionName.Age
    );
    return {
      ...this.params,
      includes: condList,
    };
  }

  protected createChartItem(rawItem: AgeRawItem, index: number): ChartItem {
    const condList = this.params.includes.additions.with(ConditionName.Age);
    let color = '#333f6b';
    if (condList.length !== 0) {
      const condition = condList[0] as unknown as RangeCondition;
      color = UNSELECTED_COLOR;
      if (condition.hitStartOrEnd(rawItem.from, rawItem.to)) {
        color = '#333f6b';
      }
    }

    return {
      key: XAXIS_LABEL[index],
      value: rawItem.doc_count,
      code: XAXIS_LABEL[index],
      name: XAXIS_LABEL[index],
      color,
      percentage: Math.round((rawItem.doc_count / this.recordCount) * 100.0),
    };
  }

  public createConditionFromItem(chartItem: ChartItem): RangeConditionData {
    const { name } = chartItem;
    const condition = {
      name: ConditionName.Age,
      tags: [SearchType.Basic, SearchType.Additional],
    };
    if (name !== '100+') {
      return {
        ...condition,
        ranges: [
          {
            start: Number(name.split('-')[0]),
            end: Number(name.split('-')[1]),
          },
        ],
      };
    }
    return {
      ...condition,
      ranges: [{ start: 100, end: 200 }],
    };
  }

  protected get aggsQuery(): Record<string, unknown> {
    return {
      range: {
        field: 'AGE',
        ranges: RANGES,
      },
    };
  }
}
