<template>
  <div class="mt-10px">
    <a-tabs v-model="tabName" hide-add type="editable-card" class="tabs-result" @change="switchResultTab">
      <a-tab-pane v-for="(tab, index) in tabs" :key="index" :closable="true">
        <template slot="tab">
          <div class="custom-tab d-flex align-items-center">
            <b-img src="@/assets/images/icon_analysis_tab_event_enable.svg" v-if="tabName === index" />
            <b-img src="@/assets/images/icon_analysis_tab.svg" v-else />
            <div class="border-fixer ml-5px">
              {{ getAbbreviationName(tab.name) }}
            </div>
            <b-img src="@/assets/images/icon_close.svg" class="icon-close ml-10px" @click.stop="closeTab(index)" />
          </div>
        </template>
      </a-tab-pane>
    </a-tabs>
    <a-tabs id="search-portal-tab" type="card" @change="switchTab" :activeKey="tabName">
      <a-tab-pane :key="PAGES.SEARCH" :closable="false">
        <template slot="tab">
          <div class="custom-tab d-flex align-items-center">
            <b-img src="@/assets/images/icon_search_portal_event_enable.svg" v-if="tabName === PAGES.SEARCH" />
            <b-img src="@/assets/images/icon_search_portal.svg" v-else />
            <div class="border-fixer ml-5px" style="width: 55px">
              {{ PAGES.SEARCH }}
            </div>
          </div>
        </template>
        <a-row>
          <a-col>
            <AdvanceSearch />
          </a-col>
        </a-row>
      </a-tab-pane>
    </a-tabs>
    <AdvanceAnalysis v-if="activeTabIndex >= 0" :uniqueIndex="activeTabIndex" />
  </div>
</template>
<script>
import { PAGES } from '@/utils/constants';
import { ClickMixin } from '@/mixins';
import { mapState, mapMutations } from '@/store';
import AdvanceSearch from '@/views/AdvanceSearch.vue';
import AdvanceAnalysis from '@/components/advanceSearch/analysis/AdvanceAnalysis.vue';

export default {
  name: 'ByEventSearchPortal',
  mixins: [ClickMixin],
  components: {
    AdvanceSearch,
    AdvanceAnalysis,
  },
  computed: {
    ...mapState('byEventSession', ['tabs', 'activeTabIndex']),
  },
  watch: {
    activeTabIndex() {
      this.checkTabName();
    },
  },
  data() {
    return {
      PAGES,
      tabName: PAGES.SEARCH,
    };
  },
  async mounted() {
    this.checkTabName();
  },
  methods: {
    ...mapMutations('byEventSession', ['setActiveTabIndex', 'removeTab']),
    switchResultTab(tab) {
      this.tabName = tab;
      this.setActiveTabIndex(tab);
      this.advanceTrackClick('switchResultTab', tab);
    },
    switchTab(tab) {
      this.tabName = tab;
      this.setActiveTabIndex(-1);
      this.advanceTrackClick('switchTab', tab);
    },
    closeTab(index) {
      this.removeTab(index);
      this.advanceTrackClick('closeTab', index);
    },
    checkTabName() {
      if (this.activeTabIndex >= 0) {
        this.tabName = this.activeTabIndex;
      } else {
        this.tabName = PAGES.SEARCH;
      }
    },
    getAbbreviationName(name) {
      if (name.length > 20) {
        return `${name.slice(0, 20)} ...`;
      }
      return name;
    },
  },
};
</script>
<style scoped>
#search-portal-tab >>> .ant-tabs-tab > .custom-tab {
  position: relative;
  border-bottom: 5px solid #ffffff !important;
  top: -2px;
}
#search-portal-tab >>> .ant-tabs-tab-active > .custom-tab {
  font-weight: bold;
  position: relative;
  border-bottom: 5px solid #333f6b !important;
  top: -2px;
}
#search-portal-tab >>> .ant-tabs-tab-active > .custom-tab > .border-fixer {
  position: relative;
}
</style>
<style lang="scss" scoped>
#search-portal-tab {
  /deep/ .ant-tabs-nav {
    margin-left: 20px !important;
  }
}

/deep/ .ant-tabs-bar {
  margin: 0px;
}

.tabs-result {
  position: absolute;
  margin-left: 135px;
  max-width: calc(100% - 280px);
  min-width: calc(100% - 280px);
  width: calc(100% - 280px);
  z-index: 1;

  /deep/ .ant-tabs-nav {
    margin-left: 0px !important;
  }

  /deep/ .ant-tabs-tab {
    .custom-tab {
      border-bottom: 5px solid #ffffff !important;
      margin-top: -2px;
    }
  }

  /deep/ .ant-tabs-tab-active {
    .custom-tab {
      font-weight: bold;
      border-bottom: 5px solid #333f6b !important;
      margin-top: -2px;
      color: #333f6b;
    }
  }

  /deep/ .ant-tabs-nav-container-scrolling {
    span {
      background: #ffffff;
      border: 1px solid #e8e8e8;
      border-bottom: none;

      i {
        display: none;
      }

      .ant-tabs-tab-next-icon {
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 6px solid #334e97;
      }

      .ant-tabs-tab-prev-icon {
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-right: 6px solid #334e97;
      }
    }
  }
}

.icon-close {
  width: 20px;
  height: 20px;
}
</style>
