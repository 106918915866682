<template>
  <div class="text-keyword-content">
    <span v-for="(keywords, index) in condition.data" :key="index">
      <span>
        <a-tooltip
          :class="{ keyword: true, deletable }"
          :title="`${getFieldNames(keywords.fields)}: ${keywords.list.join(', ')}`"
        >
          <span>
            <span class="keyword-text">
              {{ keywords.list.join(', ') }}
            </span>
            <span v-if="deletable" @click="onDelete(keywords, index)" class="del-icon">x</span>
          </span>
        </a-tooltip>
        <span v-if="index !== condition.data.length - 1" class="concater">
          {{ concater }}
        </span>
      </span>
    </span>
  </div>
</template>

<script>
import { xorWith } from 'lodash';
import { ClickMixin, TemplateValue, UpdateMixin } from '@/mixins';
import { mapSubUiConfig } from '@/utils/uiConfig';
import { mapState } from '../../../../store';

const FIELD_MAPPING = {
  PATH_REPORT_1: 'Chief Complaint',
  PATH_REPORT_2: 'History',
  MEDICAL_REPORT: 'Physical Examination',
  TREATMENT_PATH: 'Treatment Course',
  OP_NOTE: 'OP Note',
  ICD10_DISDIAG: 'Discharge Diagnosis',
  ADM_PAHDG: 'Pathology',
  EXAM_FULL_TXT: 'Exam',
  SUBJ_NOTE: 'Subjective Note',
  OBJ_NOTE: 'Objective Note',
  SYS_REVIEW: 'Review of System',
  ASSESSMENT: 'Assessment',
  PLAN: 'Plan',
};

export default {
  name: 'KeywordValue',
  mixins: [ClickMixin, TemplateValue, UpdateMixin],
  props: {
    condition: Object,
    uniqueIndex: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapSubUiConfig('Analyze'),
    ...mapState('session', ['tabs']),
    tab() {
      if (this.tabs.length === 0) {
        return {};
      }
      return this.tabs[this.uniqueIndex];
    },
  },
  methods: {
    getFieldNames(fields) {
      if (fields.length > 1) {
        return 'All (Inpatient/Outpatient)';
      }

      if (this.uiConfig.OPD_Searchable && this.getKeywordFields('all').includes(fields[0])) {
        return fields.map((field) => `${FIELD_MAPPING[field]} (Inpatient/Outpatient)`).join(', ');
      }

      if (this.uiConfig.OPD_Searchable && this.getKeywordFields('ipd').includes(fields[0])) {
        return fields.map((field) => `${FIELD_MAPPING[field]} (Inpatient)`).join(', ');
      }

      if (this.uiConfig.OPD_Searchable && this.getKeywordFields('opd').includes(fields[0])) {
        return fields.map((field) => `${FIELD_MAPPING[field]} (Outpatient)`).join(', ');
      }

      return fields.map((field) => FIELD_MAPPING[field]).join(', ');
    },
    onDelete(keyword, index) {
      const extendConditions = [this.refCond[index]];
      this.trackClick('onDelete', {
        includes: xorWith(this.tab.includes, extendConditions),
        excludes: this.tab.excludes,
        keyword,
        uniqueIndex: this.uniqueIndex,
      });

      this.update({
        includes: xorWith(this.tab.includes, extendConditions),
        excludes: this.tab.excludes,
        keyword,
        uniqueIndex: this.uniqueIndex,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.text-keyword-content {
  float: left;
  overflow: visible;
  font-family: Muli, Serif, Sans-serif, cursive, fantasy, Monospace;

  .keyword {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333f6b;
    display: inline-block;
  }

  .keyword-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 200px;
    display: inline-block;
  }

  .concater {
    font-size: 12px;
    font-weight: normal;
    color: #828282;
    position: relative;
    top: -4px !important;
    float: none !important;
  }

  .deletable {
    height: 24px;
    border-radius: 20px;
    margin: 2px 5px;
    padding: 2px 10px;
    background-color: #333f6b;
    font-family: Muli, Serif, Sans-serif, cursive, fantasy, Monospace;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    display: inline-flex;
    position: relative;
    top: -4px;
  }

  .del-icon {
    cursor: pointer;
    color: #f5ba42;
    margin-left: 5px;
  }
}

.disable-block {
  opacity: 0.5;
}
</style>
