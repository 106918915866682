export default {
  Blood: [
    '慢性食物發炎檢測',
    'Cortisol',
    'Aspergillus Ag',
    '女性抗衰老賀爾蒙',
    'Mumps CF virus Ab',
    'CYFRA21-1',
    'Reticulocyte',
    'Allergen Phadiatop(Allergen Phadiatop)',
    'Alcohol篩檢-駕駛',
    '抗壓力賀爾蒙',
    'Anti-HAV IgM',
    'HCV 基因型檢測',
    'Progesterone',
    'GLU-AC',
    'SMA(母血篩檢)',
    'FSH',
    'P.T. Control',
    'INR',
    'AMH',
    'Aldosterone',
    'Coombs test indirect',
    '(愛滋匿篩)HIV1+2確認',
    'PreALB',
    'OGTT(75g)--120min',
    'Mumps virus IgG',
    'APO-B',
    'Total protein',
    'APO-A1',
    'Prothrombin Time',
    'ctHb',
    'E2',
    'Lithium(用藥後12hr)',
    '(MTD,HIV)Quantiferon',
    'P',
    'C.pneumonia IgM',
    'Fibrinogen',
    '25-OH-D3',
    'Prolactin',
    'CBC Routine',
    'Insulin Ab',
    'AMA',
    'Free-Testosterone',
    '脂肪酸評估',
    'Renin',
    'Anti-TG',
    'Protein -C',
    'OGTT(75g)--90min',
    'OGTT (75g)',
    'OGTT(75g)--60min',
    'HLA-B*5801',
    'SMA gene',
    'Anti-HBeAg',
    'OGTT(75g)--30min',
    'T3',
    'IgM',
    'Widal test(自費體檢)',
    'HLA-B 5801(for APL)',
    'LA1 screening',
    'LA2 confirmation',
    'LA1/LA2 ratio',
    'Globulin',
    'Cortisol(AM8:00)',
    'APTT',
    'APTT Control',
    'OGTT (100g婦產科)',
    'OGTT(100g)--60min',
    'OGTT(100g)--180min',
    'OGTT(100g)--120min',
    'Protein -S',
    'HBsAg',
    'C3',
    'hGH',
    'OGTT(100g)--30min',
    'HLA-B 1502(for CAZ)',
    'Creatinine',
    'eGFR',
    'TPPA',
    'C4',
    'Ceruloplasmin',
    '(必治妥)HCV-1b變異檢',
    'Glucose (PC)',
    'Anti-HAV IgG',
    'T4',
    'HLA-B 1502 gene',
    'Tissue poly. Ag',
    '(中外)HBV viral load',
    'EBVCA IgA',
    'EB-VCA IgA',
    'Hb EP',
    'FDP',
    'Neutrophil',
    'Eosinophil',
    'Basophil',
    'Anti-HBe',
    'Hb',
    'Anti-dsDNA',
    'Osteocalcin',
    'Anti-HBs',
    'Anti-CTD Ab screen',
    'Testosterone',
    'LH',
    'ALT(GPT)',
    'hsTroponin I',
    'AMON',
    'Myco.pneu IgG',
    'Cortisol(8:00)',
    'Estradiol(E2)',
    'Immuno-EP',
    'NIFTY',
    'GAD',
    'IMR阿茲海默症檢測',
    'Cu',
    'HCG',
    'WBC',
    'FTA-ABS',
    '(衛采)HCV viral load',
    '新生兒聽損基因',
    '嚴重免疫缺乏症(複)',
    'Myelo.cell panel',
    'Anti-HAV Igg',
    'Thalassemia gene-自',
    'HCT',
    'PLT',
    'RBC',
    'MCV',
    '(中外)Quantiferon-TB',
    '男性荷爾蒙套組',
    '癌症風險基因套組',
    'Absolute Eosinophil Count',
    'MCH',
    'MCHC',
    'EBVCA-IgM',
    'Fe',
    'Free PSA',
    'LAP Score',
    'Anti-HCV',
    'HIV Viral load test',
    'HIV Ab 1+2 combo',
    '前降鈣素原(PCT)',
    'FK-506',
    'D_Dimer',
    'free Ca',
    'RDWSD',
    'HIV-1Ab(WesternBlot)',
    'Pro GRP',
    'Bleeding Time',
    'Testosteron',
    'IgE(new)',
    'Mg',
    'PSA',
    'ECP',
    'Sirolimus',
    'TRAb',
    'EBV DNA定量檢測',
    'PCT',
    '40項IgE過敏原套組',
    '長鏈脂肪酸檢查',
    'HLA Class I抗體鑑定',
    'HBV YMDD test',
    'HLA Class II抗體鑑定',
    'LA1',
    'LA ratio',
    'LA2',
    'LDL-C(計算)',
    'EB VCA IgM',
    'Rubella IgM',
    'ADA',
    'PDW',
    'Free-PSA',
    'Hb H',
    'MPV',
    'Widal & Weil Felix',
    '自費IGRA',
    '心血管風險基因套組',
    'HSV-2 IgG',
    'Transferrin',
    'JAK2基因V617F突變點',
    'Anti-parietal cell',
    'C.trachomatis IgG',
    'Lipase',
    'Cryoglobulin',
    'RDWCV',
    'Cyclosporin-A',
    'Vitamin B12',
    '酒精代謝基因ALDH2',
    'B2-Microglo',
    '25-OH Vitamin D',
    'HBV 病毒量檢測',
    'hsCRP',
    "第一孕期Down' s",
    'Antibody ID',
    'AST(GOT)',
    'DHEA-S',
    '染色體檢查, 血液(自)',
    'Anti-HTLV-I,II',
    'ACTH',
    'AMIA',
    '(中外)HCV viral load',
    '(賽特)Quantiferon-TB',
    '染色體檢查, 血液(健)',
    'Im marker',
    'Lym.cell panel',
    'Sugar water test',
    'Total T/B lym.',
    'Factor V assay',
    'Lymphocyte',
    'Monocyte',
    'Folate',
    '(美沙冬)HIV Combo',
    '血液染色體-優生補助',
    '(衛采)HBV viral load',
    'HSV-1 IgG',
    'ESR',
    'EB VCA IgG',
    '17-OHP',
    'Zn',
    'HCV VL (試辦)',
    'EBNA IgG',
    'K (Blood Gas)',
    'Anti-Thrombin III',
    'Phenytoin',
    'CPK',
    'HBV VL (試辦)',
    'ABO Typing',
    'ACTH(PM4:00)',
    'K',
    'EBVCA-IgG',
    'Blood Parasite',
    'Basemen mem.',
    'Phenobarbital',
    'CL',
    'RPR',
    'Everolimus(certican)',
    'Anti-HBc',
    'Intact-PTH',
    'Measles Ab IgM',
    'CMV-IgM',
    'Neutrophile',
    'Eosinophile',
    'Basophile',
    '游離輕鏈kappa/lambda',
    'Haptoglobin',
    'CA125',
    'BCR-ABLp210定量',
    '癌症風險基因NQO1',
    'LDL-C',
    'HCV Viral load test',
    'Total Cholesterol',
    'CA19.9',
    'EGFR突變分析(血液)',
    '',
    'Infectious DMarker',
    'OGTT (50g)',
    'C.trachomatis IgM',
    'Measles IgG',
    'HbA1c',
    'Factor VIII',
    'RH Typing',
    'Carbamazepine',
    'HSV-2 IgM',
    'Cortisol(17:00)',
    'T3 uptake',
    'Ammonia',
    'TG',
    '(腎科)Quantiferon-TB',
    '心血管風險基因AGT',
    'NIPT-進階檢測',
    'Methotrexate',
    'HCV genotyping(外檢)',
    'Euroflow LST',
    '肺癌監控癌症基因檢測',
    'HBV Genotype or YMDD',
    '有適應症男性脆折排除',
    '高雪氏症(賽諾菲)',
    'Cold hemaglutinin',
    'Na (Blood Gas檢體)',
    '龐貝氏症(賽諾菲)',
    'Factor VII assay',
    'Glucose (AC)-健檢',
    '女-法布瑞氏症-賽諾菲',
    'Osmolality',
    'CMV-IgG',
    'Glucose (AC)',
    'Prealbumin',
    '癌症風險基因SOD2',
    '自體血清抽取離心',
    'Mercury(Hg)',
    '心血管風險基因MTHFR',
    'Thrombin time(TT)',
    'HBV Viral load test',
    'Thyroglobulin Ab',
    '氧化壓力評估(MDA)',
    'CA',
    'β-2 Microglobubin',
    'β-HCG',
    'β-2 Microglobulin',
    'Alcohol(非駕駛)',
    '阿茲海默基因APOE',
    'Cortisol ( 16:00 )',
    '(嬌生)HBV viral load',
    'Chromium(Cr)',
    'Methyl alcohol',
    'BUN',
    '抗氧化維生素',
    'Testosterone(120min)',
    'Testosterone(0min)',
    'HSV-1 IgM',
    'Cholinesterase',
    'Lactate(Blood)',
    'Myco.pneu IgM',
    'Blood ketone(定量)',
    '登革熱抗原快速篩檢',
    'Toxo-IgG',
    'HLA-B5801(for Allopu',
    '癌症風險基因GSTP1',
    '(諾華)Quantiferon-TB',
    '心血管風險基因PAI1',
    'CA153',
    'Alcohol(駕駛)',
    'HDLC',
    '(衛采)Quantiferon-TB',
    'ASLO',
    'CA199',
    'HBV viral load(BC肝)',
    'PT(治療用)',
    'Anti-Cardio IgG',
    'Toxo-IgM',
    'C-Peptide',
    '陽光維生素',
    'G-6-P-D',
    'LDH',
    '血漿胺基酸(首次)',
    'Acetaminophen',
    'HIV Ag/Ab Combo(CHIV',
    'Infectous Dis.Marker',
    'Glycated albumin',
    'PRA Class II',
    'PRA Class I',
    'Cortisol(PM4:00)',
    'HLA-B27',
    'Homocysteine',
    'Gastrin',
    '癌症風險基因CYP1A1',
    '陣發性夜間血尿症檢查',
    'DR-70',
    'GLU-PC',
    'SCC',
    '新生兒篩檢(複檢)',
    '無適應症Fragile-X',
    '(必)BCR-ABLp210定性',
    'C.pneumonia IgG',
    'IgG4',
    'Lithium',
    'Theophyline',
    'Myco.pneu.IgM',
    'Dengue virus NS1 Ag',
    'Arsenic(As)',
    'Dengue virus IgM',
    '(諾華)HCV viral load',
    '代謝疾病mass(首次)',
    'Dengue virus IgG',
    'K (Blood Gas檢體)',
    '丙型干擾素釋放試驗',
    'Thalassemia gene-補',
    '(諾華)HBV viral load',
    'HIV 1+2免疫層析確認',
    'LDL-C(CAL)',
    'Mixing APTT',
    '直接抗球蛋白C3d試驗',
    'ICG(15分鐘)',
    'LDH-isoenzyme',
    '染色體基因檢測',
    'AADC',
    '(必治妥)HCV viral lo',
    '晶片羊水異常血液確診',
    'DHT(Dihydrotestoster',
    'FISH analysis of SRY',
    '過敏體質分析套組',
    'BRCA遺傳性基因檢測',
    'OGTT(75g)--180min',
    'Zn-AAS',
    '有適應症Fragile-X',
    'TdT stain',
    'Mixing PT',
    'UA',
    'Anti-CCP',
    'TBIL',
    'Aluminum(Al)',
    'Anti-PL-IgG',
    'HCV 病毒量檢測',
    'H.Pylori Ab',
    'Bleeding time(Ivy)',
    'PSA-Free',
    '(必)Quantiferon-TB',
    'Valproic Acid',
    'Pb',
    'NSE',
    'ANA',
    'HBcAb-IgM',
    'FSH(30min)',
    'FSH(45min)',
    'FSH(0min)',
    'LH(120min)',
    'LH(45min)',
    'FSH(90min)',
    'LH(90min)',
    'LH(30min)',
    'LH(60min)',
    'FSH(60min)',
    'FSH(120min)',
    'LH(0min)',
    'FSH(15min)',
    'LH(15min)',
    '嚴重複合型免疫缺乏症',
    '公費IGRA',
    'Anti-Cardio IgM',
    'IgG',
    'Rheumatoid F.',
    'Glucose',
    'HLA-ABC',
    'HLA-DQ/DR',
    'HCV viral load(BC肝)',
    'GLO',
    '新生兒篩檢',
    'Factor VIII inhibito',
    'Ferritin',
    'Chromogranin A(CgA)',
    'HLA-B1502(for Carbam',
    'RBC Morphology',
    '子癲前症篩檢',
    'Thyroglbulin',
    'Aty-Lym',
    'BNP',
    'A1-antitrypsin',
    'EBEA IgG',
    'Cryoglobulin ID',
    'vWF',
    'HCV-1b NS5A基因檢測',
    'Glucose(75g, AC)',
    'MG',
    '(糖尿病DOPT專案)IGRA',
    'TSH',
    'Blast',
    'Albumin',
    'A/G',
    'Estradiol(120min)',
    'T Lym. Crossmatching',
    'B Lym. Crossmatching',
    'Estradiol(0min)',
    'Prog',
    'AFP',
    '(LTBI醫護)IGRA',
    '龐貝氏症篩檢',
    'HIV Ab 1+2 (美沙冬)',
    'Protein-EP',
    'AMY',
    'CKMB',
    'HIV EIA 孕婦愛滋篩檢',
    'Calcitonin',
    '凝血報告',
    'Cortisol ( 8:00 )',
    'HCV 基因型檢測(健保)',
    'Insulin(IRI)',
    'Estradiol',
    'Normoblast',
    'PTH-Intact',
    'JAK2基因V617突變檢測',
    '(益詮)HBV viral load',
    '(益詮)Quantiferon-TB',
    '(益詮)HCV viral load',
    'SMA(新生兒篩檢)',
    "Down's(4指標)",
    'ALKP',
    'HBsAg(定量)',
    '(必)BCR-ABLp210定量',
    'Cortisol(10:00)',
    '(嬌生)Quantiferon-TB',
    'Rubella IgG',
    'Cortisol(16:00)',
    'ACTH(AM8:00)',
    'CRP',
    'MPL W515K基因檢測',
    'Anti-HIV',
    '心理抗壓基因COMT',
    'Type II CALR基因檢測',
    'Type I CALR基因檢測',
    'Glucose(75g, 2h)',
    'MPL W515L基因檢測',
    '心理抗壓基因5HTT',
    'Promyelocyte',
    'IgA',
    'Band',
    'C-peptide',
    'Myelocyte',
    'IGF-1',
    'Myoglobin',
    'CRP High Sensitive',
    '女性抗初老賀爾蒙',
    'Aspergillus 抗原測定',
    'AchR-Ab',
    'Cl',
    'Zn(Zinc)',
    '男-法布瑞氏症-賽諾菲',
    'Factor IX',
    'ASD (Androstenedione',
    '非新生兒聽損基因',
    'HIV-1 Ab(Western blo',
    'CMV PP65 antigenemia',
    'PFA (ADP)',
    '新生兒篩檢-ALD',
    'Anti-Int.Substace',
    '(自費)Quantiferon-TB',
    'HBeAg',
    'Cryptococcus',
    'Free-T4',
    '活力男性賀爾蒙',
    'Anti-HIV 1,2(EIA)',
    'Alcohol篩檢-非駕駛',
    'PFA (EPI)',
    'Vancomycin(trough)',
    '新生兒自費篩檢GMFB',
    'DBIL',
    'B2glycoprotein-I IgG',
    '脆折症Fragile-X',
    'Digoxin',
    'VZV IgG',
    'Amebiasis Ab',
    '女性荷爾蒙套組',
    'Ca',
    'Allergen Phadiatop(CAP Phadiatop)',
    'IgE',
    'FE',
    'CEA',
    'Coombs test direct',
    'Cystatin C',
    'VZV IgM',
    'Metamyelocyte',
    'G.G.T',
    'Measles Ab IgG',
    'Anti-Smooth muscle',
    '(嬌生)HCV viral load',
    '(必)BCR-ABLp190定性',
    'Microbilirubin',
    '(必治妥)HBV viral lo',
    'BCR-ABLp210定性',
    'OGTT(75g ) -- 30min',
    'BUN(洗腎前)',
    'Anti-HIV Quick test',
    'hGH 11:30',
    'OGTT(75g ) -- 60min',
    'OGTT(75g ) -- 90min',
    'hGH 8:30',
    'C-Peptide--120min',
    'hGH 12:00',
    'C-Peptide--60min',
    'hGH 9:00',
    'Rh phenotyping',
    'hGH 10:00',
    'C-Peptide--180min',
    '羊水異常血液染色體',
    '抗體力價鑑定',
    'P1NP',
    '染色體檢查, 血液',
    'FT4',
    'C-Peptide--30min',
    'hGH 8:00',
    'Indium',
    'hGH 10:30',
    '直接抗球蛋白IgG試驗',
    '(愛滋匿篩)Xpert HIV1',
    'hGH 9:30',
    'HIV(ELISA)<孕婦,性病',
    'Pb(特勞)',
    'C-Peptide--90min',
    '無適應症男性脆折排除',
    'PML-RARα(bcr1)定性',
    'Allergen Phadiatop',
    'Vancomycin(peak)',
    'hGH 7:30',
    'MetHb',
    'BCR-ABLp190定性',
    'hGH 11:00',
    'Euroflow BCP-ALL MRD',
    'Lipoprotein-EP',
    'OGTT(75g ) -- 120min',
    'Euroflow B-CLPD',
    'Blood Gas(Artery)',
    'Free-PSA(健診)',
    'Antibody Screening',
    'Brca Chip',
    'CPK-isoenzyme',
    'Elution and Ab ID',
    'BUN(洗腎後)',
    'Insulin-180min',
  ],
  Urine: [
    'Urine-Creatinine',
    'Morphine(U)',
    '雌激素代謝評估',
    'UUN(R.U)',
    'Protein EP,Urine',
    'Benzodiazepine(U)',
    '尿沉渣Cast',
    'Xenoestrogens Profil',
    'ACR',
    'Urine Osmolality',
    'Dysmorphic RBC (U)',
    'Immuno EP Urine',
    'U-Cl',
    'XenoestrogensProfile',
    '尿沉渣Other',
    'Amphetamine(U)',
    'Urine Routine',
    'U-Mg',
    'U-Na',
    'UPCR',
    'U-Ca',
    'Microalbumin(R.U)',
    'Bacteria',
    'Mucus',
    'Crystal',
    '代謝健康評估',
    'U-Hg',
    '尿液有機酸',
    '藥物濫用檢驗套餐',
    'PRO',
    'OB',
    '愷他命(Ketamine)',
    'GLU',
    'U-K',
    'PH',
    'U-Cd',
    'Porphyrin',
    'Appearance',
    'Benzodiazepine',
    'URO',
    'KET',
    'BIL',
    'S.G.',
    'NIT',
    '尿沉渣WBC',
    '尿沉渣Ep.Cell',
    '尿沉渣RBC',
    '氧化壓力評估(8OHdG)',
    'TP (random urine)',
    'Color',
    'LEU',
    'Random Urine P',
    'Reducing substance-U',
    'T. vaginalis Ag',
    'U-IP',
    'Coproporphyrin',
    'Urine Chromium',
    'Amphetamine篩檢',
    'Legionella Ag, Urine',
    'U-Ni',
    'UTP(Random urine)',
    'Paraquat(Urine',
    '腸道菌叢評估',
    'Morphine篩檢',
    'Other',
    'Gr,B strepto Ag-U',
    'S. pneumonia Ag,urin',
    'Pregnancy Test',
    'Microalb',
    'Random U-Pb(特勞)',
    'U-UN',
    'Uric Acid(other)',
    'U-Pb',
  ],
  Stool: [
    'Rota virus Ag',
    'Ova',
    'Consistency',
    'Occult Blood',
    'Mucus',
    'Pus',
    'Color',
    'Ova Conc.',
    '困難梭菌抗原及毒素',
    'Stool Routine',
    'pH(Stool)',
    'OB(Chemical Method)',
    'Neutral Fat(Sudan 3)',
    'Reducing substance-S',
    'PH(Stool)',
    '困難梭菌Ag & Toxin',
    '蟯蟲膠片',
    'Amoeba',
    'OB_value',
    'OB_result',
    'APT test',
  ],
  'Pleural effusion': [
    'Total Protein(other)',
    'Glucose (other)',
    'LDH (other)',
    'Pleural Effusion',
    'CEA (other)',
    'ADA(Pleural)',
    'CA-12-5(other)',
    'LDH ( other )',
    'CEA ( other )',
    'ADA(PE)',
    'Pleural Effusion-Lympho',
    'Pleural Effusion-Rivaltas',
    'Pleural Effusion-Color',
    'Pleural Effusion-WBC',
    'Pleural Effusion-Apearance',
    'Pleural Effusion-Seg',
    'Pleural Effusion-RBC',
    'Pleural Effusion-Mono',
    'Pleural Effusion-S.G.',
    'Pleural Effusion-Mesothelial cell',
    'Pleural Effusion-Histocyte',
  ],
  'Ascites Fluid': [
    'ADA(Ascites)',
    'Ascitis',
    'ALB (Other)',
    'Albumin(other)',
    'Ascitis-Apearance',
    'Ascitis-Rivaltas',
    'Ascitis-Color',
    'Ascitis-WBC',
    'Ascitis-S.G.',
    'Ascitis-RBC',
    'Ascitis-Mono',
    'Ascitis-Lympho',
    'Ascitis-Seg',
    'Ascitis-Histocyte',
    'Ascitis-Mesothelial cell',
    'Total Protein(other)',
    'LDH (other)',
  ],
  'Gastric biopsy': ['H.pyroli(clotest)', 'H. pylori(clotest)'],
  Other: [
    'LDH (other)',
    'ADA (Other)',
    'TG (Other)',
    'CEA (other)',
    'pH(other)',
    'ADA(PE)',
    'Occult Blood',
    'ALB (Other)',
    'Creatinine(other)',
    'ADA(Other)',
    'Albumin(other)',
    'Uric Acid(other)',
    'EGFR突變分析(體液)',
    'ADA(Ascites)',
    'PH(other)',
    '罕病檢驗項目-代送',
    'CA-12-5(other)',
    'Stone analysis',
    'Lipase(other)',
    'LDH ( other )',
    'Stat Gram stain',
    'Triglyceride(other)',
    'BodyFluid-pH',
    'CA-15-3(other)',
    'CEA ( other )',
    'ADA(Pleural)',
    'Lactate (Other)',
    '頭髮重金屬檢測分析',
    'Amylase ( other )',
    '*EGFR突變分析(體液)',
    'Lactate(Other)',
    'Total Protein(other)',
    'CA-19-9(other)',
    'Glucose (other)',
    'AFP ( other )',
    'T-CHO (Other)',
    'AFP (other)',
    'T-Cholesterol(other)',
    'UPCR',
    'Lipase (Other)',
    'Urine-Creatinine',
    'UA (Other)',
  ],
  'Synovial Fluid': [
    'Synovial-Mucin clot',
    'Synovial-WBC',
    'Synovial-Color',
    'Synovial-String Test',
    'Synovial-Seg',
    'Synovial-RBC',
    'Synovial-Lympho',
    'Synovial-Mono',
    'Synovial fluid',
    'Crystal exam.',
  ],
  腹膜透析液HD: [
    'Glu(0 hr Dialysate)',
    'Cre(0 hr Dialysate)',
    'Cre(2 hr Dialysate)',
    'Glu(2 hr Dialysate)',
    'Cre(4 hr Dialysate)',
    'Glu(4 hr Dialysate)',
  ],
  CSF: [
    'CSF-Albumin',
    'H.Influenzae B',
    'S.pneumoniae',
    'CSF',
    'N.Men.ACYW',
    'N.Men.B/E.coli',
    'CSF-CMV IgM',
    'Strep.B Direct',
    'CSF-IgG',
    'CSF-Immuno EP',
    'CSF-LDH',
    'CSF-pH',
    'CSF Total Protein',
    'CSF-GLU',
    'CSF-Color',
    'CSF-RBC',
    'CSF-Seg',
    'CSF-Pandy',
    'CSF-Mono',
    'CSF-Apearance',
    'CSF-Lympho',
    'CSF-WBC',
    'TPPA(CSF)',
    'Microalbumin(CSF)',
    'CSF-Toxo IgM',
    'CSF-CRP',
    'CSF-Lactate',
    'CSF-Glucose',
    'CSF-Total Protein',
    'Crypto.(CSF)',
    'CSF-HSV-1 IgM',
    'CSF-HSV-2 IgM',
    'CSF-HSV-1 IgG',
    'CSF-VDRL',
    'CSF-HSV-2 IgG',
    'Protein EP (CSF)',
    'CSF-CMV IgG',
    'EBVCA IgM(CSF)',
    'CSF-Toxo IgG',
    'CMV IgG (CSF)',
  ],
  Semen: [
    'PR',
    'NP',
    'Apearance-Semen',
    'Sperm count',
    'Volume-Semen',
    'PH-Semen',
    'Motility 1hr',
    'Morphology',
    'Semen Routine',
    'WBC',
    'RBC',
    'Liquation Time-Semen',
    'EPI',
    'Total motility(PR+NP)',
  ],
  '24hr Urine': [
    '24 hr U-Cortisol',
    '24 hr U-Mg',
    '24hr U-Ca',
    'Urine Aldosterone',
    '24 hr U-Creatinine',
    '24hr catecholamines',
    '24 hr U-Cl',
    '24hr U Arsenic(As)',
    '24 hr U-TP',
    '24hr U-Cortisol',
    '24 hr U-K',
    '24 hr U-Na',
    '24 hr U-P',
    '24hr U Cadmium',
    '24hr U-TP',
    '24 hr U-UA',
    'Urine Cortisol',
    '24hr U-Osmolality',
    '24 hr U-UN',
    '24hr U-Cu',
  ],
  'Nasopharyngeal swab/Nasal wash': [
    '肺炎黴漿菌抗原(快篩)',
    'Inf. virus B RNA',
    'Inf. virus A RNA',
    'M.pneumoniae',
    'Coronavirus 229E',
    'C.pneumoniae',
    'RSV',
    'B.pertussis',
    'Adenovirus',
    'Metapneumovirus',
    'B.parapertussis',
    'M.pneumonia',
    '腺病毒快速抗原(快篩)',
    '呼吸道融合病毒抗原',
  ],
  'Bone Marrow': [
    'Myelo.cell panel(BM',
    '染色體檢查, 骨髓',
    'Bone marrow exam.',
    'Euroflow BCP-ALL',
    'Euroflow AML/MDS',
    'Euroflow LST',
    'Lym.cell panel(BM)',
    '染色體檢查, 骨髓(健)',
    'Euroflow PCST',
    '染色體檢查, 血液(健)',
    '染色體檢查, 骨髓(自)',
  ],
  羊水: [
    '羊水染色體(高齡產)',
    '羊水染色體',
    'Thalassemia gene羊水',
    'NIFTY異常羊水染色體',
    '羊水染色體(低收戶)',
    '晶片羊水染色體檢驗',
    '雙親帶因者羊水SMA',
  ],
  Tissue: ['*RAS突變分析', '*EGFR突變分析(切片)', '核心型癌症基因檢測', 'BRCA基因檢測(FFPET)'],
  'BAL(Bronchial alveolar lavage)': ['BAL Routine', 'Aspergillus Ag(BAL)'],
  'Cervical swab': ['HPV DNA Genotyping', 'C.trachomatis DNA'],
  Nasopharynx: ['腺病毒快速抗原(快篩)'],
  'Gastric fluid': ['Occult Blood'],
  流產組織: ['流產組織染色體'],
  'Throat/Pharynx': ['Gr.A strep Ag'],
  Sputum: ['Aspergillus Ag(SP)'],
  'Pericardial fluid': ['Pericardial fluid'],
};
