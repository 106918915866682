import { cloneDeep, unset } from 'lodash';
import { getUiConfig } from '@/utils/uiConfig';
import ApiHelper from '../../../utils/ApiHelper';
import { FEEDBACK } from '../../../utils/constants';
import ConditionArray from '../../../utils/query/core/ConditionArray';
import ESQuery from '../../../utils/query/core/ESQuery';
import ChartConfig, { seriesMapper } from './ChartConfig';
import { BasicChartQueryParams, ChartKey, ChartOption, ChartParams, ChartViewState, RawItem } from './Interface';
import TFIDFMap from './TFIDFMap';

const OPTION = {
  title: {
    show: false,
  },
  grid: {
    top: 10,
    right: 30,
    bottom: 20,
    left: 0,
  },
};
export default abstract class BasicChartConfig<RawItemType extends RawItem> extends ChartConfig<RawItem> {
  protected params!: BasicChartQueryParams;

  constructor(key: ChartKey) {
    super(key);
    this.merge(OPTION);
  }

  public get isNoDataChart(): boolean {
    return (getUiConfig().Analyze.StatisticSummary.no_data_charts || []).includes(this.key);
  }

  public setChartParams(chartParams: ChartParams) {
    this.params = {
      includes: new ConditionArray([]),
      excludes: new ConditionArray([]),
      chartParams,
    };
  }

  public setChartFilters(includes: ConditionArray, excludes: ConditionArray) {
    this.params = {
      ...this.params,
      includes,
      excludes,
    };
  }

  public setChartSelection(selection: string) {
    this.params.chartParams.selection = selection;
  }

  public setBucketByPatient(byPatient: boolean) {
    this.bucketByPatient = byPatient;
  }

  protected processQueryParams(): BasicChartQueryParams {
    return this.params;
  }

  protected getAggsQueryForSelections(selections: string[]) {
    const fullAggsQuery = this.getBaseAggsQuery();
    selections.forEach((selection) => {
      this.params.chartParams.selection = selection;
      const agg = this.aggsQuery;
      fullAggsQuery[`result##${selection}`] = agg;
    });
    return fullAggsQuery;
  }

  protected getTFIDFField(chartKey: ChartKey): string {
    const { selection } = this.params.chartParams;
    const TFIDFKey = TFIDFMap.get(chartKey);

    if (TFIDFKey === undefined) {
      throw Error(`Not defined chartKey type: ${chartKey}`);
    }

    switch (chartKey) {
      case ChartKey.ICD10:
      case ChartKey.ICD9:
        switch (selection) {
          case 'primary':
            return `${TFIDFKey}-1`;
          case 'all':
            return `${TFIDFKey}`;
          case 'top-5':
            return `${TFIDFKey}-20`;
          default:
            throw Error(`Not defined selection type: ${selection}`);
        }
      case ChartKey.MedicalOrder:
        return `${TFIDFKey}-${selection}`;
      default:
        return TFIDFKey;
    }
  }

  public async applyData(
    params: BasicChartQueryParams,
    api: ApiHelper,
    userId: string,
    trackContent: any,
    priority: number
  ): Promise<ChartOption> {
    const formatter = (seriesList: any) => this.tooltipFormatter(seriesList, api, userId, trackContent);
    this.merge({
      tooltip: {
        formatter,
      },
    });

    this.params = cloneDeep(params);

    if (params.sortChartByAI === true && TFIDFMap.has(this.chartKey)) {
      this.resultSize = 100;
    } else if (params.chartParams.viewState === ChartViewState.Maximize) {
      this.resultSize = 50;
    } else {
      this.resultSize = 10;
    }

    if (
      !this.disallowBucketChange &&
      params.bucketByPatient !== undefined &&
      params.bucketByPatient !== this.bucketByPatient
    ) {
      // Set bucketing setting on config if already preset by tab (ex. sharing links).
      this.setBucketByPatient(params.bucketByPatient);
    }

    const query = new ESQuery(this.processQueryParams(), this);

    this.rawData = await api.search(query.getQuery(), priority).catch((error) => {
      console.error(error);
    });
    this.recordCount = this.rawData.hits.total.value;
    this.patientCount = this.rawData.aggregations.patientNum.value;
    this.dataCount = this.rawData.aggregations.result.buckets.length;
    this.validCount = this.rawData.aggregations.valid_record?.value || 0;

    if (params.sortChartByAI === true && TFIDFMap.has(this.chartKey)) {
      api.setLogQueryInfo({
        type: FEEDBACK.QUERY_TYPE.CHART_TFIDF_AGGREGATE,
        conditions: params,
      });
      this.rawData.aggregations.result.buckets = await api
        .getTfidf(
          {
            agg_term_list: this.rawData.aggregations.result.buckets.map((record: any) => ({
              ...record,
              count_unique_patient: record.patient_count,
            })),
            input_field: this.getTFIDFField(this.chartKey),
            patient_num: this.patientCount,
            return_size: params.chartParams.viewState === ChartViewState.Normal ? 10 : 100,
          },
          priority
        )
        .catch((error) => {
          console.error(error);
        });
    }

    this.data = this.createChartData(this.rawData.aggregations.result.buckets);
    this.reloadChartItemMap();

    unset(this.option, 'series.data');
    this.merge({
      series: { data: this.data.map(seriesMapper) },
    });

    this.setAxisData();
    return Promise.resolve(this.getOption());
  }
}
