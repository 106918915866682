import { DescriptionType } from '@/utils/descriptionCache';
import { ChartItem, ChartKey, RawItem } from '@/components/charts/core/Interface';
import { ConditionData } from '@/utils/conditions/core';
import ChartConfig from './core/AdvChartConfig';
import { removePoint } from '../utils/utils';

const XAXIS_LABEL = ['0-9', '10-19', '20-29', '30-39', '40-49', '50-59', '60-69', '70-79', '80-89', '90-99', '100+'];

const OPTION = {
  xAxis: {
    type: 'category',
    data: XAXIS_LABEL,
    triggerEvent: true,
    axisLabel: {
      interval: 0,
      rotate: 45,
      margin: 20,
      align: 'center',
    },
  },
  yAxis: {
    type: 'value',
    axisLabel: {
      formatter: removePoint,
    },
  },
  series: {
    type: 'bar',
    barWidth: '70%',
    animation: false,
  },
  grid: {
    top: 80,
  },
};

interface AgeRawItem extends RawItem {
  from: number;
  to?: number;
  count_unique_patient: {
    value: number;
  };
}

interface DiagnosisRawItem extends RawItem {
  count_unique_patient: {
    value: number;
  };
}

export default class AdvAgeConfig extends ChartConfig<AgeRawItem> {
  protected descriptionType = DescriptionType.ICD10;

  constructor() {
    super(ChartKey.AdvAge);

    this.merge(OPTION);
  }

  protected createConditionFromItem(): ConditionData {
    throw new Error('Method not implemented.');
  }

  protected createChartItem(rawItem: AgeRawItem, index: number): ChartItem {
    return {
      key: XAXIS_LABEL[index],
      value: rawItem.count_unique_patient.value,
      code: XAXIS_LABEL[index],
      name: XAXIS_LABEL[index],
      color: '#333f6b',
      percentage: Math.round((rawItem.count_unique_patient.value / this.recordCount) * 100.0),
    };
  }

  protected get aggsQuery(): Record<string, unknown> {
    return {
      range: {
        field: 'AGE',
        ranges: [
          {
            from: 0,
            to: 10,
          },
          {
            from: 10,
            to: 20,
          },
          {
            from: 20,
            to: 30,
          },
          {
            from: 30,
            to: 40,
          },
          {
            from: 40,
            to: 50,
          },
          {
            from: 50,
            to: 60,
          },
          {
            from: 60,
            to: 70,
          },
          {
            from: 70,
            to: 80,
          },
          {
            from: 80,
            to: 90,
          },
          {
            from: 90,
            to: 100,
          },
          {
            from: 100,
          },
        ],
      },
      aggs: {
        count_unique_patient: {
          cardinality: {
            field: 'CHART_NO',
          },
        },
      },
    };
  }
}
