<template>
  <div class="d-inline-flex align-items-center constraint-capsule" @click.stop="">
    <div class="constraint-text">Primary Diagnosis</div>
    <a-select
      default-value="all"
      @change="changeDiagnosisRange"
      :getPopupContainer="(trigger) => trigger.parentNode"
      class="constraint-select"
    >
      <a-icon slot="suffixIcon" type="caret-down" />
      <a-select-option value="all"> any </a-select-option>
      <a-select-option value="primary"> 1st code </a-select-option>
      <a-select-option value="top-5"> 1st~5nd code </a-select-option>
    </a-select>
    <b-img src="@/assets/images/icon_remove_blue.svg" @click="deleteConstraint" class="delete-button" />
  </div>
</template>
<script>
import { mapMutations } from '@/store';
import { cloneDeep } from 'lodash';

export default {
  name: 'DiagnosisRangeRender',
  props: {
    condition: Object,
  },
  methods: {
    ...mapMutations('condition', ['setCondition', 'setExcludedCondition']),
    getCondition() {
      const result =
        this.condition.conceptType === 'include'
          ? cloneDeep(this.$store.state.condition.conditions)
          : cloneDeep(this.$store.state.condition.excludedConditions);
      const resultIndex = result.findIndex((item) => item.time === this.condition.time);
      let tagsResult = result[resultIndex].tags;
      const removeTags = ['all', 'primary', 'top-5'];
      removeTags.forEach((item) => {
        tagsResult = tagsResult.filter((tag) => item !== tag);
      });
      return { result, resultIndex, tagsResult };
    },
    changeDiagnosisRange(value) {
      const { result, resultIndex, tagsResult } = this.getCondition();
      tagsResult.push(value);
      result[resultIndex].tags = tagsResult;

      if (this.condition.conceptType === 'include') {
        this.setCondition(result);
      } else {
        this.setExcludedCondition(result);
      }
    },
    deleteConstraint() {
      const { result, resultIndex, tagsResult } = this.getCondition();
      tagsResult.push('all');
      result[resultIndex].tags = tagsResult;
      result[resultIndex].diagnosisRange = false;

      if (this.condition.conceptType === 'include') {
        this.setCondition(result);
      } else {
        this.setExcludedCondition(result);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.constraint-capsule {
  padding: 5px 10px;
  background: #edf5fb;
  border-radius: 100px;
  margin-left: 10px;
}

.constraint-text {
  height: 18px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #333333;
  margin-right: 10px;
}

.constraint-select {
  width: 140px;
  height: 26px;
  margin-right: 10px;
}

.delete-button {
  cursor: pointer;
  size: 13px;
}

/deep/ .ant-select-selection--single {
  border-radius: 53px;
  border: 1px solid #e0e0e0;
  height: 26px;
}

/deep/ .ant-select-selection-selected-value {
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #334e97;
}

/deep/ .ant-select-arrow {
  color: #334e97;
}
</style>
