export default function getPatientTopRecordAggsQuery(
  conditionQuery,
  includes,
  { highlight, additionalAggs, slicePatientList = [], sortField = ['IN_DATE'] } = {}
) {
  const aggsQuery = {
    aggs: {
      patientNum: {
        filters: {
          filters: slicePatientList.map((item) => ({ bool: { should: [{ term: { CHART_NO: item } }] } })),
        },
        aggs: {
          top: {
            top_hits: {
              sort: [...sortField.map((field) => ({ [field]: { order: 'asc' } }))],
              _source: {
                includes,
              },
              ...highlight,
              size: 1,
            },
          },
          ...additionalAggs,
        },
      },
    },
  };
  return {
    ...conditionQuery,
    ...aggsQuery,
    size: 0,
    track_total_hits: true,
  };
}
