import { API_TARGET, KEY } from '@/utils/constants';
import { READMISSION_STANDARD, SEARCH_PLACEHOLDER } from '@/utils/string';
import { ChartKey } from '../../../components/charts/core/Interface';

export default {
  SearchForm: {
    diagnosis: true,
    procedure: false,
    'medical-order': true,
    procedureTarget: API_TARGET.PCS,
    procedureKey: KEY.ICD10_LIST,
    suggestion: {
      types: ['Diagnosis', 'Medical Order'],
    },
    exclude: {
      types: ['Diagnosis', 'Medical Order'],
    },
    age: true,
    time: {
      start: 1472688000, // 2016/09/01
      end: 1588204800, // 2020/04/30
    },
    OPD_Searchable: true,
    rightClick: true,
    searchPlaceholder: {
      DX: SEARCH_PLACEHOLDER.DX,
      MED: SEARCH_PLACEHOLDER.MED,
      ORDER: SEARCH_PLACEHOLDER.ORDER,
      DOCTOR: SEARCH_PLACEHOLDER.DOCTOR,
    },
    standard: READMISSION_STANDARD.ANY,
  },
  Analyze: {
    export: false,
    share: true,
    save: true,
    StatisticSummary: {
      riskUI: false,
      charts: [ChartKey.Stay, ChartKey.Symptom],
      all_charts: [ChartKey.ICD10, ChartKey.MedicalOrder, ChartKey.Visit],
    },
    LabResult: true,
    PatientResult: {
      tabs: ['PATH_REPORT_1', 'PATH_REPORT_2', 'TREATMENT_PATH', 'OP_NOTE', 'EXAM_FULL_TXT', 'ADM_PAHDG'],
      OPD_tabs: ['SUBJ_NOTE', 'OBJ_NOTE', 'EXAM_FULL_TXT', 'ADM_PAHDG'],
      all_tabs: ['EXAM_FULL_TXT', 'ADM_PAHDG'],
    },
    OPD_Searchable: true,
    rightClick: true,
  },
  PatientInformationModal: {
    Timeline: {
      additionalQueryFields: [
        'PATH_REPORT_1',
        'PATH_REPORT_2',
        'TREATMENT_PATH',
        'OP_NOTE',
        'EXAM_FULL_TXT',
        'SECTION_NAME',
        'ICD_20_ENG_FULL',
      ],
      additionalOutPatientQueryFields: [
        'ICD',
        'EXAM_FULL_TXT',
        'ADM_PAHDG',
        'SUBJ_NOTE',
        'OBJ_NOTE',
        'CHCASENO',
        'SECTION_NAME',
        'ICD_20_ENG_FULL',
      ],
      outPatient: true,
    },
    PatientLabTable: true,
  },
  UserGuide: {
    path: 'userGuide/userGuide.pdf',
    cohortStudyPath: 'userGuide/userGuideV3.pdf',
  },
};
