import { MutationTree } from 'vuex';
import ApiHelper from '@/utils/ApiHelper';
import { PAGES } from '../utils/constants';
import ConditionModule from './session/ConditionModule';
import User from './user/User';
import EventSearch from './session/EventSearch';
import EventResult from './session/EventResult';
import { Session, AdvancePortal, Indicator, EventSession, ByEventSession } from './session';

type exportIdList = {
  [key: string]: number;
};

function getStoreConfig() {
  const rootState = {
    loaded: false,
    tabName: PAGES.STATISTIC_SUMMARY,
    rootId: '',
    iPD: true,
    exportLoading: false,
    counter: 0,
    queueEmpty: true,
    demoVideoIndex: 1,
    demoVideoVisible: false,
    medicalOrderCategory: [],
    scrollTop: 0,
    scrollLeft: 0,
    confirmIcdMap: true,
    settingMenu: true,
    advancePortal: { SearchStateObject: { timePeriod: {} } },
    api: {},
    hintReadyDataset: false,
    feedbackHintVisable: false,
    EMRSearchTabName: PAGES.SEARCH,
    exportIdMappingOPDCount: {} as exportIdList,
    exportIdMappingIPDCount: {} as exportIdList,
    EMRSearchExportIdList: [] as string[],
    cohortStudyExportIdList: [] as string[],
    EMRSearchLandingIndex: 0,
  };
  type RootState = typeof rootState;

  const mutations: MutationTree<any> = {
    setTabName(state: RootState, tabName: any) {
      state.tabName = tabName;
    },
    setRootId(state: RootState, rootId: any) {
      state.rootId = rootId;
    },
    setExportLoading(state: RootState, exportLoading: any) {
      state.exportLoading = exportLoading;
    },
    setCounter(state: RootState, counter: any) {
      state.counter = counter;
    },
    setQueueEmpty(state: RootState, queueEmpty: any) {
      state.queueEmpty = queueEmpty;
    },
    setDemoVideoIndex(state: RootState, demoVideoIndex: any) {
      state.demoVideoIndex = demoVideoIndex;
    },
    setDemoVideoVisible(state: RootState, demoVideoVisible: any) {
      state.demoVideoVisible = demoVideoVisible;
    },
    setMedicalOrderCategory(state: RootState, data: any) {
      state.medicalOrderCategory = data;
    },
    setScrollTop(state: RootState, scrollTop: any) {
      state.scrollTop = scrollTop;
    },
    setScrollLeft(state: RootState, scrollLeft: any) {
      state.scrollLeft = scrollLeft;
    },
    setConfirmIcdMap(state: RootState, confirmIcdMap: any) {
      state.confirmIcdMap = confirmIcdMap;
    },
    setSettingMenu(state: RootState, settingMenu: any) {
      state.settingMenu = settingMenu;
    },
    setAdvTimePeriod(state: RootState, timePeriod: any) {
      state.advancePortal.SearchStateObject.timePeriod = timePeriod;
    },
    setApi(state: RootState, api: ApiHelper) {
      state.api = api;
    },
    setHintReadyDataset(state: RootState, hintReadyDataset: boolean) {
      state.hintReadyDataset = hintReadyDataset;
    },
    setFeedbackHintVisable(state: RootState, feedbackHintVisable: boolean) {
      state.feedbackHintVisable = feedbackHintVisable;
    },
    setEMRSearchTabName(state: RootState, EMRSearchTabName: string) {
      state.EMRSearchTabName = EMRSearchTabName;
    },
    setExportIdMappingCount(state: RootState, data: { id: string; OPDCount: number; IPDCount: number }) {
      state.exportIdMappingOPDCount[data.id] = data.OPDCount;
      state.exportIdMappingIPDCount[data.id] = data.IPDCount;
    },
    setEMRSearchExportIdList(state: RootState, id: string) {
      state.EMRSearchExportIdList.push(id);
    },
    setCohortStudyExportIdList(state: RootState, id: string) {
      state.cohortStudyExportIdList.push(id);
    },
    setEMRSearchLandingIndex(state: RootState, EMRSearchLandingIndex: any) {
      state.EMRSearchLandingIndex = EMRSearchLandingIndex;
    },
  };

  return {
    state: rootState,
    mutations,
    modules: {
      condition: ConditionModule,
      session: Session,
      user: User,
      advancePortal: AdvancePortal,
      indicator: Indicator,
      eventSearch: EventSearch,
      eventResult: EventResult,
      cohortSession: EventSession,
      byEventSession: ByEventSession,
    },
  };
}

export default getStoreConfig;
