<template>
  <div>
    <a-tooltip
      placement="bottomLeft"
      overlayClassName="groupandmap-tag-tooltip"
      :get-popup-container="getPopupContainer"
      :destroyTooltipOnHide="true"
    >
      <template slot="title">
        <div class="codesearch-menu-content hover-content">
          <VuePerfectScrollbar @wheel.stop>
            <b-row
              no-gutters
              v-for="option in hoverOptions"
              :title="option.description"
              :key="option.code"
              class="row-option"
            >
              <b-col>
                <b-row no-gutters class="code-search-hover-menu">
                  <b-col
                    cols="2"
                    class="d-flex align-items-center"
                    :style="{
                      'margin-left': `${getExpandMargin(option.level)}px`,
                    }"
                  >
                    <div class="code-hover-text">
                      {{ `${option.code}` }}
                    </div>
                  </b-col>
                  <b-col
                    class="d-flex align-items-center"
                    :style="{
                      flex: `0 0 calc(100%/12*10 - ${getExpandMargin(option.level)}px)`,
                      'max-width': `calc(100%/12*10 - ${getExpandMargin(option.level)}px)`,
                    }"
                  >
                    <div style="max-width: calc(100% - 64px)">
                      <div class="icd-hover-description">
                        {{ option.description }}
                      </div>
                      <div class="icd-hover-description">
                        {{ option.chineseDescription }}
                      </div>
                    </div>
                    <b-img
                      src="@/assets/images/icon_expand_open_blue.svg"
                      class="icon-expand ml-auto"
                      v-if="!option.billable && !option.expand"
                      @click.stop="openHoverExpand(option)"
                    />
                    <b-img
                      src="@/assets/images/icon_expand_close_blue.svg"
                      class="icon-expand ml-auto"
                      v-if="!option.billable && option.expand"
                      @click.stop="closeHoverExpand(option)"
                    />
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </VuePerfectScrollbar>
        </div>
      </template>
      <div @mouseover="mouseoverTag(tag)">
        <span v-if="tag.partial">
          <div class="partial-dot"></div>
        </span>
        <span class="capsule-abbr">DX</span>
        <span class="capsule-code">
          {{ tag.code }}
        </span>
        <b-img
          src="@/assets/images/icon_capsule_delete_white.svg"
          class="capsule-delete"
          @click.stop="clickDelete(tag)"
          title="Remove"
        />
      </div>
    </a-tooltip>
  </div>
</template>
<script>
/* eslint-disable no-param-reassign */
/* eslint-disable vue/require-valid-default-prop */
import FilterMixin from '@/mixins/filterMixin';
import ClickOutside from 'vue-click-outside';
import { mapSubUiConfig } from '@/utils/uiConfig';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { PatientNumberMixin, ClickMixin, EventMixin, OptionsMixin } from '@/mixins';
import { mapState } from '@/store';

export default {
  name: 'ICDCapsule',
  directives: {
    ClickOutside,
  },
  mixins: [FilterMixin, PatientNumberMixin, ClickMixin, EventMixin, OptionsMixin],
  components: {
    VuePerfectScrollbar,
  },
  props: {
    tag: {
      type: Object,
      default: () => {},
    },
    path: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      inputText: '',
      inputFocus: false,
      andOr: 'should',
      selectAll: false,
      queryMode: 'ICD_KEYWORD',
      hoverTag: false,
      hoverTagData: {},
      hoverOptions: [],
      deleteBackspace: false,
      inputSearchFlag: true,
    };
  },
  computed: {
    ...mapSubUiConfig('SearchForm'),
    ...mapState('condition', ['formDiagnosisRange']),
    getDiagnosisRange() {
      switch (this.formDiagnosisRange) {
        case 'all':
          return 'Any';
        case 'primary':
          return 'Primary';
        case 'top-5':
          return 'Top5';
        default:
          return 'Any';
      }
    },
  },
  methods: {
    getExpandMargin(level) {
      return (level - 1) * 12;
    },
    async mouseoverTag(tag) {
      this.hoverTag = tag.code;
      this.onlyClearText();
      tag.expand = false;
      if (tag.partial) {
        await this.getHoverInitData(tag);
        const valueDescendants = this.value.map((item) => item.selectDescendants).flat();
        this.hoverOptions.forEach((item) => {
          item.check = this.getOptionCheck(item, valueDescendants);
        });
        this.hoverOptions = this.hoverOptions.filter((item) => item.check);
      } else {
        this.hoverOptions = [tag];
      }
      this.hoverTagData = tag;
    },
    getOptionCheck(option, valueDescendants) {
      return (
        valueDescendants.includes(option.code) ||
        !!this.value.find((itemValue) => option.code === itemValue.code && !itemValue.partial)
      );
    },
    closeHoverExpand(option) {
      this.hoverOptions = this.hoverOptions.filter((item) => !option.descendants.includes(item.code));
      option.expand = false;
    },
    getPopupContainer(trigger) {
      return trigger.parentElement;
    },
    mouseLeaveTag() {
      this.hoverTag = false;
    },
    onlyClearText() {
      this.inputSearchFlag = false;
      this.inputText = '';
    },
    clickDelete(tag) {
      this.$emit('clickDelete', tag);
      this.cohortStudyTrackClick('clickDelete');
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.capsule-delete {
  cursor: pointer;
  margin-bottom: 3px;
  padding: 2px;
}

.codesearch-menu-content {
  border: 1px solid #333f6b;
  border-radius: 6px;
  background: #ffffff;
}

.header-row {
  height: 38px;
  padding: 10px 20px 10px 20px;
  background-color: #eff0f3;
}

.header-row-top-radius {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.hover-header-row {
  height: 38px;
  padding: 10px 20px 10px 20px;
  background-color: #ffffff;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  cursor: pointer;
}

.header-text-left-title {
  height: 15px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #333f6b;
}

.header-text-left {
  height: 15px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #333f6b;
}

.header-text-right {
  height: 15px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #333f6b;
}

.icon-unselect {
  cursor: pointer;
}

.row-option {
  box-shadow: inset 0px 1px 0px #d6d9e1;
  cursor: pointer;
}

.code-search-menu {
  padding: 0px 20px;
  height: 54px;
}

.code-search-hover-menu {
  padding: 0px 20px;
  height: 46px;
}

.disable-option {
  opacity: 0.3;
  cursor: not-allowed;
}

.code-text {
  height: 18px;
  margin: 1px 10px;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #333f6b;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.code-hover-text {
  margin: 0px 10px;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #334e97;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icd-description {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  height: 20px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
}

.icd-hover-description {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  height: 20px;
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
}

.icd-record-count {
  text-align: right;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #828282;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.option-content {
  /deep/ .ps {
    max-height: 405px;
  }
}

.hover-content {
  /deep/ .ps {
    max-height: 230px;
  }
}

.icon-expand {
  width: 34px;
  height: 34px;
}

.header-convert {
  height: 39px;
  background-color: #ffffff;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding: 0px 10px;
}

.header-convert-text {
  height: 19px;
  font-size: 15px;
  line-height: 19px;
  color: #333333;
}

.partial-dot {
  width: 6px;
  height: 6px;
  background: #4fc2e7;
  border-radius: 6px;
  margin-right: 5px;
  display: inline-block;
}
</style>
<style scoped>
#and-or-selector {
  width: 62px;
  height: 24px;
}

#and-or-selector >>> .ant-select-selection--single {
  border-radius: 53px !important;
  height: 24px;
  border: solid 1px #333f6b;
  background-color: #ffffff;
}

#and-or-selector >>> .ant-select-selection-selected-value {
  font-size: 16px;
  height: 24px;
  width: 36px;
  position: relative;
  bottom: 4px;
  right: 3px;
  text-align: center;
}

#and-or-selector >>> .ant-select-arrow {
  color: #333f6b;
  right: 3px;
}

#and-or-selector >>> .ant-select-selection__rendered {
  margin-right: 0px;
}

.capsule-abbr {
  padding: 0px 2px;
  color: #0f4895;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  background: #c1e0f9;
  border-radius: 2px;
}

.capsule-code {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}
</style>
<style lang="scss">
.remove-tooltip {
  .ant-tooltip-arrow {
    display: none;
  }
  .ant-tooltip-inner {
    background: #ffffff;
    border: solid 1px rgba(117, 117, 117, 0.5);
    border-radius: 3px;
    color: #da6d6d;
  }
}

.groupandmap-tag-tooltip {
  width: 740px;
  max-width: 740px !important;
  padding-top: 0px !important;

  .ant-tooltip-arrow {
    display: none;
  }
  .ant-tooltip-inner {
    border-radius: 6px;
    width: 740px;
    background: #ffffff;
    padding: 0px;
  }
}
</style>
