import DataCondition from './DataCondition';
import { LabConditionData, RangeData } from './Interface';

abstract class LabCondition extends DataCondition<LabConditionData> implements LabConditionData {
  public get ranges(): RangeData[] {
    return this.view.ranges;
  }

  public get exam(): string {
    return this.view.exam;
  }

  public get lab(): string {
    return this.view.lab;
  }

  public get view(): LabConditionData {
    const ranges = this.data.map((cond) => cond.ranges).reduce((results, range) => results.concat(range), []);

    return {
      ...super.view,
      ranges,
    };
  }
}

export default LabCondition;
