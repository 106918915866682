import { cloneDeep } from 'lodash';
import JBN from './Anti-TNF-Biologics/JBN';
import JBC from './Anti-TNF-Biologics/JBC';
import JBS from './Anti-TNF-Biologics/JBS';
import JBE from './Anti-TNF-Biologics/JBE';
import CC from './Anti-TNF-Biologics/CC';
import CP from './Anti-TNF-Biologics/CP';
import GL from './Anti-TNF-Biologics/GL';
import SD from './Anti-TNF-Biologics/SD';
import ASUS from './Anti-TNF-Biologics/ASUS';
import PC from './Anti-TNF-Biologics/PC';
import { getUiConfig } from '@/utils/uiConfig/';

function getAntiTNFBiologicsConfig() {
  switch (getUiConfig().hospital) {
    case 'Jelly BeanN':
      return cloneDeep(JBN);
    case 'Jelly BeanC':
      return cloneDeep(JBC);
    case 'Jelly BeanS':
      return cloneDeep(JBS);
    case 'Jelly BeanE':
      return cloneDeep(JBE);
    case 'Cheese Cake':
      return cloneDeep(CC);
    case 'Yogurt':
      return cloneDeep(JBN);
    case 'Crepes':
      return cloneDeep(CP);
    case 'Gelato':
      return cloneDeep(GL);
    case 'Sundae':
      return cloneDeep(SD);
    case 'Asus':
      return cloneDeep(ASUS);
    case 'Pineapple Cake':
      return cloneDeep(PC);
    default:
      return cloneDeep(JBN);
  }
}

export { getAntiTNFBiologicsConfig };
