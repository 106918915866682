import ConditionQuery from './core/ConditionQuery';

// eslint-disable-next-line no-unused-vars
export default function getLabHistogramAggsQuery(
  conditionQuery: ConditionQuery,
  CHSPECI: string,
  CHHEAD: string,
  UNIT: string,
  ranges: string
) {
  const aggsQuery = {
    aggs: {
      patientNum: {
        nested: {
          path: 'FIRST_LAB',
        },
        aggs: {
          recordCount: {
            filters: {
              filters: [
                {
                  bool: {
                    filter: [
                      {
                        match: {
                          'FIRST_LAB.CHSPECI': CHSPECI,
                        },
                      },
                      {
                        match: {
                          'FIRST_LAB.CHHEAD': CHHEAD,
                        },
                      },
                      {
                        match: {
                          'FIRST_LAB.UNIT': UNIT,
                        },
                      },
                      {
                        match: {
                          'FIRST_LAB.is_number': true,
                        },
                      },
                    ],
                  },
                },
              ],
            },
            aggs: {
              lab_value: {
                range: {
                  field: 'FIRST_LAB.VALUE',
                  ranges,
                },
              },
              highbound: {
                terms: {
                  field: 'FIRST_LAB.CHNH',
                  size: 1,
                },
              },
              lowbound: {
                terms: {
                  field: 'FIRST_LAB.CHNL',
                  size: 1,
                },
              },
            },
          },
        },
      },
    },
  };
  return {
    ...conditionQuery,
    ...aggsQuery,
    size: 0,
    track_total_hits: true,
  };
}
