<template>
  <div class="text-keyword-content">
    <span v-for="(range, index) in getRanges" :key="index">
      <span :class="{ range: true, deletable }">
        <template v-if="range.start !== range.end">
          <template v-if="conditionName === 'Time'">
            {{ `${formatTime(range.start)}~${formatTime(range.end)}` }}
          </template>
          <template v-else-if="conditionName === 'Age'">
            {{ `${formatAge(range)}` }}
          </template>
          <template v-else-if="conditionName === 'Stay'">
            {{ `${formatStay(range)}` }}
          </template>
          <template v-else-if="conditionName === 'Readmission'">
            {{ `${formatReadmission(range)}` }}
          </template>
          <template v-else-if="conditionName === 'Revisit to ER'">
            {{ `${formatReOPDE(range)}` }}
          </template>
          <template v-else-if="conditionName === 'BMI'">
            {{ `${formatBMI(range)}` }}
          </template>
          <template v-else>
            {{ `${range.start}~${range.end}` }}
          </template>
        </template>
        <template v-else>
          {{ range.start }}
        </template>
        <span v-if="deletable" @click="onDelete(range, index)" class="del-icon">x</span>
      </span>
      <span v-if="index !== getRanges.length - 1" class="concater">
        {{ concater }}
      </span>
    </span>
  </div>
</template>

<script>
import { xorWith } from 'lodash';
import { ClickMixin, TemplateValue, UpdateMixin } from '@/mixins';
import dayjs from 'dayjs';
import { getUiConfig } from '@/utils/uiConfig';
import { mapState } from '../../../../store';

export default {
  name: 'RangeValue',
  mixins: [ClickMixin, TemplateValue, UpdateMixin],
  props: {
    ranges: [Array],
    extend: {
      type: Boolean,
      default: false,
    },
    uniqueIndex: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapState('session', ['tabs']),
    tab() {
      if (this.tabs.length === 0) {
        return {};
      }
      return this.tabs[this.uniqueIndex];
    },
    getRanges() {
      if (this.ranges.length === 0 && this.conditionName === 'Time') {
        return [
          {
            start: getUiConfig().SearchForm.time.start,
            end: getUiConfig().SearchForm.time.end,
          },
        ];
      }
      return this.ranges;
    },
  },
  methods: {
    onDelete(range, index) {
      const extendConditions = [this.refCond[index]];
      this.trackClick('onDelete', {
        includes: xorWith(this.tab.includes, extendConditions),
        excludes: this.tab.excludes,
        range,
        uniqueIndex: this.uniqueIndex,
      });
      this.update({
        includes: xorWith(this.tab.includes, extendConditions),
        excludes: this.tab.excludes,
        uniqueIndex: this.uniqueIndex,
      });
    },
    formatTime(time) {
      return dayjs.unix(time).format('YYYY/MM');
    },
    formatAge(age) {
      if (age.end >= 100) {
        if (age.start < 100) {
          return `${age.start}~100+`;
        }
        return '100+';
      }
      if (age.start === age.end) {
        return `${age.start}`;
      }
      return `${age.start}~${age.end}`;
    },
    formatStay(stay) {
      if (stay.end >= 40) {
        if (stay.start < 40) {
          return `${stay.start}~40+`;
        }
        return '40+';
      }
      if (stay.start === stay.end) {
        return `${stay.start}`;
      }
      return `${stay.start}~${stay.end}`;
    },
    formatReadmission(days) {
      if (days.end === 0) {
        return 'No readmission';
      } else if (days.end === 15) {
        return 'Within 14 days';
      }
      return 'Over 14 days';
    },
    formatReOPDE(days) {
      if (days.end === 0) {
        return 'No ER revisit';
      } else if (days.end === 4) {
        return 'Within 3 days';
      }
      return 'Over 3 days';
    },
    formatBMI(value) {
      let type = '';
      if (value.start === 0) {
        type = 'No Info';
      } else if (value.end === 100) {
        type = '>=21 kg/m2';
      } else {
        type = '<21 kg/m2';
      }
      return type;
    },
  },
};
</script>

<style lang="scss" scoped>
.text-keyword-content {
  float: left;
  overflow: visible;
  font-family: Muli, Serif, Sans-serif, cursive, fantasy, Monospace;

  .range {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333f6b;
  }

  .concater {
    font-size: 12px;
    font-weight: normal;
    color: #828282;
    position: relative;
    top: -4px !important;
    float: none !important;
  }

  .deletable {
    height: 24px;
    border-radius: 20px;
    margin: 2px 5px;
    padding: 2px 10px;
    background-color: #333f6b;
    font-family: Muli, Serif, Sans-serif, cursive, fantasy, Monospace;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    display: inline-block;
    position: relative;
    top: -4px;
  }

  .del-icon {
    cursor: pointer;
    color: #f5ba42;
  }
}

.disable-block {
  opacity: 0.5;
}
</style>
