import BasicChartConfig from '@/components/charts/core/BasicChartConfig';
import { BasicChartQueryParams, ChartItem, ChartKey, RawItem } from '@/components/charts/core/Interface';
import {
  CategoryCondition,
  CategoryConditionData,
  ConditionName,
  LogicOp,
  SearchType,
  VisitType,
} from '../../utils/conditions/core';
import { PRECISION_THRESHOLD } from '../../utils/constants';
import { removePoint, UNSELECTED_COLOR } from './utils/utils';

const OPTION = {
  xAxis: {
    type: 'category',
    data: ['1', '2', '3', '4', '5', '6', '7'],
    triggerEvent: true,
    name: 'Level',
    nameLocation: 'end',
    nameGap: -30,
    nameTextStyle: {
      padding: [60, 0, 0, 0],
      color: '#757575',
    },
  },
  yAxis: {
    type: 'value',
    axisLabel: {
      formatter: removePoint,
    },
  },
};

function transformRisk(data: DiagnosisRawItem[]): DiagnosisRawItem[] {
  const result = [];
  let j = 0;
  for (let i = 0; i < 7; i += 1) {
    if (data[j] && `${data[j].key}` === `${i + 1}`) {
      result.push({
        ...data[j],
        key: `${i + 1}`,
      });
      j += 1;
    } else {
      result.push({
        patient_count: {
          value: 0,
        },
        doc_count: 0,
        key: `${i + 1}`,
      });
    }
  }
  return result;
}

interface DiagnosisRawItem extends RawItem {
  patient_count: {
    value: number;
  };
}

export default class RiskConfig extends BasicChartConfig<DiagnosisRawItem> {
  constructor() {
    super(ChartKey.Risk);

    this.merge(OPTION);
    this.bucketByPatient = false;
    this.disallowBucketChange = true;
  }

  protected processQueryParams(): BasicChartQueryParams {
    const condList = this.params.includes.without(ConditionName.Risk);
    return {
      ...this.params,
      includes: condList,
      visitType: VisitType.IPD,
    };
  }

  protected createChartData(rawData: any) {
    return transformRisk(rawData).map((item: any) => this.createChartItem(item));
  }

  protected createChartItem(rawItem: DiagnosisRawItem): ChartItem {
    const { key, doc_count: value } = rawItem;
    const condList = this.params.includes.additions.with(ConditionName.Risk);

    let color: string | null = null;
    if (condList.length !== 0) {
      const condition = condList[0] as CategoryCondition;
      color = UNSELECTED_COLOR;
      if (condition.hitKey(key)) {
        color = null;
      }
    }

    return {
      key,
      value,
      code: key,
      name: key,
      color,
      percentage: this.calculatePercentage(value),
    };
  }

  public createConditionFromItem(chartItem: ChartItem): CategoryConditionData {
    return {
      name: ConditionName.Risk,
      tags: [SearchType.Basic, SearchType.Additional],
      andOr: LogicOp.And,
      list: [chartItem.name],
    };
  }

  protected get aggsQuery(): Record<string, unknown> {
    return {
      terms: {
        field: 'RISK_GROUP',
        size: this.resultSize,
        order: {
          _term: 'asc',
        },
      },
      aggs: {
        patient_count: {
          cardinality: {
            field: 'CHART_NO',
            precision_threshold: PRECISION_THRESHOLD,
          },
        },
      },
    };
  }
}
