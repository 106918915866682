<template>
  <a-modal
    :visible="visible"
    :mask="true"
    :destroyOnClose="true"
    :footer="null"
    :zIndex="9999"
    :closable="false"
    @cancel="closeModal"
    centered
  >
    <div class="landing-title-text">{{ content[index].title }}</div>
    <div class="landing-subtitle-text mt-10px">{{ content[index].subtitle }}</div>
    <video controls autoplay class="mt-40px" :src="getVideoLink" ref="landingVideo" muted>
      <source :src="getVideoLink" />
      <track src="@/assets/vtt/video.vtt" kind="subtitles" srclang="zh" label="zh" />
    </video>
    <div class="d-flex justify-content-between align-items-center mt-40px">
      <div class="skip-button" v-if="content.length - 1 !== index" @click="closeModal">Skip</div>
      <div class="skip-button" v-else></div>
      <b-img src="@/assets/images/icon_landing_indocator1.svg" v-if="index === 0" />
      <b-img src="@/assets/images/icon_landing_indocator2.svg" v-else-if="index === 1" />
      <b-img src="@/assets/images/icon_landing_indocator3.svg" v-else-if="index === 2" />
      <b-img src="@/assets/images/icon_landing_indocator4.svg" v-else-if="index === 3" />
      <div class="next-button" v-if="content.length - 1 !== index" @click="clickNext">Next</div>
      <div class="next-button" v-else @click="closeModal">Get Started</div>
    </div>
  </a-modal>
</template>
<script>
import { mapState } from 'vuex';
import { FEATURES } from '@/utils/constants';
import { ClickMixin } from '@/mixins';

export default {
  name: 'LandingModal',
  mixins: [ClickMixin],
  data() {
    return {
      visible: false,
      index: 0,
      content: [
        {
          title: 'Introducing New EMR Search!',
          subtitle: '主要分成 3 個功能區塊，搜尋範圍條件設定、病歷搜尋Criteria設定、個人化My Concepts區',
          video: 'demoVideo/landing1.mov',
        },
        {
          title: '搜尋範圍條件設定',
          subtitle: '套用 demograpgic, time period 等設定到所有的 criteria',
          video: 'demoVideo/landing2.mov',
        },
        {
          title: '病歷搜尋Criteria設定',
          subtitle: '設定條件區域，可以加 doagnosis, medication, medical order 編碼定義',
          video: 'demoVideo/landing3.mov',
        },
        {
          title: '個人化My Concepts區',
          subtitle: '儲存定義好的 doagnosis, medication, medical order 到 concept 裡方便之後使用',
          video: 'demoVideo/landing4.mov',
        },
      ],
    };
  },
  computed: {
    ...mapState('user', ['isLogin', 'userId']),
    getVideoLink() {
      return this.getVideoPath();
    },
  },
  mounted() {
    this.checkFirstLanding();
  },
  methods: {
    openModal() {
      this.index = 0;
      this.visible = true;
    },
    closeModal() {
      this.visible = false;
    },
    async checkFirstLanding() {
      if (this.isLogin) {
        const firstTime = await this.$api.getIsFirstTime(this.userId, FEATURES.LANDING);

        if (firstTime.first_use) {
          this.openModal();
          this.setUsedFunction(FEATURES.LANDING);
        }
      }
    },
    getVideoPath() {
      return `${process.env.VUE_APP_BASE_URL}${this.content[this.index].video}`;
    },
    clickNext() {
      this.index += 1;
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .ant-modal-body {
  padding: 40px;
  background: #ffffff;
  border-radius: 16px;
}

/deep/ .ant-modal-content {
  border-radius: 16px;
}

/deep/ .ant-modal {
  min-width: 940px;
  max-width: 940px;
  width: 940px !important;
}

video {
  width: 100% !important;
  height: 537px !important;
  min-width: 690px !important;
  min-height: 537px !important;
  border-radius: 16px;
}

.landing-title-text {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #212121;
  text-align: center;
}

.landing-subtitle-text {
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #262626;
}

.skip-button {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #5b5b5b;
  cursor: pointer;
  width: 38px;
}

.next-button {
  padding: 12px 16px;
  background: #0074e6;
  border-radius: 8px;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  cursor: pointer;
}
</style>
