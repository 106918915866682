import ReadmissionConfig from '@/components/charts/ReadmissionConfig';
import { BasicChartQueryParams, ChartItem, ChartKey, RangeRawItem } from './core/Interface';
import { ConditionName, SearchType, VisitType } from '../../utils/conditions/core';

const reOPDEThreshold = 3;

const OPTION = {
  series: {
    type: 'pie',
    center: ['50%', '70%'],
    height: '80%',
  },
  legend: {
    left: 0,
    top: 0,
    icon: 'circle',
    data: [`Within ${reOPDEThreshold} days`, `Over ${reOPDEThreshold} days`, `No ER revisit`],
    selectedMode: false,
  },
  tooltip: {
    trigger: 'item',
  },
};

export default class ReOPDEConfig extends ReadmissionConfig {
  constructor() {
    super(ChartKey.ReOPDE);

    this.merge(OPTION);
    this.bucketByPatient = false;
    this.disallowBucketChange = true;
  }

  protected processQueryParams(): BasicChartQueryParams {
    const condList = this.params.includes.without(ConditionName.ReOPDE);
    return {
      ...this.params,
      includes: condList,
      visitType: VisitType.OPD,
    };
  }

  protected createChartItem(rawItem: RangeRawItem): ChartItem {
    const condList = this.params.includes.with(ConditionName.ReOPDE);
    const { color, key, doc_count, name, percentage } = this.getInfo(condList, rawItem);

    return {
      key,
      value: doc_count,
      code: '',
      name,
      color,
      percentage,
    };
  }

  protected get threshold(): number {
    return reOPDEThreshold;
  }

  protected get fieldName(): string {
    return 'REOPDE_TIMEDELTA';
  }

  protected get condition() {
    return {
      name: ConditionName.ReOPDE,
      tags: [SearchType.Basic, SearchType.Additional],
      key: this.fieldName,
    };
  }

  protected getRangeItemDisplayName(key: string): string {
    let name = '';
    if (key === 'NO_REVISIT') {
      name = `No ER revisit`;
    } else if (key === 'W') {
      name = `Within ${this.threshold} days`;
    } else {
      name = `Over ${this.threshold} days`;
    }
    return name;
  }
}
