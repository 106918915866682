export default {
  Blood: [
    'CRE',
    'ALT',
    'WBC',
    'Hb',
    'PL',
    'Ht',
    'MCV',
    'RBC',
    'MCH',
    'MCHC',
    'RDW-CV',
    'PDW',
    'MPV',
    'P-LCR',
    'GLU-AC',
    'K',
    'Lym.',
    'N.seg.',
    'Mono.',
    'Eosin.',
    'Baso.',
    'TG',
    'LDL-C',
    'Na',
    'HbA1C',
    'TCH',
    'BUN',
    'AST',
    'UA',
    'GLU',
    'HDL-C',
    'TBI',
    'CRP',
    'ALT (GPT)',
    'Value',
    'TSH',
    'ALB',
    'Ca',
    'Control',
    'FT4',
    'AFP',
    'GLU 2hr',
    'ALP',
    'PT',
    'INR',
    'I P',
    'Troponin I',
    'CEA',
    'C K',
    'GGT',
    'DBI',
    'HBs Ag',
    'APTT',
    'Lipase',
    'ESR',
    'Anti HCV',
    'S/C value',
    'pH',
    'pO2',
    'HCO3',
    'TCO2',
    'ABE',
    'BEecf',
    'SBC',
    'O2 sat.',
    'pCO2',
    'AST (GOT)',
    'STS-RPR',
    'CK-MB',
    'T P',
    'hs Troponin I',
    'S∕Cvalue',
    'CA-199',
    'PSA',
    'Aty.Lym.',
    'HIV Ab-EIA',
    'N.band',
    'CO2',
    'Free T4',
    'Anti HBs',
    'NT-proBNP',
    'Anti HAV',
    'ANA',
    'U A',
    'TIBC',
    'Ferritin',
    'Fe',
    'Blood Type',
    'CA 125',
    'Rh TypingD',
    'Irr. Ab',
    'T3',
    'FDP-Ddimer',
    'Mg',
    'Lactate',
    'LDH',
    'CA-153',
    'FSH',
    'GLO',
    'AMY',
    'E2',
    'C 3',
    'LH',
    'Anti HBc',
    'HBe Ag',
    'C 4',
    'Cortisol',
    'Anti-TPO',
    'Cl',
    'Meta.',
    'R F',
    'Vitamin D',
    'Anti nDNA',
    'PRA',
    'Aldo.',
    'GLU 1hr',
    'NH3',
    'Ig E',
    'Intact PT',
    'ACTH(8AM)',
    'Cortisol(8AM)',
    'Anti SS-A',
    'Anti SS-B',
    'PRL',
    'Testo.',
    'Myelo.',
    'Anti HBs(Value)',
    'Vit B12',
    'Rube. IgG',
    'CA-125',
    'Folate',
    'Ab-TSH Re.',
    'ACTH',
    'ATG',
    'N.RBC',
    'HBV DNA',
    'Insulin',
    'Thyroglobu',
    'Free PSA',
    'S/N Ratio',
    'ABO Type',
    'ATA',
    'Rh D',
    'f PSA/PSA',
    'HCV RNA',
    'CA 19-9',
    'Ethanol',
    'KetoneBody',
    'Anti HBe',
    'IGF-1',
    'Proge.',
    'Ret.',
    'Ig G',
    'HBV DNA 必治妥',
    'Measles Ab',
    'HLA B27',
    'Osmo.',
    'T4',
    'SCC',
    'PCT',
    'OGTT AC',
    'OGTT 2hr',
    'OGTT 1hr',
    'Anti RNP',
    'Anti Sm',
    'B-HCG',
    'ASMA',
    'HBsAg',
    'Ig A',
    'Calcitonin',
    'Testostero',
    'Zn',
    'M.Pn. IgM',
    'VitaminB12',
    'Cortisol(9PM)',
    'ACTH(9PM)',
    'M.Pneu. IgM',
    'Depakine',
    'DHEA-S',
    'CA 15-3',
    'AMA',
    'Al',
    'BT(IVY)',
    'HAV IgM',
    'TPHA',
    'Anti-HCV',
    'Ab-TSH rec',
    'PTH-intact',
    'VZV IgG',
    'LA 1(Screening)',
    'LA 2(Confirmation',
    'C-Peptide',
    '蛋白',
    'Ig M',
    'Thyroglo',
    'HBs Ag( 定量)',
    'D.pteronys',
    'Cockroach',
    'Cat Hair',
    'Dog dander',
    'mx2',
    'B.grass',
    'value',
    'Cardio-IgG',
    'T4 (Thyrox',
    'Hb A2',
    'S∕N Ratio',
    'Hb A',
    'Hb F',
    '狗毛屑',
    'AMH',
    'AvgRisk',
    'Anti CCP',
    'B2-GP1 IgG',
    '蝦',
    'Cardio-IgM',
    '花粉',
    'Cerulopla.',
    '牛奶',
    '蟹',
    '鱈魚',
    'HCV Genoty',
    'SMA',
    'BIL(Micro)',
    'T13',
    'T18',
    'T21',
    '花生',
    'HBV DNA 禾利行',
    'Cortiso8A',
    'MPO Value',
    'PR3',
    'PR3 Value',
    'MPO',
    '蛋黃',
    'class I',
    'class II',
    'S.typhi OH',
    'S.typhi O',
    'Sal. PA',
    'Sal. PB',
    'P.OX19',
    'P.OX2',
    'P.OXK',
    '貓毛屑',
    '混合花草',
    'FXS scn',
    'LA 1∕LA2 ratio',
    'Mitogen minus Nil',
    'Promyelo.',
    '小麥',
    'Nil Control',
    'TB Antigen',
    'Mitogen Control',
    'MTB Inf.Report',
    '大豆',
    'Folic acid',
    'ALP(bone)',
    'TB Antigen minus Nil',
    '粉塵蹣',
    '交錯黴菌',
    '屋塵蹣',
    'Digoxin',
    'ACTH 8A',
    'M.Pn. IgG',
    'Tacrolimus',
    '蟑螂',
    '蚌',
    '家塵',
    '白色念珠菌',
    'Theophylin',
    'M-peak',
    'H.pylo Ab',
    'Everolimus',
    'Blast',
    'Tacrolimus(FK-506)',
    'GLU 3hr',
    'EB-VCA IgA',
    'LA 1/LA2 ratio',
    'HBeAg',
    'Phadiatop',
    'Fibrinogen',
    'ASLO',
    'AntiScl-70',
    'Cryoglobin',
    'EBV PCR-健',
    'EBEA+NAIgA',
    'Ig G 4',
    'CD3+∕CD4+ Helper T',
    'CD3+∕CD8+ Suppre. T',
    'FKLC',
    'FLLC',
    'HSV 1 IgG',
    'HSV 2 IgG',
    'GLA activity',
    'Sirolimus',
    'HSV 1 IgM',
    'HSV 2 IgM',
    'Cortisol8AM(1mgDexa)',
    'Anti Jo-1',
    'f PSA∕PSA',
    'Anti-HBs',
    'Cyc.sporin',
    'M.Pneu. IgG',
    'Cold HA',
    'EB-VCA IgM',
    'Irr. Ab ID',
    'AFPmom',
    'uE3',
    'uE3mom',
    'Total B-HC',
    'b-hcg mom',
    'Inhibin A',
    'Inhi-Amom',
    'downs risk',
    'NTDrisk',
    'T18risk',
    'HLA DR',
    'HLA A',
    'HLA B',
    'HLA C',
    'CMV IgM',
    'Free B-HCG',
    'PAPP-A',
    'Crypto. Ag',
    'Alb',
    'A1-Glo',
    'A2-Glo',
    'B-Glo',
    'G-Glo',
    'HIV-1 RNA',
    'tHb',
    'O2Hb',
    'COHb',
    'MetHb',
    'O2ct',
    'O2cap',
    'sO2m',
    'G H',
    'EB-VCA IgG',
    '灰塵類篩檢',
    '貓皮毛屑',
    '黴菌類篩檢',
    'CD3+ T cells',
    'CD19+ B cells',
    'Aneuploidy',
    'Ca ionized',
    'Ca i-pH7.4',
    'Haptoglo.',
    'CMV IgG',
    'Lithium',
    'Phenytoin',
    'HTLV 1+2Ab',
    'FK∕FL ratio',
    'IGF-I',
    'Albumin',
    'Cortisol4P',
    'Anti-HBe',
    'DAT',
    'Protein',
    'Alpha-1',
    'Alpha-2',
    'Beta',
    'Gamma',
    'A/G Ratio',
    'IgG/A/M Kappa/Lambda',
    'ACTH 4P',
    'Dengue NS1',
    'ACHR-AB(Ac',
    'IAT',
    'HBc IgM',
    'VZV IgM',
    'Dengue IgM',
    'Dengue IgG',
    'Cu',
    'CD4∕CD8',
    '酪蛋白',
    '紅蘿蔔',
    '馬鈴薯',
    '麥粉',
    '榛子',
    '核桃',
    '杏仁',
    '芹菜',
    '蕃茄',
    '橘子',
    '蘋果',
    '芝麻種子',
    '黃豆',
    'Anti ICS',
    'Anti BMZ',
    'tHCY',
    'HLA DQ',
    'HHb',
    'CD3+/CD4+ Helper T',
    'CD3+/CD8+ Suppre. T',
    'Toxo. Ab',
    'Monocytoid',
    'β-2 micro',
    '17-OH Prg.',
    'B2 MG',
    'PRA class I',
    'A∕G rate',
    'Toxo.IgM',
    'Index',
    '樺樹',
    'Protein S',
    'Protein C',
    'A/G rate',
    '艾萵',
    '車前草',
    '馬毛屑',
    '天竺鼠',
    '黃金鼠',
    '兔子',
    '青黴菌',
    '芽枝菌屬',
    '麴菌屬',
    '交錯黴菌屬',
    '屋塵璊',
    '粉塵璊',
    '赤楊',
    '榛木',
    '橡木',
    'Anti GBM',
    'RATIO',
    'DHEA-SO4',
    'InsulinPC',
    'RHb',
    'FK/FL ratio',
    'Cystatin C',
    'Aldosteron',
    'AT III',
    'P1NP',
    'Tegretol',
    'JAK2-ss',
    'CMV PCR-健',
    'Pb',
    'Cyclosporin',
    'IgE',
    'Aspergillus Ag',
    'TB Ag minus Nil',
    'CD4/CD8',
    'HCV NS5A',
    'Eos. Count',
    'ICG 15min.',
    'GPT',
    'GOT',
    'AChR Ab',
    'RBC Morph.',
    'HCV RNA 羅氏',
    'Osteocalcin',
    'G-6-PD',
    'M.Pneu. Ab',
    'β- HCG',
    'α-Tha. SEA',
    'Vit.A',
    'EBNA',
    'Anti-HBc',
    'CYFRA 21-1',
    'ASD',
    'AAT',
    'MeaslesIgM',
    'DHT',
    'Mumps Ab',
    'T3 uptake',
    'Lyso-Gb3',
    'HIV-1(WB)',
    'P18-GAG',
    'P25-GAG',
    'P55-GAG',
    'P34-POL',
    'P52-POL',
    'P68-POL',
    'GP41-ENV',
    'GP120-ENV',
    'GP160-ENV',
    'Chl. trachomatis IgG',
    'Gastrin',
    'chromosome',
    'Anti-ds DN',
    'EBEA IgG',
    'IDUA∕GLA',
    'Chl.trachomatis IgA',
    'Myoglobin',
    'Barbital',
    'Panadol',
    'FactorVIII',
    'LAP Stain',
    'GLU 0.5hr',
    'AntiHAVIgM',
    'VWF',
    'Rube. IgM',
    'HLA-B1502',
    'Ig D',
    'Chlamyd pneumoniaIgM',
    'ECP',
    'Hg',
    'AntiHBstit',
    'TITER',
    'CD4',
    'CD8',
    'As',
    'HGH',
    'HCV RNA默沙東',
    'Transferin',
    'AQP4 Ab',
    'AGAD',
    'Mumps IgM',
    '橙',
    '酵母',
    '甜瓜',
    '芒果',
    'T cell cross match',
    'B cell cross match',
    'APCA',
    'PRG',
    'SHBG',
    'Free Testosterone',
    'Bio Testosterone',
    'Testosterone',
    'VZV-PCR',
    'Bcr-Abl RNA定量',
    'HBV DNA 羅氏-限風免',
    'FSH-0min',
    'LH-0min',
    'Chlamyd trachomatIgM',
    'Apo B',
    'Cd',
    'HSV',
    'HBV DNA 諾華',
    'FSH-90min',
    'LH-90min',
    'FSH-60min',
    'LH-30min',
    'FSH-30min',
    'LH-120min',
    'FSH-120min',
    'LH-60min',
    'Anti-HAV',
    'E3',
    'Parasite',
    'Fetal Hb',
    'HBV DNA 中外',
    'Erythropoi',
    'ChlamydIgM',
    '柳樹花粉',
    '日本杉花粉',
    '油加利樹花粉',
    '相思樹花粉',
    '豬草花粉',
    '葎草花粉',
    'Methanol',
    'ChlamydIgG',
    'UIBC',
    'BCR∕abl(q)',
    'GLU(0min)',
    'mAPTT1.25x',
    'mAPTT  2x',
    'mAPTT  5x',
    'FLAER/CD24 TII Def.',
    'FLAER/CD24 TIII Def.',
    'FLAER/CD16 TII Def.',
    'FLAER/CD16 TIII Def.',
    'FT3',
    'HCV RNA 羅氏-限腸胃',
    'EPO',
    'HBV DNA 輝瑞',
    'Sex chomo',
    'other chomo',
    '22q11',
    '1p36',
    '5p15',
    'BK virus',
    'Score',
    'Cyclospori',
    '腸追蹤',
    'IDUA∕GAA',
    'HBV DNA 嬌生',
    'Anti-ENA Sm',
    'Anti-ENA RNP',
    'Anti-ENA RNP 68',
    'Anti-ENA RNP A',
    'Anti-ENA SmRNP',
    '巴陶氏症Ch13',
    '愛德華氏症Ch18',
    '唐氏症Ch21',
    'JC virus',
    'Factor IX',
    'BCR/abl(q)',
    'Insulin Ab',
    'HBV DNA 台田',
    'HPC',
    'A-Lipo',
    'Pre B-Lipo',
    'B-Lipo',
    'Chyl.',
    'TPA',
    'STR DNA',
    'ADP test',
    'DBI/TBI',
    'mPT 1.25x',
    'mPT 2x',
    'mPT 5x',
    'Prealbumin',
    '晚發型龐貝氏症篩檢',
    'a-乳酸蛋白',
    'B-乳酸球蛋',
    '牛肉蛋白',
    'CMV-PCR(定性)',
    'AntiHBcIgM',
    'CD20',
    'CD5',
    'CD3',
    'CD14',
    'CD23',
    'Anti A',
    'Anti B',
    'Free β-HC',
    'Vancomycin(trough)',
    'CHS',
    'PAP',
    'TRAP test',
    'Lept-rtPCR',
    'HCV RNA 羅氏-限風免',
    'HCV RNA 必治妥',
    'Amoeba IHA',
    'CD34',
    'CD19',
    'CD10',
    'CD19∕K',
    'CD19∕L',
    'CD25',
    '法布瑞氏症檢測',
    'FTA-ABS',
    'HBV DNA 可善挺',
    'HBV DNA 衛采',
    'RISTO test',
    'GLU(120min)',
    'HLA DQ-H',
    'Testo.(pre-test)',
    'IGFBP-3',
    'MTB Inf. Report',
    'IU/ml',
    'FMC7',
    'CD7',
    'ASPI test',
    'ChlamydIgA',
    'HLA-B5801',
    'ACE',
    'HLA Class I Ab',
    'HLA Class II Ab',
    'GH(0 min)',
    'Cortisol 8AM (HDDS)',
    'Apo A1',
    'Parvovirus B19',
    'T3 Uptake ratio',
    'Osteocalci',
    '判讀',
    'G719X',
    'Exon19缺失',
    'S768I',
    'T790M',
    'Exon20插入',
    'L858R',
    'L861Q',
    'HCV 1a NS5A',
    'Pl. Ab',
    'HBV DNA 羅氏-限腸胃',
    'Paraquat',
    'DBI∕TBI',
    'TroponinI',
    'GLU(30min)',
    'Salicylate',
    'HCV RNA 輝瑞',
    'HBV DNA 羅氏',
    'Cortisol 8AM (LDDS)',
    'CK-MM',
    'CK-BB',
    'COL test',
    'PT(E)',
    'C-Peptide(0 min)',
    'p2PSA',
    '攝護腺健康指標-phi',
    'Free PSA∕PSA',
    'NS.Enolase',
    'TBI/DBI',
    'CMV PP67',
    'CT',
    'FSH-180min',
    'LH-180min',
    'Aldo.(post ARB)',
    'GLU(60min)',
    'GLU(90min)',
    'APOE genotype',
    'MICA',
    'NPM1DNA(q)',
    'FLT3∕ITD',
    'FLT3-D835',
    'PML-RARA',
    'CMV PCR',
    'Peroxidase',
    'P2Y12 test',
    'GLU(180min)',
    'Li.Carbo.',
    'HCV RNA 嬌生',
    'GH(60 min)',
    'Inhibition',
    'C-Peptide(6 min)',
    'DHT(post-tes)',
    'CD15',
    'CD56',
    'α-galactosidase A',
  ],
  Urine: [
    'Oth.',
    'Bact.',
    'GLU',
    'KET',
    'OB',
    'PRO',
    'NIT',
    'BIL',
    'Sp. Gr.',
    'pH',
    'URO',
    'LEU',
    'Color',
    'Clarity',
    'RBC',
    'WBC',
    'Ep. Cell',
    'Cast',
    'CRE單次尿',
    'ALB',
    'TP(Urine)',
    'ALB∕CRE',
    'ALB/CRE',
    'Urine HCG',
    'UR PROT',
    '24 hrs urine',
    'CRE(urine)',
    'VMA',
    'Epinephrin',
    'Norepineph',
    'Dopamin',
    'Value',
    'Cut-off',
    'Opiates',
    'free corti',
    'Amphetamine',
    'BENZ',
    'K',
    'BUN',
    'Osmo.',
    'Cocaines',
    'Methylamphetamines',
    'Phencyclidine',
    'Barbiturates',
    'Tetrahydrocannabinol',
    'Tricyclic Antidepres',
    'Methadone',
    'Acetaminophen',
    'Benzodiazepines',
    'Na',
    'Aldo.',
    '24hrs Urine',
    'Cannabinoids',
    'Ca',
    'Ketamine',
    'MDMA',
    'BK virus',
    'JC virus',
    'Amphetamin',
    'Cortisol U',
    'I P',
    'Cl',
    'U A',
    'Mg',
    'Microalbum',
    'Phencyclidine P',
    'Cocaine',
    'Methamphetamine',
    'Trichomonas Ag',
    '5-HIAA',
    'CT',
    'C.Cr',
    'NG',
    'BJ Protein',
    'Cu',
    'Alb',
    'A1-Glo',
    'A2-Glo',
    'B-Glo',
    'G-Glo',
    'AFB Stain',
    'Protein',
    'Albumin',
    'Alpha-1',
    'Alpha-2',
    'Beta',
    'Gamma',
    'A/G Ratio',
    'ABN peak',
    'Free Light Chain',
    'Aldosteron',
    'T P',
    'Cop.phyrin',
    'Myoglobin',
    'Pre Alb',
    'Gram Stain',
    'As',
    'MTB-PCR',
    'Chl. trachomatis Ag',
    'Paraquat',
    'Toxicology',
    'TP(U)',
    'CRE',
    'Cd',
    'Hg',
    'Pb',
    'Cortisol',
    'Cortisol urine',
    'FKLC',
    'FLLC',
    'Morphine(Heroin)',
    'Cotinine',
    'AMY',
    'S-ALA',
    '24 hr urine',
    'Mn',
    'Ni',
    'Sb',
    'Te',
    'Bi',
    'Tl',
    'Zn',
    'TP(單次尿)',
    'FK/FL ratio',
    'FK∕FL ratio',
  ],
  Stool: [
    'OB(result)',
    'OB(value)',
    'OB',
    'Color',
    'RBC',
    'WBC',
    'Pus',
    'Parasite',
    'Consistenc',
    'Consistency',
    'FOB',
    'TF',
    'Amoeba',
    'Oth.',
    'Ova Conc.',
    'Rota Ag',
    'Ova Direct',
    'Neut. Fat',
    'Fatty Acid',
    'Starch',
    'Redu.Sugar',
    'pH',
    'Noro rapid',
    'H. pylori Ag',
    'C.difficile toxin B',
    '027 strain',
    'TP',
    'AAT',
    'Pinewarm',
    'AFB Stain',
  ],
  Sputum: [
    'AFB Stain',
    'WBC',
    'Gram Stain',
    'Ep.Cell',
    'Color',
    'Clarity',
    'Chess Mass',
    'Curshman`s',
    'Bron. Cast',
    'Lung Stone',
    'Dittr.Plug',
    'RBC',
    'Pigm Cell',
    'C-L Cryst.',
    'Fungi',
    'Parasite',
    'MTB-PCR',
    'Value',
    'RSV Ag',
    'P.jiroveci',
    'FungiStain',
    'Rif. resistance',
  ],
  'Throat swab': ['Flu A Ag', 'Flu B Ag', 'Adeno Ag', 'Mycoplasma Ag'],
  Cervical: ['HPV', 'HPV 16', 'HPV 18', 'Other HR HPV'],
  Ascites: [
    'Color',
    'Clarity',
    'WBC',
    'RBC',
    'WBC Count',
    'Monocyte',
    'Lymphocyte',
    'N.seg.',
    'Meso.cell',
    'Macrophage',
    'Eosinophil',
    'Gram Stain',
    'Plasma cell',
    'N.band',
    'Basophil',
    'AFB Stain',
    'ALB',
    'T P',
    'LDH',
    'GLU',
    'AMY',
    'TG',
    'Lipase',
    'CRE',
    'BUN',
    'TCH',
  ],
  'Synovial fluid': [
    'Color',
    'Clarity',
    'WBC',
    'RBC',
    'WBC Count',
    'Crystals',
    'Lymphocyte',
    'Monocyte',
    'N.seg.',
    'Synov.cell',
    'Macrophage',
    'Eosinophil',
    'Plasma cell',
    'N.band',
    'Basophil',
    'Gram Stain',
    'AFB Stain',
    'U A',
    'Crystal-PM',
    'GLU',
    'IndiaStain',
    'Lym.',
    'Mono.',
    'T P',
  ],
  'Wax block': [
    '檢體號碼',
    'PD-L1',
    'G719X',
    'Exon19缺失',
    'S768I',
    'T790M',
    'Exon20插入',
    'L858R',
    'L861Q',
    'ALK IHC',
    'NRAS/KRAS',
    'KRAS 12∕13',
    'K-ras',
    'ROS1 FISH',
    'BRAF',
    'ALK FISH',
  ],
  'Gastric juice': ['Color', 'Clarity', 'OB', 'pH-pHMeter'],
  'Pleural fluid': [
    'Color',
    'Clarity',
    'WBC',
    'RBC',
    'WBC Count',
    'Lymphocyte',
    'Monocyte',
    'N.seg.',
    'AFB Stain',
    'LDH',
    'T P',
    'GLU',
    'Meso.cell',
    'Macrophage',
    'Gram Stain',
    'Eosinophil',
    'Plasma cell',
    'Basophil',
    'N.band',
    'pH-pHMeter',
    'MTB-PCR',
    'Value',
    'TG',
    'AMY',
    'ALB',
    'TCH',
    'CRE',
  ],
  Dialysate: [
    'CRE',
    'GLU',
    'BUN',
    'Color',
    'Clarity',
    'WBC',
    'RBC',
    'WBC Count',
    'Monocyte',
    'Lymphocyte',
    'N.seg.',
    'Meso. cell',
    'Eosinophil',
    'Macrophage',
    'Gram Stain',
    'N.band',
    'Plasma cell',
    'Basophil',
    'AFB Stain',
    'MTB-PCR',
    'Value',
  ],
  Discharge: [
    'Chl. trachomatis Ag',
    'Chlamyd.Ag',
    'Gram Stain',
    'Color',
    'Clarity',
    'WBC',
    'RBC',
    'WBC Count',
    'PMNs',
    'MoNs',
    'Trichmonas',
    'AFB Stain',
    'Fungus',
    'Bacteria',
    'FungiStain',
  ],
  'Genital dischar': ['Chl. trachomatis Ag', 'Chlamyd.Ag', 'Trichmonas', 'Fungus', 'Bacteria'],
  Nasopharyngeal: ['Flu A Ag', 'Flu B Ag'],
  Semen: ['RBC', 'WBC', 'Color', 'Clarity', 'Sperm No', 'pH', 'Lique Time', 'Volume', 'Motile', 'Morph.(N)'],
  Vomit: ['Color', 'OB', 'Consistency', 'Consistenc', 'pH-pHMeter'],
  Stone: [
    'Na Urate',
    'Uric Acid',
    'Ammo.Urate',
    'Ammonium Mg Phosphat',
    'Ca Phosphate CO3 For',
    'Protein(Calculi)蛋白',
    'Ca Carbo.',
    'Ca(HPO4)2',
    'Ca Oxalate',
    'Ca-P',
    'Cysteine',
    'Ca Stearat',
    'CHO',
    'Ca-P(Biliary)',
  ],
  Bile: ['Gram Stain', 'AFB Stain'],
  Pus: ['Gram Stain', 'AFB Stain', 'MTB-PCR', 'Value', 'FungiStain'],
  CSF: [
    'Color',
    'Clarity',
    'WBC',
    'RBC',
    'WBC count',
    'RBC count',
    'RBC Morph.',
    'Xanthochro',
    'TP(CSF)',
    'GLU',
    'Lymphocyte',
    'Value',
    'IndiaStain',
    'Gram Stain',
    'Monocyte',
    'AFB Stain',
    'Crypto. Ag',
    'Lactate',
    'N.seg.',
    'Eosinophil',
    'Macrophage',
    'Basophil',
    'Epend.cell',
    'Plasma cell',
    'N.band',
    'HSV 1 IgG',
    'HSV 2 IgG',
    'STS-RPR',
    'TPHA',
    'VZV IgM',
    'VZV IgG',
    'HSV 1 IgM',
    'HSV 2 IgM',
    'MTB-PCR',
    'HSV',
    'Oligo band',
    'FungiStain',
    'VZV-PCR',
    'TP',
    'Alb',
    'A1-Glo',
    'A2-Glo',
    'B-Glo',
    'G-Glo',
    'Prealbumin',
    'CMV-PCR(定性)',
    'Ig G',
  ],
  'Bronchial wash': [
    'AFB Stain',
    'Value',
    'MTB-PCR',
    'Aspergillus Ag',
    'Gram Stain',
    'P.jiroveci',
    'IndiaStain',
    'FungiStain',
  ],
  'Eye discharge': ['Gram Stain', 'FungiStain', 'Chl. trachomatis Ag', 'AFB Stain'],
  'Anterior chambe': ['CMV PCR', 'VZV-PCR', 'HSV', 'Value'],
  'Heel Blood': ['TBI', 'DBI', 'DBI/TBI'],
  swab: ['Mycoplasma Ag'],
  'Pericardi fluid': [
    'Color',
    'Clarity',
    'WBC',
    'RBC',
    'WBC Count',
    'N.seg.',
    'Lymphocyte',
    'Monocyte',
    'Gram Stain',
    'LDH',
    'Lipase',
    'GLU',
    'AMY',
    'N.band',
    'Eosinophil',
    'Basophil',
    'Macrophage',
    'Meso.cell',
    'AFB Stain',
    'T P',
    'ALB',
  ],
  Tissue: ['MTB-PCR', 'Value'],
  'Body fluid': ['MTB-PCR', 'Value', 'Gram Stain', 'pH-pHMeter', 'AFB Stain'],
  'Perianal tape': ['Ova Direct'],
  'Lung aspirate': ['AFB Stain'],
  Wound: ['Gram Stain', 'AFB Stain'],
  Vaginal: ['NG', 'CT'],
  'Liver abscess': ['Gram Stain'],
  Tissues: ['AFB Stain'],
  RBC: ['CHS'],
  'Tumor aspirate': ['AFB Stain'],
  Plasma: ['CHS(plasma'],
  'Bone marrow': [
    'HLA-DR',
    'FLT3∕ITD',
    'NPM1DNA(q)',
    'JAK2-ss',
    'WBC',
    'RBC',
    'Hb',
    'Ht',
    'MCV',
    'MCH',
    'MCHC',
    'PL',
    'RDW-CV',
    'PDW',
    'MPV',
    'P-LCR',
  ],
  'Pleural flui': ['T P', 'LDH'],
  'Nasal aspirate': ['Flu A Ag', 'Flu B Ag'],
};
