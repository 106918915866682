import { ITailTimeRelationEvent } from '../Interface';
import BaseMedicalEvent from './BaseMedicalEvent';
import TimeRelation from './TimeRelation';

class TailTimeRelationEvent extends BaseMedicalEvent implements ITailTimeRelationEvent {
  readonly relation: TimeRelation;

  constructor(timeRelationEvent: ITailTimeRelationEvent) {
    super(timeRelationEvent);
    this.relation = new TimeRelation(timeRelationEvent.relation);
  }
}

export default TailTimeRelationEvent;
