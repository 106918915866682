class GenderQuery {
  private genderList: any[];

  private genderMap = new Map<string, string>();

  constructor(genderList: any[]) {
    this.genderList = genderList;
    this.genderMap.set('male', 'M');
    this.genderMap.set('female', 'F');
  }

  public getQuery() {
    if (this.genderList.length === 0) {
      return [
        {
          terms: {
            SEX: ['dummy'],
          },
        },
      ];
    }
    return [
      {
        terms: {
          SEX: this.genderList.map((gender) => this.genderMap.get(gender.type)),
        },
      },
    ];
  }
}

export default GenderQuery;
