<template>
  <div class="ready-dataset">
    <div class="top-title-text">Saved Analyses</div>
    <b-row no-gutters>
      <b-col>
        <b-row no-gutters v-if="savedList.length > 0 || searchAnalysisNameText">
          <b-col>
            <b-row no-gutters>
              <b-col class="d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center">
                  <b-input placeholder="Search" v-model="searchAnalysisNameText" class="name-search"> </b-input>
                  <b-img src="@/assets/images/icon_search_blue.svg" class="icon-input-search" />
                </div>
              </b-col>
            </b-row>
            <b-row no-gutters class="title-row mt-20px">
              <b-col class="d-flex align-items-center">
                <div class="title-name">Name</div>
              </b-col>
              <b-col class="d-flex align-items-center justify-content-end">
                <div class="title-date">Modified Date</div>
              </b-col>
            </b-row>
            <b-row no-gutters>
              <b-col>
                <b-row
                  no-gutters
                  v-for="(item, index) in savedList"
                  :key="`${index}-savedList`"
                  class="data-row"
                  @click="restoreAnalysis(item)"
                >
                  <b-col class="d-flex justify-content-center">
                    <div class="left-div" style="padding-right: 20px">
                      <b-row no-gutters>
                        <b-col>
                          <div class="cohort-name-text">{{ item.name }}</div>
                        </b-col>
                      </b-row>
                      <b-row no-gutters>
                        <b-col class="mt-5px" v-if="item.searchState">
                          <a-tooltip placement="bottomLeft" overlayClassName="cohort-save-analysis-tooltip">
                            <template slot="title">
                              <div class="sub-title-text">Experimental Group</div>
                              <div class="sub-content-text mt-5px">{{ item.searchState.experimental.name }}</div>
                              <div class="sub-title-text mt-20px">Control Group</div>
                              <div class="sub-content-text mt-5px">{{ item.searchState.control.name }}</div>
                              <div class="sub-title-text mt-20px">Covariates</div>
                              <div class="sub-content-text mt-5px" style="margin-left: -5px">
                                {{ getCovariatesText(item.searchState.covariate) }}
                              </div>
                              <div class="sub-title-text mt-20px">Outcome</div>
                              <div class="sub-content-text mt-5px" style="margin-left: -5px">
                                {{ item.searchState.outcome.inclusionList[0].name }}
                              </div>
                            </template>
                            <div class="display-block">
                              <div class="sub-title-text d-inline">Experimental Group</div>
                              <div class="ml-5px sub-content-text d-inline">
                                {{ item.searchState.experimental.name }}
                              </div>
                              <div class="ml-20px sub-title-text d-inline">Control Group</div>
                              <div class="ml-5px sub-content-text d-inline">{{ item.searchState.control.name }}</div>
                              <div class="ml-20px sub-title-text d-inline">Covariates</div>
                              <div class="ml-5px sub-content-text d-inline">
                                {{ getCovariatesText(item.searchState.covariate) }}
                              </div>
                              <div class="ml-20px sub-title-text d-inline">Outcome</div>
                              <div class="ml-5px sub-content-text d-inline">
                                {{ item.searchState.outcome.inclusionList[0].name }}
                              </div>
                            </div>
                          </a-tooltip>
                        </b-col>
                      </b-row>
                    </div>
                    <div class="right-div d-flex align-items-center justify-content-end">
                      <b-img
                        src="@/assets/images/icon_event_share.svg"
                        class="mr-20px"
                        style="cursor: pointer"
                        title="Copy link"
                        @click.stop="clickShare(item)"
                      />
                      <b-img
                        src="@/assets/images/icon_delete_event.svg"
                        class="icon-edit mr-30px"
                        style="cursor: pointer"
                        title="Delete"
                        @click.stop="clickDelete(item)"
                      />
                      <div class="sub-content-text" style="width: 90px">{{ item.updateTime }}</div>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row no-gutters>
              <b-col>
                <a-pagination
                  v-show="total > pageSize"
                  class="pagin"
                  size="small"
                  v-model="current"
                  :total="total"
                  showLessItems
                  :itemRender="analysisPagerRender"
                  @change="handlePaginationChange"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row no-gutters v-else>
          <b-col>
            <b-row no-gutters>
              <b-col class="d-flex justify-content-center align-items-center">
                <b-img src="@/assets/images/icon_event_no_dataset.svg" style="margin-top: 130px" />
              </b-col>
            </b-row>
            <b-row no-gutters>
              <b-col class="d-flex justify-content-center align-items-center">
                <div class="top-title-text mt-30px">No Saved Analysis</div>
              </b-col>
            </b-row>
            <b-row no-gutters>
              <b-col class="d-flex justify-content-center align-items-center">
                <div class="cohort-name-text mt-20px" style="width: 365px">
                  Click the “Save analysis” button at the top of the analysis page to save your analysis.
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-modal
      :visible="deleteModal"
      centered
      no-close-on-backdrop
      no-close-on-esc
      hide-header
      hide-footer
      hide-header-close
      content-class="delete-content-modal"
      body-class="delete-body-modal"
    >
      <b-row no-gutters>
        <b-col>
          <b-row no-gutters>
            <b-col class="title-text" style="text-align: center">Delete Analysis</b-col>
          </b-row>
          <b-row no-gutters class="mt-20px">
            <b-col class="name-delete-text"> Are you sure you want to delete this analysis? </b-col>
          </b-row>
          <b-row no-gutters>
            <b-col class="name-delete-text"> It will be deleted permanently. </b-col>
          </b-row>
          <b-row no-gutters>
            <b-col class="d-flex justify-content-center button-col">
              <b-button variant="outline-primary" class="button-close" @click="closeDeleteModal">CANCEL</b-button>
              <b-button variant="outline-primary" class="button-cancel" @click="clickDeleteCohort">DELETE</b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-modal>
    <div class="hint-link-copied" v-if="hintLinkCopied">✓ Link copied to clipboard</div>
  </div>
</template>
<script>
import dayjs from 'dayjs';
import { mapState, mapMutations } from '@/store';
import { v4 as uuid } from 'uuid';
import { cloneDeep } from 'lodash';
import { getRedirectLink } from '@/utils/util';
import urljoin from 'url-join';
import { ClickMixin } from '@/mixins';
import { VUE_EVENT_NAME } from '@/utils/constants';

const pageSize = 10;

const LINK_COPIED_WAIT = 3000; // 3 seconds

export default {
  name: 'SaveAnalysisList',
  mixins: [ClickMixin],
  data() {
    return {
      searchAnalysisNameText: '',
      savedList: [],
      total: 1000,
      pageSize,
      current: 1,
      deleteModal: false,
      deleteInfo: {},
      hintLinkCopied: false,
      copiedTextTimeout: null,
    };
  },
  mounted() {
    this.setSavedAnalysisList();

    this.$root.$on(VUE_EVENT_NAME.REFRESH_SAVE_ANALYSIS, () => {
      this.setSavedAnalysisList();
    });
  },
  destroyed() {
    this.$root.$off(VUE_EVENT_NAME.REFRESH_SAVE_ANALYSIS);
  },
  computed: {
    ...mapState('user', ['userId']),
    ...mapState('cohortSession', ['tabs']),
  },
  watch: {
    searchAnalysisNameText() {
      this.setSavedAnalysisList();
    },
  },
  methods: {
    ...mapMutations('cohortSession', ['addTab', 'setActiveTabIndex', 'updateTab']),
    analysisPagerRender(current, type, originalElement) {
      if (type === 'prev') {
        return (
          <a>
            <b-icon-caret-left-fill />
          </a>
        );
      }
      if (type === 'next') {
        return (
          <a>
            <b-icon-caret-right-fill />
          </a>
        );
      }
      return originalElement;
    },
    async setSavedAnalysisList() {
      let result = [];
      if (this.searchAnalysisNameText === '') {
        result = await this.$api.getCohortStudy({
          sort: {
            updateTime: 'desc',
          },
          topN: pageSize,
          page: this.current - 1,
          purpose: 'save',
        });
      } else {
        result = await this.$api.searchCohortStudy({
          userId: this.userId,
          keyword: this.searchAnalysisNameText,
          sort: {
            updateTime: 'desc',
          },
          topN: pageSize,
          page: this.current - 1,
          purpose: 'save',
        });
      }

      this.total = Math.min(result.max_limit, result.content.hits.total.value);

      const savedList = [];

      result.content.hits.hits.forEach((item) => {
        savedList.push({
          name: item._source.displayName,
          searchState: item._source.request.searchState,
          updateTime: dayjs.unix(item._source.updateTime).format('YYYY/MM/DD'),
          url: item._source.url,
          data: item._source.data,
        });
      });

      this.savedList = savedList;
      this.$root.$emit(VUE_EVENT_NAME.SET_VUEPERFECTSCROLLBAR_UPDATE);

      if (this.total !== 0 && Math.min(result.max_limit, Math.ceil(this.total / pageSize)) < this.current) {
        this.current = 1;
        this.setSavedAnalysisList();
      }
    },

    getCovariatesText(data) {
      return data.inclusionList.map((item) => item.name).join(', ');
    },
    handlePaginationChange(pagination) {
      this.current = pagination;
      this.setSavedAnalysisList();
      this.cohortStudyTrackClick('handlePaginationChange', { pagination });
    },
    getDuplicatedTabIndex(item) {
      return this.tabs.findIndex((tab) => tab.id === item.url);
    },
    restoreAnalysis(data) {
      const index = this.getDuplicatedTabIndex(data);
      if (index !== -1) {
        this.setActiveTabIndex(index);
      } else {
        this.addTab({
          id: data.url,
          name: data.name,
          updateTime: data.updateTime,
          eventSearch: cloneDeep(data.searchState),
          currentMenu: '',
          experimentalGroupPatient: 0,
          controlGroupPatient: 0,
          data: {
            root: { trackId: '' },
            trackId: uuid(),
          },
          tabType: 'save',
          getLatest: false,
          url: data.url,
          characteristicsTableRequestInProgress: false,
          incidenceRateTableRequestInProgress: false,
          survivalRateChartRequestInProgress: false,
          coxRegressionRequestInProgress: false,
          patientListRequestInProgress: false,
          resultDone: false,
          characteristicsTableProgressPercent: 0,
          incidenceRateTableProgressPercent: 0,
          survivalRateChartProgressPercent: 0,
          coxRegressionProgressPercent: 0,
          patientListProgressPercent: 0,
        });
      }

      this.cohortStudyTrackClick('restoreAnalysis');
    },
    clickDelete(data) {
      this.deleteModal = true;
      this.deleteInfo = data;

      this.cohortStudyTrackClick('clickDelete');
    },
    closeDeleteModal() {
      this.deleteModal = false;
      this.deleteInfo = {};

      this.cohortStudyTrackClick('closeDeleteModal');
    },
    async clickDeleteCohort() {
      this.deleteModal = false;

      this.tabs.forEach((tab, index) => {
        if (tab.id === this.deleteInfo.url) {
          this.updateTab({ index, id: '' });
        }
      });

      await this.$api.deleteCohortStudy(this.deleteInfo.url);
      this.setSavedAnalysisList();
      this.deleteInfo = {};

      this.cohortStudyTrackClick('clickDeleteCohort');
    },
    listener(e) {
      e.clipboardData.setData('text/plain', this.copyLink);
      e.preventDefault();
    },
    getSharedUrl(itemId) {
      const { origin:baseUrl } = window.location;
      return urljoin(baseUrl, 'analysis-url', itemId);
    },
    async clickShare(data) {
      this.copyLinkMenu = false;
      const result = await this.$api.postCohortStudy({
        name: data.name,
        updateTime: dayjs().toISOString(),
        purpose: 'share',
        request: {
          searchState: data.searchState,
        },
      });

      const sharedUrl = this.getSharedUrl(result.url);
      this.copyLink = getRedirectLink(sharedUrl);

      document.addEventListener('copy', this.listener);
      document.execCommand('copy');
      document.removeEventListener('copy', this.listener);

      this.showCopiedText();

      this.cohortStudyTrackClick('clickShare');
    },
    showCopiedText() {
      clearTimeout(this.copiedTextTimeout);
      this.hintLinkCopied = true;
      this.copiedTextTimeout = setTimeout(() => {
        this.hintLinkCopied = false;
      }, LINK_COPIED_WAIT);
    },
  },
};
</script>
<style lang="scss" scoped>
.ready-dataset {
  background: #ffffff;
  min-height: calc(100vh - 116px);
  padding: 20px 0px 0px 0px;
}

.name-search {
  width: 505px;
  height: 36px;
  border-radius: 6px;
  border: solid 1px #d6d9e1;
  background-color: #ffffff;
  margin-top: 20px;
  padding-left: 38px;
}

.icon-input-search {
  position: absolute;
  margin: 20px 0px 0px 10px;
}

.new-cohort-button {
  padding: 8px 16px;
  background: #4d8edc;
  border-radius: 6px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;
}

.title-row {
  padding: 4px 20px;
  border-radius: 6px;
  background-color: #ebecf0;
}

.title-name {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #334e97;
  text-align: left;
}

.title-date {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #334e97;
  text-align: right;
}

.data-row {
  box-shadow: inset 0 -1px 0 0 #d6d9e1;
  padding: 20px;
  cursor: pointer;
}

.data-row:hover {
  background-color: #eaf2fd;
}

.icon-edit {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.cohort-name-text {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #212121;
}

.block-title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #334e97;
}

.sub-title-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #757575;
  white-space: pre;
}

.sub-content-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #212121;
  white-space: pre;
}

/deep/ .delete-content-modal {
  width: 475px;
  height: 217px;
  border-radius: 6px;
  background-color: #ffffff;
  border: 1px solid white;
}

/deep/ .delete-body-modal {
  padding: 30px 30px 30px 30px;
}

.title-text {
  height: 23px;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #333f6b;
}

.name-delete-text {
  height: 20px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333f6b;
}

.button-col {
  margin-top: 30px;
}

.button-cancel,
.button-cancel:hover,
.button-cancel:focus,
.button-cancel:active,
.button-cancel:active:visited {
  margin-right: 20px;
  width: 168px;
  height: 44px;
  border-radius: 6px;
  border: solid 1px #da6d6d !important;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff !important;
  background-color: #da6d6d !important;
}

.button-close,
.button-close:hover,
.button-close:focus,
.button-close:active,
.button-close:active:visited {
  margin-right: 20px;
  width: 168px;
  height: 44px;
  border-radius: 6px;
  border: solid 1px #333f6b !important;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333f6b !important;
  background-color: #ffffff !important;
}

.top-title-text {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #334e97;
}

.hint-link-copied {
  padding: 12px 16px;
  background: #57ac79;
  border: 1px solid #ffffff;
  border-radius: 6px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 150%;
  color: #ffffff;
  position: fixed;
  bottom: 40px;
  left: 43%;
}

.right-div {
  width: 200px;
  max-width: 200px;
  min-width: 200px;
}

.left-div {
  width: calc(100% - 200px);
  max-width: calc(100% - 200px);
  min-width: calc(100% - 200px);
}
</style>
<style scoped>
.pagin {
  float: None;
  margin: 48px 0px 30px 0px;
  text-align: center;
}

.pagin >>> .ant-pagination-item-active {
  border: 1px solid #ffffff;
  color: #333f6b;
  font-weight: bold;
}

.pagin >>> .ant-pagination-item-active a {
  color: #333f6b;
}

.pagin >>> .ant-pagination-item-container {
  bottom: 2px;
  font-size: 1px;
}

.pagin >>> .ant-pagination-jump-next,
.pagin >>> .ant-pagination-jump-prev {
  pointer-events: none;
}

.display-block {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
<style lang="scss">
.cohort-save-analysis-tooltip {
  width: 400px;
  max-width: 400px !important;
  z-index: 10000 !important;

  .ant-tooltip-arrow {
    display: none;
  }

  .ant-tooltip-inner {
    background: #ffffff;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    border-radius: 4px;
  }
}
</style>
