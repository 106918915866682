<template>
  <div class="saved-list" :class="loading ? 'block' : ''">
    <a-spin id="loading-spin" v-if="loading" />
    <div v-if="initSavedListLength !== 0">
      <div>
        <div class="d-inline-block save-title">Results</div>
      </div>
      <b-row no-gutters>
        <b-col>
          <b-input placeholder="Search" v-model="searchNameText" class="name-search"> </b-input>
          <b-img src="@/assets/images/icon_search_blue.svg" class="icon-input-search" />
        </b-col>
      </b-row>
      <b-row no-gutters class="title-row">
        <b-col class="title-name">Name</b-col>
        <b-col class="title-date">Modified Date</b-col>
      </b-row>
      <b-row no-gutters>
        <b-col>
          <b-row
            no-gutters
            v-for="(item, index) in savedList"
            :key="index"
            class="data-row"
            @click.prevent="restore(item)"
          >
            <b-col class="d-flex justify-content-center" style="padding-right: 30px">
              <div class="left-div">
                <b-row no-gutters>
                  <b-col
                    ><div class="name-text">{{ item.name }}</div></b-col
                  >
                </b-row>
                <b-row no-gutters>
                  <b-col>
                    <div class="condition-text">
                      <div v-if="getConditions(item.includes).length > 0" class="condition-type-text">Criteria:</div>
                      <SearchCondition
                        v-for="(condition, index) in getConditions(item.includes)"
                        :key="`condition-${index}`"
                        :condition="condition"
                        style="margin: 0px 10px 0px 0px"
                      />
                      <div v-if="item.excludes.length > 0" class="condition-type-text">Excluded:</div>
                      <SearchCondition
                        v-for="(condition, index) in getConditions(item.excludes)"
                        :key="`excludedConditions-${index}`"
                        :condition="condition"
                        style="margin: 0px 10px 0px 0px"
                      />
                      <div v-if="getExtendConditions(item.includes).length > 0" class="condition-type-text">
                        Filter:
                      </div>
                      <SearchCondition
                        v-for="(condition, index) in groupedConditions(getExtendConditions(item.includes))"
                        :key="`extendConditions-${index}`"
                        :condition="condition"
                        style="margin: 0px 10px 0px 0px"
                      />
                    </div>
                  </b-col>
                </b-row>
              </div>
              <div class="d-flex align-items-center justify-content-end right-div">
                <div class="d-flex justify-content-center" style="max-width: 120px; width: 120px; min-width: 120px">
                  <div
                    class="button-container"
                    style="margin-right: 20px"
                    @click.stop="openCopyLinkMenu(item, index)"
                    v-if="uiConfig.share"
                  >
                    <b-img src="@/assets/images/icon_share.svg" class="icon-share" />
                  </div>
                  <div
                    class="share-link-menu"
                    v-if="copyLinkMenu === index"
                    v-click-outside="closeCopyLinkMenu"
                    @click.stop=""
                  >
                    <b-row no-gutters>
                      <b-col>
                        <b-row no-gutters>
                          <b-col class="title-text">Share Link</b-col>
                          <b-col>
                            <b-img
                              src="@/assets/images/icon_close.svg"
                              class="icon-close"
                              @click.stop="closeCopyLinkMenu(0)"
                            />
                          </b-col>
                        </b-row>
                        <b-row no-gutters>
                          <b-col class="name-search-text">Name your search</b-col>
                        </b-row>
                        <b-row no-gutters>
                          <b-col>
                            <b-form-input
                              placeholder="Search result name"
                              v-model="nameText"
                              class="name-input"
                              ref="shareQueryInput"
                            ></b-form-input>
                          </b-col>
                        </b-row>
                        <b-row no-gutters>
                          <b-col>
                            <b-button variant="outline-primary" class="copy-link-button" @click.stop="copyToClip(item)"
                              >COPY LINK</b-button
                            >
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </div>
                  <div class="button-container" @click.stop="openMoreDetail(item, index)">
                    <b-img src="@/assets/images/icon_more.svg" class="icon-share" />
                  </div>
                  <div class="more-detail-menu" v-if="moreDetailMenu === index" v-click-outside="closeMoreDetail">
                    <b-row no-gutters>
                      <b-col>
                        <b-row no-gutters class="more-detail-row" @click.stop="openRenameModal">
                          <b-col> <div class="rename-text">Rename</div></b-col>
                        </b-row>
                        <b-row no-gutters class="more-detail-row" @click.stop="openDeleteModal">
                          <b-col><div class="delete-text">Delete</div> </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </div>
                </div>
                <div class="time-text">{{ item.updateTime }}</div>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col>
          <a-pagination
            v-show="total > pageSize"
            class="pagin"
            size="small"
            v-model="current"
            :total="total"
            @change="handlePaginationChange"
            showLessItems
            :itemRender="itemPagerRender"
          />
        </b-col>
      </b-row>
      <b-modal
        :visible="moreDetailMenu === 'rename'"
        centered
        no-close-on-backdrop
        no-close-on-esc
        hide-header
        hide-footer
        hide-header-close
        content-class="rename-content-modal"
        body-class="rename-body-modal"
      >
        <b-row no-gutters>
          <b-col>
            <b-row no-gutters>
              <b-col class="title-text">Rename</b-col>
              <b-col>
                <b-img src="@/assets/images/icon_close.svg" class="icon-close" @click.stop="closeRenameModal()" />
              </b-col>
            </b-row>
            <b-row no-gutters>
              <b-col class="name-search-text">Name your search</b-col>
            </b-row>
            <b-row no-gutters>
              <b-col>
                <b-input
                  placeholder="Search result name"
                  v-model="renameText"
                  class="name-input"
                  ref="renameQueryInput"
                  :autofocus="true"
                ></b-input>
              </b-col>
            </b-row>
            <b-row no-gutters>
              <b-col>
                <b-button variant="outline-primary" class="copy-link-button" @click.stop="clickRename()">OK</b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-modal>
      <b-modal
        :visible="moreDetailMenu === 'delete'"
        centered
        no-close-on-backdrop
        no-close-on-esc
        hide-header
        hide-footer
        hide-header-close
        content-class="delete-content-modal"
        body-class="delete-body-modal"
      >
        <b-row no-gutters>
          <b-col>
            <b-row no-gutters>
              <b-img src="@/assets/images/icon_close.svg" class="icon-close-delete" @click.stop="closeDeleteModal(0)" />
              <b-col class="title-text" style="text-align: center">Delete Search</b-col>
            </b-row>
            <b-row no-gutters style="margin-top: 20px">
              <b-col class="name-delete-text"> Are you sure you want to delete this search? </b-col>
            </b-row>
            <b-row no-gutters>
              <b-col class="name-delete-text"> It will be deleted permanently. </b-col>
            </b-row>
            <b-row no-gutters>
              <b-col class="d-flex justify-content-center button-col">
                <b-button variant="outline-primary" class="button-close" @click="closeDeleteModal(0)">Cancel</b-button>
                <b-button variant="outline-primary" class="button-cancel" @click="clickDelete">Delete</b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-modal>
    </div>
    <div v-else>
      <b-row no-gutters>
        <b-col>
          <div class="d-flex justify-content-center">
            <b-img src="@/assets/images/no_results.svg" class="icon-no-result" />
          </div>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col class="no-result-title-text">No saved search</b-col>
      </b-row>
      <b-row no-gutters>
        <b-col>
          <div class="d-flex justify-content-center">
            <div class="no-result-content-text">
              There’s no saved search. To save your search and keep all the criteria and filter, simply click
              <div style="display: inline-block; font-weight: bold">SAVE</div>
              button at the top-right in your search page
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col>
          <div class="d-flex justify-content-center">
            <b-button variant="outline-primary" class="button-new-search" @click="clickNewSearch">NEW SEARCH</b-button>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import SearchCondition from '@/components/analysis/status/SearchCondition.vue';
import dayjs from 'dayjs';
import { groupBy, debounce } from 'lodash';
import { ClickMixin, UpdateMixin } from '@/mixins';
import { UICONFIG, VUE_EVENT_NAME, PAGES } from '@/utils/constants';
import { getRedirectLink, getSharedUrl } from '@/utils/util';
import ClickOutside from 'vue-click-outside';
import { v4 as uuid } from 'uuid';
import { mapSubUiConfig } from '@/utils/uiConfig';
import { mapState, mapMutations } from '../../store';
import ConditionArray from '../../utils/query/core/ConditionArray';

const pageSize = 10;
const WAIT_TIME = 3000; // 3 seconds

function mapResult(element) {
  // eslint-disable-next-line no-underscore-dangle
  const { query, url } = element._source;
  return {
    ...query,
    id: url,
  };
}

export default {
  name: 'SavedList',
  mixins: [ClickMixin, UpdateMixin],
  directives: {
    ClickOutside,
  },
  async mounted() {
    this.loading = true;
    await this.fetchData().catch((error) => {
      console.error(error);
    });
    this.initSavedListLength = this.savedList.length;
    this.$root.$on(VUE_EVENT_NAME.REFRESH_SAVED_LIST, () => {
      this.fetchData();
    });
    this.loading = false;
  },
  destroyed() {
    this.$root.$off(VUE_EVENT_NAME.REFRESH_SAVED_LIST);
  },
  computed: {
    ...mapState('user', ['userId']),
    ...mapState('session', ['tabs']),
    ...mapSubUiConfig('Analyze'),
  },
  data() {
    return {
      savedList: [],
      loading: false,
      nameText: 'Lumos',
      copyLinkMenu: false,
      shareCondition: {},
      moreDetailMenu: false,
      moreDetailIndex: 0,
      moreDetailContent: {},
      renameText: 'New 1',
      searchNameText: '',
      total: pageSize,
      pageSize,
      current: 1,
      initSavedListLength: 0,
      deletedTextTimeout: null,
      copiedTextTimeout: null,
      errorMenuTimeout: null,
    };
  },
  components: {
    SearchCondition,
  },
  watch: {
    searchNameText() {
      if (this.searchNameText === '') {
        this.fetchData();
      } else {
        this.current = 1;
        this.searchByName();
      }
    },
  },
  methods: {
    ...mapMutations('session', ['addTab', 'setActiveTabIndex', 'updateTab']),
    ...mapMutations(['setEMRSearchTabName']),
    getConditions(includes) {
      return new ConditionArray(includes).raws;
    },
    getExtendConditions(includes) {
      return new ConditionArray(includes).additions;
    },
    getFormatData(result) {
      return result.content.hits.hits.map(mapResult);
    },
    async fetchData(pagination = 1) {
      this.current = pagination;
      const result = await this.$api.getUserQuery(this.userId, { page: pagination - 1 }).catch((error) => {
        console.error(error);
      });
      if (result.content) {
        this.total = result.content.hits.total.value;

        this.savedList = this.getFormatData(result);

        this.initSavedListLength = this.savedList.length;
        this.$root.$emit(VUE_EVENT_NAME.SET_VUEPERFECTSCROLLBAR_UPDATE);
      }
    },
    getDuplicatedTabIndex(item) {
      return this.tabs.findIndex((tab) => tab.id === item.id);
    },
    restore(item) {
      if (this.tabs.length >= UICONFIG.MAX_NUM_RESULT_TABS && this.getDuplicatedTabIndex(item) === -1) {
        this.$root.$emit(VUE_EVENT_NAME.ADD_TAB_LIMITATION, item);
        this.trackClick('addTabLimitation', { value: 'addTabLimitation', item });
      } else if (this.tabs.length > 0 && this.getDuplicatedTabIndex(item) !== -1) {
        this.setActiveTabIndex(this.getDuplicatedTabIndex(item));
      } else {
        // eslint-disable-next-line no-param-reassign
        item.data.root = '';
        // eslint-disable-next-line no-param-reassign
        item.data.trackId = uuid();

        this.addTab(item);
        const lastIndex = this.tabs.length - 1;
        const lastTab = this.tabs[lastIndex];
        this.update({
          includes: lastTab.includes,
          excludes: lastTab.excludes,
          uniqueIndex: lastIndex,
        });
        this.trackClick('restore', { value: 'restore', item });
      }
    },
    groupedConditions(data) {
      return Object.values(groupBy(data, 'name'));
    },
    openCopyLinkMenu(item, index) {
      this.moreDetailMenu = false;
      this.copyLinkMenu = index;
      this.shareCondition = item;
      this.nameText = item.name;
      this.trackClick('openCopyLinkMenu', { index, item });
      this.$nextTick(() => {
        try {
          this.$refs.shareQueryInput[0].select();
        } catch (error) {
          console.error(error);
        }
      });
    },
    listener(e) {
      e.clipboardData.setData('text/html', this.content);
      e.clipboardData.setData('text/plain', this.copyLink);
      e.preventDefault();
    },
    copyHint() {
      clearTimeout(this.copiedTextTimeout);
      this.$root.$emit(VUE_EVENT_NAME.OPEN_LINK_COPIED_TEXT_IN_SAVED_SEARCH);
      this.copiedTextTimeout = setTimeout(() => {
        this.$root.$emit(VUE_EVENT_NAME.CLOSE_LINK_COPIED_TEXT_IN_SAVED_SEARCH);
      }, WAIT_TIME);
    },
    async copyToClip(item) {
      this.copyLinkMenu = false;

      this.copyHint();

      const text = this.nameText === '' ? 'unnamed' : this.nameText;
      const sharedUrl = getSharedUrl(item.id);
      this.copyLink = getRedirectLink(sharedUrl);
      this.content = `<div>邀請您使用 AICS Lumos <a href="${this.copyLink}">${text}</a></div>`;

      document.addEventListener('copy', this.listener);
      document.execCommand('copy');
      document.removeEventListener('copy', this.listener);

      this.trackClick('copyToClip', { url: this.copyLink, text });
    },
    initState() {
      this.nameText = 'Lumos';
    },
    closeCopyLinkMenu(index) {
      this.copyLinkMenu = false;
      this.initState();
      if (index === 0) {
        this.trackClick('closeCopyLinkMenu');
      }
    },
    openMoreDetail(item, index) {
      this.copyLinkMenu = false;
      this.moreDetailMenu = index;
      this.moreDetailIndex = index;
      this.moreDetailContent = item;
      this.trackClick('openMoreDetail', { index, item });
    },
    closeMoreDetail(index) {
      this.moreDetailMenu = false;
      if (index === 0) {
        this.trackClick('closeMoreDetail');
      }
    },
    openRenameModal() {
      this.renameText = this.savedList[this.moreDetailIndex].name;
      this.moreDetailMenu = 'rename';
      this.trackClick('openRenameModal', { renameText: this.renameText });
      this.$nextTick(() => {
        this.$refs.renameQueryInput.select();
      });
    },
    closeRenameModal() {
      this.moreDetailMenu = false;
      this.trackClick('closeRenameModal');
    },
    errorHint(e) {
      clearTimeout(this.errorMenuTimeout);
      this.$root.$emit(VUE_EVENT_NAME.OPEN_ERROR_MENU_IN_SAVED_SEARCH, 'Rename query failed!', `${e}`);
      this.errorMenuTimeout = setTimeout(() => {
        this.$root.$emit(VUE_EVENT_NAME.CLOSE_ERROR_MENU_IN_SAVED_SEARCH);
      }, WAIT_TIME);
    },
    async clickRename() {
      try {
        this.trackClick('clickRename', { renameText: this.renameText });
        this.savedList[this.moreDetailIndex].name = this.renameText;
        this.savedList[this.moreDetailIndex].updateTime = dayjs().format('YYYY/MM/DD HH:mm:ss');
        this.moreDetailMenu = false;
        this.loading = true;

        await this.$api
          .updateQuery(this.savedList[this.moreDetailIndex].id, this.savedList[this.moreDetailIndex])
          .catch((error) => {
            console.error(error);
          });
        this.fetchData();
      } catch (e) {
        this.errorHint(e);
        throw Error(`Rename query failed! ${e}`);
      } finally {
        this.loading = false;
      }
    },
    openDeleteModal() {
      this.moreDetailMenu = 'delete';
      this.trackClick('openDeleteModal');
    },
    closeDeleteModal() {
      this.moreDetailMenu = false;
      this.trackClick('closeDeleteModal');
    },
    checkTabId(id) {
      // Delete query that id do not exist
      const indices = [];
      this.tabs.forEach((element, index) => {
        if (element.id === id) {
          indices.push(index);
        }
      });
      if (indices.length !== 0) {
        indices.forEach((element) => {
          this.updateTab({
            index: element,
            id: '',
          });
        });
      }
    },
    deleteErrorHint(e) {
      clearTimeout(this.errorMenuTimeout);
      this.$root.$emit(VUE_EVENT_NAME.OPEN_ERROR_MENU_IN_SAVED_SEARCH, 'Delete query failed!', `${e}`);
      this.errorMenuTimeout = setTimeout(() => {
        this.$root.$emit(VUE_EVENT_NAME.CLOSE_ERROR_MENU_IN_SAVED_SEARCH);
      }, WAIT_TIME);
    },
    deleteHint() {
      clearTimeout(this.deletedTextTimeout);
      this.$root.$emit(VUE_EVENT_NAME.OPEN_DELETED_TEXT_IN_SAVED_SEARCH);
      this.deletedTextTimeout = setTimeout(() => {
        this.$root.$emit(VUE_EVENT_NAME.CLOSE_DELETED_TEXT_IN_SAVED_SEARCH);
      }, WAIT_TIME);
    },
    async clickDelete() {
      try {
        this.trackClick('clickDelete', { data: this.savedList[this.moreDetailIndex] });
        this.moreDetailMenu = false;

        this.loading = true;
        this.current = 1;
        this.checkTabId(this.savedList[this.moreDetailIndex].id);
        const result = await this.$api.deleteQuery(this.savedList[this.moreDetailIndex].id).catch((error) => {
          console.error(error);
        });

        if (result.content) {
          this.total = result.content.hits.total.value;
          this.savedList = result.content.hits.hits.map(mapResult);
          this.initSavedListLength = this.savedList.length;
          this.$root.$emit(VUE_EVENT_NAME.SET_VUEPERFECTSCROLLBAR_UPDATE);
        }

        this.$root.$emit(VUE_EVENT_NAME.SET_VUEPERFECTSCROLLBAR_UPDATE);

        this.deleteHint();
      } catch (e) {
        this.deleteErrorHint(e);
        throw Error(`Delete query failed! ${e}`);
      } finally {
        this.loading = false;
      }
    },
    itemPagerRender(current, type, originalElement) {
      if (type === 'prev') {
        return (
          <a>
            <b-icon-caret-left-fill />
          </a>
        );
      }
      if (type === 'next') {
        return (
          <a>
            <b-icon-caret-right-fill />
          </a>
        );
      }
      return originalElement;
    },
    async handlePaginationChange(pagination) {
      if (this.searchNameText.length > 0) {
        await this.searchByName().catch((error) => {
          console.error(error);
        });
      } else {
        await this.fetchData(pagination).catch((error) => {
          console.error(error);
        });
      }
      this.trackClick('handlePaginationChange', { pagination });
    },
    clickNewSearch() {
      this.setActiveTabIndex(-1);
      this.setEMRSearchTabName(PAGES.SEARCH);
      this.trackClick('clickNewSearch');
    },
    searchByName: debounce(async function () {
      const content = {
        userId: this.userId,
        keyword: this.searchNameText,
        topN: 10,
        page: this.current - 1,
        purpose: 'save',
      };
      this.trackClick('searchSavedQueryByName', { query: this.searchNameText });
      const result = await this.$api.searchQuery(content).catch((error) => {
        console.error(error);
      });
      if (result.content) {
        this.total = result.content.hits.total.value;

        this.savedList = this.getFormatData(result);

        this.$root.$emit(VUE_EVENT_NAME.SET_VUEPERFECTSCROLLBAR_UPDATE);
      }
    }, 100),
  },
};
</script>
<style lang="scss" scoped>
.saved-list {
  padding: 30px 25px 125px 25px;
  min-height: 800px;
}

.icon-save {
  margin-top: -4px;
}

.save-title {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #333f6b;
  height: 23px;
}

.title-row {
  height: 38px;
  border-radius: 6px;
  background-color: #ebecf0;
  margin-top: 10px;
}

.title-name {
  height: 18px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #333f6b;
  margin: 10px 0px 0px 20px;
}

.title-date {
  height: 18px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #333f6b;
  margin: 10px 30px 0px 0px;
}

.data-row {
  height: 68px;
  box-shadow: inset 0 -1px 0 0 #d6d9e1;
  cursor: pointer;
}

.data-row:hover {
  background-color: #eaf2fd;
}

.name-text {
  height: 20px;
  margin: 10px 20px 0px 20px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
}

.condition-text {
  height: 18px;
  margin: 5px 20px 0px 20px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #828282;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}

.time-text {
  height: 20px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #333333;
  width: 170px;
}

.icon-share {
  width: 24px;
  height: 24px;
}

.button-container {
  display: inline-block;
}

.condition-type-text {
  float: left;
  margin-right: 10px;
}

.share-query-text {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333f6b;
}

.share-query-url {
  color: #0000ff;
  word-wrap: break-word;
}

.share-link-menu {
  position: absolute;
  width: 420px;
  height: 200px;
  border-radius: 6px;
  box-shadow: 0 4px 10px 0 rgba(51, 63, 107, 0.2);
  border: solid 1px #d6d9e1;
  background-color: #ffffff;
  padding: 20px 25px 20px 25px;
  z-index: 10;
  margin: 0px 0px 0px -150px;
  cursor: default;
}

.title-text {
  height: 23px;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #333f6b;
}

.icon-close {
  float: right;
  cursor: pointer;
}

.name-search-text {
  height: 20px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
  margin-top: 10px;
}

.name-input {
  height: 40px;
  border-radius: 3px;
  border: solid 1px #333f6b;
  margin-top: 10px;
}

.copy-link-button {
  width: 370px;
  height: 44px;
  border-radius: 6px !important;
  border-color: #333f6b !important;
  background-color: #333f6b !important;
  font-size: 16px !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff !important;
  margin-top: 10px;
}

.highlight-button {
  border-radius: 100px;
  background-color: #ffffff;
}

.more-detail-menu {
  position: absolute;
  width: 130px;
  height: 100px;
  border-radius: 6px;
  box-shadow: 0 4px 10px 0 rgba(51, 63, 107, 0.2);
  border: solid 1px #d6d9e1;
  background-color: #ffffff;
  z-index: 10;
  margin: 0px 0px 0px 25px;
}

.rename-text {
  width: 63px;
  height: 20px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #333f6b;
}

.delete-text {
  width: 63px;
  height: 20px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #da6d6d;
}

.more-detail-row {
  padding: 15px 25px;
}

.more-detail-row:hover {
  background-color: #eaf2fd;
}

/deep/ .rename-content-modal {
  width: 420px;
  height: 200px;
  border-radius: 6px;
  background-color: #ffffff;
  border: 1px solid white;
}

/deep/ .rename-body-modal {
  padding: 20px 25px 20px 25px;
}

#loading-spin {
  top: 50%;
  position: fixed;
  left: 50%;
  z-index: 10;
}

.block {
  position: relative;
  background: transparent;
  opacity: 0.5;
}

.block:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}

/deep/ .delete-content-modal {
  width: 475px;
  height: 217px;
  border-radius: 6px;
  background-color: #ffffff;
  border: 1px solid white;
}

/deep/ .delete-body-modal {
  padding: 30px 30px 30px 30px;
}

.button-col {
  margin-top: 30px;
}

.button-cancel,
.button-cancel:hover,
.button-cancel:focus,
.button-cancel:active,
.button-cancel:active:visited {
  margin-right: 20px;
  width: 168px;
  height: 44px;
  border-radius: 6px;
  border: solid 1px #da6d6d !important;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff !important;
  background-color: #da6d6d !important;
}

.button-close,
.button-close:hover,
.button-close:focus,
.button-close:active,
.button-close:active:visited {
  margin-right: 20px;
  width: 168px;
  height: 44px;
  border-radius: 6px;
  border: solid 1px #333f6b !important;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333f6b !important;
  background-color: #ffffff !important;
}

.name-delete-text {
  height: 20px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333f6b;
}

.icon-close-delete {
  cursor: pointer;
  position: absolute;
  margin: -10px 0px 0px 401px;
}

.name-search {
  width: 505px;
  height: 36px;
  border-radius: 6px;
  border: solid 1px #d6d9e1;
  background-color: #ffffff;
  margin-top: 10px;
  padding-left: 38px;
}

.icon-input-search {
  position: absolute;
  margin: -28px 0px 0px 10px;
}
</style>
<style scoped>
.pagin {
  float: None;
  margin: 48px 0px 30px 0px;
  text-align: center;
}

.pagin >>> .ant-pagination-item-active {
  border: 1px solid #ffffff;
  color: #333f6b;
  font-weight: bold;
}

.pagin >>> .ant-pagination-item-active a {
  color: #333f6b;
}

.pagin >>> .ant-pagination-item-container {
  bottom: 2px;
  font-size: 1px;
}

.pagin >>> .ant-pagination-jump-next,
.pagin >>> .ant-pagination-jump-prev {
  pointer-events: none;
}

.icon-no-result {
  width: 88px;
  height: 88px;
  margin-top: 194px;
  object-fit: contain;
}

.no-result-title-text {
  height: 36px;
  margin-top: 12px;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #333f6b;
}

.no-result-content-text {
  width: 417px;
  height: 82px;
  margin-top: 12px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #333333;
}

.button-new-search,
.button-new-search:hover,
.button-new-search:focus,
.button-new-search:active,
.button-new-search:active:visited {
  width: 417px;
  height: 44px;
  border-radius: 6px;
  background-color: #333f6b !important;
  border: solid 1px #333f6b !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-top: 35px;
}
</style>
<style lang="scss" scoped>
/deep/ .text-keyword-content {
  display: inline !important;
  float: none !important;
}

/deep/ .text-keyword-content .range {
  color: #828282 !important;
  display: inline !important;
}

/deep/ .text-keyword-content .category-text {
  color: #828282 !important;
  display: inline !important;
}

/deep/ .text-keyword-content .keyword-text {
  color: #828282 !important;
  display: inline !important;
}

/deep/ .text-keyword-content .category {
  color: #828282 !important;
  display: inline !important;
}

/deep/ .text-keyword-content .concater {
  top: 0px !important;
}

/deep/ .text-keyword {
  display: inline !important;
  float: none !important;
}

/deep/ .floater {
  display: inline !important;
  float: none !important;
}

/deep/ .condition-type-text {
  display: inline !important;
  float: none !important;
}

.right-div {
  width: 290px;
  max-width: 290px;
  min-width: 290px;
}

.left-div {
  width: calc(100% - 290px);
  max-width: calc(100% - 290px);
  min-width: calc(100% - 290px);
}
</style>
