<template>
  <VuePerfectScrollbar id="app" ref="scroll" @ps-scroll-y="onScrollY" @ps-scroll-x="onScrollX">
    <div class="d-flex flex-column" id="app-content">
      <NavBar v-if="isAuthenticatedLayout" />
      <div :class="['emr-content d-flex', { 'top-offset': isAuthenticatedLayout }]" :style="{ paddingRight: isAuthenticatedLayout ? '40px' : 0 }">
        <ServiceTab v-if="isAuthenticatedLayout" />
        <vue-headful title="Lumos" description="Lumos" />
        <router-view :class="{ 'right-div': isAuthenticatedLayout}" />
      </div>
    </div>
  </VuePerfectScrollbar>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { v4 as uuid } from 'uuid';
import { mapMutations, mapState } from './store';
import { VUE_EVENT_NAME } from '@/utils/constants';
import ServiceTab from '@/views/components/ServiceTab.vue';

export default {
  components: {
    NavBar,
    VuePerfectScrollbar,
    ServiceTab,
  },
  computed: {
    ...mapState(['idleVue']),
    ...mapState('user', ['isLogin']),
    isAuthenticatedLayout() {
      return this.$route.meta.auth;
    },
  },
  methods: {
    ...mapMutations(['setQueueEmpty', 'setScrollTop', 'setScrollLeft', 'setConfirmIcdMap', 'setApi']),
    ...mapMutations('user', ['login', 'setSessionTrackId']),
    onScrollY(event) {
      this.setScrollTop(event.target.scrollTop);
    },
    // be used when windows width < 1280px
    onScrollX(event) {
      this.setScrollLeft(event.target.scrollLeft);
    },
    observeAppHeight() {
      const APP_CONTENT = 'app-content';
      const resizeObserver = new ResizeObserver(() => {
        if (document.getElementById(APP_CONTENT)) {
          this.navBaroffsetHeight = document.getElementById(APP_CONTENT).offsetHeight;
          this.$root.$emit(VUE_EVENT_NAME.SET_VUEPERFECTSCROLLBAR_UPDATE);
        }
      });

      resizeObserver.observe(document.getElementById(APP_CONTENT));
    },
    updateScrollBar() {
      if ('scroll' in this.$refs) {
        this.$refs.scroll.update();
      }
    },
  },
  async mounted() {
    this.login();
    this.$message.config({
      top: '150px',
    });
    this.$api.updateApiQueue.on('idle', () => {
      this.setQueueEmpty(true);
    });
    this.$api.updateApiQueue.on('active', () => {
      this.setQueueEmpty(false);
    });
    this.observeAppHeight();
  },
  created() {
    this.$api.setTrackId(uuid());
    this.$api.setAdvanceTrackId(uuid());
    this.$api.setCohortTrackId(uuid());
    this.setSessionTrackId(uuid());
    this.setApi(this.$api);

    this.$root.$on(VUE_EVENT_NAME.SET_VUEPERFECTSCROLLBAR_UPDATE, this.updateScrollBar);
  },
  destroyed() {
    this.$root.$off(VUE_EVENT_NAME.SET_VUEPERFECTSCROLLBAR_UPDATE, this.updateScrollBar);
  },
};
</script>

<style lang="scss">
html {
  overflow-y: hidden;
}

body {
  overflow-y: hidden;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  background: #f1f0f4;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  height: 100%;
}

.emr-content {
  min-width: 1280px;
}

.emr-title {
  width: 103px;
  height: 23px;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
}

$font-family: Muli, Roboto, Montserrat, 'OpenSans', 'PingFangTC', '微軟正黑體', 'Microsoft JhengHei', sans-serif;
* {
  font-family: $font-family;
}

.ps__rail-y {
  z-index: 1000;
}
</style>
<style lang="scss" scoped>
.right-div {
  width: calc(100% - 88px);
  max-width: calc(100% - 88px);
  min-width: calc(100% - 88px);
  background: #ffffff;
  border-radius: 16px 16px 0px 0px;
  height: calc(100vh - 56px);
}
</style>
<style lang="scss" src="@/assets/scss/global.scss"></style>
