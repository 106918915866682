import IndexEvent from '@/utils/conditions/core/advance/IndexEvent';
import TimePeriod from '@/utils/conditions/core/advance/TimePeriod';
import ConditionQuery from './ConditionQuery';
import ConstraintQueryFactory from './ConstraintQueryFactory';
import InclusionQuery from './InclusionQuery';
import ExclusionQuery from './ExclusionQuery';

class IndexEventQuery {
  private indexEvent: IndexEvent;

  private timePeriod: TimePeriod;

  private constraintQueryFactory = new ConstraintQueryFactory();

  private isFirstTime = false;

  constructor(indexEvent: IndexEvent, timePeriod: TimePeriod) {
    this.indexEvent = indexEvent;
    this.timePeriod = timePeriod;
    this.constraintQueryFactory.setIsIndex();
    this.isFirstTime = indexEvent.constraint.firstOccurrence;
  }

  private getFirstTimeExcludeQuery() {
    let firstConditionList: any[] = [];
    this.indexEvent.condition.forEach((c) => {
      const conditionList = new ConditionQuery(c, this.indexEvent.constraintList).getFirstTimeQuery();
      firstConditionList = firstConditionList.concat(conditionList);
    });
    return {
      bool: {
        must_not: {
          bool: {
            should: firstConditionList,
          },
        },
      },
    };
  }

  getQuery() {
    let conditionQueryList: any[] = [];
    this.indexEvent.condition.forEach((c) => {
      const conditionList = new ConditionQuery(c, this.indexEvent.constraintList).getQuery();
      conditionQueryList = conditionQueryList.concat(conditionList);
    });

    const inclusionsQueryList =
      this.indexEvent.condition.length > 0
        ? new InclusionQuery(this.indexEvent.inclusionList, this.timePeriod).getQuery()
        : [];

    const exclusionsQueryList =
      this.indexEvent.condition.length > 0 
        ? new ExclusionQuery(this.indexEvent.exclusionList, this.timePeriod).getQuery()
        : [];

    const indexConstraintQueryList = this.indexEvent.constraintList
      .map((e) => this.constraintQueryFactory.getQuery(e))
      .filter((e) => e != undefined);

    const firstTimeConstraint = this.isFirstTime ? [this.getFirstTimeExcludeQuery()] : [];

    return [
      {
        bool: {
          should: conditionQueryList,
        },
      },
      ...firstTimeConstraint,
      ...indexConstraintQueryList,
      ...inclusionsQueryList,
      ...exclusionsQueryList,
    ];
  }
}

export default IndexEventQuery;
