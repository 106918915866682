import { throttle } from 'lodash';
import { getPatientQuery } from '@/utils/query';
import { FEEDBACK } from '@/utils/constants';
import { getUiConfig } from '@/utils/uiConfig';
import trackTemplate from '@/mixins/trackTemplate';
import { mapMutations, mapState } from 'vuex';
import { VisitType } from '../utils/conditions/core';

export default {
  mixins: [trackTemplate],
  computed: {
    ...mapState('session', ['tabs']),
    ...mapState('condition', ['inPatient', 'inRecord', 'outPatient', 'outRecord']),
    ...mapState('user', ['userId']),
  },
  methods: {
    ...mapMutations('session', ['updateTab']),
    ...mapMutations('condition', [
      'setInPatient',
      'setInRecord',
      'setRisk',
      'setPatient',
      'setRecord',
      'setOutPatient',
      'setOutRecord',
      'setTotalPatient',
      'setTotalRecord',
    ]),
    async getQueryData({ patientQuery, patientQueryOpd, patientQueryAll }) {
      const reqs = [];

      reqs.push(this.$api.search(patientQuery, 0));

      if (getUiConfig().SearchForm.OPD_Searchable && getUiConfig().Analyze.OPD_Searchable) {
        reqs.push(this.$api.search(patientQueryOpd, 0));
        reqs.push(this.$api.search(patientQueryAll, 0));
      }

      this.$api.setLogQueryInfo({
        type: FEEDBACK.QUERY_TYPE.PATIENT_COUNT,
        conditions: this.$store.getters['condition/currentConditions'],
      });
      const results = await Promise.all(reqs).catch((error) => {
        console.error(error);
      });

      return {
        patient: results[0].aggregations.patientNum.value,
        record: results[0].hits.total.value,
        risk: results[0].aggregations.riskAvg.value,
        results,
      };
    },
    getCountRecordConditions(includes = [], excludes = []) {
      const filterData = includes;
      const excludedFilterData = excludes;
      const query = this.getQuery({
        includes: filterData,
        excludes: excludedFilterData,
      });
      return { filterData, excludedFilterData, query };
    },
    setTabCountData({ results, uniqueIndex, tab, patient, record, risk }) {
      // conditions to track in countRecords
      const conditions = this.getCountRecordConditions(tab.includes, tab.excludes);

      if (getUiConfig().SearchForm.OPD_Searchable && getUiConfig().Analyze.OPD_Searchable) {
        const { outPatient, outRecord } = {
          outPatient: results[1].aggregations.patientNum.value,
          outRecord: results[1].hits.total.value,
        };

        const { totalPatient, totalRecord } = {
          totalPatient: results[2].aggregations.patientNum.value,
          totalRecord: results[2].hits.total.value,
        };

        this.updateTab({
          index: uniqueIndex,
          counters: {
            ...tab.counters,
            [VisitType.IPD]: {
              Patient: patient,
              Record: record,
            },
            [VisitType.OPD]: {
              Patient: outPatient,
              Record: outRecord,
            },
            [VisitType.ALL]: {
              Patient: totalPatient,
              Record: totalRecord,
            },
          },
          data: {
            ...tab.data,
            riskLevel: risk,
          },
        });
        this.countRecords({ totalPatient, totalRecord, ...conditions });
      } else {
        this.updateTab({
          index: uniqueIndex,
          counters: {
            ...tab.counters,
            [VisitType.ALL]: {
              Patient: patient,
              Record: record,
            },
          },
          data: {
            ...tab.data,
            riskLevel: risk,
          },
        });
        this.countRecords({ patient, record, ...conditions });
      }
    },
    setPortalCountData({ patient, record, risk, results, includes, excludes }) {
      this.setInPatient(patient);
      this.setInRecord(record);
      this.setRisk(risk);

      this.setPatient(this.inPatient);
      this.setRecord(this.inRecord);

      // conditions to track in countRecords
      const conditions = this.getCountRecordConditions(includes, excludes);

      if (getUiConfig().SearchForm.OPD_Searchable && getUiConfig().Analyze.OPD_Searchable) {
        const { outPatient, outRecord } = {
          outPatient: results[1].aggregations.patientNum.value,
          outRecord: results[1].hits.total.value,
        };

        this.setOutPatient(outPatient);
        this.setOutRecord(outRecord);

        const { totalPatient, totalRecord } = {
          totalPatient: results[2].aggregations.patientNum.value,
          totalRecord: results[2].hits.total.value,
        };

        this.setTotalPatient(totalPatient);
        this.setTotalRecord(totalRecord);
        this.countRecords({ totalPatient, totalRecord, ...conditions });
      } else {
        this.setTotalPatient(patient);
        this.setTotalRecord(record);
        this.countRecords({ patient, record, ...conditions });
      }
    },
    async updateStatus({
      patientQuery,
      patientQueryOpd,
      patientQueryAll,
      uniqueIndex = undefined,
      includes = [],
      excludes = [],
    }) {
      if (uniqueIndex !== undefined) {
        const { patient, record, risk, results } = await this.getQueryData({
          patientQuery,
          patientQueryOpd,
          patientQueryAll,
        }).catch((error) => {
          console.error(error);
        });

        const tab = this.tabs[uniqueIndex];

        this.setTabCountData({
          results,
          uniqueIndex,
          tab,
          patient,
          record,
          risk,
        });
      } else {
        const { patient, record, risk, results } = await this.getQueryData({
          patientQuery,
          patientQueryOpd,
          patientQueryAll,
        }).catch((error) => {
          console.error(error);
        });

        this.setPortalCountData({
          patient,
          record,
          risk,
          results,
          includes,
          excludes,
        });
      }
      return Promise.resolve();
    },
    update: throttle(async function update({ includes = [], excludes = [], uniqueIndex = undefined }) {
      if (uniqueIndex !== undefined) {
        this.updateTab({
          index: uniqueIndex,
          includes,
          excludes,
        });
      }
      const query = this.getQuery({
        includes,
        excludes,
      });
      const queryOPD = this.getQuery({
        includes,
        excludes,
      });
      const queryAll = this.getQuery({
        includes,
        excludes,
      });
      const patientQuery = getPatientQuery(query);
      patientQuery.comp = 'IPD';
      const patientQueryOpd = getPatientQuery(queryOPD);
      patientQueryOpd.comp = 'OPD';
      const patientQueryAll = getPatientQuery(queryAll);
      this.$api.getQuery = patientQueryAll;
      await this.updateStatus({
        patientQuery,
        patientQueryOpd,
        patientQueryAll,
        uniqueIndex,
        includes,
        excludes,
      }).catch((error) => {
        console.error(error);
      });
      return Promise.resolve();
    }, 100),
    countRecords(value) {
      this.$api.feedback(
        FEEDBACK.NAME.COUNT_RECORDS,
        {
          [FEEDBACK.COMPONENT_NAME]: this.$options.name,
          [FEEDBACK.USER_ID]: this.userId,
          [FEEDBACK.VALUE]: value,
          [FEEDBACK.ROUTE]: this.$route.path,
          [FEEDBACK.TIME]: Date.now(),
        },
        this.trackContentTemplate()
      );
    },
  },
};
