import { inRange } from 'lodash';
import DataCondition from './DataCondition';
import { RangeConditionData, RangeData } from './Interface';

abstract class RangeCondition extends DataCondition<RangeConditionData> implements RangeConditionData {
  public get ranges(): RangeData[] {
    return this.view.ranges;
  }

  public get view(): RangeConditionData {
    const ranges = this.data.map((cond) => cond.ranges).reduce((results, range) => results.concat(range), []);

    return {
      ...super.view,
      ranges,
    };
  }

  public hitStartOrEnd(start?: number, end?: number): boolean {
    return this.ranges.some((range) => range.start === start || range.end + 1 === end);
  }

  public isSurrounding(num: number): boolean {
    return this.ranges.some((range) => inRange(num, range.start, range.end + 1));
  }
}

export default RangeCondition;
