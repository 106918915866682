import { CHART } from '../constants';
import { CategoryCondition, ConditionFactory, ConditionName } from './core';

function getSectionQuery(section: string[]) {
  return section.map((value) => ({
    term: {
      SECTION_NAME: value,
    },
  }));
}

export default class SectionDef extends CategoryCondition {
  // static get TYPE(): string { return ConditionType.CATEGORY; }

  static get NAME(): string {
    return CHART.TITLE.SECTION;
  }

  public getFilter(): Record<string, unknown> {
    return {
      bool: {
        [this.andOr]: getSectionQuery(this.list),
      },
    };
  }
}

ConditionFactory.register(SectionDef, ConditionName.Section);
