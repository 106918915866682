<template>
  <div>
    <b-row no-gutters>
      <b-col>
        <PortalSearchDescription :uniqueIndex="uniqueIndex" />
        <b-row no-gutters style="margin-top: 20px">
          <b-col class="d-flex align-items-center">
            <div class="content-text">Statistics period: within</div>
            <a-select
              default-value="6 months"
              class="d-inline-block statistics-period-select"
              @change="changeStatisticsPeriod"
              :getPopupContainer="(trigger) => trigger.parentNode"
            >
              <a-icon slot="suffixIcon" type="caret-down" />
              <a-select-option value="6" :key="6" title="6 month"> 6 month(s) </a-select-option>
              <a-select-option value="12" :key="12" title="12 month"> 1 year(s) </a-select-option>
            </a-select>
            <div class="content-text">after</div>
            <div :id="`index-event${uuid}`" class="event-name">{{ `\xa0${indexEvent.name}` }}</div>
            <b-tooltip :target="`index-event${uuid}`" triggers="hover" variant="tooltip-style">
              <EventToolTip :event="indexEvent" />
            </b-tooltip>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { cloneDeep } from 'lodash';

import PortalSearchDescription from '@/components/advanceSearch/status/PortalSearchDescription.vue';
import EventToolTip from '@/components/advanceSearch/EventToolTip.vue';
import { ClickMixin } from '@/mixins';
import { DateUnit } from '@/utils/conditions/core/Interface';
import { v4 as uuid } from 'uuid';

import { mapState, mapMutations } from '../../../store';

export default {
  name: 'TabSearchDescription',
  components: {
    PortalSearchDescription,
    EventToolTip,
  },
  mixins: [ClickMixin],
  data() {
    return {
      timeValue: '6',
      timeUnit: DateUnit.MONTHS,
      timeUnitList: [DateUnit.DAYS, DateUnit.MONTHS, DateUnit.YEARS],
      uuid: '',
    };
  },
  props: {
    uniqueIndex: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapState('byEventSession', ['tabs']),
    tab() {
      return this.tabs[this.uniqueIndex];
    },
    indexEvent() {
      return this.tab.SearchStateObject.indexEvent;
    },
  },
  mounted() {
    this.uuid = uuid();
  },
  methods: {
    ...mapMutations('byEventSession', ['updateTab']),
    changeStatisticsPeriod(value) {
      const currentTab = cloneDeep(this.tab);
      currentTab.TimeSelectObject.afterValue = value;
      this.updateTab({
        index: this.uniqueIndex,
        ...currentTab,
      });

      this.advanceTrackClick('changeStatisticsPeriod', {
        tabIndex: this.uniqueIndex,
        value,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.content-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #333333;
  height: 26px;
  margin-top: 5px;
}

.event-name {
  color: #0f4895;
  font-weight: bold;
}

.statistics-period-select {
  width: 130px;
  height: 26px;
  margin: 0px 5px;

  /deep/ .ant-select-selection--single {
    border-radius: 53px;
    height: 26px;
  }

  /deep/ .ant-select-selection-selected-value {
    font-weight: bold;
    color: #333f6b;
    font-size: 18px;
    height: 26px;
  }

  /deep/ .ant-select-arrow {
    color: #333f6b;
  }

  /deep/.ant-select-selection__rendered {
    height: 26px;
    line-height: 26px;
  }
}
</style>
