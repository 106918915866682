import { CategoryCondition, ConditionFactory, ConditionName } from './core';

function getChartNoQuery(chartno: string[]): Record<string, unknown>[] {
  return chartno.map((value) => ({
    term: {
      CHART_NO: value,
    },
  }));
}

export default class ChartNorDef extends CategoryCondition {
  static get NAME(): string {
    return 'ChartNo';
  }

  public getFilter(): Record<string, unknown> {
    return {
      bool: {
        [this.andOr]: getChartNoQuery(this.list),
      },
    };
  }
}

ConditionFactory.register(ChartNorDef, ConditionName.ChartNo);
