import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import VueCookie from 'vue-cookie';

import { v4 as uuid } from 'uuid';
import { COOKIE, SESSION } from '../../utils/constants';
import { clearAllCookie } from '@/utils/cookie';

// Set rawError to true by default on all @Action decorators
// config.rawError = true;

const SESSION_TOKEN = 'session-token';

@Module({ namespaced: true })
export default class User extends VuexModule {
  isLogin = false;

  userId = '';

  name = '';

  email = '';

  permission: any = {};

  sessionTrackId = '';

  @Mutation
  login(meta: { auth: boolean }): void {
    const session = VueCookie.get(COOKIE.SESSION_ID);
    const userID = VueCookie.get(COOKIE.USER_ID);
    this.name = VueCookie.get(COOKIE.USER_NAME);
    this.email = VueCookie.get(COOKIE.USER_EMAIL);
  
    if (session && meta && meta.auth) {
      this.isLogin = true;
      this.userId = userID;

      if (!localStorage.getItem(SESSION_TOKEN) || localStorage.getItem(SESSION_TOKEN) !== session) {
        localStorage.setItem(SESSION_TOKEN, session);
        localStorage.setItem(SESSION.LOGIN_TRACK_ID, uuid());
      }

      if (!localStorage.getItem(SESSION.LOGIN_TRACK_ID)) {
        localStorage.setItem(SESSION.LOGIN_TRACK_ID, uuid());
      }

      if (!this.sessionTrackId) {
        this.sessionTrackId = uuid();
      }
    } else {
      this.isLogin = false;
      this.userId = '';
    }
  }

  @Mutation
  setPermission(permission: any): void {
    this.permission = permission;
  }

  @Mutation
  resetUser(): void {
    this.isLogin = false;
    this.userId = '';
    clearAllCookie();
  }

  @Mutation
  setUserId(userId: string): void {
    this.userId = userId;
  }

  @Mutation
  setSessionTrackId(sessionTrackId: string): void {
    this.sessionTrackId = sessionTrackId;
  }

  @Action
  logout() {
    this.context.commit('resetUser');
    this.context.commit('session/resetSession', null, { root: true });
    this.context.commit('condition/resetCondition', null, { root: true });
    localStorage.removeItem(SESSION.LOGIN_TRACK_ID);
    localStorage.removeItem(SESSION_TOKEN);
    this.sessionTrackId = '';
  }
}
