import { ChartKey, IndicatorChartQueryParams, RawItem } from '@/components/charts/core/Interface';
import AdvanceESQuery from '@/utils/query/core/advance/AdvanceESQuery';
import { cloneDeep, isEmpty } from 'lodash';
import { foolProof } from '@/utils/advance/foolproof';
import dayjs from 'dayjs';
import Indicator from '@/utils/indicatorConfig/Indicator';
import ConstraintType from '@/utils/conditions/core/advance/constraint/Interface';
import AdvChartConfig from '../../advance/core/AdvChartConfig';
import ApiHelper from '@/utils/ApiHelper';
import { FEEDBACK } from '@/utils/constants';

interface DiagnosisRawItem extends RawItem {
  count_unique_patient: {
    value: number;
  };
}

export default abstract class IndicatorChartConfig extends AdvChartConfig<DiagnosisRawItem> {
  protected params!: IndicatorChartQueryParams;
  indicator: Indicator;
  protected results: any[] = [];

  constructor(key: ChartKey, indicator: Indicator) {
    super(key);

    this.indicator = indicator;
  }

  public getConditions() {
    const { timePeriod, ageRange } = this.params;
    const data = [this.indicator.denominator, this.indicator.numerator];

    return data.map((item) => {
      const input = cloneDeep(item);
      input.SearchStateObject.timePeriod = {
        start: dayjs.unix(timePeriod.start).year(),
        end: dayjs.unix(timePeriod.end).year(),
      };

      const ageConstraint = {
        constraintType: ConstraintType.AgeConstraint,
        start: ageRange.start,
        end: ageRange.end,
      };
      input.SearchStateObject.eventList.forEach((event: any, index: number) => {
        if (!isEmpty(event.condition)) {
          input.SearchStateObject.eventList[index].constraintList = [ageConstraint];
        }
      });
      if (!isEmpty(input.SearchStateObject.indexEvent.condition)) {
        input.SearchStateObject.indexEvent.constraintList.push(ageConstraint);
      }

      return input;
    });
  }

  public getQueries(queryEnhancer = (q: any) => q) {
    return this.getConditions().map((condition) => {
      const query = new AdvanceESQuery(foolProof(condition)).getQuery();

      return queryEnhancer(query);
    });
  }

  protected async fetchData(api: ApiHelper, queries: any[]) {
    const [denomQuery, numerQuery] = queries;
    const [denomCondition, numerCondition] = this.getConditions();
    api.setLogQueryInfo({
      type: FEEDBACK.QUERY_TYPE.CHART_AGGREGATE_FIXED,
      conditions: denomCondition,
    });
    const denominator = await api.advSearch(denomQuery, 0);

    api.setLogQueryInfo({
      type: FEEDBACK.QUERY_TYPE.CHART_AGGREGATE_FIXED,
      conditions: numerCondition,
    });
    const numerator = await api.advSearch(numerQuery, 0);

    return Promise.resolve([denominator, numerator]);
  }
}
