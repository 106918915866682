import { EventType } from '@/utils/conditions/core/Interface';
import { EventSearchMixin, EventUpdateMixin, ClickMixin } from '@/mixins';

export default {
  mixins: [EventSearchMixin, EventUpdateMixin, ClickMixin],
  props: {
    index: {
      type: Number,
      default: 1,
    },
    eventType: {
      type: String,
      default: EventType.FIX_EVENT,
    },
    path: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    currentEvent() {
      return this.getEventDataByPath(this.path);
    },
    hasCondition() {
      return this.currentEvent.condition.length > 0;
    },
  },
};
