import BasicChartConfig from '@/components/charts/core/BasicChartConfig';
import { BasicChartQueryParams, ChartItem, ChartKey, RawItem } from '@/components/charts/core/Interface';
import { sourceOption, CodeViewAllOption } from '@/utils/util';
import { sourceData } from '@/components/charts/utils/utils';
import { UICONFIG } from '@/utils/constants';
import { CategoryConditionData, ConditionName, LogicOp, SearchType, VisitType } from '../../utils/conditions/core';

type PatientSourceRawItem = RawItem;

export default class PatientSourceConfig extends BasicChartConfig<PatientSourceRawItem> {
  constructor() {
    super(ChartKey.PatientSource);

    this.hasViewAll = true;
    this.merge({ ...sourceOption });
    this.bucketByPatient = false;
    this.disallowBucketChange = true;
  }

  protected setAxisData(): void {
    this.unset('yAxis.data');
    this.merge({
      yAxis: {
        data: this.data.map((item) => item.key),
        visitType: VisitType.IPD,
      },
    });
  }

  public createViewAllConfig(): PatientSourceConfig {
    const config = new PatientSourceConfig();
    config.resultSize = UICONFIG.VIEW_ALL_SIZE;
    config.merge({ ...CodeViewAllOption });
    return config;
  }

  protected processQueryParams(): BasicChartQueryParams {
    const condList = this.params.includes.without(ConditionName.PatientSource);
    return {
      ...this.params,
      includes: condList,
      visitType: VisitType.IPD,
    };
  }

  protected createChartItem(rawItem: PatientSourceRawItem): ChartItem {
    const { key, doc_count } = rawItem;
    const condList = this.params.includes.additions.with(ConditionName.PatientSource);

    return sourceData(key, doc_count, this.recordCount, condList);
  }

  public createConditionFromItem(chartItem: ChartItem): CategoryConditionData {
    return {
      name: ConditionName.PatientSource,
      tags: [SearchType.Basic, SearchType.Additional],
      andOr: LogicOp.Or,
      list: [chartItem.name],
    };
  }

  protected get aggsQuery(): Record<string, unknown> {
    return {
      terms: {
        field: 'PATIENT_SOURCE_NAME',
        size: this.resultSize,
        shard_size: UICONFIG.SHARD_SIZE,
      },
    };
  }
}
