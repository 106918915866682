import TimePeriod from '@/utils/conditions/core/advance/TimePeriod';

import { numberRange } from '@/utils/util';

class TimePeriodQuery {
  private timePeriod: TimePeriod;

  private isTimeRelation: boolean;

  constructor(timePeriod: TimePeriod, isTimeRelation?: boolean) {
    this.timePeriod = timePeriod;
    this.isTimeRelation = isTimeRelation || false;
  }

  getQuery() {
    const queryPathName = `${this.isTimeRelation ? 'EVENT_HISTORY.' : ''}TIME_KEY_YEAR`;
    const timeRangeKey = numberRange(this.timePeriod.start, this.timePeriod.end);
    return {
      terms: {
        [queryPathName]: timeRangeKey,
      },
    };
  }
}

export default TimePeriodQuery;
