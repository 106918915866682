import { ConditionFactory, ConditionName, LabCondition } from './core';

export default class LabDef extends LabCondition {
  static get NAME(): string {
    return 'Lab';
  }

  public getFilter(): Record<string, unknown> {
    const ranges = this.ranges.map((item) => ({
      range: {
        'FIRST_LAB.VALUE': {
          gte: Number.parseFloat(item.start.toString()),
          lte: Number.parseFloat(item.end.toString()),
        },
      },
    }));
    return {
      nested: {
        path: 'FIRST_LAB',
        query: {
          bool: {
            filter: [
              {
                match: {
                  'FIRST_LAB.CHSPECI': this.exam,
                },
              },
              {
                match: {
                  'FIRST_LAB.CHHEAD': this.lab,
                },
              },
              {
                bool: {
                  should: ranges,
                },
              },
            ],
          },
        },
      },
    };
  }
}

ConditionFactory.register(LabDef, ConditionName.Lab);
