<template>
  <b-modal
    :visible="tabIndex !== -1"
    centered
    no-close-on-backdrop
    no-close-on-esc
    hide-header
    hide-footer
    hide-header-close
  >
    <b-img src="@/assets/images/icon_close.svg" class="close-icon" @click="closeModal" />
    <b-row no-gutters>
      <b-col>
        <b-row no-gutters>
          <b-col>
            <b-img src="@/assets/images/icon_alert.svg" class="alert-icon" />
          </b-col>
        </b-row>
        <b-row no-gutters>
          <b-col class="modal-title-text">
            <div v-if="hasTabId">Unsaved changes</div>
            <div v-else>Unsaved result</div>
          </b-col>
        </b-row>
        <b-row no-gutters>
          <b-col class="modal-content-text">
            <div v-if="hasTabId">You have changed this search result, would you like to apply this update?</div>
            <div v-else>Would you like to save this search result?</div>
          </b-col>
        </b-row>
        <b-row no-gutters>
          <b-col>
            <b-form-input
              placeholder="Search result name"
              v-model="nameText"
              class="name-input"
              ref="unsaveRenameInput"
              :autofocus="true"
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row no-gutters>
          <b-col class="button-col d-flex">
            <b-button variant="outline-primary" class="button-cancel" @click="leaveModal">LEAVE</b-button>
          </b-col>
          <b-col class="button-col d-flex justify-content-end">
            <b-button variant="outline-primary" class="button-close" @click="saveTab">
              <div v-if="hasTabId">UPDATE</div>
              <div v-else>SAVE</div>
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>
import dayjs from 'dayjs';

import { ClickMixin } from '@/mixins';
import { mapState, mapMutations } from '../../store';
import { VUE_EVENT_NAME } from '@/utils/constants';

export default {
  name: 'UnsavedChanges',
  mixins: [ClickMixin],
  computed: {
    ...mapState(['unsavedVisible']),
    ...mapState('session', ['tabs', 'historyTabs']),
    tab() {
      if (this.tabIndex === -1) {
        return null;
      }

      this.$nextTick(() => {
        this.$refs.unsaveRenameInput.select();
      });
      return this.tabs[this.tabIndex];
    },
    hasTabId() {
      return this.tab && this.tab.id !== '';
    },
  },
  mounted() {
    this.$root.$on(VUE_EVENT_NAME.UNSAVED_ALERT, (data) => {
      this.tabIndex = data.tabIndex;
      this.nameText = this.tab.name;
    });
  },
  destroyed() {
    this.$root.$off(VUE_EVENT_NAME.UNSAVED_ALERT);
  },
  data() {
    return {
      tabIndex: -1,
      nameText: 'New 1',
    };
  },
  methods: {
    ...mapMutations('session', ['removeTab', 'updateTab', 'addHistoryTab']),
    moveToHistory(tab, index) {
      // eslint-disable-next-line no-param-reassign
      tab.updateTime = dayjs().format('YYYY/MM/DD HH:mm:ss');
      this.addHistoryTab(tab);
      this.$api.saveQuery(tab.name, tab, 'history');
      this.removeTab(index);
    },
    closeModal() {
      this.tabIndex = -1;
      this.trackClick('closeModal');
    },
    leaveModal() {
      this.moveToHistory(this.tab, this.tabIndex);
      this.tabIndex = -1;
      this.trackClick('leaveModal', { tab: this.tab, tabIndex: this.tabIndex });
    },
    async saveTab() {
      this.updateTab({
        index: this.tabIndex,
        updateTime: dayjs().format('YYYY/MM/DD HH:mm:ss'),
      });
      const index = this.tabIndex;
      const { tab } = this;
      this.tabIndex = -1;

      // rename text
      tab.name = this.nameText;

      if (tab.id === '') {
        this.trackClick('saveTab', { tab, type: 'save' });

        const { url } = await this.$api.saveQuery(tab.name, tab, 'save').catch((error) => {
          console.error(error);
        });
        this.updateTab({
          index,
          id: url,
        });
      } else {
        this.trackClick('saveTab', { tab, type: 'update' });

        await this.$api.updateQuery(tab.id, tab).catch((error) => {
          console.error(error);
        });
      }
      this.$root.$emit(VUE_EVENT_NAME.REFRESH_SAVED_LIST);
      this.moveToHistory(tab, index);
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .modal-content {
  width: 475px;
  border-radius: 6px;
  background-color: #ffffff;
  border: 1px solid white;
}

/deep/ .modal-body {
  padding: 30px 30px 30px 30px;
}

.modal-title-text {
  height: 23px;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333f6b;
}

.close-icon {
  width: 24px;
  height: 24px;
  position: absolute;
  margin: -10px 0px 0px 401px;
  cursor: pointer;
}

.modal-content-text {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333f6b;
  margin-top: 20px;
}

.button-col {
  margin-top: 30px;
}

.button-cancel,
.button-cancel:hover,
.button-cancel:focus,
.button-cancel:active,
.button-cancel:active:visited {
  height: 44px;
  border-radius: 6px;
  border: solid 1px #da6d6d !important;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #da6d6d !important;
  background-color: #ffffff !important;
  width: 198px;
}

.button-close,
.button-close:hover,
.button-close:focus,
.button-close:active,
.button-close:active:visited {
  height: 44px;
  border-radius: 6px;
  border: solid 1px #333f6b !important;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff !important;
  background-color: #333f6b !important;
  width: 198px;
}

.alert-icon {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.name-input {
  border-radius: 3px;
  border: solid 1px #333f6b;
}
</style>
