export function getPatientQuery(query) {
  return {
    ...query,
    _source: false,
    docvalue_fields: ['CHART_NO', 'TIME_KEY', 'LAST_RECORD_DATE'],
    track_total_hits: true,
    size: 10000,
  };
}

export function getPatientListQuery(query, covariate) {
  const docvalue_fields = ['CHART_NO', 'SEX', 'AGE', 'TIME_KEY', 'LAST_RECORD_DATE'];

  if (covariate.bodyMassList && covariate.bodyMassList.some((item) => item.type === 'height')) {
    docvalue_fields.push('HEIGHT');
  }
  if (covariate.bodyMassList && covariate.bodyMassList.some((item) => item.type === 'weight')) {
    docvalue_fields.push('WEIGHT');
  }
  if (covariate.bodyMassList && covariate.bodyMassList.some((item) => item.type === 'BMI')) {
    docvalue_fields.push('BMI');
  }
  return {
    ...query,
    _source: false,
    docvalue_fields,
    track_total_hits: true,
    size: 10000,
  };
}
