















































import { Vue, Component, Prop, Mixins } from 'vue-property-decorator';

import { xorWith } from 'lodash';
import { mapState } from '../../../../store';

import { ClickMixin, UpdateMixin } from '../../../../mixins';
import TemplateValue from '../../../../mixins/templateValue';
import ICDValue from './medicalTerm/ICDValue.vue';
import MedicationValue from './medicalTerm/MedicationValue.vue';
import ProcedureValue from './medicalTerm/ProcedureValue.vue';
import MedicalOrderValue from './medicalTerm/MedicalOrderValue.vue';
import ATCCodeValue from './medicalTerm/ATCCodeValue.vue';
import PCSCodeValue from './medicalTerm/PCSCodeValue.vue';
import DRGCodeValue from './medicalTerm/DRGCodeValue.vue';

// eslint-disable-next-line no-unused-vars
import Condition from '../../../../utils/conditions/core/Condition';
// eslint-disable-next-line no-unused-vars
import { MedicalTerm } from '../../../../utils/conditions/core';
import { STATUS_MAP } from '@/utils/constants';

declare module 'vue/types/vue' {
  export interface Vue {
    update(params: any): any;
    trackClick(name: string, params: any): any;
  }
}

@Component<CategoricalValue>({
  components: {
    ICDValue,
    MedicationValue,
    ProcedureValue,
    MedicalOrderValue,
    ATCCodeValue,
    PCSCodeValue,
    DRGCodeValue,
  },
  computed: {
    ...mapState('session', ['tabs']),
  },
  mixins: [Vue.extend(ClickMixin), Vue.extend(UpdateMixin)],
})
export default class CategoricalValue extends Mixins(TemplateValue) {
  @Prop(Array) categories!: string[] | MedicalTerm[];

  @Prop({ type: Number, default: 0 })
  private readonly uniqueIndex!: number;

  private get tab() {
    if (this.tabs.length === 0) {
      return {
        includes: [],
        excludes: [],
      };
    }
    return this.tabs[this.uniqueIndex];
  }

  onDelete(item: Condition, index: number): void {
    const extendConditions = [this.refCond[index]];

    const feedbackData = {
      includes: xorWith(this.tab.includes, extendConditions),
      excludes: this.tab.excludes,
      value: item,
      uniqueIndex: this.uniqueIndex,
    };
    this.trackClick('onDelete', feedbackData);
    this.update({
      includes: xorWith(this.tab.includes, extendConditions),
      excludes: this.tab.excludes,
      uniqueIndex: this.uniqueIndex,
    });
  }

  getVisitText(text: string): string {
    if (text === 'I') {
      return 'Inpatient';
    }
    if (text === 'O') {
      return 'Outpatient';
    }
    return 'ER';
  }

  getGenderText(text: string): string {
    if (text === 'M') {
      return 'Male';
    }
    if (text === 'F') {
      return 'Female';
    }
    return 'Unknown';
  }

  getHabitText(text: { habit: string; status: string }): string {
    const HABIT_MAP: { [k: string]: string } = {
      smoke: '抽菸習慣',
      alcohol: '喝酒習慣',
      betelnut: '吃檳榔習慣',
    };
    return `${HABIT_MAP[text.habit]}: ${STATUS_MAP[text.status]}`;
  }
}
