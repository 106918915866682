





import { Vue, Component, Prop } from 'vue-property-decorator';
// eslint-disable-next-line no-unused-vars
import { MedicalTerm } from '../../../../../utils/conditions/core';

@Component
export default class MedicalOrderValue extends Vue {
  @Prop(Object)
  private readonly value!: MedicalTerm;
}
