<template>
  <div class="event-tooltip">
    <EventDescribeTemplate :event="event" class="condition-block" title="Condition" />
    <div v-if="hasInclusion" class="clusion-block">
      <div v-for="(inclusionEvent, key) in event.inclusionList" :key="key">
        <EventDescribeTemplate :event="inclusionEvent" title="Inclusion" />
      </div>
    </div>
    <div v-if="hasExclusion" class="clusion-block">
      <div v-for="(exclusionEvent, key) in event.exclusionList" :key="key">
        <EventDescribeTemplate :event="exclusionEvent" title="Exclusion" />
      </div>
    </div>
  </div>
</template>

<script>
import EventDescribeTemplate from '@/components/template/EventDescribeTemplate.vue';

export default {
  name: 'EventToolTip',
  props: {
    event: {
      type: Object,
      default: null,
    },
  },
  components: {
    EventDescribeTemplate,
  },
  computed: {
    hasInclusion() {
      return this.event.inclusionList.filter((event) => event.condition.length > 0).length > 0;
    },
    hasExclusion() {
      return this.event.exclusionList.filter((event) => event.condition.length > 0).length > 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.condition-block {
  border: 1px solid #bdbdbd;
  border-radius: 6px;
}

.clusion-block {
  margin-top: 10px;
  background: #e6f3fc;
  border-radius: 6px;
}

.event-tooltip {
  padding: 10px;
}
</style>
