<template>
  <div
    v-on:click.stop="addAgeConstraint"
    class="advance-ageFilter-adder"
    :class="{ disabled: this.constraintIndex !== -1 }"
    v-if="hasCondition"
  >
    + Age
  </div>
</template>

<script>
import { findIndex, cloneDeep } from 'lodash';
import { EventType } from '@/utils/conditions/core/Interface';
import { EventSearchMixin, EventUpdateMixin, ClickMixin, EventFilterMixin } from '@/mixins';
import ConstraintType from '@/utils/conditions/core/advance/constraint/Interface';

export default {
  name: 'AgeFilter',
  props: {
    index: {
      type: Number,
      default: 1,
    },
    eventType: {
      type: String,
      default: EventType.FIX_EVENT,
    },
    path: {
      type: Array,
      default: () => [],
    },
  },
  mixins: [EventSearchMixin, EventUpdateMixin, ClickMixin, EventFilterMixin],
  computed: {
    currentEvent() {
      return this.getEventDataByPath(this.path);
    },
    constraintIndex() {
      return findIndex(this.currentEvent.constraintList, {
        constraintType: ConstraintType.AgeConstraint,
      });
    },
  },
  methods: {
    addAgeConstraint() {
      const ageConstraint = {
        start: 0,
        end: 200,
      };
      const event = cloneDeep(this.currentEvent);

      if (this.constraintIndex === -1) {
        event.constraintList.push({
          constraintType: ConstraintType.AgeConstraint,
          ...ageConstraint,
        });
      }
      const body = {
        constraintList: event.constraintList,
      };
      this.updateEventWithPath(body, this.path);
      this.setCurrentMenu(this.path, 'AgeConstraint');
      this.cohortStudyTrackClick('openAgeFilter', {
        path: this.path,
        index: this.index,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.advance-ageFilter-adder {
  padding: 4px 8px;
  background: #ffffff;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #4d8edc;
  cursor: pointer;
  border: 1px solid #4d8edc;
  box-sizing: border-box;
  border-radius: 4px;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
