import ConstraintName from '@/utils/conditions/core/advance/constraint/Interface';
import AgeConstraintQuery from './AgeConstraintQuery';
import { VisitTypeName, EventDiagnosisRange } from '@/utils/conditions/core/Interface';

class ConstraintQueryFactory {
  private isTimeRelation = false;

  public setIsIndex() {
    this.isTimeRelation = false;
  }

  public setIsTimeRelation() {
    this.isTimeRelation = true;
  }

  public getVisitType(constraintList: any) {
    const index = constraintList.findIndex(
      (data: { constraintType: any }) => data.constraintType === ConstraintName.VisitTypeConstraint
    );
    if (index === -1) {
      return VisitTypeName.ALL;
    }
    return constraintList[index].visitType;
  }

  public getIcdRange(constraintList: any) {
    const index = constraintList.findIndex(
      (data: { constraintType: any }) => data.constraintType === ConstraintName.DiagnosisRangeConstraint
    );
    if (index === -1) {
      return EventDiagnosisRange.ALL;
    }
    return constraintList[index].diagnosisRange;
  }

  public getQuery(constraint: any) {
    if (constraint.constraintType === ConstraintName.AgeConstraint) {
      return new AgeConstraintQuery(constraint, this.isTimeRelation).getQuery();
    }
    return undefined;
  }
}
export default ConstraintQueryFactory;
