<template>
  <div>
    <div
      class="button-export d-flex align-items-center justify-content-center"
      @click="showModal"
      :class="visible ? 'enable-button' : ''"
      :disabled="exportLoading"
    >
      <b-img src="@/assets/images/icon_export.svg" style="margin-right: 10px" v-show="!exportLoading" />
      <span class="dropdown-text">Export</span>
    </div>
    <div class="export-limit-error-menu" v-if="exportLimitHint" v-click-outside="closeExportLimitHint">
      <div class="d-flex justify-content-between align-items-center">
        <div class="export-limit-error-text">Please Try Again Later</div>
        <b-img src="@/assets/images/icon_close_white.svg" style="cursor: pointer" @click="closeExportLimitHint" />
      </div>
      <div class="mt-5px">
        <div class="export-limit-error-content-text">
          {{
            `You can only export up to ${MAX_EXPORT_LIMIT} files at a time. Our system is working on processing your requests; please check the status of your current exports`
          }}
        </div>
        <div class="d-flex align-items-center">
          <div class="export-limit-error-content-text">in the Export Manager.</div>
          <img alt="aics" title="Exports" src="@/../public/img/navbar/icon_export_center.svg" class="ml-5px mr-5px" />
        </div>
      </div>
    </div>
    <a-modal
      id="exportModal"
      v-model="visible"
      class="export-modal"
      destroyOnClose
      centered
      :closable="false"
      :zIndex="9999"
      :footer="null"
      :maskClosable="!examItemNameVisible"
    >
      <template #closeIcon>
        <img src="@/../public/img/icon_close.svg" alt="icon_close" />
      </template>
      <template #title>
        <div class="title">Export</div>
      </template>
      <div class="warning-select-text mt-10px mb-5px" v-if="exportRecordsLimit">
        Exceeded record export limit. Your export will only contain up to 100,000 inpatient and outpatient records each.
      </div>
      <div
        class="error-select-text mb-5px"
        v-if="emptySelectError"
        :class="{
          'mt-20px': emptySelectError && exportRecordsLimit,
          'mt-10px': emptySelectError && !exportRecordsLimit,
        }"
      >
        Please select at least 1 export item
      </div>
      <a-checkbox-group v-model="checkedOptions" class="export-checkbox-group">
        <div
          v-for="(option, index) in exportOptions"
          :key="`${option}-${index}-exportOptions`"
          class="export-item mt-15px"
          :title="
            !hasMedication && option === DOWNLOAD.ORDERS
              ? 'Select at least one medication in your search criteria to enable medications export.'
              : ''
          "
        >
          <a-checkbox :value="option" :disabled="!hasMedication && option === DOWNLOAD.ORDERS">
            {{ option }}
          </a-checkbox>
          <template v-if="option === DOWNLOAD.LABS">
            <div>
              <div class="dropdown-lab-menu" v-if="examItemNameVisible" v-click-outside="closeDropdown">
                <div class="d-flex">
                  <div class="specimen-block mr-5px">
                    <div style="padding: 20px">
                      <div class="lab-filter-title mb-20px">Specimens</div>
                      <a-input placeholder="Search specimens" v-model="specimenKeyword" />
                    </div>
                    <DynamicScroller
                      :items="getCandidate('specimen')"
                      :min-item-size="24"
                      class="scroller-specimen"
                      key-field="name"
                    >
                      <template v-slot="{ item, index, active }">
                        <DynamicScrollerItem
                          :item="item"
                          :active="active"
                          :size-dependencies="[item.name]"
                          :data-index="index"
                        >
                          <div
                            :class="{ 'hl-select-option': item.name === specimen }"
                            class="select-option d-flex justify-content-between"
                            @click="clickSpecimen(item.name)"
                          >
                            <div>{{ item.name }}</div>
                            <b-img src="@/assets/images/icon_right_expand.svg" />
                          </div>
                        </DynamicScrollerItem>
                      </template>
                    </DynamicScroller>
                  </div>
                  <div class="exam-item-name-block ml-5px">
                    <div style="padding: 20px">
                      <div class="lab-filter-title mb-20px">{{ `${specimen} test items` }}</div>
                      <a-input :placeholder="`Search ${specimen} test items`" v-model="examItemNameKeyword" />
                    </div>
                    <DynamicScroller
                      :items="getExamItemNameCandidate()"
                      :min-item-size="24"
                      class="scroller-exam-item-name"
                      key-field="name"
                    >
                      <template v-slot="{ item, index, active }">
                        <DynamicScrollerItem
                          :item="item"
                          :active="active"
                          :size-dependencies="[item.name]"
                          :data-index="index"
                        >
                          <div
                            v-if="examItemNameList.includes(item.name)"
                            class="d-flex exam-item-name-option"
                            @click="onChangeCheckbox({ target: { value: item.name, checked: false } })"
                          >
                            <b-img src="@/assets/images/icon_select.svg" />
                            <div class="ml-5px">
                              {{ item.name }}
                            </div>
                          </div>
                          <div
                            v-else
                            class="d-flex exam-item-name-option"
                            @click="onChangeCheckbox({ target: { value: item.name, checked: true } })"
                            :class="{ disabled: examItemNameList.length >= 100 }"
                          >
                            <b-img src="@/assets/images/icon_unselect.svg" />
                            <div class="ml-5px">
                              {{ item.name }}
                            </div>
                          </div>
                        </DynamicScrollerItem>
                      </template>
                    </DynamicScroller>
                  </div>
                </div>
              </div>
              <div
                class="d-flex align-items-center null-select-lab justify-content-between mt-15px"
                @click.stop="clickDropdown(true)"
                v-if="examItemNameList.length === 0"
                :style="{
                  border: !examItemNameVisible ? (labError ? '1px solid #d93643' : 'none') : '1px solid #0074E6',
                  background: !examItemNameVisible ? '#f2f2f2' : '#FFFFFF',
                }"
              >
                <div>Select lab test items</div>
                <b-img src="@/assets/images/icon_export_lab_dropdown.svg" />
              </div>
              <div
                class="selected-exam-item-name-block d-flex flex-wrap mt-15px"
                v-else
                :style="{ border: !examItemNameVisible ? '1px solid #919191' : '1px solid #0074E6' }"
                :class="{ disabled: getLabItemDisable }"
                @click.stop="clickDropdown(true)"
              >
                <b-img src="@/assets/images/icon_clear.svg" class="icon-clear" @click.stop="clearLabItem" />
                <div
                  v-for="(examItemName, indexExamItemName) in examItemNameList"
                  :key="`${examItemName}-${indexExamItemName}`"
                  class="d-flex align-items-center selected-exam-item-name"
                >
                  <div class="exam-item-name-text">{{ examItemName }}</div>
                  <div
                    @click.stop="clickDeleteExamItemName({ target: { value: examItemName, checked: false } })"
                    class="delete-icon ml-5px"
                  >
                    x
                  </div>
                </div>
              </div>
              <div class="error-select-text mt-10px" v-if="labError">Please select at least 1 lab test item</div>
              <div class="selected-text mt-10px">{{ `${examItemNameList.length}/100 selected` }}</div>
            </div>
          </template>
        </div>
      </a-checkbox-group>
      <div class="d-flex justify-content-end mt-30px mb-20px">
        <div class="cancel-button mr-10px" @click="closeModal">Cancel</div>
        <div class="export-button" @click="doExportAndCloseModal">Export</div>
      </div>
    </a-modal>
    <b-modal
      :visible="exportLimitModalVisible"
      centered
      no-close-on-backdrop
      no-close-on-esc
      hide-header
      hide-footer
      hide-header-close
      content-class="delete-content-modal"
      body-class="delete-body-modal"
    >
      <b-row no-gutters>
        <b-col>
          <b-row no-gutters>
            <b-col class="title-text" style="text-align: center">Export Limit Exceeded</b-col>
          </b-row>
          <b-row no-gutters style="margin-top: 20px">
            <b-col class="name-delete-text">
              You can export up to 100,000 inpatient and outpatient records each.
            </b-col>
          </b-row>
          <b-row no-gutters style="margin-top: 20px">
            <b-col
              class="d-flex justify-content-between align-items-center"
              :class="{
                'warning-export-text': tab.counters.IPD.Record > EXPORT_RECORDS_NUM,
                'normal-export-text': tab.counters.IPD.Record <= EXPORT_RECORDS_NUM,
              }"
            >
              <div>Current inpatient record number:</div>
              <div>{{ formatedTemplateNumber(tab.counters.IPD.Record) }}</div>
            </b-col>
          </b-row>
          <b-row no-gutters style="margin-top: 4px">
            <b-col
              class="d-flex justify-content-between align-items-center"
              :class="{
                'warning-export-text': tab.counters.OPD.Record > EXPORT_RECORDS_NUM,
                'normal-export-text': tab.counters.OPD.Record <= EXPORT_RECORDS_NUM,
              }"
            >
              <div>Current outpatient record number:</div>
              <div>{{ formatedTemplateNumber(tab.counters.OPD.Record) }}</div>
            </b-col>
          </b-row>
          <b-row no-gutters>
            <b-col class="d-flex justify-content-between align-items-center mt-40px">
              <b-button variant="outline-primary" class="button-close mr-10px flex-fill" @click="closeExportLimitModal"
                >Modify Criteria</b-button
              >
              <b-button variant="outline-primary" class="button-cancel ml-10px flex-fill" @click="showModal"
                >Export Anyway</b-button
              >
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { AdvanceTabMixin, ExportMixin, PatientNumberMixin } from '@/mixins';
import ClickOutside from 'vue-click-outside';
import { mapState, mapMutations } from '../../store';
import { cloneDeep, difference, uniq } from 'lodash';
import { DateUnit } from '@/utils/conditions/core/Interface';
import { DOWNLOAD, MAX_EXPORT_LIMIT } from '@/utils/constants';
import { ConditionName } from '@/utils/conditions/core';
import { mapSubUiConfig, getUiConfig } from '@/utils/uiConfig';
import AdvanceESQuery from '@/utils/query/core/advance/AdvanceESQuery';
import getEventPatientListAggsQuery from '@/utils/query/getEventPatientListAggsQuery';
import { defaultInclusionEvent } from '@/utils/advance/initState';
import DEPRESSION from '@/utils/exportConfig/conditions/depression.json';
import DIABETES from '@/utils/exportConfig/conditions/diabetes.json';
import HYPERTENSION from '@/utils/exportConfig/conditions/hypertension.json';
import HYPERLIPIDAEMIA from '@/utils/exportConfig/conditions/hyperlipidaemia.json';
import VENTRICULAR_FIBRILLATION from '@/utils/exportConfig/conditions/ventricular_fibrillation.json';

const EXPORT_RECORDS_NUM = 100000;

export default {
  name: 'ExportModal',
  mixins: [AdvanceTabMixin, ExportMixin, PatientNumberMixin],
  data() {
    return {
      DOWNLOAD,
      comorbidities: {
        DIABETES,
        HYPERTENSION,
        HYPERLIPIDAEMIA,
        DEPRESSION,
        VENTRICULAR_FIBRILLATION,
      },
      examItemNameVisible: false,
      specimen: '',
      examItemNameList: [],
      specimenListCandidate: [],
      examItemNameListCandidate: [],
      specimenKeyword: '',
      examItemNameKeyword: '',
      exportLimitHint: false,
      MAX_EXPORT_LIMIT,
      labError: false,
      emptySelectError: false,
      exportLimitModalVisible: false,
      EXPORT_RECORDS_NUM,
    };
  },
  directives: {
    ClickOutside,
  },
  props: {
    uniqueIndex: {
      type: Number,
      default: 0,
    },
    exportOptions: {
      type: Array,
      default: () => [],
    },
    isAdvance: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.specimen = '';
    this.examItemNameList = this.storeExamItemNameList;
    this.specimenListCandidate = [];
    this.examItemNameListCandidate = [];

    const LabName = getUiConfig().LabConfig;
    if (LabName) {
      let specimenId = 1;
      let examItemNameId = 1;

      Object.keys(LabName).forEach((item) => {
        const descendantKeys = LabName[item].map((element) => element.label);
        this.specimenListCandidate.push({
          name: item,
          descendantList: LabName[item],
          descendantKeys,
          id: specimenId,
        });
        specimenId += 1;

        LabName[item].forEach((item2) => {
          this.examItemNameListCandidate.push({
            name: item2.label,
            ...item2,
            id: examItemNameId,
          });
          examItemNameId += 1;
        });
      });

      this.specimenListCandidate.push({
        name: 'All',
        id: specimenId,
      });

      this.specimen = 'All';
      this.sortLabList('specimen');
      this.sortLabList('examItemName');
    }
  },
  watch: {
    checkedOptions() {
      if (this.checkedOptions.length !== 0) {
        this.emptySelectError = false;
      }
      if (!this.checkedOptions.includes(DOWNLOAD.LABS)) {
        this.labError = false;
      }
    },
  },
  computed: {
    ...mapState('condition', ['patient', 'searchByPatient']),
    ...mapState(['tabName', 'medicalOrderCategory']),
    ...mapState('session', ['storeExamItemNameList']),
    ...mapSubUiConfig('Analyze.StatisticSummary'),
    query() {
      return this.getQuery({
        includes: this.tab.includes,
        excludes: this.tab.excludes,
      });
    },
    oderQuery() {
      const templateNested = {
        nested: {
          path: 'MEDICATION_INFO_LIST',
          inner_hits: {
            name: 'medication_info',
            size: 100,
          },
        },
      };

      const medicationQuery = this.getQuery({
        includes: this.tab.includes.filter((item) => item.name === ConditionName.Medication),
        excludes: [],
      });

      const nestedMedicationQuery = JSON.parse(
        JSON.stringify(medicationQuery).replace(/"MEDICATION_NEW_CODE":/g, '"MEDICATION_INFO_LIST.ORDER_ID":')
      );
      const mergeQuery = cloneDeep(this.query);
      templateNested.nested.query = nestedMedicationQuery.query;
      mergeQuery.query.bool.filter.push(templateNested);
      return mergeQuery;
    },
    getMedicationCondition() {
      const medicationCondition = this.tab.includes.filter((item) => item.name === ConditionName.Medication);
      let result = [];
      medicationCondition.forEach((item) => {
        result = result.concat(item.list);
      });

      return result;
    },
    labList() {
      const result = [];
      this.examItemNameList.forEach((item) => {
        const examItemName = this.examItemNameListCandidate.find((element) => element.label === item);
        result.push({
          specimen: examItemName.parent,
          chhead: examItemName.value,
        });
      });
      return result;
    },
    getLabItemDisable() {
      return this.examItemNameList.length !== 0 && !this.checkedOptions.includes(DOWNLOAD.LABS);
    },
    exportRecordsLimit() {
      return this.tab.counters.OPD.Record > EXPORT_RECORDS_NUM || this.tab.counters.IPD.Record > EXPORT_RECORDS_NUM;
    },
    hasMedication() {
      return this.tab.includes.some((item) => item.name === ConditionName.Medication);
    },
  },
  methods: {
    ...mapMutations('session', ['setStoreExamItemNameList']),
    getTemplateContent(exportType) {
      return {
        comp: 'ALL',
        searchMode: this.searchByPatient ? 'Patient' : 'Record',
        exportType,
      };
    },
    buildRequestPayload(exportType) {
      let exportContent = null;
      if (exportType === DOWNLOAD.EVENT_PATIENT_LIST) {
        exportContent = {
          queries: this.eventPatientListQueries(this.currentSearchStateFP),
          exportType,
        };
      } else if (exportType === DOWNLOAD.ORDERS) {
        exportContent = {
          ...this.getTemplateContent(exportType),
          finalQuery: this.oderQuery,
          medicationList: this.getMedicationCondition,
        };
      } else if (exportType === DOWNLOAD.LABS) {
        exportContent = {
          ...this.getTemplateContent(exportType),
          finalQuery: this.query,
          labList: this.labList,
        };
      } else {
        exportContent = {
          ...this.getTemplateContent(exportType),
          finalQuery: this.query,
        };
      }

      return {
        ...this.exportTypeInfo[exportType],
        exportContent: {
          ...exportContent,
          analysisName: this.tab.name,
        },
      };
    },
    eventPatientListQueries(advState) {
      const includes = ['TIME_KEY', 'CHART_NO', 'SEX', 'AGE', 'TIME_DIFF'];
      const sortField = ['TIME_KEY'];
      const advESQuery = new AdvanceESQuery({ SearchStateObject: advState }).getQuery();
      const patientListQuery = getEventPatientListAggsQuery(advESQuery, includes, {
        result_size: 10000,
        sortField,
      });
      const comorbidityQueries = [];
      for (const key in this.comorbidities) {
        const comQuery = this.comorbidityQuery(advState, this.comorbidities[key]);
        const aggsQuery = getEventPatientListAggsQuery(comQuery, includes, {
          result_size: 10000,
          sortField,
        });
        comorbidityQueries.push({
          name: key,
          query: aggsQuery,
        });
      }
      return {
        patientList: patientListQuery,
        comorbidities: comorbidityQueries,
      };
    },
    comorbidityQuery(advState, condition) {
      // prepare comorbidity event
      const inclusionEvent = cloneDeep(defaultInclusionEvent);
      inclusionEvent.condition = [condition];
      inclusionEvent.relation = {
        beforeValue: '0',
        beforeUnit: DateUnit.DAYS,
        afterValue: '180',
        afterUnit: DateUnit.DAYS,
      };
      // merge comorbidity into index event
      // to query the cohort with the comorbidity
      const state = cloneDeep(advState);
      state.indexEvent.inclusionList.push({
        ...inclusionEvent,
      });
      return new AdvanceESQuery({ SearchStateObject: state }).getQuery();
    },
    doExportAndCloseModal() {
      if (this.checkedOptions.length === 0) {
        this.emptySelectError = true;
      } else if (this.examItemNameList.length === 0 && this.checkedOptions.includes(DOWNLOAD.LABS)) {
        this.labError = true;
      } else {
        this.emptySelectError = false;
        this.exportLimitModalVisible = false;
        this.handleExport(this.$api, this.buildRequestPayload);
      }
    },
    clickDropdown(state) {
      this.examItemNameVisible = state;
    },
    closeDropdown() {
      this.clickDropdown(false);
    },
    getCandidate(name) {
      return this[`${name}ListCandidate`].filter((item) =>
        item.name.toLowerCase().includes(this[`${name}Keyword`].toLowerCase())
      );
    },
    getExamItemNameCandidate() {
      if (this.specimen === 'All') {
        return this.getCandidate('examItemName');
      }
      return this.getCandidate('examItemName').filter((item) => item.parent === this.specimen);
    },
    sortLabList(name) {
      this[`${name}ListCandidate`] = this[`${name}ListCandidate`].sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      if (name === 'specimen') {
        this[`${name}ListCandidate`] = [
          this[`${name}ListCandidate`].find((item) => item.name === 'All'),
          ...this[`${name}ListCandidate`].filter((item) => item.name !== 'All'),
        ];
      }
    },
    examItemNameChange(data) {
      const result = this.examItemNameListCandidate.find((item) => item.name === data.value);
      if (result) {
        if (!data.checked) {
          this.examItemNameList = difference(this.examItemNameList, [result.label]);
        } else {
          this.examItemNameList = uniq(this.examItemNameList.concat([result.label]));
        }
      }
      this.setStoreExamItemNameList(this.examItemNameList);
    },
    clickSpecimen(name) {
      this.specimen = name;
    },
    onChangeCheckbox(event) {
      if (event.target.checked && !this.checkedOptions.includes(DOWNLOAD.LABS)) {
        this.checkedOptions.push(DOWNLOAD.LABS);
      }
      if (event.target.checked) {
        this.labError = false;
      }
      this.examItemNameChange({
        value: event.target.value,
        checked: event.target.checked,
      });
    },
    clickDeleteExamItemName(event) {
      this.onChangeCheckbox(event);
    },
    clearLabItem() {
      this.examItemNameList = [];
      this.setStoreExamItemNameList(this.examItemNameList);
    },
    closeExportLimitHint() {
      this.exportLimitHint = false;
    },
    closeModal() {
      this.visible = false;
    },
    closeExportLimitModal() {
      this.exportLimitModalVisible = false;
    },
  },
};
</script>

<style scoped>
#exportModal {
  width: 660px;
  height: 310px;
}

#exportModal >>> .ant-modal-body {
  overflow: auto;
  max-height: 800px;
  padding: 0px 30px;
}

#exportModal >>> .ant-modal-footer {
  border-top: 0px;
  padding: 30px 30px 20px 30px;
}

#exportModal >>> .ant-btn {
  width: 96px;
  height: 36px;
  font-size: 14px;
  color: #334e97;
  border-color: #334e97;
}

#exportModal >>> .ant-btn-primary {
  color: #ffffff;
  background-color: #334e97;
}

.button-export {
  border-radius: 6px;
  border: solid 1px #333f6b;
  background-color: white;
  outline: 0;
  padding: 8px 16px;
  cursor: pointer;
}

.enable-button {
  background-color: #eaf2fd;
  cursor: pointer;
}

.dropdown-text {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333f6b;
}
</style>

<style lang="scss" scoped>
/deep/ .ant-modal-header {
  border-bottom: 0px !important;
  padding: 20px 30px 0px 30px !important;
}

.export-modal {
  min-width: 500px;
}

.title {
  font-size: 24px;
  font-weight: bold;
  color: #333f6b;
  margin: 0px 0px 0px 0px;
  height: 30px;
}

.ant-modal {
  transition: width 1s;
  width: fit-content;
}

.ps-container > .ps-scrollbar-y-rail {
  opacity: 0.6;
}

/deep/ .export-checkbox-group {
  .export-item {
    font-size: 16px;
    color: #212121;

    .ant-checkbox-wrapper {
      display: block;
      font-size: 16px;
      color: #212121;
    }

    .ant-checkbox {
      border-color: #334e97;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #334e97;
      border-color: #334e97;
    }
  }
}

.dropdown-lab-menu {
  position: absolute;
  border-radius: 6px;
  border: solid 1px #d6d9e1;
  background-color: #ffffff;
  z-index: 1;
  max-width: 700px;
  width: 700px;
  min-width: 700px;
  margin-top: -432px;
  margin-left: -124px;
}

.scroller-exam-item-name {
  overflow-y: auto;
  max-height: 300px;
  height: 300px;
  min-height: 300px;
  max-width: 490px;
  width: 490px;
  min-width: 490px;
}

.scroller-specimen {
  overflow-y: auto;
  max-height: 300px;
  height: 300px;
  min-height: 300px;
  max-width: 190px;
  width: 190px;
  min-width: 190px;
}

.exam-item-name-block {
  max-width: 490px;
  width: 490px;
  min-width: 490px;
  border-left: 1px solid #e0e0e0;
}

.specimen-block {
  max-width: 190px;
  width: 190px;
  min-width: 190px;
}

.select-option {
  cursor: pointer;
  padding: 12px;
  font-size: 16px;
  line-height: 19px;
  color: #262626;
}

.selected-exam-item-name-block {
  padding: 5px 35px 5px 5px;
  min-height: 34px;
  box-sizing: border-box;
  border-radius: 8px;
  width: 460px;
  cursor: pointer;
  max-height: 200px;
  overflow-y: auto;
}

.delete-icon {
  cursor: pointer;
  color: #f5ba42;
  margin-top: -4px;
}

.exam-item-name-text {
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
}

.selected-exam-item-name {
  border-radius: 20px;
  margin: 2px 5px;
  background-color: #333f6b;
  padding: 2px 10px;
  width: fit-content;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.null-select-lab {
  padding: 8px 16px;
  height: 36px;
  border-radius: 8px;
  font-size: 16px;
  line-height: 19px;
  color: #212121;
  cursor: pointer;
  width: 460px;
}

.selected-text {
  color: #919191;
  font-size: 14px;
  line-height: 17px;
}

.icon-clear {
  position: absolute;
  right: 50px;
  margin-top: 3px;
}

.lab-filter-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #262626;
}

.hl-select-option {
  background: #e6f1ff;
}

.exam-item-name-option {
  cursor: pointer;
  padding: 10px 30px;
  font-size: 16px;
  line-height: 19px;
  color: #262626;
}

.export-limit-error-menu {
  padding: 20px;
  position: absolute;
  width: 350px;
  background: #4d8edc;
  border-radius: 5px;
  margin-left: -238px;
  margin-top: 12px;
}

.export-limit-error-menu::before {
  content: '';
  position: absolute;
  display: block;
  width: 0px;
  left: 308px;
  top: 5px;
  border: 7px solid transparent;
  border-top: 0;
  border-bottom: 7px solid #4d8edc;
  -webkit-transform: translate(-50%, calc(-100% - 5px));
  -ms-transform: translate(-50%, calc(-100% - 5px));
  transform: translate(-50%, calc(-100% - 5px));
}

.export-limit-error-text {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}

.export-limit-error-content-text {
  font-size: 14px;
  line-height: 150%;
  color: #ffffff;
}

.sub-title-text {
  font-size: 16px;
  line-height: 150%;
  color: #212121;
}

.error-select-text {
  font-size: 14px;
  line-height: 17px;
  color: #d93643;
}

.cancel-button {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #334e97;
  border: 1px solid #334e97;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 8px 25px;
  cursor: pointer;
}

.export-button {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  background: #334e97;
  border-radius: 4px;
  padding: 8px 25px;
  cursor: pointer;
}

/deep/ .delete-content-modal {
  width: 475px;
  border-radius: 6px;
  background-color: #ffffff;
  border: 1px solid white;
}

/deep/ .delete-body-modal {
  padding: 40px;
}

.title-text {
  height: 29px;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-align: left;
  color: #333f6b;
}

.name-delete-text {
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #212121;
}

.button-close,
.button-close:hover,
.button-close:focus,
.button-close:active,
.button-close:active:visited {
  width: 187px;
  border-radius: 6px;
  border: solid 1px #333f6b !important;
  font-size: 16px;
  line-height: 20px;
  padding: 16px 0px;
  text-align: center;
  color: #333f6b !important;
  background-color: #ffffff !important;
}

.button-cancel,
.button-cancel:hover,
.button-cancel:focus,
.button-cancel:active,
.button-cancel:active:visited {
  width: 187px;
  border-radius: 6px;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #ffffff !important;
  border: solid 1px #da6d6d !important;
  background-color: #da6d6d !important;
  padding: 16px 0px;
}

.warning-export-text {
  font-size: 16px;
  line-height: 22px;
  color: #d93643;
}

.normal-export-text {
  font-size: 16px;
  line-height: 22px;
  color: #212121;
}

.warning-select-text {
  font-size: 16px;
  line-height: 19px;
  color: #e8891c;
}
</style>
