export enum ChartKey {
  AbnormalLab = 'AbnormalLab',
  Age = 'Age',
  AdvAge = 'AdvAge',
  ICD10 = 'ICD10',
  AdvICD10 = 'AdvICD10',
  ICD9 = 'ICD9',
  AdvICD9 = 'AdvICD9',
  Gender = 'Gender',
  AdvGender = 'AdvGender',
  LabInsight = 'LabInsight',
  Medication = 'Medication',
  ATCCode = 'ATCCode',
  AdvMedication = 'AdvMedication',
  Procedure = 'Procedure',
  PCSCode = 'PCSCode',
  Risk = 'Risk',
  Section = 'Section',
  Stay = 'Stay',
  Symptom = 'Symptom',
  Visit = 'Visit',
  MedicalOrder = 'MedicalOrder',
  Diagnosis = 'Diagnosis',
  AdvSurvival = 'AdvSurvival',
  DoctorId = 'DoctorId',
  Trend = 'Trend',
  AdvTrend = 'AdvTrend',
  DRGCode = 'DRGCode',
  DischargeStatus = 'DischargeStatus',
  PatientSource = 'PatientSource',
  BMI = 'BMI',
  Readmission = 'Readmission',
  ReOPDE = 'ReOPDE',
  IndicatorPie = 'IndicatorPie',
  IndicatorTrend = 'IndicatorTrend',
  Habit = 'Habit',
}

const Chart = {
  [ChartKey.AbnormalLab]: {
    Title: 'Abnormal Lab',
    Hint: '符合條件的病患人數',
  },

  [ChartKey.Age]: {
    Title: 'Age',
    Subtext: 'Record',
    Hint: '統計符合條件的病歷，其住院的年紀',
  },

  [ChartKey.AdvAge]: {
    Title: 'Age',
    Subtext: 'Age of patients in the search conditions grouped by 10 years',
    Hint: '統計符合條件的病歷，其住院的年紀',
  },

  [ChartKey.Gender]: {
    Title: 'Gender',
    Hint: '只要搜到的任一筆紀錄符合此項目，就會納入統計人數',
  },

  [ChartKey.AdvGender]: {
    Title: 'Gender',
    Subtext: 'Proportion of male and female patients in the search conditions',
    Hint: '只要搜到的任一筆紀錄符合此項目，就會納入統計人數',
  },

  [ChartKey.ICD10]: {
    Title: 'ICD-10 CM',
    Hint: '符合條件的紀錄中，前 5 碼出現此疾病編碼的病患人數',
  },

  [ChartKey.AdvICD10]: {
    Title: 'ICD-10 CM',
    Subtext: 'Among patients in the search conditions, number of those who have been diagnosed with given diseases',
    Hint: '符合條件的紀錄中，前 5 碼出現此疾病編碼的病患人數',
  },

  [ChartKey.ICD9]: {
    Title: 'ICD-9',
    Hint: '統計符合條件的病歷，其住院的年紀',
  },

  [ChartKey.AdvICD9]: {
    Title: 'ICD-9',
    Subtext: 'Among patients in the search conditions, number of those who have been diagnosed with given diseases',
    Hint: '統計符合條件的病歷，其住院的年紀',
  },

  [ChartKey.Diagnosis]: {
    Title: 'Diagnosis',
    Subtext: '以病患為單位',
    Hint: '統計符合條件的病歷，其住院的年紀',
  },

  [ChartKey.LabInsight]: {
    Title: 'Lab Insight',
    Subtext: '以病患為單位',
    Hint: 'Lab Insight',
  },

  [ChartKey.Medication]: {
    Title: 'Medication',
    Hint: '符合條件的紀錄中，有開立此藥品的病患人數',
  },

  [ChartKey.ATCCode]: {
    Title: 'ATC Code',
    Hint: '符合條件的紀錄中，有開立此主成分的病患人數',
  },

  [ChartKey.AdvMedication]: {
    Title: 'Medication',
    Subtext: 'Among patients in the search conditions, number of patients who have taken given medications',
    Hint: '符合條件的紀錄中，有開立此藥品的病患人數',
  },

  [ChartKey.Procedure]: {
    Title: 'Procedure',
    Subtext: '以病患為單位',
    Hint: '符合條件的紀錄中，有開立此處置的病患人數',
  },

  [ChartKey.PCSCode]: {
    Title: 'ICD-10 PCS',
    Hint: '符合條件的紀錄中，有開立此處置的病患人數',
  },

  [ChartKey.Risk]: {
    Title: 'Risk Level',
    Subtext: 'Record',
    Hint: '統計符合條件的病歷，14日非計畫性再入院風險指數（透過 AICS AI 模型預測）',
  },

  [ChartKey.Section]: {
    Title: 'Section',
    Hint: '符合條件的紀錄中，有此科別的病患人數',
  },

  [ChartKey.Stay]: {
    Title: 'Length of Stay',
    Subtext: 'Record',
    Hint: '統計符合條件的病歷，其住院的時間長度',
  },

  [ChartKey.Symptom]: {
    Title: 'Symptom',
    Hint: '符合條件的住院紀錄中，有此症狀的病患人數（透過AICS AI 自然語言技術）',
  },

  [ChartKey.Visit]: {
    Title: 'Visit',
    Hint: '統計符合條件的病歷，其就診方式',
  },

  [ChartKey.MedicalOrder]: {
    Title: 'Medical Order',
    Hint: '符合條件的紀錄中，有開立此處置的病患人數',
  },

  [ChartKey.AdvSurvival]: {
    Title: '5 Year Survival Rate',
    Subtext: 'Percentage of living patients in the search conditions defined by Kaplan-Meier Method',
    Hint: '五年存活率',
  },

  [ChartKey.DoctorId]: {
    Title: 'Physician ID',
    Hint: '符合條件的紀錄中，由該醫師診治的病患人數',
  },

  [ChartKey.Trend]: {
    Title: 'Trend',
    Subtext: 'Record',
    Hint: 'TBD',
  },

  [ChartKey.AdvTrend]: {
    Title: 'Trend',
    Subtext: 'TBD',
    Hint: 'TBD',
  },

  [ChartKey.DRGCode]: {
    Title: 'DRG',
    Hint: 'TBD',
  },

  [ChartKey.DischargeStatus]: {
    Title: 'Discharge Status',
    Hint: 'TBD',
  },

  [ChartKey.PatientSource]: {
    Title: 'Patient Source',
    Hint: 'TBD',
  },

  [ChartKey.BMI]: {
    Title: 'BMI',
    Hint: 'TBD',
  },

  [ChartKey.Readmission]: {
    Title: 'Readmission Days Summary',
    Hint: '符合健保署再入院標準的病歷比例',
  },

  [ChartKey.ReOPDE]: {
    Title: 'Revisit to ER Days Summary',
    Hint: '重返急診的病歷比例',
  },

  [ChartKey.IndicatorPie]: {
    Title: 'Indicator',
    Subtext: '以病患為單位',
    Hint: 'TBD',
  },

  [ChartKey.IndicatorTrend]: {
    Title: 'Indicator',
    Subtext: '以病患為單位',
    Hint: 'TBD',
  },

  [ChartKey.Habit]: {
    Title: 'Habit',
    Hint: 'TBD',
  },
};

export default Chart;
