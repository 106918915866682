import { CHART } from '../constants';
import { CategoryCondition, ConditionFactory, ConditionName } from './core';

function getSymptomQuery(symptoms: string[]): Record<string, unknown>[] {
  return symptoms.map((value) => ({
    term: {
      SYMPTOM_NAME: value,
    },
  }));
}

export default class SymptomDef extends CategoryCondition {
  // static get TYPE(): string { return ConditionType.CATEGORY; }

  static get NAME(): string {
    return CHART.TITLE.SYMPTOM;
  }

  public getFilter(): Record<string, unknown> {
    return {
      bool: {
        [this.andOr]: getSymptomQuery(this.list),
      },
    };
  }
}

ConditionFactory.register(SymptomDef, ConditionName.Symptom);
