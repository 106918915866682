<template>
    <div class="loading-wrapper">
        <div v-if="!apiHasError" class="loading-modal">
            <b-img class="icon" src="@/assets/images/spinner.svg" />
            <p>讀取中</p>
        </div>
        <div v-else class="report-modal">
            <b-img class="error-img" src="@/assets/images/error.png" />
            <div>
                <p>Oops!</p>
                <span>Please try again. If it persists, please report it.</span>
            </div>
            <a class="contact" :href="`mailto: emr_account@asus.com`">Contact Us to report a problem</a>
            <a-button class="retry" type="primary" @click="getPermission">
                Retry
            </a-button>
        </div>
    </div>
</template>

<script>
import router from '@/router';
import { fetchUiConfig, getUiConfig } from '@/utils/uiConfig';
import { getTimeRangeInYear } from '@/utils/conditions/TimeDef';
import VueCookie from 'vue-cookie';
import { COOKIE } from '@/utils/constants';

export default {
    name: 'Loading',
    data(){
        return {
            apiHasError: false,
            targetUrl: '/',
        }
    },
    methods:{
        async getPermission(){
            this.apiHasError = false;
            try{
                await Promise.all([
                    fetchUiConfig(this.$api),
                    this.updatePermission(),
                ]);
                await this.setSetting();
                this.$store.commit('user/login', { auth: true });  
                await router.replace(this.targetUrl)
            } catch (error){
                this.apiHasError = true;
            }
        },
        async updatePermission() {
            const session = VueCookie.get(COOKIE.SESSION_ID);
            const permission = await this.$api.getPermission(session)
            this.$store.commit('user/setPermission', permission);
        },
        async setSetting() {
            if (getUiConfig().onlyIcd10) {
                this.$store.commit('setConfirmIcdMap', false);
                this.$store.commit('setSettingMenu', false);
            } else {
                this.$store.commit('setSettingMenu', true);
                const userId = VueCookie.get(COOKIE.USER_ID) || VueCookie.get(COOKIE.USER_ID);
                const settings = await this.$api.getSettings(userId).catch((error) => {
                    this.apiHasError = error.response?.status ?? 400
                });

                this.$store.commit('setConfirmIcdMap', settings['search.edit_mapped_icd']);
            }
            // update advanced portal time range
            this.$store.commit('setAdvTimePeriod', getTimeRangeInYear(getUiConfig().SearchForm.time));
        },
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.targetUrl = to.params?.target ?? '/' ;
            vm.getPermission();
        });
    },
};
</script>


<style lang="scss">
.loading-wrapper {
    height: 100vh;
    width: 100vw;
    background: #3333334D;
    display: flex;
    align-items: center;
    justify-content: center;

    .loading-modal {
        width: 236px;
        height: 284px;
        padding: 40px;
        border-radius: 16px;
        background: var(--White, #FFF);
        box-shadow: 0px 4px 16px 0px rgba(66, 66, 66, 0.20), 0px 4px 30px 0px rgba(157, 205, 245, 0.20);

        .icon {
            width: 96px;
            height: 96px;
            margin: 30px;
            animation: spin 2s linear infinite;

            @keyframes spin {
                from {
                    transform: rotate(0deg);
                }
                to {
                    transform: rotate(360deg);
                }
            }
        }
        p {
            margin: 0;
            text-align: center;
            color: var(--Text-high-emphasis-text, #1A1A1A);
            font-family: "Noto Sans TC";
            font-size: 24px;
            font-weight: 700;
        }
    }

    .report-modal {
        width: 289px;
        height: 342px;
        padding: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 12px;
        border-radius: 16px;
        background: var(--White, #FFF);
        box-shadow: 0px 4px 16px 0px rgba(66, 66, 66, 0.20), 0px 4px 30px 0px rgba(157, 205, 245, 0.20);

        .error-img {
            width: 120px;
            height: 120px;
        }
        p {
            margin: 0;
            text-align: center;
            font-size: 24px;
            font-weight: 600;
            color: var(--Grey-900, #212121);
        }

        span {
            display: block;
            text-align: center;
            font-size: 16px;
            font-weight: 400;
            color: var(--Grey-900, #262626);
        }

        .contact{
            color: var(--Blue-3, #4FC2E7);
            font-size: 14px;
            font-weight: 700;
        }

        .retry {
            width: 100%;
            height: 40px;
            padding: 12px 24px;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid var(--Status-primary, #0074E6);
            background-color: var(--Status-primary, #0074E6);
            span {
                color: #FFF
            }
        }
    }
}
</style>