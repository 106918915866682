export default {
  conceptName: 'Anti-TNF Biologics',
  conceptType: 'include',
  name: 'Medication',
  tags: ['Basic', 'Raw'],
  andOr: 'should',
  list: [
    {
      code: 'IADAL04',
      label: 'IADAL04',
      description: 'Humira 40mg/0.4ml/syringe (Adalimumab)',
      chineseDescription: 'Humira 40mg/0.4ml/syringe (Adalimumab)',
      value: 'IADAL04',
      type: 'med',
      patientNum: 116,
      recordNum: 2790,
      field: 'med',
      ingredient: 'adalimumab',
      atcCode: 'L04AB04',
      time: 1646617157024,
    },
    {
      code: 'IETAN25',
      label: 'IETAN25',
      description: 'ENBREL 針筒裝 25mg/0.5ml/vial(Etanercept)',
      chineseDescription: 'ENBREL 針筒裝 25mg/0.5ml/vial(Etanercept)',
      value: 'IETAN25',
      type: 'med',
      patientNum: 213,
      recordNum: 12647,
      field: 'med',
      ingredient: 'etanercept',
      atcCode: 'L04AA11',
      time: 1646617320395,
    },
    {
      code: 'IETAN',
      label: 'IETAN',
      description: 'Enbrel 凍晶型25mg/ml/vial (Etanercept)',
      chineseDescription: 'Enbrel 凍晶型25mg/ml/vial (Etanercept)',
      value: 'IETAN',
      type: 'med',
      patientNum: 69,
      recordNum: 411,
      field: 'med',
      ingredient: 'etanercept',
      atcCode: 'L04AA11',
      time: 1646617320930,
    },
    {
      code: 'IINFL',
      label: 'IINFL',
      description: 'Remicade 100mg/vial (Infliximab)',
      chineseDescription: 'Remicade 100mg/vial (Infliximab)',
      value: 'IINFL',
      type: 'med',
      patientNum: 3,
      recordNum: 20,
      field: 'med',
      ingredient: 'infliximab',
      atcCode: 'L04AB02',
      time: 1646617326077,
    },
    {
      code: 'IGOLIM',
      label: 'IGOLIM',
      description: 'Golimumab (Simponi) 50mg/0.5ml/vial',
      chineseDescription: 'Golimumab (Simponi) 50mg/0.5ml/vial',
      value: 'IGOLIM',
      type: 'med',
      patientNum: 118,
      recordNum: 4453,
      field: 'med',
      ingredient: 'golimumab',
      atcCode: 'L04AB06',
      time: 1646617331137,
    },
  ],
  diagnosisRange: false,
  index: 1,
};
