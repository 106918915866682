<template>
  <div>
    <a-spin id="loading-spin" v-if="loading" />
    <div :class="loading ? 'block' : ''">
      <v-chart
        :options="config"
        :style="{
          width: '800px',
        }"
      />
    </div>
  </div>
</template>

<script>
import { queryMixin } from '@/mixins';
import { findIndex } from 'lodash';
import LabInsightConfig from './LabInsightConfig';
import { mapState } from '../../store';
import ConditionArray from '../../utils/query/core/ConditionArray';

export default {
  name: 'LabHistogramChart',
  mixins: [queryMixin],
  mounted() {
    this.configUpdate();
  },
  props: ['CHSPECI', 'CHHEAD', 'unit', 'uniqueIndex'],
  computed: {
    ...mapState('session', ['tabs']),
    ...mapState('user', ['userId']),
    extendConditions() {
      return new ConditionArray(this.tabs[this.uniqueIndex].includes).additions;
    },
    index() {
      return findIndex(this.extendConditions, {
        exam: this.CHSPECI,
        lab: this.CHHEAD,
        unit: this.unit,
      });
    },
  },
  data() {
    return {
      recordCount: 0,
      loading: false,
      config: {},
    };
  },
  methods: {
    async getData() {
      const newExtendConditions =
        this.index === -1 ? this.extendConditions : this.extendConditions.filter((item, index) => index !== this.index);
      const labInfo = await this.getLabHistogramInfo(this.CHSPECI, this.CHHEAD, this.unit, newExtendConditions).catch(
        (error) => {
          console.error(error);
        }
      );
      this.recordCount = labInfo.count;
      return this.getLabHistogramData({
        CHSPECI: this.CHSPECI,
        CHHEAD: this.CHHEAD,
        UNIT: this.unit,
        newExtendConditions,
        labInfo,
      });
    },
    async configUpdate() {
      this.loading = true;
      // eslint-disable-next-line no-unused-vars
      const { seriesData, highBound, lowBound, digit, seriesDataWithRange } = await this.getData().catch((error) => {
        console.error(error);
      });

      const realSeriesData = seriesData.map((item, index) => {
        let key = '';
        const noRecord = seriesDataWithRange[index].doc_count === 0;
        if (seriesData.length === 1) {
          key = item.from;
        } else if (index === 0) {
          key = `${item.to}-`;
        } else if (index === seriesData.length - 1 && index !== 0) {
          key = `${item.from}+`;
        } else {
          key = item.from;
        }
        return {
          ...item,
          key,
          noRecord,
        };
      });
      this.loading = false;
      const newExtendConditions = this.index !== -1 ? [this.extendConditions[this.index]] : [];
      this.config = await new LabInsightConfig().applyLabData(
        realSeriesData,
        newExtendConditions,
        this.recordCount,
        highBound,
        lowBound,
        digit
      );
      return this.config;
    },
  },
};
</script>

<style scoped>
#loading-spin {
  top: 50%;
  position: fixed;
  left: 50%;
}
</style>
