/* eslint-disable global-require */
module.exports = {
  DEMO_VIDEO_CONFIG: [
    {
      TITLE: '簡介',
      START: 0,
      END: 22,
      TIME: '0:00',
    },
    {
      TITLE: '研究設計的條件設定',
      START: 22,
      END: 85,
      TIME: '0:22',
    },
    {
      TITLE: '統計與數據分析',
      START: 85,
      END: 171,
      TIME: '1:25',
    },
  ],
  DEMO_VIDEO_CONFIG2: [
    {
      TITLE: '簡介與搜尋條件設定',
      START: 0,
      END: 38,
      TIME: '0:00',
    },
    {
      TITLE: '人群統計分析',
      START: 38,
      END: 113,
      TIME: '0:38',
    },
    {
      TITLE: '住院與門診文字記錄',
      START: 113,
      END: 136,
      TIME: '1:53',
    },
    {
      TITLE: '檢驗值統計',
      START: 136,
      END: 162,
      TIME: '2:16',
    },
  ],
};
