import numeral from 'numeral';
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('condition', [
      'inPatient',
      'inRecord',
      'risk',
      'outPatient',
      'outRecord',
      'totalPatient',
      'totalRecord',
    ]),
    formatedPatient() {
      return numeral(this.inPatient).format('0,0');
    },
    formatedRecord() {
      return numeral(this.inRecord).format('0,0');
    },
    formatedRisk() {
      return `${numeral(this.risk).format('0,0.[0]')}/7`;
    },
    formatedOutPatient() {
      return numeral(this.outPatient).format('0,0');
    },
    formatedOutRecord() {
      return numeral(this.outRecord).format('0,0');
    },
    formatedTotalPatient() {
      return numeral(this.totalPatient).format('0,0');
    },
    formatedTotalRecord() {
      return numeral(this.totalRecord).format('0,0');
    },
  },
  methods: {
    formatedTemplateNumber(number) {
      return numeral(number).format('0,0');
    },
    formatedTemplateRisk(risk) {
      return `${numeral(risk).format('0,0.[0]')}/7`;
    },
  },
};
