<template>
  <div class="ready-dataset">
    <div class="top-title-text">History</div>
    <b-row no-gutters>
      <b-col>
        <b-row no-gutters v-if="savedList.length > 0 || searchNameText">
          <b-col>
            <b-row no-gutters>
              <b-col class="d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center">
                  <b-input placeholder="Search" v-model="searchNameText" class="name-search"> </b-input>
                  <b-img src="@/assets/images/icon_search_blue.svg" class="icon-input-search" />
                </div>
              </b-col>
            </b-row>
            <b-row no-gutters class="title-row mt-20px">
              <b-col class="d-flex align-items-center">
                <div class="title-name">Name</div>
              </b-col>
              <b-col class="d-flex align-items-center justify-content-end">
                <div class="title-date">Modified Date</div>
              </b-col>
            </b-row>
            <b-row no-gutters>
              <b-col>
                <b-row
                  no-gutters
                  v-for="(item, index) in savedList"
                  :key="`${index}-savedList`"
                  class="data-row"
                  @click="restoreAnalysis(item)"
                >
                  <b-col style="overflow: hidden" class="d-flex justify-content-center">
                    <div class="left-div" style="padding-right: 20px">
                      <b-row no-gutters>
                        <b-col>
                          <div class="cohort-name-text">{{ item.name }}</div>
                        </b-col>
                      </b-row>
                      <b-row no-gutters>
                        <b-col class="d-flex align-items-center mt-5px" v-if="item.searchState">
                          <a-tooltip placement="bottomLeft" overlayClassName="cohort-history-analysis-tooltip">
                            <template slot="title">
                              <div class="sub-title-text">Experimental Group</div>
                              <div class="sub-content-text mt-5px">{{ item.searchState.experimental.name }}</div>
                              <div class="sub-title-text mt-20px">Control Group</div>
                              <div class="sub-content-text mt-5px">{{ item.searchState.control.name }}</div>
                              <div class="sub-title-text mt-20px">Covariates</div>
                              <div class="sub-content-text mt-5px" style="margin-left: -5px">
                                {{ getCovariatesText(item.searchState.covariate) }}
                              </div>
                              <div class="sub-title-text mt-20px">Outcome</div>
                              <div class="sub-content-text mt-5px" style="margin-left: -5px">
                                {{ item.searchState.outcome.inclusionList[0].name }}
                              </div>
                            </template>
                            <div class="display-block">
                              <div class="sub-title-text d-inline">Experimental Group</div>
                              <div class="ml-5px sub-content-text d-inline">
                                {{ item.searchState.experimental.name }}
                              </div>
                              <div class="ml-20px sub-title-text d-inline">Control Group</div>
                              <div class="ml-5px sub-content-text d-inline">{{ item.searchState.control.name }}</div>
                              <div class="ml-20px sub-title-text d-inline">Covariates</div>
                              <div class="ml-5px sub-content-text d-inline">
                                {{ getCovariatesText(item.searchState.covariate) }}
                              </div>
                              <div class="ml-20px sub-title-text d-inline">Outcome</div>
                              <div class="ml-5px sub-content-text d-inline">
                                {{ item.searchState.outcome.inclusionList[0].name }}
                              </div>
                            </div>
                          </a-tooltip>
                        </b-col>
                      </b-row>
                    </div>
                    <div class="right-div d-flex align-items-center justify-content-end">
                      <div class="sub-content-text" style="width: 90px">{{ item.updateTime }}</div>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row no-gutters>
              <b-col>
                <a-pagination
                  v-show="total > pageSize"
                  class="pagin"
                  size="small"
                  v-model="current"
                  :total="total"
                  showLessItems
                  :itemRender="itemPagerRender"
                  @change="handlePaginationChange"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row no-gutters v-else>
          <b-col>
            <b-row no-gutters>
              <b-col class="d-flex justify-content-center align-items-center">
                <b-img src="@/assets/images/icon_event_no_dataset.svg" style="margin-top: 130px" />
              </b-col>
            </b-row>
            <b-row no-gutters>
              <b-col class="d-flex justify-content-center align-items-center">
                <div class="top-title-text mt-30px">No History</div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import dayjs from 'dayjs';
import { mapState, mapMutations } from '@/store';
import { v4 as uuid } from 'uuid';
import { cloneDeep } from 'lodash';
import { ClickMixin } from '@/mixins';
import { VUE_EVENT_NAME } from '@/utils/constants';

const pageSize = 10;

export default {
  name: 'HistoryAnalysisList',
  mixins: [ClickMixin],
  data() {
    return {
      searchNameText: '',
      savedList: [],
      total: 1000,
      pageSize,
      current: 1,
    };
  },
  mounted() {
    this.setSavedList();

    this.$root.$on(VUE_EVENT_NAME.REFRESH_HISTORY_ANALYSIS_LIST, () => {
      this.setSavedList();
    });
  },
  destroyed() {
    this.$root.$off(VUE_EVENT_NAME.REFRESH_HISTORY_ANALYSIS_LIST);
  },
  computed: {
    ...mapState('user', ['userId']),
    ...mapState('cohortSession', ['tabs']),
  },
  watch: {
    searchNameText() {
      this.setSavedList();
    },
  },
  methods: {
    ...mapMutations('cohortSession', ['addTab', 'setActiveTabIndex']),
    async setSavedList() {
      let result = [];
      if (this.searchNameText === '') {
        result = await this.$api.getCohortStudy({
          sort: {
            updateTime: 'desc',
          },
          topN: pageSize,
          page: this.current - 1,
          purpose: 'history',
        });
      } else {
        result = await this.$api.searchCohortStudy({
          userId: this.userId,
          keyword: this.searchNameText,
          sort: {
            updateTime: 'desc',
          },
          topN: pageSize,
          page: this.current - 1,
          purpose: 'history',
        });
      }

      this.total = Math.min(result.max_limit, result.content.hits.total.value);

      const savedList = [];

      result.content.hits.hits.forEach((item) => {
        savedList.push({
          name: item._source.displayName,
          searchState: item._source.request.searchState,
          updateTime: dayjs.unix(item._source.updateTime).format('YYYY/MM/DD'),
          url: item._source.url,
          data: item._source.data,
        });
      });

      this.savedList = savedList;
      this.$root.$emit(VUE_EVENT_NAME.SET_VUEPERFECTSCROLLBAR_UPDATE);

      if (this.total !== 0 && Math.min(result.max_limit, Math.ceil(this.total / pageSize)) < this.current) {
        this.current = 1;
        this.setSavedList();
      }
    },
    itemPagerRender(current, type, originalElement) {
      if (type === 'prev') {
        return (
          <a>
            <b-icon-caret-left-fill />
          </a>
        );
      }
      if (type === 'next') {
        return (
          <a>
            <b-icon-caret-right-fill />
          </a>
        );
      }
      return originalElement;
    },
    getCovariatesText(data) {
      return data.inclusionList.map((item) => item.name).join(', ');
    },
    handlePaginationChange(pagination) {
      this.current = pagination;
      this.setSavedList();
      this.cohortStudyTrackClick('handlePaginationChange', { pagination });
    },
    getDuplicatedTabIndex(item) {
      return this.tabs.findIndex((tab) => tab.id === item.url);
    },
    restoreAnalysis(data) {
      const index = this.getDuplicatedTabIndex(data);
      if (index !== -1) {
        this.setActiveTabIndex(index);
      } else {
        this.addTab({
          id: '',
          name: data.name,
          updateTime: data.updateTime,
          eventSearch: cloneDeep(data.searchState),
          currentMenu: '',
          experimentalGroupPatient: 0,
          controlGroupPatient: 0,
          data: {
            root: { trackId: '' },
            trackId: uuid(),
          },
          tabType: 'history',
          getLatest: false,
          url: data.url,
          characteristicsTableRequestInProgress: false,
          incidenceRateTableRequestInProgress: false,
          survivalRateChartRequestInProgress: false,
          coxRegressionRequestInProgress: false,
          patientListRequestInProgress: false,
          resultDone: false,
          characteristicsTableProgressPercent: 0,
          incidenceRateTableProgressPercent: 0,
          survivalRateChartProgressPercent: 0,
          coxRegressionProgressPercent: 0,
          patientListProgressPercent: 0,
        });
      }

      this.cohortStudyTrackClick('restoreAnalysis');
    },
  },
};
</script>
<style lang="scss" scoped>
.ready-dataset {
  background: #ffffff;
  min-height: calc(100vh - 116px);
  padding: 20px 0px 0px 0px;
}

.name-search {
  width: 505px;
  height: 36px;
  border-radius: 6px;
  border: solid 1px #d6d9e1;
  background-color: #ffffff;
  margin-top: 20px;
  padding-left: 38px;
}

.icon-input-search {
  position: absolute;
  margin: 20px 0px 0px 10px;
}

.new-cohort-button {
  padding: 8px 16px;
  background: #4d8edc;
  border-radius: 6px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;
}

.title-row {
  padding: 4px 20px;
  border-radius: 6px;
  background-color: #ebecf0;
}

.title-name {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #334e97;
  text-align: left;
}

.title-date {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #334e97;
  text-align: right;
}

.data-row {
  box-shadow: inset 0 -1px 0 0 #d6d9e1;
  padding: 20px;
  cursor: pointer;
}

.data-row:hover {
  background-color: #eaf2fd;
}

.icon-edit {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.cohort-name-text {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #212121;
}

.block-title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #334e97;
}

.sub-title-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #757575;
  white-space: pre;
}

.sub-content-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #212121;
  white-space: pre;
}

.top-title-text {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #334e97;
}

.right-div {
  width: 90px;
  max-width: 90px;
  min-width: 90px;
}

.left-div {
  width: calc(100% - 90px);
  max-width: calc(100% - 90px);
  min-width: calc(100% - 90px);
}
</style>
<style scoped>
.pagin {
  float: None;
  margin: 48px 0px 30px 0px;
  text-align: center;
}

.pagin >>> .ant-pagination-item-active {
  border: 1px solid #ffffff;
  color: #333f6b;
  font-weight: bold;
}

.pagin >>> .ant-pagination-item-active a {
  color: #333f6b;
}

.pagin >>> .ant-pagination-item-container {
  bottom: 2px;
  font-size: 1px;
}

.pagin >>> .ant-pagination-jump-next,
.pagin >>> .ant-pagination-jump-prev {
  pointer-events: none;
}

.display-block {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
<style lang="scss">
.cohort-history-analysis-tooltip {
  width: 400px;
  max-width: 400px !important;
  z-index: 10000 !important;

  .ant-tooltip-arrow {
    display: none;
  }

  .ant-tooltip-inner {
    background: #ffffff;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    border-radius: 4px;
  }
}
</style>
