import Vue from 'vue';
import VueAppInsights from '@asus-aics/aicsapi-vue-app-insights';
import router from '@/router/index';

if (process.env.VUE_APP_INSIGHTS_KEY) {
  Vue.use(VueAppInsights, {
    instrumentationKey: process.env.VUE_APP_INSIGHTS_KEY,
    router,
  });
}
