<template>
  <div @click.stop="">
    <div id="capsule-container">
      <div id="datarange-capsule">
        <div id="datarange-capsule-word">Time Period</div>
        <div @click.stop="openDataRangeSlider" id="datarange-capsule-dropbox">
          <div id="datarange-capsule-dropbox-word">{{ getDateRangeStart }}~{{ getDateRangeEnd }}</div>
          <span>
            <b-img src="@/assets/images/icon_polygon.svg" class="icon-polygon" />
          </span>
        </div>
        <div id="datarange-capsule-delete">
          <b-img
            src="@/assets/images/icon_remove_blue.svg"
            style="cursor: pointer; size: 13px"
            @click.stop="deleteConstraint(ConstraintType.DataRangeConstraint)"
          />
        </div>
      </div>
    </div>
    <div v-if="!hasCondition" id="invalid-warning">Invalid when no condition</div>
    <div v-if="isOpenMenu" v-click-outside="closeDataRangeSlider" id="slide-container">
      <div>
        <span id="slide-datarange-describe">
          <span id="slide-datarange-word">Time Period</span>
          <span id="slide-datarange-range">{{ getDateRangeStart }}~{{ getDateRangeEnd }}</span>
        </span>
        <span>
          <b-img
            id="slide-datarange-remove"
            src="@/assets/images/icon_close.svg"
            style="cursor: pointer; size: 13px"
            @click.stop="closeDataRangeSlider"
          />
        </span>
      </div>
      <div style="width: 310px; margin: auto">
        <a-slider
          :min="min"
          :max="max"
          range
          :defaultValue="getDefaultValue"
          @afterChange="changeTime"
          :getTooltipPopupContainer="(trigger) => trigger.parentNode"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { findIndex } from 'lodash';
import ClickOutside from 'vue-click-outside';
import { EventSearchMixin, ClickMixin, EventUpdateMixin } from '@/mixins';
import ConstraintType from '@/utils/conditions/core/advance/constraint/Interface';
import { EventType } from '@/utils/conditions/core/Interface';
import { getUiConfig } from '@/utils/uiConfig';
import { getTimeRangeInYear } from '@/utils/conditions/TimeDef';

export default {
  name: 'DataRangeConstraint',
  directives: {
    ClickOutside,
  },
  mixins: [EventSearchMixin, EventUpdateMixin, ClickMixin],
  props: {
    index: {
      type: Number,
      default: 1,
    },
    data: {
      type: Object,
    },
    eventType: {
      type: String,
      default: EventType.FIX_EVENT,
    },
    path: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isSliderOpen: true,
      ConstraintType,
      min: getTimeRangeInYear(getUiConfig().SearchForm.time).start,
      max: getTimeRangeInYear(getUiConfig().SearchForm.time).end,
    };
  },
  computed: {
    currentEvent() {
      return this.getEventDataByPath(this.path);
    },
    constrainIndex() {
      return findIndex(this.currentEvent.constraintList, {
        constraintType: ConstraintType.DataRangeConstraint,
      });
    },
    hasCondition() {
      return this.currentEvent.condition.length > 0;
    },
    getDataRangeData() {
      return this.currentEvent.constraintList[this.constrainIndex];
    },
    getDateRangeStart() {
      return this.getDataRangeData.start;
    },
    getDateRangeEnd() {
      return this.getDataRangeData.end;
    },
    getDefaultValue() {
      return [this.getDateRangeStart, this.getDateRangeEnd];
    },
    isOpenMenu() {
      return this.isCurrentMenu(this.path, 'DataRangeConstraint') && this.isSliderOpen;
    },
  },
  methods: {
    changeTime(values) {
      const event = this.getEventDataByPath(this.path);
      const constraintIndex = findIndex(event.constraintList, {
        constraintType: ConstraintType.DataRangeConstraint,
      });
      event.constraintList[constraintIndex].start = values[0];
      event.constraintList[constraintIndex].end = values[1];
      const body = {
        constraintList: event.constraintList,
      };
      this.updateEventWithPath(body, this.path);

      this.cohortStudyTrackClick('changeTime');
    },
    closeDataRangeSlider() {
      this.isSliderOpen = false;
    },
    openDataRangeSlider() {
      this.cohortStudyTrackClick('openDataRangeSlider', {
        path: this.path,
        index: this.index,
      });

      this.isSliderOpen = true;
      this.setCurrentMenu(this.path, 'DataRangeConstraint');
    },
  },
};
</script>

<style scoped>
#capsule-container {
  display: inline-block;
}

#datarange-capsule {
  display: -webkit-inline-box;
  padding: 8px 0px 8px 0px;
  margin-left: 10px;
  background-color: #edf5fb;
  border-radius: 100px;
  width: 254px;
}

#datarange-capsule-word {
  position: relative;
  top: 2px;
  font-weight: normal;
  size: 14px;
  color: #333333;
  margin-left: 10px;
}

#datarange-capsule-dropbox {
  background-color: white;
  border-radius: 20px;
  margin-left: 5px;
  width: 122px;
  padding: 1px 0px;
  cursor: pointer;
  border: 1px solid #e0e0e0;
}

#datarange-capsule-dropbox-word {
  margin-left: 10px;
  font-weight: normal;
  font-size: 16px;
  color: #334e97;
  display: inline-block;
}

#datarange-capsule-delete {
  margin-left: 10px;
}

#slide-container {
  border: #d6d9e1 solid 1px;
  width: 360px;
  height: 92px;
  position: absolute;
  background-color: white;
  z-index: 1;
  border-radius: 5px;
}

#slide-datarange-describe {
  display: inline-block;
  position: relative;
  margin-left: 20px;
  margin-top: 20px;
}

#slide-datarange-word {
  color: #334e97;
}

#slide-datarange-range {
  margin-left: 10px;
}

#slide-datarange-remove {
  position: relative;
  float: right;
  margin-right: 18px;
  margin-top: 18px;
  width: 13px;
  height: 13px;
}

#invalid-warning {
  display: inline-block;
  color: #da6d6d;
  font-size: 14px;
  margin-left: 10px;
}

.icon-polygon {
  width: 10px;
  height: 10px;
  margin-left: 5px;
}
</style>
