<template>
  <div @hover="hoverEvent">
    <b-row no-gutters>
      <b-col>
        <b-row no-gutters>
          <b-col class="title-text">Search Description</b-col>
        </b-row>
        <b-row no-gutters style="margin: 10px 0px 0px 0px">
          <b-col class="statistics-text">{{ `In ${getTimeStart}~${getTimeEnd}` }}</b-col>
        </b-row>
        <b-row no-gutters>
          <b-col>
            <b-row no-gutters v-if="getConditionName(firstEventFP) !== ''">
              <b-col class="event-content d-flex">
                <div v-if="getFirstDescIndex === 0">{{ 'patient\xa0' }}</div>
                <div>{{ `${getConditionName(firstEventFP)}` }}</div>
                <div
                  :id="`first-event${uuid}`"
                  class="event-name"
                  @mouseover="setEventTime(1)"
                  @mouseleave="hoverEvent(1, getEventDesc(firstEventFP))"
                >
                  {{ `\xa0${firstEventFP.name}` }}
                </div>
                <b-tooltip :target="`first-event${uuid}`" triggers="hover">
                  <EventToolTip :event="firstEventFP" />
                </b-tooltip>
                <div v-if="firstEventFP.constraint.firstOccurrence">
                  {{ `\xa0for the first time` }}
                </div>
              </b-col>
            </b-row>
            <b-row no-gutters v-if="getConditionName(indexEventFP) !== ''">
              <b-col class="then-text d-flex">
                <div v-if="getFirstDescIndex !== 1">then</div>
                <div
                  v-if="firstEventFP.relation.beforeValue !== 'any' && getFirstDescIndex !== 1"
                  class="font-weight-bolder"
                >
                  {{ `\xa0${getFirstTimeRelation}` }}
                </div>
              </b-col>
            </b-row>
            <b-row no-gutters v-if="getConditionName(indexEventFP) !== ''">
              <b-col class="event-content d-flex">
                <div v-if="getFirstDescIndex === 1">{{ 'patient\xa0' }}</div>
                <div>{{ `${getConditionName(indexEventFP)}` }}</div>
                <div
                  :id="`second-event${uuid}`"
                  class="event-name"
                  @mouseover="setEventTime(2)"
                  @mouseleave="hoverEvent(2, getEventDesc(indexEventFP))"
                >
                  {{ `\xa0${indexEventFP.name}` }}
                </div>
                <b-tooltip :target="`second-event${uuid}`" triggers="hover">
                  <EventToolTip :event="indexEventFP" />
                </b-tooltip>
                <div v-if="indexEventFP.constraint.firstOccurrence">
                  {{ `\xa0for the first time` }}
                </div>
              </b-col>
            </b-row>
            <b-row no-gutters v-if="getConditionName(thirdEventFP) !== ''">
              <b-col class="then-text d-flex">
                <div v-if="getFirstDescIndex !== 2">then</div>
                <div
                  v-if="thirdEventFP.relation.afterValue !== 'any' && getFirstDescIndex !== 2"
                  class="font-weight-bolder"
                >
                  {{ `\xa0${getThirdTimeRelation}` }}
                </div>
              </b-col>
            </b-row>
            <b-row no-gutters v-if="getConditionName(thirdEventFP) !== ''">
              <b-col class="event-content d-flex">
                <div v-if="getFirstDescIndex === 2">{{ 'patient\xa0' }}</div>
                <div>{{ `${getConditionName(thirdEventFP)}` }}</div>
                <div
                  :id="`third-event${uuid}`"
                  class="event-name"
                  @mouseover="setEventTime(3)"
                  @mouseleave="hoverEvent(3, getEventDesc(thirdEventFP))"
                >
                  {{ `\xa0${thirdEventFP.name}` }}
                </div>
                <b-tooltip :target="`third-event${uuid}`" triggers="focus hover ">
                  <EventToolTip :event="thirdEventFP" />
                </b-tooltip>
                <div v-if="thirdEventFP.constraint.firstOccurrence">
                  {{ `\xa0for the first time` }}
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import EventToolTip from '@/components/advanceSearch/EventToolTip.vue';
import { AdvanceTabMixin, EventMixin } from '@/mixins';
import { getEventDesc } from '@/utils/util';
import { ConditionName } from '@/utils/conditions/core/Interface';
import { v4 as uuid } from 'uuid';

import { mapState } from '../../../store';

export default {
  name: 'PortalSearchDescription',
  components: {
    EventToolTip,
  },
  mixins: [AdvanceTabMixin, EventMixin],
  props: {
    uniqueIndex: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      show: true,
      uuid: '',
    };
  },
  computed: {
    ...mapState('byEventSession', ['tabs']),
    getFirstTimeRelation() {
      return `within ${this.firstEventFP.relation.beforeValue} ${this.firstEventFP.relation.beforeUnit}`;
    },
    getThirdTimeRelation() {
      return `within ${this.thirdEventFP.relation.afterValue} ${this.thirdEventFP.relation.afterUnit}`;
    },
    getFirstDescIndex() {
      if (this.getConditionName(this.firstEventFP) !== '') {
        return 0;
      }
      if (this.getConditionName(this.indexEventFP) !== '') {
        return 1;
      }
      if (this.getConditionName(this.thirdEventFP) !== '') {
        return 2;
      }
      return -1;
    },
  },
  mounted() {
    this.uuid = uuid();
  },
  methods: {
    getEventDesc,
    getConditionName(event) {
      const conditionNameToVerbMapper = new Map([
        [ConditionName.Diagnosis, 'diagnosed'],
        [ConditionName.Medication, 'took'],
        [ConditionName.MedicalOrder, 'had'],
      ]);
      if (event.condition.length !== 0) {
        return conditionNameToVerbMapper.get(event.condition[0].name);
      }
      return '';
    },
  },
};
</script>
<style lang="scss" scoped>
.title-text {
  height: 23px;
  left: 0px;
  top: 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #0f4895;
}
.event-content {
  height: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 165%;
  color: #333333;
  margin-top: 2px;
  // height: 26px;
}

.then-text {
  height: 18px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 165%;
  color: #828282;
  margin-top: 2px;
  // height: 23px;
}

.statistics-text {
  height: 26px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 165%;
  color: #333333;
}

.event-name {
  color: #0f4895;
  font-weight: bold;
}

/deep/ .arrow::before {
  display: none;
}

/deep/ .tooltip-inner {
  max-width: 456px;
  background-color: white;
  border: #bdbdbd 1px solid;
  color: black;
  text-align: inherit;
  padding: 0px;
  border-radius: 6px;
}
</style>
