<template>
  <div>
    <AgeConstraint
      :index="index"
      :eventType="eventType"
      :data="data"
      :path="path"
      v-if="constraintType === ConstraintType.AgeConstraint"
    />
    <VisitTypeConstraint
      :index="index"
      :eventType="eventType"
      :data="data"
      :path="path"
      v-if="constraintType === ConstraintType.VisitTypeConstraint"
    />
    <DiagnosisRangeConstraint
      :index="index"
      :eventType="eventType"
      :data="data"
      :path="path"
      v-if="constraintType === ConstraintType.DiagnosisRangeConstraint"
    />
    <DataRangeConstraint
      :index="index"
      :eventType="eventType"
      :data="data"
      :path="path"
      v-if="constraintType === ConstraintType.DataRangeConstraint"
    />
    <MedicationTotalConstraint
      :index="index"
      :eventType="eventType"
      :data="data"
      :path="path"
      :constraintType="constraintType"
      v-if="isMedicationConstraint"
    />
    <!-- <DiagnosisRepeatedTimesConstraint
      :index="index"
      :eventType="eventType"
      :data="data"
      :path="path"
      :constraintType="constraintType"
      v-if="constraintType === ConstraintType.DiagnosisRepeatedTimesConstraint"
    /> -->
    <!-- this feature's BE not ready yet, will active it when BE ready -->
    <!-- <DiagnosisDepartmentConstraint
      :index="index"
      :eventType="eventType"
      :data="data"
      :path="path"
      :constraintType="constraintType"
      v-if="constraintType === ConstraintType.DiagnosisDepartmentConstraint"
    /> -->
  </div>
</template>

<script>
import { EventType } from '@/utils/conditions/core/Interface';
import AgeConstraint from '@/components/byEvent/constraint/AgeConstraint.vue';
import DiagnosisRangeConstraint from '@/components/byEvent/constraint/DiagnosisRangeConstraint.vue';
import ConstraintType from '@/utils/conditions/core/advance/constraint/Interface';
import VisitTypeConstraint from '@/components/byEvent/constraint/VisitTypeConstraint.vue';
import DataRangeConstraint from '@/components/byEvent/constraint/DataRangeConstraint.vue';
import MedicationTotalConstraint from '@/components/byEvent/constraint/MedicationTotalConstraint.vue';
// import DiagnosisRepeatedTimesConstraint from '@/components/byEvent/constraint/DiagnosisRepeatedTimesConstraint.vue';
// import DiagnosisDepartmentConstraint from '@/components/byEvent/constraint/DiagnosisDepartmentConstraint.vue';

export default {
  name: 'ConstraintRender',
  components: {
    AgeConstraint,
    DiagnosisRangeConstraint,
    VisitTypeConstraint,
    DataRangeConstraint,
    MedicationTotalConstraint,
    // DiagnosisRepeatedTimesConstraint,
    // DiagnosisDepartmentConstraint,
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    eventType: {
      type: String,
      default: EventType.FIX_EVENT,
    },
    constraintType: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      default: Object,
    },
    path: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      ConstraintType,
    };
  },
  computed: {
    isMedicationConstraint() {
      const medicationConstraintList = [
        ConstraintType.MedicationTotalConstraint,
        // ConstraintType.MedicationDoseTimesConstraint,
        // ConstraintType.MedicationPrescriptionTimesConstraint,
      ];
      return medicationConstraintList.includes(this.constraintType);
    },
  },
};
</script>
