<template>
  <div>
    <div class="age-title-text mb-5px">Age</div>
    <div class="d-flex align-items-center">
      <a-input
        type="number"
        min="0"
        max="200"
        class="mr-10px"
        placeholder="0"
        style="width: 70px"
        v-model="start"
        @focus="$event.target.select()"
      />
      <div class="age-text mr-10px ml-10px">~</div>
      <a-input
        type="number"
        min="0"
        max="200"
        placeholder="200"
        style="width: 70px"
        v-model="end"
        @focus="$event.target.select()"
      />
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from '@/store';
import { cloneDeep } from 'lodash';
import ConditionList from '@/utils/ConditionList';

export default {
  name: 'AgeFilter',
  data() {
    return {
      start: '',
      end: '',
    };
  },
  mounted() {
    this.syncStoreToLocal();
  },
  computed: {
    ...mapState('condition', ['conditions']),
    getAgeFromStore() {
      return this.conditions.find((item) => item.name === 'Age');
    },
  },
  watch: {
    conditions() {
      this.syncStoreToLocal();
    },
    start() {
      this.setAgeCondition();
    },
    end() {
      this.setAgeCondition();
    },
  },
  methods: {
    ...mapMutations('condition', ['setCondition']),
    syncStoreToLocal() {
      if (this.getAgeFromStore) {
        const result = this.getAgeFromStore.ranges[0];
        this.start = result.end > result.start ? result.start : result.end;
        this.end = result.end > result.start ? result.end : result.start;
      } else {
        this.start = '';
        this.end = '';
      }
    },
    setAgeCondition() {
      if (!this.start) {
        this.start = 0;
      }

      if (!this.end) {
        this.end = 200;
      }

      const ageRanges = [
        {
          start: this.end > this.start ? parseInt(this.start, 10) : parseInt(this.end, 10),
          end: this.end > this.start ? parseInt(this.end, 10) : parseInt(this.start, 10),
        },
      ];

      const result = cloneDeep(this.conditions);
      if (this.getAgeFromStore) {
        const resultIndex = this.conditions.findIndex((item) => item.name === 'Age');
        result[resultIndex].ranges = ageRanges;
      } else {
        const ageResult = cloneDeep(ConditionList.age);
        ageResult.ranges = ageRanges;
        result.push(ageResult);
      }

      this.setCondition(result);
    },
  },
};
</script>
<style lang="scss" scoped>
.age-text {
  font-size: 16px;
  line-height: 19px;
  color: #919191;
}

.age-title-text {
  font-size: 14px;
  line-height: 17px;
  color: #919191;
}
</style>
