import BasicChartConfig from '@/components/charts/core/BasicChartConfig';
import { ChartItem, RawItem, ChartKey } from '@/components/charts/core/Interface';
import { PRECISION_THRESHOLD, UICONFIG } from '@/utils/constants';
import { sourceOption, CodeViewAllOption } from '@/utils/util';
import {
  CategoryCondition,
  CategoryConditionData,
  ConditionName,
  LogicOp,
  SearchType,
} from '../../utils/conditions/core';
import { SELECTED_COLOR } from './utils/utils';

interface AbnormalRawItem extends RawItem {
  patient_count: {
    value: number;
  };
}

export default class AbnormalLabConfig extends BasicChartConfig<AbnormalRawItem> {
  constructor() {
    super(ChartKey.AbnormalLab);
    this.hasViewAll = true;
    this.merge({ ...sourceOption });
  }

  protected setAxisData(): void {
    this.unset('xAxis.data');
    this.unset('yAxis.data');
    this.merge({
      xAxis: {
        name: this.bucketByPatient ? 'Patient' : 'Record',
      },
      yAxis: {
        data: this.data.map((item) => item.key),
      },
    });
  }

  public createViewAllConfig(): AbnormalLabConfig {
    const config = new AbnormalLabConfig();
    config.resultSize = UICONFIG.VIEW_ALL_SIZE;
    config.bucketByPatient = this.bucketByPatient; // Copy over previous bucketing status.
    config.merge({ ...CodeViewAllOption });
    return config;
  }

  protected createChartItem(rawItem: AbnormalRawItem): ChartItem {
    const {
      key,
      doc_count,
      patient_count: { value },
    } = rawItem;
    const condList = this.params.includes.additions.with(ConditionName.AbnormalLab);

    let color = '#333f6b';
    if (condList.length !== 0) {
      const condition = condList[0] as CategoryCondition;
      if (condition.hitKey(key)) {
        color = SELECTED_COLOR;
      }
    }

    const targetValue = this.bucketByPatient ? value : doc_count;

    return {
      key,
      value: targetValue,
      code: key,
      name: key,
      color,
      percentage: this.calculatePercentage(targetValue),
    };
  }

  public createConditionFromItem(chartItem: ChartItem): CategoryConditionData {
    const { name } = chartItem;
    return {
      name: ConditionName.AbnormalLab,
      tags: [SearchType.Basic, SearchType.Additional],
      andOr: LogicOp.And,
      list: [name],
    };
  }

  protected get aggsQuery(): Record<string, unknown> {
    const orderField = this.getOrderField();
    return {
      terms: {
        field: 'ABNORMAL_LIST',
        size: this.resultSize,
        order: {
          [orderField]: 'desc',
        },
      },
      aggs: {
        patient_count: {
          cardinality: {
            field: 'CHART_NO',
            precision_threshold: PRECISION_THRESHOLD,
          },
        },
      },
    };
  }
}
