<template>
  <div class="edit-lab-capsule-menu" v-if="item && isOpenMenu" v-click-outside="closeEditMenu" @click.stop="">
    <div class="d-flex justify-content-between align-items-center">
      <div class="title-text">Lab filter</div>
      <b-img src="@/assets/images/icon_close.svg" class="close-icon" @click="closeEditMenu" />
    </div>
    <div class="d-flex align-items-center mt-10px">
      <a-select
        default-value="gte"
        style="width: 230px"
        size="small"
        :getPopupContainer="(trigger) => trigger.parentNode"
        v-model="startOperator"
        @change="handleChangeStartOperator"
      >
        <a-icon slot="suffixIcon" type="caret-down" />
        <a-select-option value="gte"> >= Greater than or equal to </a-select-option>
        <a-select-option value="lte"> {{ lessThanString }} </a-select-option>
        <a-select-option value="gt"> > Greater than </a-select-option>
        <a-select-option value="lt"> {{ lessString }} </a-select-option>
        <a-select-option value="e"> = Equals </a-select-option>
      </a-select>
      <b-form-input v-model="greaterText" placeholder="Enter value" class="lab-input ml-10px"> </b-form-input>
      <div class="lab-description-text ml-10px">{{ item.unit }}</div>
      <b-img
        src="@/assets/images/icon_add_age_range.svg"
        class="ml-10px"
        v-if="!twoOperators"
        @click.stop="clickAddCondition"
        style="cursor: pointer"
      />
    </div>
    <div class="d-flex align-items-center mt-10px" v-if="twoOperators">
      <div class="normal-logic" :class="{ 'select-logic': labAndOr === 'filter' }" @click.stop="clickAnd">
        {{ andText }}
      </div>
      <div class="normal-logic ml-10px" :class="{ 'select-logic': labAndOr === 'should' }" @click.stop="clickOr">
        {{ orText }}
      </div>
    </div>
    <div class="d-flex align-items-center mt-10px" v-if="twoOperators">
      <a-select
        default-value="lte"
        style="width: 230px"
        size="small"
        :getPopupContainer="(trigger) => trigger.parentNode"
        v-model="endOperator"
        @change="handleChangeEndOperator"
      >
        <a-icon slot="suffixIcon" type="caret-down" />
        <a-select-option value="gte"> >= Greater than or equal to </a-select-option>
        <a-select-option value="lte"> {{ lessThanString }} </a-select-option>
        <a-select-option value="gt"> > Greater than </a-select-option>
        <a-select-option value="lt"> {{ lessString }} </a-select-option>
        <a-select-option value="e"> = Equals </a-select-option>
      </a-select>
      <b-form-input v-model="lessText" placeholder="Enter value" class="lab-input ml-10px"> </b-form-input>
      <div class="lab-description-text ml-10px">{{ item.unit }}</div>
      <b-img
        src="@/assets/images/icon_delete_event.svg"
        class="ml-10px"
        style="cursor: pointer"
        @click.stop="clickDeleteCondition"
      />
    </div>
  </div>
</template>
<script>
/* eslint-disable vue/require-valid-default-prop */
import { EventSearchMixin, PatientNumberMixin, EventUpdateMixin, ClickMixin } from '@/mixins';
import ClickOutside from 'vue-click-outside';
import { ConditionName } from '@/utils/conditions/core';
import { cloneDeep } from 'lodash';

export default {
  name: 'EditLabCapsuleMenu',
  directives: {
    ClickOutside,
  },
  mixins: [EventSearchMixin, PatientNumberMixin, EventUpdateMixin, ClickMixin],
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    path: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      greaterText: this.item.start,
      startOperator: this.item.startOperator,
      lessText: this.item.end,
      endOperator: this.item.endOperator,
      labAndOr: this.item.andOr,
      twoOperators: this.item.mutilRow,
      lessThanString: '<= Less than or equal to',
      lessString: '< Less than',
    };
  },
  watch: {
    greaterText() {
      this.changeLabCondition('start', this.greaterText);

      this.cohortStudyTrackClick('greaterText', { greaterText: this.greaterText });
    },
    lessText() {
      this.changeLabCondition('end', this.lessText);

      this.cohortStudyTrackClick('lessText', { lessText: this.lessText });
    },
    item() {
      this.greaterText = this.item.start;
      this.startOperator = this.item.startOperator;
      this.lessText = this.item.end;
      this.endOperator = this.item.endOperator;
      this.labAndOr = this.item.andOr;
      this.twoOperators = this.item.mutilRow;
    },
  },
  computed: {
    isOpenMenu() {
      return this.isCurrentMenu(this.path, `LabCapsule-${this.item.code}-${this.item.exam}-${this.item.unit}`);
    },
    andText() {
      if (this.labAndOr === 'filter') {
        return '✓ And';
      }
      return 'And';
    },
    orText() {
      if (this.labAndOr === 'should') {
        return '✓ Or';
      }
      return 'Or';
    },
  },
  methods: {
    closeEditMenu() {
      this.setCurrentMenu(this.path, '');
    },
    changeLabCondition(field, value) {
      const index = this.currentEvent.condition.findIndex((condition) => condition.name === ConditionName.Lab);
      if (index > -1) {
        const itemIndex = this.currentEvent.condition[index].list.findIndex(
          (condition) =>
            condition.code === this.item.code && condition.exam === this.item.exam && condition.unit === this.item.unit
        );

        if (itemIndex > -1) {
          const cloneCondition = cloneDeep(this.currentEvent.condition);
          cloneCondition[index].list[itemIndex][field] = value;

          this.updateEventWithPath(
            {
              condition: cloneCondition,
            },
            this.path
          );
        }
      }
    },
    handleChangeLabLogic(value) {
      this.labAndOr = value;
      this.changeLabCondition('andOr', value);
    },
    clickAddCondition() {
      this.twoOperators = true;
      this.changeLabCondition('mutilRow', true);

      this.cohortStudyTrackClick('clickAddCondition');
    },
    clickAnd() {
      this.handleChangeLabLogic('filter');

      this.cohortStudyTrackClick('clickAnd');
    },
    clickOr() {
      this.handleChangeLabLogic('should');

      this.cohortStudyTrackClick('clickOr');
    },
    handleChangeStartOperator(value) {
      this.startOperator = value;
      this.changeLabCondition('startOperator', value);

      this.cohortStudyTrackClick('handleChangeStartOperator', value);
    },
    handleChangeEndOperator(value) {
      this.endOperator = value;
      this.changeLabCondition('endOperator', value);

      this.cohortStudyTrackClick('handleChangeEndOperator', value);
    },
    clickDeleteCondition() {
      this.twoOperators = false;
      this.endOperator = 'lte';
      this.changeLabCondition('endOperator', 'lte');
      this.lessText = '';
      this.changeLabCondition('end', '');
      this.twoOperators = false;
      this.changeLabCondition('mutilRow', false);

      this.cohortStudyTrackClick('clickDeleteCondition');
    },
  },
};
</script>
<style lang="scss" scoped>
.edit-lab-capsule-menu {
  position: absolute;
  margin-top: 10px;
  z-index: 1;
  box-shadow: 0px 4px 10px rgba(51, 63, 107, 0.2);
  border-radius: 6px;
  padding: 20px 20px;
  background: #ffffff;
  height: auto;
  margin-left: -300px;
}

.menu-title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #334e97;
  margin-left: 20px;
}

.close-icon {
  cursor: pointer;
}

.lab-input {
  width: 100px;
  height: 23px;
  padding: 0px 5px;
}

.lab-description-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #212121;
}

.lab-logic-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #757575;
}

.header-row {
  height: 38px;
  padding: 10px 20px 10px 20px;
  background-color: #eff0f3;
}

.header-text-left-title {
  height: 15px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #334e97;
}

.header-text-left {
  height: 15px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #334e97;
}

.header-text-right {
  height: 15px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #334e97;
}

.header-text-margin {
  margin-top: 3px;
}

.padding-12 {
  padding-right: 12px !important;
}

.row-item {
  box-shadow: inset 0px 1px 0px #d6d9e1;
}

.code-search-menu {
  padding: 0px 20px;
  height: 54px;
}

.code-text {
  height: 18px;
  margin: 1px 10px;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #334e97;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.description-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.icd-record-count {
  text-align: right;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #828282;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

/deep/ .ant-select-arrow {
  color: #0f4895;
}

.normal-logic {
  padding: 4px 8px;
  border: 1px solid #4d8edc;
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #4d8edc;
  cursor: pointer;
}

.select-logic {
  padding: 4px 8px;
  background: #4d8edc;
  border: 1px solid #4d8edc;
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  cursor: pointer;
}

.title-text {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #333f6b;
}
</style>
