<template>
  <div @click.stop="clickEdit" style="cursor: pointer" :title="`${item.code} ${item.exam} ${item.unit}`">
    <span class="capsule-abbr">L</span>
    <span class="capsule-code">{{ getCapsuleText }}</span>
    <b-img src="@/assets/images/icon_capsule_edit.svg" class="capsule-edit" />
    <b-img src="@/assets/images/icon_capsule_delete_white.svg" class="capsule-delete" @click.stop="clickDelete" />
  </div>
</template>
<script>
/* eslint-disable vue/require-valid-default-prop */
import { EventSearchMixin, ClickMixin } from '@/mixins';

export default {
  name: 'LabCapsule',
  mixins: [EventSearchMixin, ClickMixin],
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    path: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    getCapsuleText() {
      const andOr = {
        filter: 'and',
        should: 'or',
      };

      const nameMapper = new Map([
        ['gte', '>='],
        ['lte', '<='],
        ['gt', '>'],
        ['lt', '<'],
        ['e', '='],
      ]);
      if (this.item.end && this.item.start) {
        return `${this.item.code} ${nameMapper.get(this.item.startOperator)} ${this.item.start} ${
          andOr[this.item.andOr]
        } ${nameMapper.get(this.item.endOperator)}${this.item.end}`;
      }
      if (this.item.start) {
        return `${this.item.code} ${nameMapper.get(this.item.startOperator)} ${this.item.start}`;
      }
      if (this.item.end) {
        return `${this.item.code} ${nameMapper.get(this.item.endOperator)} ${this.item.end}`;
      }
      return this.item.code;
    },
  },
  methods: {
    clickDelete() {
      this.$emit('clickDelete', this.item);
      this.cohortStudyTrackClick('clickDelete');
    },
    clickEdit() {
      this.setCurrentMenu(this.path, `LabCapsule-${this.item.code}-${this.item.exam}-${this.item.unit}`);
      this.cohortStudyTrackClick('clickEdit');
    },
  },
};
</script>
<style lang="scss" scoped>
.capsule-abbr {
  padding: 0px 2px;
  color: #0f4895;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  margin-right: 5px;
  background: #e1c1f0;
  border-radius: 2px;
}

.capsule-code {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  margin-right: 5px;
}

.capsule-delete {
  cursor: pointer;
  margin-bottom: 3px;
  padding: 2px;
}

.capsule-edit {
  width: 12px;
  height: 12px;
  cursor: pointer;
  margin-bottom: 3px;
  margin-right: 5px;
}
</style>
