import { mapState } from 'vuex';
import { FEEDBACK, SESSION } from '@/utils/constants';

export default {
  computed: {
    ...mapState('session', ['tabs']),
    ...mapState('user', ['sessionTrackId']),
    tab() {
      if (this.tabs.length === 0) {
        return {
          includes: [],
          excludes: [],
          data: {},
        };
      }
      return this.tabs[this.uniqueIndex];
    },
  },
  methods: {
    rootTemplate() {
      if (this.uniqueIndex !== undefined && this.uniqueIndex !== -1) {
        return this.$store.state.session.tabs[this.uniqueIndex].data.root;
      }
      return this.$api.root;
    },
    trackIdTemplate() {
      if (this.uniqueIndex !== undefined && this.uniqueIndex !== -1) {
        return this.$store.state.session.tabs[this.uniqueIndex].data.trackId;
      }
      return this.$api.trackId;
    },
    trackContentTemplate() {
      return {
        root: this.rootTemplate(),
        trackId: this.trackIdTemplate(),
        [FEEDBACK.MODE]: FEEDBACK.MODE_TYPE.RECORD,
        [SESSION.SESSION_TRACK_ID]: this.sessionTrackId,
      };
    },
    advanceRootTemplate() {
      if (this.uniqueIndex !== undefined && this.uniqueIndex !== -1) {
        return this.$store.state.byEventSession.tabs[this.uniqueIndex].data.root;
      }
      return this.$api.advanceRoot;
    },
    advanceTrackIdTemplate() {
      if (this.uniqueIndex !== undefined && this.uniqueIndex !== -1) {
        return this.$store.state.byEventSession.tabs[this.uniqueIndex].data.trackId;
      }
      return this.$api.advanceTrackId;
    },
    advanceTrackContentTemplate() {
      return {
        root: this.advanceRootTemplate(),
        trackId: this.advanceTrackIdTemplate(),
        [FEEDBACK.MODE]: FEEDBACK.MODE_TYPE.EVENT,
        [SESSION.SESSION_TRACK_ID]: this.sessionTrackId,
      };
    },
    cohortRootTemplate() {
      if (this.uniqueIndex !== undefined && this.uniqueIndex !== -1) {
        return this.$store.state.cohortSession.tabs[this.uniqueIndex].data.root;
      }
      return this.$api.cohortRoot;
    },
    cohortTrackIdTemplate() {
      if (this.uniqueIndex !== undefined && this.uniqueIndex !== -1) {
        return this.$store.state.cohortSession.tabs[this.uniqueIndex].data.trackId;
      }
      return this.$api.cohortTrackId;
    },
    cohortStudyTrackContentTemplate() {
      return {
        root: this.cohortRootTemplate(),
        trackId: this.cohortTrackIdTemplate(),
        [FEEDBACK.MODE]: FEEDBACK.MODE_TYPE.COHORT_STUDY,
        [SESSION.SESSION_TRACK_ID]: this.sessionTrackId,
      };
    },
    trackTimeTemplate(name, extras) {
      return {
        name,
        extras,
      };
    },
  },
};
