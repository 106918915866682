<template>
  <div class="main-search">
    <b-row no-gutters>
      <b-col>
        <b-row
          no-gutters
          v-if="
            experimentalGroupState === GROUP_STATE.EDITING ||
            controlGroupState === GROUP_STATE.EDITING ||
            covariantGroupState === GROUP_STATE.EDITING ||
            outcomeGroupState === GROUP_STATE.EDITING
          "
        >
          <b-col class="d-flex justify-content-center mt-20px">
            <div class="search-title">Click the button below to start a new cohort study</div>
          </b-col>
        </b-row>
        <b-row no-gutters>
          <b-col class="d-flex justify-content-center align-items-center mt-20px">
            <div class="search-second-title mr-10px">{{ formatedTemplateNumber(totalPatient) }}</div>
            <div class="patient-count-text">patients total</div>
          </b-col>
        </b-row>
        <b-row no-gutters class="mt-20px">
          <b-col cols="6" style="padding: 0px 20px 0px 0px">
            <b-row no-gutters>
              <b-col class="d-flex justify-content-end">
                <div class="search-second-title text-center title-text-overflow top-group-width">
                  {{ getExperimentalName }}
                </div>
              </b-col>
            </b-row>
            <b-row no-gutters class="mt-5px">
              <b-col class="d-flex justify-content-end">
                <div class="d-flex align-items-center justify-content-center title-text-overflow top-group-width">
                  <div class="group-count-text mr-10px">{{ formatedTemplateNumber(experimentalGroupPatient) }}</div>
                  <div class="patient-text">patients</div>
                </div>
              </b-col>
            </b-row>
            <b-row no-gutters>
              <b-col class="d-flex justify-content-end">
                <div
                  class="top-group-content mt-5px top-group-width"
                  @click.stop="clickExperimentalGroup"
                  :class="{
                    'editing-group': experimentalGroupState === GROUP_STATE.EDITING,
                    'done-group': experimentalGroupState === GROUP_STATE.DONE,
                  }"
                >
                  <div class="group-content-text title-text-overflow">{{ getExperimentalPopulationName }}</div>
                  <div class="group-content-text mt-40px title-text-overflow">
                    {{ getExperimentalInterventionName }}
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="6" style="padding: 0px 0px 0px 20px">
            <b-row no-gutters>
              <b-col class="d-flex justify-content-start">
                <div class="search-second-title text-center title-text-overflow top-group-width">
                  {{ getControlName }}
                </div>
              </b-col>
            </b-row>
            <b-row no-gutters class="mt-5px">
              <b-col class="d-flex justify-content-start">
                <div class="d-flex align-items-center justify-content-center title-text-overflow top-group-width">
                  <div class="group-count-text mr-10px">{{ formatedTemplateNumber(controlGroupPatient) }}</div>
                  <div class="patient-text">patients</div>
                </div>
              </b-col>
            </b-row>
            <b-row no-gutters>
              <b-col class="d-flex justify-content-start">
                <div
                  class="top-group-content mt-5px top-group-width"
                  @click.stop="clickControlGroup"
                  :class="{
                    'editing-group': controlGroupState === GROUP_STATE.EDITING,
                    'done-group': controlGroupState === GROUP_STATE.DONE,
                  }"
                >
                  <div class="group-content-text title-text-overflow">{{ getControlPopulationName }}</div>
                  <div class="group-content-text mt-40px title-text-overflow">{{ getControlInterventionName }}</div>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row no-gutters>
          <b-col class="d-flex justify-content-center mt-20px">
            <div
              class="group-content group-margin-top d-flex justify-content-center align-items-center"
              @click.stop="clickCovariantGroup"
              :class="{
                'editing-group': covariantGroupState === GROUP_STATE.EDITING,
                'done-group': covariantGroupState === GROUP_STATE.DONE,
              }"
            >
              <b-img src="@/assets/images/icon_name_warning.svg" class="ml-5px" v-if="getCovariateNameError" />
              <div class="group-content-text">Covariate</div>
            </div>
          </b-col>
        </b-row>
        <b-row no-gutters>
          <b-col class="d-flex justify-content-center mt-20px">
            <div
              class="group-content group-margin-top d-flex justify-content-center align-items-center"
              @click.stop="clickOutcomeGroup"
              :class="{
                'editing-group': outcomeGroupState === GROUP_STATE.EDITING,
                'done-group': outcomeGroupState === GROUP_STATE.DONE,
              }"
            >
              <b-img src="@/assets/images/icon_name_warning.svg" class="ml-5px" v-if="getOutcomeNameError" />
              <div class="group-content-text">Outcome</div>
            </div>
          </b-col>
        </b-row>
        <b-row no-gutters>
          <b-col class="d-flex justify-content-center mt-20px">
            <div
              class="d-flex justify-content-center compare-button group-margin-top"
              @click="clickCompare"
              :class="{ 'button-disable': disableCompare || getCovariateNameError || getOutcomeNameError }"
            >
              <b-img src="@/assets/images/icon_compare.svg" />
              <div class="compare-button-text ml-10px">Compare</div>
            </div>
          </b-col>
        </b-row>
        <b-row no-gutters>
          <b-col class="d-flex justify-content-center mt-20px">
            <div class="group-content-text" style="cursor: pointer" @click="clickClearAllModal">Clear All</div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-modal
      :visible="clearAllModal"
      centered
      no-close-on-backdrop
      no-close-on-esc
      hide-header
      hide-footer
      hide-header-close
      content-class="clear-all-content-modal"
      body-class="clear-all-body-modal"
    >
      <b-row no-gutters>
        <b-col>
          <b-row no-gutters>
            <b-col class="title-text" style="text-align: center">Clear All Criteria</b-col>
          </b-row>
          <b-row no-gutters class="mt-20px">
            <b-col class="clear-all-text"> Are you sure you want to clear all criteria? </b-col>
          </b-row>
          <b-row no-gutters>
            <b-col class="d-flex justify-content-center button-col">
              <b-button variant="outline-primary" class="button-close" @click="closeClearAllModal">Cancel</b-button>
              <b-button variant="outline-primary" class="button-cancel" @click="clickClearAll">Clear All</b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from '@/store';
import { GROUP_STATE, VUE_EVENT_NAME } from '@/utils/constants';
import ClickOutside from 'vue-click-outside';
import { PatientNumberMixin, ClickMixin } from '@/mixins';
import dayjs from 'dayjs';
import { v4 as uuid } from 'uuid';
import { cloneDeep } from 'lodash';

export default {
  name: 'MainSearch',
  directives: {
    ClickOutside,
  },
  mixins: [PatientNumberMixin, ClickMixin],
  data() {
    return {
      GROUP_STATE,
      editStudyName: false,
      localStudyName: 'New Study',
      clearAllModal: false,
    };
  },
  computed: {
    ...mapState('eventSearch', [
      'experimentalGroupState',
      'controlGroupState',
      'covariantGroupState',
      'outcomeGroupState',
      'disableCompare',
      'totalPatient',
      'studyName',
      'eventSearch',
      'experimentalGroupPatient',
      'controlGroupPatient',
      'nameError',
    ]),
    ...mapState('cohortSession', ['tabs', 'NAME_COUNTER']),
    getExperimentalName() {
      return this.eventSearch.experimental.name;
    },
    getExperimentalPopulationName() {
      return this.eventSearch.experimental.eventList[0].name;
    },
    getExperimentalInterventionName() {
      return this.eventSearch.experimental.indexEvent.name;
    },
    getControlName() {
      return this.eventSearch.control.name;
    },
    getControlPopulationName() {
      return this.eventSearch.control.eventList[0].name;
    },
    getControlInterventionName() {
      return this.eventSearch.control.indexEvent.name;
    },
    getCovariateNameError() {
      return this.nameError && this.nameError.split('-')[0] === 'covariate';
    },
    getOutcomeNameError() {
      return this.nameError && this.nameError.split('-')[0] === 'outcome';
    },
  },
  async mounted() {
    this.updateTotalPatient({ api: this.$api });
  },
  methods: {
    ...mapActions('eventSearch', ['updateTotalPatient']),
    ...mapMutations('eventSearch', [
      'updateExperimentalGroupState',
      'updateControlGroupState',
      'updateCovariantGroupState',
      'updateOutcomeGroupState',
      'updateStudyName',
      'setDefaultEventSearch',
    ]),
    ...mapMutations('cohortSession', ['addTab']),
    clickExperimentalGroup() {
      this.$root.$emit(VUE_EVENT_NAME.SET_EVENT_SEARCH_TO_TOP);
      this.updateExperimentalGroupState(GROUP_STATE.EDITING);
      this.updateControlGroupState(GROUP_STATE.INIT);
      this.updateCovariantGroupState(GROUP_STATE.INIT);
      this.updateOutcomeGroupState(GROUP_STATE.INIT);
      this.cohortStudyTrackClick('clickExperimentalGroup');
    },
    clickControlGroup() {
      this.$root.$emit(VUE_EVENT_NAME.SET_EVENT_SEARCH_TO_TOP);
      this.updateControlGroupState(GROUP_STATE.EDITING);
      this.updateExperimentalGroupState(GROUP_STATE.INIT);
      this.updateCovariantGroupState(GROUP_STATE.INIT);
      this.updateOutcomeGroupState(GROUP_STATE.INIT);
      this.cohortStudyTrackClick('clickControlGroup');
    },
    clickCovariantGroup() {
      this.$root.$emit(VUE_EVENT_NAME.SET_EVENT_SEARCH_TO_TOP);
      this.updateCovariantGroupState(GROUP_STATE.EDITING);
      this.updateExperimentalGroupState(GROUP_STATE.INIT);
      this.updateControlGroupState(GROUP_STATE.INIT);
      this.updateOutcomeGroupState(GROUP_STATE.INIT);
      this.cohortStudyTrackClick('clickCovariantGroup');
    },
    clickOutcomeGroup() {
      this.$root.$emit(VUE_EVENT_NAME.SET_EVENT_SEARCH_TO_TOP);
      this.updateOutcomeGroupState(GROUP_STATE.EDITING);
      this.updateExperimentalGroupState(GROUP_STATE.INIT);
      this.updateControlGroupState(GROUP_STATE.INIT);
      this.updateCovariantGroupState(GROUP_STATE.INIT);
      this.cohortStudyTrackClick('clickOutcomeGroup');
    },
    clickEditStudyName() {
      this.editStudyName = true;
      this.$nextTick(() => {
        this.$refs.editStudyName.select();
      });
      this.cohortStudyTrackClick('clickEditStudyName');
    },
    closeEditStudyName() {
      this.editStudyName = false;
      this.updateStudyName(this.localStudyName);
    },
    genTabContent(url) {
      return {
        id: '',
        name: 'Analysis',
        updateTime: dayjs().format('YYYY/MM/DD HH:mm:ss'),
        eventSearch: cloneDeep(this.eventSearch),
        currentMenu: '',
        experimentalGroupPatient: cloneDeep(this.experimentalGroupPatient),
        controlGroupPatient: cloneDeep(this.controlGroupPatient),
        data: {
          root: { trackId: this.$api.cohortTrackId },
          trackId: uuid(),
        },
        url,
        tabType: 'history',
        getLatest: true,
        characteristicsTableRequestInProgress: false,
        incidenceRateTableRequestInProgress: false,
        survivalRateChartRequestInProgress: false,
        coxRegressionRequestInProgress: false,
        patientListRequestInProgress: false,
        resultDone: false,
        characteristicsTableProgressPercent: 0,
        incidenceRateTableProgressPercent: 0,
        survivalRateChartProgressPercent: 0,
        coxRegressionProgressPercent: 0,
        patientListProgressPercent: 0,
      };
    },
    async clickCompare() {
      const result = await this.$api.postCohortStudy({
        name: `Analysis ${this.NAME_COUNTER}`,
        updateTime: dayjs().toISOString(),
        purpose: 'history',
        request: {
          searchState: this.eventSearch,
        },
      });

      this.addTab(this.genTabContent(result.url));
      this.$root.$emit(VUE_EVENT_NAME.REFRESH_HISTORY_ANALYSIS_LIST);
      this.cohortStudyTrackClick('clickCompare');

      this.$api.setCohortTrackId(uuid());
      this.$root.$emit(VUE_EVENT_NAME.GET_INTERVAL_FEEDBACK);
    },
    clickClearAllModal() {
      this.clearAllModal = true;
    },
    clickClearAll() {
      this.setDefaultEventSearch();
      this.clearAllModal = false;
      this.cohortStudyTrackClick('clickClearAll');
    },
    closeClearAllModal() {
      this.clearAllModal = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.main-search {
  padding: 20px 0px;
  position: sticky;
  top: 0;
  left: 0;
}

.search-title {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
  color: #334e97;
  padding: 0px 40px;
  text-align: center;
}

.patient-count-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #212121;
}

.search-second-title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #334e97;
}

.group-content {
  padding: 20px 55px;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #4d8edc;
  box-sizing: border-box;
  border-radius: 8px;
  width: calc(100% - 80px);
}

.group-content-text {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  color: #4d8edc;
  text-align: center;
}

.top-group-content {
  padding: 20px 0px;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #4d8edc;
  box-sizing: border-box;
  border-radius: 8px;
}

.compare-button {
  cursor: pointer;
  padding: 16px 0px;
  background: #4d8edc;
  border: 1px solid #4d8edc;
  box-sizing: border-box;
  border-radius: 8px;
  width: calc(100% - 80px);
}

.compare-button-text {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: #ffffff;
}

.editing-group {
  background: #c1e0f9;
  box-shadow: 0px 4px 12px #e0e0e0, 0px 12px 12px rgba(157, 205, 245, 0.1);
}

.done-group {
  background: #e9f4ed;
}

.button-disable {
  opacity: 0.5;
  pointer-events: none;
}

.title-text-overflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.top-group-width {
  width: calc(100% - 40px);
  max-width: calc(100% - 40px);
  min-width: calc(100% - 40px);
}

/deep/ .clear-all-content-modal {
  width: 475px;
  height: 217px;
  border-radius: 6px;
  background-color: #ffffff;
  border: 1px solid white;
}

/deep/ .clear-all-body-modal {
  padding: 30px 30px 30px 30px;
}

.title-text {
  height: 23px;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #333f6b;
}

.clear-all-text {
  height: 20px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333f6b;
}

.button-col {
  margin-top: 30px;
}

.button-cancel,
.button-cancel:hover,
.button-cancel:focus,
.button-cancel:active,
.button-cancel:active:visited {
  margin-right: 20px;
  width: 168px;
  height: 44px;
  border-radius: 6px;
  border: solid 1px #da6d6d !important;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff !important;
  background-color: #da6d6d !important;
}

.button-close,
.button-close:hover,
.button-close:focus,
.button-close:active,
.button-close:active:visited {
  margin-right: 20px;
  width: 168px;
  height: 44px;
  border-radius: 6px;
  border: solid 1px #333f6b !important;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333f6b !important;
  background-color: #ffffff !important;
}

.group-count-text {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #334e97;
}

.patient-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #212121;
}
</style>
<style scoped>
@media screen and (min-height: 0px) and (max-height: 700px) {
  .group-margin-top {
    margin-top: 0vh;
  }
}

@media screen and (min-height: 701px) and (max-height: 800px) {
  .group-margin-top {
    margin-top: 1vh;
  }
}

@media screen and (min-height: 801px) and (max-height: 900px) {
  .group-margin-top {
    margin-top: 2vh;
  }
}

@media screen and (min-height: 901px) {
  .group-margin-top {
    margin-top: 3vh;
  }
}
</style>
