import { merge, cloneDeep } from 'lodash';
import CCConfig from './CCConfig';
import DefaultConfig from './DefaultConfig';
import YGConfig from './YGConfig';
import CPConfig from './CPConfig';
import GLConfig from './GLConfig';
import SDConfig from './SDConfig';
import JBNConfig from './JBNConfig';
import JBCConfig from './JBCConfig';
import JBSConfig from './JBSConfig';
import JBEConfig from './JBEConfig';
import ASUSConfig from './ASUSConfig';

function getLocalUIConfig(hospitalId) {
  switch (hospitalId) {
    case 'Jelly BeanN':
      return merge(cloneDeep(DefaultConfig), cloneDeep(JBNConfig));
    case 'Jelly BeanC':
      return merge(cloneDeep(DefaultConfig), cloneDeep(JBCConfig));
    case 'Jelly BeanS':
      return merge(cloneDeep(DefaultConfig), cloneDeep(JBSConfig));
    case 'Jelly BeanE':
      return merge(cloneDeep(DefaultConfig), cloneDeep(JBEConfig));
    case 'Cheese Cake':
      return CCConfig;
    case 'Yogurt':
      return YGConfig;
    case 'Crepes':
      return CPConfig;
    case 'Gelato':
      return GLConfig;
    case 'Sundae':
      return SDConfig;
    case 'Asus':
      return merge(cloneDeep(DefaultConfig), cloneDeep(ASUSConfig));
    default:
      return DefaultConfig;
  }
}

export { CCConfig, DefaultConfig, getLocalUIConfig, GLConfig };
