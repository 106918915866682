<template>
  <a-slider :max="range.end" :min="range.start" range :defaultValue="value" @afterChange="changeTime" />
</template>

<script>
import { ClickMixin } from '@/mixins';

import { mapActions } from '../../../store';

export default {
  name: 'AdvanceTimeFilter',
  props: {
    range: Object,
  },
  mixins: [ClickMixin],
  computed: {
    value() {
      return [this.range.start, this.range.end];
    },
  },
  methods: {
    ...mapActions('advancePortal', ['updateTimePeriod']),
    changeTime(value) {
      this.advanceTrackClick('changeTime', { start: value[0], end: value[1] });
      this.updateTimePeriod({
        api: this.$api,
        start: value[0],
        end: value[1],
      });
    },
  },
};
</script>
<style scoped src="@/css/time-slider.css"></style>
