import { FEEDBACK } from '@/utils/constants';
import trackTemplate from '@/mixins/trackTemplate';
import { mapState } from '../store';

export default {
  mixins: [trackTemplate],
  computed: {
    ...mapState('user', ['userId']),
  },
  data() {
    return {
      firstEventTime: 0,
      indexEventTime: 0,
      thirdEventTime: 0,
    };
  },
  methods: {
    keySearch(value) {
      this.sendEventFeedback(FEEDBACK.NAME.KEY_SEARCH, value);
    },
    keySearchPCS(value) {
      this.sendEventFeedback(FEEDBACK.NAME.KEY_SEARCH_PCS, value);
    },
    keySearchProcedure(value) {
      this.sendEventFeedback(FEEDBACK.NAME.KEY_SEARCH_PROCEDURE, value);
    },
    keySearchDoctorId(value) {
      this.sendEventFeedback(FEEDBACK.NAME.KEY_SEARCH_DOCTOR_ID, value);
    },
    advanceKeySearch(value) {
      this.sendAdvanceEventFeedback(FEEDBACK.NAME.KEY_SEARCH, value);
    },
    advanceKeySearchPCS(value) {
      this.sendAdvanceEventFeedback(FEEDBACK.NAME.KEY_SEARCH_PCS, value);
    },
    advanceKeySearchProcedure(value) {
      this.sendAdvanceEventFeedback(FEEDBACK.NAME.KEY_SEARCH_PROCEDURE, value);
    },
    serviceTransfer(value) {
      this.sendEventFeedback(FEEDBACK.NAME.SERVIECE_TRANSFER, value);
    },
    keySearchDeselect(value) {
      this.sendEventFeedback(FEEDBACK.NAME.KEY_SEARCH_DESELECT, value);
    },
    advanceKeySearchDeselect(value) {
      this.sendAdvanceEventFeedback(FEEDBACK.NAME.KEY_SEARCH_DESELECT, value);
    },
    advanceEventHover(value) {
      this.sendAdvanceEventFeedback(FEEDBACK.NAME.EVENT_HOVER, value);
    },
    sendEventFeedback(action, value) {
      this.$api.feedback(
        action,
        {
          [FEEDBACK.COMPONENT_NAME]: this.$options.name,
          [FEEDBACK.USER_ID]: this.userId,
          [FEEDBACK.VALUE]: value,
          [FEEDBACK.ROUTE]: this.$route.path,
          [FEEDBACK.TIME]: Date.now(),
          [FEEDBACK.FROM_SERVICE]: this.$api.fromService,
          [FEEDBACK.MODE]: FEEDBACK.MODE_TYPE.RECORD,
        },
        this.trackContentTemplate()
      );
    },
    sendAdvanceEventFeedback(action, value) {
      this.$api.feedback(
        action,
        {
          [FEEDBACK.COMPONENT_NAME]: this.$options.name,
          [FEEDBACK.USER_ID]: this.userId,
          [FEEDBACK.VALUE]: value,
          [FEEDBACK.ROUTE]: this.$route.path,
          [FEEDBACK.TIME]: Date.now(),
          [FEEDBACK.FROM_SERVICE]: this.$api.fromService,
          [FEEDBACK.MODE]: FEEDBACK.MODE_TYPE.EVENT,
        },
        this.advanceTrackContentTemplate()
      );
    },
    setEventTime(index) {
      const indexToSetEventTimeListMapper = new Map([
        [
          1,
          () => {
            this.firstEventTime = Date.now();
          },
        ],
        [
          2,
          () => {
            this.indexEventTime = Date.now();
          },
        ],
        [
          3,
          () => {
            this.thirdEventTime = Date.now();
          },
        ],
      ]);
      indexToSetEventTimeListMapper.get(index)();
    },
    hoverEvent(index, content) {
      const indexToGetEventPeriodListMapper = new Map([
        [1, () => Date.now() - this.firstEventTime],
        [2, () => Date.now() - this.indexEventTime],
        [3, () => Date.now() - this.thirdEventTime],
      ]);
      const period = indexToGetEventPeriodListMapper.get(index)();

      this.advanceEventHover({
        index,
        content,
        period,
      });
    },
  },
};
