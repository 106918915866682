import AdvanceESQuery from '@/utils/query/core/cohortStudy/AdvanceESQuery';
import SearchStateQuery from '@/utils/query/core/cohortStudy/SearchStateQuery';
import { cloneDeep, find } from 'lodash';
import { defaultFirstEvent } from '@/utils/advance/initState';
import { SearchState } from '../conditions/core/advance';
import { getPatientQuery } from '@/utils/query/getEventPatientFromDocValue';

function hasCondition(event: any) {
  return event.condition.length > 0;
}

function hasTimeRelationEvent(event: any) {
  return event.inclusionList.length > 0 || event.exclusionList.length > 0;
}

function hasTimePeriodConstraint(event: any) {
  return (
    'constraintList' in event &&
    find(event.constraintList, function (constraint: { constraintType: string }) {
      return constraint.constraintType === 'DataRangeConstraint';
    })
  );
}

function checkIsTwoStepQuery(searchQuery: any) {
  if (
    hasCondition(searchQuery.indexEvent) &&
    hasCondition(searchQuery.eventList[0]) &&
    (hasTimeRelationEvent(searchQuery.eventList[0]) || hasTimePeriodConstraint(searchQuery.eventList[0]))
  ) {
    return true;
  } else {
    return false;
  }
}

function foolProof(searchObject: any) {
  const finalSearchObject = cloneDeep(searchObject);
  if (!hasCondition(finalSearchObject.indexEvent)) {
    finalSearchObject.indexEvent = cloneDeep(finalSearchObject.eventList[0]);
    finalSearchObject.eventList[0] = defaultFirstEvent;
  }
  return finalSearchObject;
}

function setOutcomeDate(outcome: any[]) {
  const updatedOutcome = [...outcome]
  for (let i = 0; i < outcome.length; i++) {
    updatedOutcome[i].relation.beforeValue = -1;
  }
  return updatedOutcome;
}

class SearchQueryGenerator {
  static generateCohortQuery(searchObject: { indexEvent: any; eventList: any; timePeriod: any }) {
    let queries = [];
    if (checkIsTwoStepQuery(searchObject)) {
      queries = [
        getPatientQuery(SearchQueryGenerator.generateFirstQuery(searchObject)),
        getPatientQuery(SearchQueryGenerator.generateSecondQuery(searchObject)),
      ];
    } else {
      queries = [SearchQueryGenerator.generateNormalQuery(searchObject)];
    }
    return queries;
  }

  static generateNormalQuery(searchObject: { indexEvent: any; eventList: any; timePeriod: any }): any {
    const finalSearchObject = foolProof(searchObject);

    const params = {
      SearchStateObject: finalSearchObject,
    };
    const AdvEsQueryGenerator = new AdvanceESQuery(params);
    return AdvEsQueryGenerator.getQuery();
  }

  static generateFirstQuery(searchObject: { indexEvent: any; eventList: any; timePeriod: any }) {
    const finalSearchObject = cloneDeep(searchObject);
    finalSearchObject.indexEvent = cloneDeep(finalSearchObject.eventList[0]);
    finalSearchObject.eventList[0] = defaultFirstEvent;

    const params = {
      SearchStateObject: finalSearchObject,
    };
    const AdvEsQueryGenerator = new AdvanceESQuery(params);
    AdvEsQueryGenerator.setFirstOccurrence(true);
    return AdvEsQueryGenerator.getQuery();
  }

  static generateSecondQuery(searchObject: { indexEvent: any; eventList: any; timePeriod: any }): any {
    const finalSearchObject = foolProof(searchObject);
    finalSearchObject.eventList[0].inclusionList = [];
    finalSearchObject.eventList[0].exclusionList = [];

    const params = {
      SearchStateObject: finalSearchObject,
    };
    const AdvEsQueryGenerator = new AdvanceESQuery(params);
    AdvEsQueryGenerator.setFirstOccurrence(true);
    return AdvEsQueryGenerator.getQuery();
  }

  static generateCharacteristicQuery(searchObject: SearchState, covariate: any, outcome: any) {
    const finalSearchObject = foolProof(searchObject);
    finalSearchObject.eventList[0].inclusionList = [];
    finalSearchObject.eventList[0].exclusionList = [];
    const AdvEsQueryGenerator = new SearchStateQuery(finalSearchObject, {
      isDeath: false,
      isFirstTime: true,
    });
    AdvEsQueryGenerator.setCovariant(covariate.concat(setOutcomeDate(outcome)));
    AdvEsQueryGenerator.setInnerhits(finalSearchObject, covariate.concat(setOutcomeDate(outcome)));
    return AdvEsQueryGenerator.getQuery();
  }
}

export { checkIsTwoStepQuery, SearchQueryGenerator };
