import { CHART } from '../constants';
import { CategoryCondition, ConditionFactory, ConditionName, LogicOp } from './core';

function getRiskQuery(riskGroups: string[]) {
  return riskGroups.map((value) => ({
    term: {
      RISK_GROUP: value,
    },
  }));
}

export default class RiskDef extends CategoryCondition {
  // static get TYPE(): string { return ConditionType.CATEGORY; }

  static get NAME(): string {
    return CHART.TITLE.RISK_LEVEL;
  }

  // eslint-disable-next-line class-methods-use-this
  public get andOr(): LogicOp {
    return LogicOp.Or;
  }

  public getFilter(): Record<string, unknown> {
    return {
      bool: {
        [this.andOr]: getRiskQuery(this.list),
      },
    };
  }
}

ConditionFactory.register(RiskDef, ConditionName.Risk);
