<template>
  <div class="feedback button-group">
    <div class="feedback d-flex justify-content-center align-items-center">
      <div class="update-dot" v-if="feedbackHintVisable || showFeedbackDot || intervalFeedback"></div>
      <img
        alt="aics"
        title="Feedback"
        src="@/../public/img/navbar/icon_feedback_nav.svg"
        style="cursor: pointer"
        @click="clickFeedbackNav"
      />
    </div>
    <div class="hint-div" v-if="feedbackHintVisable">
      <div class="hint-title">New Feedback feature</div>
      <div class="hint-content mt-10px">Now you can write anything to us. To help Lumos to be better.</div>
      <div class="d-flex justify-content-end mt-20px">
        <div class="dismiss-button mr-10px" @click="clickDismiss">DISMISS</div>
        <div class="try-it-button" @click="clickTryIt">TRY IT</div>
      </div>
    </div>
    <div class="hint-div" v-else-if="intervalFeedback">
      <div class="hint-title">How do you like Lumos?</div>
      <div class="hint-content mt-10px">Rate or give some feedback to help improve our product.</div>
      <div class="d-flex justify-content-end mt-20px">
        <div class="dismiss-button mr-10px" @click="clickIntervalDismiss">DISMISS</div>
        <div class="try-it-button" @click="clickIntervalOK">OK</div>
      </div>
    </div>
    <div>
      <a-modal
        :visible="modalVisable"
        :mask="true"
        :footer="null"
        centered
        :zIndex="9999"
        :destroyOnClose="true"
        :closable="false"
        width="619px"
        :maskClosable="true"
        @cancel="clickMaskToClose"
      >
        <div>
          <div class="title">Rate Your Experience</div>
          <div class="title-content mt-20px mb-15px" v-if="cohortStudyRating">Cohort Study</div>
          <a-radio-group v-model="cohortStudyValue" class="d-flex justify-content-between" v-if="cohortStudyRating">
            <div v-for="value in 11" :key="value" style="width: 28px">
              <b-row no-gutters>
                <b-col class="feedback d-flex justify-content-center align-items-center">
                  <a-radio :value="value - 1"></a-radio>
                </b-col>
              </b-row>
              <b-row no-gutters class="mt-5px">
                <b-col class="feedback d-flex justify-content-center align-items-center">
                  <div class="value-text">{{ value - 1 }}</div>
                </b-col>
              </b-row>
              <b-row no-gutters class="mt-5px">
                <b-col class="feedback d-flex justify-content-center align-items-center">
                  <b-img src="@/assets/images/icon_feedback_0.svg" v-if="value === 1" />
                  <b-img src="@/assets/images/icon_feedback_5.svg" v-if="value === 6" />
                  <b-img src="@/assets/images/icon_feedback_10.svg" v-if="value === 11" />
                </b-col>
              </b-row>
            </div>
          </a-radio-group>
          <div class="title-content mt-30px mb-15px">EMR Search</div>
          <a-radio-group v-model="byRecordValue" class="d-flex justify-content-between">
            <div v-for="value in 11" :key="value" style="width: 28px">
              <b-row no-gutters>
                <b-col class="feedback d-flex justify-content-center align-items-center">
                  <a-radio :value="value - 1"></a-radio>
                </b-col>
              </b-row>
              <b-row no-gutters class="mt-5px">
                <b-col class="feedback d-flex justify-content-center align-items-center">
                  <div class="value-text">{{ value - 1 }}</div>
                </b-col>
              </b-row>
              <b-row no-gutters class="mt-5px">
                <b-col class="feedback d-flex justify-content-center align-items-center">
                  <b-img src="@/assets/images/icon_feedback_0.svg" v-if="value === 1" />
                  <b-img src="@/assets/images/icon_feedback_5.svg" v-if="value === 6" />
                  <b-img src="@/assets/images/icon_feedback_10.svg" v-if="value === 11" />
                </b-col>
              </b-row>
            </div>
          </a-radio-group>
          <div class="title-content mt-20px mb-5px">Feedback</div>
          <b-form-textarea
            v-model="feedbackText"
            placeholder="Comment, suggestion, question..."
            rows="5"
            max-rows="5"
          ></b-form-textarea>
          <div class="d-flex justify-content-between mt-40px">
            <div class="cancel-button" @click="clickCancel">Cancel</div>
            <div class="submit-button" :class="{ disabled: submitDisable }" @click="clickSubmitFeedback">Submit</div>
          </div>
          <div class="d-flex justify-content-center align-items-center mt-20px">
            <a-checkbox @change="onChangeCheck" :defaultChecked="true"> </a-checkbox>
            <div class="check-text ml-15px">Follow up interview with me for more information, if needed.</div>
          </div>
        </div>
      </a-modal>
    </div>
    <div>
      <a-modal
        :visible="endFeedbackModal"
        :mask="true"
        :footer="null"
        centered
        :zIndex="9999"
        :destroyOnClose="true"
        :closable="false"
        width="375px"
        :maskClosable="true"
        @cancel="clickEndFeedbackCancel"
      >
        <b-row no-gutters>
          <b-col class="d-flex justify-content-center align-items-center">
            <b-img src="@/assets/images/icon_feedback_end.svg" />
          </b-col>
        </b-row>
        <b-row no-gutters class="mt-10px">
          <b-col class="d-flex justify-content-center align-items-center">
            <div class="end-feedback-text">Thanks for your feedback</div>
          </b-col>
        </b-row>
        <div></div>
      </a-modal>
    </div>
    <div>
      <a-modal
        :visible="alertVisable"
        :mask="true"
        :footer="null"
        centered
        :zIndex="9999"
        :destroyOnClose="true"
        :closable="false"
        width="495px"
      >
        <b-row no-gutters>
          <b-col class="d-flex justify-content-center align-items-center">
            <div class="modal-submit-title">Submit Feedback?</div>
          </b-col>
        </b-row>
        <b-row no-gutters class="mt-40px">
          <b-col class="d-flex justify-content-between">
            <div class="no-button" @click="closeAlertModal">No</div>
            <div class="modal-submit-button" @click="submitAlertModal">Submit</div>
          </b-col>
        </b-row>
      </a-modal>
    </div>
  </div>
</template>
<script>
import { ClickMixin } from '@/mixins';
import { getUiConfig } from '@/utils/uiConfig';
import { mapState, mapMutations } from '@/store';
import { FEATURES, VUE_EVENT_NAME } from '@/utils/constants';
import dayjs from 'dayjs';

const FEEDBACK_TEXT_WAIT = 2000; // 2 seconds

export default {
  name: 'Feedback',
  mixins: [ClickMixin],
  data() {
    return {
      cohortStudyValue: -1,
      byRecordValue: -1,
      feedbackText: '',
      modalVisable: false,
      infoCheck: true,
      feedbackTextTimeout: null,
      cohortStudyRating: false,
      endFeedbackModal: false,
      alertVisable: false,
      showFeedbackDot: false,
      intervalFeedback: false,
    };
  },
  computed: {
    ...mapState(['feedbackHintVisable']),
    ...mapState('user', ['userId']),
    submitDisable() {
      return this.checkEmptyFeedback() || this.checkTextFeedback();
    },
  },
  async mounted() {
    this.changeCohortStudyEnabled();
    window.addEventListener('uiConfigChange', () => {
      this.changeCohortStudyEnabled();
    });

    this.$root.$on(VUE_EVENT_NAME.GET_INTERVAL_FEEDBACK, this.getIntervalFeedback);

    const firstTime = await this.$api.getIsFirstTime(this.userId, FEATURES.SEND_FEEDBACK_HINT);

    if (firstTime.first_use) {
      this.showFeedbackDot = true;
    }
  },
  destroyed() {
    window.removeEventListener('uiConfigChange', this.changeCohortStudyEnabled());
    this.$root.$off(VUE_EVENT_NAME.GET_INTERVAL_FEEDBACK, this.getIntervalFeedback);
  },
  methods: {
    ...mapMutations(['setFeedbackHintVisable']),
    clickDismiss() {
      this.setFeedbackHintVisable(false);
      this.setUsedFunction(FEATURES.FEEDBACK_HINT);
    },
    clickTryIt() {
      this.setFeedbackHintVisable(false);
      this.modalVisable = true;
      this.setUsedFunction(FEATURES.FEEDBACK_HINT);
    },
    initModalState() {
      this.cohortStudyValue = -1;
      this.byRecordValue = -1;
      this.feedbackText = '';
      this.infoCheck = true;
    },
    clickFeedbackNav() {
      this.initModalState();
      this.modalVisable = true;
    },
    clickCancel() {
      this.modalVisable = false;
    },
    sendSubmitFeedback() {
      this.showFeedbackDot = false;
      this.intervalFeedback = false;
      this.setUsedFunction(FEATURES.SEND_FEEDBACK_HINT);

      this.cohortStudyTrackClick('clickSubmitFeedback', {
        cohortStudyValue: this.cohortStudyValue,
        byRecordValue: this.byRecordValue,
        feedbackText: this.feedbackText,
        infoCheck: this.infoCheck,
      });

      clearTimeout(this.feedbackTextTimeout);
      this.endFeedbackModal = true;
      this.feedbackTextTimeout = setTimeout(() => {
        this.endFeedbackModal = false;
      }, FEEDBACK_TEXT_WAIT);
    },
    clickSubmitFeedback() {
      this.modalVisable = false;
      this.sendSubmitFeedback();
    },
    onChangeCheck(e) {
      this.infoCheck = e.target.checked;
    },
    changeCohortStudyEnabled() {
      this.cohortStudyRating = getUiConfig().SearchForm.cohortStudy;
    },
    clickEndFeedbackCancel() {
      this.endFeedbackModal = false;
    },
    clickMaskToClose() {
      this.modalVisable = false;
      if (
        (this.feedbackText !== '' || this.cohortStudyValue !== -1 || this.byRecordValue !== -1) &&
        !this.checkTextFeedback()
      ) {
        this.alertVisable = true;
      }
    },
    closeAlertModal() {
      this.alertVisable = false;
    },
    submitAlertModal() {
      this.alertVisable = false;
      this.sendSubmitFeedback();
    },
    clickIntervalDismiss() {
      this.intervalFeedback = false;
    },
    clickIntervalOK() {
      this.intervalFeedback = false;
      this.modalVisable = true;
    },
    async getIntervalFeedback() {
      const firstTime = await this.$api.getIsFirstTime(this.userId, FEATURES.SEND_FEEDBACK_HINT);

      this.intervalFeedback =
        firstTime.first_use || !firstTime.timestamp || dayjs().isAfter(dayjs(firstTime.timestamp).add(15, 'day'));
    },
    checkEmptyFeedback() {
      return this.cohortStudyValue === -1 && this.byRecordValue === -1 && this.feedbackText === '';
    },
    checkTextFeedback() {
      return (
        ((this.cohortStudyValue <= 6 && this.cohortStudyValue !== -1) ||
          (this.byRecordValue <= 6 && this.byRecordValue !== -1)) &&
        this.feedbackText === ''
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.feedback {
  width: 24px;
}

.button-group {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}

.hint-div {
  position: absolute;
  padding: 20px;
  width: 350px;
  left: 0px;
  background: #4d8edc;
  border-radius: 5px;
  top: 36px;
  margin-left: -258px;
  z-index: 99;
}

.hint-div::before {
  content: '';
  position: absolute;
  display: block;
  width: 0px;
  left: 271px;
  top: 5px;
  border: 7px solid transparent;
  border-top: 0;
  border-bottom: 7px solid #4d8edc;
  -webkit-transform: translate(-50%, calc(-100% - 5px));
  -ms-transform: translate(-50%, calc(-100% - 5px));
  transform: translate(-50%, calc(-100% - 5px));
}

.hint-title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  color: #ffffff;
}

.hint-content {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 23px;
  color: #ffffff;
}

.dismiss-button {
  height: 35px;
  padding: 8px 16px;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  cursor: pointer;
}

.try-it-button {
  padding: 8px 16px;
  height: 35px;
  background: #ffffff;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #4d8edc;
  cursor: pointer;
}

/deep/ .ant-modal-body {
  padding: 40px;
  background: #ffffff;
  border-radius: 8px;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #334e97;
}

.title-content {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #334e97;
}

.sub-title {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #334e97;
}

/deep/.ant-radio-wrapper {
  margin-right: 0px;
}

.value-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #212121;
}

.cancel-button {
  padding: 16px 0px;
  border: 1px solid #4d8edc;
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #4d8edc;
  width: 260px;
  text-align: center;
  cursor: pointer;
}

.submit-button {
  padding: 16px 0px;
  background: #4d8edc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  width: 260px;
  text-align: center;
  cursor: pointer;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.check-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #212121;
}

.end-feedback-text {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  color: #212121;
}

.update-dot {
  width: 8px;
  height: 8px;
  background: #f5d03d;
  border-radius: 99px;
  position: absolute;
  top: 0px;
  right: 3px;
}

.modal-submit-title {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #333f6b;
}

.no-button {
  width: 197px;
  padding: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #4d8edc;
  border: 1px solid #4d8edc;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
}

.modal-submit-button {
  width: 197px;
  padding: 12px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  background: #4d8edc;
  border-radius: 4px;
  cursor: pointer;
}
</style>
