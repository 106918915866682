import VueCookie from 'vue-cookie';
import { COOKIE, SESSION } from '@/utils/constants';
import { getLocalUIConfig, DefaultConfig } from './hospitals';

export function getUiConfig(withDefault = true) {
  const sessionId = VueCookie.get(COOKIE.SESSION_ID);
  const uiConfig = JSON.parse(window.sessionStorage.getItem(SESSION.UI_CONFIG)) || {};

  if (uiConfig.sessionId === sessionId) {
    return uiConfig;
  }
  if (withDefault) {
    return DefaultConfig;
  }
  return undefined;
}

export function setUiConfig(uiConfig) {
  const sessionId = VueCookie.get(COOKIE.SESSION_ID);
  const localConfig = getLocalUIConfig(uiConfig.hospital);
  // [TODO]: manually merge backend config if needed
  const categories = uiConfig.Analyze.StatisticSummary.medicalOrderCategories;
  localConfig.Analyze.StatisticSummary.medicalOrderCategories = categories || [];
  localConfig.SearchForm.findMyPatient = uiConfig.SearchForm.findMyPatient;
  localConfig.SearchForm.findByDoctor = uiConfig.SearchForm.findByDoctor;
  localConfig.SearchForm.rightClick = uiConfig.SearchForm.rightClick;
  localConfig.SearchForm.doctorId = uiConfig.SearchForm.doctorId;
  localConfig.Analyze.share = uiConfig.Analyze.share;
  if (uiConfig.Analyze.export !== undefined) {
    localConfig.Analyze.export = uiConfig.Analyze.export;
  }
  if (uiConfig.SearchForm.rightClick !== undefined) {
    localConfig.SearchForm.rightClick = uiConfig.SearchForm.rightClick;
  }
  localConfig.SearchForm.time = uiConfig.SearchForm.time;
  localConfig.LabConfig = uiConfig.LabConfig;
  window.sessionStorage.setItem(
    SESSION.UI_CONFIG,
    JSON.stringify({
      ...localConfig,
      sessionId,
      hospital: uiConfig.hospital,
    })
  );

  const uiConfigChangeEvent = new CustomEvent('uiConfigChange', {});
  window.dispatchEvent(uiConfigChangeEvent);
}

export function mapSubUiConfig(path) {
  function uiConfigGetter() {
    let uiConfig = getUiConfig();
    if (path) {
      const keys = path.split('.');
      keys.forEach((key) => {
        uiConfig = uiConfig[key];
      });
    }
    return uiConfig;
  }
  return { uiConfig: uiConfigGetter };
}

export async function fetchUiConfig(api) {
  if (getUiConfig(false) === undefined) {
    setUiConfig(
      await api.uiConfig()
    );
  }
}
