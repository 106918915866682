import { CategoryCondition, ConditionFactory, ConditionName } from './core';

function getPatientSourceQuery(patientSource: string[]) {
  return patientSource.map((value) => {
    let modified_value = value;
    if (modified_value === '無記錄') {
      modified_value = '';
    }

    return {
      term: {
        PATIENT_SOURCE_NAME: modified_value,
      },
    };
  });
}

export default class PatientSourceDef extends CategoryCondition {
  public getFilter(): Record<string, unknown> {
    return {
      bool: {
        [this.andOr]: getPatientSourceQuery(this.list),
      },
    };
  }
}

ConditionFactory.register(PatientSourceDef, ConditionName.PatientSource);
