import { CHART } from '../constants';
import { ConditionName, RangeCondition, ConditionFactory, LogicOp, RangeData } from './core';

function getReOPDEQuery(ranges: RangeData[]): Record<string, unknown>[] {
  return ranges.map((range) => ({
    range: {
      REOPDE_TIMEDELTA: {
        gte: range.start,
        lt: range.end,
      },
    },
  }));
}

export default class ReOPDEDef extends RangeCondition {
  static get NAME(): string {
    return CHART.TITLE.REOPDE;
  }

  public getFilter(): Record<string, unknown> {
    return {
      bool: {
        [LogicOp.Or]: getReOPDEQuery(this.ranges),
      },
    };
  }
}

ConditionFactory.register(ReOPDEDef, ConditionName.ReOPDE);
