<template>
  <div class="timerelation-block" :class="{ 'left-border': eventType !== EventType.FIX_EVENT }" @click.stop="">
    <div v-if="index === 3 && eventType !== EventType.FIX_EVENT">
      <div class="content-text mb-10px">occurred within</div>
      <div class="d-inline-block" style="height: 32px">
        <a-select
          v-model="afterTimeValue"
          default-value="any"
          class="d-inline-block select-container"
          @change="changeTimeRelation('value')"
          style="width: 70px"
          :getPopupContainer="(trigger) => trigger.parentNode"
        >
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option value="any"> any </a-select-option>
          <template v-if="afterTimeUnit === DateUnit.DAYS">
            <a-select-option v-for="(n, key) in getAfterRange" :value="n - 1" :key="key">{{ n - 1 }}</a-select-option>
          </template>
          <template v-if="afterTimeUnit === DateUnit.MONTHS">
            <a-select-option v-for="(n, key) in getAfterRange" :value="n - 1" :key="key">{{ n - 1 }}</a-select-option>
          </template>
          <template v-if="afterTimeUnit === DateUnit.YEARS">
            <a-select-option v-for="(n, key) in getAfterRange" :value="n - 1" :key="key">{{ n - 1 }}</a-select-option>
          </template>
        </a-select>
        <a-select
          v-model="afterTimeUnit"
          :default-value="DateUnit.DAYS"
          class="d-inline-block select-container"
          @change="changeTimeRelation('unit')"
          :getPopupContainer="(trigger) => trigger.parentNode"
        >
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option v-for="option in timeUnitList" :value="option" :key="option">
            {{ option }}
          </a-select-option>
        </a-select>
      </div>
      <div class="d-inline-block content-text">
        {{ `after ${eventName}` }}
      </div>
    </div>
    <div v-else>
      <div class="d-inline-block content-text" v-if="index === 1 && eventType === EventType.FIX_EVENT">
        {{ `${SearchStateObject.indexEvent.name} occurred within` }}
      </div>
      <div class="d-inline-block content-text" v-if="index === 3 && eventType === EventType.FIX_EVENT">
        {{ `${SearchStateObject.eventList[1].name} occurred` }}
      </div>
      <div class="content-text mb-10px" v-if="eventType !== EventType.FIX_EVENT">occurred within</div>
      <div :class="{ 'ml-5px': eventType === EventType.FIX_EVENT }" class="d-inline-block" style="height: 32px">
        <a-select
          v-model="beforeTimeValue"
          default-value="any"
          class="d-inline-block select-container"
          @change="changeTimeRelation('value')"
          style="width: 70px"
          :getPopupContainer="(trigger) => trigger.parentNode"
        >
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option value="any"> any </a-select-option>
          <template v-if="beforeTimeUnit === DateUnit.DAYS">
            <a-select-option v-for="(n, key) in getBeforeRange" :value="n - 1" :key="key">{{ n - 1 }}</a-select-option>
          </template>
          <template v-if="beforeTimeUnit === DateUnit.MONTHS">
            <a-select-option v-for="(n, key) in getBeforeRange" :value="n - 1" :key="key">{{ n - 1 }}</a-select-option>
          </template>
          <template v-if="beforeTimeUnit === DateUnit.YEARS">
            <a-select-option v-for="(n, key) in getBeforeRange" :value="n - 1" :key="key">{{ n - 1 }}</a-select-option>
          </template>
        </a-select>
        <a-select
          v-model="beforeTimeUnit"
          :default-value="DateUnit.DAYS"
          class="d-inline-block select-container"
          @change="changeTimeRelation('unit')"
          :getPopupContainer="(trigger) => trigger.parentNode"
        >
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option v-for="option in timeUnitList" :value="option" :key="option">
            {{ option }}
          </a-select-option>
        </a-select>
      </div>
      <div class="d-inline-block content-text" v-if="index === 1 && eventType === EventType.FIX_EVENT">
        {{ `after ${SearchStateObject.eventList[0].name}` }}
      </div>
      <div class="d-inline-block content-text mb-10px" v-if="eventType !== EventType.FIX_EVENT">
        {{ `before ${eventName}` }}
      </div>
      <div
        v-if="eventType !== EventType.FIX_EVENT"
        style="display: inline-flex; position: relative"
        class="d-inline-block mb-10px"
      >
        <div class="d-inline-block content-text">and</div>
      </div>
      <div
        v-if="(index === 3 && eventType === EventType.FIX_EVENT) || eventType !== EventType.FIX_EVENT"
        :class="{ 'mb-10px': eventType !== EventType.FIX_EVENT }"
        class="d-inline-block"
      >
        <a-select
          v-model="afterTimeValue"
          default-value="any"
          class="d-inline-block select-container"
          @change="changeTimeRelation('value')"
          style="width: 70px"
          :getPopupContainer="(trigger) => trigger.parentNode"
        >
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option value="any"> any </a-select-option>
          <template v-if="afterTimeUnit === DateUnit.DAYS">
            <a-select-option v-for="(n, key) in getAfterRange" :value="n - 1" :key="key">{{ n - 1 }}</a-select-option>
          </template>
          <template v-if="afterTimeUnit === DateUnit.MONTHS">
            <a-select-option v-for="(n, key) in getAfterRange" :value="n - 1" :key="key">{{ n - 1 }}</a-select-option>
          </template>
          <template v-if="afterTimeUnit === DateUnit.YEARS">
            <a-select-option v-for="(n, key) in getAfterRange" :value="n - 1" :key="key">{{ n - 1 }}</a-select-option>
          </template>
        </a-select>
        <a-select
          v-model="afterTimeUnit"
          :default-value="DateUnit.DAYS"
          class="d-inline-block select-container"
          @change="changeTimeRelation('unit')"
          :getPopupContainer="(trigger) => trigger.parentNode"
        >
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option v-for="option in timeUnitList" :value="option" :key="option">
            {{ option }}
          </a-select-option>
        </a-select>
      </div>
      <div
        class="d-inline-block content-text"
        v-if="(index === 3 && eventType === EventType.FIX_EVENT) || eventType !== EventType.FIX_EVENT"
        :class="{ 'mb-10px': eventType !== EventType.FIX_EVENT }"
      >
        {{ `after ${eventName}` }}
      </div>
    </div>
  </div>
</template>
<script>
import { ClickMixin, EventUpdateMixin, EventSearchMixin } from '@/mixins';
import { DateUnit, EventType } from '@/utils/conditions/core/Interface';
import { getUiConfig } from '@/utils/uiConfig';
import { getTimeRangeInYear } from '@/utils/conditions/TimeDef';
import { EVENT } from '@/utils/constants';

export default {
  name: 'EventTimeRelation',
  mixins: [ClickMixin, EventUpdateMixin, EventSearchMixin],
  data() {
    return {
      beforeTimeValue: 'any',
      beforeTimeUnit: DateUnit.DAYS,
      afterTimeValue: 'any',
      afterTimeUnit: DateUnit.DAYS,
      timeUnitList: [DateUnit.DAYS, DateUnit.MONTHS, DateUnit.YEARS],
      DateUnit,
      EventType,
    };
  },
  props: {
    index: {
      type: Number,
      default: 1,
    },
    eventType: {
      type: String,
      default: EventType.FIX_EVENT,
    },
    path: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    eventName() {
      if (this.index === 1 && this.eventType === EventType.FIX_EVENT) {
        return this.getEventData(2).name;
      } else if (this.eventType !== EventType.FIX_EVENT && this.path.includes('covariate')) {
        return EVENT.DEFAULT_NAME.INTERVENTION;
      } else if (this.eventType !== EventType.FIX_EVENT && this.path.includes('outcome')) {
        return EVENT.DEFAULT_NAME.INTERVENTION_DATE;
      }
      return this.getEventData(this.index).name;
    },
    getBeforeRange() {
      switch (this.beforeTimeUnit) {
        case DateUnit.DAYS:
          return 29;
        case DateUnit.MONTHS:
          return 12;
        case DateUnit.YEARS:
          return this.formatTime() + 1;
        default:
          throw new Error('Get time range error.');
      }
    },
    getAfterRange() {
      switch (this.afterTimeUnit) {
        case DateUnit.DAYS:
          return 29;
        case DateUnit.MONTHS:
          return 12;
        case DateUnit.YEARS:
          return this.formatTime() + 1;
        default:
          throw new Error('Get time range error.');
      }
    },
    storeRelation() {
      return this.currentEvent.relation;
    },
  },
  watch: {
    storeRelation() {
      this.restoreFromStore();
    },
  },
  mounted() {
    this.restoreFromStore();
  },
  methods: {
    restoreFromStore() {
      const { relation } = this.getEventDataByPath(this.path);
      this.beforeTimeValue = relation.beforeValue;
      this.beforeTimeUnit = relation.beforeUnit;
      this.afterTimeValue = relation.afterValue;
      this.afterTimeUnit = relation.afterUnit;
    },
    formatTime() {
      const timeRange = getTimeRangeInYear(getUiConfig().SearchForm.time);
      return timeRange.end - timeRange.start;
    },
    changeTimeRelation(state, beforeOrAfter = 'before') {
      const timeValue = beforeOrAfter === 'before' ? this.beforeTimeValue : this.afterTimeValue;
      let range = [];
      const getRange = beforeOrAfter === 'before' ? this.getBeforeRange : this.getafterRange;
      range = Array.from(Array(getRange), (_, i) => i + 1);
      if (state === 'unit' && !range.includes(timeValue)) {
        if (beforeOrAfter === 'before') {
          this.beforeTimeValue = 'any';
        } else {
          this.afterTimeValue = 'any';
        }
      }
      this.updateTimeRelation();

      this.cohortStudyTrackClick('changeTimeRelationValue', {
        state,
        beforeTimeUnit: this.beforeTimeUnit,
        beforeTimeValue: this.beforeTimeValue,
        afterTimeUnit: this.afterTimeUnit,
        afterTimeValue: this.afterTimeValue,
        index: this.index,
        path: this.path,
      });
    },
    updateTimeRelation() {
      const currentEventRelation = this.getEventDataByPath(this.path).relation;
      if (this.eventType === EventType.FIX_EVENT) {
        switch (this.index) {
          case 1: {
            currentEventRelation.beforeValue = this.beforeTimeValue;
            currentEventRelation.beforeUnit = this.beforeTimeUnit;
            break;
          }
          case 3: {
            currentEventRelation.afterValue = this.afterTimeValue;
            currentEventRelation.afterUnit = this.afterTimeUnit;
            break;
          }
          default:
            break;
        }
      } else {
        currentEventRelation.beforeValue = this.beforeTimeValue;
        currentEventRelation.beforeUnit = this.beforeTimeUnit;
        currentEventRelation.afterValue = this.afterTimeValue;
        currentEventRelation.afterUnit = this.afterTimeUnit;
      }
      this.updateEventWithPath(
        {
          relation: currentEventRelation,
        },
        this.path
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.title-text {
  height: 20px;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  color: #333333;
}

.content-text {
  height: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 20px;
  color: #333333;
  margin-left: 10px;
}

.select-container {
  width: auto;
  height: 20px;
  margin-left: 10px;
}

/deep/ .ant-select-selection--single {
  border-radius: 30px;
}

/deep/ .ant-select-selection-selected-value {
  font-weight: normal;
  color: #0f4895;
  font-size: 18px;
}

/deep/ .ant-select-arrow {
  color: #0f4895;
}
</style>
