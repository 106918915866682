function hasLabCondition(body) {
  const labConditions = body.condition.filter((covCondition) => {
    return covCondition.name === 'Lab';
  });
  return labConditions.length > 0;
}

export const postProcessEvent = (body, path) => {
  if (path.includes('covariate') && 'condition' in body) {
    if (hasLabCondition(body)) {
      return { ...body, has_extend: true };
    } else {
      return { ...body, has_extend: false };
    }
  }
  return body;
};

export default {
  postProcessEvent,
};
