import { FEEDBACK } from '@/utils/constants';

export default {
  data() {
    return {
      value: [],
      options: [],
      andOr: 'should',
    };
  },
  props: {
    conditions: {
      type: Array,
    },
    codeCondition: {
      type: Object,
    },
    placeholder: {
      type: String,
    },
    fetchOptions: {
      type: Function,
    },
    hint: {
      type: String,
    },
    feedbackField: {
      type: String,
    },
    dynamicCount: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleAndOrChange(andOrValue) {
      this.andOr = andOrValue;
      this.handleChange();
    },
    handleFocus() {
      this.options = [];
      this.$emit('onFocus');
    },
    setLog() {
      this.$api.setLogQueryInfo({
        type: FEEDBACK.QUERY_TYPE.DISTINCT_COUNT_AGGREGATE,
        conditions: this.$store.getters['condition/currentConditions'],
      });
    },
    getDropdownMenuStyle() {
      // 56px banner + 60px tabs + 40px (2 x 20px padding) + 44px search bar.
      // Min dropdown height 100px.
      const calcheight = Math.max((window.innerHeight - 56 - 60 - 40 - 44) / 3, 100);
      return {
        // Setting max dropdown height to 280px.
        'max-height': `${Math.min(calcheight, 280)}px`,
      };
    },
    addOption(option, init) {
      const newOption = {
        value: option.desc,
        key: option.key,
        label: <div title={option.desc}>{option.label}</div>,
        title: option.desc,
        type: option.type,
        desc: option.desc,
        code: option.key,
      };
      if (this.value.some((item) => item.key === option.key)) {
        return;
      }
      this.value.push(newOption);
      this.handleChange(init);
    },
  },
};
