import 'core-js/stable';
import './utils';
import './plugins';
import './utils/conditions';
import Vue from 'vue';
import AsyncComputed from 'vue-async-computed';
import Antd from 'ant-design-vue';
import VueCookie from 'vue-cookie';
import vueHeadful from 'vue-headful';
import VueClipboard from 'vue-clipboard2';
import VueAgile from 'vue-agile';
import Icon from '@/components/Icon/Icon.vue';
import App from './App.vue';
import router from './router';
import { store } from './store';
import i18n from './i18n';

import 'ant-design-vue/dist/antd.css';

Vue.config.productionTip = false;

Vue.component('vue-headful', vueHeadful);
Vue.component('Icon', Icon);
Vue.use(AsyncComputed, {
  useRawError: true,
  errorHandler(err, _, stack) {
    console.error(err);
    console.error(stack);
  },
});
Vue.use(Antd);
Vue.use(VueCookie);
Vue.use(VueClipboard);
Vue.use(VueAgile);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
