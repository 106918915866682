import { ConditionTag, ConditionName, SearchType } from '../../conditions/core';
import Condition from '../../conditions/core/Condition';

export default class ConditionArray extends Array<Condition> {
  constructor(args: Array<Condition> | number) {
    if (Array.isArray(args)) {
      super(...args);
    } else if (args) {
      super(args);
    } else {
      super(0);
    }
  }

  public with(...filters: (ConditionTag | ConditionName)[]): ConditionArray {
    return new ConditionArray(
      super.filter((cond) =>
        filters.every((filter) => cond.tags.includes(filter as ConditionTag) || cond.name === filter)
      )
    );
  }

  public without(...filters: (ConditionTag | ConditionName)[]): ConditionArray {
    return new ConditionArray(
      super.filter(
        (cond) => !filters.some((filter) => cond.tags.includes(filter as ConditionTag) || cond.name === filter)
      )
    );
  }

  public select(conditionFunction: (cond: Condition) => boolean): ConditionArray {
    return new ConditionArray(super.filter(conditionFunction));
  }

  public get additions(): ConditionArray {
    return this.with(SearchType.Additional);
  }

  public get raws(): ConditionArray {
    return this.with(SearchType.Raw);
  }
}
