<template>
  <div>
    <div class="fixed-div" ref="container">
      <b-row no-gutters class="advance-search margin-20">
        <b-col>
          <b-row no-gutters>
            <b-col class="name-title" @click="clickCollapse">
              <b-img src="@/assets/images/icon_collapse.svg" class="collapse-icon" v-if="listVisible" />
              <b-img src="@/assets/images/icon_expand.svg" class="collapse-icon" v-if="!listVisible" />
              {{ tab.name }}
              <span class="content-text" style="margin-left: 20px">about</span>
              <span class="content-text">{{ formatedTemplateNumber(tab.patientCount) }}</span>
              <span class="content-text">patients</span>
            </b-col>
            <b-col>
              <div class="button-group">
                <button class="patient-list-btn" @click="goToPatientList">
                  <span class="patient-list-text">Patient List</span>
                </button>
                <ExportModal
                  v-if="uiConfig.export"
                  :uniqueIndex="uniqueIndex"
                  :exportOptions="exportOptions"
                  :isAdvance="true"
                />
              </div>
            </b-col>
          </b-row>
          <b-row no-gutters>
            <b-col>
              <b-collapse v-model="listVisible">
                <b-row no-gutters>
                  <b-col>
                    <b-row no-gutters>
                      <b-col>
                        <b-row no-gutters class="desc-container row-margin">
                          <b-col>
                            <TabSearchDescription :uniqueIndex="uniqueIndex" />
                          </b-col>
                          <b-col class="d-flex justify-content-end">
                            <TimeDescription :uniqueIndex="uniqueIndex" />
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-collapse>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <div class="padding-gray"></div>
      <div
        class="d-flex align-items-center margin-20 statistic-text"
        :class="{
          'no-shadow': scrollTop === 0,
        }"
      >
        <div class="advance-statisticsummary-title">
          {{
            `Statistics of ${statisPeriodDescribe}
      after ${indexEventName}`
          }}
        </div>
        <a-switch @change="onChange" style="margin-left: 10px" />
        <div class="ai-sort-text">AI sorting</div>
      </div>
    </div>
    <div class="advance-statisticsummary margin-20">
      <GridFlow :cells="charts" :columns="2">
        <template #cell="{ config: config }">
          <Chart :config="config" :expanded="false" :uniqueIndex="uniqueIndex" />
        </template>
      </GridFlow>
    </div>
  </div>
</template>
<script>
import GridFlow from '@/components/layout/GridFlow.vue';
import { ClickMixin, PatientNumberMixin } from '@/mixins';
import { transformTimeUnit } from '@/utils/util';
import ExportModal from '@/components/modals/ExportModal.vue';
import TabSearchDescription from '@/components/advanceSearch/status/TabSearchDescription.vue';
import TimeDescription from '@/components/advanceSearch/status/TimeDescription.vue';

import { mapSubUiConfig } from '@/utils/uiConfig';
import { DOWNLOAD } from '@/utils/constants';
import { ChartKey } from '../../charts/core/Interface';
import Chart from '../../charts/advance/core/Chart.vue';
import {
  AdvAgeConfig,
  AdvGenderConfig,
  AdvICD10Config,
  AdvICD9Config,
  AdvSurvivalConfig,
  AdvMedConfig,
  AdvTrendConfig,
} from '../../charts/advance';
import { mapState, mapMutations } from '../../../store';

export default {
  name: 'AdvanceStatisticSummary',
  mixins: [ClickMixin, PatientNumberMixin],
  components: {
    GridFlow,
    Chart,
    ExportModal,
    TabSearchDescription,
    TimeDescription,
  },
  props: {
    uniqueIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      exportOptions: [DOWNLOAD.EVENT_PATIENT_LIST],
      listVisible: true,
      marginHeight: 0,
      CHART_CONFIG: {
        [ChartKey.AdvAge]: new AdvAgeConfig(),
        [ChartKey.AdvGender]: new AdvGenderConfig(),
        [ChartKey.AdvICD10]: new AdvICD10Config(),
        [ChartKey.AdvICD9]: new AdvICD9Config(),
        [ChartKey.AdvMedication]: new AdvMedConfig(),
        [ChartKey.AdvSurvival]: new AdvSurvivalConfig(),
        [ChartKey.AdvTrend]: new AdvTrendConfig(),
      },
    };
  },
  watch: {
    scrollTop() {
      this.listVisible = this.scrollTop === 0;
    },
  },
  computed: {
    ...mapState('byEventSession', ['tabs']),
    ...mapState(['scrollTop']),
    ...mapSubUiConfig('Analyze'),
    statisPeriodDescribe() {
      return transformTimeUnit(
        `${this.tabs[this.uniqueIndex].TimeSelectObject.afterValue} ${
          this.tabs[this.uniqueIndex].TimeSelectObject.afterUnit
        }`
      );
    },
    indexEventName() {
      return this.tabs[this.uniqueIndex].SearchStateObject.indexEvent.name;
    },
    checkNullCondition() {
      return this.tabs[this.uniqueIndex].SearchStateObject.indexEvent.condition.length !== 0;
    },
    charts() {
      if (this.checkNullCondition) {
        return this.uiConfig.AdvanceStatisticSummary.all_charts.map((chartType) => this.CHART_CONFIG[chartType]);
      }
      return this.uiConfig.AdvanceStatisticSummary.all_charts
        .filter((chart) => chart !== ChartKey.AdvSurvival)
        .map((chartType) => this.CHART_CONFIG[chartType]);
    },
    hasShaddow() {
      if (this.scrollTop) {
        return {
          'box-shadow': 'rgb(117 117 117 / 50%) 0px 4px 10px',
        };
      }
      return {
        'box-shadow': 'none',
      };
    },
    tab() {
      return this.tabs[this.uniqueIndex];
    },
  },
  methods: {
    ...mapMutations('byEventSession', ['updateTab']),
    onChange(checked) {
      this.updateTab({
        index: this.uniqueIndex,
        sortChartByAI: checked,
      });
      this.advanceTrackClick('onChange', {
        tabIndex: this.uniqueIndex,
        sortChartByAI: checked,
      });
    },
    clickCollapse() {
      this.listVisible = !this.listVisible;
      this.advanceTrackClick('clickCollapse', {
        listVisible: this.listVisible,
        tabIndex: this.uniqueIndex,
      });
    },
    goToPatientList() {
      this.updateTab({
        index: this.uniqueIndex,
        state: 'patientList',
      });
      this.advanceTrackClick('clickGoToPatientList', {
        tabIndex: this.uniqueIndex,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.advance-statisticsummary {
  background: #ffffff;
  border-radius: 5px;
  padding: 20px 20px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.advance-statisticsummary-title {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: #0f4895;
}

.ai-sort-text {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #333f6b;
  margin-left: 5px;
}

.left-desc {
  max-width: 200px;
  width: 200px;
  min-width: 200px;
}

.row-margin {
  margin-top: 20px;
}

.desc-container {
  border: 1px solid #bdbdbd;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 20px;
}

.advance-search {
  background-color: #fff;
  border-radius: 5px;
  padding: 20px 20px;
}

.cohort-text {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: #333f6b;
}

.content-text {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
  color: #333333;
  margin-left: 5px;
}

.count-text {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 45px;
  color: #333f6b;
  margin-left: 5px;
}

.submit-btn {
  width: 138px;
  height: 38px;
  border-radius: 5px;
  background-color: #333f6b;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  border-width: 0 0 0 0;
  margin-left: 50px;
}

.time-period-text {
  height: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #333f6b;
}
.time-period-value-text {
  height: 18px;
  left: 98px;
  top: 1px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #333333;
  margin-left: 10px;
}

.event-name-text {
  height: 23px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #333f6b;
}

.name-title {
  height: 30px;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #333f6b;
  margin-bottom: 10px;
  cursor: pointer;
}

.icon-edit {
  cursor: pointer;
  margin-left: 5px;
}

.index-event-text {
  height: 18px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #828282;
  text-align: center;
  margin-top: 5px;
}

.collapse-icon {
  padding-right: 5px;
}

.fixed-div {
  z-index: 1000;
  width: 100%;
  min-width: 1280px;
  top: 0px;
}

.margin-20 {
  margin: 0px 20px;
}

.padding-gray {
  background: #e6e9ef;
  height: 20px;
}

.statistic-text {
  padding: 20px 20px;
  background-color: #fff;
  box-shadow: 0px 4px 10px rgba(117, 117, 117, 0.5);
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.no-shadow {
  box-shadow: none;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.patient-list-btn {
  width: 150px;
  height: 38px;
  border-radius: 6px;
  border: solid 1px #333f6b;
  background-color: white;
  outline: 0;
  margin-right: 10px;
}

.patient-list-text {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333f6b;
}

.button-group {
  padding: 15px 20px 0px 0px;
  display: flex;
  justify-content: flex-end;
  z-index: 1001 !important;
}
</style>
