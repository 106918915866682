export default {
  conceptName: 'Anti-TNF Biologics',
  conceptType: 'include',
  name: 'Medication',
  tags: ['Basic', 'Raw'],
  andOr: 'should',
  list: [
    {
      code: 'IADAL04',
      label: 'IADAL04',
      description: 'Adalimumab 40mg/0.4ml/syringe(Humira) 冷藏',
      chineseDescription: 'Adalimumab 40mg/0.4ml/syringe(Humira) 冷藏',
      value: 'IADAL04',
      type: 'med',
      patientNum: 90,
      recordNum: 1191,
      field: 'med',
      ingredient: 'adalimumab',
      atcCode: 'L04AB04',
      time: 1646619306268,
    },
    {
      code: 'IINFL',
      label: 'IINFL',
      description: 'Infliximab 100mg/vial(Remicade)冷藏',
      chineseDescription: 'Infliximab 100mg/vial(Remicade)冷藏',
      value: 'IINFL',
      type: 'med',
      patientNum: 1,
      recordNum: 2,
      field: 'med',
      ingredient: 'infliximab',
      atcCode: 'L04AB02',
      time: 1646619318417,
    },
    {
      code: 'IGOLIM',
      label: 'IGOLIM',
      description: 'Golimumab 50mg/0.5ml/vial(Simponi)冷藏',
      chineseDescription: 'Golimumab 50mg/0.5ml/vial(Simponi)冷藏',
      value: 'IGOLIM',
      type: 'med',
      patientNum: 34,
      recordNum: 546,
      field: 'med',
      ingredient: 'golimumab',
      atcCode: 'L04AB06',
      time: 1646619324065,
    },
  ],
  diagnosisRange: false,
  index: 1,
};
