<template>
  <b-modal
    :visible="data !== null"
    centered
    no-close-on-backdrop
    no-close-on-esc
    hide-header
    hide-footer
    hide-header-close
  >
    <b-img src="@/assets/images/icon_close.svg" class="close-icon" @click="closeModal" />
    <b-row no-gutters>
      <b-col>
        <b-row no-gutters>
          <b-col class="modal-title-text">{{ `Maximum ${UICONFIG.MAX_NUM_RESULT_TABS} results reached` }}</b-col>
        </b-row>
        <b-row no-gutters>
          <b-col class="modal-content-text">
            {{ `You have reached the limit of ${UICONFIG.MAX_NUM_RESULT_TABS} active search results.` }}
            <br />Please close an existing tab to start a new search.
          </b-col>
        </b-row>
        <b-row no-gutters>
          <b-col class="d-flex justify-content-center button-col">
            <b-button variant="outline-primary" class="button-close" @click="closeModal">OK</b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>
import { UpdateMixin, ClickMixin } from '@/mixins';
import { UICONFIG, VUE_EVENT_NAME } from '@/utils/constants';
import { mapState } from '../../store';

export default {
  name: 'TabLimitation',
  mixins: [UpdateMixin, ClickMixin],
  computed: {
    ...mapState('session', ['tabs']),
  },
  data() {
    return {
      data: null,
      UICONFIG,
    };
  },
  mounted() {
    this.$root.$on(VUE_EVENT_NAME.ADD_TAB_LIMITATION, (data) => {
      this.data = data;
    });
  },
  destroyed() {
    this.$root.$off(VUE_EVENT_NAME.ADD_TAB_LIMITATION);
  },
  methods: {
    closeModal() {
      this.data = null;
      this.trackClick('closeModal');
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .modal-content {
  width: 475px;
  height: 217px;
  border-radius: 6px;
  background-color: #ffffff;
  border: 1px solid white;
}

/deep/ .modal-body {
  padding: 30px 30px 30px 30px;
}

.modal-title-text {
  height: 23px;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333f6b;
}

.close-icon {
  width: 24px;
  height: 24px;
  position: absolute;
  margin: -10px 0px 0px 401px;
  cursor: pointer;
}

.modal-content-text {
  height: 40px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333f6b;
  margin-top: 20px;
}

.button-col {
  margin-top: 30px;
}

.button-cancel,
.button-cancel:hover,
.button-cancel:focus,
.button-cancel:active,
.button-cancel:active:visited {
  margin-right: 20px;
  width: 168px;
  height: 44px;
  border-radius: 6px;
  border: solid 1px #333f6b !important;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333f6b !important;
  background-color: #ffffff !important;
}

.button-close,
.button-close:hover,
.button-close:focus,
.button-close:active,
.button-close:active:visited {
  width: 415px;
  height: 44px;
  border-radius: 6px;
  border: solid 1px #333f6b !important;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff !important;
  background-color: #333f6b !important;
}
</style>
