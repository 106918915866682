import { mapActions } from '../store';

export default {
  methods: {
    ...mapActions('advancePortal', ['updateFirstEvent', 'updateThirdEvent', 'updateIndexEvent', 'updateWithPath']),
    updateEventWithPath(body, path) {
      this.updateWithPath({ api: this.$api, path, ...body });
    },
  },
};
