






import { Component, Prop, Vue } from 'vue-property-decorator';

// import all svg files under './svg' folder by webpack require context
const importAll = (r: { keys: Function }) => r.keys().forEach(r);
importAll(require.context('./svg', true, /\.svg$/));

@Component
export default class Icon extends Vue {
  @Prop(String) readonly icon!: string;
  @Prop({ type: String, default: 'inherit' }) readonly color: string | undefined;
  @Prop(String) readonly variant: string | undefined;
  @Prop(Number) readonly size: number | undefined;

  get colorStyle() {
    return `color: ${this.color}`;
  }

  get variantClass() {
    return `text-${this.variant}`;
  }
}
