<template>
  <div
    @click.stop="addDiagnosisRangeConstraint"
    class="advance-diagnosisRange-adder"
    :class="{ disabled: this.constraintIndex !== -1 }"
    v-if="hasDiagnosisCondition"
  >
    + PRIMARY DIAGNOSIS
  </div>
</template>
<script>
import { findIndex, cloneDeep } from 'lodash';
import { EventDiagnosisRange } from '@/utils/conditions/core/Interface';
import { AdvanceFilterMixin } from '@/mixins';
import ConstraintType from '@/utils/conditions/core/advance/constraint/Interface';

export default {
  name: 'AdvanceDiagnosisRange',
  mixins: [AdvanceFilterMixin],
  computed: {
    constraintIndex() {
      return findIndex(this.currentEvent.constraintList, {
        constraintType: ConstraintType.DiagnosisRangeConstraint,
      });
    },
    hasDiagnosisCondition() {
      return this.currentEvent.condition.find((item) => item.name === 'Diagnosis');
    },
  },
  methods: {
    addDiagnosisRangeConstraint() {
      const diagnosisRangeConstraint = {
        diagnosisRange: EventDiagnosisRange.MAIN,
      };
      const event = cloneDeep(this.currentEvent);

      if (this.constraintIndex === -1) {
        event.constraintList.push({
          constraintType: ConstraintType.DiagnosisRangeConstraint,
          ...diagnosisRangeConstraint,
        });
      }
      const body = {
        constraintList: event.constraintList,
      };
      this.updateEventWithPath(body, this.path);
      this.setCurrentMenu(this.path, 'DiagnosisRangeConstraint');
      this.advanceTrackClick('openDiagnosisRangeFilter', {
        path: this.path,
        index: this.index,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.advance-diagnosisRange-adder {
  padding: 5px 15px;
  border: 1px solid #4a94d2;
  background: #ffffff;
  border-radius: 53px;
  font-size: 16px;
  line-height: 20px;
  color: #4a94d2;
  margin-left: 10px;
  cursor: pointer;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
