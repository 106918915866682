import TailTimeRelationEvent from '@/utils/conditions/core/advance/TailTimeRelationEvent';
import TimePeriod from '@/utils/conditions/core/advance/TimePeriod';
import { ITailTimeRelationEvent } from '@/utils/conditions/core/Interface';
import TimeEventQuery from './TimeEventQuery';

class InclusionQuery {
  private inclusions: TailTimeRelationEvent[];

  private timePeriod: TimePeriod;

  constructor(inclusions: ITailTimeRelationEvent[], timePeriod: TimePeriod) {
    this.timePeriod = timePeriod;
    this.inclusions = inclusions
      .filter((event) => event.condition.length > 0)
      .map((event: ITailTimeRelationEvent) => new TailTimeRelationEvent(event));
  }

  getQuery() {
    return this.inclusions.map((event: TailTimeRelationEvent) => new TimeEventQuery(event, this.timePeriod).getQuery());
  }
}

export default InclusionQuery;
