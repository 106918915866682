<template>
  <div class="outcome-group" :class="{ 'search-group': this.flowType[0] !== 'session' }">
    <b-row no-gutters>
      <b-col>
        <div class="group-title">Outcome</div>
      </b-col>
    </b-row>
    <b-row no-gutters>
      <b-col>
        <div class="mt-25px" v-for="(event, key) in currentEvent.inclusionList" :key="key">
          <div class="d-flex justify-content-between align-items-end mb-10px">
            <div class="d-flex align-items-center">
              <template v-if="isEditOutcomeName(key)">
                <a-tooltip
                  placement="bottom"
                  overlayClassName="edit-name-error-tooltip"
                  :visible="hasSpecialChar(SPECIAL_CHARS.COHORT_NAME, outcomeName)"
                  :get-popup-container="getPopupContainer"
                >
                  <template slot="title">
                    <div>Analysis name can’t contain any of the following charecter</div>
                    <div>[ ] : * ? / \</div>
                  </template>
                  <div class="d-flex align-items-center">
                    <input
                      v-model="outcomeName"
                      ref="editOutcomeName"
                      v-click-outside="closeEditOutcomeName"
                      @keyup.enter="closeEditOutcomeName"
                    />
                  </div>
                </a-tooltip>
              </template>
              <template v-else>
                <a-tooltip
                  placement="bottom"
                  overlayClassName="outcome-name-error-tooltip"
                  :visible="getNameError === `outcome-${key}` || hasSpecialChar(SPECIAL_CHARS.COHORT_NAME, event.name)"
                  :get-popup-container="getPopupContainer"
                >
                  <template slot="title">
                    <div v-if="getNameError === `outcome-${key}`">Dulicate name</div>
                    <div v-else-if="hasSpecialChar(SPECIAL_CHARS.COHORT_NAME, event.name)">
                      <div>Analysis name can’t contain any of the following charecter</div>
                      <div>[ ] : * ? / \</div>
                    </div>
                  </template>
                  <div class="d-flex align-items-center">
                    <div
                      class="second-title"
                      style="cursor: pointer"
                      @click.stop="clickEditOutcomeName(key)"
                      :class="{ 'name-error': getNameError === `covariate-${key}` }"
                    >
                      {{ event.name }}
                    </div>
                    <b-img
                      src="@/assets/images/icon_study_edit.svg"
                      class="mid-edit ml-5px"
                      @click.stop="clickEditOutcomeName(key)"
                    />
                  </div>
                </a-tooltip>
              </template>
            </div>
            <div class="d-flex">
              <div class="d-flex align-items-center clear-button ml-10px" @click.stop="clearInclusionEvent(key)">
                <b-img src="@/assets/images/icon_clear_event.svg" />
                <div class="clear-text ml-5px">Clear</div>
              </div>
            </div>
          </div>
          <div class="event-block">
            <EventContainer
              :index="3"
              :eventType="EventType.INCLUSION"
              :event="event"
              :path="path.concat(['inclusionList', key])"
              :ref="`inclusion${key}`"
            />
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row no-gutters>
      <b-col>
        <div class="hint-text mt-20px">*People lost to follow-up would be censored in survival analysis</div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import EventContainer from '@/components/byEvent/event/EventContainer.vue';
import { EventSearchMixin, EventUpdateMixin, ClickMixin } from '@/mixins';
import { EventType } from '@/utils/conditions/core/Interface';
import ClickOutside from 'vue-click-outside';
import { SPECIAL_CHARS } from '@/utils/constants';
import { hasSpecialChar, getPopupContainer } from '@/utils/util';

export default {
  name: 'OutcomeGroup',
  directives: {
    ClickOutside,
  },
  components: {
    EventContainer,
  },
  mixins: [EventSearchMixin, EventUpdateMixin, ClickMixin],
  props: {
    flowType: {
      type: Array,
      default: () => ['outcome'],
    },
  },
  data() {
    return {
      path: this.flowType,
      EventType,
      outcomeName: '',
      editOutcomeIndex: -1,
      SPECIAL_CHARS,
    };
  },
  watch: {
    getCurrentMenu() {
      if (this.editOutcomeIndex > -1 && !this.isEditOutcomeName(this.editOutcomeIndex)) {
        this.closeEditOutcomeName();
      }
    },
  },
  methods: {
    hasSpecialChar,
    getPopupContainer,
    clearInclusionEvent(index) {
      this.$refs[`inclusion${index}`][0].setDefaultState();

      this.cohortStudyTrackClick('clearInclusionEvent');
    },
    closeEditOutcomeName() {
      const body = {
        name: this.outcomeName,
      };

      this.updateEventWithPath(body, this.path.concat(['inclusionList', `${this.editOutcomeName}`]));
      this.checkNameErrorWithTag(this.outcomeName, `outcome-${this.editOutcomeName}`);

      this.setCurrentMenu(this.path, '');
      this.editOutcomeIndex = -1;
    },
    clickEditOutcomeName(index) {
      this.editOutcomeIndex = index;
      this.outcomeName = this.currentEvent.inclusionList[index].name;
      this.editOutcomeName = index;
      this.setCurrentMenu(this.path, `editOutcomeName${index}`);
      this.$nextTick(() => {
        this.$refs.editOutcomeName[0].select();
      });

      this.cohortStudyTrackClick('clickEditOutcomeName');
    },
    isEditOutcomeName(index) {
      return this.isCurrentMenu(this.path, `editOutcomeName${index}`);
    },
  },
};
</script>
<style lang="scss" scoped>
.outcome-group {
  background: #fafafa;
  padding: 40px 40px;
  min-height: calc(100vh - 126px);
}

.search-group {
  box-shadow: 0px 4px 16px #e0e0e0, 0px 16px 16px rgba(157, 205, 245, 0.1);
  border-radius: 8px 0px 0px 8px;
  padding: 40px 40px 550px 40px;
}

.group-title {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: #334e97;
}

.second-title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #334e97;
}

.clear-button {
  border: 1px solid #4d8edc;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
}

.clear-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #4d8edc;
}

.mid-edit {
  width: 12px;
  height: 12px;
  cursor: pointer;
}

.name-error {
  border: 2px solid #d65c51;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 2px;
}

.hint-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 165%;
  color: #757575;
}
</style>
<style lang="scss">
.outcome-name-error-tooltip {
  .ant-tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 5px 6px 5px;
    border-color: transparent transparent #d65c51 transparent;
    margin-top: 7px;
  }
  .ant-tooltip-inner {
    background: #d65c51;
    border-radius: 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
  }
}
</style>
