import { isEqual } from 'lodash';
import { IAdditionalConditionParam } from '@/utils/conditions/core/Interface';
import { AdvQueryParams } from '@/utils/query/core/Interface';
import SearchStateQuery from './SearchStateQuery';
import AggsQuery from '../AggregationQuery';

class AdvanceESQuery<AggsQueryType extends AggsQuery> {
  private readonly params: AdvQueryParams;

  private additionCondition: IAdditionalConditionParam = {
    isDeath: false,
    isFirstTime: false,
  };

  constructor(params: AdvQueryParams, private readonly aggsQuery?: AggsQueryType) {
    this.params = params;
  }

  public setFirstOccurrence(status: boolean) {
    this.additionCondition.isFirstTime = status;
  }

  public setCondition({ ...content }: Partial<IAdditionalConditionParam>) {
    Object.keys(content).forEach((key) => {
      const value = (content as Record<string, unknown>)[key];
      if (value !== undefined && !isEqual((this.additionCondition as any)[key], value)) {
        (this.additionCondition as any)[key] = value;
      }
    });
  }

  public getQuery(): Record<string, unknown> {
    // eslint-disable-next-line max-len
    const queryObject = new SearchStateQuery(this.params.SearchStateObject, this.additionCondition);
    if (queryObject.medicationConstraints.length > 0) {
      queryObject.setInnerhits(this.params.SearchStateObject, []);
    }
    return {
      ...queryObject.getQuery(),
      aggs: this.aggsQuery ? this.aggsQuery.getAggsQuery() : {},
      size: queryObject.medicationConstraints.length > 0 ? 10000 : 0,
      _source: false,
      docvalue_fields: ['CHART_NO', 'TIME_KEY', 'LAST_RECORD_DATE'],
      track_total_hits: true,
    };
  }
}

export default AdvanceESQuery;
