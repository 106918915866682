<template>
  <div class="describe-template">
    <div class="custom-title">{{ title }}</div>
    <div class="condition-block">
      <div v-for="(condition, key) in event.condition[0].list" :key="key" class="code-block">
        <div class="code-text">
          {{ condition.code }}
        </div>
        <div class="description-text">
          {{ condition.description }}
        </div>
        <div class="description-text">
          {{ condition.chineseDescription }}
        </div>
      </div>
    </div>
    <div class="constraint-block">
      <div class="custom-title" v-if="hasConstraint">Constraints</div>
      <div v-for="(constraint, index) in event.constraintList" :key="index">
        <div class="d-flex" v-if="constraint.constraintType === ConstraintType.AgeConstraint">
          <div class="code-text mr-5px">{{ CONSTRAINTS_NAME.AGE }}</div>
          <div class="description-text">{{ formatAge(constraint) }}</div>
        </div>
        <div class="d-flex" v-if="constraint.constraintType === ConstraintType.DiagnosisRangeConstraint">
          <div class="code-text mr-5px">{{ CONSTRAINTS_NAME.PRIMARY_DIAGNOSIS }}</div>
          <div class="description-text">
            {{ formatConstraintLabel(constraint.diagnosisRange, DIAGNOSIS_RANGE_OPTIONS) }}
          </div>
        </div>
        <div class="d-flex" v-if="constraint.constraintType === ConstraintType.VisitTypeConstraint">
          <div class="code-text mr-5px">{{ CONSTRAINTS_NAME.VISIT_TYPE }}</div>
          <div class="description-text">
            {{ formatConstraintLabel(constraint.visitType, VISIT_TYPE_OPTIONS) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ConstraintType from '@/utils/conditions/core/advance/constraint/Interface';
import { DIAGNOSIS_RANGE_OPTIONS, VISIT_TYPE_OPTIONS, CONSTRAINTS_NAME } from '@/utils/constants';

export default {
  name: 'EventDescribeTemplate',
  props: {
    event: {
      type: Object,
      default: null,
    },
    title: {
      type: String,
      default: 'Condition',
    },
  },
  computed: {
    hasConstraint() {
      return this.event.constraintList.length > 0;
    },
  },
  data() {
    return {
      ConstraintType,
      DIAGNOSIS_RANGE_OPTIONS,
      VISIT_TYPE_OPTIONS,
      CONSTRAINTS_NAME,
    };
  },
  methods: {
    formatAge(age) {
      if (age.end >= 100) {
        if (age.start < 100) {
          return `${age.start}~100+`;
        }
        return '100+';
      }
      if (age.start === age.end) {
        return `${age.start}`;
      }
      return `${age.start}~${age.end}`;
    },
    formatConstraintLabel(value, options) {
      return options.find((item) => item.value === value).lable;
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-title {
  font-weight: bold;
  font-size: 14px;
  line-height: 23px;
  color: #334e97;
}

.describe-template {
  padding: 10px;
}

.condition-block {
  margin-top: 10px;
}

.code-text {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #212121;
}

.description-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #212121;
}

.code-block {
  margin-bottom: 5px;
}

.constraint-block {
  border-left: 2px solid rgba(15, 72, 149, 0.5);
  padding-left: 10px;
}
</style>
