import DataCondition from './DataCondition';
import { TermConditionData, LogicOp, MedicalTerm } from './Interface';

abstract class TermCondition extends DataCondition<TermConditionData> implements TermConditionData {
  public get list(): MedicalTerm[] {
    return this.view.list;
  }

  public get andOr(): LogicOp {
    return this.view.andOr;
  }

  public get view(): TermConditionData {
    const mergedList = this.data.map((cond) => cond.list).reduce((results, list) => results.concat(list), []);

    return {
      ...super.view,
      list: mergedList,
    };
  }

  public hitCode(code: string): boolean {
    return this.list.some((term) => term.code === code);
  }
}

export default TermCondition;
