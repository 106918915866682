<template>
  <a-modal
    width="1011px"
    v-model="visible"
    @ok="handleOk"
    :mask="true"
    :destroyOnClose="true"
    :footer="null"
    :zIndex="9999"
  >
    <div class="modal-text" v-if="emr">
      <div id="EMR-title">EMR Detail</div>
      <div id="chart-no">
        {{ chartNo }}
      </div>
      <div :key="`${index}-top`" v-for="(detail, index) in topDetails">
        <div
          class="field"
          v-if="
            detail.key === 'ICD' || detail.key === 'ICD9' || typeof detail.text === 'number' || detail.text.trim() != ''
          "
        >
          <div class="field-name" v-if="detail.key !== 'ICD' && detail.key !== 'ICD9'">
            {{ detail.title }}
          </div>
          <div class="field-value" v-if="detail.key !== 'ICD' && detail.key !== 'ICD9'">
            {{ detail.text }}
          </div>
          <template v-if="(detail.key === 'ICD' || detail.key === 'ICD9') && detail.text.length !== 0">
            <div class="field-name">
              {{ detail.title }}
            </div>
            <div class="field-value" v-for="data in detail.text" :key="`${data.split('##')[0]}-top`">
              <a-row style="margin-bottom: 5px">
                <a-col class="code-text" :span="12">
                  {{ data.split('##')[0] }}
                </a-col>
                <a-col class="name-text" :span="12">
                  {{ data.split('##')[1] }}
                </a-col>
              </a-row>
            </div>
          </template>
        </div>
      </div>
      <div class="field-name" v-if="tabDetails.length !== 0 && detailType === 'inpatient'">Discharge Note</div>
      <div class="field-name" v-if="tabDetails.length !== 0 && detailType === 'outpatient'">Clinical Note</div>
      <b-tabs pills card id="second-tab emr-detail-second-tab" v-if="tabDetails.length !== 0">
        <b-tab
          v-for="(tab, i) in tabDetails"
          :key="tab.title"
          @click="switchTabEMRDetail(tab.title)"
          class="discharge-type"
          :title="tab.title"
          :active="i === 0"
        >
          <div class="field" v-if="typeof tab.text === 'number' || tab.text.trim() != ''">
            <div class="field-value">
              {{ tab.text }}
            </div>
          </div>
        </b-tab>
      </b-tabs>
      <div :key="`${index}-text`" v-for="(detail, index) in textDetails">
        <div
          class="field"
          v-if="typeof detail.text === 'number' || typeof detail.text === 'boolean' || detail.text != ''"
        >
          <div class="field-name">
            {{ detail.title }}
          </div>
          <div class="field-value">
            {{ detail.text }}
          </div>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { EMR_SECTION_NAME } from '@/utils/string';
import { ClickMixin, TrackTimeMixin } from '@/mixins';
import dayjs from 'dayjs';

const tabFields = [
  'ICD10_DISDIAG',
  'PATH_REPORT_1',
  'PATH_REPORT_2',
  'SYS_REVIEW',
  'MEDICAL_REPORT',
  'TREATMENT_PATH',
  'OP_NOTE',
  'EXAM_FULL_TXT',
  'ADM_PAHDG',
];

const tabOutPatientFields = ['SUBJ_NOTE', 'OBJ_NOTE', 'ASSESSMENT', 'PLAN', 'EXAM_FULL_TXT', 'ADM_PAHDG'];

const textFields = ['TOTAL', 'RISK_GROUP', 'TURN_NAME'];

const textOutPatientFields = ['TOTAL'];

const YYYYMMDD = 'YYYYMMDD';
const YYYY_MM_DD = 'YYYY-MM-DD';
const YYYY_MM_DD_SLASH = 'YYYY/MM/DD';

export default {
  mixins: [TrackTimeMixin, ClickMixin],
  name: 'EMRDetail',
  props: ['emr', 'chartNo', 'detailType'],
  data() {
    return {
      visible: false,
    };
  },
  computed: {
    topDetails() {
      const { emr } = this;
      let details = [];
      if (emr.IN_DATE || emr.OUT_DATE) {
        details.push({
          key: 'DATE',
          text: `${dayjs(emr.IN_DATE, YYYY_MM_DD).format(YYYY_MM_DD_SLASH)} ~ ${dayjs(emr.OUT_DATE, YYYY_MM_DD).format(
            YYYY_MM_DD_SLASH
          )}`,
        });
      }
      if (emr.TIME_KEY) {
        details.push({ key: 'OUTPATIENT_DATE', text: `${dayjs(emr.TIME_KEY, YYYYMMDD).format(YYYY_MM_DD_SLASH)}` });
      }
      if (emr.ICD_20_ENG_FULL) {
        details.push({ key: 'ICD', text: emr.ICD_20_ENG_FULL });
      }
      if (emr.ICD9_20_ENG_FULL) {
        details.push({ key: 'ICD9', text: emr.ICD9_20_ENG_FULL });
      }
      if (emr.SECTION_NAME) {
        details.push({ key: 'SECTION_NAME', text: emr.SECTION_NAME });
      }
      if (emr.MEDICATION_NEW_NAME) {
        details.push({ key: 'MEDICATION', text: emr.MEDICATION_NEW_NAME.join('\n') });
      }
      details = details.map((detail) => ({ ...detail, title: EMR_SECTION_NAME[detail.key] }));
      return details;
    },
    tabDetails() {
      if (this.detailType === 'inpatient') {
        return this.getFields(tabFields);
      }
      return this.getFields(tabOutPatientFields);
    },
    textDetails() {
      if (this.detailType === 'inpatient') {
        return this.getFields(textFields);
      }
      return this.getFields(textOutPatientFields);
    },
  },
  methods: {
    handleOk() {
      this.visible = false;
      this.trackClick('handleOk', { visible: this.visible });
    },
    show() {
      this.visible = true;
    },
    switchTabEMRDetail(tabName) {
      this.trackClick('switchTabEMRDetail', { tabName });
    },
    getFields(data) {
      const { emr } = this;
      let details = [];
      data.forEach((field) => {
        if (emr[field] !== undefined && emr[field] !== null && emr[field] !== '' && field !== 'TURN_NAME') {
          details.push({ key: field, text: emr[field] });
        }
        if (emr[field] !== undefined && emr[field] !== null && emr[field] !== '' && field === 'TURN_NAME') {
          const content = [];
          content.push(emr[field]);

          if (emr.NEXT_ADM && emr.NEXT_PLANNED) {
            content.push('14天內計畫再入院');
          }

          if (emr.NEXT_ADM && !emr.NEXT_PLANNED) {
            content.push('14天內非計劃再入院');
          }

          details.push({ key: field, text: content.join('、') });
        }
      });
      details = details.map((detail) => ({ ...detail, title: EMR_SECTION_NAME[detail.key] }));
      return details;
    },
  },
};
</script>

<style scoped>
#EMR-title {
  font-size: 16px;
  font-weight: bold;
  color: #333f6b;
}

#chart-no {
  color: #828282;
  margin-bottom: 30px;
}

.field-name {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: bold;
  color: #333f6b;
}

.field-value {
  font-size: 16px;
  color: #333333;
}

.modal-text {
  white-space: pre-line;
}

.field {
  margin-bottom: 30px;
}

.code-text {
  width: 90px;
  max-width: 90px;
  min-width: 90px;
  margin-right: 10px;
}

.name-text {
  margin-top: 0px;
  height: auto;
}
</style>

<style lang="scss" scoped>
/deep/ .card-header {
  background-color: transparent !important;
  border-bottom-width: 0px !important;
  padding: 0px !important;
}
/deep/ .card-body {
  padding: 0px !important;
}

/deep/.card-header-pills {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
</style>
