import ApiHelper from '@/utils/ApiHelper';
import TimeRelation from '@/utils/conditions/core/advance/TimeRelation';
import AdvanceESQuery from '@/utils/query/core/advance/AdvanceESQuery';
import { unset } from 'lodash';
import { DateUnit } from '../../../../utils/conditions/core/Interface';
import ChartConfig, { seriesMapper } from '../../core/ChartConfig';
import { AdvChartQueryParams, ChartKey, ChartOption, RawItem } from '../../core/Interface';

export default abstract class AdvChartConfig<RawItemType extends RawItem> extends ChartConfig<RawItemType> {
  constructor(key: ChartKey) {
    super(key);
  }

  protected params!: AdvChartQueryParams;

  protected statisticPeriod = new TimeRelation({
    beforeValue: '0',
    afterValue: '0',
    beforeUnit: DateUnit.DAYS,
    afterUnit: DateUnit.DAYS,
  });

  protected processQueryParams(): AdvChartQueryParams {
    return this.params;
  }

  protected async preProcessReturnData(datas: any) {
    return datas.aggregations.result.buckets;
  }

  protected postProcessQuery(query: AdvanceESQuery<this>): AdvanceESQuery<this> {
    return query;
  }

  public async applyData(
    params: AdvChartQueryParams,
    api: ApiHelper,
    userId: string,
    trackContent: any
  ): Promise<ChartOption> {
    this.merge({
      tooltip: {
        formatter: (seriesList: any) => this.tooltipFormatter(seriesList, api, userId, trackContent),
      },
      series: {
        cursor: 'default',
      },
    });
    this.params = params;

    this.statisticPeriod = new TimeRelation(this.params.TimeSelectObject);
    this.resultSize = 10;

    const query = new AdvanceESQuery(this.processQueryParams(), this);

    const processedQuery = this.postProcessQuery(query);
    this.rawData = await api.advSearch(processedQuery.getQuery(), 0, this.chartKey);
    this.recordCount = this.rawData.aggregations.patientNum.value;
    this.patientCount = this.rawData.aggregations.patientNum.value;
    this.dataCount = this.rawData.aggregations.result.buckets.length;

    const returnData = await this.preProcessReturnData(this.rawData);

    this.data = this.createChartData(returnData);

    this.reloadChartItemMap();

    unset(this.option, 'series.data');
    this.merge({
      series: { data: this.data.map(seriesMapper) },
    });

    this.setAxisData();
    return Promise.resolve(this.getOption());
  }
}
