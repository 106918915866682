<template>
  <div class="advance-codesearch-filter">
    <b-form-input v-model="inputText" :placeholder="placeholder" class="advance-codesearch-input" ref="inputText">
    </b-form-input>
    <b-img src="@/assets/images/icon_search_blue.svg" class="icon-search" />
    <b-img src="@/assets/images/icon_clear.svg" class="icon-clear" v-if="inputText !== ''" @click="clearInput" />
    <template v-if="options.length !== 0">
      <template v-if="feedbackField === 'diagnosis'">
        <b-row no-gutters class="header-row">
          <b-col cols="2">
            <span class="header-text-left-title">ICD</span>
          </b-col>
          <b-col class="header-text-left header-text-margin padding-12"> Description </b-col>
          <b-col cols="1" class="header-text-right header-text-margin padding-12"> Category </b-col>
          <b-col cols="2" class="header-text-right header-text-margin"> Patients/Records </b-col>
        </b-row>
      </template>
      <template v-if="feedbackField === 'medication'">
        <b-row no-gutters class="header-row">
          <b-col cols="2">
            <span class="header-text-left-title">Medication</span>
          </b-col>
          <b-col class="header-text-left header-text-margin padding-12"> Description </b-col>
          <b-col cols="2" class="header-text-left header-text-margin padding-12"> ATC & Generic name </b-col>
          <b-col cols="2" class="header-text-right header-text-margin"> Patients/Records </b-col>
        </b-row>
      </template>
      <template v-if="feedbackField === 'procedure'">
        <b-row no-gutters class="header-row">
          <b-col cols="2">
            <span class="header-text-left-title">Procedure</span>
          </b-col>
          <b-col class="header-text-left header-text-margin padding-12"> Description </b-col>
          <b-col cols="2" class="header-text-right header-text-margin"> Patients/Records </b-col>
        </b-row>
      </template>
      <template v-if="feedbackField === 'Medical Order'">
        <b-row no-gutters class="header-row">
          <b-col cols="2">
            <span class="header-text-left-title">Order</span>
          </b-col>
          <b-col class="header-text-left header-text-margin padding-12"> Description </b-col>
          <b-col cols="1" class="header-text-right header-text-margin padding-12"> NHI Order </b-col>
          <b-col cols="1" class="header-text-right header-text-margin padding-12"> Category </b-col>
          <b-col cols="1" class="header-text-right header-text-margin padding-12" v-if="uiConfig.hisVersion">
            HIS 2/5
          </b-col>
          <b-col cols="2" class="header-text-right header-text-margin"> Patients/Records </b-col>
        </b-row>
      </template>
    </template>
    <VuePerfectScrollbar v-if="optionsWithCount.length !== 0" @wheel.stop>
      <b-row
        no-gutters
        v-for="option in optionsWithCount"
        :title="option.description"
        :key="option.code + option.type"
        class="row-option"
      >
        <b-col>
          <b-row
            no-gutters
            class="code-search-menu"
            :class="option.patientNum === 0 ? 'disable-option' : ''"
            @click="handleChange(option)"
          >
            <b-col cols="2" class="d-flex align-items-center padding-12">
              <b-img
                src="@/assets/images/icon_unselect.svg"
                class="icon-unselect icon-unselect-margin"
                v-if="!checkSelect(option)"
              />
              <b-img src="@/assets/images/icon_select.svg" class="icon-unselect icon-unselect-margin" v-else />
              <div class="code-text" v-if="feedbackField === 'diagnosis'">
                {{ `${option.labelDX}` }}
              </div>
              <div class="code-text" v-else>{{ `${option.label}` }}</div>
            </b-col>
            <b-col style="padding: 6px 0px" :cols="getDescCols" class="padding-12">
              <div class="icd-description">{{ option.description }}</div>
              <div class="icd-description">{{ option.chineseDescription }}</div>
            </b-col>
            <b-col
              v-if="feedbackField === 'Medical Order'"
              cols="1"
              class="icd-record-count d-flex align-items-center justify-content-end padding-12"
            >
              <div class="icd-record-count">
                {{ getNhiDefault(option.nhiOrder) }}
              </div>
            </b-col>
            <b-col
              v-if="feedbackField === 'Medical Order'"
              cols="1"
              class="icd-record-count d-flex align-items-center justify-content-end padding-12"
            >
              <div class="icd-record-count">{{ option.type }}</div>
            </b-col>
            <b-col
              v-if="feedbackField === 'Medical Order' && uiConfig.hisVersion"
              cols="1"
              class="icd-record-count d-flex align-items-center justify-content-end padding-12"
            >
              <div class="icd-record-count">{{ option.hisVersion }}</div>
            </b-col>
            <b-col
              class="icd-record-count d-flex align-items-center justify-content-end padding-12"
              v-if="feedbackField === 'diagnosis'"
              cols="1"
            >
              <div class="icd-record-count">{{ getTypeName(option.type) }}</div>
            </b-col>
            <b-col
              class="d-flex align-items-center description-text padding-12"
              v-if="feedbackField === 'medication'"
              cols="2"
              :title="option.ingredient"
            >
              <div class="description-text">
                <div class="atc-code-text">
                  {{ getNhiDefault(option.atcCode) }}
                </div>
                <div class="ingredient-text">
                  {{ getNhiDefault(option.ingredient) }}
                </div>
              </div>
            </b-col>
            <b-col
              class="icd-record-count d-flex align-items-center justify-content-end"
              v-if="option.patientNum !== undefined"
              cols="2"
            >
              <div class="icd-record-count">
                {{ formatedTemplateNumber(option.patientNum) }} /
                {{ formatedTemplateNumber(option.recordNum) }}
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </VuePerfectScrollbar>
  </div>
</template>
<script>
import { throttle } from 'lodash';

import VuePerfectScrollbar from 'vue-perfect-scrollbar';

import { EventMixin, Utils, PatientNumberMixin, ClickMixin } from '@/mixins';
import FilterMixin from '@/mixins/filterMixin';
import { EMR_TYPE_NAME } from '@/utils/string';
import { EventType } from '@/utils/conditions/core/Interface';
import { mapSubUiConfig } from '@/utils/uiConfig';

export default {
  name: 'AdvanceCodeSearchFilter',
  mixins: [EventMixin, Utils, FilterMixin, PatientNumberMixin, ClickMixin],
  data() {
    return {
      andOr: 'should',
      inputText: '',
      value: [],
    };
  },
  props: {
    advance: Boolean,
    refCondition: Array,
    index: {
      type: Number,
      default: 1,
    },
    eventType: {
      type: String,
      default: EventType.FIX_EVENT,
    },
  },
  watch: {
    inputText() {
      this.inputDescription(this.inputText);
    },
  },
  components: {
    VuePerfectScrollbar,
  },
  computed: {
    ...mapSubUiConfig('SearchForm'),
    optionsWithCount() {
      const noRecordResult = this.options.filter((option) => option.patientNum === 0);
      return this.options.filter((option) => option.patientNum !== 0).concat(noRecordResult);
    },
    fetchOptionsThrottled() {
      return throttle(
        (description) =>
          this.fetchOptions(description, this.setOptions, 'advance').catch(() => {
            this.setOptions([]);
          }),
        100
      );
    },
    getDescCols() {
      switch (this.feedbackField) {
        case 'diagnosis':
          return 7;
        case 'medication':
          return 6;
        case 'procedure':
          return 8;
        case 'Medical Order':
          return 5;
        default:
          return 7;
      }
    },
  },
  created() {
    if (this.refCondition) {
      this.value = this.refCondition;
    }
  },
  mounted() {
    try {
      this.$refs.inputText.focus();
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    setOptions(options) {
      this.options = options;
    },
    inputDescription(text) {
      this.fetchOptionsThrottled(text);
    },
    handleChange(option) {
      if (option.patientNum === 0) {
        return;
      }

      const check = this.checkSelect(option);

      if (!check) {
        this.value.push(option);
        this.advanceTrackClick('handleChange', {
          index: this.index,
          value: this.value,
          option,
        });
      } else {
        this.value = this.value.filter((item) => item.code !== option.code);
        this.advanceKeySearchDeselect({
          index: this.index,
          value: this.value,
          option,
        });
      }

      this.$emit('setValue', this.value, this.andOr, false);
    },
    getTypeName(type) {
      return EMR_TYPE_NAME[type];
    },
    getNhiDefault(data) {
      if (data === '') {
        return '-';
      }
      return data;
    },
    checkSelect(value) {
      return this.value.map((item) => item.code + item.type).includes(value.code + value.type);
    },
    clearInput() {
      this.inputText = '';
    },
  },
};
</script>
<style lang="scss" scoped>
.advance-codesearch-filter {
  border-radius: 6px;
}

.advance-codesearch-input {
  width: calc(100% - 20px);
  height: 36px;
  margin: 0px 10px 10px 10px;
  border-radius: 6px;
  border: 1px solid #4a94d2;
  padding-left: 34px;
  padding-right: 35px;
}

.form-control:focus {
  border: 1px solid #4a94d2;
  box-shadow: none;
}

.header-row {
  height: 38px;
  padding: 10px 20px 10px 20px;
  background-color: #eff0f3;
}

.header-text-left-title {
  height: 15px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #333f6b;
}

.header-text-left {
  height: 15px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #333f6b;
}

.header-text-margin {
  margin-top: 3px;
}

.header-text-right {
  height: 15px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #333f6b;
}

.icd-description {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  height: 20px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
}

.icd-record-count {
  text-align: right;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #828282;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.icd-record-type {
  position: relative;
  float: right;
  color: rgba(51, 63, 107, 0.5);
  width: 44px;
  margin-left: 10px;
}

.code-search-menu {
  padding: 0px 20px;
  height: 54px;
}

.code-text {
  height: 18px;
  margin: 1px 10px;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #333f6b;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.row-option {
  box-shadow: inset 0px 1px 0px #d6d9e1;
  cursor: pointer;
}

.disable-option {
  opacity: 0.3;
  cursor: not-allowed;
}

/deep/ .ps {
  max-height: 405px;
}

.icon-search {
  position: absolute;
  top: 29px;
  left: 20px;
}

.icon-clear {
  position: absolute;
  top: 29px;
  right: 20px;
  cursor: pointer;
}

.description-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.atc-code-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 13px;
  line-height: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #757575;
}

.ingredient-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 13px;
  line-height: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #212121;
  margin-top: 2px;
}

.padding-12 {
  padding-right: 12px !important;
}
</style>
