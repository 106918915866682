export default {
  'Whole blood': [
    'W.B.C count',
    'R.B.C count',
    'Ht.',
    'MCV',
    'MCH',
    'MCHC',
    'Platelet',
    'Hb.',
    'CRE',
    'eGFR',
    'ALT(GPT)',
    'Seg',
    'Lymph',
    'Mono',
    'Eos',
    'Bas',
    'Normobl',
    'AST(GOT)',
    'K',
    'NA',
    'BUN',
    'HS C.R.P',
    'Glucose(AC)',
    'TG',
    'CHO',
    'Pro-Time',
    'Pro-time INR',
    'Bilirubin total',
    'Hb',
    'Ca (Calcium)',
    'ALB',
    'LDL-C',
    'A.P.T.T.',
    'Uric acid',
    'eAG',
    'HbA1c',
    'HDL-C',
    'TC/HDL-C',
    'Platelet count',
    'Bilirubin direct',
    'CEA',
    'Bilirubin Indirect',
    'ALP',
    'CA-125',
    'HBsAg(Qualitative)',
    'Glucose(PC)',
    'TSH',
    'RPR/VDRL test',
    'α-Fetoprotein',
    'A.B.AB.O & RH',
    'Sub-group',
    'Anti-A',
    'Anti-B',
    'Anti-AB',
    'Anti-D',
    'Cell-A',
    'Cell-B',
    'Irregular Antibody screening(I)',
    'Irregular Antibody screening(II)',
    'Irregular Antibody screening(III)',
    'Anti-HCV',
    'A.B.AB.O blood grouping',
    'RH(D) typing',
    'CA19-9',
    'P',
    'r-GT',
    'Fe',
    'Band',
    'Estradiol(E2)(EIA)',
    'CK',
    'E.S.R(1hr)',
    'FSH (EIA)',
    'Total-IgE (總量免疫球蛋白E)',
    'Anti-HBs',
    'LH(EIA)',
    'ANA',
    'T4',
    'Zn (Zinc)',
    'LH/FSH Ratio',
    'E.S.R.備註',
    'E.S.R.(Rheology法)',
    'Aty.Lym',
    'Free-T4(E.I.A)',
    'Myelo',
    'CK-MB',
    'Meta',
    'CL',
    'Anti-HIV',
    'LDH',
    'Mg (Magnesium)',
    'Total Eosin',
    'IGF-1',
    'R.F.',
    'Testosterone(EIA)',
    'AMMONIA',
    'TP',
    'Bleeding.T.',
    'Mycoplasma pneumonia IgM(快速法)',
    'Prolactin(PRL)(EIA)',
    "C'3(包括C3c)",
    'LIPASE',
    "C'4",
    'HBeAg EIA',
    'AMY',
    'Bermuda grass etc. (常見草類花粉6種)',
    'Milk, Egg white, Fish etc. (常見食物過敏原6種)',
    'Kiwi,Melons etc.(常見水果過敏原5種)',
    'Penicillium notatum etc. (常見黴菌6種)',
    'Cat epithelium and dander',
    'Dog dander',
    'Globulin',
    'A/G Ratio',
    'HGH',
    'T3',
    'Crab',
    'Shrimp',
    'Dermatophagoides pteronyssinus',
    'Cockroach, German',
    'VIT-B12(EIA)',
    'IgA Nephelometry',
    'Transferrin',
    'TIBC',
    'β-HCG (EIA法)',
    'FK506',
    'NT-proBNP',
    'ECP (嗜伊紅血球陽離子蛋白)',
    'HBsAg(Quantitative)',
    'Troponin I',
    'CA-153',
    'PCT',
    'TPPA/TPHA test',
    'SCC',
    'Progesterone(EIA)',
    'HCV quantitative amplification test',
    'Anti-TPO Ab',
    'HBV quantitative amplification test',
    'HS Troponin I',
    'Anti-ENA (SSA)',
    'Anti-ENA (SSB)',
    "FSH 30' (EIA)",
    "LH 30' (EIA)",
    "Estradiol(E2) 30' (EIA)",
    "LH/FSH (30') Ratio",
    'Thyroglobulin Antibody',
    'TPA',
    'Lymphocyte Count',
    'CD3 T cell',
    'CD4 Helper cell',
    'CD19 B Lymphocyte',
    'CD4/CD8 H/S Ratio',
    'IgG Nephelometry',
    'Anti-HBc(EIA)',
    'D-dimer test',
    'CD4 Absolute count',
    'CD8 Absolute count',
    'BNP',
    'CD8 Supperssor cell',
    'HIV viral load test',
    'Promyelo',
    'Hematocrite(Hct)',
    'Anti-dsDNA',
    'Folic acid',
    'Anti-rubella IgG',
    'Anti-CCP antibody',
    'QFT',
    'Speckled type',
    'Anti-Cardiolipin-IgG',
    'HLA-B27',
    'Ferritin(EIA)',
    'Anti-ENA (Sm)',
    'Anti-ENA (RNP)',
    'Intact parathyroid hormone',
    'IgM Nephelometry',
    'ANCA',
    'VALP',
    'Dermatophagoides pteronyssinus (屋塵?)',
    'Retic.',
    'TPA(EIA/LIA)',
    'Dermatophagoides micorceras (微角塵?)',
    'Fibrinogen',
    'Anti-HAV',
    'O.G.T.T-50',
    'LACTICACID',
    'Mycoplasma Ab(Total Ab)',
    'OGTT75(ac)',
    'OGTT75(1hr)',
    'OGTT75(2hr)',
    'ACTH',
    'PSA',
    'Anti-ENA (Jo-1 Ab)',
    'IHA for Amoeba',
    'Anti-ENA (ScL-70 Ab)',
    "Direct Coombs' polyspecific test",
    'Egg white',
    'Milk',
    'Fish (cod)',
    'Wheat',
    'Peanut',
    'Soya bean',
    'CMv Ab-IgM',
    'HCV genotype',
    'Tissue typing HLA HLA-ABC',
    'HBV-DNA(PCR)',
    'Prealbumin',
    'VANCO(trough)',
    'AMH',
    'PRA test-Class II',
    'HSV IIgM',
    'PRA test-Class I',
    'HSV II IgM',
    'Anti-HBe (EIA)',
    'EB-VCA IgM',
    'AntidsDNA',
    'Chlamydia trachomatis IgG',
    'Chlamydia trachomatis IgA',
    'Cold hemoagglutinin',
    'Cat dander (貓毛)',
    'Dog dander (狗毛)',
    'Anti-HAV IgM(EIA)',
    'HSV I IgG',
    'Homogeneous type',
    'Blatella germanica (德國蟑螂)',
    'Blast',
    'Penicillium notatum (青黴菌)',
    'Candida albicans (白色念珠菌)',
    'IgG4',
    'Blomia tropicalis (熱帶無爪?)',
    'Dermatophagoides farinae(粉塵?)',
    'Cladosporium herbarum (芽枝黴菌)',
    'Aspergillus fumigatus (煙色麴菌)',
    'HSV II IgG',
    'Homocysteine',
    'AMA',
    'ASMA',
    'Chlamydia Pneumoniae Antibody IgM(ELISA)',
    'Dermatophagoides farniae (粉塵?)',
    'Cockroach, German (德國蟑螂)',
    'DHEA-S',
    'EB-VCA IgG',
    'ANA-1',
    'CMv Ab-IgG',
    'Hb A',
    'Hb A2',
    'EBV viral load',
    'OSMO-B',
    'Lithium',
    '(Anisocytosis)',
    '(Target cell)',
    '(Polychromatophilic cells)',
    'Vitamin D',
    'Other',
    'Mycoplasma pneumonia IgM(ELISA)',
    '(Elliptocyte)',
    'Aldosterone',
    'Everolimus',
    'DGN',
    'ASLO',
    'Peach',
    'Sirolimus',
    'Melons',
    'Pineapple',
    'Hb F',
    'Kiwi',
    'Banana',
    'Protein S',
    'PRA',
    "Testosterone(EIA) 30'",
    'Protein C',
    'Bilirubin Total',
    'Bilirubin Direct',
    'Bilirubin Indrect',
    'Bilirubin-D/Bilirubin-T Ratio',
    'Anti-B2-glycoprotein-I Ab',
    'Air Screen (空氣過敏原定性檢驗)',
    'Lupus anticoagulant test(screening)',
    'Toxoplasma IgM Ab',
    'HLA-B1502',
    "Indirect Coombs' polyspecific test",
    'Nucleolar type',
    'Cytoplasmic',
    'Chlamydia Pneumonia Antibody IgM(ELISA)',
    'Immunoelectrophoresis',
    '(Schistocytes)',
    'Toxoplasma IgG',
    '(Poikilocytosis)',
    'HCV-RNA (PCR)',
    'Tissue typing HLA (HLA-DR/DQ)',
    'Aspergillus (Galactomannan)Ag',
    'Acetylcholine receptor Ab',
    'Ceruloplasmin',
    'AT III',
    'Chlamydia Pneumoniae Antibody IgG',
    'VZV IgG Ab',
    'Thyroglobulin',
    'ICG(15 mins)',
    'Anti-phospholipid IgM',
    'Anti-B2 glycoprotein IgM',
    'Anti-Cardiolipin IgM',
    'Milk (牛奶)',
    'Shrimp (蝦)',
    'C-peptide',
    'Anti-phospholipid IgG',
    'Anti-B2 glycoprotein IgG',
    'Anti-Cardiolipin IgG',
    'EB-NA IgG',
    '(Tear drop form cells)',
    'Cyclosporine-A',
    'IGRA TEST',
    'β2-microglobulin(Blood)',
    'Egg white (蛋白)',
    'Crab (蟹)',
    'Cu (Copper)',
    'HIV test(限器官捐贈)',
    'Yeast (酵母菌)',
    'Wheat (小麥)',
    'Peanut (花生)',
    'Codfish (鱈魚)',
    'Soya bean (大豆)',
    'Melons (哈密瓜)',
    'ALBUMIN',
    'ALPHA 1',
    'ALPHA 2',
    'BETA',
    'GAMMA',
    'HIV-1 Qualitative Test',
    '(Spherocytes)',
    'CMV viral load(Blood)',
    'Insulin',
    'BM(DC)',
    'HS Troponin I 備註',
    'BK virus quantitative amplification test (Blood)',
    'CRBAM',
    '17α-OHP',
    'Widal Test S. typhi O',
    'Widal Test S. typhi H',
    'Widal Test S. para A',
    'Widal Test S. para B',
    'Anti-HDV',
    'Alkaline Phosphatase',
    'Fast liver',
    'Liver',
    'Bone',
    'Intestine',
    'VZV IgM AB',
    'Measles IgG Ab',
    'Free T3(EIA/LIA)',
    'HE4',
    'insulin',
    'Weil-Felix Test Proteus OX-2',
    'Weil-Felix Test Proteus OX-19',
    'Weil-Felix Test Proteus OX-k',
    "GLU-120'",
    'CD8 Suppressor cell',
    'CD16+56 NK cell',
    'Anti-Rubella IgM',
    '(Blister cells)',
    'CgA',
    'G-6-P-D(新生兒)',
    'Haptoglobin Nephelometry',
    '(Burr cell)',
    'Chlamydia Pneumonia Antibody IgG',
    'HBsAg EIA',
    'RPR/VDRL test(PrEP)',
    'Enterovirus 71 IgM antibody',
    'LDH-1',
    'LDH-2',
    'LDH-3',
    'LDH-4',
    'LDH-5',
    'LDH 1/LDH 2',
    'Chlamydia Pneumoniae Antibody IgM(快速法)',
    'Mumps IgG',
    'ANA-2',
    'Anti-HBc IgM(EIA)',
    'Active T cell',
    'Anti-centromere Ab',
    'CPK isoenzyme',
    'CK-BB',
    'CK-MM',
    'EB-VCA IgA',
    'Lupus anticoagulant test(confirm)',
    'Lupus anticoagulant test(screening/confirm)',
    'Cryoglobulin',
    'HIV-1 Western blot',
    'HBV mutation',
    'PTN',
    'G-6-P-D',
    'Serum ketone body (quantitative)',
    'Basement Memb. Zone Ab',
    'Cyfra 21-1',
    'Angiotension Converting enzyme',
    'GAD Ab',
    'Pb (Lead)(血液)',
    'MTX',
    'CRE(PrEP)',
    'eGFR(PrEP)',
    'Food Screen (食物過敏原定性檢驗)',
    'CMV qualitative amplification test (Blood)',
    'LAP score',
    'Nuclear dot type',
    'Anti-HTLV-I / HTLV-II',
    'Measle IgM',
    'DNA qualitative amplification test去氧核糖核酸',
    'RDW-CV',
    'HIV-1/2 Confirmatory Assay',
    'Goat milk (羊奶)',
    'Insulin Ab',
    'PDW',
    'Mumps IgM',
    'PHNO',
    'CRC Monitor',
    'RDW-SD',
    'MPV',
    'Plasma free Ca++',
    'ALC',
    'Gluten(麩質)',
    'P-LCR',
    'Dengue NS1 Ag',
    'CD3 Absolute count',
    'CD19 Absolute count',
    'CD16+56 Absolute count',
    'Al',
    'NSE',
    'Arsenic (As) (血液)',
    '免疫球蛋白κ',
    '免疫球蛋白λ',
    'κ/λ',
    'Preeclampsia Risk Screening',
    'Adenosine deaminase',
    'Anti-Ribosomal-P',
    'Free E3',
    'BCR-ABL1',
    '(Stomatocytes)',
    'Calcitonin',
    'Gastrin',
    "Coombs' C3d monospecific test",
    'Anti-HLA specificantibodies, class I and classII (DSA)',
    'ChE',
    'AST(GOT)(PrEP)',
    'ALT(GPT)(PrEP)',
    'IgG1',
    'Erythropoietin',
    'Anti-Histone',
    'RBC morphology',
    'Coombs IgG monospecific test',
    '(Other)',
    '聽損基因',
    'HG(Mercury)',
    'Factor VIII assay',
    'T Lymphocytotoxic test',
    'B Lymphocytotoxic test',
    'Special blood group studies-D.E.C.e.c',
    'Myoglobin(Blood)',
    'BCR-ABL1(qRT-PCR )',
    '(Acanthocytes)',
    'α1-antitrypsin',
    'Cadmium (Cd) (血液)',
    'P1NP',
    'Acetaminophen',
    'Mixing APTT',
    'Parasite (malaria or filaria)',
    'Clam (蛤)',
    'ANCA(MPO)(ELISA)',
    'Beef (牛肉)',
    'Theophylline(trough)',
    'Onion (洋蔥)',
    'HSV qualitative amplification test(Blood)',
    'CA 72-4',
    'FTA-ABS',
    'Honey (蜂蜜)',
    'Oyster (牡蠣)',
    'ANCA(PR3)(ELISA)',
    '(Bizarre RBC forms)',
    '其他血色素',
    'DNA 定量檢測',
    'GA',
    'Orange (柑橘)',
    'Mixing PT',
    'Smudge Cell',
    'HBV genotype',
    'Common pigweed (莧科)',
    'Assure',
    'Dog dander (貓毛)',
    'Theophylline(peak)',
    'IgG2',
    'IgG3',
    'Corn (玉米)',
    'Tomato (蕃茄)',
    'Pork (豬肉)',
    'Carrot (紅蘿蔔)',
    'Strawberry (草莓)',
    'Mango (芒果)',
    'IMR AD',
    'Platelet antibody',
    'Leukemia/Lymphoma',
    'Anti-glomerular',
    'Mosquito (蚊子)',
    'B.M',
    'G-6-P-D(3月~12歲)',
    'inhibin B',
    'CRC Protect',
    'CA-125 EIA法',
    'HE4-停經前',
    'ROMA-停經前',
    '(Tailed RBC)',
    'Gentamicin(trough)',
    'Identification',
    'DR-70',
    'ANA-3',
    'CO',
    'HIV 快速測定',
    'Penicillyl G (盤尼西林)',
    'Amoxicilloyl (安莫西林)',
    'B lymphoproliferative disorder (CLL,MCL,HCL)(Blood)',
    'Estriol',
    'Acute Myeloid Leukemia characterizatiom(Blood)',
    'Acute Leukemia Screen(Blood)',
    'HS CRP',
    'Pigeon droppings (鴿糞)',
    'Japanses cedar (日本杉)',
    'Eucalyptus (油加利)',
    'Australian pine (木麻黃)',
    'Apple (蘋果)',
    'Egg yolk (蛋黃)',
    'Cotton (棉花)',
    'Acacia (相思樹)',
    'Watermelon (西瓜)',
    'Ab Titration Anti-A',
    'Ab Titration Anti-B',
    'Atypical lymphocyte/lymphoma Screen(Blood)',
    'MRD Panel(Blood)',
    'Leukemia/lymphoma screen panel(Blood)',
  ],
  Urine: [
    'Color',
    'Glucose',
    'Bilirubin',
    'Ketones',
    'Sp.Gr.',
    'Occult Blood',
    'pH',
    'Protein',
    'Nitrite',
    'Leukocyte esterase',
    'Urobilinogen',
    'WBC',
    'Ep.cell',
    'Bacteria',
    'RBC',
    'Casts',
    'Crystal',
    'Turbidity',
    'Appearance',
    'Creatinine (urine) Random',
    'Microalbumin-Urine',
    'UACR',
    'TP (Urine) Random',
    'UPCR',
    'Pregnancy test EIA',
    'Amphetamines screen test',
    'Streptococcus pneumonia urinary Ag',
    'Other',
    'BNZ',
    'Legionella pneumonia urinary Ag',
    'Morphine 篩檢',
    'β2-microglobulin(urine)',
    'Na (urine) Random',
    'BK virus quantitative amplification test (Urine)',
    'Amphetamine 篩檢',
    'P (Urine) Random',
    'OSMO-Urine',
    'Urine RBC morphology',
    'K (urine) Random',
    'Urea-N (urine) Random',
    'Cl (Urine) Random',
    'VMA-Urine',
    'CA (urine) Random',
    'Urine Protein',
    'Urine Occult Blood',
    'Immunoelectrophoresis(Urine)',
    'Cannabinoids screen test',
    'Creatinine (urine) 24hr',
    'TP (Urine) 24hr',
    'CMV qualitative amplification test (Urine)',
    'Coproporphyrin',
    'Urine Glucose',
    'Catecholamine',
    'Norepinephrine(Urine)',
    'Epinephrine(Urine)',
    'Dopamine(Urine)',
    'Norepinephrine(24hr.)',
    'Epinephrine(24hr.)',
    'Dopamine(24Hr.)',
    'UA (Urine) Random',
    'CMV viral load(Urine)',
    'Ketamine screen test',
    'Glucose (urine) Random',
    'Magnesium(Urine) Random',
    'CA (urine) 24hr',
    'Pb(Lead)(Urine)',
    'UA (Urine) 24hr',
    'MDMA screening test',
    'Na (Urine) 24hr',
    'Coppe(urine) 24hr',
    'Ketamine',
    'nor-Ketamine',
    'Trichomonas',
    'K (Urine) 24hr',
    'Cl (Urine) 24hr',
    'Paraquat',
    'As(Arsenic)(Urine)',
    'Ketamine screening test',
    'P (Urine) 24hr',
    'Magnesium(Urine) 24hr',
    'Amphetamine 篩檢{7天}',
    'Cd(Cadmium)(Urine)',
    'Cadmium/Creatinine',
    '5-HIAA',
    'Chlamydia trachomatis test(Urine)',
    'Neisseria gonorrhoeae DNA test(Urine)',
    'Cu(Copper)(Urine)',
    'Amphetamine',
    'Methamphetamine',
    'Cr(Chromium)/Creatinine',
    'Bence Jones protein',
    'Cannabinoids screening test',
    'BNZ(7天)',
    'Ketamine screening test(7 days)',
  ],
  'Blood venous': ['PH (Vein)', 'PCO2 (Vein)', 'PO2 (Vein)', '[HCO3] (Vein)', 'TCO2 (Vein)', 'BE (Vein)', 'SO2 (Vein)'],
  Gas: ['13C-urea breath test for helicobacter pylori infection'],
  'N/A': ['E.K.G', 'ALA/ PBG', 'BRCA-1', 'BRAC-2'],
  'Calculus (=Stone) / Stone (use CALC)': [
    'Stone analysis',
    'Ca. Bilirubinate',
    'Calcium Carbonate',
    'Protein (Calculi)',
    'Cholesterol',
    'Calcium Stearate',
    'Calcium Oxalate',
    'Ca Phosphate Ca3(PO4)2',
    'Uric Acid',
    'Ca Phosphate CaHPO4.2H2O',
    'Ammonium Mg Phosphate',
    'Sodium Urate',
    'Ammonium Urate',
    'Cystine',
    'Ca Phosphate CO3 Form',
  ],
  'Synovial fluid (Joint fluid)': [
    'Synovial effusion Appearance',
    'Cell count(RBC)',
    'Cell count(WBC)',
    'Neut.',
    'Lym.',
    'Mono.',
    'Eos',
    'Baso',
    'Synovial lining cell',
    'Aty.Lym',
    'Unidientify',
    'other',
  ],
  Throat: [
    'Influenza A Ag',
    'Influenza B Ag',
    'Adenovirus Ag test',
    'RSV screening test',
    'Isolation and identification of virus (Throat swab)',
    'Inf A RNA',
    'Inf B RNA',
  ],
  'Pleural fluid (thoracentesis fld)': [
    'Pleural effusion App.',
    'pH',
    'Sp.Gr.',
    'Rivalta test',
    'Cell count(RBC)',
    'Cell count(WBC)',
    'Neut.',
    'Lym.',
    'Histo.',
    'Mesothelial cell',
    'Eos',
    'Baso',
    'LDH-PL',
    'TP-PL',
    'GLU-PL',
    'Aty.Lym',
    'Unidientify',
    'CL-PL',
    'other',
  ],
  Stool: [
    '(E) Stool Occult Blood(EIA/LIA)',
    'Fecal Occult Blood(EIA)',
    'Transferrin (EIA)',
    'Parasite ova.(Direct)',
    'Stool Color',
    'Appearance',
    'Digestion',
    'Occult Blood',
    'Pus Cell',
    'Helicobactor pylori stool antigen test',
    'Rota virus Ag',
    'Occult Blood (Stool) Test',
    'Amoeba trophozoite',
    'Stool Occult Blood (EIA/LIA)',
    'Reducing substance (Reducing sugar)',
    'Reducing substance (pH)',
    'Parasite ova.(Conc)',
    'Stool Starch stain',
    'Neutral fat stain',
    'Other',
  ],
  'Blood arterial': [
    'PH (Artery)',
    'PCO2 (Artery)',
    'PO2 (Artery)',
    '[HCO3] (Artery)',
    'TCO2 (Artery)',
    'BE (Artery)',
    'SO2 (Artery)',
  ],
  'Peritoneal fluid /ascites': [
    'Ascites effusion App.',
    'pH',
    'Sp.Gr.',
    'Rivalta test',
    'Cell count(RBC)',
    'Cell count(WBC)',
    'Neut.',
    'Lym.',
    'Histo.',
    'Mesothelial cell',
    'Eos',
    'Baso',
    'LDH-AS',
    'GLU-AS',
    'Albumin (Ascites)',
    'TP-AS',
    'Unidientify',
    'CL-AS',
    'Aty.Lym',
    'other',
  ],
  'Cerebral spinal fluid': [
    'CSF Applearance',
    'Cell count(RBC)',
    'Cell count(WBC)',
    'Neut.',
    'Lym.',
    'Mono.',
    'Eos',
    'Baso',
    'GLU-CSF',
    'TP-CSF',
    'Isolation and identification of virus (CSF)',
    'LDH-CSF',
    'IgG Nephelometry (CSF)',
    'Immunoelectrophoresis(CSF)',
    'ALB-CSF',
    'RPR/VDRL test',
    'CL-CSF',
    'Aty.Lym',
    'HSV qualitative amplification test(CSF)',
    'other',
  ],
  'Gastric fluid/contents': ['Occult Blood (N-G) Test'],
  'Dialysis fluid': ['透析液 Creatinine', '透析液 Glucose(ac)', '透析液 BUN'],
  'Body fluid, unsp': ['Adenosine deaminase(體液)'],
  Spermatozoa: ['Semen Volume', 'Color', 'Viscosity', 'pH', 'Count', 'RBC', 'WBC', 'Motility', 'Deformity'],
  Other: [
    'HPV type 16',
    'HPV type 18',
    'Other Type',
    'Perianal swab',
    'APT test',
    'Chlamydia trachomatis test(Swab)',
    'Neisseria gonorrhoeae DNA test(Swab)',
    'Pus or Discharge(RBC)',
    'Pus or Discharge(WBC)',
  ],
  'Versicle Swab': ['Isolation and identification of virus (Versicle swab)'],
  'Rectal Swab': ['Isolation and identification of virus (Rectal)'],
  Hair: ['MeAMP', 'AMP', 'MDMA', 'MDA', 'K', 'NK', '6-AM', 'Mor'],
  Bronchial: ['Aspergillus Ag-(BAL)'],
  Conjunctiva: ['Isolation and identification of virus (Conjunctival swab)'],
  'Nose (nasal passage)': ['Isolation and identification of virus (Naso-aspirate)'],
  'Marrow (bone)': ['Atypical lymphocyte/lymphoma Screen(B.M.)', 'B-CLPD(B.M.)', 'Acute Leukemia Screen(B.M.)'],
  Sputum: ['PJP DNA'],
  Bone: ['Acute Myeloid Leukemia characterizatiom (B.M.)'],
  Others: [
    'One Touch Sugar',
    'NA(參考用)',
    'pH',
    'PO2',
    'K(參考用)',
    'PCO2',
    'TCO2',
    '[HCO3]',
    'Ht.(參考用)',
    'Ca (Free Calcium)(參考用)',
    'Hb.(參考用)',
    'SO2',
    'Blood Sugar(參考用)',
    'Lactate(參考用)',
    'BE',
    'TSH',
    'FiO2(參考用)',
    'Free T4',
    'PSA',
    'T3',
    'Ca-125',
    'CEA',
    'T4',
    'IGF-1',
    'Ab-TSH Receptor',
    'Thyroglobulin',
    'Cortisol',
    'Ferritin',
    'hGH',
    'Mg(參考用)',
    'CA19-9',
    'CA-153',
    'Testosterone',
    'E2',
    'LH',
    'PRL',
    'FSH',
    'AFP',
    'SMA基因檢測',
    'X染色體脆折症基因檢測',
    'Folic acid(EIA/LIA)',
    'Cl(參考用)',
    '非侵入性胎兒染色體檢測',
    '第二孕期唐氏症篩檢/四指標唐氏症血清篩檢',
    'Free PSA',
    'Anti-Mullerian hormone',
    'ACTH (EIA/LIA)',
    'Insulin',
    'DHEA',
    '25-OHD',
    'Aldosterone',
    'PRA',
    'C-Peptide',
    'VIT-B12(EIA/LIA)',
    'Intact parathyrin EIA/LIA',
    'RPR/VDRL test',
    'Anti-HIV(PrEP)',
    'NSE',
    'Cyfra 21-1',
    'Thalassemia',
    'Calcitonin',
    'GAD-Ab',
    'Insulin Ab titer',
    'E.K.G',
    'HBsAg(Qualitative)(PrEP)',
    'Gastrin',
    '新世代高準確性唐氏症檢測',
    'E3',
    'Prolactin(PRL)(EIA)',
    'Testosterone(EIA)',
    'FSH (EIA)',
    'LH(EIA)',
    'Estradiol(E2)(EIA)',
    '新世代染色體晶片篩檢(血液)(SNP)',
    '17- OHP',
    'Intact parathyroid hormone',
    'Inhibin B',
    '第一孕期唐氏症母血清篩檢',
    'Free-Testosterone',
    'QFT',
    'T3 uptake',
  ],
};
