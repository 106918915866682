import { ChartItem, IndicatorChartQueryParams, ChartOption, ChartKey } from '@/components/charts/core/Interface';
import { ConditionData } from '@/utils/conditions/core';
import ApiHelper from '@/utils/ApiHelper';
import { unset } from 'lodash';
import { getPatientQuery } from '@/utils/query';
import Indicator from '@/utils/indicatorConfig/Indicator';
import { seriesMapper } from '../core/ChartConfig';
import IndicatorChartConfig from './core/IndicatorChartConfig';

enum INDICATOR_TYPES {
  Y = '接受人次',
  N = '未接受人次',
}

const OPTION = {
  series: {
    type: 'pie',
    center: ['50%', '70%'],
    height: '80%',
  },
  tooltip: {
    trigger: 'item',
  },
  legend: {
    left: 0,
    top: 0,
    icon: 'circle',
    data: Object.values(INDICATOR_TYPES),
    selectedMode: false,
  },
};

export default class IndicatorPieConfig extends IndicatorChartConfig {
  constructor(indicator: Indicator) {
    super(ChartKey.IndicatorPie, indicator);

    this.merge(OPTION);
    this.indicator = indicator;
  }

  protected createConditionFromItem(): ConditionData {
    throw new Error('Method not implemented.');
  }

  protected createChartItem(rawItem: any): ChartItem {
    const { key, value, color } = rawItem;

    return {
      key,
      value,
      code: key,
      name: key,
      color,
      percentage: Math.round((value / this.recordCount) * 100.0),
    };
  }

  protected get aggsQuery(): any {
    return null;
  }

  public getQueries() {
    return super.getQueries(getPatientQuery);
  }

  public async applyData(
    params: IndicatorChartQueryParams,
    api: ApiHelper,
    userId: string,
    trackContent: any
  ): Promise<ChartOption> {
    this.merge({
      tooltip: {
        formatter: (seriesList: any) => this.tooltipFormatter(seriesList, api, userId, trackContent),
      },
      series: {
        cursor: 'default',
      },
    });
    this.params = params;

    this.results = await this.fetchData(api, this.getQueries());

    this.recordCount = this.results[0].hits.total.value;
    this.patientCount = this.results[0].aggregations.patientNum.value;

    const accept = this.results[1].hits.total.value;
    const notAccept = this.recordCount - this.results[1].hits.total.value;

    const rawData = [
      {
        key: INDICATOR_TYPES.Y,
        value: accept,
        color: '#333f6b',
      },
      {
        key: INDICATOR_TYPES.N,
        value: notAccept,
        color: '#5e76b4',
      },
    ];

    this.data = this.createChartData(rawData);

    this.reloadChartItemMap();

    unset(this.option, 'series.data');
    this.merge({
      title: {
        show: false,
      },
      series: { data: this.data.map(seriesMapper) },
    });

    this.setAxisData();
    return Promise.resolve(this.getOption());
  }

  protected get hasData(): boolean {
    return this.data.some((item) => item.value !== 0);
  }
}
