import { IAgeConstraint } from '@/utils/conditions/core';

class AgeConstraintQuery {
  // private constraint: IAgeConstraint;

  private start: number;

  private end: number;

  private isTimeRelation: boolean;

  constructor(constraint: IAgeConstraint, isTimeRelation?: boolean) {
    this.start = constraint.start;
    this.end = constraint.end;
    this.isTimeRelation = isTimeRelation || false;
  }

  getQuery() {
    const queryPathName = `${this.isTimeRelation ? 'EVENT_HISTORY.' : ''}AGE`;

    return {
      range: {
        [queryPathName]: {
          gte: this.start,
          lte: this.end,
        },
      },
    };
  }
}

export default AgeConstraintQuery;
