import { merge, assign, unset } from 'lodash';
import numeral from 'numeral';
import dayjs from 'dayjs';
import {
  ChartKey,
  ChartItem,
  ChartDescription,
  RawItem,
  ChartOption,
  BaseChartQueryParams,
  HabitItem,
} from './Interface';
import AggsQuery from '../../../utils/query/core/AggregationQuery';
import ApiHelper from '../../../utils/ApiHelper';
import Chart from '../utils/Chart';
import { ConditionData } from '../../../utils/conditions/core';
import { FEEDBACK, PRECISION_THRESHOLD } from '../../../utils/constants';

const DISTINCT_PATIENT = {
  patientNum: {
    cardinality: {
      field: 'CHART_NO',
      precision_threshold: PRECISION_THRESHOLD,
    },
  },
};

const OPTION = {
  title: {
    subtext: '',
    subtextStyle: {
      color: '#757575',
    },
    left: 'left',
    padding: [20, 0, 0, 20],
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow',
    },
    appendToBody: true,
    confine: true,
  },
  grid: {
    containLabel: true,
    top: 20,
    right: 40,
    bottom: 20,
    left: 20,
  },
  series: {
    type: 'bar',
    barWidth: '70%',
    animation: false,
  },
  color: ['#333f6b', '#5e76b4', '#8ca0d1'],
  animation: false,
};

export function seriesMapper(chartItem: ChartItem | HabitItem): Record<string, unknown> {
  return {
    ...chartItem,
    itemStyle: {
      normal: {
        color: chartItem.color,
      },
    },
  };
}

export default abstract class ChartConfig<RawItemType extends RawItem> implements AggsQuery {
  protected readonly option = {};

  protected readonly key: ChartKey;

  protected readonly chartDescr: ChartDescription;

  protected readonly chartItemMap = new Map<string, ChartItem | HabitItem>();

  protected direction = 'vertical';

  protected hasViewAll = false;

  protected resultSize = 10;

  protected params!: BaseChartQueryParams;

  protected rawData: any;

  protected recordCount = 0;

  protected patientCount = 0;

  protected dataCount = 0;

  public validCount = 0;

  protected bucketByPatient = true;

  protected disallowBucketChange = false;

  protected data: ChartItem[] = [];

  protected logInfo = {
    name: '',
    time: 1,
    diff: 1,
    title: '',
    [FEEDBACK.USER_ID]: '',
  };

  constructor(key: ChartKey) {
    this.key = key;
    this.chartDescr = Chart[this.key];
    this.merge(OPTION);
    this.merge({
      title: {
        text: this.title,
        subtext: this.subtext,
      },
    });
  }

  protected abstract get aggsQuery(): Record<string, unknown>;

  public get chartKey(): ChartKey {
    return this.key;
  }

  public get title(): string {
    return this.chartDescr.Title;
  }

  public get subtext(): string {
    return this.chartDescr.Subtext || '';
  }

  public get hint(): string {
    return this.chartDescr.Hint;
  }

  public get chartDirection() {
    return this.direction;
  }

  public get count() {
    return this.dataCount;
  }

  public get isBucketByPatient() {
    return this.bucketByPatient;
  }

  public get bucketChangeDisallowed() {
    return this.disallowBucketChange;
  }

  public getOption(): ChartOption {
    return {
      ...this.option,
      hasData: this.hasData,
    };
  }

  protected get hasData(): boolean {
    return this.data.length !== 0;
  }

  protected merge(option: Record<string, unknown>): void {
    merge(this.option, option);
  }

  protected assign(option: Record<string, unknown>): void {
    assign(this.option, option);
  }

  protected unset(path: string): void {
    unset(this.option, path);
  }

  protected processQueryParams(): BaseChartQueryParams {
    return this.params;
  }

  protected calculatePercentage(value: number) {
    const denom = this.bucketByPatient ? this.patientCount : this.recordCount;
    return Math.round((value / denom) * 100.0);
  }

  /**
   * Get order-by field. If charts are bucketing by patient, order by patient count.
   * Otherwise, order by record count.
   */
  protected getOrderField() {
    return this.bucketByPatient ? 'patient_count' : '_count';
  }

  protected percentageFormatter(seriesList: any): string {
    let currentSeries: any = null;
    if (Array.isArray(seriesList)) {
      currentSeries = seriesList[0];
    } else {
      currentSeries = seriesList;
    }
    const item = currentSeries.data as ChartItem;
    const formatedNumber = numeral(item.value).format('0,0');
    return `${item.name}: ${formatedNumber} (${item.percentage}%)`;
  }

  protected getHoverName(seriesList: any) {
    let currentSeries: any = null;
    if (Array.isArray(seriesList)) {
      currentSeries = seriesList[0];
    } else {
      currentSeries = seriesList;
    }
    const item = currentSeries.data as ChartItem;
    return `${item.name}`;
  }

  protected abstract createChartItem(rawItem: RawItemType, index: number, array: RawItemType[]): ChartItem | HabitItem;

  public get canViewAll(): boolean {
    return this.hasViewAll;
  }

  public createViewAllConfig(): ChartConfig<RawItemType> {
    throw new Error('Method not implemented.');
  }

  protected setAxisData(): void {
    this.merge({});
  }

  protected abstract createConditionFromItem(chartItem: ChartItem | HabitItem): ConditionData;

  public createCondition(key: string): ConditionData {
    const chartItem = this.chartItemMap.get(key);
    if (chartItem === undefined) {
      throw Error(`Can not find the chart item for the key: ${key}`);
    }
    return this.createConditionFromItem(chartItem);
  }

  protected reloadChartItemMap() {
    this.chartItemMap.clear();
    this.data.forEach((chartItem: ChartItem) => {
      this.chartItemMap.set(chartItem.key, chartItem);
    });
  }

  protected createChartData(rawData: any) {
    return rawData.map((item: any, index: any, array: any) => this.createChartItem(item, index, array));
  }

  protected hoverLog(data: any, apiHelper: ApiHelper, content: any) {
    apiHelper.feedback(FEEDBACK.NAME.CHART_TOOLTIP_HOVER, data, content);
  }

  protected tooltipFormatter(seriesList: any, api: ApiHelper, userId: string, trackContent: any) {
    let diff = 0;
    if (this.logInfo) {
      diff = dayjs().millisecond() - this.logInfo.time;
    }
    if (
      diff > FEEDBACK.HOVER_LOG_TRIGGER_TIME ||
      !this.logInfo.name ||
      this.logInfo.name !== this.getHoverName(seriesList)
    ) {
      this.logInfo = {
        name: this.getHoverName(seriesList),
        time: dayjs().millisecond(),
        diff,
        title: this.title,
        [FEEDBACK.USER_ID]: userId,
        selection: this.params.chartParams?.selection || '',
        [FEEDBACK.MODE]: trackContent[FEEDBACK.MODE],
      };

      this.hoverLog(this.logInfo, api, trackContent);
    }

    return this.percentageFormatter(seriesList);
  }

  public abstract applyData(
    params: BaseChartQueryParams,
    api: ApiHelper,
    userId: string,
    trackContent: any,
    priority: number
  ): Promise<ChartOption>;

  public getBaseAggsQuery(): Record<string, unknown> {
    return {
      ...DISTINCT_PATIENT,
    };
  }

  public getAggsQuery(): Record<string, unknown> {
    return {
      result: {
        ...this.aggsQuery,
      },
      ...DISTINCT_PATIENT,
    };
  }
}
