<template>
  <div class="login-wrapper">
    <VueLoading loader="dots" class="loading" color="rgb(51, 63, 107)" background-color="grey" :active.sync="isLoading"
      :can-cancel="false" :is-full-page="true" />
    <div class="login">
      <div class="login-container">
        <div class="EMR-Search">AICS Lumos</div>
        <b-form v-if="loginStep === 1" @submit.prevent="login1step" >
          <div>
            <div
              class="error-message-div"
              :style="{
                visibility: isAccountError
                  ? 'visible'
                  : 'hidden',
              }"
            >
              <b-img class="remove-icon" src="@/assets/images/login_error.svg" />
              <span
                class="error-message-text error-message-text-1"
              >
                {{ accountErrorMsg }}
              </span>
            </div>
            <b-form-group id="field" label-for="account" label-size="sm" label-align="left"
              label-class="input-label" class="input-group" label="Email or phone number">
              <b-form-input 
                v-model="account" 
                class="input-box" 
                :class="{'input-box--error':isAccountError}" 
                id="account" 
                placeholder="Enter email or phone number" 
                @input="isAccountError = false;"
                autofocus 
              />
            </b-form-group>
            <p v-if="isAccountError" class="contact">
              <span>Still have problems signing in? </span>
              <a :href="`mailto: emr_account@asus.com`">Contact Us</a>
            </p>
          </div>
          <div>
            <b-button type="submit" class="login-btn" :disabled="!account">
              <span class="login-btn-text">LOG IN</span>
            </b-button>
          </div>
          <p class="contact" style="text-align: center; margin-top: 40px;">
            <span>New to AICS Lumos?</span>
            <a :href="`mailto: emr_account@asus.com`">Contact Us to Sign Up</a>
          </p>
        </b-form>
        <b-form v-if="loginStep === 2" @submit.prevent="login2step" >
          <div>
            <div class="verification-code-wrapper">
              <h3 class="tfa-title">Enter Verification Code</h3>
              <p class="tfa-subtitle">{{ this.mfaSubtitle }}</p>
              <p class="tfa-expired-alert">The code will expire in 10 minutes.</p>
              <p class="tfa-resend">
                Didn't receive the code?
                <span class="resend-btn" :class="{'resend-btn--disabled': countDown !== 0}"  @click="resendCode()">Resend</span>
              </p>
              <span class="tfa-wait-count">Wait {{ countDown }} seconds</span>
              <p :style="{
                    visibility: isMfaError
                      ? 'visible'
                      : 'hidden',
                  }"
                class="tfa-error-text"
              >
                {{ mfaErrorMsg }}
              </p>
              <b-form-group class="input-group">
                <b-form-input 
                  v-model="mfaCode" 
                  class="input-box" 
                  :class="{'input-box--error': isMfaError, 'input-box--disabled': isMfaOverLimitError}" 
                  placeholder="Enter code" 
                  @input="isMfaError = false;"
                  :disabled="isMfaOverLimitError"
                  autofocus 
                />
              </b-form-group>
              <b-button type="submit" class="login-btn" :disabled="!mfaCode || isMfaOverLimitError" >
                <span class="login-btn-text">Done</span>
              </b-button>
            </div>
            <div class="another-text" @click="handleClear()">Sign in with a different account</div>
          </div>
        </b-form>
        
        <div class="asus-logo-box">
          <img alt="aics" src="@/../public/img/asus_aics_logo.svg" class="asus-logo-img" />
        </div>
        <div v-if="$browserDetect.isIE" class="ie-warn">
          建議用
          <img alt="chrome-icon" src="@/../public/img/chrome-icon.png" class="chrome-icon" />
          Google Chrome 登入
        </div>
      </div>
    </div>
    <div v-if="isShowExpiredToast" class="expired-toast">
      <b-img  src="@/assets/images/circle_error.svg" />
      Your session expired. Please log in again.
      <button class="close-toast" @click="isShowExpiredToast = false">
        <b-img src="@/assets/images/close.svg" /> 
      </button>
    </div>
  </div>
</template>

<script>
import VueLoading from 'vue-loading-overlay';
import { LoginErrorStatus, LOGIN_ERROR_MESSAGE } from '@/utils/constants';
import { setCookie } from '@/utils/cookie';
import 'vue-loading-overlay/dist/vue-loading.css';

const API_ID = 'med-portal-api'
const STRATEGY = 'one-time-password-auth'

export default {
  components: {
    VueLoading,
  },
  data() {
    return {
      isLoading: false,
      isAccountError: false,
      accountErrorMsg:'',
      isMfaError: false,
      isMfaOverLimitError: false,
      mfaErrorMsg:'',
      account: '',
      loginStep: 1,
      mfaKey: '',
      mfaCode: '',
      countDown: 30,
      countDownId: '',
      isShowExpiredToast: false,
    };
  },
  methods: {
    async login1step() {
      this.isLoading = true;
      this.isAccountError = false;
      this.accountErrorMsg = '';
      try {

        const requestBody = {
          apiId: API_ID,
          strategy: STRATEGY,
        };

        if (this.isAccountEmailType) {
          requestBody.contactEmail = this.account.toLowerCase().trim();
        } else {
          requestBody.phoneNumber = this.account.trim();
        }

        const data = await this.$api
          .login(requestBody)
          .catch((e) => {
            throw new Error(e.request.status);
          });

        if (data.mfaKey) {
          this.mfaKey = data.mfaKey;
          this.loginStep = 2;
          this.countDown = 30;
          this.countDownTimer();
        } else {
          this.$router.push(
            {
              path: '/',
            },
          );
        }
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        this.isAccountError = true;
        this.loginStep = 1;

        if (err.message === LoginErrorStatus.TOO_MANY_REQUESTS) {
          this.accountErrorMsg = LOGIN_ERROR_MESSAGE[LoginErrorStatus.TOO_MANY_REQUESTS];
        } else {
          this.accountErrorMsg = 'Sorry, we couldn\'t find your account. Please check your account and sign in again.';
        }
      }
    },
    async login2step() {
      this.isLoading = true;
      this.isMfaError = false;
      this.mfaErrorMsg = '';

      const requestBody = {
        apiId: API_ID,
        strategy: STRATEGY,
        mfaKey: this.mfaKey,
        mfaCode: this.mfaCode,
      };

      if (this.isAccountEmailType) {
        requestBody.contactEmail = this.account.toLowerCase().trim();
      } else {
        requestBody.phoneNumber = this.account.trim();
      }

      try {
        const respData = await this.$api
          .login(requestBody)
          .catch((e) => {
            throw new Error(e.response.status);
          });

        const data = {
          userId: respData?.id,
          userName: respData?.name,
          userEmail: respData?.email,
          userObj: respData?.user,
          session: respData?.session,
          refreshToken: respData?.refreshToken,
          doctorId: respData?.doctorId,
        };

        setCookie(data);
        this.$router.push(
          {
            path: '/',
          },
        );
      } catch (err) {
        this.isLoading = false;
        this.isMfaError = true;
        if (err.message === LoginErrorStatus.UNAUTHORIZED) {
          this.mfaErrorMsg = LOGIN_ERROR_MESSAGE[LoginErrorStatus.UNAUTHORIZED];
        } else if(err.message === LoginErrorStatus.FORBIDDEN){
          this.mfaErrorMsg = LOGIN_ERROR_MESSAGE[LoginErrorStatus.FORBIDDEN];
          this.isMfaOverLimitError = true;
          setTimeout(() => {
            if(this.isMfaOverLimitError){
              this.isMfaOverLimitError = false;
            }
          }, 30000);
        }
        else{
          this.mfaErrorMsg = 'Login Failed.';
        }
      }
    },
    handleClear() {
      this.isLoading = false;
      this.isAccountError = false;
      this.accountErrorMsg ='';
      this.isMfaError = false;
      this.isMfaOverLimitError = false;
      this.mfaErrorMsg ='';
      this.account = '';
      this.loginStep = 1;
      this.mfaKey = '';
      this.mfaCode = '';
      this.countDown = 30;

      clearTimeout(this.countDownId)
      this.countDownId = '';
    },
    countDownTimer() {
      clearInterval(this.countDownId)
      this.countDownId = setInterval(()=>{
        if (this.countDown > 0) {
          this.countDown -= 1;
        }else{
          clearInterval(this.countDownId)
        }
      },1000)
    },
    resendCode() {
      if (this.countDown === 0) {
        this.isMfaError = false;
        this.isMfaOverLimitError = false;
        this.mfaErrorMsg = '';
        this.mfaCode = '';
        this.login1step();

        this.countDown = 30;
        this.countDownTimer();
      }
    },
  },
  computed: {
    isAccountEmailType() {
      return this.account.includes('@');
    },
    mfaSubtitle() {
      if(this.isAccountEmailType){
        return `An email with a verification code was just sent to: ${this.account}. Enter the code to continue.`
      }else{
        return `A message with a verification code was just sent to: ${this.account}. Enter the code to continue.`
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.handleClear();

      if(to.query?.tokenExpired === 'true'){
        // remove the query in the url
        vm.isShowExpiredToast = true;
        window.history.replaceState(null, null, to.path);
      }
    });
  },
};
</script>
<style lang="scss">
#app {
  margin: 0 !important;
  height: 100%;
}
</style>

<style lang="scss" scoped>
.login-wrapper{
  width: 100vw;
}

.login {
  height: 100vh;
  width: 100%;
  background: #333f6b;
  display: table;
  position: relative;
}

.login .loading {
  position: absolute;
  width: 100%;
  height: 100%;
}

.login-btn {
  width: 400px;
  height: 44px;
  border-radius: 6px !important;
  background: rgb(255, 255, 255) !important;
  margin-top: 98px;

  .login-btn-text {
    width: 54px;
    height: 20px;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333f6b;
  }
}

.login-btn:hover {
  border-radius: 6px !important;
  background: rgb(255, 255, 255) !important;
}

.EMR-Search {
  width: auto;
  height: 41px;
  margin-bottom: 20px;
  object-fit: contain;
  display: inline-block;
  text-align: center;
  font-size: 33px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.asus-logo-box {
  position: absolute;
  left: 50%;
  bottom: 30px;

  .asus-logo-img {
    position: relative;
    left: -50%;
  }
}

::v-deep .login-container {
  margin: auto;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .input-group {
    width: 400px;
    display: block;
    margin: auto;

    .input-label {
      color: white;
      margin: 0px;
    }

    .input-x-icon {
      position: absolute;
      right: 10px;
      top: 40px;
      color: #d6d9e1;
      font-size: 20px;
      cursor: pointer;
    }

    .input-box {
      margin: auto;
      width: 400px;
      height: 44px;
      border-width: 0px;
      border-radius: 6px;
      background-color: rgba(255, 255, 255, 0.1) !important;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: white;

      &:focus {
        border-color: #d6d9e1;
        border-width: 1px;
        box-shadow: inset 0px 0px 0px 0px red;
      }

      &--error{
        border: 2px solid #da6d6d;
      }
      &--disabled{
        cursor: not-allowed;
      }
    }
  }
}

.ie-warn {
  margin-top: 20px;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: white;
  height: 25px;
}

.chrome-icon {
  width: 25px;
  height: 25px;
}



.tfa-resend {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  margin-top: 31px;
}

.tfa-resend-link {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4fc2e7;
  cursor: pointer;
}

.tfa-resend-link-disable {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #646a7e;
}

.tfa-login {
  margin-top: 24px;
  width: 400px;
  max-width: 400px;
  min-width: 400px;
}

.login-btn-2-step {
  width: 400px;
  height: 44px;
  border-radius: 6px;
  background: rgb(255, 255, 255);

  .login-btn-text {
    width: 54px;
    height: 20px;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333f6b;
  }
}

.another-text {
  width: fit-content;
  margin: 40px auto 0;
  font-size: 14px;
  font-weight: bold;
  color: #4fc2e7 !important;
  padding: 4px 8px 4px 8px ;
  cursor: pointer;

  &:hover{
    border-radius: 4px;
    background-color: rgba(79, 194, 231, .2);
    text-decoration: none;
  }
}

.error-message-div{
  width: 400px;
  margin: 0 auto 16px;
  padding: 8px 0 8px 16px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 2px solid #da6d6d;
  background-color: #e2c2c2;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 700;
  color: #eb5757;
  text-align: left;
}

.contact{
  width: fit-content;
  min-width: 400px;
  margin: 8px auto 0 auto;
  height: 18px;
  font-size: 16px;
  text-align: left;
  font-weight: 600;
  color: #fff;

  a{
    color: #4fc2e7 !important;
    padding: 4px 8px 4px 8px ;

    &:hover{
      border-radius: 4px;
      background-color: rgba(79, 194, 231, .2);
      text-decoration: none;
    }
  }
}

.verification-code-wrapper{
  margin: auto;
  width: 488px;
  padding: 32px 44px 40px 44px;
  border-radius: 6px;
  background-color: rgba(227, 227, 228, .06);
  text-align: left;

  .tfa-title {
    margin: 0 0 32px;
    font-size: 24px;
    font-weight: 700;
    color: #ccd6ff;
    text-align: center;
  }

  .tfa-subtitle {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    color: #ccc;
  }

  .tfa-expired-alert{
    margin: 24px 0;
    font-size: 14px;
    font-weight: 400;
    color: #4fc2e7;
  }

  .tfa-resend{    
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    color: #fff;

    .resend-btn{
      color: #4fc2e7;
      padding: 4px 8px 4px 8px;
      cursor: pointer;

      &:hover{
        border-radius: 4px;
        background-color: rgba(79, 194, 231, .2);
        text-decoration: none;
      }

      &--disabled {
        background-color: transparent !important;
        color: #646a7e;
        cursor: not-allowed;
      }
    }
  }

  .tfa-wait-count{
    font-size: 14px;
    font-weight: 400;
    color: rgb(204, 204, 204);
  }

  .tfa-error-text{
    min-height: 18px;
    margin-top: 27px ;
    margin-bottom: 4px ;
    font-size: 14px ;
    font-weight: 700;
    color: #da6d6d;
  }
}

.expired-toast{
  position: absolute;
  margin: auto;
  padding: 12px 16px;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: 6px;
  color: #FFF;
  font-size: 18px;
  font-weight: 700;
  border: 1px solid #FFF;
  background: var(--Red-red-400, #E15E69);
  
  .close-toast{
    padding: 0;
    background: transparent;
    border: none;
    outline: none;
  }
}
</style>
