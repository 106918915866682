import { ConditionFactory, ConditionName, LogicOp, MedicalTerm, TermCondition } from './core';

function getDRGCodeQuery(pcsCode: MedicalTerm[]) {
  return pcsCode.map((item) => ({ term: { TWDRG_CODE: `${item.code}` } }));
}

export default class DRGCodeDef extends TermCondition {
  public getFilter(): Record<string, unknown> {
    return {
      bool: {
        [this.andOr]: getDRGCodeQuery(this.list),
      },
    };
  }

  public get andOr(): LogicOp {
    return super.andOr;
  }
}

ConditionFactory.register(DRGCodeDef, ConditionName.DRGCode);
