import TimeRelationEvent from '@/utils/conditions/core/advance/TimeRelationEvent';
import TimePeriod from '@/utils/conditions/core/advance/TimePeriod';
import TimeEventQuery from './TimeEventQuery';

class CovariantQuery {
  private covariantList: TimeRelationEvent[];

  private timePeriod: TimePeriod;

  constructor(covariantList: TimeRelationEvent[], timePeriod: TimePeriod) {
    this.covariantList = covariantList;
    this.timePeriod = timePeriod;
  }

  public getQuery() {
    const timeEventQueryList = this.covariantList
      .filter((event) => event.condition.length > 0)
      .map((event) => new TimeEventQuery(new TimeRelationEvent(event), this.timePeriod, event.name).getQuery());
    
      return timeEventQueryList.length > 0 ? [
      {
        bool: {
          should: [
            {
              exists: {
                field: 'CHART_NO',
              },
            },
            ...timeEventQueryList,
          ],
        },
      }
    ] : [];
  }
}
export default CovariantQuery;
