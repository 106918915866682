import dayjs from 'dayjs';
import { ConditionData } from '@/utils/conditions/core';
import { ChartItem, RawItem, ChartKey } from '@/components/charts/core/Interface';
import AdvChartConfig from './core/AdvChartConfig';
import { removePoint } from '../utils/utils';

const OPTION = {
  xAxis: {
    type: 'category',
    triggerEvent: true,
    axisLabel: {
      interval: 0,
      rotate: 45,
      margin: 25,
      align: 'center',
    },
  },
  yAxis: {
    type: 'value',
    axisLabel: {
      formatter: removePoint,
    },
  },
  grid: {
    top: 80,
  },
};

interface TrendRawItem extends RawItem {
  key_as_string: string;
  count_unique_patient: {
    value: number;
  };
}

export default class AdvTrendConfig extends AdvChartConfig<TrendRawItem> {
  protected isRangeLessThanOneYear = false;

  constructor() {
    super(ChartKey.AdvTrend);

    this.merge(OPTION);
  }

  protected createConditionFromItem(): ConditionData {
    throw new Error('Method not implemented.');
  }

  protected setAxisData(): void {
    this.unset('xAxis.data');
    this.merge({
      xAxis: {
        data: this.data.map((item) => item.name),
      },
    });
  }

  protected createChartItem(rawItem: TrendRawItem): ChartItem {
    const { key: timestamp } = rawItem;

    const day = dayjs(timestamp);
    const format = this.isRangeLessThanOneYear ? 'YYYY/MM' : 'YYYY';
    const dayString = day.format(format);
    const color = '#333f6b';

    return {
      key: dayString,
      value: rawItem.count_unique_patient.value,
      code: dayString,
      name: dayString,
      color,
      percentage: Math.round((rawItem.count_unique_patient.value / this.recordCount) * 100.0),
    };
  }

  protected get aggsQuery(): Record<string, unknown> {
    const { start, end } = this.params.SearchStateObject.timePeriod;
    this.isRangeLessThanOneYear = end - start === 0;
    const calendar_interval = this.isRangeLessThanOneYear ? '1M' : '1y';

    // filed TIME_KEY to be verified
    return {
      date_histogram: {
        field: 'TIME_KEY',
        calendar_interval,
      },
      aggs: {
        count_unique_patient: {
          cardinality: {
            field: 'CHART_NO',
          },
        },
      },
    };
  }
}
