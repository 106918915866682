import { cloneDeep } from 'lodash';
import { EVENT } from '@/utils/constants';
import { defaultFirstEvent, defaultThirdEvent } from '@/utils/advance/initState';
import { EventStage } from '@/utils/conditions/core/Interface';

export const nameTransform = ({ defaultName, origin, name }) => {
  if (defaultName === name) {
    return origin;
  }
  return name;
};

export const eventTransform = ({ indexEvent, event }) => {
  const result = indexEvent;
  result.constraintList = event.constraintList;
  result.constraint = event.constraint;
  result.condition = event.condition;

  return result;
};

export const resetEvent = (event) => {
  const result = event;
  result.constraintList = [];
  result.constraint = {
    firstOccurrence: false,
  };
  result.condition = [];
  return result;
};

export const resetTimeRelation = ({ firstEvent, thirdEvent }) => {
  // eslint-disable-next-line no-param-reassign
  firstEvent.relation = cloneDeep(defaultFirstEvent.relation);

  // eslint-disable-next-line no-param-reassign
  thirdEvent.relation = cloneDeep(defaultThirdEvent.relation);
  return { firstEvent, thirdEvent };
};

export const checkExistCondition = (event) => event.condition.length !== 0;

export const checkNullEvent = ({ firstEvent, thirdEvent, indexEvent }) =>
  !checkExistCondition(firstEvent) && !checkExistCondition(thirdEvent) && !checkExistCondition(indexEvent);

export const setFoolProof = ({ firstEvent, thirdEvent, indexEvent, cloneAdvancePortal }) => {
  const resetedTimeRelation = resetTimeRelation({ firstEvent, thirdEvent });
  resetedTimeRelation.firstEvent.eventStage = EventStage.INTERVENTION;
  resetedTimeRelation.thirdEvent.eventStage = EventStage.OUTCOME;
  // eslint-disable-next-line no-param-reassign
  cloneAdvancePortal.SearchStateObject = {
    ...cloneAdvancePortal.SearchStateObject,
    eventList: [resetedTimeRelation.firstEvent, resetedTimeRelation.thirdEvent],
    indexEvent,
  };

  return cloneAdvancePortal;
};

export const foolProof = (input) => {
  const cloneAdvancePortal = cloneDeep(input);
  let { indexEvent } = cloneAdvancePortal.SearchStateObject;
  let firstEvent = cloneAdvancePortal.SearchStateObject.eventList[0];
  let thirdEvent = cloneAdvancePortal.SearchStateObject.eventList[1];

  if (!checkExistCondition(indexEvent) && checkExistCondition(firstEvent)) {
    // first event to index event
    indexEvent = eventTransform({ indexEvent, event: firstEvent });
    indexEvent.name = nameTransform({
      defaultName: EVENT.DEFAULT_NAME.FIRST_EVENT,
      origin: indexEvent.name,
      name: firstEvent.name,
    });

    // reset first event
    firstEvent = resetEvent(firstEvent);
    firstEvent.name = EVENT.DEFAULT_NAME.FIRST_EVENT;

    return setFoolProof({
      firstEvent,
      thirdEvent,
      indexEvent,
      cloneAdvancePortal,
    });
  }

  if (!checkExistCondition(indexEvent) && checkExistCondition(thirdEvent)) {
    // third event to index event
    indexEvent = eventTransform({ indexEvent, event: thirdEvent });
    indexEvent.name = nameTransform({
      defaultName: EVENT.DEFAULT_NAME.THIRD_EVENT,
      origin: indexEvent.name,
      name: thirdEvent.name,
    });

    // reset third event
    thirdEvent = resetEvent(thirdEvent);
    thirdEvent.name = EVENT.DEFAULT_NAME.THIRD_EVENT;

    return setFoolProof({
      firstEvent,
      thirdEvent,
      indexEvent,
      cloneAdvancePortal,
    });
  }

  if (
    checkNullEvent({
      firstEvent,
      thirdEvent,
      indexEvent,
    })
  ) {
    return setFoolProof({
      firstEvent,
      thirdEvent,
      indexEvent,
      cloneAdvancePortal,
    });
  }

  if (checkExistCondition(indexEvent) && !checkExistCondition(firstEvent)) {
    firstEvent.relation = cloneDeep(defaultFirstEvent.relation);
  }

  if (checkExistCondition(indexEvent) && !checkExistCondition(thirdEvent)) {
    thirdEvent.relation = cloneDeep(defaultThirdEvent.relation);
  }

  return cloneAdvancePortal;
};

export default {
  nameTransform,
  eventTransform,
  resetEvent,
  resetTimeRelation,
  checkExistCondition,
  checkNullEvent,
  setFoolProof,
  foolProof,
};
