import { ChartKey } from '@/components/charts/core/Interface';
import { EventDiagnosisRange, VisitTypeName } from '@/utils/conditions/core/Interface';

enum APIM_REDIRECT_HOME_QUERY_STR {
  APIM_SESSION = 'session',
  USER_ID = 'userId',
}

enum LoginErrorStatus {
  TOO_MANY_REQUESTS = '429',
  UNAUTHORIZED = '401',
  FORBIDDEN = '403',
}

const LOGIN_ERROR_MESSAGE = {
  [LoginErrorStatus.TOO_MANY_REQUESTS]: 'Please retry again after 30 seconds',
  [LoginErrorStatus.UNAUTHORIZED]: 'Incorrect verification code.',
  [LoginErrorStatus.FORBIDDEN]: 'Incorrect verification code. Please enter the verification code after 30 sec, or request for a new one by clicking on Resend verification code.',
}

const KEY = {
  ACCOUNT: 'account',
  EMAIL: 'email',
  PASSWORD: 'password',
  API_ID: 'apiId',
  DOCTOR_ID: 'doctor_id',
  DIVISION: 'doctor_division',
  COMP: 'comp',
  MFA_KEY: 'mfaKey',
  MFA_CODE: 'mfaCode',
  NHI_ORDER_LIST: 'nhi_order_list',
  ICD10_LIST: 'icd10_list',
  PERMISSION_SELECT: 'select',
  PERMISSION_IS_DIRTY: 'isDirty',
};

const PAGES = {
  HOME: 'Home',
  LOADING: 'Loading',
  SAVED_RESULT: 'Saved List',
  DASHBOARD: 'Dashboard',
  RESULT_TAB: 'Result Tab',
  ANALYZE: 'ANALYZE',
  STATISTIC_SUMMARY: 'Summary',
  LABS: 'Lab Data',
  PATIENT_EMR: 'Patient EMR',
  INPATIENT_EMR: 'Inpatient EMR',
  OUTPATIENT_EMR: 'Outpatient EMR',
  LOGIN: 'Login',
  LOCAL_LOGIN: 'localLogin',
  TRANSFER: 'transfer',
  URL: 'url',
  BY_RECORD: 'EMR Search',
  BY_EVENT: 'By Event',
  COHORT_STUDY: 'Cohort Study',
  CHARACTERISTICS: 'Characteristics',
  INCIDENCE_RATE: 'Incidence Rate',
  COHORT_LIST: 'Cohort List',
  READY_DATASET: 'Ready Dataset',
  ANALYSIS_URL: 'analysis-url',
  SAVE_ANALYSIS: 'Analysis',
  HISTORY_ANALYSIS: 'History',
  SEARCH: 'Search',
};

const OBSERVERS = {
  STATISTIC_SUMMARY: 'summaryHeight',
  LABS: 'labHeight',
  PATIENT_EMR: 'patientEMRHeight',
  INPATIENT_EMR: 'inpatientEMRHeight',
  OUTPATIENT_EMR: 'outpatientEMRHeight',
};

const COOKIE = {
  SESSION_ID: 'aics-lumos-session',
  USER_NAME: 'aics-lumos-userName',
  USER_EMAIL: 'aics-lumos-userEmail',
  USER_ID: 'aics-lumos-user-id',
  REFRESH_TOKEN_ID: 'aics-lumos-refreshToken',
  LOGGED_PORTAL: 'aics-lumos-logged',
  TIMEOUT_FLAG: 'aics-lumos-timeoutFlag',
  DOCTOR_ID: 'aics-lumos-doctor-id',
  ONE_PORTAL_ID: 'aics-lumos-one-portal-id',
};

const SESSION = {
  HEADER: 'ocp-apim-session-token',
  USER_ID: 'userId',
  QUERY: 'session-token',
  UI_CONFIG: 'ui-config',
  PERMISSION_HEADER: 'x-apim-session-token',
  PERMISSION_APPNAME: 'x-apim-app-name',
  DOCTOR_ID: 'doctor-id',
  ONE_PORTAL_ID: 'aics-lumos-one-portal-id',
  LOGIN_TRACK_ID: 'login-track-id',
  SESSION_TRACK_ID: 'session-track-id',
};

const API = {
  SEARCH_INPATIENT: '/_search',
  EMR_SEARCH: '/_search',
  ADV_EMR_SEARCH: '/_search/advance',
  GET_TFIDF: '/tfidf',
  EXPORT_INDICATOR_PATIENT_LIST: '/export_indicator_patient_list',
  EXPORT_INDICATOR_SUMMARY: '/export_indicator',
  EXPORT_EVENT_PATIENT_LIST: '/export_event_patient_list',
  EXPORT_LAB: '/export_lab',
  EXPORT_RECORD: '/export_record',
  EXPORT_SUMMARY: '/export_summary',
  EXPORT_ORDER: '/export-medication-info',
  KEY_SEARCH: '/keysearch',
  KEY_SEARCH_PCS: '/keysearch_pcs',
  FEEDBACK: '/feedback',
  MEDICAL_KEY_SEARCH: '/medical_keysearch/ALL',
  UI_CONFIG: '/ui_config',
  FETCH_BATCH_MEDICAL_INFORMATION: '/fetch_batch_medical_infomation',
  LOGIN: '/login',
  PROFILE_USER: '/user_roles',
  SAVED_QUERY: '/saved_query',
  UPDATE_URL: '/update_url',
  USER: '/user',
  DELETE: '/delete',
  SEARCH: '/search',
  FIRST_TIME: '/usage/first_use',
  FUNCTION_USED: '/usage/function_used',
  KEY_SEARCH_DIAGNOSIS: '/keysearch/diagnosis',
  KEY_SEARCH_DIAGNOSIS_EXPAND: '/keysearch/icd_expansion',
  KEY_SEARCH_DIAGNOSIS_MERGE: '/keysearch/icd_merge',
  SETTING: '/setting',
  PATIENT_COUNT_SEARCH: '/cohort-study/patient-count',
  PATIENT_LIST: '/cohort-study/patient-profile-form',
  PATIENT_CHARACTERISTIC: '/cohort-study/patient-characteristics',
  PATIENT_KMCURVE: '/cohort-study/patient-km-curve',
  PATIENT_COXMODEL: '/cohort-study/patient-hazard-ratio',
  PATIENT_INCIDENT_RATE: '/cohort-study/patient-incidence-rate',
  COHORT_STUDY_EXPORT: '/cohort-study/export',
  COHORT_STUDY: '/cohort-study',
  EXPORT_JOB: '/export-job',
  RERUN_EXPORT_JOB: '/export-job/rerun',
};

const API_TARGET = {
  NHI_ORDER: 'NHI-ORDER',
  PCS: 'PCS',
};

const URL = {
  HELPER_URL: '/doc/emr_user_guide.pdf',
};

const FEEDBACK = {
  MODE_TYPE: {
    RECORD: 'record',
    EVENT: 'event',
    INDICATOR: 'indicator',
    COHORT_STUDY: 'cohortStudy',
  },
  // property keys
  MODE: 'mode',
  TIME: 'time',
  TYPE: 'type',
  COMPONENT_NAME: 'componentName',
  USER_ID: 'userId',
  CLICK_NAME: 'clickName',
  VALUE: 'value',
  ROUTE: 'route',
  FROM_SERVICE: 'fromService',
  // type names
  NAME: {
    TRACK_TIME: 'EMRTrackTime',
    TRACK_CLICK: 'EMRTrackClick',
    KEY_SEARCH: 'EMRKeySearch',
    KEY_SEARCH_PCS: 'EMRKeySearchPCS',
    KEY_SEARCH_PROCEDURE: 'EMRKeySearchProcedure',
    KEY_SEARCH_DOCTOR_ID: 'EMRKeySearchDoctorId',
    KEY_SEARCH_SUCESS: 'EMRKeySearchSuccess',
    KEY_SEARCH_FAIL: 'EMRKeySearchFail',
    SERVIECE_TRANSFER: 'EMRServiceTransfer',
    CHART_TOOLTIP_HOVER: 'EMRChartTooltipHover',
    COUNT_RECORDS: 'EMRCountRecords',
    KEY_SEARCH_DESELECT: 'EMRSearchDeselect',
    EVENT_HOVER: 'EMREventHover',
  },
  QUERY_TYPE: {
    TIMELINE_ALL_RECORD: 'timelineAllRecord',
    CHART_AGGREGATE: 'chartAggregate',
    CHART_AGGREGATE_FIXED: 'chartAggregateFixed',
    DISTINCT_COUNT_AGGREGATE: 'distinctCountAggregate',
    PRETFIDF_AGGREGATE: 'pretfidfAggregate',
    PATIENT_AGGREGATE: 'patientAggregate',
    PATIENT_TOP_AGGREGATE: 'patientTopAggregate',
    LAB_HISTOGRAM_INFO_AGGREGATE: 'labHistogramInfoAggregate',
    LAB_HISTOGRAM_DATA_AGGREGATE: 'labHistogramDataAggregate',
    LAB_TABLE_CHART_NO: 'labTableChartNo',
    TIMELINE_TARGET_RECORD: 'timelineTargetRecord',
    CHART_TFIDF_AGGREGATE: 'chartTfidfAggregate',
    SUGGESTION_TFIDF: 'suggestionTfidf',
    PATIENT_COUNT: 'patientCount',
  },
  HOVER_LOG_TRIGGER_TIME: 500,
};

const CLASS = {
  SECOND_LAYER_ROW: 'second-layer-row',
  PATIENT_LAB_TABLE_COLUMN_DISABLE: 'patient-lab-table-column-disable',
  LAB_RESULT_COLUMN_DISABLE: 'lab-result-column-disable',
};

const CHART = {
  BUCKET: {
    PATIENT: 'patient',
    RECORD: 'record',
  },
  EXCEL_HEADER: {
    [ChartKey.Age]: ['Range', 'Record Count', 'Patient Count'],
    [ChartKey.Gender]: ['Gender', 'Record Count', 'Patient Count'],
    [ChartKey.Medication]: ['Code', 'Medication', 'Record Count', 'Patient Count'],
    [ChartKey.ICD10]: ['Selection', 'Code', 'Diagnosis', 'Record Count', 'Patient Count'],
    [ChartKey.ICD9]: ['Selection', 'Code', 'Diagnosis', 'Record Count', 'Patient Count'],
    [ChartKey.MedicalOrder]: ['Selection', 'Code', 'Order', 'Record Count', 'Patient Count'],
    [ChartKey.Visit]: ['Visit', 'Record Count', 'Patient Count'],
    [ChartKey.Section]: ['Section', 'Record Count', 'Patient Count'],
    [ChartKey.AbnormalLab]: ['Lab', 'Record Count', 'Patient Count'],
    [ChartKey.ReOPDE]: ['Time', 'Record Count', 'Patient Count'],
    [ChartKey.Trend]: ['Year', 'Record Count', 'Patient Count'],
    [ChartKey.Symptom]: ['Symptom', 'Record Count', 'Patient Count'],
    [ChartKey.Stay]: ['Day', 'Record Count', 'Patient Count'],
    [ChartKey.Readmission]: ['Time', 'Record Count', 'Patient Count'],
    [ChartKey.PCSCode]: ['Code', 'Procedure', 'Record Count', 'Patient Count'],
    [ChartKey.ATCCode]: ['Code', 'Generic Name', 'Record Count', 'Patient Count'],
    [ChartKey.DRGCode]: ['Code', 'DRG', 'Record Count', 'Patient Count'],
    [ChartKey.DischargeStatus]: ['Status', 'Record Count', 'Patient Count'],
    [ChartKey.PatientSource]: ['Patient Source', 'Record Count', 'Patient Count'],
    [ChartKey.BMI]: ['BMI', 'Record Count', 'Patient Count'],
  },
  TITLE: {
    AGE: 'Age',
    GENDER: 'Gender',
    DIAGNOSIS: 'ICD-10',
    ICD9: 'ICD-9',
    LENGTH_OF_STAY: 'Length of Stay',
    MEDICATION: 'Medication',
    ATC_CODE: 'ATC Code',
    SYMPTOM: 'Symptom',
    RISK_LEVEL: 'Risk Level',
    PROCEDURE: 'Procedure',
    ABNORMAL_LAB: 'Abnormal Lab',
    FEATURE: 'Feature',
    SECTION: 'Section',
    LAB_INSIGHT: 'Lab Insight',
    VISTI: 'Visit',
    MEDICAL_ORDER: 'Order',
    PCS_CODE: 'PCS Code',
    DRG_CODE: 'DRG Code',
    DISCHARGE_STATUS: 'Discharge Status',
    PATIENT_SOURCE: 'Patient Source',
    BMI: 'BMI',
    READMISSION: 'Readmission',
    REOPDE: 'ReOPDE',
    HABIT: 'Habit',
  },
  FORMAL: {
    AGE: 'Age',
    GENDER: 'Gender',
    DIAGNOSIS: 'ICD-10',
    ICD9: 'ICD-9',
    LENGTH_OF_STAY: 'Length of Stay',
    MEDICATION: 'Medication',
    ATC_CODE: 'ATC Code',
    SYMPTOM: 'Symptom',
    RISK_LEVEL: 'Risk Level',
    PROCEDURE: 'Procedure',
    ABNORMAL_LAB: 'Abnormal Lab',
    FEATURE: 'Feature',
    SECTION: 'Section',
    LAB_INSIGHT: 'Lab Insight',
    PCS_CODE: 'PCS Code',
    DRG_CODE: 'DRG Code',
    DISCHARGE_STATUS: 'Discharge Status',
    PATIENT_SOURCE: 'Patient Source',
    BMI: 'BMI',
    READMISSION: 'Readmission',
    REOPDE: 'ReOPDE',
    HABIT: 'Habit',
  },
};

const UICONFIG = {
  PATIENT_SIZE: 100,
  VIEW_ALL_SIZE: 50,
  MAX_NUM_RESULT_TABS: 20,
  RESULT_TAB_OFFSET: 2,
  SHARD_SIZE: 100,
};

const VERSION = 'v3.3';

const PERMISSION = {
  USERID: 'userId',
  COMPANIES: 'companies',
  RESOURCES: 'resources',
};

const CODE = {
  MED_EMR: 'emr',
  MED_14D: 'med-14day',
  CODE_MED_BI: 'med-bi',
};

const PRIOITY = {
  DEFAULT: 20,
  STATISTIC_SUMMARY: 10,
};

const DEMO_VIDEO_LINK = {
  VIDEO_1: 'demoVideo/demo_video.mp4',
  VIDEO_2: 'demoVideo/EMR_search_demo_video.mp4',
};

const TRANSFER = {
  KEY: {
    TRANSFER_EMR: 'transfer_emr',
  },
};

const EVENT = {
  DEFAULT_NAME: {
    FIRST_EVENT: 'Cohort',
    SECOND_EVENT: 'Intervention',
    THIRD_EVENT: 'Outcome',
    INCLUSION_EVENT: 'include Event',
    EXCLUSION_EVENT: 'exclude Event',
    POPULATION: 'Population',
    INTERVENTION: 'Intervention',
    CORVARIANT: 'Covariate',
    INDEX_DATE: 'index date',
    INTERVENTION_DATE: 'intervention date',
    EXPERIMENTAL_GROUP: 'Experimental Group',
    CONTROL_GROUP: 'Control Group',
    NEW_STUDY: 'New Study',
  },
};

const FEATURES = {
  ADVANCESEARCHMENU: 'AdvanceSearchEventMe',
  DEMO_VIDEO: 'CohortStudyDemoVideo',
  FEEDBACK_HINT: 'FeedbackHint',
  SEND_FEEDBACK_HINT: 'SendFeedbackHint',
  EXPORT_CENTER: 'ExportCenter',
  LANDING: 'Landing',
  LANDING_FLOW: 'LandingFlow',
};

const DOWNLOAD = {
  LABS: 'Lab test data',
  PATIENT_LIST: 'Patient List',
  EVENT_PATIENT_LIST: 'Event patient list',
  RECORDS: 'Demographics and diagnoses',
  SUMMARY: 'Summary',
  CSV: 'csv',
  CSV_MIMETYPE: 'text/csv',
  XLSX: 'xlsx',
  XLSX_MIMETYPE: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ZIP: 'zip',
  ZIP_MIMETYPE: 'application/zip',
  ORDERS: 'Medications',
};

const DIAGNOSIS_RANGE_OPTIONS = [
  {
    lable: '1st code',
    value: EventDiagnosisRange.MAIN,
  },
  {
    lable: '1st~3rd code',
    value: EventDiagnosisRange.TOP3,
  },
];

const VISIT_TYPE_OPTIONS = [
  {
    value: VisitTypeName.OUTPATIENT,
    lable: 'Outpatient',
  },
  {
    value: VisitTypeName.INPATIENT,
    lable: 'Inpatient',
  },
  {
    value: VisitTypeName.ERPATIENT,
    lable: 'ER',
  },
];

const PRECISION_THRESHOLD = 3000;

const INDICATOR = {
  NAME: {
    COPD: 'copd',
  },
  DISPLAY_NAME: {
    COPD: 'COPD',
  },
  MODE: {
    CONDITION: 'condition',
    QUERY: 'query',
  },
  DEFAULT_FACET: '結果',
};

const STATUS_MAP: { [index: string]: string } = {
  unknown: '未知',
  negative: '無',
  positive: '有',
  cease: '戒',
};

const CONSTRAINTS_NAME = {
  AGE: 'Age',
  VISIT_TYPE: 'Visit type',
  PRIMARY_DIAGNOSIS: 'Primary diagnosis',
};

const GROUP_STATE = {
  INIT: 'init',
  EDITING: 'editing',
  DONE: 'done',
};

const SPECIAL_CHARS = {
  COHORT_NAME: '[]:*?/\\',
};

const MAX_NAME_LENGTH = 31;

const VUE_EVENT_NAME = {
  SET_VUEPERFECTSCROLLBAR_UPDATE: 'setVuePerfectScrollbarUpdate',
  GET_INTERVAL_FEEDBACK: 'getIntervalFeedback',
  SET_CODESEARCH_MENU_OPEN: 'setCodeSearchMenuOpen',
  SET_CLICK_CONDITION: 'setClickCondition',
  OPEN_SAVED_TEXT: 'openSavedText',
  CLOSE_SAVED_TEXT: 'closeSavedText',
  OPEN_LINK_COPIED_TEXT: 'openLinkCopiedText',
  CLOSE_LINK_COPIED_TEXT: 'closeLinkCopiedText',
  OPEN_ERROR_MENU: 'openErrorMenu',
  CLOSE_ERROR_MENU: 'closeErrorMenu',
  REFRESH_HISTORY_ANALYSIS_LIST: 'refreshHistoryAnalysisList',
  REFRESH_SAVE_ANALYSIS: 'refreshSaveAnalysis',
  REFRESH_COHORT_SAVED_LIST: 'refreshCohortSavedList',
  SET_COHORT_STUDY_CODESEARCH_MENU_OPEN: 'setCohortStudyCodeSearchMenuOpen',
  SAVE_TO_ICD_CODESEARCH_FILTER: 'saveToICDCodeSearchFilter',
  SYNC_ICD9_UNSELECT_DESCENDANTS_EVENT: 'syncIcd9UnselectDescendantsEvent',
  RESET_ICD9_UNSELECT_DESCENDANTS_EVENT: 'resetIcd9UnselectDescendantsEvent',
  SET_VIEW_DATASET: 'setViewDataSet',
  SET_VIEW_SAVED_ANALYSIS: 'setViewSavedAnalysis',
  SAVE_ANALYSIS_RECORD: 'saveAnalysisRecord',
  OPEN_ANALYSIS_LINK_COPIED_TEXT: 'openAnalysisLinkCopiedText',
  CLOSE_ANALYSIS_LINK_COPIED_TEXT: 'closeAnalysisLinkCopiedText',
  OPEN_ANALYSIS_LINK_SAVE_TEXT: 'openAnalysisLinkSaveText',
  CLOSE_ANALYSIS_LINK_SAVE_TEXT: 'closeAnalysisLinkSaveText',
  FETCH_CHART_DATA: 'fetchChartData',
  BV_DROPDOWN_SHOW: 'bv::dropdown::show',
  OPEN_SAVED_ICD_MAP_TEXT: 'openSavedIcdMapText',
  CLOSE_SAVED_ICD_MAP_TEXT: 'closeSavedIcdMapText',
  SYNC_ICD9_UNSELECT_DESCENDANTS: 'syncIcd9UnselectDescendants',
  RESET_ICD9_UNSELECT_DESCENDANTS: 'resetIcd9UnselectDescendants',
  ADD_TAB_LIMITATION: 'addTabLimitation',
  UNSAVED_ALERT: 'unsavedAlert',
  REFRESH_SAVED_LIST: 'refreshSavedList',
  SET_SEARCH_PADDING_BOTTOM: 'setSearchPaddingBottom',
  CLOSE_SEARCH_PADDING_BOTTOM: 'closeSearchPaddingBottom',
  SET_SCROLL_POSITION: 'setScrollPosition',
  OPEN_DELETED_TEXT_IN_SAVED_SEARCH: 'openDeletedTextInSavedSearch',
  CLOSE_DELETED_TEXT_IN_SAVED_SEARCH: 'closeDeletedTextInSavedSearch',
  OPEN_LINK_COPIED_TEXT_IN_SAVED_SEARCH: 'openLinkCopiedTextInSavedSearch',
  CLOSE_LINK_COPIED_TEXT_IN_SAVED_SEARCH: 'closeLinkCopiedTextInSavedSearch',
  OPEN_ERROR_MENU_IN_SAVED_SEARCH: 'openErrorMenuInSavedSearch',
  CLOSE_ERROR_MENU_IN_SAVED_SEARCH: 'closeErrorMenuInSavedSearch',
  SET_EVENT_SEARCH_TO_TOP: 'setEventSearchToTop',
  SET_EXPORT_JOB: 'setExportJob',
  SET_EMR_SEARCH_SAVED_HINT_VALUE: 'setEMRSearchSavedHintValue',
  REFRESH_EMR_SEARCH_CONCEPT_LIST: 'refreshEMRSearchConceptList',
};

const MAX_EXPORT_LIMIT = 10;

const EMR_SEARCH_TEXT = {
  CONCEPT: 'Concept',
  CRITERIA: 'Criteria',
};

export {
  LoginErrorStatus,
  APIM_REDIRECT_HOME_QUERY_STR,
  KEY,
  PAGES,
  COOKIE,
  SESSION,
  API,
  API_TARGET,
  URL,
  FEEDBACK,
  CLASS,
  CHART,
  UICONFIG,
  VERSION,
  PERMISSION,
  CODE,
  PRIOITY,
  DEMO_VIDEO_LINK,
  TRANSFER,
  EVENT,
  FEATURES,
  DOWNLOAD,
  PRECISION_THRESHOLD,
  OBSERVERS,
  INDICATOR,
  STATUS_MAP,
  DIAGNOSIS_RANGE_OPTIONS,
  VISIT_TYPE_OPTIONS,
  CONSTRAINTS_NAME,
  GROUP_STATE,
  SPECIAL_CHARS,
  MAX_NAME_LENGTH,
  VUE_EVENT_NAME,
  MAX_EXPORT_LIMIT,
  EMR_SEARCH_TEXT,
  LOGIN_ERROR_MESSAGE
};
