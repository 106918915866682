import { CategoryCondition, ConditionFactory, ConditionName } from './core';

function getDischargeStatusQuery(dischargeStatus: string[]) {
  return dischargeStatus.map((value) => {
    let modified_value = value;
    if (modified_value === '無記錄') {
      modified_value = '';
    }

    return {
      term: {
        TURN_NAME: modified_value,
      },
    };
  });
}

export default class DischargeStatusDef extends CategoryCondition {
  public getFilter(): Record<string, unknown> {
    return {
      bool: {
        [this.andOr]: getDischargeStatusQuery(this.list),
      },
    };
  }
}

ConditionFactory.register(DischargeStatusDef, ConditionName.DischargeStatus);
