import TailTimeRelationEvent from '@/utils/conditions/core/advance/TailTimeRelationEvent';
import TimePeriod from '@/utils/conditions/core/advance/TimePeriod';
import { ITailTimeRelationEvent } from '@/utils/conditions/core/Interface';
import { cloneDeep } from 'lodash';
import TimeEventQuery from './TimeEventQuery';

class ExclusionQuery {
  private exclusions: TailTimeRelationEvent[];

  private timePeriod: TimePeriod;
  constructor(exclusions: ITailTimeRelationEvent[], timePeriod: TimePeriod) {
    this.timePeriod = timePeriod;
    const new_exclusions = [];
    for (const i in exclusions){
      if (exclusions[i].constraintList.some((temp)=>temp.constraintType == 'MedicationTotalConstraint' )){
        const tmp_element = cloneDeep(exclusions[i]);
        tmp_element.condition = exclusions[i].condition.filter((item) => item.name != 'Medication');
        new_exclusions.push(tmp_element);
      }
      else{
        new_exclusions.push(exclusions[i]);
      }
    }
    this.exclusions = new_exclusions
      .filter((event) => event.condition.length > 0)
      .map((event: ITailTimeRelationEvent) => new TailTimeRelationEvent(event));
  }

  getQuery() {
    return this.exclusions.map((event: TailTimeRelationEvent) =>
      new TimeEventQuery(event, this.timePeriod).getQuery(true)
    );
  }
}

export default ExclusionQuery;
