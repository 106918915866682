/* eslint-disable class-methods-use-this */
/* eslint-disable no-param-reassign */
import axios, { AxiosInstance, AxiosRequestConfig, Canceler } from 'axios';
import HttpStatus from 'http-status-codes';
import VueCookie from 'vue-cookie';
import PQueue from 'p-queue';
import NodeCache from 'node-cache';
import router from '../router';
import { COOKIE, API, SESSION, PAGES } from './constants';
import dayjs from 'dayjs';
import { getPatientQuery } from '@/utils/query';
import { getQuery } from '@/utils/queryUtils';
import { setCookie } from '@/utils/cookie';

async function postponeAutologout(apiInstance: ApiHelper) {
  const sessionToken = VueCookie.get(COOKIE.SESSION_ID);
  const refreshToken = VueCookie.get(COOKIE.REFRESH_TOKEN_ID);
  const userId = VueCookie.get(COOKIE.USER_ID);
  const userName = VueCookie.get(COOKIE.USER_NAME);
  const userEmail = VueCookie.get(COOKIE.USER_EMAIL);
  const doctorId = VueCookie.get(COOKIE.DOCTOR_ID);

  setCookie({
    session: sessionToken,
    refreshToken,
    userId,
    userName,
    userEmail,
    doctorId,
  });

  clearInterval(apiInstance.timeoutInstance);
  apiInstance.timeoutInstance = setInterval(() => {
    const timeoutFlag = VueCookie.get(COOKIE.TIMEOUT_FLAG);
    if (timeoutFlag !== 'false') {
      clearInterval(apiInstance.timeoutInstance);
      window.location.href = `${window.location.origin}/login`;
    }
  }, 1000) as any;
}

function getHeaderConfig(config: AxiosRequestConfig) {
  const result = {
    [SESSION.HEADER]: VueCookie.get(COOKIE.SESSION_ID),
    [SESSION.USER_ID]: VueCookie.get(COOKIE.USER_ID),
    [SESSION.PERMISSION_HEADER]: VueCookie.get(COOKIE.SESSION_ID),
    [SESSION.PERMISSION_APPNAME]: 'med-portal',
    ...config.headers,
  };
  return result;
}

class ApiHelper {
  public trackId = '';

  public root = '';

  public advanceTrackId = '';

  public advanceRoot = '';

  public cohortTrackId = '';

  public cohortRoot = '';

  public updateApiQueue = new PQueue({ concurrency: 3 });

  public logQueryInfo = {};

  public queryLog = {};

  public selectLog = {};

  public recommendedRanking = {};

  public timeoutInstance?: number;

  public fromService = 'emr';

  public doctorId = '';

  private legacyClient: AxiosInstance;

  private pending = new Map<string, Canceler>();

  private searchCache = new NodeCache();

  private advanceSearchCache = new NodeCache();

  private patientListCache = new NodeCache();

  private patientCharateristicCache = new NodeCache();

  private patientCMCurveCache = new NodeCache();

  private coxModelCache = new NodeCache();

  private incidentRateCache = new NodeCache();

  private cohortPatientCache = new NodeCache();

  private cohortProgress = new NodeCache();

  private addPending(config: AxiosRequestConfig): void {
    if (!config.headers.searchType) {
      return;
    }
    const key = config.headers.searchType;
    config.cancelToken =
      config.cancelToken ||
      new axios.CancelToken((cancel) => {
        if (!this.pending.has(key)) {
          this.pending.set(key, cancel);
        }
      });
  }

  private removePending(config: AxiosRequestConfig): void {
    if (!config.headers.searchType) {
      return;
    }
    const key = config.headers.searchType;
    if (this.pending.has(key)) {
      const cancel = this.pending.get(key)!;
      cancel(key);
      this.pending.delete(key);
    }
  }

  public clearPending(searchType: string | false = false): void {
    if (searchType === false) {
      this.pending.forEach((cancel, key) => cancel(key));
      this.pending.clear();
    } else {
      const toRemove: string[] = [];
      this.pending.forEach((cancel, key) => {
        if (key === searchType) {
          cancel(key);
          toRemove.push(key);
        }
      });
      toRemove.forEach((key) => {
        this.pending.delete(key);
      });
    }
  }

  private clearSearchCache() {
    this.searchCache.flushAll();
  }

  private clearAdvanceSearchCache() {
    this.advanceSearchCache.flushAll();
  }

  public clearAllCache() {
    this.clearSearchCache();
    this.clearAdvanceSearchCache();
  }

  public checkInProgress(query: any, requestUrl: any) {
    const key = JSON.stringify({ ...query, requestUrl });
    return this.cohortProgress.get(key);
  }

  private setCohortProgressData(config: any) {
    const substrings = [
      API.PATIENT_LIST,
      API.PATIENT_CHARACTERISTIC,
      API.PATIENT_KMCURVE,
      API.PATIENT_COXMODEL,
      API.PATIENT_INCIDENT_RATE,
    ];
    if (substrings.some((v) => config.url.includes(v))) {
      const key = JSON.stringify({ ...config.data, requestUrl: config.url });
      if (!this.cohortProgress.get(key)) {
        this.cohortProgress.set(key, JSON.stringify({ startTime: Date.now() }));
      }
    }
  }

  constructor() {
    const headers = {
      'Content-Type': 'application/json',
    };

    this.legacyClient = axios.create({
      baseURL: process.env.VUE_APP_BACKEND_ENDPOINT,
      headers,
    });

    [this.legacyClient].forEach((client, index) => {
      client.interceptors.request.use(
        (config) => {
          // cohort progress data
          this.setCohortProgressData(config);

          this.removePending(config);
          this.addPending(config);

          config.headers = getHeaderConfig(config);
          this.setDoctorId();

          this.setPostponeAutologout(index);
          return config;
        },
        (error) => Promise.reject(error)
      );

      client.interceptors.response.use(
        (response) => {
          this.removePending(response.config);
          if (response.config.responseType === 'blob') {
            return response;
          }
          return response.data;
        },
        (error) => {
          if (error.response && error.response.status === HttpStatus.UNAUTHORIZED) {
            if (router.currentRoute.path !== '/login') {
              router.push({
                name: PAGES.LOGIN,
                query: {
                  tokenExpired: 'true',
                }
              });
            }
          }
          return Promise.reject(error);
        }
      );
    });
  }

  setTrackId(id: string) {
    this.trackId = id;
  }

  setRoot(root: string) {
    this.root = root;
  }

  setAdvanceTrackId(id: string) {
    this.advanceTrackId = id;
  }

  setAdvanceRoot(root: string) {
    this.advanceRoot = root;
  }

  setCohortTrackId(id: string) {
    this.cohortTrackId = id;
  }

  setCohortRoot(root: string) {
    this.cohortRoot = root;
  }

  setLogQueryInfo(logQueryInfo: Record<string, unknown>) {
    this.logQueryInfo = logQueryInfo;
  }

  getPostponeAutologoutFlag(index: number) {
    return index === 0 && !VueCookie.get(COOKIE.DOCTOR_ID) && VueCookie.get(COOKIE.SESSION_ID);
  }

  setPostponeAutologout(index: number) {
    if (this.getPostponeAutologoutFlag(index)) {
      postponeAutologout(this);
    }
  }

  setFromService(service: string) {
    this.fromService = service;
  }

  setDoctorId() {
    if (VueCookie.get(COOKIE.DOCTOR_ID)) {
      this.doctorId = VueCookie.get(COOKIE.DOCTOR_ID);
    }
  }

  login(user: string) {
    return this.legacyClient.post(API.LOGIN, user);
  }

  async search(query: any, priority = 0, searchType: string | false = false) {
    const getCache: string | undefined = this.searchCache.get(JSON.stringify(query));
    if (getCache) {
      return JSON.parse(getCache);
    }
    query.info = this.logQueryInfo;
    this.logQueryInfo = {};
    const fn = () =>
      this.legacyClient.post(API.EMR_SEARCH, query, {
        headers: {
          searchType,
        },
      });
    const result = await this.updateApiQueue.add(fn, { priority });

    delete query.info;
    this.searchCache.set(JSON.stringify(query), JSON.stringify(result));
    return result;
  }

  async advSearch(query: any, priority = 0, searchType: string | false = false) {
    const getCache: string | undefined = this.advanceSearchCache.get(JSON.stringify(query));
    if (getCache) {
      return JSON.parse(getCache);
    }
    query.info = this.logQueryInfo;
    this.logQueryInfo = {};
    const fn = () =>
      this.legacyClient.post(API.ADV_EMR_SEARCH, query, {
        headers: {
          searchType,
        },
      });

    const result = await this.updateApiQueue.add(fn, { priority });

    delete query.info;
    this.advanceSearchCache.set(JSON.stringify(query), JSON.stringify(result));
    return result;
  }

  getTotalPatientCount() {
    let fn = null;

    const query = getQuery({ includes: [], excludes: [] });
    const patientQuery = getPatientQuery(query);
    fn = () => this.legacyClient.post(API.EMR_SEARCH, patientQuery);
    return this.updateApiQueue.add(fn, { priority: 0 });
  }

  async getPatientCount(queries: any, searchState: any) {
    const getCache: string | undefined = this.cohortPatientCache.get(JSON.stringify(queries));
    if (getCache) {
      return JSON.parse(getCache);
    }

    const fn = () =>
      this.legacyClient.post(API.PATIENT_COUNT_SEARCH, { queries, intersection_property: 'CHART_NO', searchState }, {});
    const result = await this.updateApiQueue.add(fn, { priority: 0 });

    this.cohortPatientCache.set(JSON.stringify(queries), JSON.stringify(result));
    return result;
  }

  async getPatientListData(params: any) {
    const getCache: string | undefined = this.patientListCache.get(JSON.stringify(params));
    if (getCache && JSON.parse(getCache).result === 'success') {
      return JSON.parse(getCache);
    }

    const fn = () => this.legacyClient.post(API.PATIENT_LIST, params, {});
    const result = await this.updateApiQueue.add(fn, { priority: 0 });

    this.patientListCache.set(JSON.stringify(params), JSON.stringify(result));
    return result;
  }

  async getPatientCharateristicData(params: any) {
    const getCache: string | undefined = this.patientCharateristicCache.get(JSON.stringify(params));
    if (getCache && JSON.parse(getCache).result === 'success') {
      return JSON.parse(getCache);
    }

    const fn = () => this.legacyClient.post(API.PATIENT_CHARACTERISTIC, params, {});
    const result = await this.updateApiQueue.add(fn, { priority: 0 });

    this.patientCharateristicCache.set(JSON.stringify(params), JSON.stringify(result));
    return result;
  }

  async getPatientCMCurveData(params: any) {
    const getCache: string | undefined = this.patientCMCurveCache.get(JSON.stringify(params));
    if (getCache && JSON.parse(getCache).result === 'success') {
      return JSON.parse(getCache);
    }

    const fn = () => this.legacyClient.post(API.PATIENT_KMCURVE, params, {});
    const result = await this.updateApiQueue.add(fn, { priority: 0 });

    this.patientCMCurveCache.set(JSON.stringify(params), JSON.stringify(result));
    return result;
  }

  async getCoxModelData(params: any) {
    const getCache: string | undefined = this.coxModelCache.get(JSON.stringify(params));
    if (getCache && JSON.parse(getCache).result === 'success') {
      return JSON.parse(getCache);
    }

    const fn = () => this.legacyClient.post(API.PATIENT_COXMODEL, params, {});
    const result = await this.updateApiQueue.add(fn, { priority: 0 });

    this.coxModelCache.set(JSON.stringify(params), JSON.stringify(result));
    return result;
  }

  async getIncidentRateData(params: any) {
    const getCache: string | undefined = this.incidentRateCache.get(JSON.stringify(params));
    if (getCache && JSON.parse(getCache).result === 'success') {
      return JSON.parse(getCache);
    }

    const fn = () => this.legacyClient.post(API.PATIENT_INCIDENT_RATE, params, {});
    const result = await this.updateApiQueue.add(fn, { priority: 0 });

    this.incidentRateCache.set(JSON.stringify(params), JSON.stringify(result));
    return result;
  }

  searchOutpatient(query: any, priority = 0) {
    query.comp = 'OPD';

    query.info = this.logQueryInfo;
    this.logQueryInfo = {};

    // eslint-disable-next-line no-underscore-dangle
    const fn = () => this.legacyClient.post(API.EMR_SEARCH, query);
    return this.updateApiQueue.add(fn, { priority });
  }

  getTfidf(data: any, priority = 1) {
    data.comp = 'ALL';
    data.info = this.logQueryInfo;
    this.logQueryInfo = {};

    const fn = () => this.legacyClient.post(API.GET_TFIDF, data);
    return this.updateApiQueue.add(fn, { priority });
  }

  exportLab(content: any) {
    return this.legacyClient.post(API.EXPORT_LAB, content);
  }

  exportRecord(content: any) {
    return this.legacyClient.post(API.EXPORT_RECORD, content);
  }

  exportSummary(content: any) {
    return this.legacyClient.post(API.EXPORT_SUMMARY, content);
  }

  exportIndicatorSummary(content: any) {
    return this.legacyClient.post(API.EXPORT_INDICATOR_SUMMARY, content);
  }

  exportIndicatorPatientList(content: any) {
    return this.legacyClient.post(API.EXPORT_INDICATOR_PATIENT_LIST, content);
  }

  exportData(endpoint: string, content: any) {
    return this.legacyClient.post(endpoint, content);
  }

  getExportJob() {
    return this.legacyClient.get(API.EXPORT_JOB);
  }

  rerunExportJob(id: any) {
    return this.legacyClient.post(`${API.RERUN_EXPORT_JOB}/${id}`);
  }

  cohortStudyExport(content: any) {
    return this.legacyClient.post(API.COHORT_STUDY_EXPORT, content);
  }

  postCohortStudy(query: any) {
    return this.legacyClient.post(API.COHORT_STUDY, query);
  }

  getCohortStudy(query: any) {
    return this.legacyClient.get(API.COHORT_STUDY, {
      params: {
        ...query,
      },
    });
  }

  searchCohortStudy(content: any) {
    return this.legacyClient.post(`${API.COHORT_STUDY}${API.SEARCH}`, content);
  }

  deleteCohortStudy(url: string) {
    return this.legacyClient.delete(`${API.COHORT_STUDY}/url/${url}`, {});
  }

  updateCohortStudy(url: string, query: any) {
    return this.legacyClient.put(`${API.COHORT_STUDY}/url/${url}`, query);
  }

  getCohortStudyByUrl(url: any) {
    return this.legacyClient.get(`${API.COHORT_STUDY}/url/${url}`);
  }

  searchDiagnosis(query: any) {
    query.non_billable = true;
    query.target = 'CM';
    this.queryLog = query;
    return this.legacyClient.post(API.MEDICAL_KEY_SEARCH, query, {
      headers: {
        searchType: 'diagnosis',
      },
    });
  }

  searchMedication(query: any) {
    query.sug_num = 50;
    query.target = 'ORDER-ID';
    this.queryLog = query;
    return this.legacyClient.post(API.MEDICAL_KEY_SEARCH, query, {
      headers: {
        searchType: 'medication',
      },
    });
  }

  searchProcedure(query: any) {
    this.queryLog = query;
    return this.legacyClient.post(API.MEDICAL_KEY_SEARCH, query, {
      headers: {
        searchType: 'procedure',
      },
    });
  }

  searchLab(query: any) {
    this.queryLog = query;
    return this.legacyClient.post(API.MEDICAL_KEY_SEARCH, query, {
      headers: {
        searchType: 'Lab',
      },
    });
  }

  searchDiagnosisGroupMap(query: any) {
    this.queryLog = query;

    query.highlight_start_tag = '<span style="mix-blend-mode: multiply; background-color: rgba(245, 186, 66, 0.3);">';
    query.highlight_end_tag = '</span>';

    return this.legacyClient.post(API.KEY_SEARCH_DIAGNOSIS, query, {
      headers: {
        searchType: 'diagnosis',
      },
    });
  }

  searchDiagnosisExpand(query: any) {
    query.highlight_start_tag = '<span style="mix-blend-mode: multiply; background-color: rgba(245, 186, 66, 0.3);">';
    query.highlight_end_tag = '</span>';

    return this.legacyClient.post(API.KEY_SEARCH_DIAGNOSIS_EXPAND, query);
  }

  searchDiagnosisMerge(query: any) {
    return this.legacyClient.post(API.KEY_SEARCH_DIAGNOSIS_MERGE, query);
  }

  feedback(name: string, data: any, content: any) {
    return this.legacyClient.post(API.FEEDBACK, {
      name,
      feedback: {
        trackId: content.trackId,
        root: content.root,
        ...data,
        loginTrackId: localStorage.getItem(SESSION.LOGIN_TRACK_ID),
        sessionTrackId: content[SESSION.SESSION_TRACK_ID],
      },
    });
  }

  lookupName(query: any) {
    return this.legacyClient.post(API.FETCH_BATCH_MEDICAL_INFORMATION, query);
  }

  uiConfig() {
    return this.legacyClient.get(API.UI_CONFIG);
  }

  getPermission(session: any) {

    return this.legacyClient.get(API.PROFILE_USER, {
      session,
    } as any);
  }

  saveQuery(name: string, query: any, purpose: string) {
    return this.legacyClient.post(API.SAVED_QUERY, {
      name,
      query,
      purpose,
    });
  }

  getQueryByUrl(url: any) {
    return this.legacyClient.get(`${API.SAVED_QUERY}/url/${url}`);
  }

  updateQuery(url: string, query: any) {
    return this.legacyClient.post(`${API.SAVED_QUERY}${API.UPDATE_URL}/${url}`, {
      update: {
        query,
      },
    });
  }

  getUserQuery(userId: string, { topN = 10, page = 0, purpose = 'save' }) {
    return this.legacyClient.get(
      `${API.SAVED_QUERY}${API.USER}/${userId}?topN=${topN}&page=${page}&purpose=${purpose}`
    );
  }

  deleteQuery(url: string) {
    return this.legacyClient.get(`${API.SAVED_QUERY}${API.DELETE}/${url}`);
  }

  deleteAllQuery() {
    return this.legacyClient.get(`${API.SAVED_QUERY}/_delete`);
  }

  searchQuery(content: any) {
    return this.legacyClient.post(`${API.SAVED_QUERY}${API.SEARCH}`, content);
  }

  getIsFirstTime(userId: string, functionName: string) {
    return this.legacyClient.get(`${API.FIRST_TIME}/${userId}?funct=${functionName}`);
  }

  setNoticeFunction(userId: string, functionName: string) {
    return this.legacyClient.post(`${API.FUNCTION_USED}`, {
      userid: userId,
      funct: functionName,
      timestamp: dayjs().toISOString(),
    });
  }

  getSettings(userId: string) {
    return this.legacyClient.get(`${API.SETTING}/${userId}`);
  }

  setSettings(userId: string, settings: any) {
    return this.legacyClient.post(`${API.SETTING}`, {
      user_id: userId,
      settings,
    });
  }

  /**
   * Provide vue js global instance
   *
   * @static
   * @param {Object} Vue - vue js global instance
   * @memberof ApiHelper
   */
  static install(Vue: any) {
    Vue.prototype.$api = new ApiHelper();
  }
}

export default ApiHelper;
