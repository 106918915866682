export default {
  methods: {
    ICDFormatter(icd10) {
      if (icd10.includes('.') || icd10.length <= 3) {
        return icd10;
      }
      return `${icd10.slice(0, 3)}.${icd10.slice(3)}`;
    },
    chartNoFormatter(chartNo) {
      if (chartNo.length > 9) {
        return `...${chartNo.slice(-9)}`;
      }
      return chartNo;
    },
  },
};
