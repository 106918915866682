import { PRECISION_THRESHOLD } from '@/utils/constants';

export default function getPatientQuery(query) {
  return {
    ...query,
    aggs: {
      patientNum: {
        cardinality: {
          field: 'CHART_NO',
          precision_threshold: PRECISION_THRESHOLD,
        },
      },
      riskAvg: {
        avg: {
          field: 'RISK_GROUP',
        },
      },
    },
    track_total_hits: true,
    size: 0,
  };
}
