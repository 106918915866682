import { cloneDeep } from 'lodash';
import { EVENT } from '@/utils/constants';
import { DateUnit, EventStage } from '@/utils/conditions/core/Interface';

const defaultConditionAndConstraint = {
  constraintList: [],
  constraint: {
    firstOccurrence: false,
  },
  condition: [],
};

const inExclusionList = {
  inclusionList: [],
  exclusionList: [],
};

const defaultFirstEvent = {
  ...cloneDeep(defaultConditionAndConstraint),
  relation: {
    beforeValue: 'any',
    beforeUnit: DateUnit.DAYS,
    afterValue: '0',
    afterUnit: DateUnit.DAYS,
  },
  name: EVENT.DEFAULT_NAME.FIRST_EVENT,
  eventStage: EventStage.COHORT,
  ...cloneDeep(inExclusionList),
};

const defaultThirdEvent = {
  ...cloneDeep(defaultConditionAndConstraint),
  relation: {
    beforeValue: '0',
    beforeUnit: DateUnit.DAYS,
    afterValue: 'any',
    afterUnit: DateUnit.DAYS,
  },
  name: EVENT.DEFAULT_NAME.THIRD_EVENT,
  eventStage: EventStage.OUTCOME,
  ...cloneDeep(inExclusionList),
};

const defaultIndexEvent = {
  ...cloneDeep(defaultConditionAndConstraint),
  name: EVENT.DEFAULT_NAME.SECOND_EVENT,
  eventStage: EventStage.INTERVENTION,
  ...cloneDeep(inExclusionList),
};

const defaultInclusionEvent = {
  ...cloneDeep(defaultConditionAndConstraint),
  relation: {
    beforeValue: 'any',
    beforeUnit: DateUnit.DAYS,
    afterValue: '0',
    afterUnit: DateUnit.DAYS,
  },
  name: EVENT.DEFAULT_NAME.INCLUSION_EVENT,
};

const defaultExclusionEvent = {
  ...cloneDeep(defaultConditionAndConstraint),
  relation: {
    beforeValue: 'any',
    beforeUnit: DateUnit.DAYS,
    afterValue: '0',
    afterUnit: DateUnit.DAYS,
  },
  name: EVENT.DEFAULT_NAME.EXCLUSION_EVENT,
};

const defaultTimeSelectObject = {
  beforeValue: '0',
  beforeUnit: DateUnit.DAYS,
  afterValue: '6',
  afterUnit: DateUnit.MONTHS,
};

export {
  defaultFirstEvent,
  defaultThirdEvent,
  defaultIndexEvent,
  defaultInclusionEvent,
  defaultExclusionEvent,
  defaultTimeSelectObject,
};
