<template>
  <div class="timerelation-block">
    <div class="d-inline-block title-text">Time relation:</div>
    <div class="d-inline-block content-text" v-if="index === 1 && eventType === EventType.FIX_EVENT">
      {{ `${SearchStateObject.eventList[0].name} happened within` }}
    </div>
    <div class="d-inline-block content-text" v-if="index === 3 && eventType === EventType.FIX_EVENT">
      {{ `${SearchStateObject.eventList[1].name} happened within` }}
    </div>
    <div class="d-inline-block content-text" v-if="eventType !== EventType.FIX_EVENT">happened between</div>
    <span v-if="(index === 1 && eventType === EventType.FIX_EVENT) || eventType !== EventType.FIX_EVENT">
      <a-select
        v-model="beforeTimeValue"
        default-value="any"
        class="d-inline-block select-container"
        @change="changeTimeRelation('value')"
        style="width: 70px"
        :getPopupContainer="(trigger) => trigger.parentNode"
      >
        <a-icon slot="suffixIcon" type="caret-down" />
        <a-select-option value="any"> any </a-select-option>
        <template v-if="beforeTimeUnit === DateUnit.DAYS">
          <a-select-option v-for="(n, key) in getBeforeRange" :value="n - 1" :key="key">{{ n - 1 }}</a-select-option>
        </template>
        <template v-if="beforeTimeUnit === DateUnit.MONTHS">
          <a-select-option v-for="(n, key) in getBeforeRange" :value="n - 1" :key="key">{{ n - 1 }}</a-select-option>
        </template>
        <template v-if="beforeTimeUnit === DateUnit.YEARS">
          <a-select-option v-for="(n, key) in getBeforeRange" :value="n - 1" :key="key">{{ n - 1 }}</a-select-option>
        </template>
      </a-select>
      <a-select
        v-model="beforeTimeUnit"
        :default-value="DateUnit.DAYS"
        class="d-inline-block select-container"
        @change="changeTimeRelation('unit')"
        :getPopupContainer="(trigger) => trigger.parentNode"
      >
        <a-icon slot="suffixIcon" type="caret-down" />
        <a-select-option v-for="option in timeUnitList" :value="option" :key="option">
          {{ option }}
        </a-select-option>
      </a-select>
    </span>
    <div
      class="d-inline-block content-text"
      v-if="(index === 1 && eventType === EventType.FIX_EVENT) || eventType !== EventType.FIX_EVENT"
    >
      {{ `before ${indexEventName}` }}
    </div>
    <div v-if="eventType !== EventType.FIX_EVENT" style="width: initial; margin: 10px"></div>
    <div v-if="eventType !== EventType.FIX_EVENT" style="margin-left: 245px; display: inline-flex; position: relative">
      <div class="d-inline-block content-text">and</div>
    </div>
    <span v-if="(index === 3 && eventType === EventType.FIX_EVENT) || eventType !== EventType.FIX_EVENT">
      <a-select
        v-model="afterTimeValue"
        default-value="any"
        class="d-inline-block select-container"
        @change="changeTimeRelation('value')"
        style="width: 70px"
        :getPopupContainer="(trigger) => trigger.parentNode"
      >
        <a-icon slot="suffixIcon" type="caret-down" />
        <a-select-option value="any"> any </a-select-option>
        <template v-if="afterTimeUnit === DateUnit.DAYS">
          <a-select-option v-for="(n, key) in getAfterRange" :value="n - 1" :key="key">{{ n - 1 }}</a-select-option>
        </template>
        <template v-if="afterTimeUnit === DateUnit.MONTHS">
          <a-select-option v-for="(n, key) in getAfterRange" :value="n - 1" :key="key">{{ n - 1 }}</a-select-option>
        </template>
        <template v-if="afterTimeUnit === DateUnit.YEARS">
          <a-select-option v-for="(n, key) in getAfterRange" :value="n - 1" :key="key">{{ n - 1 }}</a-select-option>
        </template>
      </a-select>
      <a-select
        v-model="afterTimeUnit"
        :default-value="DateUnit.DAYS"
        class="d-inline-block select-container"
        @change="changeTimeRelation('unit')"
        :getPopupContainer="(trigger) => trigger.parentNode"
      >
        <a-icon slot="suffixIcon" type="caret-down" />
        <a-select-option v-for="option in timeUnitList" :value="option" :key="option">
          {{ option }}
        </a-select-option>
      </a-select>
    </span>
    <div
      class="d-inline-block content-text"
      v-if="(index === 3 && eventType === EventType.FIX_EVENT) || eventType !== EventType.FIX_EVENT"
    >
      {{ `after ${indexEventName}` }}
    </div>
  </div>
</template>
<script>
import { ClickMixin, AdvanceUpdateMixin, AdvanceSearchMixin } from '@/mixins';
import { DateUnit, EventType } from '@/utils/conditions/core/Interface';
import { foolProof } from '@/utils/advance/foolproof';

import { mapState } from '../../../store';

export default {
  name: 'TimeRelationFilter',
  mixins: [ClickMixin, AdvanceUpdateMixin, AdvanceSearchMixin],
  data() {
    return {
      beforeTimeValue: 'any',
      beforeTimeUnit: DateUnit.DAYS,
      afterTimeValue: 'any',
      afterTimeUnit: DateUnit.DAYS,
      timeUnitList: [DateUnit.DAYS, DateUnit.MONTHS, DateUnit.YEARS],
      DateUnit,
      EventType,
    };
  },
  props: {
    index: {
      type: Number,
      default: 1,
    },
    eventType: {
      type: String,
      default: EventType.FIX_EVENT,
    },
    path: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState('advancePortal', ['SearchStateObject']),
    indexEventName() {
      return foolProof({
        SearchStateObject: this.SearchStateObject,
      }).SearchStateObject.indexEvent.name;
    },
    getBeforeRange() {
      switch (this.beforeTimeUnit) {
        case DateUnit.DAYS:
          return 29;
        case DateUnit.MONTHS:
          return 12;
        case DateUnit.YEARS:
          return this.formatTime() + 1;
        default:
          throw new Error('Get time range error.');
      }
    },
    getAfterRange() {
      switch (this.afterTimeUnit) {
        case DateUnit.DAYS:
          return 29;
        case DateUnit.MONTHS:
          return 12;
        case DateUnit.YEARS:
          return this.formatTime() + 1;
        default:
          throw new Error('Get time range error.');
      }
    },
  },
  mounted() {
    const { relation } = this.getEventDataByPath(this.path);
    this.beforeTimeValue = relation.beforeValue;
    this.beforeTimeUnit = relation.beforeUnit;
    this.afterTimeValue = relation.afterValue;
    this.afterTimeUnit = relation.afterUnit;
  },
  methods: {
    formatTime() {
      const { start } = this.SearchStateObject.timePeriod;
      const { end } = this.SearchStateObject.timePeriod;
      return end - start;
    },
    changeTimeRelation(state, beforeOrAfter = 'before') {
      const timeValue = beforeOrAfter === 'before' ? this.beforeTimeValue : this.afterTimeValue;
      let range = [];
      const getRange = beforeOrAfter === 'before' ? this.getBeforeRange : this.getafterRange;
      range = Array.from(Array(getRange), (_, i) => i + 1);
      if (state === 'unit' && !range.includes(timeValue)) {
        if (beforeOrAfter === 'before') {
          this.beforeTimeValue = 'any';
        } else {
          this.afterTimeValue = 'any';
        }
      }
      this.updateTimeRelation();

      this.advanceTrackClick('changeTimeRelationValue', {
        state,
        beforeTimeUnit: this.beforeTimeUnit,
        beforeTimeValue: this.beforeTimeValue,
        afterTimeUnit: this.afterTimeUnit,
        afterTimeValue: this.afterTimeValue,
        index: this.index,
        path: this.path,
      });
    },
    updateTimeRelation() {
      const currentEventRelation = this.getEventDataByPath(this.path).relation;
      if (this.eventType === EventType.FIX_EVENT) {
        switch (this.index) {
          case 1: {
            currentEventRelation.beforeValue = this.beforeTimeValue;
            currentEventRelation.beforeUnit = this.beforeTimeUnit;
            break;
          }
          case 3: {
            currentEventRelation.afterValue = this.afterTimeValue;
            currentEventRelation.afterUnit = this.afterTimeUnit;
            break;
          }
          default:
            break;
        }
      } else {
        currentEventRelation.beforeValue = this.beforeTimeValue;
        currentEventRelation.beforeUnit = this.beforeTimeUnit;
        currentEventRelation.afterValue = this.afterTimeValue;
        currentEventRelation.afterUnit = this.afterTimeUnit;
      }
      this.updateEventWithPath(
        {
          relation: currentEventRelation,
        },
        this.path
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.title-text {
  height: 20px;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  color: #333333;
}

.content-text {
  height: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 20px;
  color: #333333;
  margin-left: 10px;
}

.select-container {
  width: auto;
  height: 20px;
  margin-left: 10px;
}

/deep/ .ant-select-selection--single {
  border-radius: 30px;
}

/deep/ .ant-select-selection-selected-value {
  font-weight: bold;
  color: #333f6b;
  font-size: 18px;
}

/deep/ .ant-select-arrow {
  color: #333f6b;
}
</style>
