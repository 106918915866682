<template>
  <div
    @click.stop="addVisitTypeConstraint"
    class="advance-ageFilter-adder"
    :class="{ disabled: this.constraintIndex !== -1 }"
  >
    + VISIT
  </div>
</template>

<script>
import { cloneDeep, findIndex } from 'lodash';
import { VisitTypeName } from '@/utils/conditions/core/Interface';
import { AdvanceFilterMixin } from '@/mixins';
import ConstraintType from '@/utils/conditions/core/advance/constraint/Interface';

export default {
  name: 'AdvanceVisitTypeFilter',
  mixins: [AdvanceFilterMixin],
  computed: {
    constraintIndex() {
      return findIndex(this.currentEvent.constraintList, {
        constraintType: ConstraintType.VisitTypeConstraint,
      });
    },
  },
  methods: {
    addVisitTypeConstraint() {
      const visitTypeConstraint = {
        visitType: VisitTypeName.OUTPATIENT,
      };
      const event = cloneDeep(this.currentEvent);

      if (this.constraintIndex === -1) {
        event.constraintList.push({
          constraintType: ConstraintType.VisitTypeConstraint,
          ...visitTypeConstraint,
        });
      }
      const body = {
        constraintList: event.constraintList,
      };
      this.updateEventWithPath(body, this.path);
      this.setCurrentMenu(this.path, ConstraintType.VisitTypeConstraint);
      this.advanceTrackClick('openVisitConstraintFilter', {
        path: this.path,
        index: this.index,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.advance-ageFilter-adder {
  padding: 5px 15px;
  border: 1px solid #4a94d2;
  background: #ffffff;
  border-radius: 53px;
  font-size: 16px;
  line-height: 20px;
  color: #4a94d2;
  margin-left: 10px;
  cursor: pointer;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
