import BasicChartConfig from '@/components/charts/core/BasicChartConfig';
import { BasicChartQueryParams, ChartKey, ChartOption, HabitItem, RawItem } from '@/components/charts/core/Interface';
import { PRECISION_THRESHOLD, STATUS_MAP, UICONFIG } from '@/utils/constants';
import { CodeViewAllOption } from '@/utils/util';
import { ConditionName, HabitConditionData, LogicOp, SearchType } from '../../utils/conditions/core';
import { AxisFormatter, removePoint } from './utils/utils';
import { cloneDeep, unset, sum } from 'lodash';
import ApiHelper from '@/utils/ApiHelper';
import ESQuery from '@/utils/query/core/ESQuery';
import { seriesMapper } from './core/ChartConfig';

const DISTINCT_PATIENT = {
  patientNum: {
    cardinality: {
      field: 'CHART_NO',
      precision_threshold: PRECISION_THRESHOLD,
    },
  },
};

const STATUS = ['negative', 'positive', 'cease'];

const STATUS_CH = ['無', '有', '戒'];

const HABITFIELDS: { [index: string]: string } = {
  smoke: 'SMOKE_STATUS',
  alcohol: 'ALCOHOL_STATUS',
  betelnut: 'BETELNUT_STATUS',
};

const OPTION = {
  xAxis: {
    type: 'value',
    axisLabel: {
      formatter: removePoint,
    },
    name: 'Record',
    nameLocation: 'end',
    nameGap: -30,
    nameTextStyle: {
      padding: [60, 0, 0, 0],
      color: '#757575',
    },
  },
  yAxis: {
    type: 'category',
    inverse: true,
    triggerEvent: false,
    axisLabel: {
      formatter: AxisFormatter,
    },
  },
  legend: {
    left: 0,
    top: 0,
    icon: 'circle',
    data: STATUS_CH,
    selectedMode: false,
  },
  grid: {
    top: 40,
  },
};

interface HabitRawItem extends RawItem {
  buckets: {
    doc_count: number;
    key: string;
  }[];
}

export default class HabitConfig extends BasicChartConfig<HabitRawItem> {
  constructor() {
    super(ChartKey.Habit);
    this.hasViewAll = false;
    this.bucketByPatient = false;
    this.disallowBucketChange = true;

    this.merge(OPTION);
  }

  protected habitData: HabitItem[] = [];

  protected setAxisData(): void {
    this.unset('xAxis.data');
    this.unset('yAxis.data');

    const status: { [key: string]: any } = {};
    STATUS.forEach((key) => (status[key] = []));
    for (const item of this.habitData) {
      for (let i = 0; i < STATUS.length; i++) {
        const key = STATUS[i];
        status[key].push({
          key: `${item.key}.${key}`,
          habit: item.key,
          name: STATUS_MAP[key],
          value: item.values[i],
        });
      }
    }

    const seriesData = [];
    for (const key of STATUS) {
      const content = {
        name: STATUS_MAP[key],
        type: 'bar',
        stack: 'status',
        emphasis: {
          focus: 'series',
        },
        data: status[key],
      };
      seriesData.push(content);
    }

    this.merge({
      xAxis: {
        name: 'Record',
      },
      yAxis: {
        data: this.habitData.map((item) => item.key),
      },
      series: seriesData,
    });
  }

  public createViewAllConfig(): HabitConfig {
    const config = new HabitConfig();
    config.resultSize = UICONFIG.VIEW_ALL_SIZE;
    config.merge({ ...CodeViewAllOption });
    return config;
  }

  protected createChartItem(rawItem: HabitRawItem): HabitItem {
    const { key } = rawItem;
    const values = [];
    for (const status of STATUS) {
      let value = 0;
      for (const item of rawItem.buckets) {
        if (item.key == status) {
          value = item.doc_count;
        }
      }
      values.push(value);
    }

    return {
      habit: key,
      key,
      values: values,
      color: null,
    };
  }

  public createConditionFromItem(chartItem: HabitItem): HabitConditionData {
    return {
      name: ConditionName.Habit,
      tags: [SearchType.Basic, SearchType.Additional],
      andOr: LogicOp.Or,
      list: [
        {
          habit: chartItem.habit,
          status: chartItem.key,
        },
      ],
      habit: chartItem.habit,
    };
  }

  public get aggsQuery(): Record<string, unknown> {
    const orderField = this.getOrderField();
    return {
      terms: {
        field: '',
        size: this.resultSize,
        order: {
          [orderField]: 'desc',
        },
      },
      aggs: {
        patient_count: {
          cardinality: {
            field: 'CHART_NO',
            precision_threshold: PRECISION_THRESHOLD,
          },
        },
      },
    };
  }

  public getAggsQuery(): Record<string, unknown> {
    const results: { [k: string]: any } = {};

    for (const [key, field] of Object.entries(HABITFIELDS)) {
      const currentQuery = cloneDeep(this.aggsQuery);
      currentQuery.terms.field = field;
      results[key] = currentQuery;
    }

    return {
      ...results,
      ...DISTINCT_PATIENT,
    };
  }

  protected getHoverName(seriesList: any) {
    return `${seriesList.data.habit} ${seriesList.seriesName}: ${seriesList.value}`;
  }

  protected percentageFormatter(seriesList: any): string {
    return `${seriesList.data.habit} ${seriesList.seriesName}: ${seriesList.value}`;
  }

  protected get hasData(): boolean {
    for (const item of this.habitData) {
      if (sum(item.values) !== 0) {
        return true;
      }
    }
    return false;
  }

  protected reloadChartItemMap() {
    this.chartItemMap.clear();
    this.habitData.forEach((chartItem: HabitItem) => {
      for (const status of STATUS) {
        const key = `${chartItem.key}.${status}`;
        this.chartItemMap.set(key, {
          habit: chartItem.habit,
          key: status,
          values: chartItem.values,
          color: null,
        });
      }
    });
  }

  public async applyData(
    params: BasicChartQueryParams,
    api: ApiHelper,
    userId: string,
    trackContent: any,
    priority: number
  ): Promise<ChartOption> {
    const formatter = (seriesList: any) => this.tooltipFormatter(seriesList, api, userId, trackContent);
    this.merge({
      tooltip: {
        formatter,
        trigger: 'item',
        triggerOn: 'mousemove|click',
      },
    });

    this.params = cloneDeep(params);
    const query = new ESQuery(this.processQueryParams(), this);
    this.rawData = await api.search(query.getQuery(), priority).catch((error) => {
      console.error(error);
    });

    const results = [];
    for (const key in HABITFIELDS) {
      const content = this.rawData.aggregations[key];
      content.key = key;
      results.push(content);
    }

    this.recordCount = this.rawData.hits.total.value;
    this.patientCount = this.rawData.aggregations.patientNum.value;
    this.validCount = this.rawData.aggregations.valid_record?.value || 0;

    this.habitData = this.createChartData(results);
    this.reloadChartItemMap();

    unset(this.option, 'series.habitData');
    this.merge({
      series: { data: this.habitData.map(seriesMapper) },
    });

    this.setAxisData();
    return Promise.resolve(this.getOption());
  }
}
