<template>
  <div class="export-center button-group" v-click-outside="closeDropdown">
    <div class="update-dot" v-if="analysisList.length !== 0"></div>
    <img
      alt="aics"
      title="Export Manager"
      src="@/../public/img/navbar/icon_export_center.svg"
      style="cursor: pointer"
      @click="clickIcon"
    />
    <div v-if="iconActive" class="export-list-menu">
      <div class="title-text">Export Manager</div>
      <div class="export-count-text mt-20px">{{ `${analysisList.length}/${MAX_EXPORT_LIMIT} Exports` }}</div>
      <div class="ready-text mt-20px" v-if="analysisList.length === 0">There are no exports in queue.</div>
      <VuePerfectScrollbar @wheel.stop class="mt-5px">
        <div
          v-for="(analysis, index) in analysisList"
          :key="index"
          class="d-flex align-items-center justify-content-between analysis-row"
          :class="{ 'analysis-row-bottom-border': analysisList.length - 1 !== index }"
          :style="{ 'padding-bottom': analysisList.length - 1 !== index ? '20px' : '0px' }"
        >
          <div class="analysis-name mr-10px">{{ analysis.name }}</div>

          <div class="d-flex align-items-center justify-content-end">
            <div class="d-flex align-items-center mr-5px" v-if="analysis.status === 'running'">
              <a-progress
                type="circle"
                :percent="progressPercentage[analysis.id] ? progressPercentage[analysis.id] : 0"
                :width="20"
                :strokeWidth="15"
                strokeColor="#4D8EDC"
                trailColor="#E0E0E0"
                :show-info="false"
              />
            </div>
            <div class="progress-percentage-text" v-if="analysis.status === 'running'" style="margin-right: 8px">
              {{ `${progressPercentage[analysis.id] ? progressPercentage[analysis.id] : 0}%` }}
            </div>
            <div class="analysis-status" :class="{ 'analysis-status-error': analysis.status === 'failed' }">
              {{ STATUS[analysis.status] }}
            </div>
            <b-img src="@/assets/images/progress_dot.gif" v-if="analysis.status === 'running'" style="width: 20px" />
            <b-img
              src="@/assets/images/icon_refresh.svg"
              class="ml-5px"
              v-if="analysis.status === 'failed'"
              style="cursor: pointer"
              @click.stop="clickRerunExport(analysis, index)"
            />
          </div>
        </div>
      </VuePerfectScrollbar>
    </div>
    <div class="export-processing-text" v-if="exportProcess">Export processing</div>
  </div>
</template>
<script>
import { MAX_EXPORT_LIMIT, DOWNLOAD, VUE_EVENT_NAME, FEATURES } from '@/utils/constants';
import ClickOutside from 'vue-click-outside';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { delay, calcProgress } from '@/utils/util';
import { sortBy } from 'lodash';
import { mapState } from '@/store';
import { ClickMixin } from '@/mixins';
import { SLA_DEFINITION } from '@/utils/cohortProgressConfig';

const POLLING_PERIOD = 10000; // 10 seconds
const EXPORT_HINT_WAIT = 3000; // 3 seconds

export default {
  name: 'ExportCenter',
  directives: {
    ClickOutside,
  },
  components: {
    VuePerfectScrollbar,
  },
  mixins: [ClickMixin],
  mounted() {
    this.pollingData();

    this.$root.$on(VUE_EVENT_NAME.SET_EXPORT_JOB, this.syncAnalysis);
  },
  destroyed() {
    this.$root.$off(VUE_EVENT_NAME.SET_EXPORT_JOB, this.syncAnalysis);
  },
  data() {
    return {
      iconActive: false,
      MAX_EXPORT_LIMIT,
      analysisList: [],
      STATUS: {
        running: 'Processing',
        pending: 'In queue',
        failed: 'Failed',
      },
      RESULT_TYPE: {
        csv: DOWNLOAD.CSV_MIMETYPE,
        xlsx: DOWNLOAD.XLSX_MIMETYPE,
        zip: DOWNLOAD.ZIP_MIMETYPE,
      },
      exportProcess: false,
      progressTime: {},
      progressPercentage: {},
      exportHintTimeoutL: null,
    };
  },
  computed: {
    ...mapState('user', ['userId']),
    ...mapState([
      'exportIdMappingOPDCount',
      'exportIdMappingIPDCount',
      'EMRSearchExportIdList',
      'cohortStudyExportIdList',
    ]),
  },
  methods: {
    clickIcon() {
      this.iconActive = !this.iconActive;
    },
    closeDropdown() {
      this.iconActive = false;
    },
    download(data) {
      data.forEach((item) => {
        const link = document.createElement('a');
        link.href = item.url;
        link.click();
      });
    },
    processData(data) {
      if (data.result === 'success') {
        this.analysisList = sortBy(data.jobs, ['createdAt']);

        // set progress
        this.analysisList
          .filter((item) => item.status === 'running')
          .forEach((item) => {
            if (!this.progressTime[item.id]) {
              this.updateProgressPercent(item);
            }
          });

        this.download(data.done);
      }
    },
    async updateProgressPercent(data) {
      this.progressTime[data.id] = Date.now();

      while (this.analysisList.find((item) => item.id === data.id)) {
        let SLATime = 200;
        if (this.EMRSearchExportIdList.includes(data.id)) {
          // MAX(IPD, OPD) / 10k * 10sec
          SLATime =
            this.exportIdMappingOPDCount[data.id] !== undefined && this.exportIdMappingIPDCount[data.id] !== undefined
              ? (Math.min(
                  Math.max(this.exportIdMappingOPDCount[data.id], this.exportIdMappingIPDCount[data.id]),
                  100000
                ) /
                  10000) *
                10
              : SLA_DEFINITION.EXPORT_CENTER;
        } else if (this.cohortStudyExportIdList.includes(data.id)) {
          SLATime = SLA_DEFINITION.COHORT_EXPORT;
        }

        this.$set(this.progressPercentage, data.id, calcProgress(this.progressTime[data.id], SLATime));
        await delay(100);
      }
    },
    async pollingData() {
      let result = { result: 'success' };
      while (result.result === 'success') {
        result = await this.$api.getExportJob();
        this.processData(result);
        await delay(POLLING_PERIOD);
      }
    },
    clickRerunExport(data, index) {
      this.$api.rerunExportJob(data.id);
      this.analysisList[index].status = 'pending';
    },
    async syncAnalysis() {
      clearTimeout(this.exportHintTimeout);
      this.exportProcess = true;
      this.exportHintTimeout = setTimeout(() => {
        this.exportProcess = false;
      }, EXPORT_HINT_WAIT);

      const result = await this.$api.getExportJob();
      this.processData(result);

      const firstTime = await this.$api.getIsFirstTime(this.userId, FEATURES.EXPORT_CENTER);

      if (firstTime.first_use) {
        this.iconActive = true;
        this.setUsedFunction(FEATURES.EXPORT_CENTER);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.export-center {
  width: 28px;
}

.button-group {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}

.export-list-menu {
  width: 480px;
  position: absolute;
  padding: 20px 0px 20px 20px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(117, 117, 117, 0.5);
  border-radius: 12px;
  top: 31px;
  margin-left: -300px;
  z-index: 99;
}

.title-text {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #334e97;
}

.title-detail-text {
  font-size: 16px;
  line-height: 19px;
  color: #5b5b5b;
}

.export-count-text {
  font-size: 14px;
  line-height: 17px;
  color: #919191;
}

.analysis-row {
  padding: 20px 20px 20px 0px;
}

.analysis-row-bottom-border {
  box-shadow: inset 0 -1px 0 0 #d6d9e1;
}

.analysis-name {
  font-size: 16px;
  line-height: 19px;
  color: #212121;
}

.analysis-status {
  font-size: 14px;
  line-height: 17px;
  color: #757575;
}

.analysis-status-error {
  font-size: 14px;
  line-height: 17px;
  color: #d93643;
}

/deep/ .ps {
  max-height: 505px;
}

.ready-text {
  font-size: 16px;
  line-height: 19px;
  color: #919191;
  text-align: center;
}

.update-dot {
  width: 8px;
  height: 8px;
  background: #f5d03d;
  border-radius: 99px;
  position: absolute;
  right: -1px;
}

.export-processing-text {
  height: 54px;
  padding: 15px 20px;
  border-radius: 6px;
  box-shadow: 0 4px 10px 0 rgba(51, 63, 107, 0.2);
  border: solid 1px #ffffff;
  background-color: #758cc8;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  position: fixed;
  bottom: 80px;
  left: calc(50% - 94px);
  z-index: 1001;
}

.progress-percentage-text {
  font-size: 16px;
  line-height: 19px;
  color: #4d8edc;
}
</style>
