<template>
  <div>
    <b-img
      src="@/assets/images/icon_analysis_save_event_cohort.svg"
      class="mr-5px"
      style="cursor: pointer"
      title="Save"
      :class="{ 'button-disable': !getID }"
      @click="clickSaveLink"
    />
  </div>
</template>
<script>
import { mapState } from '@/store';
import dayjs from 'dayjs';
import { ClickMixin } from '@/mixins';
import { VUE_EVENT_NAME } from '@/utils/constants';

const LINK_SAVE_WAIT = 3000; // 3 seconds

export default {
  name: 'SaveAnalysis',
  mixins: [ClickMixin],
  props: {
    uniqueIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      saveTextTimeout: null,
    };
  },
  computed: {
    ...mapState('cohortSession', ['tabs']),
    tab() {
      return this.tabs[this.uniqueIndex];
    },
    getID() {
      return this.tab.id;
    },
    eventSearch() {
      return this.tab.eventSearch;
    },
  },
  methods: {
    async clickSaveLink() {
      await this.$api.updateCohortStudy(this.getID, {
        name: this.tab.name,
        updateTime: dayjs().toISOString(),
        request: {
          searchState: this.eventSearch,
        },
      });

      this.showSaveText();
      this.$root.$emit(VUE_EVENT_NAME.REFRESH_SAVE_ANALYSIS);
      this.$root.$emit(VUE_EVENT_NAME.SAVE_ANALYSIS_RECORD);

      this.cohortStudyTrackClick('clickSaveLink');
    },
    showSaveText() {
      clearTimeout(this.saveTextTimeout);
      this.$root.$emit(VUE_EVENT_NAME.OPEN_ANALYSIS_LINK_SAVE_TEXT);
      this.saveTextTimeout = setTimeout(() => {
        this.$root.$emit(VUE_EVENT_NAME.CLOSE_ANALYSIS_LINK_SAVE_TEXT);
      }, LINK_SAVE_WAIT);
    },
  },
};
</script>
<style lang="scss" scoped>
.button-disable {
  opacity: 0.5;
  pointer-events: none;
}
</style>
