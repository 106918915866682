import { CategoryCondition, ConditionName, ConditionFactory } from './core';

function getAbnormalLabQuery(abnormalLab: string[]): Record<string, unknown>[] {
  return abnormalLab.map((value) => ({
    term: {
      ABNORMAL_LIST: value,
    },
  }));
}

export default class AbnormalLabDef extends CategoryCondition {
  public getFilter(): Record<string, unknown> {
    return {
      bool: {
        [this.andOr]: getAbnormalLabQuery(this.list),
      },
    };
  }
}

ConditionFactory.register(AbnormalLabDef, ConditionName.AbnormalLab);
