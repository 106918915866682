import { IBaseMedicalEvent, IOptions, ICondition, IConstraint } from '../Interface';

class BaseMedicalEvent implements IBaseMedicalEvent {
  readonly constraint: IOptions;

  readonly condition: ICondition[];

  readonly name: string;

  readonly constraintList: IConstraint[];

  constructor(baseMedicalEvent: IBaseMedicalEvent) {
    this.constraint = baseMedicalEvent.constraint;
    this.condition = baseMedicalEvent.condition;
    this.name = baseMedicalEvent.name;
    this.constraintList = baseMedicalEvent.constraintList;
  }
}

export default BaseMedicalEvent;
