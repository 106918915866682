export default {
  list: [
    {
      description: 'Other rheumatoid arthritis with rheumatoid factor',
      chineseDescription: '其他類風濕性關節炎伴有類風濕因子',
      highlightDescription: '其他類風濕性關節炎伴有類風濕因子',
      highlightChineseDescription: 'Other rheumatoid arthritis with rheumatoid factor',
      type: 'GroupAndMap',
      patientNum: 2693,
      recordNum: 72473,
      icd10List: ['M05.8'],
      icd9List: ['714.0'],
      candidateIcd10List: ['M05.8'],
      candidateIcd9List: ['714.0'],
      code: 'M05.8',
      billable: false,
      expand: false,
      check: false,
      child: [],
      level: 1,
      parent: 'M05',
      partial: false,
      descendants: [
        'M05.80',
        'M05.81',
        'M05.811',
        'M05.812',
        'M05.819',
        'M05.82',
        'M05.821',
        'M05.822',
        'M05.829',
        'M05.83',
        'M05.831',
        'M05.832',
        'M05.839',
        'M05.84',
        'M05.841',
        'M05.842',
        'M05.849',
        'M05.85',
        'M05.851',
        'M05.852',
        'M05.859',
        'M05.86',
        'M05.861',
        'M05.862',
        'M05.869',
        'M05.87',
        'M05.871',
        'M05.872',
        'M05.879',
        'M05.89',
      ],
      siblings: ['M05.0', 'M05.1', 'M05.2', 'M05.3', 'M05.4', 'M05.5', 'M05.6', 'M05.7', 'M05.8', 'M05.9'],
      selectDescendants: [
        'M05.80',
        'M05.81',
        'M05.811',
        'M05.812',
        'M05.819',
        'M05.82',
        'M05.821',
        'M05.822',
        'M05.829',
        'M05.83',
        'M05.831',
        'M05.832',
        'M05.839',
        'M05.84',
        'M05.841',
        'M05.842',
        'M05.849',
        'M05.85',
        'M05.851',
        'M05.852',
        'M05.859',
        'M05.86',
        'M05.861',
        'M05.862',
        'M05.869',
        'M05.87',
        'M05.871',
        'M05.872',
        'M05.879',
        'M05.89',
      ],
      ancestors: ['M05', 'M05-M14'],
      time: 1646277760611,
    },
    {
      description: 'Rheumatoid arthritis without rheumatoid factor',
      chineseDescription: '類風濕性關節炎未伴有類風濕因子',
      highlightDescription: '類風濕性關節炎未伴有類風濕因子',
      highlightChineseDescription: 'Rheumatoid arthritis without rheumatoid factor',
      type: 'GroupAndMap',
      patientNum: 2692,
      recordNum: 72493,
      icd10List: ['M06.0'],
      icd9List: ['714.0'],
      candidateIcd10List: ['M06.0'],
      candidateIcd9List: ['714.0'],
      code: 'M06.0',
      billable: false,
      expand: false,
      check: false,
      child: [],
      level: 1,
      parent: 'M06',
      partial: false,
      descendants: [
        'M06.00',
        'M06.01',
        'M06.011',
        'M06.012',
        'M06.019',
        'M06.02',
        'M06.021',
        'M06.022',
        'M06.029',
        'M06.03',
        'M06.031',
        'M06.032',
        'M06.039',
        'M06.04',
        'M06.041',
        'M06.042',
        'M06.049',
        'M06.05',
        'M06.051',
        'M06.052',
        'M06.059',
        'M06.06',
        'M06.061',
        'M06.062',
        'M06.069',
        'M06.07',
        'M06.071',
        'M06.072',
        'M06.079',
        'M06.08',
        'M06.09',
      ],
      siblings: ['M06.0', 'M06.1', 'M06.2', 'M06.3', 'M06.4', 'M06.8', 'M06.9'],
      selectDescendants: [
        'M06.00',
        'M06.01',
        'M06.011',
        'M06.012',
        'M06.019',
        'M06.02',
        'M06.021',
        'M06.022',
        'M06.029',
        'M06.03',
        'M06.031',
        'M06.032',
        'M06.039',
        'M06.04',
        'M06.041',
        'M06.042',
        'M06.049',
        'M06.05',
        'M06.051',
        'M06.052',
        'M06.059',
        'M06.06',
        'M06.061',
        'M06.062',
        'M06.069',
        'M06.07',
        'M06.071',
        'M06.072',
        'M06.079',
        'M06.08',
        'M06.09',
      ],
      ancestors: ['M05-M14', 'M06'],
      time: 1646277766774,
    },
    {
      description: 'Other specified rheumatoid arthritis',
      chineseDescription: '其他特定類風濕性關節炎',
      highlightDescription: '其他特定類風濕性關節炎',
      highlightChineseDescription: 'Other specified rheumatoid arthritis',
      type: 'GroupAndMap',
      patientNum: 2690,
      recordNum: 72569,
      icd10List: ['M06.8'],
      icd9List: ['714.0'],
      candidateIcd10List: ['M06.8'],
      candidateIcd9List: ['714.0'],
      code: 'M06.8',
      billable: false,
      expand: false,
      check: false,
      child: [],
      level: 1,
      parent: 'M06',
      partial: false,
      descendants: [
        'M06.80',
        'M06.81',
        'M06.811',
        'M06.812',
        'M06.819',
        'M06.82',
        'M06.821',
        'M06.822',
        'M06.829',
        'M06.83',
        'M06.831',
        'M06.832',
        'M06.839',
        'M06.84',
        'M06.841',
        'M06.842',
        'M06.849',
        'M06.85',
        'M06.851',
        'M06.852',
        'M06.859',
        'M06.86',
        'M06.861',
        'M06.862',
        'M06.869',
        'M06.87',
        'M06.871',
        'M06.872',
        'M06.879',
        'M06.88',
        'M06.89',
      ],
      siblings: ['M06.0', 'M06.1', 'M06.2', 'M06.3', 'M06.4', 'M06.8', 'M06.9'],
      selectDescendants: [
        'M06.80',
        'M06.81',
        'M06.811',
        'M06.812',
        'M06.819',
        'M06.82',
        'M06.821',
        'M06.822',
        'M06.829',
        'M06.83',
        'M06.831',
        'M06.832',
        'M06.839',
        'M06.84',
        'M06.841',
        'M06.842',
        'M06.849',
        'M06.85',
        'M06.851',
        'M06.852',
        'M06.859',
        'M06.86',
        'M06.861',
        'M06.862',
        'M06.869',
        'M06.87',
        'M06.871',
        'M06.872',
        'M06.879',
        'M06.88',
        'M06.89',
      ],
      ancestors: ['M05-M14', 'M06'],
      time: 1646277771267,
    },
    {
      description: 'Arthropathic psoriasis',
      chineseDescription: '關節病型乾癬',
      highlightDescription: '關節病型乾癬',
      highlightChineseDescription: 'Arthropathic psoriasis',
      type: 'GroupAndMap',
      patientNum: 762,
      recordNum: 14974,
      icd10List: ['L40.5'],
      icd9List: ['696.0'],
      candidateIcd10List: ['L40.5'],
      candidateIcd9List: ['696.0'],
      code: 'L40.5',
      billable: false,
      expand: false,
      check: false,
      child: [],
      level: 1,
      parent: 'L40',
      partial: false,
      descendants: ['L40.50', 'L40.51', 'L40.52', 'L40.53', 'L40.54', 'L40.59'],
      siblings: ['L40.0', 'L40.1', 'L40.2', 'L40.3', 'L40.4', 'L40.5', 'L40.8', 'L40.9'],
      selectDescendants: ['L40.50', 'L40.51', 'L40.52', 'L40.53', 'L40.54', 'L40.59'],
      ancestors: ['L40', 'L40-L45'],
      time: 1646277778271,
    },
  ],
};
