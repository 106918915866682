import DataCondition from './DataCondition';
import { CategoryConditionData, LogicOp } from './Interface';

abstract class CategoryCondition extends DataCondition<CategoryConditionData> implements CategoryConditionData {
  public get list(): string[] {
    return this.view.list;
  }

  public get andOr(): LogicOp {
    return this.view.andOr;
  }

  public get view(): CategoryConditionData {
    const mergedList = this.data.map((cond) => cond.list).reduce((results, list) => results.concat(list), []);

    return {
      ...super.view,
      list: mergedList,
    };
  }

  public hitKey(key: string): boolean {
    return this.list.some((item) => item === key);
  }
}

export default CategoryCondition;
