import { COOKIE } from "./constants";
import VueCookie from 'vue-cookie';

interface CookieData {
    doctorId?: string;
    session: string;
    refreshToken: string;
    userId: string;
    userName: string;
    userEmail: string;
}


const setCookie = (data: CookieData)=>{
    const expiresTime = '1h'

    VueCookie.set(COOKIE.SESSION_ID, data.session,
        { expires: expiresTime, domain: process.env.VUE_APP_COOKIE_DOMAIN }
    );
    VueCookie.set(COOKIE.REFRESH_TOKEN_ID, data.refreshToken,
        { expires: expiresTime, domain: process.env.VUE_APP_COOKIE_DOMAIN }
    );
    VueCookie.set(COOKIE.USER_ID, data.userId,
        { expires: expiresTime, domain: process.env.VUE_APP_COOKIE_DOMAIN }
    );
    VueCookie.set(COOKIE.USER_NAME, data.userName,
        { expires: expiresTime, domain: process.env.VUE_APP_COOKIE_DOMAIN }
    );
    VueCookie.set(COOKIE.USER_EMAIL, data.userEmail,
        { expires: expiresTime, domain: process.env.VUE_APP_COOKIE_DOMAIN }
    );
    VueCookie.set(COOKIE.TIMEOUT_FLAG, 'false',
        { expires: expiresTime, domain: process.env.VUE_APP_COOKIE_DOMAIN }
    );
}


const clearAllCookie = ()=>{
    VueCookie.delete(COOKIE.SESSION_ID, { domain: process.env.VUE_APP_COOKIE_DOMAIN });
    VueCookie.delete(COOKIE.USER_NAME, { domain: process.env.VUE_APP_COOKIE_DOMAIN });
    VueCookie.delete(COOKIE.USER_EMAIL, { domain: process.env.VUE_APP_COOKIE_DOMAIN });
    VueCookie.delete(COOKIE.USER_ID, { domain: process.env.VUE_APP_COOKIE_DOMAIN });
    VueCookie.delete(COOKIE.REFRESH_TOKEN_ID, { domain: process.env.VUE_APP_COOKIE_DOMAIN });
    VueCookie.delete(COOKIE.TIMEOUT_FLAG, { domain: process.env.VUE_APP_COOKIE_DOMAIN });
    VueCookie.delete(COOKIE.DOCTOR_ID, { domain: process.env.VUE_APP_COOKIE_DOMAIN });
}


export { setCookie, clearAllCookie }