import { CHART } from '../constants';
import { CategoryCondition, ConditionFactory, ConditionName } from './core';

function getGenderQuery(genders: string[]): Record<string, unknown>[] {
  return genders.map((value) => ({
    term: {
      SEX: value,
    },
  }));
}

export default class GenderDef extends CategoryCondition {
  static get NAME(): string {
    return CHART.TITLE.GENDER;
  }

  public getFilter(): Record<string, unknown> {
    return {
      bool: {
        [this.andOr]: getGenderQuery(this.list),
      },
    };
  }
}

ConditionFactory.register(GenderDef, ConditionName.Gender);
