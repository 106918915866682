<template>
  <div>
    <a-switch :disabled="bucketByRecord" :checked="sortChartByAI" @change="switchTFIDF" />
    <span class="word"> AI Sorting </span>
  </div>
</template>

<script>
import { ClickMixin } from '@/mixins';
import { mapMutations, mapState } from '../../../../store';

export default {
  name: 'TfidfSwitch',
  mixins: [ClickMixin],
  props: {
    uniqueIndex: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapState('session', ['tabs']),
    tab() {
      return this.tabs[this.uniqueIndex];
    },
    sortChartByAI() {
      return this.tab.data.sortChartByAI;
    },
    bucketByRecord() {
      return !this.tab.data.bucketByPatient;
    },
  },
  methods: {
    ...mapMutations('session', ['updateTab']),
    switchTFIDF(check) {
      this.trackClick('switchTFIDF', { check, uniqueIndex: this.uniqueIndex });
      this.updateTab({
        index: this.uniqueIndex,
        data: {
          ...this.tab.data,
          sortChartByAI: check,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.word {
  margin-left: 10px;
  color: #333f6b;
  font-size: 18px;
  line-height: 23px;
  font-weight: bold;
}
</style>
