import { CHART } from '../constants';
import { ConditionFactory, ConditionName, LogicOp, MedicalTerm, TermCondition } from './core';

function getMedicationQuery(medication: MedicalTerm[], searchByPatient: boolean) {
  const fieldName = searchByPatient ? 'MEDICATION_NEW_CODE_HISTORY' : 'MEDICATION_NEW_CODE';
  return medication.map((item) => ({ term: { [fieldName]: `${item.code}` } }));
}

export default class MedicationDef extends TermCondition {
  // static get TYPE(): string { return ConditionType.CATEGORY; }

  static get NAME(): string {
    return CHART.TITLE.MEDICATION;
  }

  public getFilter(): Record<string, unknown> {
    return {
      bool: {
        [this.andOr]: getMedicationQuery(this.list, this.global.searchByPatient),
      },
    };
  }

  public get isHidden(): boolean {
    return this.global.searchByPatient;
  }

  public get andOr(): LogicOp {
    if (this.global.searchByPatient) {
      return LogicOp.Or;
    }
    return super.andOr;
  }
}

ConditionFactory.register(MedicationDef, ConditionName.Medication);
