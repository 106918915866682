import { CHART } from '../constants';
import { ConditionName, RangeCondition, ConditionFactory, LogicOp, RangeData } from './core';

function getReadmissionQuery(field: string, ranges: RangeData[]): Record<string, unknown>[] {
  return ranges.map((range) => ({
    range: {
      [field]: {
        gte: range.start,
        lt: range.end,
      },
    },
  }));
}

export default class ReadmissionDef extends RangeCondition {
  static get NAME(): string {
    return CHART.TITLE.READMISSION;
  }

  public getFilter(): Record<string, unknown> {
    const { key } = this.view;
    let field = '';
    if (key === 'any') {
      field = 'READM_TIMEDELTA';
    } else {
      field = 'READM_NHI_TIMEDELTA';
    }
    return {
      bool: {
        [LogicOp.Or]: getReadmissionQuery(field, this.ranges),
      },
    };
  }
}

ConditionFactory.register(ReadmissionDef, ConditionName.Readmission);
