import Condition from '@/utils/conditions/core/advance/Condition';
import { VisitTypeName } from '@/utils/conditions/core/Interface';
import Constraint from '@/utils/conditions/core/advance/constraint/Constraint';
import ConstraintQueryFactory from './ConstraintQueryFactory';

class ConditionQuery {
  private condition: Condition;

  private isFirstTime: boolean;

  private isTimeRelation: boolean;

  private fieldMap = new Map<string, string>();

  private constraintList: Constraint[];

  private visitType: VisitTypeName;

  private constraintQueryFactory = new ConstraintQueryFactory();

  constructor(condition: Condition, constraintList: Constraint[], isTimeRelation?: boolean, isFirstTime?: boolean) {
    this.condition = condition;
    this.isFirstTime = isFirstTime || false;
    this.isTimeRelation = isTimeRelation || false;
    this.constraintList = constraintList;
    this.visitType = this.constraintQueryFactory.getVisitType(constraintList);
    this.init();
  }

  private init() {
    this.fieldMap.set('ICD10', 'ICD10');
    this.fieldMap.set('ICD9', 'ICD9');
    this.fieldMap.set('MED', 'MEDICATION');
    this.fieldMap.set('MEDICALORDER', 'MEDICAL_ORDER');
  }

  private queryPath(condition: any, visitType: VisitTypeName, conditionType: any) {
    let diagnoisisRange = '';
    if (['ICD9', 'ICD10'].includes(conditionType)) {
      diagnoisisRange = `.${this.constraintQueryFactory.getIcdRange(this.constraintList).toUpperCase()}`;
    }
    return `${this.isTimeRelation ? 'EVENT_HISTORY.' : ''}EVENT.${this.fieldMap.get(
      conditionType.toUpperCase()
    )}.${visitType.toUpperCase()}.${this.isFirstTime && this.isTimeRelation ? 'FIRST' : 'ALL'}${diagnoisisRange}`;
  }

  private firstTimequeryPath(condition: any, diagnosisType?: string | undefined) {
    if (diagnosisType !== undefined) {
      return `BEFORE_EVENT.${this.fieldMap.get(diagnosisType)}`;
    }
    return `BEFORE_EVENT.${this.fieldMap.get(condition.field.toUpperCase())}`;
  }

  getFirstTimeQuery() {
    if (this.condition.name === 'Diagnosis') {
      const subQuery: any[] = [];
      this.condition.list.forEach((c: any) => {
        c.icd9List.forEach((code: any) => {
          subQuery.push({
            regexp: {
              [this.firstTimequeryPath(c, 'ICD9')]: `${code}.*`,
            },
          });
        });
        c.icd10List.forEach((code: any) => {
          subQuery.push({
            regexp: {
              [this.firstTimequeryPath(c, 'ICD10')]: `${code}.*`,
            },
          });
        });
      });
      return subQuery;
    }
    return this.condition.list.map((c: any) => ({
      term: {
        [this.firstTimequeryPath(c)]: `${c.code}`,
      },
    }));
  }

  getLabInnerQueryTemplate(subQuery: any, unitSubQuery: any, condition: any, visit: any): any {
    return {
      bool: {
        filter: [
          {
            term: {
              'EVENT_HISTORY.EVENT.LAB.SPECIMEN': condition.exam,
            },
          },
          {
            term: {
              'EVENT_HISTORY.EVENT.LAB.EXAM_ITEM_NAME': condition.code,
            },
          },
          ...unitSubQuery,
          ...subQuery,
          {
            terms: {
              'EVENT_HISTORY.EVENT.LAB.VISIT_TYPE': visit,
            },
          },
        ],
      },
    }
  }

  getLabQueryTemplate(condition: any, index: any) {
    let visit = [];
    if (this.visitType === VisitTypeName.ALL) {
      visit = ['OUTPATIENT', 'INPATIENT', 'ERPATIENT'];
    } else {
      visit = [this.visitType];
    }
    let firstLabSubQuery: any[] = [];
    let secondLabSubQuery: any[] = [];
    const unitSubQuery =
      condition.unit === ''
        ? []
        : [
          {
            term: {
              'EVENT_HISTORY.EVENT.LAB.UNIT': condition.unit,
            },
          },
        ];
    if (condition.start !== '') {
      if (condition.startOperator === 'e') {
        firstLabSubQuery = [
          {
            term: {
              'EVENT_HISTORY.EVENT.LAB.STRING_VALUE': condition.start,
            },
          },
        ];
      } else {
        firstLabSubQuery = [
          {
            range: {
              'EVENT_HISTORY.EVENT.LAB.VALUE': {
                [condition.startOperator]: condition.start,
              },
            },
          },
        ];
      }
    }

    if (condition.end !== '') {
      if (condition.endOperator === 'e') {
        secondLabSubQuery = [
          {
            term: {
              'EVENT_HISTORY.EVENT.LAB.STRING_VALUE': condition.end,
            },
          },
        ];
      } else {
        secondLabSubQuery = [
          {
            range: {
              'EVENT_HISTORY.EVENT.LAB.VALUE': {
                [condition.endOperator]: condition.end,
              },
            },
          },
        ];
      }
    }

    if (!condition.mutilRow) {
      return [
        {
          bool: {
            [condition.andOr]: [
              {
                nested: {
                  path: 'EVENT_HISTORY.EVENT.LAB',
                  inner_hits: {
                    name: `lab_value-${index}`,
                    docvalue_fields: [
                      'EVENT_HISTORY.EVENT.LAB.VALUE',
                      'EVENT_HISTORY.EVENT.LAB.STRING_VALUE'
                    ],
                    _source: false
                  },
                  query: this.getLabInnerQueryTemplate(firstLabSubQuery, unitSubQuery, condition, visit),
                },
              },
            ],
          },
        },
      ];
    } else {
      return [
        {
          bool: {
            filter: [
              {
                nested: {
                  path: 'EVENT_HISTORY.EVENT.LAB',
                  inner_hits: {
                    name: `lab_value-${index}`,
                    docvalue_fields: [
                      'EVENT_HISTORY.EVENT.LAB.VALUE',
                      'EVENT_HISTORY.EVENT.LAB.STRING_VALUE'
                    ],
                    _source: false
                  },
                  query: {
                    bool: {
                      [condition.andOr]: [
                        this.getLabInnerQueryTemplate(firstLabSubQuery, unitSubQuery, condition, visit),
                        this.getLabInnerQueryTemplate(secondLabSubQuery, unitSubQuery, condition, visit),
                      ],
                    },
                  },
                },
              },
            ],
          },
        },
      ];
    }
  }

  getDeathQueryTemplate() {
    return {
      term: {
        'EVENT_HISTORY.EVENT.DEATH': true,
      },
    };
  }

  getDiagnosisQueryTemplate(c: any, visitType: any) {
    const subQueryList: any[] = [];
    c.icd9List.forEach((code: any) => {
      subQueryList.push({
        regexp: {
          [this.queryPath(code, visitType, 'ICD9')]: `${code}.*`,
        },
      });
    });
    c.icd10List.forEach((code: any) => {
      subQueryList.push({
        regexp: {
          [this.queryPath(code, visitType, 'ICD10')]: `${code}.*`,
        },
      });
    });
    return subQueryList;
  }

  getQuery() {
    const queryList: any[] = [];
    this.condition.list
      .filter((c: any) => c.field === 'Death')
      .forEach(() => queryList.push(this.getDeathQueryTemplate()));
    this.condition.list
      .filter((c: any) => c.field === 'lab')
      .forEach((c: any, index: any) => queryList.push(...this.getLabQueryTemplate(c, index)));

    if ([VisitTypeName.INPATIENT, VisitTypeName.ALL].includes(this.visitType)) {
      this.condition.list
        .filter((c: any) => ['med', 'medicalOrder'].includes(c.field))
        .forEach((c: any) =>
          queryList.push({
            term: {
              [this.queryPath(c, VisitTypeName.INPATIENT, c.field)]: `${c.code}`,
            },
          })
        );
      this.condition.list
        .filter(() => ['Diagnosis'].includes(this.condition.name))
        .forEach((c: any) => queryList.push(...this.getDiagnosisQueryTemplate(c, VisitTypeName.INPATIENT)));
    }
    if ([VisitTypeName.OUTPATIENT, VisitTypeName.ALL].includes(this.visitType)) {
      this.condition.list
        .filter((c: any) => ['med', 'medicalOrder'].includes(c.field))
        .forEach((c: any) =>
          queryList.push({
            term: {
              [this.queryPath(c, VisitTypeName.OUTPATIENT, c.field)]: `${c.code}`,
            },
          })
        );
      this.condition.list
        .filter(() => ['Diagnosis'].includes(this.condition.name))
        .forEach((c: any) => queryList.push(...this.getDiagnosisQueryTemplate(c, VisitTypeName.OUTPATIENT)));
    }
    if ([VisitTypeName.ERPATIENT, VisitTypeName.ALL].includes(this.visitType)) {
      this.condition.list
        .filter((c: any) => ['med', 'medicalOrder'].includes(c.field))
        .forEach((c: any) =>
          queryList.push({
            term: {
              [this.queryPath(c, VisitTypeName.ERPATIENT, c.field)]: `${c.code}`,
            },
          })
        );
      this.condition.list
        .filter(() => ['Diagnosis'].includes(this.condition.name))
        .forEach((c: any) => queryList.push(...this.getDiagnosisQueryTemplate(c, VisitTypeName.ERPATIENT)));
    }
    return queryList;
  }
}

export default ConditionQuery;
