<template>
  <div class="basic-search">
    <EMRSearch />
  </div>
</template>

<script>
import EMRSearch from '@/components/forms/EMRSearch.vue';
import { TrackTimeMixin, PatientNumberMixin, ClickMixin } from '@/mixins';
import { mapSubUiConfig } from '@/utils/uiConfig';

export default {
  name: 'BasicSearch',
  mixins: [TrackTimeMixin, PatientNumberMixin, ClickMixin],
  components: {
    EMRSearch,
  },
  computed: {
    ...mapSubUiConfig('SearchForm'),
  },
};
</script>

<style lang="scss" scoped>
.basic-search {
  background-color: #ffffff;
}
</style>
