<template>
  <div class="advance-codeSearch-filter" v-click-outside="closeSearchMenu">
    <div class="type-menu" v-if="isOpenMenu && selectType === 1" @click.stop="">
      <b-row no-gutters>
        <b-col>
          <b-row no-gutters>
            <b-col class="option-text" @click.stop="clickType('Diagnosis')">Diagnosis</b-col>
          </b-row>
          <b-row no-gutters>
            <b-col class="option-text" @click.stop="clickType('Medication')">Medication</b-col>
          </b-row>
          <b-row no-gutters>
            <b-col class="option-text" @click.stop="clickType('Medical Order')">Medical Order</b-col>
          </b-row>
          <b-row no-gutters v-if="hasLab">
            <b-col class="option-text" @click.stop="clickType('Lab')">Lab</b-col>
          </b-row>
          <b-row no-gutters v-if="isOutcome">
            <b-col class="option-text" @click.stop="clickType('Death')" v-if="path[0]">Death</b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
    <div v-if="selectType !== 1 && selectType !== -1 && isOpenMenu" class="search-menu" @click.stop="">
      <b-row no-gutters>
        <b-col class="menu-title">{{ selectType }}</b-col>
        <b-col class="d-flex justify-content-end">
          <b-img src="@/assets/images/icon_close.svg" class="close-icon" @click="closeSearchMenu" />
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col>
          <div v-if="selectType === 'Diagnosis' && isOpenMenu" class="filter-container">
            <CohortICDCodeSearchFilter
              @setValue="handleAdvanceDiagnosis"
              :conditions="[]"
              :codeCondition="{ list: [] }"
              :placeholder="uiConfig.searchPlaceholder.DX"
              :fetchOptions="fetchICDGroupMap"
              feedbackField="diagnosis"
              :advance="true"
              :refCondition="getCodeSearchListRef(ConditionName.Diagnosis)"
              :index="index"
              :eventType="eventType"
              :path="path"
            />
          </div>
          <div v-if="selectType === 'Medication' && isOpenMenu" class="filter-container">
            <CodeSearchFilter
              @setValue="handleAdvanceMedication"
              :conditions="[]"
              :codeCondition="{ list: [] }"
              :placeholder="uiConfig.searchPlaceholder.MED"
              :fetchOptions="fetchMedication"
              feedbackField="medication"
              :advance="true"
              :refCondition="getCodeSearchListRef(ConditionName.Medication)"
              :index="index"
              :eventType="eventType"
              :path="path"
            />
          </div>
          <div v-if="selectType === 'Medical Order' && isOpenMenu" class="filter-container">
            <CodeSearchFilter
              @setValue="handleAdvanceMedicalOrder"
              :conditions="[]"
              :codeCondition="{ list: [] }"
              :placeholder="uiConfig.searchPlaceholder.ORDER"
              :fetchOptions="fetchMedicalOrder"
              feedbackField="Medical Order"
              :advance="true"
              :refCondition="getCodeSearchListRef(ConditionName.MedicalOrder)"
              :index="index"
              :eventType="eventType"
              :path="path"
            />
          </div>
          <div v-if="selectType === 'Lab' && isOpenMenu" class="filter-container">
            <CodeSearchFilter
              @setValue="handleAdvanceLab"
              :conditions="[]"
              :codeCondition="{ list: [] }"
              :placeholder="uiConfig.searchPlaceholder.LAB"
              :fetchOptions="fetchLab"
              feedbackField="Lab"
              :advance="true"
              :refCondition="getCodeSearchListRef(ConditionName.Lab)"
              :index="index"
              :eventType="eventType"
              :path="path"
            />
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import { cloneDeep, isEqual } from 'lodash';

import ClickOutside from 'vue-click-outside';

import CodeSearchFilter from '@/components/byEvent/filter/CodeSearchFilter.vue';
import CohortICDCodeSearchFilter from '@/components/byEvent/filter/CohortICDCodeSearchFilter.vue';
import { EventType, ConditionName } from '@/utils/conditions/core/Interface';
import FormMixin from '@/mixins/formMixin';
import { EventMixin, Utils, EventUpdateMixin, ClickMixin, EventSearchMixin } from '@/mixins';
import FilterMixin from '@/mixins/filterMixin';
import ConditionList from '@/utils/ConditionList';
import { mapSubUiConfig } from '@/utils/uiConfig';
import { VUE_EVENT_NAME } from '@/utils/constants';

export default {
  name: 'CodeSearchMenu',
  components: {
    CodeSearchFilter,
    CohortICDCodeSearchFilter,
  },
  mixins: [FormMixin, EventMixin, Utils, FilterMixin, EventUpdateMixin, ClickMixin, EventSearchMixin],
  directives: {
    ClickOutside,
  },
  props: {
    index: {
      type: Number,
      default: 1,
    },
    eventType: {
      type: String,
      default: EventType.FIX_EVENT,
    },
    path: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    selectType() {
      this.$emit('updateSelectType', this.selectType);
    },
    isOpenMenu() {
      if (!this.isOpenMenu) {
        this.selectType = -1;
      }
    },
  },
  data() {
    return {
      selectType: -1,
      ConditionName,
      nameToLocalDataMapper: new Map([
        [ConditionName.Diagnosis, ConditionList.diagnosisGroupMap],
        [ConditionName.Medication, ConditionList.medication],
        [ConditionName.MedicalOrder, ConditionList.medicalOrder],
        [ConditionName.Death, ConditionList.death],
        [ConditionName.Lab, ConditionList.lab],
      ]),
    };
  },
  mounted() {
    this.$root.$on(VUE_EVENT_NAME.SET_CLICK_CONDITION, (path) => {
      if (!isEqual(this.path, path)) {
        this.closeSearchMenu();
      }
    });
  },
  destroyed() {
    this.$root.$off(VUE_EVENT_NAME.SET_CLICK_CONDITION);
  },
  computed: {
    ...mapSubUiConfig('SearchForm'),
    isOpenMenu() {
      return this.isCurrentMenu(this.path, 'AdvanceCodeSearchMenu');
    },
    isOutcome() {
      return this.path.includes('outcome');
    },
    hasLab() {
      return this.path.includes('inclusionList') || this.path.includes('exclusionList');
    },
    storeCondition() {
      return this.currentEvent.condition;
    },
  },
  methods: {
    clickCondition() {
      this.openSearchMenu();
      this.cohortStudyTrackClick('clickCondition', {
        path: this.path,
        index: this.index,
      });
    },
    openSearchMenu() {
      if (this.selectType !== -1) {
        this.selectType = -1;
      } else {
        this.setCurrentMenu(this.path, 'AdvanceCodeSearchMenu');
        this.selectType = 1;
      }
    },
    closeSearchMenu() {
      this.selectType = -1;
    },
    clickType(value) {
      if (value === 'Death') {
        this.handleAdvanceDeath([
          {
            code: 'Death',
            label: 'Death',
            description: '',
            chineseDescription: '',
            value: 'Death',
            type: 'Death',
            patientNum: 0,
            recordNum: 0,
            field: 'Death',
            time: Date.now(),
          },
        ]);
        this.selectType = -1;
      } else {
        this.selectType = value;
        this.setCurrentMenu(this.path, 'AdvanceCodeSearchMenu');
      }
      this.cohortStudyTrackClick('clickType', {
        path: this.path,
        index: this.index,
        value,
      });
    },
    getTemplateCondition(name) {
      const mapperCondition = this.nameToLocalDataMapper.get(name);
      const condition = this.storeCondition.find((item) => item.name === name);
      return condition ? cloneDeep(condition) : cloneDeep(mapperCondition);
    },
    syncConditionToStore(condition, value) {
      condition.list = value;

      const index = this.storeCondition.findIndex((item) => item.name === condition.name);
      const storeCondition = cloneDeep(this.storeCondition);
      if (condition.list.length === 0) {
        storeCondition.splice(index, 1);
      } else if (index > -1) {
        storeCondition[index] = condition;
      } else {
        storeCondition.push(condition);
      }
      this.updateEventWithPath(
        {
          condition: storeCondition,
        },
        this.path
      );
    },
    handleAdvanceDiagnosis(value) {
      const condition = this.getTemplateCondition(ConditionName.Diagnosis);
      this.syncConditionToStore(condition, value);
    },
    handleAdvanceMedication(value) {
      const condition = this.getTemplateCondition(ConditionName.Medication);
      this.syncConditionToStore(condition, value);
    },
    handleAdvanceMedicalOrder(value) {
      const condition = this.getTemplateCondition(ConditionName.MedicalOrder);
      this.syncConditionToStore(condition, value);
    },
    handleAdvanceDeath(value) {
      const condition = this.getTemplateCondition(ConditionName.Death);
      this.syncConditionToStore(condition, value);
    },
    handleAdvanceLab(value) {
      const condition = this.getTemplateCondition(ConditionName.Lab);
      this.syncConditionToStore(condition, value);
    },
    clearCondition() {
      this.updateEventWithPath(
        {
          condition: [],
        },
        this.path
      );

      this.cohortStudyTrackClick('clearCondition', {
        path: this.path,
        index: this.index,
      });
    },
    getCodeSearchListRef(name) {
      return this.getTemplateCondition(name).list;
    },
  },
};
</script>
<style lang="scss" scoped>
.condition-add-text {
  padding: 5px 15px;
  background: #4a94d2;
  border-radius: 53px;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;
}

.condition-add-text-focus {
  background: #0f4895;
}

.type-menu {
  position: absolute;
  width: 135px;
  background: #ffffff;
  border: 1px solid #bdbdbd;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(51, 63, 107, 0.2);
  border-radius: 5px;
  z-index: 1;
  margin: 6px 0px 0px 20px;
  padding: 0px 0px 20px 0px;
}

.option-text {
  height: 18px;
  text-align: left;
  font-size: 14px;
  line-height: 18px;
  color: #333333;
  margin: 20px 0px 0px 20px;
  cursor: pointer;
}

.highlight-option-text {
  color: #0f4895;
  font-weight: bold;
}

.filter-container {
  margin: 21px 0px 0px 0px;
}

.search-menu {
  position: absolute;
  z-index: 1;
  border: 1px solid #0f4895;
  box-shadow: 0px 4px 10px rgba(51, 63, 107, 0.2);
  border-radius: 6px;
  padding: 20px 0px 0px 0px;
  background: #ffffff;
  height: auto;
  margin: 2px 0px 0px 0px;
  width: -webkit-fill-available;
}

.menu-title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #334e97;
  margin-left: 20px;
}

.close-icon {
  cursor: pointer;
  margin-right: 20px;
}

.disable-type {
  opacity: 0.3;
  pointer-events: none;
  cursor: not-allowed;
}
</style>
<style scoped src="@/css/home.css"></style>
<style scoped src="@/css/searchForm.css"></style>
<style lang="scss" scoped src="@/css/searchForm.scss"></style>
