import { ConditionData, GlobalData } from './Interface';
import Condition from './Condition';

abstract class DataCondition<DataType extends ConditionData> extends Condition {
  constructor(protected readonly data: DataType[], protected readonly global: GlobalData) {
    super(data, global);
  }

  public toString(): string {
    return JSON.stringify(this.data);
  }

  public get view(): DataType {
    return this.data[0];
  }
}

export default DataCondition;
