import DataCondition from './DataCondition';
import { KeywordConditionData, LogicOp } from './Interface';

abstract class KeywordCondition extends DataCondition<KeywordConditionData> implements KeywordConditionData {
  public get list(): string[] {
    return this.view.list;
  }

  public get andOr(): LogicOp {
    return this.view.andOr;
  }

  public get fields(): string[] {
    return this.view.fields;
  }

  public get view(): KeywordConditionData {
    const mergedList = this.data.map((cond) => cond.list).reduce((results, list) => results.concat(list), []);

    return {
      ...super.view,
      list: mergedList,
    };
  }
}

export default KeywordCondition;
