import { ChartKey } from './Interface';

const TFIDFMap = new Map<ChartKey, string>([
  [ChartKey.Symptom, 'Symptom'],
  [ChartKey.AbnormalLab, 'Abnormal Lab'],
  [ChartKey.Procedure, 'Procedure'],
  [ChartKey.Medication, 'Medication'],
  [ChartKey.MedicalOrder, 'MedicalOrder'],
  [ChartKey.ICD10, 'ICD-10'],
  [ChartKey.ICD9, 'ICD-9'],
  [ChartKey.ATCCode, 'ATCCode'],
  [ChartKey.PCSCode, 'PCSCode'],
  [ChartKey.DRGCode, 'DRGCode'],
]);

export default TFIDFMap;
