import { CHART } from '../constants';
import { CategoryCondition } from './core';

function getStayQuery(stayDays: string[]) {
  return stayDays.map((value) => ({
    term: {
      DURATION: value,
    },
  }));
}

export default class StayDef extends CategoryCondition {
  static get NAME(): string {
    return CHART.TITLE.LENGTH_OF_STAY;
  }

  public getFilter(): Record<string, unknown> {
    return {
      bool: {
        [this.andOr]: getStayQuery(this.list),
      },
    };
  }
}
