<template>
  <div>
    <a-spin id="loading-spin" v-if="loading" />
    <div v-if="tab.counters.ALL.Patient > 0">
      <div :class="loading ? 'block' : ''">
        <div class="hint-text">The first examination results of patients in search conditions</div>
        <div>
          <a-select
            id="filter"
            style="width: 200px; margin-top: 20px"
            placeholder="Diagnosis"
            v-model="labName"
            defaultValue="Blood"
            v-if="labNameList"
            @change="changeLabName()"
            :getPopupContainer="(trigger) => trigger.parentNode"
          >
            <a-select-option
              v-for="option in labNameList"
              :value="option.name"
              :key="option.name"
              :label="option.name"
              :title="option.name"
              :disabled="option.disable"
            >
              <template v-if="labListSet[option.name]">
                {{ option.name }}
                <template v-if="labListSet[option.name] && tab.counters.ALL.Patient < 2000"> </template>
              </template>
            </a-select-option>
          </a-select>
          <a-input
            :placeholder="LAB_FILTER_PLACEHOLDER"
            class="filter-input"
            v-model="labColumnFilter"
            v-if="columns[1]"
          />
        </div>
        <a-table
          class="lab-table"
          :columns="filteredColumns"
          :dataSource="finalDatas"
          :pagination="false"
          :scroll="{ x: 1500 }"
          tableLayout="auto"
          :customRow="setCustomRow"
        >
          <span slot="chart_no" slot-scope="chart_no">
            <a-tag :key="chart_no" class="tag-chart-no">
              <a-icon type="user" style="color: #333f6b" class="profile-icon" />
              {{ chartNoFormatter(chart_no) }}
            </a-tag>
          </span>
        </a-table>
      </div>
      <div :class="loading ? 'block' : ''">
        <div class="pagin">
          <div id="last-page-hint" v-if="current === Math.ceil(UICONFIG.PATIENT_SIZE / pageSize)">
            For more information, click EXPORT.
          </div>
          <a-pagination
            v-show="total > pageSize"
            class="pagin"
            size="small"
            v-model="current"
            :total="Math.min(UICONFIG.PATIENT_SIZE, total)"
            @change="handleTableChange"
            showLessItems
            :itemRender="itemPagerRender"
          />
        </div>
      </div>
    </div>
    <div v-if="showNoResults">
      <a-row type="flex" justify="center" align="top" style="margin-top: 15px">
        <a-col>
          <SearcClearExtendFilterhBar :uniqueIndex="uniqueIndex" />
        </a-col>
      </a-row>
    </div>
    <LabHistogram :title="modalTitle" :unit="modalUnit" :exam="labName" ref="labHistogram" :uniqueIndex="uniqueIndex" />
  </div>
</template>
<script>
import { ClickMixin, LabMixin, queryMixin, Utils } from '@/mixins';
import { handleFirstLayer } from '@/utils/lab/getTableConfig';
import { getFilteredAggrQuery } from '@/utils/lab/getLabAggrQuery';
import { filterColumn } from '@/utils/util';
import SearcClearExtendFilterhBar from '@/components/analysis/status/clear/ClearExtendFilter.vue';
import { CLASS, UICONFIG, PAGES, VUE_EVENT_NAME } from '@/utils/constants';
import LabHistogram from '@/components/modals/LabHistogram.vue';
import { difference, debounce } from 'lodash';
import { mapMutations, mapState } from '../../store';
import { getLabName, getDefaultLabName } from '../../utils/lab/LabName';
import { VisitType } from '../../utils/conditions/core';

const pageSize = 10;

export default {
  name: 'LabResult',
  mixins: [ClickMixin, LabMixin, queryMixin, Utils],
  data() {
    return {
      patientList: [],
      datas: [],
      columns: [],
      total: 0,
      loading: false,
      labName: getDefaultLabName(),
      labNameList: [],
      labListSet: {},
      patientLabMapping: {},
      current: 1,
      labColumnFilter: '',
      modalTitle: '',
      modalUnit: '',
      UICONFIG,
      PAGES,
      pageSize,
      LAB_FILTER_PLACEHOLDER: 'Filter lab items (i.e. "CRE;Hb")',
      finalDatas: [],
    };
  },
  async mounted() {
    await this.fetchLabData().catch((error) => {
      console.error(error);
    });
  },
  computed: {
    ...mapState('session', ['tabs']),
    showNoResults() {
      if (this.loading) {
        return false;
      }
      return this.tab.counters.ALL.Patient === 0;
    },
    filteredColumns() {
      if (!this.columns || !this.labName) {
        return [];
      }
      const result = this.columns.filter((column) => {
        return column.title === 'Patient ID' || column.title === this.labName;
      });
      const foundLabName = result.find((item) => item.title === this.labName);
      if (!foundLabName) {
        // eslint-disable-next-line max-len
        const labs = this.LabNames[this.labName].slice(0, pageSize);
        result.push({
          title: this.labName,
          children: labs.map((item) => this.handleColumn({ CHHEAD: item }, item, 0)),
          align: 'left',
          className: 'first-layer-row',
        });
      }
      if (this.labColumnFilter) {
        result[1].children = filterColumn('disable', result, CLASS.LAB_RESULT_COLUMN_DISABLE, this.labColumnFilter);
      }
      if (this.labColumnFilter.length === 0 && result[1]) {
        result[1].children = filterColumn('enable', result, CLASS.LAB_RESULT_COLUMN_DISABLE, this.labColumnFilter);
      }
      return result;
    },
    LabNames() {
      return getLabName(VisitType.ALL);
    },
    tab() {
      if (this.tabs.length === 0) {
        return {};
      }
      return this.tabs[this.uniqueIndex];
    },
    conditionState() {
      return JSON.stringify([this.tab.includes, this.tab.excludes]);
    },
  },
  props: {
    uniqueIndex: {
      type: Number,
      default: 0,
    },
  },
  components: {
    SearcClearExtendFilterhBar,
    LabHistogram,
  },
  methods: {
    ...mapMutations('condition', ['setLabselect', 'setStateColumn']),
    ...mapState('condition', ['stateColumn']),
    getFilteredAggrQuery,
    handleFirstLayer,
    setLabDisable(labListSet, options) {
      return Object.keys(this.LabNames).forEach((labName) => {
        let option = {};
        if (labName in labListSet) {
          option = { name: labName, disable: false, count: labListSet[labName] };
        } else {
          option = { name: labName, disable: true, count: 0 };
        }
        options.push(option);
      });
    },
    async getLabList() {
      try {
        this.loading = true;
        const labListSet = {};
        const patientLabMapping = {};
        const patientList = await this.getPatientTopRecord(['LAB_CATEGORY', 'CHART_NO'], this.patientList, {
          currentView: PAGES.LABS,
        }).catch((error) => {
          console.error(error);
        });
        if (!patientList) {
          return;
        }
        patientList.forEach((patient) => {
          // LAB_CATEGORY
          const top =
            patient.top.hits.hits.length !== 0
              ? // eslint-disable-next-line no-underscore-dangle
                patient.top.hits.hits[0]._source.LAB_CATEGORY
              : [];
          // eslint-disable-next-line no-underscore-dangle
          const patientId = patient.top.hits.hits[0]._source.CHART_NO;
          top.forEach((labName) => {
            if (labName === '') {
              return;
            }
            if (!(labName in labListSet)) {
              labListSet[labName] = 1;
            } else {
              labListSet[labName] += 1;
            }
            // generate patient-lab mapping for pagging
            if (!(labName in patientLabMapping)) {
              patientLabMapping[labName] = [];
            }
            patientLabMapping[labName].push(patientId);
          });
        });
        this.patientLabMapping = patientLabMapping;
        this.labListSet = labListSet;
        const options = [];
        this.setLabDisable(labListSet, options);
        this.labNameList = options.filter((option) => option.count !== 0);
        this.labName = this.labNameList[0].name;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.patientLabMapping = {};
        this.labListSet = {};
        this.labNameList = [];
        this.labName = getDefaultLabName();
      }
    },
    async getLabData(from) {
      this.loading = true;

      if (!this.patientLabMapping[this.labName]) {
        this.loading = false;
        this.patientLabMapping[this.labName] = this.patientList;
      }

      const currentPatientList = this.patientLabMapping[this.labName]
        .concat(difference(this.patientList, this.patientLabMapping[this.labName]))
        .slice(pageSize * (from - 1), pageSize * (from - 1) + pageSize);
      const rawData = await this.getPatientTopRecord(['FIRST_LAB', 'CHART_NO'], currentPatientList, {
        currentView: PAGES.LABS,
      }).catch((error) => {
        console.error(error);
      });
      if (!rawData) {
        return Promise.resolve([]);
      }
      const dict = {};
      const columns = [
        {
          title: 'Patient ID',
          key: 'chart_no',
          dataIndex: 'chart_no',
          scopedSlots: { customRender: 'chart_no' },
          fixed: 'left',
          className: 'chart_column',
        },
      ];
      const datas = [];
      let counter = 0;
      rawData.forEach((labListRaw) => {
        const labList = labListRaw.top.hits.hits[0];
        // eslint-disable-next-line no-underscore-dangle
        if (!labList._source.FIRST_LAB) {
          return;
        }
        const tempData = {
          key: counter,
          // eslint-disable-next-line no-underscore-dangle
          labs: labList._source.FIRST_LAB.length,
        };

        // eslint-disable-next-line no-underscore-dangle
        tempData.chart_no = labList._source.CHART_NO;

        // eslint-disable-next-line no-underscore-dangle
        const sortedLabList = labList._source.FIRST_LAB.sort((a, b) => b.SEQNO - a.SEQNO);
        // eslint-disable-next-line no-underscore-dangle
        sortedLabList.forEach((lab) => {
          if (lab.CHSPECI === '') {
            return;
          }
          const name = `${lab.CHSPECI} ${lab.CHHEAD} (${lab.UNIT})`;
          if (!(name in dict)) {
            dict[name] = {
              counter: 0,
              abCounter: 0,
              CHNL: lab.CHNL,
              CHNH: lab.CHNH,
              isNumber: lab.is_number,
              CHSPECI: lab.CHSPECI,
              CHHEAD: lab.CHHEAD,
              UNIT: lab.UNIT,
              CHART_NO: tempData.chart_no,
            };
          }
          // handle datasource
          if (lab.is_number) {
            tempData[name] = lab.VALUE;
          } else {
            tempData[name] = lab.CHCOMMT;
          }
        });
        datas.push(tempData);
        counter += 1;
      });
      const listOfDict = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const key of Object.keys(dict)) {
        listOfDict.push({
          name: key,
          ...dict[key],
        });
      }
      listOfDict.sort((a, b) => b.counter - a.counter);
      // eslint-disable-next-line no-restricted-syntax
      const firstLayer = this.handleFirstLayer(listOfDict, this.handleColumn, VisitType.ALL);
      this.columns = columns.concat(firstLayer);
      this.datas = datas;
      this.finalDatas = datas;
      this.total = Math.min(UICONFIG.PATIENT_SIZE, this.patientList.length);
      this.loading = false;
      return Promise.resolve(datas);
    },
    showModal() {
      this.$refs.labHistogram.show();
      this.trackClick('showModal', {});
    },
    handleColumn(lab, key) {
      const name = lab.CHHEAD;
      const unit = lab.UNIT && lab.UNIT.length !== 0 ? `(${lab.UNIT})` : '';
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this;
      return {
        title: lab.isNumber ? (
          <div>
            <a-button class="lab-button" ghost size="small" type="primary">
              <div class="lab-name-text">{name}</div>
              <div class="div-filter-icon">
                <a-icon type="filter" class="filter-icon" />
              </div>
            </a-button>
            {unit}
          </div>
        ) : (
          <div class="lab-unit-text">
            {name}
            {unit}
          </div>
        ),
        dataIndex: lab.name,
        key,
        chHead: name,
        unit: lab.UNIT ? lab.UNIT : '',
        customRender: (text) => this.renderAbnormal(lab, text, key),
        customHeaderCell: (column) => ({
          on: {
            click: () => {
              vm.modalTitle = column.chHead;
              vm.modalUnit = column.unit;
              if (lab.isNumber) {
                vm.showModal();
                vm.trackClick('handleColumn', { modalTitle: vm.modalTitle });
              }
            },
          },
        }),
        className: CLASS.SECOND_LAYER_ROW,
      };
    },
    async handleTableChange(pagination) {
      try {
        await this.getLabData(pagination);
        this.trackClick('handleTableChange', { pagination });
        window.scrollTo(0, 0);
        this.$root.$emit(VUE_EVENT_NAME.SET_VUEPERFECTSCROLLBAR_UPDATE);
      } catch (e) {
        console.error(e);
      }
    },
    itemPagerRender(current, type, originalElement) {
      if (type === 'prev') {
        return (
          <a>
            <b-icon-caret-left-fill />
          </a>
        );
      }
      if (type === 'next') {
        return (
          <a>
            <b-icon-caret-right-fill />
          </a>
        );
      }
      return originalElement;
    },
    setCustomRow(record) {
      const columnList = Object.keys(record).filter((word) => word.includes(this.labName));
      return {
        class: columnList.length === 0 ? 'no-data-raw' : '',
        on: {
          click: () => {
            this.$emit('showPatient', record.chart_no);
            this.trackClick('PatientDetailLab', {
              query: this.query,
              pid: record.chart_no,
            });
          },
        },
      };
    },
    changeLabName() {
      this.trackClick('changeLabName', { labName: this.labName });
    },
    async fetchLabData() {
      this.loading = true;
      this.patientList = await this.getPatientList(UICONFIG.PATIENT_SIZE, { currentView: UICONFIG.LABS }).catch(
        (error) => {
          console.error(error);
        }
      );
      await this.getLabList().catch((error) => {
        console.error(error);
      });

      this.current = 1;
      this.loading = false;
      this.getLabData(1);
    },
    debouncedFetchLabData: debounce(function () {
      this.fetchLabData();
    }, 0),
  },
  watch: {
    labName() {
      this.current = 1;
      this.getLabData(this.current);
      this.setLabselect(this.labName);
      this.loading = false;
    },
    labColumnFilter() {
      this.trackClick('changeLabColumnFilter', { labColumnFilter: this.labColumnFilter });
    },
    patient() {
      if (this.patient === 0) {
        this.loading = false;
      }
    },
    conditionState() {
      if (this.tabName === PAGES.LABS) {
        this.fetchLabData();
      } else {
        this.debouncedFetchLabData();
      }
    },
  },
};
</script>

<style lang="css" scoped>
.hint-img {
  position: relative;
  margin-right: pageSizepx;
}

.lab-table {
  margin: 20px auto auto auto;
}

.lab-table >>> .ant-table-pagination.ant-pagination {
  float: None;
  margin: 16px 0;
  text-align: center;
}

.pagin {
  float: None;
  margin: 16px 0;
  text-align: center;
}

.tag-font {
  font-size: 16px;
}

.lab-table >>> .ant-table-header-column {
  font-size: 16px;
  color: #333f6b;
}

.lab-table >>> .ant-table-thead > tr > th {
  padding: 11px 16px;
  border-bottom: none;
  background-color: #ebecf0;
}

.lab-table >>> .ant-table-thead > tr:first-child > th:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background-color: #ebecf0;
  text-align: center;
  font-size: 16px;
  width: 17px;
  height: 20px;
  border-right: 1px solid #fff;
  margin-right: 1px;
}
.lab-table >>> .ant-table-tbody > tr > .chart_column {
  background-color: white !important;
}

.lab-table >>> .ant-table-thead > tr:first-child > th:first-child > span > div > span:first-child {
  opacity: 0.5;
}

.lab-table >>> .ant-table-row-level-0 {
  cursor: pointer;
}

.pagin >>> .ant-pagination-item-active {
  border: 1px solid #ffffff;
  color: #333f6b;
  font-weight: bold;
}

.pagin >>> .ant-pagination-item-active a {
  color: #333f6b;
}

.pagin >>> .ant-pagination-item-container {
  bottom: 2px;
  font-size: 1px;
}

.pagin >>> .ant-pagination-jump-next,
.pagin >>> .ant-pagination-jump-prev {
  pointer-events: none;
}

.lab-table >>> .first-layer-row:nth-child(odd) {
  background-color: #d8d9e8;
}
.lab-table >>> .first-layer-row:nth-child(even) {
  /* background-color: #ebecf0; */
  background-color: #d8d9e8;
}

.lab-table >>> .no-data-raw {
  background-color: #f9f9f9;
}

.hint {
  width: 800px;
  height: 24px;
  font-family: Muli, '微軟正黑體', 'Microsoft JhengHei', sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #828282;
  display: inline-block;
  vertical-align: middle;
  line-height: 1.5;
}

.block {
  position: relative;
  background: transparent;
  opacity: 0.5;
}

.block:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}

.filter-input {
  width: 250px;
  margin-left: 30px;
}

.tag-chart-no {
  height: 30px;
  border-radius: 3px;
  border: solid 1px #333f6b;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333f6b;
  background-color: white;
  padding: 5px 6px 1px 6px;
  cursor: pointer;
  font-family: monospace, Roboto, Muli, Montserrat, 'OpenSans', 'PingFangTC', '微軟正黑體', 'Microsoft JhengHei',
    sans-serif;
}

.lab-button {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333f6b !important;
  border-color: #333f6b !important;
  height: 30px;
  display: flex;
}

#loading-spin {
  top: 50%;
  position: fixed;
  left: 50%;
}

.profile-icon >>> svg {
  width: 18px;
  height: 18px;
  margin-top: -6px !important;
}

.filter-icon >>> svg {
  width: 25px;
  height: 25px;
  top: 7px !important;
  padding-right: 5px;
  margin-top: -9px;
  margin-left: -9px;
}

.filter-icon {
  color: #333f6b !important;
  right: 3px !important;
}

.div-filter-icon {
  width: 20px;
  height: 30px;
}

.lab-name-text {
  margin-top: 4px;
}

.lab-unit-text {
  width: max-content;
}

.hint-text {
  height: 24px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
}
</style>
<style>
.lab-result-column-disable {
  display: none;
}
</style>
