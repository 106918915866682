<template>
  <div>
    <b-row id="patient-NavBar">
      <b-col class="margin-20">
        <b-row>
          <b-col>
            <b-button @click="goToSummary" id="back-to-summary-btn" style="margin-left: 20px"
              >← Back to Summary</b-button
            >
          </b-col>
        </b-row>
        <b-row>
          <b-col style="margin: 20px 0px 0px 20px">
            <span id="patient-list-tittle"> Patient list </span>
            <span style="margin-left: 30px" class="d-inline-block">
              <div v-if="hasCohort" class="d-inline-block margin-left-20">
                <div class="dot d-inline-block cohort-color"></div>
                <div class="d-inline-block p-relative dot-word" :id="`patient-list-first-event-name-${uuid}`">
                  {{ firstEventFP.name }}
                </div>
                <b-tooltip :target="`patient-list-first-event-name-${uuid}`" triggers="hover" variant="tooltip-style">
                  <EventToolTip :event="firstEventFP" />
                </b-tooltip>
              </div>
              <div class="d-inline-block margin-left-20">
                <div class="dot d-inline-block intervention-color"></div>
                <div class="d-inline-block p-relative dot-word" :id="`patient-list-index-event-name-${uuid}`">
                  {{ indexEventFP.name }}
                </div>
                <b-tooltip :target="`patient-list-index-event-name-${uuid}`" triggers="hover" variant="tooltip-style">
                  <EventToolTip :event="indexEventFP" />
                </b-tooltip>
              </div>
              <div v-if="hasOutcome" class="d-inline-block margin-left-20">
                <div class="dot d-inline-block outcome-color"></div>
                <div class="d-inline-block p-relative dot-word" :id="`patient-list-third-event-name-${uuid}`">
                  {{ thirdEventFP.name }}
                </div>
                <b-tooltip :target="`patient-list-third-event-name-${uuid}`" triggers="hover" variant="tooltip-style">
                  <EventToolTip :event="thirdEventFP" />
                </b-tooltip>
              </div>
            </span>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="" v-if="currentPatientList.length > 0" id="patient-list-wrapper">
      <b-col>
        <div>
          <b-table hover head-variant="light" :items="currentPatientList" :fields="fields">
            <template #cell(actions)="row">
              <b-button
                size="sm"
                class="time-toggle"
                @click="handleShowTimeline(row.toggleDetails(), row.item.chartNo)"
              >
                {{ row.detailsShowing ? 'Hide' : 'Show' }} timeline
              </b-button>
            </template>
            <template #row-details="row">
              <b-card>
                <Timeline
                  :chart_no="row.item.chartNo"
                  :uniqueIndex="uniqueIndex"
                  :tt="uuid + row.item.chartNo"
                  :zoomable="false"
                  :start="row.item.start"
                  :end="row.item.end"
                  :intervention="row.item.intervention"
                  :cohort="row.item.cohort"
                  :outcome="row.item.outcome"
                  :isAdvance="true"
                />
              </b-card>
            </template>
          </b-table>
          <div class="paging-block">
            <div id="last-page-hint" v-if="patientCount > 100">Show at most {{ UICONFIG.PATIENT_SIZE }} patients.</div>
            <a-pagination
              class="pagin"
              size="small"
              v-show="pateintList.length > 10"
              v-model="current"
              :total="Math.min(UICONFIG.PATIENT_SIZE, pateintList.length)"
              @change="handleTableChange"
              showLessItems
              :itemRender="itemPagerRender"
            />
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Timeline from '@/views/components/Timeline.vue';
import EventToolTip from '@/components/advanceSearch/EventToolTip.vue';
import { getPatientListAggsQuery, getPatientTopRecordAggsQuery } from '@/utils/query';
import AdvanceESQuery from '@/utils/query/core/advance/AdvanceESQuery';
import { AdvanceTabMixin, ClickMixin } from '@/mixins';
import { UICONFIG, PAGES, VUE_EVENT_NAME } from '@/utils/constants';
import { v4 as uuid } from 'uuid';
import { mapMutations, mapState } from '../../../store';

export default {
  name: 'PatientList',
  props: {
    uniqueIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      pateintList: [],
      currentPatientList: [],
      current: 1,
      query: {},
      fields: [
        { key: 'chartNo', label: 'Patient ID' },
        { key: 'gender', label: 'Gender' },
        { key: 'age', label: 'Age' },
        { key: 'actions', label: 'Timeline' },
      ],
      UICONFIG,
      PAGES,
      uuid: '',
      clickCount: 0,
    };
  },
  computed: {
    ...mapState('byEventSession', ['tabs']),
    ...mapState('advancePortal', ['patientCount']),
    hasCohort() {
      return this.firstEventFP.condition.length > 0;
    },
    hasOutcome() {
      return this.thirdEventFP.condition.length > 0;
    },
  },
  components: {
    Timeline,
    EventToolTip,
  },
  mixins: [AdvanceTabMixin, ClickMixin],
  async mounted() {
    this.uuid = uuid();
    this.fields.splice(-1, 0, { key: 'indexDate', label: `${this.indexEventFP.name} date` });
    if (this.hasCohort) {
      this.fields.splice(-2, 0, {
        key: 'daysToIndex',
        label: `${this.firstEventFP.name} to ${this.indexEventFP.name} days`,
      });
    }
    if (this.hasOutcome) {
      this.fields.splice(-1, 0, {
        key: 'daysToOutcome',
        label: `${this.indexEventFP.name} to ${this.thirdEventFP.name} days`,
      });
    }
    this.query = new AdvanceESQuery({ SearchStateObject: this.currentSearchStateFP }).getQuery();
    const queryWithAggs = getPatientListAggsQuery(this.query, 100);
    await this.$api
      .advSearch(queryWithAggs, 1)
      .then((response) => {
        if ('buckets' in response.aggregations.patientNum) {
          this.pateintList = response.aggregations.patientNum.buckets.map((hit) => hit.key);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    await this.getPatientList(1);
    this.clickCount += 1;
  },
  methods: {
    ...mapMutations('byEventSession', ['updateTab']),
    goToSummary() {
      this.updateTab({
        index: this.uniqueIndex,
        state: 'summary',
      });
      this.advanceTrackClick('clickGoToSummary', {
        tabIndex: this.uniqueIndex,
      });
    },
    async getPatientList(page = 1) {
      const includes = ['TIME_KEY', 'CHART_NO', 'SEX', 'AGE', 'TIME_DIFF'];
      const sortField = ['TIME_KEY'];
      const queryWithEventTimeAggs = getPatientTopRecordAggsQuery(this.query, includes, {
        slicePatientList: this.pateintList.slice((page - 1) * 10, page * 10),
        sortField,
      });

      const currentPatientList = await this.$api
        .advSearch(queryWithEventTimeAggs, 1)
        .then((response) => {
          if ('buckets' in response.aggregations.patientNum) {
            return response.aggregations.patientNum.buckets.map((hit, index) => {
              const path = hit.top.hits.hits[0];
              function hasEvent(eventType) {
                return 'inner_hits' in path && eventType in path.inner_hits;
              }
              // eslint-disable-next-line no-underscore-dangle
              let start = hit.top.hits.hits[0]._source.TIME_KEY;
              let cohort = {
                date: '',
                name: '',
              };
              // eslint-disable-next-line no-underscore-dangle
              let end = hit.top.hits.hits[0]._source.TIME_KEY;
              let outcome = {
                date: '',
                name: '',
              };
              if (hasEvent('cohort')) {
                // eslint-disable-next-line no-underscore-dangle
                start = path.inner_hits.cohort.hits.hits[0]._source.TIME_KEY;
                cohort = {
                  // eslint-disable-next-line no-underscore-dangle
                  date: path.inner_hits.cohort.hits.hits[0]._source.TIME_KEY,
                  name: this.firstEventFP.name,
                };
              }
              if (hasEvent('outcome')) {
                // eslint-disable-next-line no-underscore-dangle
                end = path.inner_hits.outcome.hits.hits[0]._source.TIME_KEY;
                // eslint-disable-next-line no-underscore-dangle
                outcome = {
                  // eslint-disable-next-line no-underscore-dangle
                  date: path.inner_hits.outcome.hits.hits[0]._source.TIME_KEY,
                  name: this.thirdEventFP.name,
                };
              }
              return {
                // eslint-disable-next-line no-underscore-dangle
                chartNo: hit.top.hits.hits[0]._source.CHART_NO,
                intervention: {
                  // eslint-disable-next-line no-underscore-dangle
                  date: hit.top.hits.hits[0]._source.TIME_KEY,
                  name: this.indexEventFP.name,
                },
                // eslint-disable-next-line no-underscore-dangle
                gender: hit.top.hits.hits[0]._source.SEX,
                // eslint-disable-next-line no-underscore-dangle
                age: hit.top.hits.hits[0]._source.AGE,
                // eslint-disable-next-line no-underscore-dangle
                indexDate: hit.top.hits.hits[0]._source.TIME_KEY,
                start,
                end,
                cohort,
                outcome,
                // eslint-disable-next-line no-underscore-dangle
                daysToOutcome: this.hasOutcome
                  ? // eslint-disable-next-line no-underscore-dangle
                    path.inner_hits.outcome.hits.hits[0]._source.TIME_DIFF
                  : '',
                daysToIndex: this.hasCohort
                  ? // eslint-disable-next-line no-underscore-dangle
                    Math.abs(parseInt(path.inner_hits.cohort.hits.hits[0]._source.TIME_DIFF, 10))
                  : '',
                _showDetails: this.clickCount === 0 && index === 0,
              };
            });
          }
          return [];
        })
        .catch(() => []);
      this.currentPatientList = currentPatientList;
      this.$forceUpdate();
    },
    handleShowTimeline(patientID) {
      this.clickCount += 1;
      this.advanceTrackClick('clickShowTimeToggle', { patientID });
    },
    async handleTableChange(pagination) {
      await this.getPatientList(pagination).catch((error) => {
        console.error(error);
      });
      window.scrollTo(0, 0);
      this.$root.$emit(VUE_EVENT_NAME.SET_VUEPERFECTSCROLLBAR_UPDATE);
      this.advanceTrackClick('clickPatientListTableChange', { pagination });
    },
    itemPagerRender(current, type, originalElement) {
      if (type === 'prev') {
        return (
          <a>
            <b-icon-caret-left-fill />
          </a>
        );
      }
      if (type === 'next') {
        return (
          <a>
            <b-icon-caret-right-fill />
          </a>
        );
      }
      return originalElement;
    },
  },
};
</script>

<style lang="scss" scoped>
#patient-NavBar {
  // margin: 20px;
  background: #ffffff;
  /* dialog shadow */
  box-shadow: 0px 4px 10px rgba(117, 117, 117, 0.5);
}

.margin-20 {
  margin: 20px 20px;
}

#patient-list-tittle {
  // font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  margin-top: 20px;
  /* primary / navy */

  color: #0f4895;
}

#back-to-summary-btn {
  background-color: transparent;
  color: #333f6b;
  border: #333f6b solid 1px;
}

#back-to-summary-btn:hover {
  background-color: #333f6b;
  color: white;
  border: #333f6b solid 1px;
}

.timeline-block {
  margin: 20px;
}

.patient-id {
  margin: auto 0px;
  height: 250px;
  border-right: grey 1px solid;
}

#patient-list-wrapper {
  background-color: white;
  padding: 0px 40px;
}

/deep/ .zoom {
  top: 30px;
}

.id {
  position: relative;
  top: 45%;
  margin-right: 50px;
  text-align: -webkit-center;
}

/deep/ .outpatienthighlight > .vis-item-content {
  padding: 0px;
}

#last-page-hint {
  text-align: center;
}

.pagin {
  float: None;
  margin: 16px 0;
  text-align: center;
}
.pagin >>> .ant-pagination-item-active {
  border: 1px solid #ffffff;
  color: #333f6b;
  font-weight: bold;
}
.pagin >>> .ant-pagination-item-active a {
  color: #333f6b;
}
.pagin >>> .ant-pagination-item-container {
  bottom: 2px;
  font-size: 1px;
}
.pagin >>> .ant-pagination-jump-next,
.pagin >>> .ant-pagination-jump-prev {
  pointer-events: none;
}

.time-toggle {
  color: #4d8edc;
  background-color: transparent;
  border: 1px solid #4d8edc;
  box-sizing: border-box;
  border-radius: 4px;
}

.dot {
  border-radius: 20px;
  height: 18px;
  width: 18px;
}

.cohort-color {
  background-color: #6cbec8;
}

.intervention-color {
  background-color: #e98484;
}

.outcome-color {
  background-color: #f1b065;
}

.p-relative {
  position: relative;
}

.dot-word {
  bottom: 3px;
  margin-left: 5px;
  color: #212121;
}

.margin-left-20 {
  margin-left: 20px;
}

/deep/ .thead-light {
  font-weight: 400;
  color: #334e97;
}
</style>
