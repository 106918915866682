import { Module } from 'vuex-module-decorators';
import { DiagnosisRanges } from '../../utils/conditions/core';
import SearchState from './SearchState';

@Module({ namespaced: true })
export default class Portal extends SearchState {
  diagnosisRange = DiagnosisRanges.ALL;

  excludeType = 'Diagnosis';

  onlyMyPatient = false;
}
