<template>
  <div class="my-study">
    <a-tabs @change="switchTab" :activeKey="tabName" class="my-study-tabs">
      <a-tab-pane :key="PAGES.SAVE_ANALYSIS" :closable="false">
        <template slot="tab">
          <div class="custom-tab d-flex align-items-center">
            <div class="border-fixer">Analyses</div>
          </div>
        </template>
        <a-row>
          <a-col>
            <SaveAnalysisList />
          </a-col>
        </a-row>
      </a-tab-pane>
      <a-tab-pane :key="PAGES.COHORT_LIST" :closable="false">
        <template slot="tab">
          <div class="custom-tab d-flex align-items-center">
            <div class="border-fixer">Cohorts</div>
          </div>
        </template>
        <a-row>
          <a-col>
            <CohortList />
          </a-col>
        </a-row>
      </a-tab-pane>
      <a-tab-pane :key="PAGES.HISTORY_ANALYSIS" :closable="false">
        <template slot="tab">
          <div class="custom-tab d-flex align-items-center">
            <div class="border-fixer">History</div>
          </div>
        </template>
        <a-row>
          <a-col>
            <HistoryAnalysisList />
          </a-col>
        </a-row>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
import CohortList from '@/components/byEvent/cohort/CohortList.vue';
import SaveAnalysisList from '@/components/byEvent/analysis/SaveAnalysisList.vue';
import HistoryAnalysisList from '@/components/byEvent/analysis/HistoryAnalysisList.vue';
import { PAGES, VUE_EVENT_NAME } from '@/utils/constants';
import { ClickMixin } from '@/mixins';

export default {
  name: 'MyStudy',
  mixins: [ClickMixin],
  components: {
    CohortList,
    SaveAnalysisList,
    HistoryAnalysisList,
  },
  data() {
    return {
      PAGES,
      tabName: PAGES.SAVE_ANALYSIS,
    };
  },
  mounted() {
    this.$root.$on(VUE_EVENT_NAME.SET_VIEW_DATASET, () => {
      this.switchTab(PAGES.READY_DATASET);
    });

    this.$root.$on(VUE_EVENT_NAME.SET_VIEW_SAVED_ANALYSIS, () => {
      this.switchTab(PAGES.SAVE_ANALYSIS);
    });
  },
  destroyed() {
    this.$root.$off(VUE_EVENT_NAME.SET_VIEW_DATASET);
    this.$root.$off(VUE_EVENT_NAME.SET_VIEW_SAVED_ANALYSIS);
  },
  methods: {
    switchTab(tabName) {
      this.tabName = tabName;

      this.cohortStudyTrackClick('switchTab', { tabName });
    },
  },
};
</script>
<style lang="scss" scoped>
.my-study {
  padding: 40px 235px 0px 235px;
  background: #ffffff;
}

/deep/ .ant-tabs-bar {
  border-bottom: none;
}

/deep/ .ant-tabs-tab {
  padding: 12px 0px !important;
  margin-right: 20px !important;
  .border-fixer {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #334e97;
  }

  .custom-tab {
    border-bottom: none !important;
    margin-top: 0px !important;
  }
}

.ant-tabs-tab-active {
  .border-fixer {
    color: #4d8edc !important;
    font-weight: 600;
  }
}

/deep/ .ant-tabs-nav-wrap {
  margin-left: -12px;
}

/deep/ .ant-tabs-tab-active {
  .custom-tab {
    border-bottom: none !important;
    margin-top: 0px !important;
  }
}
</style>
