<template>
  <div v-click-outside="handleBlur">
    <b-form-input v-model="inputText" :placeholder="placeholder" class="advance-codesearch-input" ref="inputText">
    </b-form-input>
    <b-img src="@/assets/images/icon_search_blue.svg" class="icon-search" />
    <b-img src="@/assets/images/icon_clear.svg" class="icon-clear" v-if="inputText !== ''" @click.stop="clearInput" />
    <div v-if="optionsWithCount.length !== 0">
      <div style="height: 5px"></div>
      <div class="codesearch-menu-content option-content">
        <div class="header-convert d-flex align-items-center" v-if="ckeckIcd9Mode">
          <div class="header-convert-text">Converted ICD-10 Codes</div>
        </div>
        <b-row no-gutters class="header-row">
          <b-col cols="2" class="d-flex align-items-center" style="height: 100%">
            <b-img
              src="@/assets/images/icon_unselect_blue.svg"
              class="icon-unselect"
              @click="handleSelectAll"
              v-if="!selectAll && getSelectAllStatus"
            />
            <b-img
              src="@/assets/images/icon_select_blue.svg"
              class="icon-unselect"
              @click="handleUnselectAll"
              v-if="selectAll && getSelectAllStatus"
            />
            <div class="header-text-left-title font-weight-normal ml-10px">ICD-10</div>
          </b-col>
          <b-col class="d-flex align-items-center" style="height: 100%">
            <div class="header-text-left">Description</div>
          </b-col>
          <b-col cols="2" class="d-flex align-items-center justify-content-end" style="height: 100%">
            <div class="header-text-right">Patients/Records</div>
          </b-col>
        </b-row>
      </div>
    </div>
    <VuePerfectScrollbar @wheel.stop>
      <b-row
        no-gutters
        v-for="option in optionsWithCount"
        :title="option.description"
        :key="option.code"
        class="row-option"
      >
        <b-col>
          <b-row
            no-gutters
            class="code-search-menu"
            :class="{ 'disable-option': option.patientNum === 0 }"
            @click="handleChange(option)"
          >
            <b-col
              cols="2"
              class="d-flex align-items-center"
              :style="{
                'margin-left': `${getExpandMargin(option.level)}px`,
              }"
            >
              <b-img
                src="@/assets/images/icon_unselect_blue.svg"
                class="icon-unselect"
                v-if="!option.check && option.patientNum !== 0"
              />
              <b-img
                src="@/assets/images/icon_select_blue.svg"
                class="icon-unselect"
                v-if="option.check && option.patientNum !== 0"
              />
              <div
                class="code-text"
                :style="{
                  'margin-left': option.patientNum === 0 ? '28px' : '10px',
                }"
              >
                {{ `${option.code}` }}
              </div>
            </b-col>
            <b-col
              style="padding: 6px 0px"
              class="d-flex align-items-center"
              :style="{
                flex: `0 0 calc(100%/12*8 - ${getExpandMargin(option.level)}px)`,
                'max-width': `calc(100%/12*8 - ${getExpandMargin(option.level)}px)`,
              }"
            >
              <div style="max-width: calc(100% - 64px)">
                <div class="icd-description" v-html="option.highlightDescription"></div>
                <div class="icd-description" v-html="option.highlightChineseDescription"></div>
              </div>
              <b-img
                src="@/assets/images/icon_expand_open_blue.svg"
                class="icon-expand ml-auto"
                v-if="!option.billable && !option.expand"
                @click.stop="openExpand(option)"
              />
              <b-img
                src="@/assets/images/icon_expand_close_blue.svg"
                class="icon-expand ml-auto"
                v-if="!option.billable && option.expand"
                @click.stop="closeExpand(option)"
              />
            </b-col>
            <b-col
              class="icd-record-count d-flex align-items-center justify-content-end"
              v-if="option.patientNum !== undefined"
              cols="2"
            >
              <div class="icd-record-count">
                {{ formatedTemplateNumber(option.patientNum) }} /
                {{ formatedTemplateNumber(option.recordNum) }}
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </VuePerfectScrollbar>
  </div>
</template>
<script>
/* eslint-disable no-param-reassign */
import FilterMixin from '@/mixins/filterMixin';
import ClickOutside from 'vue-click-outside';
import { mapSubUiConfig } from '@/utils/uiConfig';
import { throttle, difference, isEqual, cloneDeep, pick } from 'lodash';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { PatientNumberMixin, ClickMixin, EventMixin, OptionsMixin, EventSearchMixin } from '@/mixins';
import { forEach } from 'p-iteration';
import { formatIcdGroupMapItem, getIcdFormatList } from '@/utils/util';
import { ConditionName } from '@/utils/conditions/core';
import { EventType } from '@/utils/conditions/core/Interface';
import { VUE_EVENT_NAME } from '@/utils/constants';

const ICD_GROUP_MAP_LOG_FIELD = ['patientNum', 'recordNum', 'code'];

export default {
  name: 'ICDCodeSearchFilter',
  directives: {
    ClickOutside,
  },
  mixins: [FilterMixin, PatientNumberMixin, ClickMixin, EventMixin, OptionsMixin, EventSearchMixin],
  components: {
    VuePerfectScrollbar,
  },
  props: {
    advance: Boolean,
    refCondition: Array,
    index: {
      type: Number,
      default: 1,
    },
    eventType: {
      type: String,
      default: EventType.FIX_EVENT,
    },
    path: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    inputText() {
      if (this.inputSearchFlag) {
        this.inputDescription(this.inputText);
      }
      this.inputSearchFlag = true;
    },
  },
  created() {
    const data = this.currentEvent.condition.find((item) => item.name === ConditionName.Diagnosis);
    if (data) {
      this.value = data.list;
    }
  },
  mounted() {
    this.$root.$on(VUE_EVENT_NAME.SAVE_TO_ICD_CODESEARCH_FILTER, (value) => {
      this.value = value;
    });

    try {
      this.$refs.inputText.focus();
    } catch (error) {
      console.error(error);
    }
  },
  destroyed() {
    this.$root.$off(VUE_EVENT_NAME.SAVE_TO_ICD_CODESEARCH_FILTER);
  },
  data() {
    return {
      inputText: '',
      inputFocus: false,
      andOr: 'should',
      selectAll: false,
      queryMode: 'ICD_KEYWORD',
      hoverTag: false,
      hoverTagData: {},
      hoverOptions: [],
      deleteBackspace: false,
      inputSearchFlag: true,
      getDiagnosisRange: 'Any',
    };
  },
  computed: {
    ...mapSubUiConfig('SearchForm'),
    optionsWithCount() {
      const noRecordResult = this.options.filter((option) => option.patientNum === 0);
      return this.options.filter((option) => option.patientNum !== 0).concat(noRecordResult);
    },
    fetchOptionsThrottled() {
      return throttle(
        (description) =>
          this.fetchOptions(description, this.setOptions, this.value, 'basic').catch(() => {
            this.setOptions([]);
          }),
        100
      );
    },
    ckeckIcd9Mode() {
      return this.queryMode === 'ICD9';
    },
    getSelectAllStatus() {
      // If the items in the list are all 0 people, the selected checkbox will not be displayed
      return !this.options.every((item) => item.patientNum === 0);
    },
  },
  methods: {
    setOptions(options, queryMode) {
      this.options = options;
      this.queryMode = queryMode;
    },
    async inputDescription(text) {
      await this.fetchOptionsThrottled(text);
      if (this.inputText) {
        this.selectAll = this.checkSelectAll();
      }
    },
    focusInput() {
      try {
        this.inputFocus = true;
        this.$nextTick(() => {
          this.$refs.inputText.focus();
        });
      } catch (error) {
        console.error(error);
      }
    },
    handleBlur() {
      this.inputText = '';
      this.inputFocus = false;
      this.options = [];
    },
    getOptionCheck(option, valueDescendants) {
      return (
        valueDescendants.includes(option.code) ||
        !!this.value.find((itemValue) => option.code === itemValue.code && !itemValue.partial)
      );
    },
    async addCodeToTag(option) {
      await forEach(this.value, async (item) => {
        if (item.descendants.includes(option.code)) {
          // add code to selectDescendants
          item.selectDescendants.push(cloneDeep(option.code));
          item.selectDescendants = item.selectDescendants.concat(option.descendants);

          // selectDescendants if union code
          const response = await this.$api.searchDiagnosisMerge({
            diagnosis_range: this.getDiagnosisRange,
            query_mode: 'ICD10',
            icd_list: item.selectDescendants,
          });

          // set selectDescendants
          item.selectDescendants = [
            ...new Set(response.results.map((itemValue) => itemValue.descendants.concat(itemValue.code)).flat()),
          ];

          // set icd10List
          item.icd10List = response.merged_icd10_codes;
          item.candidateIcd10List = item.icd10List;

          // set icd9List
          item.icd9List = response.merged_icd9_codes;
          item.candidateIcd9List = item.icd9List;

          // check descendants to set partial
          if (
            isEqual(
              item.selectDescendants.filter((itemValue) => itemValue !== item.code).sort(),
              item.descendants.sort()
            )
          ) {
            item.partial = false;
            item.selectDescendants = item.descendants;
            item.icd10List = item.candidateIcd10List;
            item.icd9List = item.candidateIcd9List;
          }
        }
      });
    },
    async setSelect(option, valueDescendants) {
      let addTag = true;
      if (valueDescendants.includes(option.code)) {
        // add tag descendants
        await this.addCodeToTag(option);
        addTag = false;
      }
      if (this.value.map((item) => item.code).includes(option.code)) {
        // set tag partial is false
        await forEach(this.value, async (item) => {
          if (item.code === option.code) {
            item.partial = false;
            item.selectDescendants = item.descendants;

            const response = await this.$api.searchDiagnosisMerge({
              diagnosis_range: this.getDiagnosisRange,
              query_mode: 'ICD10',
              icd_list: item.selectDescendants,
            });

            item.candidateIcd10List = response.merged_icd10_codes;
            item.candidateIcd9List = response.merged_icd9_codes;

            item.icd10List = item.candidateIcd10List;
            item.icd9List = item.candidateIcd9List;
          }
        });
        addTag = false;
      }
      if (addTag) {
        // add tag
        this.value.push(
          cloneDeep({
            ...option,
            time: Date.now(),
          })
        );
      }
      return Promise.resolve();
    },
    async setUnselect(option) {
      if (this.value.find((item) => item.code === option.code)) {
        // Remove capsule
        this.syncIcdMapUnSelectList(option);
        this.value = this.value.filter((item) => item.code !== option.code);
      }

      // Remove code, set partial
      await forEach(this.value, async (item) => {
        if (item.descendants.includes(option.code)) {
          // Remove code, descendants and ancestors in selectDescendants
          item.selectDescendants = item.selectDescendants.filter((itemValue) => option.code !== itemValue);
          item.selectDescendants = difference(item.selectDescendants, option.descendants);
          item.selectDescendants = difference(item.selectDescendants, option.ancestors);

          // selectDescendants if union code
          const response = await this.$api.searchDiagnosisMerge({
            diagnosis_range: this.getDiagnosisRange,
            query_mode: 'ICD10',
            icd_list: item.selectDescendants,
          });

          // set icd10List
          item.icd10List = response.merged_icd10_codes;
          item.candidateIcd10List = item.icd10List;

          // set icd9List
          item.icd9List = response.merged_icd9_codes;
          item.candidateIcd9List = item.icd9List;

          item.partial = true;
          item.check = false;
        }
      });

      // Remove null selectDescendants tag
      this.value = this.value.filter(
        (item) => (item.selectDescendants.length !== 0 && !item.billable) || item.billable
      );

      // Remove option descendants tag
      this.value = this.value.filter((item) => !option.descendants.includes(item.code));
      return Promise.resolve();
    },
    async updateToParent() {
      const response = await this.$api.searchDiagnosisMerge({
        diagnosis_range: this.getDiagnosisRange,
        query_mode: 'ICD10',
        icd_list: this.value.map((item) => item.selectDescendants.concat([item.code])).flat(),
      });

      const formatOption = (item) => ({
        ...formatIcdGroupMapItem(item),
      });

      const ICDCodeParents = getIcdFormatList(response.results, formatOption);

      const valueDescendants = this.value.map((item) => item.descendants).flat();

      await forEach(ICDCodeParents, async (item) => {
        if (valueDescendants.includes(item.code)) {
          // if code include in descendants
          await this.addCodeToTag(item);
        } else if (!this.value.map((valueItem) => valueItem.code).includes(item.code)) {
          this.value.push(cloneDeep(item));
        }
      });

      return Promise.resolve();
    },
    async handleChange(option) {
      this.hoverTag = false;
      this.focusInput();
      if (option.patientNum === 0) {
        return;
      }

      let valueDescendants = this.value.map((item) => item.descendants).flat();

      if (!option.check) {
        await this.setSelect(option, valueDescendants);

        await this.updateToParent();

        option.check = true;
      } else {
        await this.setUnselect(option);

        option.check = false;

        this.deselectOption(option);
      }

      valueDescendants = this.value.map((item) => item.selectDescendants).flat();
      this.options.forEach((item) => {
        item.check = this.getOptionCheck(item, valueDescendants);
      });

      this.selectAll = this.checkSelectAll();

      this.$api.recommendedRanking = {
        candidate: this.options.map((item) => pick(item, ICD_GROUP_MAP_LOG_FIELD)),
        index: this.options.findIndex((item) => item.code === option.code),
        check: option.check,
      };
      this.$emit('setValue', this.value, this.andOr, false);

      this.cohortStudyTrackClick('handleChange', { option });
    },
    getExpandMargin(level) {
      return (level - 1) * 12;
    },
    async openExpand(option) {
      const response = await this.$api.searchDiagnosisExpand({
        query: this.inputText,
        diagnosis_range: this.getDiagnosisRange,
        code: option.code,
        query_mode: 'ICD10',
      });

      const formatItem = (item) => {
        const selectList = this.value.map((itemValue) => itemValue.selectDescendants).flat();
        const check =
          !!this.value.find((valueItem) => valueItem.code === item.code && !valueItem.partial) ||
          !!selectList.find((valueItem) => valueItem === item.code);

        return {
          ...formatIcdGroupMapItem(item),
          check,
          level: option.level + 1,
          parent: option.code,
        };
      };

      const ICDCodes = getIcdFormatList(response.children, formatItem);

      // Add ICDCodes to options
      // Set child child
      // Set expand is true
      const index = this.options.findIndex((item) => item.code === option.code);
      if (index !== -1) {
        this.options.splice(index + 1, 0, ...ICDCodes);
        option.child = ICDCodes;
        option.expand = true;
      }

      this.cohortStudyTrackClick('openExpand', { option });
    },
    selectAllLog(check) {
      this.$api.recommendedRanking = {
        candidate: this.options.map((item) => pick(item, ICD_GROUP_MAP_LOG_FIELD)),
        index: 0,
        check,
      };
    },
    async handleSelectAll() {
      this.hoverTag = false;
      this.onlyClearText();
      this.focusInput();
      await forEach(this.options, async (item) => {
        if (item.patientNum !== 0) {
          item.check = true;

          const valueDescendants = this.value.map((itemValue) => itemValue.descendants).flat();
          await this.setSelect(item, valueDescendants);
        }
      });

      this.selectAll = true;

      this.selectAllLog(true);

      this.$emit('setValue', this.value, this.andOr, false);
      this.cohortStudyTrackClick('handleSelectAll');
      return Promise.resolve();
    },
    async handleUnselectAll() {
      this.hoverTag = false;
      this.onlyClearText();
      this.focusInput();
      await forEach(this.options, async (item) => {
        item.check = false;

        await this.setUnselect(item);
      });

      // Remove all option descendants tag
      const optionsDescendants = this.options.map((item) => item.descendants).flat();
      this.value = this.value.filter((item) => !optionsDescendants.includes(item.code));

      this.selectAll = false;

      this.selectAllLog(false);

      this.$emit('setValue', this.value, this.andOr, false);
      this.cohortStudyTrackClick('handleUnselectAll');
      return Promise.resolve();
    },
    checkSelectAll() {
      if (this.value.length === 0) {
        return false;
      }

      const result = this.options.map((item) => item.check || item.patientNum === 0);
      return !result.includes(false);
    },
    closeHoverExpand(option) {
      this.hoverOptions = this.hoverOptions.filter((item) => !option.descendants.includes(item.code));
      option.expand = false;
      this.cohortStudyTrackClick('closeHoverExpand', { option });
    },
    getPopupContainer(trigger) {
      return trigger.parentElement;
    },
    deselectOption(option) {
      this.keySearchDeselect(option);
    },
    closeExpand(option) {
      this.options = this.options.filter((item) => !option.descendants.includes(item.code));
      option.expand = false;
      this.cohortStudyTrackClick('closeExpand', { option });
    },
    async syncIcdMapUnSelectList(option) {
      if (this.value.length === 1) {
        this.$root.$emit(VUE_EVENT_NAME.RESET_ICD9_UNSELECT_DESCENDANTS_EVENT, this.path);
      } else {
        const value = cloneDeep(this.value);
        const response = await this.$api.searchDiagnosisMerge({
          diagnosis_range: this.getDiagnosisRange,
          query_mode: 'ICD9',
          icd_list: value.map((item) => item.candidateIcd9List).flat(),
        });

        const newResponse = await this.$api.searchDiagnosisMerge({
          diagnosis_range: this.getDiagnosisRange,
          query_mode: 'ICD9',
          icd_list: value
            .filter((item) => item.code !== option.code)
            .map((item) => item.candidateIcd9List)
            .flat(),
        });

        const descendants = difference(
          response.results.map((item) => item.descendants.concat([item.code])).flat(),
          newResponse.results.map((item) => item.descendants.concat([item.code])).flat()
        );

        this.$root.$emit(VUE_EVENT_NAME.SYNC_ICD9_UNSELECT_DESCENDANTS_EVENT, descendants);
      }
      return Promise.resolve();
    },
    onlyClearText() {
      this.inputSearchFlag = false;
      this.inputText = '';
    },
    clearInput() {
      this.inputText = '';
      this.cohortStudyTrackClick('clearInput');
    },
  },
};
</script>
<style lang="scss" scoped>
.advance-codesearch-input {
  width: calc(100% - 20px);
  height: 36px;
  margin: 0px 10px 10px 10px;
  border-radius: 6px;
  border: 1px solid #4a94d2;
  padding-left: 34px;
  padding-right: 35px;
}

.form-control:focus {
  border: 1px solid #4a94d2;
  box-shadow: none;
}

.tag {
  padding: 3px 10px;
  height: 24px;
  background: #333f6b;
  border-radius: 53px;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  margin-bottom: 4px;
  margin-right: 5px;
  cursor: pointer;
}

.capsule-delete {
  cursor: pointer;
  width: 10px;
  height: 10px;
  margin-left: 5px;
}

.input-default-margin-top {
  margin-top: 3px;
}

.input-margin-top {
  margin-top: 5px;
}

.input-margin-bottom {
  margin-bottom: 4px;
}

.placeholder {
  height: 20px;
  color: rgba(51, 63, 107, 0.5);
  font-size: 16px;
  line-height: 1.2;
  position: absolute;
  margin-left: 4px;
  width: max-content;
}

.focus-border {
  border: solid 1px #333f6b;
}

/deep/ .ant-select-selection {
  border-radius: 6px !important;
  border: solid 1px #bdbdbd !important;
}

.right-menu {
  height: 32px;
  margin-right: 6px;
}

.codesearch-menu {
  position: relative;
  z-index: 2;
  max-height: 0px;
}

.codesearch-menu-content {
  background: #ffffff;
}

.header-row {
  height: 38px;
  padding: 10px 20px 10px 20px;
  background-color: #eff0f3;
}

.header-row-top-radius {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.hover-header-row {
  height: 38px;
  padding: 10px 20px 10px 20px;
  background-color: #ffffff;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  cursor: pointer;
}

.header-text-left-title {
  height: 15px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #334e97;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.header-text-left {
  height: 15px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #334e97;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.header-text-right {
  height: 15px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #334e97;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.icon-unselect {
  cursor: pointer;
}

.row-option {
  box-shadow: inset 0px 1px 0px #d6d9e1;
  cursor: pointer;
}

.code-search-menu {
  padding: 0px 20px;
  height: 54px;
}

.code-search-hover-menu {
  padding: 0px 20px;
  height: 46px;
}

.disable-option {
  opacity: 0.3;
  cursor: not-allowed;
}

.code-text {
  height: 18px;
  margin: 1px 10px;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #334e97;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.code-hover-text {
  margin: 0px 10px;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #334e97;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icd-description {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  height: 20px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
}

.icd-hover-description {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  height: 20px;
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
}

.icd-record-count {
  text-align: right;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #828282;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

/deep/ .ps {
  max-height: 405px;
}

.hover-content {
  /deep/ .ps {
    max-height: 230px;
  }
}

.icon-expand {
  width: 34px;
  height: 34px;
}

.header-convert {
  height: 39px;
  background-color: #ffffff;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding: 0px 10px;
}

.header-convert-text {
  height: 19px;
  font-size: 15px;
  line-height: 19px;
  color: #333333;
}

.partial-dot {
  width: 6px;
  height: 6px;
  background: #4fc2e7;
  border-radius: 6px;
  margin-right: 5px;
}

.icon-search {
  position: absolute;
  top: 29px;
  left: 20px;
}

.icon-clear {
  position: absolute;
  top: 29px;
  right: 20px;
  cursor: pointer;
}
</style>
<style scoped>
#and-or-selector {
  width: 62px;
  height: 24px;
}

#and-or-selector >>> .ant-select-selection--single {
  border-radius: 53px !important;
  height: 24px;
  border: solid 1px #333f6b;
  background-color: #ffffff;
}

#and-or-selector >>> .ant-select-selection-selected-value {
  font-size: 16px;
  height: 24px;
  width: 36px;
  position: relative;
  bottom: 4px;
  right: 3px;
  text-align: center;
}

#and-or-selector >>> .ant-select-arrow {
  color: #333f6b;
  right: 3px;
}

#and-or-selector >>> .ant-select-selection__rendered {
  margin-right: 0px;
}
</style>
<style lang="scss">
.remove-tooltip {
  .ant-tooltip-arrow {
    display: none;
  }
  .ant-tooltip-inner {
    background: #ffffff;
    border: solid 1px rgba(117, 117, 117, 0.5);
    border-radius: 3px;
    color: #da6d6d;
  }
}

.groupandmap-tag-tooltip {
  width: 740px;
  max-width: 740px !important;
  padding-top: 0px !important;

  .ant-tooltip-arrow {
    display: none;
  }
  .ant-tooltip-inner {
    border-radius: 6px;
    width: 740px;
    background: #ffffff;
    padding: 0px;
  }
}

.click-to-edit-tooltip {
  width: 55px;
  max-width: 55px !important;
  z-index: 1061 !important;

  .ant-tooltip-arrow {
    display: none;
  }
  .ant-tooltip-inner {
    background: #ffffff;
    border: solid 1px rgba(117, 117, 117, 0.5);
    border-radius: 3px;
    color: #333333;
  }
}
</style>
