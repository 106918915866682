export default {
  conceptName: 'Anti-TNF Biologics',
  conceptType: 'include',
  name: 'Medication',
  tags: ['Basic', 'Raw'],
  andOr: 'should',
  list: [
    {
      code: '108030',
      label: '108030',
      description: 'HUMIRA 40MG [Adalimumab 40mg/0.4ml/syringe]',
      chineseDescription: '復邁注射劑',
      value: '108030',
      type: 'med',
      patientNum: 40,
      recordNum: 1125,
      field: 'med',
      ingredient: 'adalimumab',
      atcCode: 'L04AB04',
      time: 1646620236795,
    },
    {
      code: '1PZI02',
      label: '1PZI02',
      description: 'REMICADE 100MG/V [Infliximab 100mg/vial(附過濾器)]',
      chineseDescription: '類克凍晶注射劑',
      value: '1PZI02',
      type: 'med',
      patientNum: 2,
      recordNum: 8,
      field: 'med',
      ingredient: 'infliximab',
      atcCode: 'L04AB02',
      time: 1646620250944,
    },
    {
      code: '108050',
      label: '108050',
      description: 'SIMPONI INJ [Golimumab 50mg/0.5ml/syringe]',
      chineseDescription: '欣普尼注射液',
      value: '108050',
      type: 'med',
      patientNum: 42,
      recordNum: 748,
      field: 'med',
      ingredient: 'golimumab',
      atcCode: 'L04AB06',
      time: 1646620255863,
    },
  ],
  diagnosisRange: false,
  index: 1,
};
