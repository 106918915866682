<template>
  <div v-click-outside="closeSaveLinkMenu">
    <b-img
      src="@/assets/images/icon_analysis_save_as_event_cohort.svg"
      class="mr-5px"
      style="cursor: pointer"
      title="Save as"
      @click="clickOpenSaveLinkMenu"
    />
    <div class="save-analysis-menu" v-if="saveLinkMenu && !saveLimit">
      <div class="d-flex justify-content-between">
        <div class="title-text">Save as</div>
        <b-img src="@/assets/images/icon_close.svg" class="icon-close" @click="closeSaveLinkMenu" />
      </div>
      <div class="name-text mt-20px">Analysis Name</div>
      <b-input
        placeholder="Analysis Name"
        v-model="nameText"
        class="name-input mt-10px"
        ref="saveQueryButtonInput"
      ></b-input>
      <div class="save-analysis-button mt-20px" @click="clickSaveAsLink">Save As</div>
    </div>
    <div class="save-analysis-menu" v-if="saveLinkMenu && saveLimit">
      <div class="d-flex align-items-center">
        <b-img src="@/assets/images/icon_warning.svg" />
        <div class="warn-title ml-10px">Max Limit Reached</div>
      </div>
      <div class="name-text mt-20px">Delete a saved analysis to proceed.</div>
      <div class="d-flex justify-content-end mt-20px">
        <div class="cancel-button" @click="closeSaveLinkMenu">Cancel</div>
        <div class="ml-20px go-to-my-study-button" @click="clickGoToMyStudy">Go To My Study</div>
      </div>
    </div>
  </div>
</template>
<script>
import ClickOutside from 'vue-click-outside';
import { mapState, mapMutations } from '@/store';
import dayjs from 'dayjs';
import { ClickMixin } from '@/mixins';
import { VUE_EVENT_NAME } from '@/utils/constants';

const LINK_SAVE_WAIT = 3000; // 3 seconds

export default {
  name: 'SaveAsAnalysis',
  mixins: [ClickMixin],
  directives: {
    ClickOutside,
  },
  props: {
    uniqueIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      saveLinkMenu: false,
      nameText: 'Analysis Name',
      saveTextTimeout: null,
      saveLimit: false,
    };
  },
  computed: {
    ...mapState('cohortSession', ['tabs']),
    tab() {
      return this.tabs[this.uniqueIndex];
    },
    eventSearch() {
      return this.tab.eventSearch;
    },
  },
  methods: {
    ...mapMutations('cohortSession', ['updateTab']),
    closeSaveLinkMenu() {
      this.saveLinkMenu = false;
      this.saveLimit = false;
    },
    clickOpenSaveLinkMenu() {
      this.saveLinkMenu = true;
      this.nameText = this.tab.name;
      this.$nextTick(() => {
        try {
          this.$refs.saveQueryButtonInput.select();
        } catch (error) {
          console.error(error);
        }
      });

      this.cohortStudyTrackClick('clickOpenSaveLinkMenu');
    },
    async clickSaveAsLink() {
      const result = await this.$api.postCohortStudy({
        name: this.nameText,
        updateTime: dayjs().toISOString(),
        purpose: 'save',
        request: {
          searchState: this.eventSearch,
        },
      });

      if (result.result === 'fail') {
        this.saveLimit = result.max_saved;
      } else {
        this.saveLinkMenu = false;

        this.updateTab({
          index: this.uniqueIndex,
          id: result.url,
          name: this.nameText,
        });

        this.showSaveText();

        this.$root.$emit(VUE_EVENT_NAME.REFRESH_SAVE_ANALYSIS);
        this.$root.$emit(VUE_EVENT_NAME.SAVE_ANALYSIS_RECORD);
      }

      this.cohortStudyTrackClick('clickSaveAsLink');
    },
    showSaveText() {
      clearTimeout(this.saveTextTimeout);
      this.$root.$emit(VUE_EVENT_NAME.OPEN_ANALYSIS_LINK_SAVE_TEXT);
      this.saveTextTimeout = setTimeout(() => {
        this.$root.$emit(VUE_EVENT_NAME.CLOSE_ANALYSIS_LINK_SAVE_TEXT);
      }, LINK_SAVE_WAIT);
    },
    clickGoToMyStudy() {
      this.closeSaveLinkMenu();
      this.$root.$emit(VUE_EVENT_NAME.SET_VIEW_SAVED_ANALYSIS);

      this.cohortStudyTrackClick('clickGoToMyStudy');
    },
  },
};
</script>
<style lang="scss" scoped>
.save-analysis-menu {
  position: absolute;
  width: 420px;
  border-radius: 6px;
  box-shadow: 0 4px 10px 0 rgba(51, 63, 107, 0.2);
  border: solid 1px #d6d9e1;
  background-color: #ffffff;
  padding: 20px 25px;
  z-index: 10;
  margin: 0px 0px 0px -308px;
}

.title-text {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #334e97;
}

.name-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #212121;
}

.name-input {
  height: 40px;
  border-radius: 3px;
  border: solid 1px #4d8edc;
}

.save-analysis-button {
  padding: 12px 12px;
  background: #4d8edc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
}

.cancel-button {
  padding: 8px 24px;
  border: 1px solid #4d8edc;
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #4d8edc;
  cursor: pointer;
}

.go-to-my-study-button {
  padding: 8px 24px;
  background: #4d8edc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  cursor: pointer;
}

.warn-title {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #d65c51;
}
</style>
