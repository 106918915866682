import { groupBy } from 'lodash';
import { ConditionData, SearchType, DiagnosisRanges } from './conditions/core';
import Condition from './conditions/core/Condition';
import ConditionArray from './query/core/ConditionArray';
import CF from './conditions/core/ConditionFactory';

export default function getConditionDef(conditions: ConditionData[]): ConditionArray {
  const filters: Condition[] = [];
  conditions
    .filter((condData) => condData.tags.includes(SearchType.Raw))
    .forEach((condition) => {
      filters.push(CF.create(condition));
    });
  const additional = conditions.filter((condData) => condData.tags.includes(SearchType.Additional));
  const group = groupBy(additional, (item: ConditionData) => {
    const { name } = item;
    const hasIcdAll = item.tags.includes(DiagnosisRanges.ALL);
    const hasIcdPrimary = item.tags.includes(DiagnosisRanges.Primary);
    const hasIcdTop5 = item.tags.includes(DiagnosisRanges.Top5);
    return `${name}-${hasIcdAll}-${hasIcdPrimary}-${hasIcdTop5}`;
  });
  Object.values(group).forEach((conditionGroup: unknown) => {
    filters.push(CF.create(conditionGroup as ConditionData[]));
  });
  return new ConditionArray(filters);
}
