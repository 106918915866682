import { isEllipsisActive } from '@/utils/util';
import Vue from 'vue';

function updateExpand(el, binding) {
  const expand = isEllipsisActive(el);
  Vue.set(binding.value, binding.arg, expand);
}

export default {
  name: 'expandable',
  inserted: updateExpand,
  componentUpdated: updateExpand,
};
