// merge with common config(DefaultConfig.js)
import { SEARCH_PLACEHOLDER } from '@/utils/string';

export default {
  SearchForm: {
    searchPlaceholder: {
      DX: SEARCH_PLACEHOLDER.DX,
      MED: SEARCH_PLACEHOLDER.MED,
      ORDER: SEARCH_PLACEHOLDER.ORDER1,
      DOCTOR: SEARCH_PLACEHOLDER.DOCTOR,
    },
  },
  UserGuide: {
    path: 'userGuide/userGuide.pdf',
  },
};
