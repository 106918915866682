import { ITimeRelation, DateUnit } from '../Interface';

class TimeRelation implements ITimeRelation {
  readonly beforeValue: string;

  readonly beforeUnit: DateUnit;

  readonly afterValue: string;

  readonly afterUnit: DateUnit;

  readonly unitMapping = new Map<DateUnit, number>([
    [DateUnit.DAYS, 1],
    [DateUnit.MONTHS, 30],
    [DateUnit.YEARS, 365],
  ]);

  constructor(relation: ITimeRelation) {
    this.beforeValue = relation.beforeValue;
    this.beforeUnit = relation.beforeUnit;
    this.afterValue = relation.afterValue;
    this.afterUnit = relation.afterUnit;
  }

  public get beforeDays(): number {
    return parseInt(this.beforeValue, 10) * this.unitMapping.get(this.beforeUnit)!;
  }

  public get afterDays(): number {
    return parseInt(this.afterValue, 10) * this.unitMapping.get(this.afterUnit)!;
  }
}

export default TimeRelation;
