var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-keyword-content"},_vm._l((_vm.labs),function(lab,index){return _c('span',{key:index,attrs:{"title":'test'}},[_c('span',{staticClass:"text-keyword"},[_vm._v(" "+_vm._s(lab.exam)+" - "+_vm._s(lab.lab)+" ")]),_c('span',{staticClass:"text-keyword-content"},_vm._l((lab.ranges),function(labItem,indexItem){return _c('span',{key:(indexItem + "-item")},[_c('span',{class:{ range: true, deletable: _vm.deletable },attrs:{"title":((lab.exam) + "-" + (lab.lab))}},[(labItem.start && labItem.end && labItem.start !== labItem.end)?[_vm._v(" "+_vm._s(((labItem.start) + "~" + (labItem.end)))+" ")]:(labItem.start && !labItem.end)?[_c('span',{staticStyle:{"font-size":"10"}},[_vm._v("≧")]),_vm._v(_vm._s(labItem.start)+" ")]:(!labItem.start && labItem.end)?[_c('span',{staticStyle:{"font-size":"10"}},[_vm._v("≦")]),_vm._v(_vm._s(labItem.end)+" ")]:[_vm._v(" "+_vm._s(labItem.start)+" ")],(_vm.deletable)?_c('span',{staticClass:"del-icon",on:{"click":function($event){return _vm.onDelete(
                {
                  start: labItem.start,
                  end: labItem.end,
                  lab: lab.lab,
                  exam: lab.exam,
                },
                indexItem
              )}}},[_vm._v("x")]):_vm._e()],2),(indexItem !== lab.ranges.length - 1)?_c('span',{staticClass:"concater"},[_vm._v(" or ")]):_vm._e()])}),0),(index !== _vm.labs.length - 1)?_c('span',{staticClass:"concater"},[_vm._v(" and ")]):_vm._e()])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }