<template>
  <div class="mt-10px">
    <div class="divide-line"></div>
    <a-tabs v-model="tabName" hide-add type="editable-card" class="tabs-result" @change="switchResultTab">
      <a-tab-pane v-for="(tab, index) in tabs" :key="index" :closable="true">
        <template slot="tab">
          <div class="custom-tab d-flex align-items-center">
            <b-img src="@/assets/images/icon_analysis_tab_record_enable.svg" v-if="EMRSearchTabName === index" />
            <b-img src="@/assets/images/icon_analysis_tab.svg" v-else />
            <div class="border-fixer ml-5px">
              {{ getAbbreviationName(tab.name) }}
            </div>
            <b-img
              src="@/assets/images/icon_close_active.svg"
              class="icon-close ml-10px"
              @click.stop="closeTab(index)"
              v-if="EMRSearchTabName === index"
            />
            <b-img
              src="@/assets/images/icon_close_nonactive.svg"
              class="icon-close ml-10px"
              @click.stop="closeTab(index)"
              v-else
            />
          </div>
        </template>
      </a-tab-pane>
    </a-tabs>
    <a-tabs id="search-portal-tab" type="card" @change="switchTab" :activeKey="EMRSearchTabName">
      <a-tab-pane :key="PAGES.SAVED_RESULT" :closable="false">
        <template slot="tab">
          <div class="custom-tab d-flex align-items-center">
            <b-img
              src="@/assets/images/icon_my_study_record_enable.svg"
              v-if="EMRSearchTabName === PAGES.SAVED_RESULT"
            />
            <b-img src="@/assets/images/icon_my_study.svg" v-else />
            <div class="border-fixer ml-5px" style="width: 80px">{{ PAGES.SAVED_RESULT }}</div>
          </div>
        </template>
        <a-row>
          <a-col>
            <SavedResult />
          </a-col>
        </a-row>
      </a-tab-pane>
      <a-tab-pane :key="PAGES.SEARCH" :closable="false">
        <template slot="tab">
          <div class="custom-tab d-flex align-items-center">
            <b-img
              src="@/assets/images/icon_search_portal_record_enable.svg"
              v-if="EMRSearchTabName === PAGES.SEARCH"
            />
            <b-img src="@/assets/images/icon_search_portal.svg" v-else />
            <div class="border-fixer ml-5px" style="width: 55px">
              {{ PAGES.SEARCH }}
            </div>
          </div>
        </template>
        <a-row>
          <a-col>
            <BasicSearch />
          </a-col>
        </a-row>
      </a-tab-pane>
    </a-tabs>
    <Analyze v-if="activeTabIndex >= 0" :uniqueIndex="activeTabIndex" />
    <div>
      <WelcomeEMRSearchModal />
    </div>
    <div>
      <EndLandingModal />
    </div>
  </div>
</template>
<script>
import { PAGES, FEATURES } from '@/utils/constants';
import { ClickMixin } from '@/mixins';
import { mapState, mapMutations } from '@/store';
import BasicSearch from '@/views/BasicSearch.vue';
import Analyze from '@/components/analysis/Analyze.vue';
import SavedResult from '@/views/SavedResult.vue';
import WelcomeEMRSearchModal from '@/components/modals/WelcomeEMRSearchModal.vue';
import EndLandingModal from '@/components/modals/EndLandingModal.vue';

export default {
  name: 'ByRecordSearchPortal',
  mixins: [ClickMixin],
  components: {
    BasicSearch,
    Analyze,
    SavedResult,
    WelcomeEMRSearchModal,
    EndLandingModal,
  },
  data() {
    return {
      PAGES,
      tabName: PAGES.SEARCH,
    };
  },
  async mounted() {
    this.checkTabName();
    // check first to new EMR search UI
    const firstTime = await this.$api.getIsFirstTime(this.userId, FEATURES.LANDING_FLOW);

    if (firstTime.first_use) {
      this.setEMRSearchLandingIndex(1);
      this.setUsedFunction(FEATURES.LANDING_FLOW);
    }
  },
  watch: {
    activeTabIndex() {
      this.checkTabName();
    },
  },
  computed: {
    ...mapState('session', ['tabs', 'activeTabIndex']),
    ...mapState(['EMRSearchTabName']),
    ...mapState('user', ['userId']),
  },
  methods: {
    ...mapMutations('session', ['setActiveTabIndex', 'removeTab']),
    ...mapMutations(['setEMRSearchTabName', 'setEMRSearchLandingIndex']),
    switchTab(tab) {
      this.setEMRSearchTabName(tab);
      this.tabName = tab;
      this.setActiveTabIndex(-1);
      this.trackClick('switchTab', tab);
    },
    switchResultTab(tab) {
      this.setEMRSearchTabName(tab);
      this.tabName = tab;
      this.setActiveTabIndex(tab);
      this.trackClick('switchResultTab', tab);
    },
    closeTab(index) {
      this.removeTab(index);
      this.trackClick('closeTab', index);
    },
    checkTabName() {
      if (this.activeTabIndex >= 0) {
        this.setEMRSearchTabName(this.activeTabIndex);
        this.tabName = this.activeTabIndex;
      } else if (this.EMRSearchTabName !== PAGES.SEARCH && this.EMRSearchTabName !== PAGES.SAVED_RESULT) {
        this.setEMRSearchTabName(PAGES.SEARCH);
        this.tabName = PAGES.SEARCH;
      }
    },
    getAbbreviationName(name) {
      if (name.length > 20) {
        return `${name.slice(0, 20)} ...`;
      }
      return name;
    },
  },
};
</script>
<style scoped>
#search-portal-tab >>> .ant-tabs-tab > .custom-tab {
  position: relative;
  border-bottom: 5px solid #ffffff !important;
  top: -2px;
}
#search-portal-tab >>> .ant-tabs-tab-active > .custom-tab {
  font-weight: bold;
  position: relative;
  border-bottom: 5px solid #0074e6 !important;
  top: -2px;
}
#search-portal-tab >>> .ant-tabs-tab-active > .custom-tab > .border-fixer {
  position: relative;
}
</style>
<style lang="scss" scoped>
#search-portal-tab {
  /deep/ .ant-tabs-nav {
    margin-left: 20px !important;
  }
}

/deep/ .ant-tabs-bar {
  margin: 0px;
}

/deep/ .ant-tabs-tab-active {
  .custom-tab {
    .border-fixer {
      font-weight: bold;
      color: #0074e6 !important;
    }
  }
}

.tabs-result {
  position: absolute;
  margin-left: 280px;
  max-width: calc(100% - 422px);
  min-width: calc(100% - 422px);
  width: calc(100% - 422px);
  z-index: 1;

  /deep/ .ant-tabs-nav {
    margin-left: 0px !important;
  }

  /deep/ .ant-tabs-nav-container-scrolling {
    span {
      background: #ffffff;
      border: 1px solid #e8e8e8;
      border-bottom: none;

      i {
        display: none;
      }

      .ant-tabs-tab-next-icon {
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 6px solid #0074e6 !important;
      }

      .ant-tabs-tab-prev-icon {
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-right: 6px solid #0074e6 !important;
      }
    }
  }

  /deep/ .ant-tabs-tab {
    .custom-tab {
      border-bottom: 5px solid #ffffff !important;
      margin-top: -2px;
    }
  }

  /deep/ .ant-tabs-tab-active {
    .custom-tab {
      font-weight: bold;
      border-bottom: 5px solid #0074e6 !important;
      margin-top: -2px;
    }
  }
}

.icon-close {
  width: 20px;
  height: 20px;
}

.ant-tabs-tab {
  font-size: 16px;
  line-height: 19px;
  color: #5b5b5b;
}

.divide-line {
  width: calc(100% - 128px);
  height: 1px;
  background: #e0e0e0;
  position: absolute;
  z-index: 1;
  margin-top: 39px;
}
</style>
