import { CHART } from '../constants';
import { ConditionFactory, ConditionName, LogicOp, MedicalTerm, TermCondition } from './core';

function getProcedureQuery(procedures: MedicalTerm[], searchByPatient: boolean) {
  const fieldName = searchByPatient ? 'PROCEDURE_INS_CODE_HISTORY' : 'PROCEDURE_INS_CODE';
  return procedures.map((item) => ({ regexp: { [fieldName]: `${item.code}.*` } }));
}

export default class ProcedureDef extends TermCondition {
  // static get TYPE(): string { return ConditionType.CATEGORY; }

  static get NAME(): string {
    return CHART.TITLE.PROCEDURE;
  }

  public getFilter(): Record<string, unknown> {
    return {
      bool: {
        [this.andOr]: getProcedureQuery(this.list, this.global.searchByPatient),
      },
    };
  }

  public get isHidden(): boolean {
    return this.global.searchByPatient;
  }

  public get andOr(): LogicOp {
    if (this.global.searchByPatient) {
      return LogicOp.Or;
    }
    return super.andOr;
  }
}

ConditionFactory.register(ProcedureDef, ConditionName.Procedure);
