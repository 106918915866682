import Condition from './Condition';
import { ConditionData, ConditionName, GlobalData, VisitType } from './Interface';

type Creator = (data: ConditionData[], global: GlobalData) => Condition;

export default class ConditionFactory {
  private static readonly registerConditions = new Map<ConditionName, Creator>();

  static register<CondType extends { fromData: Creator }>(type: CondType, name: ConditionName): void {
    if (ConditionFactory.registerConditions.has(name)) {
      throw new Error(`The condition class ${name} has been registered.`);
    }
    const creator: Creator = (data, global) => type.fromData(data, global);
    ConditionFactory.registerConditions.set(name, creator);
  }

  private static createCondition(data: ConditionData[], global: GlobalData): Condition {
    const creator = ConditionFactory.registerConditions.get(data[0].name);
    if (creator === undefined) {
      throw new Error(`The condition class ${data[0].name} doesn't exists.`);
    }
    return creator(data, global);
  }

  static create(data: ConditionData | ConditionData[]): Condition {
    const global: GlobalData = {
      visitType: VisitType.ALL,
      searchByPatient: false,
      sortChartByAI: true,
      filterByDoctorId: true,
    };
    if (Array.isArray(data)) {
      return ConditionFactory.createCondition(data, global);
    }
    return ConditionFactory.createCondition([data], global);
  }
}
