import { CHART } from '../constants';
import { ConditionName, RangeCondition, ConditionFactory, LogicOp, RangeData } from './core';

function getAgeQuery(ranges: RangeData[]): Record<string, unknown>[] {
  return ranges.map((range) => ({
    range: {
      AGE: {
        gte: range.start,
        lte: range.end,
      },
    },
  }));
}

export default class AgeDef extends RangeCondition {
  static get NAME(): string {
    return CHART.TITLE.AGE;
  }

  static get TFIDF(): boolean {
    return false;
  }

  public getFilter(): Record<string, unknown> {
    return {
      bool: {
        [LogicOp.Or]: getAgeQuery(this.ranges),
      },
    };
  }
}

ConditionFactory.register(AgeDef, ConditionName.Age);
