<template>
  <div>
    <a-row>
      <a-col class="name-title d-flex align-items-center patient-number-desc" @click="clickCollapse">
        <div class="d-flex align-items-center">
          <b-img src="@/assets/images/icon_collapse.svg" class="collapse-icon" v-if="listVisible" />
          <b-img src="@/assets/images/icon_expand.svg" class="collapse-icon" v-if="!listVisible" />
          <div class="name-title-text">{{ tab.name }}</div>
          <div v-if="isLabsPage || isStatisticPage" class="big-title">Total</div>
          <div class="patient-number" style="margin-left: 8px">{{ this.getPatientCount }}</div>
          <div v-html="`${this.getPatientText}`" class="big-title" style="margin-left: 8px"></div>
          <div class="patient-number ml-20px">
            {{ this.getRecordCount }}
          </div>
          <div class="big-title" style="margin-left: 8px">records</div>
        </div>
      </a-col>
    </a-row>
    <b-collapse v-model="listVisible">
      <div class="block-title-text" v-if="conditions.length !== 0">Criteria:</div>
      <a-row>
        <a-col>
          <a-row>
            <a-col>
              <SearchCondition
                v-for="(condition, index) in conditions"
                :key="`cond-${index}`"
                :condition="condition"
                :uniqueIndex="uniqueIndex"
                style="margin: 10px 10px 0px 0px"
              />
              <div v-if="conditions.length !== 0" class="div-break" />
              <span v-if="excludeConditions.length !== 0" style="float: left; margin: 9px 10px 0px 0px">Excluded:</span>
              <SearchCondition
                v-for="(condition, index) in excludeConditions"
                :key="`excluded-${index}`"
                :condition="condition"
                :uniqueIndex="uniqueIndex"
                style="margin: 10px 10px 0px 0px"
              />
              <div v-if="excludeConditions.length !== 0" class="div-break" />
            </a-col>
          </a-row>
          <a-row>
            <a-col>
              <div class="block-title-text mt-20px" v-if="groupedConditions.length !== 0">Filter:</div>
              <SearchCondition
                v-for="(condition, index) in groupedConditions"
                :key="`extend-${index}`"
                :condition="condition"
                :deletable="true"
                :extend="true"
                :uniqueIndex="uniqueIndex"
                style="margin: 10px 10px 0px 0px"
              />
            </a-col>
          </a-row>
        </a-col>
      </a-row>
      <a-row v-if="findMyPatient">
        <a-col class="d-flex align-items-center" style="margin-bottom: 20px">
          <a-switch :defaultChecked="false" @change="switchOnlyMyPatient" :checked="onlyMyPatientChecked" />
          <div class="find-my-patient-text" style="margin-left: 10px">My patient only</div>
        </a-col>
      </a-row>
    </b-collapse>
  </div>
</template>

<script>
import SearchCondition from '@/components/analysis/status/SearchCondition.vue';
import { PatientNumberMixin, ClickMixin, UpdateMixin } from '@/mixins';
import { flow, curryRight, groupBy } from 'lodash';
import { PAGES } from '@/utils/constants';
import { mapSubUiConfig, getUiConfig } from '@/utils/uiConfig';
import { DiagnosisRanges, ConditionName } from '@/utils/conditions/core';
import ConditionArray from '@/utils/query/core/ConditionArray';
import ConditionList from '@/utils/ConditionList';
import { mapState, mapMutations } from '../../../store';

export default {
  name: 'SearchStatus',
  mixins: [PatientNumberMixin, ClickMixin, UpdateMixin],
  components: {
    SearchCondition,
  },
  props: {
    uniqueIndex: {
      type: Number,
      default: 0,
    },
    showTFIDF: {
      type: Boolean,
      default: false,
    },
    propTabName: {
      type: String,
      default: PAGES.STATISTIC_SUMMARY,
    },
  },
  mounted() {
    this.onlyMyPatientChecked = this.tab.data.onlyMyPatient;
  },
  computed: {
    ...mapState('session', ['tabs']),
    ...mapState(['scrollTop']),
    ...mapSubUiConfig('SearchStatus'),
    tab() {
      if (this.tabs.length === 0) {
        return {};
      }
      return this.tabs[this.uniqueIndex];
    },
    conditions() {
      return new ConditionArray(this.tab.includes).raws;
    },
    extendConditions() {
      return new ConditionArray(this.tab.includes).additions;
    },
    excludeConditions() {
      return this.tab.excludes;
    },
    groupedConditions() {
      return flow(
        curryRight(groupBy)((item) => {
          const { name } = item;
          const hasIcdAll = item.tags.includes(DiagnosisRanges.ALL);
          const hasIcdPrimary = item.tags.includes(DiagnosisRanges.Primary);
          const hasIcdTop5 = item.tags.includes(DiagnosisRanges.Top5);
          let lab = '';
          if (name === ConditionName.Lab) {
            lab = `${item.lab}-${item.exam}`;
          }
          return `${name}-${hasIcdAll}-${hasIcdPrimary}-${hasIcdTop5}-${lab}`;
        }),
        Object.values
      )(this.extendConditions);
    },
    isLabsPage() {
      return this.propTabName === PAGES.LABS;
    },
    isStatisticPage() {
      return this.propTabName === PAGES.STATISTIC_SUMMARY;
    },
    isInPatientEmrPage() {
      return this.propTabName === PAGES.INPATIENT_EMR;
    },
    isOutPatientEmrPage() {
      return this.propTabName === PAGES.OUTPATIENT_EMR;
    },
    isPatientEmrPage() {
      return this.propTabName === PAGES.PATIENT_EMR;
    },
    getPatientCount() {
      if (this.isInPatientEmrPage) {
        return this.formatedTemplateNumber(this.tab.counters.IPD.Patient);
      }
      if (this.isOutPatientEmrPage) {
        return this.formatedTemplateNumber(this.tab.counters.OPD.Patient);
      }
      return this.formatedTemplateNumber(this.tab.counters.ALL.Patient);
    },
    getRecordCount() {
      if (this.isInPatientEmrPage) {
        return this.formatedTemplateNumber(this.tab.counters.IPD.Record);
      }
      if (this.isOutPatientEmrPage) {
        return this.formatedTemplateNumber(this.tab.counters.OPD.Record);
      }
      return this.formatedTemplateNumber(this.tab.counters.ALL.Record);
    },
    getPatientText() {
      if (this.isInPatientEmrPage) {
        return 'inpatients';
      }
      if (this.isOutPatientEmrPage) {
        return 'outpatients';
      }
      return 'patients';
    },
    findMyPatient() {
      const doctorIdCondition = this.conditions.filter((item) => item.name === ConditionName.DoctorId);
      return (
        getUiConfig().SearchForm.findMyPatient &&
        (this.onlyMyPatientChecked || (!this.onlyMyPatientChecked && doctorIdCondition.length === 0))
      );
    },
    getStoreOnlyMyPatient() {
      return this.tab.data.onlyMyPatient;
    },
  },
  data() {
    return {
      PAGES,
      listVisible: false,
      onlyMyPatientChecked: false,
    };
  },
  watch: {
    scrollTop() {
      this.listVisible = false;
    },
    getStoreOnlyMyPatient() {
      this.onlyMyPatientChecked = this.getStoreOnlyMyPatient;
    },
  },
  methods: {
    ...mapMutations('session', ['updateTab']),
    clickExport(index) {
      this.$emit('ExportMethod', index);
    },
    clickCollapse() {
      this.listVisible = !this.listVisible;
      this.trackClick('clickCollapse', { listVisible: this.listVisible });
    },
    switchOnlyMyPatient(check) {
      this.onlyMyPatientChecked = check;

      const result = this.tab.data;
      result.onlyMyPatient = check;

      let { includes } = this.tab;

      includes = includes.filter((item) => item.name !== ConditionName.DoctorId);

      if (check) {
        const doctorIdCondition = ConditionList.doctorId;
        doctorIdCondition.list = getUiConfig().SearchForm.doctorId.map((item) => item.code);
        includes.push(doctorIdCondition);
      }

      this.updateTab({
        index: this.uniqueIndex,
        data: result,
        includes,
      });
      this.update({
        includes: this.tab.includes,
        excludes: this.tab.excludes,
        uniqueIndex: this.uniqueIndex,
      });

      this.trackClick('switchOnlyMyPatient', { check });
    },
  },
};
</script>

<style lang="scss" scoped>
.patient-number-desc {
  font-family: Muli, Serif, Sans-serif, cursive, fantasy, Monospace;
  font-size: 30px;
  line-height: 38px;
  clear: both;
  display: block;
  width: fit-content;

  .patient-number {
    font-weight: bold;
    color: #333f6b;
  }
}

.tf-idf-block {
  display: inline-block;
  margin-right: 10px;
}

.by-patient-record-group {
  display: inline-block;
  margin-right: 10px;
}

.div-break {
  height: 0px;
  float: left;
  width: 100%;
}

.div-break-line {
  border-top: solid 1px #d6d9e1;
  height: 10px;
  float: left;
  width: 100%;
}

.dropdown-button {
  margin: 0px 0px 0px 0px !important;
}

.hint-img {
  position: relative;
  margin-right: 5px;
  margin-left: 20px;
}

.hint {
  height: 24px;
  font-family: Muli, '微軟正黑體', 'Microsoft JhengHei', sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #828282;
  display: inline-block;
  vertical-align: middle;
  line-height: 1.5;
}

.name-title {
  margin-bottom: 10px;
  cursor: pointer;
}

.name-title-text {
  font-size: 24px;
  line-height: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #333f6b;
  margin-right: 20px;
}

.block-title-text {
  height: 20px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #828282;
}

.line-icon {
  margin: 0px 10px 0px 10px;
}

.collapse-icon {
  width: 20px;
  height: 20px;
  object-fit: contain;
  margin: 0px 10px 0px 0px;
}

.find-my-patient-text {
  height: 20px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #333f6b;
}

.big-title {
  font-size: 24px;
  line-height: 30px;
  color: #333f6b;
  margin-top: 1px;
}
</style>
