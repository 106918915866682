import VueLogger from 'vuejs-logger';
import Vue from 'vue';

const isProduction = process.env.NODE_ENV === 'production';
const isDev = process.env.NODE_ENV === 'development';
const isStage = process.env.NODE_ENV === 'staging';

const options = {
  isEnabled: true,
  logLevel: isProduction || isDev || isStage ? 'error' : 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true,
};

Vue.use(VueLogger, options);
