import BasicChartConfig from '@/components/charts/core/BasicChartConfig';
import { ChartItem, ChartKey, RawItem } from '@/components/charts/core/Interface';
import { PRECISION_THRESHOLD, UICONFIG } from '@/utils/constants';
import { CodeViewAllOption } from '@/utils/util';
import {
  ConditionName,
  LogicOp,
  MedicalTermType,
  SearchType,
  TermCondition,
  TermConditionData,
} from '../../utils/conditions/core';
import { AxisFormatter, removePoint, SELECTED_COLOR } from './utils/utils';

const OPTION = {
  xAxis: {
    type: 'value',
    axisLabel: {
      formatter: removePoint,
    },
  },
  yAxis: {
    type: 'category',
    triggerEvent: true,
    inverse: true,
    axisLabel: {
      formatter: AxisFormatter,
    },
  },
};

interface DiagnosisRawItem extends RawItem {
  patient_count: {
    value: number;
  };
}

export default class ProcedureConfig extends BasicChartConfig<DiagnosisRawItem> {
  constructor() {
    super(ChartKey.Procedure);

    this.hasViewAll = true;
    this.merge(OPTION);
  }

  protected setAxisData(): void {
    this.unset('yAxis.data');
    this.merge({
      yAxis: {
        data: this.data.map((item) => item.key),
      },
    });
  }

  public createViewAllConfig(): ProcedureConfig {
    const config = new ProcedureConfig();
    config.resultSize = UICONFIG.VIEW_ALL_SIZE;
    config.bucketByPatient = this.bucketByPatient; // Copy over previous bucketing status.
    config.merge({ ...CodeViewAllOption });
    return config;
  }

  protected createChartItem(rawItem: DiagnosisRawItem): ChartItem {
    const {
      key,
      doc_count,
      patient_count: { value },
    } = rawItem;
    const [code, name] = key.split('##');

    const condList = this.params.includes.additions.with(ConditionName.Procedure);

    let color = '#333f6b';
    if (condList.length !== 0) {
      const condition = condList[0] as TermCondition;
      if (condition.hitCode(code)) {
        color = SELECTED_COLOR;
      }
    }

    const targetValue = this.bucketByPatient ? value : doc_count;

    return {
      key: name,
      value: targetValue,
      code,
      name,
      color,
      percentage: this.calculatePercentage(targetValue),
    };
  }

  public createConditionFromItem(chartItem: ChartItem): TermConditionData {
    return {
      name: ConditionName.Procedure,
      tags: [SearchType.Basic, SearchType.Additional],
      andOr: LogicOp.And,
      list: [{ code: chartItem.code, type: MedicalTermType.NHI }],
    };
  }

  protected get aggsQuery(): Record<string, unknown> {
    return {
      terms: {
        field: 'PROCEDURE_INS_FULL',
        size: this.resultSize,
        order: {
          patient_count: 'desc',
        },
      },
      aggs: {
        patient_count: {
          cardinality: {
            field: 'CHART_NO',
            precision_threshold: PRECISION_THRESHOLD,
          },
        },
      },
    };
  }
}
