import { ChartItem, ChartKey, RawItem } from '@/components/charts/core/Interface';
import { ConditionData } from '@/utils/conditions/core';
import ChartConfig from './core/AdvChartConfig';

const OPTION = {
  series: {
    type: 'pie',
    center: ['50%', '70%'],
    height: '80%',
  },
  tooltip: {
    trigger: 'item',
  },
};

enum GENDER_TYPES {
  F = 'Female',
  M = 'Male',
  U = 'Unknown',
}

interface DiagnosisRawItem extends RawItem {
  count_unique_patient: {
    value: number;
  };
}

export default class AdvGenderConfig extends ChartConfig<DiagnosisRawItem> {
  constructor() {
    super(ChartKey.AdvGender);

    this.merge(OPTION);
  }

  protected createConditionFromItem(): ConditionData {
    throw new Error('Method not implemented.');
  }

  protected createChartItem(rawItem: DiagnosisRawItem): ChartItem {
    const {
      key,
      count_unique_patient: { value },
    } = rawItem;

    const color = null;

    return {
      key,
      value,
      code: key,
      name: GENDER_TYPES[key as 'F' | 'M' | 'U'],
      color,
      percentage: Math.round((value / this.patientCount) * 100.0),
    };
  }

  protected get aggsQuery(): Record<string, unknown> {
    return {
      terms: {
        field: 'SEX',
      },
      aggs: {
        count_unique_patient: {
          cardinality: {
            field: 'CHART_NO',
          },
        },
      },
    };
  }
}
