<template>
  <div>
    <div id="patient-div" style="padding: 20px 20px">
      <div class="d-flex justify-content-between align-items-center">
        <div class="left-text-unit">Patients</div>
        <div class="right-text-number">{{ this.formatedTotalPatient }}</div>
      </div>
      <div class="d-flex justify-content-between align-items-center mt-10px">
        <div class="left-text-unit">Records</div>
        <div class="right-text-number">{{ this.formatedTotalRecord }}</div>
      </div>
      <div
        class="calculate-button d-flex align-items-center justify-content-center"
        @click="clickCalculate"
        style="margin: 10px 0px 0px 0px"
      >
        <b-img src="@/assets/images/icon_event_count_loading.svg" :class="{ 'update-rotate': updateCount !== 0 }" />
        <div class="calculate-text ml-5px">Calculate</div>
        <div class="update-dot" v-if="updateDot"></div>
      </div>
    </div>
    <div class="landing-mask" v-if="EMRSearchLandingIndex === 6">
      <div
        :style="{
          width: `${width}px`,
          margin: `${top}px 0px 0px ${left}px`,
        }"
        style="background: #ffffff; padding: 20px 20px; border-radius: 16px; display: inline-block"
      >
        <div class="d-flex justify-content-between align-items-center">
          <div class="left-text-unit">Patients</div>
          <div class="right-text-number">{{ this.formatedTotalPatient }}</div>
        </div>
        <div class="d-flex justify-content-between align-items-center mt-10px">
          <div class="left-text-unit">Records</div>
          <div class="right-text-number">{{ this.formatedTotalRecord }}</div>
        </div>
        <div
          class="calculate-button d-flex align-items-center justify-content-center"
          @click="clickCalculate"
          style="margin: 10px 0px 0px 0px"
        >
          <b-img src="@/assets/images/icon_event_count_loading.svg" :class="{ 'update-rotate': updateCount !== 0 }" />
          <div class="calculate-text ml-5px">Calculate</div>
          <div class="update-dot" v-if="updateDot"></div>
        </div>
      </div>
      <div
        class="landing-tooltip"
        :style="{ left: `${left + width + 21}px`, top: `${top - 16}px` }"
        style="display: inline-block"
      >
        <div class="d-flex align-items-center">
          <b-img src="@/assets/images/landing_3.svg" />
          <div class="mini-title ml-5px">估算與搜尋</div>
        </div>
        <div class="landing-text mt-10px">計算病人與病歷數</div>
        <div class="landing-content-text mt-10px">
          完成設定後，點擊 “Calculate” 來更新符合目前條件的人數與病歷，黃點會在條件變動後出現提示可更新
        </div>
      </div>
    </div>
    <AgeFilter class="mt-20px" style="padding: 0px 20px" />
    <TimeFilter class="mt-40px" style="padding: 0px 20px" />
  </div>
</template>
<script>
import { PatientNumberMixin, UpdateMixin, ClickMixin } from '@/mixins';
import { mapState, mapMutations } from '@/store';
import AgeFilter from '@/components/filters/AgeFilter.vue';
import TimeFilter from '@/components/filters/TimeFilter.vue';
import ConditionList from '@/utils/ConditionList';

export default {
  name: 'LeftEMRSearch',
  mixins: [PatientNumberMixin, UpdateMixin, ClickMixin],
  components: {
    AgeFilter,
    TimeFilter,
  },
  data() {
    return {
      updateDot: false,
      updateCount: 0,
      top: 0,
      left: 0,
      width: 0,
    };
  },
  mounted() {
    this.update({
      includes: [{ ...ConditionList.time }],
      excludes: [],
    });
  },
  watch: {
    conditionsStringfy() {
      this.updateDot = true;
    },
    EMRSearchLandingIndex() {
      this.$nextTick(() => {
        this.setPosition();
      });
    },
  },
  computed: {
    ...mapState('condition', ['conditions', 'excludedConditions']),
    ...mapState(['EMRSearchLandingIndex']),
    conditionsStringfy() {
      return JSON.stringify([this.conditions, this.excludedConditions]);
    },
  },
  methods: {
    ...mapMutations(['setEMRSearchLandingIndex']),
    async clickCalculate() {
      this.updateDot = false;
      this.updateCount += 1;
      await this.update({
        includes: this.conditions.filter((item) => !item.list || item.list.length !== 0),
        excludes: this.excludedConditions.filter((item) => !item.list || item.list.length !== 0),
      });
      this.updateCount -= 1;
      this.trackClick('clickCalculate');
      if (this.EMRSearchLandingIndex === 6) {
        this.setEMRSearchLandingIndex(7);
      }
    },
    setPosition() {
      const offsets = document.getElementById('patient-div').getBoundingClientRect();
      const { top } = offsets;
      const { left } = offsets;
      const { width } = offsets;
      this.top = top;
      this.left = left;
      this.width = width;
    },
  },
};
</script>
<style lang="scss" scoped>
.left-text-unit {
  font-size: 16px;
  line-height: 19px;
  color: #5b5b5b;
}

.right-text-number {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #0074e6;
}

.calculate-button {
  padding: 8px;
  background: #0074e6;
  border-radius: 8px;
  cursor: pointer;
}

.update-dot {
  width: 8px;
  height: 8px;
  background: #f5d03d;
  border-radius: 99px;
  margin-top: -18px;
}

.calculate-text {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}

.update-rotate {
  -webkit-animation: rotation 1s infinite linear;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}

.landing-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}

.landing-tooltip {
  padding: 20px;
  width: 434px;
  background: #ffffff;
  border-radius: 16px;
  position: absolute;
}

.landing-tooltip::before {
  content: '';
  position: absolute;
  display: block;
  width: 0px;
  -webkit-transform: translate(-50%, calc(-100% - 5px));
  -ms-transform: translate(-50%, calc(-100% - 5px));
  transform: translate(-50%, calc(-100% - 5px));
  margin-top: 87px;
  left: -6px;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-right: 12px solid #ffffff;
}

.mini-title {
  color: #0074e6;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}

.landing-text {
  color: #212121;
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
}

.landing-content-text {
  color: #262626;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
}
</style>
