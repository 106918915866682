import { ConditionFactory, ConditionName, LogicOp, MedicalTerm, TermCondition } from './core';

function getATCCodeQuery(atcCode: MedicalTerm[]) {
  return atcCode.map((item) => ({ term: { ATC_CODE: `${item.code}` } }));
}

export default class ATCCodeDef extends TermCondition {
  public getFilter(): Record<string, unknown> {
    return {
      bool: {
        [this.andOr]: getATCCodeQuery(this.list),
      },
    };
  }

  public get andOr(): LogicOp {
    return super.andOr;
  }
}

ConditionFactory.register(ATCCodeDef, ConditionName.ATCCode);
