import BasicChartConfig from '@/components/charts/core/BasicChartConfig';
import ConditionArray from '@/utils/query/core/ConditionArray';
import { isValidSome } from '@/utils/util';
import { BasicChartQueryParams, ChartItem, ChartKey, RangeRawItem } from './core/Interface';
import {
  ConditionName,
  ReadmissionStandard,
  RangeCondition,
  RangeConditionData,
  SearchType,
  VisitType,
} from '../../utils/conditions/core';
import { UNSELECTED_COLOR } from './utils/utils';
import { PRECISION_THRESHOLD } from '../../utils/constants';

const readimThreshold = 14;

const OPTION = {
  series: {
    type: 'pie',
    center: ['50%', '70%'],
    height: '80%',
  },
  legend: {
    left: 0,
    top: 0,
    icon: 'circle',
    data: [`Within ${readimThreshold} days`, `Over ${readimThreshold} days`, `No readmission`],
    selectedMode: false,
  },
  tooltip: {
    trigger: 'item',
  },
};

const DISTINCT_PATIENT = {
  patientNum: {
    cardinality: {
      field: 'CHART_NO',
      precision_threshold: PRECISION_THRESHOLD,
    },
  },
};

// eslint-disable-next-line max-len
export default class ReadmissionConfig extends BasicChartConfig<RangeRawItem> {
  constructor(key: ChartKey) {
    super(key);

    this.merge(OPTION);
    this.bucketByPatient = false;
    this.disallowBucketChange = true;
  }

  protected get hasData(): boolean {
    return isValidSome(this.data, 0);
  }

  protected processQueryParams(): BasicChartQueryParams {
    const condList = this.params.includes.without(ConditionName.Readmission);
    return {
      ...this.params,
      includes: condList,
      visitType: VisitType.IPD,
    };
  }

  protected getInfo(condList: ConditionArray, rawItem: RangeRawItem) {
    const { key, doc_count } = rawItem;
    let color: string | null = null;
    if (condList.length !== 0) {
      const condition = condList[0] as RangeCondition;
      color = UNSELECTED_COLOR;
      if (condition.hitStartOrEnd(rawItem.from, rawItem.to)) {
        color = null;
      }
    }

    const name = this.getRangeItemDisplayName(key);
    const percentage = Math.round((doc_count / this.validCount) * 100.0);

    return {
      color,
      key,
      doc_count,
      name,
      percentage,
    };
  }

  protected createChartItem(rawItem: RangeRawItem): ChartItem {
    const condList = this.params.includes.with(ConditionName.Readmission);
    const { color, key, doc_count, name, percentage } = this.getInfo(condList, rawItem);

    return {
      key,
      value: doc_count,
      code: '',
      name,
      color,
      percentage,
    };
  }

  protected get threshold(): number {
    return readimThreshold;
  }

  protected get condition() {
    return {
      name: ConditionName.Readmission,
      tags: [SearchType.Basic, SearchType.Additional],
      key: this.params.chartParams.selection,
    };
  }

  protected get fieldName(): string {
    const { selection } = this.params.chartParams;
    switch (selection) {
      case ReadmissionStandard.NHI:
        return 'READM_NHI_TIMEDELTA';
      case ReadmissionStandard.ANY:
        return 'READM_TIMEDELTA';
      default:
        throw new Error(`Not found standard type ${selection}`);
    }
  }

  protected getRangeItemDisplayName(key: string): string {
    let name = '';
    if (key === 'NO_REVISIT') {
      name = `No readmission`;
    } else if (key === 'W') {
      name = `Within ${this.threshold} days`;
    } else {
      name = `Over ${this.threshold} days`;
    }
    return name;
  }

  public createConditionFromItem(chartItem: ChartItem): RangeConditionData {
    const { key } = chartItem;

    if (key === 'NO_REVISIT') {
      return {
        ...this.condition,
        ranges: [{ start: -1, end: 0 }],
      };
    } else if (key === 'W') {
      return {
        ...this.condition,
        ranges: [{ start: 0, end: this.threshold + 1 }],
      };
    }
    return {
      ...this.condition,
      ranges: [{ start: this.threshold + 1, end: 366 }],
    };
  }

  protected get aggsQuery(): Record<string, unknown> {
    return {
      range: {
        field: this.fieldName,
        ranges: [
          { key: 'NO_REVISIT', from: -1, to: 0 },
          { key: 'W', from: 0, to: this.threshold + 1 },
          { key: 'O', from: this.threshold + 1, to: 366 },
        ],
      },
    };
  }

  public getAggsQuery(): Record<string, unknown> {
    return {
      result: {
        ...this.aggsQuery,
      },
      valid_record: {
        value_count: {
          field: this.fieldName,
        },
      },
      ...DISTINCT_PATIENT,
    };
  }
}
