import { KEY, API_TARGET } from '@/utils/constants';
import { READMISSION_STANDARD, SEARCH_PLACEHOLDER } from '@/utils/string';
import { ChartKey } from '../../../components/charts/core/Interface';

export default {
  SearchForm: {
    diagnosis: true,
    medication: true,
    procedure: false,
    'medical-order': true,
    procedureTarget: API_TARGET.NHI_ORDER,
    procedureKey: KEY.NHI_ORDER_LIST,
    suggestion: {
      types: ['Diagnosis', 'Medication', 'Medical Order'],
    },
    exclude: {
      types: ['Diagnosis', 'Medication', 'Medical Order'],
    },
    age: true,
    time: {
      start: 1262304000, // 2010/01/01
      end: 1604102400, // 2020/10/31
    },
    OPD_Searchable: true,
    rightClick: false,
    searchPlaceholder: {
      DX: SEARCH_PLACEHOLDER.DX,
      MED: SEARCH_PLACEHOLDER.MED4,
      ORDER: SEARCH_PLACEHOLDER.ORDER,
      DOCTOR: SEARCH_PLACEHOLDER.DOCTOR,
    },
    byEvent: true,
    standard: READMISSION_STANDARD.ANY,
  },
  Analyze: {
    export: false,
    share: true,
    save: true,
    StatisticSummary: {
      riskUI: false,
      charts: [
        ChartKey.DRGCode,
        ChartKey.PatientSource,
        ChartKey.Stay,
        ChartKey.DischargeStatus,
        ChartKey.Readmission,
        // ChartKey.BMI,
        ChartKey.Symptom,
      ],
      all_charts: [
        ChartKey.ICD9,
        ChartKey.ICD10,
        ChartKey.Medication,
        ChartKey.MedicalOrder,
        ChartKey.PCSCode,
        ChartKey.ATCCode,
        ChartKey.Visit,
        ChartKey.Section,
        ChartKey.DoctorId,
        ChartKey.Trend,
        ChartKey.ReOPDE,
        ChartKey.AbnormalLab,
      ],
      demographic_charts: [ChartKey.Age, ChartKey.Gender],
      no_data_charts: [ChartKey.ReOPDE],
    },
    LabResult: true,
    PatientResult: {
      tabs: [
        'ICD10_DISDIAG',
        'PATH_REPORT_1',
        'PATH_REPORT_2',
        'SYS_REVIEW',
        'MEDICAL_REPORT',
        'TREATMENT_PATH',
        'OP_NOTE',
        'EXAM_FULL_TXT',
      ],
      OPD_tabs: ['SUBJ_NOTE', 'OBJ_NOTE', 'ASSESSMENT', 'PLAN', 'EXAM_FULL_TXT'],
      all_tabs: ['EXAM_FULL_TXT'],
    },
    OPD_Searchable: true,
    rightClick: false,
    AdvanceStatisticSummary: {
      all_charts: [
        ChartKey.AdvAge,
        ChartKey.AdvGender,
        ChartKey.AdvICD10,
        ChartKey.AdvICD9,
        ChartKey.AdvMedication,
        ChartKey.AdvSurvival,
      ],
    },
  },
  PatientInformationModal: {
    Timeline: {
      additionalQueryFields: [
        'PATH_REPORT_1',
        'PATH_REPORT_2',
        'TREATMENT_PATH',
        'MEDICAL_REPORT',
        'MEDICATION_NEW_NAME',
        'OP_NOTE',
        'ICD10_DISDIAG',
        'EXAM_FULL_TXT',
        'TURN_NAME',
        'NEXT_ADM',
        'NEXT_PLANNED',
        'ICD9',
        'ICD_20_ENG_FULL',
        'ICD9_20_ENG_FULL',
        'SYS_REVIEW',
        'SECTION_NAME',
      ],
      additionalOutPatientQueryFields: [
        'ICD',
        'EXAM_FULL_TXT',
        'SUBJ_NOTE',
        'OBJ_NOTE',
        'CHCASENO',
        'MEDICATION_NEW_NAME',
        'ICD9',
        'ICD_20_ENG_FULL',
        'ICD9_20_ENG_FULL',
        'SECTION_NAME',
        'ASSESSMENT',
        'PLAN',
      ],
      outPatient: true,
    },
    PatientLabTable: true,
  },
  UserGuide: {
    path: 'userGuide/userGuideV2ForSD.pdf',
    cohortStudyPath: 'userGuide/userGuideV3.pdf',
  },
};
