import { ConditionData } from '@/utils/conditions/core';
import { DescriptionType } from '@/utils/descriptionCache';
import numeral from 'numeral';
import { ChartItem, ChartKey } from '../core/Interface';
import AdvCategoryChartConfig from './AdvCategoryChartConfig';

export default class AdvMedConfig extends AdvCategoryChartConfig {
  protected descriptionType = DescriptionType.MEDICATION;

  protected fieldName = 'MEDICATION';

  protected canAISort = true;

  constructor() {
    super(ChartKey.AdvMedication);
  }

  protected createConditionFromItem(): ConditionData {
    throw new Error('Method not implemented.');
  }

  protected percentageFormatter(seriesList: any) {
    let currentSeries: any = null;
    if (Array.isArray(seriesList)) {
      currentSeries = seriesList[0];
    } else {
      currentSeries = seriesList;
    }
    const item = currentSeries.data as ChartItem;
    const formatedNumber = numeral(item.value).format('0,0');
    return `[${item.code}] ${item.name}: ${formatedNumber} (${item.percentage}%)`;
  }
}
