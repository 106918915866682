import { foolProof } from '@/utils/advance/foolproof';
import { mapState } from '../store';

export default {
  computed: {
    ...mapState('advancePortal', ['SearchStateObject', 'patientCountList']),
    currentSearchState() {
      if (this.uniqueIndex !== -1 && this.$store.state.byEventSession.tabs[this.uniqueIndex]) {
        return this.$store.state.byEventSession.tabs[this.uniqueIndex].SearchStateObject;
      }
      return this.SearchStateObject;
    },
    currentSearchStateFP() {
      if (this.uniqueIndex !== -1 && this.$store.state.byEventSession.tabs[this.uniqueIndex]) {
        return this.$store.state.byEventSession.tabs[this.uniqueIndex].SearchStateObject;
      }
      return foolProof({
        SearchStateObject: this.SearchStateObject,
      }).SearchStateObject;
    },
    currentIndexToEventListMapper() {
      return new Map([
        [1, this.currentSearchState.eventList[0]],
        [2, this.currentSearchState.indexEvent],
        [3, this.currentSearchState.eventList[1]],
      ]);
    },
    currentFPIndexToEventListMapper() {
      return new Map([
        [1, this.currentSearchStateFP.eventList[0]],
        [2, this.currentSearchStateFP.indexEvent],
        [3, this.currentSearchStateFP.eventList[1]],
      ]);
    },
    firstEvent() {
      return this.currentIndexToEventListMapper.get(1);
    },
    thirdEvent() {
      return this.currentIndexToEventListMapper.get(3);
    },
    indexEvent() {
      return this.currentIndexToEventListMapper.get(2);
    },
    firstEventFP() {
      return this.currentFPIndexToEventListMapper.get(1);
    },
    thirdEventFP() {
      return this.currentFPIndexToEventListMapper.get(3);
    },
    indexEventFP() {
      return this.currentFPIndexToEventListMapper.get(2);
    },
    getTimeStart() {
      return this.currentSearchState.timePeriod.start;
    },
    getTimeEnd() {
      return this.currentSearchState.timePeriod.end;
    },
  },
};
