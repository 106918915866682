export function postMessage(message, targetOrigin, target) {
  if (!targetOrigin) {
    return;
  }
  if (window.postMessage) {
    target.postMessage(message, targetOrigin);
  } else {
    throw new Error('window.postMessage is undefined');
  }
}

export function receiveMessage(callback, delay = false) {
  let rmCallback;
  const addEventListener = 'addEventListener';
  if (window.postMessage) {
    if (callback) {
      rmCallback = (e) => callback(e);
      if (window[addEventListener]) {
        window[typeof callback === 'function' ? addEventListener : 'removeEventListener']('message', rmCallback, delay);
      } else {
        window[typeof callback === 'function' ? 'attachEvent' : 'detachEvent']('onmessage', rmCallback);
      }
    } else {
      throw new Error('callback is undefined');
    }
  } else {
    throw new Error('window.postMessage is undefined');
  }
}
