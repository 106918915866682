<template>
  <div>
    <b-row
      no-gutters
      class="event-container"
      :class="{
        'focus-border': selectType !== -1 && isFocusMenu,
      }"
      @click.stop="openCodeSearchMenu()"
      :id="`${path.join('-')}-${index}-${eventType}`"
    >
      <b-col>
        <b-row no-gutters>
          <b-col class="d-flex justify-content-between">
            <div class="ml-20px">OR</div>
            <div class="mr-20px edit-icd9-text" v-if="hasDignosisCondition" @click.stop="clickEditIcdMap">
              Edit Mapped ICD-9
            </div>
          </b-col>
        </b-row>
        <b-row no-gutters class="mt-5px" v-if="eventType === EventType.FIX_EVENT">
          <b-col class="d-flex align-items-center">
            <b-img src="@/assets/images/icon-first-time-hint.svg" class="mr-5px ml-20px" />
            <div class="first-time-text">Defined as the first diagnosis/ medication/ order in time period</div>
          </b-col>
        </b-row>
        <b-row no-gutters class="mt-10px">
          <b-col>
            <div v-if="refEvent.condition && refEvent.condition.length === 0" class="all-condition ml-20px">
              All conditions
            </div>
            <div style="padding: 0px 20px">
              <span v-for="(item, index) in getConditionList" :key="`${item.code}-${index}`" class="capsule">
                <template v-if="item.type === 'lab'">
                  <LabCapsule :path="path" :item="item" @clickDelete="clickDelete" />
                  <EditLabCapsuleMenu :path="path" :item="item" />
                </template>
                <template v-else-if="item.type === 'GroupAndMap'">
                  <ICDCapsule :path="path" :tag="item" @clickDelete="clickDelete" />
                </template>
                <template v-else>
                  <span
                    v-if="item.field !== 'Death'"
                    class="capsule-abbr"
                    :class="getCapsuleAbbreviationClass(item.field)"
                    :title="`${item.code} ${item.description}`"
                    >{{ getCapsuleAbbreviation(item.field) }}</span
                  >
                  <span class="capsule-code" :title="`${item.code} ${item.description}`">{{ item.code }}</span>
                  <b-img
                    src="@/assets/images/icon_capsule_delete_white.svg"
                    @click.stop="clickDelete(item)"
                    class="capsule-delete"
                    :title="`${item.code} ${item.description}`"
                  />
                </template>
              </span>
            </div>
          </b-col>
        </b-row>
        <b-row no-gutters style="margin-top: -21px">
          <b-col>
            <CodeSearchMenu
              class="d-inline-block"
              :index="index"
              :eventType="eventType"
              :path="path"
              ref="codeSearchMenu"
              @updateSelectType="updateSelectType"
            />
          </b-col>
        </b-row>
        <b-row no-gutters>
          <b-col
            class="d-flex constraint-box"
            :class="{ 'constraint-box-margin': refEvent.constraintList && refEvent.constraintList.length !== 0 }"
          >
            <div class="constraint-text" v-if="refEvent.constraintList && refEvent.constraintList.length !== 0">
              Constraint
            </div>
            <div>
              <div
                v-for="(constraint, constraintIndex) in refEvent.constraintList"
                :key="`${constraint.constraintType}`"
              >
                <ConstraintRender
                  :index="index"
                  :eventType="eventType"
                  :constraintType="constraint.constraintType"
                  :data="constraint.data"
                  :path="path"
                  :style="{
                    'margin-bottom': constraintIndex + 1 < refEvent.constraintList.length ? '12px' : '0px',
                  }"
                />
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row no-gutters>
          <b-col style="padding: 0px 20px">
            <AgeFilter class="d-inline-block" :index="index" :eventType="eventType" :path="path" />
            <DataRangeFilter class="d-inline-block ml-10px" :index="index" :eventType="eventType" :path="path" />
            <DiagnosisRange class="d-inline-block ml-10px" :index="index" :eventType="eventType" :path="path" />
            <VisitTypeFilterAdder class="d-inline-block ml-10px" :index="index" :eventType="eventType" :path="path" />
            <MedicationTotal class="d-inline-block ml-10px" :index="index" :eventType="eventType" :path="path" />
            <!-- this feature's BE not ready yet, will active it when BE ready -->
            <!-- <DiagnosisRepeatedTimes class="d-inline-block ml-10px" :index="index" :eventType="eventType" :path="path" /> -->
            <!-- <MedicationPrescribingTimes
              class="d-inline-block ml-10px"
              :index="index"
              :eventType="eventType"
              :path="path"
            /> -->
            <!-- <MedicationDose class="d-inline-block ml-10px" :index="index" :eventType="eventType" :path="path" /> -->
            <!-- <DiagnosisDepartment class="d-inline-block ml-10px" :index="index" :eventType="eventType" :path="path" /> -->
          </b-col>
        </b-row>
        <b-row no-gutters v-if="eventType !== EventType.FIX_EVENT">
          <b-col>
            <div class="timerelation-filter" style="padding: 0px 20px 0px 10px">
              <EventTimeRelation
                :eventType="eventType"
                :index="index"
                :path="path"
                :style="{ 'margin-bottom': eventType !== EventType.FIX_EVENT ? '-10px' : '0px' }"
              />
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row no-gutters>
      <b-col>
        <b-row no-gutters>
          <b-col>
            <div v-if="eventType === EventType.FIX_EVENT" :class="{ disabled: !this.hasCondition }">
              <InExclusionContainer id="in-exclusion-container" :index="index" :path="path" :event="event">
              </InExclusionContainer>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <div>
      <IcdMap :path="path" ref="icdmap" />
    </div>
  </div>
</template>
<script>
import AgeFilter from '@/components/byEvent/filter/AgeFilter.vue';
import VisitTypeFilterAdder from '@/components/byEvent/filter/VisitTypeFilterAdder.vue';
import DataRangeFilter from '@/components/byEvent/filter/DataRangeFilter.vue';
import ConstraintRender from '@/components/byEvent/constraint/ConstraintRender.vue';
import CodeSearchMenu from '@/components/byEvent/filter/CodeSearchMenu.vue';
import EventTimeRelation from '@/components/byEvent/filter/EventTimeRelation.vue';
import DiagnosisRange from '@/components/byEvent/filter/DiagnosisRange.vue';
import MedicationTotal from '@/components/byEvent/filter/MedicationTotal.vue';
import MedicationPrescribingTimes from '@/components/byEvent/filter/MedicationPrescribingTimes.vue';
import DiagnosisRepeatedTimes from '@/components/byEvent/filter/DiagnosisRepeatedTimes.vue';
// import DiagnosisDepartment from '@/components/byEvent/filter/DiagnosisDepartment.vue';
import LabCapsule from '@/components/byEvent/capsule/LabCapsule.vue';
import ICDCapsule from '@/components/byEvent/capsule/ICDCapsule.vue';
import EditLabCapsuleMenu from '@/components/byEvent/capsule/EditLabCapsuleMenu.vue';
import IcdMap from '@/components/byEvent/modal/IcdMap.vue';
import { EventType, ConditionName } from '@/utils/conditions/core/Interface';
import ConstraintType from '@/utils/conditions/core/advance/constraint/Interface';
import { getCapsuleAbbreviation, getCapsuleAbbreviationClass } from '@/utils/util';
import { EventUpdateMixin, EventSearchMixin, ClickMixin } from '@/mixins';
import {
  defaultFirstEvent,
  defaultThirdEvent,
  defaultIndexEvent,
  defaultInclusionEvent,
  defaultExclusionEvent,
} from '@/utils/event/initState';
import { cloneDeep, pick, sortBy } from 'lodash';
import { VUE_EVENT_NAME } from '@/utils/constants';

export default {
  name: 'EventContainer',
  mixins: [EventUpdateMixin, EventSearchMixin, ClickMixin],
  components: {
    AgeFilter,
    VisitTypeFilterAdder,
    ConstraintRender,
    CodeSearchMenu,
    EventTimeRelation,
    InExclusionContainer: () => import('@/components/byEvent/event/InExclusion.vue'),
    DiagnosisRange,
    DataRangeFilter,
    LabCapsule,
    EditLabCapsuleMenu,
    ICDCapsule,
    IcdMap,
    MedicationTotal,
    MedicationPrescribingTimes,
    DiagnosisRepeatedTimes,
    // DiagnosisDepartment,
  },
  data() {
    return {
      EventType,
      hasCodeSearchMenuOpen: false,
      selectType: -1,
      andOr: 'should',
    };
  },
  computed: {
    refEvent() {
      return this.event;
    },
    hasCondition() {
      return this.event.condition.length > 0;
    },
    getConditionList() {
      let result = [];
      this.refEvent.condition.forEach((condition) => {
        result = result.concat(condition.list);
      });

      result = sortBy(result, 'time');
      return result;
    },
    hasDignosisCondition() {
      return this.getConditionList.some((item) => item.type === 'GroupAndMap');
    },
    isFocusMenu() {
      return this.isCurrentMenu(this.path, 'AdvanceCodeSearchMenu');
    },
  },
  mounted() {
    this.$root.$on(VUE_EVENT_NAME.SET_COHORT_STUDY_CODESEARCH_MENU_OPEN, (value) => {
      this.hasCodeSearchMenuOpen = value;
    });
  },
  destroyed() {
    this.$root.$off(VUE_EVENT_NAME.SET_COHORT_STUDY_CODESEARCH_MENU_OPEN);
  },
  props: {
    index: {
      type: Number,
      default: 1,
    },
    event: {
      type: Object,
      default: null,
    },
    eventType: {
      type: String,
      default: EventType.FIX_EVENT,
    },
    path: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    getCapsuleAbbreviation,
    getCapsuleAbbreviationClass,
    checkConstraint(result, name, constraintName) {
      if (result.list.length === 0 && result.name === name) {
        this.deleteConstraint(constraintName);
      }
    },
    clickDelete(capsule) {
      this.setCurrentMenu(this.path, '');

      const filterCondition = [];
      this.refEvent.condition.forEach((element) => {
        const filterResult = element;
        let elementCondition = [];

        if (element.name === ConditionName.Lab) {
          elementCondition = element.list.filter(
            (item) => !(item.code === capsule.code && item.exam === capsule.exam && item.unit === capsule.unit)
          );
        } else {
          elementCondition = element.list.filter((item) => item.code !== capsule.code);
        }
        filterResult.list = elementCondition;

        if (filterResult.list.length !== 0) {
          filterCondition.push(filterResult);
        }

        this.checkConstraint(filterResult, 'Diagnosis', ConstraintType.DiagnosisRangeConstraint);
        this.checkConstraint(filterResult, 'Medication', ConstraintType.MedicationTotalConstraint);

        this.syncToFilter(filterResult);
      });

      const body = {
        condition: filterCondition,
      };

      this.updateEventWithPath(body, this.path);
      this.cohortStudyTrackClick('clickDelete');
    },
    syncToFilter(condition) {
      switch (condition.name) {
        case ConditionName.Diagnosis:
          this.$refs.codeSearchMenu.handleAdvanceDiagnosis(condition.list, this.andOr);
          break;
        case ConditionName.Medication:
          this.$refs.codeSearchMenu.handleAdvanceMedication(condition.list, this.andOr);
          break;
        case ConditionName.MedicalOrder:
          this.$refs.codeSearchMenu.handleAdvanceMedicalOrder(condition.list, this.andOr);
          break;
        case ConditionName.Death:
          this.$refs.codeSearchMenu.handleAdvanceDeath(condition.list);
          break;
        case ConditionName.Lab:
          this.$refs.codeSearchMenu.handleAdvanceLab(condition.list, this.andOr);
          break;
        default:
          break;
      }
    },
    setDefaultState() {
      let body = {};
      if (this.eventType === EventType.FIX_EVENT) {
        const indexToDefaultEventListMapper = new Map([
          [
            1,
            pick(cloneDeep(defaultFirstEvent), [
              'constraintList',
              'constraint',
              'condition',
              'inclusionList',
              'exclusionList',
            ]),
          ],
          [
            2,
            pick(cloneDeep(defaultIndexEvent), [
              'constraintList',
              'constraint',
              'condition',
              'inclusionList',
              'exclusionList',
            ]),
          ],
          [3, pick(cloneDeep(defaultThirdEvent), ['constraintList', 'constraint', 'condition'])],
        ]);
        body = indexToDefaultEventListMapper.get(this.index);
      } else if (this.eventType === EventType.INCLUSION) {
        body = pick(cloneDeep(defaultInclusionEvent), ['constraintList', 'constraint', 'condition']);
      } else if (this.eventType === EventType.EXCLUSION) {
        body = pick(cloneDeep(defaultExclusionEvent), ['constraintList', 'constraint', 'condition']);
      }

      this.updateEventWithPath(body, this.path);
      this.$refs.codeSearchMenu.clearCondition();
      this.checked = false;

      this.cohortStudyTrackClick('setDefaultState', {
        path: this.path,
        index: this.index,
      });
    },
    openCodeSearchMenu() {
      this.updateSelectType(1);
      this.$root.$emit(VUE_EVENT_NAME.SET_COHORT_STUDY_CODESEARCH_MENU_OPEN, this.path);
      this.$refs.codeSearchMenu.clickCondition();

      this.cohortStudyTrackClick('openCodeSearchMenu');
      this.$nextTick(() => {
        const containerDiv = document.querySelector(`#${this.path.join('-')}-${this.index}-${this.eventType}`);
        if (containerDiv) {
          containerDiv.scrollIntoView({
            block: 'center',
          });
        }
      });
    },
    updateSelectType(value) {
      this.selectType = value;
    },
    clickEditIcdMap() {
      this.$refs.icdmap.openIcdMap();
      this.cohortStudyTrackClick('clickEditIcdMap');
    },
  },
};
</script>
<style lang="scss" scoped>
.event-container {
  border: 1px solid #bdbdbd;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 20px 0px;
}

.capsule {
  padding: 2px 10px;
  margin: 0px 10px 10px 0px;
  display: inline-block;
  background: #334e97;
  border-radius: 53px;
  height: 25px;
}

.capsule-abbr {
  padding: 0px 2px;
  color: #0f4895;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  margin-right: 5px;
  background: #c1e0f9;
  border-radius: 2px;
}

.capsule-code {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  margin-right: 5px;
}

.capsule-delete {
  cursor: pointer;
  margin-bottom: 3px;
  padding: 2px;
}

.abbr-md {
  background: #6fcf97;
}

.abbr-o {
  background: #f5ba42;
}

.first-occurrence-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #333333;
  margin-left: 10px;
}

.clear-all-btn {
  width: 132px;
  height: 30px;
  border: 1px solid #da6d6d;
  box-sizing: border-box;
  border-radius: 6px;
  background-color: #ffffff;
}

.icon-close-red {
  margin-right: 8px;
}

.clear-all-text {
  font-size: 16px;
  line-height: 20px;
  color: #da6d6d;
}

button:focus {
  outline: none !important;
}

.all-condition {
  display: inline-block;
  min-height: 19px;
  font-size: 16px;
  line-height: 19px;
  color: #828282;
}

.first-occurrence-content-text {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #828282;
  margin-left: 10px;
}

.focus-border {
  border: 2px solid #4d8edc;
}

.timerelation-filter {
  margin-top: 20px;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.constraint-box-margin {
  margin: 10px 0px 10px 10px;
}

.constraint-text {
  height: 20px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #333333;
  margin: 10px 0px 0px 10px;
}

.edit-icd9-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #4d8edc;
  cursor: pointer;
}

.first-time-text {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #757575;
}
</style>
