<template>
  <a-modal
    :visible="visible"
    :mask="true"
    :destroyOnClose="true"
    :footer="null"
    :zIndex="9999"
    :closable="false"
    @cancel="closeModal"
    centered
  >
    <div class="d-flex justify-content-center">
      <b-img src="@/assets/images/icon_warning_tag.svg" />
    </div>
    <div class="delete-tag-text mt-20px">Delete Concept</div>
    <div class="delete-tag-detail-text mt-20px">
      {{ `Are you sure to delete “${condition._source ? condition._source.displayName : ''}” ?` }}
    </div>
    <div class="d-flex align-items-center justify-content-between mt-20px">
      <div class="cancel-button" @click="closeModal">Cancel</div>
      <div class="delete-button" @click="clickDelete">Delete</div>
    </div>
  </a-modal>
</template>
<script>
import { VUE_EVENT_NAME } from '@/utils/constants';
import { ClickMixin } from '@/mixins';

export default {
  name: 'DeleteTagModal',
  mixins: [ClickMixin],
  data() {
    return {
      visible: false,
      condition: {},
    };
  },
  methods: {
    closeModal() {
      this.visible = false;
      this.trackClick('closeModal');
    },
    async clickDelete() {
      await this.$api.deleteQuery(this.condition._source.url);
      this.$root.$emit(VUE_EVENT_NAME.REFRESH_EMR_SEARCH_CONCEPT_LIST);
      this.visible = false;
      this.trackClick('clickDelete');
    },
    openModal(condition) {
      this.condition = condition;
      this.visible = true;
      this.trackClick('openModal');
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .ant-modal {
  min-width: 343px;
  max-width: 343px;
  width: 343px !important;
}

/deep/ .ant-modal-body {
  padding: 40px;
}

/deep/ .ant-modal-content {
  border-radius: 16px;
}

.delete-tag-text {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #d93643;
  text-align: center;
}

.delete-tag-detail-text {
  font-size: 16px;
  line-height: 165%;
  color: #212121;
  text-align: center;
}

.cancel-button {
  padding: 12px 16px;
  width: 120px;
  background: #b4b4b4;
  border-radius: 8px;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
}

.delete-button {
  padding: 12px 16px;
  width: 120px;
  background: #d93643;
  border-radius: 8px;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
}
</style>
