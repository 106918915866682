import Vue from 'vue';
import VueRouter from 'vue-router';
import { PAGES } from '@/utils/constants';
import Login from '@/views/LocalLogin.vue';
import Loading from '@/views/Loading.vue';
import { getUiConfig } from '@/utils/uiConfig';
import Base from '../views/Base.vue';
import SearchPortal from '../views/SearchPortal.vue';
import ByRecordSearchPortal from '../views/ByRecordSearchPortal.vue';
import ByEventSearchPortal from '../views/ByEventSearchPortal.vue';
import { setCookie } from '@/utils/cookie';

// disable for test
if (!process || process.env.NODE_ENV !== 'test') {
  Vue.use(VueRouter);
}

function baseRedirect() {
  // router/index.js redirect init redirect is /emr-search
  // getUiConfig() is default
  // getUiConfig().SearchForm.cohortStudy is undefined
  if (getUiConfig().SearchForm.cohortStudy) {
    return '/cohort-study';
  }
  return '/emr-search';
}

const routes = [
  {
    path: '/',
    component: Base,
    redirect: baseRedirect,
    children: [
      {
        path: 'loading',
        name: PAGES.LOADING,
        component: Loading,
      },
      {
        path: 'login',
        name: PAGES.LOGIN,
        component: Login,
      },
      {
        path: 'cohort-study',
        name: PAGES.COHORT_STUDY,
        component: SearchPortal,
        meta: {
          auth: true,
          title: 'Lumos',
        },
      },
      {
        path: 'emr-search',
        name: PAGES.BY_RECORD,
        component: ByRecordSearchPortal,
        meta: {
          auth: true,
          title: 'Lumos',
        },
      },
      {
        path: 'by-event',
        name: PAGES.BY_EVENT,
        component: ByEventSearchPortal,
        meta: {
          auth: true,
          title: 'Lumos',
        },
      },
      {
        path: 'url/:id',
        name: PAGES.URL,
        component: SearchPortal,
        meta: {
          auth: true,
          title: 'Lumos',
        },
      },
      {
        path: 'analysis-url/:id',
        name: PAGES.ANALYSIS_URL,
        component: SearchPortal,
        meta: {
          auth: true,
          title: 'Lumos',
        },
      },
      {
        path: '*',
        redirect: baseRedirect,
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes,
});

router.beforeEach(async (to, _, next) => {
  try {
    await Vue.nextTick();

    // handle native redirect which will add session to query
    if (to.query?.session) {
      setCookie({
        session: to.query.session,
        refreshToken: '',
        userId: to.query?.userId,
        userName: '',
        userEmail: '',
      });
    }

    const store = router.app.$store;
    if (store) {
      // loads ui config into session storage
      if (to.meta?.auth && !store.state.user.isLogin) {
        return next({
          name: PAGES.LOADING,
          params: {
            target: to.path,
          },
        });
      }
    }

    next();
  } catch (e) {
    console.error(e);
    next(false);
  }
});

export default router;
