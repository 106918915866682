var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"eventSearch",staticClass:"event-search"},[(
      !(
        _vm.experimentalGroupState === _vm.GROUP_STATE.EDITING ||
        _vm.controlGroupState === _vm.GROUP_STATE.EDITING ||
        _vm.covariantGroupState === _vm.GROUP_STATE.EDITING ||
        _vm.outcomeGroupState === _vm.GROUP_STATE.EDITING
      )
    )?_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',{staticClass:"d-flex justify-content-center mt-40px"},[_c('div',{staticClass:"search-title"},[_vm._v("Click the button below to start a new cohort study")])])],1):_vm._e(),_c('b-row',{attrs:{"no-gutters":"","align-h":"center"}},[_c('b-col',{attrs:{"cols":"4"}},[_c('MainSearch')],1),_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.experimentalGroupState === _vm.GROUP_STATE.EDITING),expression:"experimentalGroupState === GROUP_STATE.EDITING"}],attrs:{"cols":"8"}},[_c('CohortGroup',{attrs:{"flowType":['experimental']}})],1),_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.controlGroupState === _vm.GROUP_STATE.EDITING),expression:"controlGroupState === GROUP_STATE.EDITING"}],attrs:{"cols":"8"}},[_c('CohortGroup',{attrs:{"flowType":['control']}})],1),_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.covariantGroupState === _vm.GROUP_STATE.EDITING),expression:"covariantGroupState === GROUP_STATE.EDITING"}],attrs:{"cols":"8"}},[_c('CovariantGroup')],1),_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.outcomeGroupState === _vm.GROUP_STATE.EDITING),expression:"outcomeGroupState === GROUP_STATE.EDITING"}],attrs:{"cols":"8"}},[_c('OutcomeGroup')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }