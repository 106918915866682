import BasicChartConfig from '@/components/charts/core/BasicChartConfig';
import { round, unset } from 'lodash';
import { ConditionData, ConditionName, RangeCondition } from '../../utils/conditions/core';
import Condition from '../../utils/conditions/core/Condition';
import ConditionArray from '../../utils/query/core/ConditionArray';
import { seriesMapper } from './core/ChartConfig';
import { ChartItem, ChartKey, ChartOption, ChartViewState, RawItem } from './core/Interface';
import { removePoint, UNSELECTED_COLOR } from './utils/utils';

const OPTION = {
  yAxis: {
    type: 'value',
    axisLabel: {
      formatter: removePoint,
    },
  },
  xAxis: {
    axisTick: {
      interval: 0,
    },
  },
  title: { text: '', subtext: '' },
  grid: {
    top: 30,
    left: 30,
  },
};

interface LabInsightRawItem extends RawItem {
  noRecord: boolean;
  from: number;
  to?: number;
}

function checkColor(cond: any, item: any): boolean {
  const startChecker = cond.start === '' || !item.to || cond.start < item.to;
  const endChecker = cond.end === '' || !item.from || cond.end >= item.from;

  return startChecker && endChecker && !item.noRecord;
}

export default class LabInsightConfig extends BasicChartConfig<LabInsightRawItem> {
  constructor() {
    super(ChartKey.LabInsight);

    this.merge(OPTION);
  }

  protected createChartItem(rawItem: LabInsightRawItem): ChartItem {
    const { key, doc_count: value } = rawItem;
    const condList = this.params.includes.additions.with(ConditionName.Lab);

    let color: string | null = null;
    if (condList.length !== 0) {
      const condition = condList[0] as RangeCondition;
      color = UNSELECTED_COLOR;
      if (condition.ranges.some((cond) => checkColor(cond, rawItem))) {
        color = null;
      }
    }

    return {
      key,
      value,
      code: key,
      name: key,
      color,
      percentage: Math.round((value / this.patientCount) * 100.0),
    };
  }

  public createConditionFromItem(): ConditionData {
    throw new Error('Method not implemented.');
  }

  public async applyLabData(
    rawData: any,
    newExtendConditions: Condition[],
    total: number,
    highBound: string,
    lowBound: string,
    digit: number
  ): Promise<ChartOption> {
    this.params = {
      includes: new ConditionArray(newExtendConditions),
      excludes: new ConditionArray([]),
      chartParams: {
        selection: '',
        viewState: ChartViewState.Normal,
      },
    };

    this.rawData = rawData;
    this.recordCount = total;
    this.patientCount = total;
    this.dataCount = rawData.length;

    this.data = this.createChartData(this.rawData);
    this.reloadChartItemMap();

    unset(this.option, 'series.data');
    this.merge({
      series: {
        data: this.data.map(seriesMapper),
        symbolSize: 6,
        markLine: {
          symbol: 'none',
          silent: true,
          lineStyle: {
            color: 'red',
          },
          data: [
            {
              name: 'high bound',
              symbol: 'none',
              xAxis: round(parseFloat(highBound), digit).toString(),
              label: {
                formatter: '{b}',
              },
            },
            {
              xAxis: round(parseFloat(lowBound), digit).toString(),
              name: 'low bound',
              label: {
                formatter: '{b}',
              },
            },
          ],
        },
      },
      xAxis: {
        type: 'category',
        triggerEvent: false,
        axisLabel: {
          align: 'center',
        },
        data: this.data.map((item) => item.key),
      },
    });

    this.setAxisData();
    return Promise.resolve(this.getOption());
  }

  protected get aggsQuery(): Record<string, unknown> {
    throw new Error('Not implemented yet.');
  }
}
