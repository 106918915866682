import { getUiConfig } from '@/utils/uiConfig';
import AgeConfig from '@/components/charts/AgeConfig';
import { ChartConfig } from '@/components/charts/core';
import { BasicChartQueryParams, RawItem, ChartKey } from '@/components/charts/core/Interface';
import DiagnosisConfig from '@/components/charts/DiagnosisConfig';
import GenderConfig from '@/components/charts/GenderConfig';
import MedicalOrderConfig from '@/components/charts/MedicalOrderConfig';
import MedicationConfig from '@/components/charts/MedicationConfig';
import ApiHelper from './ApiHelper';

const categories = getUiConfig().Analyze.StatisticSummary.medicalOrderCategories;
const ageConfig = new AgeConfig();
const genderConfig = new GenderConfig();
const medicationConfig = new MedicationConfig();
const ICD9 = new DiagnosisConfig(ChartKey.ICD9);
const ICD10 = new DiagnosisConfig(ChartKey.ICD10);
const medicalOrderConfig = new MedicalOrderConfig();

export function preloadChart(
  config: ChartConfig<RawItem>,
  params: BasicChartQueryParams,
  api: ApiHelper,
  userId: string,
  trackContent: any
) {
  if (config.chartKey === ChartKey.MedicalOrder) {
    config.applyData(
      {
        ...params,
        chartParams: {
          ...params.chartParams,
          selection: categories ? categories[0].code : '檢查',
        },
      },
      api,
      userId,
      trackContent,
      0
    );
  } else {
    config.applyData(params, api, userId, trackContent, 0);
  }
}

export default function preloadCharts(
  params: BasicChartQueryParams,
  api: ApiHelper,
  userId: string,
  trackContent: any
) {
  [ageConfig, genderConfig, medicationConfig, ICD9, ICD10, medicalOrderConfig].forEach((config) => {
    preloadChart(config, params, api, userId, trackContent);
  });
}
