import { ChartItem, ChartKey } from '@/components/charts/core/Interface';
import { ConditionData } from '@/utils/conditions/core';
import { PRECISION_THRESHOLD } from '@/utils/constants';
import AdvanceESQuery from '@/utils/query/core/advance/AdvanceESQuery';
import AdvLineChartConfig from './AdvLineChartConfig';

export interface RawItem {
  doc_count: number;
  key: string;
}

interface SurvivalRawItem extends RawItem {
  percentage: number;
  deathNumber: number;
  currentPatient: number;
}

interface SurvivalChartItem extends ChartItem {
  percentage: number;
  deathNumber: number;
  currentPatient: number;
}

const XAXIS_LABEL = [...Array(61).keys()];

const DISTINCT_PATIENT = {
  patientNum: {
    cardinality: {
      field: 'CHART_NO',
      precision_threshold: PRECISION_THRESHOLD,
    },
  },
};

const OPTION = {
  xAxis: {
    type: 'category',
    data: XAXIS_LABEL,
    axisLabel: {
      formatter(value: any) {
        return `${value.toString()}m`;
      },
      interval: 29,
    },
    splitNumber: 5,
    boundaryGap: false,
  },
  yAxis: {
    type: 'value',
    triggerEvent: true,
    axisLabel: {
      formatter(value: { toString: () => any }) {
        return `${value.toString()}%`;
      },
    },
    max: 100,
    min: 0,
    interval: 50,
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'none',
      animation: false,
      label: {
        backgroundColor: '#ccc',
        borderColor: '#aaa',
        borderWidth: 1,
        shadowBlur: 0,
        shadowOffsetX: 0,
        shadowOffsetY: 0,
        color: '#222',
      },
    },
  },
  grid: {
    top: 80,
    right: 40,
    bottom: 20,
    left: 20,
  },
};

export default class AdvSurvivalConfig extends AdvLineChartConfig {
  constructor() {
    super(ChartKey.AdvSurvival);

    this.hasViewAll = true;
    this.merge(OPTION);
    this.setChartType();
  }

  protected postProcessQuery(query: AdvanceESQuery<this>) {
    const processedQuery = query;
    processedQuery.setFirstOccurrence(true);
    return processedQuery;
  }

  // eslint-disable-next-line max-len
  protected createChartItem(rawItem: SurvivalRawItem, index: number): SurvivalChartItem {
    const { percentage, deathNumber, currentPatient } = rawItem;
    return {
      key: index.toString(),
      value: percentage,
      code: index.toString(),
      name: index.toString(),
      color: '#333f6b',
      deathNumber,
      percentage,
      currentPatient,
    };
  }

  protected createConditionFromItem(): ConditionData {
    throw new Error('Method not implemented.');
  }

  protected percentageFormatter(seriesList: any) {
    let currentSeries: any = null;
    if (Array.isArray(seriesList)) {
      currentSeries = seriesList[0];
    } else {
      currentSeries = seriesList;
    }
    const item = currentSeries.data as SurvivalChartItem;
    return `Current Patient: ${item.currentPatient}<br>Death Patient count: ${item.deathNumber}<br>Survival rate: ${item.percentage}%<br>Time: ${item.name} months`;
  }

  protected get aggsQuery(): Record<string, unknown> {
    const rangeList = [];
    for (let i = 1; i <= 61; i += 1) {
      rangeList.push({
        from: i,
      });
    }
    return {
      range: {
        field: 'MONTH_TO_DIE',
        ranges: rangeList,
      },
    };
  }

  private get deathPatientCountAggsQuery(): Record<string, unknown> {
    const rangeList = [];
    for (let i = 0; i <= 60; i += 1) {
      rangeList.push({
        bool: {
          filter: [
            {
              term: {
                MONTH_TO_DIE: i,
              },
            },
            {
              term: {
                DEATH_FLAG: true,
              },
            },
          ],
        },
      });
    }
    return {
      filters: {
        filters: rangeList,
      },
    };
  }

  protected async preProcessReturnData(datas: any) {
    const allPatient = datas.aggregations.result.buckets.map((item: { doc_count: number }) => item.doc_count);

    const deathPatient = datas.aggregations.deathPatientCount.buckets.map(
      (item: { doc_count: number }) => item.doc_count
    );

    let probabilityCounter = 1;

    const result = [];

    for (let i = 0; i < 61; i += 1) {
      probabilityCounter *= 1 - deathPatient[i] / allPatient[i];
      result.push({
        percentage: Math.round(probabilityCounter * 100),
        deathNumber: deathPatient[i],
        currentPatient: allPatient[i],
      });
    }

    return result;
  }

  public getAggsQuery(): Record<string, unknown> {
    return {
      result: {
        ...this.aggsQuery,
      },
      deathPatientCount: {
        ...this.deathPatientCountAggsQuery,
      },
      ...DISTINCT_PATIENT,
    };
  }
}
