<template>
  <a-modal
    :visible="visible"
    :mask="true"
    :destroyOnClose="true"
    :footer="null"
    :zIndex="9999"
    :closable="false"
    @cancel="closeModal"
  >
    <SavedConcept :restore="true" :condition="condition" ref="SavedConcept" />
    <div class="d-flex align-items-center justify-content-end mt-20px">
      <div class="mr-20px cancel-button" @click="closeModal">Cancel</div>
      <div class="done-button" @click="clickImport">Done</div>
    </div>
  </a-modal>
</template>
<script>
import SavedConcept from '@/components/forms/SavedConcept.vue';
import { mapMutations } from '@/store';
import { cloneDeep, uniqBy } from 'lodash';
import { ClickMixin } from '@/mixins';
import ConditionList from '@/utils/ConditionList';
import { DiagnosisRanges, DiagnosisType } from '@/utils/conditions/core';

export default {
  name: 'RestoreConceptModal',
  mixins: [ClickMixin],
  components: {
    SavedConcept,
  },
  props: {
    condition: Object,
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    ...mapMutations('condition', ['setCondition', 'setExcludedCondition']),
    openModal() {
      this.visible = true;
      this.trackClick('openModal');
    },
    closeModal() {
      this.visible = false;
      this.trackClick('closeModal');
    },
    clickImport() {
      let selectedList = [];

      const result =
        this.condition.conceptType === 'include'
          ? cloneDeep(this.$store.state.condition.conditions)
          : cloneDeep(this.$store.state.condition.excludedConditions);
      const resultIndex = result.findIndex((item) => item.time === this.condition.time);
      selectedList = selectedList.concat(result[resultIndex].list);

      this.$refs.SavedConcept.selectedList.forEach((item) => {
        selectedList = selectedList.concat(item.list);
      });

      if (result[resultIndex].list.length === 0 && selectedList[0] && selectedList[0].field === 'med') {
        result[resultIndex] = {
          ...result[resultIndex],
          ...ConditionList.medication,
        };
      } else if (result[resultIndex].list.length === 0 && selectedList[0] && selectedList[0].field === 'medicalOrder') {
        result[resultIndex] = {
          ...result[resultIndex],
          ...ConditionList.medicalOrder,
        };
      } else if (result[resultIndex].list.length === 0 && selectedList[0] && selectedList[0].type === 'GroupAndMap') {
        const diagnosis = cloneDeep(ConditionList.diagnosis);
        diagnosis.tags.push(DiagnosisRanges.ALL);
        diagnosis.tags.push(DiagnosisType.GroupAndMap);
        diagnosis.diagnosisRange = false;

        result[resultIndex] = {
          ...result[resultIndex],
          ...diagnosis,
        };
      }

      result[resultIndex].list = uniqBy(selectedList, 'code');

      if (this.condition.conceptType === 'include') {
        this.setCondition(result);
      } else {
        this.setExcludedCondition(result);
      }

      this.closeModal();
      this.trackClick('clickImport');
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .ant-modal {
  min-width: 860px;
  max-width: 860px;
  width: 860px !important;
}

/deep/ .ant-modal-body {
  padding: 40px;
}

/deep/ .ant-modal-content {
  border-radius: 16px;
}

.cancel-button {
  padding: 12px 16px;
  border: 1px solid #0074e6;
  box-sizing: border-box;
  border-radius: 8px;
  background: #ffffff;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #0074e6;
  cursor: pointer;
}

.done-button {
  background: #0074e6;
  border-radius: 8px;
  padding: 12px 16px;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  cursor: pointer;
}
</style>
