export function getLabListAggrQuery(query) {
  return {
    ...query,
    aggs: {
      patientNum: {
        terms: {
          field: 'CHART_NO',
          size: 2000,
          order: {
            patient: 'asc',
          },
        },
        aggs: {
          top: {
            top_hits: {
              sort: [
                {
                  IN_DATE: {
                    order: 'asc',
                  },
                },
              ],
              _source: {
                includes: ['LAB_CATEGORY', 'CHART_NO'],
              },
              size: 1,
            },
          },
          patient: {
            cardinality: {
              field: 'CHART_NO',
            },
          },
        },
      },
    },
    size: 0,
  };
}

export function getFilteredAggrQuery(query, labListSet, labName) {
  if (!(labName in labListSet)) {
    // eslint-disable-next-line no-param-reassign
    labListSet[labName] = 1;
  }
  return {
    ...query,
    aggs: {
      Lab_filter: {
        // LAB_CATEGORY
        filter: { term: { LAB_CATEGORY: labName } },
        aggs: {
          patientNum: {
            terms: {
              field: 'CHART_NO',
              // size: Math.min(labListSet[labName], 10),
              size: 10,
              order: {
                patient: 'asc',
              },
            },
            aggs: {
              top: {
                top_hits: {
                  sort: [
                    {
                      IN_DATE: {
                        order: 'asc',
                      },
                    },
                  ],
                  _source: {
                    includes: ['LAB', 'CHART_NO'],
                  },
                  size: 1,
                },
              },
              patient: {
                cardinality: {
                  field: 'CHART_NO',
                },
              },
            },
          },
        },
      },
    },
    size: 0,
  };
}
