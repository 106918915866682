import { ITimeRelationEvent, InExclusionList, ITailTimeRelationEvent } from '../Interface';
import BaseMedicalEvent from './BaseMedicalEvent';
import TimeRelation from './TimeRelation';
import TailTimeRelationEvent from './TailTimeRelationEvent';

class TimeRelationEvent extends BaseMedicalEvent implements InExclusionList, ITimeRelationEvent {
  readonly relation: TimeRelation;

  readonly inclusionList: ITailTimeRelationEvent[];

  readonly exclusionList: ITailTimeRelationEvent[];

  readonly eventStage: string;
  eventId: string | undefined;

  constructor(timeRelationEvent: ITimeRelationEvent) {
    super(timeRelationEvent);
    this.relation = new TimeRelation(timeRelationEvent.relation);
    this.inclusionList = timeRelationEvent.inclusionList.map((event) => new TailTimeRelationEvent(event));
    this.exclusionList = timeRelationEvent.exclusionList.map((event) => new TailTimeRelationEvent(event));
    this.eventStage = timeRelationEvent.eventStage;
  }
}

export default TimeRelationEvent;
