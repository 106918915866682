<template>
  <div id="chart">
    <v-chart ref="chart" :options="chartOptions" style="width: 100%; height: 350px" autoresize />
  </div>
</template>

<script>
export default {
  name: 'LineChart',
  props: {
    examData: Object,
  },
  data() {
    return {
      chartOptions: {
        xAxis: {
          type: 'time',
        },
        yAxis: {
          type: 'value',
          min: 'dataMin',
          max: 'dataMax',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
          },
        },
        visualMap: {
          show: false,
          precision: 1,
          dimension: 1,
          pieces: [],
          outOfRange: {
            color: '#333f6b',
          },
        },
        series: [
          {
            data: [],
            type: 'line',
            itemStyle: {
              color: '#333f6b',
            },
            symbol: 'circle',
            symbolSize: 6,
            markLine: {
              silent: true,
              symbol: 'none',
              label: {
                formatter: '{b}',
                position: 'middle',
              },
              data: [],
            },
          },
        ],
      },
    };
  },
  created() {
    this.initiate();
  },
  methods: {
    compare(a, b) {
      if (Date.parse(a[0]) > Date.parse(b[0])) {
        return 1;
      }
      return -1;
    },
    initiate() {
      this.updateOption();
      this.updateSeries();
    },
    updateSeries() {
      const { valHis } = this.examData;
      this.chartOptions.series[0].data = valHis.sort(this.compare);
    },
    isNumeric(n) {
      // eslint-disable-next-line no-restricted-globals
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
    updateOption() {
      const [lower, higher] = [this.examData.lowBound, this.examData.highBound];
      if (this.isNumeric(lower)) {
        this.chartOptions.series[0].markLine.data.push({
          name: 'low bound',
          yAxis: lower,
          lineStyle: {
            color: '#00E396',
          },
        });
      }
      if (this.isNumeric(higher)) {
        this.chartOptions.series[0].markLine.data.push({
          name: 'high bound',
          yAxis: higher,
          lineStyle: {
            color: '#00E396',
          },
        });
      }
      if (this.isNumeric(higher) && this.isNumeric(lower)) {
        if (higher === lower) {
          this.chartOptions.visualMap.pieces.push({
            lt: parseFloat(lower),
            color: '#da6d6d',
          });
          this.chartOptions.visualMap.pieces.push({
            gte: parseFloat(higher),
            lte: parseFloat(higher),
            color: '#333f6b',
          });
          this.chartOptions.visualMap.pieces.push({
            gt: parseFloat(higher),
            color: '#da6d6d',
          });
        } else {
          this.chartOptions.visualMap.pieces.push({
            lt: parseFloat(lower),
            color: '#da6d6d',
          });
          this.chartOptions.visualMap.pieces.push({
            gt: parseFloat(higher),
            color: '#da6d6d',
          });
        }
      } else if (this.isNumeric(higher) && !this.isNumeric(lower)) {
        this.chartOptions.visualMap.pieces.push({
          lte: parseFloat(higher - 1),
          color: '#333f6b',
        });
        this.chartOptions.visualMap.pieces.push({
          gt: parseFloat(higher - 1),
          lte: parseFloat(higher),
          color: '#333f6b',
        });
        this.chartOptions.visualMap.pieces.push({
          gt: parseFloat(higher),
          color: '#da6d6d',
        });
      } else if (!this.isNumeric(higher) && this.isNumeric(lower)) {
        this.chartOptions.visualMap.pieces.push({
          lte: parseFloat(lower),
          color: '#da6d6d',
        });
        this.chartOptions.visualMap.pieces.push({
          gt: parseFloat(lower),
          lte: parseFloat(lower + 1),
          color: '#333f6b',
        });
        this.chartOptions.visualMap.pieces.push({
          gt: parseFloat(lower),
          color: '#333f6b',
        });
      } else {
        this.chartOptions.visualMap.pieces.push({
          lte: parseFloat(0),
          color: '#333f6b',
        });
        this.chartOptions.visualMap.pieces.push({
          gt: parseFloat(0),
          lte: parseFloat(1),
          color: '#333f6b',
        });
        this.chartOptions.visualMap.pieces.push({
          gt: parseFloat(1),
          color: '#333f6b',
        });
      }
    },
  },
};
</script>
