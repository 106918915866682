<template>
  <div>
    <div class="time-title-text mb-5px">Time Period</div>
    <a-range-picker
      :placeholder="getPlaceholder"
      format="YYYY-MM"
      :value="value"
      :mode="mode"
      @panelChange="handlePanelChange"
      @change="handleChange"
    />
  </div>
</template>
<script>
import { mapState, mapMutations } from '@/store';
import moment from 'moment';
import { getUiConfig } from '@/utils/uiConfig';
import { cloneDeep } from 'lodash';

export default {
  name: 'TimeFilter',
  data() {
    return {
      mode: ['month', 'month'],
      value: [],
    };
  },
  mounted() {
    this.syncStoreToLocal();
  },
  watch: {
    conditions() {
      this.syncStoreToLocal();
    },
  },
  computed: {
    ...mapState('condition', ['conditions']),
    getTimeFromStore() {
      return this.conditions.find((item) => item.name === 'Time');
    },
    getPlaceholder() {
      return [
        moment(getUiConfig().SearchForm.time.start, 'X').format('YYYY-MM'),
        moment(getUiConfig().SearchForm.time.end, 'X').format('YYYY-MM'),
      ];
    },
  },
  methods: {
    moment,
    ...mapMutations('condition', ['setCondition']),
    handleChange(value) {
      this.value = value;
      this.setTimeCondition();
    },
    handlePanelChange(value, mode) {
      this.value = value;
      this.mode = [mode[0] === 'date' ? 'month' : mode[0], mode[1] === 'date' ? 'month' : mode[1]];
      this.setTimeCondition();
    },
    syncStoreToLocal() {
      if (this.getTimeFromStore) {
        if (this.getTimeFromStore.ranges[0]) {
          this.value = [
            moment(this.getTimeFromStore.ranges[0].start, 'X'),
            moment(this.getTimeFromStore.ranges[0].end, 'X'),
          ];
        } else {
          this.value = [
            moment(getUiConfig().SearchForm.time.start, 'X'),
            moment(getUiConfig().SearchForm.time.end, 'X'),
          ];
        }
      }
    },
    setTimeCondition() {
      const timeRanges = [
        {
          start: this.value[0].unix(),
          end: this.value[1].unix(),
        },
      ];

      const result = cloneDeep(this.conditions);
      const resultIndex = this.conditions.findIndex((item) => item.name === 'Time');
      result[resultIndex].ranges = timeRanges;

      this.setCondition(result);
    },
  },
};
</script>
<style lang="scss" scoped>
.time-title-text {
  font-size: 14px;
  line-height: 17px;
  color: #5b5b5b;
}
</style>
