<template>
  <div class="advance-codeSearch-filter" v-click-outside="closeSearchMenu">
    <div
      class="condition-add-text"
      @click="clickCondition"
      :class="{
        'condition-add-text-focus': isOpenMenu && selectType === 1,
      }"
    >
      + CONDITION
    </div>
    <div class="type-menu" v-if="isOpenMenu && selectType === 1" @click.stop="">
      <b-row no-gutters>
        <b-col>
          <b-row no-gutters>
            <b-col
              class="option-text"
              @click.stop="clickType('Diagnosis')"
              :class="{
                'highlight-option-text': selectType === 'Diagnosis',
                'disable-type': getDisableType('Diagnosis'),
              }"
              >Diagnosis</b-col
            >
          </b-row>
          <b-row no-gutters>
            <b-col
              class="option-text"
              @click.stop="clickType('Medication')"
              :class="{
                'highlight-option-text': selectType === 'Diagnosis',
                'disable-type': getDisableType('Medication'),
              }"
              >Medication</b-col
            >
          </b-row>
          <b-row no-gutters>
            <b-col
              class="option-text"
              @click.stop="clickType('Medical Order')"
              :class="{
                'highlight-option-text': selectType === 'Diagnosis',
                'disable-type': getDisableType('Medical Order'),
              }"
              >Medical Order</b-col
            >
          </b-row>
        </b-col>
      </b-row>
    </div>
    <div v-if="selectType !== 1 && selectType !== -1 && isOpenMenu" class="search-menu" @click.stop="">
      <b-row no-gutters>
        <b-col class="menu-title">{{ selectType }}</b-col>
        <b-col class="d-flex justify-content-end">
          <b-img src="@/assets/images/icon_close.svg" class="close-icon" @click="closeSearchMenu" />
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col>
          <div v-if="selectType === 'Diagnosis' && isOpenMenu" class="filter-container">
            <AdvanceCodeSearchFilter
              @setValue="handleAdvanceDiagnosis"
              :conditions="[]"
              :codeCondition="{ list: [] }"
              :placeholder="uiConfig.searchPlaceholder.DX"
              :fetchOptions="fetchICD"
              feedbackField="diagnosis"
              :advance="true"
              :refCondition="getCodeSearchListRef(this.diagnosis)"
              :index="index"
              :eventType="eventType"
            />
          </div>
          <div v-if="selectType === 'Medication' && isOpenMenu" class="filter-container">
            <AdvanceCodeSearchFilter
              @setValue="handleAdvanceMedication"
              :conditions="[]"
              :codeCondition="{ list: [] }"
              :placeholder="uiConfig.searchPlaceholder.MED"
              :fetchOptions="fetchMedication"
              feedbackField="medication"
              :advance="true"
              :refCondition="getCodeSearchListRef(this.medication)"
              :index="index"
              :eventType="eventType"
            />
          </div>
          <div v-if="selectType === 'Medical Order' && isOpenMenu" class="filter-container">
            <AdvanceCodeSearchFilter
              @setValue="handleAdvanceMedicalOrder"
              :conditions="[]"
              :codeCondition="{ list: [] }"
              :placeholder="uiConfig.searchPlaceholder.ORDER"
              :fetchOptions="fetchMedicalOrder"
              feedbackField="Medical Order"
              :advance="true"
              :refCondition="getCodeSearchListRef(this.medicalOrder)"
              :index="index"
              :eventType="eventType"
            />
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import { cloneDeep, isEqual } from 'lodash';

import ClickOutside from 'vue-click-outside';

import AdvanceCodeSearchFilter from '@/components/advanceSearch/filter/AdvanceCodeSearchFilter.vue';
import { EventType } from '@/utils/conditions/core/Interface';
import FormMixin from '@/mixins/formMixin';
import { EventMixin, Utils, AdvanceUpdateMixin, ClickMixin, AdvanceSearchMixin } from '@/mixins';
import FilterMixin from '@/mixins/filterMixin';
import ConditionList from '@/utils/ConditionList';
import { mapSubUiConfig } from '@/utils/uiConfig';
import { VUE_EVENT_NAME } from '@/utils/constants';

export default {
  name: 'AdvanceCodeSearchMenu',
  components: {
    AdvanceCodeSearchFilter,
  },
  mixins: [FormMixin, EventMixin, Utils, FilterMixin, AdvanceUpdateMixin, ClickMixin, AdvanceSearchMixin],
  directives: {
    ClickOutside,
  },
  props: {
    index: {
      type: Number,
      default: 1,
    },
    eventType: {
      type: String,
      default: EventType.FIX_EVENT,
    },
    path: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    selectType() {
      this.$emit('updateSelectType', this.selectType);
    },
  },
  data() {
    return {
      ...cloneDeep(ConditionList),
      selectType: -1,
    };
  },
  mounted() {
    this.$root.$on(VUE_EVENT_NAME.SET_CLICK_CONDITION, (path) => {
      if (!isEqual(this.path, path)) {
        this.closeSearchMenu();
      }
    });
  },
  destroyed() {
    this.$root.$off(VUE_EVENT_NAME.SET_CLICK_CONDITION);
  },
  computed: {
    ...mapSubUiConfig('SearchForm'),
    currentConditions() {
      const filterData = [];

      if (this.diagnosis.list.length !== 0) {
        filterData.push(this.diagnosis);
      }

      if (this.medication.list.length !== 0) {
        filterData.push(this.medication);
      }

      if (this.medicalOrder.list.length !== 0) {
        filterData.push(this.medicalOrder);
      }
      return filterData;
    },
    isOpenMenu() {
      return this.isCurrentMenu(this.path, 'AdvanceCodeSearchMenu');
    },
  },
  methods: {
    clickCondition() {
      this.$root.$emit(VUE_EVENT_NAME.SET_SEARCH_PADDING_BOTTOM, this.index, this.eventType);
      this.openSearchMenu();
      this.advanceTrackClick('clickCondition', {
        path: this.path,
        index: this.index,
      });
    },
    openSearchMenu() {
      if (this.selectType === 1) {
        this.selectType = -1;
      } else {
        this.setCurrentMenu(this.path, 'AdvanceCodeSearchMenu');
        this.selectType = 1;
      }
    },
    closeSearchMenu() {
      if (this.selectType !== -1) {
        this.advanceTrackClick('closeSearchMenu', {
          path: this.path,
          index: this.index,
        });
      }

      this.$root.$emit(VUE_EVENT_NAME.CLOSE_SEARCH_PADDING_BOTTOM);
      this.selectType = -1;
    },
    clickType(value) {
      this.$root.$emit(VUE_EVENT_NAME.SET_SEARCH_PADDING_BOTTOM);
      this.selectType = value;
      this.setCurrentMenu(this.path, 'AdvanceCodeSearchMenu');

      this.advanceTrackClick('clickType', {
        path: this.path,
        index: this.index,
        value,
      });
    },
    handleAdvanceDiagnosis(value, andOr) {
      this.diagnosis.list = value;
      this.diagnosis.andOr = andOr;
      this.updateEventWithPath(
        {
          condition: this.currentConditions,
        },
        this.path
      );
    },
    handleAdvanceMedication(value, andOr) {
      this.medication.list = value;
      this.medication.andOr = andOr;
      this.updateEventWithPath(
        {
          condition: this.currentConditions,
        },
        this.path
      );
    },
    handleAdvanceMedicalOrder(value, andOr) {
      this.medicalOrder.list = value;
      this.medicalOrder.andOr = andOr;
      this.updateEventWithPath(
        {
          condition: this.currentConditions,
        },
        this.path
      );
    },
    clearCondition() {
      this.diagnosis.list = [];
      this.medication.list = [];
      this.medicalOrder.list = [];

      this.advanceTrackClick('clearCondition', {
        path: this.path,
        index: this.index,
      });
    },
    getDisableType(name) {
      if (this.currentConditions.length !== 0) {
        return this.currentConditions[0].name !== name;
      }
      return false;
    },
    getCodeSearchListRef(data) {
      if (data.list.length !== 0) {
        return data.list;
      }
      return [];
    },
  },
};
</script>
<style lang="scss" scoped>
.condition-add-text {
  padding: 5px 15px;
  background: #4a94d2;
  border-radius: 53px;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;
}

.condition-add-text-focus {
  background: #0f4895;
}

.type-menu {
  position: absolute;
  width: 135px;
  height: 134px;
  background: #ffffff;
  border: 1px solid #bdbdbd;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(51, 63, 107, 0.2);
  border-radius: 5px;
  z-index: 1;
  margin: 6px 0px 0px 0px;
}

.option-text {
  height: 18px;
  text-align: left;
  font-size: 14px;
  line-height: 18px;
  color: #333333;
  margin: 20px 0px 0px 20px;
  cursor: pointer;
}

.highlight-option-text {
  color: #0f4895;
  font-weight: bold;
}

.filter-container {
  margin: 21px 0px 0px 0px;
}

.search-menu {
  position: absolute;
  z-index: 1;
  max-width: 880px;
  min-width: 880px;
  border: 1px solid #0f4895;
  box-shadow: 0px 4px 10px rgba(51, 63, 107, 0.2);
  border-radius: 6px;
  padding: 20px 0px 0px 0px;
  background: #ffffff;
  height: auto;
  margin: 6px 0px 0px 0px;
}

.menu-title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #333f6b;
  margin-left: 20px;
}

.close-icon {
  cursor: pointer;
  margin-right: 20px;
}

.disable-type {
  opacity: 0.3;
  pointer-events: none;
  cursor: not-allowed;
}
</style>
<style scoped src="@/css/home.css"></style>
<style scoped src="@/css/searchForm.css"></style>
<style lang="scss" scoped src="@/css/searchForm.scss"></style>
