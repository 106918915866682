<template>
  <div id="patient-lab-page">
    <a-select
      id="filter"
      style="width: 200px; margin-top: 20px; margin-bottom: 20px"
      placeholder="Diagnosis"
      v-model="labName"
      defaultValue="Blood"
      v-if="labNameList && dataSource.length !== 0"
      @change="changeLabName()"
      :getPopupContainer="(trigger) => trigger.parentNode"
    >
      <a-select-option
        v-for="option in labNameList"
        :value="option.name"
        :key="option.name"
        :label="option.name"
        :title="option.name"
        :disabled="option.disable"
      >
        {{ option.name }}
      </a-select-option>
    </a-select>
    <a-input
      placeholder="Lab data filter (use ; as delimiter)"
      class="filter-input"
      v-model="labColumnFilter"
      v-if="columns[1]"
    />
    <a-table
      class="lab-table"
      :rowKey="(record, index) => `${index}_${record.TIME_KEY}`"
      :dataSource="dataSource"
      :columns="filteredColumns"
      :loading="loading"
      :scroll="{ x: 1000 }"
      tableLayout="auto"
      :pagination="{ size: 'small', itemRender: itemPagerRender }"
      :class="{ 'lab-header-hide': dataSource.length === 0 }"
      :customRow="setCustomRow"
    >
    </a-table>
    <a-modal
      class="linechart-modal"
      width="800px"
      v-model="visible"
      @ok="handleOk"
      :mask="true"
      :destroyOnClose="true"
      :footer="null"
      :zIndex="9999"
    >
      <div id="exam-title">{{ currentExam }}</div>
      <linechart :examData="examData"></linechart>
    </a-modal>
  </div>
</template>

<script>
import { Table, Modal, Tag, Tooltip, Button } from 'ant-design-vue';
import { LineChart } from '@/components/charts';
import getExtendCondition from '@/utils/query/getExtendCondition';
import { ClickMixin, LabMixin } from '@/mixins';
import { handleFirstLayer, processPatientLabRecord } from '@/utils/lab/getTableConfig';
import { filterColumn } from '@/utils/util';
import { CLASS, FEEDBACK } from '@/utils/constants';
import { mapSubUiConfig } from '@/utils/uiConfig';
import { getLabName, getDefaultLabName } from '@/utils/lab/LabName';
import { mapState } from '../../store';
import { VisitType } from '../../utils/conditions/core';

export default {
  name: 'ExamTable',
  mixins: [ClickMixin, LabMixin],
  components: {
    'a-table': Table,
    'a-modal': Modal,
    linechart: LineChart,
    // eslint-disable-next-line
    'a-tag': Tag,
    // eslint-disable-next-line
    'a-tooltip': Tooltip,
    // eslint-disable-next-line
    'a-button': Button,
  },
  props: {
    chart_no: String,
  },
  computed: {
    ...mapState('condition', ['conditions', 'labSelect']),
    ...mapSubUiConfig('Analyze'),
    filteredColumns() {
      if (!this.columns || !this.labName) {
        return [];
      }
      const result = this.columns.filter((column) => {
        if (column.title === 'Date') {
          return true;
        }
        return column.title === this.labName;
      });
      if (this.labColumnFilter && result[1]) {
        result[1].children = filterColumn(
          'disable',
          result,
          CLASS.PATIENT_LAB_TABLE_COLUMN_DISABLE,
          this.labColumnFilter
        );
      }
      if (this.labColumnFilter.length === 0 && result[1]) {
        result[1].children = filterColumn(
          'enable',
          result,
          CLASS.PATIENT_LAB_TABLE_COLUMN_DISABLE,
          this.labColumnFilter
        );
      }
      return result;
    },
  },
  async mounted() {
    this.loading = true;
    this.records = await this.fetchAllData().catch((error) => {
      console.error(error);
    });
    this.setColumn();
    this.sortRow();
    this.labName = getDefaultLabName();
    this.loading = false;
  },
  watch: {
    labColumnFilter() {
      this.trackClick('changeLabColumnFilter', { labColumnFilter: this.labColumnFilter });
    },
  },
  data() {
    return {
      currentExam: String,
      records: [],
      dataSource: [],
      pagination: {},
      loading: true,
      columns: [],
      visible: false,
      examData: {},
      higher: null,
      lower: null,
      categoryDict: {},
      labName: getDefaultLabName(),
      labNameList: [],
      labColumnFilter: '',
    };
  },
  methods: {
    processPatientLabRecord,
    async fetchAllData() {
      let allRecordQuery = this.getQuery({
        includes: [getExtendCondition('Chart no', this.chart_no)],
      });
      allRecordQuery = {
        ...allRecordQuery,
        size: 10000,
        _source: { includes: ['LAB'] },
      };
      this.$api.setLogQueryInfo({
        type: FEEDBACK.QUERY_TYPE.LAB_TABLE_CHART_NO,
        conditions: this.$store.getters['condition/currentConditions'],
        additionalCondition: [getExtendCondition('Chart no', this.chart_no)],
      });
      const seriesData = await this.$api.search(allRecordQuery, 0);
      return seriesData.hits.hits;
    },
    showModal() {
      this.visible = true;
      this.trackClick('showModal', { visible: this.visible });
    },
    handleOk() {
      this.visible = false;
      this.trackClick('handleOk', { visible: this.visible });
    },
    setColumn() {
      const columns = [
        {
          title: 'Date',
          dataIndex: 'TIME_KEY',
          key: 'TIME_KEY',
          className: 'timekey',
        },
      ];
      const { dict, timeDict, categoryDict } = this.processPatientLabRecord(this.records);
      const dataSource = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(timeDict)) {
        dataSource.push({
          TIME_KEY: key,
          ...value,
        });
      }

      const listOfDict = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const key of Object.keys(dict)) {
        listOfDict.push({
          name: key,
          ...dict[key],
        });
      }
      listOfDict.sort((a, b) => b.counter - a.counter);
      const firstLayer = this.handleFirstLayer(listOfDict, this.handleColumn, VisitType.ALL);
      this.columns = columns.concat(firstLayer);
      this.dataSource = dataSource;
      this.categoryDict = categoryDict;

      // process disable/enable option
      const labNameList = [];
      this.columns.forEach((item, index) => {
        if (index !== 0) {
          labNameList.push(item.title);
        }
      });
      const labNameKeys = Object.keys(getLabName(VisitType.ALL));
      this.labNameList = labNameKeys.map((item) => {
        if ([...labNameList].includes(item)) {
          return { name: item, disable: false };
        }
        return { name: item, disable: true };
      });
    },
    handleFirstLayer,
    handleColumn(lab, key) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this;
      const name = lab.CHHEAD;
      const unit = lab.UNIT ? `(${lab.UNIT})` : '';
      return {
        title: lab.isNumber ? (
          <a-button class="lab-button" ghost size="small" type="primary">
            {name}
            {unit}
          </a-button>
        ) : (
          <div>
            {name}
            {unit}
          </div>
        ),
        dataIndex: lab.name,
        key,
        name: `${name}${unit}`,
        chHead: lab.CHHEAD,
        customRender: (text) => this.renderAbnormal(lab, text, key),
        customHeaderCell: (column) => ({
          on: {
            click: () => {
              vm.examData = vm.categoryDict[column.key];
              vm.currentExam = column.name;
              if (lab.isNumber) {
                vm.showModal();
                this.trackClick('handleColumn', { examData: vm.examData, currentExam: vm.currentExam });
              }
            },
          },
        }),
        className: 'second-layer-row',
      };
    },
    sortRow() {
      this.dataSource
        .sort((a, b) => {
          const keyA = Date.parse(a.TIME_KEY);
          const keyB = Date.parse(b.TIME_KEY);
          if (keyA < keyB) {
            return -1;
          }
          if (keyA > keyB) {
            return 1;
          }
          return 0;
        })
        .reverse();
    },
    setCustomRow(record) {
      const columnList = Object.keys(record).filter((word) => word.includes(this.labName));
      return {
        class: columnList.length === 0 ? 'no-data-raw' : '',
      };
    },
    itemPagerRender(current, type, originalElement) {
      if (this.dataSource.length <= 10) {
        return null;
      }
      if (type === 'prev') {
        return (
          <a>
            <b-icon-caret-left-fill />
          </a>
        );
      }
      if (type === 'next') {
        return (
          <a>
            <b-icon-caret-right-fill />
          </a>
        );
      }
      return originalElement;
    },
    changeLabName() {
      this.trackClick('changeLabName', { labName: this.labName });
    },
  },
};
</script>

<style scoped>
.lab-table >>> .ant-table-pagination.ant-pagination {
  float: None;
  margin: 16px 0;
  text-align: center;
}

.lab-table >>> .ant-pagination-item-active {
  border: 1px solid #ffffff;
  color: #333f6b;
  font-weight: bold;
}

.lab-table >>> .ant-table-thead > tr:first-child > th:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background-color: #ebecf0 !important;
  font-size: 16px;
  width: 17px;
  height: 20px;
  padding-right: 70px;
  border-right: 1px solid white;
  margin-right: 1px;
}

.lab-table >>> .ant-pagination-item-active a {
  color: #333f6b;
}

.lab-table >>> .ant-table-thead > tr:first-child > th:first-child > span > div > span:first-child {
  opacity: 0.5;
}

.lab-table >>> .ant-table-thead > tr:first-child > th:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.lab-table >>> .ant-table-thead > tr > th {
  border-bottom: none;
  background-color: #ebecf0;
}

.pagin >>> .ant-pagination-item-container {
  bottom: 2px;
  font-size: 1px;
}

.pagin >>> .ant-pagination-jump-next,
.pagin >>> .ant-pagination-jump-prev {
  pointer-events: none;
}

.lab-table >>> .first-layer-row:nth-child(odd) {
  background-color: #d8d9e8;
  /* background-color: #ebecf0; */
}
.lab-table >>> .first-layer-row:nth-child(even) {
  background-color: #d8d9e8;
}

.lab-table >>> .lab-button {
  background-color: #ffffff !important;
  color: #333f6b !important;
  border: 1px solid #333f6b !important;
}

.lab-header-hide /deep/ .ant-table-body {
  display: none;
}

.lab-table >>> .no-data-raw {
  background-color: #f9f9f9 !important;
}

.lab-table >>> .no-data-raw:hover {
  background-color: #f9f9f9 !important;
  -webkit-transition: none;
  transition: none;
}

.lab-table >>> .ant-table-tbody > .no-data-raw:hover > td {
  background-color: #f9f9f9;
}

.lab-table >>> .timekey {
  background-color: white !important;
}

#patient-lab-page >>> .linechart-modal >>> .ant-modal-header {
  padding-bottom: 0px !important;
  border-bottom: 0px !important;
}
.linechart-modal >>> .ant-modal-body {
  padding: 5px 24px;
}

#exam-title {
  margin-top: 10px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}

.filter-input {
  width: 250px;
  margin-left: 30px;
}
</style>

<style lang="scss">
.tag-font {
  height: 16px;
  /* font-family: Muli; */
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
}

.lab-table >>> .ant-table-header-column {
  font-size: 16px;
  color: #333f6b;
}

.lab-table >>> .ant-table-thead > tr > th {
  padding: 11px 16px;
}

.linechart-modal > .ant-modal-content > .ant-modal-header {
  border-bottom: 0px;
}

.linechart-modal > .ant-modal-content > .ant-modal-body {
  padding-top: 0px;
}

.patient-lab-table-column-disable {
  display: none;
}
</style>
