/* eslint-disable max-classes-per-file */
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { get, assign, cloneDeep } from 'lodash';
import IndicatorPieConfig from '@/components/charts/indicator/IndicatorPieConfig';
import IndicatorTrendConfig from '@/components/charts/indicator/IndicatorTrendConfig';
import { getUiConfig } from '@/utils/uiConfig';
import { getIndicatorCondition } from '@/utils/indicatorConfig/condition';
import { getPatientQuery } from '@/utils/query';
import ConditionList from '@/utils/ConditionList';
import { ISearchState } from '@/utils/conditions/core/Interface';
import { getQuery } from '@/utils/queryUtils';

export interface IndicatorConfigSet {
  trend: IndicatorTrendConfig;
  pie: IndicatorPieConfig;

  index: number;
  name: string;
  facet: string;
  description: string;
}

@Module({
  namespaced: true,
})
export default class IndicatorModule extends VuexModule {
  indicatorKey = Object.keys(getIndicatorCondition())[0];
  charts: IndicatorConfigSet[] = [];
  cohortDetail: { SearchStateObject: ISearchState } | {} = {};

  totalPatient = 0;
  totalRecord = 0;

  timePeriod = {
    start: getUiConfig().SearchForm.time.start,
    end: getUiConfig().SearchForm.time.end,
  };

  ageRange = {
    start: 0,
    end: 200,
  };

  @Mutation
  initConfig() {
    this.charts = getIndicatorCondition()[this.indicatorKey].indicators.map((indicator) => ({
      index: indicator.index,
      name: indicator.name,
      facet: indicator.facet,
      description: indicator.description,
      trend: new IndicatorTrendConfig(indicator),
      pie: new IndicatorPieConfig(indicator),
    }));
    this.cohortDetail = getIndicatorCondition()[this.indicatorKey].cohort;
  }

  @Mutation
  setIndicatorKey(newKey: string) {
    this.indicatorKey = newKey;
  }

  @Mutation
  updateWithPathMutation({ path, ...content }: any) {
    const target = get(this, path);
    assign(target, content);
  }

  @Mutation
  setTotalPatient(num: number) {
    this.totalPatient = num;
  }

  @Mutation
  clearIndicator() {
    this.charts = [];
    this.cohortDetail = {};

    this.totalPatient = 0;
    this.totalRecord = 0;

    this.timePeriod = {
      start: getUiConfig().SearchForm.time.start,
      end: getUiConfig().SearchForm.time.end,
    };

    this.ageRange = {
      start: 0,
      end: 200,
    };
  }

  @Action initIndicator() {
    this.context.commit('initConfig');
    this.context.dispatch('fetchTotalPatient');
  }

  @Action
  updateIndicatorKey({ key }: { key: string }) {
    this.context.commit('setIndicatorKey', key);
    this.context.dispatch('initIndicator');
  }

  @Action({ rawError: true })
  async fetchTotalPatient() {
    const condList = { includes: [], excludes: [] } as any;
    condList.includes = condList.includes.concat(cloneDeep(this.cohortDetail).SearchStateObject.indexEvent.condition);
    condList.includes.push(
      { ...ConditionList.age, ranges: this.ageRange },
      { ...ConditionList.time, ranges: this.timePeriod }
    );
    const query = getPatientQuery(getQuery(condList));
    const res = await this.context.rootState.api.search(query);
    this.context.commit('setTotalPatient', res.aggregations.patientNum.value);
  }
}
