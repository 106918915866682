import { RawItem, IndicatorChartQueryParams, ChartOption, ChartKey } from '@/components/charts/core/Interface';
import { ConditionData } from '@/utils/conditions/core';
import ApiHelper from '@/utils/ApiHelper';
import { unset, get } from 'lodash';
import { PRECISION_THRESHOLD } from '@/utils/constants';
import dayjs from 'dayjs';
import Indicator from '@/utils/indicatorConfig/Indicator';
import numeral from 'numeral';
import IndicatorChartConfig from './core/IndicatorChartConfig';

const DISTINCT_PATIENT = {
  patientNum: {
    cardinality: {
      field: 'CHART_NO',
      precision_threshold: PRECISION_THRESHOLD,
    },
  },
};

const OPTION = {
  title: {
    show: false,
  },
  series: {
    type: 'bar',
    cursor: 'default',
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow',
    },
    formatter: (params: any[]) => {
      const { data, axisValueLabel }: { data: { [key: string]: number }; axisValueLabel: string } = params[0];

      return `
        ${axisValueLabel}<br>
        percentage: ${numeral(data.value).format('0.00%')}<br>
        接受人次: ${data.numerator}<br>
        總人次: ${data.denominator}<br>`;
    },
  },
  xAxis: {
    type: 'category',
    axisLabel: {
      rotate: 45,
    },
    axisTick: {
      show: false,
    },
    axisLine: {
      show: false,
    },
  },
  yAxis: {
    type: 'value',
    axisLabel: {
      formatter: (val: number) => numeral(val).format('0%'),
    },
    axisTick: {
      show: false,
    },
    axisLine: {
      show: false,
    },
  },
};

interface DiagnosisRawItem extends RawItem {
  count_unique_patient: {
    value: number;
  };
}

export default class IndicatorTrendConfig extends IndicatorChartConfig {
  protected isRangeLessThanOneYear = false;

  constructor(indicator: Indicator) {
    super(ChartKey.IndicatorTrend, indicator);

    this.merge(OPTION);
    this.indicator = indicator;
  }

  protected createConditionFromItem(): ConditionData {
    throw new Error('Method not implemented.');
  }

  protected createChartItem(rawItem: any): any {
    return rawItem;
  }

  protected get aggsQuery(): any {
    return null;
  }

  protected setAxisData(): void {
    unset(this.option, 'xAxis.data');
    const format = this.isRangeLessThanOneYear ? 'YYYY/MM' : 'YYYY';
    this.merge({
      xAxis: {
        data: this.results[0].aggregations.result.buckets.map((item: { key: number }) =>
          dayjs(item.key).format(format)
        ),
      },
    });
  }

  protected getTrendQuery(query: any): any {
    const format = this.isRangeLessThanOneYear ? 'yyyy-MM' : 'yyyy';

    return {
      ...query,
      aggs: {
        result: {
          date_histogram: {
            field: 'TIME_KEY',
            calendar_interval: this.isRangeLessThanOneYear ? '1M' : '1y',
            min_doc_count: 0,
            format,
            extended_bounds: {
              min: dayjs.unix(this.params.timePeriod.start).format(format.toUpperCase()),
              max: dayjs.unix(this.params.timePeriod.end).format(format.toUpperCase()),
            },
          },
          aggs: {
            count_unique_patient: {
              cardinality: {
                field: 'CHART_NO',
              },
            },
          },
        },
        ...DISTINCT_PATIENT,
      },
    };
  }

  public getQueries() {
    return super.getQueries(this.getTrendQuery.bind(this));
  }

  public async applyData(params: IndicatorChartQueryParams, api: ApiHelper): Promise<ChartOption> {
    this.params = params;

    const { timePeriod } = this.params;
    const startDay = dayjs.unix(timePeriod.start);
    const endDay = dayjs.unix(timePeriod.end);
    this.isRangeLessThanOneYear = endDay.diff(startDay, 'year') === 0;

    this.results = await this.fetchData(api, this.getQueries());

    const rawData = this.results[0].aggregations.result.buckets.map(
      (item: { count_unique_patient: { value: number } }, index: number) => {
        const path = `aggregations.result.buckets[${index}].doc_count`;
        const numerator = get(this.results[1], path);
        const denominator = get(this.results[0], path);

        return {
          value: numerator / denominator,
          numerator,
          denominator,
        };
      }
    );

    this.data = this.createChartData(rawData);

    this.reloadChartItemMap();

    unset(this.option, 'series.data');
    this.merge({
      series: {
        data: this.data,
      },
    });

    this.setAxisData();
    return Promise.resolve(this.getOption());
  }

  protected get hasData(): boolean {
    return this.data.some((item) => !isNaN(item.value));
  }
}
