import BasicChartConfig from '@/components/charts/core/BasicChartConfig';
import { PRECISION_THRESHOLD, UICONFIG } from '@/utils/constants';
import { sourceOption, CodeViewAllOption } from '@/utils/util';
import {
  ConditionName,
  LogicOp,
  SearchType,
  CategoryConditionData,
  CategoryCondition,
} from '../../utils/conditions/core';
import { ChartItem, RawItem, BasicChartQueryParams, ChartKey } from './core/Interface';
import { UNSELECTED_COLOR } from './utils/utils';

interface DiagnosisRawItem extends RawItem {
  patient_count: {
    value: number;
  };
}

export default class DoctorIdConfig extends BasicChartConfig<DiagnosisRawItem> {
  constructor() {
    super(ChartKey.DoctorId);

    this.hasViewAll = true;
    this.merge({ ...sourceOption });
  }

  protected setAxisData(): void {
    this.unset('xAxis.data');
    this.unset('yAxis.data');
    this.merge({
      xAxis: {
        name: this.bucketByPatient ? 'Patient' : 'Record',
      },
      yAxis: {
        data: this.data.map((item) => item.key),
      },
    });
  }

  public createViewAllConfig(): DoctorIdConfig {
    const config = new DoctorIdConfig();
    config.resultSize = UICONFIG.VIEW_ALL_SIZE;
    config.bucketByPatient = this.bucketByPatient; // Copy over previous bucketing status.
    config.merge({ ...CodeViewAllOption });
    return config;
  }

  protected processQueryParams(): BasicChartQueryParams {
    const condList = this.params.includes.select(
      (cond) => cond.hasTag(SearchType.Raw) || cond.name !== ConditionName.DoctorId
    );
    return {
      ...this.params,
      includes: condList,
    };
  }

  protected createChartItem(rawItem: DiagnosisRawItem): ChartItem {
    const {
      key,
      doc_count,
      patient_count: { value },
    } = rawItem;
    const condList = this.params.includes.additions.with(ConditionName.DoctorId);

    let color: string | null = null;
    if (condList.length !== 0) {
      const condition = condList[0] as CategoryCondition;
      color = UNSELECTED_COLOR;
      if (condition.hitKey(key)) {
        color = null;
      }
    }

    const targetValue = this.bucketByPatient ? value : doc_count;

    return {
      key,
      value: targetValue,
      code: key,
      name: key,
      color,
      percentage: this.calculatePercentage(targetValue),
    };
  }

  public createConditionFromItem(chartItem: ChartItem): CategoryConditionData {
    return {
      name: ConditionName.DoctorId,
      tags: [SearchType.Basic, SearchType.Additional],
      andOr: LogicOp.Or,
      list: [chartItem.code],
    };
  }

  protected get aggsQuery(): Record<string, unknown> {
    const orderField = this.getOrderField();
    return {
      terms: {
        field: 'DOCTOR_ID',
        size: this.resultSize,
        order: {
          [orderField]: 'desc',
        },
        exclude: '',
      },
      aggs: {
        patient_count: {
          cardinality: {
            field: 'CHART_NO',
            precision_threshold: PRECISION_THRESHOLD,
          },
        },
      },
    };
  }
}
