<template>
  <b-navbar class="nav-bar" :style="`left: ${-scrollLeft}px`">
    <div class="d-flex align-items-center">
      <div class="product-name" ref="productName">ASUS Lumos</div>
      <div class="version ml-5px" ref="version" style="margin-top: 3px">{{ VERSION }}</div>
    </div>
    <div ref="mutilDropdown" class="ml-auto d-flex align-items-center">
      <ExportCenter :style="{ margin: '-5px 24px 0px 0px' }" />
      <Feedback :style="{ margin: '-5px 10px 0px 0px' }" />
      <b-dropdown
        right
        :no-caret="true"
        toggle-class="text-decoration-none"
        variant="link"
        class="question-mark-dropdown"
        title="Help"
        @show="showQuestionMarkDropdown"
        @hide="hideQuestionMarkDropdown"
      >
        <template v-slot:button-content>
          <img alt="aics" src="@/../public/img/navbar/help.svg" class="icon-help" />
        </template>
        <b-dropdown-item-button @click="openUserGuide(getUiConfig().UserGuide.path)"
          >EMR Search / By Event User Guide</b-dropdown-item-button
        >
        <b-dropdown-item-button @click="openDemoVideo(2)" class="mt-30px">
          <div>EMR Search Demo Video</div>
        </b-dropdown-item-button>
        <b-dropdown-item-button @click="openUserGuide(getUiConfig().UserGuide.cohortStudyPath)" class="mt-30px"
          >Cohort Study User Guide</b-dropdown-item-button
        >
        <b-dropdown-item-button @click="openDemoVideo(1)" class="mt-30px" v-if="getUiConfig().SearchForm.cohortStudy">
          <div>Cohort Study Demo Video</div>
        </b-dropdown-item-button>
        <b-dropdown-item-button @click="clickLanding" class="mt-30px">
          <div>Reset Tutorial</div>
        </b-dropdown-item-button>
      </b-dropdown>
      <b-dropdown
        right
        class="aics-menu-portal"
        :no-caret="true"
        toggle-class="text-decoration-none"
        variant="link"
        @show="hideLockPortal = true"
        @hidden="hideLockPortal = false"
        v-if="showPortalMenu && !doctorId"
      >
        <template v-slot:button-content>
          <a-tooltip
            overlayClassName="portal-switch-hint-icon"
            placement="bottom"
            arrowPointAtCenter
            :title="'AICS Smart Medical Services'"
            :visible="visibleTooplip"
          >
            <img
              class="portal-switch-icon"
              src="@/../public/img/navbar/portal_switch.svg"
              alt="portal-switch"
              @mouseover="showHoverSwitch(true)"
              @mouseout="showHoverSwitch(false)"
            />
          </a-tooltip>
        </template>
        <b-dropdown-item href="#">
          <div>
            <div class="portal-brand justify-content-md-center" @click="goPortal()">
              <span class="portal-switch-title justify-content-md-center">ASUS AICS Smart Medical</span>
            </div>
            <div class="service-box">
              <div
                v-for="(value, key) in ServiceConfig"
                :key="key"
                class="service-item"
                v-show="checkResource(value.CODE)"
              >
                <Service
                  :code="value.CODE"
                  :title="value.TITLE"
                  :info="value.INFO"
                  :serviceImg="value.IMG"
                  :url="value.URL"
                  :enable="checkResource(value.CODE)"
                ></Service>
              </div>
            </div>
          </div>
        </b-dropdown-item>
      </b-dropdown>
      <b-dropdown
        right
        class="aics-menu"
        :no-caret="true"
        v-if="!doctorId"
        @show="hideLockLogout = true"
        @hidden="hideLockLogout = false"
        title="Account"
      >
        <!-- Using 'button-content' slot -->
        <template v-slot:button-content>
          <img class="avatar" src="@/../public/img/navbar/member.svg" alt="avatar" />
        </template>
        <b-dropdown-item disabled class="userinfo-border-bottom">
          <div class="userinfo-card">
            <span class="userinfo-img">
              <img src="@/../public/img/navbar/default_avatar.svg" alt="avatar" />
            </span>
            <span class="userinfo-text">
              <div class="userinfo-name">{{ name }}</div>
              <div class="userinfo-email">{{ email }}</div>
            </span>
          </div>
        </b-dropdown-item>
        <b-dropdown-item href="#" @click="handleLogout" class="logout-block">Log Out</b-dropdown-item>
      </b-dropdown>
    </div>
  </b-navbar>
</template>

<script>
import VueCookie from 'vue-cookie';
import Feedback from '@/components/Feedback.vue';
import ExportCenter from '@/components/ExportCenter.vue';
import { URL, VERSION, PAGES, DEMO_VIDEO_LINK, COOKIE } from '@/utils/constants';
import { SERVICE_CONFIG } from '@/utils/servieConfig';
import { ClickMixin } from '@/mixins';
import Service from '@/views/components/Service.vue';
import { debounce } from 'lodash';
import { getUiConfig } from '@/utils/uiConfig';
import { mapState, mapMutations, mapActions } from '../store';
export default {
  name: 'NavBar',
  mixins: [ClickMixin],
  data() {
    return {
      URL,
      VERSION,
      ServiceConfig: SERVICE_CONFIG,
      showHover: false,
      hideLockPortal: false,
      hideLockLogout: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
      doctorId: VueCookie.get(COOKIE.DOCTOR_ID),
      icdMapchecked: this.$store.state.confirmIcdMap,
      maxNavTabWidth: 200,
      questionMarkDropdown: false,
    };
  },
  components: {
    Service,
    Feedback,
    ExportCenter,
  },
  watch: {
    confirmIcdMap() {
      this.icdMapchecked = this.confirmIcdMap;
    },
  },
  computed: {
    ...mapState('user', ['permission', 'name', 'email', 'userId']),
    ...mapState('session', ['tabs']),
    ...mapState(['confirmIcdMap', 'settingMenu', 'scrollLeft']),
    resources() {
      return this.permission.resources;
    },
    showPortalMenu() {
      if (!this.resources) {
        return false;
      }
      const getResources = [];
      this.resources.forEach((element) => {
        const serviceCode = SERVICE_CONFIG.map((item) => item.CODE);
        if (serviceCode.some((item) => item === element.code)) {
          getResources.push(element.code);
        }
      });
      return getResources.length > 1;
    },
    visibleTooplip() {
      if (this.hideLockPortal || this.hideLockLogout) {
        return false;
      }
      return this.showHover;
    },
  },
  methods: {
    ...mapMutations(['setDemoVideoIndex', 'setDemoVideoVisible', 'setConfirmIcdMap', 'setEMRSearchLandingIndex']),
    ...mapActions('user', ['logout']),
    getUiConfig,
    openUserGuide(path) {
      window.open(`${process.env.VUE_APP_BASE_URL}${path}`);
      this.trackClick('openUserGuide', { path });
    },
    checkResource(code) {
      return !!this.permission.resources && this.permission.resources.some((res) => res.code === code);
    },
    async goPortal() {
      this.trackClick('goPortal', { name: 'goPortal' });
      window.location.replace(`${process.env.VUE_APP_ENDPOINT_PORTAL}from/EMR`);
    },
    showHoverSwitch: debounce(function (hover) {
      this.showHover = hover;
    }, 200),
    handleLogout() {
      this.trackClick('handleLogout', { name: 'handleLogout' });
      this.logout();
      this.$api.clearAllCache();
      this.$router.push({ name: PAGES.LOGIN }).catch((error) => {
        console.error(error);
      });
    },
    openDemoVideo(index) {
      this.setDemoVideoIndex(index);
      this.setDemoVideoVisible(true);
      this.trackClick('openDemoVideo', { index, video: DEMO_VIDEO_LINK, isAuto: false });
    },
    onChangeIcdMap(check) {
      this.icdMapchecked = check;
      this.setConfirmIcdMap(check);
      this.$api.setSettings(this.userId, {
        'search.edit_mapped_icd': check,
      });
      this.trackClick('onChangeIcdMap', { check });
    },
    preventClose() {
      this.$refs.dropdownSetting.show(true);
    },
    getMaxNavTabWidth() {
      const navBarWidth = window.innerWidth >= 1280 ? window.innerWidth : 1280;
      // navBarWidth - padding-left:20px - navLogo - productName - version - mutilDropdown
      if (Object.keys(this.$refs).length > 0) {
        this.maxNavTabWidth =
          navBarWidth -
          20 -
          this.$refs.navLogo.clientWidth -
          this.$refs.productName.clientWidth -
          this.$refs.version.clientWidth -
          this.$refs.mutilDropdown.clientWidth;
      }
    },
    showQuestionMarkDropdown() {
      this.questionMarkDropdown = true;
    },
    hideQuestionMarkDropdown() {
      this.questionMarkDropdown = false;
    },
    clickLanding() {
      this.setEMRSearchLandingIndex(1);
    },
  },
};
</script>
<style lang="scss" scoped>
.nav-img {
  width: 109px;
  height: 12px;
}

.nav-bar {
  top: 0;
  left: 0;
  background: #f1f0f4;
  line-height: 56px;
  height: 56px;
  padding: 0px 20px !important;
  min-width: 1280px;
}

.product-name {
  font-size: 18px;
  line-height: 23px;
  color: #212121;
}

.nav-logo {
  margin-left: 0px !important;
  margin-right: 15px !important;
}

.aics-menu {
  background-color: #f1f0f4;
  height: 43px;
  margin-right: 20px;
  margin-bottom: 3px;
  .text {
    font-size: 14px;
    color: #ffffff;
  }
}

.btn-img {
  background-image: url(../../public/img/navbar/member.svg);
  background-color: #f1f0f4;
  height: 24px;
  width: 24px;
  margin-right: 20px;
  margin-bottom: 2px;
  border: 0px;
  border-color: #f1f0f4;
  border-style: solid;
  &:focus,
  &:active {
    outline: 0px;
    box-shadow: inset 0px 0px 0px 0px red !important;
    background-color: #f1f0f4;
  }
}

.version {
  font-size: 14px;
  line-height: 18px;
  color: #212121;
}

/deep/ .dropdown-item {
  border-top: 0px !important;
  padding: 0px 25px 0px 26px !important;
}

/deep/ .dropdown-item:hover {
  background-color: #ffffff !important;
}

/deep/ .dropdown-menu {
  height: 221px;
  border-radius: 6px;
  box-shadow: 0 2px 10px 0 rgba(51, 63, 107, 0.15);
  border: solid 1px #cacdd9;
  background-color: #ffffff;
  li:hover {
    background-color: #ffffff;
  }
}

/deep/ .dropdown-menu:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid white;
  top: -7px;
  right: 16px;
  z-index: 1000;
}

.hint-container {
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  height: 40px;
  width: 40px;
  margin-top: -4px;
  margin-right: -8px;
  display: block;
  border-radius: 3px;
}

.hint-container:hover {
  background-color: #f5f5f522;
}

.portal-switch-icon {
  width: 24px;
  height: 24px;
}

.portal-brand {
  padding-left: 63px;
  padding-right: 62px;
  padding-top: 17px;
}

.portal-switch-brand {
  height: 15px;
  margin-bottom: 2px;
}

.portal-switch-title {
  height: 20px;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333f6b;
  padding-left: 13px;
}

.service-box {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  align-items: center;
  padding-top: 14px;
  padding-bottom: 8px;
  overflow: auto;
}

.service-item {
  flex: 0 1 auto;
  align-self: auto;
  width: 100px;
}

.question-mark {
  margin-right: 20px;
  width: 24px;
  height: 24px;
}

.nav-tab {
  padding: 0px 30px 0px 30px;
  height: 46px;
}

.no-cursur {
  cursor: default;
}

/deep/ .btn-secondary {
  color: transparent !important;
  background-color: transparent !important;
  border-color: transparent !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  border-left-width: 0px !important;
  border-right-width: 0px !important;
}

/deep/ .btn-secondary:hover {
  color: transparent !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

/deep/ .btn-secondary:after {
  color: transparent !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.settings-title {
  height: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #334e97;
}

.settings-sub-title {
  height: 18px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #212121;
  width: 271px;
}

.icon-help {
  width: 24px;
}

.question-mark-dropdown {
  /deep/.dropdown-menu {
    padding: 16px 0px !important;
    background: #ffffff;
    box-shadow: 0px 4px 10px rgba(51, 63, 107, 0.2);
    border-radius: 6px;
    border: none;
  }
  /deep/ .dropdown-item {
    padding: 0px 20px !important;
    font-size: 16px;
    line-height: 19px;
    color: #334e97;
  }
  /deep/ .dropdown-menu:after {
    right: 18px;
  }
}
</style>
<style lang="scss">
a#nav-member-dropdown__BV_button_ {
  background-color: #333f6b !important;
}

.portal-switch-hint-icon {
  top: 50px !important;
  width: 128px;
  height: 44px;
  .ant-tooltip-inner {
    text-align: center !important;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #e3e3e4;
    border-radius: 6px;
    width: 128px;
    height: 44px;
  }
}

.aics-menu .text {
  font-size: 14px;
  color: #ffffff;
}

.aics-menu {
  .dropdown-menu {
    height: auto !important;
  }
  .dropdown-menu-right {
    right: -14px !important;
  }
}

::v-deep .aics-menu {
  > a {
    outline-width: 0px;
  }

  .dropdown-menu {
    margin-top: 3px;
    border-radius: 6px;
    box-shadow: 0 2px 10px 0 rgba(51, 63, 107, 0.15);
    border: solid 1px #cacdd9;
    background-color: #ffffff;
  }

  .dropdown-menu:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid white;
    top: -7px;
    right: 16px;
    z-index: 1000;
  }
}

.avatar {
  width: 24px;
  height: 24px;
}

.userinfo-border-bottom {
  border-bottom: 1px solid #cacdd9;
}

.userinfo-card {
  padding: 6px 0px 11px 0px;
  margin-top: 0px;
  margin-left: -8px;
  display: flex;

  .userinfo-img {
    display: inline-block;

    > img {
      width: 32px;
      height: 32px;
    }
  }

  .userinfo-text {
    margin-top: -2px;
    padding-left: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    display: inline-block;

    .userinfo-name {
      padding-bottom: 2px;
      font-size: 14px;
      color: #333333;
    }

    .userinfo-email {
      font-size: 12px;
      color: #828282;
    }
  }
}

.logout-block {
  text-align: center;
  .dropdown-item {
    height: 32px;
    font-size: 14px;
    font-weight: normal !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333333 !important;
    padding-top: 10px !important;
    padding-left: 22px !important;
  }
}

.aics-menu-portal {
  margin-right: 20px;
  .dropdown-toggle {
    padding-right: 0px !important;
    padding-left: 0px !important;
    border-right-width: 0px !important;
    border-left-width: 0px !important;
  }

  .dropdown-menu {
    margin-top: 4px;
  }

  .dropdown-menu-right {
    right: -23px;
  }

  .dropdown-menu:after {
    right: 25px;
  }
}

.btn:focus {
  -webkit-box-shadow: 0px 0px 0px 0px white !important;
  box-shadow: none !important;
}

.div-product-name {
  margin-top: 3px;
}

.info-item {
  height: 60px !important;
  font-size: 16px;
  line-height: 20px;
  .dropdown-item {
    padding: 20px 15px !important;
    width: fit-content;
    height: fit-content;
  }
}

.question-mark-dropdown {
  margin: -5px 10px 0px 0px;
  .dropdown-menu {
    height: auto;
  }
}

.settings-dropdown {
  margin: -2px 7px 0px 0px;
  .dropdown-menu {
    padding: 0px;
    height: 70px;
    min-width: 125px;
  }
}

.setting-item {
  height: 60px !important;
  font-size: 16px;
  line-height: 20px;
  .dropdown-item {
    padding: 20px 15px !important;
    width: fit-content;
    height: fit-content;
    cursor: default !important;
    outline: 0px auto -webkit-focus-ring-color;
  }
}
</style>
