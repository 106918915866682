<template>
  <div :class="loading ? 'block' : ''">
    <a-spin id="loading-spin" v-if="loading" />
    <!-- NOTE: keep-alive can be removed after data are cached in vuex store -->
    <keep-alive>
      <router-view />
    </keep-alive>

    <DemoVideo />
    <TabLimitation />
    <UnsavedChanges />
    <LandingModal />
  </div>
</template>

<script>
import DemoVideo from '@/components/modals/DemoVideo.vue';
import TabLimitation from '@/components/modals/TabLimitation.vue';
import UnsavedChanges from '@/components/modals/UnsavedChanges.vue';
import LandingModal from '@/components/modals/LandingModal.vue';
import dayjs from 'dayjs';
import TrackTimeMixin from '@/mixins/trackTimeMixin';
import PatientNumberMixin from '@/mixins/patientNumberMixin';
import UpdateMixin from '@/mixins/updateMixin';
import { getUiConfig, mapSubUiConfig } from '@/utils/uiConfig';
import { PAGES, FEATURES } from '@/utils/constants';
import { mapMutations, mapState } from 'vuex';
import { v4 as uuid } from 'uuid';
import { ClickMixin } from '@/mixins';

export default {
  name: 'Base',
  mixins: [PatientNumberMixin, TrackTimeMixin, UpdateMixin, ClickMixin],
  components: {
    DemoVideo,
    TabLimitation,
    UnsavedChanges,
    LandingModal,
  },
  computed: {
    ...mapSubUiConfig('SearchForm'),
    ...mapState('session', ['tabs', 'activeTabIndex']),
    ...mapState('user', ['isLogin', 'userId']),
  },
  data() {
    return {
      loading: false,
    };
  },
  watch: {
    isLogin() {
      this.checkFeedbackHint();
    },
  },
  methods: {
    ...mapMutations([
      'setDemoVideoIndex',
      'setDemoVideoVisible',
      'setMedicalOrderCategory',
      'setRootId',
      'setFeedbackHintVisable',
    ]),
    ...mapMutations('session', ['addTab', 'setActiveTabIndex']),
    ...mapMutations('cohortSession', {
      eventAddTab: 'addTab',
      eventSetActiveTabIndex: 'setActiveTabIndex',
    }),
    redirect(data, next) {
      if (data.content.hits) {
        // eslint-disable-next-line no-underscore-dangle
        const { query } = data.content.hits.hits[0]._source;

        const tabData = query.data || {};
        tabData.root = '';
        tabData.trackId = uuid();

        this.addTab({
          ...query,
          data: tabData,
        });
        const lastIndex = this.tabs.length - 1;
        const lastTab = this.tabs[lastIndex];
        this.update({
          includes: lastTab.includes,
          excludes: lastTab.excludes,
          uniqueIndex: lastIndex,
        });
        this.setActiveTabIndex(0);

        next({ name: PAGES.BY_RECORD });
      }
    },
    redirectEventAnalysis(data, next, id) {
      if (data.content.hits) {
        // eslint-disable-next-line no-underscore-dangle
        const query = data.content.hits.hits[0]._source;
        this.eventAddTab({
          id,
          name: query.displayName,
          eventSearch: query.request.searchState,
          currentMenu: '',
          experimentalGroupPatient: 0,
          controlGroupPatient: 0,
          data: {
            root: '',
            trackId: uuid(),
          },
          state: 'summary',
          includes: [],
          excludes: [],
          tabType: 'share',
          getLatest: true,
          characteristicsTableRequestInProgress: false,
          incidenceRateTableRequestInProgress: false,
          survivalRateChartRequestInProgress: false,
          coxRegressionRequestInProgress: false,
          patientListRequestInProgress: false,
          resultDone: false,
          characteristicsTableProgressPercent: 0,
          incidenceRateTableProgressPercent: 0,
          survivalRateChartProgressPercent: 0,
          coxRegressionProgressPercent: 0,
          patientListProgressPercent: 0,
        });
        this.eventSetActiveTabIndex(0);

        next({ name: PAGES.COHORT_STUDY })
      }
    },
    async checkFeedbackHint() {
      if (this.isLogin) {
        const firstTime = await this.$api.getIsFirstTime(this.userId, FEATURES.FEEDBACK_HINT);

        if (firstTime.first_use) {
          this.setFeedbackHintVisable(true);
        }
      }
    },
  },
  created() {
    // Disable right click for copy
    if (!this.uiConfig.rightClick) {
      window.addEventListener(
        'contextmenu',
        (e) => {
          if (e.origin !== process.env.VUE_APP_BASE_URL) {
            e.preventDefault();
          }
        },
        false
      );
    }

    window.addEventListener('beforeunload', () => {
      function sleep(delay) {
        const start = new Date().getTime();
        // eslint-disable-next-line no-empty
        while (new Date().getTime() < start + delay) {
          // block to send data
        }
      }
      this.tabs.forEach((element) => {
        if (!Object.keys(element).includes('SearchStateObject')) {
          // eslint-disable-next-line no-param-reassign
          element.updateTime = dayjs().format('YYYY/MM/DD HH:mm:ss');
          this.$api.saveQuery(element.name, element, 'history');
        }
      });
      if (this.tabs.length !== 0) {
        sleep(1000);
      }
    });
  },
  async mounted() {
    this.startTime('home');
    this.setDemoVideoVisible(false);
    this.setDemoVideoIndex(0);

    this.$api.setRoot({ trackId: this.$api.trackId });
    this.$api.setAdvanceRoot({ trackId: this.$api.advanceTrackId });
    this.$api.setCohortRoot({ trackId: this.$api.cohortTrackId });

    const categories = getUiConfig().Analyze.StatisticSummary.medicalOrderCategories ?? [];

    this.setMedicalOrderCategory(categories.map((item) => item.code));

    this.setRootId(this.$api.root);
    this.checkFeedbackHint();
  },
  async beforeRouteUpdate(to, from, next) {
    if (to.name === PAGES.URL && to.params.id) {
      this.loading = true;
      this.$api.getQueryByUrl(to.params.id).then((result) => {
        this.redirect(result, next);
        this.loading = false;
      });
    } else if (to.name === PAGES.ANALYSIS_URL && to.params.id) {
      this.loading = true;
      this.$api.getCohortStudyByUrl(to.params.id).then((result) => {
        this.redirectEventAnalysis(result, next, to.params.id);
        this.loading = false;
      });
    } else {
      next();
    }
  },
};
</script>

<style lang="scss" scoped>
#loading-spin {
  top: 50%;
  position: fixed;
  left: 50%;
  z-index: 10;
}

.block {
  position: relative;
  background: transparent;
  opacity: 0.5;
}

.block:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}
</style>
