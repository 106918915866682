import { ConditionFactory, ConditionName, LogicOp, MedicalTerm, TermCondition } from './core';

function getPCSCodeQuery(pcsCode: MedicalTerm[]) {
  return pcsCode.map((item) => ({ term: { PROCEDURE_PCS_CODE: `${item.code}` } }));
}

export default class PCSCodeDef extends TermCondition {
  public getFilter(): Record<string, unknown> {
    return {
      bool: {
        [this.andOr]: getPCSCodeQuery(this.list),
      },
    };
  }

  public get andOr(): LogicOp {
    return super.andOr;
  }
}

ConditionFactory.register(PCSCodeDef, ConditionName.PCSCode);
