module.exports = {
  HINT: {
    DIAGNOSIS_FILTER: 'Search top 5 ICD-10 and ICD-9 codes of each inpatient record.',
    CHARTS: {
      AGE: 'Count by first inpatient record of each patient',
    },
  },
  EMR_SECTION_NAME: {
    ICD: 'ICD-10',
    ICD9: 'ICD-9',
    DATE: 'Admission & Discharge Date',
    ICD10_DISDIAG: 'Discharge Diagnosis',
    MEDICATION: 'Medication',
    PATH_REPORT_1: 'Chief Complaint',
    PATH_REPORT_2: 'History',
    MEDICAL_REPORT: 'Physical Examination',
    TREATMENT_PATH: 'Treatment Course',
    OP_NOTE: 'OP Note',
    TOTAL: 'Cost',
    RISK_GROUP: 'Readmission risk with 14 days',
    ADM_PAHDG: 'Pathology',
    EXAM_FULL_TXT: 'Exam',
    SUBJ_NOTE: 'Subjective Note',
    OBJ_NOTE: 'Objective Note',
    OUTPATIENT_DATE: 'Date',
    TURN_NAME: '出院狀況',
    SECTION_NAME: 'Section',
    SYS_REVIEW: 'Review of System',
    ASSESSMENT: 'Assessment',
    PLAN: 'Plan',
  },
  EMR_TYPE_NAME: {
    icd9: 'ICD-9',
    icd10: 'ICD-10',
  },
  SEARCH_PLACEHOLDER: {
    DX: 'AMI... or 410 (ICD-9) or I21 (ICD-10)​',
    MED: 'Nitroglycerin... or ONITR (code)',
    MED1: 'Nitroglycerin... or NTG (code)',
    MED2: 'Nitroglycerin... or MTGLYR (code)',
    MED3: 'Nitroglycerin... or NTG02O (code)',
    MED4: 'Nitroglycerin... or 1MFN07 (code)',
    ORDER: 'Cardiac cath… or 心導管… or 18020B, 18021B (NHI code)',
    ORDER1: 'Cardiac catheterization… or 18020A, 18021A (NHI code)',
    P: 'Procedure',
    DOCTOR: 'Physician ID',
    LAB: 'wbc',
  },
  READMISSION_STANDARD: {
    ANY: 'any',
    NHI: 'nhi',
  },
};
