import { isEqual, merge } from 'lodash';
import dayjs from 'dayjs';
import { Module, Mutation } from 'vuex-module-decorators';
import { getUiConfig } from '@/utils/uiConfig';
import { getDefaultTabContent } from '@/utils/util';
import { ConditionData } from '../../utils/conditions/core';
import AdvancePortalModule from './AdvancePortalModule';
import { SearchStateCounter } from './Interface';
import SearchState from './SearchState';

export const VERSION = 1;

type TabUpdateParams = Partial<TabContent> & { index: number };

interface TabData {
  sortChartByAI: boolean;
  riskLevel: number;
  root: string;
  trackId: string;
  analyzeTab: string;
  onlyMyPatient: boolean;
}

interface BasicTabContent {
  id: string;
  name: string;
  updateTime: string;

  counters: SearchStateCounter;
  data: TabData;
}

export interface TabContent extends BasicTabContent {
  includes: Array<ConditionData>;
  excludes: Array<ConditionData>;
}
export interface AdvancePortalContent extends AdvancePortalModule, BasicTabContent {
  state: string;
  includes: Array<ConditionData>;
  excludes: Array<ConditionData>;
}

@Module({ namespaced: true })
export default class SessionModule extends SearchState {
  tabs: any = [];

  modifiedTabs = new Map<string, boolean>();

  historyTabs: Array<TabContent | AdvancePortalContent> = [];

  activeTabIndex = -1;

  NAME_COUNTER = 1;

  get isTabDirty(): (index: number) => boolean {
    return (index: number) => {
      const tabId = this.tabs[index].id;
      if (tabId === '') {
        return true;
      }
      if (this.modifiedTabs.get(tabId) === true) {
        return true;
      }
      return false;
    };
  }

  @Mutation
  resetSession() {
    this.activeTabIndex = -1;
    this.tabs.length = 0;
    this.modifiedTabs.clear();
    this.historyTabs.length = 0;
  }

  @Mutation
  setTabUndirty(index: number) {
    const tabId = this.tabs[index].id;
    if (tabId !== '') {
      this.modifiedTabs.delete(tabId);
    }
  }

  @Mutation
  setActiveTabIndex(activeTabIndex: number) {
    this.activeTabIndex = activeTabIndex;
  }

  @Mutation
  addTab(tab: TabContent) {
    const { medicalOrderCategories } = getUiConfig().Analyze.StatisticSummary;

    const advSearch = tab && Object.keys(tab).includes('SearchStateObject');

    const tabPayload = merge(getDefaultTabContent(medicalOrderCategories, advSearch), {
      ...tab,
      name: tab.name === 'Analysis' ? `Analysis ${this.NAME_COUNTER}` : tab.name,
    });

    // version 3.2 add bodyMassList in covariate
    if (!tabPayload.eventSearch.covariate.bodyMassList) {
      tabPayload.eventSearch.covariate.bodyMassList = [];
    }

    this.tabs.push(tabPayload);
    this.activeTabIndex = this.tabs.length - 1;
    this.NAME_COUNTER += 1;
  }

  @Mutation
  removeTab(index: number) {
    this.tabs.splice(index, 1);
    if (this.activeTabIndex > 1 && this.tabs.length === 0) {
      this.activeTabIndex = -1;
    } else if (this.activeTabIndex > this.tabs.length - 1) {
      this.activeTabIndex -= 1;
    }
  }

  @Mutation
  updateTab({ index, ...tab }: TabUpdateParams) {
    const currentTab = this.tabs[index];
    let dirty = false;
    Object.keys(tab).forEach((key) => {
      const value = (tab as any)[key];
      if (value !== undefined && !isEqual(currentTab[key], value)) {
        currentTab[key] = value;
        dirty = true;
      }
    });
    currentTab.updateTime = dayjs().format('YYYY/MM/DD HH:mm:ss');
    if (currentTab.id !== '' && dirty) {
      this.modifiedTabs.set(currentTab.id, true);
    }
  }

  @Mutation
  addHistoryTab(historyTab: TabContent) {
    this.historyTabs.push({
      ...historyTab,
      id: '',
    });
  }

  @Mutation
  setHistoryTabs(historyTabs: TabContent[]) {
    this.historyTabs = historyTabs;
  }
}
