export default {
  conceptName: 'Anti-TNF Biologics',
  conceptType: 'include',
  name: 'Medication',
  tags: ['Basic', 'Raw'],
  andOr: 'should',
  list: [
    {
      code: 'MIATG',
      label: 'MIATG',
      description: 'Thymoglobuline Inj.',
      chineseDescription: '兔抗胸腺細胞免疫球蛋白',
      value: 'MIATG',
      type: 'med',
      patientNum: 203,
      recordNum: 475,
      field: 'med',
      ingredient: 'antithymocyte immunoglobulin (rabbit)',
      atcCode: 'L04AA04',
      time: 1646619121093,
    },
    {
      code: 'MLCCS',
      label: 'MLCCS',
      description: "Sandimmun Neoral Oral Sol'n(急採)",
      chineseDescription: '新體睦(急採)',
      value: 'MLCCS',
      type: 'med',
      patientNum: 19,
      recordNum: 186,
      field: 'med',
      ingredient: 'ciclosporin',
      atcCode: 'L04AA01',
      time: 1646619126329,
    },
    {
      code: 'MTSET',
      label: 'MTSET',
      description: 'Zoloft (社區-需釋出)',
      chineseDescription: '樂復得',
      value: 'MTSET',
      type: 'med',
      patientNum: 25,
      recordNum: 202,
      field: 'med',
      ingredient: 'sertraline',
      atcCode: 'N06AB06',
      time: 1646619136347,
    },
  ],
  diagnosisRange: false,
  index: 1,
};
